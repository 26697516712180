import { useState } from 'react';
import {
  AppBarItem,
  AppBarNavLink,
  AppBarHeader,
  AppBarNav,
  AppBarNavList,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import {
  queryHelpers,
  useMediaQuery,
  Button,
  Modal,
  styled,
} from '@leagueplatform/genesis-core';
import careAdvisorLogo from 'assets/care-advisor-logo.svg';
import { UserDropdown } from 'components/navbar/user-dropdown/user-dropdown.component';
import { APP_NAV_DEFAULT_HEIGHT } from 'components/navbar/nav.constants';
import {
  NavLink,
  useNavLinksList,
} from 'components/navbar/use-nav-links-list.hook';
import { MobileNav } from 'components/navbar/mobile-nav/mobile-nav.component';
import { sendCapabilityNavLinkEvent } from 'utils/analytics-events';

const Logo = styled('img', {
  'max-width': '233px',
  'margin-right': '$four',
  'margin-left': '$one',
  '@mobile': {
    'margin-right': '$none',
    'min-width': '174px',
    width: '66%',
  },
});

interface Props {
  handleDeleteAccount: () => void;
}

export const AppNavBarComponent = ({ handleDeleteAccount }: Props) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { navLinks } = useNavLinksList();
  const { formatMessage } = useIntl();
  const isAboveLaptop = useMediaQuery(queryHelpers.up('laptop'));

  return (
    <AppBarHeader navHeight={APP_NAV_DEFAULT_HEIGHT}>
      <Logo src={careAdvisorLogo} alt="Care Advisor Connect" />
      {isAboveLaptop ? (
        // Desktop Navigation
        <AppBarNav>
          <AppBarNavList>
            {/* App Nav Home & Capability Areas */}
            {navLinks.map((navLink: NavLink) => (
              <AppBarNavLink
                to={navLink.to}
                key={navLink.message_id}
                onClick={sendCapabilityNavLinkEvent(navLink.message_id)}
                color="onSurface.text.primary"
                fontWeight="medium"
                hoverStyle={{ color: 'interactive.action.primary' }}
                decorativeBarColor="interactive.action.primary"
                listItemProps={{
                  flexGrow: 0,
                  marginRight: 'twoAndHalf',
                }}
              >
                {formatMessage({ id: navLink.message_id })}
              </AppBarNavLink>
            ))}
            <AppBarItem
              justifyContent="flex-end"
              marginRight={isAboveLaptop ? 'five' : 0}
            >
              {/* App Nav User Dropdown */}
              <UserDropdown handleDeleteAccount={handleDeleteAccount} />
            </AppBarItem>
          </AppBarNavList>
        </AppBarNav>
      ) : (
        // Mobile Navigation
        <Modal.Root
          open={menuOpen}
          onOpenChange={() => {
            setMenuOpen((previous) => !previous);
          }}
        >
          <Modal.Trigger>
            <Button
              icon="interfaceMenu"
              hideLabel
              quiet
              priority="tertiary"
              size="large"
              css={{
                margin: '$half $quarter',
              }}
              // TODO [OPTM-2355] Investigate best way to handle aria-conrols
              aria-controls={undefined}
            >
              {formatMessage({ id: 'OPEN_MENU' })}
            </Button>
          </Modal.Trigger>
          <Modal.Content
            layout="right"
            css={{
              '.GDS-modal': { maxWidth: 335 },
              '.GDS-modal-content': { paddingLeft: '$one' },
            }}
          >
            <MobileNav
              closeModal={() => setMenuOpen(false)}
              handleDeleteAccount={handleDeleteAccount}
            />
          </Modal.Content>
        </Modal.Root>
      )}
    </AppBarHeader>
  );
};
