import type { UseAuthReturn } from '@leagueplatform/auth-utils';
import { CamelCasedUserProfileAggregate } from '@leagueplatform/web-common';
import {
  AGREEMENT_CONTENT_TYPES,
  REACCEPT_GINA_FLOW_STATUS,
} from 'common/constants';

export type AuthError =
  | UseAuthReturn['error']
  | {
      type?: string;
      description: string;
    };

export type AgreementStatus = {
  agreementType: string;
  isAccepted: true;
  acceptanceDate?: string;
};

// Return object values as a union type
export type AgreementContentTypes =
  typeof AGREEMENT_CONTENT_TYPES[keyof typeof AGREEMENT_CONTENT_TYPES];

export type ReacceptGINAFlowStatus =
  typeof REACCEPT_GINA_FLOW_STATUS[keyof typeof REACCEPT_GINA_FLOW_STATUS];

export const enum SENTRY_SEVERITY_LEVELS {
  ERROR = 'error',
}

type CommonLinkProps = {
  text: string;
  color?: string;
};

type InternalLink = CommonLinkProps & {
  to: string;
};
type ExternalLink = InternalLink & {
  target: string;
};
type ButtonLink = CommonLinkProps & {
  onClick: () => void;
};

export type Link = InternalLink | ExternalLink | ButtonLink;

export type OptumUserProfile = CamelCasedUserProfileAggregate['userProfile'] & {
  optumSubscriberId?: string;
};

export type FeatureFlag = boolean | string;
