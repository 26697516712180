import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import {
  PRODUCT_AREA,
  SCREEN_NAMES,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { Modal } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { NotificationModal } from 'components/modals/notification-modal.component';
import { TERMS_OF_USE_ROUTE } from 'common/constants';
import { BodyContainer } from 'components/body-container/body-container.component';
import { CheckConfirmation } from 'components/check-confirmation/check-confirmation.component';
import { CaptionWithLink } from 'components/caption-with-link.component/caption-with-link.component';
import { useCommunicationPreferences } from 'hooks/use-communication-preferences.hook';

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'COMMUNICATION_PREFERENCES' }));

  const history = useHistory();
  const [
    { disableSms, allowEmailNotification, isLoading, isModalOpen, isWaiting },
    { toggleModal, setSmsNotification, setEmailNotification },
  ] = useCommunicationPreferences();
  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
  });

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'COMMUNICATION_PREFERENCES' })}
        goBack={history.goBack}
      />
      <BodyContainer css={{ maxWidth: '816px' }}>
        <CheckConfirmation
          label={formatMessage({ id: 'COMMUNICATION_PREFERENCES_ALLOW_EMAIL' })}
          name="allowEmailNotification"
          checked={allowEmailNotification}
          handleCheck={setEmailNotification}
          loading={isLoading || isWaiting === 'email'}
          ariaDisabled={!!isWaiting}
        />
        <CheckConfirmation
          label={formatMessage({ id: 'COMMUNICATION_PREFERENCES_ALLOW_TEXT' })}
          name="allowTextNotification"
          checked={!disableSms}
          handleCheck={setSmsNotification}
          loading={isLoading || isWaiting === 'sms'}
          ariaDisabled={!!isWaiting}
          hint={
            <CaptionWithLink
              messageId="COMMUNICATION_PREFERENCES_LEGAL_COPY"
              linkHref={TERMS_OF_USE_ROUTE}
              linkTextId="VISIT_OUR_TERMS"
              css={{ color: '$onSurfaceTextSubdued' }}
            />
          }
        />
      </BodyContainer>
      <Modal.Root open={isModalOpen} onOpenChange={toggleModal}>
        <NotificationModal
          submitButtonText={formatMessage({ id: 'OKAY' })}
          headingText={formatMessage({ id: 'N0_PHONE_NUMBER_ON_FILE' })}
          description={formatMessage({
            id: 'NO_PHONE_NUMBER_ON_FILE_DESCRIPTION',
          })}
        />
      </Modal.Root>
    </MainPageContainer>
  );
};
