import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  Icon,
  NavList,
  Modal,
  Divider,
  Box,
} from '@leagueplatform/genesis-core';
import { isMobileOS } from '@leagueplatform/analytics';
import {
  AppleAppIcon,
  GoogleAppIcon,
} from '@leagueplatform/web-common-components';
import { useLocation } from '@leagueplatform/routing';
import {
  NavLink,
  useNavLinksList,
} from 'components/navbar/use-nav-links-list.hook';
import {
  sendCapabilityNavLinkEvent,
  sendUserOptionEvent,
} from 'utils/analytics-events';
import {
  MobileNavLink,
  defaultMobileNavLinkStyles,
} from 'components/navbar/mobile-nav/mobile-nav.links.component';
import { APP_STORE } from 'common/constants';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';

type MobileNavProps = {
  closeModal: () => void;
  handleDeleteAccount: () => void;
};

const APP_STORE_ICON_WIDTH = '41px';

export const MobileNav = ({
  closeModal,
  handleDeleteAccount,
}: MobileNavProps) => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const location = useLocation();
  const isMobileDevice = isMobileOS(window?.navigator?.userAgent);
  const handleCapabilityNavLinkClick = (action: string) => {
    closeModal();
    sendCapabilityNavLinkEvent(action);
  };
  const mainNavLinks = navLinks.map(({ message_id, icon, to }: NavLink) => {
    const isActiveLink = location.pathname.startsWith(to as string);
    return (
      <NavList.RouterLink
        path={to as string}
        id={`${formatMessage({ id: message_id })}-link`}
        key={`${formatMessage({ id: message_id })}-link`}
        css={{
          '.GDS-nav-router-link': defaultMobileNavLinkStyles,
        }}
        onClick={() => handleCapabilityNavLinkClick(message_id)}
      >
        {icon && <Icon icon={isActiveLink ? `${icon}Filled` : icon} />}
        {formatMessage({ id: message_id })}
      </NavList.RouterLink>
    );
  });

  const userDropdownItems = useUserDropdownConfig(handleDeleteAccount);

  return (
    <StackLayout as="nav" spacing="$one" horizontalAlignment="stretch">
      <Modal.Title>
        <HeadingText
          level="2"
          size="xl"
          css={{
            paddingLeft: '$half',
          }}
        >
          {formatMessage({ id: 'MENU' })}
        </HeadingText>
      </Modal.Title>
      <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
        {mainNavLinks}
      </StackLayout>
      {userDropdownItems.map(({ heading, items }) => (
        <StackLayout
          spacing="$oneAndHalf"
          css={{
            width: '100%',
          }}
          key={`mobile_nav--${heading || items[0]?.text}`}
          horizontalAlignment="stretch"
        >
          <Divider css={{ margin: '0 $half', width: 'calc(100% - $half)' }} />
          <StackLayout spacing="$half" horizontalAlignment="stretch">
            {heading && (
              <HeadingText
                level="3"
                size="xl"
                css={{
                  paddingLeft: '$half',
                }}
              >
                {formatMessage({ id: heading })}
              </HeadingText>
            )}
            <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
              {items.map((item) => {
                const { text } = item;
                const handleOptionClick = () => {
                  closeModal();
                  sendUserOptionEvent(text);
                  if ('onClick' in item) item.onClick();
                };

                return (
                  <MobileNavLink
                    linkData={item}
                    onLinkClick={handleOptionClick}
                    key={`mobile-nav-link--${text}`}
                  >
                    {formatMessage({ id: text })}
                  </MobileNavLink>
                );
              })}
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ))}
      {isMobileDevice && (
        <>
          <Divider css={{ margin: '0 $half', width: 'calc(100% - $half)' }} />
          <StackLayout
            orientation="horizontal"
            css={{ padding: '$half' }}
            as="ul"
            spacing="$half"
          >
            <Box as="li">
              <AppleAppIcon
                imageSize={APP_STORE_ICON_WIDTH}
                href={APP_STORE.APPLE}
              />
            </Box>
            <Box as="li">
              <GoogleAppIcon
                imageSize={APP_STORE_ICON_WIDTH}
                href={APP_STORE.GOOGLE}
              />
            </Box>
          </StackLayout>
        </>
      )}
    </StackLayout>
  );
};
