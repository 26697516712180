import {
  StackLayout,
  Checkbox,
  FormField,
  TextInput,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { Field, FormikHelpers } from 'formik';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { TermAndConditionsText } from 'components/term-and-conditions-text/term-and-conditions-text.component';
import { TERMS_OF_USE_ROUTE } from 'common/constants';
import { CaptionWithLink } from 'components/caption-with-link.component/caption-with-link.component';
import { AccountSetupFormValues } from './account-setup.types';

const analyticsPropsFnMap: {
  [key: string]: (value: boolean) => object;
} = {
  isTermsAccepted: (value) => ({
    detail: 'consent status checkbox',
    consent_status: `member ${value ? 'selects' : 'does not select'} checkbox`,
  }),
  isEmailEnabled: (value) => ({
    detail: 'email opt-in checkbox',
    email_status: `member ${value ? 'selects' : 'does not select'} checkbox`,
  }),
};

const preferredFirstName = 'preferredFirstName';
const isTermsAccepted = 'isTermsAccepted';
const isEmailEnabled = 'isEmailEnabled';

export const FormContent = ({
  setFieldValue,
}: {
  setFieldValue: FormikHelpers<AccountSetupFormValues>['setFieldValue'];
}) => {
  const { formatMessage } = useIntl();

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { name, checked },
  }) => {
    setFieldValue(name, checked);
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.ACCOUNT_SET_UP,
      screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
      ...analyticsPropsFnMap[name](checked),
    });
  };

  return (
    <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
      <FormField
        id={preferredFirstName}
        name={preferredFirstName}
        label={formatMessage({ id: 'PREFERRED_NAME' })}
      >
        <Field as={TextInput} />
      </FormField>
      <div>
        <Field
          as={Checkbox}
          label={<TermAndConditionsText />}
          id={isTermsAccepted}
          name={isTermsAccepted}
          onChange={handleCheckboxChange}
        />
      </div>
      <div>
        <Field
          as={Checkbox}
          label={formatMessage({
            id: 'RECEIVE_OCCASIONAL_EMAIL',
          })}
          id={isEmailEnabled}
          name={isEmailEnabled}
          onChange={handleCheckboxChange}
          hint={
            <CaptionWithLink
              messageId="ONBOARDING_ACCOUNT_SETUP_DISCLAIMER"
              linkHref={TERMS_OF_USE_ROUTE}
              linkTextId="VISIT_OUR_TERMS"
            />
          }
        />
      </div>
    </StackLayout>
  );
};
