import { Modal, StackLayout } from '@leagueplatform/genesis-core';
import { UserDataModel } from 'models/user-data.model';
import { ProfileImageErrorModal } from 'components/modals/profile-image-error-modal.component';
import { InvisibleFileInput } from './invisible-file-input/invisible-file-input.component';
import { ProfileImage } from './profile-image/profile-image.component';

interface Props {
  title: string;
  user: {
    data: UserDataModel;
    image?: {
      src: string;
      width: string;
    };
  };
  error?: boolean;
  setIsFileTooLarge: (val: boolean) => void;
  setUploadError: (val: boolean) => void;
  uploadProfilePhoto: (file: File) => void;
  onCTAClick?: () => void;
}

export const ProfileImageSection = ({
  title,
  user,
  error = false,
  setIsFileTooLarge,
  setUploadError,
  uploadProfilePhoto,
  onCTAClick = () => {},
}: Props) => (
  <>
    <Modal.Root open={error} onOpenChange={() => setUploadError(false)}>
      <ProfileImageErrorModal />
    </Modal.Root>
    <StackLayout horizontalAlignment="center" spacing="$one">
      <ProfileImage user={user} />
      <InvisibleFileInput
        setIsFileTooLarge={setIsFileTooLarge}
        uploadProfilePhoto={uploadProfilePhoto}
        title={title}
        onClickCallback={onCTAClick}
      />
    </StackLayout>
  </>
);
