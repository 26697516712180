import { NavList, Box, Button } from '@leagueplatform/genesis-core';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';
import type { Link } from 'common/types';

export const defaultMobileNavLinkStyles = {
  borderStyle: 'none',
  borderRadius: '$medium',
  justifyContent: 'flex-start',
  width: '100%',
  padding: '$half',
  '&:focus': {
    outlineOffset: '0',
  },
};

type MobileNavLinkProps = {
  children: React.ReactNode;
  linkData: Link;
  onLinkClick: () => void;
};

export const MobileNavLink = ({
  children,
  linkData,
  onLinkClick,
}: MobileNavLinkProps) => {
  const { text, color } = linkData;

  if ('to' in linkData) {
    const { to } = linkData;

    if ('target' in linkData && isAbsoluteUrl(linkData?.to)) {
      // Nav item is an external link
      const { target } = linkData;

      return (
        <NavList.Link
          href={to}
          target={target}
          rel="noopener noreferrer"
          css={{
            '.GDS-nav-link': {
              ...defaultMobileNavLinkStyles,
            },
          }}
          onClick={onLinkClick}
        >
          {children}
          {target === '_blank' && <OpensInANewTabMessage />}
        </NavList.Link>
      );
    }
    // Nav item is an internal link
    return (
      <NavList.RouterLink
        path={to}
        id={`mobile-nav-link-${text}`}
        css={{
          '.GDS-nav-router-link': {
            ...defaultMobileNavLinkStyles,
            color: color || undefined,
          },
        }}
        onClick={onLinkClick}
      >
        {children}
      </NavList.RouterLink>
    );
  }

  // Nav item is a button link
  if ('onClick' in linkData)
    return (
      <Box as="li" css={{ width: '100%' }}>
        <Button
          quiet
          size="medium"
          priority="tertiary"
          css={{
            ...defaultMobileNavLinkStyles,
            borderRadius: '$medium',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '$interactiveActionHovered',
            },
            '&&:focus': {
              outlineOffset: '$none',
            },
          }}
          onClick={onLinkClick}
        >
          {children}
        </Button>
      </Box>
    );

  return null;
};
