var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/en.json
var en_default = {
  COMPLETED_ACTIVITIES: "Completed Activities",
  AVAILABLE: "Available",
  SEE_MORE_ACTIVITIES: "See More",
  SEE_MORE_COMPLETED_ACTIVITIES_ARIA_LABEL: "See More Completed Activities",
  COMPLETED_ACTIVITIES_EMPTY_STATE: "When you complete activities, they will appear here!",
  MISSED_ACTIVITIES: "Missed Activities",
  SEE_MORE_MISSED_ACTIVITIES_ARIA_LABEL: "See More Missed Activities",
  MISSED_ACTIVITIES_EMPTY_STATE: "When you miss activities, they will appear here!",
  DISPLAYING_ACTIVITIES_FROM_DATE: "Displaying activities since {date}",
  STANDALONE_ACTIVITIES: "Standalone Activities",
  STANDALONE_ACTIVITIES_EMPTY_STATE: "Nothing to do here yet! Come back later.",
  VIEW_ALL_STANDALONE_ACTIVITIES: "View All Standalone Activities",
  MODULE_TEMPLATE_BUTTON: "Module Template Button",
  GROUP_NAME: "Group Name",
  ACHIEVEMENTS: "Achievements",
  VIEW_ACHIEVEMENTS: "View achievements",
  USER_TERMS_UPDATE_FAILED: "Error occurred while accepting Terms & Conditions",
  DEPENDENT_NOTIFICATION_MESSAGE: "New dependents could be invited",
  DEPENDENT_ERROR_MESSAGE: "The server encountered an error, please try adding your dependent again.",
  DEPENDENT_ADDED_SUCCESS_HEADING: "New dependent added",
  DEPENDENT_ADDED_SUCCESS_MESSAGE: "Your dependent is now covered by your benefits.",
  DEPENDENT_EDITED_SUCCESS_HEADING: "Dependent details saved",
  DEPENDENT_EDITED_SUCCESS_MESSAGE: "You've successfully edited the information of your dependent",
  EDIT_DEPENDENT_CONTACT_CUST_CARE: "If you need to make any changes or remove this dependent, please {chatLink}.",
  EDIT_DEPENDENT_CONTACT_CUST_CARE_LINK: "chat with an agent",
  CHANGE_DEPENDENT_RELATIONSHIP: "To change your relationship with your dependent, please {emailLink}.",
  CHANGE_DEPENDENT_RELATIONSHIP_LINK: "contact customer care",
  DEPENDENT_COVERED_BY_HSA_ONLY: "Note: This dependent will be only covered under your Health Spending Account.",
  EDIT_DEPENDENT_DETAILS: "Edit Dependent Details",
  EDIT_DETAILS: "Edit Details",
  INVITATION_STATUS: "Invitation status: ",
  OPTIONS_MENU_FOR_DEPENDENT: "Options menu for {dependent} {firstName}",
  WALLET_LANDING_NAV_LABEL: "Wallet Navigation",
  OPEN_DOCUMENT_INSTRUCTION_HIDDEN: "Open {documentType} document in new tab",
  CLAIM_DETAIL_EXPAND_DOCUMENT_HIDDEN: "Expand {documentType} Document",
  CLAIM_DETAIL_ASIDE_HEADING: "Claim options",
  DOCUMENT_THUMBNAIL_PDF_HIDDEN: "PDF",
  DOCUMENT_MULTIPLE_HIDDEN: "Multiple attached documents",
  DOCUMENT_THUMBNAIL_HIDDEN: "Attached document",
  CLAIMS_TABLE_READ_MORE_HIDDEN: "Link to claim details",
  TRIGGER_INTERAC_PAYMENT_HEADING: "Resubmit Interac Payment",
  TRIGGER_INTERAC_PAYMENT_BODY: `Enter the transaction's "Unique ID" to reissue payment.`,
  TRIGGER_INTERAC_PAYMENT_PLACEHOLDER: "Enter a Interac payment ID",
  TRIGGER_INTERAC_PAYMENT_MODAL: "Are you sure you want to resubmit the following payment?",
  TRIGGER_PAYMENT_HEADING: "Trigger payment to be resent",
  TRIGGER_PAYMENT_BODY: 'Provide an individual payment ID to change the payment\u2019s status from "cancelled" or "transfer pending" to "settled". This will allow the payment to be picked up with the next scheduled payout job.',
  TRIGGER_PAYMENT_PLACEHOLDER: "Enter a payment ID",
  TRIGGER_PAYMENT_MODAL: "Are you sure you want to retrigger the following payment?",
  GET_STARTED_ERROR: "The invitation code you used is invalid. Please check the link in your invitation email and try again.",
  WELCOME_EXCLAMATION: "Welcome!",
  WELCOME_EXCLAMATION_NAME: "Welcome, {firstName}!",
  LETS_CREATE_ACCOUNT: "Let\u2019s create your League account.",
  YOULL_GET_TOOLS: "You\u2019ll get tools, resources and support to help you live happier and healthier every day. It only takes a few minutes and we'll guide you every step of the way.",
  CREATE_ACCOUNT: "Create Account",
  YOUR_INVITE_HAS_EXPIRED: "Your invite has expired",
  INVITE_EXPIRED_NOTE: "This invite link is no longer valid. Please ask the primary account holder to send you a new invite email.",
  YOUR_INVITE_WAS_REDEEMED: "Your invite was redeemed",
  INVITE_REDEEMED_NOTE: "It looks like this invite link was already redeemed. You can go ahead and sign into your account now.",
  INVITE_ERROR_SOMETHING_WENT_WRONG: "We\u2019re sorry, something went wrong.",
  INVITE_ERROR_NOTE: "Please try the link in your invite email again, or email <a>Customer Care</a> for help.",
  ALREADY_HAVE_ACCOUNT: "Already have an account?",
  IN_IT_FOR_YOU: "Here\u2019s what\u2019s in it for you:",
  COVERAGE_HEADING: "All your benefit cards info in one place",
  DATE_CONSULT_ENDED: "Date Consult Ended",
  COVERAGE_BODY: "Easily check out your coverage and submit claims with just a tap, right in your app.",
  PRODUCTS_HEADING: "Exclusive access to Lifemarket",
  PRODUCTS_BODY: "hop a curated selection of health and wellness items and experiences from our trusted vendors.",
  ANSWERS_HEADING: "Speedy answers to health questions",
  ANSWERS_BODY: "Get one-on-one personalized advice from our team of health experts through live chat.",
  REWARDS_HEADING: "Rewards for healthy habits",
  REWARDS_BODY: "Hit your goals and earn rewards points for building healthy habits. Redeem your points for League Credit in our Lifemarket.",
  VIEW_CARD_FOR_DEPENDENT: "View card for {dependentName}",
  LEARN_MORE_LEAGUE_FAMILY: "about League Family for your dependents",
  LEARN_MORE_QLE: "about life events that might qualify for re-enrollment",
  GO_TO_LIFEMARKET: "Go to Lifemarket to view order number {invoiceId}",
  GO_TO_CLAIM: "View claim details for {title}",
  GO_TO_RECEIPT: "View receipt for {title}",
  LINK_TO_TRANSACTION_DETAILS: "Link to details",
  EXPAND_BENEFIT_LIST: "Expand Benefit List",
  CLOSE_BENEFIT_LIST: "Close Benefit List",
  DEFAULT_TEMPLATE_TOOLTIP: "Default templates are shared across groups that use the same carrier and benefit type (e.g., Cigna Medical, Manulife Critical Illness). If necessary, please reach out to Operations to create/edit a template.",
  CUSTOM_TEMPLATE_TOOLTIP: "Custom templates are specific to a group and override any existing default templates. If necessary, please reach out to Operations to create/edit a template.",
  CONTENTFUL_ERROR_BANNER: "Content for this benefit does not exist in Contentful. Please disable the integration or reach out to Operations to have content created.",
  CONTENTFUL_ERROR_BANNER_MULTICARD: "Content for one or more cards in this benefit does not exist in Contentful. If content does not exist for an enabled card, please disable the integration or reach out to Operations to have the content created.",
  CONTENTFUL_INFO_BANNER: "Be sure to save this benefit after enabling/disabling the integration.",
  CONTENTFUL_DESCRIPTION: "This integration retrieves content for this benefit from Contentful (our content management system) for display in the Wallet.",
  DEFAULT_TEMPLATE_STATUS: "Default Template Status",
  CUSTOM_TEMPLATE_STATUS: "Custom Template Status",
  VIEW_IN_CONTENTFUL: "View in Contentful",
  CREATE_IN_CONTENTFUL: "Create in Contentful",
  ERROR_CREATING_WALLET_TEMPLATE: "Error creating wallet template",
  BENEFIT_WALLET_CONTENT: "Benefit Wallet Content",
  NO_TEMPLATE: "no template",
  LIVE: "live",
  INACTIVE: "inactive",
  VIEW_CLAIM_SELECT_LABEL: "View Claims Status",
  VIEW_CLAIM_LABEL_HIDDEN: "View {vendor} claim {referenceId}",
  VIEW_CLAIM_OF_STATUS: "View {status} claim",
  QLE_LINK_LABEL: "Learn more about qualifying life events",
  PRO_ASSOCIATION: "Professional Association",
  PRO_DESIGNATION: "Professional Designation",
  TRANSACTION_DATE: "Transaction Date",
  RECEIPT_NUMBER: "Receipt Number",
  HST_NUMBER: "HST Number",
  LIFEMARKET_RECEIPT_DESCRIPTION: "If this service is covered by your health care plan, you may use this receipt as proof of purchase for your insurance provider.",
  SERVICE_PROVIDER: "Service Provider",
  LICENSE_NUMBER: "License Number",
  SUMMARY: "Summary",
  ISSUED_TO: "Issued to",
  TAX: "Tax",
  TOTAL_DUE: "Total Due",
  AMOUNT_OWING: "Amount Owing",
  LIFEMARKET_RECEIPT_FOOTER: "Thank you for choosing League. If you have any questions, please contact us through {leagueChatLink}.",
  LEAGUE_CHAT: "League Chat",
  VIEW_NOTIFICATIONS: "View notifications ({unreadCount} unread notifications)",
  HIDE_NOTIFICATIONS: "Hide notifications ({unreadCount} unread notifications)",
  PLEASE_CALL: "Please call {phoneNumber}",
  UPDATE_CLAIM_SUCCESS: "Claim updated",
  PREVIOUSLY_UPLOADED: "Previously uploaded",
  CANCEL_CLAIM_ERROR_HEADING: "Error canceling claim",
  CANCEL_CLAIM_ERROR_BODY: "Please try again. If that doesn\u2019t work, please contact our Customer Care team.",
  CANCEL_CLAIM_SUCCESS_HEADING: "Claim canceled",
  CANCEL_CLAIM_SUCCESS_BODY: "We\u2019ve gone ahead and successfully canceled this claim.",
  CANCEL_CLAIM_HEADING: "Are you sure you want to cancel this claim?",
  CANCEL_CLAIM_BODY: "If you cancel your claim, it can't be undone.",
  CANCEL_CLAIM_BUTTON: "Cancel this Claim",
  SUBMIT_CLAIM_SUCCESS_HEADING: "Your claim has been successfully submitted.",
  SUBMIT_CLAIM_SUCCESS_DESCRIPTION: "Please give us 1-3 business days to process your request. You will receive an email when your claim is approved, or if we need more information to process this claim.",
  CLAIM_ERROR_HEADING: "Uh-oh! It looks like something went wrong.",
  SUBMIT_CLAIM_ERROR_DESCRIPTION: "There was an issue submitting your claim, please try again. If that doesn\u2019t work, please contact our {custCareLink}.",
  UPDATE_CLAIM_ERROR_DESCRIPTION: "There was an issue updating your claim, please try again. If that doesn\u2019t work, please contact our {custCareLink}.",
  CLAIM_ERROR_LINK: "Customer Care team",
  HTML_SOURCE: "HTML Source",
  STYLED_CONTENT: "Styled Content",
  SELECT_A_VARIABLE: "Select a variable\u2026",
  INSERT: "Insert",
  FILES_REQUIRED_ERROR: "Please add at least one image or PDF to submit a claim.",
  FILE_TOO_LARGE_ERROR: "The file you selected is too large. Please select a file under 100 MB.",
  CLAIM_FORM_HAS_ERRORS: "You must fill in all the required fields before submitting a claim.",
  DOCUMENT_FORM_HAS_ERRORS: "You must fill in all the required fields before submitting a document.",
  REMOVE_NAMED_FILE: "Remove file {name}",
  SUBMIT_CLAIM_HEADING: "Submit a Claim",
  SUBMIT_CLAIM_SUBHEADING: "Upload receipts and get reimbursed in just a few days!",
  SUBMIT_CLAIM_INTRO: "Make sure the date you paid for the item or received the service is within the policy period of the benefit you\u2019re submitting a claim for. You can find your policy period on the benefit\u2019s card in your {walletLink} before you submit your claim. If you have any questions or want to learn more, read through {ourGuideToSubmittingClaimsLink}.",
  OUR_GUIDE_TO_SUBMITTING_CLAIMS: "our guide to submitting claims",
  BANKING_INFO_BANNER: "You\u2019ll first need to set up your {bankingInfoLink} in your League account before we can reimburse you!",
  BANKING_INFORMATION: "Banking information",
  CLAIM_BENEFIT_LABEL: "Benefit to claim against",
  CLAIM_BENEFIT_TOOLTIP_1: "This is where you'll find the benefits you can submit claims for in your League account.",
  CLAIM_BENEFIT_TOOLTIP_2: "Need to submit a claim for an insurance benefit? Select the benefit in your Wallet to access your insurance carrier's portal and submit your claim.",
  CLAIM_AMOUNT_LABEL: "Amount you want to claim",
  CLAIM_AMOUNT_TOOLTIP_1: "You can enter the amount of money you want us to reimburse you (even if it's less than the amount on your receipt).",
  CLAIM_AMOUNT_TOOLTIP_2: "For example, if you submit a claim for a purchase that costs $100 but only want us to reimburse you $80, then enter $80 here.",
  NOTES_PLACEHOLDER: "Have any other info you\u2019d like to share with us that doesn\u2019t appear on your documents? Add it here (e.g. provider name, service date, etc.).",
  CLAIM_UPLOAD_INSTRUCTIONS_HEADING: "Before you upload, make sure that...",
  CLAIM_UPLOAD_INSTRUCTIONS_1: "Documents include the following information",
  CLAIM_UPLOAD_INSTRUCTIONS_1_1: "The item or service you purchased",
  CLAIM_UPLOAD_INSTRUCTIONS_1_2: "The date you made the purchase or received the service",
  CLAIM_UPLOAD_INSTRUCTIONS_1_3: "The name of the vendor",
  CLAIM_UPLOAD_INSTRUCTIONS_2: "You include proof of payment for the product or service",
  CLAIM_UPLOAD_INSTRUCTIONS_2_1: "This could be a receipt or credit card statement",
  CLAIM_UPLOAD_INSTRUCTIONS_3: "Your images are clear and easy to read",
  CLAIM_UPLOAD_INSTRUCTIONS_3_1: "Images take up the whole page and have good lighting",
  DRAG_AND_DROP_FILES_HERE: "Drag and drop files here",
  OR: "or",
  BROWSE_FILES: "Browse Files",
  SUBMIT_CLAIM_AGREEMENT_HEADING: "By submitting I understand and agree to the following:",
  SUBMIT_CLAIM_AGREEMENT_1: "The information is, to the best of my knowledge true and correct and I understand that false or fraudulent claims can result in civil/criminal prosecution.",
  SUBMIT_CLAIM_AGREEMENT_2: "I have already paid the provider and the documentation provided is specific to this claim and for either myself or another insured member for this benefit.",
  SUBMIT_CLAIM_AGREEMENT_3: "That I cannot and will not submit this claim elsewhere for reimbursement.",
  SUBMIT_CLAIM_AGREEMENT_4: "I certify that the information provided is accurate and that my claims are subject to final approval. Additional documentation may be required for verification. If found not to meet the relevant criteria, this claim can be revoked anytime.",
  TOTAL_BALANCE: "Total Balance",
  LIFEMARKET: "Lifemarket",
  ABOUT: "About",
  LEAGUE_CREDIT_DESCRIPTION_1: `You can spend League Credit in {lifemarketLink} on a variety of lifestyle and wellness products and services. The only thing you can't spend it on is anything marked as a "League Partner Offer".`,
  LEAGUE_CREDIT_DESCRIPTION_2: 'To use your League Credit, select "League Credit" from the list of payment options when you checkout from Lifemarket. {learnMoreLink}',
  DID_YOU_GET_A_PROMO_CODE: "Did You Get a Promo Code?",
  DID_YOU_GET_A_PROMO_CODE_DESCRIPTION: "If you received a store credit or a promo code, you can enter it here to receive the credits. Once entered they will be automatically applied to your account.",
  SELECT_A_BENEFIT: "Select a benefit",
  DEX_VALIDATION_ERRORS_BANNER: "<strong>We found some errors on this page.</strong> Please correct the fields marked in red and submit again.",
  GET_CARE: "Get Care",
  SELECT_LANGUAGE: "Select language",
  MAN: "Man",
  WOMAN: "Woman",
  AGENDER: "Agender",
  BIGENDER: "Bigender",
  GENDERQUEER: "Genderqueer",
  GENDERQUEER_NONCOMFORMING: "Genderqueer Nonconforming",
  INTERSEX: "Intersex",
  NON_BINARY: "Non Binary",
  TRANSGENDER_MAN: "Transgender Man",
  TRANSGENDER_WOMAN: "Transgender Woman",
  TWO_SPIRIT: "Two Spirit",
  HE_HIM: "He/Him",
  SHE_HER: "She/Her",
  THEY_THEM: "They/Them",
  XE_XEM: "Xe/Xem",
  ZE_ZIM: "Ze/Zim",
  DEX_SUCCESS_MESSAGE_HEADING: "Invite sent!",
  DEX_SUCCESS_MESSAGE: "Your invite is on its way!",
  DEX_EMAIL_EXISTS_ERROR: "This email is already associated with a League account.",
  DEX_GENERIC_ERROR_MESSAGE_HEADING: "Uh oh! Something went wrong.",
  DEX_GENERIC_ERROR_MESSAGE: "Looks like something went wrong on our end. Please reload or try again. If the problem persists <a>contact us</a>.",
  AUTH0_GENERIC_ERROR_MESSAGE: "Looks like something went wrong on our end. Please try again. If the problem persists <a>contact us</a>.",
  ALL_OFFICES: "All offices",
  EMAIL_ERROR_MESSAGE: "Must be in a valid email format",
  EMAIL: "Email",
  "RE-SEND_DEX_INVITE_HEADING": "Are you sure you want to re-send an invitation to {name}? ",
  SEND_DEX_INVITE_HEADING: "What email should we use to invite {name}?",
  "RE-SEND_DEX_INVITE_DESCRIPTION": "An invitation has already been sent to the email address listed. If they haven\u2019t received it or it has expired, please make sure the email address you\u2019ve entered is correct and resend it.",
  SEND_DEX_INVITE_DESCRIPTION: "Please use an email address that isn\u2019t associated with an existing League account. This invitation expires 14 days after it gets sent.",
  "RE-SEND_DEX_INVITE_CHECKBOX": "I consent that the recipient of this invitation has acknowledged and accepted this request, and that the information provided is accurate and true.",
  DEX_INVITE_PRIVACY_DISCLAIMER: "Your personal information is safe with us, your information will not be shared with your dependents. Learn more in our {privacyPolicyLink}.",
  SEND_DEX_INVITE_CHECKBOX: "I confirm that the information provided is accurate and true.",
  INVITATION_SENT_TO: "INVITATION SENT TO",
  SEND_INVITATION: "Send Invitation",
  INVITATION_SENT: "Invitation Sent",
  DISCLAIMER: "Disclaimer",
  JOINED: "Joined",
  INVITATION_EXPIRED: "Invitation Expired",
  "RE-SEND_INVITATION": "Resend Invitation",
  THERE_IS_A_PROBLEM_LOADING_THIS_PAGE: "There's a problem loading this page",
  TRY_RELOADING_THE_PAGE: "Try reloading the page or check back later.",
  CANADA: "Canada",
  UNITED_STATES: "United States",
  IRELAND: "Ireland",
  PLEASE_TRY_RELOADING_THE_PAGE_OR_CHECK_BACK_LATER: "Please try reloading the page or check back later",
  ADMIN_PORTAL: "Admin Portal",
  BUSINESS_PORTAL: "Business Portal",
  PERSONAL_ACCOUNT: "Personal Account",
  SWITCH_VIEW: "Switch View",
  PERSONAL_DETAILS: "Personal Details",
  BY_SUBMITTING_YOU_CERTIFY: "By submitting, you certify that you have provided accurate information",
  BACK_TO_DEPENDENTS: "Back to Dependents",
  ROLLED_OVER: "Rolled Over",
  NEED_MORE_INFO_SINGLE_CLAIM: "Your claim needs more info to be processed. View details below.",
  NEED_MORE_INFO_MULTIPLE_CLAIMS: "Your claims need more info to be processed. View details below.",
  GO_TO_CLAIM_DETAIL: "Go to claim detail",
  RECENT_CLAIMS: "Recent Claims",
  IF_YOU_HAVE_AN_URGENT_CLAIM_PLEASE_CONTACT_CUSTOMER_SUPPORT: "If you have an urgent claim, please contact <a>Customer Support</a>.",
  ABOUT_THIS_BENEFIT: "About this Benefit",
  ABOUT_THIS_BENEFIT_DESCRIPTION: "This benefit is made available to you by your employer. For questions or detailed information, please reach out to your HR administrator.",
  ID_LIKE_TO_DOTDOTDOT: "I'd like to ...",
  HAS_DEPENDENTS_DESCRIPTION: "Your dependents for this benefit are listed below. If you recently experienced a life event, you may be eligible to make changes to your dependents. Click the button below to check if you qualify in just a few steps.",
  HAS_NO_DEPENDENTS_DESCRIPTION: "You don't have any dependents associated with this benefit. If you recently experienced a life event, you may be eligible to add or change your dependents. Click the button below to check if you qualify in just a few steps.",
  ADD_A_DEPENDENT_CAPITAL: "Add a Dependent",
  NO_NOTIFICATIONS: "No notifications",
  NO_NOTIFICATIONS_DESCRIPTIONS: "New notifications like claims status, upcoming appointments, and health goals can be found here.",
  BOOKINGS: "Bookings",
  ARTICLES: "Articles",
  TERMS_OF_SERVICE: "Terms of Service",
  SERVICE: "Service",
  CONSULT_TYPE: "Consult type",
  LOG_OUT: "Log Out",
  WALLET_FEEDBACK_TITLE: "Help us improve the Wallet experience",
  WALLET_FEEDBACK_SUBTITLE_BENEFIT: "How helpful is the information provided for your \u201C{benefitName}\u201D benefit?",
  WALLET_FEEDBACK_SUBTITLE_OTHER: "How helpful is the information provided for your {cardName}?",
  SEE_ALL_NOTIFICATIONS: "See All notifications",
  GO_BACK: "Go back",
  WALLET_LANDING_TITLE: "Your benefits and coverage info, all in one place.",
  WALLET_LANDING_BODY: "Your digital wallet is home to your employer benefits, programs and coverage details. Find what you need in a quick tap.",
  POLICY_ACTIVE: "Policy Active",
  PAST_BENEFIT: "This is a past benefit.",
  PENDING_BENEFIT: "This is a pending benefit.",
  PAST_BENEFIT_DESCRIPTION: "This benefit is no longer available to the member.",
  PENDING_BENEFIT_DESCRIPTION: "This benefit is not yet available to the member.",
  DOES_MY_BENEFIT_COVER: "Does my {benefitName} cover...",
  REMAINING_BALANCE: "Remaining Balance",
  EMPLOYEE_NAME_SLASH_ID: "Employee name / ID",
  TOTAL_EMPLOYEES_AT_THIS_LOCATION: "{totalEmployees} employees at this location",
  OFFICE: "Office",
  ELIGIBILITY: "Eligibility",
  PHYSICAL_WORKER: "Physical worker",
  REMOTE_WORKER: "Remote worker",
  ON_LEAVE: "On leave",
  UNKNOWN: "Unknown",
  OVERDUE: "Overdue",
  INCOMPLETE: "Incomplete",
  NOT_REQUIRED: "Not required",
  INDIVIDUAL_RECORDS_FOR_TODAY: "Individual records for today",
  MEMBER_ID_ERROR_HEADING: "Member ID seems to be invalid or missing",
  CONTACT_CUSTOMER_CARE: "contact Customer Care",
  MEMBER_ID_ERROR_BODY: "We\u2019re having trouble loading this information because your Member ID is either invalid or missing. Please {custCareLink} to update your Member ID.",
  FROM: "from",
  CARRIER: "carrier",
  UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG: "Uh oh, looks like something went wrong",
  WERE_HAVING_TROUBLE: "We\u2019re having trouble loading this information, please try to refresh the page. If the problem persists, please {custCareLink}.",
  REFRESH_PAGE: "Refresh Page",
  COVERAGE_COSTS: "Coverage and Costs",
  IN_NETWORK: "In-Network",
  OUT_OF_NETWORK: "Out-of-Network",
  FAMILY_DEDUCTIBLE: "Family Deductible",
  INDIVIDUAL_DEDUCTIBLE: "Individual Deductible",
  FAMILY_OUT_OF_POCKET_MAXIMUM: "Family Out-of-Pocket Maximum",
  INDIVIDUAL_OUT_OF_POCKET_MAXIMUM: "Individual Out-of-Pocket Maximum",
  DEDUCTIBLE_DESCRIPTION: "<p>A deductible is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing (<a href={copays} target='_blank'>copays</a> or <a href={coinsurance} target='_blank'>coinsurance</a>). The deductible applies to all or most items and services covered by your plan.</p><p> If you have a <a href={familyDeductible} target='_blank'>family deductible</a> and the costs for you and your dependents combined meet this deductible, your plan will start to offer coverage for your family's future costs.</p>",
  DEDUCTIBLE_DESCRIPTION_1: "A deductible is the amount of money you must pay out-of-pocket (with your own money) in a plan year before your insurance coverage starts. Once you meet the deductible, your insurance plan will offer coverage in the form of cost sharing ({copaysLink} or {coinsuranceLink}). The deductible applies to all or most items and services covered by your plan.",
  COPAYS_LINK: "copays",
  COINSURANCE_LINK: "coinsurance",
  DEDUCTIBLE_DESCRIPTION_2: "If you have a {familyDeductibleLink} and the costs for you and your dependents combined meet this deductible, your plan will start to offer coverage for your family's future costs.",
  FAMILY_DEDUCTIBLE_LINK: "family deductible",
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION: "An {oopLink} is the most money you can pay during your plan year. Once you meet the maximum, your plan will cover a percentage of future costs for the rest of the plan year.",
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION_LINK: "out-of-pocket (OOP) maximum",
  OUT_OF_POCKET_MAXIMUM: "Out-of-Pocket Maximum",
  DEDUCTIBLE: "Deductible",
  HEALTH: "Health",
  PROGRAMS: "Programs",
  PROGRAM_CREATED_BY: "Program Created by",
  PROGRAM_RULES: "Program Rules",
  TOP_FOCUS_AREA: "Top focus area",
  DIMENSION_OF_HEALTH: "DIMENSION OF HEALTH",
  READING_MORE_ABOUT_DIMENSIONS: "Interested in reading more about how dimensions impact your overall health?",
  READ_OUR_ARTICLE: "Read Our Article",
  ENABLE_LADDER_RULES_PROMPT: "Enable ladder rules for benefit selections?",
  LADDER_RULES_TOOLTIP: "If ladder rules are enabled, members can only elect their current plan or a plan one rung up/down from their current plan during a QLE. \n\n Note: In order for this setting to work, ladder rules must be configured on the benefit.",
  BENEFIT_EFFECT_DATE: "Benefit effective date",
  BENEFIT_EFFECT_DATE_TOOLTIP: "The day that the member\u2019s new benefits become active. The default is on the QLE  date.",
  QLE_DATE: "QLE date",
  FIRST_OF_FOLLOWING_MONTH: "The first of the month following the QLE date",
  SDM_ID_HELPER_TEXT: "Enter your Member ID without the leading zeros; for 0000123456, enter 123456.",
  PLATFORMS: "Platforms",
  HEALTH_PROGRAMS_FOR_YOU: "Health Programs for you",
  COMPLETE_ACTIVITIES: "Complete activities to reach your health goals. Recommended based on your Health Profile.",
  ACTIVE_CARDS_STATUS: "Submit a claim, view coverage details, and more by selecting a card from below.",
  PENDING_CARDS_STATUS: "The following cards/benefits are pending activation.",
  PAST_CARDS_STATUS: "View grace period details for submitting claims by selecting a card from below.",
  PROGRAMS_FOR_YOU: "Programs for you",
  BASED_ON_YOUR_HEALTH_PROFILE: "Based on your Health Profile",
  YOU_HAVENT_BUILT: "You haven\u2019t built your Health Profile yet",
  ITLL_ONLY_TAKE: "It\u2019ll only take about 5\u201310 minutes to complete.",
  FT: "ft",
  CM: "cm",
  IN: "in",
  LB: "lb",
  KG: "kg",
  LETS_BEGIN: "Let\u2019s Begin!",
  FINISH_UP_TO_GET: "Finish up to get personalized insights and content to support your health journey\u2014 plus {points} reward points.",
  KEEP_GOING: "Keep Going",
  SKIP: "Skip",
  SKIP_FOR_NOW: "Skip for now",
  HP_ONBOARDING_SUBMIT: "Let's begin",
  ANY_LEFTOVER_FLEX_DOLLARS_WILL_BE_ADDED_INTO_THIS_ACCOUNT: "Any {flexDollarTerm} that aren't used will automatically be added to this account",
  THIS_ACCOUNT_IS_FUNDED_BY_LEFTOVER_FLEX_DOLLARS: "This account is funded by leftover {flexDollarTerm}.",
  WHAT_ARE_LEFTOVER_FLEX_DOLLARS_TOOLTIP: "<p>You will only be enrolled in this account if you have leftover {flexDollarTerm} after submitting your selections.</p><p>For Example:</p><p>If Alex has $230.00 {flexDollarTerm} after they made all of their benefit selections, those dollars will automatically be added to an HSA after they\u2019re done with enrollment.<p>",
  YOU_CURRENTLY_HAVE_NO_FLEX_DOLLARS_REMAINING: "You currently have no {flexDollarTerm} remaining.",
  YOU_CURRENTLY_HAVE_AMOUNT_FLEX_DOLLARS_REMAINING: "You currently have <strong>{amount}</strong> {flexDollarTerm} remaining, here\u2019s what your contribution would look like as of right now:",
  WHAT_IS_PRORATION_TOOLTIP: "<p>Proration is when we divide your funds proportionally to when you started at your company and how long you have left in the policy.</p><p>For Example:</p><p>If Alex started halfway through a policy period, their {flexDollarTerm} contribution would be cut in half.</p>",
  LEFTOVER_FLEX_HELPER_TEXT: "<strong>{percent}%</strong> of any {flexDollarTerm} that aren't used will automatically be added to this account",
  SORRY_REFRESH_THE_PAGE: "Sorry! Try to refresh the page.",
  SORRY_QLE_REQUEST_EXPIRED: "Sorry! The window for this request has expired.",
  ARE_YOU_READY_TO_SUBMIT: "Are you ready to submit?",
  ONCE_YOU_SUBMIT: "Once you submit, answers can\u2019t be changed. They form your baseline health profile and help give us a clearer picture of your health by benchmarking your progress over time.",
  FOR_PROVIDERS_AND_PHARMACISTS: "For Providers/Pharmacists",
  WEVE_PICKED_UP: "We've picked up where you left off.",
  YOURE_QUESTIONS_AWAY: "You're {number} questions away from earning {points} reward points.",
  YOURE_QUESTIONS_AWAY_WITHOUT_POINTS: "You're {number} questions away from completing the assessment.",
  YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NUMBER: "You're only a few questions away from earning {points} points.",
  YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NOR_POINTS: "You're only a few questions away from completing the assessment.",
  PRESS_ENTER: "Press Enter",
  SELECT_ALL_THAT_APPLY: "Select all that apply",
  SELECT_UP_TO: "Select up to {number}",
  AMOUNT_QUESTIONS: "{amount} questions",
  THIS_WILL_TAKE: "This will take about {minutes} minutes.",
  QUESTION_PROGRESS: "QUESTION {questionNumber} OF {totalQuestions}",
  OPTIONAL_LINK: "\\{Optional link to learn more\\}",
  SELECT_AN_ANSWER: "Select an answer",
  EXIT_HEALTH_ASSESSMENT: "Exit Health Assessment",
  RECOMMENDED: "Recommended For You",
  INDIVIDUALLY_CONFIGURE_QLES: "This section allows you to individually configure Qualifying Life Event types for this benefit class.",
  SETTINGS_APPLIED: "Settings Applied!",
  HR_EMAILS_UPDATED: "HR Emails updated!",
  PLEASE_ENTER_HR_APPROVAL_EMAILS: "Please enter the email address(es) of the recipient(s) that should be notified for approval.",
  SEPARATE_EMAILS_BY_COMMA: "Separate multiple email addresses by a comma",
  AUTOMATIC_EMAIL_SENT_TO_HE_EMAILS_PROVIDED: "An automatic email is sent to the list provided. The list is at a group level and any changes made gets reflected at the group level for all other QLE types configured to require approval. If you want to include multiple recipients, separate their email addresses with a comma.",
  HR_EMAILS_FOR_QLE_APPROVALS: "HR admin emails for QLE approvals",
  ONLY_CERTAIN_LIFE_EVENTS_QUALIFY_A_MEMBER: "Only certain life events qualify a member to make changes to dependents or make different benefit choices. You can configure the settings for these qualifying life events (QLE\u2019s) per benefit class level.",
  TITLE_BY_PROVIDER: "{title} by {provider}",
  TITLE_BY_PROVIDER_FOR_PRICE: "{title} by {provider} for {price}",
  HELP_MENU: "Help Menu",
  TITLE_DESCRIPTION: "{title} - {description}",
  NOT_PROVIDED: "Not Provided",
  GOAL_POINTS: "Goal Points",
  LEARN_MORE_ABOUT_THE_PROGRAM: "learn more about the {name} program",
  LEARN_MORE_ABOUT_THE_QUESTION: "Learn more about this question",
  GO_TO_HEALTH_BLOG: "Go to health blog",
  GO_TO_MARKETPLACE: "Go to marketplace",
  COMPLETE: "Complete",
  ALL_PROGRAMS: "All Programs",
  NONE: "None",
  TYPEFORM: "Typeform",
  NEX: "NEX",
  ON_CAPS: "ON",
  OFF: "Off",
  OFF_CAPS: "OFF",
  NUM_DAYS: "# Days",
  ELECTIONS: "Elections",
  HR: "HR",
  QLE_TYPE: "QLE Type",
  QLE_MARRIAGE: "I got married",
  QLE_BIRTH: "I had a baby",
  QLE_BECAME_COMMON_LAW: "I entered a Common Law relationship",
  QLE_DIVORCE: "I got divorced",
  QLE_SEPARATION: "I got separated",
  QLE_ADOPTION: "I adopted a child",
  QLE_DEPENDENT_DEATH: "A dependent passed away",
  QLE_DOMESTIC_PARTNERSHIP: "I entered a Domestic Partnership",
  QLE_FOSTER_CARE_FOR_CHILD: "I started fostering a child",
  QLE_CIVIL_UNION: "I entered a Civil Union",
  QLE_DEPENDENT_LOST_COVERAGE: "My dependent is losing coverage",
  QLE_LOST_MY_COVERAGE: "I lost coverage",
  QLE_LOST_COVERAGE_AS_DEPENDENT: "I'm a dependent losing coverage",
  QLE_MOVE_TO_DIFFERENT_ZIP_COUNTRY: "I moved to a different ZIP code or country",
  QLE_STUDENT_MOVE_TO_OR_FROM_SCHOOL: "I'm a student moving to/from school",
  QLE_MOVE_TO_OR_FROM_QUEBEC: "I moved to/from Quebec",
  QLE_MOVE_TO_OR_FROM_BC: "I moved to/from British Columbia",
  QLE_DATE_OF_GAIN_OF_COVERAGE: "My dependent gained coverage",
  QLE_ENDED_COMMON_LAW_RELATIONSHIP: "My Common Law relationship ended",
  VIEW_MORE: "View More",
  VIEW_LESS: "View Less",
  X_MIN_READ: "{readTime} min read",
  GOOD_MORNING: "Good morning!",
  GOOD_AFTERNOON: "Good afternoon!",
  GOOD_EVENING: "Good evening!",
  GOOD_MORNING_WITH_NAME: "Good morning, {name}!",
  GOOD_AFTERNOON_WITH_NAME: "Good afternoon, {name}!",
  GOOD_EVENING_WITH_NAME: "Good evening, {name}!",
  DONT_HAVE_OUR_APP: "Don't have our app?",
  TRY_OUR_APP: "This content looks so much better in the app, try it out!",
  OPEN_IN_APP_BUTTON: "Open in App",
  GET_THE_APP: "Get the app to manage your personalized benefits, access exclusive offers in our Marketplace, or contact our best-in-class customer support!",
  TEXT_ME_DOWNLOAD_LINK: "Text Me a Download Link",
  DOWNLOAD_APP_STORE: "Download on the App Store",
  DOWNLOAD_PLAY_STORE: "Get it on Google Play",
  DONE: "Done",
  ALL_DONE_GO_BACK_HOME: "All done? Go back home",
  WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID: "We\u2019re sorry, the page you\u2019ve requested has either expired or is no longer valid.",
  EVENT_DATE_IS_OUT_OF_ACCEPTABLE_DATE_RANGE: "This event date is out of the acceptable date range.",
  HELPFUL_TIPS: "Helpful Tips",
  TOMORROWS_ACTIVITY: "Tomorrow's Activity",
  EARNED_X_TIMES: "Earned {amount}X",
  EARNED: "Earned",
  YOUVE_EARNED_TOTAL: "You've earned a total of",
  YOUVE_EARNED_GRAND_TOTAL: "You've earned a grand total of",
  YOUVE_EARNED_ACHIEVEMENT_BADGE: "You've earned a Achievement Badge",
  CONGRATS_COMPLETED: "Congrats! You've completed",
  VIEW_ALL_PROGRAMS: "View All Programs",
  VIEW_MY_HEALTH_PROFILE: "View My Health Profile",
  VIEW_MY_HEALTH_DASHBOARD: "View My Health Dashboard",
  LATEST_INSIGHTS_METRICS_AND_MORE: "Latest insights, metrics, and more",
  FEEDBACK_TITLE: "How helpful was this information?",
  FEEDBACK_MIN_SCALE: "Unhelpful",
  FEEDBACK_MAX_SCALE: "Very helpful",
  HAVE_YOU_RECENTLY_EXPERIENCED_CHANGES: "Have you recently experienced changes in your life or family?",
  DID_YOU_RECENTLY: "Did you recently get married or have a baby? Certain life events may qualify you for re-enrollment.",
  LEARN_MORE: "Learn More",
  DOCUMENT_URL: "Document URL",
  TODAYS_ACTIVITY: "Today's Activity",
  VIEW_ACTIVITY: "View Activity",
  VIEW_ACTIVITIES: "View Activities",
  QLE_CONFIRMATION_CHECKBOX: "I certify that the information provided is accurate and that proposed plan changes are subject to final approval. Additional documentation may be required for verification. If found not to meet the relevant criteria, dependents or elections can be revoked anytime.",
  HSA_CONFIRMATION_CHECKBOX: "I understand this dependent will only be covered on my Health Spending Account. I certify that the information I provided is accurate and is subject to final approval. Additional documentation may be required for verification. If found not to meet the relevant criteria, dependents or elections can be revoked at anytime.",
  PLEASE_ENTER_A: "Please enter a {fieldName}.",
  INFORMATION_SUCCESSFULLY_SENT: "Information successfully sent!",
  INFORMATION_HAS_BEEN_SENT_FOR_HR_APPROVAL: "Your information has been successfully sent! We will reach out with next steps shortly.",
  CONTINUE: "Continue",
  IF_APPROVED_RE_ENTER_ENROLLMENT_FOR_YOURSELF_AND_FAMILY: "If approved, League will email you to let you know when you can re-enter enrollment. In enrollment, you\u2019ll be able to add or remove dependents and make changes to your plan elections.",
  DEADLINE_FOR_BENEFIT_SELECTION_AND_DEPENDENTS: "You\u2019ll have until <strong>{deadlineDate}</strong> to complete your new benefit elections, and add or remove dependents.",
  SENT_INFORMATION_TO_HR_ADMIN_FOR_FINAL_APPROVAL: "Once you submit, your information will be sent to your HR administrator for final approval.",
  ONCE_YOU_SUBMIT_YOU_WILL_BE_ABLE_TO_ADD_HSA: "Once you submit, you\u2019ll be able to add your dependent to your Health Spending Account. This change will take effect immediately and no further action is required on your end.",
  RE_ENTER_ENROLLMENT: "Once you submit, you will re-enter enrollment where you can make changes to your plan elections for yourself and your family.",
  YOU_RE_ALMOST_DONE: "You\u2019re almost done",
  BASED_ON_YOUR_INFO: "Based on the information you\u2019ve provided, you may qualify to make new benefit elections.",
  ARIA_CIRCLED_CHECKMARK: "White checkmark enclosed within a circle",
  START: "Start",
  QLE_YOU_QUALIFY: "You qualify! Do you want to start making your new plan selections?",
  QLE_ENROLLMENT_DISCLAIMER: "Once you start, you\u2019ll re-enter enrollment with a fresh slate where you\u2019ll have 2 weeks to make all your new plan selections, add or remove your dependents.",
  NOT_NOW: "Not Now",
  FEELING_STUCK: "Feeling stuck?",
  POINTS: "Points",
  X_POINTS: "{points, plural, one {# token} other {# tokens}}",
  VAL_POINTS: "{points} points",
  POINTS_OVER_TOTAL: "<span>{current}</span> / {total} Points",
  POINTS_OVER_TOTAL_ALT: "You have {current} out of {total} points",
  PROGRAM_INFO: "Program Info",
  FIELDS_MARKED_WITH_AN_ASTERISK: "All fields marked with an asterisk are required",
  MAKE_SURE_THE_DOCUMENT: "Make sure that the document...",
  YOUR_FILE_TYPES: "Your file format is JPG, PNG, or PDF",
  OTHER_FILE_TYPES: "Other file types are not accepted",
  DOCUMENT_FILE_TYPES: "It will work with JPG, PNG, and PDF formats",
  YES_REMOVE: "Yes, Remove",
  REMOVE_DOCUMENT_QUESTION: "Remove this document?",
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_DOCUMENT: "Are you sure you want to remove this document from your profile? If it 's required by League, it's recommended you edit or replace it instead.",
  REMOVE_THIS_DOCUMENT: "Remove this Document",
  YOUR_BENEFIT_PLAN_REQUIRES_THE_FOLLOWING_FORMS: "The following forms are necessary to ensure you and your loved ones are protected. Additional information and instructions are provided below to help you complete them.",
  FORMS: "Forms",
  FORMS_AVAILABLE: "Forms Available",
  UPLOADS: "Uploads",
  ABLE_TO_DOWNLOAD_FORMS_HERE: "In the future, if your plan design or selections require you to complete forms (ex. beneficiary forms) you will be able to download them from here.",
  NO_FORMS_REQUIRED_FOR_YOUR_BENEFIT_PLAN: "There are no forms required with your current plan",
  NO_FORMS_AVAILABLE_FOR_EMPLOYEE_PLAN: "There are no forms available for the employee's plan",
  AVAILABLE_FORMS_FOR_EMPLOYEE_PLAN: "These are the available forms for the employee plan",
  ALL_UPLOADED_DOCUMENTS_ARE_SECURE: "All documents uploaded to League are secure and confidential. Easily access all your uploaded documents below.",
  EVIDENCE_OF_INSURABILITY: "Evidence of Insurability",
  ACTIONS: "Actions",
  DOCUMENTS_AND_FORMS: "Documents & Forms",
  UPLOAD: "Upload",
  YOU_DONT_HAVE_CLAIMS: `You don't have any "{status}" claims.`,
  VIEW_ALL_CLAIMS: "View All Claims",
  THIS_BOX_MUST_BE_CHECKED_TO_CONTINUE: "This box must be checked to continue",
  SNAIL_MAIL_IT_IS: "Got it, snail mail it is!",
  PLEASE_NOTE: "Please Note",
  EOI_FORM_ON_DOWNLOAD: "All Evidence of Insurability forms must be signed with ink and then <strong>mailed directly to the carrier</strong>. If they don\u2019t receive it, your application for optional coverage will not be complete.",
  EOI_FORM_ON_UPLOAD: "All Evidence of Insurability forms must be physically signed and <strong>mailed directly to the carrier</strong>. League does not approve EOIs, this upload is for your reference only.",
  SUBMIT_TO_STRIPE: "Submit to Stripe",
  STRIPE_SUCCESS: "Member ID submitted",
  STRIPE_ERROR: "Issue submitting member ID",
  NEW_PASSCODE: "New Passcode",
  INTERAC_USAGE_DESCRIPTION: "Enter the email address where you would like to receive notification of your electronic fund transfer. Once you submit your email we will generate a passcode for you to access your funds. You can use this passcode every time you receive a payment via INTERAC e-transfer.",
  ENTER_YOUR_EMAIL_ADDRESS: "Enter your email address",
  ENTER_VALID_IBAN: "Your IBAN number needs to be between 15-34 characters.",
  ENTER_VALID_ROUTING_NUMBER: "Enter a valid 9 digit routing number.",
  ENTER_VALID_US_ACCOUNT_NUMBER: "Enter a valid 4-17 digit account number.",
  ENTER_VALID_BRANCH_TRANSIT_NUMBER: "Enter a valid 5 digit branch transit number.",
  ENTER_VALID_EMAIL: "Enter a valid email.",
  ENTER_VALID_INSTITUTION_NUMBER: "Enter a valid 3 digit institution number.",
  ENTER_VALID_CA_ACCOUNT_NUMBER: "Enter a valid 5-12 digit account number.",
  ACCOUNT_NUMBER_REQUIRED: "Account number is required.",
  ROUTING_NUMBER_REQUIRED: "Routing number is required.",
  BRANCH_NUMBER_REQUIRED: "Branch number is required.",
  EMAIL_REQUIRED: "Email is required.",
  FIELD_REQUIRED: "{field} is required.",
  INSTITUTION_NUMBER_REQUIRED: "Institution number is required.",
  YOU_CAN_FIND_DIRECT_DEPOSIT_INFORMATION_ON: "You can find this information on a personal check, your online banking or bank account statements.",
  ROUTING_NUMBER: "Routing Number",
  ENTER_YOUR_ROUTING_NUMBER: "Enter your 9 digit routing number",
  YOUR_NAME: "Your Name",
  FULL_NAME: "Full Name",
  ENTER_YOUR_NAME_AS_APPEARS_ON_ACCOUNT: "Enter your name as it appears on account",
  BRANCH_TRANSIT_NUMBER: "Branch Transit Number",
  ENTER_YOUR_BRANCH_TRANSIT_NUMBER: "Enter your 5 digit branch transit number",
  INSTITUTION_NUMBER: "Institution Number",
  ENTER_YOUR_INSTITUTION_NUMBER: "Enter your 3 digit institution number",
  ENTER_YOUR_ACCOUNT_NUMBER_CA: "Enter your account number (up to 12 digits)",
  ENTER_YOUR_ACCOUNT_NUMBER_US: "Enter your account number",
  AN_ISSUE_ADDING_YOUR_ACCOUNT: "There was an issue adding your account, please try again. If this problem persists reach out to our customer care team.",
  AN_ISSUE_EDITING_YOUR_ACCOUNT: "There was an issue editing your account, please try again. If this problem persists reach out to our customer care team.",
  WE_SAVED_YOUR_CHANGES: "We saved your changes.",
  YOUR_DEPOSIT_ACCOUNT_WAS_DELETED: "Your deposit account was deleted.",
  ENTER_YOUR_IBAN_NUMBER_TO_HAVE_PAYMENTS_DIRECTLY_DEPOSITED: "Enter your IBAN number to have payments directly deposited to your account. ",
  HOW_CAN_I_FIND_MY_IBAN_NUMBER: "How can I find my IBAN number?",
  EDIT_INTERAC_DEPOSIT: "Edit Interac e-Transfer",
  EDIT_DIRECT_DEPOSIT: "Edit Direct Deposit",
  DELETE_DIRECT_DEPOSIT: "Delete Direct Deposit",
  IBAN_NUMBER_IS_REQUIRED: "IBAN number is required.",
  BY_DELETING_THIS_PAYMENT_METHOD: "By deleting this payment method, you won\u2019t be able to receive any more payments through this method. To ensure that all claims payment are processed correctly, make sure to have at least one payment method set up.",
  DELETE_ACCOUNT: "Delete Account",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DEPOSIT_ACCOUNT: "Are you sure you want to delete this deposit account? This cannot be undone.",
  BANKING_INFO: "Banking Info",
  SUCCESS_ACCOUNT_ADDED: "Success! Account added.",
  BACK_TO_BANKING_INFO: "Back to Banking Info",
  SELECT_DEPOSIT_METHOD: "Select the deposit method you want to add.",
  DEPOSIT_METHODS_APPLY_ONLY_TO: "These deposit methods only apply to League spending accounts.",
  ACCOUNT_INFORMATION: "Account Information",
  ENTER_YOUR_BANK_INFORMATION: "Enter your bank information to have payments directly deposited to your account. ",
  WHERE_CAN_I_FIND_THIS_INFORMATION: "Where can I find this information?",
  IBAN_NUMBER: "IBAN Number",
  IBAN_NUMBER_WITH_ACCOUNT_NUMBER: "<strong>IBAN Number</strong>: {accountNumber}",
  ENTER_IBAN_NUMBER: "Enter IBAN number",
  USE_AS_DEFAULT_DEPOSIT_ACCOUNT: "Use this as my default deposit account",
  HOW_CAN_I_FIND_IBAN_NUMBER: "How can I find my IBAN number?",
  COUNTRY_CODE: "Country Code",
  CHECK_NUMBER: "Check Number",
  BANK_IDENTIFIER: "Bank Identifier",
  BRANCH_IDENTIFIER: "Branch Identifier",
  ACCOUNT_NUMBER: "Account Number",
  IBAN_BASED_ON_EXAMPLE_BELOW: "You can either work out your IBAN based on the example below, on your online banking or your bank account statements.",
  ADD_ACCOUNT: "Add Account",
  CONFIRM_ACCOUNT_INFORMATION: "Confirm Account Information",
  INTERAC: "Interac",
  DEFAULT_PAYEE_SUCCESS: "Default account changed",
  DEFAULT_PAYEE_ERROR: "Couldn't change default",
  NEW_PASSCODE_GENERATED_SUCCESS: "New passcode generated",
  COPY: "Copy",
  COPY_PROPERTY: "Copy {property}",
  COPIED_PROPERTY: "Copied {property}",
  COPIED_INTERAC_CODE: "Copied Interac Code",
  PASSCODE: "Passcode",
  DEFAULT: "Default",
  MAKE_DEFAULT: "Set As Default",
  DIRECT_DEPOSIT: "Direct Deposit",
  INTERAC_E_TRANSFER: "Interac e-Transfer",
  REIMBURSEMENT_METHODS_APPLY_ONLY_TO: "These reimbursement methods only apply to League spending accounts.",
  NO_DEPOSIT_ACCOUNTS_CONNECTED: "You have no deposit accounts connected",
  QUICKLY_ADD_YOUR_BANKING_INFO: "Quickly add your banking info in order to receive claim payments.",
  ADD_DEPOSIT_ACCOUNT: "Add Deposit Account",
  BULK_TERMINATION_CONFIRMATION_HEADER: "Terminate Employees",
  BULK_TERMINATION_CONFIRMATION_DESCRIPTION: "Are you sure you want to terminate the selected employees?",
  BULK_TERMINATION_CONFIRMATION_BUTTON: "Terminate Employees",
  BULK_SUSPENSION_CONFIRMATION_HEADER: "Suspend Employees",
  BULK_SUSPENSION_CONFIRMATION_DESCRIPTION: "Are you sure you want to suspend the selected employees?",
  BULK_SUSPENSION_CONFIRMATION_BUTTON: "Suspend Employees",
  BULK_REINSTATE_CONFIRMATION_HEADER: "Reinstate Employees",
  BULK_REINSTATE_CONFIRMATION_DESCRIPTION: "Are you sure you want to reinstate the selected employees?",
  BULK_REINSTATE_CONFIRMATION_BUTTON: "Reinstate Employees",
  TERMINATION_CONFIRMATION_HEADER: "Terminate {name}",
  TERMINATION_CONFIRMATION_DESCRIPTION: "Terminating {name} will also update the dates for when their benefits will expire. Choose the termination date for this employee below.",
  SCHEDULE_TERMINATION_CONFIRMATION_LABEL: "Termination Date",
  SCHEDULE_TERMINATION_CODE_LABEL: "Termination Reason",
  TERMINATION_CONFIRMATION_BUTTON: "Terminate Employee",
  SUSPENSION_CONFIRMATION_HEADER: "Suspend {name}",
  SUSPENSION_CONFIRMATION_DESCRIPTION: "Suspending {name} will also update the dates for when their benefits will expire. Choose the suspension date for this employee below.",
  SCHEDULE_SUSPENSION_CONFIRMATION_LABEL: "Suspension Date",
  SUSPENSION_CONFIRMATION_BUTTON: "Suspend Employee",
  SUSPENSION_EMPLOYER_CONFIRMATION_DESCRIPTION: "Are you sure you want to suspend the employer {name}?",
  REINSTATE_CONFIRMATION_HEADER: "Reinstate {name}",
  REINSTATE_CONFIRMATION_DESCRIPTION: "Are you sure you want to reinstate the employee {name}? Choose the reinstatement date for this employee below.",
  SCHEDULE_REINSTATE_CONFIRMATION_LABEL: "Reinstate Date",
  REINSTATE_CONFIRMATION_BUTTON: "Reinstate Employee",
  COPY_INVITE_URL: "Copy Invite URL",
  COPY_INVITE_URL_CONFIRMATION_HEADER: "Invite URL Copied",
  COPY_INVITE_URL_CONFIRMATION_CONTENT: "The invite URL ({url}) has been copied to your clipboard. Paste the URL wherever you need.",
  COPY_INVITE_URL_CONFIRMATION_BTN: "Continue",
  RESEND_INVITE_EMAIL: "Resend Invite Email",
  RESEND_INVITE_EMAIL_SUCCESS: "The invitation email has been resent.",
  RESEND_INVITE_EMAIL_ERROR: "Sorry, there's been an error resending the invitation email.",
  ADD_ADMIN_ROLE_HEADER: "Add Admin Role",
  ADD_ADMIN_ROLE_DESCRIPTION: "Are you sure you want to add Admin permission for employee {name}?",
  ADD_ADMIN_ROLE_TEXT: "By changing this user to Admin, they will have:",
  ADD_ADMIN_ROLE_BUTTON: "Add Admin Role",
  REMOVE_ADMIN_ROLE_HEADER: "Remove Admin Role",
  REMOVE_ADMIN_ROLE_DESCRIPTION: "Are you sure you want to remove Admin permission for employee {name}?",
  REMOVE_ADMIN_ROLE_TEXT: "By removing this user as an Admin, they will no longer have:",
  REMOVE_ADMIN_ROLE_BUTTON: "Remove Admin Role",
  CHANGE_EMPLOYEE_CLASS_HEADER: "Changing {employee}'s Class",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_1: "By changing an employee's class, the following changes will take place:",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_2: "The employee's current benefit plan will remain active until activation of a new benefit plan",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_3: "If the employee has options for coverage type and plan selection, the employee will have to log in to activate their benefits",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_4: "Any spending account balances will be transitioned to matching new spending accounts",
  CHANGE_EMPLOYEE_CLASS_CANCEL: "Cancel",
  CHANGE_EMPLOYEE_CLASS_CONFIRM: "Change Class",
  CHECKING_CLASS_CHANGE_TYPE: "Checking class change type...",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_CONTENT_1: "The benefit class change you triggered is eligible for <strong>auto allocation.</strong> Once confirmed, the following will take place:",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_1: "Member\u2019s current benefits will be mapped over to the new class and maintain the same <em>effective date</em>",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_2: "Any newly offered benefits (that do not require selections) will be auto allocated with an <em>effective date</em> = date of class change",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_3: "Any benefits no longer offered will be deactivated, and enter grace period",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_4: "Any existing spending accounts will be mapped over to the new class & balance will be updated per allocation of the new class",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_5: "Any new spending accounts will be added, with allocation prorated",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_6: "Any spending accounts no longer offered will be removed, and enter grace period",
  CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_CONTENT_1: "The benefit class change you triggered requires manual selections. Once confirmed, the following will take place:",
  CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_BULLET_1: "<strong>A ticket will created in the Ops BenAdmin queue to contact the member to collect selections for required benefits</strong>",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_CONTENT_1: "The benefit class change you triggered is eligible for <strong>BCC Enrollment.</strong> Once confirmed, the following will take place:",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_1: "Enrollment will be opened for member",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_2: "Member will be emailed about their pending enrollment",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_3: "Upon member submission they will be allocated benefits immediately",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_4: "If the member does not submit within the enrollment period, they will receive default selections for newly unlocked benefits",
  CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_CONTENT_1: "The benefit class change you triggered is eligible for <strong>New Hire Enrollment.</strong> Once confirmed, the following will take place:",
  CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_BULLET_1: "Enrollment will be opened for member",
  CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_1: "<strong>Reason:</strong>",
  CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_2: "{change_reason}",
  CHANGE_EMPLOYEE_CLASS_ERROR_MESSAGE: "There was an error while trying to determine the class change type:",
  CHANGE_EMPLOYEE_CLASS_ERROR_UNDEFINED_CHANGE_TYPE_MESSAGE: "Undefined change type",
  LEAGUE: "League",
  CLAIM_ID: "Claim ID",
  USER: "User",
  USER_ID: "User ID",
  STATUS: "Status",
  STARTED: "Started",
  APPROVED: "Approved",
  CANCELLED: "Cancelled",
  MODIFIED_BY: "Modified by",
  MODIFIED_ON: "Modified on",
  UPLOADED_DOCUMENT: "Uploaded {date} \u2022 Uploaded by {user}",
  MORE_INFORMATION_NEEDED: "Needs Info",
  PAID: "Paid",
  PENDING_EXTERNAL_REVIEW: "Sent for External Review",
  PREAPPROVAL_MORE_INFORMATION_NEEDED: "Pre-approval More Info",
  PREAPPROVAL_REJECTED: "Pre-approval Rejected",
  PREAPPROVED: "Pre-approved",
  REJECTED: "Rejected",
  UPDATE_CLAIM: "Update Claim",
  SUBMITTED_FOR_PREAPPROVAL: "Submitted for Pre-approval",
  SUBMITTED_ON: "Submitted on",
  SUBMITTED: "Submitted",
  SETTLED: "Settled",
  PENDING_TRANSFER: "Pending Transfer",
  VOIDED_BY_LEAGUE: "Voided by League",
  CLOSED: "Closed",
  ALL_CLAIMS: "All Claims",
  LOADING_CLAIMS: "Loading Claims\u2026",
  WALLET: "Wallet",
  VIEW_WALLET_IN_NEW_TAB: "View wallet in new tab",
  ALL: "All",
  WALLET_INDEX_NAV: "{current} of {total}",
  SEARCH_CLAIMS: "Search Claims",
  FILTER_BY_MODIFIED_BY: 'Filter by "Modified By"',
  FILTER_BY_STATUS: "Filter by Status",
  RESULTS_FOR_ALL_CLAIMS: 'Results for "All Claims"',
  RESULTS_FOR: "Results for {query}",
  CLEAR: "Clear",
  SEARCH_HINT: "Search by Claim ID, User's name or email",
  NEW_EMPLOYER: "New Employer",
  NEW_EMPLOYEE_SUCCESS_MESSAGE: "Success! Employee has been added to {group}",
  EMPLOYEE_PROFILE_SAVED: "Employee's profile has been saved!",
  EMPLOYEE_PROFILE_ERROR: "Sorry, there's been an error creating the employee",
  EMPLOYER_SEARCH_HINT: "Enter Employer name or ID",
  EMPLOYEE_SEARCH_HINT: "Enter employee name or email",
  IMPORT_PLAN_DESIGN: "Import Plan Design",
  NEW_BENEFIT_SUCCESS_MESSAGE: "Success! Benefit has been added",
  NEW_BENEFIT_ERROR_MESSAGE: "Sorry, there's been an error adding the benefit",
  PAYMENT_STATUS: "Payment Status",
  PAYMENT_ID: "Payment ID",
  PAYMENT: "Payment",
  INTERAC_ID: "Interac ID",
  AMOUNT: "Amount",
  RESEND_PAYMENT: "Resend Payment",
  ADD_NOTE: "Add Note",
  ADD: "Add",
  ONLY_VISIBLE_TO_ADMIN: "Only visible to League Admins",
  VISIBLE_TO_MEMBER: "Visible to the member",
  FILTER_LOG: "Filter Log",
  MY_BAD: "My bad, I messed up...",
  NOTES: "Notes",
  EDIT: "Edit",
  DELETE: "Delete",
  HEALTH_SPENDING: "Health Spending:",
  LIFESTYLE_SPENDING: "Lifestyle Spending:",
  PURCHASE_DATE: "Purchase Date",
  AGENT_RESPONSE: "Agent Response",
  DETAILS: "Details",
  IMAGES: "Images",
  INFORMATION: "Information",
  ENROLLMENT: "Enrollment",
  ENROLLMENT_YEAR: "{planName} Enrollment",
  QLE_ENROLLMENT: "QLE ENROLLMENT",
  EMPLOYER: "Employer",
  CURRENT_STATUS: "Current Status",
  ON: "on ",
  BY: "By ",
  REJECTION_REASON: "Rejection reason: {reason} ",
  AND: "and",
  WAS_APPROVED: "{amount} was approved.",
  SUBMITTED_CLAIM_ON: "submitted a claim on",
  WAS_REQUESTED: "{amount} was requested. ",
  SEE_DETAILS: "See Details",
  OPEN_CHAT: "Open Chat",
  NOT_COVERED_BY_YOUR_POLICY: "Not covered by your policy",
  COVERED_TOOLTIP_TITLE: "Covered",
  PARTIAL_TOOLTIP_TITLE: "Covered With Restrictions",
  CHECK_COVERAGE_TOOLTIP_TITLE: "Check Your Coverage",
  COVERED_TOOLTIP_TEXT: "This product is fully covered by your {name}.",
  PARTIAL_TOOLTIP_TEXT: "This product is covered but with the restriction of:",
  CHECK_COVERAGE_TOOLTIP_TEXT: "This could be covered by {name}. {walletLink} or {chatLink}",
  CHECK_COVERAGE_WALLET_LINK: "Check coverage",
  CHAT_WITH_US: "Chat with us",
  CHAT_WITH_US_LOWERCASE: "chat with us.",
  COVERAGE_NOT_COVERED_BY_YOUR_POLICY: "Not covered by your policy",
  TODAY: "Today",
  LAST_14_DAYS: "Last 14 days",
  LAST_MONTH: "Last month",
  LAST_3_MONTHS: "Last 3 months",
  NOTIFICATIONS: "Notifications",
  LOADING_NOTIFICATIONS: "Loading Notifications",
  YOU_HAVENT_RECEIVED_ANY_NOTIFICATIONS: "You haven't received any notifications recently.",
  NOTHING_MORE_TO_SEE_HERE: "Nothing more to see here!",
  CLAIMS: "Claims",
  EMPLOYERS: "Employers",
  INSIGHTS: "Insights",
  NOTE: "Note",
  PROFILE: "Profile",
  PLAN_DESIGN: "Plan Design",
  ENROLLMENT_DESIGN: "Enrollment Design",
  PRICING_CONFIG: "Pricing Config",
  WALLET_DETAILS: "Wallet Details",
  SALESFORCE_SYNC_TAB_LABEL: "Salesforce Notifications",
  SALESFORCE_SYNC_INTRO: "From the list below, select or deselect vendors to turn on the Product Vendor Sync. Once enabled, SalesForce tickets will be generated for operations associates on a per vendor basis for member\u2019s changes to demographics and/or benefit eligibility.",
  SALESFORCE_SYNC_NOTE: "NOTE: Once enabled, tickets will begin to be generated within 48 hours. Please ensure enrollment is complete & the initial enrollment file has already been sent to the carrier.",
  SALESFORCE_SYNC_COBRA_DESCRIPTION: "COBRA Administration - after you quit or lose a job in the United States, you can temporarily continue your employer-sponsored health insurance coverage through a federal law known as COBRA. League's role is to enroll terminated members into the group\u2019s COBRA vendor by submitting member termination info to the COBRA vendor - and then the COBRA vendor handles it from there.",
  SALESFORCE_SYNC_ENABLE_BUTTON: "Save",
  SALESFORCE_SYNC_ENABLE_SUCCESS_MESSAGE: "The changes were successfully saved.",
  BILLING: "Billing",
  INVOICES: "Invoices",
  SAVE_EMPLOYER: "Save Employer",
  WAITING_PERIOD: "Waiting Period",
  DAYS: "Days",
  GROUP_ID: "Group ID",
  SALESFORCE_ID: "Salesforce ID",
  POSTAL_CODE: "Postal Code",
  POSTAL_CODE_SENTENCE_CASE: "Postal code",
  ZIP_CODE: "Zip Code",
  ZIP_CODE_SENTENCE_CASE: "Zip code",
  CITY: "City",
  CITY_TOWN: "City/Town",
  PROVINCE: "Province",
  PROVINCE_COUNTY: "Province/County",
  STATE: "State",
  UNIT_SUITE: "Unit/Suite",
  OPTIONAL: "Optional",
  ADDRESS: "Address",
  DISABLE_EMAIL_SETTING_CHECKBOX_LABEL: "Email",
  EMAIL_ADDRESS: "Email Address",
  CURRENCY: "Currency",
  COUNTRY: "Country",
  EMPLOYER_NAME: "Employer Name",
  US_DOLLAR: "US - US Dollar",
  CAD_DOLLAR: "CAD - Canadian Dollar",
  CREATE_EMPLOYER_TITLE: "Create a New Employer",
  CREATE_EMPLOYER_INTRO: "To set up an Employer, we will need to know a little about the company. Unless otherwise stated, all the fields below are required.",
  CREATE_EMPLOYER_ERROR_TITLE: "We were unable to create your profile because of an error",
  CREATED_ON: "Created on",
  EDIT_COMPANY_TITLE: "Edit {companyName}'s Profile",
  EDIT_COMPANY_INTRO: "Unless otherwise stated, all the fields below are required.",
  EDIT_COMPANY_ERROR_TITLE: "We were unable to update your profile because of an error.",
  ALL_EMPLOYERS: "All Employers",
  SEARCH_EMPLOYERS: "Search Employers",
  NO_RESULTS: "No Results",
  ACTIVE_USERS: "Active Users",
  INVITES: "Invites",
  ADD_BENEFITS_CLASS: "Add Class",
  ADD_BENEFITS_CLASS_PLACEHOLDER: "Enter Class Name...",
  ADD_BENEFITS_PLAN: "Add Plan",
  ADD_BENEFITS_PLAN_PLACEHOLDER: "Enter Plan Name...",
  ADD_BENEFIT: "Add Benefit",
  SEARCH_BENEFITS_PLACEHOLDER: "Search benefit or carrier",
  PUBLISH_BENEFIT: "Publish Benefit",
  RENEW_BENEFIT: "Renew Benefit",
  REMOVE_BENEFIT: "Remove Benefit",
  PREVIEW_BENEFIT: "Preview Benefit",
  ADJUST_END_DATE: "Adjust End Date",
  REMOVE_USER_BENEFIT: "Delete Benefit",
  REMOVE_BENEFITS_CLASS: "Remove",
  RENAME_BENEFITS_CLASS: "Rename",
  RENAME_BENEFITS_CLASS_PLACEHOLDER: "Enter Class Name...",
  REMOVE_BENEFITS_PLAN: "Remove",
  RENAME_BENEFITS_PLAN: "Rename",
  RENAME_BENEFITS_PLAN_PLACEHOLDER: "Enter Plan Name...",
  REMOVE: "Remove",
  RESET: "Reset",
  SETUP_YOUR_BENEFITS_INTRO: "Pick the Benefits available for the employees\n    in your company. Create a \u201CClass\u201D to segment your employees. Create a\n    \u201CPlan\u201D to give your employees the option to choose between sets of Benefits.",
  FREE: "FREE",
  DISCOUNT_OFF: "{discount} off",
  ENDS_DATE: "Ends {date}",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  POLICY_PERIOD: "Policy Period",
  PAY_PERIOD: "Pay Period",
  MONTHLY_DRAW: "Monthly Draw",
  BI_WEEKLY: "Bi-Weekly",
  SEMI_MONTHLY: "Semi-Monthly",
  WEEK: "Week",
  MORE_ARROW: "More >",
  PHONE_NUMBER: "Phone Number",
  PHONE_NUMBERS: "Phone Numbers",
  DURATION: "Duration",
  GET_INSPIRED: "Get Inspired",
  GET_REWARDED: "Get Rewarded",
  JOIN_OUR_EXCLUSIVE_BETA: "COMING SOON - JANUARY 2018",
  BE_HEALTHIER_BE_REWARDED: "Be Healthier, Be Rewarded",
  HEALTH_GOALS_INTRO: "Announcing our new Beta Program! We want to make it easier for you to improve your health.",
  JOIN_THE_MOVEMENT: "Join the movement!",
  JOIN_THE_MOVEMENT_BULLET: "Exclusive programs by certified health professionals.",
  EARN_POINTS_DAILY: "Earn points daily!",
  EARN_POINTS_DAILY_BULLET: "Track your progress and get rewarded.",
  STAY_MOTIVATED: "Stay motivated!",
  STAY_MOTIVATED_BULLET: "Daily challenges, health content and exclusive promos.",
  FREE_FOR_ALL_LEAGUE_MEMBERS: "FREE for all League members!",
  WANT_TO_BE_THE_FIRST_TO_KNOW: "Want to be the first to know when we launch? Get early & exclusive access with a single tap, then watch your email for more details.",
  YOURE_ALREADY_SIGNED_UP: "You\u2019re already signed up!",
  SIGN_ME_UP: "Sign me up",
  SIGN_UPS_CURRENTLY_CLOSED: "Sign ups are currently closed.",
  REMOVE_BENEFIT_CLASS_CONFIRMATION_HEADER: 'Delete "{benefitClassName}"?',
  REMOVE_BENEFIT_CLASS_CONFIRMATION_COPY: `Are you sure you want to delete the benefit class "{benefitClassName}" and all of it's plans and benefits? This action cannot be reversed.`,
  REMOVE_LAST_BENEFIT_CLASS_ERROR_COPY: 'You cannot delete the benefit class "{benefitClassName}". There should be at least one benefit class set.',
  REMOVE_BENEFIT_CLASS_CONFIRMATION_CANCEL_BTN: "Cancel",
  REMOVE_BENEFIT_CLASS_CONFIRMATION_REMOVE_BTN: "Remove Class",
  REMOVE_BENEFIT_PLAN_CONFIRMATION_HEADER: 'Delete "{benefitPlanName}"?',
  REMOVE_BENEFIT_PLAN_CONFIRMATION_COPY: `Are you sure you want to delete the benefit plan "{benefitPlanName}" and all of it's benefits? This action cannot be reversed.`,
  REMOVE_LAST_BENEFIT_PLAN_ERROR_COPY: 'You cannot delete the benefit plan "{benefitPlanName}". There should be at least one benefit plan set.',
  REMOVE_BENEFIT_PLAN_CONFIRMATION_CANCEL_BTN: "Cancel",
  REMOVE_BENEFIT_PLAN_CONFIRMATION_REMOVE_BTN: "Remove Plan",
  REMOVE_BENEFIT_CONFIRMATION_HEADER: "Delete {benefitName}?",
  REMOVE_BENEFIT_CONFIRMATION_COPY: "Are you sure you want to delete the benefit {benefitName}? This action cannot be reversed.",
  REMOVE_COVERAGE_CATEGORY_CONFIRMATION_TITLE: "Remove Coverage Category: {benefitName}",
  REMOVE_COVERAGE_CATEGORY_CONFIRMATION_DESCRIPTION: "Are you sure you want to remove all coverage categories for {benefitName}?",
  REMOVE_BENEFIT_CONFIRMATION_CANCEL_BTN: "Cancel",
  REMOVE_BENEFIT_CONFIRMATION_REMOVE_BTN: "Remove",
  STATUS_BENEFIT_CONFIRMATION_HEADER: "Transitioning Benefit Status",
  STATUS_BENEFIT_CONFIRMATION_COPY: "Are you sure you want to change the benefit status of {benefitName} from <strong>{benefitStatus}</strong> to <strong>{newStatus}</strong>?",
  READY_BENEFIT_CONFIRMATION_NOTE: "<strong>Note:</strong> Please check that the benefit activation and end dates are correct. If the benefit activation date is before the current date, the benefit will automatically transition to <strong>Active</strong>.",
  OPTED_OUT_BENEFIT_CONFIRMATION_NOTE: "<strong>Note:</strong> This will remove the benefit from the user's wallet.",
  BENEFIT_STATUS_CONFIRMATION_CANCEL_BTN: "Cancel",
  STATUS_BENEFIT_CONFIRMATION_CONFIRM_BTN: "Confirm",
  ADJUST_END_DATE_CONFIRMATION_COPY: "Adjusting the end date means {benefitName} is Active until the new date you select. This benefit currently ends or ended on {benefitEndDate}. What date would you like to set as the new end date?",
  ADJUST_END_DATE_CONFIRMATION_NOTE: "<strong>Note:</strong> You can also adjust this date to be in the past.",
  SELECT_DATE: "<strong>Select Date</strong>",
  ADJUST_END_DATE_ERROR: "Please choose a date that is within the current policy period",
  ADJUST_END_DATE_CONFIRMATION_CONFIRM_BTN: "Adjust",
  ADJUST_END_DATE_SUCCESS_MESSAGE: "The end date for {benefitName} has been adjusted!",
  ADJUST_END_DATE_EMPTY_ERROR: "Please specify the end date for this benefit",
  BILLING_SUBHEADER: "Manage your current payment method and fee structure",
  INVOICE_DATA: "Invoice Data",
  BILLINGS_INSIGHTS_MESSAGE: "This table has complete billing data from Jan 2023 only. For previous billing cycle, please go back to invoices.",
  BACK_TO_INVOICES: "Back to invoices",
  VIEW_OR_DOWNLOAD: "View / Download",
  INVOICES_TOOLTIP: "Click here to view user-level billing data",
  PAYMENT_AUTO_RENEWAL: "Auto-renewal for all benefits, products and services",
  PAYMENT_METHOD: "Payment Method",
  SAVE_PAYMENT_METHOD: "Save Payment Method",
  SAVE_BILLING_PREFERENCE: "Save Billing Preference",
  PAYMENT_METHOD_SAVED: "Payment Method Saved",
  PAYMENT_METHOD_ERRORED: "Payment Method Save Failed",
  BILLING_PREFERENCE_SAVED: "Billing Preference Saved",
  BILLING_PREFERENCE_ERRORED: "Billing Preference Save Failed",
  PRE_PAYMENTS: "Pre-Payments",
  HEALTH_SPENDING_ACCOUNT: "Health Spending Account",
  HSA: "HSA",
  LIFESTYLE_SPENDING_ACCOUNT: "Lifestyle Spending Account",
  LSA: "LSA",
  HEALTH_REIMBURSEMENT_ARRANGEMENT: "Health Reimbursement Arrangement",
  DRUGS: "Drugs",
  TRAVEL: "Travel",
  HEALTH_INSURANCE: "Health Insurance",
  CHARITY: "Charitable Giving Account",
  CHARITABLE_GIVING_ACCOUNT: "Charitable Giving Account",
  SAVE_PRE_PAYMENTS: "Save Pre-Payments",
  PRE_PAYMENTS_SAVED: "Pre-Payments Saved",
  PRE_PAYMENTS_ERRORED: "Pre-Payments Save Failed",
  FEES: "Fees",
  SUBSCRIPTION_FEE: "Subscription Fee",
  ADMIN_FEE: "Admin Fee",
  PLATFORM_FEE: "Platform Fee",
  SAVE_FEES: "Save Fees",
  FEES_SAVED: "Fees Saved",
  FEES_ERRORED: "Fees Save Failed",
  CONTACT: "Contact",
  SAVE_CONTACT: "Save Contact",
  CONTACT_SAVED: "Contact Saved",
  CONTACT_ERRORED: "Contact Save Failed",
  LOWER: "Lower",
  UPPER: "Upper",
  RATE: "Rate (per employee/month)",
  EMPLOYEES: "Employees",
  REPORTS: "Reports",
  NO_REPORTS_AVAILABLE: "There are no reports available.",
  GET_PREVIOUS_REPORTS_ERROR: "There was an error loading previous reports. If you continue to experience issues, please reach out to our customer care team at help@league.com and we'd be happy to help.",
  USAGE: "Usage",
  USAGE_REPORTS: "Usage Reports",
  USAGE_REPORTS_COPY: "Generate a report that includes a row-by-row breakdown for each employees' spending account transactions for a specified period of time (up to a range of 12 months). The report will be downloaded as a CSV and can be used to determine usage of a taxable benefit for payroll entries.",
  REPORTS_LOADING_NOTE_COPY: "Depending on the number of records, this report could take a few hours to generate. We will email you a link to download the report once it has been processed.",
  SPENDING_ACCOUNT_BALANCE: "Spending Account Balance",
  SPENDING_ACCOUNT_BALANCE_REPORT: "Spending Account Balance Report",
  SPENDING_ACCOUNT_BALANCE_REPORT_COPY: "This report provides the spending account balance of each employee as of the date selected. The report includes information on rolled over allocation from previous policy years, top-up allocations, as well as total usage to date. The report is exported as a CSV",
  EMPLOYER_REPORT_REQUESTED_COPY: "Your report has been requested. An email will be sent to {email} with a link to download the file when it's done. This could take a few hours to generate depending on the number of records.",
  EMPLOYER_REPORT_GENERATED_COPY: "Your report has been generated. An email has been sent to {email} with a link to download the file.",
  EMPLOYER_REPORT_WITH_DATES_ERROR_COPY: "Unfortunately there was an issue generating the report you requested. Please select a date and try generating the report again. If you continue to experience issues, please reach out to our customer care team at help@league.com and we'd be happy to help.",
  EMPLOYER_REPORT_WITH_DATES_NO_DATA_ERROR_COPY: "We could not generate the report requested because there is no data for the date(s) selected. Please select a different date and try generating the report again. If you continue to experience issues, please reach out to our customer care team at help@league.com and we'd be happy to help.",
  EMPLOYER_REPORT_ERROR_COPY: "Unfortunately there was an issue generating the report you requested. Please try generating the report again. If you continue to experience issues, please reach out to our customer care team at help@league.com and we'd be happy to help.",
  EMPLOYER_REPORT_NO_DATA_ERROR_COPY: "We could not generate the report requested because there is no data available. If you require further support, please reach out to our customer care team at help@league.com and we'd be happy to help.",
  PREMIUMS_EXPORT_REQUESTED_COPY: "Your report has been requested and an email will be sent to {email} with a link to download. This could take a few hours to generate.",
  PREMIUMS_EXPORT_ERROR_COPY: "There was an issue generating the report. Try generating the report again, and if you continue to experience issues please reach out to help@league.com.",
  DAILY_ASSESSMENT_REPORT_REQUESTED_COPY: "Your report has been requested. This could take a few minutes to generate depending on the number of records, but the download will automatically start when the report is done.",
  CREATE_CHANGE_REPORT: "Create Change Report",
  REQUEST_CHANGE_REPORT: "Request Change Report",
  REQUEST_CHANGE_REPORT_EXPLANATION: "A change report will be created that compares the report you selected to a newly generated full report.",
  REQUEST_CHANGE_REPORT_NOTE: "Note: This report will take some time to generate and will be sent to your email when ready.",
  SELECTED_REPORT: "Selected Report",
  RUN_REPORT: "Run Report",
  REPORT_ID_CLIPBOARD: "Report ID has been copied to clipboard",
  BENEFITS: "Benefits",
  OTHER_FIELDS: "Other Fields",
  SETTINGS: "Settings",
  BULK_UPLOAD: "Bulk Upload",
  ADD_EMPLOYEE: "Add Employee",
  ADD_EMPLOYER: "Add Employer",
  SEARCH_EMPLOYEES: "Search Employees",
  FILTER_EMPLOYEES: "Filter Employees",
  EMPLOYEES_SEARCH_HINT: "Enter or select: role, class, plan or status",
  BENEFIT_START_DATE: "Start Date",
  BENEFIT_END_DATE: "End Date",
  BENEFIT_NAME: "{name} ",
  BENEFIT_VENDOR_NAME: "by {vendorName}",
  ALL_EMPLOYEES: "All Employees",
  SINGLE_COUPLE_FAMILY: "Individual / Individual + Spouse / Family",
  SINGLE_FAMILY: "Individual / Family",
  FUNDS_PER_POLICY_LABEL: "Funds Per Policy Period",
  ALLOCATION_RULE_SET_LABEL: "Employee Relationship Categories",
  ALLOCATION_CLASS_LABEL: "Allocation Class",
  SINGLE_EMPLOYEES_HEADING: "Employees who are single...",
  SINGLE_EMPLOYEES_SUB_HEADING: "with no spouse or dependents",
  ALL_EMPLOYEES_CLASS_DESCRIPTION: "All employees will receive",
  EMPLOYEES_COUPLE_HEADING: "Employees part of a couple...",
  EMPLOYEES_COUPLE_SUB_HEADING: "with a spouse, and with no other dependents",
  EMPLOYEES_FAMILY_HEADING: "Employees part of a family...",
  EMPLOYEES_FAMILY_SUB_HEADING: "with at least 1 dependent other than a spouse",
  EMPLOYEES_ALL_SUB_HEADING: "with or without spouse or dependents",
  ACTIVE: "Active",
  ENDED: "Ended",
  READY: "Ready",
  SUSPENDED: "Suspended",
  DRAFT: "Draft",
  PENDING: "Pending",
  PAST: "Past",
  CURRENT: "Current",
  STATUS_UPDATED: "Status Updated",
  ROLE: "Role",
  CLASS: "Class",
  PLAN: "Plan",
  STEP_PLANS: "{benefitName} Plans",
  PLANS_AVAILABLE: "{number} Plans Available",
  ONE_PLAN_AVAILABLE: "1 Plan Available",
  EMPLOYEE: "Employee",
  TERMINATE_EMPLOYEE: "Terminate Employee",
  SUSPEND_EMPLOYEE: "Suspend Employee",
  REINSTATE_EMPLOYEE: "Reinstate Employee",
  EMPLOYEE_ACTIONS: "Actions",
  TAX_RECEIPTS_LABEL: "Issue tax receipts",
  TERMINATE_EMPLOYEES: "Terminate Employees",
  SUSPEND_EMPLOYEES: "Suspend Employees",
  REINSTATE_EMPLOYEES: "Reinstate Employees",
  BULK_ACTIONS: "Bulk Actions",
  ERROR_CREATING_EMPLOYEE: "We were unable to create the employee profile because of an error.",
  THIRTY_DAYS_LIMIT_SUSPEND_ERROR: "Employees can only be suspended in League up to 30 days in the past.",
  THIRTY_DAYS_LIMIT_REINSTATE_ERROR: "Employees can only be reinstated in League up to 30 days in the past.",
  PLEASE_CONTACT_FOR_SUPPORT: "Please contact help@league.com for additional support.",
  EMPTY_DATE_TERMINATE_ERROR: "Please select a termination date",
  EMPTY_REASON_TERMINATE_ERROR: "Please select a termination reason",
  EMPTY_DATE_SUSPEND_ERROR: "Please select a suspension date",
  EMPTY_DATE_REINSTATE_ERROR: "Please select a reinstatement date",
  LOADING: "Loading",
  SAVING: "Saving",
  SUCCESS_STATE_TITLE: "Success!",
  ERROR_STATE_TITLE: "Something went wrong",
  ERROR_STATE_MESSAGE: "We encountered an unexpected problem. Try refreshing the page.\nIf the problem persists, please contact customer service.",
  HELP_EMAIL: "help@league.com",
  NO_NOTIFICATIONS_TITLE: "No notifications yet",
  NO_NOTIFICATIONS_MESSAGE: "Updates for important things like claim submissions, bookings, and purchases made through League will appear here.",
  COMING_SOON: "Coming soon",
  WE_BE_BACK_WITH_SOMETHING_NEW: "We'll be back with something new!",
  INVITE_AN_EMPLOYEE: "Add Employee",
  ADD_EMPLOYEE_INTRO: "Upon saving this employee, the newly created employee will receive an email inviting them to complete our online on-boarding experience.",
  SEND_INVITE: "Send Invite",
  BACK_TO_EMPLOYEES: "Back to Employees",
  PUBLISH_EMPLOYER: "Publish Employer",
  ACTIVATE_EMPLOYER: "Activate Employer",
  SUSPEND_EMPLOYER: "Suspend Employer",
  UPDATE_FLEX_DOLLARS: "Update Flex Dollars",
  UPDATE_FLEX_DOLLARS_DESCRIPTION: "This tool provides the ability to update the Flex Dollars for specific employees who experience a class change mid plan year. You can update the member's flex dollar amount in the field below.",
  UPDATE_FLEX_DOLLARS_TOTAL_FLEX_LABEL: "Current Total Flex Dollars",
  UPDATE_FLEX_DOLLARS_LOADED_FLEX_LABEL: "Base Flex Dollars",
  UPDATE_FLEX_DOLLARS_SUCCESS_MESSAGE: "Base Flex Dollars successfully updated.",
  BULK_UPLOAD_DOWNLOAD_TEMPLATE_TITLE: "Download Template",
  BULK_UPLOAD_DOWNLOAD_TEMPLATE_DESC: "Select fields to update",
  BULK_UPLOAD_UPLOAD_CENSUS_TITLE: "Upload Census",
  BULK_UPLOAD_UPLOAD_CENSUS_DESC: "Upload and validate your CSV",
  BULK_UPLOAD_SAVE_IMPORT_TITLE: "Save Import",
  BULK_UPLOAD_SAVE_IMPORT_DESC: "Save and invite employees",
  BULK_UPLOAD_SUCCESS_HEADER: "Success!",
  BULK_UPLOAD_SUCCESS_COPY: "We were able to successfully add {successfullyAddedEmployeesCount} employees.",
  BULK_UPLOAD_ERROR_HEADER: "Warning!",
  BULK_UPLOAD_ERROR_COPY: "We were unable to import {erroredEmployeesCount} employees with {errorsCount} errors.  Please fix the errors in your CSV and re-upload your Census.",
  BULK_UPLOAD_DOWNLOAD_BTN_LABEL: "Download Template",
  BULK_UPLOAD_CENSUS_BTN_LABEL: "Upload Census",
  BULK_UPLOAD_NEXT_BTN_LABEL: "Next",
  BULK_UPLOAD_FINISH_BTN_LABEL: "Done",
  BULK_UPLOAD_BACK_BTN_LABEL: "Back",
  BULK_UPLOAD_STEP_1_HEADER: "1. Download Census Template",
  BULK_UPLOAD_STEP_2_HEADER: "2. Upload Your Census CSV",
  BULK_UPLOAD_STEP_1_CONTENT: "Download our Census CSV template.  Open the template in your preferred spreadsheet editor (Microsoft Excel, Numbers, Google Sheets, etc.) and complete all the fields for each employee.",
  BULK_UPLOAD_STEP_2_CONTENT: "Please upload your completed CSV",
  PUBLISH: "Publish",
  SUBMIT: "Submit",
  BENEFIT_DRAFT: "Draft",
  BENEFIT_OPTED_OUT: "Opted Out",
  BENEFIT_CREATED: "Created",
  BENEFIT_READY: "Ready",
  BENEFIT_PUBLISHED: "Published",
  BENEFIT_ACTIVE: "Active",
  BENEFIT_GRACE_PERIOD: "Grace Period",
  BENEFIT_INACTIVE: "Inactive",
  BENEFIT_INACTIVE_GRACE_PERIOD: "Inactive Grace Period",
  IN_GRACE_PERIOD: "In Grace Period",
  WAITING_PERIOD_DAYS: "Waiting Period Length",
  PROGRAM_SELECTION: "Program Selection",
  SELECT_PROGRAM_FOR_MORE_DETAILS: "Select a program for more details",
  DURATION_DAYS: "{duration} days",
  EARN_NUMBER_POINTS: "Earn {points} pts",
  EARN_NUMBER_POINTS_FULL: "Earn {points} points",
  LETS_DO_IT: "Let's do it",
  TERMS_AND_CONDITIONS: "terms and conditions",
  NOTIFICATION_SETTINGS: "Notification Settings",
  DISABLE_PUSH_SETTING_CHECKBOX_LABEL: "Mobile Push Notifications",
  I_AGREE_TO_THESE_TERMS_AND_CONDITIONS: "I agree to these {linkToTOS}",
  CLOSE: "Close",
  POINT_BALANCE: "Point balance",
  VIEW_REWARDS: "View rewards",
  YOU_DONT_HAVE_ANY_POINTS: "You don't have any points right now. Complete daily goals to earn points.",
  THIS_CHALLENGE_RUNS_YEAR_ROUND: "This challenge runs year-round",
  END_DAY_AT_TIME: "Ends {day}",
  ENDED_DAY_AT_TIME: "Ended {day}",
  START_DAY_AT_TIME: "Starts {day}",
  SPONSOR_LOGO: "{name} logo",
  START_AND_END_DATES: "Starts {startDate} \u2022 Ends {endDate}",
  SIGN_UP_BY_DATE: "Sign up by {date}",
  SIGN_UP_BEGINS_DATE: "Sign-up begins {date}",
  SIGN_UP_DATE_PASSED: "Sign-up date passed",
  SIGN_UP_PERIOD_PASSED: "Sign-up period passed",
  JOINED_CHALLENGE: "Joined Challenge",
  PARTICIPATE_TO_WIN: "Participate for a chance to win!",
  CHALLENGE_DETAILS: "Challenge Details",
  CHALLENGE_PROGRESS: "Challenge Progress",
  X_PERCENT_COMPLETE: "{number}% Complete",
  SIGN_UP_FOR_CHALLENGE: "Sign Up",
  GROUP_CHALLENGE: "Group Challenge",
  SOLO_CHALLENGE: "Solo Challenge",
  TRACK_WEARABLES_ALT_TEXT: "Person looking at smart watch data",
  CONNECT_A_DEVICE: "Connect a Device",
  DEVICES_CONNECTED_TITLE: "Looks like you\u2019re already connected!",
  DEVICES_CONNECTED_DESCRIPTION: "We\u2019re already syncing your data. If you choose to track automatically, we can automatically mark activities as complete using your device's {dataPoint}.",
  DEVICES_UNCONNECTED_TITLE: "Let\u2019s connect your device!",
  DEVICES_UNCONNECTED_TRACK_AUTOMATICALLY_SUBTITLE: "Tracking automatically",
  DEVICES_UNCONNECTED_TRACK_MANUALLY_SUBTITLE: "Tracking manually",
  DEVICES_UNCONNECTED_DESCRIPTION: "Connect your favorite health apps and devices to automatically mark activities as complete.",
  TRACK_CHALLENGE_AUTOMATICALLY: "Track automatically",
  TRACK_CHALLENGE_AUTOMATICALLY_DESCRIPTION: "If you choose to track automatically, after you\u2019ve signed up, {instructionsLink} to connect your device using the League mobile app.",
  TRACK_CHALLENGE_AUTOMATICALLY_INSTRUCTION: "follow these steps",
  TRACK_CHALLENGE_MANUALLY: "Track manually instead",
  TRACK_CHALLENGE_MANUALLY_DESCRIPTION: "If you choose to track manually, you can mark activities as complete but you won\u2019t be able to connect a device later on. {learnMoreLink} about tracking your progress manually.",
  TRACK_CHALLENGE_MANUALLY_CHANGE_LATER: "If you choose to track manually, you can still connect a device later. {learnMoreLink} about tracking your progress manually.",
  TRACK_CHALLENGE_MANUALLY_LEARN_MORE: "Learn more",
  TRACK_HEALTH_DATA_ALT: "Dashboard with graph tracking health data",
  TRACK_MANUALLY_CONFIRM_TITLE: "Are you sure you want to track manually?",
  TRACK_MANUALLY_CONFIRM_DESCRIPTION: "You will not be able to connect to a device later on.",
  TRACK_MANUALLY_CONFIRM_CONNECT_LATER_DESCRIPTION: "You will still be able to connect a device at a later time",
  EXIT_CHALLENGE_SIGN_UP_CONFIRM_TITLE: "Are you sure you want to exit?",
  EXIT_CHALLENGE_SIGN_UP_CONFIRM_DESCRIPTION: "If you choose to exit, your changes may not be saved.",
  EXIT: "Exit",
  CHALLENGE_STATUS: "Challenge Status:",
  CHALLENGE: "Challenge",
  CHALLENGES: "Challenges",
  CHALLENGE_PROGRAM_LIBRARY_DESCRIPTION: "Sign up for a challenge to complete daily activities and compete against your peers!",
  THIS_CHALLENGE_HAS_ENDED: "This challenge has ended",
  COMPLETED_ON_DATE: "Completed on {date}",
  ENDED_ON_DATE: "Ended on {date}",
  LEAVE_CHALLENGE: "Leave Challenge",
  LEAVE_CHALLENGE_QUESTION: "Leave Challenge?",
  LEAVE_CHALLENGE_DETAIL: "Are you sure you want to remove this challenge from your journey? Any progress in this challenge will be reset.",
  LEAVE: "Leave",
  SIGN_UP_FOR_THE_CHALLENGE: "Sign Up for the Challenge",
  CHALLENGE_SENSITIVE_DATA: "Your health data and personal information are highly sensitive and it\u2019s our responsibility to keep it private and secure. We take this responsibility very seriously.",
  CHALLENGE_USING_DATA_TO: "When you sign up for a challenge, we are using your data to:",
  COMPLETE_ACTIVITIES_SHORT: "Complete activities",
  SHARE_CHALLENGE_PROGRESS_WITH_EMPLOYER: "Share your challenge progress with your employer",
  SHARE_CHALLENGE_PROGRESS: "Share your challenge progress with your peers (and employer)",
  RANK_SCORE: "Rank your score in a leaderboard",
  DETERMINE_CHALLENGE_WINNERS: "Determine challenge winners",
  ACCEPT_BEFORE_CONTINUE: "You must accept the privacy policy before continuing.",
  PHONE_LOCK_ALT_TEXT: "Phone with a lock overlaid on top.",
  CHECK_TO_CONFIRM_PRIVACY_POLICY: "By checking this box, I agree to the <a>privacy policy.</a>",
  LEARN_MORE_ABOUT_PRIVACY_POLICY: "Learn more about how League manages and uses your data in our <a>privacy policy.</a>",
  PRIZE: "Prize",
  RULES: "Rules",
  LEADERBOARD: "Leaderboard",
  TOOLTIP_LEADERBOARD_USER: "Participants are ranked by their total {rankingUnit} for this challenge",
  TOOLTIP_LEADERBOARD_TEAMS: "Teams are ranked by their average {rankingUnit} for this challenge",
  TOOLTIP_ACTION_DESCRIPTION: "See leaderboard ranking criterion",
  UNLOCKS_DAY_AT_TIME: "{icon} Unlocks {day} at {time}",
  CHECK_BACK_TOMORROW: "Check back tomorrow!",
  MARK_AS_COMPLETE: "Mark as complete",
  MARK_AS_DONE: "Mark as done",
  CLOSE_ACTIVITY: "Close Activity",
  GOAL_INCOMPLETE: "You must complete the goal before continuing.",
  PROGRESS_RECORD_ERROR: "There was an issue recording your last progress.",
  WAY_TO_GO: "Way to go!",
  YOUVE_COMPLETED: "You completed",
  ACTIVITY_COMPLETE: "Activity complete",
  YOU_COMPLETED_THE_ACTIVITY_AND_EARNED_POINTS: "You completed the activity {name} and earned yourself {points} pts! Nice!",
  YOU_HAVE_EARNED_A_REWARD: "You have earned a reward!",
  CONGRATS_ON_EARNING_POINTS: "Congrats on earning enough points for a reward! You deserve it!",
  BACK_TO_PROGRAM: "Back to program",
  CONGRATS: "Congrats!",
  CONGRATULATIONS: "Congratulations!",
  YOU_HAVE_REACHED_THE_END_OF_THE_PROGRAM: "You have reached the end of the {name} earning a total of {points} pts!",
  SELECT_NEW_PROGRAM: "Select a new program",
  PROGRAM_HAS_ENDED: "Program has ended. Ready to start a new one?",
  START_A_NEW_PROGRAM: "Start a new program to continue earning rewards for completing your goals!",
  BENEFIT_SAVED: "Benefit saved",
  BENEFIT_ERROR: "Benefit error",
  SAVE_BENEFIT: "Save Benefit",
  USER_BENEFIT_OPTIONS_HEADER: "Set benefit status to:",
  USER_BULK_BENEFIT_OPTIONS_HEADER: "Set all benefit statuses to:",
  SIGN_OUT: "Sign Out",
  PAGE_NOT_FOUND_HEADER: "Page Not Found",
  PAGE_NOT_FOUND_CONTENT: "Looks like you got a little lost!",
  PAGE_NOT_FOUND_CONTENT_SECONDARY: "Let's help you find yourself.",
  PAGE_NOT_FOUND_BUTTON: "Return Home",
  SUCCESS_ADDING_USER_BENEFIT: "Success! Benefit was added.",
  SUCCESS_UPDATING_USER_BENEFIT: "Success! Benefit was updated.",
  SUCCESS_REMOVING_USER_BENEFIT: "Success! Benefit was removed.",
  WANT_TO_MAKE_A_CHANGE: "Want to make a change to your benefits?",
  WE_CAN_HELP: "We can help, contact our customer success team:",
  USER_BENEFITS_SETTINGS_HEADER: "Settings",
  CHANGE_CLASS_LABEL: "Class",
  EMPLOYEE_CREATE_SUCCESS_MESSAGE: "Success! Employee was created.",
  EMPLOYEE_UPDATE_SUCCESS_MESSAGE: "Success! Employee was updated.",
  EMPLOYEE_UPDATE_ERROR_MESSAGE_HEADER: "Employee was not updated",
  EMPLOYEE_UPDATE_ERROR_MESSAGE: "Please try again. If the problem persists please contact help@league.com",
  EXIT_ADMIN: "Your Account",
  CURRENT_GOAL: "Current goal",
  MORE_DETAILS_ARROW: "More details >",
  LEAVE_PROGRAM: "Leave program",
  ARE_YOU_SURE: "Are you sure?",
  YOU_WILL_LOSE_ALL_PROGRESS_IN_PROGRAM: "You will lose all progress in this program. If you decide to try again later, you will need to start from the beginning.",
  STAY_IN_PROGRAM: "Stay in program",
  REDEEM_REWARDS: "Redeem Rewards",
  REWARDS_ERROR: "Rewards Error",
  YOUR_NEXT_REWARD: "Your next reward",
  MORE_POINTS_TO_REDEEM: "{points} more points to redeem {leagueCredit}",
  MORE_POINTS_TO_REACH: "{points} more points needed to reach your next reward",
  DOLLARS_LEAGUE_CREDIT: "{dollars} League Credit",
  YOUR_REWARD_POINTS: "Your Reward Points",
  REWARD_POINTS: "Reward Points",
  REWARD_POINTS_EARNED: "Reward Points Earned",
  REWARD_POINTS_MISSED: "Reward Points Missed",
  X_REWARD_POINTS: "{points} Reward Points",
  X_LEAGUE_POINTS: "{points} League Points",
  REWARDS_PROGRAM_ELIGIBLE: "Rewards Program Eligible",
  REDEEM_NOW: "Redeem now",
  EXPIRY_DATE: "Expiry date",
  NO_EXPIRY_DATE: "No expiry date",
  WHAT_ARE_MY_POINTS_WORTH: "What are my points worth?",
  POINTS_NEEDED: "Points Needed",
  REWARD: "Reward",
  HOW_EXCITING: "How exciting!",
  YOU_SUCCESSFULLY_REDEEMED: "You successfully redeemed {points} points. That's enough for {dollars} in League Credit!",
  POINTS_BALANCE: "Points Balance",
  REDEEM_NEXT_TIER_POINTS: "You\u2019re {points} away from redeeming {value}.",
  VIEW_YOUR_REWARDS: "View Your Rewards",
  REDEEMABLE: "Redeemable for {value}.",
  CURRENT_POINT_BALANCE: "Current Points Balance: {points}",
  REMAINING_POINTS_AFTER_REDEMPTION: "Points Remaining After Redemption: {point}",
  REDEEM_YOUR_REWARD_NOW: "Redeem your reward now!",
  YOU_QUALIFY_FOR_A_REWARD_OF: "You qualify for a reward of {amount} League Credit!",
  NO_BENEFITS_MESSAGE: "There are no benefits available.",
  NO_PLANS_MESSAGE: "There are no plans for this benefit class.",
  NO_BENEFITS_FOR_PLAN_MESSAGE: "There are no benefits for this plan.",
  NO_BENEFITS_FOR_GROUP_MESSAGE: "There are no benefits for this group.",
  NO_INVOICES_MESSAGE: "There are no invoices available.",
  INVOICES_HELP_KB_MESSAGE: "Have any questions about your invoice? Check out our {link}!",
  KNOWLEDGE_BASE: "knowledge base",
  KNOWLEDGE_BASE_URL: "https://help.league.com/s/topic/0TO0d000000MbAxGAK/for-hr-admins?utm_source=Backend",
  INVOICES_HELP_EMAIL_MESSAGE: "If you can't find the answers you're looking for, please feel free reach out to us at {link}.",
  BILLING_EMAIL: "billing@league.com",
  PAYROLL: "Payroll",
  PAYROLL_HEADER: "Payroll Reports",
  PAYROLL_HEADER_COPY: "This section contains the payroll reports for each billing period. Payroll reports are generated alongside invoices at the beginning of each month and cover the same billing period as your monthly invoice.",
  NO_PAYROLL_MESSAGE: "There are no payroll reports available.",
  INVOICE_HEADER_INVOICE_NUMBER: "Invoice Number",
  INVOICE_BILLING_DIVISION: "Billing Division",
  INVOICE_HEADER_DATE_ISSUED: "Date Issued",
  INVOICE_HEADER_STATUS: "Status",
  INVOICE_HEADER_DOWNLOAD: "Download",
  INVOICE_DRY_RUN: "Invoice Dry Run",
  DRY_RUN_DOWNLOAD: "Download Dry Run: ",
  DRY_RUN_GROUP_NAME: "Dry Run Group Name",
  DRY_RUN_CREATED: "Dry Run Created",
  STATEMENT_OF_ACCOUNT_DOWNLOAD: "Download Statement of Account: ",
  INVOICE_DIAGNOSTIC_DOWNLOAD: "Download Invoice Diagnostics Report: ",
  CLEAR_INVOICING_DIAGNOSTICS: "Clear Invoice Diagnostics",
  CLEAR_INVOICE_DIAGNOSTICS_CONFIRMATION: "<strong>Warning: </strong> Clearing the invoice diagnostics is a permanent action, and will remove all existing diagnostics from the League system.",
  PAYROLL_HEADER_BILLING_PERIOD: "Billing Period",
  PAYROLL_BILLING_DATE_RANGE: "{startDate} to {endDate}",
  PAYROLL_HEADER_DATE_ISSUED: "Date Issued",
  PAYROLL_HEADER_DOWNLOAD: "Download",
  NO_EMPLOYEE_BENEFITS_MESSAGER: "There are no benefits available.",
  ERROR_UPDATING_EMPLOYER: "Error! Profile could not be updated",
  SUCCESS_UPDATING_EMPLOYER: "Success! Profile updated",
  SKIP_THIS_ACTIVITY: "Skip this activity",
  ARE_YOU_SURE_SKIP_GOAL: "Are you sure you want to skip this goal?",
  THE_GOAL_WILL_BE_MARKED_INCOMPLETE: "The goal will be marked as incomplete and you won't receive points for it this time around.",
  YES_SKIP_GOAL: "Yes, skip this goal",
  NO_DONT_SKIP: "No, don't skip",
  VIEW_ALL_WITH_ARROW: "View all >",
  SELECT_CARD: "Select card",
  SELECT_A_BENEFIT_CARD_TO_SUBMIT_A_CLAIM: "Select a benefit card to submit a claim, view your coverage, manage your dependents, and more.",
  NEED_HELP: "Need Help?",
  NEED_HELP_TEXT: "If you have any questions about your coverage, our team is here to help!",
  CONTACT_CUSTOMER_SERVICE: "Contact Customer Service",
  ACTIVATION_PENDING: "Activation Pending",
  EXPIRING_SOON: "Expiring Soon",
  EXPIRED: "Expired",
  CURRENTLY_UNAVAILABLE: "Currently Unavailable",
  YOU_CAN_USE_YOUR_ACCOUNT_ON: "You can use your account on:",
  YOUR_ACCOUNT_IS_ACTIVE_AND_IN_THE_PROCESS_OF_BEING_SET_UP: "Your account is active and in the process of being set up.",
  YOUR_BENEFITS_WILL_BE_ACTIVE_ON: "Your benefits will be active on:",
  YOU_HAVE_UNTIL_DATE_TO_SUBMIT: "You have until <strong>{inactiveDate}</strong> to submit for purchases prior to <strong>{endDate}</strong>.",
  CLAIMS_FOR_PURCHASES_MADE_AFTER_DATE_WILL_BE_REJECTED: "Claims for purchases made after {endDate} will be rejected.",
  YOUR_ACCOUNT_EXPIRED_ON: "Your account expired on:",
  WE_CANT_CURRENTLY_ACCESS_YOUR_ACCOUNT_DETAILS: "We can't currently access your account details.",
  PLEASE_TRY_AGAIN_SHORTLY: "Please try again shortly",
  IF_YOU_HAVE_AN_URGENT_CLAIM: "If you have an urgent claim,",
  IF_YOU_BELIEVE_THIS_IS_AN_ERROR: "If you believe this is an error",
  IF_THE_PROBLEM_PERSISTS: "If the problem persists",
  PLEASE_CONTACT: "please contact",
  MANAGE_DEPENDENTS: "Manage Dependents",
  VIEW_DEPENDENTS_DESCRIPTION_TEXT: "Your dependents can also use your {benefitName} as much they like.",
  VIEW_DEPENDENTS_MORE_INFO_LINK_TEXT: "Find out who qualifies as a dependent",
  VIEW_CARD: "View Card",
  MEMBER_ID: "Member ID",
  RELATIONSHIP: "Relationship",
  SPOUSE: "Spouse",
  COMMON_LAW: "Common-law",
  PARTNER: "Partner",
  CHILD: "Child",
  PARENT: "Parent",
  GRANDPARENT: "Grandparent",
  GRANDCHILD: "Grandchild",
  UNCLE_AUNT: "Uncle / Aunt",
  NEPHEW_NIECE: "Nephew / Niece",
  INELIGIBLE_CHILD: "Ineligible Child",
  SIBLING: "Sibling",
  OTHER: "Other",
  PARTNER_LOWERCASE: "partner",
  CHILD_LOWERCASE: "child",
  MORE_ACTIONS: "More Actions",
  DEPENDENT_CARD_HOLDER: "{dependentName}'s card for",
  SAVE_CARD: "Save Card",
  DATE: "Date",
  TIME: "Time",
  TITLE: "Title",
  DESCRIPTION: "Description",
  TYPE: "Type",
  ID: "ID",
  CLAIM: "Claim",
  MARKETPLACE: "Marketplace",
  CREDIT: "Credit",
  TOP_UP: "Top Up",
  ADJUSTMENT: "Adjustment",
  REFUND: "Refund",
  REFUNDED: "Refunded",
  LEAGUE_CREDIT: "League Credit",
  CREDIT_CARD: "Credit Card",
  CGA: "CGA",
  NO_TRANSACTIONS_YET: "No transactions yet",
  NO_MORE_TRANSACTIONS: "No more transactions",
  PROMO_CODE: "Promo code",
  PROMO_CODE_NOT_FOUND: "Invalid promo code",
  PROMO_CODE_SUCCESS: "Promo code redeemed!",
  CODE_ALREADY_REDEEMED: "Promo code already redeemed",
  CREDIT_CARD_INTRO: "Let the app take care of the bill.",
  SIMPLY_ADD_YOUR_CREDIT_CARD_TO: "Simply add your credit card to the app, charge away and submit as a claim for reimbursement.",
  ADD_CREDIT_CARD_TEXT: "To book healthy services on League, please add a valid VISA, MasterCard, or AMEX.",
  ADD_CARD: "Add Card",
  YOUR_CARD: "Your Card",
  TOTAL_CREDIT: "Total Credit:",
  CREDIT_INFORMATION: "Credit Information",
  ENTER_CODE: "Enter Code",
  CLAIM_DOCUMENTS: "Claim Documents",
  SUPPORTING_DOCUMENTS: "Supporting Documents",
  ATTACH_TO_CLAIM: "Attach to Claim",
  REMOVE_FROM_CLAIM: "Remove from Claim",
  ATTACHED_TO_CLAIM: "Attached to Claim",
  DOCUMENT_INFORMATION: "Document Information",
  ADD_DOCUMENT_INFORMATION: "Add Document Information",
  UPLOAD_SUPPORTING_DOCUMENT: "Upload Supporting Document",
  EDIT_SUPPORTING_DOCUMENT: "Edit Supporting Document",
  EDIT_DOCUMENT: "Edit Document",
  ALL_FIELDS_REQUIRED: "All fields are required unless marked as optional",
  DOCUMENT_TYPE: "Document type",
  DOCUMENT_NAME: "Document name",
  REQUIRED: "Required",
  CONFIRM_REMOVE_SUPPORTING_DOC_TEXT: "This will remove the Supporting Document from the user\u2019s profile. Are you sure?",
  CONFIRM_DELETE_SUPPORTING_DOC_TEXT: "This will delete this supporting document and cannot be undone. Are you sure?",
  UNPIN_TO_REMOVE_USER_DOCUMENT: "Unpin to remove from the user\u2019s profile",
  EXPIRES: "Expires",
  PIN_TO_SAVE_USER_DOCUMENT: "Pin to save this document to the user\u2019s profile",
  ADJUSTMENTS: "Adjustments",
  MAKE_ADJUSTMENT: "Make Adjustment",
  NO_ADJUSTMENTS: "There are no adjustments",
  UPDATE_STATUS: "Update Status",
  CHOOSE_NEW_STATUS: "Choose a new status",
  APPROVE: "Approve",
  SENT_FOR_EXTERNAL_REVIEW: "Sent for external review",
  DUPLICATE_CLAIM_HEADER: "The claim you are submitting is a duplicate of {claimLink}",
  CLAIM_REF: "<Claim ID: {referenceId}>",
  DUPLICATE_CLAIM_INTRO: "You have the choice of the following options:",
  DUPLICATE_CLAIM_CANCEL_ACTION: "Cancel",
  DUPLICATE_CLAIM_CANCEL_DESCRIPTION: "No action is taken and you will return to the previous screen.",
  DUPLICATE_CLAIM_APPROVE_ACTION: "Approve Claim",
  DUPLICATE_CLAIM_APPROVE_DESCRIPTION: "Approve claim and ignore warning of duplicate claim.",
  DUPLICATE_CLAIM_REJECT_ACTION: "Reject Claim",
  DUPLICATE_CLAIM_REJECT_DESCRIPTION: "Reject claim and send the member a canned response denoting this claim as a duplicate.",
  DUPLICATE_CLAIM_CONTACT_MANAGER: "If you are unsure what action to take, contact a manager.",
  PAYMENT_ADJUSTMENT_DATE: "Date",
  PAYMENT_ADJUSTMENT_AMOUNT: "Amount",
  PAYMENT_ADJUSTMENT_BILLING_DIVISION: "Billing Division",
  PAYMENT_ADJUSTMENT_NOTE: "Note",
  CLAIM_NOTE: "The user will receive this message via claim history:",
  REQUEST_MORE_INFORMATION: "Request More Information",
  REJECT: "Reject",
  UPDATE: "Update",
  CONFIGURE_THIRD_PARTY_INTEGRATIONS: "Configure 3rd-Party Integrations",
  ENABLE_THIRD_PARTY_INTEGRATIONS_BY_FILLING_IN_FIELDS: "Enable 3rd-party integrations by filling in associated fields.",
  COPY_PASTE_TYPEFORM_URL: "Copy and paste a Typeform URL to add that Typeform as a new step to the enrollment process. Classes can be added or removed from the Benefits tab.",
  BENEFIT_CLASS: "Benefit Class",
  TYPEFORM_URL: "Typeform URL",
  INTEGRATIONS_CONFIGURATION_SAVED: "Integrations configuration saved successfully",
  REINSTATE: "Reinstate",
  SUSPEND: "Suspend",
  TERMINATE: "Terminate",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  UNSCHEDULE_TERMINATION_HEADER: "Cancel Termination",
  UNSCHEDULE_SUSPENSION_HEADER: "Cancel Suspension",
  UNSCHEDULE_REINSTATE_HEADER: "Cancel Reinstatement",
  UNSCHEDULE_SUSPENSION_DESCRIPTION: "Please confirm you would like to unschedule this employee's suspension.",
  UNSCHEDULE_TERMINATION_DESCRIPTION: "Please confirm you would like to unschedule this employee's termination.",
  UNSCHEDULE_REINSTATE_DESCRIPTION: "Please confirm you would like to unschedule this employee's reinstatement.",
  CONFIRM_UNSCHEDULE: "Confirm Unschedule",
  DOWNLOAD_THE_LATEST: "Download the latest reports to your desktop.",
  ENROLLMENT_REPORT: "Enrollment Report",
  DOWNLOAD_A_CSV: "Download a report containing all employee details, plan selections, and dependents information collected by League to date",
  DOWNLOAD_ENROLLMENT_REPORT: "Download Enrollment Report",
  GENERATE_CSV: "Generate CSV",
  BENEFICIARIES_REPORT: "Beneficiaries Report",
  BENEFICIARIES_REPORT_TEXT: "This report provides details about each employee's beneficiary designations, for each applicable benefit.",
  DOWNLOAD_BENEFICIARIES_REPORT: "Download Beneficiaries Report",
  USAGE_HISTORY: "Get Usage Report",
  GENERATE_CSV_ERROR_STATE_MESSAGE: "Something went wrong! Please try again. If the problem persists, please contact {emailLink} for assistance.",
  DUPLICATE_BENEFIT_NAME_WARNING: "Multiple benefits of this type have the same name. Consider giving them different names.",
  ACTION: "Action",
  ACTION_HINT: "Select Open URL if you want members to take an action on the banner, or leave as none if it's just informative.",
  ALLOCATION: "Allocation",
  AMOUNT_ALLOCATION: "{amount} Allocation",
  AMOUNT_CONTRIBUTION: "{amount} Contribution",
  BALANCE: "Balance",
  ADD_ADMIN_ROLE: "Add Admin Role",
  REMOVE_ADMIN_ROLE: "Remove Admin Role",
  ADD_FUNDS: "Add Funds",
  ADD_FUNDS_EMPLOYEE: "Add Funds to {employee}",
  THESE_FUNDS_ARE_ONE_TIME_ALLOCATION: "This is a one-time addition. If you need to make changes to the total allocation, {linkToCustomerSupport}.",
  CONTACT_CUSTOMER_SUPPORT: "Contact Customer Support",
  CUSTOMER_SUPPORT: "Customer Support",
  FUNDS_TO_BE_ADDED: "Funds to be added",
  ADD_FUNDS_SUCCESS: "Success, funds added! {userName}'s new balance is {balance}",
  ACCOUNT: "Account",
  MISSING_SPENDING_ACCOUNT: "Missing spending account",
  MISSING_AMOUNT_TO_BE_ADDED: "Missing amount to be added",
  TOTAL_ALLOCATION_SUMMARY: "Each total policy allocation will be divided into equal installments and paid {frequency} between {startDate} and {endDate}.",
  ONE_TIME_TOTAL_ALLOCATION_SUMMARY: "Each total policy allocation will be paid {frequency} at the policy period start date of {startDate}",
  EMPLOYEE_ALLOCATION_LABEL: "Individuals {dependentClassDescription} will receive",
  DEPENDENT_CLASS_DESCRIPTION_SINGLE: "who are single with no {spouse} or {dependents}",
  DEPENDENT_CLASS_DESCRIPTION_COUPLE: "with a {spouse} and no other {dependents}",
  DEPENDENT_CLASS_DESCRIPTION_FAMILY: "part of a family with at least 1 dependent other than a spouse",
  DEPENDENT_CLASS_DESCRIPTION_COUPLE_FAMILY: "part of a {family} with at least 1 dependent",
  SPOUSE_TOOLTIP: "A spouse is a person who is legally married to or is in a common-law relationship with an employee.",
  DEPENDENT_TOOLTIP: "Dependents can be an employee's children and grandchildren (either biological, adopted or stepchildren), parents and grandparents, brothers or sisters (including brothers-in-law and sisters-in-law), nieces, nephews, aunts or uncles.",
  DEPENDENT_UPDATED: "Dependent saved",
  DEPENDENT_UPDATE_ERRORED: "There was an error saving your dependent",
  DEPENDENT_REMOVED: "Dependent removed",
  DEPENDENT_REMOVED_FROM_UPCOMING_PLAN: "Dependent has been removed from your upcoming plan",
  DEPENDENT_REMOVED_ERROR: "Error removing dependent",
  LINKED_BENEFIT_ADDED_HEADER: "{benefitName} Added!",
  LINKED_BENEFIT_REMOVED_HEADER: "{benefitName} Removed",
  LINKED_BENEFIT_ADDED_CONTENT: "You'll be able to view this benefit on the {benefitSetName} page.",
  LINKED_BENEFIT_REMOVED_CONTENT: "You'll no longer be able to view this benefit on the {benefitSetName} page.",
  BUNDLED_BENEFIT_SELECTED_HEADER: "Your {selectedBenefitName} and {bundledBenefitName} plans are packaged together",
  BUNDLED_BENEFIT_SELECTED_CONTENT: "We updated your {bundledBenefitName} plan to <strong>{bundledBenefitPlanName}</strong> to match your selection for <strong>{selectedBenefitPlanName}</strong>",
  CHANGE_ALLOCATION_DETAILS: "Change Allocation Details",
  EDIT_SPENDING_ACCOUNT_ALLOCATIONS: "Edit Spending Account Allocations",
  ALLOCATION_EXCEEDS_WELLNESS_POOL: "<strong>Hey, big spender!</strong> You've exceeded your allocated amount for your {flexTerm}. Please re-allocate funds to not exceed {amount}.",
  ALLOCATION_EXCEEDS_CONTRIBUTION_LIMIT: "<strong>Hey, big spender!</strong> It looks like you've exceeded a contribution limit",
  ALLOCATION_UNDER_WELLNESS_POOL: "<strong>Don't be frugal!</strong> You haven't spent your full allocation of {amount}.",
  CHANGES_WILL_AFFECT_OVERALL_POLICY: "Any changes made to the user's fund will affect their overall policy including proration & periodic allocations.",
  CURRENT_FUNDS: "Current Funds",
  NEW_FUNDS: "New Funds",
  INCORRECT_AMOUNT: "Incorrect amount",
  COVERED: "Covered",
  NOT_COVERED: "Not covered",
  COVERED_WITH_RESTRICTIONS: "Covered with restrictions",
  VIEW_YOUR_COVERAGE: "View your coverage",
  VIEW_COVERAGE: "View Coverage",
  MORE_DETAILS_ON_THE_BENEFIT: "More details on the {benefitShortName}",
  EXPAND_ALL: "Expand All",
  COLLAPSE_ALL: "Collapse All",
  EXPAND: "Expand",
  COLLAPSE: "Collapse",
  EXAMPLES: "Examples: ",
  RESTRICTIONS: "Restrictions",
  SEARCH_COVERAGE: "Search your coverage",
  COVERAGE_CATEGORIES: "Coverage categories",
  COVERAGE_SEARCH_BEHAVIOUR_DESCRIPTION: "Search results will update automatically as you type",
  ADD_FUNDS_MODAL_HEADER: "Add {flexDollarTerm} to your {benefitName}",
  EDIT_FUNDS_MODAL_HEADER: "Edit {flexDollarTerm} in your {benefitName}",
  PRORATED_CONTRIBUTION_DETAILS_MODAL_DESCRIPTION: "We\u2019re partway through your policy period. Funds contributed to this account have been prorated based on the number of {periodName} left in your policy period.",
  YOUR_FLEX_DOLLAR_BALANCE: "Your {flexDollarTerm} balance: ",
  ADD_FUNDS_MODAL_TEXT: "Add however many funds you\u2019d like from your {flexDollarTerm} balance to this account.",
  CONTRIBUTION_AFTER_PRORATION: "Contribution after proration",
  CONTRIBUTION_AFTER_PRORATION_AMOUNT: "Contribution after proration is {proratedAmount}",
  ADD_FLEXIBLE_DOLLARS: "Add {flexDollarTerm}",
  ADD_FLEXIBLE_DOLLARS_TO_BENEFIT: "Add {flexDollarTerm} to {vendorName} {benefitName}",
  CHANGE_CONTRIBUTION: "Change Contribution",
  EDIT_CONTRIBUTION: "Edit Contribution",
  EDIT_CONTRIBUTION_OF_BENEFIT: "Edit Contribution of {vendorName} {benefitName}",
  BENEFIT_NAME_CONTRIBUTION: "{benefitName} Contribution",
  BENEFIT_NAME_CONTRIBUTION_UPDATED: "{benefitName} Contribution Updated!",
  CURRENT_CONTRIBUTION: "Current Contribution",
  CURRENT_CONTRIBUTION_NOTE: "This is the amount you elected to contribute during your previous enrollment.",
  NEW_CONTRIBUTION: "New Contribution",
  NEW_CONTRIBUTION_NOTE: "You can personally contribute up to {amount}",
  EDIT_CONTRIBUTION_WARNING: "If your new contribution falls below what you\u2019ve already contributed for this plan year, your payroll deductions will be $0 moving forward. You will not be refunded any funds from previous payroll deductions.",
  EDIT_CONTRIBUTION_SUCCESS_DEFAULT: "You will see your new payroll deductions take effect within 1-2 pay dates after you complete enrollment.",
  EDIT_CONTRIBUTION_SUCCESS_CRA: "You will see your new payroll deductions take effect within 2-3 pay dates after you complete enrollment.",
  EDIT_CONTRIBUTION_ERROR_NO_CHANGE_VALUE: "Please enter a new contribution to continue",
  OKAY: "Okay",
  CONTRIBUTION_GUIDANCE_HEADER: "How much should I contribute?",
  HSA_CONTRIBUTION_GUIDANCE_SUBHEADING: "Financial experts recommend these goals when contributing to your HSA:",
  HSA_CONTRIBUTION_FIRST_GOAL: "Your first goal can aim to cover your {dependentCoverageType} deductible of <strong>{deductible} / policy period</strong>.",
  HSA_CONTRIBUTION_NEXT_GOAL: "Your next savings goal can be to cover your {dependentCoverageType} out-of-pocket maximum of <strong>{outOfPocketMax} / policy period</strong>.",
  HSA_CONTRIBUTION_FINAL_GOAL_NO_EMPLOYER_CONTRIBUTION: "Finally, if you\u2019re able, you can contribute up to <strong>{employeeContributionLimit} / policy period</strong>.",
  HSA_CONTRIBUTION_FINAL_GOAL_WITH_EMPLOYER_CONTRIBUTION: "Finally, if you\u2019re able, you can contribute up to <strong>{employeeContributionLimit} / policy period</strong>. This takes into account your employer\u2019s contribution of <strong>{employerContribution} / policy period</strong>.",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_HEADER: "You can contribute up to {employeeContributionLimit} for this policy period",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_BODY_NO_EMPLOYER_CONTRIBUTION: "Your personal contribution limit is based on the annual IRS limit. If you are 55 or older this year, your personal contribution limit includes an additional catch-up contribution limit of {catchUpLimit}",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_BODY_WITH_EMPLOYER_CONTRIBUTION: "We\u2019ve calculated your personal contribution limit by taking the annual IRS limit and subtracting your employer\u2019s contribution for the policy period. If you are 55 or older this year, your personal contribution limit includes an additional catch-up contribution limit of {catchUpLimit}.",
  CALCULATING_PRORATED_AMOUNT: "Calculating prorated amount",
  PRORATED_EXPLAINER_TEXT: "Because you have <strong>{periodsRemaining} {periodName}</strong> left in your policy, we take the full amount and multiply it by <strong>{periodsRemaining}/{totalPeriods}</strong> to get to this prorated amount.",
  PRORATION_EQUATION: "{fullAmount} x {periodsRemaining}/{totalPeriods} ({periodName} left in policy period)  = {proratedAmount}",
  ADD_FUNDS_NO_ALLOCATION_ERROR: "Please add funds here to proceed",
  ADD_FUNDS_EDIT_ALLOCATION_ERROR: "This is the same amount that was previously entered",
  ADD_FUNDS_OVER_ALLOCATION_ERROR: "Looks like you\u2019re trying to add funds greater than your available balance!",
  PRORATION_BANNER_HEADING: "Heads-up: funds allocated to these accounts will be prorated.",
  PRORATION_BANNER_CONTENT: "We\u2019re partway through your policy period. Any funds allocated to these accounts will be prorated based on the number of {periodName} you have left in your policy period. {prorationExample}.",
  SEE_AN_EXAMPLE_OF_PRORATION: "See an example of proration",
  LEARN_MORE_ABOUT_PRORATION: "Learn more about proration",
  PRORATION_BANNER_TOOLTIP_EXAMPLE: "Let\u2019s say your policy period started January 1st and you have a Qualifying Life Event (QLE) in July. You make some changes to your elections and end up with $500 {flexDollarTerm}. This amount is for your full policy period.",
  PRORATION_BANNER_TOOLTIP_REASON: "Because you\u2019re partway through your policy period, you\u2019ll only get part of this amount when you allocate your funds to an account. In this case, you\u2019ll end up getting half.",
  PRORATION_BANNER_TOOLTIP_REASON_MOBILE: "Because we\u2019re partway through your policy period, funds added will be prorated based on the number of {periodName} you have left in your policy period.",
  PRORATION_CALCULATION_EXAMPLE: "$500 x 6/12 (months left in policy period) = $250",
  PRORATED_FUNDS: "Prorated Funds",
  WHY_ARE_MY_FUNDS_PRORATED: "Why are my funds prorated?",
  LEFTOVER_FLEX_DOLLAR: "Leftover {flexDollarTerm}",
  QLE_CONTRIBUTIONS_RESTRICTIONS_INFO: "Because you\u2019re in a Qualifying Life Event (QLE) Enrollment, you won\u2019t be able to add or edit funds for certain accounts on this page. Your next opportunity to add or edit funds will be during your next annual enrollment.",
  CONTRIBUTION_LIMIT_SOFT: "You can contribute up to <strong>{amount}</strong> pre-tax, any funds that exceed this limit will be taxed.",
  CONTRIBUTION_LIMIT_MAX: "Your contribution limit for this account is <strong>{amount}</strong>{contributionFrequency}.",
  LEARN_MORE_ABOUT_HSA_CONTRIBUTION_LIMIT: "Learn more about HSA personal contribution limit",
  PERSONAL_CONTRIBUTION_LIMIT_MAX: "Your personal contribution limit for this account is <strong>{amount}</strong>{contributionFrequency}.",
  CONTRIBUTION_LIMIT_SOFT_AND_MAX: "You can contribute up to <strong>{maxAmount}</strong> to this account. Contributions up to <strong>{amount}</strong> will be pre-tax.",
  ZERO_CONTRIBUTION_LIMIT_HSA: "You must be enrolled in a HSA-eligible Medical plan before December 1st in order to contribute to this account.",
  ZERO_CONTRIBUTION_LIMIT_DEFAULT: "You cannot add funds to this account at this time. Reach out to our Customer Care team for more information.",
  FOR_THE_POLICY_PERIOD: "for the policy period",
  SUCCESSFULLY_UPDATED_ALLOCATION: "Success updating allocation!",
  FAMILY: "family",
  FAMILY_CAP: "Family",
  INDIVIDUAL: "individual",
  INDIVIDUAL_CAP: "Individual",
  DRY_RUN_ERROR: "Error getting invoice dry run",
  GET_STATEMENT_OF_ACCOUNT_ERROR: "Error getting statement of account",
  GET_INVOICE_DIAGNOSTICS_ERROR: "Error getting invoice diagnostics",
  CLEAR_INVOICE_DIAGNOSTICS_SUCCESS: "Invoicing diagnostics cleared",
  CLEAR_INVOICE_DIAGNOSTICS_ERROR: "Error clearing invoice diagnostics",
  DRY_RUN: "Perform Dry Run",
  PDF_LINK: ".PDF",
  CSV_LINK: ".CSV",
  GET_STATEMENT_OF_ACCOUNT: "Get Statement of Account",
  GET_INVOICE_DIAGNOSTICS: "Get Invoice Diagnostics",
  STATEMENT_OF_ACCOUNT: "Statement of Account",
  INVOICE_DIAGNOSTICS: "Invoice Diagnostics",
  BILLING_DIVISIONS: "Billing Divisions",
  ADD_BILLING_DIVISION: "Add Billing Division",
  DIVISIONS: "Divisions",
  DIVISION_DELETED: "Billing division successfully deleted!",
  DIVISION_DELETE_ERROR: "An error occurred deleting the billing division. Ensure there are no employees in the division",
  DIVISION_ADD_ERROR: "An error occurred adding billing division. Ensure name is not already being used!",
  YOUR_PROFILE: "Your Profile",
  DEPENDENTS: "Dependents",
  STEP_NOT_COMPLETED: "Step not completed",
  STEP_HAS_ERROR: "This step has error(s)",
  STEP_COMPLETED: "Step completed",
  STEP_SELECTED: "Current step",
  PROFILE_PAGE_HEAD: "Build Your Benefits",
  YOUR_PROFILE_SECTION_HEADING: "Welcome! Let's get to know you.",
  YOUR_PROFILE_SECTION_SUBHEADING: "We've pre-populated some fields based on information we already know from {employerName}. You can edit these if you like.",
  YOUR_PROFILE_SECTION_SUBHEADING_INTEGRATION: "We've pre-populated some fields based on information we already know from {employerName}. Please ensure your information is correct to complete your enrollment. Note that any changes made here will also need to be updated in your HR system.",
  NEXT_UP_DEPENDENTS: "Next Up: Dependents",
  REVIEW_DEPENDENTS: "Review Dependents",
  NO_DEPENDENTS_DESCRIPTION: "You can move onto the next step if you don't have anyone to add today.",
  ADD_CHILD: "Add Child",
  ADD_PARTNER: "Add Partner",
  ADD_FAMILY_MEMBER: "Add Family Member",
  CURIOUS_ABOUT: "Curious about",
  DEPENDENT_COVERAGE_SELECT_HEADER: "Who will you be enrolling for {benefitName} coverage?",
  DEPENDENT_COVERAGE_SELECT_ADD_DEPENDENTS: "Need to add another dependent? Head to the {link} to make this selection. We\u2019ll save your current selections.",
  OTHER_FAMILY_MEMBERS: "Other Family Members*",
  PARTNER_DEPENDENT_TYPE_DESCRIPTION: "Must be someone you are married to or a common-law partner",
  PARTNER_DEPENDENT_TYPE_DESCRIPTION_US: "Must be someone you are married to or a domestic partner",
  PARTNER_ALREADY_ADDED: "You have already added a Spouse/Partner",
  CHILD_DEPENDENT_TYPE_DESCRIPTION: "Must be your child or the child of your spouse/partner",
  OTHER_DEPENDENT_TYPE_DESCRIPTION: "Must be a resident of Canada and depend on you financially",
  OTHER_DEPENDENT_TYPE_FOOTNOTE: "*Only eligible for spending accounts",
  FAMILY_MEMBER: "Family Member",
  DEPENDENT_COVERAGE_SELECT_DEPENDENTS_LINK_LABEL: "dependents section",
  PLAN_SELECTION: "Plan Selection",
  CHOOSE_PLAN_INDICATOR: "You'll be able to review your chosen plan after you make your selection!",
  NA: "N/A",
  SELECT_AN_OPTION: "Select an option",
  SELECT_ONE: "Select one",
  WOULD_YOU_LIKE_TO_ENROLL: "Would you like to enroll in {benefitName} Coverage?",
  WHO_WILL_YOU_BE_ENROLLING: "Who will you be enrolling for coverage?",
  YES_ENROLL_IN_COVERAGE: "Yes, Enroll in Coverage",
  NO_WAIVE_COVERAGE: "No, Waive Coverage",
  CHOOSE_COVERAGE_RIGHT_FOR_YOU: "Your {benefitName} Coverage",
  SELECT_THIS_PLAN: "Select This Plan",
  CLASS_PLAN_NAME: "Plan",
  PLAN_ID: "Plan ID",
  BENEFIT_IS_INELIGIBLE: "This benefit is ineligible",
  QUESTION_MARK: "?",
  NEXT_BENEFIT_HEADING: "Next Up: Review your {benefitType} Coverage",
  NEXT_BENEFIT_BUTTON: "{benefitType, select, other {Review {benefitType}} Accident {Review Accident Insurance} Dental {Review Dental Insurance} Disability {Review Long-Term Disability} Drug {Review Drug Insurance} Life {Review Life Insurance} Medical {Review Medical Insurance} Wellness {Review Wellness Accounts}}",
  CHOOSE_YOUR_PLAN: "Next Up: Choose Your Plan",
  CHOOSE_PLAN: "Choose Plan",
  REVIEW_YOUR_PLAN: "Next Up: Review Your Plan",
  REVIEW_PLAN: "Review Plan",
  BENEFITS_SUMMARY: "Benefits Summary",
  YOUR_BENEFITS_SUMMARY: "Your Coverage Summary",
  PLAN_YEAR: "Plan Year",
  UPCOMING_PLAN: "Upcoming Plan",
  CURRENT_PLAN: "Current Plan",
  PAST_PLANS: "Past Plan(s)",
  ESTIMATED_BENEFIT_LABEL: "Your Estimated Benefit Amount",
  ESTIMATED_BENEFIT_TOOLTIP_MONTHLY: "This is an estimate of the amount of money you\u2019ll receive on a monthly basis during your coverage period. It is calculated based on your monthly earnings.",
  ESTIMATED_BENEFIT_TOOLTIP_WEEKLY: "This is an estimate of the amount of money you\u2019ll receive on a weekly basis during your coverage period. It is calculated based on your weekly earnings.",
  COVERAGE_VOLUME_AMOUNT: "{amount} / {period}",
  YOUR_BENEFITS_SUMMARY_TEXT: "On the next screen, you\u2019ll see a final summary of your selections.",
  MORE_OPTIONS: "More Options",
  PRINT: "Print",
  REVIEW: "Review",
  CONFIRM_YOUR_SELECTIONS: "Confirm Your Elections",
  CONFIRM_SELECTIONS_TEXT: "By completing your enrollment, you certify that you have provided accurate information.",
  CONFIRM_SELECTIONS_FINAL: "Once you submit, your elections will be final.",
  CONFIRM_SUBMISSION: "By submitting, you certify that you have provided accurate information.",
  CONFIRM_SELECTIONS_TEXT_EXTENDED: "Submitting doesn't have to be scary; you can come back to update your choices before they become final. You have until the end of your enrollment period to do this. Make sure to submit every time you make any changes to your plan choices.",
  SUBMIT_SELECTIONS: "Submit Elections",
  DEFAULT_DESCRIPTION_CURRENCY_INPUT: "Enter your custom amount.",
  ACCOUNT_LINK_COPY: "+ much more",
  ACCOUNT_DESCRIPTION_COPY: "Read about your full coverage details.",
  YOUR_SPENDING_ACCOUNT_COVERS: "Your {accountType} Covers",
  VENDOR: "Vendor",
  HSA_SHORT_DESC: "<p>Take care of your well-being with the {name} ({shortName}). You can use this account to help meet your medical needs, covering appointments and services for your dental care, vision care, including registered practitioners such as RMTs, acupuncture, chiropractors, etc.</p>This account is tax-free - which means you won\u2019t have to pay income tax on the money you spend from this account (Exception: {shortName} is taxable in Quebec).",
  LSA_SHORT_DESC: "<p>Fuel your way of life with the {name} ({shortName}). You can use the funds from this account to purchase a variety of products or services any way you like.</p>Keep in mind the {shortName} is a taxable benefit - this means you will need to pay income tax on the money you spend from this account.",
  PSA_SHORT_DESC: "<p>Use your {name} ({shortName}) to pay for lifestyle and wellness services that matter to you. This account is a taxable benefit. Your PSA covers many products and services to support a healthy lifestyle.</p>",
  HRA_SHORT_DESC: "<p>Since you enrolled in a Health Reimbursement Arrangement (HRA)-qualified medical plan, {employerName} will fund an HRA to help you pay for eligible healthcare expenses.</p>HRAs are owned by the employer and funded by employer contributions only.",
  RRSP_SHORT_DESC: "<p>Save for a future you. You can use an RRSP to save for retirement and buy different investments or help you purchase your first home. Any contributions you make to the RRSP are tax-exempt.</p>Be mindful of your personal limit when you contribute.",
  TFSA_SHORT_DESC: "<p>Money doesn\u2019t grow on trees, but it does grow in a TFSA. A TFSA is a government-sponsored investment account that you can use to grow your savings and invest in mutual funds. The income you generate from the funds in your TFSA is, you guessed it, tax-free!</p>Be mindful of your personal limit when you contribute.",
  PENSION_SHORT_DESC: "Personal Retirement Savings Account (PRSA) is a flexible pension benefit that offers tax relief on contributions.",
  CHARITY_SHORT_DESC: "Use your Charitable Giving Account (CGA) to donate to registered charities of your choice.",
  HEALTH_SAVINGS_ACCOUNT_SHORT_DESC: "<p>A health savings account (HSA) is a long-term, tax-advantaged account that you can use for healthcare expenses. You own the account, and the balance left in it carries over from year to year. The amount you choose to contribute will be deducted evenly across your paychecks throughout the year.</p><strong>Please note:</strong> You cannot contribute to an HSA if you're enrolled in Medicare or if you're a dependent on another individual\u2019s tax return.",
  CRA_SHORT_DESC: "A commuter reimbursement account (CRA) is a tax-advantaged account that you can use to save and pay for qualified transit and parking commuting expenses. {postTaxContributionDesc}The amount you choose to contribute will be deducted evenly across your paychecks throughout the year.",
  SHORT_DESC_WITH_POST_TAX_CONTRIBUTION: "Any funds that you contribute beyond the monthly limit will be taxed. ",
  LDSA_SHORT_DESC: "If you have some big self-improvement goals this year, dedicating some funds to this account is a step in the right direction! Any funds added to this account can be used to pay for qualified Learning and Development expenses.",
  FSA_SHORT_DESC: "If you have some big self-improvement goals this year, dedicating some funds to this account is a step in the right direction! Any funds added to this account can be used to pay for qualified Fitness expenses. This is a taxable benefit, so any money spent from this account will be taxed. {employerContributionDesc}",
  DCFSA_SHORT_DESC: "<p>A dependent care flexible spending account (DCFSA) is a tax-advantaged account that you can use to save and pay for eligible expenses, such as day care, preschool, or after-school care for a qualified individual. The amount you choose to contribute will be deducted evenly across your paychecks throughout the year.</p><strong>Note:</strong> Contribution limits for this account will also depend on if you are contributing as a household or if you are married but file taxes separately.{employerContributionDesc}",
  HFSA_SHORT_DESC: "A healthcare flexible spending account (HCFSA) is a tax-advantaged account that you can use to save and pay for eligible medical, dental, and vision expenses that are not covered elsewhere. The amount you choose to contribute will be deducted evenly across your paychecks throughout the year. {employerContributionDesc}",
  LPFSA_DESC: "A limited purpose flexible spending account (LPFSA) is a tax-advantaged account that you can use to save and pay for eligible dental and vision expenses. The amount you choose to contribute will be deducted evenly across your paychecks throughout the year. {employerContributionDesc}",
  SHORT_DESC_WITH_EMPLOYER_CONTRIBUTION: "Your employer\u2019s contribution does not count towards your annual contribution limit.",
  WHY_CONTRIBUTE: "Why Contribute?",
  EVIDENCE_OF_INSURABILITY_US_TOOLTIP: "If you elect a coverage amount higher than the Guarantee Issue amount (GI), you will need to complete an Evidence of Insurability (EOI) application form. This will start an application process which requires you to provide information about your health. Once you complete the EOI application, it will need to be reviewed and approved by {vendor} before your full coverage amount becomes effective.\n\nYour coverage amount will be based on the GI until your EOI is approved by {vendor}. Your per pay period cost is what you can expect to pay after your EOI is approved by {vendor}. League will coordinate with {vendor} to provide you with instructions on how to complete the EOI form.",
  EVIDENCE_OF_INSURABILITY_TOOLTIP: "Evidence of Insurability (EOI) is required to apply for this benefit. You will need to complete and submit an EOI form (which includes a health questionnaire) for approval by {vendor} before your selected coverage amount takes effect.\n\nPlease note, further medical information may be required which will be obtained by a third party vendor by requesting an in-person appointment with you.",
  WHAT_FOR: "What is this for?",
  WHAT_DOES_THIS_MEAN: "What does this mean?",
  WHO_DOES_THIS_COVER: "Who does this cover?",
  EVIDENCE_OF_INSURABILITY_TEXT: "In order to apply for this benefit, you will need to complete Evidence of Insurability and submit to {vendor} for their approval. League will follow-up with instructions on how to complete and submit Evidence of Insurability to {vendor}.",
  EVIDENCE_OF_INSURABILITY_TEXT_KPMG: "In order to apply for this benefit, you need to provide Evidence of Insurability. Please complete and send a version of {questionnaireLink} to {vendor}.",
  EVIDENCE_OF_INSURABILITY_TEXT_NOTE: "Note: If you are already enrolled in this benefit, then you do not need to submit the EOI form unless you're choosing to increase your coverage",
  EVIDENCE_THIS_FORM: "this form",
  EVIDENCE_OF_INSURABILITY_BANNER_TEXT: "You must fill out and submit an {eoi} form to apply for {benefitName}. We\u2019ll supply this form after you complete enrollment.",
  EVIDENCE_OF_INSURABILITY_BANNER_TEXT_WITH_NEM: "You must fill out and submit an {eoi} form to apply for coverage over {noEvidenceMaximumValue}. We\u2019ll supply this form after you complete enrollment.",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT1: "Evidence of Insurability (EOI) is like a medical questionnaire that your carrier asks for in these situations:",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_LIST: "<li>You enroll in a benefit that requires an EOI for all coverage</li><li>You choose a level of coverage that requires an EOI</li><li>Your salary automatically puts you in a coverage level that requires an EOI</li>",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT2: "You may also need a physical evaluation and/or bloodwork to complete this process.",
  EVIDENCE_OF_INSURABILITY_EOI: "Evidence of Insurability (EOI)",
  LEARN_MORE_ABOUT_EOI: "Learn more about Evidence of Insurability",
  EOI_CARD_DESCRIPTION: "This form is required by your carrier for the <strong>{benefitNames}</strong> coverage that you selected.",
  FORM_NOT_READY_INSTRUCTIONS: "<strong>Instructions:</strong> Once your form is available, we\u2019ll send you an email with instructions on how to complete it.",
  SHOPIFY_EOI_FORM_NOT_READY_INSTRUCTIONS: "<strong>Instructions:</strong> This form is required by your carrier for the Optional Life, Optional Spouse Life, Optional Critical Illness and Optional Spouse Critical Illness Insurance if you are applying for the first time, or applying to increase your amount of coverage. If you are confirming amounts that have already been approved, this form is not required.",
  EOI_FORM_READY_INSTRUCTIONS: "<strong>Instructions:</strong> You\u2019ll need to complete this form and send it to your carrier. Further instructions are included in the form.",
  KPMG_EQUITY_PARTNER_WITH_OPTIONAL_LTD_EOI_FORM_INSTRUCTIONS: "<strong>Instructions:</strong> Please do not download the Evidence of Insurability (EOI) form. KPMG Total Rewards will provide you with the required form to complete",
  PAPER_BENEFICIARIES_FORM_READY_INSTRUCTIONS: "<strong>Instructions:</strong> After completing the form, you\u2019ll be able to upload it within League, unless otherwise instructed by your HR team.",
  LEARN_MORE_ABOUT_ADDING_DOCUMENTS: "Learn more about adding documents in League",
  FLEX_DOLLAR_TITLE: "{company} has provided {value} in {flexTerm} for you to spend as you like. {maxValue}",
  WELLNESS_POOL_TITLE: "{company} has provided {value} amount of {flexTerm} for you to spend as you like. {maxValue}",
  FLEX_DOLLAR_SUBTITLE_MAX_COST: "You can allocate up to this amount to the accounts below.",
  FLEX_DOLLAR_SUBTITLE_EXACT_COST: "Make sure to allocate the full amount between any of the following accounts.",
  FLEX_DOLLAR_INFO_TEXT: "If you have leftover {flexDollarTerm}, {message}",
  FLEX_DOLLAR_INFO_TEXT_SINGLE: "they will automatically be added to your {benefitName}.",
  FLEX_DOLLAR_INFO_TEXT_MULTIPLE: "<strong>{percent}%</strong> will be added to your <strong>{benefitName}</strong>",
  FLEX_DOLLAR_EXPLAINER_TITLE: "What if I\u2019ve already spent some or all of my {flexDollarTerm}?",
  IT_LOOKS_LIKE_YOU_HAVE_SOME_LEFTOVER_FLEX: "It looks like you have some leftover {flexDollarTerm}! {message}",
  LEFTOVER_FLEX_DOLLAR_INFO_TEXT_SINGLE: "<strong>{percent}%</strong> of it will be added to your <strong>{benefitName}</strong>.",
  LEFTOVER_FLEX_BANNER_HEADING: "You have {leftoverFlexDollars} in leftover {flexDollarTerm}",
  LEFTOVER_FLEX_BANNER_HEADING_ZERO: "You have no {flexDollarTerm} remaining",
  LEFTOVER_FLEX_BANNER_EXPLANATION: "Leftover {flexDollarTerm} is the amount of funds you have left after covering the cost of eligible benefits. {message}",
  LEFTOVER_FLEX_BANNER_INFO_SINGLE: "These leftover funds will be added to your {benefitName}.",
  LEFTOVER_FLEX_BANNER_INFO_ZERO_SINGLE: "Any leftover funds will be added to your {benefitName}.",
  LEFTOVER_FLEX_BANNER_INFO_SPLIT: "These leftover funds will be split between your accounts: {message}",
  LEFTOVER_FLEX_BANNER_INFO_ZERO_SPLIT: "Any leftover funds will be split between your accounts: {message}",
  DOCUMENT_TITLE: "{title} - League",
  FLEXIBLE_DOLLARS: "flexible dollars",
  WELLNESS_POOL: "wellness pool",
  FLEXIBLE_DOLLARS_CAPITALIZED: "Flexible Dollars",
  WELLNESS_POOL_CAPITALIZED: "Wellness Pool",
  FLEXIBLE_DOLLARS_WITH_DOLLARS: "flexible dollars",
  WELLNESS_POOL_WITH_DOLLARS: "wellness pool dollars",
  FLEXIBLE_DOLLARS_USED: "Flexible Dollars Used",
  WELLNESS_POOL_USED: "Wellness Pool Used",
  FLEXIBLE_DOLLARS_USED_WITH_TERM: "{flexDollarTerm} Used",
  YOUR_PAYROLL_DEDUCTION: "Your Payroll Deduction",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP_ALTTEXT: "Your Payroll Deduction tool-tip",
  YOU_PAY_ANNUALLY: "You Pay Annually",
  YOU_PAY_ANNUALLY_TOOLTIP_ALTTEXT: "You Pay Annually tool-tip",
  YOUR_FLEX_DOLLARS_USED: "Your {flexDollarTerm} used",
  VIEW_BREAKDOWN: "View Breakdown",
  TOTAL_FLEXIBLE_DOLLARS: "Total {flexType}: ",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP: "This is what you can expect to come out of your paycheck on a {payGroup} basis.",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP_NOTE: "Note: This amount is subject to change if you have benefits you still need to be approved for.",
  YOU_PAY_ANNUALLY_TOOLTIP: "This is your personal annual cost for your benefits for the full policy period.",
  FLEXIBLE_DOLLARS_USED_TOOLTIP: "This is how many {flexDollarTerm} you have used towards your benefits.",
  FLEX_DOLLAR_TOAST: "FLEXIBLE DOLLARS: {amount}",
  FLEX_DOLLAR_ELIGIBLE: "You can use your {flexDollarTerm} to pay for this benefit!",
  WELLNESS_POOL_TOAST: "WELLNESS POOL: {amount}",
  PAYROLL_DEDUCTION_TOAST: "PAYROLL DEDUCTION: {amount}",
  EMPLOYER_COST_TABLE_INFO: "Your employer contributes <strong>{amount}</strong> / {periodType} for this plan",
  EMPLOYER_COST_BANNER_HEADER: "Your employer is invested in you and your health",
  EMPLOYER_COST_BANNER_NO_FLEX_DOLLAR: "We\u2019ve added it all up! Your employer has contributed a total of <strong>{amount}</strong> towards your health and well-being for the year.",
  EMPLOYER_COST_BANNER_WITH_FLEX_DOLLAR: "We\u2019ve added it all up! Your employer has contributed a total of <strong>{amount}</strong> towards your health and well-being for the year. This includes your {flexDollarTerm} allocation and all benefit contributions.",
  YOUR_BALANCE_UPDATED_AMOUNT: "Your balance updated! {amount}",
  YOU_PAY: "You Pay",
  WEEKLY: "Weekly",
  WEEKLY_PAYROLL_DEDUCTION: "Weekly Payroll Deduction",
  BI_WEEKLY_PAYROLL_DEDUCTION: "Bi-Weekly Payroll Deduction",
  SEMI_MONTHLY_PAYROLL_DEDUCTION: "Semi-Monthly Payroll Deduction",
  MONTHLY_PAYROLL_DEDUCTION: "Monthly Payroll Deduction",
  REDUCTION_IN_MONTHLY_DRAW: "Reduction in Monthly Draw of",
  FLEX_JOIN_TEXT: " and/or ",
  FLEX_BETWEEN_TEXT: " between ",
  DRUG: "Drug",
  DRUG_INSURANCE: "Drug Insurance",
  DID_YOU_KNOW: "Did you know?",
  MEDICAL: "Medical",
  MEDICAL_INSURANCE: "Medical Insurance",
  DENTAL: "Dental",
  DENTAL_INSURANCE: "Dental Insurance",
  VISION: "Vision",
  VISION_INSURANCE: "Vision Insurance",
  HOSPITAL_INDEMNITY: "Hospital Indemnity",
  LIFE: "Life",
  LIFE_INSURANCE: "Life Insurance",
  LIFE_AND_ADD: "Life and AD&D",
  LIFE_AND_ADD_INSURANCE: "Life and Accidental Death and Dismemberment",
  ACCIDENT: "Accident",
  ACCIDENT_INSURANCE: "Accident Insurance",
  ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE: "Accidental Death and Dismemberment",
  AD_AND_D: "AD&D",
  STD: "STD",
  SHORT_TERM_DISABILITY: "Short-Term Disability",
  LTD: "LTD",
  LONG_TERM_DISABILITY: "Long-Term Disability",
  GROUP_UMBRELLA_LIABILITY_INSURANCE: "Group Umbrella Liability Insurance",
  GROUP_UMBRELLA_LIABILITY: "Group Umbrella Liability",
  SPENDING_ACCOUNTS: "Spending Accounts",
  FINANCIAL_CONTRIBUTIONS: "Financial Contributions",
  EAP: "EAP",
  SAVE_PROFILE: "Save Profile",
  ALL_DONE: "All done",
  ACCOUNT_TYPE_DRUG: "drug",
  ACCOUNT_TYPE_MEDICAL: "medical",
  ACCOUNT_TYPE_DENTAL: "dental",
  ACCOUNT_TYPE_LIFE: "life",
  ACCOUNT_TYPE_ACCIDENT: "accident",
  ACCOUNT_TYPE_STD: "std",
  ACCOUNT_TYPE_LTD: "ltd",
  ACCOUNT_TYPE_SPENDING_ACCOUNT: "spending account",
  ACCOUNT_TYPE_FINANCIAL_CONTRIBUTION: "financial contribution",
  LIFE_INSURANCE_TITLE: "Life Insurance",
  LIFE_AND_ADD_INSURANCE_TITLE: "Life and Accidental Death and Dismemberment",
  ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE_TITLE: "Accidental Death and Dismemberment",
  CANT_OPT_OUT_TITLE: "This is a required benefit",
  CANT_OPT_OUT_INFO: "<strong>{employerName}</strong> requires you to enroll in this benefit as part of your plan offering.",
  CANT_OPT_OUT_INFO_EMPLOYER_PAID: "As a valued employee, <strong>{employerName}</strong> is providing this benefit to you at no cost.",
  OPTIONAL_LIFE: "Optional Life Insurance",
  YOUR_SALARY: "Your Salary",
  SPOUSE_OPTIONAL_LIFE: "Spouse Optional Life",
  ACCIDENT_INSURANCE_TITLE: "Accident Insurance",
  VOLUME_OPTIONS_EOI_GROUP_LABEL: "All coverage requires an Evidence of Insurability form.",
  VOLUME_OPTIONS_EOI_GROUP_LABEL_WITH_NEM: "Coverage above {noEvidenceMaximum} requires an Evidence of Insurability form.",
  VOLUME_PLAN_BASE_AND_OPTIONAL_HEADING: "Your {benefitName} Insurance Coverage",
  VOLUME_PLAN_BASE_AND_OPTIONAL_TOOLTIP: "<p>Your beneficiary is the person or entity who you choose to receive payment from your carrier in the unfortunate event you pass away.</p><p>It\u2019s important to designate your beneficiaries so your money goes to the right person or people upon your death. Without beneficiaries, your money can get held up and be subject to expensive and unnecessary fees.</p><p>We\u2019ll show you how to designate a beneficiary, if you haven\u2019t already, after enrollment.</p>",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "Life Insurance is an important source of financial protection for your family.",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_PT2: "This benefit provides a level of financial security in the event that you or an eligible family member passes away. Review your plan details for more information.",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_WP: "If after making your drug, medical and dental selections, you have remaining WP$, these will be automatically applied to the cost of basic life insurance. Wellness pool dollars used to cover the cost of basic life insurance are a taxable benefit. This is not applicable for Optional Life benefits.",
  VOLUME_PLAN_LIFE_BASE_SELECTOR_TOOLTIP: "In the unfortunate event that you pass away, your beneficiary will receive a one-time tax free payment equal to the coverage amount specified below",
  VOLUME_PLAN_OPTIONAL_SELECTOR_HEADING: "{benefitName} Insurance",
  VOLUME_PLAN_LIFE_OPTIONAL_SELECTOR_TOOLTIP: "{benefitName} Insurance supplements your mandatory basic life coverage to reduce the potentially devastating impact that the loss of your income could have on your family.\n\n{benefitName} Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until you are approved for the additional coverage.\n\nIn the unfortunate event that you pass away, your beneficiary will receive a one-time tax free payment equal to the coverage amount specified below",
  VOLUME_PLAN_LIFE_OPTIONAL_SELECTOR_TOOLTIP_US: "In the event you pass away, your beneficiary(ies) will receive a one-time cash benefit equal to the amount you elect below.",
  VOLUME_PLAN_SPOUSE_SELECTOR_HEADING: "Choose the {benefitName} Insurance coverage that\u2019s right for your Spouse or Partner",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "Spousal Life Insurance provides coverage for you in the unfortunate event that your spouse or partner passes away.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "Spousal Life Insurance provides coverage for you in the unfortunate event that your spouse or partner passes away. Need to add your spouse? Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_SUBHEADING: "Select your Spouse's life insurance coverage amount",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_TOOLTIP: "In the event your spouse or partner passes away, you will receive a tax free lump sum amount equal to the amount you select below. You are the beneficiary in the event of a Spousal Life claim.\n\nSpousal Life Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until your spouse or partner is approved for the additional coverage.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_TOOLTIP_US: "In the event your insured spouse or domestic partner passes away, the beneficiary(ies) will receive a one-time cash benefit equal to the amount you elect below.",
  VOLUME_PLAN_CHILD_SELECTOR_HEADING: "Choose the {benefitName} Insurance coverage that\u2019s right for your Child",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_SUBHEADING: "Select your Child's life insurance coverage amount",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "Child Life Insurance provides coverage for you in the unfortunate event that your child(ren) pass away. Selecting Child Life insurance will ensure coverage for all your eligible children.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "Child Life Insurance provides coverage for you in the unfortunate event that your child(ren) pass away. Selecting Child Life insurance will ensure coverage for all your eligible children. Need to add your children?  Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP: "In the event your child passes away, you will receive a tax free lump sum amount equal to the amount you select below. You are the beneficiary in the event of a Child Life claim.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP_US: "In the event your insured child (as defined by the policy) passes away, the beneficiary(ies) will receive a one-time cash benefit equal to the amount you elect below.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP_EOI_REQUIRED: "In the event your child passes away, you will receive a tax free lump sum amount equal to the amount you select below. You are the beneficiary in the event of a Child Life claim.\n\nChild Life Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until your eligible child is approved for the additional coverage.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_AND_OPTIONAL_HEADING: "Your AD&D Insurance Coverage",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_HEADING: "Your AD&D Insurance",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "A serious injury or death is devastating, and all the unexpected costs that may arise can cause even more stress. AD&D Insurance is an important source of financial protection for you and your loved ones if you are seriously injured in an accident or pass away.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_TOOLTIP: "You or your beneficiary(ies) will receive a sum of money in the event you are seriously injured or pass away.<br/><br/>The amount received will be based on the amount of coverage you select, and the percentage is dependent on your type of covered accident or injury.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_OPTIONAL_HEADING: "Your Voluntary AD&D Insurance",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HEADING: "Spouse Voluntary AD&D Insurance",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_SUBHEADING: "Choose the AD&D coverage that\u2019s right for your spouse or partner",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HAS_SPOUSE_DESCRIPTION: "Spousal AD&D insurance provides coverage for you in the event that your spouse or partner suffers a serious injury or passes away.",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_NO_SPOUSE_DESCRIPTION: "Spousal AD&D insurance provides coverage for you in the event that your spouse or partner suffers a serious injury or passes away. Need to add your spouse? Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HEADING: "Child Voluntary AD&D Insurance",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_SUBHEADING: "Choose the AD&D coverage that\u2019s right for your child",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HAS_CHILD_DESCRIPTION: "Child AD&D insurance provides coverage for you in the event that your child suffers a serious injury or passes away",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_NO_CHILD_DESCRIPTION: "Child AD&D insurance provides coverage for you in the event that your child suffers a serious injury or passes away. Need to add your children?  Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_TOOLTIP_EOI_REQUIRED: "Child AD&D insurance provides coverage for you in the event that your child suffers a serious injury or passes away.<br/><br/>Child AD&D Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until your eligible child is approved for the additional coverage.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_HEADING: "Your {benefitName} Coverage",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "Life and AD&D Insurance is an important source of financial protection for you and your beneficiary(ies).",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_DESCRIPTION_PT2: "This benefit provides a level of financial security in the event that you pass away or suffer an accidental death or dismemberment. Review your plan details for more information.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_SELECTOR_TOOLTIP: "Basic Life and Accidental Death & Dismemberment (AD&D) insurance is provided at no cost to you. Your beneficiary(ies) will receive a sum of money in the event of your death or accidental death or dismemberment.",
  VOLUME_PLAN_LIFE_AND_ADD_OPTIONAL_SELECTOR_TOOLTIP: "{benefitName} Insurance supplements your mandatory basic life coverage to reduce the potentially devastating impact that the loss of your income could have on your family.\n\n{benefitName} Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until you are approved for the additional coverage.\n\nIn the unfortunate event that you pass away, your beneficiary will receive a one-time tax free payment equal to the coverage amount specified below",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "Spousal Life and AD&D Insurance provides coverage for you in the unfortunate event that your spouse or partner passes away.",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "Spousal Life and AD&D Insurance provides coverage for you in the unfortunate event that your spouse or partner passes away. Need to add your spouse? Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_SUBHEADING: "Select your Spouse's Life and AD&D insurance coverage amount",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_TOOLTIP: "In the event your spouse or partner passes away, you will receive a tax free lump sum amount equal to the amount you select below. You are the beneficiary in the event of a Spousal Life and AD&D claim.\n\nSpousal Life and AD&D Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until your spouse or partner is approved for the additional coverage.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_SUBHEADING: "Select your Child's Life and AD&D insurance coverage amount",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "Child Life and AD&D Insurance provides coverage for you in the unfortunate event that your child(ren) pass away. Selecting Child Life and AD&D insurance will ensure coverage for all your eligible children.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "Child Life and AD&D Insurance provides coverage for you in the unfortunate event that your child(ren) pass away. Selecting Child Life and AD&D insurance will ensure coverage for all your eligible children. Need to add your children?  Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_TOOLTIP: "In the event your child passes away, you will receive a tax free lump sum amount equal to the amount you select below. You are the beneficiary in the event of a Child Life and AD&D claim.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_TOOLTIP_EOI_REQUIRED: "In the event your child passes away, you will receive a tax free lump sum amount equal to the amount you select below. You are the beneficiary in the event of a Child Life and AD&D claim.\n\nChild Life and AD&D Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until your eligible child is approved for the additional coverage.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION: "Accident Insurance is an important source of financial protection for you and your loved ones if you are seriously injured in an accident or pass away. The extent of your coverage depends on the amount elected and the severity and nature of the accident.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION_US_PT1: "Accident Insurance can pay a set benefit amount based on the type of injury you have and the type of treatment you need.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION_US_PT2: "It covers accidents that occur on and off the job. And it includes a range of incidents, from common injuries to more serious events. Review your plan details for more information.<br/><br/>By electing coverage, you are confirming:<ul><li>You have reviewed the attached legal and compliance documentation (in the plan details)</li><li>You are employed and actively working</li><li>If electing spousal coverage, you are confirming your spouse or domestic partner currently lives in the U.S</li></ul>",
  VOLUME_PLAN_ACCIDENT_BASE_SELECTOR_TOOLTIP: "If you suffer a qualifying injury in an accident, you will be paid a benefit. If you die in an accident, your beneficiary will receive the benefit. Your benefit amount depends on the amount of coverage and the extent of the accidental loss.",
  VOLUME_PLAN_ACCIDENT_BASE_SELECTOR_TOOLTIP_US: "Accident Insurance helps you pay for the medical and out-of-pocket costs that you may incur after an accidental injury. This includes emergency treatment, hospital stays, and medical exams, and other expenses you may face, such as transportation and lodging needs.",
  VOLUME_PLAN_ACCIDENT_OPTIONAL_TOOLTIP: "Optional Accident Insurance supplements your accident coverage to reduce the potentially devastating impact that the loss of your income could have on your family.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_SUBHEADING: "Select your Spouse's accident insurance coverage amount",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "Spousal Accident Insurance provides coverage for you in the event your spouse suffers an accident or injury.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "Spousal Accident Insurance provides coverage for you in the event your spouse suffers an accident or injury. Need to add your spouse? Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_TOOLTIP: "If your spouse suffers a qualifying injury, Accident Insurance pays you a benefit based on the selected coverage amount and the nature of the injury.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_SUBHEADING: "Select your Child's Accident Insurance coverage amount",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "Child Accident Insurance provides coverage for you in the event your child suffers an accident or injury.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "Child Accident Insurance provides coverage for you in the event your child suffers an accident or injury. Need to add your children? Head to the {dependentsLink} to make this selection.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_TOOLTIP: "If your child suffers a qualifying injury, Accident Insurance pays you a benefit based on the selected coverage amount and the nature of the injury.",
  BENEFICIARIES_INFO_BANNER_CARD: "When you enroll in certain benefits, your dependent(s) won\u2019t be fully protected if you don\u2019t {designateBeneficiariesTooltip}. You can add or update your beneficiaries after you complete enrollment.",
  DESIGNATE_BENEFICIARIES: "designate beneficiaries",
  LEARN_MORE_ABOUT_BENEFICIARIES: "Learn more about beneficiaries",
  BENEFICIARIES_INFO_BANNER_CARD_TOOLTIP: "<p>A beneficiary is a person or entity who you choose to receive payment from your carrier in the unfortunate event you pass away.</p><p>It\u2019s important to designate your beneficiaries so your money goes to the right person or people upon your death. Without beneficiaries, your money can get held up and be subject to expensive and unnecessary fees.</p><p>We\u2019ll show you how to designate a beneficiary, if you haven\u2019t already, after enrollment.</p>",
  PREVIOUSLY_SELECTED_PLAN_TOOLTIP_CONTENT: "This is the plan you chose during your previous enrollment",
  PROVIDED_BY_VENDOR: "Provided by {vendor}",
  PROVIDED_BY: "Provided by",
  ANNUAL_DEDUCTION: "{deduction} Annually",
  PER_PAY_PERIOD_DEDUCTION: "{deduction} / {periodType}",
  BEST_MATCH: "Best Match",
  DRUG_PLAN_SELECTOR_TITLE: "Drug Benefits",
  DENTAL_PLAN_SELECTOR_TITLE: "Dental Benefits",
  VISION_PLAN_SELECTOR_TITLE: "Vision Benefits",
  MEDICAL_PLAN_SELECTOR_TITLE: "Medical Benefits",
  STD_PLAN_SELECTOR_TITLE: "Short-Term Disability",
  STD_PLAN_SELECTOR_DESCRIPTION: "You're customizing your long-term disability benefits package",
  LTD_PLAN_SELECTOR_TITLE: "Long-Term Disability",
  LTD_PLAN_SELECTOR_DESCRIPTION: "You're customizing your long-term disability benefits package",
  CLASSES_EXPLANATION: "Classes are used to segment employees into different categories",
  CLASSES_EXAMPLE: "Full-Time, Part-Time and Partner",
  PLANS_EXPLANATION: "Plans are used to separate out different sets of benefits",
  PLANS_EXAMPLE: "Gold, Silver, Bronze",
  PLAN_SELECTOR_HEADING: "Your {benefitName} Coverage",
  PLAN_SELECTOR_DESCRIPTION_STD: "Short-Term Disability (STD) Insurance makes sure that you are protected from loss of income in the case you are temporarily unable to work due to illness, injury, or accident.",
  PLAN_SELECTOR_DESCRIPTION_LTD: "Long-Term Disability (LTD) Insurance makes sure that you are protected from loss of income in the case you are unable to work for an extended period of time due to illness, injury, or accident. Based on your circumstance and eligibility, the Long-Term Disability benefit begins to cover you once your elimination period ends.",
  PLAN_SELECTOR_DESCRIPTION: "{benefitName} Insurance makes sure that your paycheque is protected in the case of you being unable to work due to a {benefitNameLowered}.",
  INSURABLE_EARNINGS_SUBTITLE: "Your Annual Earnings",
  INSURABLE_EARNINGS_AMOUNT: "Annual Earnings",
  INSURABLE_EARNINGS_TOOLTIP: "The amount of money you receive while on {benefitName} will be based off your insurable earnings.",
  INSURABLE_EARNINGS_TOOLTIP_STD: "The amount of money you receive while on short-term disability to protect your loss of income is calculated as a percentage of your weekly earnings.",
  INSURABLE_EARNINGS_TOOLTIP_LTD: "The amount of money you receive while on long-term disability to protect your loss of income is calculated as a percentage of your monthly earnings.",
  PLAN_COST: "Plan Cost",
  VOLUME_DOCUMENT_LINK_LABEL: "{benefitName} Details",
  VOLUME_INELIGIBLE_DEFAULT: "You are not eligible for this benefit: {reason}",
  VOLUME_PREREQUISITE_NOT_MET: "{benefitName} Insurance is only available if you are enrolled in {prerequisiteName}",
  BENEFIT_PRICE_ERROR: "Sorry! Something went wrong on our end with getting the price for this benefit. Please reach out to our benefit concierge team to get this resolved.",
  PLAN_DETAILS: "Plan Details",
  FIND_IN_NETWORK_PROVIDERS: "Find In-Network Providers",
  PLAN_BOOKLET: "Plan Booklet",
  LEARN_MORE_ABOUT_FIND_IN_NETWORK_PROVIDERS: "Learn more about how to find in-network providers",
  PLAN_NAME_DETAILS: "{planName} Details",
  LEARN_MORE_ABOUT_PLAN_DETAILS: "Learn more about {planName} details",
  COVERAGE_TYPE: "Coverage Type",
  COVERAGE_AMOUNT: "Coverage Amount",
  CRITICAL_ILLNESS_INSURANCE_TITLE: "Critical Illness Insurance",
  CRITICAL_ILLNESS: "Critical Illness",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_SELECTOR_TOOLTIP: "If you are diagnosed with a covered critical illness, you will be paid a one-time, lump-sum payment equal to the coverage amount you specify below.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_SELECTOR_TOOLTIP_US: "The money can help you pay out-of-pocket medical expenses, like co-pays and deductibles. You can use this coverage more than once. Even after you receive a payout for one illness, you\u2019re still covered for the remaining conditions.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION: "Critical Illness Insurance is an important source of financial protection in the event you or your covered dependents are diagnosed with a covered critical condition, e.g.  Alzheimer's, Cancer, Parkinson's, among others.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION_US_PT1: "Critical Illness insurance can provide a lump sum of money in the event of a covered illness or condition. You can use the money however you want.<br/><br/>",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION_US_PT2: "It\u2019s important for you to know that Critical Illness insurance is:<ul><li>Available to all employees in active employment in the United States working at least 20 hours per week and their eligible spouse and children (up to age 26 regardless of student or marital status)</li><li>A supplement to health insurance - It is not a substitute for essential health benefits or minimum essential coverage as defined in federal law. If you, and each person for whom coverage is selected, do not have essential health benefits or minimum essential coverage you are not eligible for this coverage.</li></ul><br/>Review the plan details attached below for more information including the Medicare Notice and Guide.",
  VOLUME_PLAN_CRITICAL_ILLNESS_OPTIONAL_TOOLTIP: "If you are diagnosed with a covered critical illness, you will be paid a one-time, lump-sum payment equal to the coverage amount you specify below.\n\nCritical Illness requires you to provide evidence of insurability. This will start an underwriting process with {vendorName}. Please note that no charges apply for this benefit until you are approved for the additional coverage. ",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "Spousal Critical Illness Insurance provides financial coverage for you in the event your spouse or partner is diagnosed with a covered critical condition. Need to add your spouse or partner? Head to the {dependentsLink} to make this selection",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "Spousal Critical Illness Insurance provides financial coverage for you in the event your spouse or partner is diagnosed with a covered critical condition.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_SUBHEADING: "Select your Spouse's critical illness insurance coverage amount",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_TOOLTIP: "If your spouse or partner is diagnosed with a covered critical illness, you will receive a tax free lump sum amount equal to the amount you select below.\n\nSpousal Critical Illness Insurance requires you to provide evidence of insurability. This will start an approval and underwriting process with {vendorName}. Please note that no charges apply for this benefit until your spouse or partner is approved for the additional coverage.",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "Child Critical Illness Insurance provides financial coverage for you in the event your child is diagnosed with a covered critical condition. Need to add your children? Head to the {dependentsLink} to make this selection",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "Child Critical Illness Insurance provides financial coverage for you in the event your child is diagnosed with a covered critical condition.",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_SUBHEADING: "Select your Child's critical illness insurance coverage amount",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_TOOLTIP: "If your child is diagnosed with a covered critical illness, you will receive a tax free lump sum amount equal to the amount you select below.",
  TOTAL: "Total",
  TOTAL_COVERAGE_COST: "Total Coverage Cost",
  TOTAL_OUT_OF_POCKET: "Total Annual Out-Of-Pocket Cost ",
  ADDITIONAL_BENEFITS_CARD_TITLE: "Wait, there's more!",
  VIEW_ADDITIONAL_BENEFITS: "View Additional Benefits",
  ADDITIONAL_BENEFITS: "Additional Benefits",
  ENROLLMENT_STEP: "Step",
  COVERAGE_PLAN_SELECTION: "Plan Selection",
  ANNUAL_COST_PRICE: "Your Annual Plan Cost <strong>{price}</strong>",
  COST_PER_PAY_PERIOD: "<strong>{price}</strong> / {periodType}",
  PRICE_PERIOD_PREMIUM: "{periodType} Premium",
  YEARLY_PREMIUM: "Yearly Premium",
  PRICE_PERIOD_PREMIUM_TOOLTIP_CONTENT: "To keep your plan active, you need to pay this {periodType} fee. This does not contribute to any deductible(s) or out-of-pocket maximum(s).",
  TRACKER_FLEX_DOLLAR_TOOLTIP: "Flexible Dollars is money {company} has provided for you to \u2018spend\u2019. You\u2019ll notice this amount decrease as you go through the enrollment steps.",
  TRACKER_WELLNESS_POOL_TOOLTIP: "The Wellness Pool is extra money {company} has provided for you to \u2018spend\u2019. You\u2019ll notice this amount decrease as you go through the enrollment steps.",
  TRACKER_YOU_PAY_TOOLTIP_CONTENT: "This is the running total of the annual cost of all the benefits in your benefits package. You'll notice this amount increase as you go through the enrollment steps.",
  TRACKER_YOU_PAY_TOOLTIP_CONTENT_NOTE: "Note: You may already see a balance if some benefits are mandatory or pre-selected for you by {company}!",
  WAIVED: "Waived",
  YOUR_ALLOCATION: "Your Allocation",
  NO_SELECTION_MADE: "No Selection Made",
  EMPLOYEE_ASSISTANCE_PROGRAM: "Employee Assistance Program",
  LEGAL_FIRST_NAME: "Legal First Name",
  LEGAL_FIRST_NAME_SENTENCE_CASE: "Legal first name",
  LEGAL_LAST_NAME: "Legal last name",
  MIDDLE_NAME_OPTIONAL: "Middle Name (Optional)",
  LAST_NAME: "Last Name",
  PREFERRED_FIRST_NAME: "Preferred First Name",
  WHAT_SHOULD_WE_CALL_YOU: "What should we call you?",
  WHAT_SHOULD_WE_CALL_YOU_SENTENCE_CASE: "What should we call you? (optional)",
  PREFERRED_NAME_PLACEHOLDER: "If different from your legal first name",
  PREFERRED_NAME_TOOLTIP: "Have a different name you use? We'll be sure to be mindful of this and use it as often as we can.",
  MIDDLE_INITIAL: "Middle Name or Initial",
  DATE_OF_BIRTH: "Date of Birth",
  SEX: "Sex",
  SEX_TOOLTIP: "The insurance carriers we partner with require your sex to administer your benefits.<br/><br/>Our team is committed to ensuring all of our members feel safe and respected. If you would like to tell us more about how you identify, use the form fields below to add your Gender Identity and Pronouns.",
  GENDER_IDENTITY: "Gender Identity",
  GENDER_IDENTITY_OPTIONAL: "Gender Identity (Optional)",
  GENDER_IDENTITY_TOOLTIP: "League supports gender expression and would like to know how you identify.<br/><br/>Gender identity is the personal sense of your own gender. It can correlate with your sex, or it can differ from it.",
  PRONOUNS: "Pronouns",
  PRONOUNS_OPTIONAL: "Pronouns (Optional)",
  PRONOUNS_TOOLTIP: "Add your pronouns so we know how to refer to you!<br /><br />For example: If Alex\u2019s pronouns are they/them, we could say \u201CAlex recently enrolled in a mental health program, and they\u2019ve noticed a boost in their mental well-being.\u201D",
  LEAGUE_SUPPORTS_GENDER_EXPRESSION: "League supports gender expression and would like to know how you identify",
  INFORMATION_NOT_SHARED_WITH_EMPLOYER: "This information will not be shared with your employer and/or carrier(s).",
  THIS_INFO_WILL_NOT_BE_SHARED: "This information is private and will not be shared.",
  PROFILE_UPDATED: "Profile Updated",
  PROFILE_UPDATE_FAILED: "Profile Update Failed",
  ARE_YOU_SURE_DISCARD_CHANGES: "Are you sure you want to discard these changes?",
  DISCARD: "Discard",
  ENTER_VERIFICATION_CODE: "Enter Verification Code",
  SENT_TO_YOUR_MOBILE: "A verification code has been sent to your mobile phone number.",
  VERIFICATION_CODE: "Verification code",
  ENTER_YOUR_VERIFICATION_CODE: "Enter your verification code",
  SEND_AGAIN: "Send again",
  CALL_ME_INSTEAD: "Call me instead",
  EDIT_MOBILE_PHONE: "Edit Mobile Phone Number",
  EDIT_MOBILE_PHONE_FAILED: "Edit Mobile Phone Number failed",
  WELL_TEXT_YOU: "We\u2019ll text you a verification code",
  SEND_VERIFICATION_CODE: "Send verification code",
  VERIFICATION_CODE_FAILED: "Verification code failed",
  VERIFICATION_CODE_SENT: "Verification code sent",
  VERIFICATION_CALL_SENT: "Verification call sent",
  PHONE_NUMBER_SAME: "The phone numbers are the same, please try again",
  CHANGE_PASSWORD: "Change Password",
  CHOOSE_A_STRONG_PASSWORD: "Choose a strong password and don't reuse it for other accounts.",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  CONFIRM_NEW_PASSWORD: "Confirm new password",
  CHANGE_PASSWORD_ERROR: "Change password failed",
  PASSWORD_UPDATED: "Password updated",
  UPDATE_PHOTO: "Update Photo",
  DRAG_AND_DROP_PHOTO: "Drag and drop a photo",
  UPLOAD_PHOTO: "Upload photo",
  PROFILE_PHOTO: "Profile photo",
  SELECT_PROFILE_PHOTO: "Select Profile Photo",
  SET_UP_PROFILE_PHOTO: "Set up your profile photo",
  UPDATE_PROFILE_PHOTO: "Update your profile photo",
  PROFILE_PHOTO_DESCRIPTION: "This photo shows up on your user profile",
  SELECT_AVATAR: "Select Avatar",
  SELECT_AVATAR_ERROR: "You must choose an avatar before continuing.",
  GET_AVATARS_ERROR: "Oops! There was an issue loading the avatars. Please go back and select a different option.",
  SELECT_PROFILE_PICTURE_ERROR: "You must choose a profile picture before continuing.",
  UPLOAD_MY_OWN: "Upload My Own",
  NO_PHONE_NUMBER_FOUND: "No phone number found",
  CHANGING_YOUR_EMAIL_ADDRESS_WILL_ALSO: "Changing your email address will also change your sign-in credentials.",
  GET_DEPENDENTS_ERROR: "Fetching dependents failed",
  THIS_PHOTO_SHOWS_UP: "This photo shows up on your user profile and Health Profile.",
  CHANGE_PHOTO: "Change photo",
  YOU_HAVE_UNSAVED: "You have unsaved changes",
  WE_NEED_TO_MAKE_SURE_ONLY_YOU: "We need it to make sure only you have access to your account. We won\u2019t share your number with anyone.",
  LOOKS_LIKE_CHANGES: "Looks like you have some unsaved changes. If you leave this page, these changes will be lost.",
  PERSONAL_INFO: "Personal Info",
  ACCOUNT_DETAILS: "Account Details",
  MOBILE_PHONE_NUMBER: "Mobile phone number",
  MOBILE_PHONE_NUMBER_UPDATED: "Mobile phone number updated",
  COMMUNICATION_PREFERENCES: "Communication preferences",
  PASSWORD: "Password",
  NO_DEPENDENTS: "No dependents",
  YOU_CURRENTLY_HAVE_NO_DEPENDENTS: "You currently have no dependents associated with this account.",
  SIN_OR_SSN: "SIN or SSN Number",
  SIN: "SIN",
  SOCIAL_INSURANCE_NUMBER_OPTIONAL: "Social Insurance Number (Optional)",
  SSN: "Social Security Number",
  SOCIAL_SECURITY_NUMBER_OPTIONAL: "Social Security Number (Optional)",
  NO_DEPENDENT_SSN: "My {dependentType} doesn't have an SSN",
  LANGUAGE_PREFERENCE: "Language Preference",
  DO_YOU_USE_TOBACCO: "Do you use tobacco?",
  DOES_YOUR_PARTNER_USE_TOBACCO: "Does your partner use tobacco?",
  ARE_YOU_REGISTERED_INDIAN_ACT: "Do you have First Nations status?",
  STREET_ADDRESS: "Street Address",
  STREET_ADDRESS_SENTENCE_CASE: "Street address",
  SUITE_NUMBER: "Suite or Apartment Number",
  SUITE_NUMBER_SENTENCE_CASE: "Suite or apartment number (optional)",
  ADDRESS_LINE_1: "Address Line 1",
  ADDRESS_LINE_2: "Address Line 2",
  RELATIONSHIP_TO_YOU: "Relationship to you",
  RELATIONSHIP_TYPE: "Relationship Type",
  RELATIONSHIP_EFFECTIVE_DATE: "Relationship Effective Date",
  IS_DEPENDENT_FULL_TIME_STUDENT: "Is your dependent a full-time student?",
  IS_DEPENDENT_DISABLED: "Is your dependent disabled?",
  WAIVE: "Waive",
  WELLNESS_ACCOUNTS: "Wellness Accounts",
  PROFILE_DEPENDENTS_SECTION_TITLE: "Let's get to know your loved ones!",
  ALBERTA: "Alberta",
  BRITISH_COLUMBIA: "British Columbia",
  MANITOBA: "Manitoba",
  NEW_BRUNSWICK: "New Brunswick",
  NEWFOUNDLAND_LABRADOR: "Newfoundland and Labrador",
  NOVA_SCOTIA: "Nova Scotia",
  ONTARIO: "Ontario",
  PRINCE_EDWARD_ISLAND: "Prince Edward Island",
  QUEBEC: "Quebec",
  SASKATCHEWAN: "Saskatchewan",
  NORTHWEST_TERRITORIES: "Northwest Territories",
  NUNAVUT: "Nunavut",
  YUKON: "Yukon",
  ALABAMA: "Alabama",
  ALASKA: "Alaska",
  AMERICAN_SAMOA: "American Samoa",
  ARIZONA: "Arizona",
  ARKANSAS: "Arkansas",
  CALIFORNIA: "California",
  COLORADO: "Colorado",
  CONNECTICUT: "Connecticut",
  DELAWARE: "Delaware",
  DISTRICT_OF_COLUMBIA: "District of Columbia",
  FLORIDA: "Florida",
  GEORGIA: "Georgia",
  GUAM: "Guam",
  HAWAII: "Hawaii",
  IDAHO: "Idaho",
  ILLINOIS: "Illinois",
  INDIANA: "Indiana",
  IOWA: "Iowa",
  KANSAS: "Kansas",
  KENTUCKY: "Kentucky",
  LOUISIANA: "Louisiana",
  MAINE: "Maine",
  MARYLAND: "Maryland",
  MASSACHUSETTS: "Massachusetts",
  MICHIGAN: "Michigan",
  MINNESOTA: "Minnesota",
  MISSISSIPPI: "Mississippi",
  MISSOURI: "Missouri",
  MONTANA: "Montana",
  NEBRASKA: "Nebraska",
  NEVADA: "Nevada",
  NEW_HAMPSHIRE: "New Hampshire",
  NEW_JERSEY: "New Jersey",
  NEW_MEXICO: "New Mexico",
  NEW_YORK: "New York",
  NORTH_CAROLINA: "North Carolina",
  NORTH_DAKOTA: "North Dakota",
  OHIO: "Ohio",
  OKLAHOMA: "Oklahoma",
  OREGON: "Oregon",
  PENNSYLVANIA: "Pennsylvania",
  PUERTO_RICO: "Puerto Rico",
  RHODE_ISLAND: "Rhode Island",
  SOUTH_CAROLINA: "South Carolina",
  SOUTH_DAKOTA: "South Dakota",
  TENNESSEE: "Tennessee",
  TEXAS: "Texas",
  US_VIRGIN_ISLANDS: "US Virgin Islands",
  UTAH: "Utah",
  VERMONT: "Vermont",
  VIRGINIA: "Virginia",
  WASHINGTON: "Washington",
  WEST_VIRGINIA: "West Virginia",
  WISCONSIN: "Wisconsin",
  WYOMING: "Wyoming",
  FEDERATED_STATES_OF_MICRONESIA: "Federated States of Micronesia",
  MARSHALL_ISLANDS: "Marshall Islands",
  NORTHERN_MARIANA_ISLANDS: "Northern Mariana Islands",
  ENGLISH: "English",
  FRENCH: "French",
  SPANISH: "Spanish",
  LANGUAGE_SELECTOR_ENGLISH: "English",
  LANGUAGE_SELECTOR_FRENCH: "Fran\xE7ais",
  LANGUAGE_SELECTOR_SPANISH: "Espa\xF1ol",
  MALE: "Male",
  FEMALE: "Female",
  MARRIED: "Married",
  SINGLE: "Single",
  DOMESTIC_PARTNERSHIP: "Domestic Partnership",
  YES: "Yes",
  NO: "No",
  WHY_DO_YOU_ASK: "Why do you ask?",
  PROFILE_INDIAN_ACT_TOOLTIP: "Your insurance carrier needs to know if you\u2019re registered under the Canadian Indian Act so they can calculate your taxes.",
  PROFILE_DOB_TOOLTIP: "We are asking so we can provide this necessary information to your insurance provider. Sometimes, pricing or eligibility for some insurance products and services can be based on age.",
  PROFILE_LOCALE_TOOLTIP: "We are asking this so we know what language to send you information in.",
  PROFILE_TOBACCO_USER_TOOLTIP: "We ask because using tobacco will impact the premium costs of your benefits.<br/><br/>To use tobacco means that (within the past 12 months) you have smoked or used cigarettes, electronic cigarettes, hashish, cigars, pipe, cigarillos, chewing tobacco, nicotine patch and/or gum, betel nuts, or tobacco, or nicotine in any other form.",
  PROFILE_SEX_TOOLTIP: "The insurance carriers we partner with require your sex to administer your benefits. <br/><br/>Our team is committed to ensuring that all members feel safe & respected. If you would like to tell us more about how you identify, use the form fields below to add your <strong>Gender Identity and Pronouns.</strong>",
  PROFILE_SSN_TOOLTIP: "We are asking this because the insurance providers we partner with require this information to enroll you in your plan.",
  DEPENDENTS_SSN_TOOLTIP: "We are asking this because the insurance providers we partner with require this information to enroll your dependent in your plan.",
  DEPENDENTS_SEX_TOOLTIP: "The insurance carriers we partner with require your dependent's current sex to administer benefits to them (Note: they do not require any legal transition documents if they are in the process of a transition). This information will not be shared outside of your insurance carrier. Our team is committed to ensuring that all members feel safe & respected.",
  DEPENDENTS_TOBACCO_USER_TOOLTIP: "We are asking this because it\u2019s an important factor in determining life insurance premiums. You can change this status for your dependent at a later date by contacting us if their status changes.",
  INTERNAL_ERROR_TITLE: "Hmm, something went wrong.",
  REMOVING_DEPENDENT: "Removing Dependent",
  REMOVING_DEPENDENT_TEXT: "By removing {name} as a dependent, all of your previous selections will be cleared. You will have to go through each benefit and make your selections again.",
  REMOVING_DEPENDENT_TEXT_CONTINUED: "Are you sure you want to remove {name} as a dependent?",
  DEPENDENTS_PARTNER_RELATIONSHIP_TYPE_TOOLTIP: "We are asking this so we can determine your relationship to your dependent to share with your insurance carrier ",
  DEPENDENTS_PARTNER_EFFECTIVE_DATE_TOOLTIP: "We are asking this so we can determine the length of your relationship for your dependent to share with your insurance carrier",
  DEPENDENTS_CHILD_FULL_TIME_STUDENT_TOOLTIP: "Dependents between the ages of {validChildAge} and {validFullTimeStudentAge} are eligible for benefits coverage if they are a full-time student at the time of enrollment.",
  DEPENDENTS_CHILD_DISABLED_TOOLTIP: "A child is considered disabled if they are incapable of supporting themselves or engaging in any substantially gainful activity. They are considered disabled if they are dependent on you, the Member, for financial support, maintenance and care, within the terms of the Income Tax Act, due to a mental or physical disability.",
  CHILD_OF_DOMESTIC_PARTNER_TOOLTIP: "We are asking this so we can determine your relationship to your dependent to share with your insurance carrier.",
  PLEASE_NOTE_NO_DEPENDENT: "Please note that if you do not have a dependent, you can leave this section empty. These fields are only required if you enter your dependent information. you may save your profile and proceed to the next step regardless.",
  WHO_QUALIFIES_DEPENDENT_TOOLTIP_TITLE: "who qualifies as a dependent?",
  WHO_QUALIFIES_DEPENDENT_TITLE: "Who Qualifies as a Dependent?",
  WHO_QUALIFIES_DEPENDENT_PLAIN: "Who qualifies as a dependent?",
  WHO_QUALIFIES: "Who Qualifies?",
  WHO_QUALIFIES_DEPENDENT_TEXT_CA: "To be eligible to use your League benefits, a dependent is a special someone who meets the following criteria:",
  WHO_QUALIFIES_SPOUSE_TEXT_US: "A <strong>spouse</strong> is your wife or husband to whom you are lawfully married to under Federal law.",
  WHO_QUALIFIES_PARTNER_TEXT_US: "A <strong>domestic partnership</strong> is any-gender, unmarried couple registered with any state or local government department. Your insurance carrier may extend benefits to domestic partners and their child(ren). Benefits provided to domestic partners may be subject to additional or different tax treatment under Federal or state law. Please consult your tax or legal professional to learn more about how Federal and state law will be applied in connection with this election.",
  WHO_QUALIFIES_CHILD_TEXT_US: "A <strong>child</strong> is your biological child, step-child, legally adopted child, foster child, legal ward, or your domestic's partner child who is 25 years of age or younger. If you are legally responsible for another individual and have questions about their eligibility, please contact us at help@league.com and we'll help you determine if they are eligible to enroll.",
  FOR_INSURED_BENEFITS: "For Insured Benefits",
  FOR_INSURED_BENEFITS_1: "Your spouse, common-law partner, child, or child of your spouse or common-law partner",
  FOR_INSURED_BENEFITS_2: "Depends on you for financial support",
  FOR_INSURED_BENEFITS_3: "There may also be age, geographic, or other limitations for dependents. For example, a child can be covered under your insured benefits if they are under the age of {validChildAge}, or under the age of {validFullTimeStudentAge} if they are a full-time student",
  FOR_INSURED_BENEFITS_4: "For full information, please refer to your full coverage booklet for your group benefits with your insurance carrier",
  FOR_INSURED_BENEFITS_5: "For a common-law spouse to be covered, you must have been cohabiting for a minimum of 12 months",
  ADD_DEPENDENT_CTA: "Add Another Dependent",
  INTERNAL_ERROR_PROMPT: "Good thing we save all of your information as soon as you add it!",
  INTERNAL_ERROR_CTA: "Go Back",
  LAST_DAY_OF_ENROLLMENT: "Last day of enrollment period: {lastDay}",
  WALLET_WELCOME: "Welcome to your Wallet!",
  WALLET_DESCRIPTION: "Your wallet is where you'll be able to access your benefits, submit claims, and check your coverage.  You will be able to submit claims for costs incurred during the enrollment period once your wallet becomes active. In the meantime, {healthProgramLink}!",
  HEALTH_PROGRAM_LINK_COPY: "try one of our health programs",
  FINALIZE_SELECTIONS_HEADER_COPY: "Do you want access to your benefits sooner?",
  FINALIZE_SELECTIONS_COPY_1: "By finalizing your selections, we\u2019ll be able to send your plan details to the carrier right away! This means you won't have to wait until the end of your enrollment period to view your wallet.",
  FINALIZE_SELECTIONS_COPY_2: "This also means that you won\u2019t be able to make any changes to your plan after clicking submit.",
  FINALIZE_SELECTIONS_ACKNOWLEDGEMENT: "I understand I won't be able to change my selections",
  FINALIZE_PLAN_SELECTIONS: "Finalize Plan Selections",
  ERROR_FINALIZING_SELECTIONS: "There was an error submitting your selections.",
  ERROR_FINALIZING_SELECTIONS_INVALID_SELECTIONS: "Looks like your selections are incomplete. Please complete enrollment and try again.",
  SELECTIONS_FINALIZED_SUCCESS: "Selections finalized!",
  TOTAL_REWARDS_LABEL: "Coverage Summary",
  TOTAL_REWARDS_DESCRIPTION: "Review the plan selections that you made during your enrollment.",
  TOTAL_REWARDS_LINK: "View Coverage Summary",
  CHANGE_PLAN_LABEL: "Change Plan Selections",
  CHANGE_PLAN_DESCRIPTION: "Want to make changes to your plan or personal information?",
  CHANGE_PLAN_LINK: "Change Plan Selections",
  DISMISS: "Dismiss",
  DISMISSED: "Dismissed",
  DISMISS_TOAST: "Dismiss notification",
  NEXT_STEPS_HEADING: "You\u2019re all set, {name}!",
  NEXT_STEPS_SUBHEADING: "Check out your next steps below and you\u2019ll be on your way to living your healthiest year yet.",
  YOUR_INSURANCE_FORMS: "Your Insurance Forms",
  FORMS_DOWNLOAD_INFO: "The following forms are necessary to ensure that you and your loved ones are protected. If your carrier accepts digital forms, they will also be available here.",
  ERROR_DOWNLOADING_INSURANCE_FORM: "There was an error downloading your form",
  BENEFICIARIES: "Beneficiaries",
  BENEFICIARIES_FORM: "Beneficiaries Form:",
  BENEFICIARY_FORMS: "Beneficiary Forms",
  BENEFICIARIES_FORM_TEXT: "{beneficiariesForm} In order to provide security for your loved ones, League recommends that you always have beneficiaries designated for your insurance policies. This form is made available for download after your enrollment period ends in the {documentsAndFormsLink} section of your League app.",
  BENEFICIARIES_FORM_TEXT_AVAILABLE: "{beneficiariesForm} In order to provide security for your loved ones, League recommends that you always have beneficiaries designated for your insurance policies.",
  EOI_FORM: "Evidence of Insurability Form:",
  EOI_FORMS: " Evidence of Insurability (Required)",
  EOI_FORM_TEXT: "{eoiForm} Because you chose {allSelectedEoiBenefitNames}, you are required to complete an Evidence of Insurability form. This form will be provided to you after your enrollment period ends. Please sign it with ink and then {mailToCarrier} for approval. If your carrier does not receive it, your application will not be complete. {downloadOrWait}",
  DOWNLOAD_NOW: "Download now.",
  EOI_INFO: "Evidence of Insurability is a health questionnaire that provides information on the condition of your health. This form must be completed, then signed with ink and mailed directly to the carrier for approval for {benefitNames} Insurance.",
  WILL_NOTIFY_ONCE_AVAILABLE: "We will notify you once the form is made available.",
  MAIL_TO_CARRIER: "mail it directly to the carrier",
  MUST_BE_INK_SIGNED: "Must be ink signed and then mailed directly to the carrier",
  SUBMITTING_FORMS: "Submitting Forms",
  SUBMITTING_FORMS_EOI: "Evidence of Insurability: This form needs to be submitted {boldText}",
  SUBMITTING_FORMS_BENEFICIARIES: `Beneficiaries: You can download your beneficiary form here. Please download the form and complete and sign either digitally or with wet ink. Note that this is Sun Life's standard form which requests a wet signature, however an e-signature is also permitted by Sun Life.<br /> You can upload the completed form onto "My Documents" on your desktop or by taking a picture and uploading it directly through your League App.`,
  SUBMITTING_FORMS_EOI_BOLD: "directly to the carrier.",
  MY_DOCUMENTS: "My documents",
  BACK_TO_DOCUMENTS_AND_FORMS: "Back to Documents & Forms",
  DONT_HAVE_ANY_UPLOADED_DOCUMENTS: "You don\u2019t have any uploaded documents yet",
  ONCE_YOU_UPLOAD: "When you do, or an agent pins one for you, you will see them here. Let\u2019s get started shall we?",
  UPLOAD_DOCUMENT: "Upload Document",
  UPLOAD_CSV_FILE: "Upload a CSV file",
  UPLOAD_A_DOCUMENT: "Upload a Document",
  UPLOADED: "Uploaded",
  NAME: "Name",
  PRESCRIPTION: "Prescription",
  GYM_MEMBERSHIP: "Gym Membership",
  BENEFICIARY_FORM: "Beneficiary Form",
  PROOF_OF_BUSINESS: "Proof of Business",
  IDENTIFICATION: "Identification",
  VIEW: "View",
  DOWNLOAD: "Download",
  FOREIGN_CONTRACT: "Foreign Contract Worker Questionnaire",
  DOWNLOAD_FORM: "Download Form",
  DOWNLOAD_FORMS: "Download Forms",
  DOWNLOAD_FORMS_TEXT: "Your insurance carriers require you to submit a hard copy, a scanned version or even a photo of the following forms to get you covered.",
  BENEFICIARIES_FORM_CARD_TOOLTIP: "<p>Your beneficiary is the person or entity who receives payment from the carrier in the unfortunate event you pass away.</p><p>It\u2019s important to designate your beneficiaries to make sure your money ends up in the right hands in the event that you pass away. If you don\u2019t, your money could get held up and be subject to expensive and unnecessary fees.</p><p>If you haven\u2019t already, we\u2019ll explain how to designate a beneficiary after you complete enrollment.</p>",
  BENEFICIARIES_FORM_CARD_DESCRIPTION: "To provide security for your loved ones, League recommends that you always designate beneficiaries for your insurance policies.",
  PAPER_BENEFICIARIES_CARD_DESCRIPTION_NOTE: "Please note: If you\u2019ve already submitted a beneficiary form with your current employer and you don\u2019t want to make any changes to your designations, you don\u2019t need to submit a new form.",
  PAPER_BENEFICIARIES_CARD_INFO_BANNER: "<strong>Instructions:</strong> After completing the form, you can add it to the <strong>Uploads</strong> section of this page unless otherwise instructed by your HR team.",
  DIGITAL_BENEFICIARIES_CARD_EMPTY: "You haven\u2019t designated any beneficiaries",
  DIGITAL_BENEFICIARIES_CARD_TOTAL_BENEFICIARIES: "Your Beneficiaries ({totalBeneficiaries})",
  DIGITAL_BENEFICIARIES_CARD_LAST_UPDATED: "Last Updated: {lastUpdated}",
  REQUIRED_FORM: "Required",
  RECOMMENDED_FORM: "Recommended",
  DESIGNATIONS: "Designations",
  YOUR_BENEFICIARIES: "Your Beneficiaries",
  DESIGNATIONS_VIEW_DESCRIPTION: "For each benefit, you can name one or more primary beneficiaries and, optionally, one or more contingent beneficiaries. When naming a beneficiary, specify what percentage of the benefit\u2019s payout they should receive.",
  DESIGNATING_BENEFICIARIES: "Designating Beneficiaries",
  SAVE_AND_SUBMIT: "Save and Submit",
  ADD_BENEFICIARY_DROPDOWN_BUTTON: "+ Add Beneficiary",
  ADD_BENEFICIARY_DROPDOWN_BUTTON_TO_BENEFIT: "Add {designationType} beneficiary to {benefitName}",
  ADD_BENEFICIARIES_EMPTY_TEXT: "You haven't added any beneficiaries",
  CREATE_NEW_BENEFICIARY: "Create new",
  BENEFICIARY_DESIGNATION_COUNT: "{designationCount, plural, one {# beneficiary} other {# beneficiaries}} designated",
  PERCENTAGE: "Percentage",
  PERCENTAGE_TOTAL_REQUIREMENT: "Must equal 100%",
  PERCENTAGE_USED: "{percentage} used.",
  DESIGNATION_SHARE_PERCENTAGE_ERROR: "Please specify a percentage for each beneficiary and ensure that the total percentage is equal to 100.",
  DESIGNATION_LIST_HEADER: "{designationType} Beneficiaries",
  PRIMARY: "Primary",
  CONTINGENT: "Contingent",
  TOTAL_PERCENTAGE: "Total percentage",
  NO_BENEFICIARIES_DESIGNATED: "No {designationType} beneficiaries designated",
  ADD_BENEFICIARY: "Add Beneficiary",
  EDIT_BENEFICIARY: "Edit Beneficiary",
  BENEFICIARY_DETAILS: "Beneficiary Details",
  ALL_FIELDS_ARE_REQUIRED_UNLESS_STATED_AS_OPTIONAL: "All fields are required unless stated as optional.",
  BENEFICIARY_FORM_LEGEND: "Select the type of beneficiary you'd like to add",
  PERSON: "Person",
  PERSON_DESCRIPTION: "You can name any individual as a beneficiary.",
  ORGANIZATION: "Organization",
  ORGANIZATION_DESCRIPTION: "You can name a non-person beneficiary, such as a charity, trust or estate.",
  ORGANIZATION_LEGAL_NAME: "Organization Legal Name",
  TAX_ID_OPTIONAL: "Tax ID (Optional)",
  STATE_PROVINCE_REGION: "State/Province/Region",
  ZIP_CODE_OR_POSTAL_CODE: "Zip Code/Postal Code",
  TRUSTEE: "Trustee",
  PRIMARY_BENEFICIARIES_TOOLTIP: "Primary beneficiaries are first in line to receive the proceeds from the benefit\u2019s payout.",
  CONTINGENT_BENEFICIARIES_TOOLTIP: "Contingent beneficiaries are second in line to receive the proceeds from the benefit\u2019s payout. Contingent beneficiaries are entitled to receive the money only if none of the named primary beneficiaries are available to receive it.",
  MISSING_DESIGNATIONS: "Missing Designations",
  SAVE_WITHOUT_DESIGNATIONS: "Are you sure you want to save without designations for the following benefit(s):",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_HEADER: "Confirm your new beneficiary designation(s)",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_STATEMENT: "I revoke any beneficiary designation that was previously made for my employer plan sponsored Life and/or AD&D benefits, and I authorize that the proceeds from the benefits above be paid to the beneficiary or beneficiaries named on this form.",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_ACCEPTANCE: "I\u2019ve read the above and confirm my new designation(s)",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_ERROR: "This confirmation is needed to save your designation(s)",
  SUCCESS: "Success!",
  ERROR: "Error",
  BENEFICIARY_DESIGNATIONS_SAVED: "Your beneficiary designation(s) have been saved.",
  ERROR_SAVING_BENEFICIARY_DESIGNATIONS: "Sorry, there was an error saving your beneficiary designations. {error}",
  ERROR_BENEFICIARY_ALREADY_ADDED: "{beneficiaryName} has already been added to this benefit.",
  BENEFICIARIES_LIST_VIEW_DESCRIPTION: "A beneficiary is a person or entity who receives payment from your carrier in the unfortunate event you pass away.  It is important to designate your beneficiaries to make sure your money ends up in the right hands.",
  BENEFICIARIES_LIST_VIEW_EMPTY_STATE: "You haven\u2019t added any beneficiaries yet. You can do this in the Designations section by clicking \u201CAdd Beneficiary\u201D within a benefit.",
  BENEFICIARY_CARD_WITH_DESIGNATIONS: "Designations: {benefitDesignations}",
  BENEFICIARY_CARD_WITHOUT_DESIGNATIONS: "This beneficiary isn\u2019t designated to any benefit",
  DELETE_BENEFICIARY_CONFIRM_MODAL_HEADER: "Are you sure you want to delete \u201C{beneficiaryName}\u201D?",
  DELETE_BENEFICIARY_CONFIRM_MODAL_DESCRIPTION: "This is a permanent action and cannot be undone.",
  DELETE_BENEFICIARY: "Delete Beneficiary",
  DELETE_BENEFICIARY_BUTTON_LABEL: "Delete {beneficiaryName}",
  DELETE_BENEFICIARY_WARNING_MODAL_HEADER: "Beneficiary cannot be deleted",
  DELETE_BENEFICIARY_WARNING_MODAL_DESCRIPTION: "You must first remove the designation(s) associated with this beneficiary from the following benefits:",
  VIEW_DESIGNATIONS: "View Designations",
  WE_WANT_TO_HEAR_FROM_YOU: "We want to hear from you",
  BY_FILLING_OUT_SURVEY: "By filling out this speedy survey (seriously it only takes 2 minutes), you\u2019ll help us make this enrollment experience better for all future users \u2013 including you, next enrollment!",
  TAKE_SURVEY: "Take our Survey",
  EXTERNAL_ACCOUNT_CARD_TITLE: "Some accounts still need setting up",
  EXTERNAL_ACCOUNT_CARD_CONTENT_SINGLE: "It looks like you've contributed funds to {formattedExternalAccountBenefits}. If you haven't already, you'll need to set up a profile for this account. We'll explain how to do this in your League Digital Wallet when your benefits are added.",
  EXTERNAL_ACCOUNT_CARD_CONTENT_MULTIPLE: "It looks like you've contributed funds to {formattedExternalAccountBenefits}. If you haven't already, you'll need to set up profiles for these accounts. We'll explain how to do this in your League Digital Wallet when your benefits are added.",
  VIEW_MY_WALLET: "View my Wallet",
  ARTICLE_EXTERNAL_ACCOUNT_BENEFIT: "a {benefitName}",
  EXPERIENCE_LEAGUE: "Experience League",
  EXPERIENCE_LEAGUE_SUBHEADING: "You don\u2019t have to wait any longer to start experiencing League! To access your health everywhere, get the League app on all your devices.",
  DOWNLOAD_MOBILE_APPS: "Download our mobile apps!",
  OR_VISIT: "or visit",
  LEAGUE_DESKTOP: "League Desktop",
  SIGN_IN_FROM_DESKTOP: "Or sign-in from your desktop device!",
  DOWNLOAD_IOS: "Download on the App Store.",
  DOWNLOAD_ANDROID: "Get it on Google Play.",
  GET_STARTED: "Get started with League!",
  VISIT_LEAGUE: "Visit League",
  OPTIONAL_LTD: "Optional LTD",
  WELCOME_TO_YOUR_BENEFITS_EXPERIENCE: "Welcome to your benefits experience",
  WELCOME_SCREEN_SUB_HEAD_DESCRIPTION: "KPMG has provided a total rewards package just for you. In this enrollment process, you'll decide between benefits options to build a flexible plan that is unique to your needs. We'll guide you through every step of the way.",
  OVERVIEW: "Overview",
  KPMG_PAYS: "KPMG Pays",
  WELLNESS_POOL_DESCRIPTION: "KPMG has designed a total rewards package for you that is heavily subsidized through a wellness pool. You have flexibility to choose how you allocate your wellness pool dollars. You will see your wellness pool balance decrease as you make plan selections to suit your needs.",
  BENEFIT_SUBSIDIZATION: "Benefits Subsidization",
  BENEFIT_SUBSIDIZATION_DESCRIPTION: "KPMG recognizes the additional cost to enroll in Drug, Medical & Dental benefits for your family and therefore, they provide subsidization in addition to your Wellness Pool to support you should you choose to enroll any eligible dependents in benefits coverage.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_HEADING: "NEW in 2024! Increased mental health benefit fund and wellness pool dollars",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS: "Effective December 1, 2023, we have implemented a couple of changes to our benefits plan:",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_1: "KPMG\u2019s fully firm-funded mental health benefit is increasing to $3,000 at no cost to our employees. KPMG continues the commitment to cover the full cost of this benefit on your behalf.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_2: "Consistent with what we are seeing across many day-to-day expenses, the costs of medical and dental services continue to increase, and this is reflected in pricing changes for KPMG benefits.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_3: "While we can\u2019t control what\u2019s happening in the broader market, we invested additional money in the wellness pool to offset some of the increases to benefit price tags.",
  KPMG_RE_ENROLL_HELP_TEXT: "If you don\u2019t re-select previously approved coverage, it will not carry forward into the new plan year.",
  PAYROLL_DEDUCTIONS_DESCRIPTION: "This value indicates the amount that will be covered as an out-of-pocket expense to you. <p>Optional Life Insurance, Long-Term Disability (LTD), and Accident Insurance (AD&D) are required to be paid for through payroll deductions to avoid a taxable benefit to you.</p><p>The \u2018You Pay\u2019 amount will change based on your choices. If your Medical, Drug, Dental and Basic Life Insurance plan selections exceed the wellness pool balance, any remaining costs are also covered by payroll deductions.</p>",
  PAYROLL_DEDUCTIONS_PARTNERS: "This value indicates the amount that will be covered as an out-of-pocket expense to you. <p>You will already see the cost of mandatory benefits (LTD, life and accident insurance) reflected when you log in.</p><p>The \u2018You Pay\u2019 amount will change based on your choices.</p>",
  ENROLLMENT_PERIOD: "Enrollment Period",
  ENROLLMENT_PERIOD_DESCRIPTION: "You can review and update your choices as many times as you\u2019d like until the end of the enrollment period ({enrollmentEndDate}). If you do not submit any choices, your current coverage will remain in place. Any remaining wellness pool dollars will be allocated to your Lifestyle Spending Account. <p>Your next opportunity to make any changes will be as a result of a life event or during next year\u2019s enrollment period.</p>",
  NEW_HIRE_ENROLLMENT_PERIOD_DESCRIPTION: "You have 31 days from your date of hire to complete and submit your choices. If you do not submit any choices, default coverage will be assigned. Once you submit, you may not make further changes. Your next opportunity to make any changes would be as a result of a life event or during enrollment for next year's policy period.",
  PARTNER_ENROLLMENT_PERIOD_DESCRIPTION: "You can review and update your choices as you'd like until the end of the enrollment period ({enrollmentEndDate}). You can also make changes to your plan selections at any time throughout the year by reaching out to League's Customer Care team. Benefits will take effect on the following first of the month upon submission.",
  NEW_HIRE_PARTNER_ENROLLMENT_PERIOD_DESCRIPTION: "You have 31 days from your date of hire to complete and submit your choices. You can also make changes to your plan selections at any time throughout the year by reaching out to League's Customer Care team. Benefits will take effect on the following first of the month upon submission.",
  POLICY_DURATION: "Policy Duration",
  POLICY_DURATION_DESCRIPTION: "Your choices made during the enrollment period will take effect the 1st of the month following submission of your benefit enrolment. They will be valid up to <strong>{planEndDate}</strong>.",
  NEW_HIRE_POLICY_DURATION_DESCRIPTION: "Your choices made during the enrollment period will take effect the 1st of the month following submission of your benefit enrolment. They will be valid up to <strong>{planEndDate}</strong>.",
  SAVING_AND_EDITING: "Saving and Editing",
  SAVING_AND_EDITING_DESCRIPTION: "You may want to take your time completing your enrollment. Don't worry, your selections are saved as you go through each section, but you must submit them in order for them to take effect.",
  SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS: "You may want to take your time completing your enrollment. Don't worry, your selections are saved as you go through each section, but you must submit them in order for them to take effect.",
  PARTNER_SAVING_AND_EDITING_DESCRIPTION: "You may want to take your time completing your enrollment. Don't worry, your selections are saved as you go through each section, but you must submit them in order for them to take effect.",
  PARTNER_SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS: "You may want to take your time completing your enrollment. Don't worry, your selections are saved as you go through each section, but you must submit them in order for them to take effect. You can also make changes to your plan selections at any time throughout the year by reaching out to League's Customer Care team.",
  NEW_HIRE_SAVING_AND_EDITING_DESCRIPTION: "You may want to take your time completing your enrollment. Don't worry, your selections are saved as you go through each section, but you must submit them in order for them to take effect.",
  HAVE_QUESTIONS: "Have Questions?",
  HAVE_QUESTIONS_DESCRIPTION: "League's Customer Care team is available if you have questions throughout the process. ",
  YOU_CAN_ALSO_VISIT_KNOWLEDGE_BASE: "You can also visit our {knowledgeBaseLink} to learn more about your benefit offerings.",
  LETS_GET_STARTED: "Let's Get Started",
  FLEX_BALANCE_DEDUCTION_DESCRIPTION: "These accounts will take priority, so you can spend up to your full {flexDollarTerm} amount on these accounts and then your previous selections will be covered by payroll deductions.",
  THIS_FIELD_IS_REQUIRED: "This field is required",
  INVALID_POSTAL_CODE: "Invalid postal code format (A1A 1A1)",
  INVALID_POSTAL_CODE_NO_FORMAT: "Invalid postal code",
  INVALID_ZIP_CODE: "Invalid zip code format (11111 or 11111-2222)",
  INVALID_ZIP_CODE_NO_FORMAT: "Invalid zip code",
  INVALID_US_SSN: "Invalid SSN format (111-22-3333 or 111111111)",
  SPOUSE_DEPENDENT_FORM_TITLE: "Your Spouse or Partner's Information",
  DEPENDENT_FORM_TITLE: "Your {dependent}'s Information",
  SAVE_CHANGES: "Save Changes",
  REMOVE_DEPENDENT: "Remove Dependent",
  REMOVE_DEPENDENT_NAME: "Remove {dependentFullName}",
  CANCEL_DEPENDENT_CHANGES_HEADING: "Are you sure you want to cancel your changes?",
  CANCEL_DEPENDENT_CHANGES_WARNING: "Any updates that you\u2019ve made to your dependent will be lost.",
  UNDO_REMOVE_DEPENDENT_HEADING: "Are you sure you want to undo this pending removal?",
  CANCEL_DEPENDENT_DEACTIVATION_SUCCESS_TOAST: "Dependent has been added back to your plan",
  DEPENDENT_MODAL_WARNING: "Please review and update any elections that you\u2019ve previously made for this dependent.",
  UNDO_REMOVAL: "Undo Removal",
  UNDO_REMOVAL_OF_DEPENDENT: "Undo removal of {dependentName}",
  UNDO: "Undo",
  PENDING_DEPENDENT_REMOVAL: "Pending removal: {deactivationDate}",
  PENDING_COVERAGE_APPROVAL: "Pending approval",
  REMOVE_DEPENDENT_HEADING: "Are you sure you want to remove this dependent?",
  REMOVE_DEPENDENT_TEXT: "You are about to remove <strong>{dependentName}</strong> as a dependent on your <strong>{planName}</strong> plan.",
  MISSING_INFORMATION_VALIDATION_ERROR_MESSAGE: "<strong>We've found errors on this page.</strong> You'll have to fix them before moving onto the next step",
  RAMQ_ACKNOWLEDGEMENT_REQUIRED_ERROR: "<strong>The plan you selected isn\u2019t RAMQ compliant.</strong> Let us know that you have alternate coverage at the bottom of this page.",
  OPT_IN_TERMS_NOT_ACKNOWLEDGED_ERROR: "<strong>Wait up! We're missing some information.</strong> Please read and accept the agreement before proceeding.",
  REQUIRED_BENEFIT_NOT_SELECTED_ERROR: "<strong>Wait up! We're missing some information.</strong> Please select a plan to sort this out.",
  OPTIONAL_BENEFIT_NOT_SELECTED_ERROR: "<strong>Wait up! We're missing some information.</strong> Please select a plan or waive coverage to sort this out.",
  DEPENDENTS_PAGE_UNSAVED_ERROR_MESSAGE: "<strong>Save your dependents!</strong> You must complete and save each form before proceeding to the next step.",
  USER_PROFILE_UNSAVED_ERROR_MESSAGE: "<strong>Save your profile!</strong> You must complete and save the form before proceeding to the next step.",
  ERROR_SAVING_USER_PROFILE: "There was an error saving your user profile.",
  YMD_DATE_FIELD_PLACEHOLDER: "YYYY/MM/DD",
  MDY_DATE_FIELD_PLACEHOLDER: "MM/DD/YYYY",
  DMY_DATE_FIELD_PLACEHOLDER: "DD/MM/YYYY",
  RAMQ_ALT_COVERAGE_HEADING: "Do you have alternate coverage?",
  WHAT_IS_RAMQ: "What is RAMQ?",
  RAMQ_TOOLTIP_TEXT: "RAMQ (R\xE9gie de l'assurance maladie du Qu\xE9bec) is the mandatory government health insurance system in Quebec. RAMQ provides residents of Quebec with basic health coverage (prescriptive drugs) if they are not covered by an eligible group plan.",
  RAMQ_CONFIRM_ALT_COVERAGE: "Yes, I have alternate coverage",
  RAMQ_ALT_COVERAGE_INFO: "The plan you selected is not RAMQ compliant. RAMQ requires you to certify that you have alternate coverage, either through your partner, spouse or through other means.",
  READY_TO_BUILD_YOUR_PLAN: "Ready to build your plan?",
  FLEX_INCREASE_HEADING: "Your {flexTerm} has increased based on your selected coverage!",
  FLEX_INCREASE_SUBHEADING: "{employerName} has provided you with additional funds based on your selection.",
  FLEX_DECREASE_HEADING: "Your {flexTerm} has been adjusted based on your selected coverage.",
  FLEX_DECREASE_SUBHEADING: "{employerName}'s additional subsidization can change based on your selections.",
  INVALID_DATE_ERROR_MESSAGE: "This date isn't valid, please enter a valid date.",
  INVALID_CHARACTER_ERROR_MESSAGE: 'This field contains invalid characters. "{invalidCharacter}"',
  INVALID_DAY_ERROR_MESSAGE: "The day you selected is invalid.",
  FUTURE_DATE_ERROR_MESSAGE: "Please choose a date in the past.",
  TOO_LONG_AGO_DATE_ERROR_MESSAGE: "Please choose a date after 1900",
  UNIQUE_IDENTIFIER_MIN_LENGTH_ERROR: "Must be at least {length} characters",
  UNIQUE_IDENTIFIER_MAX_LENGTH_ERROR: "Must be at most {length} characters",
  PLEASE_ENTER_A_VALID_VALUE: "Please enter a valid value",
  DEPENDENT_COVERAGE_PREPOPULATED: "Note: Your dependent coverage selection must be the same for {benefits} coverage. We'll pre-populate this for you.",
  DEPENDENT_COVERAGE_CHANGED_TO: 'We updated your {plan} dependent coverage to "{dependentCoverage}"',
  DECISION_SUPPORT_BANNER_HEADER: "Need help choosing a plan?",
  DECISION_SUPPORT_BANNER_DESCRIPTION: "Picwell is a tool we've partnered with that helps determine which {benefitSetName} plan is the best fit for you. The survey is anonymous and only takes 6 minutes!",
  HELP_ME_CHOOSE: "Help Me Choose",
  COMPLETED_STATE_TITLE: "Completed",
  PENDING_STATE_TITLE: "We\u2019re waiting on your results...",
  DECISION_SUPPORT_STATUS_COMPLETED_DESCRIPTION: "Your best match will show up in the table below.",
  DECISION_SUPPORT_STATUS_PENDING_DESCRIPTION: "Finish the Picwell survey to get your best match.",
  DECISION_SUPPORT_STATUS_ERROR_DESCRIPTION: "Try taking the survey again, or refreshing your browser.",
  THE_TOOL: "The Tool",
  YOUR_ENROLLMENT_EXPERIENCE: "Your enrollment experience",
  NEW_JOB_CONGRATS: "Congrats on your new job",
  NEW_JOB_EXPLANATION: "Because you just started at {employer}, your enrollment is going to be a little different than normal. You\u2019ll need to choose coverage for the current policy period, as well as coverage for the next policy period.",
  QLE_PLAN_PICKER_EXPLANATION: "Because your life change event falls in and around open enrollment, you have the opportunity to update and/or choose coverage for your current and upcoming policies.",
  QLE_PLAN_PICKER_INFO_BANNER: "Please complete both enrollments as your elections in one enrollment won\u2019t automatically transfer to the other.",
  PLAN_PICKER_DEADLINE: "You have until <strong>{date}</strong> to enroll in this plan",
  QLE_PLAN_PICKER_DEADLINE: "You have until <strong>{date}</strong> to update this plan",
  QLE_PLAN_PICKER_COMPLETED: "All done! Your {planName} elections are officially locked in.",
  PLAN_PICKER_WAIT: "Your {waitingName} will open when you finish your {currentName}",
  PLAN_PICKER_EXPLANATION: "This is the period in which your benefits will be active and can be used.",
  START_ENROLLMENT: "Start Enrollment",
  CONTINUE_ENROLLMENT: "Continue Enrollment",
  UPDATE_ELECTIONS: "Update Elections",
  WAIT_ENROLLMENT: "Your {planName} Enrollment will open when you finish your {previousPlanName} Enrollment",
  DRAG_AND_DROP: "Drag and drop a document into this window, or select from your desktop.",
  DRAG_OR_UPLOAD: "Drag or Upload a Document",
  DRAG_OR_UPLOAD_PHOTO: "Upload a photo of this document",
  SELECT_TYPE: "Select a Type",
  ADD_IMAGE: "Add more pages to this document (if applicable)",
  TIPS_FOR_UPLOADING: "Tips for Uploading",
  MAKE_SURE_DOCUMENT: "Make sure that the document\u2026",
  FULLY_VISIBLE: "Make sure that the image is fully visible and not cut off",
  ADD_EXTRA_FILES: "Add extra files as necessary",
  IDENTIFICATION_TYPE_TWO_FILES: "Identification documents can only have up to 2 supporting images.",
  WHAT_TYPE: "What type of document is it?",
  BENEFIT_COVERAGE: "{benefit} Coverage",
  INTEGRATIONS: "Integrations",
  COVERAGE_SUMMARY_ERROR_TEXT: "<strong>You have incomplete steps and/or errors.</strong> You will need to resolve these errors before you complete your enrollment.",
  SUPPORTING_DOC_ADDED: "Supporting Document added successfully!",
  SUPPORTING_DOC_REMOVED: "Supporting Document removed",
  DOC_SUCCESSFULLY_UPDATED: "Document successfully updated!",
  ERROR_UPDATING_SUPP_DOC: "Error updating supporting document",
  ERROR_UPLOADING_DOC: "Error uploading supporting document",
  CLAIM_DOC_PINNED: "Claim document pinned!",
  ERROR_PINNING: "Error pinning claim document",
  SUPPORTING_DOC_REMOVED_CLAIM: "Supporting document removed from claim",
  DOC_ADDED_CLAIM: "Supporting document added to claim!",
  ERROR_ADDING_DOC_CLAIM: "Error adding supporting document to claim",
  SELECT_FILE: "Select a document from your desktop",
  BAD_NETWORK_CONNECTION_TITLE: "Bad Network Connection",
  BAD_NETWORK_CONNECTION_TEXT: "Try refreshing your browser or checking<br /> your internet connection!",
  SUPPORTING_DOCUMENTS_CAN_BE_THINGS: "Supporting documents can be things like prescriptions, gym memberships, etc. that are regularly referred to in the claims adjudication process.",
  CONTRIBUTION_NOT_ALLOWED_INFO: "<strong>Hold tight!</strong> You\u2019re not eligible to contribute to some of your wellness accounts just yet, but you will be able to contribute during your next enrollment period.",
  ON_DISABILITY_LEAVE_INFO: "<strong>Why can't I change anything?</strong> You are not able to update or change your benefit plan while you are on disability leave.",
  ENROLLMENT_PERIOD_OVER_INFO: "<strong>Why can\u2019t I change anything?</strong> Because you missed your enrollment period, you are not able to update or change your benefit plan.",
  PRELOADED_INFO: "<strong>Why can\u2019t I change anything?</strong> This election cannot be modified for this enrollment period. If you have questions, League's Customer Care team is available to help.",
  BCC_ENROLLMENT: "<strong>Why can't I change my elections?</strong> During this enrollment, you will only be able to enroll in newly offered benefits as part of your job change.",
  CANT_MANAGE_DEPENDENTS: "<strong>Why can't I change anything?</strong> Based on your coverage, you do not need to add dependents.",
  DONT_NEED_TO_ADD_DEPENDENTS: "Adding or updating dependents to your plan is not currently available to you.",
  YOU_HAVENT_ADDED_ANY_DEPENDENTS: "You haven\u2019t added any dependents",
  WELCOME: "Welcome",
  VIEW_USER_PLAN_ENROLLMENT: "\u{1F440} View User Plan Enrollment",
  USER_PLAN_ENROLLMENTS_TITLE: "User Plan Enrollments for: {planName} plan",
  BACK_TO_PREVIOUS: "\u2190 Return to previous page",
  CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_TITLE: "Remove User Plan Enrollment?",
  CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_MESSAGE: "This will take immediate effect on the user.",
  NO_PLAN_ENROLLMENT: "No Plan Enrollment",
  ENROLLMENT_CONFIGURATION: "Enrollment Configuration",
  SWITCH_TO_NEW_ENROLLMENT_DESIGN_TOOL: "Switch to New Enrollment Design Tool",
  VIEW_BENEFIT_SELECTIONS_DIFF: "\u{1F575}\uFE0F\u200D\u2642\uFE0F View Plan Benefit Selections History",
  VIEW_BENEFIT_ALLOCATION_DRY_RUN: "\u{1F4BE} View Benefit Allocation Dry Run",
  BENEFIT_ALLOCATION_DRY_RUN: "Benefit Allocation Dry Run",
  ALLOCATED_BENEFITS: "Allocated Benefits",
  TRACE: "Trace",
  MAP: "Map",
  SPECIFY_A_TIME_OPTIONAL: "Specify a time (Optional)",
  BENEFIT_DETAILS: "Benefit Details",
  PARENT_BENEFIT_ID: "Parent Benefit ID",
  PLAN_NAME: "Plan Name",
  ACTIVATION_DATE: "Activation Date",
  DEPENDENT_COVERAGE: "Dependent Coverage",
  CHILD_COVERAGE: "Child Coverage",
  SPOUSE_COVERAGE: "Spouse Coverage",
  DRY_RUN_COVERAGE_VOLUME_AMOUNT: "Coverage Volume Amount",
  DRY_RUN_COVERAGE_VOLUME_AMOUNT_APPROVED: "Coverage Volume Amount Approved",
  DRY_RUN_COVERAGE_VOLUME_OPTION: "Coverage Volume Option",
  USER_SELECTION: "User Selection",
  FULL_AMOUNT: "Full Amount",
  PLAN_ID_VALUE: "Plan ID: {planId}",
  RESET_USER_BENEFITS: "Reset User Benefits",
  RESET_BENEFIT_SELECTION_CONFIRM_HEADER: "Reset benefit selection for {employeeName}?",
  ENROLLMENT_END_DATE: "Enrollment End Date",
  ENROLLMENT_END_DATE_IS_REQUIRED: "Enrollment end date is required",
  YOU_MUST_SELECT_A_RESET_TYPE: "You must select a reset type",
  BENEFIT_PLAN_OPTIONAL: "Benefit Plan (Optional)",
  ENROLLMENT_TYPE_OPTIONAL: "Enrollment Type (optional)",
  RESET_TYPE: "Reset Type",
  RESET_USER_BENEFIT_SELECTION_SPLAINER_TEXT: "This will reset previous user benefits and place them back into FTUX. They will have until the entered Enrollment End Date to complete their selection and resubmit. New user benefits will be allocated after the Enrollment End Date",
  RESET_USER_BENEFITS_AND_CLEAR_SELECTION: "Reset user benefits and clear selection",
  RESET_USER_BENEFITS_AND_MAINTAIN_SELECTION: "Reset user benefits and maintain selection",
  USER_BENEFITS_RESET_CLEAR_SELECTION_SUCCEEDED: "User benefits and selection reset, enrollment end date set to {enrollmentEndDate}",
  USER_BENEFITS_RESET_MAINTAIN_SELECTION_SUCCEEDED: "User benefits reset, enrollment end date set to {enrollmentEndDate}",
  USER_BENEFITS_RESET_FAILED: "Error resetting User Benefit Selection",
  BALANCE_FUNDS: "Balance Funds",
  EMPLOYEE_FUNDS_UNBALANCED: "{employeeName}'s funds are out of sync",
  EMPLOYEE_FUNDS_UNBALANCED_BODY: "The funds applied to {employeeName}'s benefits vs the total amount available to them are out of sync. Balance {employeeName}'s funds to send the excess amount into their LSA",
  BALANCE_FUNDS_SPLAINER: "The funds applied to {employeeName}'s benefits vs the total amount available to them are out of sync. This means that they have unused money in our system that should go into their LSA. Balancing funds will send the excess amount in their LSA.",
  BALANCE_FUNDS_CANT_BE_UNDONE: "<strong>Note:</strong> Only balance funds after you're done making the necessary changes to {employeeName}'s benefits. This action can't be undone.",
  BALANCE_FUNDS_SUCCESS_NOTIFICATION: "Funds successfully re-balanced",
  TOTAL_FUNDS: "Total Funds",
  FUNDS_USED: "Funds Used",
  FUNDS_OUT_OF_SYNC: "These funds are out of sync",
  SOME_PAGES_HAVE_ERRORS: "Some pages have errors that need your attention.",
  INITIATE_QLE_REENROLLMENT: "Initiate QLE Re-Enrollment",
  INITIATE_QLE_CONTENT: "Initiating a Qualifying Life Event (QLE) Re-Enrollment will allow <strong>{employeeName}</strong> to go back into enrollment and make new selections. Fill out all required fields below.",
  INITIATE_QLE_COVERAGE_START_DATE_QUESTION: "What date should their coverage start?",
  INITIATE_QLE_COVERAGE_START_DATE_NOTE: "(Note: Their old coverage will end at 11:59PM EST the night before this date)",
  INITIATE_QLE_COVERAGE_START_DATE_TOOLTIP: "The users coverage will become effective on this date.",
  INITIATE_QLE_COVERAGE_START_DATE_LABEL: "Coverage Start Date",
  INITIATE_QLE_COVERAGE_START_DATE_ERROR: "You must select a coverage start date",
  INITIATE_QLE_ENROLLMENT_END_DATE_QUESTION: "When should enrollment close for this user?",
  INITIATE_QLE_ENROLLMENT_END_DATE_TOOLTIP: "This will mark the end of the users enrollment period. The user will have until 11:59PM (based on the groups timezone) to make or update their selections.",
  INITIATE_QLE_ENROLLMENT_END_DATE_LABEL: "QLE Enrollment End Date",
  INITIATE_QLE_ENROLLMENT_END_DATE_ERROR: "You must select an enrollment end date",
  INITIATE_QLE_TYPE_QUESTION: "What kind of Qualifying Life Event did the user have?",
  INITIATE_QLE_TYPE_LABEL: "Type of QLE",
  INITIATE_QLE_TYPE_PLACEHOLDER: "Select a Qualifying Life Event",
  INITIATE_QLE_TYPE_ERROR: "You must select a qualifying life event",
  INITIATE_QLE_SUCCESS_TOAST: "QLE Re-Enrollment has been started for {employeeName}",
  INITIATE_QLE_ERROR_TOAST: "Error in initiating QLE for the employee: {reason}",
  COVERAGE_SUMMARY: "Coverage Summary",
  DEPENDENT_CHECK_TEXT: "<strong>Just checking\u2026</strong> We see that you added dependents but then didn\u2019t cover them in any benefits. If you meant to do this, great! If not, head back and update your <strong>Dependent Coverage Preference</strong> to cover your loved ones.",
  DEPENDENT_COVERAGE_SELECTION_TEXT: "For {dependentCoverageType}",
  DEPENDENT_COVERAGE_TYPE_MYSELF: "Myself",
  DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE: "Myself and my spouse",
  DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE_CHILDREN: "Myself, my spouse, and my child(ren)",
  DEPENDENT_COVERAGE_TYPE_MYSELF_CHILDREN: "Myself and my children",
  DEPENDENT_COVERAGE_TYPE_MYSELF_ONE_CHILD: "Myself and my child",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER: "Myself and my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD: "Myself and the child of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN: "Myself and the children of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD_CHILDREN: "Myself, my children and the child of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN_CHILDREN: "Myself, my children and the children of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILD: "Myself, my domestic partner, and the child of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILDREN: "Myself, my domestic partner, and the children of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN: "Myself, my domestic partner and my children",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILD: "Myself, my children, my domestic partner, and the child of my domestic partner",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILDREN: "Myself, my children, my domestic partner, and the children of my domestic partner",
  NO_DEPENDENT_SELECTION: "No dependent coverage selected",
  GOT_IT: "Got It!",
  VISIT_LEGACY: "H@W Legacy Tool",
  LEGACY_ADMIN_TOOLS: "Legacy Tool",
  FINANCE: "Finance",
  FEATURE_FLAGS: "Feature Flags",
  NO_DESCRIPTION: "No description",
  BACK_TO_FLAGS_LIST: "Back to Flags List",
  FLAG_HAS_NO_ID_WARNING: "Something is amiss! According to the backend, this flag doesn't have an id, which simply won't do. This requires some manual love from an engineer.",
  FLAG_DETAILS: "Flag details: {flagId}",
  ON_OFF_FOR_ALL: "On/Off For All",
  ON_FOR_ALL: "On for all?",
  OFF_FOR_ALL: "Off for all?",
  USERS: "Users",
  GROUPS: "Groups",
  ADD_A_LIST_OF_COMMA_SEPARATED_IDS: "Add a list of comma separated IDs",
  ON_FOR_USERS: "On for users: ",
  OFF_FOR_USERS: "Off for users: ",
  ON_FOR_GROUPS: "On for groups: ",
  USERS_COUNT: "{count} users",
  GROUPS_COUNT: "{count} groups",
  FEATURE_FLAG_UPDATED: "Feature Flag updated!",
  READ_MORE: "Read More",
  READ_LESS: "Read Less",
  DOWNLOAD_EOI_FORM: "Download EOI form",
  ADD_DEPENDENT: "Add Dependent",
  NEW_DEPENDENT: "New Dependent",
  YOUR_BENEFIT_COVERAGE_PLANS: "Your {benefitName} Coverage Plans",
  NUMBER_OF_PLANS_AVAILABLE: "{number} Plans Available",
  NEXT_STEPS: "Next Steps",
  BENEFITS_WALLET_LANDING_TEMPLATE_HEADER: "Wallet Landing Screen",
  BENEFITS_WALLET_LANDING_TEMPLATE_DESCRIPTION: "Select a Landing Screen to be applied for this group. <br />To create a new one, visit Contentful and create a landing screen or contact a League Contentful admin.",
  BENEFITS_WALLET_LANDING_TEMPLATE_LABEL: "Landing Screen",
  BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_TITLE: "Landing screen published",
  BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_DESCRIPTION: "Wallet Landing Screen edited successfully",
  BENEFITS_WALLET_LANDING_TEMPLATE_SELECT_ERROR: "Select a different landing screen to publish",
  BENEFITS_WALLET_HEADER: "Edit Wallet Details",
  BENEFITS_WALLET_HEADER_SUB_HEADER: "Changing any of the information below will update what is displayed on the corresponding benefit's wallet card. Please note, any change to the benefit would apply across all benefit classes.",
  OVERVIEW_EMPTY: "Once you choose a benefit, you'll be able to configure the overview here",
  OVERVIEW_TITLE: "{product} ({carrier}) - Overview",
  OVERVIEW_INFO_BOX: "Recommendation: Set an Overview to better describe company programs.",
  OVERVIEW_UPDATE_MODAL_HEADER: "Are you sure you want to update the Overview for {productName}?",
  OVERVIEW_UPDATE_MODAL_CONTENT: "<strong>Note for insured benefits:</strong> Setting a custom Overview will also hide the coverage details for this benefit in the wallet. This means that users won't see any of the coverage information that has been set up for this benefit (if any).",
  OVERVIEW_CLEAR_MODAL_HEADER: "Are you sure you want to clear the overview description?",
  OVERVIEW_CLEAR_MODAL_CONTENT: "If you clear the configured overview, the user will see the default overview description and coverage details set up for this benefit.",
  WALLET_OVERVIEW_SUCCESS: "Overview Updated!",
  WALLET_OVERVIEW_ERROR: "There was an error in updating overview",
  USAGE_INSTRUCTIONS: "Usage Instructions",
  USAGE_INSTRUCTIONS_EMPTY: "Once you choose a benefit, you'll be able to configure the usage instructions here",
  USAGE_INSTRUCTIONS_TITLE: "{product} ({carrier}) - Usage Instructions",
  NO_CONFIGURABLE_BENEFITS_HEADER: "<strong>This group doesn't have any configurable benefits!</strong>",
  NO_CONFIGURABLE_BENEFITS_SUB_HEADER: "Once you add an insured product, you'll be able to configure the wallet benefit details here.",
  SELECT_BENEFIT: "Select Benefit",
  USAGE_INSTRUCTIONS_SUCCESS: "Usage instructions were saved successfully!",
  USAGE_INSTRUCTIONS_ERROR: "There was an error in updating the usage instructions",
  USAGE_INSTRUCTIONS_ARE_YOU_SURE: "Are you sure you want to clear the usage instructions?",
  USAGE_INSTRUCTIONS_GONE_FOREVER: "If you clear the configured usage instructions, the user will see the default usage instructions (if any) set for the benefit. If no usage instructions are available for the benefit, the user will not see the 'Instructions' tab in the wallet.",
  NO_SELECTED_BENEFIT: "Once you choose a benefit, you'll be able to configure the wallet actions here",
  BENEFIT_NAME_WALLET_ACTIONS_HEADER: "{benefitName} ({vendorName}) - Wallet Actions",
  BENEFIT_NAME_WALLET_ACTIONS_SUBHEADER: "Here is where you can edit, add, and configure the benefit's wallet actions",
  PRIMARY_ACTION: "Primary Action",
  CANT_ADD_SECONDARY_ACTIONS: "You can't add any secondary actions until you've added a primary action",
  ADD_MORE_ACTION: "+ Add a New Action",
  SAVE: "Save",
  LABEL: "Label",
  VALUE: "Value",
  REMOVE_WALLET_ACTION_SUCCESS_MESSAGE: "Action successfully removed!",
  REMOVE_WALLET_ACTION_ERROR_MESSAGE: "Failed to remove action",
  SAVE_WALLET_ACTION_SUCCESS_MESSAGE: "Action saved successfully!",
  SAVE_WALLET_ACTION_ERROR_MESSAGE: "There was an error!",
  REMOVE_WALLET_ACTIONS_MODAL_HEADER: "Are you sure you want to remove this action?",
  REMOVE_WALLET_ACTIONS_MODAL_BODY: "If you remove this action, it won't be available in the user's wallet for this benefit.",
  BENEFIT_WALLET_ACTION_LABEL_TOOLTIP: "The label is what is displayed to the user in the wallet for this action",
  BENEFIT_WALLET_ACTION_TYPE_TOOLTIP: "The type lets the system know how to treat this action in the wallet based on whether it is a URL or phone number",
  BENEFIT_WALLET_ACTION_VALUE_TOOLTIP: "The value that is tied to the action. <br> As a note, make sure to include the area code for phone numbers and full web address for URLs.",
  EDIT_WALLET_BUTTON: "Edit Wallet Details",
  VALID_PHONE_NUMBER_SHORT: "Must be at least 4 digits",
  VALID_PHONE_NUMBER_LONG: "Must be at most 15 digits",
  VALID_PHONE_NUMBER_CHARACTERS: "Must contain only characters 0123456789+",
  VALID_PHONE_NUMBER_PLUS: "Must contain at most one +",
  VALID_URL_REQUIRED: "A valid URL is required",
  VALUE_REQUIRED: "Value is required",
  LABEL_REQUIRED: "Label is required",
  TYPE_REQUIRED: "Type is required",
  VIEW_LESS_DETAILS: "View Less Details",
  VIEW_MORE_DETAILS: "View More Details",
  VIEW_MORE_DETAILS_SENTENCE_CASE: "View more details",
  VIEW_INSTRUCTIONS: "View Instructions",
  BUNDLE_SELECTION_HEAD: "Plan Selection",
  BUNDLE_SELECTION_SUBHEAD: "You have the option to choose between the plans outlined below, select whichever plan makes the most sense for you.",
  BUNDLE_SELECTOR_CARD_HEAD: "Which plan is right for you?",
  BUNDLE_SELECTOR_CARD_TITLE: "Plan Comparison",
  BUNDLE_SELECTOR_CARD_DESCRIPTION: "{employerName} is giving you the choice between the plans outlined below. We recommend downloading the plan comparison document and taking some time to figure out which plan makes the most sense for you. You'll have the chance to review your selected plans details a bit further after making a choice!",
  BUNDLES_NO_SELECTION_ERROR_MESSAGE: "<strong>Hold your horses!</strong> You need to a choose a plan before you review it.",
  DOWNLOAD_PLAN_COMPARISON: "Download Plan Comparison",
  LAST_UPDATED: "Last Updated",
  LAST_UPDATED_LOWERCASE: "Last updated",
  CLAIM_REF_NUMBER: "Claim #",
  QUALIFYING_LIFE_EVENT: "Qualifying Life Event",
  EFFECTIVE_DATE: "Effective Date",
  REASON_FOR_ADDING: "Reason for Adding",
  DEPENDENT_GAIN_COVERAGE: "Dependent gain coverage",
  DEPENDENT_LOST_COVERAGE: "Dependent lost coverage",
  MARRIAGE: "Marriage",
  BECAME_COMMON_LAW: "Became common-law",
  ENDING_COMMON_LAW_RELATIONSHIP: "Ending common-law relationship",
  BIRTH: "Birth",
  ADOPTION: "Adoption",
  DIVORCE: "Divorce",
  SEPARATION: "Separation",
  DEATH: "Death",
  CHANGE_IN_LOCATION: "Change in location",
  EMPLOYEE_ACTIVE_DEPENDENTS: "Active Dependents",
  EMPLOYEE_INACTIVE_DEPENDENTS: "Inactive Dependents",
  EMPLOYEE_NO_DEPENDENTS: "This employee has no dependents",
  BACK_TO_CLAIMS: "Back to Claims",
  BACK_TO_WALLET: "Back to Wallet",
  CLAIM_STATUS: "Claim Status",
  SPOUSE_HAS_COVERAGE: "Your spouse has <strong>{amount}</strong> of basic life coverage included in your plan. Any {benefitName} coverage will be added to this existing coverage.",
  CHILD_HAS_COVERAGE: "Your child has <strong>{amount}</strong> of basic life coverage included in your plan. Any {benefitName} coverage will be added to this existing coverage.",
  NO_CLAIMS: "No claims",
  NO_CLAIMS_INFO: "Once you make claims they will be listed here and you can check their status!",
  SUBMIT_CLAIM: "Submit Claim",
  VIEW_ALL: "View All",
  EDIT_CLAIM: "Edit Claim",
  CANCEL_CLAIM: "Cancel Claim",
  CHAT_WITH_AN_AGENT: "Chat With an Agent",
  CLAIM_LIST_NEEDS_MORE_INFO: "You have claims that need additional information.",
  TOTAL_OUTSTANDING: "TOTAL OUTSTANDING",
  TOTAL_OUTSTANDING_ERROR: "Sorry, there has been an error getting total outstanding.",
  EDIT_CLAIM_PROVIDE_MORE_INFO: "To provide more info,",
  EDIT_CLAIM_LINK_TEXT: "edit your claim.",
  AUTHOR_ON_DATE: "By {author} on {date}",
  TIME_SELECTOR_TEXT_1: " at ",
  TIME_SELECTOR_TEXT_2: " in EST time",
  SUBMITTED_DATE: "Submitted Date",
  HSA_AMOUNT: "HSA Amount",
  PSA_AMOUNT: "LSA Amount",
  FSA_AMOUNT: "FSA Amount",
  LDSA_AMOUNT: "LDSA Amount",
  HRA_AMOUNT: "HRA Amount",
  SA_AMOUNT: "SA Amount",
  CHARITY_AMOUNT: "Charity Amount",
  CGA_AMOUNT: "CGA Amount",
  PSA: "LSA",
  FSA: "FSA",
  LDSA: "LDSA",
  HRA: "HRA",
  SA: "SA",
  TOTAL_AMOUNT: "Total Amount",
  INVOICE_TABLE_AMOUNT_OWING: "Amount Owing:",
  INVOICE_TABLE_EXPAND: "Expand",
  INVOICE_TABLE_COLLAPSE: "Collapse",
  REQUIRED_FIELDS: "Required fields",
  ENROLLMENT_DESIGN_BLURB: "This section will allow you to design {employerName}'s enrollment experience based on the benefits you configured in the Plan Design section.",
  "SELECTED PLAN": "Selected Plan",
  NO_PLAN_SELECTED: "No plan selected",
  CURRENT_STEP: "Step {stepNumber}: {stepName}",
  SELECT_PLAN: "Select a Plan",
  BENEFIT_SET_CONFIG: "Benefit Set Configuration",
  ENROLLMENT_EXPERIENCE_CONFIG: "Enrollment Experience Configuration",
  PRICING_DESIGN_BLURB: "This section will allow you to set up employer benefit prices.",
  NEW_BENEFIT_SET: "New Benefit Set",
  PLAN_OPTIONS_SAVED_SUCCESS: "Plan Options saved successfully!",
  PLAN_OPTIONS_SAVE_ERROR: "Error saving plan options",
  ERROR_UPLOADING_ENROLLMENT_DOCUMENT: "There was an error saving the document, please try again.",
  UPLOADING_ENROLLMENT_DOCUMENT_SUCCEEDED: "Document uploaded",
  BENEFIT_SET_DETAILS: "Set Details",
  BENEFIT_SET_DETAILS_BLURB: "These details affect what will be displayed to the user while going through enrollment.",
  BENEFIT_SET_NAME_LABEL: "What kind of benefits will be in this set?",
  BENEFIT_SET_NAME_TOOLTIP: "This will affect the page name in the enrollment experience and what the set will be called.<br /><br /><strong>Note:</strong> You can't have multiple sets of the same type.",
  BENEFIT_SET_WAIVABLE_LABEL: "Can the user waive coverage?",
  BENEFIT_SET_WAIVABLE_TOOLTIP: "This will affect whether the user sees an option to waive coverage or not.<br /><br /><strong>Yes:</strong> The user will see an option to enroll or waive<br /><strong>No:</strong> The user will be prompted that this is a mandatory benefit and that they must opt-in<br /><strong>Neither:</strong> This is for spending accounts only",
  BENEFIT_SET_WAIVABLE_NEITHER: "Neither, it's a spending account!",
  DRAG_A_FILE_HERE: "Drag a file here or",
  BROWSE: "browse",
  UPLOAD_A_FILE: "for a file to upload",
  UPLOAD_BENEFIT_SET_DOCUMENT_BLURB: "Any document uploaded here will display to the user on the benefit set level",
  ADDING_BENEFITS: "Adding Benefits",
  ADDING_BENEFITS_BLURB: "Benefits added in this section will appear to the user",
  AVAILABLE_BENEFITS: "Available Benefits",
  AVAILABLE_BENEFITS_BLURB: "Benefits from other classes or benefits that have already been added to a set will not show up in this list",
  BENEFIT_SET_SAVE: "Save Set",
  SET_EMPLOYER_BENEFIT_SET_SUCCESS_MESSAGE: "Success! Benefit set saved.",
  BENEFIT_SETS: "Benefit Sets",
  BENEFIT_SET: "Benefit Set",
  BENEFIT_SETS_TOOLTIP: "A benefit set is a group of benefits that you can apply specific configurations to.<br /><br /><strong>Example:</strong><br /><br />A <em>Medical Benefit</em> Set would include all medical benefits you would want the user to choose from and how they can interact with their options.",
  BENEFIT_SETS_PLANS_BLURB: "Benefit sets provide you with the flexibility to configure how you want benefits to appear in the enrollment experience. Classes you configured in the Plan Design section will show up below.",
  PICKED_BENEFITS_BLURB: "These benefits will be shown to the user in the order that you define",
  BENEFIT_SETS_PLANS_INFO_BOX: "Creating benefits sets will be much easier if you have all of your classes, plans and benefits defined first!<br /> <strong>Restriction: You won\u2019t be able to configure this group if you have more than one plan per class.</strong>",
  BENEFIT_SETS_PLANS_EMPTY_1: "You don't have any plans configured yet.",
  BENEFIT_SETS_PLANS_EMPTY_2: 'Add a plan on the "Plan Design" tab.',
  GROUP_OPTIONS: "Group Options",
  CONFIGURATION: "Configuration",
  MISSING_PLAN_OPTIONS: "Start here to create the configuration for this new plan",
  START_PLAN_CONFIGURATION: "Start Plan Configuration",
  EMPLOYER_TIME_ZONE: "Note: These date and times are being set for the employer's time zone, which is <strong>{timezone}</strong>",
  ADDING_ENROLLMENT_DATES: "Adding Enrollment Dates",
  ADD_ENROLLMENT_DATES: "Add Enrollment Dates",
  ENROLLMENT_DATES_WARNING: "We currently don't support removing enrollment dates completely after they have been saved, are you sure you want to add these?",
  NEW_EMPLOYEE_ENROLLMENT_DURATION: "New employee enrollment duration (days)",
  SET_ENROLLMENT_DATES_SUCCESS_MESSAGE: "Enrollment dates saved",
  EXPORT_CONFIGURATION: "Export Configuration",
  VIEW_DEFAULT_PLAN: "View Default Plan",
  DOWNLOADING: "Downloading...",
  DEFAULTS_IN_MULTIPLE_BUNDLES_WARNING: "Uh oh, you have default benefits in more than one bundle!",
  NO_DEFAULT_BENEFITS_IN_PLAN: "No default benefits in plan",
  CONFIRM_START_ENROLLMENT: "Confirm start enrollment",
  OPEN_ENROLLMENT_TEXT: "You're about to start enrollment for these {employerName} members:",
  ENROLLMENT_STARTED_SUCCESSFULLY: "Enrollment started successfully",
  START_ENROLLMENT_SERVER_ERROR: "Error starting enrollment: {reason}",
  ENROLLMENT_STARTED: "Enrollment Started",
  ENROLLMENT_COMPLETE: "Enrollment Complete",
  NO_VALIDATION_WARNINGS_MESSAGE: "\u2705 No validation warnings, ready to start enrollment!",
  VALIDATION_WARNINGS_HEADER: "Hold up! We found some potential problems with this plan configuration:",
  VALIDATION_WARNING_ERRORS: "\u{1F6D1} {errorCount, plural, one {# error} other {# errors}}",
  VALIDATION_WARNING_WARNINGS: "\u26A0\uFE0F {warningCount, plural, one {# warning} other {# warnings}}",
  FORM_VALIDATION_ERROR_HEADER: "We found <strong>{errorCount, plural, one {# error} other {# errors}}</strong> with this <strong>{formTitle}</strong>",
  FORM_VALIDATION_WARNING_HEADER: "We found <strong>{warningCount, plural, one {# warning} other {# warnings}}</strong> with this <strong>{formTitle}</strong>",
  COPY_PLAN_HEADER: 'Copy "{benefitPlanName}" Plan',
  COPY_PLAN_DESCRIPTION: "A new plan will be created with the same settings, attachments, and enrollment configurations as the current plan, {benefitPlanName}.  Please make sure to update the new plan accordingly.",
  COPY_PLAN_BENEFIT_CLASS_SELECT_LABEL: "Which benefit class should the plan be copied to?",
  CHOOSE_A_BENEFIT_CLASS: "Choose a benefit class",
  PLAN_COPIED_SUCCESSFULLY: "Plan copied successfully",
  ERROR_COPYING_PLAN: "An error occurred while copying plan: {error}",
  RENEW: "Renew",
  RENEW_PLAN_HEADER: 'Renew "{benefitPlanName}" Plan',
  RENEW_PLAN_DESCRIPTION: "A new plan will be created with the same settings, attachments, and enrollment configurations as the current plan, {benefitPlanName}. Benefits will automatically be linked from the previous plan to the new plan to help with passive renewals and spending account rollovers.",
  PLAN_RENEWED_SUCCESSFULLY: "Plan renewed successfully",
  PLAN_START_DATE: "Start Date",
  PLAN_END_DATE: "End Date",
  ERROR_RENEWING_PLAN: "An error occurred while renewing plan: {error}",
  SEARCH: "Search",
  NO_INVOICES_BY_DIVISION_MESSAGE: "There are no invoices for the billing division(s) selected.",
  BENEFIT_SETS_BLURB: "Benefit sets are organized by the type of benefits that are added to the set. You'll be able to define this when you edit or add a new set.",
  BENEFIT_SETS_EMPTY_1: "You don't have any sets configured yet.",
  BENEFIT_SETS_EMPTY_2: "Add a set using the button in the top right corner.",
  ADD_NEW_BENEFIT_SET: "+ Add New Set",
  NEW: "New",
  INVOICE_STATUS_OUTSTANDING: "Outstanding",
  INVOICE_STATUS_PARTIALLY_PAID: "Partially Paid",
  YOU_HAVE_DAYS_LEFT: "You have <strong>{daysLeft} days</strong> left!",
  TODAY_IS_YOUR_LAST_DAY_TO_SUBMIT: "Today is your last day!",
  YOU_HAVE_UNTIL_THIS_DATE_TO_COMPLETE_YOUR_ENROLLMENT: "You have until {endDate} at {time} {timeZone} to submit your selections.",
  WAS_THE_LAST_DAY_TO_SUBMIT: "{endDate} at {time} {timeZone} was the last day to submit your selections.",
  FINANCE_REPORTS: "Finance Reports",
  FINANCE_INVOICING: "Invoicing",
  DAY: "Day",
  MONTH: "Month",
  YEAR: "Year",
  DAY_LOWERCASE: "day",
  MONTH_LOWERCASE: "month",
  YEAR_LOWERCASE: "year",
  FILE_DOWNLOAD: "File Download",
  COVERAGE_SUMMARY_UNAVAILABLE_MESSAGE: "This group isn't on the new enrollment experience (yet!) so we can't pull in their benefit details as a summary. Please take a look at the Benefits tab instead to see what benefits this user is enrolled in.",
  REQUEST_REPORT: "Request Report",
  CHOOSE_DATE: "Choose date",
  REPORT_FUTURE_DATE_ERROR_COPY: "Please select the current date or a date in the past.",
  REPORT_DATE_REQUIRED_ERROR_COPY: "Please select a date to generate the report.",
  LIFE_IS_UNPREDICTABLE: "Life is unpredictable but your benefits shouldn't be",
  IF_YOU_EXPERIENCED_A_CERTAIN_LIFE_EVENT: "If you\u2019ve experienced a certain life event like getting married or having a baby, you\u2019re eligible to make changes to your plan elections.",
  IF_YOU_DID_NOT_EXPERIENCE_A_QLE: "If you\u2019ve experienced another type of event ",
  WHAT_IS_A_QLE: "What is a qualifying life event?",
  WHEN_COVERAGE_TAKE_EFFECT: "When does the new coverage take effect?",
  CHECK_IF_I_QUALIFY: "Check if I Qualify",
  WHICH_APPLIES_TO_YOU: "Which applies to you?",
  QLE_MUST_BE_REPORTED_IN_TIME: "You have a certain amount of time to report a qualifying life event and complete your plan elections.",
  SOME_EXCEPTIONS_APPLY: "Some exceptions apply.",
  QUESTION_X_OF_Y: "QUESTION {x} OF {y}",
  CONTRIBUTIONS: "Tax-Advantaged Accounts",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYEE: "Your Contribution",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYER: "Employer Contribution",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYEE_TO_BENEFIT: "Your Contribution to {vendorName} {benefitName}",
  PRORATED_CONTRIBUTOR_CONTRIBUTION_EMPLOYEE: "Your Prorated Contribution",
  PRORATED_CONTRIBUTOR_CONTRIBUTION_EMPLOYER: "Prorated Employer Contribution",
  PRORATED_VALUE: "Prorated Value: ",
  PREVIOUS_ENROLLMENT_ALLOCATION: "Previous enrollment allocation",
  PREVIOUS_ENROLLMENT_ALLOCATION_TOOLTIP: "This is the total amount you have allocated to this account through your previous enrollments.",
  QLE_ALLOCATION_TOOLTIP_NOTE: "Note: It\u2019s not a live balance of your account, any transactions you\u2019ve made won\u2019t be reflected within this amount.",
  NO_FLEX_DOLLAR_TO_ADD: "You currently have no {flexDollarTerm} to add to this account.",
  NO_FLEX_DOLLAR_REMAINING: "You currently have no {flexDollarTerm} remaining",
  QLE_CONTRIBUTIONS_CHANGE_DISABLED: "You cannot make changes to your contribution amount for this account during a QLE.",
  NEW_ALLOCATION: "New Allocation",
  THIS_AMOUNT_IS_PRORATED: "This amount is prorated",
  LEARN_MORE_ABOUT_PRORATED_FUNDS: "Learn more about prorated funds",
  TOTAL_ALLOCATION_FOR_THIS_PERIOD: "Total allocation for this policy period",
  TOTAL_ALLOCATION_FOR_THIS_PERIOD_TOOLTIP: "This is a sum of your previous enrollment allocation and your new allocation.",
  YOU_ARE_OUTSIDE: "You\u2019re outside of the available window for this life event.",
  HOWEVER_STILL_QUALIFY: "However, you might still qualify. We\u2019ll just need a bit more info to get you started. An agent is waiting to help you.",
  BACK: "Back",
  NEXT: "Next",
  PREVIOUS: "Previous",
  SOME_EXCEPTIONS_APPLY_SEE_ASTERISK: "Some exceptions apply. Fields marked with an asterisk<em>*</em> are required.",
  DATE_OF_MARRIAGE: "Date of Marriage",
  MISSING_QLE_DATA: "Missing qualifying life event information.",
  REASON_FOR_WAIVING_HEADING: "Do you have alternate coverage?",
  REASON_FOR_WAIVING_DISCLAIMER: "Your health is important, and {employerName} wants to make sure you\u2019re covered. In order to waive this benefit, you must certify that you have alternate coverage through your spouse or partner's insurance.",
  WAIVE_ACKNOWLEDGED_LABEL: "Yes, I have alternate coverage",
  REASON_FOR_WAIVING_PLACEHOLDER: "Please specify the insurance carrier and the policy number you're covered under.",
  OPT_OUT_HEADING_USA: "Are you sure you want to waive {benefitName} coverage? ",
  OPT_OUT_DISCLAIMER_USA: "If you choose to waive coverage, you will not be able to enroll again until the next annual enrollment period unless you or one of your eligible dependents experience a qualifying life event.",
  OPT_OUT_ACKNOWLEDGED_LABEL_USA: "Yes, I want to waive {benefitName} coverage",
  OPT_OUT_REASON_PLACEHOLDER_USA: "Let us know your reason for declining coverage",
  OPT_OUT_DOCUMENTS: "To waive coverage, you must also submit proof of coverage. <a>Here are some instructions</a> to help guide you through this.",
  OPT_OUT_DOCUMENTS_LINK_LABEL: "View instructions on how to submit proof of coverage",
  OPT_OUT_BONUS: "Already have coverage? {employerName} will provide you an extra <strong>{optOutBonusAmount}</strong> if you waive coverage.",
  OPT_OUT_BONUS_PER_PAY_PERIOD: "Already have coverage? {employerName} will provide you an extra <strong>{optOutBonusAmount} / {periodType}</strong> if you waive coverage.",
  SELECTION_REASON_DISCLAIMER: "Your health is important, and {employerName} wants to make sure you're covered. The plan you selected offers very little to no coverage, and we need you to certify you have alternate coverage through your spouse or partner's insurance.",
  HELP_MODAL_HEADER: "Our team is here to help",
  HELP_MODAL_DESCRIPTION: "Whether it's a question about your coverage or if something isn't working quite like you'd expect it, we want to hear from you!",
  CONTACT_AGENTS_TITLE: "Email",
  CONTACT_AGENTS_DESCRIPTION: "Our customer care agents can be reached out at the email {email}",
  HELP_CENTER: "Help Center",
  HELP_CENTER_DESCRIPTION: "You can also visit our {helpCenter} where common questions are answered.",
  LIVE_CHAT_TITLE: "Live Chat",
  LIVE_CHAT_DESCRIPTION: "You can live chat with our agents on your desktop device!",
  NAVIGATION_STEPS_MENU: "Navigation Steps Menu",
  NAVIGATION_STEPS_MENU_ERROR: "Navigation Steps have errors",
  CLICK_HERE_FOR_HELP: "Click here for help.",
  INSURED_BENEFIT_DETAILS: "Insured Benefit Details",
  YOU_CAN_ADD_A_DEPENDENT: "You can add a dependent to your Insured Benefits (like Medical, Vision, Dental, Life etc.) If you experience a Qualifying Life Event such as getting married or having a baby.",
  FINE_PRINT: "If you also have a Health Spending account, dependents added to your Insured Benefits will be reflected there as well.",
  BETA: "BETA",
  GENERAL_INFO: "General Information",
  BENEFIT_PLAN_YEAR: "Benefit Plan Year",
  WE_ARE_HERE_TO_HELP: "We're here to help",
  BENEFIT_GUIDES: "Benefit Guides",
  FULL_TIME_EMPLOYEES_BENEFIT_GUIDES: "Full Time Employees Benefit Guides",
  PART_TIME_EMPLOYEES_BENEFIT_GUIDES: "Part Time Employees Benefit Guides",
  BENEFIT_PROGRAM_DESCRIPTION: "Lush is pleased to provide you with an enhanced benefit program. This is an <strong>ACTIVE</strong> enrollment which means if you do not complete enrollment, no past elections will rollover and you will not be covered for the {planStartYear}-{planEndYear} plan year. This self-guided process will only take a few minutes",
  UNTIL_WHEN_CAN_I_ENROLL: "You have until <strong>{enrollmentEndDate}</strong> to submit your benefit elections. If you do not complete this enrollment process, your {prevPlanStartYear}-{prevPlanEndYear} plan year elections will not rollover and you will not be covered for the {planStartYear}-{planEndYear} plan year. <strong>Please make sure you submit your elections.</strong>",
  READY_TO_ENROLL: "Ready to enroll in benefits?",
  ENROLLMENT_WELCOME: "Welcome to the Lush US enrollment experience.",
  ENROLLMENT_WELCOME_DESCRIPTION: "Throughout this enrollment process we'll guide you through your plan options so you fully understand what's being offered to you by <strong>Lush US</strong>.",
  BENEFIT_GUIDES_DESCRIPTION: "We've prepared an overview of your benefit offerings in this simple and easy-to-read guide. We recommend using this reference throughout the enrollment experience.",
  FULL_TIME_EMPLOYEES: "Full-Time Employees",
  PART_TIME_EMPLOYEES: "Part-Time Employees",
  DATE_WINDOW: "<strong>{startDate}</strong> to <strong>{endDate}</strong>",
  MY_DEPENDENTS: "My Dependents",
  YOU_HAVE_NO_DEPENDENTS: "You don\u2019t have any dependents.",
  FINE_PRINT_INSURED: "If you also have a Health Spending Account, dependents added to your Insured Benefits will be reflected there as well.",
  HEALTH_SPENDING_ACCOUNTS: "Health Spending Accounts",
  IF_YOU_ARE_CARING_FOR_A_LOVED_ONE: "If you are caring for a loved that is financially dependent on you, such as a spouse, parent, grandparent or grandchild, you can add them to your HSA at anytime.",
  FINE_PRINT_HSA: "All dependents must live in Canada (with the exception of children and grandchildren) and be financially dependent on you to qualify.",
  ADD_A_DEPENDENT: "Add a dependent",
  WHICH_PLAN_WOULD_YOU_LIKE: "Which plan would you like to add a dependent to?",
  LETS_GET_TO_KNOW: "Let\u2019s get to know your loved ones!",
  IF_YOU_DONT_HAVE_ANYONE: "If you don\u2019t have anyone to add you can skip this step.",
  REVIEW_PLANS: "Review Plans",
  NEXT_UP_REVIEW_PLANS: "Next Up: Review your Plans",
  BACK_HOME: "Back Home",
  CHOOSE_YOUR_BENEFITS: "Choose your benefits",
  BACK_TO_MANAGE_DEPENDENTS: "Back to Manage Dependents",
  CUSTOMIZE_YOUR_BENEFITS: "Customize your benefit plans, please make your selections by filling out the form below. ",
  SORRY_ERROR_ONLOAD_PLEASE_REFRESH: "Sorry! Try to refresh the page.",
  SORRY_REQUEST_HAS_EXPIRED: "Sorry! The window for this request has expired.",
  ENROLLMENT_PROGRESS_REPORT: "Enrollment Progress Report",
  ENROLLMENT_PROGRESS: "Enrollment Progress",
  ENROLLMENT_PROGRESS_REPORT_DESCRIPTION: "This report provides the enrollment status of each employee collected by League to date.",
  DOWNLOAD_ENROLLMENT_PROGRESS_REPORT: "Download Enrollment Progress Report",
  INFO_SUCCESSFULLY_SENT: "Information successfully sent!",
  EMPLOYEE_DEMOGRAPHICS_REPORT: "Employee Demographics Report",
  EMPLOYEE_DEMOGRAPHICS: "Employee Demographics",
  EMPLOYEE_DEMOGRAPHICS_REPORT_DESCRIPTION: "This report provides details on demographic information of all employees collected by League to date.",
  DOWNLOAD_EMPLOYEE_DEMOGRAPHICS_REPORT: "Download Employee Demographics Report",
  EMPLOYER_BENEFITS_REPORT: "Benefits Report",
  EMPLOYER_BENEFITS_REPORT_DESCRIPTION: "This report contains a subset of the data that exists today in the enrollment report, and will be formatted with a single user benefit per row.",
  DOWNLOAD_EMPLOYER_BENEFITS_REPORT: "Download Benefits Report",
  CARRIER_AUDITS: "Carrier Audits",
  CARRIER_AUDITS_REPORT: "Carrier Audits Report",
  CARRIER_AUDITS_REPORTS_DESCRIPTION: "Fill all the fields below to request a report. The request will take some time to complete. After that, the report will open in a new window.",
  FLOW_TYPE: "Flow Type",
  DOCUMENT: "Document",
  VIEW_DOCUMENT: "View Document:",
  REQUEST_DATE: "Request Date",
  CHOOSE_FILE: "Choose file",
  DEPENDENTS_REPORT: "Dependents Report",
  DEPENDENTS_REPORT_DESCRIPTION: "This report contains a subset of the data that exists today in the enrollment report, and will be formatted with a single dependent per row.",
  SORRY_LET_US_TRY_AGAIN: "Sorry, let us try again.",
  SOMETHING_WENT_WRONG: "Something went wrong while logging you in. Please try again or {emailLink}.",
  CONTACT_US: "contact us",
  LOG_IN_MANUALLY: "Log in manually",
  CATEGORIES: "Categories",
  FEATURED: "Featured",
  OUR_HEALTH_PROGRAMS_HELP: "Our Health Programs help you proactively reach your health and wellness goals.",
  ACTIVE_PROGRAM: "Active {length, plural, one {Program} other {Programs}}",
  ACTIVE_CHALLENGE: "Active {length, plural, one {Challenge} other {Challenges}}",
  START_PROGRAM: "Start Program",
  HAVING_TROUBLE: "Having trouble finding the right program for you? Chat with a <strong>Registered Nurse!</strong>",
  LETS_CHAT: "Let's Chat",
  BACK_TO_HEALTH: "Back to Health",
  DAY_NUMBER: "DAY {number}",
  VIEW_MY_PROGRESS: "View My Progress",
  VIEW_PROGRAM_ACTIVITIES: "View Program Activities",
  PROGRAM_PROGRESS: "Program Progress",
  PROGRAM_ACTIVITIES: "Program Activities",
  PROGRAM_TYPE: "Program type:",
  MULTI_SELECT_DEFAULT_CAN_CREATE: "You can also type to create a new option!",
  MULTI_SELECT_DEFAULT_PLACEHOLDER: "Select your options",
  ACTIVITIES_THIS_WEEK: "Activities This Week",
  START_THIS_PROGRAM_TO_UNLOCK_ACTIVITIES: "Start this program to unlock these activities!",
  DAY_OF_WEEK: "Day {day} of {week}",
  HEALTH_PROGRAMS_RECOMMENDED_FOR_YOU: "Health Programs recommended for you",
  BROWSE_HEALTH_PROGRAM_CATEGORIES: "Browse health program categories",
  LOOKING_FOR_SOMETHING_ELSE: "Looking for something else?",
  REGULAR_PRICE: "Reg {regularPrice}",
  PLUS_MORE: "{date} +MORE",
  NUMBER_MINS: " \u2022 {durationValue}mins",
  DOLLAR_OFF: "{discount} OFF",
  LEAGUE_MEMBER_EXCLUSIVE: "LEAGUE MEMBER EXCLUSIVE",
  QLE_CONFIGURATIONS: "QLE Configurations",
  QLE_CONFIG: "QLE Config",
  ALLOW_INFINITE_NUMBER_OF_DAYS: "Allow infinite number of days (\u221E)",
  NUMBER_OF_DAYS_MUST_BE_GREATER_THAN_0: "Number of days must be greater than 0",
  INFINITE_DAYS: "\u221E infinite days",
  QLE_REPORTING_WINDOW: "QLE Reporting Window",
  WHEN_A_MEMBER_REPORTS_A_QLE_TO_LEAGUE: "When a member reports a QLE to League, they input the event day of that QLE. You can configure the available window a member has to report it preceding and following the QLE event day.",
  ALLOW_MEMBERS_TO_REPORT_THE_QLE_EVENT_DATE: "Allow members to report {followOrPrecede} the QLE event date?",
  FOLLOWING: "FOLLOWING",
  PRECEDING: "PRECEDING",
  FOLLOWING_LOWERCASE: "following",
  PRECEDING_LOWERCASE: "preceding",
  NUMBER_OF_DAYS_ALLOWED_PRECEDING: "# of days allowed preceding the QLE event date",
  NUMBER_OF_DAYS_ALLOWED_FOLLOWING: "Number of days allowed to report",
  FULL_TIME_CLASS: "Full-time class",
  REQUIRES_APPROVAL_FROM_HR: "Requires approval from HR?",
  HR_EMAIL: "HR email address(es)",
  SEPARATE_MULTIPLE_EMAIL: "Separate multiple email addresses by a comma",
  PLEASE_ENTER_THE_EMAIL: "Please enter the email address(es) of the recipient(s) that should be notified for approval.",
  CUSTOM_CONFIGURATION: "Custom Configuration",
  CUSTOM_CONFIGURATION_BLURB: "When you configure a custom or partial QLE, the member does not go back to enrollment in NEX. You can enable manage dependents (members can add/remove their dependents) and/or Typeform (members update plan elections). If you enable Typeform, paste your typeform link in the below field.",
  ENABLE_MANAGE_DEPENDENTS: "Enable manage dependents?",
  ENABLE_TYPEFORM: "Enable Typeform?",
  ALLOW_MEMBERS_TO_CHANGE_DEPENDENTS: "Allow members to ",
  LOWERCASE_DEPENDENTS: "dependents",
  ADD_CAPS: "ADD",
  REMOVE_CAPS: "REMOVE",
  TYPEFORM_URL_CAPS: "Typeform URL:",
  PASTE_TYPEFORM_URL_HERE: "Paste typeform URL here",
  WHERE_SHOULD_MEMBERS_BE_DIRECTED: "Where should members be directed?",
  SEND_MEMBERS_TO_ENROLLMENT_IN_NEX_FULL_QLE: "Send members to Enrollment in NEX (full QLE\u2019s)",
  SEND_MEMBERS_TO_MANAGE_DEPENDENTS_OR_TYPEFORM: "Custom Configuration - Send members to Manage Dependents/Typeform (partial QLE\u2019s/Typeform Enrollment)",
  QLE_CONFIG_FORM_ERROR_REPORTING_WINDOW: "Please select a reporting window. You must configure at least 1 of the above options",
  QLE_CONFIG_FORM_ERROR_MISSING_HR_EMAIL: "Please enter the email address(es) of the recipient(s) that should be notified for approval.",
  QLE_CONFIG_FORM_ERROR_MISSING_NON_NEX_CONFIG: "Please select a configuration. You must configure at least 1 of the above options",
  TURN_THIS_QLE: "Turn {toggle} this QLE",
  TURN: "Turn {toggle}",
  ARE_YOU_SURE_TURN_QLE: "Are you sure you want to turn this QLE type {toggle}?",
  TURN_OFF_IMPLICATIONS: "By turning this QLE off, your configurations will be disabled. <strong>Once you hit submit</strong>, members will no longer be able to view/report this QLE type in the member experience.",
  TURN_ON_IMPLICATIONS: "By turning this QLE on, your configurations will be enabled. <strong>Once you hit submit</strong>, members will be able to view/report this QLE type in the member experience.",
  NO_ITEMS_OF_NAME: "No {name}",
  INVOICE_HEADER_INVOICE_DATE: "Invoice Date",
  EXPORT_PLAN_DESIGN: "Export Plan Design",
  EXPORT_CUSTOM_PLAN: "Export Custom Plan",
  EXPORT_BENEFIT_IDS: "Export with Benefit IDs",
  FILTER: "Filter",
  CLEAR_FILTERS: "Clear Filters",
  IMPORT_PLAN_DESIGN_DESCRIPTION: "Importing a plan design generates classes, plans and benefits from a Google Sheet template. It will either create or update a benefit based on the Benefit ID field being empty or not. Importing a plan won\u2019t delete any data, only create or update.",
  EXPORT_PLAN_DESIGN_DESCRIPTION: "Exporting a plan design will generate a Google Sheet with the existing benefit configuration. You can use this Sheet to update the current plan and then import it back into our Admin tool. You can also use it to create new benefits for a new year.",
  EXPORT_CUSTOM_PLAN_DESCRIPTION: "Select the benefits that you would like to export.",
  AVAILABLE_BENEFITS_DESCRIPTION: "All the benefits that have already been added to this group will show up here.",
  CUSTOM_PLAN: "Custom Plan",
  CUSTOM_PLAN_DESCRIPTION: "These benefits will be exported into a new Google Sheet.",
  EXPORT_PLAN_DESIGN_SUCCESS: "Done! We exported the benefits, and the Google Sheet is now ready for you.",
  IMPORT_PLAN_DESIGN_SUCCESS: "Done! We imported the Google Sheet, and the group benefits have been updated.",
  VIEW_BENEFITS: "View Benefits",
  NOT_VERIFIED_HEADING: "You aren't verified yet",
  NOT_VERIFIED_TEXT: "Before you can get started, we need some more information to help you create your League account.",
  INVITE_EXPIRED_HEADING: "Your verification expired",
  INVITE_EXPIRED_TEXT: "Your invite has unfortunately expired. But don\u2019t worry, you just need to confirm your identity again before you can proceed with creating an account. After verifying, you have 1 hour to create your account.",
  START_VERIFICATION: "Start Verification",
  FIND_ACCOUNT: "Find Account",
  SAY_HELLO_TO_LEAGUE: "Say hello to League",
  IF_ELIGIBLE_FOR_BENEFITS: "Activate your League account to unlock your personalized health and wellness experience. First, let us know if you are eligible for benefits.",
  IF_YOURE_NOT_SURE: "Not sure if you\u2019re eligible? Reach out to your store admin to find out.",
  ARE_YOU_ELIGIBLE_FOR_BENEFITS: "Are you eligible for benefits?",
  YES_I_AM_ELIGIBLE: "Yes, I am eligible",
  NO_I_AM_NOT_ELIGIBLE: "No, I am not eligible",
  YOU_ARE_ELIGIBLE_IF: "<p>You are eligible for benefits if you are offered Health and Dental coverage by your employer.</p><p>You are benefits eligible even if you have opted out of these benefits.</p>",
  SELECT_ONE_OF_THE_OPTIONS: "Please select one of the options above.",
  FIRST_NAME: "First Name",
  ALLOW_FOLLOWING_QLE_DATE_TOOLTIP: "This allows members to report their QLE after it has occurred, i.e in the past.",
  ALLOW_PRECEDING_QLE_DATE_TOOLTIP: "This allows members to report their QLE before it has occurred, i.e in the future.",
  QLE_REQUIRES_HR_TOOLTIP: "This determines whether a member can proceed directly into updating their benefits or if they require HR approval first.",
  WHERE_TO_DIRECT_MEMBERS_TOOLTIP: "This determines whether a member gets sent back to enrollment in NEX (currently only available if this is a full QLE) or if it\u2019s a custom configuration which you can configure to enable manage dependents and/or Typeform (current handling of partial QLE\u2019s).",
  ENABLE_MANAGE_DEPENDENTS_TOOLTIP: "This determines whether a member can proceed to the manage dependents page where they can add and/or remove dependents. Disable this for QLEs that do not allow members to update their dependents.",
  ENABLE_TYPEFORM_TOOLTIP: "This determines whether a member can update their benefit plan elections. This is provided by Ops and can be customized per QLE type and benefit class. Disable this for QLEs that do not allow members to update their plan elections.",
  BLOCK_TOGGLE_NODE_MODAL_HEADER: "You have unsaved changes on this form",
  BLOCK_TOGGLE_NODE_MODAL_BODY: "We noticed you made some changes that haven't been saved. Make sure to save your changes before moving on. <br/><br/>If you don't want to save anything you've changed or updated, you can Reset the form to revert back to it's previous version.",
  ADD_AN_ITEM: "Add an item",
  CONFIRM_REMOVE_ITEM: 'Are you sure you want to remove "{itemName}"?',
  CANT_UNDO: "You will not be able to undo this action.",
  BENEFIT: "benefit",
  COORDINATION_OF_BENEFITS: "Coordination of Benefits",
  COORDINATION_OF_BENEFITS_SUBTITLE: "Sometimes two insurance plans work together to pay claims for the same person. This is called coordination of benefits and it helps you get more of your claim costs covered.",
  EXISTING_COVERAGE_DRUG: "Your spouse's existing drug coverage.",
  EXISTING_COVERAGE_VISION: "Your spouse's existing vision coverage.",
  EXISTING_COVERAGE_DENTAL: "Your spouse's existing dental coverage.",
  EXISTING_COVERAGE_HEALTH: "Your spouse's existing health coverage.",
  COORDINATION_OF_BENEFITS_EXPLANATION_TITLE: "What does this mean for my claims?",
  COORDINATION_OF_BENEFITS_EXPLANATION: "When you submit a claim, your insurance plan will be the <strong>primary payor</strong>, and if your partner has an insurance plan that covers you, it will be the <strong>secondary payor</strong>. This means that any claim you submit, your plan will pay for it first, and any remainder will be paid by your partner\u2019s plan.",
  LEARN_MORE_ABOUT_COORDINATION_OF_BENEFITS: "Learn more about Coordination of Benefits",
  TOTAL_ALLOCATION: "Total Allocation",
  PERSONAL_ALLOCATION: "Personal Allocation",
  EMPLOYER_ALLOCATION: "Employer Allocation",
  CURRENT_COVERAGE: "Current Coverage",
  SEE_CURRENT_COVERAGE: "See Current Coverage",
  YOUR_CURRENT_PLAN_COST: "Your current plan cost",
  YOUR_CURRENT_PLAN: "Your Current Plan",
  CURRENT_COVERAGE_INFO: "This is a summary of your current benefits. We thought this would make your enrollment selections a little easier.",
  COVERAGE_FOR: "Coverage For",
  COST: "Cost",
  COVERAGE: "Coverage",
  TOTAL_COST: "Total Cost",
  APPROVED_COVERAGE: "Approved Coverage",
  APPROVED_COVERAGE_TOOLTIP: "This is the amount of coverage you're currently approved for. You\u2019ll only be charged for this coverage until your Evidence of Insurability form has been submitted to and approved by your carrier.",
  APPROVED_COVERAGE_EOI_BANNER: "You\u2019ll only be charged for coverage once it's been approved by your carrier following submission of your {eoiTooltip} form. If you have existing coverage in place, you\u2019ll continue to pay premiums for these approved amounts.",
  NICE_TO_SEE_YOU: "Nice to see you, {name}",
  WE_FOUND_YOUR_ACCOUNT: "We found your account. Let's finish up by creating your password.",
  CREATE_PASSWORD: "Create Password",
  YOUR_ACCOUNT_IS_ALREADY_ACTIVE: "Your account is already active",
  LOOKS_LIKE_YOU_ALREADY_ACTIVATED: "It looks like you already activated your account.",
  IF_THIS_WASNT_YOU: "If this wasn't you or if you have any questions, reach out to",
  SIGN_IN: "Sign in",
  CHAT_HEADING: "Chat",
  CHAT_DESCRIPTION: "Get speedy answers to all your health and benefits questions.",
  SET_EMPLOYER_BENEFIT_PRICE_CONFIG_SUCCESS_MESSAGE: "Benefit price configuration saved successfully",
  PRIVACY_POLICY: "Privacy Policy",
  WHERE_CAN_I_FIND_MY: "Where can I find my",
  NUMBER_OF_BENEFITS: "{number} Benefits",
  IDENT_VALUE: "{value}",
  IDENT_AMOUNT: "{amount}",
  BENEFIT_NAME_WITH_DEPENDENT_COVERAGE: "{benefitName} ({dependentCoverage})",
  FOR_YOU: "For you",
  FOR_YOU_SPOUSE: "For you and your spouse",
  FOR_YOU_SPOUSE_CHILDREN: "For you, your spouse, and your child(ren)",
  FOR_YOU_CHILDREN: "For you and your children",
  FOR_YOU_ONE_CHILD: "For you and your child",
  CAROUSEL_ALL_IN_ONE_HEADING: "All-in One App",
  CAROUSEL_ALL_IN_ONE_DESCRIPTION: "Easily stay on top of your health, wellness and benefits.",
  CAROUSEL_JOURNEY_HEADING: "Journey",
  CAROUSEL_JOURNEY_DESCRIPTION: "Reach your goals with a personalized health journey.",
  CAROUSEL_CHAT_HEADING: "Live chat",
  CAROUSEL_CHAT_DESCRIPTION: "Get speedy answers to all your health and benefits questions.",
  CAROUSEL_DIGITAL_WALLET_HEADING: "Digital Wallet",
  CAROUSEL_DIGITAL_WALLET_DESCRIPTION: "Access all your benefits and spending accounts, in one place.",
  CAROUSEL_LIFEMARKET_HEADING: "Lifemarket",
  CAROUSEL_LIFEMARKET_DESCRIPTION: "Exclusive offers and freebies for your health, fitness and lifestyle.",
  PRICING_CONFIG_SAVE_HEADER: "Confirm save benefit pricing configuration",
  PRICING_CONFIG_SAVE_CONTENT: "<strong>Note:</strong> Saving a pricing configuration will take effect immediately on the chosen employee benefit {benefitId}",
  MEMBER_POLICY_ID_ERROR: "Something went wrong on our end. Please try again.",
  MEMBER_POLICY_ID_SUCCESS: "Card Info Saved! View it anytime in your wallet.",
  MEMBER_POLICY_ID_DELETED: "Card info successfully deleted",
  BENEFIT_SELECTION_INFO: "<strong>Benefit Id</strong>: {benefitId} </br> <strong>Benefit Type</strong>: {benefitType}",
  BENEFIT_ID: "Benefit ID",
  BENEFIT_TYPE: "Benefit Type",
  WE_COULDNT_FIND_YOU: "We couldn't find you. Please double check your information above and try again.",
  WE_COULDNT_FIND_YOU_DESCRIPTION: "If you still can\u2019t get access, send an email to {emailLink} for additional support.",
  YOURE_A_TAD_EARLY: "You\u2019re a tad early. Please check back on {enrollmentStartDate} (registration start date) to activate your account with League.",
  YOURE_A_TAD_EARLY_NO_DATE: "You\u2019re a tad early. Please check back on the registration start date to activate your account with League.",
  UH_OH_SOMETHING_WENT_WRONG: "Uh oh! Something went wrong",
  SORRY_WE_CANT_FIND_PAGE: "We\u2019re sorry, we can\u2019t find the page you\u2019re looking for.",
  IF_YOU_NEED_HELP_EMAIL_US: "If you need help or have questions about your account, reach out to {emailLink}.",
  PLEASE_ENTER_A_VALID_EMAIL: "Please enter a valid email address",
  EXPORT_PRICING_CONFIG_TO_CSV: "Export Group Config To CSV",
  PRICING_CONFIG_DOWNLOAD: "Download Group Pricing Config: ",
  USER_PROFILE: "User Profile",
  HEALTH_PROFILE: "Health Profile",
  HEALTH_PROGRAM: "Health Program",
  HEALTH_PROGRAMS: "Health Programs",
  HEALTH_ACTIVITIES: "Health Activities",
  HOME: "Home",
  INPUT_USER_ID: "Please input a User ID",
  ENTER: "Enter",
  RISK_FACTOR_INPUTS: "Risk Factor Inputs",
  RISK_FACTOR: "Risk Factor",
  USER_INPUT: "User Input",
  RISK_FACTOR_RESULTS: "Risk Factor Results",
  RISK: "Risk",
  AT_RISK: "At Risk",
  AT_RISK_DESCRIPTION: "At Risk Description",
  DEFAULT_UNITS: "Default Units",
  STEPS: "steps",
  ACTIVE_DURATION: "active minutes",
  MINDFUL_DURATION: "mindful minutes",
  FLOORS_CLIMBED: "flights climbed",
  DEVICE: "device",
  DISTANCE: "distance traveled",
  ENERGY_BURNED: "calories burned",
  METRIC_DETAILS: "Metric Details",
  HEALTH_PROGRAMS_SYNC_DEVICE: "Sync your {device} for the optimal program experience.",
  HEALTH_PROGRAMS_CONNECTED_PROGRAM: "Connected health program",
  TRACK_AUTOMATICALLY: "Track Your Progress Automatically",
  SYNC_ISSUE: "SYNC ISSUE",
  FIX_SYNC_ISSUE: "Fix this issue",
  DATAPOINT_SYNC_ISSUE: "There was an issue syncing your {dataPoint}.",
  CONNECT_TO_TRACK: "Connect your favourite health apps and devices to automatically mark activities as complete.",
  CONNECT_APPS_DEVICES: "Connect apps & devices",
  ADD_APPS_DEVICES: "View Apps & Devices",
  MANAGE_UNITS: "Change units of measurement",
  DONT_CONNECT_APPS_DEVICES: "Don't track my progress automatically",
  CONNECTING_UNAVAILABLE: "Connecting apps and devices is not available on the web",
  CONNECTING_UNAVAILABLE_DETAILS: "For now, please visit the League mobile app to connect your favourite health apps and devices.",
  CONNECTING_UNAVAILABLE_DETAILS_WITH_LINK: "For now, please visit the League mobile app to connect your favourite health apps and devices. If you\u2019d like to learn more, you can find a full list of the",
  CONNECT_DEVICES_LEARN_MORE: "Learn More",
  APPS_AND_DEVICES_CURRENTLY_SUPPORTED: "apps and devices currently supported",
  DONT_HAVE_THE_APP: "Don\u2019t have the League mobile app?",
  WHAT_DEVICES_CAN_CONNECT: "What apps and devices are supported?",
  CONNECTED_DEVICES_IMAGE_ALT: "Person reviewing data from connected device",
  CONNECT_DEVICES_IMAGE_ALT: "Woman reviewing data from connected device",
  CERTIFICATION_PROGRAM_DISCOUNT_AVAILABLE: "You could receive a discount of <strong>{discount}</strong> from your <strong>{benefitSetName}</strong> plan if you complete <strong>{name}</strong> within {deadlineDays} of your enrollment date.",
  CERTIFICATION_PROGRAM_DISCOUNT_APPLIED: "Congrats on completing the {name} - We applied a discount of <strong>{discount}</strong> to your {benefitSetName} plan options.",
  CERTIFICATION_DESCRIPTION_ALTERNATE_COVERAGE: "You must certify whether your spouse or partner has medical coverage offered through their employer. A surcharge will be applied to the overall cost of your chosen medical plan if you certify <strong>Yes</strong>",
  CERTIFICATION_LINK_ALTERNATE_COVERAGE: "Learn More",
  LEARN_MORE_ABOUT_THIS_SURCHARGE: "Learn more about this surcharge",
  CERTIFICATION_OPTION_YES_ALTERNATE_COVERAGE: "<strong>Yes</strong> - My partner has access to employer provided coverage",
  CERTIFICATION_OPTION_NO_ALTERNATE_COVERAGE: "<strong>No</strong> - My partner does not have access to employer provided coverage",
  CERTIFICATION_TITLE_ALTERNATE_COVERAGE: "Does your spouse or partner have access to coverage elsewhere?",
  CERTIFICATION_DESCRIPTION_MEDICARE: "If you are enrolled in Medicare (Parts A, B, C, and/or D), you would be disqualified from making or receiving employer contributions to a {ineligibleBenefitIfSelectedName} ({ineligibleBenefitIfSelectedShortName}). You can still enroll in an {ineligibleBenefitIfSelectedShortName}-qualified medical plan \u2013 you just won\u2019t be enrolled in an {ineligibleBenefitIfSelectedShortName}.",
  CERTIFICATION_DESCRIPTION_MEDICARE_WITH_FALLBACK: "If you are enrolled in Medicare (Parts A, B, C, and/or D), you would be disqualified from making or receiving employer contributions to a {ineligibleBenefitIfSelectedName} ({ineligibleBenefitIfSelectedShortName}). You can still enroll in an {ineligibleBenefitIfSelectedShortName}-qualified medical plan, but instead of receiving an {ineligibleBenefitIfSelectedShortName}, you will be enrolled in a {eligibleBenefitIfSelectedName} ({eligibleBenefitIfSelectedShortName}).",
  CERTIFICATION_DESCRIPTION_TOOLTIP_MEDICARE: "HRAs allow {employerName} to set aside funds to reimburse you for qualified medical expenses; you will receive the Company funds in your HRA that you would have otherwise received in a Health Savings Account.",
  CERTIFICATION_DESCRIPTION_MEDICARE_CONTACT: "If you have any questions or decide in the future to enroll in Medicare, please contact <a>help@league.com</a>.",
  CERTIFICATION_LINK_MEDICARE: "Learn More",
  LEARN_MORE_ABOUT_MEDICARE: "Learn more about Medicare",
  CERTIFICATION_OPTION_YES_MEDICARE: "<strong>Yes</strong> - I am enrolled in Medicare",
  CERTIFICATION_OPTION_NO_MEDICARE: "<strong>No</strong> - I\u2019m not enrolled in Medicare",
  CERTIFICATION_TITLE_MEDICARE: "Are you enrolled in Medicare?",
  CERTIFICATION_TITLE_TOBACCO_CESSATION_MYSELF: "Do you use tobacco?",
  CERTIFICATION_TITLE_TOBACCO_CESSATION_DEPENDENTS: "Do you or your dependents use tobacco?",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION: "You must certify whether or not you or your dependents over the age of 18 use tobacco in order to enroll in medical coverage. A charge of <strong>{amount}</strong> will be applied to your medical plan cost if you certify <strong>Yes</strong>.",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION_MYSELF: "If you participate in {name} the tobacco charge will no longer apply to the cost of your medical plan.",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION_DEPENDENTS: "If you and your dependent(s) participate in {name} the tobacco charge will no longer apply to the cost of your medical plan.",
  CERTIFICATION_LINK_TOBACCO_CESSATION: "Need help quitting? Learn more here",
  CERTIFICATION_OPTION_YES_TOBACCO_CESSATION_MYSELF: "<strong>Yes</strong> - I use tobacco",
  CERTIFICATION_OPTION_NO_TOBACCO_CESSATION_MYSELF: "<strong>No</strong> - I don't use tobacco",
  CERTIFICATION_OPTION_YES_TOBACCO_CESSATION_DEPENDENTS: "<strong>Yes</strong> - I and/or my dependents use tobacco",
  CERTIFICATION_OPTION_NO_TOBACCO_CESSATION_DEPENDENTS: "<strong>No</strong> - Neither I nor my dependents use tobacco",
  CERTIFICATION_TITLE_TOOLTIP_TOBACCO_CESSATION: "<p>You qualify as a tobacco user if you have used tobacco more than <strong>once per month on average over the past 6 months</strong>.</p><p>Using tobacco includes smoking, chewing, sniffing, and/or vaping. This surcharge is designed to promote good health and to offset a portion of the higher-than-average healthcare costs incurred from tobacco users.</p>",
  CERTIFICATION_ERROR_REQUIRED: "This information is needed in order to proceed",
  AMOUNT_PER_PERIOD: "{amount} / {period}",
  USES_TOBACCO: "Uses Tobacco",
  CHILD_OF_DOMESTIC_PARTNER: "Child of Domestic Partner",
  BENEFITS_TERMINATION_DATE: "Benefits Termination Date",
  TERMINATION_REASON: "Termination Reason",
  DEPENDENT_TERMINATION_PENDING: "Becomes inactive {terminationDate}",
  REMOVE_DEPENDENT_MODAL_TITLE: "Remove {dependentName}",
  ADD_QLE_TYPE: "Add QLE Type",
  ADD_QLE_DATE: "Add QLE Event Date",
  REMOVE_QLE_DATE: "Remove QLE Event Date",
  VERIFICATION_STATUS: "Verification Status",
  VERIFICATION_STATUS_EFFECTIVE_DATE: "Verification Status Effective Date",
  VERIFIED: "Verified",
  BASIC_INFO: "Basic Info",
  DEPENDENT_DETAILS: "Dependent Info",
  REMOVE_DEPENDENT_MODAL_MESSAGE: "Please identify a Benefits Termination Date and Termination Reason to remove <strong>{dependentName}</strong> as a dependent of <strong>{employeeName}</strong>.",
  DEPENDENT_TOBACCO_USER: "Is this dependent a tobacco user?",
  DEPENDENT_FULL_TIME_STUDENT: "Is this child a full-time student?",
  DEPENDENT_OVERAGE_DISABLED_CHILD: "Is this child an over-age disabled dependent?",
  DEPENDENT_CHILD_OF_DOMESTIC_PARTNER: "Is this dependent the child of a domestic partner?",
  LOST_COVERAGE: "Lost Coverage",
  PREFERENCES: "Preferences",
  UPDATE_GROUP_CATEGORIES: "Update Categories",
  REMOVE_GROUP_CATEGORIES: "Remove Categories",
  REMOVE_GROUP_CATEGORIES_SUCCESS: "Categories group successfully removed",
  UPDATE_GROUP_CATEGORIES_DESCRIPTION: "Please select a CSV file to use to update this spending account's coverage (it may take a moment to process).",
  UPDATE_GROUP_CATEGORIES_SUCCESS: "Coverage list successfully updated",
  IN_ENROLLMENT: "In Enrollment",
  ENROLLMENT_CLOSED: "Enrollment Closed",
  SUBMITTED_STATE: "Submitted State:",
  LAST_SUBMITTED: "Last Submitted {submittedTime}",
  NOT_SUBMITTED: "Not submitted",
  NEVER_SUBMITTED_DEFAULTED: "Never Submitted (Defaulted)",
  TAKE_ACTION: "Take action",
  CHOOSE_ONE: "Choose one",
  FEE_TYPE: "Fee Type",
  FEE_TYPE_HELPER: "Select the fee type",
  PLATFORM: "Platform",
  HEALTH_ADVISORY: "Health Advisory",
  IMPLEMENTATION: "Implementation",
  INTEGRATION: "Integration",
  BILLING_DIVISION: "Billing Division",
  BILLING_PERIOD_START_DATE: "Billing period start",
  BILLING_PERIOD_END_DATE: "Billing period end date (Optional)",
  UNITS: "Number of Units",
  UNITS_TOOLTIP: "This value can represent platform seats or a single charge (in which case it should be set to 1)",
  TERM: "Term",
  TERM_TOOLTIP: "Specify a value if the fee is recurring. For example, a monthly Platform fee over 12 months or a quarterly Health Advisory fee over 4 quarters",
  FEE_RATE: "Rate",
  FEE_RATE_TOOLTIP: "This value should take into account \u2018Number of units\u2019 and, if applicable, \u2018Term\u2019. For example, a rate that is per unit per month.",
  FEE_RATE_PLACEHOLDER: "10.00",
  MONTHS: "Months",
  QUARTER: "Quarter",
  QUARTERS: "Quarters",
  YEARS: "Years",
  DAYS_LOWERCASE: "days",
  MONTHS_LOWERCASE: "months",
  QUARTER_LOWERCASE: "quarter",
  QUARTERS_LOWERCASE: "quarters",
  YEARS_LOWERCASE: "years",
  UNIT_TOOLTIP: "This value can represent either the number of employees or a single charge, in which case, the value would be 1.",
  QLE_WELCOME: "Welcome back, {userName}",
  QLE_WELCOME_SUB_HEAD_DESCRIPTION: "You recently had a {qleLink}. Let\u2019s see if your current benefit plan needs adjusting to better suit your situation.",
  QUALIFYING_LIFE_EVENT_QLE: "Qualifying Life Event (QLE)",
  QLE_WELCOME_HERE_IS_WHAT_YOU_CAN_DO: "Here's what you can do:",
  QLE_WELCOME_REVIEW_YOUR_CURRENT_ELECTIONS: "Review your current elections",
  QLE_WELCOME_SPEND_AVAILABLE_FLEX: "Spend your available {flexDollarTerm} towards your benefits",
  QLE_WELCOME_MANAGE_DEPENDENTS: "Add, edit or remove dependents",
  QLE_WELCOME_SUBMIT_CHANGES: "Submit your changes (or keep everything the same)",
  QLE_WELCOME_IMPORTANT: "Important things to note:",
  QLE_WELCOME_IMPORTANT_NOTE_PRORATED: "Any new funds that you add to your wellness accounts will be prorated based on the date of your Qualifying Life Event {qleDate}.",
  QLE_WELCOME_IMPORTANT_NOTE_EFFECTIVE_DATE: "You have until {qleEnrollmentEndDate} to make changes to your elections.  If you change your elections, your new benefits will take effect {qleCoverageStartDate}.",
  QLE_WELCOME_IMPORTANT_NOTE_EFFECTIVE_DATE_KPMG: "You have until {qleEnrollmentEndDate} to make changes to your elections. If you change your elections, your new benefits will take effective on the first day of the month following submission of your choices.",
  QLE_WELCOME_FLEX_DOLLAR_TERM_TOOLTIP: "{group} is providing you {flexDollarTerm}, which are additional funds you can use to spend as you like on your benefits.",
  SUBMIT_FEE_SUCCESS: "Fee submitted. View {link}.",
  PENDING_FEE: "pending fee",
  NO_PENDING_FEES: "There are no pending fees. To add one, go to Fees.",
  PENDING_FEES_INFO: "Preview and issue invoices. All fees listed below will be added to one invoice.",
  PREVIEW_INVOICE: "Preview Invoice",
  ISSUE_INVOICE: "Issue Invoice",
  ADD_FEE_INFORMATION: "Add platform, implementation, integration, and other fees to a standalone invoice.",
  REMOVE_FEE: "Remove Fee",
  REMOVE_FEE_SUCCESS: "Fee Successfully Removed",
  PENDING_FEE_UNITS: "Unit(s)",
  ENROLLING: "Enrolling",
  PLAN_STATUS: "Plan Status",
  PREMIUMS: "Premiums Export",
  PREMIUMS_HEADER: "Premiums Export Report",
  PREMIUMS_HEADER_COPY: "Download a report containing per pay period deductions for all employees in this group.",
  REMOVE_FEE_CONFIRM: "Are you sure that you want to remove this fee?",
  ISSUE_INVOICE_MODAL_TITLE: "Confirm invoice",
  ISSUE_INVOICE_MODAL_INFO: "Are you sure you\u2019d like to issue a stand-alone invoice with the following fees?",
  ISSUE_INVOICE_SUCCESS: "Invoice issued. It can now be viewed under the {link} tab.",
  COMPLETED_EXPIRED_REMOVED_ACTIVITIES: "Completed, Expired, Removed, and Upcoming Activity Details",
  COPY_ACTIVITY_CAMPAIGN_ID_HINT: "*Click an activity or campaign name to copy an activity ID or campaign ID respectively",
  PROPERTY_NAME_UNAVAILABLE: "No {property} Name",
  COMPLETED_PROGRAM_DETAILS: "Completed Program Details",
  IN_PROGRESS_PROGRAM_DETAILS: "In Progress Program Details",
  PROGRAM_START_DATE: "Start Date",
  PROGRAM_END_DATE: "End Date",
  PROGRAM_QUIT_DATE: "Quit Date",
  PROGRAM_GOAL: "Goal",
  NO_HEALTH_GOAL_PROGRAMS: "The following user ID has not completed any health goal programs",
  NO_REWARDS_HISTORY: "The following user ID does not have any rewards history",
  NO_PURCHASE_HISTORY: "The following user ID does not have any purchase history",
  NO_CARE_PROVIDER_HISTORY: "The following user ID does not have a care provider history",
  ADHOC_FEE_MULTIPART_TERM_ERROR: "Must fill in both fields",
  ADDITIONAL_BENEFITS_BANNER_DESCRIPTION: "Your employer has also provided the following benefits as part of your total rewards package.",
  CLEAR_FIELD_VALUE: "--Clear field value--",
  ADDITIONAL_BENEFITS_MODAL_DESCRIPTION: "These benefits and how to use them can be found in the League app.",
  LEAGUE_HEALTH_PROGRAM: "League Health Program",
  MORE_GREAT_RESOURCES: "More great resources",
  OFFERED_BY_LEAGUE: "Offered by League",
  ADHOC_FEE_RATE_TOOLTIP: "This value should take into account \u2018Number of units\u2019 and, if applicable, \u2018Term\u2019. For example, a rate that is per unit per month.",
  ADHOC_FEE_UNIT_TOOLTIP: "This value can represent platform seats or a single charge (in which case it should be set to 1).",
  ADHOC_FEE_TERM_TOOLTIP: "Specify a value if the fee is recurring. For example, a monthly Platform fee over 12 months or a quarterly Health Advisory fee over 4 quarters.",
  PENDING_FEES: "Pending Fees",
  LEGACY_FEES: "Legacy Fees",
  VIEW_MORE_IN_OUR_HELP_CENTER: "View more in our Help Center",
  HELP_FIND_RIGHT_RESOURCES_FOR_HEALTH_NEEDS: "We can help you find the right resources for your health needs",
  TALK_ABOUT_PERSONALIZED_HEALTH_NEEDS: "Talk to a human about your personalized health needs. Our team of Certified Health Professionals are just a quick chat away.",
  CHAT_WITH_HEALTH_CONCIERGE: "Chat with a Registered Nurse",
  REPORT_ID: "Report ID",
  FULL_REPORT: "Full Report",
  DOWNLOAD_REPORT: "Download Report",
  CHANGE_REPORT: "Change Report",
  HISTORY: "History",
  TOP_MATCH: "Top match",
  CHAT: "Chat",
  CHAT_TEAM_HEADING: "Get answers from our Care Team",
  CHAT_TEAM_DESCRIPTION: "Chat with our certified health professionals and customer care staff. They can help you save money by directing you to the most affordable, highest quality care in your area as well as answer any questions about your benefits.",
  REGISTERED_NURSE: "Registered Nurse",
  CHAT_REGISTERED_NURSE_DESCRIPTION: "Get the answers and advice you need on health-related topics, including signs, symptoms and treatment options.",
  CUSTOMER_CARE: "Customer Care",
  CHAT_CUSTOMER_CARE_DESCRIPTION: "Running into a problem? Have a question about your benefits, account, or dependents? Chat with a member from our team.",
  CHAT_CARE_NAVIGATOR_HEADING: "Care Navigator",
  CHAT_CARE_NAVIGATOR_DESCRIPTION: "Get help navigating the healthcare landscape, including direct assistance with finding a provider, facility or service for a specific need.",
  CHAT_START_CHAT: "Start Chat",
  CHAT_START_CHAT_WITH_TAB_WARNING: "Start Chat (opens in new tab)",
  CHAT_NOT_AVAILABLE: "Not Available",
  CHAT_OFFLINE: "Offline",
  CHAT_FAILED_TO_LOAD_MESSAGE: "We\u2019re experiencing some issues with our chat but we\u2019re working hard to get things up and running. In the meantime, you can send your questions to {email}.",
  CHAT_EMERGENCY_MESSAGE: "If this is an emergency, please contact 911 immediately.",
  CHAT_SEND_EMAIL: "Send Email",
  CHAT_SEND_EMAIL_TO_REGISTERED_NURSE: "Send Email to a Registered Nurse",
  CHAT_SEND_EMAIL_TO_CUSTOMER_CARE: "Send Email to Customer Care",
  CHAT_SEND_EMAIL_TO_CARE_NAVIGATOR: "Send Email to a Care Navigator",
  CHAT_OFFLINE_CASE_DESCRIPTION: "Send us your message and we\u2019ll look into it as soon as our team members are back online. Check your email inbox for a reply in the next 24 hours.",
  CHAT_OFFLINE_CASE_SUBJECT_LABEL: "What's on your mind?",
  CHAT_OFFLINE_CASE_SUBJECT_TOO_SHORT: "A message is required",
  CHAT_CREATE_OFFLINE_CASE_ERROR: "There was an error sending your email. Please try again.",
  CHAT_CREATE_OFFLINE_CASE_SUCCESS: "We got your email",
  CHAT_CREATE_OFFLINE_CASE_SUCCESS_BODY: "We\u2019re on it and we will get back to you soon.",
  CHAT_VIEW_ONLINE_HOURS: "View Online Hours",
  "CHAT_REGISTERED_NURSE_ONLINE_HOURS ": "Registered Nurse Online Hours",
  CHAT_CUSTOMER_CARE_ONLINE_HOURS: "Customer Care Online Hours",
  CHAT_CARE_NAVIGATOR_ONLINE_HOURS: "Care Navigator Online Hours",
  CHAT_HISTORY: "Chat History",
  CHAT_DETAILS: "Chat Details",
  CHAT_HELPFUL_ACTIONS: "Helpful Actions",
  CHAT_VIEW_HISTORY: "View Chat History",
  CHAT_PROVIDER: "Chat Provider",
  CHAT_WITH: "Chat With",
  CHAT_HISTORY_EMPTY_HEADING: "Nothing here yet",
  CHAT_HISTORY_EMPTY_DESCRIPTION: "When you end a chat session you\u2019ll be able to see the details and the transcript of that conversation here.",
  CHAT_WITH_NAME: "Chat with {name}",
  CHAT_VIEW_TRANSCRIPT_ARIA_LABEL: "View chat transcript with {owner} on {date} at {time}",
  CHAT_WITH_CARE_NAVIGATOR: "Chat with a care navigator",
  CHAT_WITH_CUSTOMER_CARE: "Chat with a customer care agent",
  CHAT_WITH_REGISTERED_NURSE: "Chat with a registered nurse",
  CHAT_PAGE_TITLE: "Chat - League",
  CHAT_HISTORY_PAGE_TITLE: "Chat History - League",
  CHAT_HISTORY_DETAILS_PAGE_TITLE: "Chat Transcript - League",
  CHAT_WITH_CARE_NAVIGATOR_PAGE_TITLE: "Chat with a care navigator - League",
  CHAT_WITH_CUSTOMER_CARE_PAGE_TITLE: "Chat with a customer care agent - League",
  CHAT_WITH_REGISTERED_NURSE_PAGE_TITLE: "Chat with a registered nurse - League",
  START_CHAT_WITH_CARE_NAVIGATOR: "Start a chat with a care navigator",
  START_CHAT_WITH_CUSTOMER_CARE: "Start a chat with a customer care agent",
  START_CHAT_WITH_REGISTERED_NURSE: "Start a chat with a registered nurse",
  END_CHAT: "End Chat",
  ARE_YOU_SURE_YOU_WANT_TO_END_THIS_CHAT: "Are you sure you want to end this chat? Don\u2019t worry - you can still export your chat afterwards.",
  YOUR_CHAT_HAS_ENDED: "Your chat has ended",
  NO_AGENTS_ARE_AVAILABLE_AT_THE_MOMENT: "No agents are available at the moment so your chat session has ended. Please try again later.",
  CONNECTION_LOST: "Connection Lost",
  YOUR_CHAT_ENDED_BECAUSE_ISSUE_WITH_CONNECTION: "Your chat ended because we were having issues maintaining your connection. Check if you\u2019re connected to the internet and try again.",
  TRYING_TO_RECONNECT: "Trying to reconnect...",
  YOU_HAVE_BEEN_DISCONNECTED: "You\u2019ve been disconnected. Sit tight while we try to get you back to your chat.",
  THE_AGENT_IS_TYPING: "The agent is typing.",
  NEW_MESSAGE_FROM_THE_AGENT: "New message from the agent.",
  THE_AGENT_HAS_ENDED_THE_CHAT: "The agent has ended the chat.",
  THE_AGENT_HAS_REQUESTED_A_FILE: "The agent has requested a file to be uploaded.",
  YOUR_FILE_HAS_BEEN_UPLOADED: "Your file has been successfully uploaded to the agent.",
  YOUR_FILE_FAILED_TO_UPLOAD: "Your file failed to upload.",
  FILE_TRANSFER_IS_CANCELED: "The file transfer has been canceled.",
  EMAIL_MESSAGE: "Email message",
  BECOMES_ACTIVE_ON_DATE: "Becomes active on {date}",
  BECOMES_ACTIVE_IN_X_DAYS: "This benefit becomes active in {days} days",
  YOU_CAN_ONLY_SUBMIT_CLAIMS_IN_DATE_RANGE: "You can only submit claims with a purchase/service date between <strong>{dateRange}</strong> for this benefit. View your coverage details for more info.",
  SUBMIT_PAST_CLAIMS_BY_DATE: "Submit past claims by {date}",
  THIS_POLICY_HAS_EXPIRED: "This policy period has expired, but you have {days} days left to submit past claims for this benefit",
  THIS_POLICY_EXPIRES_TOMORROW: "This policy period has expired, but today is your last day to submit past claims for this benefit",
  YOU_HAVE_UNTIL_DATE_TO_SUBMIT_PAST_CLAIMS: "You have until <strong>{inactiveDate}</strong> to submit past claims with a purchase/service date on or before <strong>{endDate}</strong>",
  EXPIRES_ON_DATE: "Expires on {date}",
  ASSIGNED: "Assigned",
  ASSIGNED_ON: "on {date}",
  ASSIGNED_TODAY: "today",
  ASSIGN_AN_ACTIVITY: "Assign an activity",
  ACTIVITY_ASSIGNMENT: "Activity Assignment",
  NO_MEMBERS_MESSAGE: "You currently have no family members or caregivers added",
  NO_ONE_HERE: "No one's here",
  MEMBERS_SORTED_BY_ACTIVITIES: "Members sorted by recent activities",
  CARE_TEAM_MEMBER: "Care team members",
  PREVIOUSLY_ASSIGNED_ACTIVITIES: "Previously assigned activities",
  CREATE_NEW_ACTIVITY: "Create new activity",
  ASSIGN_ACTIVITY: "Assign Activity",
  YOU_HAVE_X_DAYS_BEFORE_EXPIRY: "You have {days} days left to use this benefit before the policy period expires",
  AFTER_EXPIRY_YOU_HAVE_UNTIL_DATE_TO_SUBMIT: "After expiry, you\u2019ll have until <strong>{inactiveDate}</strong> to submit claims with a purchase/service date on or before <strong>{endDate}</strong>",
  WAITING_FOR_PROVIDER: "Waiting for the next available provider",
  CANCEL_CHAT: "Cancel Chat",
  APPROXIMATE_WAIT: "Approx. {waitTime, plural, one {# minute} other {# minutes}}",
  YOUR_PLACE_IN_QUEUE: "Your place in queue",
  CHAT_UNAVAILABLE: "Chat isn\u2019t available right now",
  CHAT_UNAVAILABLE_INFO: "We\u2019re experiencing some issues with our chat but we\u2019re working hard to get things up and running. If you need assistance reach out to {helpLink}.",
  CONGRATULATIONS_ON_THE_NEW_JOB: "Congratulations on the new job!",
  NEW_HIRE_PRORATION_MODAL_HEADER: "The pricing in your experience is prorated",
  NEW_HIRE_PRORATION_MODAL_SUB_HEADING: "{greeting} Because you\u2019re starting partway through the policy period, {message}",
  NEW_HIRE_PRORATION_EXAMPLE_TITLE: "Proration Example",
  NEW_HIRE_PRORATION_EXAMPLE: "Fun Company Limited gives every employee $500 {flexDollarTerm} at the beginning of the policy year.  If you join the company halfway through the year, that amount will be prorated to $250 for you.",
  PRORATED_PRICING_AND_FLEX_TEXT: "we\u2019ve adjusted the benefit pricing and your available {flexDollarTerm} amount based on the time left in the policy period.",
  PRORATED_FLEX_TEXT: "we\u2019ve adjusted your available {flexDollarTerm} amount based on the time left in the policy period.",
  PRORATED_PRICING_TEXT: "we\u2019ve adjusted the benefit pricing based on the time left in the policy period.",
  PRORATED_AMOUNTS_TEXT: "we\u2019ve adjusted some amounts based on the time left in the policy period.",
  CHAT_IN_PROGRESS: "Chat already in progress",
  CHAT_IN_PROGRESS_EXPLAINER: "You\u2019re currently in a chat session. In order to start a new chat, you need to end your existing session.",
  "CHAT_DEEPLINK_START_REGISTERED_NURSE_CHAT ": "Registered Nurse Chat",
  CHAT_DEEPLINK_START_CUSTOMER_CARE_CHAT: "Customer Care Chat",
  CHAT_DEEPLINK_START_CARE_NAVIGATOR_CHAT: "Care Navigator Chat",
  CHAT_DEEPLINK_EXPLAINER: "Start a live chat with one of our Care Team experts and get answers to all of your questions.",
  LOAD_MORE: "Load more",
  SHOW_MORE: "Show more",
  PLEASE_TRY_AGAIN: "Please try again",
  END_OF_PAGINATION_LIST: "Looks like you've reached the end",
  ENROLLMENT_SELECTION_REPORT: "Enrollment Selection Report",
  GET_ENROLLMENT_SELECTION_REPORT: "Get Enrollment Selection Report",
  ENROLLMENT_SELECTION_REPORT_DOWNLOAD: "Download Enrollment Selection Report:",
  CREATED: "Created",
  MEMBER_TOOLS: "Member Tools",
  MEMBER_TOOLING: "Member Tooling",
  YOUR_REWARDS: "Your Rewards",
  SCRIPT_HELPERS: "Script Helpers",
  WALLET_TOOLS: "Wallet Tools",
  HEALTH_TOOLS: "Health Tools",
  MERCH_LISTS: "Merch Lists",
  LIFEMARKET_MERCH_LIST: "Lifemarket Merch List",
  CONTENT_MERCH_LIST: "Content Merch List",
  SET_MERCH_LIST: "Merch list set successfully!",
  HOME_PAGE_CONFIG: "Home Page Config",
  SECTION_TYPE: "Section Type",
  LIST_HEADING: "List Heading",
  HOME_PAGE_NO_RESULTS: "No home page content",
  HOME_PAGE_CONFIG_SET: "Home page content was set",
  UPDATE_MERCH_LIST_SUCCESSFUL: "Merch list successfully updated",
  ADD_NEW_SECTION: "Add new section",
  ADD_SECTION: "Add section",
  DISPLAY_AS_BANNER: "Display as Banner",
  DISPLAY_AS_CAROUSEL: "Display as League Blog Carousel (Carousel will be hidden for Spanish members)",
  REDEEMABLE_VALUE: "Redeemable Value",
  REDEEM_POINTS: "Redeem Points",
  REWARD_STATUS: "Reward Status",
  EARN_POINTS: "Earn points, build healthy habits",
  EARN_POINTS_PROGRAMS: "Earn rewards points by completing Health Program activities.",
  EXPLORE_PROGRAMS: "Explore Programs",
  USE_LEAGUE_CREDIT: "Use League Credit to pay",
  LEAGUE_CREDIT_LIFEMARKET_DESCRIPTION: "Did something in our Lifemarket catch your interest? Put your League Credit towards the purchase of any product or service.",
  EXPLORE_LIFEMARKET: "Explore Lifemarket",
  GET_STARTED_REWARDS: "Get started with League Rewards",
  REDEEM_POINTS_DESCRIPTION: "By clicking <strong>\u2018Redeem Points\u2019</strong> your points will be converted to League Credit to use towards products and services in our Lifemarket.",
  CONFIRM_REDEEM_POINTS: "Confirm redeem points",
  ARE_YOU_SURE_REDEEM_POINTS: "Are you sure you\u2019d like to redeem <strong>{points} points for ${value} League Credit?</strong>",
  YOUR_POINTS_CANNOT_CONVERT: "Your points will be converted to League Credit to use in our Lifemarket and cannot be converted back into points.",
  POINTS_REDEEMED: "Points redeemed",
  YOUVE_SUCCESSFULLY_REDEEMED: "You\u2019ve successfully redeemed {points} points for ${value} in League Credit.",
  PREV_POINTS_BALANCE: "Previous points balance",
  NEW_POINTS_BALANCE: "New points balance",
  BACK_TO_REWARDS: "Back to Rewards",
  REWARDS_HISTORY: "Rewards History",
  REDEEM_MY_POINTS: "Redeem my points",
  ARE_YOU_SURE_TO_REDEEM_POINTS: "Are you sure you'd like to redeem your points?",
  YOU_WILL_EARN_DOLLARS_IN_LEAGUE_CREDIT: "You will earn {dollars} in League Credit.",
  PURCHASE_HISTORY: "Purchase History",
  INVOICE_HEADER_INVOICE_AMOUNT: "Invoice Amount",
  PAYROLL_TAB_DESCRIPTION: "View and manage payroll files and schedules.",
  PAY_CALENDARS: "Pay Calendars",
  PAY_CALENDARS_COPY: "Calendars which state pay periods used to drive contributions and payroll related reports.",
  PAY_CALENDAR: "Pay Calendar",
  PAY_SCHEDULE: "Pay Schedule",
  COPY_REPORT_ID: "Copy Report ID",
  GROUP_PREMIUM_EXPORTS_REPORT: "Group PX Report",
  PAY_PERIOD_PREMIUM_EXPORTS_REPORT: "Pay Period PX Report",
  ONLY_REGISTERED_PAY_PERIODS: "Only registered pay periods will show up in this dropdown",
  REQUEST: "Request",
  ERROR_PAY_CALENDAR_DOES_NOT_EXIST: "Could not request report, pay calendar does not exist.",
  LEAGUE_LOGO: "League logo",
  PC_HEALTH_LOGO: "PC Health logo",
  PC_HEALTH_ID: "PC Health ID",
  IMPORT_PAY_CALENDAR_COPY: "Importing a pay calendar will generate plan periods and register dates from a Google Sheet template. Column headers must match the example shown below.",
  IMPORT_PAY_CALENDAR_NOTE: "If you make any changes within the google spreadsheet it won\u2019t automatically update within the admin experience.",
  PAY_SCHEDULE_UPLOADED: "Pay schedule uploaded",
  PAY_CALENDAR_NAME: "Pay Calendar Name",
  RENAME_PAY_CALENDAR: "Rename Pay Calendar",
  RENAME_PAY_CALENDAR_COPY: "Edit the display name of this pay calendar.",
  SAVE_PAYROLL_SCHEDULE_SUCCESS: "Successfully saved pay calendar",
  NO_PAY_SCHEDULE_UPLOADED: "You haven't uploaded a pay schedule spreadsheet yet!",
  PROVIDE_PAY_CALENDAR_NAME: "Provide pay calendar name",
  IMPORT_PAYROLL_SCHEDULE_SUCCESS: "Successfully imported pay calendar",
  ADD_PAY_CALENDAR: "Add Pay Calendar",
  LINK_OUT_MESSAGE: "Must See",
  IMPORT: "Import",
  BUILD_YOUR_HEALTH_PROFILE: "Build your Health Profile",
  BUILD_YOUR_HEALTH_PROFILE_SUBTITLE: "All your health information, resources and goals, all in one place.",
  EASILY_STAY_ON_TOP_OF_YOUR_HEALTH: "Easily stay on top of your health.",
  EASILY_STAY_ON_TOP_OF_YOUR_HEALTH_SUBTITLE: "Your Health Profile puts all your health information in one easily-accessible place so you can monitor your health and goals anytime, any place.",
  LEARN_MORE_ABOUT_YOUR_HEALTH_PROFILE: "Learn more about your Health Profile",
  GET_INSIGHTS_INTO_YOUR_HEALTH: "Get insights into six areas of your health.",
  GET_INSIGHTS_INTO_YOUR_HEALTH_SUBTITLE: "Your health is multifaceted. So is your Health Profile. You\u2019ll learn about your body, lifestyle, mind, self, medical health and social health.",
  LEARN_MORE_ABOUT_LEAGUE_REWARDS: "Learn more about League Rewards",
  REACH_YOUR_GOALS: "Reach your goals with personalized programs, content and services.",
  REACH_YOUR_GOALS_SUBTITLE: "We use your Health Profile to recommend the best Health Programs, Marketplace offerings and content for your unique needs and goals.",
  REQUESTED_PAYROLL_SCHEDULE_EXPORT: "Requested Payroll Schedule Export, please wait.",
  EXPORT_PAY_SCHEDULE: "Export Pay Schedule",
  DOWNLOAD_EXPORTED_PAY_SCHEDULE: "Download Exported Pay Schedule",
  POLICY_INFORMATION: "Policy Information",
  PLAN_PERIOD_START_DATE: "Plan Period Start Date",
  PLAN_PERIOD_END_DATE: "Plan Period End Date",
  PAY_PERIOD_START_DATE: "Pay Period Start Date",
  PAY_PERIOD_END_DATE: "Pay Period End Date",
  PAY_PERIOD_REGISTER_DATE: "Register Date",
  PAY_PERIOD_REGISTER_STATUS: "Register Status",
  PAY_PERIOD_SCHEDULED_FILE_DELIVERY_DATE: "Scheduled File Delivery Date",
  PAY_PERIOD_MEMBER_PAY_DATE: "Member Pay Date",
  PLAN_PERIOD_START_DATE_TOOLTIP: "This is the first day of the plan period for members in this payroll calendar.",
  PLAN_PERIOD_END_DATE_TOOLTIP: "This is the last day of the plan period for members in this payroll calendar.",
  PAY_PERIOD_START_DATE_TOOLTIP: "This is the first day of the pay period for members in this payroll calendar.",
  PAY_PERIOD_END_DATE_TOOLTIP: "This is the last day of the pay period for members in this payroll calendar.",
  PAY_PERIOD_REGISTER_DATE_TOOLTIP: "We\u2019ll use this date to log a members payroll deduction for this pay period in our system.",
  PAY_PERIOD_REGISTER_STATUS_TOOLTIP: "This status shows if a pay period has been registered or not. When a pay period is registered, it means members payroll deductions will be logged into our system. ",
  PAY_PERIOD_SCHEDULED_FILE_DELIVERY_DATE_TOOLTIP: "This is the date that was initially scheduled for payroll to be sent to the client. ",
  PAY_PERIOD_MEMBER_PAY_DATE_TOOLTIP: "This is the date when members will receive their paycheque for this specific pay period.",
  REGISTERED: "Registered",
  NOT_REGISTERED: "Not Registered",
  PAY_PERIOD_DETAILS: "Pay Period Details",
  BACK_TO_PAY_CALENDARS: "Back to Pay Calendars",
  YOU_HAVE_UNSAVED_CHANGES: "You have unsaved changes, would you like to navigate away and discard all changes?",
  PRICING_SCENARIO: "Pricing Scenario",
  PRICING_SCENARIO_REPORT: "Pricing Scenario Report",
  PRICING_SCENARIO_COPY: "This report is used to audit payroll deductions for a user's benefit selection across different coverage options.",
  COMMA_SEPARATED_USER_IDS: "User IDs (Comma Separated)",
  CREDIT_ADJUSTMENT: "Credit Adjustment",
  DISCOUNT: "Discount",
  FIXED_MONTHLY_PLATFORM_FEES: "Fixed Monthly Platform Fees",
  CHAT_WITH_YOUR_CARE_TEAM: "Chat with your Care Team",
  THERE_SEEMS_T0_HAVE_BEEN_A_PROBLEM: "Oops, there seems to have been a problem.",
  TRY_AGAIN: "Try Again",
  DEPENDENT_VERIFICATION: "Dependent Verification",
  RATE_SHEET_EXTRACT: "Rate Sheet Extract",
  RATE_SHEET_EXTRACT_COPY: "Download a report containing rate tables and pricing data for selected benefits and benefit classes.",
  RATE_SHEET_EXTRACT_SEARCH_PLACEHOLDER: "Search by benefit class, name, or ID",
  COPY_PRICING_CONFIG: "Copy Pricing Config",
  COPY_PRICING_CONFIG_COPY: "Copy current saved benefit pricing config into another benefit.",
  COPY_PRICING_CONFIG_NOTE: "Note: This action will override any existing pricing config for the selected benefit, if defined. This action is irreversible.",
  EMPLOYER_BENEFIT: "Employer Benefit",
  BENEFIT_TYPE_FILTER: "Benefit Type Filter",
  BENEFIT_YEAR: "Benefit Year",
  NETSUITE_INVOICE_REPORT: "Invoice Report formatted for NetSuite",
  GET_NETSUITE_INVOICE_REPORT: "Request Report",
  NETSUITE_INVOICE_REPORT_FROM_DATE: "From Date",
  NETSUITE_INVOICE_REPORT_TO_DATE: "To Date",
  GET_NETSUITE_INVOICE_REPORT_SUCCESS: "NetSuite formatted invoice report was generated successfully!",
  GET_NETSUITE_INVOICE_REPORT_ERROR: "Failed to generate NetSuite formatted invoice report, please try again!",
  PTS: "pts",
  EMPLOYEE_DEDUCTIONS: "Employee Deductions",
  EMPLOYEE_TASA_DEDUCTIONS: "Employee TASA Deductions",
  EMPLOYEE_TASA_DEDUCTIONS_COPY: "View and manage employee TASA deductions.",
  BENEFIT_CONTRIBUTION_LABEL: "A contribution amount of ${amount} {currency} has been made for {period}",
  SEARCH_EMPLOYEE_ID: "Search Employee ID",
  CONTRIBUTIONS_ACCOUNT: "Contributions Account",
  YTD_EMPLOYEE_CONTRIBUTION: "YTD Employee Contribution",
  TARGET_EMPLOYEE_CONTRIBUTION: "Target Employee Contribution",
  NEXT_CONTRIBUTION_AMOUNT: "Next Contribution Amount",
  CONTRIBUTION_SOURCE: "Contribution Source",
  NO_CONTRIBUTIONS: "User has not made any contributions.",
  NO_CONTRIBUTION_ACCOUNTS: "User has no contribution accounts",
  PLAN_PERIOD: "Plan Period",
  DOWNLOAD_DETAILED_USAGE_REPORT: "Download Detailed Usage Report",
  DOWNLOAD_USAGE_SUMMARY_REPORT: "Download Usage Summary Report",
  NOTE_REPORTS_LOADING_COPY: "<strong> Note: </strong>Depending on the number of records, this report could take a few hours to generate. We will email you a link to download the report once it has been processed.",
  SCHEDULED_REPORTS: "Scheduled Reports",
  SCHEDULED_REPORTS_COPY: "View and download automatically generated reports.",
  PREMIUM_REGISTRATION_RUN: "Premium Registration Run",
  NO_SCHEDULED_REPORTS: "The are no scheduled reports for this pay calendar.",
  BACK_TO_SCHEDULED_REPORTS: "Back to Scheduled Reports",
  REPORT_NAME: "Report Name",
  FULL: "Full",
  CHANGE: "Change",
  PROVIDER: "Provider",
  RISK_PERSONA: "Risk Persona",
  AGENT: "Agent",
  CARE_PROVIDER_HISTORY: "Care Provider History",
  YEAR_PLAN_YEAR: "{year} Plan Year",
  NAMED_PAY_CALENDAR: "{name} Pay Calendar",
  NAMED_REPORTS: "{name} Reports",
  RISK_PERSONA_CARD_IMAGE_TEXT: "Risk Persona",
  RISK_PERSONA_CARD_TITLE: "Who\u2019s this plan good for?",
  RISK_PERSONA_CARD_CAPTION: "We work with a team of benefit experts to provide these recommendations.",
  NO_PAY_CALENDARS: "There are no pay calendars set up for this group.",
  RE_REGISTER: "Re-Register",
  RE_REGISTER_PAY_PERIOD: "Re-Register Pay Period",
  RE_REGISTER_WARNING: "<strong>Warning: </strong>This action will overwrite any existing reports that have been registered for this pay period. Please make sure the files are not used by anyone before proceeding.",
  RE_REGISTER_CONFIRMATION: "Are you sure you would like to re-register?",
  RE_REGISTER_SUCCESS: "Successfully re-registered reports! View the update report in the <a>Scheduled Reports Tab</a>",
  RE_REGISTER_START: "Re-registering pay period, please wait as this may take several minutes.",
  WARNING_OPENS_NEW_TAB: "Opens in a new tab",
  PAYROLL_CONFIG: "Payroll Config",
  PAYROLL_CONFIG_COPY: "Configure rules to drive employee registration and reports.",
  ANY_HEALTH_CHANGES: "Have there been any recent changes in your health?",
  LAST_UPDATED_DATE: "Last updated {date}",
  UPDATE_HEALTH_PROFILE: "Update your Health Profile",
  HEALTH_PROFILE_COMPLETION_POINTS: "You've earned {points} points",
  DATE_OF_CONSULT: "Date of Consult",
  MAPLE_USER_TYPE: "Maple User Type",
  MAPLE_CONSENT_DATE: "Maple Consent Date",
  MAPLE_UNLINK_DATE: "Maple Unlink Date",
  NO_MAPLE_ACCOUNT_LINKED: "No Maple account linked",
  SDM_MAPLE_USER: "SDM user - this user was first introduced to Maple through PC Health",
  SHARED_MAPLE_USER: "Shared user - this user linked their existing Maple account to PC Health",
  CURRENT_MAPLE_USER_STATUS: "Current Maple User Status",
  LINKED: "Linked",
  UNLINKED: "Unlinked",
  UNLINKED_MAPLE_ACCOUNT_OPTIONS: "Should the member wish to access Maple through the PC Health handoff flow, please share the following options:",
  UNLINKED_MAPLE_ACCOUNT_OPTION_ONE: "(Existing Maple User) Use existing Maple account credentials within the PC Health handoff flow to access Maple, and link their PC Health account.",
  OPENS_IN_A_NEW_TAB: "Opens in a new tab",
  COVID_19_VACCINE_JOURNEY: "COVID-19 Vaccine Journey",
  VACCINE_JOURNEY: "Vaccine Journey",
  REGISTRATION_DETAILS: "Registration Details",
  APPOINTMENT_DETAILS_FOR_DOSE: "Appointment Details for Dose: {dose} / {seriesDoses}",
  REQUEST_STATUS: "Request status",
  REQUESTED_DATE: "Requested date",
  REGISTRATION_CODE: "Registration code",
  ELIGIBILITY_STATUS: "Eligibility status",
  ELIGIBILITY_DATE: "Eligibility date",
  BOOKING_STATUS: "Booking status",
  BOOKING_DATE: "Booking date",
  BOOKING_EXPIRY_DATE: "Booking expiry date",
  APPOINTMENT_STATUS: "Appointment status",
  APPOINTMENT_DATE: "Appointment date",
  STORE_NAME: "Store name",
  STORE_PHONE: "Store phone",
  STORE_ADDRESS: "Store address",
  COMPLETED: "Completed",
  COMPLETED_X_TIMES: "Completed {amount} times",
  CANCELED: "Canceled",
  IN_PROGRESS: "In progress",
  PATIENT_DETAILS: "Patient Details",
  PATIENT_GUID: "Patient GUID",
  PATIENT: "Patient",
  PROVINCE_OF_REGISTRATION: "Province of registration",
  UNLINKED_MAPLE_ACCOUNT_OPTION_TWO: "(New Maple User) Create a new Maple account through the PC Health handoff flow to link their PC Health account.",
  UPDATE_CONTRIBUTIONS_ERROR: "Sorry, there's been an error updating employee benefit contributions",
  SAVED_BUSINESS_RULE: "Successfully saved business rule!",
  AVAILABLE_ON: "Available on {date}",
  SUGGESTED: "Suggested",
  RE_REGISTER_REASON: "Re-register Reason",
  OPEN_LINK: "Open link >",
  REMOVE_FROM_JOURNEY: "Remove from journey",
  GO_TO_JOURNEY: "Go to Journey",
  ACTIVITY_NOT_AVAILABLE: "This activity isn't available yet!",
  COME_BACK_IN_DAYS: "Come back in {days} days to complete it.",
  WHAT_IS_MY_HEALTH_PROFILE: "What is my health profile?",
  WHY_TALK_TO_RN: "Why talk to a registered nurse?",
  ABOUT_THIS_ARTICLE: "About this article",
  ABOUT_THIS_PROGRAM: "About this program",
  WHAT_TO_DO: "What to do",
  JOURNEY: "Journey",
  HEALTH_JOURNEY: "Health Journey",
  REMOVE_ACTIVITY: "Remove Activity?",
  ARE_YOU_SURE_REMOVE_ACTIVITY: "Are you sure you want to remove this activity from your journey? This action can\u2019t be undone.",
  ACTIVITY_REMOVED: "Activity removed",
  YOU_WILL_NO_LONGER_SEE_IN_JOURNEY: "You will no longer see it in your journey",
  DISCOVER_ACTIVITIES: "Discover new activities",
  DISCOVER_ACTIVITIES_CAPTION: "Browse activities we think you\u2019ll love",
  ACTIVITIES: "Activities",
  ACTIVITIES_PLURALIZATION: "{count, plural, one {Activity} other {Activities}}",
  ACTIVITIES_PILL: "Activities",
  PROGRAM_LIBRARY: "Program Library",
  THIS_WEEK: "This week",
  THIS_MONTH: "This month",
  LATER: "Later",
  PREVIEW: "Preview",
  SNEAK_PEEK_AT_HEALTH_PROGRAM_ACTIVITIES: "Get a sneak peek at upcoming health program activities that will be available soon",
  NOTHING_HERE_YET: "Nothing here yet!",
  GO_TO_PROGRAM_LIBRARY: 'Go to the "Program Library" to browse our Health Programs and start your health journey.',
  BROWSE_PROGRAMS: "Browse Programs",
  BACK_TO_JOURNEY: "Back to Journey",
  GO_TO_HEALTH_JOURNEY: "Go to health journey",
  TODAYS_ACTIVITIES: "Today's activities",
  UPCOMING_ACTIVITIES: "Upcoming activities",
  INVITED: "Invited",
  TERMINATED: "Terminated",
  EXTENSION_OF_BENEFITS: "Extension of Benefits",
  ADD_TO_JOURNEY: "Add to Journey",
  REQUEST_WAIT: "Please wait while we complete your request",
  JOURNEY_DELIGHT_1: "Motivation gets you started. Habit keeps you going. You got this!",
  JOURNEY_DELIGHT_2: "Active. Focused. Staying in your lane. We love to see it.",
  JOURNEY_DELIGHT_3: "Small steps can make a big difference. You are making that step!",
  ACTIVITY_INPUT_FREE_TEXT: "Enter your own value",
  ANIMATION_ACCESSIBILITY_PLAYBACK: "Play or pause the currently selected animation",
  POPULAR_PROGRAMS: "Popular Programs",
  FIELD: "Field",
  VALUES: "Values",
  ADD_FILTER: "Add Filter",
  DEDUCTION_CODE: "Deduction Code",
  DEDUCTION_CODES: "Deduction Codes",
  DEDUCTION_CODE_COPY: "Define deduction code mapping for user benefits",
  IMPORT_DEDUCTION_CODES_COPY: "Bulk upload deduction codes from a CSV file. Please note that a new upload will create new deduction codes, and not replace the existing ones!",
  NEW_DEDUCTION_CODE: "New Deduction Code",
  EDIT_DEDUCTION_CODE: "Edit Deduction Code",
  NEW_MAPPING: "New Mapping",
  NO_DEDUCTION_CODES: "There are no deduction codes for this group!",
  UPLOAD_DEDUCTION_CODES: "Upload Deduction Codes",
  BENEFIT_PLAN_NAME: "Benefit Plan Name",
  TYPE_OF_PREMIUM: "Type of Premium",
  DEPENDENT_COVERAGE_LEVEL: "Dependent Coverage Level",
  PROVINCE_OF_RESIDENCE: "Province of Residence",
  DOWNLOAD_TEMPLATE: "Download Template",
  ADDRESS_1: "Address 1",
  ADDRESS_2: "Address 2",
  ADDRESS_2_OPTIONAL: "Address 2 (Optional)",
  EMPLOYMENT_STATUS: "Employment status",
  DEPENDENT_CLASS: "Dependent class",
  GENDER: "Gender",
  USER_OPTIMUM_PROFILE: "User Optimum Profile",
  ENROLLED_IN_PCO: "Enrolled in PCO",
  MAPLE_USER_PROFILE: "Maple User Profile",
  USER_GROUP: "User Group",
  CENTIMETERS: "Centimeters",
  FEET: "Feet",
  FOOT: "Foot",
  INCHES: "Inches",
  INCH: "Inch",
  KILOGRAMS: "Kilograms",
  POUNDS: "Pounds",
  DURATION_WEEKS: "{duration, plural, =0 {Zero weeks} one {# week} other {# weeks}}",
  EARN_UP_TO_X_POINTS: "Earn up to {points} points",
  EXPIRES_TOMORROW: "Expires tomorrow",
  EXPIRES_TODAY: "Expires today",
  PROGRAM_DETAILS: "Program Details",
  PROGRESS: "Progress",
  X_DAYS_REMAINING: "{duration, plural, =0 {No days} one {# day} other {# days}} remaining",
  X_OF_Y_COMPLETE: "{completed}/{total} complete",
  X_OF_Y_COMPLETED_WITH_UNIT: "<span>{progress}</span> / {goal} {unit}",
  X_MISSED: "{missed} missed",
  MISSED: "Missed",
  HEALTH_NEED: "Health need",
  VIEW_ACTIVE_PROGRAM_DETAILS: "View active health program details",
  SCROLL_CAROUSEL_RIGHT: "Scroll the carousel to the right",
  SCROLL_CAROUSEL_LEFT: "Scroll the carousel to the left",
  YOU_HAVENT_STARTED_PROGRAMS: "You haven't started any health programs",
  ADD_PROGRAM: "Add Program",
  TAP_ADD_PROGRAM_BUTTON: "Tap the \u2018Add Program\u2019 button to browse programs.",
  ACTIVE_PROGRAMS: "Active Programs",
  STARTING_ON: "Starting on {start, date, medium}",
  YOUVE_REACHED_PROGRAM_LIMIT: "You've reached your program limit!",
  START_X_MORE_PROGRAMS: "Start {duration, plural, one {# more program} other {# more programs}}. Keep the momentum.",
  FIELD_MUST_NOT_BE_EMPTY: "Field must not be empty",
  OK: "Ok",
  NOTICE_DATE: "Notice Date",
  EXTENSION_BENEFITS_END_DATE: "Extension of Benefits End Date",
  DURATION_MONTHS: "{duration, plural, =0 {Zero months} one {# month} other {# months}}",
  COMPLETE_ONE_OF_ACTIVE_PROGRAMS: "Complete one of your active programs to add this to your journey.",
  SHOW_LESS: "Show Less",
  LOOKS_LIKE_REACHED_PROGRAM_LIMIT: "Looks like you\u2019ve reached your program limit!",
  COMPLETE_ACTIVE_PROGRAM_BEFORE_NEW_ONE: "Complete an active program before adding a new one. <strong>{ctaText}</strong>",
  REMOVE_PROGRAM: "Remove program?",
  ARE_YOU_SURE_REMOVE_PROGRAM: "Are you sure you want to remove this program from your journey? Any progress in this program will be reset and you will have to start over from the beginning.",
  PROGRAM_REMOVED: "Program removed",
  PROGRAM_ADDED: "Program added",
  THE_PROGRAM_WILL_APPEAR_IN_JOURNEY: "The program will now appear in your journey",
  PROGRAM_ADD_ERROR: "We were unable to add the program at this time. Please try again or choose another program.",
  PROGRAM_REMOVE_ERROR: "We were unable to remove the program at this time. Please try again.",
  HOME_PAGE: "Home Page",
  PAYROLL_DEDUCTIONS: "Payroll Deductions",
  PAYROLL_DEDUCTIONS_COPY: "View and manage payroll deductions",
  COST_TYPE: "Cost Type",
  NO_PAYROLL_DEDUCTIONS: "No payroll deductions",
  EMPLOYEE_COST: "Employee Cost",
  EMPLOYER_COST: "Employer Cost",
  FLEX_COST: "Flex Cost",
  QUEBEC_TAXABLE_BENEFIT: "Quebec Taxable Benefit",
  IMPUTED_INCOME: "Imputed Income",
  PRE_TAX_COST: "Pre Tax Cost",
  POST_TAX_COST: "Post Tax Cost",
  TASA_CONTRIBUTIONS: "TASA Contributions",
  SKIP_TO_CONTENT_MESSAGE: "Skip to content",
  JUMP_TO_NAVIGATOR_LINK: "Jump to navigation",
  MAIN_NAVIGATION_LIST_LABEL: "League navigation items",
  PAGE_TITLE_ACTIVITY_DETAILS: "Activity details",
  X_OF_Y: "{current} of {total}",
  ACTIVITY_COUNT_EDIT: "Edit {unit}",
  ACTIVITY_COUNT_GOAL: "Goal: {goal, number} {unit}",
  ACTIVITY_COMPLETE_PROMPT: 'To complete this activity, click "Mark as Done".',
  ACTIVITY_COMPLETE_LATER_PROMPT: 'To keep going, click "Back to Journey" and come back later to mark it done.',
  ACTIVITY_COMPLETE_ERROR: "We were unable to complete the activity at this time. Please try again.",
  ACTIVITY_DISMISS_ERROR: "We were unable to remove the activity at this time. Please try again.",
  TOOLBOX_VALIDATION_DAY_MONTH_YEAR: "Please select a day, month and year",
  TOOLBOX_VALIDATION_MONTH_YEAR: "Please select a month and year",
  TOOLBOX_VALIDATION_MAXIMUM_CHARACTERS: "Characters limit is {limit}",
  TOOLBOX_VALIDATION_NUMBER_MINIMUM: "Enter a number equal to or greater than {value}",
  TOOLBOX_VALIDATION_NUMBER_MAXIMUM: "Enter a number equal to or less than {value}",
  TOOLBOX_VALIDATION_REQUIRED_FIELD: "This is a required field",
  TOOLBOX_VALIDATION_SELECTION_REQUIRED: "Please make a selection",
  TOOLBOX_VALIDATION_SELECT_DATE_LATER: "Select a date later than {date, date, medium}",
  TOOLBOX_VALIDATION_SELECT_DATE_EARLIER: "Select a date earlier than {date, date, medium}",
  TOOLBOX_VALIDATION_ENTER_WHOLE_NUMBER: "Enter a whole number",
  TOOLBOX_VALIDATION_SELECT_MAX_SELECTION: "Select a maximum of {integer} options",
  TOOLBOX_VALIDATION_SELECT_OPTIONS_TO_CONTINUE: "Select {integer} options to continue",
  TOOLBOX_VALIDATION_FIELD_CANNOT_BE_EMPTY: "Field can\u2019t be empty when selected",
  TOOLBOX_VALIDATION_WEIGHT_MINIMUM: "Weight needs to be at least {value}{unit}",
  TOOLBOX_VALIDATION_WEIGHT_MAXIMUM: "Weight needs to be at most {value}{unit}",
  TOOLBOX_VALIDATION_HEIGHT_MINIMUM: "Height needs to be at least {value}{unit}",
  TOOLBOX_VALIDATION_HEIGHT_MAXIMUM: "Height needs to be at most {value}{unit}",
  ENTER_YOUR_WEIGHT_IN_KILOGRAMS: "Enter your weight in kilograms",
  ENTER_YOUR_WEIGHT_IN_POUNDS: "Enter your weight in pounds",
  ENTER_YOUR_HEIGHT_IN_CENTIMETERS: "Enter your height in centimeters",
  ENTER_YOUR_HEIGHT_IN_FEET: "Enter your height in feet",
  ENTER_YOUR_HEIGHT_IN_INCHES: "Enter your height in inches",
  GOAL_REACHED: "You hit the goal!",
  ACTIVITY_SAVE_PROGRESS: "Save your progress for the day!",
  EXIT_ACTIVITY_TITLE: "Are you sure you want to leave this activity?",
  EXIT_ACTIVITY_BODY: "Your progress won\u2019t be saved and you will have to restart the activity from the beginning.",
  EXIT_ACTIVITY_CTA: "Leave Activity",
  PAGE_TITLE_HEALTH_PROGRAM_CATEGORY: "Health Program Category",
  SUBMIT_FEEDBACK: "Submit Feedback",
  WEVE_REVISED_OUR_TERMS: "We've revised our terms and conditions",
  TO_SERVE_YOU_BETTER: "To serve you better, we wanted you to be aware of the changes we've made. Please review and accept the new Terms and Conditions to continue using League.",
  CONTACT_TC_EMAIL: "Contact {helpEmail} if you have any questions about this update.",
  TERMS_AND_PRIVACY: "Terms & Privacy",
  PLEASE_REVIEW_OUR_TERMS_CONDITIONS: "Please review our Terms & Conditions, as well as our <a>Privacy Policy</a>",
  I_AGREE_TO_THE_TERMS_AND: "I agree to the Terms & Conditions and Privacy Policy",
  I_WOULD_LIKE_TO_OPTIN_MARKETING: "I would like to receive emails including rewards and discounts for health & wellness services.",
  NEXT_STEP: "Next Step",
  ENROLLMENT_DESIGN_PLAN_SELECTION_TITLE: "Select a plan",
  BENEFIT_CONFIG_TITLE: "Configure Benefit Sets",
  NO_PLAN_MESSAGE: "There are no Plans",
  ENROLLMENT_EXPERIENCE_CONFIG_TITLE: "Set up your Enrollment Experience",
  YOU_EARNED: "You earned",
  YOU_EARNED_X_POINTS: "You earned {points} points!",
  FORM_OPENS_IN_A_NEW_TAB: "Opens employee survey in a new tab",
  ENROLLMENT_DESIGN_NEXT_STEP_BUTTON: "Next Step",
  ENROLLMENT_DESIGN_PREV_STEP_BUTTON: "Previous Step",
  COMPANY_PROFILE: "Company Profile",
  REPORTS_SECTION_DESCRIPTION: "These are the report(s) that populate the insights you see in this dashboard",
  ENTER_CLASS_OR_PLAN: "Enter class or plan name...",
  NO_PLAN_SELECTION_RESULTS: "No results",
  CREATE: "Create {label}",
  ONLY_IN_LIFEMARKET: "Can only be redeemed in Lifemarket",
  ENROLLMENT_DESIGN_STEPS: "Steps",
  ENROLLMENT_DESIGN_STEP: "Step",
  ENROLLMENT_DESIGN_GROUP: "Benefit Set Group",
  ENROLLMENT_DESIGN_SET: "Benefit Set",
  AVAILABLE_BENEFIT_SETS: "Available Benefit Sets",
  PROVINCE_OF_EMPLOYMENT: "Province of Employment",
  GROUP_DRAG_ERROR_WITHIN_LIST: "Cannot Drag Group to Available Benefit Sets List",
  ENROLLMENT_DESIGN_OPTIONS_SAVED_SUCCESS: "Enrollment design options saved successfully!",
  ENROLLMENT_DESIGN_OPTIONS_SAVED_ERROR: "Error saving enrollment design options",
  ENROLLMENT_DESIGN_SETTINGS: "Settings",
  ENROLLMENT_DESIGN_SELECT_TO_EDIT: "Please select a Step, Benefit Set Group or Benefit Set to start editing.",
  ENROLLMENT_TEMPLATES_CREATED_SUCCESS: "Successfully created a new contentful template!",
  ENROLLMENT_TEMPLATES_CREATED_ERROR: "Error creating a new contentful template",
  YTD_REPORT: "YTD Report",
  YTD_REPORT_COPY: "This report shows premium deductions, adjustments, and TASA contributions for member(s) that meet the selected criteria during the dates specified.",
  START_DATE: "Start Date",
  YTD_END_DATE: "End Date",
  PAY_GROUP: "Pay Group",
  CREATE_STEP_TEXT: "Create Step",
  CREATE_GROUP_TEXT: "Add a Group",
  ENROLLMENT_DEFAULT_TEMPLATE_TOOLTIP: "Default templates are shared across all employers within the same country. If necessary, please reach out to Operations to create/edit a template.",
  ENROLLMENT_TEMPLATE_TOOLTIP: "Choose a default template from the list or create your own custom template specific to group, class or set using the buttons below.",
  ENROLLMENT_CONTENTFUL_DEFAULT_ERROR_BANNER: "There is no default template, please use a custom template or create a new template below.",
  ENROLLMENT_CONTENTFUL_ERROR_BANNER: "No applicable templates found in Contentful. Please create a new template below or reach out to Operations to have content created.",
  ENROLLMENT_CONTENTFUL_INFO_BANNER: "Be sure to save the configuration after changing the selected template.",
  ENROLLMENT_CONTENTFUL_DESCRIPTION: "This integration retrieves content for this benefit from Contentful (our content management system) for display in the Enrollment Experience.",
  ENROLLMENT_TEMPLATE_STATUS: "Template Status",
  ENROLLMENT_CONFIGURE_TEMPLATE: "Configure Template",
  ENROLLMENT_DEFAULT_TEMPLATE: "Default Template",
  CREATE_NEW_TEMPLATE: "Create New Template",
  ENROLLMENT_TEMPLATE_SELECT_LIST_LABEL: "Select a Template",
  ENROLLMENT_CREATE_TEMPLATE_GROUP: "Create template for Group ID",
  ENROLLMENT_CREATE_TEMPLATE_CLASS: "Create template for Class ID",
  ENROLLMENT_CREATE_TEMPLATE_TYPE: "Create template for {type} ID",
  ENROLLMENT_CHOOSE_A_TEMPLATE: "Choose a template...",
  DELETE_ENROLLMENT_STEP: "Delete Step",
  DELETE_ENROLLMENT_GROUP: "Delete Benefit Set Group",
  COMPANY_NAME: "Company Name",
  TRUSTEE_APPOINTMENT: "Trustee Appointment",
  TRUSTEE_APPOINTMENT_DESCRIPTION: "If the beneficiary is under the age of 18, please name a trustee. By filling in the fields below, I authorize the trustee to receive any payments on behalf of the beneficiary while under the age of 18 and to apply the proceeds solely for the support, maintenance, education and benefit of such beneficiary at the discretion of the trustee.",
  DATE_OF_BIRTH_TOOLTIP_QUEBEC: "<strong>For Quebec members, </strong> If you designate a Minor as a beneficiary, unless a Trust is created, the amount payable to the Minor (while they are still a Minor) will be paid to the parent(s) or legal guardian(s) of the Minor.  For further information, on creating a Trust, please reach out to your carrier.",
  DATE_OF_BIRTH_TOOLTIP_NON_QUEBEC: "<strong> For non-Quebec members, </strong> please fill out the information below when designating a Minor as a beneficiary.",
  REVOCABLE_DESIGNATIONS_QUEBEC_RESIDENTS: "For Quebec residents, if you name your legal spouse (marriage or common-law) as the beneficiary, this beneficiary will be Irrevocable for all insurances below, unless you check the box below.",
  DESIGNATE_REVOCABLE_BENEFICIARY: "If applicable, designate my spouse as an Revocable Beneficiary",
  UPDATE_AMOUNT_SUCCESS: "Success! Amount updated.",
  EDITING_PANEL_NAME: "Name",
  EDITING_PANEL_NAME_PLACEHOLDER: "Enter name...",
  EDITING_PANEL_NAME_HINT: "Name is only used within the enrollment design tools. To edit the name shown in the enrollment experience, the selected contentful template below would need to be updated.",
  EDITING_PANEL_NAME_IS_REQUIRED: "Name is required.",
  EDITING_PANEL_URL: "URL Slug",
  EDITING_PANEL_URL_PLACEHOLDER: "Enter step url slug...",
  EDITING_PANEL_URL_HINT: 'The step URL slug is added at the end of the URL to identify the step, ex "medical" or "dental". The URL slug must be unique, and only contain letters, underscores(_) or dashes(-).',
  EDITING_PANEL_URL_IS_REQUIRED: "URL Slug is required.",
  EDITING_PANEL_URL_MUST_BE_UNIQUE: '"{url}" is being used by another step. Please choose a different URL slug.',
  EDITING_PANEL_URL_MUST_BE_VALID: "URL Slug is not valid, only letters, underscores(_) or dashes(-) are allowed.",
  EDITING_PANEL_LAYOUT: "Layout",
  EDITING_PANEL_LAYOUT_PLACEHOLDER: "Select a layout...",
  APPLY_DEFAULT_CONFIG_BUTTON: "Apply Default Config",
  APPLY_DEFAULT_CONFIG_TITLE: "Apply Default Configurations",
  APPLY_DEFAULT_CONFIG_DESCRIPTION: "Applying the default configuration will overwrite the existing enrollment experience configuration. The order, grouping, layout and content of all steps, groups and benefit sets will be set to match the default configuration.",
  APPLY_DEFAULT_CONFIG_WARNING: "Any customizations made to the enrollment experience configuration will be lost. This action cannot be undone.",
  ENROLLMENT_DESIGN_OPTIONS_RESET_SUCCESS: "Default configurations applied successfully!",
  ENROLLMENT_DESIGN_OPTIONS_RESET_ERROR: "Error applying default configurations",
  ENROLLMENT_DETAILS: "Enrollment Details",
  ON_LEAVE_REASON: "<strong>Why can't I change anything?</strong> You are not able to update or change your benefit plan while on leave.",
  AUTHENTICATION_METHOD: "Authentication Method",
  RESET_MFA: "Reset multifactor authentication",
  RESET_LEGACY: "Please use the Legacy Tool to update account details",
  AUTH0_USER: "Auth0 User",
  LEGACY_USER: "Legacy Password User",
  RESET_MFA_CONFIRM: "An email will be sent to {email} with a link to enroll in multifactor authentication",
  RESET_MFA_SUCCESS: "Multifactor authentication method successfully reset",
  RESET_MFA_ERROR: "Multifactor authentication method failed to reset because {reason}",
  UPDATE_EMAIL_ADDRESS: "Update email address",
  UPDATE_PHONE_NUMBER: "Update phone number",
  CURRENT_EMAIL: "Current email",
  CURRENT_PHONE_NUMBER: "Current phone number",
  NEW_EMAIL: "New Email",
  NEW_PHONE_COUNTRY_CODE: "New country code",
  NEW_PHONE_NUMBER: "New phone number",
  CONFIRM_NEW_PHONE_NUMBER: "Confirm new phone number",
  CONFIRM_NEW_EMAIL: "Confirm new email",
  EMAIL_ADDRESS_ERROR: "Enter a valid email address",
  EMAIL_CONFIRM_MISMATCH: "Email addresses must match",
  PHONE_NUMBER_ERROR: "Enter a valid phone number",
  PHONE_COUNTRY_CODE_ERROR: "Enter a valid country code",
  PHONE_NUMBER_CONFIRM_MISMATCH: "Phone numbers must match",
  EMAIL_UPDATE_SUCCESS: "Email address updated",
  EMAIL_UPDATE_ERROR: "Email address failed to update because {reason}",
  PHONE_NUMBER_UPDATE_SUCCESS: "Phone number updated",
  BENEFIT_SET_HEADING: "Your {benefitSetName} Coverage",
  EMPLOYEE_SEARCH_FILTER_LABEL: "Filter",
  ACTIVE_EMPLOYEE_SEARCH_FILTER_TEXT: "Show only active employees",
  HR_ADMIN_SEARCH_FILTER_TEXT: "Show only HR administrators",
  CONTRIBUTION_CARD_HEADING: "{vendorName} {name}",
  WELLNESS_POOL_ELIGIBLE: "You can use your {flexDollarTerm} to pay for this benefit!",
  WELLNESS_POOL_BALANCE_DEDUCTION_DESCRIPTION: "These accounts will take priority, so you can spend up to your full {flexDollarTerm} amount on these accounts and then your previous selections will be covered by payroll deductions.",
  LEFTOVER_WELLNESS_POOL_HELPER_TEXT: "<strong>{percent}%</strong> of any {flexDollarTerm} that aren't used will automatically be added to this account",
  WELLNESS_POOL_USED_WITH_TERM: "{flexDollarTerm} Used",
  IT_LOOKS_LIKE_YOU_HAVE_SOME_LEFTOVER_WELLNESS_POOL: "It looks like you have some leftover {flexDollarTerm}! {message}",
  TOMORROW: "Tomorrow",
  YESTERDAY: "Yesterday",
  BACK_TO_TODAY: "Back to today",
  PREVIOUS_DAY: "Previous day",
  NEXT_DAY: "Next day",
  ACTIVITY_STATUS: "Activity status: ",
  ACTIVITY_STATUS_EXPIRED: "Expired",
  ACTIVITY_STATUS_REMOVED: "Skipped",
  ACTIVITY_STATUS_UPCOMING: "Available Soon",
  PAYMENT_PAYOUT_DETAILS_HEADING: "Payment Payout Details",
  RETRIGGER_PAYMENT_HEADING: "Retrigger Payment",
  REFUND_CLAIM_HEADING: "Refund Claim",
  PROMO_CODES_HEADING: "Promo Codes",
  NEW_PROMO_CODE: "New promo code",
  PROMO_CODE_CODE_LABEL: "Code",
  PROMO_CODE_CODE_PLACEHOLDER: "Must be three lowercase letters followed by three numbers",
  PROMO_CODE_USER_ID_PLACEHOLDER: "Enter the member's user ID",
  PROMO_CODE_AMOUNT_PLACEHOLDER: "Any amount from $1.00 up to $50.00 (anything above $50.00, please seek confirmation from Team Lead)",
  NEW_PROMO_CODE_MAX_USERS_PLACEHOLDER: "The number of user accounts that you'd like to apply a promo code to",
  PROMO_CODE_UPDATE_FUNDS_AMOUNT_PLACEHOLDER: "Specify a positive amount to add, or a negative amount to remove.",
  NEW_PROMO_CODE_MAX_USERS_LABEL: "Max users",
  NEW_PROMO_CODE_EXPIRES_LABEL: "Expires",
  UPDATE_FUNDS_PROMO_CODE_POLICY_LABEL: "Policy type",
  TRIGGER_NEW_PROMO_CODE_MODAL: "Are you sure you want to create the new promo code?",
  ADD_PROMO_CODE: "Add promo code to account",
  PROMO_CODE_USER_ID_LABEL: "User ID",
  REMOVE_PROMO_CODE: "Remove promo code from account",
  UPDATE_FUNDS_PROMO_CODE: "Update funds",
  UPDATE_FUNDS_DESCRIPTION_PROMO_CODE: "Add or remove funds from a user's HSA or PSA balance.",
  TRIGGER_UPDATE_FUNDS_PROMO_CODE_MODAL: "Are you sure you want to update funds?",
  TRIGGER_REMOVE_PROMO_CODE_MODAL: "Are you sure you want to remove the promo code from the account?",
  TRIGGER_ADD_PROMO_CODE_MODAL: "Are you sure you want to add the promo code to the account?",
  TRIGGER_RESUBMIT_CLAIM_CLAIM_ID_PLACEHOLDER: "Enter a claim ID",
  TRIGGER_RESUBMIT_CLAIM_HSA_PLACEHOLDER: "Enter an HSA Amount",
  TRIGGER_RESUBMIT_CLAIM_PSA_PLACEHOLDER: "Enter an LSA Amount",
  TRIGGER_REFUND_CLAIM_MODAL: "Are you sure you want to refund the following claim?",
  INSURANCE_ERROR_PREPARING_FORMS: "There was an error preparing your form.",
  TRUPANION_IMPORTANT_READ_ALL: "Important, please read all of the below",
  TRUPANION_IMPORTANT_READ_ALL_DESCRIPTION: "As part of the experience, you will be able to elect Aflac Accident, Critical Illness and Hospital Indemnity benefits. Please review the below compliance language before moving forward with your 2022 Open Enrollment elections",
  TRUPANION_ACCIDENT_DESCRIPTION_1: "If a covered child reaches a limiting age as specified in the certificate or a rider, it is your responsibility to notify the company.",
  TRUPANION_ACCIDENT_DESCRIPTION_2: "To the best of my knowledge and belief, my answers to the questions are true and complete.  They are offered to Continental American Insurance Company as the basis for any insurance issued. I realize any false statement or misrepresentation in the application may result in loss of coverage under the certificate. I understand that no insurance will be in effect unless I am actively at work on the effective date of coverage, and until my application is approved and the necessary premium is paid. If I am not actively at work on the effective date of coverage, coverage will become effective on the date I return to an active work status.",
  TRUPANION_ACCIDENT_DESCRIPTION_3: "I authorize the Group Policyholder to deduct the appropriate dollar amount from my earnings each pay period to pay Continental American Insurance Company the required premium for my insurance.",
  TRUPANION_ACCIDENT_DESCRIPTION_4: "It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_1: "If this coverage will replace any existing Aflac individual policy, please be aware that it may be in your best interest to maintain your individual guaranteed-renewable policy via direct bill.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_2: "I have considered all of my existing health insurance coverage with Aflac and believe this additional coverage is appropriate for my insurance needs. I further understand that I can contact Aflac at 1-800-992-3522 regarding my individual policy and for assistance in evaluating the suitability of my insurance coverage.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_3: "If a covered child reaches a limiting age as specified in the certificate or a rider, it is your responsibility to notify the company.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_4: "To the best of my knowledge and belief, my answers to the questions are true and complete.  They are offered to Continental American Insurance Company as the basis for any insurance issued. I realize any false statement or misrepresentation in the application may result in loss of coverage under the certificate. I understand that no insurance will be in effect unless I am actively at work on the effective date of coverage, and until my application is approved and the necessary premium is paid. If I am not actively at work on the effective date of coverage, coverage will become effective on the date I return to an active work status.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_5: "I authorize the Group Policyholder to deduct the appropriate dollar amount from my earnings each pay period to pay Continental American Insurance Company the required premium for my insurance.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_6: "It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_1: "If this coverage will replace any existing Aflac individual policy, please be aware that it may be in your best interest to maintain your individual guaranteed-renewable policy via direct bill.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_2: "I have considered all of my existing health insurance coverage with Aflac and believe this additional coverage is appropriate for my insurance needs. I further understand that I can contact Aflac at 1-800-992-3522 regarding my individual policy and for assistance in evaluating the suitability of my insurance coverage.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_3: "If a covered child reaches a limiting age as specified in the certificate or a rider, it is your responsibility to notify the company.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_4: "To the best of my knowledge and belief, my answers to the questions are true and complete.  They are offered to Continental American Insurance Company as the basis for any insurance issued. I realize any false statement or misrepresentation in the application may result in loss of coverage under the certificate. I understand that no insurance will be in effect unless I am actively at work on the effective date of coverage, and until my application is approved and the necessary premium is paid. If I am not actively at work on the effective date of coverage, coverage will become effective on the date I return to an active work status.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_5: "I authorize the Group Policyholder to deduct the appropriate dollar amount from my earnings each pay period to pay Continental American Insurance Company the required premium for my insurance.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_6: "It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.",
  TRUPANION_CONFIRMATION_DESCRIPTION: 'By enrolling in Aflac benefit(s) I affirm that I have read the "Things to Consider About My Insurance Coverage" notice, which has been made available to me on this electronic enrollment site',
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_OR_FUTURE_HEADING: "Nothing planned - yet!",
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_DESCRIPTION: "Tap the \u2018Add\u2019 button to find new programs or browse curated list of activities below.",
  NO_ACTIVITIES_COMPLETED_TODAY_HEADING: "Well done, you\u2019re on a roll!",
  NO_ACTIVITIES_COMPLETED_TODAY_DESCRIPTION: "Come back tomorrow for more or browse through new programs and suggested activities.",
  NO_ACTIVITIES_FUTURE_DESCRIPTION: "You don\u2019t have any activities scheduled for this day.",
  NO_ACTIVITIES_PAST_HEADING: "Rest Day",
  NO_ACTIVITIES_PAST_DESCRIPTION: "Looks like you took this day to relax and recover.",
  BUTTON_BROWSE_PROGRAMS: "Browse Programs",
  IMG_ALT_HIGH_FIVE_HANDS: "Two hands slapping a high five",
  IMG_ALT_STANDING_YOGA_POSE: "Person doing a standing yoga pose on a mat",
  IMG_ALT_TO_DO_CLIPBOARD: "Clipboard with to-do list completed",
  IMG_ALT_EMPTY_STATE_PROGRAMS: "Woman standing and scrolling a wall-sized list of programs",
  IMG_ALT_EMPTY_STATE_REST: "Man resting in a comfy chair with a warm mug and headphones",
  THIS_IS_JUST_A_SNEAK_PEAK: "This is just a sneak peek!",
  COME_BACK_COMPLETE_ACTIVITY_ON_DATE: "Come back and complete this activity on {date}.",
  COME_BACK_COMPLETE_ACTIVITY_LATER: "Come back later to complete this activity.",
  LOOKS_LIKE_YOU_MISSED_THIS_ONE: "Looks like you missed this one!",
  THIS_ACTIVITY_EXPIRED_ON_DATE: "This activity expired on {date}.",
  LOOKS_LIKE_THIS_WASNT_MEANT_FOR_YOU: "Looks like this one wasn\u2019t meant for you",
  YOU_SKIPPED_THIS_ACTIVITY: "You skipped this activity.",
  SEE_EXAMPLE: "See Example",
  CLOSE_MODAL: "Close Modal",
  NO_PROGRAMS: "Nothing to see here just yet",
  NO_ACTIVITY_TITLE: "Nothing here (yet!)",
  NO_LEADERBOARD_ACTIVITY_DESCRIPTION: "We'll display your ranking here once the challenge starts.",
  NO_HIGHLIGHTS_ACTIVITY_DESCRIPTION: "Check back here to see challenge highlights soon.",
  LEADERBOARD_ALL_TIME_RANKING: "All Time Ranking",
  LEADERBOARD_CURRENT_USER_NAME: "{user} (You)",
  ACTIVITY_PROGRESS: "Activity Progress",
  SEARCH_BY_USERID_EMAIL_OR_NAME: "Search by user_id, email or name",
  USERS_AWAITING_VALIDATION: "Users awaiting validation",
  NO_USERS_AWAITING_VALIDATION: "No users awaiting validation",
  NO_USERS_FOUND: "No users found",
  SEARCH_USERS_HEADING: "Search Users",
  DOWNLOAD_SDR_REPORT: "Download SDR Report",
  CONTENT_MERCH_LIST_TITLE: "Content Merch List Title",
  CREATE_NEW_LIST: "Create new list",
  CONFIRM_REMOVAL: "Confirm removal",
  CONFIRM_UPDATE: "Confirm update",
  CONFIRM_REMOVAL_TEXT: "Are you sure you want to remove {title}? This cannot be undone.",
  CONFIRM_REMOVAL_TEXT_UPDATE_LIST: "Are you sure you want to save this list? This cannot be undone.",
  SAVE_MERCH_LIST: "Save list",
  PUBLISHED_MERCH_LIST: "Published",
  ENGLISH_TITLE_MERCH_LIST: "List title",
  ENGLISH_TITLE_MERCH_LIST_HINT: "This field is only viewable in-app on carousels, for regular banners (such as client banners), this field is internal facing only.",
  FRENCH_TITLE_MERCH_LIST: "Localized title (fr-CA)",
  SPANISH_TITLE_MERCH_LIST: "Localized title (es-MX)",
  ENGLISH_DESCRIPTION_MERCH_LIST: "English description",
  FRENCH_DESCRIPTION_MERCH_LIST: "Localized description (fr-CA)",
  SPANISH_DESCRIPTION_MERCH_LIST: "Localized description (es-MX)",
  REQUIRED_FIELD_MERCH_LIST: "Required if English description provided",
  BACK_TO_MERCH_LISTS: "Back to Merch Lists",
  LIST_TITLE: "List Title",
  LIFEMARKET_MERCH_LIST_HELP_TEXT: "This is an internal admin title for this Lifemarket Merch List. The Shopify Collection Title will be the one shown to users in the carousels.",
  SHOPIFY_COLLECTION_HANDLE: "Shopify Collection Handle",
  SHOPIFY_HELP_TEXT: "ex. https://marketplace.ca.league.dev/collections/medical-supplies = `medical-supplies`",
  SHOPIFY_COLLECTION_TITLE: "Lifemarket Products in Shopify Collection",
  PREVIEW_ITEMS: "Preview Items",
  SHOPIFY_COLLECTION_CA_TITLE: "CA Shopify Preview",
  SHOPIFY_COLLECTION_US_TITLE: "US Shopify Preview",
  SHOPIFY_COLLECTION_NO_DATA: "There is no data for this",
  YOUR_JOURNEY: "Your journey",
  DEACTIVATE_ACCOUNT: "Deactivate Account",
  TRIGGER_DEACTIVATE_ACCOUNT_MODAL: "Are you sure you want to deactivate this account?",
  PAYMENT_PROFILE: "Payment profile",
  CREDENTIALS: "Credentials",
  VALIDATION: "Validation",
  DEVICES: "Devices",
  SUGGESTED_FOR_YOU: "Suggested For You",
  ASSIGNED_BY_CARE_ADVISOR: "Assigned by your Care Advisor",
  PAYMENT_PROFILES: "Payment profiles",
  NO_PAYMENT_PROFILES_PLACEHOLDER: "There are no payment profiles associated with this user",
  NO_USER_DEVICES_PLACEHOLDER: "There are no devices associated with this user",
  PROMO_CODE_HISTORY: "Promo code history",
  NO_PROMO_CODE_HISTORY_PLACEHOLDER: "There is no promo code history associated with this user",
  CUSTOM: "Custom",
  CUSTOM_REPORT: "Custom Report",
  PRESETS_COLUMN: "Presets Column",
  PRESETS_NAME: "Presets Name",
  REMOVE_PRESETS_REPORT: "Remove presets report",
  PRESETS_REPORTS: "Presets Report",
  REMOVE_PRESETS: "Remove presets",
  AVAILABLE_COLUMNS: "Available Columns",
  AVAILABLE_COLUMNS_BLURB: "All columns currently available for this report are listed below. If you do not add a column to the 'Presets' section it will not appear on the report.",
  PRESETS_COLUMN_BLURB: "These columns will be shown in the report from left to right in the order that you define in this list.",
  USER_LINKS: "User Links",
  EMPLOYEE_PROFILE_LINKS_BY_GROUP: "Employee Profile Links by Group",
  MEMBER_TOOLING_LINK: "Member Tooling Link",
  MEMBER_WALLET_LINK: "Member Wallet Link",
  EXTERNAL_LINK: "External link",
  ALREADY_A_LEAGUE_MEMBER: "Already a League member?",
  SIGN_IN_HERE: "Sign In Here",
  EXPORT_DEDUCTION_CODES: "Export Deduction Codes",
  NO_ACHIEVEMENTS_DESCRIPTION: "Complete activities in your Journey to start unlocking achievements.",
  UNLOCK_ACHIEVEMENTS: "Unlock an achievement for completing all activities in this program.",
  MARKETING_CONTENT: "Marketing Content",
  SUBTITLE_OPTIONAL: "Subtitle (optional)",
  SOURCE_OPTIONAL: "Source (optional)",
  BACKGROUND_COLOR_OPTIONAL: "Background Color (optional)",
  READ_TIME_OPTIONAL: "Read Time (optional)",
  TAGS_OPTIONAL: "Tags (optional)",
  TARGET_BY_COUNTRY_OPTIONAL: "Target by Country (optional)",
  TARGET_BY_COUNTRY_OPTIONAL_HINT: "To target your banner to specific countries. If your banner should show to all members, no matter their location, do not select anything in this section.",
  END_DATE: "End Date",
  TARGET_GROUP_IDS_OPTIONAL: "Target group IDs (optional)",
  TARGET_GROUP_IDS_OPTIONAL_HINT: "To target your banner to members from specific companies. Group IDs must be separated by commas. You may manually type in the IDs, or you may upload a CSV file that contains one ID per unit, which will automatically render the IDs in the input field below. Leaving this section blank will either target your banner to all members, or just to members specified under User IDs (if using that field).",
  TARGET_USER_IDS_OPTIONAL: "Target user IDs (optional)",
  TARGET_USER_IDS_OPTIONAL_HINT: "To target your banner to specific members. User IDs must be separated by commas. You may manually type in the IDs, or you may upload a CSV file that contains one ID per unit, which will automatically render the IDs in the input field below. Leaving this section blank will either target all members, or just to members at companies that are specified under Group IDs (if using that field).",
  TARGET_USER_TYPE_OPTIONAL: "Target User Type (optional)",
  EVERYONE: "Everyone",
  HR_ADMINS: "HR Admins",
  MEMBERS: "Members",
  PROS: "Pros",
  TARGET_USERS_WITH_ALL_OPTIONAL: "Target Users with ALL of the following (optional)",
  TARGET_USERS_WITH_ANY_OPTIONAL: "Target Users with ANY of the following (optional)",
  TARGET_LANGUAGES_OPTIONAL: "Target Languages (optional)",
  TARGET_LANGUAGES_OPTIONAL_HINT: "Select the language your banner is written in to target members with that language setting. If you make no selection, the banner will be viewable to all members regardless of language setting.",
  OPEN_URL: "Open URL",
  URL: "URL",
  HAS_HSA_BALANCE: "Has HSA Balance",
  HAS_LSA_BALANCE: "Has LSA Balance",
  CORPORATE_WELLNESS: "Corporate Wellness",
  SPENDING_ACCOUNT: "Spending Account",
  SPENDING_ACCOUNTS_INSIGHTS: "Spending Account Insights",
  HEALTH_REIMBURSEMENT_ACCOUNT: "Health Reimbursement Account",
  PEACE_OF_MIND: "Peace of Mind",
  CONSUMER_SERVICE: "Consumer Service",
  FLU_CLINICS: "Flu Clinics",
  GROUP_CLASSES: "Group Classes",
  WELLNESS_SEMINARS: "Wellness Seminars",
  HEALTH_SCREENINGS: "Health Screenings",
  ON_SITE_TREATMENTS: "On-site Treatments",
  ENTER_SUBTITLE_FOR_ARTICLE: "Enter a subtitle for the article",
  ENTER_DESCRIPTION_SHOW_ARTICLE_PREVIEW: "Enter a description to show for article preview text",
  ENTER_SOURCE_ARTICLE: "Enter the source of the article (i.e. NY Times)",
  ENTER_HEX_CODE: "Enter a hex code (e.g. #eaeefb)",
  ENTER_READ_TIME_MINUTES: "Enter read time in minutes (Numeric value only!)",
  ENTER_TAGS_ASSOCIATED_ARTICLE: "Enter the comma-separated tags associated with this article (i.e. health, nutrition, millenials)",
  ENTER_TARGET_GROUP_IDS: "Enter comma separated target group ids",
  ENTER_TARGET_USER_IDS: "Enter comma separated target user ids",
  IMAGE: "Image",
  SELECT_AN_IMAGE: "Select an image",
  MARKETING_CONTENT_ADDED: "Marketing Content was added!",
  ENROLLMENT_PASSED: "You can't sign up for this challenge anymore because the enrollment dates have passed.",
  SIGN_UP_PERIOD_PASSED_DETAIL: "You can't sign up for this challenge anymore because the sign-up period has passed.",
  REFERENCES: "References",
  LINK_PAYROLL_REPORT_CONFIGS: "Link Payroll Report Configs",
  PAYROLL_REPORT_CONFIGS: "Payroll Report Configs",
  PAYROLL_REPORT_CONFIGS_COPY: "Choose a payroll report config to link the schedule.",
  LINK_PAYROLL_CONFIGS: "Link Config",
  DEFAULT_CONFIG: "Default Payroll Config",
  PAY_CALENDAR_MENU: "Pay Calendar Menu",
  MORE_INFORMATION: "More information",
  MARKETING_CONTENT_SAVED: "Marketing Content was saved!",
  SIGN_UP_BY: "Sign up by {date}",
  VIEW_FORM: "View Form",
  YOURE_ALL_SET: "You're all set!",
  YOURE_GOOD_TO_GO: "You're good to go!",
  YOU_LOOK_GREAT: "You look great!",
  CHALLENGE_LETS_START: "You're ready to start the challenge.",
  CHECK_CHALLENGE_DETAILS: "Check out the challenge details to familiarize yourself with the activities",
  CHALLENGE_LETS_START_TEAMS_ENABLED: "You're ready to start the challenge. Do you want to join or create a team?",
  CREATE_NEW_MARKETING_CONTENT: "Create new Marketing Content",
  SAVE_LIST_BEFORE_ADDING_CONTENT: "Please save this new list first before adding content.",
  BACK_TO_MERCH_LIST: "Back to Merch List",
  BLUECAT_LSA_TOOLTIP: "This benefit is only available for employees hired on or before December 31, 2023.",
  CHALLENGE_ABOUT_TO_START: "Your challenge is about to start!",
  ACTIVITIES_APPEAR_HERE_WHEN_CHALLENGE_STARTS: "Daily activities will appear here when your challenge starts on <strong>{date}</strong>.",
  NO_CHALLENGE_ACTIVITIES_TODAY: "There are no challenge activities for you today.",
  MARKETING_CONTENT_REMOVED: "Marketing Content was removed!",
  COVERAGE_SEARCH_QUERY_NOT_FOUND: '"{query}" could not be found.',
  COVERAGE_SEARCH_TRY_AGAIN: "Try searching again using a different keyword.",
  EARN: "Earn",
  WORD_WITH_EXCLAMATION_POINT: "{word}!",
  UPDATED: "Updated",
  YOUR_PROGRESS: "Your progress",
  YOUR_PROGRESS_IN_NUMBERS: "{current} of {total} {unit} or {percentage} percent complete",
  OUTCOMES_FRICTION_SCREEN_TITLE: "Personalizing suggestions...",
  OUTCOMES_FRICTION_SCREEN_DESCRIPTION: "Give us a moment to find some helpful resources for you based on your answers",
  RELOAD_MY_SUGGESTIONS: "Reload my suggestions",
  I_AGREE: "I Agree",
  YOUR_RANKING: "Your ranking",
  HEADLINE: "Headline",
  ENTER_HEADLINE_FOR_ARTICLE: "Enter a headline for the article",
  BODY_COPY: "Body copy",
  ENTER_BODY_COPY_FOR_ARTICLE: "Enter a body copy for the article",
  CALL_TO_ACTION_TEXT: "Call to action text",
  CALL_TO_ACTION_HINT: "This is the highlighted text that members can click to open the URL",
  KAISER_COMPLIANCE_TITLE: "Kaiser Foundation Health Plan Arbitration Agreement",
  KAISER_COMPLIANCE_DESCRIPTION: "I understand that (except for Small Claims Court cases, claims subject to a Medicare appeals procedure or the ERISA claims procedure regulation, and any other claims that cannot be subject to binding arbitration under governing law) any dispute between myself, my heirs, relatives, or other associated parties on the one hand and Kaiser Foundation Health Plan, Inc. (KFHP), any contracted health care providers, administrators, or other associated parties on the other hand, for alleged violation of any duty arising out of or related to membership in KFHP, including any claim for medical or hospital malpractice (a claim that medical services were unnecessary or unauthorized or were improperly, negligently.",
  DEPARTMENT: "Department",
  WORK_LOCATION: "Work Location",
  MANAGER: "Manager",
  TOP_RANKED: "Top Ranked",
  TOP_RANKED_SUBTITLE: "by number of",
  VIEW_CHALLENGE_DETAILS: "View Challenge Details",
  IMAGE_BANNER_DISPLAY: "Banner display",
  IMAGE_CAROUSEL_DISPLAY: "League blog carousel display",
  NEXT_SLIDE: "Next slide",
  PREVIOUS_SLIDE: "Previous slide",
  LEAGUE_FAMILY_EDUCATION_BANNER_HEADER: "Wondering why it's beneficial to add your dependents?",
  LEAGUE_FAMILY_EDUCATION_BANNER_SUBHEADER: "Your dependents can access League and enjoy a healthier life too!",
  USER_SEARCH_SUCCESSFUL: "User search successful",
  PROFILE_DETAILS: "Profile Details",
  LEAGUE_FAMILY_INVITE_MODAL_HEADING: "Where should we send {name}\u2019s invitation?",
  LEAGUE_FAMILY_INVITE_MODAL_RESEND_HEADING: "Where should we resend {name}\u2019s invitation?",
  LEAGUE_FAMILY_INVITE_MODAL_DESCRIPTION: "Please use an email address that isn\u2019t associated with an existing League account. This invitation expires 14 days after it is sent.",
  LEAGUE_FAMILY_INVITE_MODAL_CONSENT_LABEL: "I consent that the recipient of this invitation has acknowledged and accepted this request, and that the information provided is accurate and true.",
  LEAGUE_FAMILY_INVITE_MODAL_PRIVACY_DISCLAIMER: "Your personal information is safe with us, your information will not be shared with your dependents. Learn more in our {privacyPolicyLink}.",
  LEAGUE_FAMILY_INVITE_MODAL_SUCCESS_HEADING: "Your invitation has been sent",
  LEAGUE_FAMILY_INVITE_MODAL_SUCCESS_DESCRIPTION: "Let your loved one know they can now download the League app.",
  JOB_DETAILS: "Job Details",
  UNABLE_TO_LEAVE_CHALLENGE: "Unable to leave the challenge, please try again!",
  SUCCESSFULLY_LEFT_CHALLENGE: "You have successfully left the challenge.",
  UNABLE_TO_JOIN_CHALLENGE: "Unable to join the challenge, please try again!",
  SECONDARY_DESCRIPTION: "Secondary Description",
  JOIN_TEAM: "Join Team",
  X_TEAM_MEMBERS: "{memberTotal, plural, one {# team member} other {# team members}}",
  X_SPOTS_AVAILABLE: "{spotsAvailable, plural, one {# spot available} other {# spots available}}",
  HIGHLIGHTS: "Highlights",
  CURRENT_RANK: "Current Rank",
  X_LIKES: "{activityLikes, plural, one {# Like} other {# Likes}}",
  LIKE: "Like",
  LIKED: "Liked",
  USER_EARNED: "{user} earned a total of",
  TEAM_INVITE_HEADING: "We're better together!",
  TEAM_INVITE_DESCRIPTION: "Invite friends to join your team to complete activities together.",
  TEAM_INVITE_LINK_DESCRIPTION: "The more the merrier! Share this link with friends so they can join your team.",
  TEAM_INVITE_LINK: "Team invite link",
  INVITE: "Invite",
  LEAVE_TEAM: "Leave Team",
  INVITE_FRIENDS: "Invite Friends",
  PRIVACY: "Privacy Settings",
  JOIN_TEAM_SUCCESS_TITLE: "Welcome to the team!",
  JOIN_TEAM_SUCCESS_DETAIL: "Let\u2019s conquer these activities together.",
  JOIN_TEAM_SUCCESS_BUTTON: "Go to Team Details",
  CANT_JOIN_TEAM_TITLE: "Unable to join {teamName}",
  CANT_JOIN_TEAM_DETAIL: "Please try again! If you've already joined a team, you'll need to leave your current team before you can join {teamName}.",
  CANT_JOIN_TEAM_JOIN_CHALLENGE_DETAIL: "Looks like you haven't signed up for the challenge yet. You'll need to sign up before you can join {teamName}.",
  LEAVE_TEAM_QUESTION: "Leave {teamName}?",
  LEAVE_TEAM_DESCRIPTION: "Are you sure you want to leave {teamName}? Your score will no longer be considered as part of the team\u2019s average.",
  CHALLENGE_ACTIVITY: "Challenge Activity",
  CHALLENGE_ACTIVITY_DETAIL: "Allow others to see your activity in this challenge.",
  SHOW_MY_ACTIVITY: "Show My Activity",
  YOUR_TEAM: "Your team",
  JOIN_OR_CREATE_TEAM: "Join or Create a Team",
  TEAMS: "Teams",
  CHALLENGE_TEAMS_DESCRIPTION: "Join an existing team or create your own to compete in the challenge together!",
  JOIN_A_TEAM: "Join a Team",
  JOIN_TEAM_DESCRIPTION: "Search for an existing team to join and compete together!",
  SEARCH_FOR_TEAM: "Search for a team",
  CREATE_A_TEAM: "Create a Team",
  CREATE_TEAM_DESCRIPTION: "Customize your team by setting your own avatar and team name. Invite others to join you!",
  UP_YOUR_GAME: "Level up your game",
  UP_YOUR_GAME_DESCRIPTION: "There's strength in numbers. Join a team or create your own.",
  XTH_PLACE: "{currentRank, selectordinal, one {#st} two {#nd} few {#rd} other {#th} } Place",
  LEADERBOARD_RANKING: "Ranking",
  LEADERBOARD_TOKENS: "Tokens",
  LEADERBOARD_USERNAME: "Username",
  INVITE_LINK_COPIED_TO_CLIPBOARD: "Invite link copied to clipboard!",
  UNABLE_TO_COPY_LINK_TO_CLIPBOARD: "We weren't able to copy the link to your clipboard! Please highlight the link and copy manually.",
  DATE_AND_TIME: "Date and Time",
  SELECT_TEAM_AVATAR: "Select Team Avatar",
  CHANGE_TEAM_AVATAR: "Change Team Avatar",
  TEAM_NAME_LABEL: "Team Name",
  CREATE_TEAM: "Create Team",
  TEAM_NAME_ERROR: "Please enter a name for your team.",
  SLIDE_OF_TOTAL: "Slide {key} of {total}",
  PAGE_OF_TOTAL: "Page {current} of {total}",
  SET_TEAM_AVATAR: "Set Team Avatar",
  NONE_OF_THE_ABOVE: "None of the above",
  NO_SOLUTIONS_AVAILABLE: "We're sorry, there are no solutions available at the moment.",
  ACHIEVEMENT_MODAL_LABEL: "Achievement Detail Modal",
  SEE_ALL_ACHIEVEMENTS: "See all achievements",
  VIEW_ALL_ACHIEVEMENTS: "View All Achievements",
  TAKE_ME_BACK: "Take Me Back",
  TEAM_PROFILE_PHOTO_DESCRIPTION: "This photo shows up on your team\u2019s profile and on the leaderboard.",
  TEAMS_SELECT_AVATAR_DESCRIPTION: "This avatar shows up on your team\u2019s profile and on the leaderboard.",
  CREATE_TEAM_SUCCESS: "Your team is ready!",
  CREATE_TEAM_SUCCESS_DESCRIPTION: "Start building your team. Share this link with others so you can complete activities together.",
  VIEW_TEAM_DETAILS: "View Team Details",
  FEATURES_HIGHLIGHT_CAROUSEL_LABEL: "Features highlight carousel",
  CHALLENGE_FAQ_HEADING: "Have questions about Challenges?",
  CHALLENGE_FAQ_CONTENT: "Check out our {helpCenterLink} for all your frequently asked questions.",
  REMEMBER_TO_SAVE: "Please remember to scroll to the bottom and hit save.",
  VISIBILITY: "Visibility",
  VIEW_THIS_RESOURCE: "View This Resource",
  RECOMMENDED_NEXT_STEP: "Recommended Next Step",
  FIELD_SETTINGS_TITLE: "Profile field settings",
  FIELD_SETTINGS_SUBHEADER: "Provide a tailored employer experience to the HR Admin by choosing which non-system fields should be visible in the Employee Profile Form.",
  FIELD_SETTINGS_SUBTITLE: "The setting on this page allows you to toggle the visibility of specific profile fields in the member profile. Use these to hide fields that a group is not using from their member view.",
  FIELD_SETTINGS_NOTE: "<strong>Note:</strong>",
  FIELD_SETTINGS_LIST_ONE: "Toggling a field off will not remove it from the database or delete previously entered values.",
  FIELD_SETTINGS_LIST_TWO: "You must use the 'save' button at the bottom of this page to apply your changes.",
  FIELD_SETTINGS: "Field Settings",
  ACTIVATION_DATE_DESCRIPTION: "Date on which the member creates/registers their League login",
  ANNUAL_EARNINGS_FOR_POOLED_BENEFITS_DESCRIPTION: "Employee's annual salary, tied to salary-based benefits",
  BILLING_DIVISION_DESCRIPTION: "Client is invoiced based on billing division, a member is included on the corresponding invoice based on the division they're assigned",
  CUSTOM_FIELDS_DESCRIPTION: "Specific (Can vary per group requirements)",
  DENTAL_CARE_DESCRIPTION: "Populated with dependent name if Dental benefits have been coordinated and if the functionality is turned ON for the group",
  DEPARTMENT_DESCRIPTION: "Employee Place of work within the Company",
  DRUG_BENEFITS_DESCRIPTION: "Populated with dependent name if Drug benefits have been coordinated and if the functionality is turned ON for the group",
  EMPLOYEE_LEAVE_START_DATE_DESCRIPTION: "Example:  a member goes on maternity Leave this field reflects the date the Leave started",
  EXTENDED_HEALTH_BENEFITS_DESCRIPTION: "Populated with dependent name if health benefits have been coordinated and if the functionality is turned ON for the group",
  FLSA_EXEMPT_DESCRIPTION: "Fair Labor Standards Act Exemption Flag",
  HIDE_BENEFIT_CONFIG_STEP_DESCRIPTION: "This field stops a member from making benefit changes at any time",
  HIDE_DEPENDENTS_STEP_DESCRIPTION: "Does not allow member to add/change dependents at any time",
  HIDE_TYPEFORM_STEP_DESCRIPTION: "Member will not be prompted to complete a Typeform",
  HOURLY_RATE_DESCRIPTION: "An amount will reflect on this field if the member is paid hourly and not on a salary basis",
  HR_MANAGER_DESCRIPTION: "Human resource manager",
  INVITATION_CODE_DESCRIPTION: "This code is generated for a link to be sent to member to activate their League profile",
  NO_PLATFORM_FEE_CHARGES_DESCRIPTION: "Exclude employee from platform fees",
  OFFICE_LOCATION_DESCRIPTION: "City/ID of the office where member has been employed ",
  PAY_GROUP_DESCRIPTION: "For payroll purposes members are put in pay groups based on Pay frequency, location etc. Also impacts the per pay amount shown in enrollment to users.",
  PAYROLL_ID_DESCRIPTION: "Employee Identification number used by payroll agent",
  PHONE_NUMBER_DESCRIPTION: "Member contact number",
  PREFERRED_FIRST_NAME_DESCRIPTION: "Employee's Name",
  REINSTATED_DESCRIPTION: "Member who was previously terminated is now active again",
  REINSTATED_DATE_DESCRIPTION: "Date a member has been reactivated after being previously terminated",
  SUPERVISOR_DESCRIPTION: "A person in charge for specific department who employee's can report to",
  SUSPENDED_DESCRIPTION: "Terminated",
  SUSPENDED_BENEFITS_EXTENSION_DATE_DESCRIPTION: "Terminated employee is granted to maintain benefits active, this field reflects the date these benefits are ending.",
  SUSPENDED_DATE_DESCRIPTION: "Date of Termination",
  SUSPENDED_NOTICE_DATE_DESCRIPTION: "Date of termination notice",
  SUSPENDED_TYPE_DESCRIPTION: "The termination status of the member",
  TERMINATION_REASON_DESCRIPTION: "This field reflects the cause of member separation from Employer",
  VISION_BENEFITS_DESCRIPTION: "Populated with dependent name if vision benefits have been coordinated and if the functionality is turned ON for the group",
  VIEWABLE: "Viewable",
  CARE_COLLECTIONS: "Care Collections",
  PLAN_PROGRESS: "Plan Progress",
  SINCE_LAST_WEEK: "since last week",
  INSIGHT_CARD_HEADER_DISTANCE: "Distance",
  INSIGHT_CARD_HEADER_STEPS: "Steps",
  MILES: "{count, plural, one {mile} other {miles}}",
  MIN: "min",
  KJ: "KJ",
  CAL: "Cal",
  KM: "km",
  INSIGHT_CARD_UNIT_STEPS: "{count, plural, one {step} other {steps}}",
  INSIGHT_CARD_HEADER_FLOORS_CLIMBED: "Flights Climbed",
  INSIGHT_CARD_UNIT_FLOORS_CLIMBED: "{count, plural, one {flight} other {flights}}",
  INSIGHT_CARD_HEADER_ACTIVE_DURATION: "Active Minutes",
  INSIGHT_CARD_HEADER_ENERGY_BURNED: "Energy Burned",
  INSIGHT_CARD_HEADER_SLEEP_DURATION: "Sleep Duration",
  INSIGHT_CARD_HEADER_HEART_RATE: "Heart Rate",
  INSIGHT_CARD_HEADER_BLOOD_GLUCOSE: "Blood Glucose",
  INSIGHT_CARD_HEADER_BLOOD_PRESSURE: "Blood Pressure",
  INSIGHT_CARD_HEADER_BLOOD_OXYGEN: "Blood Oxygen",
  INSIGHT_CARD_HEADER_WEIGHT: "Weight",
  INSIGHT_CARD_HEADER_BODY_TEMPERATURE: "Body Temperature",
  INSIGHT_CARD_HEADER_HEIGHT: "Height",
  INSIGHT_CARD_HEADER_MINDFUL_DURATION: "Mindful Minutes",
  INSIGHT_CARD_HEADER_WAIST_CIRCUMFERENCE: "Waist Circumference",
  DAY_OF_THE_WEEK: "DAY OF THE WEEK",
  DAY_OF_THE_MONTH: "DAY OF THE MONTH",
  INSIGHT_CHART_WEEK_TITLE: "Weekly {type} bar chart",
  INSIGHT_CHART_MONTH_TITLE: "Monthly {type} bar chart",
  INSIGHT_CHART_DISTANCE_WEEK_DESCRIPTION: "The number of {unit} you've walked each day of the week.",
  INSIGHT_CHART_DISTANCE_MONTH_DESCRIPTION: "The number of {unit} you've walked each day of the month.",
  INSIGHT_CHART_DISTANCE_DATUM_LABEL: "You've walked {distance} {unit} on {date}",
  INSIGHT_CHART_STEPS_WEEK_DESCRIPTION: "The number of steps you've walked each day of the week.",
  INSIGHT_CHART_STEPS_MONTH_DESCRIPTION: "The number of steps you've walked each day of the month.",
  INSIGHT_CHART_STEPS_DATUM_LABEL: "You've walked {distance} {unit} on {date}",
  INSIGHT_CHART_ENERGY_BURNED_WEEK_DESCRIPTION: "The number of {unit} you've burned each day of the week.",
  INSIGHT_CHART_ENERGY_BURNED_MONTH_DESCRIPTION: "The number of {unit} you've burned each day of the month.",
  INSIGHT_CHART_ENERGY_BURNED_DATUM_LABEL: "You've burned {energy} {unit} on {date}",
  INSIGHT_CHART_ACTIVE_DURATION_WEEK_DESCRIPTION: "The number of active minutes for each day of the week.",
  INSIGHT_CHART_ACTIVE_DURATION_MONTH_DESCRIPTION: "The number of active minutes each day of the month.",
  INSIGHT_CHART_ACTIVE_DURATION_DATUM_LABEL: "You had {value} active minutes on {date}",
  INSIGHT_CHART_FLOORS_CLIMBED_WEEK_DESCRIPTION: "The number of flights you've climbed each day of the week.",
  INSIGHT_CHART_FLOORS_CLIMBED_MONTH_DESCRIPTION: "The number of flights you've climbed each day of the month.",
  INSIGHT_CHART_FLOORS_CLIMBED_DATUM_LABEL: "You've climbed {value} flights of stairs on {date}",
  INSIGHT_CHART_MINDFUL_DURATION_WEEK_DESCRIPTION: "The number of mindful minutes for each day of the week.",
  INSIGHT_CHART_MINDFUL_DURATION_MONTH_DESCRIPTION: "The number of mindful minutes each day of the month.",
  INSIGHT_CHART_MINDFUL_DURATION_DATUM_LABEL: "You had {value} mindful minutes on {date}",
  DATAPOINT_CATEGORY_ACTIVITY: "Activity",
  DATAPOINT_CATEGORY_BODY: "Body",
  DATAPOINT_CATEGORY_BLOOD: "Blood",
  MANAGE_UNITS_APPLYING_CHANGES: "Applying changes...",
  MANAGE_UNITS_CHANGES_SAVED: "Changes saved at {time}",
  START_TRACKING_HEALTH_HEADER: "Start tracking your health",
  START_TRACKING_HEALTH_BODY: "Easily track your metrics over time to see how far you've come and how you can work towards your health goals.",
  BROWSE_SUPPORTED_DATA: "What can I track?",
  LOAD_MORE_ERROR: "There was an error loading your results. Please try again.",
  LOAD_MORE_FALLBACK_ERROR: "We are unable to load more results at this time. Please try again later.",
  LAST_UPDATED_CARRIER_DATE: "Last updated: {date} from {carrier}",
  ACCUMULATION_PERIOD: "Accumulation period: {start} - {end}",
  NO_DATA_AVAILABLE: "No data available",
  ALL_INSIGHTS: "All Insights",
  LAST_7_DAYS: "Last 7 Days",
  LAST_30_DAYS: "Last 30 Days",
  OLDER: "Older",
  GET_ASSISTANCE_NOW: "Chat with your care team",
  GET_ASSISTANCE_NOW_SUBHEADING: "Chat with our certified health professionals and customer care staff. They can help you save money by directing you to the most affordable, highest quality care in your area as well as answer any questions about your benefits.",
  HOW_CAN_WE_HELP: "How Can We Help?",
  LIVE_BALANCE_SPEND: "{spentAmount} of {totalAmount} spent",
  LIVE_BALANCE_REMAINING: "{amount} left",
  UPDATING_UNITS_OF_MEASURE: "Updating units of measure",
  CHANGING_YOUR_UNITS: "Changing your units will affect how they are displayed in the app.",
  YOUR_INSIGHTS_TITLE: "Your insights",
  YOUR_INSIGHTS_SUBTITLE: "View the most recent health data received from your connected apps & devices",
  VIEW_ALL_INSIGHTS: "View all insights",
  MESSAGES: "Messages",
  SEND_NEW_MESSAGE: "Send new message",
  NEW_MESSAGE_DEFAULT: "This is the start of a new message",
  NETWORK_MISSING_COVERAGE: "Your plan doesn't have {network} coverage.",
  NETWORK_MISSING_COVERAGE_ADMIN: "This plan doesn't have {network} coverage",
  CARD_FRONT_FILENAME: "{benefitName}-front",
  CARD_BACK_FILENAME: "{benefitName}-back",
  START_A_VISIT: "Start a Visit",
  UNREAD: "Unread",
  NO_MESSAGES_HEADING: "No messages yet",
  NO_MESSAGES_DESCRIPTION: "Get started by sending a new message!",
  RETURN_TO: "Return to {location}",
  TODAYS_FOCUS: "Today's Focus",
  TODAYS_FOCUS_USER_PROMPT: "Visit your Journey to complete your Challenge activities.",
  NO_RECOMENDATIONS: "Sorry, we don\u2019t have any recommendations for this health area right now.",
  CHALLENGES_LEGAL_DISCLAIMER: "This Challenge is intended to be a fun way for everyone to participate and get a fair chance at winning. At times, cash or other prizes may be offered by the Challenge organizer. League doesn't design the Challenge structure or decide whether to offer prizes. If you have concerns about the structure of this Challenge or believe you've been excluded from the chance to win, please talk to the Challenge organizer.",
  ALL_RECORDED_DATA: "All Recorded Data",
  NEW_MESSAGE: "New Message",
  MESSAGE_SUBJECT: "Message subject (optional)",
  THREAD_SUBJECT_MAX_LENGTH_ERROR: "You are over the maximum character limit ({maximum})",
  CHAR_LIMIT_EXCEEDED: "Character limit exceed ({maximum})",
  MESSAGE_SUBJECT_PLACEHOLDER: "Set the message topic",
  TO: "To",
  CREATE_MESSAGE: "Create Message",
  MEMBER_LABEL: "Member",
  POLICYHOLDER: "Policyholder",
  RESULT_COUNT: "{count, plural, one {# result} other {# results}}",
  USER_COLON: "{user}:",
  JOINED_CHALLENGE_HIGHLIGHT: "Joined <strong>{challengeTitle}</strong>!",
  CREATED_TEAM_HIGHLIGHT: "Created <strong>{teamName}</strong>!",
  JOINED_TEAM_HIGHLIGHT: "Joined <strong>{teamName}</strong>!",
  STEPS_WALKED_HIGHLIGHT: "<strong>Walked {total} steps!</strong>",
  ACTIVE_MINUTES_HIGHLIGHT: "<strong>Moved {total} minutes!</strong>",
  MINDFUL_MINUTES_HIGHLIGHT: "<strong>Completed {total} minutes!</strong>",
  EARNED_ACHIEVEMENT_HIGHLIGHT: "<strong>Earned {achievement} Achievement!</strong>",
  USER_OR_TEAM_EARNED_TOKENS_HIGHLIGHT: "Earned a total of ",
  COMPLETED_ACTIVITY_HIGHLIGHT: "<strong>Completed an activity!</strong>",
  NO_CARE_COLLECTIONS: "Sorry, there are no care collections available.",
  WRITE_TO: "Write to {user}",
  WRITE_A_MESSAGE: "Write a message...",
  SEND: "Send",
  DATA_SOURCES: "Data Sources",
  SOURCES_CAN_UPDATE: "The following sources are allowed to update your",
  WHEN_MULTIPLE_SOURCES_AVAILABLE: "When multiple sources are available, the data source with the largest value will be recorded at the end of the day (11:59pm).",
  APPLE_HEALTH: "Apple Health",
  GOOGLE_FIT: "Google Fit",
  FITBIT: "Fitbit",
  GARMIN: "Garmin",
  POLAR: "Polar",
  WITHINGS: "Withings",
  HEALTH_PLAN: "Health Plan",
  OMRON: "Omron",
  STRAVA: "Strava",
  IHEALTH: "iHealth",
  MISFIT: "Misfit",
  LOSE_IT: "Lose It",
  UNDERARMOUR: "Under Armour",
  IGLUCOSE: "iGlucose",
  BIOTEL_CARE: "BioTel Care",
  YOO_FITNESS: "YOO Fitness",
  MEMBERS_LOWERCASE: "{totalParticipants} members",
  BACK_TO_VENDOR_LIST: "Back to Vendor List",
  BENEFIT_VENDOR_LIST: "Benefit Vendor List",
  BENEFIT_VENDOR_LIST_INFO: "Benefit vendors feed into League members wallets to indicate who the insured provider is for a specific benefit. First check if a vendor exists before creating a new one. Once created, the vendor will be made available in a dropdown when configuring a benefit within a group/benefit class for US groups only.",
  UPLOAD_IMAGE_NOTE: "Note: Make sure that the logo is in a horizontal format before uploading or else it will not display correctly on the wallet card.",
  VENDOR_ID: "Vendor Id",
  VENDOR_NAME: "Name",
  LOGO_IMAGE: "Logo Image",
  LOGO_IMAGE_TOOLTIP: "For best results use a PNG image format",
  VENDOR_LOGO: "Vendor Logo",
  PRIMARY_COLOUR_HEX: "Primary Colour Hex",
  PRIMARY_COLOUR_HEX_TOOLTIP: "This can be fetched with an eyedropper tool, which is available in Figma. Select the primary colour /main colour of the vendor",
  SAVE_VENDOR: "Save Vendor",
  CREATE_NEW_VENDOR: "Create New Vendor",
  SET_BENEFIT_VENDOR: "Benefit Vendor was saved!",
  UPDATE_UNITS_OF_MEASURE: "Changing units of measurement",
  CHANGING_UNITS_OF_MEASURE_TIP: "This will affect how measurements are displayed for app & device metrics.",
  UPDATE_UNITS_OF_MEASURE_MODAL_TITLE: "Updating units of measurement",
  UPDATE_UNITS_OF_MEASURE_MODAL_DESCRIPTION: "Changing your units will affect how they are displayed in the app",
  DOCUMENTS: "Documents",
  AVERAGE: "Average",
  VIEW_ALL_METRIC_DATA: "View all {metric} data",
  EDIT_METRIC_DATA: "Edit {metric} data",
  NEXT_WEEK: "Next week",
  PREVIOUS_WEEK: "Previous week",
  NEXT_30_DAYS: "Next 30 Days",
  PREVIOUS_30_DAYS: "Previous 30 Days",
  RETRIEVE_ACCOUNT: "Request an invitation email",
  RETRIEVE_ACCOUNT_MESSAGE: "Enter the email address associated with your League account.",
  BACK_TO_SIGN_IN: "Back to Sign In",
  RETRIEVE_ACCOUNT_SUCCESS_MESSAGE: "If you don't see an email us within the next 30 minutes, make sure you entered the right email address. Still having issues? { FAQ }",
  CHECK_FAQ: "Check our FAQs.",
  FAQ: "FAQ",
  RETRIEVAL_EMAIL_SENT: "Your invitation is on the way!",
  RETRIEVAL_EMAIL_SENT_MESSAGE: "Check your inbox for an email from League.",
  UNABLE_TO_CREATE_TEAM: "Unable to create a new team - please try again! If you've already joined a team, you'll need to leave your current team before creating a new one.",
  ENROLLMENT_INSIGHTS_FEEDBACK_BANNER: "Just in time for the upcoming Open Enrollment, we are delighted to release some new enhancements surrounding our Enrollment Insight solution. How would you rate the value of the Insight & Reporting experience to you?",
  ENROLLMENT_INSIGHTS_FEEDBACK_BANNER_TITLE: "Help us improve the Enrollment Insight & Reporting experience",
  SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER: "We are delighted to announce the release of the interactive reporting dashboard for Spending Accounts. How would you rate the value of the Insights & Reporting experience to you?",
  SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER_TITLE: "Help us improve the Spending Account Insight experience",
  START_TRACKING_METRIC: "Start tracking {metric}",
  CURRENTLY_NOT_SHARING_DATA: "You\u2019re currently not sharing any data for {metric}",
  SUCCESSFULLY_LEFT_TEAM: "You have successfully left {teamName}",
  LEAVE_TEAM_FAILURE: "Something went wrong, please try again!",
  VIEW_DATA_SOURCES: "View data sources",
  OR_CALL_PHONE: "or call {phoneNumber}{extension}",
  NO_DOCUMENTS: "No documents here (yet)",
  NO_DOCUMENTS_DESCRIPTION: "You can add documents to share with your Care Advisor and keep them organized in one place.",
  LEADERBOARD_CURRENT_TEAM_NAME: "{team} (Your team)",
  YOUR_CARE_ADVISOR: "Your Care Advisor",
  COMPOSE: "Compose",
  CARE_ADVISOR: "Care Advisor",
  POLICY_INFORMATION_UNAVAILABLE: "There is no information available for this policy period.",
  POLICY_INFORMATION_UNAVAILABLE_DEPENDENTS: "There's no information for this member. Please try another member.",
  MESSAGE_THREADS_LIST_SUBTITLE: "It may take up to 2 business days for our team to respond. If this is a medical emergency, call 911.",
  ALL_MESSAGES_FOR_THREAD_BANNER: "Please do not send us specific information about your health conditions through this chat.",
  ACTIVITY: "Activity",
  UNASSIGN: "Unassign",
  RETURN: "Return",
  LINK: "Link",
  YOU_ARE_PREVIEWING_THE_ACTIVITY: "You are previewing the activity",
  TROUBLE_DISPLAYING_DATA: "We're having trouble displaying your data",
  RETRY: "Retry",
  OPEN_DOCUMENT_ACTIONS_DROPDOWN: "Open document actions dropdown for {title}",
  YOU: "You",
  MESSAGES_IN_CONVERSATION_TITLED: "Messages in conversation titled {subject}",
  MESSAGES_IN_CONVERSATION_WITH: "Messages in conversation with {participants}",
  AVERAGE_TOKENS: "Average Tokens",
  TEAM_AVERAGE: "Team Average",
  YOUR_METRICS: "My Metrics",
  YOUR_METRICS_SUBTITLE: "The most recent health data from your connected apps and devices.",
  APPS_AND_DEVICES: "Apps & Devices",
  APP_AND_DEVICE_METRICS: "App & Device Metrics",
  VIEW_ALL_METRICS: "View apps & devices metrics",
  RENAME: "Rename",
  ENTER_DOCUMENT_NAME: "Please enter a document name",
  COMPLETED_ASSIGNED_ACTIVITY_TABLE_HEADING: "{memberFirstName} has completed {activityCount} {amount, plural, one {Activity} other {Activities}}.",
  EMPTY_ASSIGNED_ACTIVITIES: "The activities you assign to this member will show up here.",
  EMPTY_COMPLETED_ACTIVITIES: "As the member completes activities, they'll show up here.",
  LOW_PRIORITY_ACTIVITY_ALT_TEXT: "This is a low priority activity",
  MEDIUM_PRIORITY_ACTIVITY_ALT_TEXT: "This is a medium priority activity",
  HIGH_PRIORITY_ACTIVITY_ALT_TEXT: "This is a high priority activity",
  HIGH_PRIORITY: "High Priority",
  MEDIUM_PRIORITY: "Medium Priority",
  ACTIVITY_EXPIRY_DATE: "Expires {date}",
  POINTS_EARNED: "{points} points earned",
  POINTS_MISSED: "{points} points missed",
  TOKENS_EARNED: "{points} tokens earned",
  TOKENS_MISSED: "{points} tokens missed",
  GENERIC_MESSAGING_ERROR: "Looks like there was a problem, please refresh the page and try again.",
  CATEGORY: "Category",
  SELECT_A_CATEGORY: "Select a category",
  ACTIVITY_NAME: "Activity name",
  NAME_THE_ACTIVITY: "Name the activity",
  VIEW_THE_ACTIVITIES: "View the Activities",
  CONTENT: "Content",
  DESCRIBE_THE_ACTIVITY: "Describe the activity",
  LINK_NAME: "Link name",
  LINK_URL: "Link URL",
  ADD_A_LINK: "Add a valid link",
  ADD_A_LINK_NAME: "Add a link name",
  ASSIGNED_TO: "Assigned to",
  SELECT_A_MEMBER: "Select a member",
  DRAG_FILES_HERE_OR: "Drag files here or",
  ADD_A_DOCUMENT: "Add a document",
  ADD_DOCUMENT: "Add document",
  REMOVE_FILE: "Remove file",
  REMOVE_FILE_FILENAME: "Remove file: {filename}",
  ACTIVITY_ASSIGNED_TO_NAME: "Success assigning activity to {name}",
  ERROR_ASSIGNING_ACTIVITY_TO_NAME: "Error assigning activity to {name}",
  REFRESH_PAGE_TRY_AGAIN: "Refresh the page and try again",
  UNABLE_TO_SEND: "Unable to send, please try again",
  ERROR_HEADING: "Something went wrong on our end.",
  ERROR_MESSAGE_SALESFORCE: "Try reloading the page. If that doesn't work, quit Salesforce and log back in.",
  ERROR_HEADING_ASSIGN_MODAL: "Something went wrong.",
  ERROR_MESSAGE_ASSIGN_MODAL: "Try reloading the page.",
  FILE_TYPE_NOT_SUPPORTED: "File type not supported",
  USE_FILE_IN_ONE_OF_THESE_FORMATS: "Use a file in one of these formats: {fileTypes}",
  UNASSIGN_ACTIVITY_ERROR_TOAST: "Error: activity wasn\u2019t unassigned",
  ACTIVITY_ERROR_MESSAGE_TOAST: "Refresh the page and try again.",
  MARK_AS_DONE_ERROR_TOAST: "Error: activity wasn\u2019t marked as done",
  UNASSIGN_ACTIVITY_SUCCESS_TOAST: "Activity has been unassigned to {memberName}.",
  MARK_AS_DONE_ACTIVITY_SUCCESS_TOAST: "Activity has been marked as done for {memberName}",
  RECENT_MESSAGES: "Recent messages",
  NO_MESSAGES_YET: "You have no messages (yet!)",
  NO_MESSAGES_DETAIL: "Your assigned Care Advisor will assist you in making smarter health care decisions, every step of the way.",
  START_A_NEW_MESSAGE: "Start a new message",
  USER_ADDED_DOCUMENT: "{user} added a document",
  NETSUITE: "NetSuite",
  NETSUITE_ID: "NetSuite ID",
  SAVE_NETSUITE_ID: "Save NetSuite ID",
  SAVED_NETSUITE_ID: "NetSuite ID was saved!",
  ERROR_SAVING_TRY_AGAIN: "Error saving changes. Please try again",
  ERROR_ASSIGNING_ACTIVITY: "Error assigning activity.",
  ASSIGNING_ACTIVITY_ERROR_MESSAGE_TOAST: "Return to the edit activity form page to fix any errors and try again",
  MODIFIED_ON_DATE: "Modified on {date}",
  FILE_TYPE_TOO_LARGE: "File size is too large",
  MAXIMUM_FILE_SIZE_IS: "Maximum file size is {fileSize}.",
  MESSAGES_PAGINATION_ERROR: "Unable to load messages. <button>Try again.</button>",
  TRANSACTION_TABLE_CAPTION: "Transaction History",
  SELECT_PARTICIPANT_ERROR: "Select someone to send a message to",
  PREVIEW_OF_FILE: "Preview of {fileName}",
  CERTIFICATION_CREDITS: "Credits",
  CERTIFICATION_TYPE: "Certification Type",
  CREDITS_EXPIRY_DATE: "Expiry Date",
  SET_CREDITS: "Set Credits",
  SAVED_CREDITS: "Credits was saved!",
  VALIDATION_ERROR_MSG: "Please choose the Expiry date after Effective date",
  VIEW_DOCUMENT_ACTIONS: "View {documentName} actions.",
  VISIT_LINK: "Visit Link",
  ASSIGNED_TO_MEMBER_DATE: "Assigned to {member} {date}",
  DISMISSED_BY_MEMBER_DATE: "Dismissed by {member} {date}",
  COMPLETED_BY_MEMBER_DATE: "Completed by {member} {date}",
  COMPLETE_BY_DATE: "Complete by {date}",
  ERROR_UPLOADING_YOUR_FILE: "Error uploading your file. Try again.",
  DOCUMENT_SUBMITTED: "Document submitted",
  YOUR_DOCUMENT_WILL_BE_AVAILABLE_SOON: "Your document will be available soon. Please wait a few moments.",
  ERROR_RENAMING_YOUR_DOCUMENT: "Error renaming your document. Try again.",
  DOCUMENT_RENAMED_SUCCESSFULLY: "Document renamed successfully.",
  ALL_DOCUMENTS: "All documents",
  ERROR_HEADING_GENERIC: "Something went wrong",
  ERROR_HEADING_NO_CONNECTION: "No internet connection",
  ERROR_CAPTION_BACKEND: "There was a problem connecting to our server.\n Please try again later.",
  ERROR_CAPTION_NO_CONNECTION: "There was a problem loading data.\n Check your internet and try again.",
  SURE_YOU_WANT_TO_DELETE: "Are you sure you want to delete {name}",
  CANNOT_BE_UNDONE: "This action cannot be undone",
  DELETE_DOCUMENT: "Delete document",
  DOCUMENT_DELETED: "Document deleted",
  ERROR_DELETING_YOUR_DOCUMENT: "Error deleting your document",
  ABOUT_ME: "About Me",
  SEE_MORE: "SEE MORE",
  SUBSCRIPTION_FEES: "Subscription Fees",
  SAVE_SUBSCRIPTION_FEES: "Save Fees",
  SAVED_SUBSCRIPTION_FEES: "Subscription Fees saved!",
  SUBSCRIPTION_FEES_FOR_INVITED_USER: "Subscription Fees for Invited Users",
  SUBSCRIPTION_RATE: "Subscription Rate(per employee/month)",
  SUBSCRIPTION_FEES_FOR_PER_BENEFIT_CLASS: "Subscription Fees for Per Benefit Class",
  CHARGE_SUBSCRIPTION_FEES: "Charge Subscription Fees",
  UNABLE_TO_LOAD_PAGE_TRY_AGAIN: "We're unable to load this page. Check your internet and try again.",
  WHO_IS_THIS_DOCUMENT_ABOUT: "Who is this document about?",
  SELECT_A_USER: "Select a user",
  SELECT_DOCUMENT: "Select document",
  THIS_HELPS_IDENTIFY_PERSON_DOCUMENT_RELATES_TO: "This helps your Care Advisor identify the person this document relates to.",
  VIEW_ALL_MESSAGE_THREADS: "View all message threads",
  TOGGLE_MENU: "Toggle message list",
  ASSIGN_NEW_ACTIVITY: "Assign new activity",
  UNREGISTERED: "Unregistered",
  METRICS: "Metrics",
  UNREAD_MESSAGES: "Unread Messages",
  CONTINUE_WITH_HSID: "Continue with HealthSafe ID",
  EXPIRED_ROI_BANNER: "Your Release of Information expired.",
  EXPIRED_ROI_BANNER_WITH_DATE: "Your Release of Information expired on {expiryDate}.",
  ROI_PAGE_HEADING: "Authorization for release of information",
  SIGN_ROI: "Sign Release of Information",
  SIGN_ROI_SUCCESS_MSG_TITLE: "Your release of information has been successfully signed. You can change these permissions at any time in",
  SIGN_ROI_SUCCESS_MSG_DESC: "Settings > Sharing Permissions.",
  UPDATE_ROI: "Update Release of Information",
  VIEW_ROI: "View Release of Information",
  EDIT_ROI_MEMBERS: "Edit members on release of information",
  REVOKE_PERMISSIONS_MODAL_TITLE: "Are you sure you want to remove sharing permissions with all members?",
  REVOKE_PERMISSIONS_MODAL_DESC: "You will no longer be able to share documents or discuss your care with this person(s).",
  REVOKE_PERMISSIONS_MODAL_CONFIRM_CTA: "Stop sharing",
  MEMBER_ROLE_PAO: "Primary Account Owner",
  MEMBER_ROLE_NON_POLICY: "Non-policy Member",
  MEMBER_ROLE_POLICY: "Policy Member",
  BACK_TO_FAMILY_PAGE: "Back to family page",
  FME_PERSONAL_CARE_TEAM: "Personal Care Team",
  INVITE_FAMILY_MEMBERS_BANNER_TEXT_PAO: "Invite your family members to join the experience to navigate your health journey together.",
  INVITE_FAMILY_MEMBERS_BANNER_TEXT_NON_PAO: "To invite members to join Care Advisor Connect, reach out to the primary account owner listed below.",
  NO_PERSONAL_CARE_TEAM_MEMBERS: "No care team members yet",
  INVITE_MEMBER_SUCCESS_MSG_DESC: "An invite will be sent shortly to {invitedMember}\u2019s email address.",
  SHARING_PERMISSIONS_EMPTY_STATE_DESC: "You\u2019ll need to add family members to your care team before you can manage their sharing permissions.",
  SHARING_PERMISSIONS_EMPTY_STATE_SUB_DESC: "Connect with your representative to get started.",
  PERSONAL_CARE_TEAM_EMPTY_STATE_DESC: "You currently have no family members added.",
  INVALID_SIGNATURE_ERROR_MSG: "Sorry, your signature is not valid. Please enter your name as you see it in the form.",
  SIGNATURE: "Signature",
  SIGNATURE_PANEL_HINT_TEXT: "Please enter your full name as it appears above to authorize the disclosure of your health information",
  SIGNATURE_PANEL_INPUT_PLACEHOLDER: "Full name (as it appears above)",
  SKIP_TO_SIGN_FORM: "Skip to sign form",
  SCROLL_DOWN_TO_SIGN: "Scroll down to sign",
  AUTHORIZED_BY: "Authorized by",
  DATE_SIGNED: "Date signed",
  ENABLE_SHARING_PERMISSIONS: "To discuss and navigate your care with others, you will need to enable sharing permissions.",
  SELECT_MEMBERS_TO_ENABLE_SHARING_PERMISSIONS: "Select members to enable sharing permissions",
  STOP_SHARING_WITH_MEMBERS: "Stop sharing with all members",
  FME_SHARING_PERMISSIONS: "Sharing Permissions",
  WITH_DOCUMENT: "with document",
  X_TOKENS: "{tokenCount, plural, one {# token} other {# tokens}}",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_EYEBROW: "Programs & Challenges",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_HEADING: "Ready for something new?",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_DESCRIPTION: "Find Health Programs and Challenges that match your personal goals.",
  EXPLORE_LIBRARY: "Explore Library",
  YOUVE_GOT_THIS: "You've got this!",
  YOUVE_GOT_THIS_NAME: "You've got this, {firstName}!",
  HEALTH_JOURNEY_PAGE: "Health Journey Page",
  WARNING: "Warning",
  VIEW_DETAILS: "View Details",
  NEXT_ACTIVITY: "Next Activity",
  X_OF_Y_ACTIVITIES: "{completed} of {total} activities",
  X_PERCENTAGE: "{percent}%",
  SOLUTIONS: "Solutions",
  DELETE_ACCOUNT_FAILED: "Delete Account Failed",
  URGENT_BANNER_ICON_ALT_TEXT: "Alert",
  EXPLORE: "Explore",
  CLAIMS_SUMMARY: "Claims Summary",
  DOCUMENT_NAME_TOO_LONG: "You are over the maximum character limit ({maximum})",
  OWED_AMOUNT: "You Owe: ",
  MAY_OWE_AMOUNT: "You May Owe: ",
  CLAIM_PATIENT_NAME: "For: {patientName}",
  PARTIALLY_PAID: "Partially Paid",
  STANDALONE_ACTIVITIES_DESCRIPTION: "No Programs. No Challenges. Just one-off activities.",
  FEEDBACK_LINK_LABEL: "Rate information {value} out of {maximum} in helpfulness",
  UNAVAILABLE_ACTION: "{name} (not yet available)",
  RECENT_ACHIEVEMENTS: "Recent Achievements",
  ACHIEVEMENTS_BANNER_HEADER: "Congrats on earning these badges!",
  SINGLE_ACHIEVEMENT_DESCRIPTION: "We love to see you prioritizing your health and well-being! Check out other achievements you're working towards.",
  MULTIPLE_ACHIEVEMENT_DESCRIPTION: "We love to see you prioritizing your health and well-being! Check out other achievements you've earned and those you're working towards.",
  ACHIEVEMENTS_EMPTY_STATE_HEADER: "No achievements (yet!)",
  ACHIEVEMENTS_EMPTY_STATE_DESCRIPTION: "Complete activities in your Journey to start earning achievements.",
  HISTORY_EMPTY_STATE_HEADER: "Nothing here - yet!",
  HISTORY_EMPTY_STATE_DESCRIPTION: "When you complete an activity, program, or challenge it will appear here.",
  CAPABILITY: "Capability",
  FILTER_WALLET_CLAIMS: "Pick a benefit period to search wallet claims.",
  HELP: "Help",
  "SUPPORT_AGENT_NOT_FOUND ": "Support agent name not found",
  RECENT_SESSIONS: "Recent Sessions",
  NO_RECENT_SESSIONS: "No Recent Sessions",
  NO_RECENT_SESSIONS_DESCRIPTION: "No member activity during the last 90 days.",
  RECOMMENDED_DEVICE_TITLE: "Recommended web browsers and app versions",
  RECOMMENDED_DEVICE_DESCRIPTION_WEB_BROWSERS: "<strong>Web browsers:</strong> The most recent version of Chrome, Firefox, Safari, or Microsoft Edge",
  RECOMMENDED_DEVICE_DESCRIPTION_APP_VERSION: "<strong>App version:</strong> {primaryName} {primaryCurrentVersion} or {secondaryName} {secondaryCurrentVersion}",
  APP_VERSION: "App Version",
  DEVICE_TITLE_CASE: "Device",
  IOS: "iOS",
  ANDROID: "Android",
  LAST_ACCESSED: "Last Accessed",
  SESSION_DURATION: "Session Duration",
  BROWSER_APP_INFO: "Browser or app info",
  GENERIC_TOOLTIP_NAME: "{name} tool-tip",
  INDIVIDUAL_BALANCE: "{membersFullName}'s {balance}",
  FAMILY_BALANCE: "Family {balance}",
  DOCUMENT_THUMBNAIL_MODAL: "Attached document modal",
  UPCOMING: "Upcoming",
  REMOVED: "Removed",
  COMPLETED_TODAY: "Completed",
  MULTISELECT_HINT: "Use the arrow and backspace keys to clear a selection.",
  INVITE_MEMBER: "Invite member",
  ONCE_MEMBER_REGISTERED: "Once this member has registered, they will be able to:",
  CLAIMS_BENEFIT_CARD_TITLE: "Insurance Claims",
  CLAIMS_BENEFIT_CARD_SUBTITLE: "Keep track of what you owe for your care and how much insurance will cover",
  CLAIMS_FILTER_PROPERTY_TYPE: "Service Type",
  CLAIMS_FILTER_PROPERTY_STATUS: "Claim Status",
  CLAIMS_FILTER_PROPERTY_PATIENTNAME: "Member",
  CLAIMS_FILTER_VALUE_ORAL: "Dental",
  CLAIMS_FILTER_VALUE_INSTITUTIONAL: "Medical",
  CLAIMS_FILTER_VALUE_VISION: "Vision",
  CLAIMS_FILTER_VALUE_PHARMACY: "Pharmacy",
  CLAIMS_FILTER_VALUE_PROFESSIONAL: "Professional",
  CLAIMS_FILTER_VALUE_APPROVED: "We'll Pay Our Part",
  CLAIMS_FILTER_VALUE_PENDING: "Processing",
  CLAIMS_FILTER_VALUE_PARTIALLY_PAID: "Partially Covered",
  CLAIMS_FILTER_VALUE_NOT_COVERED: "Not Covered",
  CLAIMS_FILTER_VALUE_ADJUSTED: "Adjusted",
  CLAIM_TABLE_CAPTION: "Claim History",
  DRINKS_PER_WEEK: "Drinks per week",
  REWARD_PROGRAM: "Reward Program",
  REWARD_PROGRAMS: "Reward Programs",
  REWARDS_PROGRAMS: "Rewards Programs",
  STANDARD_PROGRAMS: "Standard Programs",
  SEE_REWARD_PROGRAM: "See Reward Program",
  INVITATION_WILL_EXPIRE: "Any email address saved will be saved on file for this member. This invitation will expire after 14 days.",
  OR_SELECT_BETWEEN_THESE: "or select between these",
  ADJUSTED: "Adjusted",
  CLAIMS_FILTER_PROPERTY_SERVICEDATE: "Date Range",
  CLAIMS_FILTER_VALUE_YEAR_TO_DATE: "Year to Date",
  CLAIMS_FILTER_VALUE_PRIOR_YEAR: "Prior Year",
  CLAIMS_FILTER_VALUE_LAST_12_MONTHS: "Last 12 Months",
  CLAIMS_FILTER_VALUE_LAST_2_YEARS: "Last 2 Years",
  CHALLENGE_TYPE_GROUP_TAG: "Group",
  CHALLENGE_TYPE_SOLO_TAG: "Solo",
  VIEW_ALL_GROUP_CHALLENGES_HEADING: "Group Challenges",
  VIEW_ALL_SOLO_CHALLENGES_HEADING: "Solo Challenges",
  VIEW_ALL_CHALLENGES_DESCRIPTION_GROUP: "Stay accountable as you challenge yourself with mindfulness work, healthy eating initiatives, fitness competitions, stress reduction activities, step challenges, and more.",
  VIEW_ALL_CHALLENGES_DESCRIPTION_SOLO: "Go solo and challenge yourself to complete groups of activities focused on physical fitness, nutrition, mental health, stress management, and more.",
  HELPFUL_LINKS: "Helpful Links",
  HOW_CLAIMS_WORK: "How Claims Work",
  UNDERSTANDING_HOW_YOUR_CLAIMS_WORK_TITLE: "Understanding how your claims work",
  UNDERSTANDING_HOW_YOUR_CLAIMS_WORK_TEXT: "<p>When you visit one of the doctors in your network, they request payment. If you have a copay, they collect it from you and then they submit a claim to the insurance company.</p><p>We then review your insurance plan, also known as your benefit contract, to determine if the service is covered. If it is, we will approve it based on the terms of the benefit contract. If it is not covered as part of your benefit plan, you are responsible for paying for the service.</p>",
  PERIOD: "Period",
  CLAIMS_STATUS_DEFINITIONS: "Claim Status Definitions",
  CLAIMS_STATUS_DEFINITION_APPROVED: "We'll pay our part means the claim is approved and we agree to pay some or all of the claim based on your plan.",
  CLAIMS_STATUS_DEFINITION_PENDING: "Processing means we're figuring out who owes what.",
  CLAIMS_STATUS_DEFINITION_PARTIALLY_PAID: "Partially covered means we will cover a portion of your claim.",
  CLAIMS_STATUS_DEFINITION_NOT_COVERED: "Not covered means that the service is not covered in your benefit plan and you will have to pay for it.",
  CLAIMS_STATUS_DEFINITION_ADJUSTED: "Adjusted means something changed here, so we had to take another look at this claim.",
  YOUR_COST: "Your Cost:",
  CLAIMS_SERVICE_TYPE: "{type, select, institutional {Medical Claim} oral {Dental Claim} vision {Vision Claim} pharmacy {Pharmacy Claim} professional {Professional Claim} other {Claim}}",
  CLEAR_ALL_FILTERS: "Clear All Filters",
  FILTER_BY: "Filter By",
  PUBLISHED: "Published",
  PUBLISHED_PROGRAMS_TOOLTIP: "Programs that are published are live and visible to users.",
  DRAFT_PROGRAMS_TOOLTIP: "Programs that are in draft state are not live and are not visible to users.",
  APPROVED_PROGRAMS_TOOLTIP: "Programs that are approved have been reviewed and ready to be published. They are not live and are not visible to users. ",
  PUBLISHED_ACTIVITIES_TOOLTIP: "Activities that are published are live and visible to users.",
  DRAFT_ACTIVITIES_TOOLTIP: "Activities that are in draft state are not live and are not visible to users.",
  APPROVED_ACTIVITIES_TOOLTIP: "Activities that are approved have been reviewed and ready to be published. They are not live and are not visible to users. ",
  CLAIMS_LIST_EMPTY_HEADING: "Nothing here, yet!",
  CLAIMS_LIST_EMPTY_MESSAGE: "Claims that are submitted will show up here.",
  CLAIMS_GENERIC_ERROR_HEADING: "Something went wrong",
  CLAIMS_GENERIC_ERROR_MESSAGE: "There was a technical problem on our end.{br}Please try again in a few minutes.",
  CLAIMS_SUMMARY_PAGE_SUB_HEADING: "View what your insurance has covered, and what you may still owe your provider. You can access your claims submitted within two years to today's date.",
  CLAIMS_LIST_NO_CLAIMS_MATCH_FILTER_HEADING: "No results",
  CLAIMS_LIST_NO_CLAIMS_MATCH_FILTER_MESSAGE: "No claims match the {filterCount, plural, one {filter} other {filters}} you\u2019ve chosen",
  ANSWER_SHOULD_BE_INTEGER: "Answer should be an integer",
  VIEW_BENEFIT_LABEL_HIDDEN: "View member {from} benefit details",
  VIEW_REWARD_LABEL_HIDDEN: "View member reward details for {from} reward program",
  REWARDS_ID: "Rewards Id",
  REWARDS_ID_UNDERSCORE: "Rewards_ID: {rewardId}",
  BIOMETRICS: "Biometrics",
  PROGRAM: "Program",
  VIEW_MY_PROFILE: "View My Profile",
  ALL_BENEFITS: "All Benefits",
  PLAN_PROGRESS_COST_COVERAGE: "Plan progress and costs",
  PLAN_PROGRESS_POP_UP_LABEL: "What is a {title}?",
  REMOVE_RULE: "Remove rule",
  REMOVE_GROUP: "Remove group",
  RECENT_CLAIMS_LIST_EMPTY: "Claims submitted within the past 90 days will show up here. You can access all claims by selecting \u2018View all\u2019. ",
  COMBINATOR_SELECTOR_LABEL: "Set relationship between conditions",
  DOWNLOAD_CLAIMS: "Download Claims",
  X_OF_Y_BENEFITS: "showing {visibleAmount} of {totalAmount} benefits",
  VIEW_DEFINITION: "View definition",
  PLAIN_SOMETHING_WENT_WRONG: "Something went wrong",
  CLAIMS_SUMMARY_DOWNLOAD_ERROR: "There was a problem downloading your claims.",
  WALLET_WITH_CLAIMS_LANDING_TITLE: "Your benefits, claims, and wellness info, all in one place.",
  NO_FILTERED_BENEFITS: "We couldn\u2019t find anything matching those filters.",
  NO_BENEFITS_AVAILABLE_HEADING: "No benefits available",
  NO_BENEFITS_AVAILABLE_BODY: "Member has no benefits at the moment.",
  NO_REWARDS_AVAILABLE_HEADING: "No campaigns available",
  NO_REWARDS_AVAILABLE_BODY: "Member has no rewards at the moment.",
  SOMETHING_WENT_WRONG_HEADING: "Something went wrong",
  SOMETHING_WENT_WRONG_BODY: "There was a problem loading data.{br}Please try again later.",
  NO_CHALLENGES_PROGRAMS_EMPTY_STATE_HEADING: "Ready to Get Started?",
  NO_CHALLENGES_PROGRAMS_EMPTY_STATE_BODY: "Browse our library to find Health Programs and Challenges that match your personal goals.",
  USER_DROPDOWN_BTN_LABEL: "Profile Menu",
  MEMBER_ACTIONS: "Member Actions",
  QUICK_LINKS_AGENT: "Quick links and documents available to members.",
  DIGITAL_CARD: "Digital Card",
  DIGITAL_CARD_FRONT: "Front image of digital card",
  DIGITAL_CARD_BACK: "Back image of digital card",
  NO_DIGITAL_CARD: "No card expected",
  PROVIDER_SERVICE_POPOVER_BUTTON_NAME: "What is a copay and coinsurance?",
  ANSWERS_NOT_SAVED: "We're not able to save your answers right now. Please try again later",
  STUDENT_RECERTIFICATION: "Student Recertification",
  STUDENT_RECERTIFICATION_DESCRIPTION: "Once a dependent child turns either 18 or 21 (depending on what it says in your plan booklet), they must be a full-time student in order to be insured under your policy. For dependents aged 25 or under, you must confirm their student status at the beginning of each post-secondary school year.",
  STUDENT_RECERTIFICATION_YES_INFO: "By selecting YES, you are declaring that the dependent is a full-time student.",
  STUDENT_RECERTIFICATION_NO_INFO: "By selecting NO, the dependent will be removed from coverage. Please ensure you check again before submitting your response.",
  CONFIRM_DEPENDENT_STATUS: "Is {firstName} {lastName} a full-time student?",
  RESPONDED_STUDENT_CERTIFICATION: "Your response has been successfully recorded!",
  RULE_BUILDER_SUBHEADING: "Define a set of rules using logical conditions that will evaluate to true.",
  RULE_BUILDER: "Rule Builder",
  SESSION_ABOUT_TO_TIMEOUT: "You\u2019ve been inactive for a while and your session is about to time out. Do you want to continue your session?",
  SESSION_TIMEOUT: "Session Timeout",
  CONTINUE_SESSION: "Continue Session",
  SESSION_LOG_OUT: "Log Out",
  ORDER_NEW_CARD_ADDRESS_SELECT_HEADING: "Select a delivery address",
  ORDER_NEW_CARD_MEMBER_SELECT_HEADING: "Who needs a new card?",
  ORDER_NEW_CARD_MEMBER_SELECT_HELP_TEXT: "Select one or more members.",
  DEFAULT_ADDRESS: "Default Address",
  CHOOSE_NEW_ADDRESS: "Choose a new address",
  CHOOSE_NEW_ADDRESS_HINT: "Provide a different mailing address for card delivery. This will not change your account's default address.",
  CARD_ACTIONS: "Card actions",
  IMPORT_CTA: "Import",
  IMPORT_JSON: "Import JSON",
  IMPORT_JSON_LABEL: "Paste JSON code in order to render it to the Rule Builder",
  IMPORT_JSON_HINT: "Only strings that evaluate to JavaScript objects when processed with JSON.parse will translate into queries.",
  VALID_RULE_ERROR: "Unable to import as there are no rules.",
  VALID_JSON_ERROR: "Unable to import as it is not valid JSON.",
  GENERATE_JSON: "Generate JSON",
  GENERATE_JSON_INPUT_LABEL: "JSON Logic to be copied and pasted into Contentful",
  COPY_JSON: "Copy JSON",
  JSON_COPY_SUCCESS: "JSON Copied",
  JSON_COPY_ERROR: "Unable to copy. Please try again or select the text and copy.",
  EARN_ACHIEVEMENT_COMPLETING_ACTIVITIES_CHALLENGE: "Earn an achievement for completing all activities in this challenge",
  ADD_RULE_BUTTON_TITLE: "Add rule",
  ADD_RULE_BUTTON_LABEL: "+ Add rule",
  ADD_GROUP_BUTTON_TITLE: "Add group",
  ADD_GROUP_BUTTON_LABEL: "+ Add group",
  EMPTY_RULE_STATE_HEADING: "Select <strong>+ Add Rule</strong> or <strong>+ Add Group</strong>",
  RULE_DESCRIPTION_SHORT: "<strong>Rule:</strong> An individual condition.",
  GROUP_DESCRIPTION_SHORT: "<strong>Group:</strong> A container that holds multiple rules and defines their logical relationship. Groups can be nested to create complex combinations of conditions.",
  CONFIRM_REMOVE_RULE_HEADING: "Delete Rule",
  CONFIRM_REMOVE_GROUP_HEADING: "Delete Group",
  CONFIRM_REMOVE_RULE_DESCRIPTION: "Are you sure you want to delete this rule?",
  CONFIRM_REMOVE_GROUP_DESCRIPTION: "Are you sure you want to delete this group? This will also delete any rules and groups within this group.",
  CHALLENGE_ACTIVITY_EMPTY_STATE_HEADER: "Nothing here - yet!",
  CHALLENGE_ACTIVITY_UP_NEXT: "Up Next ({count})",
  CHALLENGE_ACTIVITY_UP_NEXT_EMPTY_STATE: "Come back when the challenge starts!",
  CHALLENGE_ACTIVITY_COMPLETED: "Completed ({count})",
  CHALLENGE_ACTIVITY_COMPLETED_EMPTY_STATE: "Activities you complete will show up here.",
  CHALLENGE_ACTIVITY_MISSED: "Missed ({count})",
  CHALLENGE_ACTIVITY_MISSED_EMPTY_STATE: "Activities you miss will show up here.",
  CHALLENGE_COMPLETED_TITLE: "That's a wrap!",
  CHALLENGE_COMPLETED_BODY: "There are no more activities to complete. Nicely done!",
  ACHIEVEMENT_EARNED: "Achievement earned celebration",
  ACHIEVEMENT_PROGRESS: "Achievement progress",
  EQUALS_TO: "Equals to",
  NOT_EQUAL_TO: "Not equal to",
  LESS_THAN: "Less than",
  GREATER_THAN: "Greater than",
  LESS_THAN_OR_EQUAL_TO: "Less than or equal to",
  GREATER_THAN_OR_EQUAL_TO: "Greater than or equal to",
  IS_BLANK: "Is blank",
  IS_NOT_BLANK: "Is not blank",
  INCLUDING: "Including",
  BETWEEN: "Between",
  IS_NOT_BETWEEN: "Is not between",
  DOES_NOT_INCLUDE: "Does not include",
  OPERATOR_INPUT_LABEL: "Operator",
  OPERATOR_SELECT_TITLE: "Operator selector for comparison",
  ADD_DATA: "Add Data",
  EDIT_DATA: "Edit Data",
  DATA_POINT_LABEL: "Data Point",
  VALUE_LABEL: "Value",
  ADD_DATA_BUTTON_ARIA_LABEL: "Add data point to {inputLabel} input",
  EDIT_DATA_BUTTON_ARIA_LABEL: "Edit data point to {inputLabel} input",
  ADD_TO_RULE: "Add to rule",
  DATA_POINT_MODAL_HEADING: "Select Category",
  DATA_POINT_MODAL_INSTRUCTIONS: "Choose a category to find a data point:",
  DATA_POINT_EMPTY_SELECTION: "Please select a data point to apply",
  CONFIRM_DETAILS: "Confirm your details",
  MEDICAL_ID_CARD: "Medical ID card",
  NEW_ADDRESS: "New delivery address",
  DATA_POINT_LOADING_ERROR: "Unable to load data categories at this time.",
  MESSAGE_CENTER: "Message Center",
  VALID_DATA_POINT: "Valid data point",
  NOT_VALID_DATA_POINT: "Not a valid data point",
  CHOOSE_NEW_ADDRESS_DESC: "Provide a different mailing address for card delivery. This will not change your account's default address. Mailing address must be within the United States.",
  SELECT_STATE: "Select a state",
  REQUIRED_FIELD: "{field} is a required field",
  PLEASE_MAKE_SELECTION_FOR: "Please make a selection for {input}",
  CLAIMS_YOUR_COST: "Your cost",
  CLAIMS_PROCESSING_DESCRIPTION: "We\u2019re still processing this claim. You\u2019ll be notified when its ready for you to review.",
  DOWNLOAD_BENEFITS_EXPLANATION: "Download Explanation of Benefits",
  DATA_COLLECTED: "Data collected {date}",
  MEMBER_CARD_ON_ITS_WAY: "Your new member ID card is on its way.",
  MEMBER_CARD_ARRIVAL: "The new member ID card(s) will arrive in approximately 10 business days.",
  TRY_LATER: "Please try again later.",
  RESUBMIT: "Resubmit",
  ORDER_NEW_CARD_HEADING: "Order a new card",
  CANCEL_ORDER_NEW_CARD_TITLE: "Are you sure you want to cancel your order?",
  CANCEL_ORDER_NEW_CARD_DESC: "You can always come back later.",
  CANCEL_ORDER: "Cancel order",
  STEP: "Step",
  ORDER_CARD: "Order card",
  PLEASE_MAKE_A_SELECTION: "Please make a selection",
  TOTAL_COST_OVERVIEW: "Total Cost Overview",
  TOTAL_COST_DESCRIPTION: "The sum of costs from your claim\u2019s services.",
  DEDUCTIBLE_TRACKER: "Deductible Tracker",
  VIEW_DEDUCTIBLE: "View Deductible",
  INNETWORK_DEDUCTIBLE: "goes towards your in-network deductible",
  SUPPORT: "Support",
  GET_SUPPORT: "Get Support",
  MESSAGE_WITH_A_MEMBER: "Contact a member advocate for questions about your claim.",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_DATETIME: "Data Point requires a date (YYYY-MM-DD) and/or time (24-hour as HH:MM)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_BOOLEAN: "Data Point requires true or false (boolean)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_INT: "Data Point requires a whole number (integer)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_NUMBER: "Data Point requires a number or decimal",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_STRING: "Data Point works with text (string)",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_TITLE: "Rule Builder Guide",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE: "<h3>Rule</h3><p>A rule represents an individual condition and includes the following:</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE_LIST: "<ul><li><strong>Add data:</strong> Finding the data point. This is the source your rule will apply to.</li><li><strong>Operator:</strong> Choosing the conditions of your rule. This is how the data point will be compared to a value.</li><li><strong>Value:</strong> What you\u2019re comparing the data point to via the operator. Switch a value to a data point to compare more than 1 data point.</li></ul>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_GROUP: "<h3>Group</h3><p>A container that holds multiple rules and defines the logical relationship between them (And/or). Groups can be nested within each other to create complex combinations of conditions.</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_COMBINATOR: "<h3>And/Or</h3><p>Use the and/or selector to connect two or more combinations of rules or groups together.</p>",
  CLAIMS_SERVICE_DETAILS_HEADING: "Service Details",
  CLAIMS_ADDITIONAL_RESOURCES_HEADING: "Additional Resources",
  CLAIMS_SERVICE_DETAILS_SUBHEADING: "A breakdown of the individual services you received and the costs associated with them.",
  CLAIMS_SERVICE_DETAILS_SERVICE: "Service",
  CLAIMS_SERVICE_DETAILS_DATE: "Date of Service",
  CLAIMS_SERVICE_DETAILS_SERVICE_STATUS: "Status",
  CLAIMS_SERVICE_DETAILS_SERVICE_PROCEDURE_CODE: "Procedure Code",
  CLAIMS_SERVICE_DETAILS_SERVICE_RX_NUMBER: "Rx Number",
  CLAIMS_SERVICE_DETAILS_SERVICE_YOUR_COST: "Your Cost",
  CLAIMS_SERVICE_DETAILS_SERVICE_COST_DETAILS: "Cost Details",
  CLAIMS_SERVICE_DETAILS_SHOW_COST_DETAILS: "Show Cost Details",
  CLAIMS_SERVICE_DETAILS_HIDE_COST_DETAILS: "Hide Cost Details",
  CLAIMS_SERVICE_DETAILS_SERVICE_OTHER_INSURANCE_PAID: "Other Insurance Paid",
  CLAIMS_SERVICE_DETAILS_YOUR_COST_BREAKDOWN_HEADING: "Breakdown of your cost",
  CLAIMS_SERVICE_DETAILS_YOUR_COST_BREAKDOWN_SUBHEADING: "The amounts below go towards your plan progress",
  WALLET_ORDER_NEW_CARD_SELECT_MEMBER_ERROR: "Select one or more members to continue",
  WALLET_INVALID_CHARACTER_ERROR_MESSAGE: "Field contains invalid characters",
  CLAIMS_COST_DETAILS_PROVIDER_CHARGED: "Provider Charged",
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE: "Other Insurance Paid",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT: "Your Member Discount",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT_TOOLTIP: "Your plan negotiated with your provider to give you a discounted rate.",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT_TOOLTIP_LABEL: "Your member discount explanation",
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE_TOOLTIP: "The amount your other insurance pays.",
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE_TOOLTIP_LABEL: "Other insurance paid explanation",
  CLAIMS_COST_DETAILS_WE_PAID_ON: "{paymentDate, select, other {Your Plan Paid on {paymentDate, date, medium}} undefined {Your Plan Paid}}",
  CLAIMS_COST_DETAILS_WE_PAID_ON_TOOLTIP: "The amount your plan covered for health care services.",
  CLAIMS_COST_DETAILS_WE_PAID_ON_TOOLTIP_LABEL: "Your plan paid explanation",
  CLAIMS_COST_DETAILS_YOUR_COST: "Your Cost",
  CLAIMS_SERVICE_DETAILS_SERVICE_REMARK: "Service Remark",
  CLAIMS_SERVICE_NETWORK_IN_NETWORK: "In-Network",
  CLAIMS_SERVICE_NETWORK_OUT_OF_NETWORK: "Out-Of-Network",
  FLOW_RULE_ENGINE_MAX_VALUE: "Max Value",
  FLOW_RULE_ENGINE_MIN_VALUE: "Min Value",
  FLOW_RULE_ENGINE_VALIDATION_EMPTY_RULE: "Add at least one rule to generate the JSON object",
  FLOW_RULE_ENGINE_VALIDATION_INCOMPLETE_RULE: "Complete all fields to generate the JSON object",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_DATA_POINT: "Select or enter a valid Data Point.",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_OPERATOR: "Select an Operator that will work with the Data Point.",
  FLOW_RULE_ENGINE_VALIDATION_MISSING_VALUE: "Enter or select a Value",
  FLOW_RULE_ENGINE_VALIDATION_MULTIPLE_VALUES: "Add more than one Value for the selected Operator to compare.",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_VALUE: "Select or enter valid data for any Value fields.",
  WALLET_ORDER_NEW_CARD_SELECT_ADDRESS_OPTION_ERROR: "Select an address option to continue",
  WALLET_ORDER_NEW_CARD_NO_CITY_NUMBERS_ERROR: "City can not contain numbers",
  COVERAGE_AND_COSTS_TOOLTIP: "Information about services that are covered under the plan, including the cost of services for both in-network and out-of-network providers.",
  ASSESSMENTS_AND_DASHBOARDS: "Assessments & Dashboards",
  MESSAGE_CLOSED_TEXT: "This message has been delivered.",
  CLAIMS_PLAN_PROGRESS: "Plan Progress",
  CLAIMS_PLAN_PROGRESS_DESCRIPTION: "Get an overview of your accumulated costs, cost-share, and more.",
  VIEW_PLAN_PROGRESS: "View Plan Progress",
  CLAIMS_BILL_PROVIDER_DESCRIPTION: "You\u2019ll receive a bill associated with this claim from your provider.",
  MEMBER_DESCRIPTION: "Member Description",
  COMPLETION_DATE: "Completion Date",
  ACTIVITY_POINTS_EARNED_AVAILABLE: "{earned} / {available} Points Earned",
  ACTIVITY_POINTS_MISSED: "{available} Points Missed",
  ACTIVITY_POINTS_EARNED: "{earned} Points Earned",
  ACTIVITY_POINTS_ACTIVE: "{available} Points",
  PROGRAM_DATES: "Program Dates",
  ACTIVE_PERIOD: "Active Period",
  VISIBILITY_PERIOD: "Visibility Period",
  OPEN_ACTIVITY_DETAILS_ARIA: "View more program activity details",
  CLAIMS_EXPLANATION_OF_BENEFITS_DOWNLOAD_ERROR: "There was a problem downloading your explanation of benefits.",
  END_CHAT_MODAL_DESCRIPTION: "Continuing will end the chat. You will not be able to return to this conversation in the future.",
  RETURN_TO_CHAT: "Return to Chat",
  CHATS_IN_CONVERSATION: "Chats in conversation",
  LIVE_CHAT_CHARACTER_LIMIT: "Character limit reached ({characterLimit})",
  CHAT_MESSAGE_INPUT: "Chat message input",
  CLAIMS_SERVICE_LINE_ITEM_LABEL: "{code, select, deductible {Deductible} non_covered {Non Covered} copayment {Copayment} coinsurance {Coinsurance} may_be_billed {You May Be Billed} amount_exceeding_maximum {Amount Exceeding Maximum} additional_member_liability {Additional Member Liability} special_processing {Special Processing} paid_to_member {Paid To Member} plan_paid_to_you {Plan Paid To You} other {Other}}",
  QUESTIONNAIRE_FLOW_NEW_MESSAGE: "New Message",
  QUESTIONNAIRE_FLOW_CLOSE: "Close",
  QUESTIONNAIRE_FLOW_GO_BACK: "Go Back",
  QUESTIONNAIRE_FLOW_CONTINUE: "Continue",
  QUESTIONNAIRE_FLOW_RETRY: "Retry",
  CLEAR_ALL: "Clear All",
  FRE_FLOW: "Flow",
  FRE_NEW_FLOW: "New Flow",
  FRE_ZOOM_IN_FLOW: "Zoom into flow",
  FRE_ZOOM_OUT_FLOW: "Zoom out of flow",
  FRE_FIT_VIEW_FLOW: "Fit flow to view",
  FRE_NODE_HANDLE_THEN: "Then",
  FRE_NODE_HANDLE_IF_TRUE: "If True",
  FRE_NODE_HANDLE_IF_FALSE: "If False",
  FRE_NODE_OPTIONS_MENU: "Node options",
  ACTIVE_ELIGIBLE_CHALLENGES: "Active Eligible Challenges",
  HEALTH_REPORT_PAGE_HEADING: "Your Health Report",
  USERNAME_HAS_JOINED_THE_CHAT: "{username} has joined the chat",
  USERNAME_HAS_LEFT_THE_CHAT: "{username} has left the chat",
  PERVIOUS_MONTH: "Previous month button",
  NEXT_MONTH: "Next month button",
  CHAT_CLOSED: "This chat has been closed.",
  CONNECTING_YOU_WITH_AN_AGENT: "Connecting you with an agent...",
  LAST_DAY_TO_USE_BALANCE: "Last day to use balance",
  LAST_DAY_TO_SUBMIT_CLAIMS: "Last day to submit claims",
  NOT_STARTED: "Not Started",
  CS_FORM_NAME: "Name",
  CS_COMPLETION_DATE: "Completion Date",
  CS_VALIDATION_PERIOD: "Validation Period",
  CS_FORM_STATUS: "Status",
  CS_NOT_STARTED_DEFINITION: "Member has not started the task.",
  CS_IN_PROGRESS_DEFINITION: "Member started task but did not complete.",
  CS_COMPLETED_DEFINITION: "Member successfully completed task.",
  CS_REJECTED_DEFINITION: "Member has rejected task.",
  CS_EMPTY_CONSENT_FORMS_TITLE: "No consent forms on file",
  CS_EMPTY_CONSENT_FORMS_BODY: "Note: Member may have a Pending consent form to be completed this year.",
  CS_CONSENT_FORMS: "Consent forms",
  CS_ERROR_HEADING: "Something went wrong",
  CS_ERROR_BODY: "There was a problem loading data. Please try again later.",
  LIVE_CHAT_GENERIC_ERROR_TITLE: "Something went wrong",
  LIVE_CHAT_GENERIC_ERROR_DESCRIPTION: "There was an issue, please try again",
  LIVE_CHAT_GENERIC_TOAST_ERROR_TITLE: "Something went wrong",
  LIVE_CHAT_GENERIC_TOAST_ERROR_DESCRIPTION: "There was an issue, please try again",
  LIVE_CHAT_GENERIC_ERROR_BUTTON: "Close",
  CS_HEALTH_ASSESSMENTS_TITLE: "Health assessments",
  CS_HEALTH_ASSESSMENTS_STATUS_DEFINITIONS: "Health assessment status definitions",
  CS_CONSENT_FORMS_STATUS_DEFINITIONS: "Consent forms status definitions",
  CS_HEALTH_ASSESSMENTS_EMPTY_MESSAGE: "Member does not have any Health Assessments.",
  FRE_GROUP_HEADER: "Group {groupLevel}",
  LIVE_CHAT_LANDING_PAGE_TITLE: "Chat",
  LIVE_CHAT_DELIVERY_FAILURE: "Not delivered",
  LIVE_CHAT_RESEND_MESSAGE: "Resend message",
  LIVE_CHAT_MESSAGE_FAILED_TITLE: "Message failed to send",
  LIVE_CHAT_MESSAGE_FAILED_BODY: "Sorry about that, it looks like your message didn\u2019t make it to our team.",
  CS_VALIDATION_PERIOD_ENDED: "Period ended on {date}",
  CS_NO_CONSENTS_IN_VALIDATION_PERIOD: "No Completed or Rejected consent forms within validation period. Member may have a Pending consent form to be completed this year.",
  SERVICE_NAME: "Service name",
  CS_BIOMETRICS_EMPTY_MESSAGE: "Member does not have any available Biometrics.",
  JOINED_DATE: "Joined Date",
  VIEW_TOOLTIP: "view {tooltipLabel} tooltip",
  CS_NO_DATE_AVAILABLE: "No date(s) available"
};

// src/fr-CA.json
var fr_CA_default = {
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE_TOOLTIP: "Le montant pay\xE9 par votre autre assurance.",
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE: "Autre assurance a pay\xE9",
  CLAIMS_SERVICE_DETAILS_SERVICE_OTHER_INSURANCE_PAID: "Autre assurance a pay\xE9",
  FRE_NODE_OPTIONS_MENU: "Options de n\u0153ud",
  FRE_NODE_HANDLE_IF_FALSE: "Si Faux",
  FRE_NODE_HANDLE_IF_TRUE: "Si Vrai",
  FRE_NODE_HANDLE_THEN: "Alors",
  LAST_DAY_TO_SUBMIT_CLAIMS: "Dernier jour pour soumettre des r\xE9clamations",
  LAST_DAY_TO_USE_BALANCE: "Dernier jour pour utiliser le solde",
  CHAT_CLOSED: "Le clavardage est termin\xE9.",
  CONNECTING_YOU_WITH_AN_AGENT: "Vous mettre en relation avec un agent...",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_3: "Bien que nous ne puissions pas contr\xF4ler ce qui se passe dans le march\xE9 en g\xE9n\xE9ral, nous avons investi des fonds suppl\xE9mentaires dans la R\xE9serve bien-\xEAtre pour compenser l'augmentation du prix des prestations.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_2: "Conform\xE9ment \xE0 ce qui est constat\xE9 dans de nombreuses d\xE9penses quotidiennes, les co\xFBts des services m\xE9dicaux et dentaires continuent de monter et cela se traduit dans les changements de prix pour les prestations de KPMG.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_1: "La prestation de sant\xE9 mentale offerte par KPMG, enti\xE8rement financ\xE9e par l'entreprise, passe \xE0 3 000 $ sans frais pour nos employ\xE9s. KPMG continue de s'engager \xE0 couvrir la totalit\xE9 du co\xFBt de cette prestation en votre nom.",
  NEXT_MONTH: "Mois prochain",
  PERVIOUS_MONTH: "Mois pr\xE9c\xE9dent",
  USERNAME_HAS_LEFT_THE_CHAT: "{username} a quitt\xE9 le clavardage",
  USERNAME_HAS_JOINED_THE_CHAT: "{username} a rejoint le clavardage",
  HEALTH_REPORT_PAGE_HEADING: "Votre rapport de sant\xE9",
  CLAIMS_PLAN_PROGRESS_DESCRIPTION: "Obtenez un aper\xE7u de vos co\xFBts cumul\xE9s, de vos niveaux de couverture et plus encore.",
  CLAIMS_COST_DETAILS_WE_PAID_ON_TOOLTIP: "Le montant couvert par votre r\xE9gime pour les services de soins de sant\xE9.",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT_TOOLTIP: "Votre r\xE9gime a n\xE9goci\xE9 avec votre prestataire un tarif pr\xE9f\xE9rentiel.",
  CLAIMS_SERVICE_DETAILS_DATE: "Service",
  CLAIMS_ADDITIONAL_RESOURCES_HEADING: "Ressources suppl\xE9mentaires",
  CLAIMS_SERVICE_TYPE: "{type, select, institutional {R\xE9clamation m\xE9dicale} oral {R\xE9clamation dentaire} vision {R\xE9clamation pour les soins de la vue} pharmacy {R\xE9clamation pharmaceutique} professional {R\xE9clamation professionnelle} other {R\xE9clamation}}",
  ACTIVE_ELIGIBLE_CHALLENGES: "D\xE9fis admissibles actifs",
  KG: "kg",
  LB: "lb",
  IN: "po",
  CM: "cm",
  CONNECT_A_DEVICE: "Connecter un appareil",
  FRE_FIT_VIEW_FLOW: "Adapter le flux \xE0 la vue",
  FRE_ZOOM_OUT_FLOW: "Zoom arri\xE8re sur le flux",
  FRE_ZOOM_IN_FLOW: "Zoom sur le flux",
  INSIGHT_CARD_HEADER_WAIST_CIRCUMFERENCE: "Tour de taille",
  FRE_NEW_FLOW: "Nouveau flux",
  FRE_FLOW: "Flux",
  CLEAR_ALL: "Tout effacer",
  CLAIMS_SERVICE_TYPE_SUFFIX_PROFESSIONAL: "R\xE9clamation professionnelle",
  QUESTIONNAIRE_FLOW_RETRY: "R\xE9essayer",
  QUESTIONNAIRE_FLOW_CONTINUE: "Continuer",
  QUESTIONNAIRE_FLOW_GO_BACK: "Retour",
  QUESTIONNAIRE_FLOW_CLOSE: "Fermer",
  QUESTIONNAIRE_FLOW_NEW_MESSAGE: "Nouveau message",
  OPEN_ACTIVITY_DETAILS_ARIA: "Voir les d\xE9tails de l'activit\xE9 du programme",
  VISIBILITY_PERIOD: "P\xE9riode de visibilit\xE9",
  ACTIVE_PERIOD: "P\xE9riode active",
  PROGRAM_DATES: "Dates du programme",
  REWARDS_ID_UNDERSCORE: "R\xE9compenses_ID\xA0: {rewardId}",
  VIEW_ALL_CHALLENGES_DESCRIPTION_SOLO: "Lancez-vous en solo et mettez-vous au d\xE9fi de r\xE9aliser des activit\xE9s ax\xE9es sur le conditionnement physique, la nutrition, la sant\xE9 mentale, la gestion du stress, et bien d'autres choses encore.",
  VIEW_ALL_CHALLENGES_DESCRIPTION_GROUP: "Restez responsable lorsque vous vous mettez au d\xE9fi avec un travail de pleine conscience, des initiatives en mati\xE8re d'alimentation saine, des comp\xE9titions de fitness, des activit\xE9s de r\xE9duction du stress, des d\xE9fis de pas, et bien plus encore.",
  LATEST_INSIGHTS_METRICS_AND_MORE: "Derni\xE8rs aper\xE7us, mesures et plus encore",
  VIEW_MY_HEALTH_DASHBOARD: "Consulter le tableau de bord de ma sant\xE9",
  CLAIMS_SERVICE_LINE_ITEM_LABEL: "{code, select, deductible {Deductible} copayment {Copayment} coinsurance {Coinsurance} may_be_billed {May Be Billed} amount_exceeding_maximum {Amount Exceeding Maximum} additional_member_liability {Additional Member Liability} special_processing {Special Processing} medicare_paid {Medicare Paid} paid_to_member {Paid To Member} paid_by_other_insurance {Paid By Other Insurance} plan_paid_to_you {Plan Paid To You} other {Other}}",
  CHAT_MESSAGE_INPUT: "Saisie du message de clavardage",
  LIVE_CHAT_CHARACTER_LIMIT: "Limite de caract\xE8res atteinte ({characterLimit})",
  ACTIVITY_POINTS_ACTIVE: "{available} points",
  ACTIVITY_POINTS_EARNED_AVAILABLE: "{earned} / {available} points accumul\xE9s",
  ENTER_YOUR_HEIGHT_IN_INCHES: "Entrez votre taille en pouces",
  ENTER_YOUR_HEIGHT_IN_FEET: "Entrez votre taille en pieds",
  ENTER_YOUR_HEIGHT_IN_CENTIMETERS: "Entrez votre taille en centim\xE8tres",
  ENTER_YOUR_WEIGHT_IN_POUNDS: "Entrez votre poids en livres",
  ENTER_YOUR_WEIGHT_IN_KILOGRAMS: "Entrez votre poids en kilogrammes",
  TOOLBOX_VALIDATION_HEIGHT_MAXIMUM: "La hauteur doit \xEAtre au maximum {value}{unit}",
  TOOLBOX_VALIDATION_HEIGHT_MINIMUM: "La hauteur doit \xEAtre d'au moins {value}{unit}",
  TOOLBOX_VALIDATION_WEIGHT_MAXIMUM: "Le poids doit \xEAtre au maximum {value}{unit}",
  TOOLBOX_VALIDATION_WEIGHT_MINIMUM: "Le poids doit \xEAtre d'au moins {value}{unit}",
  RETURN_TO_CHAT: "Retourner au clavardage",
  END_CHAT_MODAL_DESCRIPTION: "Continuer mettra fin au clavardage. Vous ne pourrez plus revenir sur cette conversation \xE0 l'avenir.",
  POINTS_OVER_TOTAL_ALT: "Vous avez {actuel} sur {total} points",
  POINTS_OVER_TOTAL: "<span>{current}</span> / {total} points",
  CHATS_IN_CONVERSATION: "Clavardages en direct",
  CLAIMS_SERVICE_BANNER_REMARKS_CODE_IN_EOB: "Code des remarques de prolongation de prestations : {code}",
  CLAIMS_SERVICE_DETAILS_YOUR_COST_BREAKDOWN_SUBHEADING: "Les montants ci-dessous servent \xE0 la progression de votre r\xE9gime",
  CLAIMS_SERVICE_DETAILS_YOUR_COST_BREAKDOWN_HEADING: "R\xE9partition de votre co\xFBt",
  CLAIMS_EXPLANATION_OF_BENEFITS_DOWNLOAD_ERROR: "Un probl\xE8me est survenu lors du t\xE9l\xE9chargement des d\xE9tails de vos avantages.",
  "SUPPORT_AGENT_NOT_FOUND ": "Nom de l'agent de soutien introuvable",
  ACTIVITY_POINTS_MISSED: "{available} points manqu\xE9s",
  ACTIVITY_POINTS_EARNED: "{earned} / {available} points accumul\xE9s",
  COMPLETION_DATE: "Date d'ach\xE8vement",
  MEMBER_DESCRIPTION: "Description du membre",
  NO_REWARDS_AVAILABLE_BODY: "Le membre n'a pas de r\xE9compenses pour le moment.",
  NO_REWARDS_AVAILABLE_HEADING: "Aucune campagne disponible",
  PROGRAM: "Programme",
  REWARDS_ID: "ID de r\xE9compense",
  VIEW_REWARD_LABEL_HIDDEN: "Voir les d\xE9tails de la r\xE9compense du membre pour le programme de r\xE9compense {from}",
  REWARDS_PROGRAMS: "Programmes de r\xE9compenses",
  WALLET_ORDER_NEW_CARD_NO_CITY_NUMBERS_ERROR: "La ville ne peut pas contenir de chiffres",
  ASSESSMENTS_AND_DASHBOARDS: "\xC9valuations et tableaux de bord",
  STANDARD_PROGRAMS: "Programmes standards",
  REWARD_PROGRAMS: "Programmes de r\xE9compense",
  CLAIMS_BILL_PROVIDER_DESCRIPTION: "Vous recevrez une facture associ\xE9e \xE0 cette demande de la part de votre fournisseur.",
  VIEW_PLAN_PROGRESS: "Afficher la progression du plan",
  CLAIMS_PLAN_PROGRESS: "Progression du plan",
  MESSAGE_CLOSED_TEXT: "Ce message a \xE9t\xE9 envoy\xE9.",
  CLAIMS_COST_DETAILS_WE_PAID: "Nous avons pay\xE9",
  CLAIMS_SERVICE_DETAILS_SERVICE_REMARK: "Remarque sur le service",
  CLAIMS_SERVICE_BANNER_VIEW_BENEFITS_BOOKLET: "Voir le livret des avantages sociaux",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_VALUE: "Veuillez s\xE9lectionner ou saisir des donn\xE9es valides pour tous les champs de valeur.",
  FLOW_RULE_ENGINE_VALIDATION_MULTIPLE_VALUES: "Veuillez ajouter plusieurs valeurs pour l'op\xE9rateur s\xE9lectionn\xE9 \xE0 comparer.",
  FLOW_RULE_ENGINE_VALIDATION_MISSING_VALUE: "Veuillez saisir ou s\xE9lectionner une valeur pour cette r\xE8gle.",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_OPERATOR: "Veuillez s\xE9lectionner un op\xE9rateur qui fonctionnera avec le point de donn\xE9es.",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_DATA_POINT: "Veuillez saisir ou s\xE9lectionner un point de donn\xE9es pour cette r\xE8gle.",
  FLOW_RULE_ENGINE_VALIDATION_INCOMPLETE_RULE: "Veuillez remplir tous les champs pour g\xE9n\xE9rer l'objet JSON",
  FLOW_RULE_ENGINE_VALIDATION_EMPTY_RULE: "Veuillez ajouter au moins une r\xE8gle pour g\xE9n\xE9rer l'objet JSON",
  WALLET_INVALID_CHARACTER_ERROR_MESSAGE: "Le champ contient des caract\xE8res non valides",
  COVERAGE_AND_COSTS_TOOLTIP: "Des informations sur les services couverts par le r\xE9gime, y compris le co\xFBt des services pour les prestataires internes et externes.",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_STRING: "Le point de donn\xE9es fonctionne avec du texte (cha\xEEne)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_NUMBER: "Le point de donn\xE9es n\xE9cessite un nombre ou une d\xE9cimale",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_INT: "Le point de donn\xE9es n\xE9cessite un nombre entier (entier)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_BOOLEAN: "Le point de donn\xE9es doit \xEAtre vrai ou faux (bool\xE9en)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_DATETIME: "Le point de donn\xE9es n\xE9cessite une date (AAAA-MM-JJ) et/ou une heure (24 heures en HH:MM)",
  FLOW_RULE_ENGINE_MIN_VALUE: "Valeur minimale",
  FLOW_RULE_ENGINE_MAX_VALUE: "Valeur maximale",
  WALLET_ORDER_NEW_CARD_SELECT_ADDRESS_OPTION_ERROR: "S\xE9lectionnez une option d'adresse pour continuer",
  WALLET_ORDER_NEW_CARD_SELECT_MEMBER_ERROR: "S\xE9lectionnez un ou plusieurs membres pour continuer",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_COMBINATOR: "<h3> Et/Ou </h3> <p> Utilisez le s\xE9lecteur et/ou pour connecter deux ou plusieurs combinaisons de r\xE8gles ou groupes ensemble. </p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_GROUP: "<h3>Groupe</h3> <p>Un conteneur qui contient plusieurs r\xE8gles et d\xE9finit la relation logique entre elles (Et/ou). Les groupes peuvent \xEAtre imbriqu\xE9s les uns dans les autres pour cr\xE9er des combinaisons complexes de conditions.</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE_LIST: "<ul><li><strong>Ajouter des donn\xE9es :</strong> Trouver le point de donn\xE9es. Il s'agit de la source \xE0 laquelle s'appliquera votre r\xE8gle.</li><li><strong>Op\xE9rateur :</strong> Choisir les conditions de votre r\xE8gle. Il s'agit de la mani\xE8re dont le point de donn\xE9es sera compar\xE9 \xE0 une valeur.</li><li><strong>Valeur :</strong> Ce \xE0 quoi vous comparez le point de donn\xE9es via l'op\xE9rateur. Associez une valeur \xE0 un point de donn\xE9es pour comparer plus d'un point de donn\xE9es.</li></ul>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE: "<h3>R\xE8gle</h3><p>Une r\xE8gle repr\xE9sente une condition individuelle et comprend les \xE9l\xE9ments suivants :</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_TITLE: "Guide du g\xE9n\xE9rateur de r\xE8gles (Rule Builder)",
  CLAIMS_COST_DETAILS_YOUR_COST: "Votre co\xFBt",
  CLAIMS_COST_DETAILS_WE_PAID_ON: "{paymentDate, select, other {Nous avons pay\xE9 le {paymentDate, date, medium}} undefined {Nous avons pay\xE9}}",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT: "Votre rabais de membre",
  CLAIMS_COST_DETAILS_PROVIDER_CHARGED: "Prestataire factur\xE9",
  RECOMMENDED_DEVICE_DESCRIPTION_APP_VERSION: "<strong>Version de l'appli :</strong> {primaryName} {primaryCurrentVersion} ou {secondaryName} {secondaryCurrentVersion}",
  RECOMMENDED_DEVICE_DESCRIPTION_WEB_BROWSERS: "<strong> Navigateurs Web\xA0: </strong> la version la plus r\xE9cente de Chrome, Firefox, Safari ou Microsoft Edge",
  CLAIMS_SERVICE_DETAILS_HIDE_COST_DETAILS: "Masquer les d\xE9tails des co\xFBts",
  CLAIMS_SERVICE_DETAILS_SHOW_COST_DETAILS: "Afficher les d\xE9tails des co\xFBts",
  CLAIMS_SERVICE_DETAILS_SERVICE_COST_DETAILS: "D\xE9tails des co\xFBts",
  CLAIMS_SERVICE_DETAILS_SERVICE_YOUR_COST: "Votre co\xFBt",
  CLAIMS_SERVICE_DETAILS_SERVICE_PROCEDURE_CODE: "Code de proc\xE9dure",
  CLAIMS_SERVICE_DETAILS_SERVICE_STATUS: "Statut",
  CLAIMS_SERVICE_DETAILS_SERVICE: "Service",
  CLAIMS_SERVICE_DETAILS_SUBHEADING: "Une r\xE9partition des services individuels que vous avez re\xE7us et de leurs co\xFBts associ\xE9s.",
  CLAIMS_SERVICE_DETAILS_HEADING: "D\xE9tails du service",
  MESSAGE_WITH_A_MEMBER: "Envoyez un message \xE0 un conseiller pour obtenir de l\u2019aide relative aux questions sur vos demandes de r\xE8glement concernant vos prestations ou vos soins.",
  GET_SUPPORT: "Obtenir de l\u2019aide",
  SUPPORT: "Soutien",
  INNETWORK_DEDUCTIBLE: "s'applique \xE0 la franchise au sein de votre r\xE9seau",
  VIEW_DEDUCTIBLE: "Voir la franchise",
  DEDUCTIBLE_TRACKER: "Suivi de franchise",
  TOTAL_COST_DESCRIPTION: "La somme des co\xFBts des services de votre demande. Cela n\u2019inclut pas les paiements que vous avez effectu\xE9s \xE0 votre prestataire, comme les quotes-parts.",
  TOTAL_COST_OVERVIEW: "Aper\xE7u du co\xFBt total",
  ORDER_CARD: "Carte de commande",
  STEP: "\xC9tape",
  DATA_TYPE_HINT_STRING: "Le point de donn\xE9es attend une cha\xEEne de caract\xE8res (texte)",
  DATA_TYPE_HINT_NUMBER: "Le point de donn\xE9es attend un nombre ou une d\xE9cimale",
  DATA_TYPE_HINT_INT: "Le point de donn\xE9es attend un nombre entier (entier)",
  DATA_TYPE_HINT_BOOLEAN: "Le point de donn\xE9es attend un vrai ou un faux (bool\xE9en)",
  DATA_TYPE_HINT_DATETIME: "Le point de donn\xE9es attend une date et/ou une heure",
  PLEASE_MAKE_A_SELECTION: "Veuillez faire une s\xE9lection",
  CANCEL_ORDER: "Annuler la commande",
  CANCEL_ORDER_NEW_CARD_DESC: "Vous pouvez toujours revenir plus tard.",
  CANCEL_ORDER_NEW_CARD_TITLE: "\xCAtes-vous s\xFBr de vouloir annuler votre commande?",
  ORDER_NEW_CARD_HEADING: "Commander une nouvelle carte",
  RESUBMIT: "Resoumettre",
  TRY_LATER: "Veuillez r\xE9essayer plus tard",
  MEMBER_CARD_ARRIVAL: "La nouvelle carte d'identit\xE9 de membre sera livr\xE9e dans un d\xE9lai d'environ 10 jours ouvrables.",
  MEMBER_CARD_ON_ITS_WAY: "Votre nouvelle carte d'identit\xE9 de membre est en route.",
  X_OF_Y_COMPLETED_WITH_UNIT: "<span>{progress}</span> / {goal} {unit}",
  REWARDS_PROGRAM_ELIGIBLE: "Admissible au programme de r\xE9compenses",
  SPOUSE_COVERAGE: "Couverture du conjoint",
  CHILD_COVERAGE: "Couverture des enfants",
  DATA_COLLECTED: "Donn\xE9es collect\xE9es {date}",
  DOWNLOAD_BENEFITS_EXPLANATION: "T\xE9l\xE9charger les d\xE9tails des avantages",
  CLAIMS_BILL_DESCRIPTION: "Vous recevrez une facture associ\xE9e \xE0 cette demande de la part de votre fournisseur.",
  CLAIMS_PROCESSING_DESCRIPTION: "Nous sommes en train de traiter cette demande. Vous serez inform\xE9 lorsqu'elle sera pr\xEAte \xE0 \xEAtre examin\xE9e.",
  CLAIMS_YOUR_COST: "Votre co\xFBt",
  PLEASE_MAKE_SELECTION_FOR: "Veuillez faire une s\xE9lection pour {input}",
  REQUIRED_FIELD: "{field} est un champ obligatoire",
  SELECT_STATE: "S\xE9lectionner un \xE9tat",
  CHOOSE_NEW_ADDRESS_DESC: "Indiquez une autre adresse postale pour la livraison de la carte. Cela ne modifiera pas l'adresse par d\xE9faut de votre compte. L'adresse postale doit \xEAtre situ\xE9e aux \xC9tats-Unis.",
  NEW_ADDRESS: "Nouvelle adresse de livraison",
  MEDICAL_ID_CARD: "Carte d'identit\xE9 m\xE9dicale",
  CONFIRM_DETAILS: "Confirmez vos coordonn\xE9es",
  NOT_VALID_DATA_POINT: "Point de donn\xE9es non valide",
  VALID_DATA_POINT: "Point de donn\xE9es valide",
  ENTER_VALID_DATA_POINT: "Veuillez entrer un point de donn\xE9es valide",
  CHOOSE_NEW_ADDRESS_HINT: "Indiquez une autre adresse postale pour la livraison de la carte. Cela ne modifiera pas l'adresse par d\xE9faut de votre compte.",
  CHOOSE_NEW_ADDRESS: "Choisissez une nouvelle adresse",
  DEFAULT_ADDRESS: "Adresse par d\xE9faut",
  DATA_POINT_LOADING_ERROR: "Impossible de charger les cat\xE9gories de donn\xE9es pour le moment.",
  TEAM_AVERAGE: "Moyenne de l'\xE9quipe",
  LEAVE_TEAM_DESCRIPTION: "Voulez-vous vraiment quitter {teamName}? Votre score ne sera plus consid\xE9r\xE9 comme faisant partie de la moyenne de l\u2019\xE9quipe.",
  MESSAGE_CENTER: "Centre de messagerie",
  DATA_POINT_EMPTY_SELECTION: "Veuillez s\xE9lectionner un point de donn\xE9es \xE0 appliquer",
  DATA_POINT_MODAL_INSTRUCTIONS: "Choisissez une cat\xE9gorie pour trouver un point de donn\xE9es\xA0:",
  DATA_POINT_MODAL_HEADING: "S\xE9lectionner une cat\xE9gorie",
  ADD_TO_RULE: "Ajouter \xE0 la r\xE8gle",
  CARD_ACTIONS: "Actions de carte",
  MINDFUL_MINUTES_HIGHLIGHT: "<strong>Termin\xE9 {total} minutes !</strong>",
  COMPLETE_BY_DATE: "\xC0 terminer avant le {date}",
  MEDIUM_PRIORITY: "Priorit\xE9 moyenne",
  HIGH_PRIORITY: "Priorit\xE9 \xE9lev\xE9e",
  PART_OF_REWARDS_PROGRAM: "Dans le cadre du programme de r\xE9compenses",
  X_LEAGUE_POINTS: "{points} points League",
  X_REWARD_POINTS: "{points} points de r\xE9compense",
  TOOLTIP_ACTION_DESCRIPTION: "Voir le crit\xE8re de classement",
  TOOLTIP_LEADERBOARD_TEAMS: "Les \xE9quipes sont class\xE9es selon leur moyenne de {rankingUnit} pour ce d\xE9fi",
  TOOLTIP_LEADERBOARD_USER: "Les participants sont class\xE9s par leur total de {rankingUnit} pour ce d\xE9fi",
  EDIT_DATA_BUTTON_ARIA_LABEL: "Modifier le point de donn\xE9es vers l'entr\xE9e {inputLabel}",
  ADD_DATA_BUTTON_ARIA_LABEL: "Ajouter un point de donn\xE9es \xE0 l'entr\xE9e {inputLabel}",
  VALUE_LABEL: "Valeur",
  DATA_POINT_LABEL: "Point de donn\xE9es",
  EDIT_DATA: "Modifier les donn\xE9es",
  ADD_DATA: "Ajouter des donn\xE9es",
  OPERATOR_SELECT_TITLE: "S\xE9lecteur d'op\xE9rateur pour comparaison",
  OPERATOR_INPUT_LABEL: "Op\xE9rateur",
  DOES_NOT_INCLUDE: "Ne comprend pas",
  IS_NOT_BETWEEN: "N'est pas entre",
  BETWEEN: "Entre",
  INCLUDING: "Y compris",
  IS_NOT_BLANK: "N'est pas vide",
  IS_BLANK: "Est vide",
  GREATER_THAN_OR_EQUAL_TO: "Sup\xE9rieure ou \xE9gale \xE0",
  LESS_THAN_OR_EQUAL_TO: "Inf\xE9rieure ou \xE9gale \xE0",
  GREATER_THAN: "Sup\xE9rieure \xE0",
  LESS_THAN: "Moins de",
  NOT_EQUAL_TO: "Pas \xE9gale \xE0",
  EQUALS_TO: "\xE9quivaut \xE0",
  EARN_ACHIEVEMENT_COMPLETING_ACTIVITIES_CHALLENGE: "Obtenez une r\xE9alisation en effectuant toutes les activit\xE9s de ce d\xE9fi.",
  ACHIEVEMENT_PROGRESS: "Progression des r\xE9alisations",
  ACHIEVEMENT_EARNED: "C\xE9l\xE9bration de la r\xE9alisation",
  CHALLENGE_COMPLETED_BODY: "Il ne reste plus d'activit\xE9s \xE0 r\xE9aliser. Bon travail!",
  CHALLENGE_COMPLETED_TITLE: "Nous avons termin\xE9!",
  CHALLENGE_ACTIVITY_MISSED_EMPTY_STATE: "Les activit\xE9s que vous avez manqu\xE9es appara\xEEtront ici.",
  CHALLENGE_ACTIVITY_MISSED: "Manqu\xE9es ({count})",
  CHALLENGE_ACTIVITY_COMPLETED_EMPTY_STATE: "Les activit\xE9s que vous avez termin\xE9es appara\xEEtront ici.",
  CHALLENGE_ACTIVITY_COMPLETED: "Termin\xE9es ({count})",
  CHALLENGE_ACTIVITY_UP_NEXT_EMPTY_STATE: "Rendez-vous ici lorsque le d\xE9fi commencera!",
  CHALLENGE_ACTIVITY_UP_NEXT: "Suivantes ({count})",
  CHALLENGE_ACTIVITY_EMPTY_STATE_HEADER: "Il n'y a rien \xE0 voir ici!",
  CONFIRM_REMOVE_GROUP_DESCRIPTION: "Voulez-vous vraiment supprimer ce groupe? Cela supprimera \xE9galement toutes les r\xE8gles et tous les groupes au sein de ce groupe.",
  CONFIRM_REMOVE_RULE_DESCRIPTION: "\xCAtes-vous s\xFBr de vouloir supprimer cette r\xE8gle?",
  CONFIRM_REMOVE_GROUP_HEADING: "Supprimer le groupe",
  CONFIRM_REMOVE_RULE_HEADING: "Supprimer la r\xE8gle",
  GROUP_DESCRIPTION_SHORT: "<strong>Groupe :</strong> Conteneur qui contient plusieurs r\xE8gles et d\xE9finit leur relation logique. Les groupes peuvent \xEAtre imbriqu\xE9s pour cr\xE9er des combinaisons complexes de conditions.",
  RULE_DESCRIPTION_SHORT: "<strong>R\xE8gle :</strong> Une condition individuelle.",
  EMPTY_RULE_STATE_HEADING: "S\xE9lectionnez <strong>+ Ajouter une r\xE8gle</strong> ou <strong>+ Ajouter un groupe</strong>",
  ADD_GROUP_BUTTON_LABEL: "+\xA0Ajouter un groupe",
  ADD_GROUP_BUTTON_TITLE: "Ajouter un groupe",
  ADD_RULE_BUTTON_LABEL: "+\xA0Ajouter une r\xE8gle",
  ADD_RULE_BUTTON_TITLE: "Ajouter une r\xE8gle",
  JSON_COPY_ERROR: "Impossible de copier. Veuillez r\xE9essayer ou s\xE9lectionner le texte et le copier.",
  JSON_COPY_SUCCESS: "JSON copi\xE9",
  COPY_JSON: "Copier JSON",
  GENERATE_JSON_INPUT_LABEL: "JSON Logic \xE0 copier et coller dans Contentful",
  GENERATE_JSON: "G\xE9n\xE9rer JSON",
  VALID_JSON_ERROR: "Impossible d'importer car il ne s'agit pas d'un JSON valide.",
  VALID_RULE_ERROR: "Impossible d'importer car il n'y a pas de r\xE8gles.",
  IMPORT_JSON_HINT: "Seules les cha\xEEnes de caract\xE8res qui sont \xE9valu\xE9es comme des objets JavaScript lorsqu'elles sont trait\xE9es avec JSON.parse seront traduites en requ\xEAtes.",
  IMPORT_JSON_LABEL: "Collez le code JSON afin de le restituer au Rule Builder",
  IMPORT_JSON: "Importer JSON",
  IMPORT_CTA: "Importer",
  ACTIVITY_COUNT_EDIT: "Modifier {unit}",
  ORDER_NEW_CARD_ADDRESS_SELECT_HEADING: "S\xE9lectionnez une adresse de livraison",
  TOP_RANKED_SUBTITLE: "par nombre de",
  REQUEST_WAIT: "Veuillez patienter pendant que nous traitons votre demande",
  PROGRAM_RULES: "R\xE8gles du programme",
  RECOMMENDED_DEVICE_DESCRIPTION: "Assurez-vous que les membres sur le Web utilisent la version la plus r\xE9cente de Chrome, Firefox, Safari et Microsoft Edge, ainsi que la derni\xE8re version de l'application iOS v2.13.4 et Android v2.14.8.",
  RECOMMENDED_DEVICE_TITLE: "Appareil recommand\xE9 pour les membres",
  ORDER_NEW_CARD_MEMBER_SELECT_HELP_TEXT: "S\xE9lectionnez un ou plusieurs membres.",
  ORDER_NEW_CARD_MEMBER_SELECT_HEADING: "Qui a besoin d\u2019une nouvelle carte?",
  SESSION_LOG_OUT: "Se d\xE9connecter",
  CONTINUE_SESSION: "Continuer la session",
  SESSION_TIMEOUT: "Expiration de la session",
  SESSION_ABOUT_TO_TIMEOUT: "Vous avez \xE9t\xE9 inactif pendant un certain temps et votre session est sur le point d'expirer. Voulez-vous continuer votre session?",
  COMPLETED_ACTIVITY_HIGHLIGHT: "Une activit\xE9 a \xE9t\xE9 termin\xE9e!",
  DEVICE_TITLE_CASE: "Appareil",
  NO_RECENT_SESSIONS_DESCRIPTION: "Aucune activit\xE9 de membre au cours des 90 derniers jours.",
  NO_RECENT_SESSIONS: "Aucune session r\xE9cente",
  POLICYHOLDER: "Titulaire de la police",
  RULE_BUILDER: "Rule Builder",
  RULE_BUILDER_SUBHEADING: "Cr\xE9er des r\xE8gles et d\xE9finir des conditions qui les rendent vraies",
  RESPONDED_STUDENT_CERTIFICATION: "Votre r\xE9ponse a bien \xE9t\xE9 enregistr\xE9e!",
  CONFIRM_DEPENDENT_STATUS: "Est-ce que {firstName} {lastName} est \xE9tudiant \xE0 temps plein?",
  STUDENT_RECERTIFICATION_NO_INFO: "En s\xE9lectionnant NON, la personne \xE0 charge sera retir\xE9e de la couverture. Assurez-vous de v\xE9rifier \xE0 nouveau avant de soumettre votre r\xE9ponse.",
  STUDENT_RECERTIFICATION_YES_INFO: "En s\xE9lectionnant OUI, vous d\xE9clarez que la personne \xE0 charge est \xE9tudiante \xE0 temps plein.",
  STUDENT_RECERTIFICATION_DESCRIPTION: "Une fois qu'un enfant \xE0 charge atteint l'\xE2ge de 18 ou 21 ans (selon ce qui est indiqu\xE9 dans votre livret d'avantages sociaux), il doit \xEAtre \xE9tudiant \xE0 temps plein pour \xEAtre assur\xE9 en vertu de votre contrat. Pour les personnes \xE0 charge \xE2g\xE9es de 25 ans ou moins, vous devez confirmer leur statut d'\xE9tudiant au d\xE9but de chaque ann\xE9e d'\xE9tudes postsecondaires.",
  STUDENT_RECERTIFICATION: "Recertification des \xE9tudiants",
  PENDING_COVERAGE_APPROVAL: "En attente d'approbation",
  ANSWERS_NOT_SAVED: "Nous ne sommes pas en mesure d'enregistrer vos r\xE9ponses pour le moment. Veuillez r\xE9essayer plus tard.",
  PROVIDER_SERVICE_POPOVER_BUTTON_NAME: "Qu\u2019est-ce qu\u2019une quote-part et une coassurance?",
  NO_DIGITAL_CARD: "Aucune carte attendue",
  DIGITAL_CARD_BACK: "Image verso de la carte num\xE9rique",
  DIGITAL_CARD_FRONT: "Image recto de la carte num\xE9rique",
  SET_TEAM_AVATAR: "D\xE9finir l'avatar de l'\xE9quipe",
  CHANGE_TEAM_AVATAR: "Modifier l'avatar de l'\xE9quipe",
  SELECT_TEAM_AVATAR: "S\xE9lectionner l'avatar de l'\xE9quipe",
  DIGITAL_CARD: "Carte num\xE9rique",
  BENEFIT_DEPENDENTS: "Personnes \xE0 charge des prestations",
  QUICK_LINKS_AGENT: "Liens rapides et documents disponibles pour les membres.",
  MEMBER_ACTIONS: "Actions des membres",
  CHAR_LIMIT_EXCEEDED: "Limite de caract\xE8res d\xE9pass\xE9e ({maximum})",
  USER_DROPDOWN_BTN_LABEL: "Menu du profil",
  NO_CHALLENGES_PROGRAMS_EMPTY_STATE_BODY: "Parcourez notre biblioth\xE8que pour trouver les programmes et d\xE9fis de sant\xE9 qui correspondent \xE0 vos objectifs personnels.",
  NO_CHALLENGES_PROGRAMS_EMPTY_STATE_HEADING: "Pr\xEAt \xE0 commencer?",
  WALLET_WITH_CLAIMS_LANDING_TITLE: "Les informations sur vos avantages sociaux, vos r\xE9clamations et votre bien-\xEAtre r\xE9unis \xE0 un seul endroit.",
  SOMETHING_WENT_WRONG_BODY: "Un probl\xE8me est survenu lors du chargement des donn\xE9es.{br}Veuillez r\xE9essayer plus tard.",
  SOMETHING_WENT_WRONG_HEADING: "Une erreur est survenue",
  NO_BENEFITS_AVAILABLE_BODY: "Membre n\u2019a pas d\u2019avantages pour le moment.",
  NO_BENEFITS_AVAILABLE_HEADING: "Aucun avantage disponible",
  NO_FILTERED_BENEFITS: "Nous n\u2019avons rien trouv\xE9 correspondant \xE0 ces filtres.",
  CLAIMS_SUMMARY_DOWNLOAD_ERROR: "Un probl\xE8me est survenu lors du t\xE9l\xE9chargement de vos demandes.",
  PLAIN_SOMETHING_WENT_WRONG: "Une erreur est survenue",
  OTHER_FILE_TYPES: "Les autres types de fichiers ne sont pas pris en charge",
  YOUR_FILE_TYPES: "Le format de votre fichier est JPG, PNG ou PDF",
  HEALTH_PROGRAM: "Programme sant\xE9",
  REQUIRED: "Requis",
  REWARD_POINTS_MISSED: "Points de r\xE9compense manqu\xE9s",
  REWARD_POINTS_EARNED: "Points de r\xE9compense accumul\xE9s",
  REWARD_POINTS: "Points de r\xE9compense",
  ENDED_DAY_AT_TIME: "Termin\xE9 le {day}",
  DOWNLOAD_CLAIMS: "T\xE9l\xE9charger les demandes",
  VIEW_DEFINITION: "Voir la d\xE9finition",
  NETWORK_MISSING_COVERAGE_ADMIN: "Ce r\xE9gime n\u2019a pas de couverture {network}",
  COVERAGE_COSTS: "Couverture et co\xFBts",
  X_OF_Y_BENEFITS: "montrant {visibleAmount} de {totalAmount} des avantages",
  SIGN_UP_PERIOD_PASSED_DETAIL: "Vous ne pouvez plus vous inscrire \xE0 ce d\xE9fi car la date d'inscription est pass\xE9e.",
  ENDED_ON_DATE: "Termin\xE9 le {date}",
  SIGN_UP_PERIOD_PASSED: "Date d'inscription d\xE9pass\xE9e",
  AVAILABLE: "Disponible",
  COMBINATOR_SELECTOR_LABEL: "D\xE9finir la relation entre les conditions",
  VIEW_THE_ACTIVITIES: "Voir les activit\xE9s",
  SPONSOR_LOGO: "logo {name}",
  ENDED: "Termin\xE9",
  PROGRAM_CREATED_BY: "Programme cr\xE9\xE9 par",
  RECENT_CLAIMS_LIST_EMPTY: `Les demandes soumises au cours des 90 derniers jours s'affichent ici. Vous pouvez acc\xE9der \xE0 toutes les demandes en s\xE9lectionnant "Voir tout".`,
  PLAN_PROGRESS_POP_UP_LABEL: "Qu\u2019est-ce qu\u2019un {title}?",
  REMOVE_GROUP: "Supprimer le groupe",
  REMOVE_RULE: "Supprimer la r\xE8gle",
  PLAN_PROGRESS_COST_COVERAGE: "Progression et cotisation du r\xE9gime",
  TOOLBOX_VALIDATION_FIELD_CANNOT_BE_EMPTY: "Le champ ne peut pas \xEAtre vide lorsqu'il est s\xE9lectionn\xE9",
  TOOLBOX_VALIDATION_SELECT_OPTIONS_TO_CONTINUE: "S\xE9lectionnez {integer} options pour continuer",
  TOOLBOX_VALIDATION_SELECT_MAX_SELECTION: "S\xE9lectionnez un maximum de {integer} options",
  TOOLBOX_VALIDATION_ENTER_WHOLE_NUMBER: "Entrez un nombre entier",
  DISCLAIMER: "Avis de non-responsabilit\xE9",
  ALL_BENEFITS: "Tous les avantages",
  TOOLBOX_VALIDATION_SELECT_DATE_EARLIER: "S\xE9lectionnez une date ant\xE9rieure \xE0 {date, date, medium}",
  TOOLBOX_VALIDATION_SELECT_DATE_LATER: "S\xE9lectionnez une date post\xE9rieure \xE0 {date, date, medium}",
  TOOLBOX_VALIDATION_SELECTION_REQUIRED: "Veuillez faire une s\xE9lection",
  VIEW_MY_PROFILE: "Voir mon profil",
  VIEW_BENEFIT_LABEL_HIDDEN: "Afficher les d\xE9tails des avantages {name} de {vendor}",
  PENDING_BENEFIT_DESCRIPTION: "Cette prestation n\u2019est pas encore offerte au membre.",
  PAST_BENEFIT_DESCRIPTION: "Cette prestation n'est plus disponible pour le membre.",
  PENDING_BENEFIT: "Il s'agit d'une prestation en attente.",
  PAST_BENEFIT: "Il s'agit d'un avantage pass\xE9.",
  TOOLBOX_VALIDATION_REQUIRED_FIELD: "Ce champ est obligatoire",
  TOOLBOX_VALIDATION_NUMBER_MAXIMUM: "Entrez un nombre \xE9gal ou inf\xE9rieur \xE0 {value}",
  TOOLBOX_VALIDATION_NUMBER_MINIMUM: "Entrez un nombre \xE9gal ou sup\xE9rieur \xE0 {value}",
  TOOLBOX_VALIDATION_MAXIMUM_CHARACTERS: "La limite de caract\xE8res est {limit}",
  THREAD_SUBJECT_MAX_LENGTH_ERROR: "Vous avez d\xE9pass\xE9 la limite maximale de caract\xE8res ({maximum})",
  POLICY_INFORMATION: "Informations sur le contrat",
  ANSWER_SHOULD_BE_INTEGER: "La r\xE9ponse doit \xEAtre un nombre entier",
  CLAIMS_LIST_NO_CLAIMS_MATCH_FILTER_MESSAGE: "Aucune demande ne correspond au {filterCount, plural, one {filter} other {filters}} que vous avez choisi.",
  CLAIMS_LIST_NO_CLAIMS_MATCH_FILTER_HEADING: "Aucun r\xE9sultats",
  HISTORY_EMPTY_STATE_DESCRIPTION: "Lorsque vous terminez une activit\xE9, un programme ou un d\xE9fi, il appara\xEEt ici.",
  HISTORY_EMPTY_STATE_HEADER: "Il n'y a rien \xE0 voir ici!",
  TOOLBOX_VALIDATION_MONTH_YEAR: "Veuillez s\xE9lectionner un mois et une ann\xE9e",
  TOOLBOX_VALIDATION_DAY_MONTH_YEAR: "Veuillez s\xE9lectionner un jour, un mois et une ann\xE9e",
  COMPLETED_ON_DATE: "Compl\xE9t\xE9 le {date}",
  THIS_CHALLENGE_HAS_ENDED: "Ce d\xE9fi est termin\xE9",
  CLAIMS_SUMMARY_PAGE_SUB_HEADING: "V\xE9rifiez ce que votre assurance a pris en charge et ce que vous devez encore \xE0 votre prestataire. Vous pouvez acc\xE9der \xE0 vos demandes de remboursement soumises dans les deux ann\xE9es pr\xE9c\xE9dant la date d'aujourd'hui.",
  THIS_CHALLENGE_RUNS_YEAR_ROUND: "Ce d\xE9fi se d\xE9roule toute l'ann\xE9e",
  CLAIMS_GENERIC_ERROR_MESSAGE: "Il y a eu un probl\xE8me technique de notre c\xF4t\xE9.{br}Veuillez r\xE9essayer dans quelques minutes.",
  CLAIMS_GENERIC_ERROR_HEADING: "Une erreur est survenue",
  CLAIMS_LIST_EMPTY_MESSAGE: "Les demandes soumises s'afficheront ici.",
  CLAIMS_LIST_EMPTY_HEADING: "Rien ici pour l'instant!",
  CLAIMS_SERVICE_TYPE_SUFFIX_ORAL: "Demande de prestation soins dentaires",
  CLAIMS_SERVICE_TYPE_SUFFIX_INSTITUTIONAL: "Demande de prestation soins m\xE9dicaux",
  CLAIMS_FILTER_VALUE_ORAL: "Soins dentaires",
  CHECK_CHALLENGE_DETAILS: "Consultez les d\xE9tails du d\xE9fi pour vous familiariser avec les activit\xE9s",
  YOURE_GOOD_TO_GO: "Vous \xEAtes pr\xEAt \xE0 partir!",
  LEARN_MORE_ABOUT_PRIVACY_POLICY: "Pour en savoir plus sur la fa\xE7on dont League g\xE8re et utilise vos donn\xE9es, consultez notre <a>politique de confidentialit\xE9.</a>",
  SHARE_CHALLENGE_PROGRESS_WITH_EMPLOYER: "Partagez les progr\xE8s de votre d\xE9fi avec votre employeur",
  TRACK_MANUALLY_CONFIRM_CONNECT_LATER_DESCRIPTION: "Vous pouvez toujours connecter un appareil ult\xE9rieurement",
  TRACK_CHALLENGE_MANUALLY_CHANGE_LATER: "Si vous choisissez d'effectuer un suivi manuel, vous pourrez toujours connecter un appareil ult\xE9rieurement. {learnmorelink} sur le suivi manuel de votre progression.",
  FILTER_BY: "Filtrer par",
  CLAIMS_SERVICE_TYPE_SUFFIX_PHARMACY: "Demande de prestation pharmacie",
  CLAIMS_SERVICE_TYPE_SUFFIX_VISION: "Demande de prestation soins de la vue",
  CLAIMS_SERVICE_TYPE_SUFFIX_DENTAL: "Demande de prestation soins dentaires",
  CLAIMS_SERVICE_TYPE_SUFFIX_MEDICAL: "Demande de prestation soins m\xE9dicaux",
  YOUR_COST: "Votre co\xFBt :",
  VIEW_ALL_SOLO_CHALLENGES_DESCRIPTION: "Relevez le d\xE9fi en vous lan\xE7ant seul et en accomplissant des groupes d'activit\xE9s en solo.",
  VIEW_ALL_GROUP_CHALLENGES_DESCRIPTION: "Vous vous sentez motiv\xE9? Mettez-vous au d\xE9fi en vous tenant responsable avec ces offres.",
  VIEW_ALL_SOLO_CHALLENGES_HEADING: "D\xE9fis en solo",
  VIEW_ALL_GROUP_CHALLENGES_HEADING: "D\xE9fis de groupe",
  CHALLENGE_TYPE_SOLO_TAG: "Solo",
  CHALLENGE_TYPE_GROUP_TAG: "Groupe",
  APPROVED_ACTIVITIES_TOOLTIP: "Les activit\xE9s approuv\xE9es ont \xE9t\xE9 examin\xE9es et pr\xEAtes \xE0 \xEAtre publi\xE9es. Ils ne sont pas en direct et ne sont pas visibles pour les utilisateurs.",
  DRAFT_ACTIVITIES_TOOLTIP: "Les activit\xE9s qui sont \xE0 l\u2019\xE9tat de brouillon ne sont pas actives et ne sont pas visibles pour les utilisateurs.",
  PUBLISHED_ACTIVITIES_TOOLTIP: "Les activit\xE9s publi\xE9es sont en direct et visibles pour les utilisateurs.",
  APPROVED_PROGRAMS_TOOLTIP: "Les programmes approuv\xE9s ont \xE9t\xE9 examin\xE9s et pr\xEAts \xE0 \xEAtre publi\xE9s. Ils ne sont pas en direct et ne sont pas visibles pour les utilisateurs.",
  DRAFT_PROGRAMS_TOOLTIP: "Les programmes qui sont \xE0 l'\xE9tat de brouillon ne sont pas en ligne et ne sont pas visibles pour les utilisateurs.",
  PUBLISHED_PROGRAMS_TOOLTIP: "Les programmes qui sont publi\xE9s sont en direct et visibles pour les utilisateurs.",
  PUBLISHED: "Publi\xE9",
  CLEAR_ALL_FILTERS: "Effacer tous les filtres",
  CLAIMS_STATUS_DEFINITION_ADJUSTED: '"Ajust\xE9" signifie que quelque chose a chang\xE9 et que nous avons d\xFB r\xE9examiner cette demande.',
  CLAIMS_STATUS_DEFINITION_NOT_COVERED: `"Non couvert" signifie que le service n'est pas couvert par votre r\xE9gime d'avantages sociaux et que vous devrez le payer.`,
  CLAIMS_STATUS_DEFINITION_PARTIALLY_PAID: '"Partiellement couvert" signifie que nous couvrirons une partie de votre r\xE9clamation.',
  CLAIMS_STATUS_DEFINITION_PENDING: '"Traitement" signifie que nous sommes en train de d\xE9terminer qui doit quoi.',
  CLAIMS_STATUS_DEFINITION_APPROVED: '"Nous jouerons notre r\xF4le" signifie que la r\xE9clamation est approuv\xE9e et que nous acceptons de payer une partie ou la totalit\xE9 de la r\xE9clamation en fonction de votre r\xE9gime.',
  CLAIMS_STATUS_DEFINITIONS: "D\xE9finitions de l\u2019\xE9tat de la demande",
  UNDERSTANDING_HOW_YOUR_CLAIMS_WORK_TEXT: "<p>Lorsque vous consultez l'un des m\xE9decins de votre r\xE9seau, il demande \xE0 \xEAtre pay\xE9. Si vous devez payer une quote-part, il la per\xE7oit aupr\xE8s de vous et soumet ensuite une demande de remboursement \xE0 la compagnie d'assurance.</p><p>Nous examinons alors votre r\xE9gime d'assurance, \xE9galement appel\xE9 contrat de garanties, afin de d\xE9terminer si le service est couvert. Si c'est le cas, nous l'approuverons sur la base des termes du contrat de garanties. Si le service n'est pas couvert par votre r\xE9gime d'assurance, il vous incombe de le payer.</p>",
  UNDERSTANDING_HOW_YOUR_CLAIMS_WORK_TITLE: "Comprendre le fonctionnement de vos r\xE9clamations",
  X_PERCENT_COMPLETE: "{number}% termin\xE9",
  CHALLENGE_PROGRESS: "Progression du d\xE9fi",
  SOLO_CHALLENGE: "D\xE9fi solo",
  GROUP_CHALLENGE: "D\xE9fi de groupe",
  ACTIVITY_INPUT_FREE_TEXT: "Entrez votre propre valeur",
  ADJUSTED: "Ajust\xE9",
  CLAIMS_BENEFIT_CARD_SUBTITLE: "Suivez les sommes que vous devez payer pour vos soins et le montant que l'assurance couvrira.",
  CLAIMS_BENEFIT_CARD_TITLE: "R\xE9clamations d\u2019assurance",
  PERIOD: "P\xE9riode",
  HOW_CLAIMS_WORK: "Comment fonctionnent les r\xE9clamations",
  HELPFUL_LINKS: "Liens utiles",
  CLAIMS_FILTER_VALUE_LAST_2_YEARS: "2 derni\xE8res ann\xE9es",
  CLAIMS_FILTER_VALUE_LAST_12_MONTHS: "12 derniers mois",
  CLAIMS_FILTER_VALUE_PRIOR_YEAR: "Ann\xE9e pr\xE9c\xE9dente",
  CLAIMS_FILTER_VALUE_YEAR_TO_DATE: "Depuis le d\xE9but de l'ann\xE9e",
  CLAIMS_FILTER_PROPERTY_SERVICEDATE: "Fourchette de dates",
  FME_SHARING_PERMISSIONS: "Autorisations de partage",
  STOP_SHARING_WITH_MEMBERS: "Arr\xEAter de partager avec tous les membres",
  SELECT_MEMBERS_TO_ENABLE_SHARING_PERMISSIONS: "S\xE9lectionnez les membres pour activer les autorisations de partage",
  ENABLE_SHARING_PERMISSIONS: "Pour discuter et g\xE9rer vos soins avec d'autres personnes, vous devez autoriser le partage d'informations.",
  CLAIMS_FILTER_VALUE_ADJUSTED: "Ajust\xE9",
  CLAIMS_FILTER_VALUE_NOT_COVERED: "Non couvert",
  CLAIMS_FILTER_VALUE_PARTIALLY_PAID: "Partiellement couvert",
  CLAIMS_FILTER_VALUE_PENDING: "Traitement",
  CLAIMS_FILTER_VALUE_APPROVED: "Nous jouerons notre r\xF4le",
  CLAIMS_FILTER_VALUE_PROFESSIONAL: "Professionnel",
  CLAIMS_FILTER_VALUE_INSTITUTIONAL: "Institutionnel",
  CLAIMS_FILTER_VALUE_PHARMACY: "Pharmacie",
  CLAIMS_FILTER_VALUE_VISION: "Vue",
  CLAIMS_FILTER_VALUE_MEDICAL: "M\xE9dical",
  CLAIMS_FILTER_VALUE_DENTAL: "Soins dentaires",
  CLAIMS_FILTER_PROPERTY_PATIENTNAME: "Membre",
  CLAIMS_FILTER_PROPERTY_STATUS: "Statut de la demande",
  CLAIMS_FILTER_PROPERTY_TYPE: "Type de service",
  CLAIM_TABLE_CAPTION: "Historique de la demande",
  DRINKS_PER_WEEK: "Boissons par semaine",
  OR_SELECT_BETWEEN_THESE: "ou s\xE9lectionnez l'une des options suivantes",
  INVITATION_WILL_EXPIRE: "Toute adresse courriel enregistr\xE9 sera enregistr\xE9e dans le dossier de ce membre. Cette invitation expirera apr\xE8s 14 jours.",
  SEE_REWARD_PROGRAM: "Voir le programme de r\xE9compense",
  REWARD_PROGRAM: "Programme de r\xE9compense",
  SESSION_DURATION: "Dur\xE9e de la session",
  LAST_ACCESSED: "Dernier acc\xE8s",
  ANDROID: "Android",
  IOS: "iOS",
  LATEST_APP_VERSION: "Derni\xE8re version de l'application",
  APP_VERSION: "Version de l'application",
  ONCE_MEMBER_REGISTERED: "Une fois que ce membre s'est inscrit, il pourra :",
  INVITE_MEMBER: "Inviter un membre",
  FAQ: "FAQ",
  MULTISELECT_HINT: "Utilisez les touches fl\xE9ch\xE9es et l'effacement arri\xE8re pour effacer une s\xE9lection.",
  DISMISS_TOAST: "Ignorer la notification",
  INVITE_MEMBER_SUCCESS_MSG_DESC: "Une invitation sera envoy\xE9e sous peu au courriel de {invitedMember}.",
  INVITE_FAMILY_MEMBERS_BANNER_TEXT_NON_PAO: "Pour inviter les membres \xE0 rejoindre Care Advisor Connect, contactez le propri\xE9taire principal du compte indiqu\xE9 ci-dessous.",
  INVITE_FAMILY_MEMBERS_BANNER_TEXT_PAO: "Invitez les membres de votre famille \xE0 se joindre \xE0 l'exp\xE9rience pour parcourir ensemble votre parcours sant\xE9.",
  FME_PERSONAL_CARE_TEAM: "\xC9quipe de soins personnels",
  COMPLETED_TODAY: "Compl\xE9t\xE9",
  REMOVED: "Supprim\xE9",
  UPCOMING: "\xC0 venir",
  UPDATE_ROI: "Mettre \xE0 jour la publication d'informations",
  SIGN_ROI_SUCCESS_MSG_DESC: "Param\xE8tres > Autorisations de partage",
  SIGN_ROI_SUCCESS_MSG_TITLE: "Votre autorisation d'acc\xE8s aux renseignements a \xE9t\xE9 sign\xE9e avec succ\xE8s. Vous pouvez modifier ces autorisations \xE0 tout moment dans",
  SIGN_ROI: "Signer la d\xE9charge d'information",
  ROI_PAGE_HEADING: "Autorisation de divulgation de renseignements",
  VIEW_ALL_STANDALONE_ACTIVITIES: "Voir toutes les activit\xE9s autonomes",
  DATE_SIGNED: "Date de signature",
  AUTHORIZED_BY: "Autoris\xE9 par",
  DOCUMENT_THUMBNAIL_MODAL: "Document joint modal",
  SCROLL_DOWN_TO_SIGN: "Faites d\xE9filer la page pour signer",
  SKIP_TO_SIGN_FORM: "Passer au formulaire de signature",
  SIGNATURE_PANEL_INPUT_PLACEHOLDER: "Nom complet (tel qu'il appara\xEEt ci-dessus)",
  SIGNATURE_PANEL_HINT_TEXT: "Veuillez entrer votre nom complet tel qu\u2019il appara\xEEt ci-dessus pour autoriser la divulgation de vos renseignements m\xE9dicaux",
  SIGNATURE: "Signature",
  INVALID_SIGNATURE_ERROR_MSG: "D\xE9sol\xE9, votre signature n'est pas valide. Veuillez saisir votre nom tel qu'il appara\xEEt dans le formulaire.",
  FAMILY_BALANCE: "Famille {balance}",
  INDIVIDUAL_BALANCE: "{balance} de {membersFullName}",
  SEE_MORE_MISSED_ACTIVITIES_ARIA_LABEL: "Voir plus d\u2019activit\xE9s manqu\xE9es",
  SEE_MORE_COMPLETED_ACTIVITIES_ARIA_LABEL: "Voir plus d\u2019activit\xE9s termin\xE9es",
  SEE_MORE_ACTIVITIES: "Voir plus",
  GENERIC_TOOLTIP_NAME: "info-bulle {name}",
  ACHIEVEMENTS_EMPTY_STATE_DESCRIPTION: "Terminez les activit\xE9s de votre parcours pour commencer \xE0 accumuler des r\xE9alisations.",
  ACHIEVEMENTS_EMPTY_STATE_HEADER: "Pas de r\xE9alisations (pour l'instant!)",
  MULTIPLE_ACHIEVEMENT_DESCRIPTION: "Nous aimons vous voir prioriser votre sant\xE9 et votre bien-\xEAtre! D\xE9couvrez les autres r\xE9alisations que vous avez obtenues et celles vers lesquelles vous travaillez.",
  SINGLE_ACHIEVEMENT_DESCRIPTION: "Nous aimons vous voir prioriser votre sant\xE9 et votre bien-\xEAtre! D\xE9couvrez les autres r\xE9alisations vers lesquelles vous travaillez.",
  ACHIEVEMENTS_BANNER_HEADER: "F\xE9licitations pour l'obtention de ces badges!",
  RECENT_ACHIEVEMENTS: "R\xE9alisations r\xE9centes",
  BROWSER_APP_INFO: "Informations sur le navigateur ou l\u2019application",
  SESSION_TIME: "Dur\xE9e de la session (minutes/secondes)",
  LOG_IN_HISTORY: "Historique de connexion",
  MEMBER_DEVICE_OS: "Appareil ou syst\xE8me d\u2019exploitation",
  RECENT_SESSIONS: "Sessions r\xE9centes",
  HELP: "Aide",
  X_POINTS: "{points, plural, one {# token } other {# tokens }}",
  FILTER_WALLET_CLAIMS: "Choisissez une p\xE9riode contractuelle pour rechercher vos demandes de remboursement du portefeuille.",
  CURRENT: "Actuel",
  CAPABILITY: "Capacit\xE9",
  YOUR_SALARY: "Votre salaire",
  UNAVAILABLE_ACTION: "{name} (pas encore disponible)",
  STANDALONE_ACTIVITIES_EMPTY_STATE: "Il n'y a rien \xE0 faire ici pour l'instant ! Revenez plus tard.",
  PARTIALLY_PAID: "Pay\xE9 en partie",
  DOLLAR_AMOUNT: "{amount} $",
  CLAIM_PATIENT_NAME: "Pour\xA0: {patientName}",
  MAY_OWE_AMOUNT: "Vous devez peut-\xEAtre rembourser :",
  OWED_AMOUNT: "Vous devez :",
  FEEDBACK_LINK_LABEL: "\xC9valuez l'information {value} sur {maximum} en termes d'utilit\xE9",
  URGENT_BANNER_ICON_ALT_TEXT: "Alerte",
  STANDALONE_ACTIVITIES_DESCRIPTION: "Aucun programme. Aucun d\xE9fi. Juste des activit\xE9s de temps en temps.",
  VIEW_ALL_MISSED_ACTIVITIES_ARIA_LABEL: "Afficher toutes les activit\xE9s manqu\xE9es",
  VIEW_ALL_COMPLETED_ACTIVITIES_ARIA_LABEL: "Afficher toutes les activit\xE9s termin\xE9es",
  EXPLORE: "Parcourir",
  DOCUMENT_NAME_TOO_LONG: "Vous avez d\xE9pass\xE9 la limite maximale de caract\xE8res ({maximum})",
  CLAIMS_SUMMARY: "R\xE9capitulatif des demandes",
  STANDALONE_ACTIVITIES: "Activit\xE9s autonomes",
  DISPLAYING_ACTIVITIES_FROM_DATE: "Affichage des activit\xE9s depuis {date}",
  DELETE_ACCOUNT_FAILED: "\xC9chec de la suppression du compte",
  SOLUTIONS: "Solutions",
  X_PERCENTAGE: "{percent}%",
  X_OF_Y_ACTIVITIES: "{completed} sur {total} activit\xE9s",
  NEXT_ACTIVITY: "Activit\xE9 suivante",
  VIEW_DETAILS: "Consulter les d\xE9tails",
  TOKENS_MISSED: "{points} jetons manqu\xE9s",
  TOKENS_EARNED: "{points} jetons gagn\xE9s",
  WARNING: "Avertissement",
  HEALTH_JOURNEY_PAGE: "Page du Parcours Sant\xE9",
  YOUVE_GOT_THIS_NAME: "Vous pouvez le faire, {firstName}!",
  YOUVE_GOT_THIS: "Vous pouvez le faire!",
  PERSONAL_CARE_TEAM_EMPTY_STATE_DESC: "Vous n'avez actuellement aucun membre de votre famille ajout\xE9.",
  SHARING_PERMISSIONS_EMPTY_STATE_SUB_DESC: "Contactez votre repr\xE9sentant pour commencer.",
  SHARING_PERMISSIONS_EMPTY_STATE_DESC: "Vous devez ajouter des membres de votre famille \xE0 votre \xE9quipe de soins avant de pouvoir g\xE9rer leurs autorisations de partage.",
  NO_PERSONAL_CARE_TEAM_MEMBERS: "Aucun membre de l'\xE9quipe de soins pour le moment",
  MISSED_ACTIVITIES_EMPTY_STATE: "Si vous manquez des activit\xE9s, elles appara\xEEtront ici !",
  MISSED_ACTIVITIES: "Activit\xE9s manqu\xE9es",
  COMPLETED_ACTIVITIES_EMPTY_STATE: "Lorsque vous r\xE9alisez des activit\xE9s, elles apparaissent ici !",
  COMPLETED_ACTIVITIES: "Activit\xE9s termin\xE9es",
  EXPLORE_LIBRARY: "Parcourir la biblioth\xE8que",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_DESCRIPTION: "Parcourez notre biblioth\xE8que pour trouver les programmes et d\xE9fis de sant\xE9 qui correspondent \xE0 vos objectifs personnels.",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_HEADING: "Pr\xEAt \xE0 commencer?",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_EYEBROW: "Programmes et d\xE9fis",
  X_TOKENS: "{tokenCount, plural, one {# jeton} other {# jetons}}",
  YOU_PAY_ANNUALLY_TOOLTIP_ALTTEXT: "Vous payez annuellement tool-tip",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP_ALTTEXT: "Votre retenue sur la paie tool-tip",
  MEMBER_ROLE_POLICY: "Membre contractuel",
  MEMBER_ROLE_NON_POLICY: "Membre non-contractuel",
  MEMBER_ROLE_PAO: "Titulaire principal du compte",
  REVOKE_PERMISSIONS_MODAL_CONFIRM_CTA: "Arr\xEAter le partage",
  REVOKE_PERMISSIONS_MODAL_DESC: "Vous ne pourrez plus partager de documents ou discuter de vos soins avec cette ou ces personnes.",
  REVOKE_PERMISSIONS_MODAL_TITLE: "\xCAtes-vous s\xFBr de vouloir supprimer les droits de partage avec tous les membres?",
  WITH_DOCUMENT: "avec document",
  BACK_TO_FAMILY_PAGE: "Retour \xE0 la page famille",
  EXPIRED_ROI_BANNER_WITH_DATE: "Votre d\xE9charge d'informations a expir\xE9 le {expiryDate}.",
  EXPIRED_ROI_BANNER: "Votre d\xE9charge d'informations a expir\xE9.",
  REMOVE_FILE_FILENAME: "Supprimer le fichier : {filename}",
  START_X_MORE_PROGRAMS_PLURALIZED: "Commencer {duration, plural, one {# programme de plus} other {# plus de programmes}}. Continuez votre lanc\xE9e.",
  CONTINUE_WITH_HSID: "Continuer avec l'ID HealthSafe",
  UNREAD_MESSAGES: "Messages non lus",
  METRICS: "Mesures",
  UNREGISTERED: "Non enregistr\xE9",
  ASSIGN_NEW_ACTIVITY: "Attribuer une nouvelle activit\xE9",
  TOGGLE_MENU: "Afficher la liste des messages",
  VIEW_ALL_MESSAGE_THREADS: "Voir tous les messages",
  LINK_OUT_MESSAGE: "\xC0 voir",
  THIS_HELPS_IDENTIFY_PERSON_DOCUMENT_RELATES_TO: "Cela aide votre conseiller en soins \xE0 identifier la personne \xE0 laquelle se rapporte ce document.",
  SELECT_DOCUMENT: "S\xE9lectionner le document",
  SELECT_A_USER: "S\xE9lectionnez un utilisateur",
  WHO_IS_THIS_DOCUMENT_ABOUT: "\xC0 qui s'adresse le document?",
  SEE_MORE: "EN SAVOIR PLUS",
  UNABLE_TO_LOAD_PAGE_TRY_AGAIN: "Nous n'arrivons pas \xE0 charger cette page. V\xE9rifiez votre Internet et r\xE9essayez.",
  CHARGE_SUBSCRIPTION_FEES: "Facturer les frais d'abonnement",
  SUBSCRIPTION_FEES_FOR_PER_BENEFIT_CLASS: "Frais de souscription pour chaque cat\xE9gorie d'avantages",
  SUBSCRIPTION_RATE: "Tarif de souscription (par employ\xE9/mois)",
  SUBSCRIPTION_FEES_FOR_INVITED_USER: "Frais de souscription pour les utilisateurs invit\xE9s",
  SAVED_SUBSCRIPTION_FEES: "Frais de souscription sauvegard\xE9!",
  SAVE_SUBSCRIPTION_FEES: "Sauvegarder les frais",
  SUBSCRIPTION_FEES: "Frais d'abonnement",
  ERROR_CAPTION_NO_CONNECTION: "Un probl\xE8me est survenu lors du chargement de vos donn\xE9es. \n Veuillez v\xE9rifier votre Internet et r\xE9essayer.",
  ERROR_CAPTION_BACKEND: "Un probl\xE8me est survenu lors de la connexion \xE0 notre serveur. \n Veuillez r\xE9essayer plus tard.",
  ERROR_HEADING_NO_CONNECTION: "Aucune connexion Internet",
  ERROR_HEADING_GENERIC: "Une erreur est survenue",
  ABOUT_ME: "\xC0 propos de moi",
  ERROR_DELETING_YOUR_DOCUMENT: "Erreur lors de la suppression de votre document",
  DOCUMENT_DELETED: "Document supprim\xE9",
  ADD_A_LINK_NAME: "Ajouter le nom du lien",
  ACTIVITIES_PLURALIZATION: "{count, plural, one {Activit\xE9} other {Activit\xE9s}}",
  DELETE_DOCUMENT: "Supprimer le document",
  CANNOT_BE_UNDONE: "Cette action est irr\xE9versible",
  SURE_YOU_WANT_TO_DELETE: "\xCAtes-vous certain de vouloir supprimer {name}",
  DISMISSED_BY_MEMBER_DATE: "Rejet\xE9 par {member} {date}",
  DISMISSED: "Rejet\xE9",
  ALL_DOCUMENTS: "Tous les documents",
  DOCUMENT_RENAMED_SUCCESSFULLY: "Document renomm\xE9 avec succ\xE8s.",
  ERROR_RENAMING_YOUR_DOCUMENT: "Erreur de renommage de votre document. Essayez \xE0 nouveau.",
  SPENDING_ACCOUNTS_INSIGHTS: "Aper\xE7u des comptes de d\xE9penses",
  INSIGHT_CARD_UNIT_FLOORS_CLIMBED_OLD: "\xE9tages",
  INSIGHT_CARD_UNIT_STEPS_OLD: "pas",
  KM: "km",
  CAL: "Cal",
  KJ: "KJ",
  MIN: "min",
  MILES: "{count, plural, one {mile} other {miles}}",
  YOUR_DOCUMENT_WILL_BE_AVAILABLE_SOON: "Votre document sera bient\xF4t disponible. Veuillez patienter quelques instants.",
  DOCUMENT_SUBMITTED: "Document soumis",
  ERROR_UPLOADING_YOUR_FILE: "Erreur de chargement de votre fichier. Essayez \xE0 nouveau.",
  COMPLETED_BY_MEMBER_DATE: "Rempli par {member} {date}",
  ASSIGNED_TO_MEMBER_DATE: "Attribu\xE9 \xE0 {member} {date}",
  VISIT_LINK: "Visitez le lien",
  QUESTION_PROGRESS: "QUESTION {questionNumber} DE {totalQuestions}",
  AMOUNT_QUESTIONS: "{amount} questions",
  VIEW_DOCUMENT_ACTIONS: "Afficher les actions {documentName}.",
  VALIDATION_ERROR_MSG: "Veuillez choisir la date d'expiration apr\xE8s la date d'entr\xE9e en vigueur.",
  SAVED_CREDITS: "Les cr\xE9dits ont \xE9t\xE9 enregistr\xE9s!",
  SET_CREDITS: "D\xE9finir les cr\xE9dits",
  CREDITS_EXPIRY_DATE: "Date d'expiration",
  CERTIFICATION_TYPE: "Type de certification",
  CERTIFICATION_CREDITS: "Cr\xE9dits",
  INVOICES_TOOLTIP: "Cliquez ici pour afficher les donn\xE9es de facturation par utilisateur",
  VIEW_OR_DOWNLOAD: "Afficher / T\xE9l\xE9charger",
  PREVIEW_OF_FILE: "Aper\xE7u de {fileName}",
  SELECT_PARTICIPANT_ERROR: "S\xE9lectionnez une personne \xE0 qui envoyer un message",
  TRANSACTION_TABLE_CAPTION: "Historique des transactions",
  MESSAGES_PAGINATION_ERROR: "Impossible de charger les messages. <button>Essayez \xE0 nouveau.</button>",
  MAXIMUM_FILE_SIZE_IS: "La taille de fichier maximale est {fileSize}\xA0MB.",
  FILE_TYPE_TOO_LARGE: "La taille du fichier est trop grande",
  MODIFIED_ON_DATE: "Modifi\xE9 le {date}",
  ASSIGNING_ACTIVITY_ERROR_MESSAGE_TOAST: "Retournez \xE0 la page du formulaire d'\xE9dition d'activit\xE9 pour corriger les erreurs et r\xE9essayez.",
  ERROR_ASSIGNING_ACTIVITY: "Erreur d'attribution d'activit\xE9.",
  ERROR_SAVING_TRY_AGAIN: "Erreur de sauvegarde des modifications. Veuillez r\xE9essayer",
  SAVED_NETSUITE_ID: "L'ID NetSuite a \xE9t\xE9 enregistr\xE9!",
  SAVE_NETSUITE_ID: "Enregistrer l'ID NetSuite",
  NETSUITE_ID: "ID NetSuite",
  NETSUITE: "NetSuite",
  USER_ADDED_DOCUMENT: "{user} a ajout\xE9 un document",
  START_A_NEW_MESSAGE: "Commencer un nouveau message",
  NO_MESSAGES_DETAIL: "Votre conseiller en soins vous aidera \xE0 prendre des d\xE9cisions plus avis\xE9es en mati\xE8re de soins de sant\xE9, \xE0 chaque \xE9tape du parcours.",
  NO_MESSAGES_YET: "Vous n'avez aucun message (pour le moment!)",
  RECENT_MESSAGES: "Messages r\xE9cents",
  MARK_AS_DONE_ACTIVITY_SUCCESS_TOAST: "L'activit\xE9 a \xE9t\xE9 marqu\xE9e comme compl\xE9t\xE9e pour {memberName}.",
  UNASSIGN_ACTIVITY_SUCCESS_TOAST: "L'activit\xE9 n'a pas \xE9t\xE9 attribu\xE9e \xE0 {memberName}.",
  MARK_AS_DONE_ERROR_TOAST: "Erreur : l'activit\xE9 n'a pas \xE9t\xE9 marqu\xE9e comme compl\xE9t\xE9e",
  ACTIVITY_ERROR_MESSAGE_TOAST: "Rafra\xEEchissez la page et r\xE9essayez.",
  UNASSIGN_ACTIVITY_ERROR_TOAST: "Erreur\xA0: l'activit\xE9 n'a pas \xE9t\xE9 d\xE9sattribu\xE9e",
  USE_FILE_IN_ONE_OF_THESE_FORMATS: "Utilisez un fichier dans l'un de ces formats : {fileTypes}",
  FILE_TYPE_NOT_SUPPORTED: "Ce type de fichier n'est pas pris en charge",
  ADD_A_LINK: "Ajouter un lien valide",
  ERROR_MESSAGE_ASSIGN_MODAL: "Essayez de recharger la page.",
  ERROR_HEADING_ASSIGN_MODAL: "Quelque chose a mal tourn\xE9.",
  ERROR_MESSAGE_SALESFORCE: "Essayez de recharger la page. Si cela ne fonctionne pas, quittez Salesforce et reconnectez-vous.",
  ERROR_HEADING: "Quelque chose a mal tourn\xE9 de notre c\xF4t\xE9.",
  UNABLE_TO_SEND: "Impossible d'envoyer, veuillez r\xE9essayer",
  REFRESH_PAGE_TRY_AGAIN: "Rafra\xEEchissez la page et r\xE9essayez",
  ERROR_ASSIGNING_ACTIVITY_TO_NAME: "Erreur lors de l'attribution de l'activit\xE9 \xE0 {name}",
  ACTIVITY_ASSIGNED_TO_NAME: "Attribution de l'activit\xE9 r\xE9ussie \xE0 {name}",
  APP_AND_DEVICE_METRICS: "Mesures de l\u2019appli et de l\u2019appareil",
  REMOVE_FILE: "Supprimer le fichier",
  ADD_A_DOCUMENT: "Ajouter un document",
  DRAG_FILES_HERE_OR: "Faites glisser les fichiers ici ou",
  SELECT_A_MEMBER: "S\xE9lectionnez un membre",
  ASSIGNED_TO: "Affect\xE9 \xE0",
  LINK_URL: "Lien URL",
  LINK_NAME: "Nom du lien",
  DESCRIBE_THE_ACTIVITY: "D\xE9crire l'activit\xE9",
  CONTENT: "Contenu",
  NAME_THE_ACTIVITY: "Nommez l'activit\xE9",
  ACTIVITY_NAME: "Nom de l'activit\xE9",
  SELECT_A_CATEGORY: "S\xE9lectionner une cat\xE9gorie",
  CATEGORY: "Cat\xE9gorie",
  SELECT_AN_IMAGE: "S\xE9lectionnez une image",
  LAST_30_DAYS: "30 derniers jours",
  LAST_7_DAYS: "7 derniers jours",
  GENERIC_MESSAGING_ERROR: "Il semble qu'il y ait eu un probl\xE8me, veuillez rafra\xEEchir la page et r\xE9essayer.",
  POINTS_MISSED: "{points} points manqu\xE9s",
  POINTS_EARNED: "{points} points gagn\xE9s",
  ACTIVITY_EXPIRY_DATE: "Expire le {date}",
  HIGH_PRIORITY_ACTIVITY_ALT_TEXT: "Il s'agit d'une activit\xE9 de haute priorit\xE9",
  MEDIUM_PRIORITY_ACTIVITY_ALT_TEXT: "Il s'agit d'une activit\xE9 de priorit\xE9 moyenne",
  LOW_PRIORITY_ACTIVITY_ALT_TEXT: "Il s'agit d'une activit\xE9 peu prioritaire",
  BACK_TO_INVOICES: "Retour aux factures",
  BILLINGS_INSIGHTS_MESSAGE: "Ce tableau contient les donn\xE9es de facturation compl\xE8tes \xE0 partir de janvier 2023 uniquement. Pour le cycle de facturation pr\xE9c\xE9dent, veuillez retourner aux factures.",
  INVOICE_DATA: "Donn\xE9es de facturation",
  EMPTY_COMPLETED_ACTIVITIES: "Au fur et \xE0 mesure que le membre accomplit des activit\xE9s, celles-ci apparaissent ici.",
  EMPTY_ASSIGNED_ACTIVITIES: "Les activit\xE9s que vous attribuez \xE0 ce membre appara\xEEtront ici.",
  COMPLETED_ASSIGNED_ACTIVITY_TABLE_HEADING: "{memberFirstName} a compl\xE9t\xE9 {activityCount} {amount, plural, one {activit\xE9} other {activit\xE9s}}.",
  ACTIVITY: "Activit\xE9",
  ENTER_DOCUMENT_NAME: "Veuillez ajouter un nom pour ce document",
  RENAME: "Renommer",
  VIEW_ALL_METRICS: "Afficher les mesures des applis et des appareils",
  APPS_AND_DEVICES: "Applis et appareils",
  YOUR_METRICS_SUBTITLE: "Les donn\xE9es de sant\xE9 les plus r\xE9centes de vos applis et appareils connect\xE9s.",
  YOUR_METRICS: "Mes mesures",
  AVERAGE_TOKENS: "Moyenne des jetons",
  PROGRAM_QUIT_DATE: "Date de d\xE9part",
  CARE_TEAM_MEMBER: "Membres de l'\xE9quipe de soins",
  MEMBERS_SORTED_BY_ACTIVITIES: "Membres tri\xE9s par activit\xE9s r\xE9centes",
  NO_ONE_HERE: "Il n'y a personne ici.",
  NO_MEMBERS_MESSAGE: "Vous n'avez actuellement aucun membre de la famille ou soignant ajout\xE9",
  ACTIVITY_ASSIGNMENT: "Attribution des activit\xE9s",
  ASSIGNED: "Attribu\xE9",
  MESSAGES_IN_CONVERSATION_WITH: "Messages dans la conversation avec {participants}",
  MESSAGES_IN_CONVERSATION_TITLED: "Messages dans la conversation intitul\xE9e {subject}",
  YOU: "Vous",
  USER_COLON: "{user} :",
  ADD_DOCUMENT: "Ajouter un document",
  OPEN_DOCUMENT_ACTIONS_DROPDOWN: "Ouvrir le menu des actions du document : {title}",
  RETRY: "R\xE9essayer",
  TROUBLE_DISPLAYING_DATA: "Nous avons des difficult\xE9s \xE0 afficher vos donn\xE9es",
  YOU_ARE_PREVIEWING_THE_ACTIVITY: "Vous avez un aper\xE7u de l'activit\xE9",
  LINK: "Lien",
  RETURN: "Retour",
  UNASSIGN: "D\xE9sattribuer",
  CREATE_NEW_ACTIVITY: "Cr\xE9er une nouvelle activit\xE9",
  ALL_MESSAGES_FOR_THREAD_BANNER: "Veuillez ne pas nous envoyer d'informations sp\xE9cifiques sur votre \xE9tat de sant\xE9 par le biais de ce clavardage.",
  MESSAGE_THREADS_LIST_SUBTITLE: "La r\xE9ponse de notre \xE9quipe peut prendre jusqu'\xE0 2 jours ouvrables. S'il s'agit d'une urgence m\xE9dicale, appelez le 911.",
  KPMG_RE_ENROLL_HELP_TEXT: "Si vous ne choisissez pas \xE0 nouveau une couverture pr\xE9c\xE9demment approuv\xE9e, elle ne sera pas report\xE9e dans la nouvelle ann\xE9e du r\xE9gime.",
  CAROUSEL_DIGITAL_WALLET_DESCRIPTION: "Acc\xE9dez \xE0 tous vos avantages sociaux et \xE0 vos comptes de d\xE9penses, tout en un seul endroit.",
  CAROUSEL_DIGITAL_WALLET_HEADING: "Portefeuille num\xE9rique",
  CAROUSEL_JOURNEY_DESCRIPTION: "Atteignez vos objectifs gr\xE2ce \xE0 un parcours sant\xE9 personnalis\xE9.",
  CAROUSEL_JOURNEY_HEADING: "Parcours",
  CAROUSEL_ALL_IN_ONE_DESCRIPTION: "Gardez facilement le contr\xF4le de votre sant\xE9, bien-\xEAtre et avantages.",
  CAROUSEL_ALL_IN_ONE_HEADING: "Appli tout-en-un",
  PREVIOUSLY_ASSIGNED_ACTIVITIES: "Activit\xE9s pr\xE9c\xE9demment attribu\xE9es",
  POLICY_INFORMATION_UNAVAILABLE_DEPENDENTS: "Il n'y a aucune information pour ce membre. Veuillez essayer un autre membre.",
  POLICY_INFORMATION_UNAVAILABLE: "Il n'y a pas d'information disponible pour cette p\xE9riode contractuelle.",
  INSIGHT_CHART_MINDFUL_DURATION_DATUM_LABEL: "Vous avez b\xE9n\xE9fici\xE9 de {value} minutes de pleine conscience le {date}",
  INSIGHT_CHART_MINDFUL_DURATION_MONTH_DESCRIPTION: "Le nombre de minutes de pleine conscience chaque jour du mois.",
  INSIGHT_CHART_MINDFUL_DURATION_WEEK_DESCRIPTION: "Le nombre de minutes de pleine conscience pour chaque jour de la semaine.",
  INSIGHT_CHART_FLOORS_CLIMBED_DATUM_LABEL: "Vous avez mont\xE9 {value} \xE9tages le {date}",
  INSIGHT_CHART_FLOORS_CLIMBED_MONTH_DESCRIPTION: "Le nombre d'\xE9tages que vous avez mont\xE9s chaque jour du mois.",
  INSIGHT_CHART_FLOORS_CLIMBED_WEEK_DESCRIPTION: "Le nombre d'\xE9tages que vous avez mont\xE9s chaque jour de la semaine.",
  INSIGHT_CHART_ACTIVE_DURATION_DATUM_LABEL: "Vous avez b\xE9n\xE9fici\xE9 de {value} minutes d'activit\xE9s le {date}",
  INSIGHT_CHART_ACTIVE_DURATION_MONTH_DESCRIPTION: "Le nombre de minutes d'activit\xE9s chaque jour du mois.",
  INSIGHT_CHART_ACTIVE_DURATION_WEEK_DESCRIPTION: "Le nombre de minutes d'activit\xE9s pour chaque jour de la semaine.",
  INSIGHT_CHART_ENERGY_BURNED_DATUM_LABEL: "Vous avez br\xFBl\xE9 {energy} {unit} le {date}",
  INSIGHT_CHART_ENERGY_BURNED_MONTH_DESCRIPTION: "Le nombre de {unit} que vous avez br\xFBl\xE9 chaque jour du mois.",
  INSIGHT_CHART_ENERGY_BURNED_WEEK_DESCRIPTION: "Le nombre de {unit} que vous avez br\xFBl\xE9 chaque jour de la semaine.",
  INSIGHT_CHART_STEPS_DATUM_LABEL: "Vous avez march\xE9 {distance} {unit} le {date}",
  INSIGHT_CHART_STEPS_MONTH_DESCRIPTION: "Le nombre de pas que vous avez march\xE9 chaque jour du mois.",
  INSIGHT_CHART_STEPS_WEEK_DESCRIPTION: "Le nombre de pas que vous avez march\xE9 chaque jour de la semaine.",
  INSIGHT_CHART_MONTH_TITLE: "Graphique \xE0 barres {type} mensuel",
  INSIGHT_CHART_WEEK_TITLE: "Graphique \xE0 barres hebdomadaire {type}",
  INSIGHT_CARD_HEADER_MINDFUL_DURATION: "Minutes de pleine conscience",
  CARE_ADVISOR: "Conseiller en soins",
  CHECK_FAQ: "Consultez notre FAQ.",
  BACK_TO_SIGN_IN: "Retour \xE0 la page d'identification",
  FAMILY_CAP: "Famille",
  ASSIGN_ACTIVITY: "Attribuer l'activit\xE9",
  ASSIGN_AN_ACTIVITY: "Attribuer une activit\xE9",
  COMPOSE: "Composer",
  YOUR_CARE_ADVISOR: "Votre conseiller en soins",
  VIEW_DATA_SOURCES: "Afficher les sources de donn\xE9es",
  INSIGHT_CHART_DISTANCE_DATUM_LABEL: "Vous avez march\xE9 {distance} {unit} le {date}",
  INSIGHT_CHART_DISTANCE_MONTH_DESCRIPTION: "Le nombre de {unit} que vous avez march\xE9 chaque jour du mois.",
  INSIGHT_CHART_DISTANCE_MONTH_TITLE: "Diagramme \xE0 barres des distances mensuelles",
  INSIGHT_CHART_DISTANCE_WEEK_DESCRIPTION: "Le nombre de {unit} que vous avez march\xE9 chaque jour de la semaine.",
  INSIGHT_CHART_DISTANCE_WEEK_TITLE: "Graphique \xE0 barres de distance hebdomadaire",
  DAY_OF_THE_MONTH: "JOUR DU MOIS",
  DAY_OF_THE_WEEK: "JOUR DE LA SEMAINE",
  LEADERBOARD_CURRENT_TEAM_NAME: "{team} (Votre \xE9quipe)",
  NO_DOCUMENTS_DESCRIPTION: "Vous pouvez ajouter des documents \xE0 partager avec votre conseiller en soins et les organiser au m\xEAme endroit.",
  NO_DOCUMENTS: "Aucun document pour le moment",
  OR_CALL_PHONE: "ou appelez le {phoneNumber}",
  LEAVE_TEAM_FAILURE: "Une erreur est survenue. Veuillez r\xE9essayer!",
  SUCCESSFULLY_LEFT_TEAM: "Vous avez quitter {teamName}",
  CANT_JOIN_TEAM_JOIN_CHALLENGE_DETAIL: "Il semble que vous ne vous soyez pas encore inscrit au d\xE9fi. Vous devez vous inscrire avant de pouvoir rejoindre {teamName}.",
  JOIN_TEAM_SUCCESS_BUTTON: "Afficher les d\xE9tails de l'\xE9quipe",
  JOIN_TEAM_SUCCESS_DETAIL: "Conqu\xE9rons ces activit\xE9s ensemble.",
  JOIN_TEAM_SUCCESS_TITLE: "Bienvenue dans l'\xE9quipe!",
  VIEW_DOCUMENT: "Afficher le document\xA0:",
  UPLOADED_DOCUMENT: "Import\xE9 le {date} \u2022 Import\xE9 par {user}",
  INDIVIDUAL_CAP: "Individuel",
  CURRENTLY_NOT_SHARING_DATA: "Vous ne partagez actuellement aucune donn\xE9e pour {metric}",
  START_TRACKING_METRIC: "Commencer \xE0 suivre {metric}",
  SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER_TITLE: "Aidez-nous \xE0 am\xE9liorer l'exp\xE9rience relative \xE0 l'obtention de pr\xE9cieuses informations sur le compte de d\xE9penses",
  SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER: "Nous sommes ravis de vous annoncer la sortie du PMV de notre tableau de bord de g\xE9n\xE9ration de rapports interactif pour les comptes de d\xE9penses. Comment \xE9valueriez-vous votre exp\xE9rience relative \xE0 l'obtention de pr\xE9cieuses informations et \xE0 la g\xE9n\xE9ration de rapports?",
  ASSIGNED_BY_CARE_ADVISOR: "Attribu\xE9 par votre conseiller en soins",
  SHOPIFY_EOI_FORM_NOT_READY_INSTRUCTIONS: "<strong>Instructions :</strong> ce formulaire est requis par votre assureur si vous souscrivez pour la premi\xE8re fois \xE0 l'assurance-vie facultative pour vous ou votre conjoint ou bien \xE0 l'assurance maladie grave facultative pour vous ou votre conjoint,  ou si vous cherchez \xE0 augmenter le montant de votre couverture. Si vous souhaitez simplement confirmer des montants qui ont d\xE9j\xE0 \xE9t\xE9 approuv\xE9s, il n'est pas n\xE9cessaire de remplir ce formulaire.",
  ACTIVITIES_PILL: "Activit\xE9s",
  INSIGHT_CARD_UNIT_FLOORS_CLIMBED: "{count, plural, one {\xE9tage} other {\xE9tages}}",
  INSIGHT_CARD_UNIT_STEPS: "{count, plural, one {pas} other {pas}}",
  FOR_INSURED_BENEFITS_5: "Pour que votre conjoint de fait soit couvert, vous devez avoir habit\xE9 ensemble pendant au moins 12 mois",
  ENROLLMENT_INSIGHTS_FEEDBACK_BANNER_TITLE: "Aidez-nous \xE0 am\xE9liorer l'exp\xE9rience relative \xE0 l'obtention de pr\xE9cieuses informations et \xE0 la g\xE9n\xE9ration de rapports",
  ENROLLMENT_INSIGHTS_FEEDBACK_BANNER: "Nous sommes ravis de mettre \xE0 votre disposition de nouvelles am\xE9liorations de notre solution proposant de pr\xE9cieuses informations sur les adh\xE9sions juste \xE0 temps pour la p\xE9riode d'adh\xE9sion. Quelle note attribueriez-vous \xE0 l'exp\xE9rience relative \xE0 l'obtention de pr\xE9cieuses informations et \xE0 la g\xE9n\xE9ration de rapports?",
  UNABLE_TO_CREATE_TEAM: "Impossible de cr\xE9er une \xE9quipe. Veuillez r\xE9essayer. Si vous avez d\xE9j\xE0 rejoint une \xE9quipe, vous devrez la quitter avant d'en cr\xE9er une autre.",
  RETRIEVAL_EMAIL_SENT_MESSAGE: "Veuillez consulter votre bo\xEEte de r\xE9ception. Vous devriez avoir re\xE7u un courriel de League vous invitant \xE0 configurer votre nouveau compte.",
  RETRIEVAL_EMAIL_SENT: "Courriel de r\xE9cup\xE9ration envoy\xE9",
  REVIEW_FAQ: "consulter notre FAQ",
  RETRIEVE_ACCOUNT_SUCCESS_MESSAGE: "Si vous ne recevez pas de courriel de League dans un d\xE9lai de 30 minutes, veuillez v\xE9rifier que vous avez saisi la bonne adresse et { FAQ } si le probl\xE8me persiste.",
  BACK_TO_LOGIN: "Retourner \xE0 l'\xE9cran de connexion",
  RETRIEVE_ACCOUNT_MESSAGE: "Pour r\xE9cup\xE9rer votre compte League, veuillez saisir l'adresse courriel associ\xE9e.",
  RETRIEVE_ACCOUNT: "R\xE9cup\xE9rer votre compte",
  ASSIGNED_TODAY: "aujourd'hui",
  ASSIGNED_ON: "le {date}",
  PREVIOUS_30_DAYS: "30 jours pr\xE9c\xE9dents",
  NEXT_30_DAYS: "30 prochains jours",
  PREVIOUS_WEEK: "Semaine derni\xE8re",
  NEXT_WEEK: "Semaine prochaine",
  VIEW_ALL_METRIC_DATA: "Afficher toutes les donn\xE9es relatives \xE0 la mesure {metric}",
  AVERAGE: "Moyenne",
  SPOUSE_OPTIONAL_LIFE: "Vie facultative pour conjoint",
  DOCUMENTS: "Documents",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILD: "Moi-m\xEAme, mes enfants, mon partenaire domestique et l'enfant de mon partenaire domestique.",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILD: "Moi-m\xEAme, mon partenaire domestique et l'enfant de mon partenaire domestique.",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD_CHILDREN: "Moi-m\xEAme, mes enfants et l'enfant de mon partenaire domestique",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD: "Moi-m\xEAme et l'enfant de mon partenaire domestique",
  BROWSE_SUPPORTED_DATA: "Que puis-je suivre?",
  START_TRACKING_HEALTH_BODY: "Faites facilement le suivi de vos mesures au fil du temps pour voir o\xF9 vous en \xEAtes et comment vous pouvez atteindre vos objectifs de sant\xE9.",
  START_TRACKING_HEALTH_HEADER: "Commencez \xE0 suivre votre sant\xE9",
  DATA_AND_INSIGHTS: "Donn\xE9es et informations",
  SPENDING_ACCOUNTS_PILOT: "Obtention de pr\xE9cieuses informations sur le compte de d\xE9penses (projet pilote)",
  CARE_COLLECTIONS: "Collections de soins",
  YEARLY_PREMIUM: "Prime annuelle",
  CHANGING_UNITS_OF_MEASURE_TIP: "Cela aura une incidence sur la fa\xE7on dont les mesures sont affich\xE9es dans l\u2019appli et l\u2019appareil.",
  UPDATE_UNITS_OF_MEASURE: "Modification des unit\xE9s de mesure",
  MANAGE_UNITS_CHANGES_SAVED: "Modifications enregistr\xE9es \xE0 {time}",
  MANAGE_UNITS_APPLYING_CHANGES: "Vos modifications sont en cours d'application\u2026",
  DATAPOINT_CATEGORY_BLOOD: "Sang",
  DATAPOINT_CATEGORY_BODY: "Corps",
  DATAPOINT_CATEGORY_ACTIVITY: "Activit\xE9",
  SET_BENEFIT_VENDOR: "Le fournisseur d'avantages sociaux a \xE9t\xE9 enregistr\xE9",
  CREATE_NEW_VENDOR: "Cr\xE9er un fournisseur",
  SAVE_VENDOR: "Enregistrer le fournisseur",
  PRIMARY_COLOUR_HEX_TOOLTIP: "Vous pouvez la d\xE9terminer \xE0 l'aide d'un outil pipette, disponible dans Figma. S\xE9lectionnez la couleur principale du fournisseur.",
  PRIMARY_COLOUR_HEX: "Code hex de la couleur principale",
  VENDOR_LOGO: "Logo du fournisseur",
  LOGO_IMAGE_TOOLTIP: "Pour un r\xE9sultat optimal, utilisez une image au format PNG",
  LOGO_IMAGE: "Image du logo",
  VENDOR_NAME: "Nom",
  VENDOR_ID: "Identifiant du fournisseur",
  UPLOAD_IMAGE_NOTE: "Remarque : assurez-vous que le logo soit horizontal avant de le t\xE9l\xE9verser, sinon il ne s'affichera pas correctement sur la carte du portefeuille.",
  BENEFIT_VENDOR_LIST_INFO: "Les fournisseurs d'avantages sociaux sont disponibles dans le portefeuille des membres League afin d'indiquer \xE0 ces derniers quel est le fournisseur assur\xE9 correspondant \xE0 tel ou tel avantage social. Veuillez commencer par v\xE9rifier s'il existe un fournisseur avant d'en ajouter un nouveau. Une fois le fournisseur cr\xE9\xE9, il pourra \xEAtre s\xE9lectionn\xE9 dans une liste d\xE9roulante au moment de la configuration d'un avantage social appartenant \xE0 un groupe ou une cat\xE9gorie d'avantages sociaux, pour les groupes des \xC9tats-Unis uniquement.",
  BENEFIT_VENDOR_LIST: "Liste de fournisseurs de garanties",
  BACK_TO_VENDOR_LIST: "Retourner \xE0 la liste des fournisseurs",
  MEMBER_LABEL: "Membre",
  MEMBERS_LOWERCASE: "{totalParticipants} membres",
  YOO_FITNESS: "YOO Fitness",
  BIOTEL_CARE: "BioTel Care",
  IGLUCOSE: "iGlucose",
  UNDERARMOUR: "Under Armour",
  LOSE_IT: "Lose It",
  MISFIT: "Misfit",
  IHEALTH: "iHealth",
  STRAVA: "Strava",
  OMRON: "Omron",
  WITHINGS: "Withings",
  POLAR: "Polar",
  GARMIN: "Garmin",
  FITBIT: "Fitbit",
  GOOGLE_FIT: "Google Fit",
  APPLE_HEALTH: "Apple Sant\xE9",
  WHEN_MULTIPLE_SOURCES_AVAILABLE: "Lorsque plusieurs sources sont disponibles, la source de donn\xE9es ayant la plus grande valeur sera enregistr\xE9e \xE0 la fin de la journ\xE9e (23h59).",
  SOURCES_CAN_UPDATE: "Les sources ci-dessous sont autoris\xE9es \xE0 mettre \xE0 jour votre",
  DATA_SOURCES: "Sources de donn\xE9es",
  GET_ASSISTANCE_NOW_SUBHEADING: "Clavardez avec nos professionnels de la sant\xE9 agr\xE9\xE9s et notre service \xE0 la client\xE8le. Ils peuvent vous aider \xE0 \xE9pargner de l'argent en vous dirigeant aux meilleurs soins de sant\xE9 les plus abordables dans votre r\xE9gion, ainsi qu'\xE0 r\xE9pondre \xE0 vos questions sur vos avantages sociaux.",
  SEND: "Envoyer",
  WRITE_A_MESSAGE: "\xC9crivez un message...",
  WRITE_TO: "\xC9crire \xE0 {user}",
  NO_CARE_COLLECTIONS: "D\xE9sol\xE9, il n'y a aucune collection de soins disponible.",
  TOKENS_YESTERDAY_HIGHLIGHT: " hier!",
  USER_OR_TEAM_EARNED_TOKENS_HIGHLIGHT: "<strong>{name}</strong> a accumul\xE9 un total de ",
  EARNED_ACHIEVEMENT_HIGHLIGHT: "<strong>Objectif {achievement} atteint!</strong>",
  ACTIVE_MINUTES_HIGHLIGHT: "<strong>A boug\xE9 pendant {total} minutes aujourd'hui!</strong>",
  STEPS_WALKED_HIGHLIGHT: "<strong>A fait {total} pas hier!</strong>",
  JOINED_TEAM_HIGHLIGHT: "A rejoint <strong>{teamName}</strong>!",
  CREATED_TEAM_HIGHLIGHT: "A cr\xE9\xE9 <strong>{teamName}</strong>!",
  JOINED_CHALLENGE_HIGHLIGHT: "A rejoint <strong>{challengeTitle}</strong>!",
  YOU_SENT: "Vous avez envoy\xE9 :",
  RESULT_COUNT: "{count, plural, one {# r\xE9sultat} other {# r\xE9sultats}}",
  ANIMATION_ACCESSIBILITY_PLAYBACK: "Lancer ou mettre en pause l'animation s\xE9lectionn\xE9e",
  JOURNEY_DELIGHT_3: "Les petits pas peuvent faire une grande diff\xE9rence. Vous faites ce pas!",
  JOURNEY_DELIGHT_2: "Actif. Concentr\xE9. Dans votre voie. Nous aimons le voir.",
  JOURNEY_DELIGHT_1: "La motivation vous fait d\xE9marrer. L'habitude vous fait avancer. Vous \xEAtes pr\xE8s!",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS: "\xC0 compter du 1er d\xE9cembre 2023, nous avons apport\xE9 quelques changements au r\xE9gime d\u2019avantages sociaux :",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_HEADING: "NOUVEAU en 2024! Des fonds accrus pour les prestations de sant\xE9 mentale et la r\xE9serve bien-\xEAtre",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_WP: "S'il vous reste des dollars dans votre r\xE9serve bien-\xEAtre apr\xE8s avoir fait vos s\xE9lections pour les m\xE9dicaments et la couverture m\xE9dicale et dentaire, ils seront automatiquement appliqu\xE9s au co\xFBt de l'assurance-vie de base. Les fonds de la r\xE9serve bien-\xEAtre utilis\xE9s pour r\xE9gler l'assurance-vie de base sont une indemnit\xE9 imposable. Cela ne s'applique pas aux assurances-vie facultatives.",
  CREATE_MESSAGE: "Cr\xE9er un message",
  TO: "\xC0",
  MESSAGE_SUBJECT_PLACEHOLDER: "D\xE9finissez le sujet du message",
  MESSAGE_SUBJECT: "Objet du message (facultatif)",
  NEW_MESSAGE: "Nouveau message",
  ALL_RECORDED_DATA: "Toutes les donn\xE9es enregistr\xE9es",
  CHALLENGES_LEGAL_DISCLAIMER: "Ce d\xE9fi se veut un moyen amusant pour tous de participer et d'avoir une chance \xE9quitable de gagner. Parfois, des prix en esp\xE8ces ou autres peuvent \xEAtre offerts par l'organisateur du d\xE9fi. League ne con\xE7oit pas la structure du d\xE9fi, ni si celui-ci offre des prix. Si vous avez des pr\xE9occupations concernant la structure de ce d\xE9fi ou si vous pensez avoir \xE9t\xE9 exclu de la chance de gagner, veuillez en parler \xE0 l'organisateur du d\xE9fi.",
  NO_RECOMENDATIONS: "D\xE9sol\xE9, nous n'avons pas de recommandations \xE0 vous proposer dans ce domaine de sant\xE9 pour le moment.",
  FEEDBACK_TITLE: "Est-ce que cette information \xE9tait utile?",
  TODAYS_FOCUS_USER_PROMPT: "Visitez votre parcours pour compl\xE9ter vos activit\xE9s du d\xE9fi.",
  TODAYS_FOCUS: "Focus du jour",
  RETURN_TO: "Retour \xE0 {location}",
  NO_MESSAGES_DESCRIPTION: "Commencez par envoyer un nouveau message!",
  NO_MESSAGES_HEADING: "Aucun message pour le moment",
  UNREAD: "Non lu",
  CURRENT_PAGE: "page en cours",
  NEW_MESSAGE_DEFAULT: "Ceci est le d\xE9but d'un nouveau message",
  CARD_BACK_FILENAME: "{benefitName}-verso",
  CARD_FRONT_FILENAME: "{benefitName}-recto",
  START_A_VISIT: "Commencez une visite",
  NETWORK_MISSING_COVERAGE: "Votre r\xE9gime n'inclut aucune couverture {network}",
  SEND_NEW_MESSAGE: "Envoyer un nouveau message",
  MESSAGES: "Messages",
  LIKED: "Aim\xE9",
  LIKE: "Aimer",
  VISION_BENEFITS_DESCRIPTION: "Pr\xE9rempli avec le nom de la personne \xE0 charge si une assurance pour soins de la vue a \xE9t\xE9 coordonn\xE9e et si la fonctionnalit\xE9 est r\xE9gl\xE9e sur ON pour ce groupe",
  TERMINATION_REASON_DESCRIPTION: "Ce champ indique le motif de s\xE9paration du membre de l'Employeur",
  SUSPENDED_TYPE_DESCRIPTION: "Le statut de la cessation d'emploi du membre",
  SUSPENDED_NOTICE_DATE_DESCRIPTION: "Date de l'avis de cessation d'emploi",
  SUSPENDED_DATE_DESCRIPTION: "Date de la cessation d'emploi",
  SUSPENDED_BENEFITS_EXTENSION_DATE_DESCRIPTION: "L'employ\xE9 ayant cess\xE9 ses fonctions est autoris\xE9 \xE0 maintenir ses avantages actifs. Ce champ indique la date \xE0 laquelle ces avantages prennent fin.",
  SUSPENDED_DESCRIPTION: "R\xE9sili\xE9",
  SUPERVISOR_DESCRIPTION: "Une personne responsable d'un certain service \xE0 qui les employ\xE9s peuvent rendre compte",
  REINSTATED_DATE_DESCRIPTION: "Date \xE0 laquelle un membre a \xE9t\xE9 r\xE9activ\xE9 suite \xE0 une cessation d'emploi",
  REINSTATED_DESCRIPTION: "Le membre ayant pr\xE9c\xE9demment cess\xE9 ses fonctions est de nouveau actif",
  PREFERRED_FIRST_NAME_DESCRIPTION: "Nom de l'employ\xE9",
  PHONE_NUMBER_DESCRIPTION: "Num\xE9ro de t\xE9l\xE9phone du membre",
  PAYROLL_ID_DESCRIPTION: "Num\xE9ro d'identification de l'employ\xE9 utilis\xE9 par le responsable de la paie",
  PAY_GROUP_DESCRIPTION: "\xC0 des fins de paie, les membres sont class\xE9s par groupes de paie selon la fr\xE9quence des paies, l'emplacement, etc. Cela affecte \xE9galement le montant par paie indiqu\xE9 aux utilisateurs lors de l'adh\xE9sion.",
  OFFICE_LOCATION_DESCRIPTION: "Ville/Identification du bureau o\xF9 le membre \xE9tait un employ\xE9",
  NO_PLATFORM_FEE_CHARGES_DESCRIPTION: "Exclure l'employ\xE9 des frais associ\xE9s \xE0 la plateforme",
  INVITATION_CODE_DESCRIPTION: "Ce code est g\xE9n\xE9r\xE9 pour qu'un lien soit envoy\xE9 au membre pour activer son profil League",
  HR_MANAGER_DESCRIPTION: "Responsable des ressources humaines",
  HOURLY_RATE_DESCRIPTION: "Un montant sera affich\xE9 ici si le membre est pay\xE9 \xE0 l'heure et non sur une base salariale",
  HIDE_TYPEFORM_STEP_DESCRIPTION: "Le membre ne sera pas invit\xE9 \xE0 remplir un formulaire Typeform",
  HIDE_DEPENDENTS_STEP_DESCRIPTION: "N'autorise pas le membre \xE0 ajouter/modifier des personnes \xE0 charge ",
  HIDE_BENEFIT_CONFIG_STEP_DESCRIPTION: "Ce champ emp\xEAche un membre d'effectuer des modifications sur les avantages \xE0 tout moment",
  FLSA_EXEMPT_DESCRIPTION: "Banderole d'exemption du Fair Labor Standards Act ",
  EXTENDED_HEALTH_BENEFITS_DESCRIPTION: "Contient le nom de la personne \xE0 charge si des prestations de sant\xE9 ont \xE9t\xE9 coordonn\xE9es et si la fonctionnalit\xE9 a \xE9t\xE9 r\xE9gl\xE9e sur ON pour le groupe",
  EMPLOYEE_LEAVE_START_DATE_DESCRIPTION: "Par exemple : Une membre part en cong\xE9 maternit\xE9, ce champ indique la date du d\xE9but du cong\xE9",
  DRUG_BENEFITS_DESCRIPTION: "Contient le nom de la personne \xE0 charge si des prestations de m\xE9dicaments ont \xE9t\xE9 coordonn\xE9es et si la fonctionnalit\xE9 a \xE9t\xE9 r\xE9gl\xE9e sur ON pour le groupe",
  DEPARTMENT_DESCRIPTION: "Lieu de travail de l'employ\xE9 au sein de l'entreprise",
  DENTAL_CARE_DESCRIPTION: "Contient le nom de la personne \xE0 charge si des prestations dentaires ont \xE9t\xE9 coordonn\xE9es et si la fonctionnalit\xE9 a \xE9t\xE9 r\xE9gl\xE9e sur ON pour le groupe",
  CUSTOM_FIELDS_DESCRIPTION: "Sp\xE9cifique (peut varier selon les exigences du groupe)",
  BILLING_DIVISION_DESCRIPTION: "Le client est factur\xE9 selon une division de la facturation, un membre est inclus sur la facture correspondante selon la division qui lui est attribu\xE9e",
  ANNUAL_EARNINGS_FOR_POOLED_BENEFITS_DESCRIPTION: "Salaire annuel de l'employ\xE9 associ\xE9 aux avantages sociaux bas\xE9s sur le salaire",
  ACTIVATION_DATE_DESCRIPTION: "Date \xE0 laquelle le membre cr\xE9e/inscrit son identifiant League",
  VIEW_ALL_INSIGHTS: "Voir tous les aper\xE7us",
  YOUR_INSIGHTS_SUBTITLE: "Afficher les donn\xE9es sur votre sant\xE9 les plus r\xE9centes \xE0 partir de vos applications et appareils connect\xE9s",
  YOUR_INSIGHTS_TITLE: "Vos aper\xE7us",
  CHANGING_YOUR_UNITS: "La modification de vos unit\xE9s affectera la fa\xE7on dont elles sont affich\xE9es dans l'application.",
  UPDATING_UNITS_OF_MEASURE: "Mise \xE0 jour des unit\xE9s de mesure",
  YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NOR_POINTS: "Il ne vous reste plus que quelques questions pour compl\xE9ter l'\xE9valuation.",
  YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NUMBER: "Il ne vous reste plus que quelques questions avant d'accumuler {points} points.",
  LIVE_BALANCE_REMAINING: "{amount} restant",
  LIVE_BALANCE_SPEND: "{spentAmount} sur {totalAmount} d\xE9pens\xE9",
  APPS_AND_DEVICES_CURRENTLY_SUPPORTED: "applications et appareils actuellement pris en charge",
  CONNECTING_UNAVAILABLE_DETAILS_WITH_LINK: "Pour l'instant, veuillez consulter l'application mobile League pour connecter vos applications et appareils de sant\xE9 pr\xE9f\xE9r\xE9s. Si vous souhaitez en savoir plus, vous pouvez trouver une liste compl\xE8te des",
  CONNECT_DEVICES_LEARN_MORE: "En savoir plus",
  HOW_CAN_WE_HELP: "Comment pouvons-nous aider?",
  GET_ASSISTANCE_NOW: "Obtenir de l'aide maintenant",
  OLDER: "Plus vieux",
  PAST_30_DAYS: "30\xA0derniers jours",
  PAST_7_DAYS: "7 derniers jours",
  ALL_INSIGHTS: "Tous les aper\xE7u",
  NO_DATA_AVAILABLE: "Aucune donn\xE9e disponible",
  MANAGE_UNITS: "Modifier les unit\xE9s de mesure",
  ADD_APPS_DEVICES: "Voir les applications et les appareils",
  ACCUMULATION_PERIOD: "P\xE9riode d'accumulation\xA0: {start} - {end}",
  LAST_UPDATED_CARRIER_DATE: "Derni\xE8re mise \xE0 jour\xA0: {date} de {carrier}",
  FIELD_SETTINGS_LIST_TWO: 'Vous devez utiliser le bouton "Sauvegarder" au bas de cette page pour appliquer vos modifications.',
  FIELD_SETTINGS_LIST_ONE: "Le fait de d\xE9sactiver un champ ne le supprime pas de la base de donn\xE9es et ne supprime pas les valeurs pr\xE9c\xE9demment saisies.",
  LOAD_MORE_FALLBACK_ERROR: "Nous ne pouvons pas charger plus de r\xE9sultats pour le moment. Veuillez r\xE9essayer plus tard.",
  LOAD_MORE_ERROR: "Une erreur est survenue lors du chargement de vos r\xE9sultats. Veuillez r\xE9essayer.",
  REMEMBER_TO_SAVE: "N'oubliez pas de faire d\xE9filer vers le bas et d'appuyer sur Enregistrer.",
  FIELD_SETTINGS: "Param\xE8tres du champ",
  INSIGHT_CARD_HEADER_MINDFULNESS: "Pleine conscience",
  INSIGHT_CARD_HEADER_HEIGHT: "Taille",
  INSIGHT_CARD_HEADER_BODY_TEMPERATURE: "Temp\xE9rature du corps",
  INSIGHT_CARD_HEADER_WEIGHT: "Poids",
  INSIGHT_CARD_HEADER_BLOOD_OXYGEN: "Oxyg\xE8ne sanguin",
  INSIGHT_CARD_HEADER_BLOOD_PRESSURE: "Pression sanguine",
  INSIGHT_CARD_HEADER_BLOOD_GLUCOSE: "Glucose sanguine",
  INSIGHT_CARD_HEADER_HEART_RATE: "Fr\xE9quence cardiaque",
  INSIGHT_CARD_HEADER_SLEEP_DURATION: "Dur\xE9e du sommeil",
  INSIGHT_CARD_HEADER_ENERGY_BURNED: "\xC9nergie br\xFBl\xE9e",
  INSIGHT_CARD_HEADER_ACTIVE_DURATION: "Minutes actives",
  INSIGHT_CARD_HEADER_FLOORS_CLIMBED: "\xC9tages mont\xE9s",
  INSIGHT_CARD_HEADER_STEPS: "Pas",
  INSIGHT_CARD_HEADER_DISTANCE: "Distance",
  SINCE_LAST_WEEK: "depuis la semaine derni\xE8re",
  PLAN_PROGRESS: "Progression du plan",
  CHALLENGE_LETS_START_TEAMS_ENABLED: "Vous \xEAtes pr\xEAt \xE0 commencer votre d\xE9fi. Voulez-vous rejoindre ou cr\xE9er une \xE9quipe?",
  YOU_LOOK_GREAT: "\xC7a te va bien!",
  VIEWABLE: "Visible",
  FIELD_SETTINGS_NOTE: "<strong> Remarque\xA0: </strong>",
  FIELD_SETTINGS_SUBTITLE: "Les param\xE8tres de cette page vous permettent d'activer la visibilit\xE9 de champs de profil sp\xE9cifiques dans le profil du membre. Utilisez-les pour masquer les champs qu'un groupe n'utilise pas dans la vue de ses membres.",
  FIELD_SETTINGS_TITLE: "Param\xE8tres du champ de profil",
  RECOMMENDED_NEXT_STEP: "\xC9tape suivante recommand\xE9e",
  VIEW_THIS_RESOURCE: "Voir cette ressource",
  VISIBILITY: "Visibilit\xE9",
  FIELD_SETTINGS_SUBHEADER: "Offrez une exp\xE9rience d'employeur personnalis\xE9e \xE0 l'administrateur RH en choisissant les champs hors syst\xE8me qui doivent \xEAtre visibles dans le formulaire de profil de l'employ\xE9.",
  YOUVE_EARNED_ACHIEVEMENT_BADGE: "Vous avez gagn\xE9 un badge de r\xE9alisation",
  TAKE_ME_BACK: "Revenir en arri\xE8re",
  VIEW_ALL_ACHIEVEMENTS: "Voir toutes les r\xE9alisations",
  SEE_ALL_ACHIEVEMENTS: "Voir toutes les r\xE9alisations",
  CHALLENGE_FAQ_CONTENT: "Consultez notre {helpCenterLink} pour toutes vos questions fr\xE9quemment pos\xE9es.",
  CHALLENGE_FAQ_HEADING: "Vous avez des questions sur les D\xE9fis?",
  FEATURES_HIGHLIGHT_CAROUSEL_LABEL: "Caract\xE9ristiques du carrousel",
  ENROLLMENT_CHOOSE_A_TEMPLATE: "Choisissez un mod\xE8le...",
  ENROLLMENT_TEMPLATE_SELECT_LIST_LABEL: "S\xE9lectionner un mod\xE8le",
  CREATE_NEW_TEMPLATE: "Cr\xE9er un nouveau mod\xE8le",
  ENROLLMENT_CONFIGURE_TEMPLATE: "Configurer le mod\xE8le",
  ENROLLMENT_TEMPLATE_TOOLTIP: "Choisissez un mod\xE8le par d\xE9faut dans la liste ou cr\xE9ez votre propre mod\xE8le personnalis\xE9 sp\xE9cifique au groupe, \xE0 la classe ou \xE0 l'ensemble en utilisant les boutons ci-dessous.",
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_OR_FUTURE_HEADING: "Rien de pr\xE9vu pour l'instant",
  NO_HIGHLIGHTS_ACTIVITY_DESCRIPTION: "Rendez-vous ici pour voir les highlights de vos d\xE9fis.",
  NO_ACTIVITY_TITLE: "Il n\u2019y a rien \xE0 voir par ici (pour le moment!)",
  VIEW_TEAM_DETAILS: "Afficher les d\xE9tails de l'\xE9quipe",
  CREATE_TEAM_SUCCESS_DESCRIPTION: "Commencez \xE0 constituer votre \xE9quipe. Partagez ce lien avec d'autres personnes afin de pouvoir r\xE9aliser des activit\xE9s ensemble.",
  CREATE_TEAM_SUCCESS: "Votre \xE9quipe est pr\xEAte!",
  TEAMS_SELECT_AVATAR_DESCRIPTION: "Cet avatar appara\xEEt sur le profil de votre \xE9quipe et dans le classement.",
  TEAM_PROFILE_PHOTO_DESCRIPTION: "Cet photo appara\xEEt sur le profil de votre \xE9quipe et dans le classement.",
  SEARCH_FOR_TEAM: "Rechercher une \xE9quipe",
  PC_HEALTH_ID: "ID PC Sant\xE9",
  ACTIVITY_DISMISS_ERROR: "Nous n'avons pas \xE9t\xE9 en mesure de supprimer l'activit\xE9 pour le moment. Veuillez r\xE9essayer.",
  ACTIVITY_COMPLETE_ERROR: "Nous n'avons pas \xE9t\xE9 en mesure de compl\xE9ter l'activit\xE9 pour le moment. Veuillez r\xE9essayer.",
  ACHIEVEMENT_MODAL_LABEL: "Modalit\xE9 de d\xE9tail des r\xE9alisations",
  NO_SOLUTIONS_AVAILABLE: "Nous sommes d\xE9sol\xE9s, aucune solution n'est disponible pour le moment.",
  NONE_OF_THE_ABOVE: "Aucun des choix ci-dessus",
  DATAPOINT_SYNC_ISSUE: "Un probl\xE8me est survenu lors de la synchronisation de votre {dataPoint}.",
  FIX_SYNC_ISSUE: "R\xE9soudre ce probl\xE8me",
  SYNC_ISSUE: "PROBL\xC8ME DE SYNCHRONISATION",
  SET_TEAM_PHOTO: "D\xE9finir la photo de l'\xE9quipe",
  SLIDE_OF_TOTAL: "Diapositive {key} sur {total}",
  TEAM_NAME_ERROR: "Veuillez entrer un nom pour votre \xE9quipe.",
  TEAM_NAME_LABEL: "Nom de l'\xE9quipe",
  CHANGE_TEAM_PHOTO: "Changer la photo d'\xE9quipe",
  SELECT_TEAM_PHOTO: "S\xE9lectionnez la photo d'\xE9quipe",
  CREATE_A_TEAM: "Cr\xE9er une \xE9quipe",
  QLE_WELCOME_IMPORTANT_NOTE_EFFECTIVE_DATE_KPMG: "Vous avez jusqu'au {qleEnrollmentEndDate} pour modifier vos s\xE9lections. Si vous modifiez vos s\xE9lections, vos nouveaux avantages entreront en vigueur le premier jour du mois suivant la soumission de vos s\xE9lections.",
  DATE_AND_TIME: "Date et heure",
  PROGRAM_REMOVE_ERROR: "Nous n'avons pas \xE9t\xE9 en mesure de supprimer le programme pour le moment. Veuillez r\xE9essayer.",
  PROGRAM_ADD_ERROR: "Nous n'avons pas \xE9t\xE9 en mesure d'ajouter le programme pour le moment. Veuillez r\xE9essayer ou choisir un autre programme.",
  SWITCH_TO_NEW_ENROLLMENT_DESIGN_TOOL: "Passer au nouvel outil de conception des adh\xE9sions",
  ENROLLMENT_CONFIGURATION: "Configuration de l'adh\xE9sion",
  UNABLE_TO_COPY_LINK_TO_CLIPBOARD: "Nous n'avons pas \xE9t\xE9 en mesure de copier le lien dans votre presse-papiers! Veuillez mettre le lien en surbrillance et le copier manuellement.",
  INVITE_LINK_COPIED_TO_CLIPBOARD: "Le lien de l'invitation a \xE9t\xE9 copi\xE9 dans le presse-papiers!",
  TEAM_INVITE_LINK: "Lien d'invitation pour l'\xE9quipe",
  "CHAT_DEEPLINK_START_REGISTERED_NURSE_CHAT ": "Clavardage du personnel infirmier agr\xE9\xE9!",
  "CHAT_REGISTERED_NURSE_ONLINE_HOURS ": "Heures de service en ligne du personnel infirmier agr\xE9\xE9",
  CHAT_SEND_EMAIL_TO_REGISTERED_NURSE: "Envoyer un courriel \xE0 un personnel infirmier agr\xE9\xE9",
  CHAT_REGISTERED_NURSE_DESCRIPTION: "Obtenez les r\xE9ponses et les conseils dont vous avez besoin sur des sujets li\xE9s \xE0 la sant\xE9, y compris les signes, les sympt\xF4mes et les options de traitement.",
  REGISTERED_NURSE: "Personnel infirmier agr\xE9\xE9",
  GET_AVATARS_ERROR: "Oups! Il y a eu un probl\xE8me pour charger les avatars. Veuillez rafra\xEEchir et r\xE9essayer.",
  LEADERBOARD_USERNAME: "Nom d'utilisateur",
  LEADERBOARD_TOKENS: "Jetons",
  LEADERBOARD_RANKING: "Classement",
  USER_EARNED: "{user} a accumul\xE9 un total de",
  XTH_PLACE: "{currentRank, selectordinal, one {#\xE8re} other {#e} } place",
  SELECT_UP_TO: "S\xE9lectionnez jusqu'\xE0 {number}",
  WORD_WITH_EXCLAMATION_POINT: "{word}!",
  TOKENS: "jetons",
  EARN: "Accumuler",
  UP_YOUR_GAME_DESCRIPTION: "Rejoignez une \xE9quipe ou cr\xE9ez la v\xF4tre pour relever le d\xE9fi ensemble.",
  UP_YOUR_GAME: "Am\xE9liorez votre niveau!",
  CREATE_TEAM_DESCRIPTION: "Personnalisez votre \xE9quipe en d\xE9finissant votre propre avatar et votre propre nom. Invitez d'autres personnes \xE0 se joindre \xE0 vous!",
  CREATE_TEAM: "Cr\xE9er une \xE9quipe",
  JOIN_TEAM_DESCRIPTION: "Recherchez une \xE9quipe existante \xE0 rejoindre et participez \xE0 la comp\xE9tition ensemble!",
  JOIN_A_TEAM: "Rejoindre une \xE9quipe",
  CHALLENGE_TEAMS_DESCRIPTION: "Rejoignez-vous \xE0 une \xE9quipe existante ou cr\xE9ez v\xF4tre propre \xE9quipe pour relever le d\xE9fi ensemble!",
  TEAMS: "\xC9quipes",
  JOIN_OR_CREATE_TEAM: "Rejoindre/cr\xE9er une \xE9quipe",
  YOUR_TEAM: "Votre \xE9quipe",
  SHOW_MY_ACTIVITY: "Afficher mon activit\xE9",
  CHALLENGE_ACTIVITY_DETAIL: "Permettez aux utilisateurs de voir quand vous terminez une activit\xE9 ou gagnez une r\xE9alisation en participant \xE0 un d\xE9fi.",
  CHALLENGE_ACTIVITY: "Activit\xE9 de d\xE9fi",
  LEAVE_TEAM_DETAIL: "\xCAtes-vous s\xFBr de vouloir supprimer {teamName}? Vos points ne seront plus pris en compte dans la moyenne de l'\xE9quipe.",
  LEAVE_TEAM_QUESTION: "Voulez-vous quitter {teamName}?",
  CANT_JOIN_TEAM_DETAIL: "Veuillez vous inscrire au d\xE9fi afin de rejoindre {teamName}",
  CANT_JOIN_TEAM_TITLE: "Vous ne pouvez pas encore rejoindre {teamName}",
  PRIVACY: "Confidentialit\xE9",
  INVITE_FRIENDS: "Inviter des amis",
  LEAVE_TEAM: "Quitter l'\xE9quipe",
  INVITE: "Invitation",
  TEAM_INVITE_LINK_DESCRIPTION: "Plus on est de fous, plus on rit! Partagez ce lien avec vos amis pour qu'ils puissent se joindre \xE0 votre \xE9quipe.",
  TEAM_INVITE_DESCRIPTION: "Invitez des amis \xE0 se joindre \xE0 votre \xE9quipe pour r\xE9aliser des activit\xE9s ensemble.",
  TEAM_INVITE_HEADING: "Nous sommes meilleurs ensemble!",
  X_LIKES: "{activityLikes} J'aime",
  TOTAL_TOKENS: "Nombre total de jetons",
  CURRENT_RANK: "Rang actuel",
  HIGHLIGHTS: "Points saillants",
  X_SPOTS_AVAILABLE: "{spotsAvailable} places disponibles",
  X_TEAM_MEMBERS: "{memberTotal}\xA0membres de l'\xE9quipe",
  JOIN_TEAM: "Rejoindre une \xE9quipe",
  SELECT_PROFILE_PICTURE_ERROR: "Vous devez choisir une photo de profil avant de continuer.",
  SIGN_UP_BEGINS_DATE: "L'inscription commence le {date}",
  SUCCESSFULLY_LEFT_CHALLENGE: "Vous avez quitt\xE9 le d\xE9fi avec succ\xE8s!",
  SECONDARY_DESCRIPTION: "Description secondaire",
  PROFILE_DETAILS: "D\xE9tails du profil",
  USER_SEARCH_SUCCESSFUL: "Recherche d'utilisateur r\xE9ussie",
  NO_USERS_FOUND: "Aucun utilisateur trouv\xE9",
  NO_USERS_AWAITING_VALIDATION: "Aucun utilisateur en attente de validation",
  UNABLE_TO_JOIN_CHALLENGE: "Impossible de rejoindre le d\xE9fi, veuillez r\xE9essayer!",
  UNABLE_TO_LEAVE_CHALLENGE: "Impossible de quitter le d\xE9fi, veuillez r\xE9essayer!",
  LEAGUE_FAMILY_INVITE_MODAL_SUCCESS_DESCRIPTION: "Faites savoir \xE0 votre proche qu'il peut d\xE9sormais t\xE9l\xE9charger l'application League.",
  LEAGUE_FAMILY_INVITE_MODAL_SUCCESS_HEADING: "Votre invitation a \xE9t\xE9 envoy\xE9!",
  LEAGUE_FAMILY_INVITE_MODAL_PRIVACY_DISCLAIMER: "Vos donn\xE9es sont en s\xE9curit\xE9 avec nous. Nous ne partagerons jamais vos donn\xE9es avec vos personnes \xE0 charge. En savoir plus sur notre {privacyPolicyLink}.",
  LEAGUE_FAMILY_INVITE_MODAL_CONSENT_LABEL: "Je reconnais que le destinataire de cette invitation a pris connaissance et accept\xE9 cette demande, et que les informations fournies sont exactes et v\xE9ridiques.",
  LEAGUE_FAMILY_INVITE_MODAL_DESCRIPTION: "Veuillez utiliser une adresse \xE9lectronique qui n'est pas associ\xE9e \xE0 un compte League existant. Cette invitation expirera 14 jours apr\xE8s son envoi.",
  LEAGUE_FAMILY_INVITE_MODAL_RESEND_HEADING: "O\xF9 devons-nous renvoyer l'invitation de {name}?",
  LEAGUE_FAMILY_INVITE_MODAL_HEADING: "O\xF9 devons-nous envoyer l'invitation de {name}?",
  OPT_IN_TERMS_NOT_ACKNOWLEDGED_ERROR: "<strong>Attendez! Il nous manque des informations.</strong> Veuillez lire et accepter le contrat avant de continuer.",
  JOB_DETAILS: "D\xE9tails du poste",
  DEPENDENT_NOTIFICATION_MESSAGE: "De nouvelles personnes \xE0 charge pourraient \xEAtre invit\xE9es",
  LEAGUE_FAMILY_EDUCATION_BANNER_SUBHEADER: "Vos personnes \xE0 charge peuvent acc\xE9der League et profiter elles aussi d'une vie plus saine!",
  LEAGUE_FAMILY_EDUCATION_BANNER_HEADER: "Demandez-vous la raison pour laquelle il est avantageux d'ajouter vos personnes \xE0 charge?",
  GOAL_REACHED: "Vous avez atteint l'objectif!",
  ACTIVITY_COMPLETE_LATER_PROMPT: 'Pour continuer, cliquez sur "Retour au parcours" et revenez plus tard pour le marquer comme fait.',
  RELOAD_MY_SUGGESTIONS: "Rechargez mes suggestions",
  OUTCOMES_FRICTION_SCREEN_DESCRIPTION: "Laissez-nous le temps de trouver des ressources utiles pour vous en fonction de vos r\xE9ponses.",
  OUTCOMES_FRICTION_SCREEN_TITLE: "Des suggestions de personnalisation...",
  LEADERBOARD: "Tableau de classement",
  TARGET_LANGUAGES_OPTIONAL_HINT: "S\xE9lectionnez la langue dans laquelle votre banni\xE8re est r\xE9dig\xE9e pour cibler les membres ayant ce param\xE8tre linguistique. Si vous ne faites aucune s\xE9lection, la banni\xE8re sera visible par tous les membres, quel que soit le param\xE8tre linguistique.",
  PREVIOUS_SLIDE: "Page pr\xE9c\xE9dente",
  NEXT_SLIDE: "Page suivante",
  IMAGE_CAROUSEL_DISPLAY: "Affichage du carrousel de blogs League",
  IMAGE_BANNER_DISPLAY: "Affichage de la banni\xE8re",
  VIEW_CHALLENGE_DETAILS: "Afficher les d\xE9tails du d\xE9fi",
  TOP_RANKED: "Mieux class\xE9",
  LEADERBOARD_CURRENT_USER_NAME: "{user} (Vous)",
  LEADERBOARD_ALL_TIME_RANKING: "Classement de tous les temps",
  ACTIVE_CHALLENGE: "{length, plural, one {D\xE9fi} other {D\xE9fis}} en cours",
  PROGRESS_RECORD_ERROR: "Un probl\xE8me est survenu lors de l'enregistrement de votre derni\xE8re progression.",
  GOAL_INCOMPLETE: "Vous devez compl\xE9ter l'objectif avant de continuer.",
  MANAGER: "Manager",
  WORK_LOCATION: "Lieu de travail",
  DEPARTMENT: "D\xE9partement",
  ENGLISH_TITLE_MERCH_LIST_HINT: "Ce champ est uniquement visible dans l'application sur les carrousels. Pour les banni\xE8res ordinaires (telles que les banni\xE8res de clients), ce champ est uniquement visible en interne.",
  YOUR_RANKING: "Votre classement",
  KAISER_COMPLIANCE_DESCRIPTION: "Je comprends que (\xE0 l'exception des affaires de la Cour des petites cr\xE9ances, des r\xE9clamations soumises \xE0 une proc\xE9dure d'appel Medicare ou \xE0 la r\xE9glementation de la proc\xE9dure de r\xE9clamation ERISA, et de toute autre r\xE9clamation qui ne peut \xEAtre soumise \xE0 un arbitrage contraignant en vertu de la loi en vigueur) tout litige entre moi-m\xEAme, mes h\xE9ritiers, mes parents ou d'autres parties associ\xE9es d'une part et le Kaiser Foundation Health Plan, Inc. (KFHP), tout prestataire de soins de sant\xE9 contractuel, tout administrateur ou toute autre partie associ\xE9e d'autre part, pour violation pr\xE9sum\xE9e de toute obligation d\xE9coulant de ou li\xE9e \xE0 l'adh\xE9sion au KFHP, y compris toute r\xE9clamation pour faute m\xE9dicale ou hospitali\xE8re (une r\xE9clamation selon laquelle les services m\xE9dicaux n'\xE9taient pas n\xE9cessaires ou n'\xE9taient pas autoris\xE9s ou ont \xE9t\xE9 fournis de mani\xE8re inappropri\xE9e ou n\xE9gligente.",
  KAISER_COMPLIANCE_TITLE: "Convention d'arbitrage du Kaiser Foundation Health Plan",
  CALL_TO_ACTION_HINT: "Il s'agit du texte mis en \xE9vidence sur lequel les membres peuvent cliquer pour ouvrir l'URL.",
  TARGET_USER_IDS_OPTIONAL_HINT: "Pour cibler votre banni\xE8re sur des membres sp\xE9cifiques. Les identifiants d'utilisateur doivent \xEAtre s\xE9par\xE9s par des virgules. Si vous laissez cette section vide, la banni\xE8re s'adressera soit \xE0 tous les membres, soit uniquement aux membres des entreprises sp\xE9cifi\xE9es dans le champ ID de groupe (si vous utilisez ce champ).",
  TARGET_GROUP_IDS_OPTIONAL_HINT: "Pour cibler votre banni\xE8re sur les membres de soci\xE9t\xE9s sp\xE9cifiques. Si vous laissez cette section vide, votre banni\xE8re s'adressera soit \xE0 tous les membres, soit uniquement aux membres sp\xE9cifi\xE9s dans le champ ID utilisateur (si vous utilisez ce champ).",
  TARGET_BY_COUNTRY_OPTIONAL_HINT: "Pour cibler votre banni\xE8re sur des pays sp\xE9cifiques. Si votre banni\xE8re doit s'afficher pour tous les membres, quel que soit leur emplacement, ne s\xE9lectionnez rien dans cette section.",
  ACTION_HINT: "S\xE9lectionnez Ouvrir l'URL si vous souhaitez que les membres effectuent une action sur la banni\xE8re, ou laissez le champ vide si la banni\xE8re est simplement informative.",
  CALL_TO_ACTION_TEXT: "Texte d'appel \xE0 l'action",
  ENTER_BODY_COPY_FOR_ARTICLE: "Saisissez un corps de texte pour l'article",
  BODY_COPY: "Corps du texte",
  ENTER_HEADLINE_FOR_ARTICLE: "Saisissez un titre pour l'article",
  HEADLINE: "Headline",
  ENTER_TARGET_USER_IDS: "Entrez des identifiants d'utilisateurs cibles s\xE9par\xE9s par des virgules",
  ENTER_TARGET_GROUP_IDS: "Entrez les identifiants des groupes cibles s\xE9par\xE9s par des virgules",
  TARGET_USER_IDS_OPTIONAL: "ID des utilisateurs cibles (facultatif)",
  TARGET_GROUP_IDS_OPTIONAL: "ID des groupes cibles (facultatif)",
  UPLOAD_CSV_FILE: "T\xE9l\xE9verser un fichier CSV",
  ENERGY_BURNED: "calories br\xFBl\xE9es",
  DISTANCE: "distance parcourue",
  NO_LEADERBOARD_ACTIVITY_DESCRIPTION: "Rendez-vous ici pour voir votre classement lorsque le d\xE9fi commencera.",
  I_AGREE: "J'accepte",
  PSA_SHORT_DESC: "<p>Utilisez votre {name} ({shortname}) pour payer les services de style de vie et de bien-\xEAtre qui vous tiennent \xE0 c\u0153ur. Ce compte est un avantage imposable. Votre CDP couvre de nombreux produits et services destin\xE9s \xE0 favoriser un mode de vie sain.</p>",
  ACTIVITY_SAVE_PROGRESS: "Enregistrez votre progr\xE8s de la journ\xE9e!",
  ACTIVITY_COMPLETE_PROMPT: `Cette activit\xE9 ne sera pas termin\xE9e tant que vous n'aurez pas "marquer comme fait".`,
  CLOSE_ACTIVITY: "Fermer l'activit\xE9",
  MARK_AS_DONE: "Marquer comme fait",
  UPDATE_MERCH_LIST_SUCCESSFUL: "La liste des marchands a \xE9t\xE9 mise \xE0 jour avec succ\xE8s",
  MODULE_TEMPLATE_BUTTON: "Bouton de mod\xE8le de module",
  EXIT: "Sortie",
  EXIT_CHALLENGE_SIGN_UP_CONFIRM_DESCRIPTION: "Si vous choisissez de quitter, il se peut que vos modifications ne soient pas enregistr\xE9es.",
  EXIT_CHALLENGE_SIGN_UP_CONFIRM_TITLE: "Vous \xEAtes s\xFBr de vouloir sortir ?",
  YOUR_PROGRESS_IN_NUMBERS: "{current} of {total} {unit} ou {percentage}\xA0pourcentage termin\xE9",
  YOUR_PROGRESS: "Votre progr\xE8s",
  UPDATED: "Actualis\xE9",
  TRACK_CHALLENGE_MANUALLY_LEARN_MORE: "En savoir plus",
  TRACK_CHALLENGE_MANUALLY_DESCRIPTION: "Si vous d\xE9cidez de faire le suivi manuellement, vous pouvez marquer les activit\xE9s comme faites, mais vous ne pourrez pas connecter un appareil par la suite.  {learnMoreLink} au sujet du suivi manuel.",
  TRACK_CHALLENGE_AUTOMATICALLY_INSTRUCTION: "suivez ces \xE9tapes",
  TRACK_CHALLENGE_AUTOMATICALLY_DESCRIPTION: "Si vous d\xE9cidez de faire le suivi automatiquement, apr\xE8s votre adh\xE9sion, {instructionsLink} pour connecter votre appareil en utilisant l'application mobile League.",
  DEVICES_UNCONNECTED_DESCRIPTION: "Connectez vos applications et appareils de suivi de sant\xE9 pr\xE9f\xE9r\xE9s pour marquer automatiquement les activit\xE9s comme faites.",
  DEVICES_UNCONNECTED_TRACK_MANUALLY_SUBTITLE: "Suivre manuellement",
  DEVICES_UNCONNECTED_TRACK_AUTOMATICALLY_SUBTITLE: "Suivre automatiquement",
  DEVICES_UNCONNECTED_TITLE: "Connexion de votre appareil",
  BENEFITS_WALLET_LANDING_TEMPLATE_SELECT_ERROR: "S\xE9lectionnez une page d'accueil diff\xE9rente pour publier",
  BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_DESCRIPTION: "Page d'accueil du portefeuille correctement modifi\xE9e",
  BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_TITLE: "Page d'accueil publi\xE9e",
  BENEFITS_WALLET_LANDING_TEMPLATE_LABEL: "Page d'accueil",
  BENEFITS_WALLET_LANDING_TEMPLATE_DESCRIPTION: "S\xE9lectionnez une page d'accueil \xE0 appliquer \xE0 ce groupe. <br /> Pour en cr\xE9er une nouvelle, rendez-vous sur Contentful et cr\xE9ez une page d'accueil, ou contactez un administrateur Contentful de League.",
  BENEFITS_WALLET_LANDING_TEMPLATE_HEADER: "Page d'accueil du portefeuille",
  PUBLISH: "Publier",
  TRACK_MANUALLY_CONFIRM_DESCRIPTION: "Vous ne pourrez pas connecter un appareil plus tard.",
  TRACK_MANUALLY_CONFIRM_TITLE: "Voulez-vous vraiment faire le suivi manuellement?",
  TRACK_HEALTH_DATA_ALT: "Tableau de bord avec un graphique de suivi des donn\xE9es de sant\xE9",
  COVERAGE_SEARCH_TRY_AGAIN: "Essayez de rechercher \xE0 nouveau avec un mot-cl\xE9 diff\xE9rent.",
  COVERAGE_SEARCH_QUERY_NOT_FOUND: '"{query}" introuvable.',
  SELECT_AN_ANSWER: "S\xE9lectionnez une r\xE9ponse",
  TRACK_CHALLENGE_MANUALLY: "Suivre manuellement \xE0 la place",
  TRACK_CHALLENGE_AUTOMATICALLY: "Suivre automatiquement",
  DEVICES_CONNECTED_DESCRIPTION: "Nous synchronisons d\xE9j\xE0 vos donn\xE9es. Si vous choisissez le suivi automatique, nous pourrons automatiquement marquer les activit\xE9s comme faites en utilisant {dataPoint} de votre appareil.",
  DEVICES_CONNECTED_TITLE: "On dirait que vous \xEAtes d\xE9j\xE0 connect\xE9!",
  TRACK_WEARABLES_ALT_TEXT: "Une personne regardant les donn\xE9es de sa montre connect\xE9e",
  STARTING_ON: "D\xE9but le {start, date, medium}",
  MARKETING_CONTENT_REMOVED: "Le contenu marketing a \xE9t\xE9 retir\xE9!",
  ACTIVITY_COUNT_GOAL: "Objectif : {goal} {unit}",
  NO_CHALLENGE_ACTIVITIES_TODAY: "Il n'y a pas d'activit\xE9s dans ce d\xE9fi pour vous aujourd'hui.",
  ACTIVITIES_APPEAR_HERE_WHEN_CHALLENGE_STARTS: "Les activit\xE9s quotidiennes appara\xEEtront ici au d\xE9but de votre d\xE9fi, le <strong>{date}</strong>.",
  CHALLENGE_ABOUT_TO_START: "Votre d\xE9fi est sur le point de commencer!",
  FLOORS_CLIMBED: "\xE9tages mont\xE9s",
  MINDFUL_DURATION: "minutes de pleine conscience",
  LEAVE: "Cong\xE9",
  LEAVE_CHALLENGE_DETAIL: "\xCAtes-vous s\xFBr de vouloir supprimer ce d\xE9fi de votre parcours? Toute progression dans ce d\xE9fi sera remise \xE0 z\xE9ro.",
  LEAVE_CHALLENGE_QUESTION: "Quitter le d\xE9fi?",
  CONFIRM_UPDATE: "Confirmer la mise \xE0 jour",
  DISPLAY_AS_CAROUSEL: "Afficher en tant que carrousel de blogs League (le carrousel sera masqu\xE9 pour les membres espagnols)",
  DISPLAY_AS_BANNER: "Afficher comme banni\xE8re",
  ADD_SECTION: "Ajouter une section",
  SELECT_AVATAR_ERROR: "Vous devez choisir un avatar avant de continuer.",
  BLUECAT_LSA_TOOLTIP: "Cette prestation n'est disponible que pour les employ\xE9s embauch\xE9s avant le 7 janvier 2022.",
  BACK_TO_MERCH_LIST: "Retour aux listes de marchandises",
  SAVE_LIST_BEFORE_ADDING_CONTENT: "Veuillez d'abord sauvegarder cette nouvelle liste avant d'y ajouter du contenu.",
  CREATE_NEW_MARKETING_CONTENT: "Cr\xE9er du nouveau contenu marketing",
  CHALLENGE_PROGRAM_LIBRARY_DESCRIPTION: "Inscrivez-vous \xE0 un d\xE9fi pour r\xE9aliser des activit\xE9s quotidiennes et rivalisez avec vos coll\xE8gues!",
  CHALLENGES: "D\xE9fis",
  CHALLENGE_LETS_START: "Commen\xE7ons le d\xE9fi.",
  YOURE_ALL_SET: "Vous \xEAtes pr\xEAt !",
  VIEW_FORM: "Afficher le formulaire",
  SIGN_UP_BY: "Inscrivez-vous avant {date}",
  UPLOAD_MY_OWN: "T\xE9l\xE9verser",
  SELECT_AVATAR: "S\xE9lectionnez un avatar",
  PROFILE_PHOTO_DESCRIPTION: "Cette photo appara\xEEt sur votre profil d'utilisateur et votre profil Sant\xE9",
  UPDATE_PROFILE_PHOTO: "Mettez \xE0 jour votre photo de profil",
  SET_UP_PROFILE_PHOTO: "Configurez votre photo de profil",
  SKIP_FOR_NOW: "Ignorer pour l'instant",
  MARKETING_CONTENT_SAVED: "Le contenu marketing a \xE9t\xE9 sauvegard\xE9!",
  SET_MERCH_LIST: "Liste des marchandises \xE9tablie avec succ\xE8s!",
  CONFIRM_REMOVAL_TEXT_UPDATE_LIST: "\xCAtes-vous s\xFBr de vouloir sauvegarder cette liste? Cette op\xE9ration ne peut pas \xEAtre annul\xE9e.",
  HOME_PAGE_CONFIG_SET: "Le contenu de la page d'accueil a \xE9t\xE9 d\xE9fini",
  MORE_INFORMATION: "Plus d'informations",
  CHECK_TO_CONFIRM_PRIVACY_POLICY: "En cochant cette case, j'accepte la <a>politique de confidentialit\xE9.</a>",
  PHONE_LOCK_ALT_TEXT: "T\xE9l\xE9phone avec une serrure superpos\xE9e.",
  ACCEPT_BEFORE_CONTINUE: "Vous devez accepter avant de continuer.",
  DETERMINE_CHALLENGE_WINNERS: "D\xE9terminer les gagnants du d\xE9fi",
  RANK_SCORE: "Classez votre score dans un tableau de classement",
  SHARE_CHALLENGE_PROGRESS: "Partagez les progr\xE8s de votre d\xE9fi avec vos coll\xE8gues (et votre employeur).",
  COMPLETE_ACTIVITIES_SHORT: "Activit\xE9s compl\xE8tes",
  CHALLENGE_USING_DATA_TO: "Lorsque vous vous inscrivez \xE0 un d\xE9fi, nous utilisons vos donn\xE9es pour :",
  CHALLENGE_SENSITIVE_DATA: "Vos donn\xE9es de sant\xE9 et vos renseignements personnels sont extr\xEAmement sensibles et il nous incombe de les prot\xE9ger et de les pr\xE9server. Nous prenons cette responsabilit\xE9 tr\xE8s au s\xE9rieux.",
  SIGN_UP_FOR_THE_CHALLENGE: "Inscrivez-vous pour le d\xE9fi",
  PAY_CALENDAR_MENU: "Menu du calendrier des salaires",
  DEFAULT_CONFIG: "Configuration par d\xE9faut de la paie",
  LINK_PAYROLL_CONFIGS: "Configuration du lien",
  PAYROLL_REPORT_CONFIGS_COPY: "Choisissez une configuration de rapport de paie pour lier le calendrier.",
  PAYROLL_REPORT_CONFIGS: "Configurations des rapports de paie",
  LINK_PAYROLL_REPORT_CONFIGS: "Lier les configurations des rapports de paie",
  REFERENCES: "R\xE9f\xE9rences",
  ENROLLMENT_PASSED: "Vous ne pouvez plus vous inscrire \xE0 ce d\xE9fi car les dates d'inscription sont pass\xE9es.",
  REQUIRED_FIELD_MERCH_LIST: "Requis si une description en anglais est fournie",
  MARKETING_CONTENT_ADDED: "Le contenu marketing a \xE9t\xE9 ajout\xE9 !",
  IMAGE: "Image",
  ENTER_TAGS_ASSOCIATED_ARTICLE: "Indiquez les balises, s\xE9par\xE9es par des virgules, associ\xE9es \xE0 cet article (par exemple, sant\xE9, nutrition, mill\xE9niaux).",
  ENTER_READ_TIME_MINUTES: "Indiquez le temps de lecture en minutes (valeur num\xE9rique uniquement !)",
  ENTER_HEX_CODE: "\xC9crivez un code hexagonal (par exemple #eaeefb)",
  ENTER_SOURCE_ARTICLE: "Indiquez la source de l'article (par exemple NY Times).",
  ENTER_DESCRIPTION_SHOW_ARTICLE_PREVIEW: "\xC9crivez une description \xE0 afficher pour le texte de l'aper\xE7u de l'article",
  ENTER_SUBTITLE_FOR_ARTICLE: "\xC9crivez un sous-titre pour l'article",
  ON_SITE_TREATMENTS: "Traitements sur place",
  HEALTH_SCREENINGS: "Examens de sant\xE9",
  WELLNESS_SEMINARS: "S\xE9minaires sur le bien-\xEAtre",
  GROUP_CLASSES: "Cours collectifs",
  FLU_CLINICS: "Cliniques de grippe",
  CONSUMER_SERVICE: "Service consommateurs",
  PEACE_OF_MIND: "Tranquillit\xE9 d'esprit",
  HEALTH_REIMBURSEMENT_ACCOUNT: "Compte de remboursement des soins de sant\xE9",
  SPENDING_ACCOUNT: "Compte de d\xE9penses",
  CORPORATE_WELLNESS: "Bien-\xEAtre corporatif",
  HAS_LSA_BALANCE: "A un solde CDB",
  HAS_HSA_BALANCE: "A un solde CSS",
  URL: "URL",
  OPEN_URL: "Ouvrir l'URL",
  TARGET_LANGUAGES_OPTIONAL: "Langues concern\xE9s (facultatif)",
  TARGET_USERS_WITH_ANY_OPTIONAL: "Utilisateurs concern\xE9s avec l'un des \xE9l\xE9ments suivants (facultatif)",
  TARGET_USERS_WITH_ALL_OPTIONAL: "Utilisateurs concern\xE9s avec TOUS les \xE9l\xE9ments suivants (facultatif)",
  PROS: "Pour",
  MEMBERS: "Membres",
  HR_ADMINS: "Administrateurs RH",
  EVERYONE: "Tout le monde",
  TARGET_USER_TYPE_OPTIONAL: "Type d'utilisateurs concern\xE9s (facultatif)",
  TARGET_BY_COUNTRY_OPTIONAL: "Objectif par pays (facultatif)",
  TAGS_OPTIONAL: "\xC9tiquettes (facultatif)",
  READ_TIME_OPTIONAL: "Temps de lecture (facultatif)",
  BACKGROUND_COLOR_OPTIONAL: "Couleur de fond (facultatif)",
  SOURCE_OPTIONAL: "Source (facultatif)",
  SUBTITLE_OPTIONAL: "Sous-titre (facultatif)",
  TITLE: "Titre",
  MARKETING_CONTENT: "Contenu Marketing",
  ACTIVITY_COMPLETE: "Activit\xE9 termin\xE9e",
  ADD_NEW_SECTION: "Ajouter une nouvelle section",
  HOME_PAGE_NO_RESULTS: "Pas de contenu sur la page d'accueil",
  LIST_HEADING: "Intitul\xE9 de la liste",
  SECTION_TYPE: "Type de section",
  EXPIRES_TODAY: "Expire aujourd'hui",
  REMOVE_PRESETS: "Supprimer les pr\xE9r\xE9glages",
  PRESETS_REPORTS: "Rapport sur les pr\xE9r\xE9glages",
  REMOVE_PRESETS_REPORT: "Rapport sur la suppression des pr\xE9r\xE9glages",
  YOUVE_COMPLETED: "Vous avez termin\xE9",
  YOUVE_EARNED_GRAND_TOTAL: "Vous avez accumul\xE9 un grand total de",
  VIEW_ACHIEVEMENTS: "Voir les r\xE9alisations",
  YOU_EARNED: "Vous avez accumul\xE9",
  UNLOCK_ACHIEVEMENTS: "D\xE9bloquez une r\xE9alisation pour avoir termin\xE9 toutes les activit\xE9s de ce programme.",
  GO_TO_JOURNEY: "Aller au parcours",
  SHOPIFY_COLLECTION_NO_DATA: "Il n'y a pas de donn\xE9es",
  SHOPIFY_COLLECTION_US_TITLE: "Aper\xE7u de Shopify US",
  SHOPIFY_COLLECTION_CA_TITLE: "Aper\xE7u de Shopify CA",
  PREVIEW_ITEMS: "Articles en avant-premi\xE8re",
  SHOPIFY_COLLECTION_TITLE: "Produits March\xE9vie dans la collection Shopify",
  JOINED_CHALLENGE: "D\xE9fi rejoint",
  SIGN_UP_DATE_PASSED: "La date d'inscription est pass\xE9e",
  LEAVE_CHALLENGE: "Quitter le d\xE9fi",
  EXPORT_DEDUCTION_CODES: "Codes de d\xE9duction \xE0 l'exportation",
  SHOPIFY_HELP_TEXT: "ex. https://marketplace.ca.league.dev/collections/medical-supplies = `medical-supplies`",
  SHOPIFY_COLLECTION_HANDLE: "Poign\xE9e de la collection Shopify",
  LIFEMARKET_MERCH_LIST_HELP_TEXT: "Il s'agit d'un titre d'administration interne pour cette liste de marchandises du March\xE9vie. Le titre de la collection Shopify sera celui qui sera affich\xE9 aux utilisateurs dans les carrousels.",
  LIST_TITLE: "Titre de la liste",
  PROGRAM_TYPE: "Type de programme :",
  CHALLENGE: "D\xE9fi",
  CHALLENGE_STATUS: "Statut du d\xE9fi :",
  SIGN_IN_HERE: "S'inscrire ici",
  ALREADY_A_LEAGUE_MEMBER: "Vous \xEAtes d\xE9j\xE0 membre de League?",
  RULES: "R\xE8gles",
  PRIZE: "Prix",
  SIGN_UP_FOR_CHALLENGE: "S'inscrire au d\xE9fi",
  CHALLENGE_DETAILS: "D\xE9tails du d\xE9fi",
  PARTICIPATE_TO_WIN: "Participez pour avoir une chance de gagner!",
  ENDS_DATE: "Se termine le {date}",
  SIGN_UP_BY_DATE: "Inscrivez-vous avant {date}",
  START_AND_END_DATES: "Commence le {startDate} - Se termine le {endDate}",
  EXTERNAL_LINK: "Lien externe",
  MEMBER_WALLET_LINK: "Lien vers le portefeuille du membre",
  MEMBER_TOOLING_LINK: "Lien vers l'outillage des membres",
  EMPLOYEE_PROFILE_LINKS_BY_GROUP: "Liens vers le profil des employ\xE9s par groupe",
  USER_LINKS: "Liens utilisateurs",
  AVAILABLE_FORMS_FOR_EMPLOYEE_PLAN: "Voici les formulaires disponibles pour le r\xE9gime des salari\xE9s",
  NO_FORMS_AVAILABLE_FOR_EMPLOYEE_PLAN: "Il n'y a pas de formulaire disponible pour le r\xE9gime de l'employ\xE9.",
  DEVICES: "Appareils",
  NO_USER_DEVICES_PLACEHOLDER: "Il n'y a pas de dispositifs associ\xE9s \xE0 cet utilisateur",
  PRESETS_COLUMN_BLURB: "Ces colonnes seront affich\xE9es dans le rapport de gauche \xE0 droite dans l'ordre que vous d\xE9finissez dans cette liste.",
  AVAILABLE_COLUMNS_BLURB: `Toutes les colonnes actuellement disponibles pour ce rapport sont \xE9num\xE9r\xE9es ci-dessous. Si vous n'ajoutez pas une colonne \xE0 la section "Pr\xE9r\xE9glages", elle n'appara\xEEtra pas dans le rapport.`,
  AVAILABLE_COLUMNS: "Colonnes disponibles",
  PRESETS_NAME: "Nom des pr\xE9r\xE9glages",
  PRESETS_COLUMN: "Colonne des pr\xE9r\xE9glages",
  CUSTOM_REPORT: "Rapport personnalis\xE9",
  CUSTOM: "Sur mesure",
  GROUP_NAME: "Nom du groupe",
  COMPLETED_X_TIMES: "Compl\xE9t\xE9 {amount} fois",
  EARNED_X_TIMES: "Gagn\xE9 {amount}X",
  CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_MESSAGE: "Cela aura un effet imm\xE9diat sur l'utilisateur.",
  CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_TITLE: "Supprimer l'adh\xE9sion au r\xE9gime de l'utilisateur ?",
  COME_BACK_COMPLETE_ACTIVITY_LATER: "Revenez plus tard pour compl\xE9ter cette activit\xE9.",
  NO_PAYMENT_PROFILES_PLACEHOLDER: "Il n'y a pas de profil de paiement associ\xE9 \xE0 cet utilisateur",
  PAYMENT_PROFILES: "Profils de paiement",
  NO_PROMO_CODE_HISTORY_PLACEHOLDER: "Il n'y a pas d'historique de code promo associ\xE9 \xE0 cet utilisateur.",
  PROMO_CODE_HISTORY: "Historique du code promotionnel.",
  PERCENTAGE_USED: "{percentage} utilis\xE9.",
  PROMO_CODE: "Code promotionnel",
  SUGGESTED_FOR_YOU: "Sugg\xE9r\xE9 pour vous",
  VALIDATION: "Validation",
  CREDENTIALS: "Identifiants",
  PAYMENT_PROFILE: "Profil de paiement",
  TRIGGER_DEACTIVATE_ACCOUNT_MODAL: "\xCAtes-vous s\xFBr de vouloir d\xE9sactiver ce compte\xA0?",
  DEACTIVATE_ACCOUNT: "D\xE9sactiver le compte",
  YOUR_JOURNEY: "Votre parcours",
  EARNED: "accumul\xE9",
  EDIT_DEPENDENT_DETAILS: "Modifier les d\xE9tails de la personne \xE0 charge",
  BACK_TO_MERCH_LISTS: "Retour aux listes de marchandises",
  SPANISH_DESCRIPTION_MERCH_LIST: "Description localis\xE9e (es-MX) (obligatoire si la description en anglais est fournie)",
  FRENCH_DESCRIPTION_MERCH_LIST: "Description localis\xE9e (fr-CA) (obligatoire si la description en anglais est fournie)",
  ENGLISH_DESCRIPTION_MERCH_LIST: "Description en anglais (facultatif)",
  SPANISH_TITLE_MERCH_LIST: "Titre localis\xE9 (es-MX)",
  FRENCH_TITLE_MERCH_LIST: "Titre localis\xE9 (fr-CA)",
  ENGLISH_TITLE_MERCH_LIST: "Titre anglais",
  PUBLISHED_MERCH_LIST: "Publi\xE9",
  SAVE_MERCH_LIST: "Sauvegarder la liste",
  ACTIVITY_PROGRESS: "Progression de l'activit\xE9",
  CONFIRM_REMOVAL_TEXT: "Voulez-vous vraiment supprimer {title}? Cette op\xE9ration est irr\xE9versible.",
  CONFIRM_REMOVAL: "Confirmer la suppression",
  CREATE_NEW_LIST: "Cr\xE9er une nouvelle liste",
  CONTENT_MERCH_LIST_TITLE: "Titre de la liste de marchandises de contenu",
  CLEAR_FILTERS: "Effacer les filtres",
  FILTER: "Filtrer",
  PHONE_NUMBER_UPDATE_SUCCESS: "Num\xE9ro de cellulaire mis \xE0 jour",
  PHONE_NUMBER_CONFIRM_MISMATCH: "Les num\xE9ros de t\xE9l\xE9phone doivent correspondre",
  PHONE_COUNTRY_CODE_ERROR: "Veuillez entrer un code de pays valide",
  PHONE_NUMBER_ERROR: "Veuillez entrer un num\xE9ro de t\xE9l\xE9phone valide",
  CONFIRM_NEW_PHONE_NUMBER: "Confirmer le nouveau num\xE9ro de t\xE9l\xE9phone",
  NEW_PHONE_NUMBER: "Nouveau num\xE9ro de t\xE9l\xE9phone",
  NEW_PHONE_COUNTRY_CODE: "Nouveau code de pays",
  CURRENT_PHONE_NUMBER: "Num\xE9ro de t\xE9l\xE9phone actuel",
  UPDATE_PHONE_NUMBER: "Mettre \xE0 jour le num\xE9ro de t\xE9l\xE9phone",
  NO_ACHIEVEMENTS_DESCRIPTION: "Terminez les activit\xE9s de votre parcours pour commencer \xE0 d\xE9bloquer des R\xE9alisations.",
  VIEW_ACTIVITIES: "Voir les activit\xE9s",
  MISSED: "Manqu\xE9",
  EXPIRES_TOMORROW: "Expire demain",
  RESET_TYPE: "R\xE9initialiser le type",
  ENROLLMENT_TYPE_OPTIONAL: "Type d'adh\xE9sion (facultatif)",
  BENEFIT_PLAN_OPTIONAL: "R\xE9gime d'avantages sociaux (facultatif)",
  TRIGGER_ADD_PROMO_CODE_MODAL: "\xCAtes-vous s\xFBr de vouloir ajouter le code promotionnel au compte?",
  TRIGGER_REMOVE_PROMO_CODE_MODAL: "\xCAtes-vous s\xFBr de vouloir enlever le code promotionnel au compte?",
  TRIGGER_UPDATE_FUNDS_PROMO_CODE_MODAL: "\xCAtes-vous s\xFBr de vouloir mettre \xE0 jour les fonds ?",
  UPDATE_FUNDS_DESCRIPTION_PROMO_CODE: "Ajouter ou retirer des fonds du CDP ou du CSS d'un employ\xE9.",
  UPDATE_FUNDS_PROMO_CODE: "Mettre les fonds \xE0 jour",
  REMOVE_PROMO_CODE: "Supprimer le code promotionnel du compte",
  PROMO_CODE_USER_ID_LABEL: "Identifiant de l\u2019utilisateur",
  ADD_PROMO_CODE: "Ajouter un code promotionnel au compte",
  TRIGGER_NEW_PROMO_CODE_MODAL: "\xCAtes-vous s\xFBr de vouloir cr\xE9er un nouveau code promotionnel?",
  UPDATE_FUNDS_PROMO_CODE_POLICY_LABEL: "Type de contrat",
  NEW_PROMO_CODE_EXPIRES_LABEL: "Date d'expiration",
  NEW_PROMO_CODE_MAX_USERS_LABEL: "Nombre maximum d'utilisateurs",
  PROMO_CODE_UPDATE_FUNDS_AMOUNT_PLACEHOLDER: "Pr\xE9ciser un montant positif \xE0 ajouter ou n\xE9gatif \xE0 retirer.",
  NEW_PROMO_CODE_MAX_USERS_PLACEHOLDER: "Le nombre de comptes d'utilisateurs auxquels vous souhaitez appliquer un code promo",
  PROMO_CODE_AMOUNT_PLACEHOLDER: "Tout montant entre 1 $ et 50 $ (tout montant sup\xE9rieur \xE0 50 $ doit \xEAtre confirm\xE9 par le chef d'\xE9quipe).",
  PROMO_CODE_USER_ID_PLACEHOLDER: "Entrez l'identifiant d'utilisateur du membre",
  PROMO_CODE_CODE_PLACEHOLDER: "Doit \xEAtre compos\xE9 de trois lettres minuscules suivies de trois chiffres.",
  PROMO_CODE_CODE_LABEL: "Code",
  NEW_PROMO_CODE: "Nouveau code promotionnel",
  PROMO_CODES_HEADING: "Codes promotionnels",
  DOWNLOAD_SDR_REPORT: "T\xE9l\xE9charger le rapport SDR",
  SEARCH_USERS_HEADING: "Rechercher des utilisateurs",
  IMG_ALT_EMPTY_STATE_REST: "Homme se reposant dans un fauteuil confortable avec une boisson chaude et des \xE9couteurs.",
  IMG_ALT_EMPTY_STATE_PROGRAMS: "Femme debout faisant d\xE9filer une liste de programmes de la taille d'un mur.",
  USERS_AWAITING_VALIDATION: "Les utilisateurs en attente de validation",
  SEARCH_BY_USERID_EMAIL_OR_NAME: "Rechercher par identifiant d'utilisateur, par courriel ou par nom",
  ACHIEVEMENTS: "R\xE9alisations",
  NO_PROGRAMS: "Rien \xE0 voir ici pour l'instant",
  IMG_ALT_HIGH_FIVE_HANDS: "Deux mains font un tape m'en cinq.",
  NO_ACTIVITIES_COMPLETED_TODAY_DESCRIPTION: "Revenez demain pour en savoir plus ou parcourez les activit\xE9s propos\xE9es.",
  NO_ACTIVITIES_COMPLETED_TODAY_HEADING: "Bravo, vous \xEAtes sur une lanc\xE9e!",
  REMOVE_GROUP_CATEGORIES_SUCCESS: "Le groupe de cat\xE9gories a \xE9t\xE9 supprim\xE9 avec succ\xE8s",
  REMOVE_GROUP_CATEGORIES: "Supprimer les cat\xE9gories",
  REMOVE_COVERAGE_CATEGORY_CONFIRMATION_DESCRIPTION: "\xCAtes-vous s\xFBr de vouloir supprimer toutes les cat\xE9gories de couverture pour {benefitName}?",
  REMOVE_COVERAGE_CATEGORY_CONFIRMATION_TITLE: "Supprimer la cat\xE9gorie de couverture : {benefitName}",
  PATIENT: "Patient",
  DATE_OF_CONSULT: "Date de consultation",
  CONSULT_TYPE: "Type de consultation",
  SERVICE: "Service",
  RISK_PERSONA_CARD_CAPTION: "Nous travaillons avec une \xE9quipe d'experts en avantages sociaux pour fournir ces recommandations.",
  RISK_PERSONA_CARD_TITLE: "\xC0 qui s'adresse ce plan?",
  RISK_PERSONA_CARD_IMAGE_TEXT: "Persona li\xE9e au risque",
  RISK_PERSONA: "Persona li\xE9e au risque",
  CLOSE_MODAL: "Fermer le modal",
  SEE_EXAMPLE: "Voir l'exemple",
  YOU_SKIPPED_THIS_ACTIVITY: "Vous avez saut\xE9 cette activit\xE9.",
  LOOKS_LIKE_THIS_WASNT_MEANT_FOR_YOU: "On dirait que cette activit\xE9 n'\xE9tait pas faite pour toi.",
  THIS_ACTIVITY_EXPIRED_ON_DATE: "Cette activit\xE9 a expir\xE9 le {date}.",
  LOOKS_LIKE_YOU_MISSED_THIS_ONE: "On dirait que vous avez manqu\xE9 cette activit\xE9!",
  COME_BACK_COMPLETE_ACTIVITY_ON_DATE: "Revenez et terminez cette activit\xE9 le {date}.",
  THIS_IS_JUST_A_SNEAK_PEAK: "Ce n'est qu'un avant-go\xFBt!",
  HOME_PAGE_CONFIG: "Configuration de la page d'accueil",
  CONTENT_MERCH_LIST: "Contenu de la liste de marchandises",
  LIFEMARKET_MERCH_LIST: "Liste de marchandises du March\xE9vie",
  MERCH_LISTS: "Listes des marchandises",
  SELECT_PROFILE_PHOTO: "S\xE9lectionnez la photo de profil",
  AUTH0_GENERIC_ERROR_MESSAGE: "Quelque chose n'a pas fonctionn\xE9 de notre c\xF4t\xE9. Veuillez r\xE9essayer. Si le probl\xE8me persiste, <a>contactez-nous</a>.",
  INSURANCE_ERROR_PREPARING_FORMS: "Il y a eu une erreur dans la pr\xE9paration de votre formulaire.",
  IMG_ALT_TO_DO_CLIPBOARD: "Presse-papiers avec liste de choses \xE0 faire compl\xE9t\xE9e",
  IMG_ALT_STANDING_YOGA_POSE: "Personne faisant une pose de yoga debout sur un tapis",
  BUTTON_BROWSE_PROGRAMS: "Parcourir les programmes",
  NO_ACTIVITIES_PAST_DESCRIPTION: "On dirait que vous avez pris cette journ\xE9e pour vous d\xE9tendre et r\xE9cup\xE9rer.",
  NO_ACTIVITIES_PAST_HEADING: "Jour de repos",
  NO_ACTIVITIES_FUTURE_DESCRIPTION: "Vous n'avez pas d'activit\xE9s pr\xE9vues pour ce jour.",
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_DESCRIPTION: `Appuyez sur le bouton "Ajouter" pour trouver de nouveaux programmes ou parcourir une liste d'activit\xE9s s\xE9lectionn\xE9es juste pour vous.`,
  DISCOVER_ACTIVITIES_CAPTION: "Parcourez les activit\xE9s qui devraient vous plaire",
  DISCOVER_ACTIVITIES: "D\xE9couvrir de nouvelles activit\xE9s",
  NO_PLAN_ENROLLMENT: "Aucune adh\xE9sion au r\xE9gime",
  BACK_TO_PREVIOUS: "\u2190 Retour \xE0 la page pr\xE9c\xE9dente",
  USER_PLAN_ENROLLMENTS_TITLE: "Adh\xE9sion de l'utilisateur au r\xE9gime : r\xE9gime {planName}",
  VIEW_USER_PLAN_ENROLLMENT: "\u{1F440} Voir l'adh\xE9sion du r\xE9gime de l'utilisateur",
  HR_ADMIN_SEARCH_FILTER_TEXT: "Afficher uniquement les administrateurs RH",
  ACTIVE_EMPLOYEE_SEARCH_FILTER_TEXT: "Afficher uniquement les employ\xE9s actifs",
  EMPLOYEE_SEARCH_FILTER_LABEL: "Filtrer",
  TRUPANION_CONFIRMATION_DESCRIPTION: `En m'adh\xE9rer \xE0 une ou plusieurs garanties Aflac, j'affirme avoir lu l'avis " \xC9l\xE9ments \xE0 prendre en compte concernant ma couverture d'assurance ", qui a \xE9t\xE9 mis \xE0 ma disposition sur ce site d'adh\xE9sion \xE9lectronique.`,
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_6: "Je comprends et j'accepte que la couverture pour laquelle je fais une demande peut comporter une exclusion pour condition pr\xE9existante.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_5: "J'ai lu la proposition d'employ\xE9/d\xE9claration d'assurabilit\xE9 d\xFBment remplie ainsi que les d\xE9clarations et les r\xE9ponses qui nous concernent, moi et mon conjoint. Je certifie que ces d\xE9clarations et r\xE9ponses sont v\xE9ridiques et compl\xE8tes au meilleur de ma connaissance, et que ces d\xE9clarations et r\xE9ponses seront utilis\xE9es par la compagnie d'assurance pour d\xE9terminer l'assurabilit\xE9. Je suis conscient(e) que toute fausse d\xE9claration ou d\xE9claration inexacte dans la demande d'adh\xE9sion de l'employ\xE9/d\xE9claration d'assurabilit\xE9 peut entra\xEEner la perte de la couverture en vertu du certificat. Je comprends qu'aucune assurance ne sera en vigueur tant que ma demande d'emploi/d\xE9claration d'assurabilit\xE9 n'aura pas \xE9t\xE9 approuv\xE9e et que la prime n\xE9cessaire n'aura pas \xE9t\xE9 pay\xE9e.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_4: "VEUILLEZ NOTER : Le choix de l'assurance maladies graves du conjoint est fix\xE9 \xE0 un minimum de 5 000 $. Tout montant inf\xE9rieur \xE0 5 000 $ sera corrig\xE9 avant que les choix ne soient soumis \xE0 Aflac. Si un changement est apport\xE9 \xE0 votre taux, vous serez contact\xE9.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_3: "La couverture ne prendra effet que si vous \xEAtes activement au travail \xE0 la date d'entr\xE9e en vigueur du certificat. Si vous n'\xEAtes pas activement au travail \xE0 cette date, la couverture entrera en vigueur \xE0 la date de votre retour \xE0 un statut de travail actif.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_2: "J'ai pris en consid\xE9ration toute ma couverture d'assurance sant\xE9 existante avec Aflac et je crois que cette couverture suppl\xE9mentaire est appropri\xE9e pour mes besoins d'assurance. Je comprends \xE9galement que je peux communiquer avec Aflac au 1-800-992-3522 au sujet de ma police individuelle et pour obtenir de l'aide afin d'\xE9valuer la pertinence de ma couverture d'assurance.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_1: "Si cette couverture doit remplacer une police individuelle Aflac existante, sachez qu'il peut \xEAtre dans votre int\xE9r\xEAt de maintenir votre police individuelle \xE0 renouvellement garanti par facturation directe.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_6: "J'autorise le titulaire de la police collective \xE0 d\xE9duire le montant appropri\xE9 de mes gains \xE0 chaque p\xE9riode de paie pour payer \xE0 Continental American Insurance Company la prime requise pour mon assurance.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_5: "Je comprends et j'accepte que la couverture que je demande puisse avoir une limitation pour les conditions pr\xE9existantes. Je comprends et j'accepte qu'une telle limitation de condition pr\xE9existante puisse s'appliquer \xE0 toute condition qui a \xE9t\xE9 r\xE9v\xE9l\xE9e dans ce formulaire de demande. J'ai re\xE7u un aper\xE7u de la couverture applicable \xE0 la couverture demand\xE9e.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_4: "Pour autant que je sache, mes r\xE9ponses aux questions sont v\xE9ridiques et compl\xE8tes.  Elles sont offertes \xE0 Continental American Insurance Company comme base pour toute assurance \xE9mise. Je r\xE9alise que toute fausse d\xE9claration ou repr\xE9sentation inexacte dans la demande peut entra\xEEner la perte de la couverture en vertu du certificat. Je comprends qu'aucune assurance ne sera en vigueur \xE0 moins que je ne sois activement au travail \xE0 la date d'entr\xE9e en vigueur de la couverture, et jusqu'\xE0 ce que ma demande soit approuv\xE9e et que la prime n\xE9cessaire soit pay\xE9e. Si je ne suis pas activement au travail \xE0 la date d'entr\xE9e en vigueur de la couverture, celle-ci entrera en vigueur \xE0 la date de mon retour \xE0 un statut de travailleur actif.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_3: "Si un enfant couvert atteint un \xE2ge limite tel que sp\xE9cifi\xE9 dans le certificat ou un avenant, il est de votre responsabilit\xE9 d'en informer la compagnie.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_2: "J'ai pris en consid\xE9ration toute ma couverture d'assurance sant\xE9 existante avec Aflac et je crois que cette couverture suppl\xE9mentaire est appropri\xE9e pour mes besoins d'assurance. Je comprends \xE9galement que je peux communiquer avec Aflac au 1-800-992-3522 au sujet de ma police individuelle et pour obtenir de l'aide afin d'\xE9valuer la pertinence de ma couverture d'assurance.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_1: "Si cette couverture doit remplacer une police individuelle Aflac existante, sachez qu'il peut \xEAtre dans votre int\xE9r\xEAt de maintenir votre police individuelle \xE0 renouvellement garanti par facturation directe.",
  TRUPANION_ACCIDENT_DESCRIPTION_4: "Pour autant que je sache, mes r\xE9ponses aux questions sont v\xE9ridiques et compl\xE8tes.  Elles sont offertes \xE0 Continental American Insurance Company comme base pour toute assurance \xE9mise. Je r\xE9alise que toute fausse d\xE9claration ou repr\xE9sentation inexacte dans la demande peut entra\xEEner la perte de la couverture en vertu du certificat. Je comprends qu'aucune assurance ne sera en vigueur \xE0 moins que je ne sois activement au travail \xE0 la date d'entr\xE9e en vigueur de la couverture, et jusqu'\xE0 ce que ma demande soit approuv\xE9e et que la prime n\xE9cessaire soit pay\xE9e. Si je ne suis pas activement au travail \xE0 la date d'entr\xE9e en vigueur de la couverture, celle-ci entrera en vigueur \xE0 la date de mon retour \xE0 un statut de travailleur actif.",
  TRUPANION_ACCIDENT_DESCRIPTION_3: "Si un enfant couvert atteint un \xE2ge limite tel que sp\xE9cifi\xE9 dans le certificat ou un avenant, il est de votre responsabilit\xE9 d'en informer la compagnie.",
  TRUPANION_ACCIDENT_DESCRIPTION_2: "J'ai pris en consid\xE9ration toute ma couverture d'assurance sant\xE9 existante avec Aflac et je crois que cette couverture suppl\xE9mentaire est appropri\xE9e pour mes besoins d'assurance. Je comprends \xE9galement que je peux communiquer avec Aflac au 1-800-992-3522 au sujet de ma police individuelle et pour obtenir de l'aide afin d'\xE9valuer la pertinence de ma couverture d'assurance.",
  TRUPANION_ACCIDENT_DESCRIPTION_1: "Si cette couverture doit remplacer une police individuelle Aflac existante, sachez qu'il peut \xEAtre dans votre int\xE9r\xEAt de maintenir votre police individuelle \xE0 renouvellement garanti par facturation directe.",
  TRUPANION_IMPORTANT_READ_ALL_DESCRIPTION: "Dans le cadre de cette exp\xE9rience, vous pourrez choisir les garanties Aflac Accident, Maladie Grave et Indemnit\xE9 Hospitali\xE8re. Veuillez lire le texte de conformit\xE9 ci-dessous avant de proc\xE9der \xE0 vos choix pour l'inscription ouverte de 2022.",
  TRUPANION_IMPORTANT_READ_ALL: "Important, veuillez lire tout ce qui suit",
  TRIGGER_REFUND_CLAIM_MODAL: "\xCAtes-vous s\xFBr de vouloir rembourser la demande suivante?",
  TRIGGER_RESUBMIT_CLAIM_PSA_PLACEHOLDER: "Saisir un montant CDB",
  TRIGGER_RESUBMIT_CLAIM_HSA_PLACEHOLDER: "Saisir un montant CSS",
  TRIGGER_RESUBMIT_CLAIM_CLAIM_ID_PLACEHOLDER: "Entrez un ID de demande",
  REFUND_CLAIM_HEADING: "Demande de remboursement",
  RETRIGGER_PAYMENT_HEADING: "Red\xE9clencher le paiement",
  PAYMENT_PAYOUT_DETAILS_HEADING: "D\xE9tails du paiement",
  INVALID_ZIP_CODE_NO_FORMAT: "Code postal invalide",
  INVALID_POSTAL_CODE_NO_FORMAT: "Code postal non valide",
  ACTIVITY_STATUS_UPCOMING: "Disponible prochainement",
  ACTIVITY_STATUS_REMOVED: "Ignor\xE9",
  ACTIVITY_STATUS_EXPIRED: "Expir\xE9e",
  ACTIVITY_STATUS: "Statut de l'activit\xE9 : ",
  NEXT_DAY: "Jour suivant",
  PREVIOUS_DAY: "Jour pr\xE9c\xE9dent",
  BACK_TO_TODAY: "Retour \xE0 aujourd'hui",
  YESTERDAY: "Hier",
  TOMORROW: "Demain",
  CONTRIBUTION_CARD_HEADING: "{vendorName} {name}",
  IT_LOOKS_LIKE_YOU_HAVE_SOME_LEFTOVER_WELLNESS_POOL: "Il semble vous rester des {flexDollarTerm}! {message}",
  WELLNESS_POOL_USED_WITH_TERM: "{flexDollarTerm} utilis\xE9e",
  LEFTOVER_WELLNESS_POOL_HELPER_TEXT: "<strong>{percent}%</strong> de votre {flexDollarTerm} non d\xE9pens\xE9s seront ajout\xE9s \xE0 ce compte",
  WELLNESS_POOL_BALANCE_DEDUCTION_DESCRIPTION: "Ces comptes seront prioritaires. Vous pouvez d\xE9penser la totalit\xE9 de votre {flexDollarTerm} sur ces comptes et vos choix pr\xE9c\xE9dents seront couverts par des retenues salariales.",
  WELLNESS_POOL_ELIGIBLE: "Vous pouvez utiliser votre {flexDollarTerm} pour payer cette garantie!",
  TRIGGER_INTERAC_PAYMENT_MODAL: "\xCAtes-vous s\xFBr de vouloir soumettre \xE0 nouveau le paiement suivant ?",
  TRIGGER_INTERAC_PAYMENT_PLACEHOLDER: "Entrez un identifiant de paiement Interac",
  TRIGGER_INTERAC_PAYMENT_BODY: "Saisissez \xABl'identifiant unique\xBB de la transaction pour r\xE9\xE9mettre le paiement.",
  TRIGGER_INTERAC_PAYMENT_HEADING: "Soumettre le paiement Interac \xE0 nouveau",
  VIEW_BENEFIT_ALLOCATION_DRY_RUN: "\u{1F4BE} Voir le test d'allocation des avantages",
  FULL_AMOUNT: "Montant total",
  USER_SELECTION: "S\xE9lection de l'utilisateur",
  DRY_RUN_COVERAGE_VOLUME_OPTION: "Option du volume de couverture",
  DRY_RUN_COVERAGE_VOLUME_AMOUNT_APPROVED: "Montant du volume de couverture approuv\xE9",
  DRY_RUN_COVERAGE_VOLUME_AMOUNT: "Montant du volume de couverture",
  DEPENDENT_COVERAGE: "Couverture pour personnes \xE0 charge",
  ACTIVATION_DATE: "Date d'activation",
  PLAN_NAME: "Nom du r\xE9gime",
  PARENT_BENEFIT_ID: "Identifiant parent des avantages sociaux",
  BENEFIT_DETAILS: "D\xE9tails de la prestation",
  SPECIFY_A_TIME_OPTIONAL: "Sp\xE9cifier une heure (facultatif)",
  ALLOCATED_BENEFITS: "Prestations allou\xE9es",
  DATE_OF_BIRTH_TOOLTIP_NON_QUEBEC: "<strong> Pour les membres non qu\xE9b\xE9cois, </strong> veuillez remplir les informations ci-dessous lorsque vous d\xE9signez un mineur comme b\xE9n\xE9ficiaire.",
  DATE_OF_BIRTH_TOOLTIP_QUEBEC: "<strong>Pour les membres r\xE9sidant au Qu\xE9bec,</strong> si vous d\xE9signez un mineur comme b\xE9n\xE9ficiaire, \xE0 moins qu'une fiducie ne soit cr\xE9\xE9e, le montant payable au mineur (pendant qu'il est encore mineur) sera vers\xE9 au(x) parent(s) ou au(x) tuteur(s) l\xE9gal(aux) du mineur. Pour de plus amples renseignements sur la cr\xE9ation d'une fiducie, veuillez communiquer avec votre assureur.",
  BENEFIT_SET_HEADING: "Votre couverture de {benefitSetName}.",
  X_MISSED: "{missed} manqu\xE9",
  X_OF_Y_COMPLETE: "{completed} / {total} termin\xE9",
  EMAIL_UPDATE_ERROR: "La mise \xE0 jour de l'adresse courriel a \xE9chou\xE9 en raison de {reason}.",
  EMAIL_UPDATE_SUCCESS: "Adresse courriel mise \xE0 jour",
  EMAIL_CONFIRM_MISMATCH: "Les adresses courriel doivent correspondre",
  EMAIL_ADDRESS_ERROR: "Entrez une adresse courriel valide",
  CONFIRM_NEW_EMAIL: "Confirmer le nouveau courriel",
  NEW_EMAIL: "Nouveau courriel",
  CURRENT_EMAIL: "Courriel actuel",
  UPDATE_EMAIL_ADDRESS: "Mettre a jour l'adresse courriel",
  RESET_MFA_ERROR: "La r\xE9initialisation de la m\xE9thode d'authentification multifactorielle a \xE9chou\xE9 car {reason}",
  RESET_MFA_SUCCESS: "La m\xE9thode d'authentification multifactorielle a \xE9t\xE9 r\xE9initialis\xE9e avec succ\xE8s",
  RESET_MFA_CONFIRM: "Un courriel sera envoy\xE9 \xE0 {email} avec un lien pour s'inscrire \xE0 l'authentification multifactorielle.",
  LEGACY_USER: "Utilisateur de mot de passe Legacy",
  AUTH0_USER: "Utilisateur Auth0",
  RESET_LEGACY: "Veuillez utiliser l'outil Legacy pour mettre \xE0 jour les d\xE9tails du compte.",
  RESET_MFA: "R\xE9initialiser l'authentification multifactorielle",
  AUTHENTICATION_METHOD: "M\xE9thode d'authentification",
  ON_LEAVE_REASON: "<strong>Pourquoi est-ce que je ne peux rien modifier?</strong> Vous ne pouvez pas mettre \xE0 jour ou changer votre r\xE9gime d\u2019avantages sociaux lorsque vous \xEAtes en cong\xE9.",
  ENROLLMENT_DETAILS: "D\xE9tails de l'adh\xE9sion",
  ENROLLMENT_DESIGN_OPTIONS_RESET_ERROR: "Erreur lors de l'application des configurations par d\xE9faut",
  ENROLLMENT_DESIGN_OPTIONS_RESET_SUCCESS: "Configurations par d\xE9faut appliqu\xE9es avec succ\xE8s\xA0!",
  APPLY_DEFAULT_CONFIG_WARNING: "Toutes les personnalisations apport\xE9es \xE0 la configuration de l'exp\xE9rience d'adh\xE9sion seront perdues. Cette action ne peut pas \xEAtre annul\xE9e.",
  APPLY_DEFAULT_CONFIG_DESCRIPTION: "L'application de la configuration par d\xE9faut remplacera la configuration existante de l'exp\xE9rience d'adh\xE9sion. L'ordre, le regroupement, la mise en page et le contenu de toutes les \xE9tapes, groupes et ensembles d'avantages seront d\xE9finis pour correspondre \xE0 la configuration par d\xE9faut.",
  APPLY_DEFAULT_CONFIG_TITLE: "Appliquer les configurations par d\xE9faut",
  APPLY_DEFAULT_CONFIG_BUTTON: "Appliquer la configuration par d\xE9faut",
  OPEN_IN_APP_BUTTON: "Ouvrir dans l'application",
  TRY_OUR_APP: "Ce contenu para\xEEt beaucoup mieux dans l'application, essayez-la!",
  ENROLLMENT_CREATE_TEMPLATE_TYPE: "Cr\xE9er un mod\xE8le pour l'ID {type}",
  ENROLLMENT_TEMPLATES_CREATED_ERROR: "Erreur lors de la cr\xE9ation d'un nouveau mod\xE8le Contentful",
  ENROLLMENT_TEMPLATES_CREATED_SUCCESS: "Cr\xE9ation d'un nouveau mod\xE8le Contentful r\xE9ussie!",
  DESIGNATE_REVOCABLE_BENEFICIARY: "Le cas \xE9ch\xE9ant, d\xE9signer mon conjoint comme b\xE9n\xE9ficiaire r\xE9vocable",
  REVOCABLE_DESIGNATIONS_QUEBEC_RESIDENTS: "Pour les r\xE9sidents du Qu\xE9bec, si vous d\xE9signez votre conjoint l\xE9gal (mariage ou union de fait) comme b\xE9n\xE9ficiaire, ce b\xE9n\xE9ficiaire sera irr\xE9vocable pour toutes les assurances ci-dessous, \xE0 moins que vous ne cochiez la case ci-dessous.",
  EDITING_PANEL_LAYOUT_PLACEHOLDER: "S\xE9lectionnez une mise en page...",
  EDITING_PANEL_LAYOUT: "Mise en page",
  EDITING_PANEL_URL_MUST_BE_VALID: "L'URL Slug n'est pas valide, seules les lettres, les caract\xE8res de soulignement (_) ou les tirets (-) sont autoris\xE9s.",
  EDITING_PANEL_URL_MUST_BE_UNIQUE: '"{url}" est utilis\xE9 par une autre \xE9tape. Veuillez choisir un slug URL diff\xE9rent.',
  EDITING_PANEL_URL_IS_REQUIRED: "L'URL Slug est requis.",
  EDITING_PANEL_URL_HINT: `Le slug URL de l'\xE9tape est ajout\xE9 \xE0 la fin de l'URL pour identifier l'\xE9tape, par exemple "m\xE9dical" ou "dentaire". Le slug de l'URL doit \xEAtre unique et ne doit contenir que des lettres, des tirets (_) ou des tirets (-).`,
  EDITING_PANEL_URL_PLACEHOLDER: "Entrez l'URL slug de l'\xE9tape...",
  EDITING_PANEL_URL: "URL Slug",
  EDITING_PANEL_NAME_IS_REQUIRED: "Le nom est requis.",
  EDITING_PANEL_NAME_HINT: "Le nom est uniquement utilis\xE9 dans les outils de conception des inscriptions. Pour modifier le nom affich\xE9 dans l'exp\xE9rience d'inscription, il faut mettre \xE0 jour le mod\xE8le contentful s\xE9lectionn\xE9 ci-dessous.",
  EDITING_PANEL_NAME_PLACEHOLDER: "Entrez le nom...",
  EDITING_PANEL_NAME: "Nom",
  PRONOUNS_OPTIONAL: "Pronoms (facultatif)",
  GENDER_IDENTITY_OPTIONAL: "Identit\xE9 sexuelle (facultatif)",
  CAROUSEL_LIFEMARKET_DESCRIPTION: "Des offres exclusives pour votre sant\xE9, votre forme physique et votre mode de vie.",
  CAROUSEL_LIFEMARKET_HEADING: "March\xE9vie",
  LIFEMARKET_RECEIPT_FOOTER: "Merci d'avoir choisi League. Si vous avez des questions, veuillez nous contacter avec {leagueChatLink}.",
  LIFEMARKET_RECEIPT_DESCRIPTION: "Si ce service est couvert par votre r\xE9gime d'assurance maladie, vous pouvez utiliser ce re\xE7u comme preuve d'achat pour votre assureur.",
  SPANISH: "Espagnol",
  DELETE_ENROLLMENT_GROUP: "Supprimer le groupe d'ensembles de prestations",
  DELETE_ENROLLMENT_STEP: "Supprimer l'\xE9tape",
  UPDATE_AMOUNT_SUCCESS: "Succ\xE8s ! Montant mis \xE0 jour.",
  KPMG_EQUITY_PARTNER_WITH_OPTIONAL_LTD_EOI_FORM_INSTRUCTIONS: "<strong>Instructions :</strong> veuillez ne pas t\xE9l\xE9charger le formulaire de preuve d'assurabilit\xE9 (PA). L'\xE9quipe de Re\u0301mune\u0301ration Globale vous fournira le formulaire requis \xE0 remplir.",
  CREATE_GROUP_TEXT: "Ajouter un groupe",
  CREATE_STEP_TEXT: "Cr\xE9er une \xE9tape",
  ENROLLMENT_CREATE_TEMPLATE_CLASS: "Cr\xE9er un mod\xE8le personnalis\xE9 pour l'ID de la classe d'avantages",
  ENROLLMENT_CREATE_TEMPLATE_GROUP: "Cr\xE9er un mod\xE8le personnalis\xE9 pour l'ID du groupe",
  ENROLLMENT_DEFAULT_TEMPLATE: "Mod\xE8le par d\xE9faut",
  ENROLLMENT_TEMPLATE_STATUS: "Statut du mod\xE8le",
  ENROLLMENT_CONTENTFUL_DESCRIPTION: "Cette int\xE9gration r\xE9cup\xE8re le contenu de cet avantage \xE0 partir de Contentful (notre syst\xE8me de gestion du contenu) pour l'afficher dans l'exp\xE9rience d'inscription.",
  ENROLLMENT_CONTENTFUL_INFO_BANNER: "Veillez \xE0 enregistrer la configuration apr\xE8s avoir modifi\xE9 le mod\xE8le s\xE9lectionn\xE9,",
  ENROLLMENT_CONTENTFUL_ERROR_BANNER: "Aucun mod\xE8le applicable trouv\xE9 dans Contentful. Veuillez cr\xE9er un nouveau mod\xE8le ci-dessous ou contacter les op\xE9rations pour faire cr\xE9er du contenu.",
  ENROLLMENT_CONTENTFUL_DEFAULT_ERROR_BANNER: "Il n'y a pas de mod\xE8le par d\xE9faut. Veuillez utiliser un mod\xE8le personnalis\xE9 ou cr\xE9er un nouveau mod\xE8le ci-dessous.",
  ENROLLMENT_DEFAULT_TEMPLATE_TOOLTIP: "Les mod\xE8les par d\xE9faut sont partag\xE9s par tous les employeurs d'un m\xEAme pays. Si n\xE9cessaire, veuillez contacter les op\xE9rations pour cr\xE9er ou modifier un mod\xE8le.",
  TRUSTEE_APPOINTMENT_DESCRIPTION: "Si le ou la b\xE9n\xE9ficiaire a moins de 18 ans, veuillez nommer un fiduciaire. En remplissant les champs ci-dessous, j'autorise le fiduciaire \xE0 recevoir tous les paiements au nom du b\xE9n\xE9ficiaire \xE2g\xE9 de moins de 18 ans et \xE0 utiliser les sommes uniquement pour son soutien, son entretien, son \xE9ducation et son b\xE9n\xE9fice propre et unique, \xE0 sa discr\xE9tion.",
  TRUSTEE_APPOINTMENT: "Rendez-vous avec le fiduciaire",
  COMPANY_NAME: "Nom de l'entreprise",
  GROUP_DRAG_ERROR_WITHIN_LIST: "Impossible de d\xE9placer le groupe vers la liste des ensembles d'avantages disponibles",
  TRUSTEE: "Fiduciaire",
  ENROLLMENT_DESIGN_SELECT_TO_EDIT: "Veuillez s\xE9lectionner une \xE9tape, un groupe d'ensembles d'avantages ou un ensemble d'avantages pour commencer la modification.",
  ENROLLMENT_DESIGN_SETTINGS: "Param\xE8tres",
  PAY_GROUP: "Groupe de paie",
  YTD_END_DATE: "Date de fin",
  START_DATE: "Date de d\xE9but",
  YTD_REPORT_COPY: "Ce rapport montre les d\xE9ductions de primes, les ajustements et les contributions TASA pour le ou les membres qui r\xE9pondent aux crit\xE8res s\xE9lectionn\xE9s pendant les dates sp\xE9cifi\xE9es.",
  YTD_REPORT: "Rapport depuis le d\xE9but de l\u2019ann\xE9e",
  ENROLLMENT_DESIGN_OPTIONS_SAVED_ERROR: "Erreur lors de l'enregistrement des options de conception d'inscription",
  ENROLLMENT_DESIGN_OPTIONS_SAVED_SUCCESS: "Options de conception d'inscription enregistr\xE9es!",
  DEPENDENT_EDITED_SUCCESS_MESSAGE: "Vous avez correctement modifi\xE9 les renseignements sur votre personne \xE0 charge",
  DEPENDENT_EDITED_SUCCESS_HEADING: "D\xE9tails relatifs \xE0 la personne \xE0 charge enregistr\xE9s",
  DEPENDENT_ADDED_SUCCESS_MESSAGE: "Votre personne \xE0 charge est d\xE9sormais couverte par vos avantages.",
  DEPENDENT_ADDED_SUCCESS_HEADING: "Nouvelle personne \xE0 charge ajout\xE9e",
  DEPENDENT_ERROR_MESSAGE: "Une erreur est survenue, veuillez r\xE9essayer d'ajouter votre personne \xE0 charge.",
  ADDRESS_2_OPTIONAL: "Adresse 2 (facultative)",
  ZIP_CODE_OR_POSTAL_CODE: "Code Zip/Postal",
  STATE_PROVINCE_REGION: "\xC9tat / province / r\xE9gion",
  TAX_ID_OPTIONAL: "Num\xE9ro d'identification fiscale (facultatif)",
  ORGANIZATION_LEGAL_NAME: "Nom l\xE9gal de l'organisation",
  RELATIONSHIP_TO_YOU: "Relation avec vous",
  SOCIAL_SECURITY_NUMBER_OPTIONAL: "Num\xE9ro de s\xE9curit\xE9 sociale (facultatif)",
  SOCIAL_INSURANCE_NUMBER_OPTIONAL: "Num\xE9ro d'assurance sociale (facultatif)",
  MIDDLE_NAME_OPTIONAL: "Deuxi\xE8me pr\xE9nom (facultatif)",
  PROVINCE_OF_EMPLOYMENT: "Province d'emploi",
  WHAT_DEVICES_CAN_CONNECT: "Quelles sont les applications et les appareils pris en charge ?",
  CONNECT_DEVICES_IMAGE_ALT: "Femme examinant les donn\xE9es d'un appareil connect\xE9",
  DONT_HAVE_THE_APP: "Vous n'avez pas l'application mobile League ?",
  CONNECTING_UNAVAILABLE_DETAILS: "Pour l'instant, veuillez consulter l'application mobile League pour connecter vos applications et appareils de sant\xE9 pr\xE9f\xE9r\xE9s.",
  CONNECTING_UNAVAILABLE: "La connexion d'applications et d'appareils n'est pas disponible sur le Web",
  DONT_CONNECT_APPS_DEVICES: "Ne pas suivre mes progr\xE8s automatiquement",
  CONNECT_APPS_DEVICES: "Connecter des applis et des appareils",
  CONNECT_TO_TRACK: "Connectez vos applications et appareils de sant\xE9 pr\xE9f\xE9r\xE9s pour marquer automatiquement les activit\xE9s comme termin\xE9es.",
  TRACK_AUTOMATICALLY: "Suivez vos progr\xE8s automatiquement",
  HEALTH_PROGRAMS_CONNECTED_PROGRAM: "Programme sant\xE9 connect\xE9",
  HEALTH_PROGRAMS_SYNC_DEVICE: "Synchronisez votre {device} pour une exp\xE9rience optimale du programme.",
  DEVICE: "appareil",
  ACTIVE_DURATION: "minutes actives",
  STEPS: "pas",
  X_OF_Y: "{current} sur {total}",
  USER_TERMS_UPDATE_FAILED: "Une erreur est survenue lors de l'acceptation des conditions g\xE9n\xE9rales",
  AVAILABLE_BENEFIT_SETS: "Ensembles de prestations disponibles",
  DOWNLOAD_PLAY_STORE: "T\xE9l\xE9charger sur Google Play",
  DOWNLOAD_APP_STORE: "T\xE9l\xE9charger sur l'App Store",
  TEXT_ME_DOWNLOAD_LINK: "Envoyer moi un texto avec un lien de t\xE9l\xE9chargement",
  GET_THE_APP: "Obtenez l'application pour g\xE9rer vos avantages sociaux personnalis\xE9s, acc\xE9der \xE0 des offres exclusives dans notre March\xE9 Sant\xE9 ou contacter notre service client\xE8le hors pair!",
  DONT_HAVE_OUR_APP: "Vous n'avez pas notre application?",
  PLAN_ID: "Identifiant du r\xE9gime",
  CLASS_PLAN_NAME: "R\xE9gime",
  ENROLLMENT_DESIGN_SET: "Ensemble de garantie",
  ENROLLMENT_DESIGN_GROUP: "Groupe d'ensemble de prestations",
  ENROLLMENT_DESIGN_STEP: "\xC9tape",
  ENROLLMENT_DESIGN_STEPS: "\xC9tapes",
  NO_PLAN_SELECTION_RESULTS: "Aucun r\xE9sultats",
  ENTER_CLASS_OR_PLAN: "Entrer le nom de la cat\xE9gorie ou du nom du r\xE9gime",
  GOOD_EVENING_WITH_NAME: "Bonsoir, {name} !",
  GOOD_AFTERNOON_WITH_NAME: "Bonjour, {name} !",
  GOOD_MORNING_WITH_NAME: "Bonjour, {name}\xA0!",
  CREATE: "Cr\xE9er {label}",
  ONLY_IN_LIFEMARKET: "Peut seulement \xEAtre \xE9chang\xE9 dans le March\xE9vie",
  REPORTS_SECTION_DESCRIPTION: "Ceci sont les rapports qui alimentent les informations que vous voyez dans ce tableau de bord.",
  PLAN_ID_VALUE: "ID du r\xE9gime : {planId}",
  MAP: "Carte",
  TRACE: "Trace",
  BENEFIT_ALLOCATION_DRY_RUN: "Test d'allocation des avantages",
  EXIT_ACTIVITY_CTA: "Quitter l'activit\xE9",
  EXIT_ACTIVITY_BODY: "Votre progression ne sera pas sauvegard\xE9e et vous devrez recommencer l'activit\xE9 depuis le d\xE9but.",
  EXIT_ACTIVITY_TITLE: "\xCAtes-vous certain de vouloir quitter cette activit\xE9?",
  COMPANY_PROFILE: "Profil de l'entreprise",
  ENROLLMENT_DESIGN_PREV_STEP_BUTTON: "\xC9tape pr\xE9c\xE9dente",
  ENROLLMENT_DESIGN_NEXT_STEP_BUTTON: "\xC9tape suivante",
  NO_PLAN_MESSAGE: "Il n'y a pas de r\xE9gimes",
  BENEFIT_CONFIG_TITLE: "Configurer les ensembles d'avantages",
  NEXT_STEP: "\xC9tape suivante",
  I_WOULD_LIKE_TO_OPTIN_MARKETING: "J'aimerais recevoir des courriels incluant des r\xE9compenses et des rabais pour les services de sant\xE9 et de bien-\xEAtre.",
  I_AGREE_TO_THE_TERMS_AND: "J'accepte les modalit\xE9s et conditions ainsi que la politique de confidentialit\xE9.",
  PLEASE_REVIEW_OUR_TERMS_CONDITIONS: "Veuillez passer en revue nos modalit\xE9s et conditions ainsi que notre <a>politique de confidentialit\xE9</a>",
  TERMS_AND_PRIVACY: "Modalit\xE9s et confidentialit\xE9",
  CONTACT_TC_EMAIL: "Contactez {helpEmail} si vous avez des questions sur cette mise \xE0 jour.",
  TO_SERVE_YOU_BETTER: "Pour vous offrir un meilleur service, nous tenions \xE0 vous informer des changements qui ont eu lieu. Veuillez lire et accepter les nouvelles modalit\xE9s et conditions pour continuer \xE0 utiliser League.",
  WEVE_REVISED_OUR_TERMS: "Nous avons r\xE9vis\xE9 nos modalit\xE9s et conditions",
  HEALTH_TOOLS: "Outils sant\xE9",
  WALLET_TOOLS: "Outils du portefeuille",
  SCRIPT_HELPERS: "Aides de script",
  SUBMIT_FEEDBACK: "Soumettre un commentaire",
  FORM_OPENS_IN_A_NEW_TAB: "Ouvre le sondage aupr\xE8s des employ\xE9s dans un nouvel onglet",
  ENROLLMENT_EXPERIENCE_CONFIG: "Configuration de l'exp\xE9rience d'inscription",
  BENEFIT_SET_CONFIG: "Configuration de l'ensemble des avantages",
  SELECT_PLAN: "S\xE9lectionnez un r\xE9gime",
  CURRENT_STEP: "\xC9tape {stepNumber}: {stepName}",
  NO_PLAN_SELECTED: "Aucun r\xE9gime s\xE9lectionn\xE9",
  "SELECTED PLAN": "R\xE9gime s\xE9lectionn\xE9",
  DEPENDENT_COVERED_BY_HSA_ONLY: "Remarque : Cette personne \xE0 charge sera uniquement couverte par votre compte soins de sant\xE9.",
  CHANGE_DEPENDENT_RELATIONSHIP_LINK: "communiquer avec le service \xE0 la client\xE8le",
  CHANGE_DEPENDENT_RELATIONSHIP: "Pour modifier votre relation avec votre personne \xE0 charge, veuillez {emailLink}.",
  EDIT_DEPENDENT_CONTACT_CUST_CARE_LINK: "clavarder avec un conseiller",
  EDIT_DEPENDENT_CONTACT_CUST_CARE: "Si vous devez apporter des modifications ou supprimer cette personne \xE0 charge, veuillez {chatLink}.",
  ENROLLMENT_EXPERIENCE_CONFIG_TITLE: "Configurez votre exp\xE9rience d'inscription",
  ENROLLMENT_DESIGN_PLAN_SELECTION_TITLE: "S\xE9lectionnez un r\xE9gime",
  MAIN_NAVIGATION_LIST_LABEL: "\xC9l\xE9ments de navigation de League",
  YOU_EARNED_X_POINTS: "Vous avez accumul\xE9 {points} points!",
  PROPERTY_NAME_UNAVAILABLE: "Pas de {property} Nom",
  COPY_ACTIVITY_CAMPAIGN_ID_HINT: "*Cliquez sur le nom d'une activit\xE9 ou d'une campagne pour copier l'ID d'une activit\xE9 ou d'une campagne respectivement.",
  EDIT_DETAILS: "Modifier les d\xE9tails",
  COMPLETED_EXPIRED_REMOVED_ACTIVITIES: "D\xE9tails des activit\xE9s termin\xE9es, expir\xE9es, supprim\xE9es et \xE0 venir",
  HEALTH_ACTIVITIES: "Activit\xE9s de sant\xE9",
  COPIED_PROPERTY: "{property} copi\xE9",
  COPY_PROPERTY: "Copier {property}",
  DOCUMENT_FORM_HAS_ERRORS: "Vous devez remplir tous les champs obligatoires avant de soumettre un document.",
  EDIT_INTERAC_DEPOSIT: "Modifier le virement Interac",
  INVITE_ERROR_NOTE: "Veuillez r\xE9essayer le lien figurant dans votre courriel d'invitation, ou envoyez un courriel au <a>service \xE0 la client\xE8le</a> pour obtenir de l'aide.",
  INVITE_ERROR_SOMETHING_WENT_WRONG: "Nous sommes d\xE9sol\xE9s, quelque chose n'a pas fonctionn\xE9.",
  INVITE_REDEEMED_NOTE: "Il semble que ce lien d'invitation ait d\xE9j\xE0 \xE9t\xE9 utilis\xE9. Vous pouvez maintenant vous connecter \xE0 votre compte.",
  YOUR_INVITE_WAS_REDEEMED: "Votre invitation a \xE9t\xE9 \xE9chang\xE9e",
  INVITE_EXPIRED_NOTE: "Ce lien d'invitation n'est plus valide. Veuillez demander au titulaire principal du compte de vous envoyer un nouveau courriel d'invitation.",
  YOUR_INVITE_HAS_EXPIRED: "Votre invitation a expir\xE9",
  YOULL_GET_TOOLS: "Vous obtiendrez des outils, des ressources et un soutien pour vous aider \xE0 vivre plus heureux et plus sainement chaque jour. Cela ne prend que quelques minutes et nous vous guiderons \xE0 chaque \xE9tape du processus.",
  OPTIONS_MENU_FOR_DEPENDENT: "Menu d'options pour {dependent}",
  INVITATION_STATUS: "Statut de l'invitation : ",
  TASA_CONTRIBUTIONS: "Cotisations TASA",
  POST_TAX_COST: "Co\xFBt apr\xE8s imp\xF4t",
  PRE_TAX_COST: "Co\xFBt avant imp\xF4t",
  IMPUTED_INCOME: "Revenu imput\xE9",
  QUEBEC_TAXABLE_BENEFIT: "Avantage imposable du Qu\xE9bec",
  FLEX_COST: "Co\xFBt flexible",
  EMPLOYER_COST: "Co\xFBt pour l'employeur",
  EMPLOYEE_COST: "Co\xFBt pour l'employ\xE9",
  NO_PAYROLL_DEDUCTIONS: "Aucune retenue sur salaire",
  COST_TYPE: "Type de co\xFBt",
  PAYROLL_DEDUCTIONS_COPY: "Visualiser et g\xE9rer les retenues sur salaire",
  QLE_LOST_MY_COVERAGE: "J'ai perdu la couverture",
  FIELD_REQUIRED: "{field} est obligatoire.",
  PAGE_TITLE_HEALTH_PROGRAM_CATEGORY: "Cat\xE9gorie du programme sant\xE9",
  PAGE_TITLE_ACTIVITY_DETAILS: "D\xE9tails de l'activit\xE9",
  VIEW_BENEFIT_SELECTIONS_DIFF: "\u{1F575}\uFE0F Afficher l'historique des choix de prestations du r\xE9gime",
  VIEW_CARD_FOR_DEPENDENT: "Voir la carte pour {dependentName}",
  JUMP_TO_NAVIGATOR_LINK: "Aller \xE0 la navigation",
  SKIP_TO_CONTENT_MESSAGE: "Aller au contenu",
  COVERAGE_SEARCH_BEHAVIOUR_DESCRIPTION: "Les r\xE9sultats de la recherche s'actualisent automatiquement au fur et \xE0 mesure que vous tapez",
  COVERAGE_CATEGORIES: "Cat\xE9gories de couverture",
  HOME_PAGE: "Page d'accueil",
  FILE_TRANSFER_IS_CANCELED: "Le transfert de fichier a \xE9t\xE9 annul\xE9.",
  YOUR_FILE_FAILED_TO_UPLOAD: "Votre fichier n'a pas pu \xEAtre t\xE9l\xE9charg\xE9.",
  YOUR_FILE_HAS_BEEN_UPLOADED: "Votre fichier a bien \xE9t\xE9 tel\xE9charg\xE9 pour le conseiller.",
  THE_AGENT_HAS_REQUESTED_A_FILE: "Le conseiller a demand\xE9 le t\xE9l\xE9chargement d'un fichier.",
  THE_AGENT_HAS_ENDED_THE_CHAT: "Le conseiller a ferm\xE9 la discussion.",
  NEW_MESSAGE_FROM_THE_AGENT: "Nouveau message du conseiller.",
  THE_AGENT_IS_TYPING: "Le conseiller est en train d'\xE9crire.",
  REWARDS_ERROR: "Erreur dans les r\xE9compenses",
  TITLE_BY_PROVIDER_FOR_PRICE: "{title} par {provider} pour {price}",
  INVALID_CHARACTER_ERROR_MESSAGE: 'Ce champ contient des caract\xE8res invalides. "{invalidCharacter}"',
  VERIFICATION_CALL_SENT: "Appel de v\xE9rification envoy\xE9",
  VERIFICATION_CODE_SENT: "Code de v\xE9rification envoy\xE9",
  OPEN_DOCUMENT_INSTRUCTION_HIDDEN: "Ouvrir {documentType} dans un nouvel onglet",
  YOU_HAVENT_ADDED_ANY_DEPENDENTS: "Vous n\u2019avez pas ajout\xE9 de personne \xE0 charge.",
  SUITE_NUMBER_SENTENCE_CASE: "Num\xE9ro de bureau ou d'appartement (facultatif)",
  STREET_ADDRESS_SENTENCE_CASE: "Nom de la rue",
  WHAT_SHOULD_WE_CALL_YOU_SENTENCE_CASE: "Comment devrions-nous vous appeler? (facultatif)",
  LEGAL_FIRST_NAME_SENTENCE_CASE: "Pr\xE9nom l\xE9gal",
  ZIP_CODE_SENTENCE_CASE: "Code postal",
  POSTAL_CODE_SENTENCE_CASE: "Code postal",
  NORTHERN_MARIANA_ISLANDS: "\xCEles Mariannes du Nord",
  MARSHALL_ISLANDS: "\xCEles Marshall",
  FEDERATED_STATES_OF_MICRONESIA: "\xC9tats f\xE9d\xE9r\xE9s de Micron\xE9sie",
  LEGAL_LAST_NAME: "Nom de famille l\xE9gal",
  WALLET_LANDING_NAV_LABEL: "Navigation dans le portefeuille",
  FIELD_MUST_NOT_BE_EMPTY: "Le champ ne doit pas \xEAtre vide",
  YOU_HAVE_BEEN_DISCONNECTED: "Vous avez \xE9t\xE9 d\xE9connect\xE9. Veuillez patienter, nous tentons de vous ramener \xE0 votre conversation.",
  TRYING_TO_RECONNECT: "Tentative de reconnexion...",
  YOUR_CHAT_ENDED_BECAUSE_ISSUE_WITH_CONNECTION: "La conversation s'est termin\xE9e \xE0 cause d'un probl\xE8me de connexion. Veuillez v\xE9rifier si vous \xEAtes connect\xE9 \xE0 Internet et r\xE9essayez.",
  CONNECTION_LOST: "Connexion perdue",
  NO_AGENTS_ARE_AVAILABLE_AT_THE_MOMENT: "Aucun conseiller n'est disponible pour le moment. Votre clavardage est donc termin\xE9. Veuillez r\xE9essayer plus tard.",
  YOUR_CHAT_HAS_ENDED: "Votre clavardage est termin\xE9.",
  ARE_YOU_SURE_YOU_WANT_TO_END_THIS_CHAT: "Voulez-vous vraiment fermer ce clavardage? Pas d'inqui\xE9tude, vous pourrez toujours exporter votre historique de conversation ult\xE9rieurement.",
  END_CHAT: "Terminer le clavardage",
  PRICE_PERIOD_PREMIUM_TOOLTIP_CONTENT: "Pour que votre forfait reste actif, vous devez payer ces frais {periodType}. Cela ne contribue pas \xE0 une franchise ou un d\xE9bours\xE9 maximum.",
  PRICE_PERIOD_PREMIUM: "Prime {periodType}",
  EMPLOYER_COST_TABLE_INFO: "Votre employeur contribue \xE0 hauteur de <strong>{amount}</strong> / {periodType} pour ce plan",
  YOU_CURRENTLY_HAVE_NO_DEPENDENTS: "Vous n'avez actuellement aucune personne \xE0 charge associ\xE9e \xE0 ce compte.",
  NO_DEPENDENTS: "Aucune personne \xE0 charge",
  PASSWORD: "Mot de passe",
  COMMUNICATION_PREFERENCES: "Pr\xE9f\xE9rences de communication",
  MOBILE_PHONE_NUMBER_UPDATED: "Num\xE9ro de cellulaire mis \xE0 jour",
  MOBILE_PHONE_NUMBER: "Num\xE9ro de cellulaire",
  ACCOUNT_DETAILS: "D\xE9tails du compte",
  PERSONAL_INFO: "Informations personnelles",
  LOOKS_LIKE_CHANGES: "Certaines modifications n'ont pas \xE9t\xE9 sauvegard\xE9es. Si vous quittez cette page, ces changements seront perdus.",
  WE_NEED_TO_MAKE_SURE_ONLY_YOU: "Nous en avons besoin pour nous assurer que vous soyez la seule personne \xE0 pouvoir acc\xE9der \xE0 votre compte. Nous ne communiquerons votre num\xE9ro de t\xE9l\xE9phone \xE0 personne.",
  YOU_HAVE_UNSAVED: "Certaines modifications n'ont pas \xE9t\xE9 sauvegard\xE9es.",
  CHANGE_PHOTO: "Changer la photo",
  THIS_PHOTO_SHOWS_UP: "Cette photo appara\xEEt sur votre profil d'utilisateur et votre profil Sant\xE9.",
  GET_DEPENDENTS_ERROR: "\xC9chec de la r\xE9cup\xE9ration des personnes \xE0 charge",
  CHANGING_YOUR_EMAIL_ADDRESS_WILL_ALSO: "La modification de votre adresse courriel modifiera \xE9galement vos informations d'identification.",
  NO_PHONE_NUMBER_FOUND: "Aucun num\xE9ro de t\xE9l\xE9phone trouv\xE9",
  PROFILE_PHOTO: "Photo de profil",
  UPLOAD_PHOTO: "T\xE9l\xE9verser une photo",
  DRAG_AND_DROP_PHOTO: "Glisser et d\xE9poser la photo",
  UPDATE_PHOTO: "Mettre \xE0 jour la photo",
  PASSWORD_UPDATED: "Mot de passe mis \xE0 jour",
  CHANGE_PASSWORD_ERROR: "\xC9chec de la modification du mot de passe",
  CONFIRM_NEW_PASSWORD: "Confirmez le nouveau mot de passe",
  NEW_PASSWORD: "Nouveau mot de passe",
  CURRENT_PASSWORD: "Mot de passe actuel",
  CHOOSE_A_STRONG_PASSWORD: "Choisissez un mot de passe s\xE9curis\xE9 que vous utiliserez uniquement pour ce compte.",
  CHANGE_PASSWORD: "Modifier votre mot de passe",
  PHONE_NUMBER_SAME: "Les num\xE9ros de t\xE9l\xE9phone sont les m\xEAmes, veuillez r\xE9essayer",
  VERIFICATION_CODE_FAILED: "\xC9chec du code de v\xE9rification",
  SEND_VERIFICATION_CODE: "Envoyer le code de v\xE9rification",
  WELL_TEXT_YOU: "Nous vous enverrons un code de confirmation par message texte",
  EDIT_MOBILE_PHONE_FAILED: "\xC9chec de la modification du num\xE9ro de t\xE9l\xE9phone mobile",
  EDIT_MOBILE_PHONE: "Modifier votre num\xE9ro de cellulaire",
  CALL_ME_INSTEAD: "Je pr\xE9f\xE8re que l\u2019on m\u2019appelle",
  SEND_AGAIN: "Envoyer le code \xE0 nouveau",
  ENTER_YOUR_VERIFICATION_CODE: "Veuillez entrer votre code de validation",
  VERIFICATION_CODE: "Code de v\xE9rification",
  SENT_TO_YOUR_MOBILE: "Un code de v\xE9rification a \xE9t\xE9 envoy\xE9 \xE0 votre num\xE9ro de t\xE9l\xE9phone mobile.",
  ENTER_VERIFICATION_CODE: "Entrez le code de v\xE9rification",
  DISCARD: "Supprimer",
  ARE_YOU_SURE_DISCARD_CHANGES: "Voulez-vous vraiment annuler ces modifications?",
  PROFILE_UPDATE_FAILED: "\xC9chec de la mise \xE0 jour du profil",
  PROFILE_UPDATED: "Le profil a \xE9t\xE9 mis \xE0 jour",
  SEX_TOOLTIP: "La compagnie d\u2019assurance avec qui nous collaborons a besoin de conna\xEEtre votre sexe afin d'administrer vos avantages sociaux.<br/><br/> Notre \xE9quipe s\u2019engage \xE0 garantir la s\xE9curit\xE9 et le respect de tous nos membres. Si vous souhaitez nous fournir des pr\xE9cisions sur la fa\xE7on dont vous vous identifiez, veuillez remplir les champs du formulaire ci-dessous pour ajouter votre identit\xE9 de genre et vos pronoms.",
  THE_PROGRAM_WILL_APPEAR_IN_JOURNEY: "Le programme appara\xEEtra d\xE9sormais dans votre parcours",
  PROGRAM_ADDED: "Programme ajout\xE9",
  PROGRAM_REMOVED: "Programme retir\xE9",
  ARE_YOU_SURE_REMOVE_PROGRAM: "Voulez-vous vraiment retirer ce programme de votre parcours? Tous les progr\xE8s faits dans ce programme seront perdus et vous devrez recommencer.",
  REMOVE_PROGRAM: "Retirer le programme?",
  YOU_WILL_NO_LONGER_SEE_IN_JOURNEY: "Vous ne le verrez plus dans votre parcours",
  COLLAPSE: "r\xE9duire",
  EXPAND: "\xC9largir",
  QLE_LINK_LABEL: "En apprendre plus sur les \xE9v\xE9nements de vie admissibles",
  VIEW_CLAIM_OF_STATUS: "Afficher la demande {status}",
  CLAIM_DETAIL_EXPAND_DOCUMENT_HIDDEN: "D\xE9velopper le document {documentType}",
  PREVIOUS: "Pr\xE9c\xE9dent",
  PLAN_BOOKLET: "Livret du r\xE9gime",
  CLAIM_DETAIL_ASIDE_HEADING: "Options de r\xE9clamation",
  COMPLETE_ACTIVE_PROGRAM_BEFORE_NEW_ONE: "Terminez un programme en cours avant d'en ajouter un nouveau. <strong>{ctaText}</strong>",
  LOOKS_LIKE_REACHED_PROGRAM_LIMIT: "Il semble que vous avez atteint la limite de votre programme!",
  EMPLOYER_COST_BANNER_WITH_FLEX_DOLLAR: "Nous avons fait le calcul! Votre employeur a contribu\xE9 \xE0 hauteur de {amount} \xE0 votre sant\xE9 et \xE0 votre bien-\xEAtre pour l'ann\xE9e. Ce total comprend votre {flexDollarTerm} et toutes les contributions aux prestations.",
  EMPLOYER_COST_BANNER_NO_FLEX_DOLLAR: "Nous avons fait le calcul! Votre employeur a contribu\xE9 \xE0 hauteur de {amount} \xE0 votre sant\xE9 et \xE0 votre bien-\xEAtre pour l'ann\xE9e.",
  EMPLOYER_COST_BANNER_HEADER: "Votre employeur investit dans vous et votre sant\xE9",
  SHOW_LESS: "Voir moins",
  CLAIMS_TABLE_READ_MORE_HIDDEN: "Lien vers les d\xE9tails de la demande",
  DOCUMENT_THUMBNAIL_HIDDEN: "Document en pi\xE8ce jointe",
  DOCUMENT_MULTIPLE_HIDDEN: "Plusieurs documents en pi\xE8ces jointes",
  DOCUMENT_THUMBNAIL_PDF_HIDDEN: "PDF",
  PROVINCE_OF_REGISTRATION: "Province d'inscription",
  PATIENT_GUID: "GUID du patient",
  PATIENT_DETAILS: "Informations du patient",
  IN_PROGRESS: "En cours",
  CANCELED: "Annul\xE9",
  COMPLETED: "Compl\xE9t\xE9",
  STORE_ADDRESS: "Adresse du magasin",
  STORE_PHONE: "T\xE9l\xE9phone du magasin",
  STORE_NAME: "Nom du magasin",
  APPOINTMENT_DATE: "Date du rendez-vous",
  APPOINTMENT_STATUS: "Statut du rendez-vous",
  BOOKING_EXPIRY_DATE: "Date d'expiration de la r\xE9servation",
  BOOKING_DATE: "Date de r\xE9servation",
  BOOKING_STATUS: "Statut de la r\xE9servation",
  ELIGIBILITY_DATE: "Date d'admissibilit\xE9",
  ELIGIBILITY_STATUS: "Statut d'admissibilit\xE9",
  REGISTRATION_CODE: "Code d'inscription",
  REQUESTED_DATE: "Date demand\xE9e",
  REQUEST_STATUS: "Statut de la demande",
  APPOINTMENT_DETAILS_FOR_DOSE: "D\xE9tails du rendez-vous pour la dose : {dose} / {seriesDoses}",
  REGISTRATION_DETAILS: "D\xE9tails de l'inscription",
  VACCINE_JOURNEY: "Parcours vaccinal",
  COVID_19_VACCINE_JOURNEY: "Parcours vaccinal COVID-19",
  VIEW_CLAIM_LABEL_HIDDEN: "Afficher la demande {vendor} {referenceId}",
  VIEW_CLAIM_SELECT_LABEL: "Afficher le statut des demandes",
  COMPLETE_ONE_OF_ACTIVE_PROGRAMS: "Terminez l\u2019un de vos programmes actifs pour ajouter celui-ci \xE0 votre parcours.",
  DURATION_MONTHS: "{duration, plural, =0 {z\xE9ro mois} one {# mois} other {# mois}}",
  OPENS_IN_A_NEW_TAB: "S'ouvre dans un nouvel onglet",
  EXTENSION_BENEFITS_END_DATE: "Date de prolongation des prestations",
  NOTICE_DATE: "Date de l'avis",
  USER_GROUP: "Groupe de l'utilisateur",
  MAPLE_USER_PROFILE: "Profil de l'utilisateur Maple",
  ENROLLED_IN_PCO: "Inscrit \xE0 PCO",
  USER_OPTIMUM_PROFILE: "Profil Optimum de l\u2019utilisateur",
  GENDER: "Genre",
  DEPENDENT_CLASS: "Cat\xE9gorie de personne \xE0 charge",
  EMPLOYMENT_STATUS: "Statut d'emploi",
  ADDRESS_2: "Adresse 2",
  ADDRESS_1: "Adresse 1",
  OK: "D'accord",
  START_X_MORE_PROGRAMS: "Commencez {duration} plus de programmes. Maintenez l'\xE9lan.",
  YOUVE_REACHED_PROGRAM_LIMIT: "Vous avez atteint la limite de votre programme!",
  ACTIVE_PROGRAMS: "Programmes actifs",
  NAVIGATION_STEPS_MENU_ERROR: "Les \xE9tapes de navigation comportent des erreurs",
  NAVIGATION_STEPS_MENU: "Menu des \xE9tapes de navigation",
  TAP_ADD_PROGRAM_BUTTON: 'Cliquez sur le bouton "Ajouter un programme" pour parcourir les programmes.',
  ADD_PROGRAM: "Ajouter un programme",
  YOU_HAVENT_STARTED_PROGRAMS: "Vous n\u2019avez pas commenc\xE9 de programmes sant\xE9",
  SCROLL_CAROUSEL_LEFT: "Faites d\xE9filer le carrousel vers la gauche",
  SCROLL_CAROUSEL_RIGHT: "Faites d\xE9filer le carrousel vers la droite",
  UPDATE_ELECTIONS: "Mettre \xE0 jour les s\xE9lections",
  QLE_PLAN_PICKER_COMPLETED: "C'est fait! Vos {planName} sont officiellement confirm\xE9s.",
  QLE_PLAN_PICKER_DEADLINE: "Vous avez jusqu'au <strong>{date}</strong> pour mettre \xE0 jour ce r\xE9gime",
  QLE_PLAN_PICKER_INFO_BANNER: "Veuillez compl\xE9ter les deux inscriptions car vos choix dans une inscription ne seront pas automatiquement transf\xE9r\xE9s dans l'autre.",
  QLE_PLAN_PICKER_EXPLANATION: "\xC9tant donn\xE9 que votre \xE9v\xE9nement de vie a lieu durant et autour de la p\xE9riode d'inscription, vous avez la possibilit\xE9 de mettre \xE0 jour et/ou de choisir une couverture pour vos contrats actuels et \xE0 venir.",
  DOCUMENT_TITLE: "{title} - League",
  VIEW_ACTIVE_PROGRAM_DETAILS: "Afficher les d\xE9tails du programme sant\xE9 courant",
  HEALTH_NEED: "Besoin de sant\xE9",
  X_DAYS_REMAINING: "{duration, plural, =0 {No days} one {# day} other {# days}} restant(s)",
  PROGRESS: "Progr\xE8s",
  PROGRAM_DETAILS: "D\xE9tails du programme",
  EARN_UP_TO_X_POINTS: "Accumulez jusqu'\xE0 {points} points",
  DURATION_WEEKS: "{duration, plural, =0 {Zero weeks} one {# week} other {# weeks}}",
  CHANGE_EMPLOYEE_CLASS_ERROR_UNDEFINED_CHANGE_TYPE_MESSAGE: "Type de changement non d\xE9fini",
  CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_2: "{change_reason}",
  CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_1: "<strong>Motif :</strong>",
  CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_BULLET_1: "L'inscription sera ouverte pour le membre",
  CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_CONTENT_1: "Le changement de classe de prestations que vous avez enclench\xE9 est admissible pour l'<strong>adh\xE9sion des nouveaux employ\xE9s</strong>. Une fois confirm\xE9, voici les prochaines \xE9tapes :",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_4: "Si le membre ne soumet pas ses choix pendant la p\xE9riode d'inscription, il b\xE9n\xE9ficiera des s\xE9lections par d\xE9faut pour les avantages nouvellement d\xE9bloqu\xE9s",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_3: "Une fois la soumission effectu\xE9e, le membre b\xE9n\xE9ficiera instantan\xE9ment des avantages",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_2: "Le membre sera inform\xE9 de son inscription en attente par courriel",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_1: "L'inscription sera ouverte pour le membre",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_CONTENT_1: "Le changement de classe de prestations que vous avez enclench\xE9 est admissible pour <strong>l'adh\xE9sion au changement de classe de prestations.</strong> Une fois confirm\xE9, voici les prochaines \xE9tapes :",
  TRIGGER_PAYMENT_MODAL: "Voulez-vous vraiment red\xE9clencher le paiement suivant?",
  TRIGGER_PAYMENT_PLACEHOLDER: "Saisissez un identifiant de paiement",
  TRIGGER_PAYMENT_BODY: "Veuillez fournir un identifiant de paiement individuel pour faire passer le statut du paiement de \xAB annul\xE9 \xBB \xE0 \xAB r\xE9gl\xE9 \xBB. Ce paiement sera ainsi r\xE9cup\xE9r\xE9 avec le prochain paiement programm\xE9.",
  TRIGGER_PAYMENT_HEADING: "D\xE9clencher le renvoi du paiement",
  REWARDS_BODY: "Accumulez des points de r\xE9compense en atteignant des objectifs qui encouragent de nouveaux comportements plus sains. \xC9changez vos points contre des cr\xE9dits League dans notre March\xE9vie.",
  REWARDS_HEADING: "R\xE9compenses pour habitudes saines",
  ANSWERS_BODY: "Clavardez en direct avec un vrai expert en sant\xE9 d\xE8s que vous avez des questions ou des doutes, directement dans l\u2019application.",
  ANSWERS_HEADING: "Des r\xE9ponses rapides \xE0 toutes vos questions sant\xE9",
  PRODUCTS_BODY: "Obtenez un acc\xE8s exclusif \xE0 une collection de produits et de services soigneusement s\xE9lectionn\xE9s pour soutenir votre mode de vie sain.",
  PRODUCTS_HEADING: "Des produits et services soigneusement s\xE9lectionn\xE9s",
  COVERAGE_BODY: "Acc\xE9dez \xE0 toutes vos informations de couverture, \xE0 vos cartes et aux informations sur vos demandes en quelques instants \xE0 partir de votre t\xE9l\xE9phone intelligent.",
  COVERAGE_HEADING: "Toutes les informations sur votre couverture r\xE9unies dans un seul et m\xEAme endroit",
  IN_IT_FOR_YOU: "Voici ce que vous pouvez en retirer :",
  ALREADY_HAVE_ACCOUNT: "Vous d\xE9tenez d\xE9j\xE0 un compte?",
  CREATE_ACCOUNT: "Cr\xE9er un compte",
  LETS_CREATE_ACCOUNT: "Cr\xE9ez votre compte League",
  WELCOME_EXCLAMATION_NAME: "Bienvenue, {firstName}!",
  WELCOME_EXCLAMATION: "Bienvenue!",
  GET_STARTED_ERROR: "Le code d'invitation que vous avez utilis\xE9 n'est pas valide. Veuillez v\xE9rifier le lien dans votre courriel d'invitation et r\xE9essayer.",
  POUNDS: "Livres sterling",
  KILOGRAMS: "Kilogrammes",
  INCH: "Pouce",
  INCHES: "Pouces",
  FOOT: "Pied",
  FEET: "Pieds",
  CENTIMETERS: "Centim\xE8tres",
  BCC_ENROLLMENT: "<strong>Pourquoi ne puis-je pas modifier mes s\xE9lections?</strong> Pendant cette p\xE9riode d'inscription, vous pourrez seulement vous inscrire aux nouveaux avantages offerts dans le cadre de votre changement d'emploi.",
  LEARN_MORE_QLE: "\xE0 propos des \xE9v\xE8nements de vie pouvant \xEAtre admissibles pour une r\xE9inscription",
  LEARN_MORE_LEAGUE_FAMILY: "\xE0 propos de League Famille pour vos personnes \xE0 charge",
  LINK_TO_TRANSACTION_DETAILS: "Lien vers les d\xE9tails",
  GO_TO_RECEIPT: "Voir les re\xE7us pour {title}",
  GO_TO_CLAIM: "Voir les d\xE9tails de la demande pour {title}",
  GO_TO_LIFEMARKET: "Rendez-vous sur le March\xE9vie pour voir la commande num\xE9ro {invoiceId}",
  COVERAGE_VOLUME_AMOUNT: "{amount} / {period}",
  ESTIMATED_BENEFIT_TOOLTIP_WEEKLY: "Il s'agit d'une estimation du montant d'argent que vous recevrez chaque semaine pendant votre p\xE9riode de couverture. Il est calcul\xE9 en fonction de vos revenus hebdomadaires.",
  ESTIMATED_BENEFIT_TOOLTIP_MONTHLY: "Il s'agit d'une estimation du montant d'argent que vous recevrez chaque semaine pendant votre p\xE9riode de couverture. Il est calcul\xE9 en fonction de vos revenus mensuels.",
  ESTIMATED_BENEFIT_LABEL: "Le montant estim\xE9 de vos prestations",
  DOWNLOAD_TEMPLATE: "T\xE9l\xE9charger le mod\xE8le",
  PROVINCE_OF_RESIDENCE: "Province de r\xE9sidence",
  DEPENDENT_COVERAGE_LEVEL: "Niveau de couverture de la personne \xE0 charge",
  TYPE_OF_PREMIUM: "Type de prime",
  BENEFIT_PLAN_NAME: "Nom du r\xE9gime d'avantages sociaux",
  UPLOAD_DEDUCTION_CODES: "T\xE9l\xE9verser les codes de d\xE9duction",
  NO_DEDUCTION_CODES: "Ce groupe n'a aucun code de d\xE9duction!",
  NEW_MAPPING: "Nouveau mappage",
  EDIT_DEDUCTION_CODE: "Modifier le code de d\xE9duction",
  NEW_DEDUCTION_CODE: "Nouveau code de d\xE9duction",
  IMPORT_DEDUCTION_CODES_COPY: "Importation de masse des codes de d\xE9duction \xE0 partir d'un fichier CSV. Veuillez noter qu'un nouveau t\xE9l\xE9versement cr\xE9era de nouveaux codes de d\xE9duction sans remplacer les codes existants!",
  DEDUCTION_CODE_COPY: "D\xE9finir le mappage du code de d\xE9duction pour les avantages des utilisateurs",
  DEDUCTION_CODES: "Codes de d\xE9duction",
  DEDUCTION_CODE: "Code de d\xE9duction",
  ADD_FILTER: "Ajouter un filtre",
  VALUES: "Valeurs",
  FIELD: "Champ",
  TRACKER_YOU_PAY_TOOLTIP_CONTENT_NOTE: "Remarque : Vous pouvez peut-\xEAtre d\xE9j\xE0 voir un solde si certains avantages sont obligatoires ou pr\xE9s\xE9lectionn\xE9s pour vous par {company}!",
  TRACKER_YOU_PAY_TOOLTIP_CONTENT: "Voici le total annuel cumul\xE9 des co\xFBts de tous les avantages de votre r\xE9gime. Vous constaterez que ce montant augmentera au fur et \xE0 mesure de vos ajouts dans les \xE9tapes d'inscription.",
  STEP_SELECTED: "\xC9tape actuelle",
  STEP_COMPLETED: "\xC9tape termin\xE9e",
  STEP_HAS_ERROR: "Cette \xE9tape comporte des erreurs",
  STEP_NOT_COMPLETED: "\xC9tape non termin\xE9e",
  CANCEL_DEPENDENT_DEACTIVATION_SUCCESS_TOAST: "La personne \xE0 charge a \xE9t\xE9 rajout\xE9e \xE0 votre r\xE9gime",
  CONTENTFUL_ERROR_BANNER_MULTICARD: "Le contenu d'une ou plusieurs cartes de cet avantage n'existe pas sur Contentful. Si le contenu n'existe pas pour une carte activ\xE9e, d\xE9sactivez l'int\xE9gration ou contactez les Op\xE9rations pour demander la cr\xE9ation du contenu.",
  CLOSE_BENEFIT_LIST: "Fermer la liste des avantages",
  EXPAND_BENEFIT_LIST: "D\xE9velopper la liste des avantages",
  DOWNLOAD_BENEFICIARIES_REPORT: "T\xE9l\xE9charger le rapport sur les b\xE9n\xE9ficiaires",
  BENEFICIARIES_REPORT_TEXT: "Ce rapport donne des d\xE9tails sur les b\xE9n\xE9ficiaires d\xE9sign\xE9s de chaque employ\xE9, pour tous les avantages applicables.",
  BENEFICIARIES_REPORT: "Rapport sur les b\xE9n\xE9ficiaires",
  LEARN_MORE_ABOUT_COORDINATION_OF_BENEFITS: "En savoir plus sur la coordination des avantages",
  ERROR_CREATING_WALLET_TEMPLATE: "Erreur de cr\xE9ation du mod\xE8le de portefeuille",
  DEPENDENT_REMOVED_FROM_UPCOMING_PLAN: "La personne \xE0 charge a \xE9t\xE9 retir\xE9e de votre futur r\xE9gime",
  LEARN_MORE_ABOUT_ADDING_DOCUMENTS: "En savoir plus sur l'ajout de documents dans League",
  CREATE_IN_CONTENTFUL: "Cr\xE9er dans Contentful",
  CONTENTFUL_INFO_BANNER: "Veillez \xE0 enregistrer cet avantage apr\xE8s avoir activ\xE9 ou d\xE9sactiv\xE9 l'int\xE9gration",
  CONTENTFUL_ERROR_BANNER: "Le contenu de cet avantage n'existe pas sur Contentful. Veuillez d\xE9sactiver l'int\xE9gration ou contacter les Op\xE9rations pour demander la cr\xE9ation du contenu.",
  CUSTOM_TEMPLATE_TOOLTIP: "Les mod\xE8les personnalis\xE9s sont sp\xE9cifiques \xE0 un groupe et remplacent tous les mod\xE8les par d\xE9faut existants. Si n\xE9cessaire, veuillez contacter les Op\xE9rations pour cr\xE9er ou modifier un mod\xE8le.",
  DEFAULT_TEMPLATE_TOOLTIP: "Les mod\xE8les par d\xE9faut sont partag\xE9s entre les groupes qui utilisent la m\xEAme compagnie d'assurance et le m\xEAme type de prestations (p. Ex., Cigna Medical, Manuvie Assurance maladies graves). Si n\xE9cessaire, veuillez contacter les Op\xE9rations pour cr\xE9er ou modifier un mod\xE8le.",
  CONTRIBUTION_AFTER_PRORATION_AMOUNT: "La cotisation apr\xE8s le calcul au prorata est de {proratedAmount}",
  ERROR_SAVING_USER_PROFILE: "Une erreur est survenue lors de la sauvegarde de votre profil.",
  ADD_TO_JOURNEY: "Ajouter au parcours",
  BACK_TO_JOURNEY: "Revenir au parcours",
  ACTIVITY_REMOVED: "Activit\xE9 supprim\xE9e",
  ARE_YOU_SURE_REMOVE_ACTIVITY: "Voulez-vous vraiment supprimer cette activit\xE9 de votre parcours sant\xE9? Cette action est irr\xE9versible.",
  REMOVE_ACTIVITY: "Supprimer l'activit\xE9?",
  DELETE_BENEFICIARY_BUTTON_LABEL: "Supprimer {beneficiaryName}",
  NEW_DEPENDENT: "Nouvelle personne \xE0 charge",
  REMOVE_DEPENDENT_NAME: "Supprimer {dependentFullName}",
  INACTIVE: "inactif",
  LIVE: "en direct",
  NO_TEMPLATE: "pas de mod\xE8le",
  BENEFIT_WALLET_CONTENT: "Contenu du portefeuille d\u2019avantages",
  VIEW_IN_CONTENTFUL: "Afficher dans Contentful",
  CUSTOM_TEMPLATE_STATUS: "Statut du mod\xE8le personnalis\xE9",
  DEFAULT_TEMPLATE_STATUS: "Statut du mod\xE8le par d\xE9faut",
  CONTENTFUL_DESCRIPTION: "Cette int\xE9gration r\xE9cup\xE8re cet aper\xE7u des avantages, les instructions et les actions rempla\xE7ables.",
  PENDING_DEPENDENT_REMOVAL: "En attente de suppression: {deactivationDate}",
  UNDO: "Annuler",
  UNDO_REMOVAL_OF_DEPENDENT: "Annuler la suppression de {dependentName}",
  POPULAR_PROGRAMS: "Programmes populaires",
  EXTENSION_OF_BENEFITS: "Prolongation des prestations",
  TERMINATED: "R\xE9sili\xE9",
  INVITED: "Invit\xE9",
  UPCOMING_ACTIVITIES: "Activit\xE9s \xE0 venir",
  TODAYS_ACTIVITIES: "Activit\xE9s d'aujourd'hui",
  GO_TO_HEALTH_JOURNEY: "Aller au Parcours sant\xE9",
  JOURNEY: "Parcours",
  BROWSE_PROGRAMS: "Parcourir les programmes",
  GO_TO_PROGRAM_LIBRARY: 'Rendez-vous \xE0 "Tous les programmes" pour parcourir les Programmes sant\xE9 et d\xE9marrer votre parcours sant\xE9.',
  NOTHING_HERE_YET: "Il n'y a rien \xE0 voir ici!",
  SNEAK_PEEK_AT_HEALTH_PROGRAM_ACTIVITIES: "Obtenez un aper\xE7u des activit\xE9s \xE0 venir qui seront bient\xF4t disponibles",
  PREVIEW: "Aper\xE7u",
  LATER: "Plus tard",
  THIS_MONTH: "Ce mois",
  THIS_WEEK: "Cette semaine",
  PROGRAM_LIBRARY: "Tous les programmes",
  ACTIVITIES: "Activit\xE9s",
  HEALTH_JOURNEY: "Parcours sant\xE9",
  REMOVE_DEPENDENT_TEXT: "Vous \xEAtes sur le point de supprimer la personne \xE0 charge <strong> {dependentName} </strong> de votre r\xE9gime {planName} <strong> {planName} </strong>",
  REMOVE_DEPENDENT_HEADING: "\xCAtes-vous s\xFBr de vouloir supprimer cette personne \xE0 charge?",
  REDEEMABLE: "\xC9changeable contre {value}.",
  VIEW_YOUR_REWARDS: "Voir vos r\xE9compenses",
  YOURE_QUESTIONS_AWAY_WITHOUT_POINTS: "Il vous reste {number} questions avant de compl\xE9ter l'\xE9valuation.",
  HIDE_NOTIFICATIONS: "Masquer les notifications ({unreadCount} notifications non lues)",
  VIEW_NOTIFICATIONS: "Voir les notifications ({unreadCount} notifications non lues)",
  PRO_DESIGNATION: "D\xE9signation professionnelle",
  PRO_ASSOCIATION: "Association professionnelle",
  LEAGUE_CHAT: "Clavardage League",
  AMOUNT_OWING: "Montant d\xFB",
  TOTAL_DUE: "Total d\xFB",
  ISSUED_TO: "\xC9mis \xE0",
  SUMMARY: "R\xE9capitulatif",
  LICENSE_NUMBER: "Num\xE9ro de permis",
  SERVICE_PROVIDER: "Fournisseur de service",
  HST_NUMBER: "Num\xE9ro de TVH",
  RECEIPT_NUMBER: "Num\xE9ro de re\xE7u",
  TRANSACTION_DATE: "Date de la transaction",
  TAX: "Taxe",
  UNDO_REMOVAL: "Annuler la suppression",
  DEPENDENT_MODAL_WARNING: "Veuillez revoir et mettre \xE0 jour tout choix que vous avez pr\xE9c\xE9demment effectu\xE9 pour cette personne \xE0 charge.",
  UNDO_REMOVE_DEPENDENT_HEADING: "\xCAtes-vous s\xFBr de vouloir annuler cette suppression en attente?",
  CANCEL_DEPENDENT_CHANGES_WARNING: "Toutes les mises \xE0 jour que vous avez apport\xE9es \xE0 votre personne \xE0 charge seront perdues.",
  CANCEL_DEPENDENT_CHANGES_HEADING: "\xCAtes-vous s\xFBr de vouloir annuler vos modifications?",
  BACK_TO_REWARDS: "Retourner aux r\xE9compenses",
  NEW_POINTS_BALANCE: "Nouveau solde de points",
  PREV_POINTS_BALANCE: "Solde de points pr\xE9c\xE9dent",
  YOUVE_SUCCESSFULLY_REDEEMED: "Vous venez d'\xE9changer {points} points pour {value} $ de Cr\xE9dit League.",
  POINTS_REDEEMED: "Points \xE9chang\xE9s",
  YOUR_POINTS_CANNOT_CONVERT: "Vos points seront convertis en cr\xE9dit League \xE0 utiliser dans notre March\xE9vie, et ils ne pourront pas \xEAtre reconvertis en points de r\xE9compenses.",
  ARE_YOU_SURE_REDEEM_POINTS: "Voulez-vous vraiment \xE9changer <strong> {points} points contre un Cr\xE9dit League de ${value}? </strong>",
  CONFIRM_REDEEM_POINTS: "Confirmer l'\xE9change des points",
  REDEEM_POINTS_DESCRIPTION: "En cliquant sur <strong> \xAB\xC9changer mes points\xBB </strong>, vos points seront convertis en Cr\xE9dit League qui pourra \xEAtre utilis\xE9 pour acheter des produits et services dans notre March\xE9vie.",
  REDEEM_NEXT_TIER_POINTS: "Il vous reste {points} avant de pouvoir \xE9changer {value}.",
  GET_STARTED_REWARDS: "Commencez \xE0 utiliser les r\xE9compenses League",
  EXPLORE_LIFEMARKET: "Parcourir le March\xE9vie",
  LEAGUE_CREDIT_LIFEMARKET_DESCRIPTION: "Quelque chose dans notre March\xE9vie a-t-il attir\xE9 votre int\xE9r\xEAt? Utilisez votre Cr\xE9dit League pour l'achat de tout produit ou service",
  USE_LEAGUE_CREDIT: "Utilisez le Cr\xE9dit League pour payer",
  EXPLORE_PROGRAMS: "Parcourir les programmes",
  EARN_POINTS_PROGRAMS: "Accumulez des points de r\xE9compense en compl\xE9tant les activit\xE9s des Programmes sant\xE9",
  EARN_POINTS: "Accumulez des points et adoptez de saines habitudes",
  REWARD_STATUS: "Statut des r\xE9compenses",
  REDEEM_POINTS: "\xC9changer les points",
  REDEEMABLE_VALUE: "Valeur \xE9changeable",
  POINTS_BALANCE: "Solde de points",
  YOUR_REWARDS: "Vos r\xE9compenses",
  WHAT_TO_DO: "Que faire",
  ABOUT_THIS_PROGRAM: "\xC0 propos de ce programme",
  ABOUT_THIS_ARTICLE: "\xC0 propos de cet article",
  WHY_TALK_TO_RN: "Pourquoi discuter avec le personnel infirmier agr\xE9\xE9",
  WHAT_IS_MY_HEALTH_PROFILE: "Quel est mon profil sant\xE9?",
  COME_BACK_IN_DAYS: "Revenez dans {days} jours pour le terminer.",
  ACTIVITY_NOT_AVAILABLE: "Cette activit\xE9 n'est pas encore disponible!",
  REMOVE_FROM_JOURNEY: "Retirer du parcours",
  OPEN_LINK: "Ouvrir le lien >",
  RE_REGISTER_REASON: "Motif de la r\xE9inscription",
  SUGGESTED: "Sugg\xE9r\xE9",
  AVAILABLE_ON: "Disponible le {date}",
  SAVED_BUSINESS_RULE: "R\xE8gle administrative enregistr\xE9e!",
  UPDATE_CONTRIBUTIONS_ERROR: "D\xE9sol\xE9, une erreur est survenue lors de la mise \xE0 jour des cotisations des garanties de l'employ\xE9",
  UNLINKED_MAPLE_ACCOUNT_OPTION_TWO: "(Nouvel utilisateur Maple) Cr\xE9ez un nouveau compte Maple via le flux de transfert PC Sant\xE9 pour lier leur compte PC Sant\xE9.",
  UNLINKED_MAPLE_ACCOUNT_OPTION_ONE: "(Utilisateur Maple pr\xE9existant) Utilisez les informations d'identification de compte Maple pr\xE9existantes dans le flux de transfert PC Sant\xE9 pour acc\xE9der \xE0 Maple et liez leur compte PC Sant\xE9.",
  UNLINKED_MAPLE_ACCOUNT_OPTIONS: "Si le membre souhaite acc\xE9der \xE0 Maple via le flux de transfert PC Sant\xE9, veuillez partager les options suivantes:",
  UNLINKED: "Dissoci\xE9s",
  LINKED: "Li\xE9s",
  CURRENT_MAPLE_USER_STATUS: "Statut actuel d'utilisateur Maple",
  SHARED_MAPLE_USER: "Utilisateur partag\xE9 - cet utilisateur a associ\xE9 son compte Maple pr\xE9existant \xE0 PC Sant\xE9",
  SDM_MAPLE_USER: "Utilisateur Phamaprix - cet utilisateur a \xE9t\xE9 introduit pour la premi\xE8re fois \xE0 Maple via PC Sant\xE9",
  NO_MAPLE_ACCOUNT_LINKED: "Aucun compte Maple associ\xE9",
  MAPLE_UNLINK_DATE: "Date de dissociation Maple",
  MAPLE_CONSENT_DATE: "Date de consentement Maple",
  MAPLE_USER_TYPE: "Type d'utilisateur Maple",
  DATE_CONSULT_ENDED: "Date de fin de consultation",
  HEALTH_PROFILE_COMPLETION_POINTS: "Vous avez accumul\xE9 {points} points",
  UPDATE_HEALTH_PROFILE: "Mettre \xE0 jour votre profil sant\xE9",
  LAST_UPDATED_DATE: "Derni\xE8re mise \xE0 jour le {date}",
  ANY_HEALTH_CHANGES: "Y a-t-il eu des changements r\xE9cents dans votre sant\xE9?",
  PAYROLL_CONFIG_COPY: "Configurer des r\xE8gles afin de g\xE9rer l'enregistrement et les rapports des employ\xE9s.",
  PAYROLL_CONFIG: "Configuration de la paie",
  WARNING_OPENS_NEW_TAB: "S'ouvre dans un nouvel onglet",
  RE_REGISTER_START: "R\xE9enregistrement de la p\xE9riode de paie. Veuillez patienter, l'op\xE9ration peut prendre plusieurs minutes.",
  RE_REGISTER_SUCCESS: "R\xE9enregistrement des rapports r\xE9ussi! Afficher le nouveau rapport dans <a> Onglet Rapports programm\xE9s </a>",
  RE_REGISTER_CONFIRMATION: "Voulez-vous vraiment vous r\xE9inscrire?",
  RE_REGISTER_WARNING: "<strong>Attention :</strong> cette action \xE9crasera tous les rapports existants qui ont \xE9t\xE9 enregistr\xE9s pour cette p\xE9riode de paie. Veuillez vous assurer que les fichiers ne sont utilis\xE9s par personne avant de poursuivre.",
  RE_REGISTER_PAY_PERIOD: "R\xE9inscrire la p\xE9riode de paie",
  RE_REGISTER: "R\xE9inscrire",
  NO_PAY_CALENDARS: "Il n'existe pas de calendrier de paie pour ce groupe.",
  NAMED_REPORTS: "Rapports {name}",
  NAMED_PAY_CALENDAR: "Calendrier de paie {name}",
  YEAR_PLAN_YEAR: "Ann\xE9e de r\xE9gime {year}",
  CARE_PROVIDER_HISTORY: "Historique de fournisseurs de soins",
  AGENT: "Conseiller",
  PROVIDER: "Fournisseur",
  CHANGE: "Modifier",
  FULL: "Complet",
  REPORT_NAME: "Nom du rapport",
  BACK_TO_SCHEDULED_REPORTS: "Retour aux rapports planifi\xE9s",
  NO_SCHEDULED_REPORTS: "Il n'y a pas de rapports planifi\xE9s pour ce calendrier de paie.",
  PREMIUM_REGISTRATION_RUN: "Ex\xE9cution de l'enregistrement de la prime",
  SCHEDULED_REPORTS_COPY: "Visualisez et t\xE9l\xE9chargez les rapports g\xE9n\xE9r\xE9s automatiquement.",
  SCHEDULED_REPORTS: "Rapports planifi\xE9s",
  NOTE_REPORTS_LOADING_COPY: "<strong>Remarque :</strong> selon le nombre d'enregistrements, ce rapport peut prendre quelques heures \xE0 produire. Nous vous enverrons par courriel un lien permettant de t\xE9l\xE9charger le rapport une fois que celui-ci aura \xE9t\xE9 trait\xE9.",
  DOWNLOAD_USAGE_SUMMARY_REPORT: "T\xE9l\xE9charger le rapport de r\xE9sum\xE9 d'utilisation",
  DOWNLOAD_DETAILED_USAGE_REPORT: "T\xE9l\xE9charger le rapport d'utilisation d\xE9taill\xE9",
  PLAN_PERIOD: "P\xE9riode de r\xE9gime",
  NO_CONTRIBUTION_ACCOUNTS: "L'utilisateur n'a pas de comptes de cotisation.",
  NO_CONTRIBUTIONS: "L'utilisateur n'a pas cotis\xE9.",
  CONTRIBUTION_SOURCE: "Source des cotisations",
  NEXT_CONTRIBUTION_AMOUNT: "Montant de la prochaine cotisation",
  TARGET_EMPLOYEE_CONTRIBUTION: "Cotisation cible de l'employ\xE9",
  YTD_EMPLOYEE_CONTRIBUTION: "Cumul annuel des cotisations de l'employ\xE9",
  CONTRIBUTIONS_ACCOUNT: "Compte de cotisations",
  SEARCH_EMPLOYEE_ID: "Recherche par identifiant de l'employ\xE9",
  BENEFIT_CONTRIBUTION_LABEL: "Une contribution d'un montant de ${amount} {currency} a \xE9t\xE9 faite pour {period}",
  EMPLOYEE_TASA_DEDUCTIONS_COPY: "Consulter et g\xE9rer les d\xE9ductions TASA de l'employ\xE9.",
  EMPLOYEE_TASA_DEDUCTIONS: "Retenues TASA de l'employ\xE9",
  EMPLOYEE_DEDUCTIONS: "Retenues de l'employ\xE9",
  PTS: "pts",
  GET_NETSUITE_INVOICE_REPORT_ERROR: "\xC9chec de la g\xE9n\xE9ration du rapport de facturation au format NetSuite, veuillez r\xE9essayer!",
  GET_NETSUITE_INVOICE_REPORT_SUCCESS: "Le rapport de facturation au format NetSuite a \xE9t\xE9 g\xE9n\xE9r\xE9 avec succ\xE8s!",
  NETSUITE_INVOICE_REPORT_TO_DATE: "\xE0 la date",
  NETSUITE_INVOICE_REPORT_FROM_DATE: "de la date",
  GET_NETSUITE_INVOICE_REPORT: "Demander un rapport",
  NETSUITE_INVOICE_REPORT: "Rapport de facturation format\xE9 pour NetSuite",
  BENEFIT_YEAR: "Ann\xE9e de prestations",
  BENEFIT_TYPE_FILTER: "Filtre de type de prestation",
  EMPLOYER_BENEFIT: "Avantages sociaux de l'employeur",
  COPY_PRICING_CONFIG_NOTE: "Remarque: cette action remplacera toute configuration de tarification existante pour l'avantage s\xE9lectionn\xE9, si elle est d\xE9finie. Cette action est irr\xE9versible.",
  COPY_PRICING_CONFIG_COPY: "Copiez la configuration de tarification des avantages enregistr\xE9e actuelle \xE0 un autre avantage.",
  COPY_PRICING_CONFIG: "Copier la configurations des prix",
  RATE_SHEET_EXTRACT_SEARCH_PLACEHOLDER: "Recherche par cat\xE9gories d'avantages, nom ou identifiant",
  RATE_SHEET_EXTRACT_COPY: "T\xE9l\xE9chargez un rapport contenant les tableaux de tarifs et des donn\xE9es de tarification pour certains avantages et cat\xE9gories d'avantages.",
  RATE_SHEET_EXTRACT: "Extrait de la grille tarifaire",
  DEPENDENT_VERIFICATION: "V\xE9rification de personne \xE0 charge",
  TRY_AGAIN: "R\xE9essayer",
  THERE_SEEMS_T0_HAVE_BEEN_A_PROBLEM: "Oups, il semble y avoir eu un probl\xE8me.",
  CHAT_WITH_YOUR_CARE_TEAM: "Clavarder avec votre \xE9quipe d'experts en soutien",
  FIXED_MONTHLY_PLATFORM_FEES: "Frais mensuels fixes de la plateforme",
  DISCOUNT: "Rabais",
  CREDIT_ADJUSTMENT: "Modifier le cr\xE9dit",
  COMMA_SEPARATED_USER_IDS: "Identifiants des utilisateurs (s\xE9par\xE9s par des virgules)",
  PRICING_SCENARIO_COPY: "Ce rapport est utilis\xE9 pour v\xE9rifier les d\xE9ductions de paie pour la s\xE9lection d'avantages sociaux d'un utilisateur, selon diff\xE9rentes options de couverture.",
  PRICING_SCENARIO_REPORT: "Rapport sur les hypoth\xE8ses de tarification",
  PRICING_SCENARIO: "Hypoth\xE8se de tarification",
  YOU_HAVE_UNSAVED_CHANGES: "Certaines modifications ne sont pas enregistr\xE9es, souhaitez-vous poursuivre la navigation et annuler toutes les modifications?",
  BACK_TO_PAY_CALENDARS: "Retour aux calendrier de paies",
  PAY_PERIOD_DETAILS: "D\xE9tails de la p\xE9riode de paie",
  NOT_REGISTERED: "Non enregistr\xE9",
  REGISTERED: "Enregistr\xE9",
  PAY_PERIOD_MEMBER_PAY_DATE_TOOLTIP: "C'est la date \xE0 laquelle les membres recevront leur ch\xE8que de paie pour cette p\xE9riode de paie sp\xE9cifique.",
  PAY_PERIOD_SCHEDULED_FILE_DELIVERY_DATE_TOOLTIP: "Il s'agit de la date initialement pr\xE9vue pour l'envoi de la paie au client. ",
  PAY_PERIOD_REGISTER_STATUS_TOOLTIP: "Ce statut indique si une p\xE9riode de paie a \xE9t\xE9 enregistr\xE9e ou non. Lorsqu'une p\xE9riode de paie est enregistr\xE9e, cela signifie que les retenues sur la paie des membres seront enregistr\xE9es dans notre syst\xE8me. ",
  PAY_PERIOD_REGISTER_DATE_TOOLTIP: "Nous utiliserons cette date pour enregistrer dans notre syst\xE8me une retenue \xE0 la source des membres pour cette p\xE9riode de paie.",
  PAY_PERIOD_END_DATE_TOOLTIP: "C'est le dernier jour de la p\xE9riode de paie pour les membres dans ce calendrier de paie.",
  PAY_PERIOD_START_DATE_TOOLTIP: "C'est le premier jour de la p\xE9riode de paie pour les membres dans ce calendrier de paie.",
  PLAN_PERIOD_END_DATE_TOOLTIP: "C'est le dernier jour de la p\xE9riode de paie pour les membres dans ce calendrier de paie.",
  PLAN_PERIOD_START_DATE_TOOLTIP: "C'est le premier jour de la p\xE9riode de paie pour les membres dans ce calendrier de paie.",
  PAY_PERIOD_MEMBER_PAY_DATE: "Date du paiement du membre",
  PAY_PERIOD_SCHEDULED_FILE_DELIVERY_DATE: "Date pr\xE9vue de remise du dossier",
  PAY_PERIOD_REGISTER_STATUS: "Enregistrer le statut",
  PAY_PERIOD_REGISTER_DATE: "Enregistrer la date",
  PAY_PERIOD_END_DATE: "Date de fin",
  PAY_PERIOD_START_DATE: "Date de d\xE9but",
  PLAN_PERIOD_END_DATE: "Date de fin",
  PLAN_PERIOD_START_DATE: "Date de d\xE9but",
  DOWNLOAD_EXPORTED_PAY_SCHEDULE: "T\xE9l\xE9charger le calendrier de paie export\xE9",
  EXPORT_PAY_SCHEDULE: "Exporter le calendrier de paie",
  REQUESTED_PAYROLL_SCHEDULE_EXPORT: "Export du calendrier de paie en cours, veuillez patienter.",
  REACH_YOUR_GOALS_SUBTITLE: "Nous utilisons votre profil sant\xE9 pour recommander les meilleurs programmes sant\xE9, des offres du March\xE9 Sant\xE9 et du contenu pour vos besoins et objectifs personnels.",
  REACH_YOUR_GOALS: "Atteignez vos objectifs avec des programmes, du contenu et des services personnalis\xE9s.",
  LEARN_MORE_ABOUT_LEAGUE_REWARDS: "Apprenez-en plus sur les r\xE9compenses League",
  GET_INSIGHTS_INTO_YOUR_HEALTH_SUBTITLE: "Votre sant\xE9 poss\xE8de plusieurs facettes. Votre profil sant\xE9 \xE9galement. Vous en apprendrez plus sur votre corps, votre style de vie, votre esprit, vous-m\xEAme, votre sant\xE9 m\xE9dicale et votre sant\xE9 sociale.",
  GET_INSIGHTS_INTO_YOUR_HEALTH: "Obtenez des informations sur six facettes de votre sant\xE9.",
  LEARN_MORE_ABOUT_YOUR_HEALTH_PROFILE: "Apprenez-en plus sur votre profil sant\xE9",
  EASILY_STAY_ON_TOP_OF_YOUR_HEALTH_SUBTITLE: "Votre profil sant\xE9 rassemble toutes vos informations de sant\xE9 dans un seul endroit facile d'acc\xE8s pour que vous puissiez surveiller vos objectifs et votre sant\xE9 partout et en tout temps.",
  EASILY_STAY_ON_TOP_OF_YOUR_HEALTH: "Gardez facilement le contr\xF4le sur votre sant\xE9.",
  BUILD_YOUR_HEALTH_PROFILE_SUBTITLE: "Toutes vos informations, vos ressources et vos objectifs sur votre sant\xE9 dans un m\xEAme endroit.",
  BUILD_YOUR_HEALTH_PROFILE: "Cr\xE9ez votre profil sant\xE9",
  IMPORT: "Importer",
  ADD_PAY_CALENDAR: "Ajouter un calendrier de paie",
  IMPORT_PAYROLL_SCHEDULE_SUCCESS: "Calendrier de paie import\xE9",
  PROVIDE_PAY_CALENDAR_NAME: "Fournir un nom de calendrier de paie",
  NO_PAY_SCHEDULE_UPLOADED: "Vous n'avez pas encore t\xE9l\xE9vers\xE9 de feuille de calcul de calendrier de paie!",
  SAVE_PAYROLL_SCHEDULE_SUCCESS: "Calendrier de paie enregistr\xE9",
  RENAME_PAY_CALENDAR_COPY: "Modifier le nom d'affichage de ce calendrier de paie.",
  RENAME_PAY_CALENDAR: "Renommer le calendrier de paie",
  PAY_CALENDAR_NAME: "Nom du calendrier de paie",
  PAY_SCHEDULE_UPLOADED: "Calendrier de paie t\xE9l\xE9vers\xE9",
  IMPORT_PAY_CALENDAR_NOTE: "Si vous apportez des modifications dans la feuille de calcul Google, elles ne seront pas automatiquement mises \xE0 jour par l'administrateur.",
  IMPORT_PAY_CALENDAR_COPY: "L'importation d'un calendrier de paie permet de g\xE9n\xE9rer des p\xE9riodes de r\xE9gime et d'enregistrer des dates \xE0 partir d'un mod\xE8le de feuille Google. Les en-t\xEAtes de colonne doivent correspondre \xE0 l'exemple ci-dessous.",
  PC_HEALTH_LOGO: "Logo PC Sant\xE9",
  LEAGUE_LOGO: "Logo League",
  ERROR_PAY_CALENDAR_DOES_NOT_EXIST: "Impossible de demander un rapport, le calendrier de paie n'existe pas.",
  REQUEST: "Demande",
  ONLY_REGISTERED_PAY_PERIODS: "Seules les p\xE9riodes de paie enregistr\xE9es appara\xEEtront dans cette liste d\xE9roulante",
  PAY_PERIOD_PREMIUM_EXPORTS_REPORT: "Rapport de p\xE9riode de paie PX",
  GROUP_PREMIUM_EXPORTS_REPORT: "Rapport de Groupe PX",
  COPY_REPORT_ID: "Copier l'identifiant du rapport",
  PAY_SCHEDULE: "Calendrier de paie",
  PAY_CALENDAR: "Calendrier de paie",
  PAY_CALENDARS_COPY: "Calendriers qui indiquent les p\xE9riodes de paie utilis\xE9es pour g\xE9rer les cotisations et les rapports relatifs aux salaires.",
  PAY_CALENDARS: "Calendriers de paie",
  PAYROLL_TAB_DESCRIPTION: "Visualiser et g\xE9rer les fichiers et les calendriers de paie.",
  INVOICE_HEADER_INVOICE_AMOUNT: "Montant de la facture",
  PURCHASE_HISTORY: "Historique des achats",
  YOU_WILL_EARN_DOLLARS_IN_LEAGUE_CREDIT: "Vous recevrez {dollars} en cr\xE9dit League.",
  ARE_YOU_SURE_TO_REDEEM_POINTS: "\xCAtes-vous certain de vouloir r\xE9clamer vos points?",
  REDEEM_MY_POINTS: "R\xE9clamer mes points",
  REWARDS_HISTORY: "Historique des r\xE9compenses",
  MEMBER_TOOLING: "Outillage des membres",
  MEMBER_TOOLS: "Outils des membres",
  CREATED: "Cr\xE9\xE9",
  ENROLLMENT_SELECTION_REPORT_DOWNLOAD: "T\xE9l\xE9charger le rapport d'inscription",
  GET_ENROLLMENT_SELECTION_REPORT: "Obtenir le rapport d'\u2019adh\xE9sion",
  ENROLLMENT_SELECTION_REPORT: "Rapport d\u2019inscription",
  END_OF_PAGINATION_LIST: "On dirait que vous \xEAtes arriv\xE9s \xE0 la fin",
  PLEASE_TRY_AGAIN: "Veuillez r\xE9essayer",
  SHOW_MORE: "Afficher plus",
  LOAD_MORE: "Charger plus de r\xE9sultats",
  CHAT_DEEPLINK_EXPLAINER: "D\xE9marrez un clavardage avec un de nos experts du service client\xE8le et obtenez des r\xE9ponses \xE0 toutes vos questions.",
  CHAT_DEEPLINK_START_CARE_NAVIGATOR_CHAT: "Clavardage du navigateur de soins de sant\xE9",
  CHAT_DEEPLINK_START_CUSTOMER_CARE_CHAT: "Clavardage avec le service \xE0 la client\xE8le",
  CHAT_IN_PROGRESS_EXPLAINER: "Vous \xEAtes actuellement dans une session de clavardage. Pour commencer une nouvelle session, vous devez terminer votre session existante.",
  CHAT_IN_PROGRESS: "Clavardage d\xE9j\xE0 en cours",
  PRORATED_AMOUNTS_TEXT: "nous avons ajust\xE9 certains montants en fonction de la dur\xE9e restante de la p\xE9riode de police.",
  PRORATED_PRICING_TEXT: "nous avons ajust\xE9 la tarification des prestations en fonction du temps restant dans la p\xE9riode de police.",
  PRORATED_FLEX_TEXT: "nous avons ajust\xE9 votre montant de {flexDollarTerm} disponible en fonction du temps restant dans la p\xE9riode de police.",
  PRORATED_PRICING_AND_FLEX_TEXT: "nous avons ajust\xE9 la tarification des prestations et votre montant de {flexDollarTerm} disponible en fonction du temps restant dans la p\xE9riode de police.",
  NEW_HIRE_PRORATION_EXAMPLE: "Fun Company Limited donne \xE0 chaque employ\xE9 500\xA0$ {flexDollarTerm} au d\xE9but de l\u2019ann\xE9e de police. Si vous rejoignez l\u2019entreprise au milieu de l\u2019ann\xE9e, ce montant sera calcul\xE9 au prorata, soit 250\xA0$.",
  NEW_HIRE_PRORATION_EXAMPLE_TITLE: "Exemple de calcul au prorata",
  NEW_HIRE_PRORATION_MODAL_SUB_HEADING: "{greeting} Puisque vous commencez au cours de la p\xE9riode de police, {message}",
  NEW_HIRE_PRORATION_MODAL_HEADER: "La tarification de votre exp\xE9rience est calcul\xE9e au prorata",
  CONGRATULATIONS_ON_THE_NEW_JOB: "F\xE9licitations pour votre nouvel emploi!",
  CHAT_UNAVAILABLE_INFO: "Nous avons quelques probl\xE8mes avec notre clavardage, mais nous travaillons dur pour que tout fonctionne. Si vous avez besoin d'aide, contactez {helpLink}.",
  CHAT_UNAVAILABLE: "Le clavardage n'est pas disponible pour le moment",
  YOUR_PLACE_IN_QUEUE: "Votre place dans la file",
  APPROXIMATE_WAIT: "Approx. {waitTime, plural, one {# minute} other {# minutes}}",
  CANCEL_CHAT: "Annuler le clavardage",
  WAITING_FOR_PROVIDER: "En attente du prochain fournisseur disponible",
  AFTER_EXPIRY_YOU_HAVE_UNTIL_DATE_TO_SUBMIT: "Apr\xE8s l'expiration, vous aurez jusqu'au {inactiveDate} pour soumettre des demandes avec une date d'achat/de service le ou avant le {endDate}",
  YOU_HAVE_X_DAYS_BEFORE_EXPIRY: "Vous avez {days} jours pour utiliser cette prestation avant que la p\xE9riode contractuelle arrive \xE0 terme",
  EXPIRES_ON_DATE: "Expire le {date}",
  YOU_HAVE_UNTIL_DATE_TO_SUBMIT_PAST_CLAIMS: "Vous aurez jusqu'au {inactiveDate} pour soumettre des demandes avec une date d'achat/de service le ou avant le {endDate}",
  THIS_POLICY_EXPIRES_TOMORROW: "Cette p\xE9riode contractuelle est expir\xE9e et aujourd'hui est votre dernier jour pour pr\xE9senter des demandes ant\xE9rieures au titre de cet avantage",
  THIS_POLICY_HAS_EXPIRED: "Cette p\xE9riode contractuelle est expir\xE9e, mais vous avez encore {days} jours pour soumettre des demandes ant\xE9rieures au titre de cet avantage.",
  SUBMIT_PAST_CLAIMS_BY_DATE: "Soumettre des demandes ant\xE9rieures avant le {date}",
  YOU_CAN_ONLY_SUBMIT_CLAIMS_IN_DATE_RANGE: "Vous pouvez seulement soumettre des demandes avec date d'achat/du service entre {dateRange} pour cette prestation. Voir les d\xE9tails de votre couverture pour plus de renseignements",
  BECOMES_ACTIVE_IN_X_DAYS: "Cette garantie s'active dans {days} jours",
  BECOMES_ACTIVE_ON_DATE: "Devient actif le {date}",
  EMAIL_MESSAGE: "Envoyer un message par courriel",
  START_CHAT_WITH_REGISTERED_NURSE: "Commencer \xE0 clavarder avec un infirmier agr\xE9\xE9 (s'ouvre dans un nouvel onglet)",
  START_CHAT_WITH_CUSTOMER_CARE: "Commencer \xE0 clavarder avec un agent du service \xE0 la client\xE8le (s'ouvre dans un nouvel onglet)",
  START_CHAT_WITH_CARE_NAVIGATOR: "Commencer \xE0 clavarder avec un expert en soutien",
  CHAT_WITH_REGISTERED_NURSE_PAGE_TITLE: "Clavarder avec un infirmier agr\xE9\xE9 - League",
  CHAT_WITH_CUSTOMER_CARE_PAGE_TITLE: "Clavarder avec un agent du service \xE0 la client\xE8le - League",
  CHAT_WITH_CARE_NAVIGATOR_PAGE_TITLE: "Clavarder avec un expert en soutien",
  CHAT_HISTORY_DETAILS_PAGE_TITLE: "Transcription du clavardage - League",
  CHAT_HISTORY_PAGE_TITLE: "Historique du clavardage - League",
  CHAT_PAGE_TITLE: "Clavardage - League",
  CHAT_WITH_REGISTERED_NURSE: "Clavarder avec un personnel infirmier agr\xE9\xE9",
  CHAT_WITH_CUSTOMER_CARE: "Clavardez avec un agent du service \xE0 la client\xE8le",
  CHAT_WITH_CARE_NAVIGATOR: "Clavarder avec un expert en soutien",
  CHAT_VIEW_TRANSCRIPT_ARIA_LABEL: "Voir la transcription du clavardage avec {owner} le {date} \xE0 {time}",
  CHAT_WITH_NAME: "Clavardage avec {name}",
  CHAT_HISTORY_EMPTY_DESCRIPTION: "Lorsque vous terminez une session de clavardage, vous pouvez voir les d\xE9tails et la transcription de cette conversation ici.",
  CHAT_HISTORY_EMPTY_HEADING: "Il n'y a rien \xE0 voir ici!",
  CHAT_WITH: "Clavardage avec",
  CHAT_PROVIDER: "Fournisseur du clavardage",
  CHAT_VIEW_HISTORY: "Voir l'historique du clavardage",
  CHAT_HELPFUL_ACTIONS: "Actions utiles",
  CHAT_DETAILS: "D\xE9tails du clavardage",
  CHAT_HISTORY: "Historique du clavardage",
  CHAT_CARE_NAVIGATOR_ONLINE_HOURS: "Heures de service en ligne du navigateur de soins de sant\xE9",
  CHAT_CUSTOMER_CARE_ONLINE_HOURS: "Heures de service en ligne du service \xE0 la client\xE8le",
  CHAT_VIEW_ONLINE_HOURS: "Afficher les heures de service en ligne",
  CHAT_CREATE_OFFLINE_CASE_SUCCESS_BODY: "Nous y travaillons et nous vous contacterons bient\xF4t.",
  CHAT_CREATE_OFFLINE_CASE_SUCCESS: "Nous avons re\xE7u votre courriel",
  CHAT_CREATE_OFFLINE_CASE_ERROR: "Une erreur est survenue lors de l'envoi de votre courriel. Veuillez r\xE9essayer.",
  CHAT_OFFLINE_CASE_SUBJECT_TOO_SHORT: "Un message est requis",
  CHAT_OFFLINE_CASE_SUBJECT_LABEL: "Qu'est-ce qui vous tracasse?",
  CHAT_OFFLINE_CASE_DESCRIPTION: "Envoyez-nous votre message et nous y r\xE9pondrons d\xE8s que nous serons de nouveau en ligne. Jetez un coup d\u2019\u0153il \xE0 votre bo\xEEte de r\xE9ception pour une r\xE9ponse dans les prochaines 24 heures.",
  CHAT_SEND_EMAIL_TO_CARE_NAVIGATOR: "Envoyer un courriel au navigateur de soins de sant\xE9",
  CHAT_SEND_EMAIL_TO_CUSTOMER_CARE: "Envoyer un courriel au service \xE0 la client\xE8le",
  CHAT_SEND_EMAIL: "Envoyer un courriel",
  CHAT_EMERGENCY_MESSAGE: "Si ceci est une urgence, veuillez contacter le 911 imm\xE9diatement.",
  CHAT_FAILED_TO_LOAD_MESSAGE: "Nous avons quelques probl\xE8mes avec notre clavardage, mais nous travaillons pour que tout fonctionne. En attendant, vous pouvez envoyer vos questions \xE0 {email}.",
  CHAT_OFFLINE: "Hors ligne",
  CHAT_NOT_AVAILABLE: "Indisponible",
  CHAT_START_CHAT_WITH_TAB_WARNING: "Commencer \xE0 clavarder (s'ouvre dans un nouvel onglet)",
  CHAT_START_CHAT: "Commencer un clavardage",
  CHAT_CARE_NAVIGATOR_DESCRIPTION: "Obtenez de l'aide pour naviguer dans le paysage des soins, y compris une assistance directe pour trouver un fournisseur, un \xE9tablissement ou un service pour un besoin sp\xE9cifique.",
  CHAT_CARE_NAVIGATOR_HEADING: "Expert en soutien",
  CHAT_CUSTOMER_CARE_DESCRIPTION: "Un probl\xE8me est survenu? Vous avez une question au sujet de vos avantages sociaux, compte, ou personnes \xE0 charge? Discutez avec un membre de notre \xE9quipe.",
  CUSTOMER_CARE: "Service \xE0 la client\xE8le",
  CHAT_TEAM_DESCRIPTION: "Clavardez avec nos professionnels de la sant\xE9 agr\xE9\xE9s et notre service \xE0 la client\xE8le. Ils peuvent vous aider \xE0 \xE9pargner de l'argent en vous dirigeant aux meilleurs soins de sant\xE9 les plus abordables dans votre r\xE9gion, ainsi qu'\xE0 r\xE9pondre \xE0 vos questions sur vos avantages sociaux.",
  CHAT_TEAM_HEADING: "Recevez des r\xE9ponses de notre \xE9quipe de soins.",
  CHAT: "Clavarder",
  TOP_MATCH: "Meilleurs r\xE9sultats",
  HISTORY: "Historique",
  CHANGE_REPORT: "Rapport de changements",
  DOWNLOAD_REPORT: "T\xE9l\xE9charger le rapport",
  FULL_REPORT: "Rapport complet",
  REPORT_ID: "Identifiant du rapport",
  CHAT_WITH_HEALTH_CONCIERGE: "Clavarder avec un personnel infirmier agr\xE9\xE9",
  TALK_ABOUT_PERSONALIZED_HEALTH_NEEDS: "Parlez de votre sant\xE9 \xE0 un \xEAtre humain. Notre \xE9quipe de professionnels de la sant\xE9 agr\xE9\xE9s sont \xE0 votre disposition pour discuter avec vous.",
  HELP_FIND_RIGHT_RESOURCES_FOR_HEALTH_NEEDS: "Nous pouvons vous aider \xE0 trouver les ressources appropri\xE9es qui convient le mieux \xE0 vos besoins de sant\xE9.",
  VIEW_MORE_IN_OUR_HELP_CENTER: "En voir plus dans notre centre d'assistance",
  LEGACY_FEES: "Frais de succession",
  PENDING_FEES: "Frais en attente",
  ADHOC_FEE_TERM_TOOLTIP: "Pr\xE9cisez une valeur si les frais sont r\xE9currents. Par exemple, des frais mensuels de plate-forme sur 12 mois ou des frais trimestriels de conseils en mati\xE8re de sant\xE9 sur 4 trimestres.",
  ADHOC_FEE_UNIT_TOOLTIP: "Cette valeur peut repr\xE9senter soit plusieurs si\xE8ges, soit un seul (auquel cas la valeur serait de 1).",
  ADHOC_FEE_RATE_TOOLTIP: "Cette valeur devrait prendre en compte 'l'unit\xE9' et le 'terme' (si applicable). Par exemple, un taux qui est par unit\xE9 par mois.",
  OFFERED_BY_LEAGUE: "Offert par League",
  MORE_GREAT_RESOURCES: "Encore plus de ressources",
  LEAGUE_HEALTH_PROGRAM: "Programme de sant\xE9 de League",
  ADDITIONAL_BENEFITS_MODAL_DESCRIPTION: "Ces garanties et leurs explications peuvent \xEAtre trouv\xE9es dans l'application League.",
  CLEAR_FIELD_VALUE: "--Effacer la valeur du champ--",
  ADDITIONAL_BENEFITS_BANNER_DESCRIPTION: "Votre employeur a \xE9galement fourni les avantages sociaux suivants dans le cadre de l'ensemble de vos r\xE9compenses.",
  ADHOC_FEE_MULTIPART_TERM_ERROR: "Les deux champs doivent \xEAtre remplis",
  NO_CARE_PROVIDER_HISTORY: "L'identifiant d'utilisateur suivant n'a pas d'historique de fournisseur de soins",
  NO_PURCHASE_HISTORY: "L'identifiant d'utilisateur suivant n'a pas d'historique d'achat",
  NO_REWARDS_HISTORY: "L'identifiant d'utilisateur suivant n'a pas d'historique de r\xE9compenses",
  NO_HEALTH_GOAL_PROGRAMS: "L'identifiant d'utilisateur suivant n'a pas compl\xE9t\xE9 de programme d'objectifs sant\xE9",
  PROGRAM_GOAL: "Objectif",
  PROGRAM_END_DATE: "Date de fin",
  PROGRAM_START_DATE: "Date de d\xE9but",
  IN_PROGRESS_PROGRAM_DETAILS: "D\xE9tails du programme en cours",
  COMPLETED_PROGRAM_DETAILS: "D\xE9tails du programme compl\xE9t\xE9",
  ISSUE_INVOICE_SUCCESS: "Facture \xE9mise. Elle peut maintenant \xEAtre consult\xE9e dans l'onglet {link}.",
  ISSUE_INVOICE_MODAL_INFO: "\xCAtes-vous certain de vouloir \xE9mettre une facture ind\xE9pendante avec les frais suivants?",
  ISSUE_INVOICE_MODAL_TITLE: "Confirmer la facture",
  REMOVE_FEE_CONFIRM: "\xCAtes-vous certain de vouloir supprimer ces frais?",
  PREMIUMS_HEADER_COPY: "T\xE9l\xE9chargez un rapport contenant les d\xE9ductions par p\xE9riode de paie pour tous les employ\xE9s de ce groupe.",
  PREMIUMS_HEADER: "Rapports de primes",
  PREMIUMS: "Primes",
  PLAN_STATUS: "Statut du r\xE9gime",
  ENROLLING: "Adh\xE9sion",
  PENDING_FEE_UNITS: "Unit\xE9(s)",
  REMOVE_FEE_SUCCESS: "Frais supprim\xE9s",
  REMOVE_FEE: "Supprimer les frais",
  ADD_FEE_INFORMATION: "Ajoutez les frais de plate-forme, de mise en \u0153uvre, d'int\xE9gration et autres \xE0 une facture individuelle.",
  ISSUE_INVOICE: "\xC9mettre la facture",
  PREVIEW_INVOICE: "Pr\xE9visualiser la facture",
  PENDING_FEES_INFO: "Pr\xE9visualiser et \xE9mettre des factures. Tous les frais \xE9num\xE9r\xE9s ci-dessous seront ajout\xE9s \xE0 une seule facture.",
  NO_PENDING_FEES: "Il n'y a pas de frais en attente. Pour en ajouter, allez sur Frais.",
  PENDING_FEE: "frais en attente",
  SUBMIT_FEE_SUCCESS: "D\xE9pense soumise. Voir {link}.",
  QLE_WELCOME_FLEX_DOLLAR_TERM_TOOLTIP: "{group} vous fournit {flexDollarTerm}, qui correspondent \xE0 des sommes additionnelles que vous pouvez d\xE9penser comme bon vous semble sur vos avantages sociaux.",
  QLE_WELCOME_IMPORTANT_NOTE_EFFECTIVE_DATE: "Vous avez jusqu'au {qleEnrollmentEndDate} pour apporter des modifications \xE0 vos s\xE9lections. Si vous modifiez vos choix, vos nouvelles prestations prendront effet le {qleCoverageStartDate}",
  QLE_WELCOME_IMPORTANT_NOTE_PRORATED: "Les nouveaux fonds allou\xE9s \xE0 vos comptes bien-\xEAtre seront calcul\xE9s au prorata selon la date de votre \xE9v\xE9nement de vie admissible {qleDate}.",
  QLE_WELCOME_IMPORTANT: "Des choses importantes \xE0 noter :",
  QLE_WELCOME_SUBMIT_CHANGES: "Soumettre vos modifications (ou ne rien changer)",
  QLE_WELCOME_MANAGE_DEPENDENTS: "Ajouter, modifier ou supprimer des personnes \xE0 charge",
  QLE_WELCOME_SPEND_AVAILABLE_FLEX: "D\xE9pensez votre {flexDollarTerm} disponibles sur vos avantages sociaux",
  QLE_WELCOME_REVIEW_YOUR_CURRENT_ELECTIONS: "R\xE9viser vos choix actuels",
  QLE_WELCOME_HERE_IS_WHAT_YOU_CAN_DO: "Voici ce que vous pouvez faire :",
  QUALIFYING_LIFE_EVENT_QLE: "\xC9v\xE9nement de vie admissible (EVA)",
  QLE_WELCOME_SUB_HEAD_DESCRIPTION: "Vous avez r\xE9cemment v\xE9cu un {qleLink}. V\xE9rifions si votre r\xE9gime de prestations actuel a besoin d'\xEAtre modifi\xE9 pour mieux r\xE9pondre \xE0 vos besoins.",
  QLE_WELCOME: "Bon retour parmi nous, {userName}",
  UNIT_TOOLTIP: "Cette valeur peut repr\xE9senter soit le nombre d'employ\xE9s, soit une op\xE9ration unique, auquel cas la valeur serait de 1.",
  YEARS_LOWERCASE: "ann\xE9es",
  QUARTERS_LOWERCASE: "trimestres",
  QUARTER_LOWERCASE: "trimestre",
  MONTHS_LOWERCASE: "mois",
  DAYS_LOWERCASE: "jours",
  YEARS: "Ann\xE9es",
  QUARTERS: "Trimestres",
  QUARTER: "Trimestre",
  MONTHS: "Mois",
  FEE_RATE_PLACEHOLDER: "10,00",
  FEE_RATE_TOOLTIP: "Cette valeur devrait prendre en compte 'l'unit\xE9' et le 'terme' (si applicable). Par exemple, un taux qui est par unit\xE9 par mois.",
  FEE_RATE: "Taux",
  TERM_TOOLTIP: "Pr\xE9cisez une valeur si les frais sont r\xE9currents. Par exemple, des frais mensuels de plate-forme sur 12 mois ou des frais trimestriels de conseils en mati\xE8re de sant\xE9 sur 4 trimestres",
  TERM: "Terme",
  UNITS_TOOLTIP: "Cette valeur peut repr\xE9senter soit plusieurs si\xE8ges, soit un seul (auquel cas la valeur serait de 1)",
  UNITS: "Nombre d'unit\xE9s",
  BILLING_PERIOD_END_DATE: "Date de fin de la p\xE9riode de facturation (facultatif)",
  BILLING_PERIOD_START_DATE: "D\xE9but de la p\xE9riode de facturation",
  BILLING_DIVISION: "Service de la facturation",
  INTEGRATION: "Int\xE9gration",
  IMPLEMENTATION: "Mise en \u0153uvre",
  HEALTH_ADVISORY: "Consultatif sur la sant\xE9",
  PLATFORM: "Plateforme",
  FEE_TYPE_HELPER: "S\xE9lectionnez le type de frais",
  FEE_TYPE: "Type de frais",
  CHOOSE_ONE: "Choisir un",
  TAKE_ACTION: "Intervenir",
  NEVER_SUBMITTED_DEFAULTED: "Jamais soumise (en d\xE9faut)",
  NOT_SUBMITTED: "Non soumise",
  LAST_SUBMITTED: "Derni\xE8re soumission {submittedTime}",
  SUBMITTED_STATE: "Date soumise",
  ENROLLMENT_CLOSED: "Inscription ferm\xE9e",
  IN_ENROLLMENT: "En p\xE9riode d'adh\xE9sion",
  UPDATE_GROUP_CATEGORIES_SUCCESS: "Liste des couvertures mise \xE0 jour",
  UPDATE_GROUP_CATEGORIES_DESCRIPTION: "Veuillez s\xE9lectionner un fichier CSV \xE0 utiliser pour mettre \xE0 jour la couverture de ce compte de d\xE9penses (le traitement peut prendre un moment).",
  UPDATE_GROUP_CATEGORIES: "Mettre les cat\xE9gories \xE0 jour",
  PREFERENCES: "Pr\xE9f\xE9rences",
  LOST_COVERAGE: "Perte de couverture",
  DEPENDENT_CHILD_OF_DOMESTIC_PARTNER: "Cette personne \xE0 charge est-elle l'enfant de votre partenaire domestique?",
  DEPENDENT_OVERAGE_DISABLED_CHILD: "Est-ce que cet enfant est une personne \xE0 charge invalide trop \xE2g\xE9e?",
  DEPENDENT_FULL_TIME_STUDENT: "Est-ce que cet enfant est un \xE9tudiant \xE0 temps plein?",
  DEPENDENT_TOBACCO_USER: "Est-ce que la personne \xE0 charge utilise des produits \xE0 base de tabac?",
  REMOVE_DEPENDENT_MODAL_MESSAGE: "Veuillez identifier une date de fin de la garantie ainsi qu'une raison pour la cessation pour supprimer <strong>{dependentName}</strong> as a dependent of <strong>{employeeName}</strong>.",
  DEPENDENT_DETAILS: "Information de la personne \xE0 charge",
  BASIC_INFO: "Information de base",
  VERIFIED: "V\xE9rifi\xE9",
  VERIFICATION_STATUS_EFFECTIVE_DATE: "Date de prise d'effet du statut de v\xE9rification",
  VERIFICATION_STATUS: "Statut de v\xE9rification",
  REMOVE_QLE_DATE: "Supprimer la date de l'\xE9v\xE9nement de vie admissible",
  ADD_QLE_DATE: "Ajouter la date de l'\xE9v\xE9nement de vie admissible",
  ADD_QLE_TYPE: "Ajouter le type d'\xE9v\xE9nement de vie admissible",
  REMOVE_DEPENDENT_MODAL_TITLE: "Supprimer {dependentName}",
  DEPENDENT_TERMINATION_PENDING: "Devient inactif {terminationDate}",
  TERMINATION_REASON: "Motif de la cessation",
  BENEFITS_TERMINATION_DATE: "Date de fin de la garantie",
  CHILD_OF_DOMESTIC_PARTNER: "Enfant du partenaire domestique",
  USES_TOBACCO: "Consomme du tabac",
  AMOUNT_PER_PERIOD: "{amount} / {period}",
  CERTIFICATION_ERROR_REQUIRED: "Cette information est requise pour proc\xE9der",
  CERTIFICATION_TITLE_TOOLTIP_TOBACCO_CESSATION: "<p>Vous vous qualifiez comme fumeur si vous avez consomm\xE9 du tabac plus de <strong>une fois par mois en moyenne au cours des 6 derniers mois</strong>.</p><p>L'usage du tabac comprend le tabagisme, la mastication, le reniflement ou le vapotage. Cette surtaxe vise \xE0 promouvoir la sant\xE9 et \xE0 compenser une partie des co\xFBts de soins de sant\xE9 sup\xE9rieurs \xE0 la moyenne encourus par les fumeurs.</p>",
  CERTIFICATION_OPTION_NO_TOBACCO_CESSATION_DEPENDENTS: "<strong>Non</strong> - Moi et mes personnes \xE0 charge sommes non-fumeurs",
  CERTIFICATION_OPTION_YES_TOBACCO_CESSATION_DEPENDENTS: "<strong>Oui</strong> - Moi ou mes personnes \xE0 charge sommes fumeurs",
  CERTIFICATION_OPTION_NO_TOBACCO_CESSATION_MYSELF: "<strong>Non</strong> - Je suis non-fumeur",
  CERTIFICATION_OPTION_YES_TOBACCO_CESSATION_MYSELF: "<strong>Oui</strong> - Je suis fumeur",
  CERTIFICATION_LINK_TOBACCO_CESSATION: "Besoin d'aide pour arr\xEAter? Apprenez-en plus ici",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION_DEPENDENTS: "Si vous et votre/vos personne(s) \xE0 charge choisissent {name}, les frais pour le tabac ne seront plus appliqu\xE9s sur le montant de votre r\xE9gime d'assurance-maladie.",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION_MYSELF: "Si vous choisissez {name}, les frais pour le tabac ne seront plus appliqu\xE9s sur le montant de votre r\xE9gime d'assurance-maladie.",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION: "Vous devez attester si vous ou les personnes \xE0 votre charge \xE2g\xE9es de plus de 18 ans consommez du tabac ou non afin de souscrire une assurance m\xE9dicale. Des frais de <strong>{amount}</strong> s'appliqueront au co\xFBt de votre r\xE9gime d'assurance-maladie si vous certifiez <strong>Oui</strong>.",
  CERTIFICATION_TITLE_TOBACCO_CESSATION_DEPENDENTS: "Utilisez-vous (ou vos personnes \xE0 charge) des produits \xE0 base de tabac?",
  CERTIFICATION_TITLE_TOBACCO_CESSATION_MYSELF: "Utilisez-vous des produits \xE0 base de tabac?",
  CERTIFICATION_TITLE_MEDICARE: "\xCAtes-vous inscrit \xE0 Medicare?",
  CERTIFICATION_OPTION_NO_MEDICARE: "<strong>Non</strong> - Je ne suis pas inscrit \xE0 Medicare",
  CERTIFICATION_OPTION_YES_MEDICARE: "<strong>Oui</strong> - Je suis inscrit \xE0 Medicare",
  LEARN_MORE_ABOUT_MEDICARE: "En savoir plus sur Medicare",
  CERTIFICATION_LINK_MEDICARE: "En savoir plus",
  CERTIFICATION_DESCRIPTION_MEDICARE_CONTACT: "Si vous avez des questions, ou d\xE9cidez \xE0 de vous inscrire \xE0 Medicare \xE0 l'avenir, veuillez contacter <a>aide@league.com</a>.",
  CERTIFICATION_DESCRIPTION_TOOLTIP_MEDICARE: "Les ERSS permettent \xE0 {employerName} de mettre de c\xF4t\xE9 des fonds pour vous rembourser les frais m\xE9dicaux admissibles; vous recevrez les fonds de l'entreprise dans votre ERSS au lieu de votre CES.",
  CERTIFICATION_DESCRIPTION_MEDICARE_WITH_FALLBACK: "Si vous \xEAtes inscrit \xE0 Medicare (Part A, B, C ou D), vous n'avez pas le droit de verser ou de recevoir des cotisations patronales \xE0 un {ineligibleBenefitIfSelectedName} ({ineligibleBenefitIfSelectedShortName}). Vous pouvez toujours vous inscrire \xE0 un r\xE9gime d'assurance-maladie admissible au {ineligibleBenefitIfSelectedShortName} - mais au lieu de recevoir {ineligibleBenefitIfSelectedShortName}, vous serez inscrit \xE0 un {eligibleBenefitIfSelectedName} ({eligibleBenefitIfSelectedShortName}).",
  CERTIFICATION_DESCRIPTION_MEDICARE: "Si vous \xEAtes inscrit \xE0 Medicare (Part A, B, C ou D), vous n'avez pas le droit de verser ou de recevoir des cotisations patronales \xE0 un {ineligibleBenefitIfSelectedName} ({ineligibleBenefitIfSelectedShortName}). Vous pouvez toujours vous inscrire \xE0 un r\xE9gime d'assurance-maladie admissible au {ineligibleBenefitIfSelectedShortName} - vous ne serez tout simplement pas inscrit \xE0 un {ineligibleBenefitIfSelectedShortName}.",
  CERTIFICATION_TITLE_ALTERNATE_COVERAGE: "Est-ce que votre conjoint a acc\xE8s \xE0 une couverture ailleurs?",
  CERTIFICATION_OPTION_NO_ALTERNATE_COVERAGE: "<strong>Non</strong> - Mon conjoint n'a pas acc\xE8s \xE0 une couverture offerte par son employeur",
  CERTIFICATION_OPTION_YES_ALTERNATE_COVERAGE: "<strong>Oui</strong> - Mon conjoint a acc\xE8s \xE0 une couverture offerte par son employeur",
  LEARN_MORE_ABOUT_THIS_SURCHARGE: "En savoir plus sur cette majoration",
  CERTIFICATION_LINK_ALTERNATE_COVERAGE: "En savoir plus",
  CERTIFICATION_DESCRIPTION_ALTERNATE_COVERAGE: "Vous devez attester que votre conjoint ou partenaire b\xE9n\xE9ficie d'une couverture m\xE9dicale offerte par son employeur. Une charge sera appliqu\xE9e au co\xFBt global de votre r\xE9gime m\xE9dical choisi si vous certifiez <strong>Oui</strong>",
  CERTIFICATION_PROGRAM_DISCOUNT_APPLIED: "Merci d'avoir compl\xE9t\xE9 le {name} - Nous avons appliqu\xE9 un rabais de <strong>{discount}</strong> \xE0 votre option de r\xE9gime {benefitSetName}.",
  CERTIFICATION_PROGRAM_DISCOUNT_AVAILABLE: "Vous pourriez recevoir un rabais de <strong>{discount}</strong> de votre r\xE9gime <strong>{benefitSetName}</strong> si vous compl\xE9tez <strong>{name}</strong> dans {deadlineDays} de votre date d'adh\xE9sion.",
  DEFAULT_UNITS: "Unit\xE9s par d\xE9faut",
  AT_RISK_DESCRIPTION: "Description de '\xE0 risque'",
  AT_RISK: "\xC0 risque",
  RISK: "Risque",
  RISK_FACTOR_RESULTS: "R\xE9sultats des facteurs de risque",
  USER_INPUT: "Intrant de l'utilisateur",
  RISK_FACTOR: "Facteur de risque",
  RISK_FACTOR_INPUTS: "Intrants de facteur de risque",
  ENTER: "Entr\xE9e",
  INPUT_USER_ID: "Veuillez entrer votre identifiant d'utilisateur",
  HOME: "Accueil",
  HEALTH_PROGRAMS: "Programmes sant\xE9",
  HEALTH_PROFILE: "Profil sant\xE9",
  USER_PROFILE: "Profil de l'utilisateur",
  PRICING_CONFIG_DOWNLOAD: "T\xE9l\xE9charger la configuration de la tarification du groupe\xA0:",
  EXPORT_PRICING_CONFIG_TO_CSV: "Exporter la configuration du groupe au format CSV",
  PLEASE_ENTER_A_VALID_EMAIL: "Veuillez entrer une adresse courriel valide",
  IF_YOU_NEED_HELP_EMAIL_US: "Si vous avez besoin d'aide ou avez des questions \xE0 propos de votre compte, \xE9crivez \xE0 {emailLink}.",
  SORRY_WE_CANT_FIND_PAGE: "D\xE9sol\xE9, nous ne trouvons pas la page que vous cherchez.",
  UH_OH_SOMETHING_WENT_WRONG: "Oh oh! Une erreur est survenue",
  YOURE_A_TAD_EARLY_NO_DATE: "Vous \xEAtes l\xE9g\xE8rement en avance. Veuillez v\xE9rifier \xE0 nouveau durant la date du d\xE9but de l'inscription pour activer votre compte avec League.",
  YOURE_A_TAD_EARLY: "Vous \xEAtes l\xE9g\xE8rement en avance. Veuillez v\xE9rifier \xE0 nouveau le {enregistrementStartDate} (date de d\xE9but de l'inscription) pour activer votre compte avec League.",
  WE_COULDNT_FIND_YOU_DESCRIPTION: "Si vous ne pouvez toujours pas y acc\xE9der, envoyez un courriel \xE0 {emailLink} pour du soutien suppl\xE9mentaire.",
  WE_COULDNT_FIND_YOU: "Nous n'avons pas pu vous trouver. Veuillez v\xE9rifier vos informations ci-dessus et r\xE9essayer.",
  BENEFIT_TYPE: "Type de garantie",
  BENEFIT_ID: "Identifiant de la garantie",
  BENEFIT_SELECTION_INFO: "<strong>Identifiant de la garantie</strong> : {benefitId} </br> \n<strong>Type de garantie</strong> : {benefitType}",
  MEMBER_POLICY_ID_DELETED: "Les informations de la carte ont \xE9t\xE9 effac\xE9es",
  MEMBER_POLICY_ID_SUCCESS: "Les renseignements sur la carte ont \xE9t\xE9 sauvegard\xE9s! Vous pouvez les revoir en tout temps dans votre portefeuille.",
  MEMBER_POLICY_ID_ERROR: "Une erreur est survenue de notre c\xF4t\xE9. Veuillez r\xE9essayer.",
  PRICING_CONFIG_SAVE_CONTENT: "<strong>Remarque :</strong> Sauvegarder une configuration de tarification prendra effet imm\xE9diatement sur la garantie de l'employ\xE9 choisie {benefitId}",
  PRICING_CONFIG_SAVE_HEADER: "Confirmer la sauvegarde de la configuration de tarification de la garantie",
  CAROUSEL_WALLET_DESCRIPTION: "Un portefeuille num\xE9rique personnalis\xE9 avec vos cartes d'avantages sociaux.",
  CAROUSEL_WALLET_HEADING: "Portefeuille",
  CAROUSEL_HEALTH_PROGRAMS_DESCRIPTION: "D\xE9couvrez les d\xE9fis que vous pouvez relever pour am\xE9liorer votre sant\xE9. ",
  CAROUSEL_HEALTH_PROGRAMS_HEADING: "Programmes de sant\xE9",
  CAROUSEL_CHAT_DESCRIPTION: "Obtenez des r\xE9ponses rapides \xE0 toutes vos questions de sant\xE9 et de garanties.",
  CAROUSEL_CHAT_HEADING: "Clavardage en direct",
  FOR_YOU_ONE_CHILD: "Pour vous et votre enfant",
  FOR_YOU_CHILDREN: "Pour vous et vos enfants",
  FOR_YOU_SPOUSE_CHILDREN: "Pour vous, votre conjoint et votre ou vos enfants",
  FOR_YOU_SPOUSE: "Pour vous et votre conjoint",
  FOR_YOU: "Pour vous",
  BENEFIT_NAME_WITH_DEPENDENT_COVERAGE: "{benefitName} ({dependentCoverage})",
  IDENT_AMOUNT: "{amount}",
  IDENT_VALUE: "{value}",
  NUMBER_OF_BENEFITS: "{number} garanties",
  WHERE_CAN_I_FIND_MY: "O\xF9 puis-je trouver mon",
  PRIVACY_POLICY: "Politique de confidentialit\xE9",
  SET_EMPLOYER_BENEFIT_PRICE_CONFIG_SUCCESS_MESSAGE: "La configuration du prix de la garantie a bien \xE9t\xE9 sauvegard\xE9e",
  CHAT_DESCRIPTION: "Obtenez des r\xE9ponses rapides \xE0 toutes vos questions de sant\xE9 et de garanties.",
  CHAT_HEADING: "Clavarder",
  SIGN_IN: "Se connecter",
  IF_THIS_WASNT_YOU: "Si cela n'\xE9tait pas vous, ou si vous avez des questions, veuillez nous contacter au",
  LOOKS_LIKE_YOU_ALREADY_ACTIVATED: "Il semble que votre compte ait d\xE9j\xE0 \xE9t\xE9 activ\xE9.",
  YOUR_ACCOUNT_IS_ALREADY_ACTIVE: "Votre compte est d\xE9j\xE0 actif.",
  CREATE_PASSWORD: "Cr\xE9er le mot de passe.",
  WE_FOUND_YOUR_ACCOUNT: "Nous avons trouv\xE9 votre compte. Nous pouvons terminer en cr\xE9ant votre mot de passe.",
  NICE_TO_SEE_YOU: "{name}, ravi de vous revoir.",
  APPROVED_COVERAGE_EOI_BANNER: "Vous serez seulement factur\xE9 pour votre couverture une fois approuv\xE9e par votre assureur suite \xE0 la soumission de votre formulaire {eoiTooltip}. Si vous disposez d'une couverture existante, vous continuerez de payer les primes pour ces montants approuv\xE9s.",
  APPROVED_COVERAGE_TOOLTIP: "C'est le montant de la couverture pour laquelle vous \xEAtes actuellement approuv\xE9. Cette couverture ne vous sera factur\xE9e que lorsque votre formulaire de preuve d'assurabilit\xE9 aura \xE9t\xE9 soumis \xE0 votre assureur et approuv\xE9 par celui-ci.",
  APPROVED_COVERAGE: "Couverture approuv\xE9e",
  TOTAL_COST: "Co\xFBt total",
  COVERAGE: "Couverture",
  COST: "Co\xFBt",
  COVERAGE_FOR: "Couverture pour",
  CURRENT_COVERAGE_INFO: "Ceci est un r\xE9capitulatif de votre couverture actuelle. Utilisez cette information pour choisir vos s\xE9lections pour cette ann\xE9e.",
  YOUR_CURRENT_PLAN: "Votre r\xE9gime actuel",
  YOUR_CURRENT_PLAN_COST: "Votre co\xFBt actuel pour ce r\xE9gime",
  SEE_CURRENT_COVERAGE: "Voir ma couverture actuelle",
  CURRENT_COVERAGE: "Couverture actuelle",
  EMPLOYER_ALLOCATION: "Allocation de l'employeur",
  PERSONAL_ALLOCATION: "Votre allocation personnelle",
  TOTAL_ALLOCATION: "Attribution totale",
  COORDINATION_OF_BENEFITS_EXPLANATION: "Lorsque vous soumettez une demande, votre r\xE9gime d'assurance sera votre <strong>assureur principal</strong>, et si votre conjoint a un r\xE9gime d'assurance qui vous couvre, il s'agira de  <strong>l'assureur secondaire</strong>. Cela signifie que pour toutes vos demandes soumises, votre r\xE9gime paiera d'abord, et tout montant restant sera pay\xE9 par le r\xE9gime de votre partenaire.",
  COORDINATION_OF_BENEFITS_EXPLANATION_TITLE: "Qu'est-ce que cela signifie pour mes demandes?",
  EXISTING_COVERAGE_HEALTH: "La couverture pour soins de sant\xE9 existante de votre conjoint.",
  EXISTING_COVERAGE_DENTAL: "La couverture dentaire existante de votre conjoint.",
  EXISTING_COVERAGE_VISION: "La couverture pour soins de la vue existante de votre conjoint.",
  EXISTING_COVERAGE_DRUG: "La couverture pour m\xE9dicament existante de votre conjoint.",
  COORDINATION_OF_BENEFITS_SUBTITLE: "Parfois, deux r\xE9gimes d'assurance peuvent s'additionner pour couvrir les demandes d'une m\xEAme personne. On appelle cela la coordination des prestations. Cela vous aide \xE0 mieux couvrir les co\xFBts de votre demande.",
  COORDINATION_OF_BENEFITS: "Coordination des prestations",
  BENEFIT: "garantie",
  CANT_UNDO: "Vous ne pourrez pas annuler cette action.",
  CONFIRM_REMOVE_ITEM: 'Voulez-vous vraiment supprimer "{itemName}"?',
  ADD_AN_ITEM: "Ajouter un article",
  BLOCK_TOGGLE_NODE_MODAL_BODY: "Nous avons remarqu\xE9 que certaines des modifications apport\xE9es \xE0 ce formulaire n'ont pas \xE9t\xE9 sauvegard\xE9es. Assurez-vous d'enregistrer vos modifications avant de poursuivre. <br/><br/>Si vous ne d\xE9sirez pas enregistrer vos modifications ou mises \xE0 jour, vous pouvez r\xE9initialiser le formulaire pour retrouver la version pr\xE9c\xE9demment enregistr\xE9e.",
  BLOCK_TOGGLE_NODE_MODAL_HEADER: "Certaines modification apport\xE9es \xE0 ce formulaire n'ont pas \xE9t\xE9 sauvegard\xE9es",
  ENABLE_TYPEFORM_TOOLTIP: "Cela permet de d\xE9terminer si un membre peut mettre \xE0 jour les choix de son r\xE9gime d'avantages sociaux. Cette option est fournie par Ops et peut \xEAtre personnalis\xE9e par type d'\xE9v\xE9nement de vie et par cat\xE9gorie d'avantages sociaux. D\xE9sactiver cette option pour les \xE9v\xE9nements de vie qui ne permettent pas aux participants de mettre \xE0 jour les choix de leur r\xE9gime.",
  ENABLE_MANAGE_DEPENDENTS_TOOLTIP: "Cela permet de d\xE9terminer si un membre peut passer \xE0 la page de gestion des personnes \xE0 charge, o\xF9 il pourra ajouter ou supprimer des personnes \xE0 charge. D\xE9sactiver cette option pour les \xE9v\xE9nements de vie qui ne permettent pas aux membres de mettre \xE0 jour les personnes \xE0 leur charge.",
  WHERE_TO_DIRECT_MEMBERS_TOOLTIP: "Cela permet de d\xE9terminer si un membre est renvoy\xE9 \xE0 l'adh\xE9sion dans NEX (actuellement disponible uniquement s'il s'agit d'un \xE9v\xE9nement de vie complet) ou s'il s'agit d'une configuration personnalis\xE9e que vous pouvez effectuer pour g\xE9rer les personnes \xE0 charge et/ou Typeform (gestion actuelle des \xE9v\xE9nements de vie partiels).",
  QLE_REQUIRES_HR_TOOLTIP: "Cela permet de d\xE9terminer si un membre peut proc\xE9der directement \xE0 la mise \xE0 jour de ses garanties ou s'il doit d'abord obtenir l'approbation des RH.",
  ALLOW_PRECEDING_QLE_DATE_TOOLTIP: "Cela permet aux membres de d\xE9clarer un \xE9v\xE9nement de vie avant qu'il se soit produit, c.-\xE0-d. un \xE9v\xE9nement futur.",
  ALLOW_FOLLOWING_QLE_DATE_TOOLTIP: "Cela permet aux membres de d\xE9clarer un \xE9v\xE9nement de vie apr\xE8s qu'il s'est produit, c.-\xE0-d. un \xE9v\xE9nement pass\xE9.",
  FIRST_NAME: "Pr\xE9nom",
  SELECT_ONE_OF_THE_OPTIONS: "Veuillez choisir une des options ci-dessus.",
  YOU_ARE_ELIGIBLE_IF: "<p>Vous \xEAtes admissible aux prestations si votre employeur vous offre une assurance maladie et dentaire.</p><p>Vous \xEAtes admissible aux prestations m\xEAme si vous avez choisi de ne pas en b\xE9n\xE9ficier.</p>",
  NO_I_AM_NOT_ELIGIBLE: "Non, je ne suis pas admissible",
  YES_I_AM_ELIGIBLE: "Oui, je suis admissible",
  ARE_YOU_ELIGIBLE_FOR_BENEFITS: "\xCAtes-vous admissible aux avantages sociaux?",
  IF_YOURE_NOT_SURE: "Vous n'\xEAtes pas certain d'\xEAtre admissible? Contactez l'administrateur de votre magasin pour le savoir.",
  IF_ELIGIBLE_FOR_BENEFITS: "Activez votre compte League pour d\xE9bloquer votre exp\xE9rience personnalis\xE9e de sant\xE9 et de bien-\xEAtre. D'abord, dites-nous si vous \xEAtes admissible aux avantages sociaux.",
  SAY_HELLO_TO_LEAGUE: "Dites bonjour \xE0 League",
  FIND_ACCOUNT: "Trouver le compte",
  START_VERIFICATION: "Commencer la v\xE9rification",
  INVITE_EXPIRED_TEXT: "Malheureusement, votre invitation a expir\xE9. Mais pas d'inqui\xE9tude, vous devez simplement confirmer votre identit\xE9 \xE0 nouveau avant de pouvoir cr\xE9er votre compte. Apr\xE8s la v\xE9rification, vous aurez une heure pour cr\xE9er votre compte.",
  INVITE_EXPIRED_HEADING: "La v\xE9rification a expir\xE9",
  NOT_VERIFIED_TEXT: "Avant de commencer, nous avons besoin de quelques informations suppl\xE9mentaires pour vous aider \xE0 cr\xE9er votre compte League",
  NOT_VERIFIED_HEADING: "La validation n'a pas encore \xE9t\xE9 faite",
  VIEW_BENEFITS: "Voir les garanties.",
  IMPORT_PLAN_DESIGN_SUCCESS: "C'est fait! Nous avons import\xE9 la feuille de calcul Google Sheet et les garanties du groupe ont \xE9t\xE9 mis \xE0 jour.",
  EXPORT_PLAN_DESIGN_SUCCESS: "C'est fait! Nous avons export\xE9 les garanties et votre feuille de calcul Google Sheet est pr\xEAte.",
  CUSTOM_PLAN_DESCRIPTION: "Ces prestations seront export\xE9es sur une nouvelle feuille Google.",
  CUSTOM_PLAN: "R\xE9gime personnalis\xE9",
  AVAILABLE_BENEFITS_DESCRIPTION: "Toutes les prestations qui ont d\xE9j\xE0 \xE9t\xE9 ajout\xE9es \xE0 ce groupe appara\xEEtront ici.",
  EXPORT_CUSTOM_PLAN_DESCRIPTION: "S\xE9lectionnez les prestations que vous voudriez exporter.",
  EXPORT_PLAN_DESIGN_DESCRIPTION: "L'exportation d'une conception de r\xE9gime g\xE9n\xE8re une feuille de calcul Google Sheet selon la configuration pr\xE9existante de la garantie. Vous pouvez utiliser ce document pour mettre \xE0 jour le r\xE9gime actuel, puis le r\xE9importer via notre outil d'administrateur. Vous pouvez \xE9galement l'utiliser pour cr\xE9er de nouvelles garanties pour une nouvelle ann\xE9e.",
  IMPORT_PLAN_DESIGN_DESCRIPTION: "L'importation d'une conception de r\xE9gime g\xE9n\xE8re des classes, r\xE9gimes et garanties depuis un mod\xE8le Google Sheet. La garantie est cr\xE9\xE9 si le champ d'identification de la garantie est vide, et mis \xE0 jour s'il est rempli. L'importation d'un r\xE9gime n'efface aucune donn\xE9e. Elle ne fait qu'en cr\xE9er ou en mettre \xE0 jour.",
  EXPORT_BENEFIT_IDS: "Exporter avec les identifiants de la garantie",
  EXPORT_CUSTOM_PLAN: "Exporter le r\xE9gime personnalis\xE9",
  EXPORT_PLAN_DESIGN: "Exporter la conception du r\xE9gime",
  INVOICE_HEADER_INVOICE_DATE: "Date de la facture",
  NO_ITEMS_OF_NAME: "Non {name}",
  TURN_ON_IMPLICATIONS: "Vos configurations actuelles seront activ\xE9es. Lorsque vous les appliquerez, les membres pourront voir/rapporter ce type d'\xE9v\xE9nement de vie dans l'exp\xE9rience du membre.",
  TURN_OFF_IMPLICATIONS: "En d\xE9cochant cet \xE9v\xE9nement de vie admissible, vos configurations seront d\xE9sactiv\xE9es. <strong>Une fois que vous cliquerez sur soumettre</strong>, les membres ne pourront plus voir/rapporter ce type d'\xE9v\xE9nement de vie admissible dans l'exp\xE9rience du membre.",
  ARE_YOU_SURE_TURN_QLE: "Voulez-vous vraiment tourner ce type d'\xE9v\xE9nement de vie {toggle}?",
  TURN: "Tourner {toggle}",
  TURN_THIS_QLE: "Tourner {toggle} cet \xE9v\xE9nement de vie",
  QLE_CONFIG_FORM_ERROR_MISSING_NON_NEX_CONFIG: "Veuillez s\xE9lectionner une configuration. Vous devez configurer au moins une des options ci-dessus",
  QLE_CONFIG_FORM_ERROR_MISSING_HR_EMAIL: "Veuillez saisir l'adresse courriel ou les adresses courriel du ou des destinataires \xE0 notifier pour approbation.",
  QLE_CONFIG_FORM_ERROR_REPORTING_WINDOW: "Veuillez s\xE9lectionner une fen\xEAtre de d\xE9claration. Vous devez configurer au moins une des options ci-dessus",
  SEND_MEMBERS_TO_MANAGE_DEPENDENTS_OR_TYPEFORM: "Configuration personnalis\xE9e - Envoyer les membres \xE0 G\xE9rer les personnes \xE0 charge/Typeform (\xE9v\xE9nements de vie partiels/inscription \xE0 Typeform)",
  SEND_MEMBERS_TO_ENROLLMENT_IN_NEX_FULL_QLE: "Envoyer les membres \xE0 l'inscription \xE0 NEX (\xE9v\xE9nement de vie complet)",
  WHERE_SHOULD_MEMBERS_BE_DIRECTED: "O\xF9 les membres devraient-ils \xEAtre dirig\xE9s?",
  PASTE_TYPEFORM_URL_HERE: "Coller l'URL du Typeform ici",
  TYPEFORM_URL_CAPS: "URL du Typeform :",
  REMOVE_CAPS: "RETIRER",
  ADD_CAPS: "AJOUTER",
  LOWERCASE_DEPENDENTS: "personnes \xE0 charge",
  ALLOW_MEMBERS_TO_CHANGE_DEPENDENTS: "Permettre aux membres de ",
  ENABLE_TYPEFORM: "Activer Typeform?",
  ENABLE_MANAGE_DEPENDENTS: "Activer la gestion des personnes \xE0 charge?",
  CUSTOM_CONFIGURATION_BLURB: "Lorsque vous configurez un \xE9v\xE9nement de vie personnalis\xE9 ou partiel, le membre ne retourne pas \xE0 l'inscription dans NEX. Vous pouvez activer la gestion des personnes \xE0 charge (les membres peuvent ajouter/supprimer les personnes \xE0 leur charge) et/ou Typeform (les membres mettent \xE0 jour leurs choix de r\xE9gime). Si vous activez Typeform, collez votre lien Typeform dans le champ ci-dessous.",
  CUSTOM_CONFIGURATION: "Configuration personnalis\xE9e",
  PLEASE_ENTER_THE_EMAIL: "Veuillez saisir l'adresse courriel ou les adresses courriel du ou des destinataires \xE0 notifier pour approbation.",
  SEPARATE_MULTIPLE_EMAIL: "S\xE9parer plusieurs adresses courriel par une virgule",
  HR_EMAIL: "Adresse(s) courriel des RH",
  REQUIRES_APPROVAL_FROM_HR: "Doit \xEAtre approuv\xE9 par les RH?",
  FULL_TIME_CLASS: "Cat\xE9gorie - temps plein",
  NUMBER_OF_DAYS_ALLOWED_FOLLOWING: "Nombre de jours autoris\xE9s pour d\xE9clarer *",
  NUMBER_OF_DAYS_ALLOWED_PRECEDING: "nombre de jours autoris\xE9s avant la date de l'\xE9v\xE9nement de vie *",
  PRECEDING_LOWERCASE: "avant",
  FOLLOWING_LOWERCASE: "apr\xE8s",
  PRECEDING: "AVANT",
  FOLLOWING: "APR\xC8S",
  ALLOW_MEMBERS_TO_REPORT_THE_QLE_EVENT_DATE: "Permettre aux membres de signaler un \xE9v\xE9nement de vie {followOrPrecede} la date de cet \xE9v\xE9nement?",
  WHEN_A_MEMBER_REPORTS_A_QLE_TO_LEAGUE: "Un membre qui signale un \xE9v\xE9nement de vie \xE0 League doit indiquer la date \xE0 laquelle cet \xE9v\xE9nement est survenu. Vous pouvez configurer la fen\xEAtre disponible qu'un membre doit utiliser pour signaler un \xE9v\xE9nement de vie avant et apr\xE8s la date de cet \xE9v\xE9nement.",
  QLE_REPORTING_WINDOW: "Fen\xEAtre de d\xE9claration d'un \xE9v\xE9nement de vie",
  INFINITE_DAYS: "\u221E infinit\xE9 de jours",
  NUMBER_OF_DAYS_MUST_BE_GREATER_THAN_0: "Le nombre de jours doit \xEAtre sup\xE9rieur \xE0 0",
  ALLOW_INFINITE_NUMBER_OF_DAYS: "Autoriser un nombre infini de jours (\u221E)",
  QLE_CONFIG: "Configurations de l'\xE9v\xE9nement de vie admissible",
  QLE_CONFIGURATIONS: "Configurations de l'\xE9v\xE9nement de vie",
  LEAGUE_MEMBER_EXCLUSIVE: "EXCLUSIVEMENT R\xC9SERV\xC9 AUX MEMBRES DE LEAGUE",
  DOLLAR_OFF: "RABAIS DE {discount}",
  NUMBER_MINS: " \u2022 {durationValue} minutes",
  PLUS_MORE: "{date} +PLUS",
  REGULAR_PRICE: "Prix courant {regularPrice}",
  LOOKING_FOR_SOMETHING_ELSE: "Vous cherchez autre chose?",
  BROWSE_HEALTH_PROGRAM_CATEGORIES: "Parcourir les cat\xE9gories de programmes en sant\xE9.",
  HEALTH_PROGRAMS_RECOMMENDED_FOR_YOU: "Programmes sant\xE9 recommand\xE9s pour vous",
  DAY_OF_WEEK: "Jour {day} de {week}",
  START_THIS_PROGRAM_TO_UNLOCK_ACTIVITIES: "Commencer ce programme pour d\xE9bloquer ces activit\xE9s!",
  ACTIVITIES_THIS_WEEK: "Activit\xE9s de cette semaine",
  MULTI_SELECT_DEFAULT_PLACEHOLDER: "S\xE9lectionnez vos options",
  MULTI_SELECT_DEFAULT_CAN_CREATE: "Vous pouvez aussi \xE9crire quelque chose pour cr\xE9er une nouvelle option!",
  PROGRAM_ACTIVITIES: "Activit\xE9s du programme",
  PROGRAM_PROGRESS: "Progr\xE8s du programme",
  VIEW_PROGRAM_ACTIVITIES: "Voir les activit\xE9s du programme",
  VIEW_MY_PROGRESS: "Voir mes progr\xE8s",
  DAY_NUMBER: "JOUR {number}",
  BACK_TO_HEALTH: "Retour \xE0 la sant\xE9",
  LETS_CHAT: "Commen\xE7ons \xE0 clavarder",
  HAVING_TROUBLE: "Vous avez de la difficult\xE9 \xE0 trouver le bon programme pour vous? Clavardez avec le <strong>personnel infirmier agr\xE9\xE9</strong>",
  START_PROGRAM: "Commencer le programme",
  ACTIVE_PROGRAM: "Programme en cours",
  OUR_HEALTH_PROGRAMS_HELP: "Nos programmes bien-\xEAtre vous permettent d\u2019atteindre vos objectifs sant\xE9 et bien-\xEAtre de fa\xE7on proactive.",
  FEATURED: "En vedette",
  CATEGORIES: "Cat\xE9gories",
  LOG_IN_MANUALLY: "Se connecter manuellement",
  CONTACT_US: "contactez-nous",
  SOMETHING_WENT_WRONG: "Une erreur est survenue lors de votre connexion. Veuillez r\xE9essayer ou {emailLink}.",
  SORRY_LET_US_TRY_AGAIN: "D\xE9sol\xE9s, nous allons essayer de nouveau.",
  DEPENDENTS_REPORT_DESCRIPTION: "Ce rapport contiendra un sous-ensemble des donn\xE9es qui existent actuellement dans le rapport d'inscription et sera format\xE9 avec une seule personne \xE0 charge par ligne.",
  DEPENDENTS_REPORT: "Rapport sur les personnes \xE0 charge",
  CHOOSE_FILE: "Choisir un fichier",
  REQUEST_DATE: "Date de la demande",
  DOCUMENT: "Document",
  FLOW_TYPE: "Type de flux",
  CARRIER_AUDITS_REPORTS_DESCRIPTION: "Remplissez tous les champs ci-dessous pour demander un rapport. La demande prendra un certain temps \xE0 remplir. Apr\xE8s cela, le rapport s'ouvrira dans une nouvelle fen\xEAtre.",
  CARRIER_AUDITS_REPORT: "Rapport d'audits de l'assureur",
  CARRIER_AUDITS: "Audits de l'assureur",
  DOWNLOAD_EMPLOYER_BENEFITS_REPORT: "T\xE9l\xE9charger le rapport des avantages sociaux",
  EMPLOYER_BENEFITS_REPORT_DESCRIPTION: "Ce rapport contient un sous-ensemble des donn\xE9es qui existent aujourd'hui dans le rapport d'inscription et sera format\xE9 avec une seule prestation d'utilisateur par ligne.",
  EMPLOYER_BENEFITS_REPORT: "Rapport des avantages sociaux",
  DOWNLOAD_EMPLOYEE_DEMOGRAPHICS_REPORT: "T\xE9l\xE9charger le rapport sur les donn\xE9es d\xE9mographiques relatives aux employ\xE9s",
  EMPLOYEE_DEMOGRAPHICS_REPORT_DESCRIPTION: "Ce rapport donne des d\xE9tails sur les donn\xE9es d\xE9mographiques de tous les employ\xE9s recueillies par League \xE0 ce jour.",
  EMPLOYEE_DEMOGRAPHICS: "Donn\xE9es d\xE9mographiques relatives aux employ\xE9s",
  EMPLOYEE_DEMOGRAPHICS_REPORT: "Rapport sur les donn\xE9es d\xE9mographiques relatives aux employ\xE9s",
  INFO_SUCCESSFULLY_SENT: "Informations envoy\xE9es avec succ\xE8s!",
  DOWNLOAD_ENROLLMENT_PROGRESS_REPORT: "T\xE9l\xE9charger le rapport de progression de l\u2019inscription",
  ENROLLMENT_PROGRESS_REPORT_DESCRIPTION: "Ce rapport donne le statut de l\u2019inscription de chaque employ\xE9 recueilli par League \xE0 ce jour.",
  ENROLLMENT_PROGRESS: "Progression de l\u2019adh\xE9sion",
  ENROLLMENT_PROGRESS_REPORT: "Rapport de progression de l\u2019inscription",
  SORRY_REQUEST_HAS_EXPIRED: "D\xE9sol\xE9s! La fen\xEAtre pour cette demande a expir\xE9.",
  SORRY_ERROR_ONLOAD_PLEASE_REFRESH: "D\xE9sol\xE9s! Essayez d\u2019actualiser la page.",
  CUSTOMIZE_YOUR_BENEFITS: "Personnalisez votre r\xE9gime d\u2019avantages sociaux. Faites votre s\xE9lection en remplissant le formulaire ci-dessous. ",
  BACK_TO_MANAGE_DEPENDENTS: "Retour \xE0 la gestion des personnes \xE0 charge",
  CHOOSE_YOUR_BENEFITS: "Choisissez vos garanties",
  BACK_HOME: "Retour \xE0 l\u2019accueil",
  NEXT_UP_REVIEW_PLANS: "Prochaine \xE9tape : passez en revue vos r\xE9gimes",
  REVIEW_PLANS: "Passer en revue les r\xE9gimes",
  IF_YOU_DONT_HAVE_ANYONE: "Si vous n\u2019avez personne \xE0 ajouter, vous pouvez sauter cette \xE9tape.",
  LETS_GET_TO_KNOW: "Dites-nous en un peu plus sur vos proches!",
  WHICH_PLAN_WOULD_YOU_LIKE: "\xC0 quel r\xE9gime souhaiteriez-vous ajouter une personne \xE0 charge?",
  ADD_A_DEPENDENT: "Ajouter une personne \xE0 charge",
  FINE_PRINT_HSA: "Pour \xEAtre admissibles, les personnes \xE0 votre charge doivent vivre au Canada (\xE0 l\u2019exception des enfants et petits enfants) et \xEAtre financi\xE8rement d\xE9pendantes de vous.",
  IF_YOU_ARE_CARING_FOR_A_LOVED_ONE: "Si vous vous occupez de l\u2019un de vos proches (conjoint, parent, grand-parent ou petit-enfant par exemple) financi\xE8rement d\xE9pendant de vous, vous pouvez l\u2019ajouter \xE0 votre CSS \xE0 tout moment.",
  HEALTH_SPENDING_ACCOUNTS: "Comptes soins de sant\xE9",
  FINE_PRINT_INSURED: "Si vous avez aussi un compte soins de sant\xE9, les personnes \xE0 charge ajout\xE9es \xE0 vos garanties y appara\xEEtront aussi.",
  YOU_HAVE_NO_DEPENDENTS: "Vous n\u2019avez pas de personne \xE0 charge.",
  MY_DEPENDENTS: "Mes personnes \xE0 charge",
  DATE_WINDOW: "<strong>{startDate}</strong> \xE0 <strong>{endDate}</strong>",
  PART_TIME_EMPLOYEES: "Employ\xE9s \xE0 temps partiel",
  FULL_TIME_EMPLOYEES: "Employ\xE9s \xE0 temps complet",
  BENEFIT_GUIDES_DESCRIPTION: "Nous avons pr\xE9par\xE9 un aper\xE7u des avantages sociaux offerts dans ce guide simple et facile \xE0 lire. Nous vous recommandons d\u2019utiliser cet outil tout au long du processus d\u2019inscription.",
  ENROLLMENT_WELCOME_DESCRIPTION: "Afin que vous compreniez ce que <strong>Lush US</strong> vous propose, nous vous accompagnerons \xE0 travers ce processus d\u2019inscription en vous renseignant sur les choix de r\xE9gimes qui s\u2019offrent \xE0 vous.",
  ENROLLMENT_WELCOME: "Bienvenue dans le processus d'inscription Lush US.",
  READY_TO_ENROLL: "\xCAtes-vous pr\xEAts \xE0 vous inscrire \xE0 vos avantages sociaux?",
  UNTIL_WHEN_CAN_I_ENROLL: "Vous avez jusqu\u2019au <strong>{enrollmentEndDate}</strong> pour soumettre vos choix de prestations. Si vous n'effectuez pas cette proc\xE9dure d'adh\xE9sion, vos choix pour l'ann\xE9e contractuelle de {prevPlanStartYear}-{prevPlanEndYear} ne seront pas transf\xE9r\xE9s et vous ne serez pas couvert pour l'ann\xE9e contractuelle de {planStartYear}-{planEndYear}. <strong>Veuillez vous assurer de soumettre vos choix.</strong>",
  BENEFIT_PROGRAM_DESCRIPTION: "Lush a le plaisir de vous offrir un r\xE9gime d'avantages sociaux am\xE9lior\xE9. Il s'agit d'une adh\xE9sion <strong>ACTIVE</strong>, ce qui signifie que si vous ne compl\xE9tez pas l'adh\xE9sion, aucun choix ant\xE9rieur ne sera transf\xE9r\xE9 et vous ne serez pas couvert pour l'ann\xE9e contractuelle de {planStartYear}-{planEndYear}. Cette proc\xE9dure autoguid\xE9e ne prendra que quelques minutes",
  PART_TIME_EMPLOYEES_BENEFIT_GUIDES: "Guide des avantages sociaux pour les employ\xE9s \xE0 temps partiel",
  FULL_TIME_EMPLOYEES_BENEFIT_GUIDES: "Guide des avantages sociaux pour les employ\xE9s \xE0 temps plein",
  BENEFIT_GUIDES: "Guide des avantages sociaux",
  WE_ARE_HERE_TO_HELP: "Nous sommes l\xE0 pour vous aider",
  BENEFIT_PLAN_YEAR: "Ann\xE9e contractuelle",
  GENERAL_INFO: "Informations g\xE9n\xE9rales",
  BETA: "BETA",
  FINE_PRINT: "Si vous avez aussi un compte soins de sant\xE9, les personnes \xE0 charge ajout\xE9es \xE0 vos garanties en b\xE9n\xE9ficieront aussi.",
  YOU_CAN_ADD_A_DEPENDENT: "Vous pouvez ajouter une personne \xE0 charge \xE0 vos garanties assur\xE9es (notamment m\xE9dicaux, soins de la vue, dentaires, vie, etc.) si un \xE9v\xE9nement de vie survient, tel qu'une naissance ou un mariage.",
  INSURED_BENEFIT_DETAILS: "D\xE9tails de la prestation assur\xE9e",
  CLICK_HERE_FOR_HELP: "Cliquez ici pour obtenir de l'aide.",
  LIVE_CHAT_DESCRIPTION: "Vous pouvez clavarder avec nos conseiller en direct \xE0 partir de votre ordinateur!",
  LIVE_CHAT_TITLE: "Clavardage en direct",
  HELP_CENTER_DESCRIPTION: "Vous pouvez \xE9galement vous rendre sur la page Web {helpCenter} pour trouver des r\xE9ponses aux questions couramment pos\xE9es.",
  HELP_CENTER: "Centre d'assistance",
  CONTACT_AGENTS_DESCRIPTION: "Vous pouvez joindre nos conseillers du service \xE0 la client\xE8le \xE0 l\u2019adresse courriel {email}",
  CONTACT_AGENTS_TITLE: "Courriel",
  HELP_MODAL_DESCRIPTION: "N\u2019h\xE9sitez pas \xE0 nous contacter pour toute question relative \xE0 votre couverture ou si quelque chose ne fonctionne pas comme vous l\u2019auriez esp\xE9r\xE9!",
  HELP_MODAL_HEADER: "Notre \xE9quipe est l\xE0 pour vous aider",
  SELECTION_REASON_DISCLAIMER: "Votre sant\xE9 est importante, et {employerName} veut s\u2019assurer que vous disposez d\u2019une couverture. Le r\xE9gime que vous avez s\xE9lectionn\xE9 offre peu ou pas de couverture. Nous vous demandons d\u2019attester que vous disposez d\u2019une autre couverture gr\xE2ce \xE0 l\u2019assurance de votre conjoint ou partenaire.",
  OPT_OUT_BONUS_PER_PAY_PERIOD: "Vous avez d\xE9j\xE0 une couverture? {employerName} vous offrira un bonus de <strong>{optOutBonusAmount} / {periodType}</strong> si vous renoncez \xE0 la couverture.",
  OPT_OUT_BONUS: "Vous avez d\xE9j\xE0 une couverture? {employerName} vous offrira un <strong>{optOutBonusAmount}</strong> bonus si vous renoncez \xE0 la couverture.",
  OPT_OUT_DOCUMENTS_LINK_LABEL: "Lire les instructions pour savoir comment soumettre une preuve de couverture",
  OPT_OUT_DOCUMENTS: "Pour renoncer \xE0 la couverture, vous devez \xE9galement soumettre une preuve de couverture. <a> Voici des instructions</a> sur la fa\xE7on de le faire.",
  OPT_OUT_REASON_PLACEHOLDER_USA: "Quelle est la raison de votre renonciation?",
  OPT_OUT_ACKNOWLEDGED_LABEL_USA: "Oui, je souhaite renoncer \xE0 la couverture {benefitName}",
  OPT_OUT_DISCLAIMER_USA: "Si vous d\xE9cidez de renoncer \xE0 la couverture, vous ne pourrez plus vous r\xE9inscrire jusqu\u2019\xE0 la prochaine p\xE9riode d\u2019inscription annuelle \xE0 moins qu\u2019un \xE9v\xE9nement de vie ne survienne pour vous ou l\u2019une des personnes \xE0 votre charge.",
  OPT_OUT_HEADING_USA: "\xCAtes-vous sur de vouloir renoncer \xE0 la couverture {benefitName}? ",
  REASON_FOR_WAIVING_PLACEHOLDER: "Veuillez indiquer le nom de l\u2019assureur qui vous couvre et votre num\xE9ro de contrat.",
  WAIVE_ACKNOWLEDGED_LABEL: "Oui, je dispose d\u2019une autre couverture",
  REASON_FOR_WAIVING_DISCLAIMER: "Votre sant\xE9 est importante, et {employerName} veut s\u2019assurer que vous disposez d\u2019une couverture. Afin de renoncer \xE0 cette garantie, vous devez attester que vous disposez d\u2019une autre couverture gr\xE2ce \xE0 l\u2019assurance de votre conjoint ou partenaire.",
  REASON_FOR_WAIVING_HEADING: "Disposez-vous d\u2019une autre couverture?",
  MISSING_QLE_DATA: "Informations relatives \xE0 l\u2019\xE9v\xE9nement de vie manquantes.",
  DATE_OF_MARRIAGE: "Date du mariage",
  SOME_EXCEPTIONS_APPLY_SEE_ASTERISK: "Il existe quelques exceptions. Les champs marqu\xE9s d'un ast\xE9risque<em>*</em> sont obligatoires.",
  NEXT: "Suivant",
  BACK: "Retour",
  HOWEVER_STILL_QUALIFY: "Cependant, vous pourriez tout de m\xEAme \xEAtre admissible. Nous aurons juste besoin de quelques informations pour commencer. Un conseiller pourra vous aider.",
  YOU_ARE_OUTSIDE: "Vous ne remplissez pas les conditions requises pour cet \xE9v\xE9nement de vie.",
  TOTAL_ALLOCATION_FOR_THIS_PERIOD_TOOLTIP: "Ceci est la somme de l'allocation de votre ancienne adh\xE9sion et de votre nouvelle allocation.",
  TOTAL_ALLOCATION_FOR_THIS_PERIOD: "Allocation totale pour cette p\xE9riode de police",
  LEARN_MORE_ABOUT_PRORATED_FUNDS: "En savoir plus sur les fonds calcul\xE9s au prorata",
  THIS_AMOUNT_IS_PRORATED: "Ce montant est calcul\xE9 au prorata",
  NEW_ALLOCATION: "Nouvelle allocation",
  QLE_CONTRIBUTIONS_CHANGE_DISABLED: "Vous ne pouvez pas modifier votre montant de cotisation pour ce compte pendant un \xE9v\xE9nement de vie admissible.",
  NO_FLEX_DOLLAR_REMAINING: "Il ne vous reste aucun {flexDollarTerm}",
  NO_FLEX_DOLLAR_TO_ADD: "Vous n'avez actuellement aucun {flexDollarTerm} \xE0 ajouter \xE0 ce compte.",
  QLE_ALLOCATION_TOOLTIP_NOTE: "Remarque : il ne s'agit pas du solde courant de votre compte. Les \xE9ventuelles transactions que vous avez effectu\xE9es ne seront pas prises en compte dans ce montant.",
  PREVIOUS_ENROLLMENT_ALLOCATION_TOOLTIP: "Ceci est le montant total que vous avez allou\xE9 \xE0 ce compte lors des adh\xE9sions pr\xE9c\xE9dentes.",
  PREVIOUS_ENROLLMENT_ALLOCATION: "Allocation de l'adh\xE9sion pr\xE9c\xE9dente",
  PRORATED_VALUE: "Valeur calcul\xE9e au prorata\xA0:",
  PRORATED_CONTRIBUTOR_CONTRIBUTION_EMPLOYER: "Contribution de l\u2019employeur calcul\xE9e au prorata",
  PRORATED_CONTRIBUTOR_CONTRIBUTION_EMPLOYEE: "Votre contribution calcul\xE9e au prorata",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYEE_TO_BENEFIT: "Votre contribution au {vendorName}{benefitName}",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYER: "Contribution de l\u2019employeur",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYEE: "Votre contribution",
  CONTRIBUTIONS: "Comptes fiscalement avantageux",
  QUESTION_X_OF_Y: "QUESTION {x} SUR {y}",
  SOME_EXCEPTIONS_APPLY: "Il existe quelques exceptions.",
  QLE_MUST_BE_REPORTED_IN_TIME: "Vous disposez d\u2019une p\xE9riode d\xE9finie pour signaler un \xE9v\xE9nement de vie et effectuer votre choix de r\xE9gime.",
  WHICH_APPLIES_TO_YOU: "Quel \xE9v\xE9nement s\u2019applique \xE0 votre situation?",
  CHECK_IF_I_QUALIFY: "V\xE9rifier si je suis admissible",
  WHEN_COVERAGE_TAKE_EFFECT: "Quand la nouvelle couverture prend-elle effet?",
  WHAT_IS_A_QLE: "Qu'est ce qu\u2019un \xE9v\xE9nement de vie?",
  IF_YOU_DID_NOT_EXPERIENCE_A_QLE: "Si vous avez v\xE9cu un autre type d'\xE9v\xE9nement ",
  IF_YOU_EXPERIENCED_A_CERTAIN_LIFE_EVENT: "Si vous avez v\xE9cu un \xE9v\xE9nement particulier, comme un mariage ou l\u2019arriv\xE9e d\u2019un enfant, vous \xEAtes autoris\xE9 \xE0 modifier vos choix de r\xE9gime.",
  LIFE_IS_UNPREDICTABLE: "La vie est impr\xE9visible, mais vos avantages sociaux ne devraient pas l\u2019\xEAtre.",
  REPORT_DATE_REQUIRED_ERROR_COPY: "Veuillez saisir une date pour g\xE9n\xE9rer le rapport.",
  REPORT_FUTURE_DATE_ERROR_COPY: "Veuillez s\xE9lectionner la date du jour ou une date pass\xE9e.",
  CHOOSE_DATE: "S\xE9lectionner une date",
  REQUEST_REPORT: "Demander un rapport",
  COVERAGE_SUMMARY_UNAVAILABLE_MESSAGE: "Ce groupe n\u2019est pas (encore!) sur la nouvelle exp\xE9rience d\u2019inscription, alors nous ne pouvons pas afficher le r\xE9sum\xE9 des d\xE9tails de leurs avantages sociaux. Veuillez consulter l\u2019onglet garanties pour voir \xE0 quels garanties cet utilisateur s'est inscrit.",
  FILE_DOWNLOAD: "T\xE9l\xE9charger un fichier",
  YEAR_LOWERCASE: "ann\xE9e",
  MONTH_LOWERCASE: "mois",
  DAY_LOWERCASE: "jour",
  YEAR: "Ann\xE9e",
  MONTH: "Mois",
  DAY: "Jour",
  FINANCE_INVOICING: "Facturation",
  FINANCE_REPORTS: "Rapports de finances",
  WAS_THE_LAST_DAY_TO_SUBMIT: "{endDate} \xE0 {time}{timeZone} \xE9tait le dernier jour pour soumettre vos s\xE9lections.",
  YOU_HAVE_UNTIL_THIS_DATE_TO_COMPLETE_YOUR_ENROLLMENT: "Vous avez jusqu\u2019 au {endDate} \xE0 {time} {timeZone} pour soumettre vos s\xE9lections.",
  TODAY_IS_YOUR_LAST_DAY_TO_SUBMIT: "Aujourd'hui est votre dernier jour!",
  YOU_HAVE_DAYS_LEFT: "Il vous reste <strong>{daysLeft} jours</strong>!",
  INVOICE_STATUS_PARTIALLY_PAID: "Pay\xE9 en partie",
  INVOICE_STATUS_OUTSTANDING: "D\xFB",
  NEW: "Nouveau",
  ADD_NEW_BENEFIT_SET: "+ Ajouter nouvel ensemble",
  BENEFIT_SETS_EMPTY_2: "Ajoutez un ensemble en cliquant sur le bouton en haut \xE0 droite.",
  BENEFIT_SETS_EMPTY_1: "Vous n'avez pas encore d'ensembles configur\xE9s.",
  BENEFIT_SETS_BLURB: "Les ensembles de garanties sont organis\xE9s en fonction du type de garanties qui y sont ajout\xE9s. Vous pouvez configurer cela lorsque vous modifiez ou ajoutez un nouvel ensemble.",
  NO_INVOICES_BY_DIVISION_MESSAGE: "Il n\u2019existe aucune facture pour le(s) service(s) de facturation s\xE9lectionn\xE9s.",
  SEARCH: "Rechercher",
  ERROR_RENEWING_PLAN: "Une erreur est survenue en renouvelant le r\xE9gime : {error}",
  PLAN_END_DATE: "Date de fin",
  PLAN_START_DATE: "Date de d\xE9but",
  PLAN_RENEWED_SUCCESSFULLY: "Le r\xE9gime a bien \xE9t\xE9 renouvel\xE9",
  RENEW_PLAN_DESCRIPTION: "Un nouveau r\xE9gime sera cr\xE9\xE9 avec les m\xEAmes param\xE8tres, pi\xE8ces jointes et configurations d\u2019inscription que le r\xE9gime actuel, {benefitPlanName}. Les avantages seront directement rattach\xE9s au nouveau r\xE9gime depuis le r\xE9gime pr\xE9c\xE9dent pour faciliter les renouvellements et les reconductions des comptes soins de sant\xE9.",
  RENEW_PLAN_HEADER: 'Renouveler le r\xE9gime "{benefitPlanName}"',
  RENEW: "Renouveler",
  ERROR_COPYING_PLAN: "Une erreur s\u2019est produite lors de la copie de votre r\xE9gime : {erreur}",
  PLAN_COPIED_SUCCESSFULLY: "Le r\xE9gime a \xE9t\xE9 copi\xE9",
  CHOOSE_A_BENEFIT_CLASS: "Choisissez une cat\xE9gorie de prestations",
  COPY_PLAN_BENEFIT_CLASS_SELECT_LABEL: "Dans quelle cat\xE9gorie de prestations le r\xE9gime doit-il \xEAtre copi\xE9?",
  COPY_PLAN_DESCRIPTION: "Un nouveau r\xE9gime sera cr\xE9\xE9 avec les m\xEAmes param\xE8tres, pi\xE8ces jointes et configurations d'inscription que le r\xE9gime actuel, {benefitPlanName}. Veuillez vous assurer de mettre \xE0 jour le nouveau r\xE9gime en cons\xE9quence.",
  COPY_PLAN_HEADER: 'Copier le r\xE9gime "{benefitPlanName}"',
  FORM_VALIDATION_WARNING_HEADER: "Nous avons trouv\xE9 <strong>{warningCount, plural, one {# warning} other {# warnings}}</strong> avec ce <strong>{formTitle}</strong>",
  FORM_VALIDATION_ERROR_HEADER: "Nous avons trouv\xE9 <strong>{errorCount, plural, one {# error} other {# errors}}</strong> avec ce <strong>{formTitle}</strong>",
  VALIDATION_WARNING_WARNINGS: "\u26A0\uFE0F {warningCount, plural, one {# warning} other {# warnings}}",
  VALIDATION_WARNING_ERRORS: "\u{1F6D1} {errorCount, plural, one {# error} other {# errors}}",
  VALIDATION_WARNINGS_HEADER: "Une minute! Nous avons trouv\xE9 des probl\xE8mes potentiels sur cette configuration de r\xE9gime :",
  NO_VALIDATION_WARNINGS_MESSAGE: "\u2705 Aucun avertissement de validation, pr\xEAt pour commencer l'adh\xE9sion!",
  ENROLLMENT_COMPLETE: "L'adh\xE9sion est compl\xE9t\xE9e",
  ENROLLMENT_STARTED: "L'adh\xE9sion a d\xE9but\xE9",
  START_ENROLLMENT_SERVER_ERROR: "Erreur au d\xE9but de l'adh\xE9sion : {reason}",
  ENROLLMENT_STARTED_SUCCESSFULLY: "L'adh\xE9sion a d\xE9but\xE9",
  OPEN_ENROLLMENT_TEXT: "Vous \xEAtes sur le point de d\xE9buter l'adh\xE9sion pour les membres {employerName} suivants :",
  CONFIRM_START_ENROLLMENT: "Confirmer le d\xE9but de l'adh\xE9sion",
  NO_DEFAULT_BENEFITS_IN_PLAN: "Il n'y a pas de garanties par d\xE9faut dans ce r\xE9gime",
  DEFAULTS_IN_MULTIPLE_BUNDLES_WARNING: "Oups! Vous avez des garanties par d\xE9faut dans plus d'un forfait.",
  DOWNLOADING: "T\xE9l\xE9chargement en cours",
  VIEW_DEFAULT_PLAN: "Voir le r\xE9gime par d\xE9faut",
  EXPORT_CONFIGURATION: "Configuration de l'exportation",
  SET_ENROLLMENT_DATES_SUCCESS_MESSAGE: "Dates d\u2019inscription sauvegard\xE9es",
  NEW_EMPLOYEE_ENROLLMENT_DURATION: "Dur\xE9e d\u2019adh\xE9sion d\u2019un nouvel employ\xE9 (jours)",
  ENROLLMENT_DATES_WARNING: "Nous ne pouvons actuellement pas supprimer les dates d\u2019inscription apr\xE8s qu\u2019elles ont \xE9t\xE9 sauvegard\xE9es. \xCAtes-vous s\xFBr de vouloir ajouter celles-ci?",
  ADD_ENROLLMENT_DATES: "Ajouter des dates d\u2019inscription",
  ADDING_ENROLLMENT_DATES: "Ajout de dates d\u2019inscription",
  EMPLOYER_TIME_ZONE: "Note\xA0: Ces dates et heures sont \xE9tablies pour le fuseau horaire de l\u2019employeur, soit <strong>{timezone}</strong>",
  START_PLAN_CONFIGURATION: "D\xE9buter la configuration du r\xE9gime",
  MISSING_PLAN_OPTIONS: "Commencer ici pour cr\xE9er la configuration de ce nouveau r\xE9gime",
  CONFIGURATION: "Configuration",
  GROUP_OPTIONS: "Options de groupe",
  BENEFIT_SETS_PLANS_EMPTY_2: "Ajoutez un r\xE9gime dans l\u2019onglet \xAB\xA0conception du r\xE9gime\xA0\xBB.",
  BENEFIT_SETS_PLANS_EMPTY_1: "Vous n\u2019avez pas encore configur\xE9 de r\xE9gime.",
  BENEFIT_SETS_PLANS_INFO_BOX: "Il sera beaucoup plus simple de cr\xE9er des ensembles de garanties si vos cat\xE9gories, r\xE9gimes et garanties sont d\xE9j\xE0 d\xE9finis!<br /><strong>Restriction\xA0: Vous ne pourrez configurer ce groupe si vous avez plus d\u2019un r\xE9gime par cat\xE9gorie.</strong>",
  PICKED_BENEFITS_BLURB: "L\u2019utilisateur verra ces garanties appara\xEEtre dans l\u2019ordre que vous avez d\xE9fini",
  BENEFIT_SETS_PLANS_BLURB: "Les ensembles de garanties vous permettent de configurer \xE0 votre guise la fa\xE7on dont les garanties apparaissent dans l'exp\xE9rience d\u2019inscription. Les cat\xE9gories configur\xE9es dans la section conception du r\xE9gime appara\xEEtront dessous.",
  BENEFIT_SETS_TOOLTIP: "Un ensemble de garanties est un groupe de garanties auxquels vous pouvez appliquer des param\xE8tres particuliers. <br /><br /><strong>Exemple\xA0: </strong><br /><br /> Un ensemble <em>de garanties m\xE9dicales</em> regroupera toutes les garanties m\xE9dicales parmi lesquels vous voulez que l\u2019utilisateur choisisse, et comment ce dernier peut interagir avec ses options.",
  BENEFIT_SET: "Ensemble de garantie",
  BENEFIT_SETS: "Ensembles des garanties",
  SET_EMPLOYER_BENEFIT_SET_SUCCESS_MESSAGE: "Op\xE9ration r\xE9ussie! L\u2019ensemble des garanties a \xE9t\xE9 sauvegard\xE9.",
  BENEFIT_SET_SAVE: "Sauvegarder l\u2019ensemble",
  AVAILABLE_BENEFITS_BLURB: "Les garanties d\u2019autres cat\xE9gories ou les garanties d\xE9j\xE0 ajout\xE9s \xE0 un ensemble n'appara\xEEtront pas dans cette liste",
  AVAILABLE_BENEFITS: "Garanties disponibles",
  ADDING_BENEFITS_BLURB: "L\u2019utilisateur pourra voir les garanties ajout\xE9s ici",
  ADDING_BENEFITS: "Ajout de garanties",
  UPLOAD_BENEFIT_SET_DOCUMENT_BLURB: "Tout document t\xE9l\xE9vers\xE9 ici sera visible par l\u2019utilisateur au niveau de l\u2019ensemble de la garantie",
  UPLOAD_A_FILE: "pour rechercher un fichier \xE0 t\xE9l\xE9verser",
  BROWSE: "parcourir",
  DRAG_A_FILE_HERE: "Faire glisser un fichier ici ou",
  BENEFIT_SET_WAIVABLE_NEITHER: "Aucun, c\u2019est un compte de d\xE9penses!",
  BENEFIT_SET_WAIVABLE_TOOLTIP: "Cela d\xE9terminera si l\u2019utilisateur verra ou non appara\xEEtre une option pour renoncer \xE0 la couverture.<br /><br /><strong>Oui :</strong> L\u2019utilisateur verra une option pour s\u2019inscrire ou renoncer <br /><strong>Non\xA0:</strong> L\u2019utilisateur sera inform\xE9 qu\u2019il s\u2019agit d\u2019une garantie obligatoire et qu'il doit la s\xE9lectionner <br /><strong> Aucun des deux\xA0:</strong> Pour les comptes de d\xE9penses uniquement",
  BENEFIT_SET_WAIVABLE_LABEL: "L\u2019utilisateur peut-il renoncer \xE0 la couverture?",
  BENEFIT_SET_NAME_TOOLTIP: "Cela influencera le nom de la page au cours de l\u2019exp\xE9rience d\u2019inscription ainsi que le nom de l\u2019ensemble.<br /><br /><strong>Remarque :</strong> Vous ne pouvez pas avoir plusieurs ensembles de la m\xEAme cat\xE9gorie.",
  BENEFIT_SET_NAME_LABEL: "Quelles garanties comprend cet ensemble?",
  BENEFIT_SET_DETAILS_BLURB: "Ces d\xE9tails influencent ce qui sera visible par l\u2019utilisateur au cours de son inscription.",
  BENEFIT_SET_DETAILS: "D\xE9tails de l'ensemble",
  UPLOADING_ENROLLMENT_DOCUMENT_SUCCEEDED: "Document t\xE9l\xE9vers\xE9",
  ERROR_UPLOADING_ENROLLMENT_DOCUMENT: "Un probl\xE8me est survenu lors de l'enregistrement du document. Veuillez r\xE9essayer.",
  PLAN_OPTIONS_SAVE_ERROR: "Erreur lors de l'enregistrement des options du r\xE9gime",
  PLAN_OPTIONS_SAVED_SUCCESS: "Les options du r\xE9gime ont bien \xE9t\xE9 sauvegard\xE9es!",
  NEW_BENEFIT_SET: "Nouvel ensemble de garantie",
  PRICING_DESIGN_BLURB: "Cette section vous permettra de fixer les montants du r\xE9gime d'assurance de l'employeur.",
  ENROLLMENT_DESIGN_BLURB: "Cette section vous permet de concevoir l\u2019exp\xE9rience d\u2019inscription de {employerName} en vous basant sur les garanties que vous avez configur\xE9s dans la section Conception du r\xE9gime.",
  REQUIRED_FIELDS: "Champs obligatoires ",
  INVOICE_TABLE_COLLAPSE: "r\xE9duire",
  INVOICE_TABLE_EXPAND: "\xC9largir",
  INVOICE_TABLE_AMOUNT_OWING: "Montant d\xFB\xA0: ",
  TOTAL_AMOUNT: "Montant total",
  SA: "Compte d'\xE9pargne",
  HRA: "ERSS",
  LDSA: "CDFD",
  FSA: "Compte d'\xE9pargne flexible",
  PSA: "CDB",
  CGA_AMOUNT: "Montant pour Don assorti d'une rente",
  CHARITY_AMOUNT: "Montant charitable",
  SA_AMOUNT: "Montant SA",
  HRA_AMOUNT: "Montant HRA",
  LDSA_AMOUNT: "Montant LDSA",
  FSA_AMOUNT: "Montant FSA",
  PSA_AMOUNT: "Montant CDB",
  HSA_AMOUNT: "Montant CSS",
  SUBMITTED_DATE: "Date de soumission",
  TIME_SELECTOR_TEXT_2: " HNE",
  TIME_SELECTOR_TEXT_1: "\xE0 ",
  AUTHOR_ON_DATE: "Par {author}, le {date}",
  EDIT_CLAIM_LINK_TEXT: "modifiez votre demande.",
  EDIT_CLAIM_PROVIDE_MORE_INFO: "Pour fournir plus de renseignements,",
  TOTAL_OUTSTANDING_ERROR: "Une erreur est survenue lors de la r\xE9cup\xE9ration du montant total d\xFB.",
  TOTAL_OUTSTANDING: "MONTANT TOTAL D\xDB",
  CLAIM_LIST_NEEDS_MORE_INFO: "Certaines de vos demandes sont en attente de renseignements suppl\xE9mentaires.",
  CHAT_WITH_AN_AGENT: "Clavarder avec un conseiller",
  CANCEL_CLAIM: "Annuler la demande",
  EDIT_CLAIM: "Modifier la demande",
  VIEW_ALL: "Voir tout",
  SUBMIT_CLAIM: "Soumettre une demande",
  NO_CLAIMS_INFO: "Une fois que vous aurez soumis des demandes, elles seront r\xE9pertori\xE9es ici et vous pourrez consulter leurs statuts!",
  NO_CLAIMS: "Aucune demande",
  CHILD_HAS_COVERAGE: "Votre r\xE9gime comprend <strong>{amount}</strong> de couverture d\u2019assurance vie de base pour votre enfant. Toute couverture {benefitName} sera ajout\xE9e \xE0 votre r\xE9gime existant.",
  SPOUSE_HAS_COVERAGE: "Votre conjoint est couvert pour <strong>{amount}</strong> d'assurance vie de base gr\xE2ce \xE0 votre r\xE9gime. Chaque couverture {benefitName} sera ajout\xE9e \xE0 cette couverture existante.",
  CLAIM_STATUS: "Statut de la demande",
  BACK_TO_WALLET: "Retour \xE0 mon portefeuille",
  BACK_TO_CLAIMS: "Retourner aux demandes",
  EMPLOYEE_NO_DEPENDENTS: "Cet employ\xE9 n'a pas de personnes \xE0 charge",
  EMPLOYEE_INACTIVE_DEPENDENTS: "Personnes \xE0 charge inactives",
  EMPLOYEE_ACTIVE_DEPENDENTS: "Personnes \xE0 charge actives",
  CHANGE_IN_LOCATION: "Changement d\u2019emplacement",
  DEATH: "D\xE9c\xE8s",
  SEPARATION: "S\xE9paration",
  DIVORCE: "Divorce\n",
  ADOPTION: "Adoption",
  BIRTH: "Naissance",
  ENDING_COMMON_LAW_RELATIONSHIP: "Fin d'une union libre",
  BECAME_COMMON_LAW: "Commencement d'une union libre",
  MARRIAGE: "Mariage",
  DEPENDENT_LOST_COVERAGE: "Une personne \xE0 charge a perdu leur propre couverture",
  DEPENDENT_GAIN_COVERAGE: "Une personne \xE0 charge a obtenu leur propre couverture",
  REASON_FOR_ADDING: "Raison de l\u2019ajout",
  EFFECTIVE_DATE: "Date de prise d\u2019effet",
  QUALIFYING_LIFE_EVENT: "\xC9v\xE9nement de vie",
  CLAIM_REF_NUMBER: "Num\xE9ro de demande ",
  LAST_UPDATED_LOWERCASE: "Derni\xE8re mise \xE0 jour",
  LAST_UPDATED: "Derni\xE8re mise \xE0 jour",
  DOWNLOAD_PLAN_COMPARISON: "T\xE9l\xE9charger le document de comparaison des r\xE9gimes",
  BUNDLES_NO_SELECTION_ERROR_MESSAGE: "<strong>Doucement!</strong> Vous devez s\xE9lectionner un r\xE9gime avant de le revoir.",
  BUNDLE_SELECTOR_CARD_DESCRIPTION: "{employerName} vous permet de choisir entre les r\xE9gimes r\xE9sum\xE9s ci-dessous. Nous vous recommandons de t\xE9l\xE9charger le document de comparaison des r\xE9gimes et de prendre le temps de d\xE9cider quel r\xE9gime vous convient le mieux. Vous aurez l\u2019occasion d\u2019\xE9tudier plus en d\xE9tail vos r\xE9gimes s\xE9lectionn\xE9s apr\xE8s avoir fait votre choix!",
  BUNDLE_SELECTOR_CARD_TITLE: "Comparaison des r\xE9gimes",
  BUNDLE_SELECTOR_CARD_HEAD: "Quel r\xE9gime vous convient le mieux?",
  BUNDLE_SELECTION_SUBHEAD: "Vous pouvez choisir parmi les r\xE9gimes r\xE9sum\xE9s ci-dessous. S\xE9lectionnez le r\xE9gime qui vous para\xEEt le plus adapt\xE9 pour vous.",
  BUNDLE_SELECTION_HEAD: "S\xE9lection du r\xE9gime",
  VIEW_INSTRUCTIONS: "Voir les consignes",
  VIEW_MORE_DETAILS_SENTENCE_CASE: "Voir plus de d\xE9tails",
  VIEW_MORE_DETAILS: "Voir plus de d\xE9tails",
  VIEW_LESS_DETAILS: "Voir moins de d\xE9tails",
  TYPE_REQUIRED: "Un type est requis",
  LABEL_REQUIRED: "Une \xE9tiquette est requise",
  VALUE_REQUIRED: "Une valeur est requise",
  VALID_URL_REQUIRED: "Une URL valide est requise",
  VALID_PHONE_NUMBER_PLUS: "Doit comporter un symbole + au maximum",
  VALID_PHONE_NUMBER_CHARACTERS: "Ne doit comporter que les caract\xE8res 0123456789+",
  VALID_PHONE_NUMBER_LONG: "Doit comporter au plus 15 chiffres",
  VALID_PHONE_NUMBER_SHORT: "Doit comporter au moins 4 chiffres",
  EDIT_WALLET_BUTTON: "Modifier les informations du portefeuille",
  BENEFIT_WALLET_ACTION_VALUE_TOOLTIP: "La valeur li\xE9e \xE0 cette action. <br>Veuillez noter de bien penser \xE0 inclure l\u2019indicatif r\xE9gional des num\xE9ros de cellulaire et l\u2019adresse Web compl\xE8te pour les URL.",
  BENEFIT_WALLET_ACTION_TYPE_TOOLTIP: "Le type permet au syst\xE8me de savoir comment traiter cette action selon s\u2019il s\u2019agit d\u2019une URL ou d\u2019un num\xE9ro de cellulaire.",
  BENEFIT_WALLET_ACTION_LABEL_TOOLTIP: "L\u2019utilisateur voit cette \xE9tiquette s\u2019afficher dans portefeuille pour cette action",
  REMOVE_WALLET_ACTIONS_MODAL_BODY: "Si vous supprimez cette action, celle-ci ne sera pas disponible dans le portefeuille de l\u2019utilisateur pour cette garantie.",
  REMOVE_WALLET_ACTIONS_MODAL_HEADER: "Voulez-vous supprimer cette action?",
  SAVE_WALLET_ACTION_ERROR_MESSAGE: "Une erreur est survenue!",
  SAVE_WALLET_ACTION_SUCCESS_MESSAGE: "L\u2019action a \xE9t\xE9 sauvegard\xE9e avec succ\xE8s!",
  REMOVE_WALLET_ACTION_ERROR_MESSAGE: "L\u2019action n\u2019a pas pu \xEAtre supprim\xE9e",
  REMOVE_WALLET_ACTION_SUCCESS_MESSAGE: "L\u2019action a \xE9t\xE9 supprim\xE9e avec succ\xE8s!",
  VALUE: "Valeur",
  LABEL: "\xC9tiquette",
  SAVE: "Sauvegarder",
  ADD_MORE_ACTION: "+ Ajouter une nouvelle action",
  CANT_ADD_SECONDARY_ACTIONS: "Vous ne pouvez pas ajouter d\u2019actions secondaires tant que vous n\u2019avez pas ajout\xE9 d\u2019action principale",
  PRIMARY_ACTION: "Action principale",
  BENEFIT_NAME_WALLET_ACTIONS_SUBHEADER: "C\u2019est ici que vous pourrez modifier, ajouter et configurer les actions du portefeuille pour la garantie.",
  BENEFIT_NAME_WALLET_ACTIONS_HEADER: "{benefitName} ({vendorName}) - Actions du portefeuille",
  NO_SELECTED_BENEFIT: "Une fois la garantie s\xE9lectionn\xE9s, vous pourrez configurer les actions du portefeuille ici",
  USAGE_INSTRUCTIONS_GONE_FOREVER: 'Si vous effacez les conseils d\u2019utilisation, l\u2019utilisateur verra les conseils d\u2019utilisation (s\u2019il y en a) d\xE9termin\xE9s par d\xE9faut pour cette garantie. Si aucun conseil d\u2019utilisation n\u2019est disponible pour cette garantie, l\u2019utilisateur ne verra pas l\u2019onglet "conseils" dans le portefeuille.',
  USAGE_INSTRUCTIONS_ARE_YOU_SURE: "Voulez-vous effacer les conseils d\u2019utilisation?",
  USAGE_INSTRUCTIONS_ERROR: "Une erreur est survenue lors de la mise \xE0 jour des conseils d'utilisation",
  USAGE_INSTRUCTIONS_SUCCESS: "Les conseils d\u2019utilisation ont \xE9t\xE9 sauvegard\xE9s avec succ\xE8s!",
  SELECT_BENEFIT: "S\xE9lectionnez une prestation",
  NO_CONFIGURABLE_BENEFITS_SUB_HEADER: "Une fois le produit assur\xE9 ajout\xE9, vous pourrez configurer les d\xE9tails de la garantie du portefeuille ici.",
  NO_CONFIGURABLE_BENEFITS_HEADER: "<strong>Ce groupe n'a pas de garanties param\xE9trables!</strong>",
  USAGE_INSTRUCTIONS_TITLE: "{product} ({carrier}) - Conseils d\u2019utilisation",
  USAGE_INSTRUCTIONS_EMPTY: "Apr\xE8s avoir choisi une garantie, vous pourrez en configurer les conseils d'utilisation ici",
  USAGE_INSTRUCTIONS: "Conseils d'utilisation",
  WALLET_OVERVIEW_ERROR: "Une erreur est survenue lors de la mise \xE0 jour de votre aper\xE7u.",
  WALLET_OVERVIEW_SUCCESS: "Aper\xE7u mis \xE0 jour!",
  OVERVIEW_CLEAR_MODAL_CONTENT: "Si vous effacez l\u2019aper\xE7u configur\xE9, l\u2019utilisateur verra la description par d\xE9faut et les informations de couverture d\xE9termin\xE9es pour cette garantie.",
  OVERVIEW_CLEAR_MODAL_HEADER: "Voulez-vous effacer la description de l\u2019aper\xE7u?",
  OVERVIEW_UPDATE_MODAL_CONTENT: "<strong>Note que pour les garanties assur\xE9es :</strong> D\xE9terminer un aper\xE7u personnalis\xE9 va \xE9galement masquer les informations de la couverture dans le portefeuille pour cette garantie. Cela signifie que les utilisateurs ne verront aucune des informations de la couverture d\xE9termin\xE9es pour cette garantie (s\u2019il y en a).",
  OVERVIEW_UPDATE_MODAL_HEADER: "Voulez-vous mettre \xE0 jour l\u2019aper\xE7u de {productName}?",
  OVERVIEW_INFO_BOX: "Recommandations : D\xE9finir un aper\xE7u pour mieux d\xE9crire les programmes de l\u2019entreprise.",
  OVERVIEW_TITLE: "{product} ({carrier}) - Aper\xE7u",
  OVERVIEW_EMPTY: "Une fois que vous aurez choisi une garantie, vous pourrez en modifier l'aper\xE7u ici.",
  BENEFITS_WALLET_HEADER_SUB_HEADER: "La modification des renseignements ci-dessous mettra \xE0 jour les informations affich\xE9es sur la carte du portefeuille de la garantie correspondante. Veuillez noter que toute modification de la garantie s\u2019appliquerait \xE0 toutes les cat\xE9gories d'avantages sociaux concern\xE9es.",
  BENEFITS_WALLET_HEADER: "Modifier les informations du portefeuille",
  NEXT_STEPS: "Prochaines \xE9tapes",
  NUMBER_OF_PLANS_AVAILABLE: "{number} r\xE9gimes disponibles",
  YOUR_BENEFIT_COVERAGE_PLANS: "Votre r\xE9gime de couverture {benefitName}",
  ADD_DEPENDENT: "Ajouter",
  DOWNLOAD_EOI_FORM: "T\xE9l\xE9charger le formulaire de preuve d'assurabilit\xE9",
  READ_LESS: "Lire moins",
  READ_MORE: "Lire plus",
  FEATURE_FLAG_UPDATED: "Avertissements affich\xE9s mis \xE0 jour!",
  GROUPS_COUNT: "{count} groupes",
  USERS_COUNT: "{count} utilisateurs",
  ON_FOR_GROUPS: "Activer pour les groupes : ",
  OFF_FOR_USERS: "D\xE9sactiver pour les utilisateurs : ",
  ON_FOR_USERS: "Activer pour les utilisateurs : ",
  ADD_A_LIST_OF_COMMA_SEPARATED_IDS: "Ajouter une liste d\u2019identifiants s\xE9par\xE9s par des virgules",
  GROUPS: "Groupes",
  USERS: "Utilisateurs",
  OFF_FOR_ALL: "D\xE9sactiver tous?",
  ON_FOR_ALL: "Activer tous?",
  ON_OFF_FOR_ALL: "Activer/D\xE9sactiver tous",
  FLAG_DETAILS: "D\xE9tails de l\u2019avertissement : {flagId}",
  FLAG_HAS_NO_ID_WARNING: "Un probl\xE8me est survenu! D\u2019apr\xE8s notre syst\xE8me principal, cet avertissement n\u2019a pas d'identifiant, ce qui va poser un probl\xE8me. Un ing\xE9nieur va devoir mettre la main \xE0 la p\xE2te.",
  BACK_TO_FLAGS_LIST: "Retour \xE0 la liste des avertissements",
  NO_DESCRIPTION: "Aucune description",
  FEATURE_FLAGS: "Feature Flags",
  FINANCE: "Finance",
  LEGACY_ADMIN_TOOLS: "Ancien outil",
  VISIT_LEGACY: "Ancien outil - Sant\xE9 au travail",
  GOT_IT: "J'ai compris",
  NO_DEPENDENT_SELECTION: "Aucune couverture pour personne \xE0 charge s\xE9lectionn\xE9e",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILDREN: "Moi, mes enfants, mon conjoint de fait et ses enfants",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN: "Moi, mon conjoint de fait et mes enfants",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILDREN: "Moi, mon conjoint de fait et ses enfants",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN_CHILDREN: "Moi, mes enfants et les enfants de mon conjoint de fait",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN: "Moi et les enfants de mon conjoint de fait",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER: "Moi et mon conjoint de fait",
  DEPENDENT_COVERAGE_TYPE_MYSELF_ONE_CHILD: "Moi-m\xEAme et mon enfant",
  DEPENDENT_COVERAGE_TYPE_MYSELF_CHILDREN: "Moi-m\xEAme et mes enfants",
  DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE_CHILDREN: "Moi-m\xEAme, mon conjoint et mon/mes enfant(s)",
  DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE: "Moi-m\xEAme et mon conjoint",
  DEPENDENT_COVERAGE_TYPE_MYSELF: "Moi-m\xEAme",
  DEPENDENT_COVERAGE_SELECTION_TEXT: "Pour {dependentCoverageType}",
  DEPENDENT_CHECK_TEXT: "<strong>Juste pour v\xE9rifier...</strong> Nous voyons que vous avez ajout\xE9 des personnes \xE0 charge, mais que vous ne les avez pas couvert sous vos avantages sociaux. Si c'\xE9tait bien votre intention, g\xE9nial! Sinon, retournez \xE0 la page pr\xE9c\xE9dente et mettez \xE0 jour les <strong>pr\xE9f\xE9rences de couverture pour vos personnes \xE0 charge</strong> afin de les couvrir.",
  COVERAGE_SUMMARY: "R\xE9capitulatif de votre couverture",
  INITIATE_QLE_ERROR_TOAST: "Erreur lors de l\u2019initiation de l\u2019\xC9v\xE9nement de vie de l\u2019employ\xE9(e)\xA0: {reason}",
  INITIATE_QLE_SUCCESS_TOAST: "La r\xE9inscription \xE0 la suite d'un \xC9v\xE9nement de vie a commenc\xE9 pour {employeeName}",
  INITIATE_QLE_TYPE_ERROR: "Vous devez s\xE9lectionner un type d\u2019\xC9v\xE9nement de vie",
  INITIATE_QLE_TYPE_PLACEHOLDER: "S\xE9lectionner un type d\u2019\xC9v\xE9nement de vie",
  INITIATE_QLE_TYPE_LABEL: "Type d\u2019\xC9v\xE9nement de vie",
  INITIATE_QLE_TYPE_QUESTION: "Quel type d\u2019\xC9v\xE9nement de vie a v\xE9cu l\u2019utilisateur?",
  INITIATE_QLE_ENROLLMENT_END_DATE_ERROR: "Vous devez choisir une date de cl\xF4ture des inscriptions",
  INITIATE_QLE_ENROLLMENT_END_DATE_LABEL: "Date de fin des inscriptions \xE0 la suite d'un \xE9v\xE9nements de vie",
  INITIATE_QLE_ENROLLMENT_END_DATE_TOOLTIP: "Cela marquera la fin de la p\xE9riode d\u2019inscriptions pour les utilisateurs. Ils auront jusqu\u2019\xE0 23\xA0h\xA059 (en fonction du fuseau horaire des groupes) pour faire ou mettre \xE0 jour leurs s\xE9lections.",
  INITIATE_QLE_ENROLLMENT_END_DATE_QUESTION: "\xC0 quelle date l\u2019inscription devrait-elle \xEAtre finie pour cet utilisateur?",
  INITIATE_QLE_COVERAGE_START_DATE_ERROR: "Vous devez s\xE9lectionner une date de d\xE9but de la couverture",
  INITIATE_QLE_COVERAGE_START_DATE_LABEL: "Date du d\xE9but de la couverture",
  INITIATE_QLE_COVERAGE_START_DATE_TOOLTIP: "La couverture des utilisateurs prendra effet \xE0 cette date.",
  INITIATE_QLE_COVERAGE_START_DATE_NOTE: "(Remarque\xA0: leur ancienne couverture prendra fin \xE0 23\xA0h\xA059 HNE le jour pr\xE9c\xE9dant cette date)",
  INITIATE_QLE_COVERAGE_START_DATE_QUESTION: "\xC0 quelle date devrait commencer leur couverture?",
  INITIATE_QLE_CONTENT: "D\xE9marrer la r\xE9inscription \xE0 la suite d'un \xE9v\xE9nement de vie permettra \xE0 <strong>{employeeName}</strong> d\u2019avoir de nouveau acc\xE8s aux inscriptions et de faire de nouvelles s\xE9lections. Remplissez tous les champs obligatoires ci-dessous.",
  INITIATE_QLE_REENROLLMENT: "D\xE9marrer la r\xE9inscription due \xE0 un \xE9v\xE9nement de vie",
  SOME_PAGES_HAVE_ERRORS: "Certaines pages contiennent des erreurs devant \xEAtre r\xE9solues.",
  FUNDS_OUT_OF_SYNC: "Ces fonds sont d\xE9synchronis\xE9s",
  FUNDS_USED: "Fonds utilis\xE9s",
  TOTAL_FUNDS: "Fonds totaux",
  BALANCE_FUNDS_SUCCESS_NOTIFICATION: "Les fonds ont \xE9t\xE9 \xE9quilibr\xE9s avec succ\xE8s",
  BALANCE_FUNDS_CANT_BE_UNDONE: "<strong>Remarque :</strong> \xC9quilibrez les fonds seulement apr\xE8s avoir termin\xE9 de faire les changements n\xE9cessaires aux garanties de {employeeName}. Cette action ne peut \xEAtre annul\xE9e.",
  BALANCE_FUNDS_SPLAINER: "Les fonds s\u2019appliquant aux garanties de {employeeName} par rapport au montant total \xE0 sa disposition sont d\xE9synchronis\xE9s. Cela signifie que ses fonds inutilis\xE9s dans notre syst\xE8me devraient \xEAtre transf\xE9r\xE9s dans son CDB. L\u2019\xE9quilibrage des fonds enverra le montant exc\xE9dentaire dans son CDB.",
  EMPLOYEE_FUNDS_UNBALANCED_BODY: "Les fonds s\u2019appliquant aux garanties de {employeeName} par rapport au montant total \xE0 sa disposition sont d\xE9synchronis\xE9s. \xC9quilibrer les fonds de {employeeName} pour envoyer le montant exc\xE9dentaire dans son CDB.",
  EMPLOYEE_FUNDS_UNBALANCED: "Les fonds de {employeeName} sont d\xE9synchronis\xE9s",
  BALANCE_FUNDS: "\xC9quilibrer les fonds",
  USER_BENEFITS_RESET_FAILED: "Erreur lors de la r\xE9initialisation de la s\xE9lection des garanties de l\u2019utilisateur",
  USER_BENEFITS_RESET_MAINTAIN_SELECTION_SUCCEEDED: "Garanties de l\u2019utilisateur r\xE9initialis\xE9s, date de fin des inscriptions fix\xE9e au {enrollmentEndDate}",
  USER_BENEFITS_RESET_CLEAR_SELECTION_SUCCEEDED: "Garanties de l\u2019utilisateur et s\xE9lection r\xE9initialis\xE9s, date de fin des inscriptions fix\xE9e au {enrollmentEndDate}",
  RESET_USER_BENEFITS_AND_MAINTAIN_SELECTION: "R\xE9initialiser les garanties de l\u2019utilisateur et conserver la s\xE9lection",
  RESET_USER_BENEFITS_AND_CLEAR_SELECTION: "R\xE9initialiser les garanties de l\u2019utilisateur et effacer la s\xE9lection",
  RESET_USER_BENEFIT_SELECTION_SPLAINER_TEXT: "Cela r\xE9initialisera les garanties des utilisateurs et les replacera dans FTUX. Les utilisateurs auront jusqu\u2019\xE0 la date de fin des inscriptions d\xE9finie pour faire leur s\xE9lection et la soumettre de nouveau. Les nouvelles garanties des utilisateurs seront allou\xE9s apr\xE8s la date de fin des inscriptions.",
  YOU_MUST_SELECT_A_RESET_TYPE: "Vous devez s\xE9lectionner un type de r\xE9initialisation",
  ENROLLMENT_END_DATE_IS_REQUIRED: "La date de fin des inscriptions est requise",
  ENROLLMENT_END_DATE: "Date de fin de l'adh\xE9sion",
  RESET_BENEFIT_SELECTION_CONFIRM_HEADER: "R\xE9initialiser la s\xE9lection des garanties de {employeeName}?",
  RESET_USER_BENEFITS: "R\xE9initialiser les garanties de l\u2019utilisateur",
  WELCOME: "Bienvenue",
  DONT_NEED_TO_ADD_DEPENDENTS: "L\u2019ajout ou la mise \xE0 jour de personnes \xE0 charge \xE0 votre r\xE9gime n\u2019est actuellement pas disponible pour vous.",
  CANT_MANAGE_DEPENDENTS: "<strong>Pourquoi est-ce que je ne peux rien modifier?</strong> En raison de votre couverture, vous n\u2019avez pas besoin d\u2019ajouter des personnes \xE0 charge.",
  PRELOADED_INFO: "<strong>Pourquoi ne puis-je pas faire de changements?</strong> Ce choix ne peut pas \xEAtre modifi\xE9 pendant cette p\xE9riode d\u2019inscriptions. Si vous avez des questions, l\u2019\xE9quipe de Soutien \xE0 la client\xE8le de League saura vous aider.",
  ENROLLMENT_PERIOD_OVER_INFO: "<strong>Pourquoi est-ce que je ne peux rien modifier?</strong> Vous ne pouvez pas mettre \xE0 jour ou changer votre r\xE9gime d\u2019avantages sociaux, car vous avez manqu\xE9 la p\xE9riode d\u2019adh\xE9sion.",
  ON_DISABILITY_LEAVE_INFO: "<strong>Pourquoi est-ce que je ne peux rien modifier?</strong> Vous ne pouvez pas mettre \xE0 jour ou changer votre r\xE9gime d\u2019avantages sociaux lorsque vous \xEAtes en cong\xE9 d\u2019invalidit\xE9.",
  CONTRIBUTION_NOT_ALLOWED_INFO: "<strong>Patience!</strong> Vous ne pouvez pas encore contribuer \xE0 certains de vos comptes bien-\xEAtre, mais vous pourrez le faire au cours de la prochaine p\xE9riode d\u2019inscriptions.",
  SUPPORTING_DOCUMENTS_CAN_BE_THINGS: "Les documents justificatifs peuvent \xEAtre des prescriptions ou des preuves d\u2019abonnement de gym par exemple, qui sont souvent mentionn\xE9s dans le processus de traitement des demandes.",
  BAD_NETWORK_CONNECTION_TEXT: "Essayez de rafra\xEEchir votre navigateur ou v\xE9rifiez<br /> votre connexion Internet!",
  BAD_NETWORK_CONNECTION_TITLE: "Mauvaise connexion r\xE9seau",
  SELECT_FILE: "S\xE9lectionnez un document \xE0 partir de votre ordinateur.",
  ERROR_ADDING_DOC_CLAIM: "Erreur lors de l\u2019ajout du document justificatif \xE0 la demande",
  DOC_ADDED_CLAIM: "Document justificatif ajout\xE9 \xE0 la demande!",
  SUPPORTING_DOC_REMOVED_CLAIM: "Document justificatif retir\xE9 de la demande",
  ERROR_PINNING: "Erreur lors de l\u2019\xE9pinglage du document de demande",
  CLAIM_DOC_PINNED: "Document de demande \xE9pingl\xE9!",
  ERROR_UPLOADING_DOC: "Erreur lors du t\xE9l\xE9versement du document justificatif",
  ERROR_UPDATING_SUPP_DOC: "Erreur lors de la mise \xE0 jour du document justificatif",
  DOC_SUCCESSFULLY_UPDATED: "Document mis \xE0 jour avec succ\xE8s!",
  SUPPORTING_DOC_REMOVED: "Document justificatif supprim\xE9",
  SUPPORTING_DOC_ADDED: "Document justificatif ajout\xE9 avec succ\xE8s!",
  COVERAGE_SUMMARY_ERROR_TEXT: "<strong>Certaines \xE9tapes sont incompl\xE8tes et/ou comportent des erreurs. </strong> Vous devez r\xE9soudre ces erreurs avant de terminer votre adh\xE9sion.",
  INTEGRATIONS: "Integrations",
  BENEFIT_COVERAGE: "Couverture de {benefit}",
  WHAT_TYPE: "De quel type de document s\u2019agit-il?",
  IDENTIFICATION_TYPE_TWO_FILES: "Les documents d\u2019identification ne peuvent comporter que 2 images.",
  ADD_EXTRA_FILES: "Ajoutez des fichiers suppl\xE9mentaires si n\xE9cessaire",
  FULLY_VISIBLE: "Assurez-vous que l'image est enti\xE8rement visible et qu'elle n'est pas coup\xE9e",
  MAKE_SURE_DOCUMENT: "Assurez-vous que le document...",
  TIPS_FOR_UPLOADING: "Conseils pour le t\xE9l\xE9versement",
  ADD_IMAGE: "Ajoutez plus de pages \xE0 ce document (le cas \xE9ch\xE9ant)",
  SELECT_TYPE: "S\xE9lectionnez un type",
  DRAG_OR_UPLOAD_PHOTO: "T\xE9l\xE9versez une photo de ce document",
  DRAG_OR_UPLOAD: "Glissez ou t\xE9l\xE9versez un document",
  DRAG_AND_DROP: "Glissez-d\xE9posez un document dans cette fen\xEAtre ou s\xE9lectionnez-en un \xE0 partir de votre bureau.",
  WAIT_ENROLLMENT: "Votre adh\xE9sion \xE0 {planName} ouvrira lorsque vous aurez termin\xE9 votre adh\xE9sion \xE0 {previousPlanName}",
  CONTINUE_ENROLLMENT: "Poursuivre l\u2019inscription",
  START_ENROLLMENT: "Commencer l'adh\xE9sion",
  PLAN_PICKER_EXPLANATION: "Il s'agit de la p\xE9riode pendant laquelle vos avantages sociaux seront actifs et pourront \xEAtre utilis\xE9s.",
  PLAN_PICKER_WAIT: "Votre {waitingName} va ouvrir lorsque vous finirez votre {currentName}",
  PLAN_PICKER_DEADLINE: "Vous avez jusqu'au <strong>{date}</strong> pour adh\xE9rer \xE0 ce r\xE9gime",
  NEW_JOB_EXPLANATION: "Comme vous venez tout juste de commencer chez {employer}, votre adh\xE9sion sera un peu diff\xE9rente de la normale. Vous devrez choisir la couverture pour la p\xE9riode de police en cours, ainsi que la couverture de la prochaine p\xE9riode.",
  NEW_JOB_CONGRATS: "F\xE9licitations pour votre nouvel emploi",
  YOUR_ENROLLMENT_EXPERIENCE: "Votre exp\xE9rience d'adh\xE9sion",
  THE_TOOL: "L\u2019outil",
  DECISION_SUPPORT_STATUS_ERROR_DESCRIPTION: "Refaites le questionnaire ou actualisez la page dans votre navigateur.",
  DECISION_SUPPORT_STATUS_PENDING_DESCRIPTION: "Terminez de remplir le questionnaire Picwell pour d\xE9couvrir le r\xE9gime qui vous correspond le mieux.",
  DECISION_SUPPORT_STATUS_COMPLETED_DESCRIPTION: "La meilleure correspondance s'affichera dans le tableau ci-dessous",
  PENDING_STATE_TITLE: "Nous attendons vos r\xE9sultats...",
  COMPLETED_STATE_TITLE: "Compl\xE9t\xE9",
  HELP_ME_CHOOSE: "Aidez-moi \xE0 choisir",
  DECISION_SUPPORT_BANNER_DESCRIPTION: "Nous sommes associ\xE9s avec Picwell, un outil qui vous aide \xE0 d\xE9terminer quel r\xE9gime {benefitSetName} vous correspondrait le mieux. Le questionnaire est anonyme et prend 6 minutes!",
  DECISION_SUPPORT_BANNER_HEADER: "Vous avez besoin d'aide pour s\xE9lectionner un r\xE9gime?",
  DEPENDENT_COVERAGE_CHANGED_TO: 'Nous avons modifi\xE9 le {plan} de couverture de votre personne \xE0 charge pour "{dependentCoverage}"',
  DEPENDENT_COVERAGE_PREPOPULATED: "Remarque: La s\xE9lection de la couverture pour votre personne \xE0 charge doit \xEAtre la m\xEAme pour la couverture {benefits}. Nous remplirons \xE7a automatiquement.",
  PLEASE_ENTER_A_VALID_VALUE: "Veuillez entrer une valeur valide",
  UNIQUE_IDENTIFIER_MAX_LENGTH_ERROR: "Doit comporter {length} caract\xE8res au maximum",
  UNIQUE_IDENTIFIER_MIN_LENGTH_ERROR: "Doit comporter au moins {length} caract\xE8res",
  TOO_LONG_AGO_DATE_ERROR_MESSAGE: "Veuillez choisir une date apr\xE8s 1900",
  FUTURE_DATE_ERROR_MESSAGE: "Veuillez choisir une date pass\xE9e.",
  INVALID_DAY_ERROR_MESSAGE: "Le jour que vous avez s\xE9lectionn\xE9 est invalide.",
  INVALID_DATE_ERROR_MESSAGE: "Cette date n\u2019est pas valide, veuillez entrer une date valide.",
  FLEX_DECREASE_SUBHEADING: "Les subventions suppl\xE9mentaires de {employerName} peuvent changer en fonction de vos s\xE9lections.",
  FLEX_DECREASE_HEADING: "Votre {flexTerm} a \xE9t\xE9 ajust\xE9 en raison de la couverture s\xE9lectionn\xE9e.",
  FLEX_INCREASE_SUBHEADING: "{employerName} offre une subvention des avantages sociaux d\xE9pendant de vos s\xE9lections.",
  FLEX_INCREASE_HEADING: "Votre {flexTerm} a augment\xE9 en raison de la couverture s\xE9lectionn\xE9e!",
  READY_TO_BUILD_YOUR_PLAN: "\xCAtes-vous pr\xEAt \xE0 concevoir votre r\xE9gime?",
  RAMQ_ALT_COVERAGE_INFO: "Le r\xE9gime que vous avez s\xE9lectionn\xE9 n\u2019est pas compatible avec la RAMQ. La RAMQ vous demande d\u2019attester que vous disposez d\u2019une autre couverture, soit par l\u2019interm\xE9diaire de votre partenaire ou conjoint(e) soit par un autre moyen.",
  RAMQ_CONFIRM_ALT_COVERAGE: "Oui, je dispose d\u2019une autre couverture",
  RAMQ_TOOLTIP_TEXT: "La RAMQ (R\xE9gie de l\u2019assurance maladie du Qu\xE9bec) est le syst\xE8me d\u2019assurance maladie publique obligatoire du Qu\xE9bec. La RAMQ offre aux r\xE9sidents du Qu\xE9bec une couverture sant\xE9 de base (qui comprend les prescriptions de m\xE9dicaments) s\u2019ils ne sont pas couverts par un r\xE9gime de groupe admissible.",
  WHAT_IS_RAMQ: "Qu\u2019est-ce que la RAMQ?",
  RAMQ_ALT_COVERAGE_HEADING: "Disposez-vous d\u2019une autre couverture?",
  DMY_DATE_FIELD_PLACEHOLDER: "JJ/MM/AAAA",
  MDY_DATE_FIELD_PLACEHOLDER: "MM/JJ/AAAA",
  YMD_DATE_FIELD_PLACEHOLDER: "AAAA/MM/JJ",
  USER_PROFILE_UNSAVED_ERROR_MESSAGE: "<strong>Sauvegardez votre profil!</strong> Vous devez remplir et sauvegarder le formulaire avant de passer \xE0 l\u2019\xE9tape suivante.",
  DEPENDENTS_PAGE_UNSAVED_ERROR_MESSAGE: "<strong>Sauvegardez vos personnes \xE0 charge!</strong> Vous devez remplir et sauvegarder chaque formulaire avant de passer \xE0 l\u2019\xE9tape suivante.",
  OPTIONAL_BENEFIT_NOT_SELECTED_ERROR: "<strong>Un instant! Il nous manque quelques informations.</strong> Veuillez s\xE9lectionner un r\xE9gime ou renoncer \xE0 une couverture pour y rem\xE9dier.",
  REQUIRED_BENEFIT_NOT_SELECTED_ERROR: "<strong>Un instant! Il nous manque quelques informations.</strong> Veuillez s\xE9lectionner un r\xE9gime pour y rem\xE9dier.",
  RAMQ_ACKNOWLEDGEMENT_REQUIRED_ERROR: "<strong>Le r\xE9gime que vous avez s\xE9lectionn\xE9 n\u2019est pas compatible avec la RAMQ.</strong> Indiquez si vous avez une autre couverture au bas de cette page.",
  MISSING_INFORMATION_VALIDATION_ERROR_MESSAGE: "<strong>Nous avons trouv\xE9 des erreurs sur cette page.</strong> Vous devez les corriger avant de passer \xE0 la prochaine \xE9tape.",
  REMOVE_DEPENDENT: "Supprimer",
  SAVE_CHANGES: "Sauvegarder les modifications ",
  DEPENDENT_FORM_TITLE: "Renseignements de votre {dependent}",
  SPOUSE_DEPENDENT_FORM_TITLE: "Renseignements de votre conjoint ou partenaire",
  INVALID_US_SSN: "Format SSN incorrect (111-22-3333 or 111111111)",
  INVALID_ZIP_CODE: "Format du code ZIP incorrect (11111 ou 11111-2222)",
  INVALID_POSTAL_CODE: "Format du code postal incorrect (A1A 1A1)",
  THIS_FIELD_IS_REQUIRED: "Ce champs est obligatoire",
  FLEX_BALANCE_DEDUCTION_DESCRIPTION: "Ces comptes seront prioritaires. Vous pouvez d\xE9penser la totalit\xE9 de votre {flexDollarTerm} sur ces comptes et vos choix pr\xE9c\xE9dents seront couverts par des retenues salariales.",
  LETS_GET_STARTED: "Commen\xE7ons",
  YOU_CAN_ALSO_VISIT_KNOWLEDGE_BASE: "Vous pouvez \xE9galement vous rendre sur {knowledgeBaseLink} pour en savoir plus sur vos offres d\u2019avantages sociaux.",
  HAVE_QUESTIONS_DESCRIPTION: "L\u2019\xE9quipe du service \xE0 la client\xE8le de League est disponible si vous avez des questions durant le processus. ",
  HAVE_QUESTIONS: "Des questions?",
  NEW_HIRE_SAVING_AND_EDITING_DESCRIPTION: "Prenez le temps de finaliser votre adh\xE9sion. Ne vous inqui\xE9tez pas, vos choix sont enregistr\xE9s lorsque vous passez aux autres sections, mais vous devrez soumettre vos choix pour finaliser l\u2019inscription.",
  PARTNER_SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS: "Prenez le temps de finaliser votre adh\xE9sion. Ne vous inqui\xE9tez pas, vos choix sont enregistr\xE9s lorsque vous passez aux autres sections, mais vous devrez soumettre vos choix pour finaliser l\u2019adh\xE9sion. Vous pouvez aussi modifier vos choix \xE0 tout moment de l'ann\xE9e en contactant l'\xE9quipe du service client\xE8le de League.",
  PARTNER_SAVING_AND_EDITING_DESCRIPTION: "Nous vous recommandons de prendre votre temps avant de finaliser votre adh\xE9sion. Ne vous inqui\xE9tez pas, vos s\xE9lections seront sauvegard\xE9es lorsque vous passez chaque section, mais vous devez les soumettre \xE0 la fin de l'adh\xE9sion pour qu'elles soient prises en compte.",
  SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS: "Prenez le temps de finaliser votre adh\xE9sion. Ne vous inqui\xE9tez pas, vos choix sont enregistr\xE9s lorsque vous passez aux autres sections, mais vous devrez soumettre vos choix pour finaliser l\u2019inscription.",
  SAVING_AND_EDITING_DESCRIPTION: "Nous vous recommandons de prendre votre temps avant de finaliser votre adh\xE9sion. Ne vous inqui\xE9tez pas, vos s\xE9lections seront sauvegard\xE9es lorsque vous passez chaque section, mais vous devez les soumettre \xE0 la fin de l'adh\xE9sion pour qu'elles soient prises en compte.",
  SAVING_AND_EDITING: "Sauvegarde et modification",
  NEW_HIRE_POLICY_DURATION_DESCRIPTION: "Vos choix effectu\xE9s pendant la p\xE9riode d\u2019adh\xE9sion entreront en vigueur le 1er jour du mois suivant la date de soumission. Ils seront en vigueur jusqu'au <strong> {planEndDate} </strong>.",
  POLICY_DURATION_DESCRIPTION: "Vos choix effectu\xE9s pendant la p\xE9riode d\u2019adh\xE9sion entreront en vigueur le 1er jour du mois suivant la date de soumission. Ils seront en vigueur jusqu'au <strong> {planEndDate} </strong>.",
  POLICY_DURATION: "Dur\xE9e du contrat",
  NEW_HIRE_PARTNER_ENROLLMENT_PERIOD_DESCRIPTION: "Vous disposez de 31 jours depuis votre date d'embauche pour compl\xE9ter et soumettre vos choix. Vous pouvez \xE9galement modifier vos s\xE9lections de prestations \xE0 tout moment au cours de l\u2019ann\xE9e si vous le souhaitez en prenant contact avec l\u2019\xE9quipe de service client\xE8le de League. Les prestations entreront en vigueur le premier jour du mois suivant la date de soumission.",
  PARTNER_ENROLLMENT_PERIOD_DESCRIPTION: "Vous pouvez r\xE9viser et mettre \xE0 jour vos choix autant que vous le souhaitez jusqu\u2019\xE0 la fin de la p\xE9riode d\u2019adh\xE9sion ({enrollmentEndDate}). Vous pouvez \xE9galement modifier vos choix de prestations \xE0 tout moment au cours de l\u2019ann\xE9e en prenant contact avec l\u2019\xE9quipe de service client\xE8le de League. Les prestations entreront en vigueur le premier jour du mois suivant la date de soumission.",
  NEW_HIRE_ENROLLMENT_PERIOD_DESCRIPTION: "Vous disposez de 31 jours depuis votre date d'embauche pour compl\xE9ter et soumettre vos choix. Si vous ne soumettez aucun choix, une couverture vous sera attribu\xE9e par d\xE9faut. Une fois soumis, vous ne pouvez plus faire de changements. La prochaine occasion de faire des changements sera \xE0 la suite d'un \xE9v\xE9nement de vie ou au cours de la p\xE9riode d\u2019adh\xE9sion de l\u2019ann\xE9e suivante.",
  ENROLLMENT_PERIOD_DESCRIPTION: "Vous pouvez r\xE9viser et mettre \xE0 jour vos choix autant que vous le souhaitez jusqu\u2019\xE0 la fin de la p\xE9riode d\u2019adh\xE9sion ({enrollmentEndDate}). Si vous ne soumettez aucun choix, votre couverture actuelle restera en vigueur. Tous les fonds restants de votre r\xE9serve bien-\xEAtre seront allou\xE9s \xE0 votre compte de d\xE9penses bien-\xEAtre. <p>La prochaine occasion de faire des changements sera \xE0 la suite d'un \xE9v\xE9nement de vie ou au cours de la p\xE9riode d\u2019adh\xE9sion de l\u2019ann\xE9e suivante.</p>",
  ENROLLMENT_PERIOD: "P\xE9riode d\u2019adh\xE9sion",
  PAYROLL_DEDUCTIONS_PARTNERS: "Cette valeur indique le montant qui sera couvert par vos frais. <p>Vous verrez d\xE9j\xE0 le co\xFBt des avantages obligatoires (assurance invalidit\xE9 de longue dur\xE9e, assurance vie et accident) lorsque vous vous connectez.</p><p>Le montant 'Vous payez' changera en fonction de vos choix.</p>",
  PAYROLL_DEDUCTIONS_DESCRIPTION: "Cette valeur indique le montant qui sera couvert comme une d\xE9pense pour vous.<p>L'assurance-vie facultative, l'assurance invalidit\xE9 de longue dur\xE9e (ILD) et l'assurance-accidents (DMA) doivent obligatoirement \xEAtre pay\xE9es par retenues salariales pour \xE9viter tout avantage imposable au moment du paiement.</p><p>Le montant \xAB Vous payez \xBB changera en fonction de vos choix. Si vos s\xE9lections de prestation m\xE9dicale, de m\xE9dicaments et de soins dentaires d\xE9passent le solde de la r\xE9serve bien-\xEAtre, tous les co\xFBts restants sont \xE9galement couverts par des retenues salariales.</p>",
  PAYROLL_DEDUCTIONS: "Retenues salariales",
  BENEFIT_SUBSIDIZATION_DESCRIPTION: "KPMG reconna\xEEt que vous encourez des co\xFBts suppl\xE9mentaires afin de b\xE9n\xE9ficier d\u2019une couverture d\u2019assurance m\xE9dicale et dentaire familiale. KPMG subventionne de ce fait l\u2019achat d\u2019avantages sociaux, en plus de votre r\xE9serve bien-\xEAtre, afin de vous venir en aide si vous d\xE9cidez d'ajouter une personne \xE0 charge admissible au r\xE9gime d\u2019avantages sociaux.",
  BENEFIT_SUBSIDIZATION: "Subventionnement des avantages sociaux",
  WELLNESS_POOL_DESCRIPTION: "KPMG a conc\u0327u pour vous un re\u0301gime de re\u0301mune\u0301ration globale largement subventionne\u0301 au moyen d\u2019une allocation appele\u0301e re\u0301serve bien-e\u0302tre. Vous re\u0301partissez les fonds qui constituent cette re\u0301serve comme bon vous semble, dans les avantages sociaux a\u0300 la carte ou ailleurs. Vous faites vos choix en fonction de votre style de vie, et le solde change sous vos yeux.",
  KPMG_PAYS: "R\xE9mun\xE9rations KPMG",
  OVERVIEW: "Aper\xE7u",
  WELCOME_SCREEN_SUB_HEAD_DESCRIPTION: "KPMG vous a tout sp\xE9cialement fourni une offre group\xE9e de re\u0301gime de re\u0301mune\u0301ration globale. Au cours de ce processus d\u2019adh\xE9sion, vous choisirez entre diff\xE9rents avantages sociaux afin d'\xE9tablir un r\xE9gime adapt\xE9 \xE0 vos besoins. Nous vous guiderons \xE0 chaque \xE9tape.",
  WELCOME_TO_YOUR_BENEFITS_EXPERIENCE: "Bienvenue dans votre exp\xE9rience d\u2019avantages sociaux",
  OPTIONAL_LTD: "ILD facultative",
  VISIT_LEAGUE: "Rendez-vous sur League",
  GET_STARTED: "Commencez \xE0 utiliser League!",
  DOWNLOAD_ANDROID: "Procurez-vous le sur Google Play.",
  DOWNLOAD_IOS: "T\xE9l\xE9charger sur l'App Store.",
  SIGN_IN_FROM_DESKTOP: "Ou connectez-vous depuis votre ordinateur!",
  LEAGUE_DESKTOP: "League pour ordinateur",
  OR_VISIT: "ou rendez-vous sur",
  DOWNLOAD_MOBILE_APPS: "T\xE9l\xE9chargez nos applis mobiles!",
  EXPERIENCE_LEAGUE_SUBHEADING: "Pas besoin d\u2019attendre pour commencer \xE0 utiliser League! Pour consulter vos services de sant\xE9 o\xF9 que vous soyez, t\xE9l\xE9chargez l\u2019appli League sur tous vos appareils.",
  EXPERIENCE_LEAGUE: "D\xE9couvrez League",
  ARTICLE_EXTERNAL_ACCOUNT_BENEFIT: "un {benefitName}",
  VIEW_MY_WALLET: "Voir mon portefeuille",
  EXTERNAL_ACCOUNT_CARD_CONTENT_MULTIPLE: "Il semble que vous ayez cotis\xE9 \xE0 des {FormattedExternalAccountBenefits}. Si ce n'est pas d\xE9j\xE0 fait, vous devrez cr\xE9er des profils pour ces comptes. Nous vous expliquerons comment le faire dans votre portefeuille num\xE9rique League lorsque vos avantages sociaux seront ajout\xE9s.",
  EXTERNAL_ACCOUNT_CARD_CONTENT_SINGLE: "Il semble que vous ayez cotis\xE9 \xE0 des {FormattedExternalAccountBenefits}. Si ce n'est pas d\xE9j\xE0 fait, vous devrez cr\xE9er un profil pour ce compte. Nous vous expliquerons comment le faire dans votre portefeuille num\xE9rique League lorsque vos avantages sociaux seront ajout\xE9s.",
  EXTERNAL_ACCOUNT_CARD_TITLE: "Certains comptes ont encore besoin d'\xEAtre configur\xE9s",
  TAKE_SURVEY: "Participer \xE0 notre sondage",
  BY_FILLING_OUT_SURVEY: "En remplissant ce sondage rapide (qui ne prendra que 2 minutes), vous nous aiderez \xE0 am\xE9liorer l\u2019exp\xE9rience d\u2019adh\xE9sion pour tous les prochains utilisateurs, et vous en profiterez \xE9galement lors de votre prochaine adh\xE9sion!",
  WE_WANT_TO_HEAR_FROM_YOU: "Nous voulons votre opinion",
  VIEW_DESIGNATIONS: "Voir les d\xE9signations",
  DELETE_BENEFICIARY_WARNING_MODAL_DESCRIPTION: "Vous devez d'abord retirer la ou les d\xE9signations associ\xE9es \xE0 ce b\xE9n\xE9ficiaire des avantages suivants :",
  DELETE_BENEFICIARY_WARNING_MODAL_HEADER: "Le b\xE9n\xE9ficiaire ne peut pas \xEAtre supprim\xE9",
  DELETE_BENEFICIARY: "Supprimer le b\xE9n\xE9ficiaire",
  DELETE_BENEFICIARY_CONFIRM_MODAL_DESCRIPTION: "Cette action est permanente et n'est pas r\xE9versible.",
  DELETE_BENEFICIARY_CONFIRM_MODAL_HEADER: '\xCAtes-vous certain de vouloir supprimer "{beneficiaryName}"?',
  BENEFICIARY_CARD_WITHOUT_DESIGNATIONS: "Ce b\xE9n\xE9ficiaire n'est d\xE9sign\xE9 pour aucune prestation",
  BENEFICIARY_CARD_WITH_DESIGNATIONS: "D\xE9signations : {benefitDesignations}",
  BENEFICIARIES_LIST_VIEW_EMPTY_STATE: `Vous n'avez pas encore ajout\xE9 de b\xE9n\xE9ficiaires. Vous pouvez le faire dans la section D\xE9signations en cliquant sur "Ajouter un b\xE9n\xE9ficiaire" dans une prestation.`,
  BENEFICIARIES_LIST_VIEW_DESCRIPTION: "Un b\xE9n\xE9ficiaire est une personne ou une entit\xE9 qui re\xE7oit un paiement de votre assureur dans le cas o\xF9 vous d\xE9c\xE9dez. Il est important de d\xE9signer vos b\xE9n\xE9ficiaires pour vous assurer que votre argent se retrouve bien entre les bonnes mains.",
  ERROR_BENEFICIARY_ALREADY_ADDED: "{beneficiaryName} a d\xE9j\xE0 \xE9t\xE9 ajout\xE9 \xE0 cette prestation.",
  ERROR_SAVING_BENEFICIARY_DESIGNATIONS: "D\xE9sol\xE9, une erreur est survenue lors de l'enregistrement de vos d\xE9signations de b\xE9n\xE9ficiaires. {error}",
  BENEFICIARY_DESIGNATIONS_SAVED: "Vos d\xE9signations de b\xE9n\xE9ficiaires ont \xE9t\xE9 sauvegard\xE9es.",
  ERROR: "Erreur",
  SUCCESS: "C'est r\xE9ussi!",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_ERROR: "Cette confirmation est n\xE9cessaire pour sauvegarder votre (vos) d\xE9signation(s)",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_ACCEPTANCE: "J'ai lu ce qui pr\xE9c\xE8de et je confirme ma (mes) nouvelle(s) d\xE9signation(s)",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_STATEMENT: "Je retire toute d\xE9signation de b\xE9n\xE9ficiaire qui a \xE9t\xE9 faite pr\xE9c\xE9demment pour les prestations d'assurance Vie et/ou de DMA de mon r\xE9gime d'employeur et j'autorise que le produit des prestations ci-dessus soit vers\xE9 au(x) b\xE9n\xE9ficiaire(s) d\xE9sign\xE9(s) sur ce formulaire.",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_HEADER: "Veuillez confirmer votre (vos) nouvelle(s) d\xE9signation(s) de b\xE9n\xE9ficiaire(s)",
  SAVE_WITHOUT_DESIGNATIONS: "\xCAtes-vous certain de vouloir enregistrer sans d\xE9signations pour la ou les prestations(s) suivante(s) :",
  MISSING_DESIGNATIONS: "D\xE9signations manquantes",
  CONTINGENT_BENEFICIARIES_TOOLTIP: "Les b\xE9n\xE9ficiaires subsidiaires sont les deuxi\xE8mes \xE0 recevoir le  versement de la prestation. Les b\xE9n\xE9ficiaires subsidiaires ne sont autoris\xE9s \xE0 recevoir l'argent que si aucun des b\xE9n\xE9ficiaires principaux d\xE9sign\xE9s n'est disponible pour le recevoir.",
  PRIMARY_BENEFICIARIES_TOOLTIP: "Les b\xE9n\xE9ficiaires principaux sont les premiers \xE0 recevoir le versement des prestations.",
  ORGANIZATION_DESCRIPTION: "Vous pouvez nommer un b\xE9n\xE9ficiaire qui n'est pas une personne, comme un association caritative, une fiducie ou une succession.",
  ORGANIZATION: "Organisation",
  PERSON_DESCRIPTION: "Vous pouvez nommer n'importe qui en tant que b\xE9n\xE9ficiaire.",
  PERSON: "Personne",
  BENEFICIARY_FORM_LEGEND: "S\xE9lectionnez le type de b\xE9n\xE9ficiaire que vous d\xE9sirez ajouter",
  ALL_FIELDS_ARE_REQUIRED_UNLESS_STATED_AS_OPTIONAL: "Tous les champs sont obligatoires \xE0 moins d'\xEAtre marqu\xE9s comme facultatifs.",
  BENEFICIARY_DETAILS: "D\xE9tails sur les b\xE9n\xE9ficiaires",
  EDIT_BENEFICIARY: "Modifier le b\xE9n\xE9ficiaire",
  ADD_BENEFICIARY: "Ajouter un b\xE9n\xE9ficiaire",
  NO_BENEFICIARIES_DESIGNATED: "Aucun b\xE9n\xE9ficiaire {designationType} d\xE9sign\xE9",
  TOTAL_PERCENTAGE: "Pourcentage total",
  CONTINGENT: "Subsidiaires",
  PRIMARY: "Principaux",
  DESIGNATION_LIST_HEADER: "B\xE9n\xE9ficiaires {designationType}",
  DESIGNATION_SHARE_PERCENTAGE_ERROR: "Veuillez sp\xE9cifier un pourcentage pour chaque b\xE9n\xE9ficiaire afin que le total soit \xE9gal \xE0 100 %.",
  PERCENTAGE_TOTAL_REQUIREMENT: "Doit \xEAtre \xE9gal \xE0 100%",
  PERCENTAGE: "Pourcentage",
  BENEFICIARY_DESIGNATION_COUNT: "{designationCount, plural, one {# b\xE9n\xE9ficiaire} other {# b\xE9n\xE9ficiaires}} d\xE9sign\xE9s",
  CREATE_NEW_BENEFICIARY: "Cr\xE9er un nouveau",
  ADD_BENEFICIARIES_EMPTY_TEXT: "Vous n\u2019avez ajout\xE9 aucun b\xE9n\xE9ficiaire",
  ADD_BENEFICIARY_DROPDOWN_BUTTON_TO_BENEFIT: "Ajoutez le b\xE9n\xE9ficiaire {designationType} \xE0 {benefitName}",
  ADD_BENEFICIARY_DROPDOWN_BUTTON: "+Ajouter un b\xE9n\xE9ficiaire",
  SAVE_AND_SUBMIT: "Enregistrer et soumettre",
  DESIGNATING_BENEFICIARIES: "D\xE9signer des b\xE9n\xE9ficiaires",
  DESIGNATIONS_VIEW_DESCRIPTION: "Pour chaque prestation, vous pouvez nommer un ou plusieurs b\xE9n\xE9ficiaires principaux et, facultativement, un ou plusieurs b\xE9n\xE9ficiaires \xE9ventuels. Lorsque vous nommez un b\xE9n\xE9ficiaire, sp\xE9cifiez le pourcentage du versement de la prestation qu\u2019il devrait recevoir.",
  YOUR_BENEFICIARIES: "Vos b\xE9n\xE9ficiaires",
  DESIGNATIONS: "D\xE9signations",
  RECOMMENDED_FORM: "Recommand\xE9",
  REQUIRED_FORM: "Requis",
  DIGITAL_BENEFICIARIES_CARD_LAST_UPDATED: "Derni\xE8re mise \xE0 jour : {lastUpdated}",
  DIGITAL_BENEFICIARIES_CARD_TOTAL_BENEFICIARIES: "Vos b\xE9n\xE9ficiaires ({totalBeneficiaries})",
  DIGITAL_BENEFICIARIES_CARD_EMPTY: "Vous n\u2019avez d\xE9sign\xE9 aucun b\xE9n\xE9ficiaire",
  PAPER_BENEFICIARIES_CARD_INFO_BANNER: "<strong>Instructions :</strong> Apr\xE8s avoir compl\xE9t\xE9 le formulaire, vous pouvez l'ajouter \xE0 la section <strong>T\xE9l\xE9versements</strong> sur cette page, \xE0 moins que votre \xE9quipe de RH vous ait soumis d'autres instructions.",
  PAPER_BENEFICIARIES_CARD_DESCRIPTION_NOTE: "Remarque : si vous avez d\xE9j\xE0 soumis un formulaire de b\xE9n\xE9ficiaire aupr\xE8s de votre employeur actuel et que vous ne souhaitez pas modifier vos d\xE9signations, vous n'avez pas besoin de soumettre un nouveau formulaire.",
  BENEFICIARIES_FORM_CARD_DESCRIPTION: "Afin de prot\xE9ger vos proches, League vous recommande de toujours d\xE9signer des b\xE9n\xE9ficiaires dans vos contrats d\u2019assurance.",
  BENEFICIARIES_FORM_CARD_TOOLTIP: "<p>Votre b\xE9n\xE9ficiaire est la personne ou l\u2019entit\xE9 que vous choisissez pour toucher les paiements de votre assurance si vous d\xE9c\xE9dez. </p><p>Il est tr\xE8s important que vous d\xE9signiez vos b\xE9n\xE9ficiaires afin que l\u2019argent revienne \xE0 la ou les bonne(s) personne(s) lors de votre d\xE9c\xE8s. Sans b\xE9n\xE9ficiaires, le versement des fonds peut \xEAtre retard\xE9 et il pourra \xEAtre soumis \xE0 des frais co\xFBteux et inutiles. </p><p>Nous vous montrerons apr\xE8s l\u2019adh\xE9sion comment d\xE9signer un b\xE9n\xE9ficiaire si vous n\u2019en avez pas d\xE9j\xE0 un.</p>",
  DOWNLOAD_FORMS_TEXT: "Vos assureurs doivent recevoir une copie papier, une copie num\xE9ris\xE9e ou une photographie des formulaires ci-apr\xE8s afin de mettre votre couverture en place.",
  DOWNLOAD_FORMS: "T\xE9l\xE9charger les formulaires",
  DOWNLOAD_FORM: "T\xE9l\xE9charger le formulaire ",
  FOREIGN_CONTRACT: "Questionnaire pour travailleurs \xE9trangers sous contrat",
  DOWNLOAD: "T\xE9l\xE9charger",
  VIEW: "Voir",
  IDENTIFICATION: "Identification",
  PROOF_OF_BUSINESS: "Preuve d\u2019activit\xE9 de l\u2019entreprise",
  BENEFICIARY_FORM: "Formulaire\xA0de d\xE9signation de b\xE9n\xE9ficiaires",
  GYM_MEMBERSHIP: "Abonnement au gym",
  PRESCRIPTION: "Ordonnance",
  NAME: "Nom",
  UPLOADED: "T\xE9l\xE9vers\xE9",
  UPLOAD_A_DOCUMENT: "T\xE9l\xE9verser un document",
  UPLOAD_DOCUMENT: "T\xE9l\xE9verser un document",
  ONCE_YOU_UPLOAD: "Lorsque vous ou un conseiller \xE9pinglez un document pour vous, il appara\xEEtra ici. Commen\xE7ons sans plus tarder!",
  DONT_HAVE_ANY_UPLOADED_DOCUMENTS: "Vous n\u2019avez pas encore t\xE9l\xE9vers\xE9 de documents",
  BACK_TO_DOCUMENTS_AND_FORMS: "Retour aux documents et formulaires",
  MY_DOCUMENTS: "Mes documents",
  SUBMITTING_FORMS_EOI_BOLD: "directement \xE0 l'assureur.",
  SUBMITTING_FORMS_BENEFICIARIES: 'B\xE9n\xE9ficiaires\xA0: Vous pouvez t\xE9l\xE9charger votre formulaire\xA0de d\xE9signation de b\xE9n\xE9ficiaires ici. Veuillez t\xE9l\xE9charger votre formulaire, le compl\xE9ter et le signer soit num\xE9riquement ou \xE0 la plume. Ceci est le formulaire standard fourni par Sun Life qui n\xE9cessite une signature \xE0 la plume, cependant une signature num\xE9rique est aussi accept\xE9e par Sun Life.<br /> Vous pouvez t\xE9l\xE9verser le formulaire complet dans la section "Documents et formulaires" de votre profil League depuis votre ordinateur ou de l\u2019appli League.',
  SUBMITTING_FORMS_EOI: "Preuve d\u2019assurabilit\xE9\xA0: Ce formulaire doit \xEAtre soumis {boldText}",
  SUBMITTING_FORMS: "Soumettre des formulaires",
  MUST_BE_INK_SIGNED: "Doit \xEAtre sign\xE9 \xE0 la main puis envoy\xE9 directement par la poste \xE0 l\u2019assureur",
  MAIL_TO_CARRIER: "envoyer directement \xE0 l\u2019assureur",
  WILL_NOTIFY_ONCE_AVAILABLE: "Vous recevrez une notification lorsque le formulaire sera disponible.",
  EOI_INFO: "La preuve d\u2019assurabilit\xE9 est un questionnaire de sant\xE9 qui fournit des informations sur votre \xE9tat de sant\xE9. Ce formulaire doit \xEAtre rempli puis sign\xE9 \xE0 l'encre et envoy\xE9 par la poste directement \xE0 l\u2019assureur pour valider l\u2019assurance {benefitNames}.",
  DOWNLOAD_NOW: "T\xE9l\xE9charger maintenant.",
  EOI_FORM_TEXT: "{eoiForm} Vous avez choisi {allSelectedEoiBenefitNames}, vous devez donc remplir un formulaire de preuve d\u2019assurabilit\xE9. Ce formulaire vous sera fourni apr\xE8s la fin de votre p\xE9riode d\u2019inscription. Veuillez le signer \xE0 l'encre et {mailToCarrier} pour validation. Si votre assureur ne le re\xE7oit pas, votre demande sera incompl\xE8te. {downloadOrWait}",
  EOI_FORMS: "Preuve d'assurabilit\xE9 (requis)",
  EOI_FORM: "Formulaire de preuve d\u2019assurabilit\xE9 :",
  BENEFICIARIES_FORM_TEXT_AVAILABLE: "{beneficiariesForm} League vous recommande de toujours d\xE9signer des b\xE9n\xE9ficiaires pour vos polices d\u2019assurance. Vous offrez ainsi une s\xE9curit\xE9 financi\xE8re \xE0 vos proches.",
  BENEFICIARIES_FORM_TEXT: "{beneficiariesForm} Afin de prot\xE9ger vos proches, League vous recommande de toujours d\xE9signer des b\xE9n\xE9ficiaires dans vos contrats d\u2019assurance. Vous pouvez t\xE9l\xE9charger ce formulaire \xE0 la fin de votre p\xE9riode d\u2019inscription dans la section {documentsAndFormsLink} de votre appli League.",
  BENEFICIARY_FORMS: "Formulaires\xA0- d\xE9signation de b\xE9n\xE9ficiaires",
  BENEFICIARIES_FORM: "Formulaire\xA0- d\xE9signation de b\xE9n\xE9ficiaires :",
  BENEFICIARIES: "B\xE9n\xE9ficiaires",
  ERROR_DOWNLOADING_INSURANCE_FORM: "Une erreur est survenue lors du t\xE9l\xE9chargement de votre formulaire",
  FORMS_DOWNLOAD_INFO: "Vous trouverez ci-dessous des informations concernant les formulaires que vous devez remplir pour assurer que vous et votre vos proches \xEAtes prot\xE9g\xE9s. Si votre op\xE9rateur accepte les formulaires num\xE9riques, ils seront \xE9galement disponibles ici.",
  YOUR_INSURANCE_FORMS: "Vos formulaires d'assurance",
  NEXT_STEPS_SUBHEADING: "Jetez un coup d'\u0153il aux prochaines \xE9tapes ci-dessous et vous serez sur la bonne voie pour vivre l'ann\xE9e la plus saine que vous n'ayez jamais v\xE9cue.",
  NEXT_STEPS_HEADING: "Vous y \xEAtes, {name}!",
  DISMISS: "Ignorer",
  CHANGE_PLAN_LINK: "Modifier la s\xE9lection du r\xE9gime",
  CHANGE_PLAN_DESCRIPTION: "Souhaitez-vous modifier votre r\xE9gime ou vos renseignements personnels?",
  CHANGE_PLAN_LABEL: "Changer les s\xE9lections du r\xE9gime",
  TOTAL_REWARDS_LINK: "Voir le r\xE9capitulatif de votre couverture",
  TOTAL_REWARDS_DESCRIPTION: "R\xE9viser la s\xE9lection du r\xE9gime effectu\xE9e pendant l\u2019inscription.",
  TOTAL_REWARDS_LABEL: "R\xE9capitulatif de votre couverture",
  SELECTIONS_FINALIZED_SUCCESS: "S\xE9lection termin\xE9e!",
  ERROR_FINALIZING_SELECTIONS_INVALID_SELECTIONS: "Vos s\xE9lections semblent incompl\xE8tes. Veuillez r\xE9aliser votre inscription et r\xE9essayer.",
  ERROR_FINALIZING_SELECTIONS: "Une erreur est survenue, nous n\u2019avons pas pu soumettre vos s\xE9lections.",
  FINALIZE_PLAN_SELECTIONS: "Finaliser les s\xE9lections de mon r\xE9gime.",
  FINALIZE_SELECTIONS_ACKNOWLEDGEMENT: "Je comprends que je ne pourrai plus modifier mes s\xE9lections.",
  FINALIZE_SELECTIONS_COPY_2: "Cela signifie \xE9galement qu'une fois que vous aurez cliqu\xE9 sur soumettre, vous ne pourrez plus modifier votre r\xE9gime.",
  FINALIZE_SELECTIONS_COPY_1: "En finalisant vos s\xE9lections, nous pourrons imm\xE9diatement envoyer les d\xE9tails de votre r\xE9gime \xE0 l\u2019assureur! Cela signifie que vous ne devrez pas attendre la fin de votre p\xE9riode d\u2019inscription pour consulter votre portefeuille.",
  FINALIZE_SELECTIONS_HEADER_COPY: "Souhaitez-vous acc\xE9der \xE0 vos avantages sociaux plus t\xF4t?",
  HEALTH_PROGRAM_LINK_COPY: "essayez l\u2019un de nos programmes de sant\xE9",
  WALLET_DESCRIPTION: "Votre portefeuille est l\u2019endroit o\xF9 vous pourrez acc\xE9der \xE0 vos avantages sociaux, soumettre des demandes et v\xE9rifier votre couverture. Vous pourrez soumettre des demandes pour frais engag\xE9s durant la p\xE9riode d\u2019inscription une fois que votre portefeuille sera activ\xE9. En attendant, {healthProgramLink}!",
  WALLET_WELCOME: "Bienvenue dans votre portefeuille!",
  LAST_DAY_OF_ENROLLMENT: "Dernier jour de la p\xE9riode d\u2019inscription : {lastDay}",
  INTERNAL_ERROR_CTA: "Retour",
  INTERNAL_ERROR_PROMPT: "C\u2019est une bonne chose que nous sauvegardions toutes vos informations d\xE8s que vous les ajoutez.",
  ADD_DEPENDENT_CTA: "Ajouter une autre personne \xE0 charge",
  FOR_INSURED_BENEFITS_4: "Pour de plus amples informations, veuillez vous r\xE9f\xE9rer \xE0 la brochure de couverture des avantages sociaux de votre groupe aupr\xE8s de votre compagnie d\u2019assurance",
  FOR_INSURED_BENEFITS_3: "Il peut \xE9galement exister d\u2019autres restrictions pour les personnes \xE0 charge, notamment l\u2019\xE2ge, ou la situation g\xE9ographique. Un enfant peut par exemple \xEAtre couvert par vos avantages sociaux s\u2019il a moins de {validChildAge} ou de {validFullTimeStudentAge} s\u2019il est \xE9tudiant \xE0 plein temps.",
  FOR_INSURED_BENEFITS_2: "D\xE9pend de vous pour une aide financi\xE8re",
  FOR_INSURED_BENEFITS_1: "Votre conjoint, votre conjoint de fait, votre enfant ou l'enfant de votre conjoint ou de votre conjoint de fait",
  FOR_INSURED_BENEFITS: "Pour les garanties assur\xE9es",
  WHO_QUALIFIES_CHILD_TEXT_US: "Un <strong>enfant</strong> est un enfant biologique, un enfant adopt\xE9 l\xE9galement, un enfant en famille d'accueil, un enfant en tutelle l\xE9gale ou un enfant de votre partenaire domestique de 25 ans ou moins. Si vous \xEAtes l\xE9galement responsable d'une autre personne et que vous avez des questions sur son admissibilit\xE9, veuillez communiquer avec nous \xE0 help@league.com et nous vous aiderons \xE0 d\xE9terminer si elle est admissible \xE0 s'inscrire.",
  WHO_QUALIFIES_PARTNER_TEXT_US: "Un <strong>partenariat domestique</strong> est un couple non mari\xE9, quel que soit le sexe, enregistr\xE9 aupr\xE8s d'un minist\xE8re gouvernemental local ou d'un \xC9tat. Votre assureur peut offrir des garanties aux partenaires domestiques et \xE0 leurs enfants. Les garanties fournies aux partenaires domestiques peuvent faire l'objet d'un traitement fiscal suppl\xE9mentaire ou diff\xE9rent en vertu de la l\xE9gislation f\xE9d\xE9rale ou \xE9tatique. Veuillez consulter votre fiscaliste ou votre juriste pour en savoir plus sur la fa\xE7on dont les lois f\xE9d\xE9rales et \xE9tatiques seront appliqu\xE9es dans le cadre de ce choix.",
  WHO_QUALIFIES_SPOUSE_TEXT_US: "Un <strong>conjoint</strong> est la personne \xE0 laquelle vous \xEAtes mari\xE9 l\xE9galement en vertu la loi f\xE9d\xE9rale.",
  WHO_QUALIFIES_DEPENDENT_TEXT_CA: "Pour avoir le droit d'utiliser vos avantages sociaux League, la personne \xE0 charge doit remplir les crit\xE8res suivants :",
  WHO_QUALIFIES: "Qui peut \xEAtre admis?",
  WHO_QUALIFIES_DEPENDENT_PLAIN: "Qui peut \xEAtre admis comme personne \xE0 charge?",
  WHO_QUALIFIES_DEPENDENT_TITLE: "Qui peut \xEAtre admis comme personne \xE0 charge?",
  WHO_QUALIFIES_DEPENDENT_TOOLTIP_TITLE: "qui peut \xEAtre admis comme personne \xE0 charge?",
  PLEASE_NOTE_NO_DEPENDENT: "Veuillez noter que si vous n\u2019avez pas de personne \xE0 charge, vous pouvez laisser cette section vide. Ces champs sont obligatoires uniquement si vous entrez les informations relatives \xE0 votre personne \xE0 charge. Vous pouvez quoi qu\u2019il en soit sauvegarder votre profil et passer aux \xE9tapes suivantes.",
  CHILD_OF_DOMESTIC_PARTNER_TOOLTIP: "Nous vous posons cette question pour d\xE9terminer le lien entre vous et votre personne \xE0 charge. Cette information sera transmise \xE0 votre compagnie d'assurance.",
  DEPENDENTS_CHILD_DISABLED_TOOLTIP: "Un enfant est consid\xE9r\xE9 handicap\xE9 s\u2019il n\u2019est pas capable de subvenir \xE0 ses besoins ou d\u2019exercer une activit\xE9 professionnelle. Il est consid\xE9r\xE9 handicap\xE9 s\u2019il est d\xE9pendant de vous, le membre, pour une aide financi\xE8re ou des soins, dans le cadre des dispositions de la Loi de l\u2019imp\xF4t sur le revenu, en raison d\u2019un handicap mental ou physique.",
  DEPENDENTS_CHILD_FULL_TIME_STUDENT_TOOLTIP: "Les personnes \xE0 charge \xE2g\xE9es de {validChildAge} \xE0 {validFullTimeStudentAge} sont admissibles \xE0 la couverture des avantages s'ils sont \xE9tudiants \xE0 temps plein au moment de l'adh\xE9sion.",
  DEPENDENTS_PARTNER_EFFECTIVE_DATE_TOOLTIP: "Nous avons besoin de cette information pour pouvoir d\xE9terminer la dur\xE9e de votre relation avec votre personne \xE0 charge et la transmettre \xE0 votre compagnie d\u2019assurance.",
  DEPENDENTS_PARTNER_RELATIONSHIP_TYPE_TOOLTIP: "Nous avons besoin de cette information pour pouvoir d\xE9terminer la relation entre vous et votre personne \xE0 charge et la transmettre \xE0 votre compagnie d\u2019assurance. ",
  REMOVING_DEPENDENT_TEXT_CONTINUED: "\xCAtes-vous certain de vouloir supprimer {name} de vos personnes \xE0 charge?",
  REMOVING_DEPENDENT_TEXT: "En supprimant {name} de vos personnes \xE0 charge, toutes vos s\xE9lections pr\xE9c\xE9dentes seront effac\xE9es. Vous devrez repasser \xE0 travers tous les choix de garanties et refaire une s\xE9lection.",
  REMOVING_DEPENDENT: "Suppression de la personne a charge",
  INTERNAL_ERROR_TITLE: "Hum, une erreur est survenue.",
  DEPENDENTS_TOBACCO_USER_TOOLTIP: "Nous avons besoin de cette information car il s\u2019agit d\u2019un facteur d\xE9terminant pour le calcul de vos primes d\u2019assurance-vie. Vous pouvez modifier ce statut \xE0 une date ult\xE9rieure en nous contactant si celui-ci change.",
  DEPENDENTS_SEX_TOOLTIP: "La compagnie d\u2019assurance avec qui nous collaborons a besoin de conna\xEEtre le sexe actuel de votre personne \xE0 charge afin d'administrer ses avantages sociaux (remarque\xA0: si celle-ci est en processus de transition, la compagnie n\u2019a pas besoin des documents officiels relatifs \xE0 la transition). Cette information ne sera pas divulgu\xE9e en dehors de la compagnie d\u2019assurance. Notre \xE9quipe s\u2019engage \xE0 garantir la s\xE9curit\xE9 et le respect de tous les membres.",
  DEPENDENTS_SSN_TOOLTIP: "Nous avons besoin de cette information pour la transmettre au fournisseur d\u2019assurance avec qui nous collaborons afin qu\u2019il inscrive la personne \xE0 charge \xE0 votre r\xE9gime.",
  PROFILE_SSN_TOOLTIP: "Nous avons besoin de cette information car le fournisseur d\u2019assurance avec qui nous collaborons la requiert pour votre inscription \xE0 votre r\xE9gime.",
  PROFILE_SEX_TOOLTIP: "La compagnie d\u2019assurance avec qui nous collaborons a besoin de conna\xEEtre votre sexe afin d'administrer vos avantages sociaux. <br/><br/>Notre \xE9quipe s\u2019engage \xE0 garantir la s\xE9curit\xE9 et le respect de tous les membres. Si vous souhaitez nous fournir des pr\xE9cisions sur votre identit\xE9 sexuelle, veuillez remplir les champs du formulaire ci-dessous pour ajouter votre <strong>identit\xE9 sexuelle et vos pronoms.</strong>",
  PROFILE_TOBACCO_USER_TOOLTIP: "Nous avons besoin de cette information car il s\u2019agit d\u2019un facteur d\xE9terminant pour le calcul des primes d\u2019assurance.<br/><br/>Utiliser du tabac signifie que (au cours des 12 derniers mois) vous avez fum\xE9 ou utilis\xE9 des cigarettes, des cigarettes \xE9lectroniques, du haschich, des cigares, des pipes, des cigarillos, du tabac \xE0 chiquer, des patchs et / ou de la gomme \xE0 la nicotine, des noix de b\xE9tel ou du tabac, ou de la nicotine dans autre forme.",
  PROFILE_LOCALE_TOOLTIP: "Nous avons besoin de cette information pour vous envoyer des renseignements dans la langue que vous avez choisie.",
  PROFILE_DOB_TOOLTIP: "Nous avons besoin de cette information obligatoire pour pouvoir la transmettre \xE0 votre fournisseur d\u2019assurance. Parfois, les prix ou l\u2019admissibilit\xE9 pour certains produits et services d\u2019assurance peuvent d\xE9pendre de l\u2019\xE2ge.",
  PROFILE_INDIAN_ACT_TOOLTIP: "Votre compagnie d\u2019assurance a besoin de savoir si vous \xEAtes inscrit en vertu de la Loi sur les Indiens afin de calculer vos taxes.",
  WHY_DO_YOU_ASK: "Pourquoi avez-vous besoin de cette information?",
  NO: "Non",
  YES: "Oui",
  DOMESTIC_PARTNERSHIP: "Partenariat domestique",
  SINGLE: "C\xE9libataire",
  MARRIED: "Mari\xE9",
  FEMALE: "Femme",
  MALE: "Homme",
  FRENCH: "Fran\xE7ais",
  ENGLISH: "Anglais",
  WYOMING: "Wyoming",
  WISCONSIN: "Wisconsin",
  WEST_VIRGINIA: "Virginie-Occidentale",
  WASHINGTON: "Washington",
  VIRGINIA: "Virginie",
  VERMONT: "Vermont",
  UTAH: "Utah",
  US_VIRGIN_ISLANDS: "\xCEles Vierges des \xC9tats-Unis",
  TEXAS: "Texas",
  TENNESSEE: "Tennessee",
  SOUTH_DAKOTA: "Dakota du Sud",
  SOUTH_CAROLINA: "Caroline du Sud",
  RHODE_ISLAND: "Rhode Island",
  PUERTO_RICO: "Porto Rico",
  PENNSYLVANIA: "Pennsylvanie",
  OREGON: "Oregon",
  OKLAHOMA: "Oklahoma",
  OHIO: "Ohio",
  NORTH_DAKOTA: "Dakota du Nord",
  NORTH_CAROLINA: "Caroline du Nord",
  NEW_YORK: "New York",
  NEW_MEXICO: "Nouveau-Mexique",
  NEW_JERSEY: "New Jersey",
  NEW_HAMPSHIRE: "New Hampshire",
  NEVADA: "Nevada",
  NEBRASKA: "Nebraska",
  MONTANA: "Montana",
  MISSOURI: "Missouri",
  MISSISSIPPI: "Mississippi",
  MINNESOTA: "Minnesota",
  MICHIGAN: "Michigan",
  MASSACHUSETTS: "Massachusetts",
  MARYLAND: "Maryland",
  MAINE: "Maine",
  LOUISIANA: "Louisiane",
  KENTUCKY: "Kentucky",
  KANSAS: "Kansas",
  IOWA: "Iowa",
  INDIANA: "Indiana",
  ILLINOIS: "Illinois",
  IDAHO: "Idaho",
  HAWAII: "Hawaii",
  GUAM: "Guam",
  GEORGIA: "G\xE9orgie",
  FLORIDA: "Floride",
  DISTRICT_OF_COLUMBIA: "District de Columbia\u200E",
  DELAWARE: "Delaware",
  CONNECTICUT: "Connecticut",
  COLORADO: "Colorado",
  CALIFORNIA: "Californie",
  ARKANSAS: "Arkansas",
  ARIZONA: "Arizona",
  AMERICAN_SAMOA: "Samoa am\xE9ricaines",
  ALASKA: "Alaska",
  ALABAMA: "Alabama",
  YUKON: "Yukon",
  NUNAVUT: "Nunavut",
  NORTHWEST_TERRITORIES: "Territoires du Nord-Ouest",
  SASKATCHEWAN: "Saskatchewan",
  QUEBEC: "Qu\xE9bec",
  PRINCE_EDWARD_ISLAND: "\xCEle-du-Prince-\xC9douard",
  ONTARIO: "Ontario",
  NOVA_SCOTIA: "Nouvelle-\xC9cosse",
  NEWFOUNDLAND_LABRADOR: "Terre-Neuve et Labrador",
  NEW_BRUNSWICK: "Nouveau-Brunswick",
  MANITOBA: "Manitoba",
  BRITISH_COLUMBIA: "Colombie-Britannique",
  ALBERTA: "Alberta",
  PROFILE_DEPENDENTS_SECTION_TITLE: "Dites-nous en un peu plus sur vos proches!",
  WELLNESS_ACCOUNTS: "Comptes bien-\xEAtre",
  WAIVE: "Renoncer",
  IS_DEPENDENT_DISABLED: "Est-ce que votre personne \xE0 charge est invalide?",
  IS_DEPENDENT_FULL_TIME_STUDENT: "Est-ce que votre personne \xE0 charge est \xE9tudiant \xE0 temps plein?",
  RELATIONSHIP_EFFECTIVE_DATE: "Date de d\xE9but de la relation",
  RELATIONSHIP_TYPE: "Type de relation",
  ADDRESS_LINE_2: "Adresse ligne 2",
  ADDRESS_LINE_1: "Adresse ligne 1",
  SUITE_NUMBER: "Num\xE9ro d'appartement",
  STREET_ADDRESS: "Nom de la rue",
  ARE_YOU_REGISTERED_INDIAN_ACT: "D\xE9tenez-vous le statut de Premi\xE8res Nations?",
  DOES_YOUR_PARTNER_USE_TOBACCO: "Votre partenaire utilise-t-il des produits \xE0 base de tabac?",
  DO_YOU_USE_TOBACCO: "Utilisez-vous des produits \xE0 base de tabac?",
  LANGUAGE_PREFERENCE: "Langue de pr\xE9f\xE9rence",
  NO_DEPENDENT_SSN: "Mon {dependentType} n'a pas de NSS",
  SSN: "Num\xE9ro d'assurance sociale",
  SIN: "NAS",
  SIN_OR_SSN: "Num\xE9ro d'assurance sociale (NAS)",
  THIS_INFO_WILL_NOT_BE_SHARED: "Cette information est confidentielle et ne sera pas partag\xE9e.",
  INFORMATION_NOT_SHARED_WITH_EMPLOYER: "Cette information ne sera pas divulgu\xE9e \xE0 votre employeur et/ou assureur(s).",
  LEAGUE_SUPPORTS_GENDER_EXPRESSION: "League soutient l\u2019expression sexuelle et voudrait savoir comment vous vous identifiez",
  PRONOUNS_TOOLTIP: "Ajoutez vos pronoms afin que nous sachions comment nous adresser \xE0 vous!<br/><br/>Par exemple\xA0: Si le pronom d\u2019Alex est ils, nous pourrions dire \xAB\u2009Alex aime les massages, ils utilisent tout le temps leur compte de d\xE9penses bien-\xEAtre pour r\xE9server des massages.\u2009\xBB",
  PRONOUNS: "Pronoms",
  GENDER_IDENTITY_TOOLTIP: "League soutient l\u2019expression sexuelle et voudrait savoir comment vous vous identifiez.<br/><br/>L\u2019identit\xE9 sexuelle est le sentiment personnel de votre propre sexualit\xE9. Il peut correspondre \xE0 votre sexe ou non.",
  GENDER_IDENTITY: "Identit\xE9 sexuelle",
  SEX: "Sexe",
  DATE_OF_BIRTH: "Date de naissance",
  MIDDLE_INITIAL: "Deuxi\xE8me pr\xE9nom ou initiale",
  PREFERRED_NAME_TOOLTIP: "Vous utilisez un autre nom? Nous nous assurerons de nous en souvenir et de l'utiliser aussi souvent que possible.",
  PREFERRED_NAME_PLACEHOLDER: "Si diff\xE9rent de votre pr\xE9nom",
  WHAT_SHOULD_WE_CALL_YOU: "Comment devrions-nous vous appeler?",
  PREFERRED_FIRST_NAME: "Pr\xE9nom pr\xE9f\xE9r\xE9",
  LAST_NAME: "Nom de famille",
  LEGAL_FIRST_NAME: "Pr\xE9nom l\xE9gal",
  EMPLOYEE_ASSISTANCE_PROGRAM: "Programme d'aide aux employ\xE9s",
  NO_SELECTION_MADE: "Aucune s\xE9lection effectu\xE9e",
  YOUR_ALLOCATION: "Votre allocation",
  WAIVED: "Renonc\xE9",
  TRACKER_WELLNESS_POOL_TOOLTIP: `La r\xE9serve bien-\xEAtre est un montant suppl\xE9mentaire \xE0 "d\xE9penser" que {company} vous a fourni. \xC0 mesure que vous progresserez dans l'inscription des avantages sociaux, ce montant devrait diminuer.`,
  TRACKER_FLEX_DOLLAR_TOOLTIP: `Les dollars flexibles sont un montant \xE0 "d\xE9penser" que {company} vous fournit. \xC0 mesure que vous progresserez dans l'inscription des avantages sociaux, ce montant devrait diminuer.`,
  COST_PER_PAY_PERIOD: "<strong>{price}</strong> / {periodType}",
  ANNUAL_COST_PRICE: "Co\xFBt annuel de votre r\xE9gime <strong>{price}</strong>",
  COVERAGE_PLAN_SELECTION: "S\xE9lection du r\xE9gime",
  ENROLLMENT_STEP: "\xC9tape",
  ADDITIONAL_BENEFITS: "Avantages sociaux additionnels",
  VIEW_ADDITIONAL_BENEFITS: "Voir des garanties suppl\xE9mentaires",
  ADDITIONAL_BENEFITS_CARD_TITLE: "Attendez, il y a plus!",
  TOTAL_OUT_OF_POCKET: "Co\xFBt annuel total d\xE9bours\xE9 par vous",
  TOTAL_COVERAGE_COST: "Co\xFBt total de la couverture",
  TOTAL: "Total",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_TOOLTIP: "Si on diagnostique une maladie grave couverte chez votre enfant, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous.",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_SUBHEADING: "S\xE9lectionnez le montant de couverture pour votre assurance contre les maladies graves pour enfants",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "L'assurance contre les maladies graves pour enfants vous offre une couverture financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 votre enfant se verrait diagnostiquer une maladie grave couverte.",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "L'assurance contre les maladies graves pour enfants vous offre une couverture financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 votre enfant se verrait diagnostiquer une maladie grave couverte sous votre r\xE9gime. Vous devez ajouter un enfant? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_TOOLTIP: "Si votre conjoint ou partenaire se voit diagnostiquer une maladie grave couverte sous votre r\xE9gime, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous.\n\nL'assurance contre les maladies graves du conjoint requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant que votre conjoint ou partenaire soit approuv\xE9 pour la couverture additionnelle.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_SUBHEADING: "S\xE9lectionnez le montant de couverture de votre assurance contre les maladies graves du conjoint",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "L'assurance contre les maladies graves du conjoint vous offre une couverture financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 votre conjoint ou partenaire se verrait diagnostiquer une maladie grave couverte.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "L'assurance contre les maladies graves du conjoint offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre conjoint ou partenaire se verrait diagnostiquer une maladie grave couverte sous votre r\xE9gime. Vous devez ajouter votre conjoint ou partenaire? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_CRITICAL_ILLNESS_OPTIONAL_TOOLTIP: "Si on vous diagnostique une maladie grave couverte, vous recevrez un versement forfaitaire \xE9quivalent au montant de couverture s\xE9lectionn\xE9 ci-dessous.\n\nL'assurance contre les maladies graves requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant votre approbation pour la couverture additionnelle. ",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION_US_PT2: "Il est important pour vous de savoir que l'assurance contre les maladies graves est :<ul><li>Offerte \xE0 tous les employ\xE9s actifs aux \xC9tats-Unis qui travaillent au moins 20 heures par semaine ainsi qu'\xE0 leur conjoint et leurs enfants admissibles (jusqu'\xE0 l'\xE2ge de 26 ans, peu importe leur statut d'\xE9tudiant ou leur \xE9tat civil).</li><li>Un suppl\xE9ment \xE0 l'assurance sant\xE9 - Elle ne remplace pas l'assurance maladie de base ni la couverture minimale telle que d\xE9finie dans la loi f\xE9d\xE9rale. Si vous, et chaque personne pour laquelle la protection est s\xE9lectionn\xE9e, n'avez pas d'assurance maladie de base ou de couverture minimale, vous n'\xEAtes pas admissible \xE0 cette couverture.</li></ul><br/>Consultez les d\xE9tails du r\xE9gime ci-dessous pour obtenir de plus amples renseignements, y compris l'avis et le guide Medicare.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION_US_PT1: "L'assurance contre les maladies graves peut offrir un versement forfaitaire en cas de maladies ou d'affections couvertes. Vous pouvez utilisez l'argent vers\xE9 comme vous le souhaitez.<br/><br/>",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION: "L'assurance contre les maladies graves est une importante protection financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 vous ou les personnes \xE0 votre charge couvertes se verraient diagnostiquer une maladie grave admissible, par exemple : Alzheimer, le cancer, Parkinson, etc.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_SELECTOR_TOOLTIP_US: "La prestation peut vous aider \xE0 payer pour les d\xE9penses m\xE9dicales \xE0 votre charge comme les d\xE9ductibles et les quotes-parts. De plus, vous pouvez utiliser cette couverture plus d'une fois. Donc, m\xEAme si vous recevez une somme pour une maladie grave, vous \xEAtes toujours couverts pour les conditions restantes.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_SELECTOR_TOOLTIP: "Si on vous diagnostique une maladie grave couverte sous votre r\xE9gime, vous recevrez un versement forfaitaire \xE9quivalent au montant de couverture s\xE9lectionn\xE9 ci-dessous.",
  CRITICAL_ILLNESS: "Maladie grave",
  CRITICAL_ILLNESS_INSURANCE_TITLE: "Assurance contre les maladies graves",
  COVERAGE_AMOUNT: "Montant de la couverture",
  COVERAGE_TYPE: "Type de couverture",
  LEARN_MORE_ABOUT_PLAN_DETAILS: "En savoir plus sur les d\xE9tails du r\xE9gime {planName}",
  PLAN_NAME_DETAILS: "D\xE9tails de {planName}",
  LEARN_MORE_ABOUT_FIND_IN_NETWORK_PROVIDERS: "En savoir plus sur la fa\xE7on de trouver les prestataires du r\xE9seau",
  FIND_IN_NETWORK_PROVIDERS: "Trouver des fournisseurs \xE0 l'int\xE9rieur du r\xE9seau",
  PLAN_DETAILS: "D\xE9tails du r\xE9gime",
  BENEFIT_PRICE_ERROR: "D\xE9sol\xE9! Une erreur est survenue lors de l'obtention du prix pour cette prestation. Veuillez contacter notre \xE9quipe de conseillers pour r\xE9soudre ce probl\xE8me.",
  VOLUME_PREREQUISITE_NOT_MET: "L'assurance {benefitName} vous est offerte seulement si vous \xEAtes inscrit \xE0 {prerequisiteName}",
  VOLUME_INELIGIBLE_DEFAULT: "Vous n'\xEAtes pas admissible pour cette garantie : {reason}",
  VOLUME_DOCUMENT_LINK_LABEL: "D\xE9tails de {benefitName}",
  PLAN_COST: "Co\xFBt du r\xE9gime",
  INSURABLE_EARNINGS_TOOLTIP_LTD: "Le montant re\xE7u pour vous prot\xE9ger contre la perte de revenus pendant une invalidit\xE9 de longue dur\xE9e correspond \xE0 un pourcentage de vos gains mensuels.",
  INSURABLE_EARNINGS_TOOLTIP_STD: "Le montant re\xE7u pour vous prot\xE9ger contre la perte de revenus pendant une invalidit\xE9 de courte dur\xE9e correspond \xE0 un pourcentage de vos gains hebdomadaires.",
  INSURABLE_EARNINGS_TOOLTIP: "Le montant de prestation re\xE7u pendant l''{benefitName} sera bas\xE9 sur vos gains assurables.",
  INSURABLE_EARNINGS_AMOUNT: "Gains annuels",
  INSURABLE_EARNINGS_SUBTITLE: "Vos gains annuels",
  PLAN_SELECTOR_DESCRIPTION: "L'assurance {benefitName} garantit le maintient de votre paie dans l'\xE9ventualit\xE9 o\xF9 vous seriez incapable de travailler suite \xE0 une {benefitNameLowered}.",
  PLAN_SELECTOR_DESCRIPTION_LTD: "L'assurance invalidit\xE9 de longue dur\xE9e (ILD) assure votre protection financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 vous seriez incapable de travailler pour une dur\xE9e prolong\xE9e suite \xE0 une maladie, une blessure ou un accident. La couverture d'invalidit\xE9 de longue dur\xE9e commence lorsque la p\xE9riode d'invalidit\xE9 de courte dur\xE9e se termine, selon votre admissibilit\xE9 et votre situation.",
  PLAN_SELECTOR_DESCRIPTION_STD: "L'assurance invalidit\xE9 de courte dur\xE9e (ICD) assure votre protection financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 vous seriez temporairement incapable de travailler suite \xE0 une maladie, une blessure ou un accident.",
  PLAN_SELECTOR_HEADING: "Votre couverture : {benefitName}",
  PLANS_EXAMPLE: "Or, Argent, Bronze",
  PLANS_EXPLANATION: "Les r\xE9gimes sont utilis\xE9s pour s\xE9parer les diff\xE9rents types de garanties",
  CLASSES_EXAMPLE: "Temps plein, temps partiel et associ\xE9",
  CLASSES_EXPLANATION: "Diff\xE9rentes classes sont utilis\xE9es pour diviser les employ\xE9s en diff\xE9rentes cat\xE9gories",
  LTD_PLAN_SELECTOR_DESCRIPTION: "Vous personnalisez votre r\xE9gime d'invalidit\xE9 de longue dur\xE9e",
  LTD_PLAN_SELECTOR_TITLE: "Invalidit\xE9 de longue dur\xE9e",
  STD_PLAN_SELECTOR_DESCRIPTION: "Vous personnalisez votre r\xE9gime d'invalidit\xE9 de longue dur\xE9e",
  STD_PLAN_SELECTOR_TITLE: "Invalidit\xE9 de courte dur\xE9e",
  MEDICAL_PLAN_SELECTOR_TITLE: "Assurance maladie",
  VISION_PLAN_SELECTOR_TITLE: "Assurance pour soins de la vue",
  DENTAL_PLAN_SELECTOR_TITLE: "Assurance dentaire",
  DRUG_PLAN_SELECTOR_TITLE: "Assurance m\xE9dicaments",
  BEST_MATCH: "Meilleure correspondance",
  PER_PAY_PERIOD_DEDUCTION: "{deduction} / {periodType}",
  ANNUAL_DEDUCTION: "{deduction} annuellement",
  PROVIDED_BY: "Fourni par",
  PROVIDED_BY_VENDOR: "Fourni par {vendor}",
  PREVIOUSLY_SELECTED_PLAN_TOOLTIP_CONTENT: "Voici le r\xE9gime choisi durant votre derni\xE8re inscription",
  BENEFICIARIES_INFO_BANNER_CARD_TOOLTIP: "<p>Un b\xE9n\xE9ficiaire est une personne ou une entit\xE9 que vous choisissez pour toucher les paiements de votre assurance si vous d\xE9c\xE9dez. </p><p>Il est tr\xE8s important que vous d\xE9signiez vos b\xE9n\xE9ficiaires afin que l\u2019argent revienne \xE0 la ou les bonne(s) personne(s) lors de votre d\xE9c\xE8s. Sans b\xE9n\xE9ficiaires, le versement des fonds peut \xEAtre retard\xE9 et ils peuvent \xEAtre soumis \xE0 des frais co\xFBteux et inutiles. </p><p>Nous vous montrerons apr\xE8s l\u2019adh\xE9sion comment d\xE9signer un b\xE9n\xE9ficiaire si vous n\u2019en avez pas d\xE9j\xE0 un.</p>",
  LEARN_MORE_ABOUT_BENEFICIARIES: "En savoir plus sur les b\xE9n\xE9ficiaires",
  DESIGNATE_BENEFICIARIES: "designer des b\xE9n\xE9ficiaires",
  BENEFICIARIES_INFO_BANNER_CARD: "Lorsque vous adh\xE9rez \xE0 certaines prestations, vos personnes \xE0 charge peuvent ne pas \xEAtre enti\xE8rement prot\xE9g\xE9es si vous ne {designateBeneficiariesTooltip}. Vous pouvez ajouter ou mettre \xE0 jour vos b\xE9n\xE9ficiaires apr\xE8s avoir finalis\xE9 l\u2019adh\xE9sion.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_TOOLTIP: "Si votre enfant subit une blessure accidentelle admissible, l'assurance accident pour enfants vous verse une prestation bas\xE9e sur le montant de couverture s\xE9lectionn\xE9 ainsi que sur la nature de la blessure.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "L'assurance accident pour enfants offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre enfant subirait un accident ou une blessure. Vous devez ajouter un enfant? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "L'assurance accident pour enfants offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre enfant subirait un accident ou une blessure.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_SUBHEADING: "S\xE9lectionnez le montant de couverture pour l'assurance accident de vos enfants",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_TOOLTIP: "Si votre conjoint ou partenaire subit une blessure accidentelle admissible, l'assurance accident du conjoint vous versera une prestation bas\xE9e sur le montant de couverture s\xE9lectionn\xE9 ainsi que sur la nature de la blessure.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "L'assurance accident du conjoint offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre conjoint subirait un accident ou une blessure. Vous devez ajouter votre conjoint ou partenaire? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "L'assurance accident du conjoint offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre conjoint ou partenaire subirait un accident ou une blessure.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_SUBHEADING: "S\xE9lectionnez votre montant de couverture pour l'assurance accident de votre conjoint",
  VOLUME_PLAN_ACCIDENT_OPTIONAL_TOOLTIP: "L'assurance accident facultative s'ajoute \xE0 votre couverture en cas d'accident afin de r\xE9duire l'incidence financi\xE8re potentiellement d\xE9vastatrice de la perte de votre revenu pour votre famille.",
  VOLUME_PLAN_ACCIDENT_BASE_SELECTOR_TOOLTIP_US: "L'assurance accident vous aide \xE0 payer pour les frais m\xE9dicaux ainsi que pour d'autres co\xFBts d\xE9bours\xE9s par vous suite \xE0 une blessure accidentelle. Par exemple, la couverture comprend les traitements d'urgence, l'hospitalisation, les examens m\xE9dicaux ainsi que d'autres frais aff\xE9rents comme le transport et les besoins d'h\xE9bergement.",
  VOLUME_PLAN_ACCIDENT_BASE_SELECTOR_TOOLTIP: "Si vous subissez une blessure admissible selon votre couverture, vous recevrez une prestation. Si vous d\xE9c\xE9dez dans un accident, c'est votre b\xE9n\xE9ficiaire qui recevra la prestation. Le montant re\xE7u d\xE9pend du montant de couverture et de l'\xE9tendue de la perte caus\xE9e par l'accident.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION_US_PT2: "La couverture prend en compte les accidents survenus au travail ainsi qu'\xE0 l'ext\xE9rieur du travail et inclut les blessures communes ainsi que des \xE9v\xE9nements plus s\xE9rieux. Passez en revue votre r\xE9gime pour plus d'informations.<br/><br/>En s\xE9lectionnant cette couverture, vous confirmez que :<ul><li>Vous avez pris connaissance de la documentation juridique et de conformit\xE9 ci-jointe (dans les d\xE9tails du r\xE9gime)</li><li>Vous \xEAtes employ\xE9 et travaillez r\xE9guli\xE8rement</li><li>Si vous s\xE9lectionnez la couverture conjointe, vous confirmez que votre conjoint(e) ou partenaire demeure actuellement aux \xC9tats-Unis</li></ul>",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION_US_PT1: "L'assurance accident peut payer un montant pr\xE9-\xE9tabli bas\xE9 sur le type de blessure subie ainsi que sur le type de traitement dont vous avez besoin.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION: "L'assurance accident est une protection financi\xE8re importante pour vous et vos proches si vous \xEAtes s\xE9rieusement bless\xE9 suite \xE0 un accident ou si vous en d\xE9c\xE9dez. L'\xE9tendue de votre couverture d\xE9pend du montant s\xE9lectionn\xE9, de la s\xE9v\xE9rit\xE9 et de la nature de l'accident.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_TOOLTIP_EOI_REQUIRED: "Dans l'\xE9ventualit\xE9 o\xF9 l'un de vos enfants d\xE9c\xE9derait, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous. Dans le cas d'une r\xE9clamation pour assurance vie et DMA pour enfants, vous \xEAtes le b\xE9n\xE9ficiaire.\n\nL'assurance vie et DMA pour enfants requiert une preuve d'assurabilit\xE9. Ceci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette garantie avant l'approbation de vos enfants admissibles pour la couverture suppl\xE9mentaire.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_TOOLTIP: "Dans l'\xE9ventualit\xE9 o\xF9 l'un de vos enfants d\xE9c\xE8derait, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous. Dans le cas d'une r\xE9clamation pour assurance vie et DMA pour enfants, vous \xEAtes le b\xE9n\xE9ficiaire.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "L'assurance vie et DMA pour enfants vous offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre ou vos enfants d\xE9c\xE9deraient. S\xE9lectionner l'assurance vie et DMA pour enfants assure la couverture de tous vos enfants admissibles. Vous devez ajouter vos enfants? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "L'assurance vie et DMA pour enfants vous offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre ou vos enfants d\xE9c\xE9deraient. S\xE9lectionner l'assurance vie et DMA pour enfants assure la couverture de tous vos enfants admissibles.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_SUBHEADING: "S\xE9lectionnez votre montant de couverture d'assurance vie et DMA pour enfants",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_TOOLTIP: "Dans l'\xE9ventualit\xE9 du d\xE9c\xE8s de votre conjoint ou partenaire, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous. Dans le cas d'une demande d'assurance vie et DMA de votre conjoint, vous \xEAtes le b\xE9n\xE9ficiaire.\n\nL'assurance vie et DMA de votre conjoint requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette garantie avant l'approbation de votre conjoint ou partenaire pour la couverture additionnelle.",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_SUBHEADING: "S\xE9lectionnez votre montant de couverture d'assurance-vie et DMA du conjoint",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "L'assurance-vie et DMA du conjoint vous offre une couverture dans l'\xE9ventualit\xE9 du d\xE9c\xE8s de votre conjoint ou partenaire. Vous devez ajouter votre conjoint ou partenaire? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "L'assurance-vie et DMA conjointe vous offre une couverture dans l'\xE9ventualit\xE9 du d\xE9c\xE8s de votre conjoint ou partenaire.",
  VOLUME_PLAN_LIFE_AND_ADD_OPTIONAL_SELECTOR_TOOLTIP: "L'assurance {benefitName} s'ajoute \xE0 votre couverture d'assurance-vie obligatoire de base afin de r\xE9duire l'incidence financi\xE8re potentiellement d\xE9vastatrice de la perte de votre revenu pour votre famille.\n\nL'assurance {benefitName} requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant l'approbation de la couverture additionnelle.\n\nSi, malheureusement, vous d\xE9c\xE9dez, votre b\xE9n\xE9ficiaire recevra un versement forfaitaire non imposable \xE9quivalent au montant de couverture pr\xE9sent\xE9 ci-dessous",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_SELECTOR_TOOLTIP: "L'assurance-vie, d\xE9c\xE8s et mutilation accidentelle (DMA) de base vous est offerte gratuitement. Vos b\xE9n\xE9ficiaires recevront un versement forfaitaire si vous d\xE9c\xE9dez accidentellement ou non ainsi que dans le cas d'une mutilation.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_DESCRIPTION_PT2: "Cette prestation vous offre une s\xE9curit\xE9 financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 vous d\xE9c\xE9deriez accidentellement ou non ainsi qu'en cas de mutilation. Passez en revue votre r\xE9gime pour plus d'informations.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "L'assurance-vie et DMA est une importante protection financi\xE8re pour vous et vos b\xE9n\xE9ficiaires.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_HEADING: "Votre couverture : {benefitName}",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_TOOLTIP_EOI_REQUIRED: "L'assurance DMA pour enfant vous offre une couverture dans le cas o\xF9 votre enfant subirait une blessure grave ou d\xE9c\xE9derait. <br/><br/> Afin de souscrire \xE0 l'assurance DMA pour enfant, vous devez fournir une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant que votre enfant admissible soit approuv\xE9 pour la couverture additionnelle.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_NO_CHILD_DESCRIPTION: "L'assurance DMA pour enfants offre une couverture dans l'\xE9ventualit\xE9 o\xF9 votre enfant subirait une blessure ou un d\xE9c\xE8s accidentelle. Vous devez ajouter un enfant? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HAS_CHILD_DESCRIPTION: "L'assurance DMA pour enfant offre une couverture dans le cas o\xF9 votre enfant subirait une blessure grave ou d\xE9c\xE9derait",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_SUBHEADING: "Choisissez la couverture d'assurance DMA appropri\xE9e pour votre enfant.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HEADING: "Assurance DMA pour enfant facultative",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_NO_SPOUSE_DESCRIPTION: "L'assurance DMA du conjoint vous offre une couverture dans l'\xE9ventualit\xE9 d'une blessure grave ou du d\xE9c\xE8s de votre conjoint ou partenaire. Vous devez ajouter votre conjoint? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HAS_SPOUSE_DESCRIPTION: "L'assurance DMA du conjoint vous offre une couverture dans l'\xE9ventualit\xE9 d'une blessure grave ou du d\xE9c\xE8s de votre conjoint ou partenaire.",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_SUBHEADING: "Choisissez la couverture d'assurance DMA appropri\xE9e pour votre conjoint ou partenaire",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HEADING: "Assurance DMA pour conjoint facultatif",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_OPTIONAL_HEADING: "Votre assurance DMA facultative",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_TOOLTIP: "Dans le cas o\xF9 vous seriez gravement bless\xE9 ou d\xE9c\xE9diez, votre ou vos b\xE9n\xE9ficiaires recevront une somme d'argent. <br/><br/> La somme re\xE7ue sera bas\xE9e sur le montant de la couverture que vous aurez s\xE9lectionn\xE9 et le pourcentage d\xE9pend du type d'accident ou de blessure couvert.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "Un d\xE9c\xE8s ou une blessure grave peuvent avoir des cons\xE9quences bouleversantes, et les d\xE9penses inattendues qui en d\xE9coulent peuvent causer encore plus de stress. Votre assurance DMA est une source importante de protection financi\xE8re pour vous et vos proches dans le cas o\xF9 vous seriez gravement bless\xE9 ou d\xE9c\xE9diez.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_HEADING: "Votre assurance DMA",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_AND_OPTIONAL_HEADING: "Votre couverture d'assurance DMA",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP_EOI_REQUIRED: "Si votre enfant d\xE9c\xE8de, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous. Dans le cas d'une r\xE9clamation d'assurance-vie pour enfants, vous \xEAtes le b\xE9n\xE9ficiaire.\n\nL'assurance-vie pour enfants requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant que vos enfants admissibles soient approuv\xE9s pour la couverture suppl\xE9mentaire.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP_US: "Si votre enfant assur\xE9 (comme d\xE9fini par le contrat d'assurance) d\xE9c\xE8de, le b\xE9n\xE9ficiaire recevra un versement forfaitaire \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP: "Si votre enfant d\xE9c\xE8de, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous. Dans le cas d'une r\xE9clamation d'assurance-vie pour enfants, vous \xEAtes le b\xE9n\xE9ficiaire.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "L'assurance-vie pour enfant vous offre une couverture dans l'\xE9ventualit\xE9 o\xF9 l'un de vos enfants d\xE9c\xE9dait. S\xE9lectionner l'assurance-vie pour enfants fournira une couverture pour tous vos enfants admissibles. Vous devez ajouter vos enfants? Dirigez-vous vers {dependentsLink} pour faire cette s\xE9lection.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "L'assurance-vie pour enfants vous offre une couverture dans l'\xE9ventualit\xE9 o\xF9 l'un de vos enfants d\xE9c\xE9derait. S\xE9lectionner l'assurance-vie pour enfants fournira une couverture pour tous vos enfants admissibles.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_SUBHEADING: "S\xE9lectionnez le montant de couverture de votre assurance-vie pour enfants",
  VOLUME_PLAN_CHILD_SELECTOR_HEADING: "Choisissez la couverture {benefitName} appropri\xE9e pour votre enfant.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_TOOLTIP_US: "Dans l'\xE9ventualit\xE9 o\xF9 votre conjoint ou partenaire d\xE9c\xE9dait, le ou les b\xE9n\xE9ficiaires recevront un versement forfaitaire \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_TOOLTIP: "Si, malheureusement, votre conjoint ou partenaire d\xE9c\xE9dait, vous recevrez un versement forfaitaire non imposable \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous. Lors d'une r\xE9clamation d'assurance-vie conjointe, vous \xEAtes le b\xE9n\xE9ficiaire.\n\nL'assurance-vie conjointe requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant que votre conjoint ou partenaire soit approuv\xE9 pour la couverture additionnelle.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_SUBHEADING: "S\xE9lectionnez le montant de couverture de l'assurance-vie de votre conjoint",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "L'assurance-vie du conjoint vous offre une couverture dans l'\xE9ventualit\xE9 du d\xE9c\xE8s de votre conjoint ou partenaire. Vous devez ajouter votre conjoint? Dirigez-vous vers {dependentsLink} pour effectuer cette s\xE9lection.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "L'assurance-vie du conjoint vous offre une couverture dans l'\xE9ventualit\xE9 du d\xE9c\xE8s de votre conjoint ou partenaire.",
  VOLUME_PLAN_SPOUSE_SELECTOR_HEADING: "Choisissez la couverture {benefitName} appropri\xE9e pour votre conjoint ou partenaire",
  VOLUME_PLAN_LIFE_OPTIONAL_SELECTOR_TOOLTIP_US: "Si, malheureusement, vous d\xE9c\xE9dez, vos b\xE9n\xE9ficiaires recevraient un versement forfaitaire \xE9quivalent au montant s\xE9lectionn\xE9 ci-dessous.",
  VOLUME_PLAN_LIFE_OPTIONAL_SELECTOR_TOOLTIP: "L'assurance {benefitName} s'ajoute \xE0 votre couverture d'assurance-vie obligatoire afin de r\xE9duire l'incidence financi\xE8re potentiellement d\xE9vastatrice de la perte de votre revenu pour votre famille.\n\nL'assurance {benefitName} requiert une preuve d'assurabilit\xE9. Celle-ci va d\xE9clencher un processus d'approbation et de s\xE9lection des risques aupr\xE8s de {vendorName}. Veuillez noter qu'aucun frais ne s'applique pour cette prestation avant l'approbation de la couverture additionnelle.\n\nSi, malheureusement, vous d\xE9c\xE9dez, votre b\xE9n\xE9ficiaire recevra un versement forfaitaire non imposable \xE9quivalent au montant de couverture pr\xE9sent\xE9 ci-dessous",
  VOLUME_PLAN_OPTIONAL_SELECTOR_HEADING: "Assurance {benefitName}",
  VOLUME_PLAN_LIFE_BASE_SELECTOR_TOOLTIP: "Si, malheureusement, vous d\xE9c\xE9dez, votre b\xE9n\xE9ficiaire recevra un versement forfaitaire non imposable \xE9quivalent au montant de couverture pr\xE9sent\xE9 ci-dessous",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_PT2: "Cette garantie offre une protection financi\xE8re dans l'\xE9ventualit\xE9 o\xF9 vous ou un membre de votre famille d\xE9c\xE9dait. Passez en revue votre r\xE9gime pour plus d'informations.",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "Une assurance-vie est une importante protection financi\xE8re pour votre famille.",
  VOLUME_PLAN_BASE_AND_OPTIONAL_TOOLTIP: "<p>Votre b\xE9n\xE9ficiaire est la personne ou l\u2019entit\xE9 que vous choisissez pour toucher les paiements de votre assurance si vous d\xE9c\xE9dez. </p><p>Il est tr\xE8s important que vous d\xE9signiez vos b\xE9n\xE9ficiaires afin que l\u2019argent revienne \xE0 la ou les bonne(s) personne(s) lors de votre d\xE9c\xE8s. Sans b\xE9n\xE9ficiaires, le versement des fonds peut \xEAtre retard\xE9 et ils peuvent \xEAtre soumis \xE0 des frais co\xFBteux et inutiles. </p><p>Nous vous montrerons apr\xE8s l\u2019adh\xE9sion comment d\xE9signer un b\xE9n\xE9ficiaire si vous n\u2019en avez pas d\xE9j\xE0 un.</p>",
  VOLUME_PLAN_BASE_AND_OPTIONAL_HEADING: "Votre couverture d'assurance {benefitName}",
  VOLUME_OPTIONS_EOI_GROUP_LABEL_WITH_NEM: "Les couvertures au-dessus de {noEvidenceMaximum} exigent un formulaire de preuve d\u2019admissibilit\xE9.",
  VOLUME_OPTIONS_EOI_GROUP_LABEL: "Toutes les couvertures exigent un formulaire de preuve d\u2019admissibilit\xE9.",
  ACCIDENT_INSURANCE_TITLE: "Assurance accident",
  OPTIONAL_LIFE: "vie facultative",
  CANT_OPT_OUT_INFO_EMPLOYER_PAID: "En tant qu'employ\xE9 valoris\xE9, <strong>{employerName}</strong> vous accorde cette garantie gratuitement.",
  CANT_OPT_OUT_INFO: "<strong>{employerName}</strong> vous oblige \xE0 vous adh\xE9rer \xE0 cette garantie dans le cadre de votre r\xE9gime d\u2019avantages sociaux.",
  CANT_OPT_OUT_TITLE: "Cette garantie est obligatoire",
  ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE_TITLE: "D\xE9c\xE8s & Mutilation Accidentels",
  LIFE_AND_ADD_INSURANCE_TITLE: "Assurance-vie, D\xE9c\xE8s et Mutilation Accidentels",
  LIFE_INSURANCE_TITLE: "Assurance-vie",
  ACCOUNT_TYPE_FINANCIAL_CONTRIBUTION: "apport financier",
  ACCOUNT_TYPE_SPENDING_ACCOUNT: "compte de d\xE9penses",
  ACCOUNT_TYPE_LTD: "ild",
  ACCOUNT_TYPE_STD: "icd",
  ACCOUNT_TYPE_ACCIDENT: "accident",
  ACCOUNT_TYPE_LIFE: "vie",
  ACCOUNT_TYPE_DENTAL: "dentaire",
  ACCOUNT_TYPE_MEDICAL: "Soins m\xE9dicaux",
  ACCOUNT_TYPE_DRUG: "m\xE9dicaments",
  ALL_DONE: "Vous avez termin\xE9?",
  SAVE_PROFILE: "Sauvegarder le profil",
  EAP: "PAE",
  FINANCIAL_CONTRIBUTIONS: "Apports financiers",
  SPENDING_ACCOUNTS: "Comptes de d\xE9penses",
  GROUP_UMBRELLA_LIABILITY: "Responsabilit\xE9 civile collective",
  GROUP_UMBRELLA_LIABILITY_INSURANCE: "Assurance responsabilit\xE9 civile collective",
  LONG_TERM_DISABILITY: "Invalidit\xE9 de longue dur\xE9e",
  LTD: "ILD",
  SHORT_TERM_DISABILITY: "Invalidit\xE9 de courte dur\xE9e",
  STD: "ICD",
  AD_AND_D: "DMA",
  ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE: "D\xE9c\xE8s & Mutilation Accidentels",
  ACCIDENT_INSURANCE: "Assurance accident",
  ACCIDENT: "Accident",
  LIFE_AND_ADD_INSURANCE: "Assurance vie et D\xE9c\xE8s & Mutilation Accidentels",
  LIFE_AND_ADD: "Assurance vie et DMA",
  LIFE_INSURANCE: "Assurance-vie",
  LIFE: "Vie",
  HOSPITAL_INDEMNITY: "Indemnit\xE9 d'hospitalisation",
  VISION_INSURANCE: "Assurance pour les soins de la vue",
  VISION: "Vue",
  DENTAL_INSURANCE: "Assurance dentaire",
  DENTAL: "Soins dentaires",
  MEDICAL_INSURANCE: "Assurance maladie",
  MEDICAL: "Soins m\xE9dicaux",
  DID_YOU_KNOW: "Saviez-vous?",
  DRUG_INSURANCE: "Assurance m\xE9dicaments",
  DRUG: "M\xE9dicaments",
  FLEX_BETWEEN_TEXT: " entre ",
  FLEX_JOIN_TEXT: " et/ou ",
  REDUCTION_IN_MONTHLY_DRAW: "R\xE9duction de la retenue mensuelle de",
  MONTHLY_PAYROLL_DEDUCTION: "D\xE9duction mensuelle sur la paie",
  SEMI_MONTHLY_PAYROLL_DEDUCTION: "D\xE9duction bi-mensuelle sur la paie",
  BI_WEEKLY_PAYROLL_DEDUCTION: "D\xE9duction bi-mensuelle sur la paie",
  WEEKLY_PAYROLL_DEDUCTION: "D\xE9duction hebdomadaire sur la paie",
  WEEKLY: "Hebdomadaire",
  YOU_PAY: "Vous payez",
  YOUR_BALANCE_UPDATED_AMOUNT: "Votre solde a \xE9t\xE9 mis \xE0 jour! {amount}",
  PAYROLL_DEDUCTION_TOAST: "D\xC9DUCTION SUR LA PAIE : {amount}",
  WELLNESS_POOL_TOAST: "R\xC9SERVE BIEN-\xCATRE : {amount}",
  FLEX_DOLLAR_ELIGIBLE: "Vous pouvez utiliser votre {flexDollarTerm} pour payer cette garantie!",
  FLEX_DOLLAR_TOAST: "DOLLARS FLEXIBLES : {amount}",
  FLEXIBLE_DOLLARS_USED_TOOLTIP: "Ceci est le montant de {flexDollarTerm} que vous avez utilis\xE9 sur vos avantages sociaux.",
  YOU_PAY_ANNUALLY_TOOLTIP: "Ceci est le co\xFBt annuel de vos avantages sociaux pour la p\xE9riode d'assurance compl\xE8te.",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP_NOTE: "Remarque : le montant est sujet \xE0 changement si vous avez des avantages sociaux en attente d'approbation.",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP: "Ceci est le montant que vous pouvez vous attendre \xE0 payer sur votre paie sur une base {payGroup}.",
  TOTAL_FLEXIBLE_DOLLARS: "Total {flexType} :",
  VIEW_BREAKDOWN: "Voir la r\xE9partition",
  YOUR_FLEX_DOLLARS_USED: "Vos {flexDollarTerm} utilis\xE9s",
  YOU_PAY_ANNUALLY: "Vous payez annuellement",
  YOUR_PAYROLL_DEDUCTION: "Votre retenue salariale",
  FLEXIBLE_DOLLARS_USED_WITH_TERM: "{flexDollarTerm} utilis\xE9e",
  WELLNESS_POOL_USED: "R\xE9serve bien-\xEAtre utilis\xE9e",
  FLEXIBLE_DOLLARS_USED: "Dollars flexibles utilis\xE9s",
  WELLNESS_POOL_WITH_DOLLARS: "fonds de la r\xE9serve bien-\xEAtre",
  FLEXIBLE_DOLLARS_WITH_DOLLARS: "dollars flexibles",
  WELLNESS_POOL_CAPITALIZED: "R\xE9serve bien-\xEAtre",
  FLEXIBLE_DOLLARS_CAPITALIZED: "Dollars flexibles",
  WELLNESS_POOL: "r\xE9serve bien-\xEAtre",
  FLEXIBLE_DOLLARS: "dollars flexibles",
  LEFTOVER_FLEX_BANNER_INFO_ZERO_SPLIT: "Les fonds restants seront partag\xE9s entre vos comptes : {message}",
  LEFTOVER_FLEX_BANNER_INFO_SPLIT: "Ces fonds restants seront partag\xE9s entre vos comptes : {message}",
  LEFTOVER_FLEX_BANNER_INFO_ZERO_SINGLE: "Les fonds restants seront ajout\xE9s \xE0 votre {benefitName}.",
  LEFTOVER_FLEX_BANNER_INFO_SINGLE: "Ces fonds restants seront ajout\xE9s \xE0 votre {benefitName}.",
  LEFTOVER_FLEX_BANNER_EXPLANATION: "Les {flexDollarTerm} restants sont les fonds qu'il vous reste apr\xE8s la couverture des co\xFBts des prestations admissibles. {message}",
  LEFTOVER_FLEX_BANNER_HEADING_ZERO: "Il ne vous reste aucun {flexDollarTerm}.",
  LEFTOVER_FLEX_BANNER_HEADING: "Il vous reste {leftoverFlexDollars} en {flexDollarTerm}.",
  LEFTOVER_FLEX_DOLLAR_INFO_TEXT_SINGLE: "<strong>{percent}%</strong> sera ajout\xE9 \xE0 votre  <strong>{benefitName}</strong>.",
  IT_LOOKS_LIKE_YOU_HAVE_SOME_LEFTOVER_FLEX: "Il semble vous rester des fonds dans votre {flexDollarTerm}! {message}",
  FLEX_DOLLAR_EXPLAINER_TITLE: "Que se passe-t-il si j\u2019ai d\xE9j\xE0 d\xE9pens\xE9 l\u2019int\xE9gralit\xE9 de ma {flexDollarTerm} ?",
  FLEX_DOLLAR_INFO_TEXT_MULTIPLE: "<strong>{percent}%</strong> sera ajout\xE9 \xE0 votre <strong>{benefitName}</strong>",
  FLEX_DOLLAR_INFO_TEXT_SINGLE: "ils seront automatiquement ajout\xE9s \xE0 votre {benefitName}.",
  FLEX_DOLLAR_INFO_TEXT: "S\u2019il vous reste des fonds de la {flexDollarTerm}, {message}.",
  FLEX_DOLLAR_SUBTITLE_EXACT_COST: "Assurez-vous de r\xE9partir le montant total entre les comptes suivants.",
  FLEX_DOLLAR_SUBTITLE_MAX_COST: "Vous pouvez allouer jusqu'\xE0 ce montant sur chacun des comptes suivants.",
  WELLNESS_POOL_TITLE: "{company} vous a fourni le montant {value} de {flexTerm} \xE0 r\xE9partir comme vous le souhaitez. {maxValue}",
  FLEX_DOLLAR_TITLE: "{company} vous a fourni {value} de {flexTerm} \xE0 r\xE9partir comme vous le souhaitez. {maxValue}",
  PAPER_BENEFICIARIES_FORM_READY_INSTRUCTIONS: "<strong>Instructions :</strong> Apr\xE8s avoir rempli le formulaire, vous pourrez le t\xE9l\xE9charger par l'entremise de League, sauf si vous obtenez des indications contraires de votre \xE9quipe des ressources humaines.",
  EOI_FORM_READY_INSTRUCTIONS: "<strong>Instructions :</strong> Vous devrez signer ce formulaire et le poster \xE0 votre assureur. Des instructions suppl\xE9mentaires sont incluses dans le formulaire.",
  FORM_NOT_READY_INSTRUCTIONS: "<strong>Instructions :</strong> D\xE8s que votre formulaire sera disponible, nous vous enverrons un courriel avec les instructions pour le remplir.",
  EOI_CARD_DESCRIPTION: "Ce formulaire est requis par votre assureur pour la couverture <strong>{benefitNames}</strong> que vous avez choisie.",
  LEARN_MORE_ABOUT_EOI: "En savoir plus sur les preuves d'assurabilit\xE9",
  EVIDENCE_OF_INSURABILITY_EOI: "Preuve d'assurabilit\xE9 (PA)",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT2: "Vous devrez sans doute \xE9galement passer un examen physique ou des analyses sanguines pour finaliser la proc\xE9dure.",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_LIST: "<li>Vous avez adh\xE9r\xE9 \xE0 une prestation qui exige une preuve d\u2019assurabilit\xE9 pour toutes les couvertures </li><li>Vous avez choisi un niveau de couverture qui exige une preuve d\u2019assurabilit\xE9 </li><li>Votre salaire vous fait adh\xE9rer automatiquement \xE0 un niveau de couverture qui exige une preuve d\u2019assurabilit\xE9</li>",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT1: "La preuve d\u2019assurabilit\xE9 est une sorte de questionnaire m\xE9dical que demande votre assurance dans ces situations :",
  EVIDENCE_OF_INSURABILITY_BANNER_TEXT_WITH_NEM: "Vous devez remplir et soumettre un formulaire {eoi} pour demander une couverture de plus de {noEvidenceMaximumValue}. Nous vous fournirons ce formulaire une fois que vous aurez finalis\xE9 votre adh\xE9sion.",
  EVIDENCE_OF_INSURABILITY_BANNER_TEXT: "Vous devez remplir et soumettre un formulaire {eoi} pour demander {benefitName}. Nous vous fournirons ce formulaire une fois que vous aurez finalis\xE9 votre adh\xE9sion.",
  EVIDENCE_THIS_FORM: "ce formulaire",
  EVIDENCE_OF_INSURABILITY_TEXT_NOTE: "Note : Si vous vous \xEAtes d\xE9j\xE0 inscrit pour cette garantie, vous n'aurez pas \xE0 soumettre un formulaire de preuve d'assurabilit\xE9, \xE0 moins que vous souhaitiez augmenter votre couverture.",
  EVIDENCE_OF_INSURABILITY_TEXT_KPMG: "Afin de souscrire \xE0 cette garantie, vous devez fournir une preuve d\u2019assurabilit\xE9. Veuillez remplir une version du {questionnaireLink} et l\u2019envoyer au {vendor}.",
  EVIDENCE_OF_INSURABILITY_TEXT: "Afin de vous inscrire cette garantie, vous devrez compl\xE9ter une preuve d'assurabilit\xE9 et la soumettre \xE0 {vendor} pour approbation. Par la suite, League fournira les instructions pour remplir et soumettre la preuve d'assurabilit\xE9 \xE0 {vendor}.",
  WHO_DOES_THIS_COVER: "Qui est couvert?",
  WHAT_DOES_THIS_MEAN: "Qu'est-ce que cela signifie?",
  WHAT_FOR: "Quel en est le but?",
  EVIDENCE_OF_INSURABILITY_TOOLTIP: "Une preuve d'assurabilit\xE9 (PA) est requise afin de demander cette garantie. Vous devrez remplir et soumettre un formulaire de PA (qui inclut un questionnaire de sant\xE9) pour l'approbation par {vendor} avant que votre montant de couverture s\xE9lectionn\xE9 soit mis en vigueur. \n\nVeuillez noter que des informations m\xE9dicales suppl\xE9mentaires peuvent \xEAtre requises. Les cas \xE9ch\xE9ant, ces informations seront obtenues par un fournisseur tiers qui vous demandera de vous pr\xE9senter en personne \xE0 un rendez-vous.",
  EVIDENCE_OF_INSURABILITY_US_TOOLTIP: "Si vous choisissez un montant de couverture sup\xE9rieur au montant garanti \xE0 la souscription (GS), vous devrez compl\xE9ter un formulaire de preuve d'assurabilit\xE9 (PA). Cela d\xE9clenchera un processus de demande n\xE9cessitant que vous fournissiez des renseignements sur votre \xE9tat de sant\xE9. Une fois que vous aurez rempli le formulaire PA, il sera r\xE9vis\xE9 et approuv\xE9 par {vendor} avant que votre montant de couverture total soit mis en vigueur. \n\nVotre montant de couverture sera bas\xE9 sur le GS jusqu'au moment o\xF9 votre PA sera approuv\xE9e par {vendor}. Votre co\xFBt par p\xE9riode de paie est le montant que vous devriez payer suite \xE0 l'approbation de {vendor}. League s'harmonisera avec {vendor} pour vous fournir les instructions afin de compl\xE9ter le formulaire PA.",
  WHY_CONTRIBUTE: "Pourquoi cotiser?",
  SHORT_DESC_WITH_EMPLOYER_CONTRIBUTION: "La contribution de votre employeur n'est pas prise en compte pour votre limite de contribution annuelle,",
  LPFSA_DESC: "Vous pouvez cotiser des dollars \xE0 l'abri de l'imp\xF4t dans un compte-gestion sant\xE9 \xE0 but restreint (CGSBR) afin de couvrir les d\xE9penses de soins dentaires et d\u2019ophtalmologie admissibles jusqu'\xE0 hauteur de la limite de contribution annuelle. Le montant que vous allouerez sera d\xE9duit de votre salaire de mani\xE8re \xE9gale tout au long de l'ann\xE9e. {employerContributionDesc}",
  HFSA_SHORT_DESC: "Vous pouvez cotiser des dollars \xE0 l'abri de l'imp\xF4t dans un compte gestion-sant\xE9 (CGS) afin de couvrir les d\xE9penses de sant\xE9 admissibles jusqu'\xE0 hauteur de la limite de contribution annuelle. Le montant que vous allouerez sera d\xE9duit de votre salaire de mani\xE8re \xE9gale tout au long de l'ann\xE9e. {employerContributionDesc}",
  DCFSA_SHORT_DESC: "<p> Un compte de d\xE9penses flexible pour la garde \xE0 charge (DCFSA) est un compte fiscalement avantageux que vous pouvez utiliser pour \xE9pargner et payer des d\xE9penses admissibles, telles que la garderie, la maternelle ou la garde apr\xE8s l'\xE9cole pour une personne qualifi\xE9e. Le montant que vous choisissez de cotiser sera d\xE9duit uniform\xE9ment sur vos ch\xE8ques de paie tout au long de l'ann\xE9e. </p> <strong> Remarque: </strong> Les limites de cotisation pour ce compte d\xE9pendront \xE9galement de votre contribution en tant que m\xE9nage ou mari\xE9 mais d\xE9clarez les imp\xF4ts s\xE9par\xE9ment. {employerContributionDesc}",
  FSA_SHORT_DESC: "Si vous avez des projets d'enrichissement individuel cette ann\xE9e, cotiser dans ce compte est d\xE9finitivement un pas dans la bonne direction! Les montants accumul\xE9s dans ce compte peuvent \xEAtre utilis\xE9s pour couvrir des d\xE9penses admissibles pour activit\xE9s physiques. Comme il s'agit d'un avantage imposable, tout montant d\xE9pens\xE9 \xE0 partir de ce compte sera soumis \xE0 une retenue d'imp\xF4t. {employerContributionDesc}",
  LDSA_SHORT_DESC: "Si vous avez des projets d'enrichissement individuel cette ann\xE9e, cotiser dans ce compte est d\xE9finitivement un pas dans la bonne direction! Les montants accumul\xE9s dans ce compte peuvent \xEAtre utilis\xE9s pour couvrir des d\xE9penses d'apprentissage et de d\xE9veloppement admissibles. Comme il s'agit d'un avantage imposable, tout montant d\xE9pens\xE9 \xE0 partir de ce compte sera soumis \xE0 une retenue d'imp\xF4t.",
  SHORT_DESC_WITH_POST_TAX_CONTRIBUTION: "Toutes vos contributions au-del\xE0 de la limite mensuelle seront soumises \xE0 l'imp\xF4t.",
  CRA_SHORT_DESC: "Vous pouvez cotiser des dollars \xE0 l'abri de l'imp\xF4t dans un compte de remboursement de transport pour couvrir les d\xE9penses de transport admissibles. Le montant cotis\xE9 dans ce compte sera d\xE9duit mensuellement \xE0 partir de votre paie durant l'ann\xE9e. Vous pouvez augmenter, diminuer, commencer ou cesser les cotisations \xE0 tout moment. Les fonds inutilis\xE9s seront achemin\xE9s automatiquement vers votre compte pour une autre ann\xE9e du r\xE9gime, pourvu que vous demeuriez employ\xE9 par votre entreprise.",
  HEALTH_SAVINGS_ACCOUNT_SHORT_DESC: "<p>Comme vous \xEAtes inscrit dans un r\xE9gime d\u2019assurance maladie admissible aux HSA, vous pouvez cotiser \xE0 l\u2019abri de l\u2019imp\xF4t (jusqu\u2019\xE0 la limite permise) dans ce compte. Vous pouvez utiliser les fonds dans ce HSA pour les d\xE9penses de sant\xE9 admissibles.{br} Il est important de savoir que vous \xEAtes titulaire du HSA et que les soldes demeurant dans votre compte sont report\xE9s d\u2019une ann\xE9e \xE0 l\u2019autre. </p><strong> Remaque:</strong> Vous pouvez \xE9galement choisir d\u2019augmenter, de diminuer, de commencer ou de cesser les cotisations \xE0 tout moment. Le montant annuel de cotisation choisi sera divis\xE9 et d\xE9duit uniform\xE9ment sur vos paies pendant toute l\u2019ann\xE9e.",
  CHARITY_SHORT_DESC: "Utilisez votre compte de bienfaisance pour donner aux organismes de bienfaisance enregistr\xE9s de votre choix.",
  PENSION_SHORT_DESC: "Le compte d'\xE9pargne retraite personnel (CERP) offre une prestation de retraite flexible tout en permettant de cotiser \xE0 l'abri de l'imp\xF4t.",
  TFSA_SHORT_DESC: "<p>L'argent ne pousse pas dans les arbres, mais il pousse dans un CELI. Un CELI est un compte de placement parrain\xE9 par le gouvernement que vous pouvez utiliser pour faire fructifier votre \xE9pargne et investir dans des fonds communs de placement. Le revenu que vous tirez des fonds de votre CELI est, vous l'avez devin\xE9, libre d'imp\xF4t!</p>Tenez compte de votre limite personnelle lorsque vous cotisez.",
  RRSP_SHORT_DESC: "<p>\xC9pargnez pour un futur vous. Vous pouvez utiliser un REER pour \xE9pargner en vue de la retraite et acheter diff\xE9rents placements ou vous aider \xE0 acheter votre premi\xE8re maison. Toutes les cotisations que vous versez au REER sont exon\xE9r\xE9es d'imp\xF4t. </p> Tenez compte de votre limite personnelle lorsque vous cotisez.",
  HRA_SHORT_DESC: "<p>Comme vous \xEAtes inscrit dans un r\xE9gime d\u2019assurance maladie admissible au Compte de remboursement des frais de sant\xE9 (HRA), {employerName} financera un HRA pour vous aider \xE0 r\xE9gler les frais m\xE9dicaux admissibles non couverts par vos r\xE9gimes de sant\xE9. </p> Les HRA sont d\xE9tenus par l\u2019employeur et financ\xE9s par des contributions de l\u2019employeur uniquement. Le financement est renouvel\xE9 chaque ann\xE9e tant que vous restez un employ\xE9.",
  LSA_SHORT_DESC: "<p>Faites le plein d'\xE9nergie avec le {name} ({shortName}). Vous pouvez utiliser les fonds de ce compte pour acheter une vari\xE9t\xE9 de produits ou de services comme bon vous semble. </p>Gardez en t\xEAte que le {shortName} est un avantage imposable - cela signifie que vous paierez de l'imp\xF4t sur les montants d\xE9pens\xE9s \xE0 partir de ce compte.",
  HSA_SHORT_DESC: "<p>Assurez votre bien-\xEAtre avec le {name} ({shortName}). Vous pouvez utiliser ce compte pour r\xE9pondre \xE0 vos besoins m\xE9dicaux, couvrir les rendez-vous et les services pour vos soins dentaires, les soins de la vue, y compris les praticiens autoris\xE9s comme les massoth\xE9rapeutes, l'acupuncture, les chiropraticiens, etc.</p>Ce compte n'est pas soumis \xE0 l'imp\xF4t, ce qui signifie que les montants que vous d\xE9pensez \xE0 partir de ce compte ne sont pas imposables (exception : le {shortName} est imposable au Qu\xE9bec).",
  VENDOR: "Fournisseur",
  YOUR_SPENDING_ACCOUNT_COVERS: "Votre {accountType} couvre",
  ACCOUNT_DESCRIPTION_COPY: "Renseignez-vous \xE0 propos de votre couverture compl\xE8te.",
  ACCOUNT_LINK_COPY: "+ beaucoup plus",
  DEFAULT_DESCRIPTION_CURRENCY_INPUT: "Entrer votre montant personnalis\xE9.",
  SUBMIT_SELECTIONS: "Soumettre les s\xE9lections",
  CONFIRM_SELECTIONS_TEXT_EXTENDED: "Ne vous inqui\xE9tez pas, vous pouvez toujours modifier vos s\xE9lections avant qu'elles soient d\xE9finitives. Vous avez jusqu'\xE0 la fin de la p\xE9riode d'adh\xE9sion pour effectuer des modifications. \xC0 chaque fois que vous modifiez une s\xE9lection au r\xE9gime, assurez-vous de la soumettre.",
  CONFIRM_SUBMISSION: "En soumettant, vous certifiez que vous avez fourni des renseignements exacts.",
  CONFIRM_SELECTIONS_FINAL: "Une fois soumis, vos choix seront d\xE9finitifs.",
  CONFIRM_SELECTIONS_TEXT: "En compl\xE9tant votre adh\xE9sion, vous attestez avoir fourni des renseignements exacts.",
  CONFIRM_YOUR_SELECTIONS: "Confirmer vos s\xE9lections",
  REVIEW: "Passer en revue mes s\xE9lections",
  PRINT: "Imprimer",
  MORE_OPTIONS: "Plus d'options",
  YOUR_BENEFITS_SUMMARY_TEXT: "Vous verrez un r\xE9capitulatif complet de vos s\xE9lections sur la page suivante",
  PAST_PLANS: "Ancien(s) r\xE9gime(s)",
  CURRENT_PLAN: "R\xE9gime actuel",
  UPCOMING_PLAN: "Prochain r\xE9gime",
  PLAN_YEAR: "Ann\xE9e contractuelle",
  YOUR_BENEFITS_SUMMARY: "R\xE9capitulatif de votre couverture",
  BENEFITS_SUMMARY: "R\xE9capitulatif des avantages sociaux",
  REVIEW_PLAN: "Passer en revue votre r\xE9gime",
  REVIEW_YOUR_PLAN: "Prochaine \xE9tape : passer en revue votre r\xE9gime",
  CHOOSE_PLAN: "S\xE9lectionner le r\xE9gime",
  CHOOSE_YOUR_PLAN: "Prochaine \xE9tape : s\xE9lectionner votre r\xE9gime",
  NEXT_BENEFIT_BUTTON: "{benefitType, select, other {Passer en revue {benefitType}} Accident {Passer en revue l'assurance accident} Dental {Passer en revue l'assurance dentaire} Disability {Passer en revue l'invalidit\xE9 de longue dur\xE9e} Drug {Passer en revue l'assurance m\xE9dicaments} Life {Passer en revue l'assurance-vie} Medical {Passer en revue l'assurance maladie} Wellness {Passer en revue les comptes bien-\xEAtre}}",
  NEXT_BENEFIT_HEADING: "Prochaine \xE9tape : passer en revue votre couverture ''{benefitType}''",
  QUESTION_MARK: "?",
  BENEFIT_IS_INELIGIBLE: "Cette garantie n'est pas admissible",
  SELECT_THIS_PLAN: "S\xE9lectionnez ce r\xE9gime",
  CHOOSE_COVERAGE_RIGHT_FOR_YOU: "Votre couverture : {benefitName}",
  NO_WAIVE_COVERAGE: "Non. Renoncer \xE0 cette couverture",
  YES_ENROLL_IN_COVERAGE: "Oui. S'adh\xE9rer \xE0 cette couverture",
  WHO_WILL_YOU_BE_ENROLLING: "Qui souhaitez-vous inscrire pour cette couverture?",
  WOULD_YOU_LIKE_TO_ENROLL: "Souhaitez-vous vous adh\xE9rer \xE0 la couverture: {benefitName}?",
  SELECT_ONE: "S\xE9lectionnez en une",
  SELECT_AN_OPTION: "S\xE9lectionner une option",
  NA: "S.O.",
  CHOOSE_PLAN_INDICATOR: "Suite \xE0 votre choix, vous pourrez passer en revue le r\xE9gime que vous avez choisi!",
  PLAN_SELECTION: "S\xE9lection du r\xE9gime",
  DEPENDENT_COVERAGE_SELECT_DEPENDENTS_LINK_LABEL: "la section des personnes \xE0 charge",
  FAMILY_MEMBER: "Membre de la famille",
  OTHER_DEPENDENT_TYPE_FOOTNOTE: "*Seulement admissible pour les comptes d\xE9penses",
  OTHER_DEPENDENT_TYPE_DESCRIPTION: "Cette personne doit \xEAtre r\xE9sidente du Canada et d\xE9pendre de vous financi\xE8rement",
  CHILD_DEPENDENT_TYPE_DESCRIPTION: "Cette personne doit \xEAtre votre enfant ou l'enfant de votre conjoint/partenaire",
  PARTNER_ALREADY_ADDED: "Vous avez d\xE9j\xE0 ajout\xE9 un conjoint de fait/partenaire",
  PARTNER_DEPENDENT_TYPE_DESCRIPTION_US: "Cette personne doit \xEAtre votre \xE9poux ou \xE9pouse ou votre partenaire domestique",
  PARTNER_DEPENDENT_TYPE_DESCRIPTION: "Cette personne doit \xEAtre votre \xE9poux ou \xE9pouse ou votre conjoint de fait",
  OTHER_FAMILY_MEMBERS: "Autres membres de la famille*",
  DEPENDENT_COVERAGE_SELECT_ADD_DEPENDENTS: "Vous souhaitez ajouter une autre personne \xE0 charge? Cliquez sur {link} pour faire cette s\xE9lection. Vos s\xE9lections actuelles seront sauvegard\xE9es.",
  DEPENDENT_COVERAGE_SELECT_HEADER: "Qui inscrivez-vous sous la couverture : {benefitName}?",
  CURIOUS_ABOUT: "Par curiosit\xE9",
  ADD_FAMILY_MEMBER: "Ajouter un membre de la famille",
  ADD_PARTNER: "Ajouter un conjoint",
  ADD_CHILD: "Ajouter un enfant",
  NO_DEPENDENTS_DESCRIPTION: "Vous pouvez passer \xE0 l'\xE9tape suivante si vous n'avez personne \xE0 ajouter aujourd'hui.",
  REVIEW_DEPENDENTS: "V\xE9rifier mes personnes \xE0 charge",
  NEXT_UP_DEPENDENTS: "Prochaine \xE9tape : personnes \xE0 charge",
  YOUR_PROFILE_SECTION_SUBHEADING_INTEGRATION: "Nous avons pr\xE9rempli certains champs \xE0 partir de renseignements que nous avons d\xE9j\xE0 obtenus de {employerName}. Veuillez vous assurer que ces renseignements sont exacts pour compl\xE9ter votre adh\xE9sion. Notez que toutes les modifications apport\xE9es ici devront \xE9galement \xEAtre mises \xE0 jour dans votre syst\xE8me des RH.",
  YOUR_PROFILE_SECTION_SUBHEADING: "Nous avons pr\xE9rempli des champs \xE0 partir de renseignements que nous avons d\xE9j\xE0 obtenus de {employerName}. Vous pouvez les modifier si vous le souhaitez.",
  YOUR_PROFILE_SECTION_HEADING: "Bienvenue! Pour commencer, faisons connaissance.",
  PROFILE_PAGE_HEAD: "Former vos garanties",
  DEPENDENTS: "Personnes \xE0 charge",
  YOUR_PROFILE: "Votre profil",
  DIVISION_ADD_ERROR: "Une erreur s'est produite lors de l'ajout du service de facturation. Assurez-vous que le nom n'est pas d\xE9j\xE0 utilis\xE9!",
  DIVISION_DELETE_ERROR: "Une erreur s'est produite lors de la suppression du service de facturation. Veuillez vous assurer qu'il n'y a aucun employ\xE9 dans ce service",
  DIVISION_DELETED: "Le service de facturation a \xE9t\xE9 supprim\xE9 avec succ\xE8s!",
  DIVISIONS: "Services",
  ADD_BILLING_DIVISION: "Ajouter un service de facturation",
  BILLING_DIVISIONS: "Services de facturation",
  INVOICE_DIAGNOSTICS: "Donn\xE9es diagnostiques relatives \xE0 la facturation",
  STATEMENT_OF_ACCOUNT: "\xC9tat de compte",
  GET_INVOICE_DIAGNOSTICS: "Obtenir des donn\xE9es diagnostiques relatives \xE0 la facturation",
  GET_STATEMENT_OF_ACCOUNT: "Obtenir un \xE9tat de compte",
  CSV_LINK: ".CSV",
  PDF_LINK: ".PDF",
  DRY_RUN: "Cr\xE9er une facture test",
  CLEAR_INVOICE_DIAGNOSTICS_ERROR: "Une erreur est survenue lors de la suppression des donn\xE9es diagnostiques relatives \xE0 la facturation",
  CLEAR_INVOICE_DIAGNOSTICS_SUCCESS: "Les donn\xE9es diagnostiques relatives \xE0 la facturation ont \xE9t\xE9 supprim\xE9es",
  GET_INVOICE_DIAGNOSTICS_ERROR: "Une erreur emp\xEAche d'obtenir les donn\xE9es diagnostiques relatives \xE0 la facturation",
  GET_STATEMENT_OF_ACCOUNT_ERROR: "Une erreur emp\xEAche d'acc\xE9der \xE0 l'\xE9tat de compte",
  DRY_RUN_ERROR: "Une erreur emp\xEAche d'acc\xE9der \xE0 la facture test",
  INDIVIDUAL: "Individuel",
  FAMILY: "famille",
  SUCCESSFULLY_UPDATED_ALLOCATION: "L'allocation a \xE9t\xE9 mise \xE0 jour avec succ\xE8s!",
  FOR_THE_POLICY_PERIOD: "pour la dur\xE9e du contrat",
  ZERO_CONTRIBUTION_LIMIT_DEFAULT: "Vous ne pouvez pas ajouter de fonds \xE0 ce compte pour le moment. Veuillez contacter votre \xE9quipe de Service \xE0 la client\xE8le pour plus d'informations.",
  ZERO_CONTRIBUTION_LIMIT_HSA: "Vous devez vous inscrire \xE0 un r\xE9gime d'assurance-maladie admissible au CSS avant le 1er d\xE9cembre pour contribuer \xE0 ce compte.",
  CONTRIBUTION_LIMIT_SOFT_AND_MAX: "Dans ce compte, la cotisation maximale est de <strong>{maxAmount}</strong>. Les cotisations jusqu\u2019\xE0 <strong>{amount}</strong> seront avant imp\xF4ts.",
  PERSONAL_CONTRIBUTION_LIMIT_MAX: "Dans ce compte, la cotisation personnelle maximale est de  <strong>{amount}</strong>{contributionFrequency}.",
  LEARN_MORE_ABOUT_HSA_CONTRIBUTION_LIMIT: "En savoir plus \xE0 propos de la limite de contribution personnelle au CSS",
  CONTRIBUTION_LIMIT_MAX: "Dans ce compte, la cotisation maximale est de  <strong>{amount}</strong>{contributionFrequency}.",
  CONTRIBUTION_LIMIT_SOFT: "Vous pouvez cotiser jusqu\u2019\xE0 <strong>{amount}</strong> avant imp\xF4ts. Les fonds d\xE9passant ce montant seront imposables.",
  QLE_CONTRIBUTIONS_RESTRICTIONS_INFO: "Parce que vous vous inscrivez \xE0 la suite d'un \xC9v\xE9nement de vie admissible, vous ne pourrez pas ajouter des sommes pour certains comptes sur cette page. La prochaine occasion de le faire sera dans la prochaine inscription annuelle.",
  LEFTOVER_FLEX_DOLLAR: "{flexDollarTerm} restants",
  WHY_ARE_MY_FUNDS_PRORATED: "Pourquoi mes fonds sont-ils calcul\xE9s au prorata?",
  PRORATED_FUNDS: "Fonds calcul\xE9s au prorata",
  PRORATION_CALCULATION_EXAMPLE: "500 $ x 6/12 (mois restant dans la p\xE9riode de police) = 250 $",
  PRORATION_BANNER_TOOLTIP_REASON_MOBILE: "Comme votre p\xE9riode de police est en cours, les fonds ajout\xE9s seront calcul\xE9s au prorata du nombre de {periodName} restant dans cette p\xE9riode.",
  PRORATION_BANNER_TOOLTIP_REASON: "Comme votre p\xE9riode de police est en cours, vous ne recevrez qu'une partie de ce montant lorsque vous affecterez vos fonds \xE0 un compte. Dans ce cas, vous en obtiendrez la moiti\xE9.",
  PRORATION_BANNER_TOOLTIP_EXAMPLE: "Disons que votre p\xE9riode de police a d\xE9but\xE9 le 1er janvier et qu'un \xE9v\xE9nement de vie admissible survient en juillet. Vous effectuez alors des changements \xE0 vos s\xE9lections, ce qui vous am\xE8ne \xE0 un solde de 500 $ {flexDollarTerm}. Ce montant est pour votre p\xE9riode compl\xE8te de police.",
  LEARN_MORE_ABOUT_PRORATION: "En apprendre plus sur le calcul au prorata",
  SEE_AN_EXAMPLE_OF_PRORATION: "Voir un exemple de prorata",
  PRORATION_BANNER_CONTENT: "Votre p\xE9riode de police est en cours. Tous les fonds allou\xE9s \xE0 ces comptes seront calcul\xE9s au prorata du nombre de {periodName} restant dans cette p\xE9riode. {prorationExample}.",
  PRORATION_BANNER_HEADING: "Remarque : les fonds allou\xE9s \xE0 ces comptes seront calcul\xE9s au prorata.",
  ADD_FUNDS_OVER_ALLOCATION_ERROR: "Il semble que vous essayez d'ajouter un montant sup\xE9rieur \xE0 votre solde disponible!",
  ADD_FUNDS_EDIT_ALLOCATION_ERROR: "Le montant est identique \xE0 celui pr\xE9c\xE9demment saisi",
  ADD_FUNDS_NO_ALLOCATION_ERROR: "Veuillez ajouter des fonds ici pour proc\xE9der",
  PRORATION_EQUATION: "{fullAmount} x {periodsRemaining}/{totalPeriods} ({periodName} de police restant(s)) = {proratedAmount}",
  PRORATED_EXPLAINER_TEXT: "Il vous reste <strong>{periodsRemaining} {periodName}</strong> dans votre contrat. Nous prenons donc votre montant complet et nous le multiplions par <strong>{periodsRemaining}/{totalPeriods}</strong> pour trouver le montant calcul\xE9 au prorata.",
  CALCULATING_PRORATED_AMOUNT: "Calcul du montant au prorata",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_BODY_WITH_EMPLOYER_CONTRIBUTION: "Nous avons calcul\xE9 votre limite de cotisation personnelle en soustrayant la cotisation de votre employeur de la limite annuelle de l'IRS pour cette p\xE9riode de police. Si vous avez 55 ans ou plus cette ann\xE9e, votre plafond de cotisation personnel comprend un plafond de cotisation de rattrapage suppl\xE9mentaire de {catchUpLimit}.",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_BODY_NO_EMPLOYER_CONTRIBUTION: "Votre plafond de cotisation personnel est bas\xE9 sur le plafond annuel de l'IRS. Si vous avez 55 ans ou plus cette ann\xE9e, votre plafond de cotisation personnel comprend un plafond de cotisation de rattrapage suppl\xE9mentaire de {catchUpLimit}",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_HEADER: "Vous pouvez cotiser jusqu'\xE0 {employeeContributionLimit} pour cette p\xE9riode de police.",
  HSA_CONTRIBUTION_FINAL_GOAL_WITH_EMPLOYER_CONTRIBUTION: "Finalement, si vous le pouvez, vous pourriez cotiser jusqu'\xE0 <strong>{employeeContributionLimit} / p\xE9riode de police</strong>. Ceci tient compte de la cotisation de votre employeur de <strong>{employerContribution} / p\xE9riode de police</strong>.",
  HSA_CONTRIBUTION_FINAL_GOAL_NO_EMPLOYER_CONTRIBUTION: "Finalement, si vous le pouvez, vous pourriez cotiser jusqu'\xE0 <strong>{employeeContributionLimit} / p\xE9riode de police</strong>.",
  HSA_CONTRIBUTION_NEXT_GOAL: "Votre prochain objectif d'\xE9pargne pourrait \xEAtre de couvrir votre maximum \xE0 vos frais {dependentCoverageType} de <strong>{outOfPocketMax} / p\xE9riode de police</strong>.",
  HSA_CONTRIBUTION_FIRST_GOAL: "Votre premier objectif pourrait \xEAtre de couvrir votre d\xE9ductible {dependentCoverageType} de <strong>{deductible} / p\xE9riode de police</strong>.",
  HSA_CONTRIBUTION_GUIDANCE_SUBHEADING: "Les experts financiers recommandent ces objectifs lorsque vous cotisez \xE0 votre CSS :",
  CONTRIBUTION_GUIDANCE_HEADER: "Combien devrais-je cotiser?",
  OKAY: "Ok",
  EDIT_CONTRIBUTION_ERROR_NO_CHANGE_VALUE: "Veuillez entrer une nouvelle cotisation pour continuer",
  EDIT_CONTRIBUTION_SUCCESS_CRA: "Vous verrez vos nouvelles retenues \xE0 la source prendre effet dans un d\xE9lai de deux ou trois paies apr\xE8s votre inscription.",
  EDIT_CONTRIBUTION_SUCCESS_DEFAULT: "Vous verrez vos nouvelles retenues \xE0 la source prendre effet dans un d\xE9lai d'une ou deux paies apr\xE8s votre inscription.",
  EDIT_CONTRIBUTION_WARNING: "Si votre nouvelle cotisation est inf\xE9rieure \xE0 celle que vous avez d\xE9j\xE0 vers\xE9e pour l'ann\xE9e en cours, vos retenues sur salaire seront nulles \xE0 l'avenir. Vous ne serez pas rembours\xE9 pour les sommes pr\xE9lev\xE9es sur les paies pr\xE9c\xE9dentes.",
  NEW_CONTRIBUTION_NOTE: "Vous pouvez cotiser personnellement jusqu'\xE0 {amount}",
  NEW_CONTRIBUTION: "Nouvelle cotisation",
  CURRENT_CONTRIBUTION_NOTE: "Il s'agit du montant que vous cotisiez lors de votre derni\xE8re inscription.",
  CURRENT_CONTRIBUTION: "Cotisation actuelle",
  BENEFIT_NAME_CONTRIBUTION_UPDATED: "Cotisation {benefitName} mise \xE0 jour!",
  BENEFIT_NAME_CONTRIBUTION: "Cotisation {benefitName}",
  EDIT_CONTRIBUTION_OF_BENEFIT: "Modifier la contribution de {vendorName}{benefitName}",
  EDIT_CONTRIBUTION: "Modifier la cotisation",
  CHANGE_CONTRIBUTION: "Modifier la cotisation",
  ADD_FLEXIBLE_DOLLARS_TO_BENEFIT: "Ajouter des {flexDollarTerm} \xE0 {vendorName}{benefitName}",
  ADD_FLEXIBLE_DOLLARS: "Ajouter des {flexDollarTerm}",
  CONTRIBUTION_AFTER_PRORATION: "Cotisation apr\xE8s le calcul au prorata",
  ADD_FUNDS_MODAL_TEXT: "Ajoutez autant de fonds que vous le souhaitez \xE0 partir de votre solde de {flexDollarTerm} \xE0 ce compte.",
  YOUR_FLEX_DOLLAR_BALANCE: "Votre solde de {flexDollarTerm} : ",
  PRORATED_CONTRIBUTION_DETAILS_MODAL_DESCRIPTION: "Votre p\xE9riode contractuelle est en cours. Les cotisations \xE0 ce compte seront calcul\xE9es au prorata bas\xE9 sur le nombre de {periodName} qu'il vous reste.",
  EDIT_FUNDS_MODAL_HEADER: "Modifier les {flexDollarTerm} de votre {benefitName}",
  ADD_FUNDS_MODAL_HEADER: "Ajoutez des {flexDollarTerm} \xE0 votre {benefitName}",
  SEARCH_COVERAGE: "Rechercher votre couverture",
  RESTRICTIONS: "Restrictions",
  EXAMPLES: "Exemples : ",
  COLLAPSE_ALL: "Tout r\xE9duire",
  EXPAND_ALL: "Tout afficher",
  MORE_DETAILS_ON_THE_BENEFIT: "Plus de d\xE9tails sur le {benefitShortName}",
  VIEW_COVERAGE: "Consulter la couverture",
  VIEW_YOUR_COVERAGE: "Consulter votre couverture",
  COVERED_WITH_RESTRICTIONS: "Couvert, sous r\xE9serve de restrictions",
  NOT_COVERED: "Non couvert",
  COVERED: "Couvert",
  INCORRECT_AMOUNT: "Montant inexact",
  NEW_FUNDS: "Nouveaux fonds",
  CURRENT_FUNDS: "Fonds actuels",
  CHANGES_WILL_AFFECT_OVERALL_POLICY: "Tout changement apport\xE9 au fonds de l'utilisateur affectera l'ensemble du contrat, y compris en ce qui a trait \xE0 la r\xE9partition proportionnelle et allocations p\xE9riodiques.",
  ALLOCATION_UNDER_WELLNESS_POOL: "<strong>Ne soyez pas frugal!</strong> Vous n'avez pas d\xE9pens\xE9 votre allocation totale de {amount}.",
  ALLOCATION_EXCEEDS_CONTRIBUTION_LIMIT: "<strong>H\xE9, on se lance dans les grosses d\xE9penses!</strong> On dirait que vous avez d\xE9pass\xE9 un montant maximal de contribution.",
  ALLOCATION_EXCEEDS_WELLNESS_POOL: "<strong>H\xE9, on se lance dans les grosses d\xE9penses!</strong> Vous avez d\xE9pass\xE9 le montant allou\xE9 pour votre {flexTerm}. Veuillez r\xE9affecter les fonds de mani\xE8re \xE0 ne pas d\xE9passer {amount}.",
  EDIT_SPENDING_ACCOUNT_ALLOCATIONS: "Modifier les allocations du compte de d\xE9penses",
  CHANGE_ALLOCATION_DETAILS: "Modifier les d\xE9tails de l'allocation",
  BUNDLED_BENEFIT_SELECTED_CONTENT: "Nous avons mis votre r\xE9gime {bundledBenefitName} \xE0 jour pour <strong>{bundledBenefitPlanName}</strong> afin d'adapter votre s\xE9lection pour <strong>{selectedBenefitPlanName}</strong>",
  BUNDLED_BENEFIT_SELECTED_HEADER: "Votre {selectedBenefitName} et vos r\xE9gimes {bundledBenefitName} sont regroup\xE9s",
  LINKED_BENEFIT_REMOVED_CONTENT: "Vous ne pourrez plus voir cette garantie sur la page {benefitSetName}.",
  LINKED_BENEFIT_ADDED_CONTENT: "Vous pourrez voir cette garantie sur la page {benefitSetName}.",
  LINKED_BENEFIT_REMOVED_HEADER: "{benefitName} a \xE9t\xE9 retir\xE9",
  LINKED_BENEFIT_ADDED_HEADER: "{benefitName} a \xE9t\xE9 ajout\xE9!",
  DEPENDENT_REMOVED_ERROR: "Erreur de suppression de la personne a charge",
  DEPENDENT_REMOVED: "La personne \xE0 charge a \xE9t\xE9 retir\xE9e",
  DEPENDENT_UPDATE_ERRORED: "Une erreur est survenue lors de l'ajout de votre personne \xE0 charge",
  DEPENDENT_UPDATED: "La personne \xE0 charge a \xE9t\xE9 sauvegard\xE9e",
  DEPENDENT_TOOLTIP: "Les personnes \xE0 charge peuvent \xEAtre les enfants et petits-enfants d'un employ\xE9 (enfants biologiques, adoptifs ou beaux-enfants), ses parents et grands-parents, fr\xE8res ou s\u0153urs (y compris ses beaux-fr\xE8res et belles-s\u0153urs), ni\xE8ces, neveux, tantes ou oncles.",
  SPOUSE_TOOLTIP: "Un conjoint est une personne l\xE9galement mari\xE9e ou vivant en union de fait avec un employ\xE9.",
  DEPENDENT_CLASS_DESCRIPTION_COUPLE_FAMILY: "membres d'une {family} avec au moins une personne \xE0 charge",
  DEPENDENT_CLASS_DESCRIPTION_FAMILY: "membres d'une famille avec au moins une personne \xE0 charge autre qu'un conjoint",
  DEPENDENT_CLASS_DESCRIPTION_COUPLE: "avec un {spouse} et aucune {dependents}",
  DEPENDENT_CLASS_DESCRIPTION_SINGLE: "c\xE9libataires sans {spouse} ou {dependents}",
  EMPLOYEE_ALLOCATION_LABEL: "Les personnes {dependentClassDescription} recevront",
  ONE_TIME_TOTAL_ALLOCATION_SUMMARY: "Le montant total d'une allocation accord\xE9e en vertu d'un contrat sera pay\xE9 {frequency} \xE0 la date de d\xE9but de la p\xE9riode couverte par le contrat, le {startDate}",
  TOTAL_ALLOCATION_SUMMARY: "Le montant total d'une allocation accord\xE9e en vertu d'un contrat sera divis\xE9 en versements \xE9gaux et pay\xE9 {frequency} entre le {startDate} et le {endDate}.",
  MISSING_AMOUNT_TO_BE_ADDED: "Montant manquant \xE0 ajouter",
  MISSING_SPENDING_ACCOUNT: "Compte de d\xE9penses manquant",
  ACCOUNT: "Compte",
  ADD_FUNDS_SUCCESS: "C'est r\xE9ussi, les fonds ont \xE9t\xE9 ajout\xE9s! Le nouveau solde de {userName} est de {balance}",
  FUNDS_TO_BE_ADDED: "Fonds \xE0 ajouter",
  CUSTOMER_SUPPORT: "Service \xE0 la client\xE8le",
  CONTACT_CUSTOMER_SUPPORT: "Contacter le service \xE0 la client\xE8le",
  THESE_FUNDS_ARE_ONE_TIME_ALLOCATION: "Cet ajout de fonds est unique. Pour modifier l'allocation totale des fonds, cliquer sur {linkToCustomerSupport}",
  ADD_FUNDS_EMPLOYEE: "Ajouter des fonds \xE0 {employee}",
  ADD_FUNDS: "Ajouter des fonds",
  REMOVE_ADMIN_ROLE: "Supprimer un r\xF4le Administrateur",
  ADD_ADMIN_ROLE: "Ajouter un r\xF4le Administrateur",
  BALANCE: "Solde",
  AMOUNT_CONTRIBUTION: "Contribution de {amount}",
  AMOUNT_ALLOCATION: "Allocation de {amount}",
  ALLOCATION: "Allocation",
  ACTION: "Action",
  DUPLICATE_BENEFIT_NAME_WARNING: "Plusieurs garanties de ce type portent le m\xEAme nom. Il est pr\xE9f\xE9rable de leur donner des noms diff\xE9rents.",
  GENERATE_CSV_ERROR_STATE_MESSAGE: "Nous avons rencontr\xE9 un probl\xE8me inattendu. Veuillez r\xE9essayer. Si le probl\xE8me persiste, veuillez contacter {emailLink} pour obtenir de l'aide.",
  USAGE_HISTORY: "Obtenir le rapport d'utilisation",
  GENERATE_CSV: "Cr\xE9er un fichier CSV",
  DOWNLOAD_ENROLLMENT_REPORT: "T\xE9l\xE9charger le rapport d'inscriptions",
  DOWNLOAD_A_CSV: "T\xE9l\xE9charger un rapport contenant tous les d\xE9tails recueillis par League \xE0 ce jour sur les employ\xE9s, les s\xE9lections de leurs r\xE9gimes et leurs personnes \xE0 charge",
  ENROLLMENT_REPORT: "Rapport d'inscriptions",
  DOWNLOAD_THE_LATEST: "T\xE9l\xE9charger les derniers rapports sur votre bureau.",
  CONFIRM_UNSCHEDULE: "Confirmer l'annulation",
  UNSCHEDULE_REINSTATE_DESCRIPTION: "Veuillez confirmer que vous souhaitez annuler la r\xE9int\xE9gration de cet employ\xE9.",
  UNSCHEDULE_TERMINATION_DESCRIPTION: "Veuillez confirmer que vous souhaitez annuler le retrait de cet employ\xE9.",
  UNSCHEDULE_SUSPENSION_DESCRIPTION: "Veuillez confirmer que vous souhaitez annuler la suspension de cet employ\xE9.",
  UNSCHEDULE_REINSTATE_HEADER: "Annuler la r\xE9int\xE9gration",
  UNSCHEDULE_SUSPENSION_HEADER: "Annuler la suspension",
  UNSCHEDULE_TERMINATION_HEADER: "Annuler le retrait",
  CONFIRM: "Confirmer",
  CANCEL: "Annuler",
  TERMINATE: "Retirer",
  SUSPEND: "Suspendre",
  REINSTATE: "R\xE9int\xE9grer",
  INTEGRATIONS_CONFIGURATION_SAVED: "La configuration des int\xE9grations a \xE9t\xE9 sauvegard\xE9e avec succ\xE8s",
  TYPEFORM_URL: "URL du Typeform",
  BENEFIT_CLASS: "Cat\xE9gorie d'avantages sociaux",
  COPY_PASTE_TYPEFORM_URL: "Copier et coller l'URL d'un formulaire Typeform pour en faire une nouvelle \xE9tape du processus d'inscription. On peut ajouter ou supprimer des cat\xE9gories sous l'onglet des garanties.",
  ENABLE_THIRD_PARTY_INTEGRATIONS_BY_FILLING_IN_FIELDS: "Autoriser l'int\xE9gration de tiers en remplissant les champs associ\xE9s.",
  CONFIGURE_THIRD_PARTY_INTEGRATIONS: "Configurer l'int\xE9gration de tiers",
  UPDATE: "Mettre \xE0 jour",
  REJECT: "Rejeter",
  REQUEST_MORE_INFORMATION: "Demander plus d'informations",
  CLAIM_NOTE: "L'utilisateur trouvera le message suivant dans l'historique de ses demandes :",
  PAYMENT_ADJUSTMENT_NOTE: "Remarque",
  PAYMENT_ADJUSTMENT_BILLING_DIVISION: "Service de la facturation",
  PAYMENT_ADJUSTMENT_AMOUNT: "Montant",
  PAYMENT_ADJUSTMENT_DATE: "Date",
  DUPLICATE_CLAIM_CONTACT_MANAGER: "Si vous avez des doutes quant \xE0 la marche \xE0 suivre, communiquez avec un gestionnaire.",
  DUPLICATE_CLAIM_REJECT_DESCRIPTION: "Rejeter la demande et envoyer au membre une r\xE9ponse standardis\xE9e indiquant que cette demande est pr\xE9sent\xE9e en double.",
  DUPLICATE_CLAIM_REJECT_ACTION: "Rejeter la demande",
  DUPLICATE_CLAIM_APPROVE_DESCRIPTION: "Approuver la demande et ignorer l'avertissement concernant une demande en double.",
  DUPLICATE_CLAIM_APPROVE_ACTION: "Approuver la demande",
  DUPLICATE_CLAIM_CANCEL_DESCRIPTION: "Aucune action n'est entreprise et vous retournez \xE0 l'\xE9cran pr\xE9c\xE9dent.",
  DUPLICATE_CLAIM_CANCEL_ACTION: "Annuler",
  DUPLICATE_CLAIM_INTRO: "Vous avez le choix entre les options suivantes :",
  CLAIM_REF: "<Identifiant de la demande : {referenceId}>",
  DUPLICATE_CLAIM_HEADER: "La demande que vous soumettez est un double de {claimLink}",
  SENT_FOR_EXTERNAL_REVIEW: "Envoy\xE9 pour \xE9valuation externe",
  APPROVE: "Approuver",
  CHOOSE_NEW_STATUS: "Choisir un nouveau statut",
  UPDATE_STATUS: "Mise \xE0 jour du statut",
  NO_ADJUSTMENTS: "Aucune modification \xE0 apporter",
  MAKE_ADJUSTMENT: "Apporter des modifications",
  ADJUSTMENTS: "Ajustements",
  PIN_TO_SAVE_USER_DOCUMENT: "\xC9pingler pour sauvegarder ce document dans le profil de l'utilisateur",
  EXPIRES: "Date d'expiration",
  UNPIN_TO_REMOVE_USER_DOCUMENT: "D\xE9s\xE9pingler le document pour le retirer du profil de l'utilisateur",
  CONFIRM_DELETE_SUPPORTING_DOC_TEXT: "Cette op\xE9ration supprimera d\xE9finitivement le document justificatif du profil de l'utilisateur. Voulez-vous vraiment le supprimer?",
  CONFIRM_REMOVE_SUPPORTING_DOC_TEXT: "Cette op\xE9ration retirera le document justificatif du profil de l'utilisateur. Est-ce vraiment ce que vous voulez faire?",
  DOCUMENT_NAME: "Nom du document",
  DOCUMENT_TYPE: "Type de document",
  ALL_FIELDS_REQUIRED: "Tous les champs sont obligatoires \xE0 moins d'\xEAtre marqu\xE9s comme facultatifs",
  EDIT_DOCUMENT: "Modifier le document",
  EDIT_SUPPORTING_DOCUMENT: "Modifier un document justificatif",
  UPLOAD_SUPPORTING_DOCUMENT: "T\xE9l\xE9verser un document justificatif",
  ADD_DOCUMENT_INFORMATION: "Ajouter des renseignements sur le document",
  DOCUMENT_INFORMATION: "Renseignements sur le document",
  ATTACHED_TO_CLAIM: "Le document a \xE9t\xE9 joint \xE0 la demande",
  REMOVE_FROM_CLAIM: "Retirer de la demande",
  ATTACH_TO_CLAIM: "Joindre \xE0 la demande",
  SUPPORTING_DOCUMENTS: "Documents justificatifs",
  CLAIM_DOCUMENTS: "Documents de la demande",
  ENTER_CODE: "Entrez un code",
  CREDIT_INFORMATION: "Renseignements sur le cr\xE9dit",
  TOTAL_CREDIT: "Cr\xE9dit total :",
  YOUR_CARD: "Votre carte",
  ADD_CARD: "Ajouter une carte",
  ADD_CREDIT_CARD_TEXT: "Pour r\xE9server des services de sant\xE9 sur League, veuillez ajouter une carte VISA, MasterCard ou AMEX.",
  SIMPLY_ADD_YOUR_CREDIT_CARD_TO: "Il vous suffit d'ajouter votre carte de cr\xE9dit \xE0 l'appli, de la d\xE9biter et de soumettre une demande de remboursement.",
  CREDIT_CARD_INTRO: "Laisser l'appli s'occuper de la facture.",
  CODE_ALREADY_REDEEMED: "Le code de promotion a d\xE9j\xE0 \xE9t\xE9 \xE9chang\xE9",
  PROMO_CODE_SUCCESS: "Le code de promotion a \xE9t\xE9 \xE9chang\xE9!",
  PROMO_CODE_NOT_FOUND: "Code de promotion invalide",
  NO_MORE_TRANSACTIONS: "Aucune autre transaction",
  NO_TRANSACTIONS_YET: "Aucune transaction n'a encore \xE9t\xE9 effectu\xE9e",
  CGA: "CBF",
  CREDIT_CARD: "Carte de cr\xE9dit",
  LEAGUE_CREDIT: "Cr\xE9dit League",
  REFUNDED: "Rembours\xE9",
  REFUND: "Remboursement",
  ADJUSTMENT: "Modification",
  TOP_UP: "Recharger",
  CREDIT: "Cr\xE9dit",
  MARKETPLACE: "March\xE9 Sant\xE9",
  CLAIM: "Demande",
  ID: "Identifiant",
  TYPE: "Type",
  DESCRIPTION: "Description",
  TIME: "Heure",
  DATE: "Date",
  SAVE_CARD: "Sauvegarder la carte",
  DEPENDENT_CARD_HOLDER: "Carte de {dependentName} pour",
  MORE_ACTIONS: "Plus d'options",
  CHILD_LOWERCASE: "enfant",
  PARTNER_LOWERCASE: "partenaire",
  OTHER: "Autre",
  SIBLING: "Fr\xE8re ou s\u0153ur",
  INELIGIBLE_CHILD: "Enfant non admissible",
  NEPHEW_NIECE: "Neveu / ni\xE8ce",
  UNCLE_AUNT: "Oncle / tante",
  GRANDCHILD: "Petit-enfant",
  GRANDPARENT: "Grand-parent",
  PARENT: "Parent",
  CHILD: "Enfant",
  PARTNER: "Partenaire",
  COMMON_LAW: "Conjoint de fait",
  SPOUSE: "Conjoint",
  RELATIONSHIP: "Relation",
  MEMBER_ID: "Identifiant du membre",
  VIEW_CARD: "Voir la carte",
  VIEW_DEPENDENTS_MORE_INFO_LINK_TEXT: "D\xE9couvrez qui est admissible \xE0 titre de personne \xE0 charge",
  VIEW_DEPENDENTS_DESCRIPTION_TEXT: "Vos personnes \xE0 charge peuvent \xE9galement utiliser votre {benefitName} autant qu'elles le souhaitent.",
  MANAGE_DEPENDENTS: "G\xE9rer mes personnes \xE0 charge",
  PLEASE_CONTACT: "veuillez contacter le",
  IF_THE_PROBLEM_PERSISTS: "Si le probl\xE8me persiste",
  IF_YOU_BELIEVE_THIS_IS_AN_ERROR: "Si vous croyez qu'il s'agit d'une erreur",
  IF_YOU_HAVE_AN_URGENT_CLAIM: "Si votre demande est urgente,",
  PLEASE_TRY_AGAIN_SHORTLY: "Veuillez r\xE9essayer sous peu",
  WE_CANT_CURRENTLY_ACCESS_YOUR_ACCOUNT_DETAILS: "Nous sommes pour l'instant dans l'impossibilit\xE9 d'acc\xE9der aux d\xE9tails de votre compte.",
  YOUR_ACCOUNT_EXPIRED_ON: "Votre compte a expir\xE9 le :",
  CLAIMS_FOR_PURCHASES_MADE_AFTER_DATE_WILL_BE_REJECTED: "Les demandes relatives \xE0 des achats effectu\xE9s apr\xE8s le {endDate} seront rejet\xE9es.",
  YOU_HAVE_UNTIL_DATE_TO_SUBMIT: "Vous avez jusqu'au <strong>{inactiveDate}</strong> pour soumettre vos achats datant d'avant le <strong>{endDate}</strong>.",
  YOUR_BENEFITS_WILL_BE_ACTIVE_ON: "Vos garanties seront actifs le :",
  YOUR_ACCOUNT_IS_ACTIVE_AND_IN_THE_PROCESS_OF_BEING_SET_UP: "Votre compte est actif et en cours de cr\xE9ation.",
  YOU_CAN_USE_YOUR_ACCOUNT_ON: "Vous pouvez utiliser votre compte sur :",
  CURRENTLY_UNAVAILABLE: "Actuellement indisponible",
  EXPIRED: "Expir\xE9e",
  EXPIRING_SOON: "Arrive bient\xF4t \xE0 expiration",
  ACTIVATION_PENDING: "En attente d'activation",
  CONTACT_CUSTOMER_SERVICE: "Contacter le service \xE0 la client\xE8le",
  NEED_HELP_TEXT: "Si vous avez la moindre question au sujet de votre couverture, posez-la \xE0 notre \xE9quipe, qui est l\xE0 pour vous aider!",
  NEED_HELP: "Besoin d'aide?",
  SELECT_A_BENEFIT_CARD_TO_SUBMIT_A_CLAIM: "S\xE9lectionnez une carte pour soumettre une demande, consulter votre couverture, g\xE9rer vos personnes \xE0 charge, et plus encore.",
  SELECT_CARD: "S\xE9lectionner une carte",
  VIEW_ALL_WITH_ARROW: "Voir tout >",
  NO_DONT_SKIP: "Non, ne pas sauter",
  YES_SKIP_GOAL: "Oui, sauter cet objectif",
  THE_GOAL_WILL_BE_MARKED_INCOMPLETE: "L'objectif sera marqu\xE9 comme incomplet et vous ne recevrez pas de points cette fois-ci.",
  ARE_YOU_SURE_SKIP_GOAL: "Voulez-vous vraiment sauter cet objectif?",
  SKIP_THIS_ACTIVITY: "Sauter cette activit\xE9",
  SUCCESS_UPDATING_EMPLOYER: "C'est r\xE9ussi! Le profil a \xE9t\xE9 mis \xE0 jour",
  ERROR_UPDATING_EMPLOYER: "Une erreur est survenue! Le profil n'a pas pu \xEAtre mis \xE0 jour",
  NO_EMPLOYEE_BENEFITS_MESSAGER: "Aucuns avantages sociaux ne sont offerts.",
  PAYROLL_HEADER_DOWNLOAD: "T\xE9l\xE9chargement",
  PAYROLL_HEADER_DATE_ISSUED: "Date d'\xE9mission",
  PAYROLL_BILLING_DATE_RANGE: "Du {startDate} au {endDate}",
  PAYROLL_HEADER_BILLING_PERIOD: "P\xE9riode de facturation",
  CLEAR_INVOICE_DIAGNOSTICS_CONFIRMATION: "<strong>Avertissement : </strong> L'effacement du diagnostic sur les factures supprimera d\xE9finitivement tous les diagnostics existants du syst\xE8me de League.",
  CLEAR_INVOICING_DIAGNOSTICS: "Effacer le diagnostic sur les factures",
  INVOICE_DIAGNOSTIC_DOWNLOAD: "T\xE9l\xE9charger le rapport de diagnostic sur les factures : ",
  STATEMENT_OF_ACCOUNT_DOWNLOAD: "T\xE9l\xE9charger l'\xE9tat de compte : ",
  DRY_RUN_CREATED: "Le test a \xE9t\xE9 cr\xE9\xE9",
  DRY_RUN_GROUP_NAME: "Nom du groupe test",
  DRY_RUN_DOWNLOAD: "T\xE9l\xE9chargement du test : ",
  INVOICE_DRY_RUN: "Facture test",
  INVOICE_HEADER_DOWNLOAD: "T\xE9l\xE9chargement",
  INVOICE_HEADER_STATUS: "Statut",
  INVOICE_HEADER_DATE_ISSUED: "Date d'\xE9mission",
  INVOICE_BILLING_DIVISION: "Service de la facturation",
  INVOICE_HEADER_INVOICE_NUMBER: "Num\xE9ro de facture",
  NO_PAYROLL_MESSAGE: "Aucun rapport de paie n'est disponible.",
  PAYROLL_HEADER_COPY: "Cette section contient les rapports de paie pour chaque p\xE9riode de facturation. Les rapports de paie, qui sont produits avec les factures au d\xE9but de chaque mois, couvrent la m\xEAme p\xE9riode de facturation que votre facture mensuelle.",
  PAYROLL_HEADER: "Rapports de paie",
  PAYROLL: "Paie",
  BILLING_EMAIL: "billing@league.com",
  INVOICES_HELP_EMAIL_MESSAGE: "Si vous ne trouvez pas les r\xE9ponses que vous cherchez, n'h\xE9sitez pas \xE0 nous contacter \xE0 {link}.",
  KNOWLEDGE_BASE_URL: "https://help.league.com/s/topic/0TO0d000000MbAxGAK/for-hr-admins?utm_source=Backend",
  KNOWLEDGE_BASE: "le centre d'aide",
  INVOICES_HELP_KB_MESSAGE: "Vous avez des questions sur votre facture? Consultez notre {link}!",
  NO_INVOICES_MESSAGE: "Aucune facture n'est disponible.",
  NO_BENEFITS_FOR_GROUP_MESSAGE: "Ce groupe ne comprend aucuns avantages sociaux.",
  NO_BENEFITS_FOR_PLAN_MESSAGE: "Ce r\xE9gime ne comprend aucunes garanties.",
  NO_PLANS_MESSAGE: "Aucun r\xE9gime n'est compris dans cette cat\xE9gorie d'avantages sociaux.",
  NO_BENEFITS_MESSAGE: "Aucunes garanties ne sont disponibles.",
  YOU_QUALIFY_FOR_A_REWARD_OF: "Vous avez droit \xE0 une r\xE9compense de {amount} en Cr\xE9dit League!",
  REDEEM_YOUR_REWARD_NOW: "Obtenez votre r\xE9compense maintenant!",
  REMAINING_POINTS_AFTER_REDEMPTION: "Solde des points apr\xE8s le rachat : {point}",
  CURRENT_POINT_BALANCE: "Solde actuel de points : {points}",
  YOU_SUCCESSFULLY_REDEEMED: "Vous venez d'\xE9changer {points} points, ce qui repr\xE9sente un Cr\xE9dit League de {dollars}!",
  HOW_EXCITING: "Fantastique!",
  REWARD: "de r\xE9compense",
  POINTS_NEEDED: "Points requis",
  WHAT_ARE_MY_POINTS_WORTH: "Quelle est la valeur de mes points?",
  NO_EXPIRY_DATE: "Aucune date d'expiration",
  EXPIRY_DATE: "Date d'expiration",
  REDEEM_NOW: "\xC9changer maintenant",
  YOUR_REWARD_POINTS: "Vos points de r\xE9compense",
  DOLLARS_LEAGUE_CREDIT: "Cr\xE9dit League de {dollars}",
  MORE_POINTS_TO_REACH: "{points} points suppl\xE9mentaires sont n\xE9cessaires pour atteindre votre prochaine r\xE9compense",
  MORE_POINTS_TO_REDEEM: "{points} points suppl\xE9mentaires pour recevoir {leagueCredit}",
  YOUR_NEXT_REWARD: "Votre prochaine r\xE9compense",
  REDEEM_REWARDS: "\xC9changer mes points r\xE9compenses",
  STAY_IN_PROGRAM: "Rester dans le programme",
  YOU_WILL_LOSE_ALL_PROGRESS_IN_PROGRAM: "Votre progression dans le programme sera interrompue. Si vous d\xE9cidez de r\xE9essayer plus tard, vous devrez recommencer depuis le d\xE9but.",
  ARE_YOU_SURE: "Voulez-vous vraiment quitter le programme?",
  LEAVE_PROGRAM: "Quitter le programme",
  MORE_DETAILS_ARROW: "Plus de d\xE9tails >",
  CURRENT_GOAL: "Objectif actuel",
  EXIT_ADMIN: "Votre compte",
  EMPLOYEE_UPDATE_ERROR_MESSAGE: "Veuillez r\xE9essayer. Si le probl\xE8me persiste, contactez aide@league.com",
  EMPLOYEE_UPDATE_ERROR_MESSAGE_HEADER: "L'employ\xE9 n'a pas \xE9t\xE9 mis \xE0 jour",
  EMPLOYEE_UPDATE_SUCCESS_MESSAGE: "C'est r\xE9ussi! L'employ\xE9 a \xE9t\xE9 mis \xE0 jour.",
  EMPLOYEE_CREATE_SUCCESS_MESSAGE: "C'est r\xE9ussi! L'employ\xE9 a \xE9t\xE9 cr\xE9\xE9.",
  CHANGE_CLASS_LABEL: "Cat\xE9gorie",
  USER_BENEFITS_SETTINGS_HEADER: "Param\xE8tres",
  WE_CAN_HELP: "Pour de l'aide, communiquez avec notre \xE9quipe de sp\xE9cialistes :",
  WANT_TO_MAKE_A_CHANGE: "Souhaitez-vous modifier vos avantages sociaux?",
  SUCCESS_REMOVING_USER_BENEFIT: "C'est r\xE9ussi! La garantie a \xE9t\xE9 retir\xE9e.",
  SUCCESS_UPDATING_USER_BENEFIT: "C'est r\xE9ussi! La garantie a \xE9t\xE9 mise \xE0 jour.",
  SUCCESS_ADDING_USER_BENEFIT: "C'est r\xE9ussi! La garantie a \xE9t\xE9 ajout\xE9e.",
  PAGE_NOT_FOUND_BUTTON: "Retour \xE0 la page d'accueil",
  PAGE_NOT_FOUND_CONTENT_SECONDARY: "Laissez-nous vous aider \xE0 retrouver votre chemin.",
  PAGE_NOT_FOUND_CONTENT: "Vous avez l'air un peu perdu!",
  PAGE_NOT_FOUND_HEADER: "Page introuvable",
  SIGN_OUT: "Se d\xE9connecter",
  USER_BULK_BENEFIT_OPTIONS_HEADER: "Fixer les statuts de toutes les garanties \xE0 :",
  USER_BENEFIT_OPTIONS_HEADER: "Fixer le statut de la garantie \xE0 :",
  SAVE_BENEFIT: "Sauvegarder la garantie",
  BENEFIT_ERROR: "Erreur de garantie",
  BENEFIT_SAVED: "Garantie sauvegard\xE9e",
  START_A_NEW_PROGRAM: "Commencez un nouveau programme et continuez \xE0 accumuler des r\xE9compenses en atteignant vos objectifs!",
  PROGRAM_HAS_ENDED: "Le programme est termin\xE9. En forme pour en commencer un nouveau?",
  SELECT_NEW_PROGRAM: "S\xE9lectionner un nouveau programme",
  YOU_HAVE_REACHED_THE_END_OF_THE_PROGRAM: "Vous avez termin\xE9 {name} en accumulant un total de {points} points!",
  CONGRATULATIONS: "F\xE9licitations!",
  CONGRATS: "Bravo!",
  BACK_TO_PROGRAM: "Retour au programme",
  CONGRATS_ON_EARNING_POINTS: "Vous avez accumul\xE9 suffisamment de points pour obtenir une r\xE9compense! F\xE9licitations, vous le m\xE9ritez bien!",
  YOU_HAVE_EARNED_A_REWARD: "Vous avez accumul\xE9 une r\xE9compense!",
  YOU_COMPLETED_THE_ACTIVITY_AND_EARNED_POINTS: "Vous avez termin\xE9 l'activit\xE9 {name} et accumul\xE9 {points} points! Joli travail!",
  WAY_TO_GO: "Bien jou\xE9!",
  MARK_AS_COMPLETE: "Marquer comme complet",
  CHECK_BACK_TOMORROW: "Revenez demain!",
  UNLOCKS_DAY_AT_TIME: "{icon} D\xE9verrouillage le {day} \xE0 {time}",
  START_DAY_AT_TIME: "Commence le {day}",
  END_DAY_AT_TIME: "Se termine le {day}",
  YOU_DONT_HAVE_ANY_POINTS: "Vous n'avez pas de points pour l'instant. R\xE9alisez vos objectifs quotidiens pour accumuler des points.",
  VIEW_REWARDS: "Voir les r\xE9compenses",
  POINT_BALANCE: "Solde des points",
  CLOSE: "Fermer",
  I_AGREE_TO_THESE_TERMS_AND_CONDITIONS: "J'accepte ces {linkToTOS}",
  DISABLE_PUSH_SETTING_CHECKBOX_LABEL: "Notification cellulaire automatique",
  NOTIFICATION_SETTINGS: "Param\xE8tres des notifications",
  TERMS_AND_CONDITIONS: "modalit\xE9s et conditions",
  LETS_DO_IT: "Faisons-le",
  EARN_NUMBER_POINTS_FULL: "Accumulez {points} points",
  EARN_NUMBER_POINTS: "Accumulez {points} points",
  DURATION_DAYS: "{duration} jours",
  SELECT_PROGRAM_FOR_MORE_DETAILS: "S\xE9lectionnez un programme pour plus de d\xE9tails",
  PROGRAM_SELECTION: "S\xE9lection d'un programme",
  WAITING_PERIOD_DAYS: "Dur\xE9e de la p\xE9riode d'attente",
  IN_GRACE_PERIOD: "En d\xE9lai de gr\xE2ce",
  BENEFIT_INACTIVE_GRACE_PERIOD: "D\xE9lai de gr\xE2ce inactive",
  BENEFIT_INACTIVE: "Inactif",
  BENEFIT_GRACE_PERIOD: "D\xE9lai de gr\xE2ce",
  BENEFIT_ACTIVE: "Actif",
  BENEFIT_PUBLISHED: "Publi\xE9",
  BENEFIT_READY: "Pr\xEAt",
  BENEFIT_CREATED: "Cr\xE9\xE9",
  BENEFIT_OPTED_OUT: "Refus\xE9",
  BENEFIT_DRAFT: "\xC9bauche",
  SUBMIT: "Soumettre",
  BULK_UPLOAD_STEP_2_CONTENT: "Veuillez t\xE9l\xE9verser votre fichier CSV compl\xE9t\xE9",
  BULK_UPLOAD_STEP_1_CONTENT: "T\xE9l\xE9chargez notre mod\xE8le de recensement en format CSV. Ouvrez le mod\xE8le dans le tableur de votre choix (Microsoft Excel, Numbers, Google Sheets, etc.) et remplissez tous les champs pour chaque employ\xE9.",
  BULK_UPLOAD_STEP_2_HEADER: "2. T\xE9l\xE9verser votre recensement en format CSV",
  BULK_UPLOAD_STEP_1_HEADER: "1. T\xE9l\xE9charger le mod\xE8le de recensement",
  BULK_UPLOAD_BACK_BTN_LABEL: "Pr\xE9c\xE9dent",
  BULK_UPLOAD_FINISH_BTN_LABEL: "Termin\xE9",
  BULK_UPLOAD_NEXT_BTN_LABEL: "Suivant",
  BULK_UPLOAD_CENSUS_BTN_LABEL: "T\xE9l\xE9verser le recensement",
  BULK_UPLOAD_DOWNLOAD_BTN_LABEL: "T\xE9l\xE9charger le mod\xE8le",
  BULK_UPLOAD_ERROR_COPY: "Nous n'avons pas \xE9t\xE9 en mesure d'importer {erroredEmployeesCount} employ\xE9s en raison de {errorsCount} erreurs. Veuillez corriger ces erreurs dans votre CSV puis t\xE9l\xE9verser de nouveau votre recensement.",
  BULK_UPLOAD_ERROR_HEADER: "Avertissement",
  BULK_UPLOAD_SUCCESS_COPY: "Nous avons r\xE9ussi \xE0 ajouter {successfullyAddedEmployeesCount} employ\xE9s.",
  BULK_UPLOAD_SUCCESS_HEADER: "C'est r\xE9ussi!",
  BULK_UPLOAD_SAVE_IMPORT_DESC: "Sauvegarder et inviter les employ\xE9s",
  BULK_UPLOAD_SAVE_IMPORT_TITLE: "Sauvegarder le fichier import\xE9",
  BULK_UPLOAD_UPLOAD_CENSUS_DESC: "T\xE9l\xE9verser et faire valider votre CSV",
  BULK_UPLOAD_UPLOAD_CENSUS_TITLE: "T\xE9l\xE9verser le recensement",
  BULK_UPLOAD_DOWNLOAD_TEMPLATE_DESC: "S\xE9lectionner les champs \xE0 mettre \xE0 jour",
  BULK_UPLOAD_DOWNLOAD_TEMPLATE_TITLE: "T\xE9l\xE9charger le mod\xE8le",
  UPDATE_FLEX_DOLLARS_SUCCESS_MESSAGE: "Dollars flexibles de base mis \xE0 jour.",
  UPDATE_FLEX_DOLLARS_LOADED_FLEX_LABEL: "Dollars flexibles de base",
  UPDATE_FLEX_DOLLARS_TOTAL_FLEX_LABEL: "Solde actuel de Dollars flexibles",
  UPDATE_FLEX_DOLLARS_DESCRIPTION: "Cet outil permet de mettre \xE0 jour les Dollars flexibles pour des employ\xE9s sp\xE9cifiques qui subissent un changement de classe en milieu d'ann\xE9e de police. Vous pouvez mettre \xE0 jour le montant de Dollars Flex du participant dans le champ ci-dessous.",
  UPDATE_FLEX_DOLLARS: "Mettre les dollars flexibles \xE0 jour",
  SUSPEND_EMPLOYER: "Suspendre l'employeur",
  ACTIVATE_EMPLOYER: "Activer l'employeur",
  PUBLISH_EMPLOYER: "Publier l'employeur",
  BACK_TO_EMPLOYEES: "Retour au r\xE9pertoire des employ\xE9s",
  SEND_INVITE: "Envoyer une invitation",
  ADD_EMPLOYEE_INTRO: "Une fois son profil sauvegard\xE9, l'employ\xE9 nouvellement ajout\xE9 recevra un courriel l'invitant \xE0 participer \xE0 notre exp\xE9rience d'accueil en ligne.",
  INVITE_AN_EMPLOYEE: "Ajouter un employ\xE9",
  WE_BE_BACK_WITH_SOMETHING_NEW: "Nous serons de retour avec du nouveau!",
  COMING_SOON: "Prochainement",
  NO_NOTIFICATIONS_MESSAGE: "Les mises \xE0 jour concernant des choses importantes comme les soumissions de demandes, les rendez-vous et les achats effectu\xE9s par l'entremise de League appara\xEEtront ici.",
  NO_NOTIFICATIONS_TITLE: "Aucune notification pour l'instant",
  HELP_EMAIL: "aide@league.com",
  ERROR_STATE_MESSAGE: "Nous avons rencontr\xE9 un probl\xE8me inattendu. Essayez de rafra\xEEchir la page.\nSi le probl\xE8me persiste, veuillez contacter {emailLink}.",
  ERROR_STATE_TITLE: "Une erreur est survenue",
  SUCCESS_STATE_TITLE: "C'est r\xE9ussi!",
  SAVING: "Sauvegardement",
  LOADING: "Chargement",
  EMPTY_DATE_REINSTATE_ERROR: "Veuillez s\xE9lectionner une date de r\xE9int\xE9gration",
  EMPTY_DATE_SUSPEND_ERROR: "Veuillez s\xE9lectionner une date de suspension",
  EMPTY_REASON_TERMINATE_ERROR: "Veuillez s\xE9lectionner la raison de la r\xE9siliation",
  EMPTY_DATE_TERMINATE_ERROR: "Veuillez s\xE9lectionner une date de fin d'emploi",
  PLEASE_CONTACT_FOR_SUPPORT: "Pour recevoir de l'aide suppl\xE9mentaire, veuillez contacter aide@league.com.",
  THIRTY_DAYS_LIMIT_REINSTATE_ERROR: "League ne permet pas de r\xE9int\xE9grer des employ\xE9s plus de 30 jours dans le pass\xE9.",
  THIRTY_DAYS_LIMIT_SUSPEND_ERROR: "League ne permet pas de suspendre des employ\xE9s plus de 30 jours dans le pass\xE9.",
  ERROR_CREATING_EMPLOYEE: "Une erreur est survenue et nous n'avons pas pu cr\xE9er le profil de l'employ\xE9(e)",
  BULK_ACTIONS: "Actions en masse",
  REINSTATE_EMPLOYEES: "R\xE9int\xE9grer ces employ\xE9s",
  SUSPEND_EMPLOYEES: "Suspendre ces employ\xE9s",
  TERMINATE_EMPLOYEES: "Retirer ces employ\xE9s",
  TAX_RECEIPTS_LABEL: "\xC9mettre les re\xE7us fiscaux",
  EMPLOYEE_ACTIONS: "Actions",
  REINSTATE_EMPLOYEE: "R\xE9int\xE9grer l'employ\xE9(e)",
  SUSPEND_EMPLOYEE: "Suspendre l'employ\xE9(e)",
  TERMINATE_EMPLOYEE: "Retirer l'employ\xE9(e)",
  EMPLOYEE: "Employ\xE9",
  ONE_PLAN_AVAILABLE: "1 r\xE9gime offert",
  PLANS_AVAILABLE: "{number} r\xE9gimes offerts",
  STEP_PLANS: "R\xE9gimes {benefitName}",
  PLAN: "R\xE9gime",
  CLASS: "Cat\xE9gorie",
  ROLE: "R\xF4le",
  STATUS_UPDATED: "Le statut a \xE9t\xE9 mis \xE0 jour",
  PAST: "Pass\xE9",
  PENDING: "En attente",
  DRAFT: "\xC9bauche",
  SUSPENDED: "Suspendu",
  READY: "Pr\xEAt",
  ACTIVE: "Actif",
  EMPLOYEES_ALL_SUB_HEADING: "avec ou sans conjoint ou personnes \xE0 charge",
  EMPLOYEES_FAMILY_SUB_HEADING: "avec au moins une personne \xE0 charge autre qu'un conjoint",
  EMPLOYEES_FAMILY_HEADING: "Employ\xE9s membres d'une famille...",
  EMPLOYEES_COUPLE_SUB_HEADING: "avec un conjoint et sans autres personnes \xE0 charge",
  EMPLOYEES_COUPLE_HEADING: "Employ\xE9s vivant en couple...",
  ALL_EMPLOYEES_CLASS_DESCRIPTION: "Tous les employ\xE9s recevront",
  SINGLE_EMPLOYEES_SUB_HEADING: "sans conjoint ou personne \xE0 charge",
  SINGLE_EMPLOYEES_HEADING: "Employ\xE9s c\xE9libataires...",
  ALLOCATION_CLASS_LABEL: "Cat\xE9gorie d'allocation",
  ALLOCATION_RULE_SET_LABEL: "Cat\xE9gories de relations des employ\xE9s",
  FUNDS_PER_POLICY_LABEL: "Fonds allou\xE9s durant la p\xE9riode du contrat",
  SINGLE_FAMILY: "Individuel / familial",
  SINGLE_COUPLE_FAMILY: "Individuel / individuel + conjoint / familial",
  ALL_EMPLOYEES: "Tous les employ\xE9s",
  BENEFIT_VENDOR_NAME: "par {vendorName}",
  BENEFIT_NAME: "{name} ",
  BENEFIT_END_DATE: "Date de fin",
  BENEFIT_START_DATE: "Date de d\xE9but",
  EMPLOYEES_SEARCH_HINT: "Entrer ou s\xE9lectionner : r\xF4le, cat\xE9gorie, r\xE9gime ou statut",
  FILTER_EMPLOYEES: "Filtrer les employ\xE9s",
  SEARCH_EMPLOYEES: "Rechercher des employ\xE9s",
  ADD_EMPLOYER: "Ajouter un employeur",
  ADD_EMPLOYEE: "Ajouter un employ\xE9",
  BULK_UPLOAD: "T\xE9l\xE9versement group\xE9",
  SETTINGS: "Param\xE8tres",
  OTHER_FIELDS: "Autres champs",
  BENEFITS: "Avantages sociaux",
  REPORT_ID_CLIPBOARD: "Identifiant du rapport copi\xE9 dans le presse-papiers",
  RUN_REPORT: "G\xE9n\xE9rer le rapport",
  SELECTED_REPORT: "Rapport s\xE9lectionn\xE9",
  REQUEST_CHANGE_REPORT_NOTE: "Remarque : ce rapport prendra un certain temps \xE0 produire. Un courriel vous sera envoy\xE9 lorsqu'il sera pr\xEAt.",
  REQUEST_CHANGE_REPORT_EXPLANATION: "Un rapport de changements sera cr\xE9\xE9. Il comparera le rapport que vous avez s\xE9lectionn\xE9 \xE0 un rapport complet nouvellement g\xE9n\xE9r\xE9.",
  REQUEST_CHANGE_REPORT: "Demander un rapport de changements",
  CREATE_CHANGE_REPORT: "Cr\xE9er un rapport de changements",
  DAILY_ASSESSMENT_REPORT_REQUESTED_COPY: "Votre rapport a \xE9t\xE9 demand\xE9. Il devrait \xEAtre g\xE9n\xE9r\xE9 dans quelques minutes, selon le nombre d'entr\xE9es. Le t\xE9l\xE9chargement commencera automatiquement lorsque le rapport est pr\xEAt.",
  PREMIUMS_EXPORT_ERROR_COPY: "Un probl\xE8me est survenu en g\xE9n\xE9rant le rapport. Essayez de g\xE9n\xE9rer le rapport \xE0 nouveau et si vous continuez \xE0 rencontrer des probl\xE8mes, veuillez contacter aide@league.com.",
  PREMIUMS_EXPORT_REQUESTED_COPY: "Votre rapport a \xE9t\xE9 demand\xE9 et un courriel sera envoy\xE9 \xE0 {email} avec un lien pour t\xE9l\xE9charger. Cela pourrait prendre quelques heures \xE0 g\xE9n\xE9rer.",
  EMPLOYER_REPORT_NO_DATA_ERROR_COPY: "Nous n'avons pas pu produire le rapport demand\xE9 car aucune donn\xE9e n\u2019est disponible. Pour obtenir de l'aide, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le \xE0 aide@league.com et nous serons heureux de vous aider.",
  EMPLOYER_REPORT_ERROR_COPY: "Malheureusement, un probl\xE8me est survenu lors de la production du rapport que vous avez demand\xE9. Veuillez essayer \xE0 nouveau. Si le probl\xE8me persiste, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le \xE0 aide@league.com et nous serons heureux de vous aider.",
  EMPLOYER_REPORT_WITH_DATES_NO_DATA_ERROR_COPY: "Nous n'avons pas pu produire le rapport demand\xE9 car aucune donn\xE9e ne correspond \xE0 la date ou aux dates choisies. Veuillez choisir une autre date et essayer \xE0 nouveau. Si le probl\xE8me persiste, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le \xE0 aide@league.com et nous serons heureux de vous aider.",
  EMPLOYER_REPORT_WITH_DATES_ERROR_COPY: "Malheureusement, un probl\xE8me est survenu lors de la production du rapport que vous avez demand\xE9. Veuillez s\xE9lectionner une date et essayer \xE0 nouveau. Si le probl\xE8me persiste, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le \xE0 aide@league.com et nous serons heureux de vous aider.",
  EMPLOYER_REPORT_GENERATED_COPY: "Votre rapport a \xE9t\xE9 produit. Un courriel a \xE9t\xE9 envoy\xE9 \xE0 {email} avec un lien pour t\xE9l\xE9charger le fichier.",
  EMPLOYER_REPORT_REQUESTED_COPY: "Votre rapport a \xE9t\xE9 demand\xE9. Lorsque celui-ci sera pr\xEAt, vous recevrez par courriel \xE0 {email} un lien qui vous permettra de t\xE9l\xE9charger le fichier. La production du rapport peut prendre quelques heures en fonction du nombre de documents \xE0 traiter.",
  SPENDING_ACCOUNT_BALANCE_REPORT_COPY: "Ce rapport pr\xE9sente le solde du compte de d\xE9penses de chaque employ\xE9 \xE0 la date s\xE9lectionn\xE9e. Le rapport renseigne sur les roulements d'allocations des ann\xE9es contractuelles pr\xE9c\xE9dentes, les allocations compl\xE9mentaires ainsi que l'utilisation totale \xE0 ce jour. Le rapport est export\xE9 en format CSV",
  SPENDING_ACCOUNT_BALANCE_REPORT: "Rapport sur le solde du compte de d\xE9penses",
  SPENDING_ACCOUNT_BALANCE: "Solde du compte de d\xE9penses",
  REPORTS_LOADING_NOTE_COPY: "Selon le nombre de documents, ce rapport peut prendre quelques heures \xE0 produire. Nous vous enverrons par courriel un lien pour t\xE9l\xE9charger le rapport une fois qu'il aura \xE9t\xE9 trait\xE9.",
  USAGE_REPORTS_COPY: "Produit un rapport qui comprend une r\xE9partition ligne par ligne des transactions du compte de d\xE9penses de chaque employ\xE9 pour une p\xE9riode donn\xE9e (jusqu'\xE0 12 mois). Le rapport sera t\xE9l\xE9charg\xE9 en format CSV et peut servir \xE0 d\xE9terminer l'utilisation d'avantages sociaux imposables pour les \xE9critures de paie.",
  USAGE_REPORTS: "Rapports d'utilisation",
  USAGE: "Utilisation",
  GET_PREVIOUS_REPORTS_ERROR: "Une erreur est survenue lors du chargement des rapports pr\xE9c\xE9dents. Si vous continuez \xE0 rencontrer des probl\xE8mes, veuillez contacter notre \xE9quipe de service \xE0 la client\xE8le \xE0 aide@league.com et nous serons heureux de vous aider.",
  NO_REPORTS_AVAILABLE: "Aucun rapport n'est disponible.",
  REPORTS: "Rapports",
  EMPLOYEES: "Employ\xE9s",
  RATE: "Tarif (par employ\xE9/mois)",
  UPPER: "Sup\xE9rieur",
  LOWER: "Inf\xE9rieur",
  CONTACT_ERRORED: "\xC9chec de la sauvegarde du contact",
  CONTACT_SAVED: "Le contact a \xE9t\xE9 sauvegard\xE9",
  SAVE_CONTACT: "Sauvegarder le contact",
  CONTACT: "Contact",
  FEES_ERRORED: "\xC9chec de la sauvegarde des frais",
  FEES_SAVED: "Frais sauvegard\xE9s",
  SAVE_FEES: "Sauvegarder les frais",
  PLATFORM_FEE: "Frais de plateforme",
  ADMIN_FEE: "Frais administratifs",
  SUBSCRIPTION_FEE: "Frais de service",
  FEES: "Frais",
  PRE_PAYMENTS_ERRORED: "\xC9chec de la sauvegarde des paiements anticip\xE9s",
  PRE_PAYMENTS_SAVED: "Les paiements anticip\xE9s ont \xE9t\xE9 sauvegard\xE9s",
  SAVE_PRE_PAYMENTS: "Sauvegarder les paiements anticip\xE9s",
  CHARITABLE_GIVING_ACCOUNT: "Compte de bienfaisance",
  CHARITY: "Compte de bienfaisance",
  HEALTH_INSURANCE: "Assurance maladie",
  TRAVEL: "Voyage",
  DRUGS: "M\xE9dicaments",
  HEALTH_REIMBURSEMENT_ARRANGEMENT: "Compte de remboursement des frais de sant\xE9",
  LSA: "CDB",
  LIFESTYLE_SPENDING_ACCOUNT: "Compte de d\xE9penses bien-\xEAtre",
  HSA: "CSS",
  HEALTH_SPENDING_ACCOUNT: "Compte soins de sant\xE9",
  PRE_PAYMENTS: "Paiements anticip\xE9s",
  BILLING_PREFERENCE_ERRORED: "\xC9chec de la sauvegarde des pr\xE9f\xE9rences de facturation",
  BILLING_PREFERENCE_SAVED: "Les pr\xE9f\xE9rences de facturation ont \xE9t\xE9 sauvegard\xE9es",
  PAYMENT_METHOD_ERRORED: "\xC9chec de la sauvegarde du mode de paiement",
  PAYMENT_METHOD_SAVED: "Le mode de paiement a \xE9t\xE9 sauvegard\xE9",
  SAVE_BILLING_PREFERENCE: "Sauvegarder les pr\xE9f\xE9rences de facturation",
  SAVE_PAYMENT_METHOD: "Sauvegarder le mode de paiement",
  PAYMENT_METHOD: "Mode de paiement",
  PAYMENT_AUTO_RENEWAL: "Renouvellement automatique de tous les avantages sociaux, produits et services",
  BILLING_SUBHEADER: "G\xE9rez votre mode de paiement et votre structure de frais actuels",
  ADJUST_END_DATE_EMPTY_ERROR: "Veuillez pr\xE9ciser la date de fin pour cette garantie",
  ADJUST_END_DATE_SUCCESS_MESSAGE: "La date de fin de {benefitName} a \xE9t\xE9 modifi\xE9e!",
  ADJUST_END_DATE_CONFIRMATION_CONFIRM_BTN: "Modifier",
  ADJUST_END_DATE_ERROR: "Veuillez choisir une date \xE0 l'int\xE9rieur de la p\xE9riode couverte par le contrat actuel",
  SELECT_DATE: "<strong>S\xE9lectionner la date</strong>",
  ADJUST_END_DATE_CONFIRMATION_NOTE: "<strong>Note :</strong> Vous pouvez aussi remplacer cette date par une date pass\xE9e.",
  ADJUST_END_DATE_CONFIRMATION_COPY: "La modification de la date de fin signifie que {benefitName} reste Actif jusqu'\xE0 la nouvelle date que vous s\xE9lectionnez. Cette garantie prendra fin ou a pris fin le {benefitEndDate}. Quelle date souhaitez-vous fixer comme nouvelle date de fin?",
  STATUS_BENEFIT_CONFIRMATION_CONFIRM_BTN: "Confirmer",
  BENEFIT_STATUS_CONFIRMATION_CANCEL_BTN: "Annuler",
  OPTED_OUT_BENEFIT_CONFIRMATION_NOTE: "<strong>Note :</strong> Ceci supprimera la garantie du portefeuille de l'utilisateur.",
  READY_BENEFIT_CONFIRMATION_NOTE: "<strong>Note :</strong> Veuillez v\xE9rifier que la dates d'activation et de fin de la garantie soit exacte. Si la date d'activation de la garantie est ant\xE9rieure \xE0 la date du jour, la garantie deviendra automatiquement <strong>Actifs</strong>.",
  STATUS_BENEFIT_CONFIRMATION_COPY: "Voulez-vous vraiment changer le statut de la garantie {benefitName} de <strong>{benefitStatus}</strong> \xE0 <strong>{newStatus}</strong>?",
  STATUS_BENEFIT_CONFIRMATION_HEADER: "Transition du statut de la garantie",
  REMOVE_BENEFIT_CONFIRMATION_REMOVE_BTN: "Retirer",
  REMOVE_BENEFIT_CONFIRMATION_CANCEL_BTN: "Annuler",
  REMOVE_BENEFIT_CONFIRMATION_COPY: "Voulez-vous vraiment supprimer la garantie {benefitName}? Cette action ne peut pas \xEAtre invers\xE9e.",
  REMOVE_BENEFIT_CONFIRMATION_HEADER: "Supprimer {benefitName}?",
  REMOVE_BENEFIT_PLAN_CONFIRMATION_REMOVE_BTN: "Retirer le r\xE9gime",
  REMOVE_BENEFIT_PLAN_CONFIRMATION_CANCEL_BTN: "Annuler",
  REMOVE_LAST_BENEFIT_PLAN_ERROR_COPY: `Vous ne pouvez pas supprimer le r\xE9gime d'avantages sociaux "{benefitPlanName}". Il doit y avoir au moins une garantie \xE9tablie.`,
  REMOVE_BENEFIT_PLAN_CONFIRMATION_COPY: `Voulez-vous vraiment supprimer le r\xE9gime d'avantages sociaux "{benefitPlanName}" et toutes ses garanties? Cette action ne peut pas \xEAtre annul\xE9e.`,
  REMOVE_BENEFIT_PLAN_CONFIRMATION_HEADER: 'Supprimer "{benefitPlanName}"?',
  REMOVE_BENEFIT_CLASS_CONFIRMATION_REMOVE_BTN: "Supprimer la cat\xE9gorie",
  REMOVE_BENEFIT_CLASS_CONFIRMATION_CANCEL_BTN: "Annuler",
  REMOVE_LAST_BENEFIT_CLASS_ERROR_COPY: `Vous ne pouvez pas supprimer la cat\xE9gorie d'avantages sociaux "{benefitClassName}". Il doit y avoir au moins une cat\xE9gorie d'avantages sociaux.`,
  REMOVE_BENEFIT_CLASS_CONFIRMATION_COPY: `Voulez-vous vraiment supprimer la cat\xE9gorie d'avantages sociaux "{benefitClassName}" et tous ses r\xE9gimes et garanties? Cette action ne peut pas \xEAtre annul\xE9e.`,
  REMOVE_BENEFIT_CLASS_CONFIRMATION_HEADER: 'Supprimer "{benefitClassName}"?',
  SIGN_UPS_CURRENTLY_CLOSED: "Les inscriptions sont actuellement ferm\xE9es.",
  SIGN_ME_UP: "Inscrivez-moi",
  YOURE_ALREADY_SIGNED_UP: "Vous \xEAtes d\xE9j\xE0 inscrit!",
  WANT_TO_BE_THE_FIRST_TO_KNOW: "Vous d\xE9sirez conna\xEEtre en avant-premi\xE8re la date de notre lancement? D'un seul clic, profitez d'un acc\xE8s rapide et exclusif, puis surveillez vos courriels pour plus de d\xE9tails.",
  FREE_FOR_ALL_LEAGUE_MEMBERS: "GRATUIT pour les membres de League!",
  STAY_MOTIVATED_BULLET: "D\xE9fis quotidiens, contenu sant\xE9 et promotions exclusives.",
  STAY_MOTIVATED: "Gardez votre motivation!",
  EARN_POINTS_DAILY_BULLET: "Suivez vos progr\xE8s et soyez r\xE9compens\xE9.",
  EARN_POINTS_DAILY: "Accumulez des points quotidiennement!",
  JOIN_THE_MOVEMENT_BULLET: "Des programmes exclusifs con\xE7us par des professionnels de la sant\xE9 agr\xE9\xE9s.",
  JOIN_THE_MOVEMENT: "Rejoignez le mouvement!",
  HEALTH_GOALS_INTRO: "Voici notre nouveau programme b\xEAta ! Notre objectif : am\xE9liorer votre sant\xE9 en vous facilitant la t\xE2che.",
  BE_HEALTHIER_BE_REWARDED: "Am\xE9liorez votre sant\xE9, les r\xE9compenses suivront",
  JOIN_OUR_EXCLUSIVE_BETA: "BIENT\xD4T - D\xC8S JANVIER 2018",
  GET_REWARDED: "Obtenez des r\xE9compenses",
  GET_INSPIRED: "Trouvez de l'inspiration",
  DURATION: "Dur\xE9e",
  PHONE_NUMBERS: "Num\xE9ros de t\xE9l\xE9phones",
  PHONE_NUMBER: "Num\xE9ro de cellulaire",
  MORE_ARROW: "Plus >",
  WEEK: "Semaine",
  SEMI_MONTHLY: "Bi-mensuelle",
  BI_WEEKLY: "Bi-hebdomadaire",
  MONTHLY_DRAW: "Retenue mensuelle",
  PAY_PERIOD: "P\xE9riode de paie",
  POLICY_PERIOD: "Dur\xE9e du contrat",
  YEARLY: "Annuellement",
  MONTHLY: "Mensuellement",
  END_DATE: "Se termine le {date}",
  DISCOUNT_OFF: "rabais de {discount}",
  FREE: "GRATUIT",
  SETUP_YOUR_BENEFITS_INTRO: 'Choisissez les avantages sociaux propos\xE9s aux employ\xE9s de votre entreprise. Cr\xE9ez une "cat\xE9gorie" pour segmenter vos employ\xE9s. Cr\xE9ez un "r\xE9gime" pour permettre \xE0 vos employ\xE9s de choisir parmi diff\xE9rents ensembles d\u2019avantages sociaux. \n',
  RESET: "R\xE9initialiser",
  REMOVE: "Retirer",
  RENAME_BENEFITS_PLAN_PLACEHOLDER: "Entrer le nom du r\xE9gime...",
  RENAME_BENEFITS_PLAN: "Renommer",
  REMOVE_BENEFITS_PLAN: "Retirer",
  RENAME_BENEFITS_CLASS_PLACEHOLDER: "Entrer le nom de la cat\xE9gorie...",
  RENAME_BENEFITS_CLASS: "Renommer",
  REMOVE_BENEFITS_CLASS: "Retirer",
  REMOVE_USER_BENEFIT: "Supprimer la garantie",
  ADJUST_END_DATE: "Modifier la date de fin",
  PREVIEW_BENEFIT: "Aper\xE7u de la garantie",
  REMOVE_BENEFIT: "Retirer la garantie",
  RENEW_BENEFIT: "Renouveler la garantie",
  PUBLISH_BENEFIT: "Publier la garantie",
  SEARCH_BENEFITS_PLACEHOLDER: "Rechercher des garanties",
  ADD_BENEFIT: "Ajouter une garantie",
  ADD_BENEFITS_PLAN_PLACEHOLDER: "Entrer le nom du r\xE9gime...",
  ADD_BENEFITS_PLAN: "Ajouter un r\xE9gime",
  ADD_BENEFITS_CLASS_PLACEHOLDER: "Entrer le nom de la cat\xE9gorie...",
  ADD_BENEFITS_CLASS: "Ajouter une cat\xE9gorie",
  INVITES: "Invitations",
  ACTIVE_USERS: "Utilisateurs actifs",
  NO_RESULTS: "Aucun r\xE9sultat",
  SEARCH_EMPLOYERS: "Rechercher des employeurs",
  ALL_EMPLOYERS: "Tous les employeurs",
  EDIT_COMPANY_ERROR_TITLE: "Une erreur est survenue et nous n\u2019avons pas pu mettre \xE0 jour votre profil.",
  EDIT_COMPANY_INTRO: "Sauf indication contraire, tous les champs ci-dessous sont obligatoires.",
  EDIT_COMPANY_TITLE: "Modifier le profil de {companyName}",
  CREATED_ON: "Cr\xE9\xE9 le",
  CREATE_EMPLOYER_ERROR_TITLE: "Une erreur est survenue et nous n\u2019avons pas pu cr\xE9er votre profil",
  CREATE_EMPLOYER_INTRO: "Pour cr\xE9er un profil d\u2019employeur, nous devons en savoir un peu plus sur l'entreprise. Sauf indication contraire, tous les champs ci-dessous sont obligatoires.",
  CREATE_EMPLOYER_TITLE: "Cr\xE9er un nouvel employeur",
  CAD_DOLLAR: "CAD - dollar canadien",
  US_DOLLAR: "USD - dollar des \xC9tats-Unis",
  EMPLOYER_NAME: "Nom de l'employeur",
  COUNTRY: "Pays",
  CURRENCY: "Devise",
  EMAIL_ADDRESS: "Adresse courriel",
  DISABLE_EMAIL_SETTING_CHECKBOX_LABEL: "Courriel",
  ADDRESS: "Adresse",
  OPTIONAL: "Facultatif",
  UNIT_SUITE: "Appartement/suite",
  STATE: "\xC9tat",
  PROVINCE_COUNTY: "Province/Comt\xE9",
  PROVINCE: "Province",
  CITY_TOWN: "Ville/municipalit\xE9",
  CITY: "Ville",
  ZIP_CODE: "Code ZIP",
  POSTAL_CODE: "Code postal",
  SALESFORCE_ID: "Identifiant de Salesforce",
  GROUP_ID: "Identifiant du groupe",
  DAYS: "Jours",
  WAITING_PERIOD: "P\xE9riode d'attente",
  SAVE_EMPLOYER: "Sauvegarder l'employeur",
  INVOICES: "Factures",
  BILLING: "Facturation",
  SALESFORCE_SYNC_ENABLE_SUCCESS_MESSAGE: "Les modifications ont bien \xE9t\xE9 enregistr\xE9es.",
  SALESFORCE_SYNC_ENABLE_BUTTON: "Sauvegarder",
  SALESFORCE_SYNC_COBRA_DESCRIPTION: "COBRA Administration \u2013 Aux \xC9tats-Unis, lorsque vous perdez ou quittez votre travail, vous pouvez continuer \xE0 \xEAtre temporairement couvert par l\u2019assurance-maladie offerte par votre employeur gr\xE2ce \xE0 la loi f\xE9d\xE9rale COBRA. Le r\xF4le de League est d\u2019inscrire les membres licenci\xE9s aupr\xE8s du groupe du vendeur COBRA en soumettant les informations de licenciement au vendeur COBRA, qui prendra alors la rel\xE8ve.",
  SALESFORCE_SYNC_NOTE: "REMARQUE\xA0: Une fois activ\xE9e, les tickets commenceront \xE0 \xEAtre g\xE9n\xE9r\xE9s dans les 48 heures. Veiller \xE0 ce que l\u2019adh\xE9sion soit finalis\xE9e et \xE0 ce que le fichier d\u2019adh\xE9sion initiale ait bien \xE9t\xE9 envoy\xE9 \xE0 l\u2019assurance.",
  SALESFORCE_SYNC_INTRO: "Dans la liste ci-dessous, s\xE9lectionnez ou d\xE9s\xE9lectionnez des vendeurs pour activer la Synchronisation des vendeurs de produits. Une fois activ\xE9e, des tickets Salesforce seront g\xE9n\xE9r\xE9s pour les associ\xE9s aux op\xE9rations, et ce pour chaque vendeur, pour tout ce qui concerne les modifications apport\xE9es par le membre aux donn\xE9es d\xE9mographiques ou l\u2019admissibilit\xE9 des membres aux avantages.",
  SALESFORCE_SYNC_TAB_LABEL: "Notifications Salesforce",
  WALLET_DETAILS: "D\xE9tails du portefeuille",
  PRICING_CONFIG: "Configurations des prix",
  ENROLLMENT_DESIGN: "Conception du processus d'inscription",
  PLAN_DESIGN: "Conception du r\xE9gime",
  PROFILE: "Profil",
  NOTE: "Remarque",
  INSIGHTS: "Aper\xE7u",
  EMPLOYERS: "Employeurs",
  CLAIMS: "Demandes",
  NOTHING_MORE_TO_SEE_HERE: "Il n'y a plus rien \xE0 voir ici!",
  YOU_HAVENT_RECEIVED_ANY_NOTIFICATIONS: "Vous n'avez re\xE7u aucune notification r\xE9cemment.",
  LOADING_NOTIFICATIONS: "Chargement des notifications",
  NOTIFICATIONS: "Notifications",
  LAST_3_MONTHS: "Trois derniers mois",
  LAST_MONTH: "Dernier mois",
  LAST_14_DAYS: "14 derniers jours",
  TODAY: "Aujourd'hui",
  COVERAGE_NOT_COVERED_BY_YOUR_POLICY: "N'est pas couvert par votre contrat",
  CHAT_WITH_US_LOWERCASE: "clavardez avec nous.",
  CHAT_WITH_US: "Clavardez avec nous",
  CHECK_COVERAGE_WALLET_LINK: "V\xE9rifier la couverture",
  CHECK_COVERAGE_TOOLTIP_TEXT: "Ceci pourrait \xEAtre couvert par {name}. {walletLink} ou {chatLink}",
  PARTIAL_TOOLTIP_TEXT: "Ce produit est couvert, mais avec la restriction suivante :",
  COVERED_TOOLTIP_TEXT: "Ce produit est enti\xE8rement couvert par votre {name}.",
  CHECK_COVERAGE_TOOLTIP_TITLE: "V\xE9rifiez votre couverture",
  PARTIAL_TOOLTIP_TITLE: "Couvert, avec des restrictions",
  COVERED_TOOLTIP_TITLE: "Couvert",
  NOT_COVERED_BY_YOUR_POLICY: "N'est pas couvert par votre contrat",
  OPEN_CHAT: "Ouvrir le clavardage",
  SEE_DETAILS: "Voir les d\xE9tails",
  WAS_REQUESTED: "le montant demand\xE9 est de {amount}. ",
  SUBMITTED_CLAIM_ON: "a soumis une demande le",
  WAS_APPROVED: "{amount} a \xE9t\xE9 approuv\xE9.",
  AND: "et",
  REJECTION_REASON: "Raison du refus : {reason} ",
  BY: "Par ",
  ON: "sur",
  CURRENT_STATUS: "Statut actuel",
  EMPLOYER: "Employeur",
  QLE_ENROLLMENT: "INSCRIPTION  \xC0 LA SUITE D'UN \xC9VA",
  ENROLLMENT_YEAR: "Adh\xE9sion annuelle {planName}",
  ENROLLMENT: "Adh\xE9sion",
  INFORMATION: "Renseignements",
  IMAGES: "Images",
  DETAILS: "D\xE9tails",
  AGENT_RESPONSE: "R\xE9ponse du conseiller",
  PURCHASE_DATE: "Date d'achat",
  LIFESTYLE_SPENDING: "D\xE9penses bien-\xEAtre :",
  HEALTH_SPENDING: "D\xE9penses soins de sant\xE9 :",
  DELETE: "Supprimer",
  EDIT: "Modifier",
  NOTES: "Remarques",
  MY_BAD: "Mon erreur, d\xE9sol\xE9...",
  FILTER_LOG: "Filtrer le journal",
  VISIBLE_TO_MEMBER: "Visible par le membre",
  ONLY_VISIBLE_TO_ADMIN: "Visible seulement par les administrateurs de League",
  ADD: "Ajouter",
  ADD_NOTE: "Ajouter une remarque",
  RESEND_PAYMENT: "Renvoyer le paiement",
  AMOUNT: "Montant",
  INTERAC_ID: "Identifiant Interac",
  PAYMENT: "Paiement",
  PAYMENT_ID: "Identifiant du paiement",
  PAYMENT_STATUS: "\xC9tat du paiement",
  NEW_BENEFIT_ERROR_MESSAGE: "D\xE9sol\xE9, une erreur est survenue lors de l'ajout de la garantie",
  NEW_BENEFIT_SUCCESS_MESSAGE: "C'est r\xE9ussi! La garantie a \xE9t\xE9 ajout\xE9",
  IMPORT_PLAN_DESIGN: "Importer la conception du r\xE9gime",
  EMPLOYEE_SEARCH_HINT: "Entrer le nom ou le courriel de l'employ\xE9(e)",
  EMPLOYER_SEARCH_HINT: "Entrer le nom ou l'identifiant de l'employeur",
  EMPLOYEE_PROFILE_ERROR: "D\xE9sol\xE9, une erreur est survenue lors de la cr\xE9ation du profil de l'employ\xE9(e).",
  EMPLOYEE_PROFILE_SAVED: "Le profil de l'employ\xE9(e) a \xE9t\xE9 sauvegard\xE9!",
  NEW_EMPLOYEE_SUCCESS_MESSAGE: "C'est r\xE9ussi! L'employ\xE9 a \xE9t\xE9 ajout\xE9 \xE0 {group}.",
  NEW_EMPLOYER: "Nouvel employeur",
  SEARCH_HINT: "Recherche par identifiant de demande, nom d'utilisateur ou courriel",
  CLEAR: "Effacer",
  RESULTS_FOR: "R\xE9sultats pour {query}",
  RESULTS_FOR_ALL_CLAIMS: 'R\xE9sultats pour "toutes les demandes"',
  FILTER_BY_STATUS: "Filtrer par statut",
  FILTER_BY_MODIFIED_BY: "Filtrer par auteur des modifications",
  SEARCH_CLAIMS: "Rechercher des demandes",
  WALLET_INDEX_NAV: "{current} sur {total}",
  ALL: "Toutes",
  VIEW_WALLET_IN_NEW_TAB: "Ouvrir le portefeuille dans un nouvel onglet",
  WALLET: "Portefeuille",
  LOADING_CLAIMS: "Chargement des demandes\u2026",
  ALL_CLAIMS: "Toutes les demandes",
  CLOSED: "Ferm\xE9e",
  VOIDED_BY_LEAGUE: "Annul\xE9e par League",
  PENDING_TRANSFER: "En attente de transfert",
  SETTLED: "R\xE9gl\xE9e",
  SUBMITTED: "Soumise",
  SUBMITTED_ON: "Soumise le",
  SUBMITTED_FOR_PREAPPROVAL: "Soumise pour approbation pr\xE9alable",
  UPDATE_CLAIM: "Mettre \xE0 jour la demande",
  REJECTED: "Refus\xE9e",
  PREAPPROVED: "Pr\xE9approuv\xE9e",
  PREAPPROVAL_REJECTED: "Pr\xE9approbation refus\xE9e",
  PREAPPROVAL_MORE_INFORMATION_NEEDED: "Pr\xE9approbation \u2013 Renseignements compl\xE9mentaires",
  PENDING_EXTERNAL_REVIEW: "Envoy\xE9 pour \xE9valuation externe",
  PAID: "Pay\xE9e",
  MORE_INFORMATION_NEEDED: "A besoin de renseignements",
  MODIFIED_ON: "Modifi\xE9 le",
  MODIFIED_BY: "Modifi\xE9 par",
  CANCELLED: "Annul\xE9e",
  APPROVED: "Approuv\xE9e",
  STARTED: "Commenc\xE9",
  STATUS: "Statut",
  USER_ID: "Identifiant de l\u2019utilisateur",
  USER: "Utilisateur",
  CLAIM_ID: "Identifiant de la demande",
  LEAGUE: "League",
  CHANGE_EMPLOYEE_CLASS_ERROR_MESSAGE: "Une erreur est survenue au moment de d\xE9terminer le type de changement de cat\xE9gorie :",
  CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_BULLET_1: "<strong>Un ticket sera cr\xE9\xE9 dans la file d'attente Ops Benadmin pour contacter le membre et collecter les s\xE9lections pour les garanties requises</strong>",
  CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_CONTENT_1: "Le changement de cat\xE9gorie de garanties que vous avez activ\xE9 n\xE9cessite la s\xE9lection d'un membre. Voici ce qui se passera une fois le changement confirm\xE9 :",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_6: "Tout compte de d\xE9penses qui n'est plus offert sera supprim\xE9 et entrera dans le d\xE9lai de gr\xE2ce",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_5: "Tout nouveau compte de d\xE9penses sera ajout\xE9 avec un solde calcul\xE9 au prorata",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_4: "Tout compte de d\xE9penses existant sera attribu\xE9 \xE0 la nouvelle cat\xE9gorie et son solde sera mis \xE0 jour \xE0 l'affectation de la nouvelle cat\xE9gorie",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_3: "Toute garantie qui n'est plus offerte sera d\xE9sactiv\xE9e et entrera dans le d\xE9lai de gr\xE2ce.",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_2: "Toute garantie r\xE9cemment offerte (ne n\xE9cessitant pas de s\xE9lections) sera affect\xE9e automatiquement. Sa <em>date de prise d'effet</em> sera similaire \xE0 la date de changement de cat\xE9gorie.",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_1: "Les garanties actuelles des membres seront attribu\xE9es \xE0 la nouvelle cat\xE9gorie et conserveront la m\xEAme <em>date de prise d'effet</em>",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_CONTENT_1: "Le changement de cat\xE9gorie de garanties que vous avez activ\xE9 est admissible \xE0 <strong>l'affectation automatique.</strong> Voici ce qui se passera une fois le changement confirm\xE9 :",
  CHECKING_CLASS_CHANGE_TYPE: "V\xE9rification du type de changement de cat\xE9gorie...",
  CHANGE_EMPLOYEE_CLASS_CONFIRM: "Changer de cat\xE9gorie",
  CHANGE_EMPLOYEE_CLASS_CANCEL: "Annuler",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_4: "Tout solde de compte de d\xE9penses sera transf\xE9r\xE9 aux nouveaux comptes de d\xE9penses correspondants.",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_3: "Si l\u2019employ\xE9 dispose d'options quant au type de couverture et \xE0 la s\xE9lection du r\xE9gime, il devra se connecter pour activer ses avantages sociaux.",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_2: "Le r\xE9gime d'avantages sociaux actuel de l'employ\xE9 demeurera en vigueur jusqu'\xE0 l'activation d'un nouveau r\xE9gime d'avantages sociaux.",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_1: "Modifier la cat\xE9gorie d'un employ\xE9 entra\xEEnera les changements suivants :",
  CHANGE_EMPLOYEE_CLASS_HEADER: "Modification de la cat\xE9gorie de {employee}",
  REMOVE_ADMIN_ROLE_BUTTON: "Supprimer comme administrateur",
  REMOVE_ADMIN_ROLE_TEXT: "En supprimant le r\xF4le administrateur de cet utilisateur, il n'aura plus :",
  REMOVE_ADMIN_ROLE_DESCRIPTION: "\xCAtes-vous s\xFBr de vouloir supprimer l'autorisation administrateur accord\xE9e \xE0 l'employ\xE9 {name}?",
  REMOVE_ADMIN_ROLE_HEADER: "Supprimer comme administrateur",
  ADD_ADMIN_ROLE_BUTTON: "Ajouter comme administrateur",
  ADD_ADMIN_ROLE_TEXT: "En devenant administrateur, cet utilisateur aura :",
  ADD_ADMIN_ROLE_DESCRIPTION: "\xCAtes-vous s\xFBr de vouloir accorder une autorisation administrateur \xE0 l'employ\xE9 {name}?",
  ADD_ADMIN_ROLE_HEADER: "Ajouter comme administrateur",
  RESEND_INVITE_EMAIL_ERROR: "D\xE9sol\xE9, une erreur est survenue lors du renvoi du courriel d'invitation.",
  RESEND_INVITE_EMAIL_SUCCESS: "Le courriel d'invitation a \xE9t\xE9 renvoy\xE9.",
  RESEND_INVITE_EMAIL: "Renvoyer le courriel d'invitation",
  COPY_INVITE_URL_CONFIRMATION_BTN: "Poursuivre",
  COPY_INVITE_URL_CONFIRMATION_CONTENT: "L'URL d'invitation ({url}) a \xE9t\xE9 copi\xE9e dans votre presse-papiers. Collez l'URL o\xF9 vous le d\xE9sirez.",
  COPY_INVITE_URL_CONFIRMATION_HEADER: "L\u2019URL d\u2019invitation a \xE9t\xE9 copi\xE9e",
  COPY_INVITE_URL: "Copier l\u2019URL d\u2019invitation",
  REINSTATE_CONFIRMATION_BUTTON: "R\xE9int\xE9grer l\u2019employ\xE9",
  SCHEDULE_REINSTATE_CONFIRMATION_LABEL: "Date de r\xE9int\xE9gration",
  REINSTATE_CONFIRMATION_DESCRIPTION: "\xCAtes-vous s\xFBr de vouloir r\xE9int\xE9grer l\u2019employ\xE9 {name}? Choisissez une date de r\xE9int\xE9gration pour l\u2019employ\xE9 ci-dessous.",
  REINSTATE_CONFIRMATION_HEADER: "R\xE9int\xE9grer {name}",
  SUSPENSION_EMPLOYER_CONFIRMATION_DESCRIPTION: "Voulez-vous vraiment suspendre l\u2019employeur {name}?",
  SUSPENSION_CONFIRMATION_BUTTON: "Suspendre l\u2019employ\xE9",
  SCHEDULE_SUSPENSION_CONFIRMATION_LABEL: "Date de suspension",
  SUSPENSION_CONFIRMATION_DESCRIPTION: "Le fait de suspendre {name} mettra \xE0 jour les dates d\u2019expiration de ses avantages sociaux. Choisissez une date de suspension pour l\u2019employ\xE9 ci-dessous.",
  SUSPENSION_CONFIRMATION_HEADER: "Suspendre {name}",
  TERMINATION_CONFIRMATION_BUTTON: "Retirer l'employ\xE9",
  SCHEDULE_TERMINATION_CODE_LABEL: "Raison de la r\xE9siliation",
  SCHEDULE_TERMINATION_CONFIRMATION_LABEL: "Date de fin d'emploi",
  TERMINATION_CONFIRMATION_DESCRIPTION: "Le fait de retirer {name} mettra \xE0 jour les dates d\u2019expiration de ses avantages sociaux. Choisissez une date de fin d'emploi pour l\u2019employ\xE9 ci-dessous.",
  TERMINATION_CONFIRMATION_HEADER: "Retirer {name}",
  BULK_REINSTATE_CONFIRMATION_BUTTON: "R\xE9int\xE9grer les employ\xE9s",
  BULK_REINSTATE_CONFIRMATION_DESCRIPTION: "Voulez-vous vraiment r\xE9int\xE9grer les employ\xE9s s\xE9lectionn\xE9s?",
  BULK_REINSTATE_CONFIRMATION_HEADER: "R\xE9int\xE9grer les employ\xE9s",
  BULK_SUSPENSION_CONFIRMATION_BUTTON: "Suspendre les employ\xE9s",
  BULK_SUSPENSION_CONFIRMATION_DESCRIPTION: "Voulez-vous vraiment suspendre les employ\xE9s s\xE9lectionn\xE9s?",
  BULK_SUSPENSION_CONFIRMATION_HEADER: "Suspendre les employ\xE9s",
  BULK_TERMINATION_CONFIRMATION_BUTTON: "Retirer les employ\xE9s",
  BULK_TERMINATION_CONFIRMATION_DESCRIPTION: "Voulez-vous vraiment retirer les employ\xE9s s\xE9lectionn\xE9s?",
  BULK_TERMINATION_CONFIRMATION_HEADER: "Retirer les employ\xE9s",
  ADD_DEPOSIT_ACCOUNT: "Ajouter un compte de d\xE9p\xF4t",
  QUICKLY_ADD_YOUR_BANKING_INFO: "Ajoutez rapidement vos informations bancaires pour recevoir les paiements de vos demandes.",
  NO_DEPOSIT_ACCOUNTS_CONNECTED: "Vous n'avez pas de comptes de d\xE9p\xF4t li\xE9s",
  REIMBURSEMENT_METHODS_APPLY_ONLY_TO: "Ces m\xE9thodes de remboursement ne s\u2019appliquent qu\u2019aux comptes de d\xE9penses League.",
  INTERAC_E_TRANSFER: "Virement Interac",
  DIRECT_DEPOSIT: "D\xE9p\xF4t direct",
  MAKE_DEFAULT: "D\xE9finir par d\xE9faut",
  DEFAULT: "Par d\xE9faut",
  PASSCODE: "Mot de passe",
  COPIED_INTERAC_CODE: "Code Interac copi\xE9",
  COPY: "Copier",
  NEW_PASSCODE_GENERATED_SUCCESS: "Nouveau mot de passe g\xE9n\xE9r\xE9",
  DEFAULT_PAYEE_ERROR: "Le compte par d\xE9faut n\u2019a pas pu \xEAtre modifi\xE9",
  DEFAULT_PAYEE_SUCCESS: "Compte par d\xE9faut modifi\xE9",
  INTERAC: "Interac",
  CONFIRM_ACCOUNT_INFORMATION: "Confirmer les informations du compte",
  ADD_ACCOUNT: "Ajouter un compte",
  IBAN_BASED_ON_EXAMPLE_BELOW: "Vous pouvez trouver votre num\xE9ro IBAN comme sur l\u2019exemple ci-dessous, par le biais de vos services bancaires en ligne, ou sur vos relev\xE9s de compte.",
  ACCOUNT_NUMBER: "Num\xE9ro de compte",
  BRANCH_IDENTIFIER: "Code identificateur de la succursale",
  BANK_IDENTIFIER: "Code identificateur de banque",
  CHECK_NUMBER: "Num\xE9ro de ch\xE8que",
  COUNTRY_CODE: "Code pays",
  HOW_CAN_I_FIND_IBAN_NUMBER: "Comment trouver mon num\xE9ro IBAN?",
  USE_AS_DEFAULT_DEPOSIT_ACCOUNT: "Utiliser ce compte comme mon compte de d\xE9p\xF4t par d\xE9faut",
  ENTER_IBAN_NUMBER: "Entrer un num\xE9ro IBAN",
  IBAN_NUMBER_WITH_ACCOUNT_NUMBER: "<strong>Num\xE9ro IBAN</strong> : {accountNumber}",
  IBAN_NUMBER: "Num\xE9ro IBAN",
  WHERE_CAN_I_FIND_THIS_INFORMATION: "O\xF9 se trouvent ces renseignements?",
  ENTER_YOUR_BANK_INFORMATION: "Entrez vos renseignements bancaires pour que les paiements soient d\xE9pos\xE9s directement dans votre compte. ",
  ACCOUNT_INFORMATION: "Renseignements sur le compte",
  DEPOSIT_METHODS_APPLY_ONLY_TO: "Ces m\xE9thodes de d\xE9p\xF4t ne s'appliquent qu'aux comptes de d\xE9penses de League.",
  SELECT_DEPOSIT_METHOD: "S\xE9lectionnez la m\xE9thode de d\xE9p\xF4t que vous souhaitez ajouter.",
  BACK_TO_BANKING_INFO: "Retour aux renseignements bancaires",
  SUCCESS_ACCOUNT_ADDED: "C\u2019est r\xE9ussi! Le compte a \xE9t\xE9 ajout\xE9.",
  BANKING_INFO: "Renseignements bancaires",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DEPOSIT_ACCOUNT: "Voulez-vous vraiment supprimer ce compte de d\xE9p\xF4t ? Cette op\xE9ration est irr\xE9versible.",
  DELETE_ACCOUNT: "Supprimer le compte",
  BY_DELETING_THIS_PAYMENT_METHOD: "Si vous supprimez ce mode de paiement, vous ne pourrez plus recevoir de paiements par ce moyen. Pour que tous les paiements de  demandes soient trait\xE9s correctement, assurez-vous d'avoir mis en place au moins un mode de paiement.",
  IBAN_NUMBER_IS_REQUIRED: "Le num\xE9ro IBAN est requis.",
  DELETE_DIRECT_DEPOSIT: "Supprimer le d\xE9p\xF4t direct",
  EDIT_DIRECT_DEPOSIT: "Modifier le d\xE9p\xF4t direct",
  HOW_CAN_I_FIND_MY_IBAN_NUMBER: "Comment trouver mon IBAN?",
  ENTER_YOUR_IBAN_NUMBER_TO_HAVE_PAYMENTS_DIRECTLY_DEPOSITED: "Entrez votre num\xE9ro IBAN pour que les paiements soient d\xE9pos\xE9s directement dans votre compte. ",
  YOUR_DEPOSIT_ACCOUNT_WAS_DELETED: "Votre compte de d\xE9p\xF4t a \xE9t\xE9 supprim\xE9.",
  WE_SAVED_YOUR_CHANGES: "Nous avons sauvegard\xE9 vos modifications.",
  AN_ISSUE_EDITING_YOUR_ACCOUNT: "Il y a eu un probl\xE8me lors de la modification de votre compte, veuillez r\xE9essayer. Si ce probl\xE8me persiste, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le.",
  AN_ISSUE_ADDING_YOUR_ACCOUNT: "Il y a eu un probl\xE8me lors de l'ajout de votre compte, veuillez r\xE9essayer. Si ce probl\xE8me persiste, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le.",
  ENTER_YOUR_ACCOUNT_NUMBER_US: "Entrez votre num\xE9ro de compte",
  ENTER_YOUR_ACCOUNT_NUMBER_CA: "Entrez votre num\xE9ro de compte (jusqu'\xE0 12 chiffres)",
  ENTER_YOUR_INSTITUTION_NUMBER: "Entrez le num\xE9ro d'institution \xE0 3 chiffres",
  INSTITUTION_NUMBER: "Num\xE9ro de l'institution",
  ENTER_YOUR_BRANCH_TRANSIT_NUMBER: "Entrez le num\xE9ro de transit de succursale \xE0 5 chiffres",
  BRANCH_TRANSIT_NUMBER: "Num\xE9ro de transit de la succursale",
  ENTER_YOUR_NAME_AS_APPEARS_ON_ACCOUNT: "Entrez votre nom tel qu'il appara\xEEt sur le compte",
  FULL_NAME: "Nom au complet",
  YOUR_NAME: "Votre nom",
  ENTER_YOUR_ROUTING_NUMBER: "Entrez votre num\xE9ro d'acheminement \xE0 9 chiffres",
  ROUTING_NUMBER: "Num\xE9ro d\u2019acheminement",
  YOU_CAN_FIND_DIRECT_DEPOSIT_INFORMATION_ON: "Vous trouverez ces informations sur vos ch\xE8ques personnels, services bancaires en ligne ou relev\xE9s de compte bancaire.",
  INSTITUTION_NUMBER_REQUIRED: "Le num\xE9ro d'institution est requis.",
  EMAIL_REQUIRED: "L'adresse courriel est requise.",
  BRANCH_NUMBER_REQUIRED: "Le num\xE9ro de la succursale est requis.",
  ROUTING_NUMBER_REQUIRED: "Le num\xE9ro d\u2019acheminement est requis.",
  ACCOUNT_NUMBER_REQUIRED: "Le num\xE9ro de compte est requis.",
  ENTER_VALID_CA_ACCOUNT_NUMBER: "Entrez un num\xE9ro de compte de 5 \xE0 12 chiffres valide.",
  ENTER_VALID_INSTITUTION_NUMBER: "Entrez un num\xE9ro d'institution \xE0 3 chiffres valide.",
  ENTER_VALID_EMAIL: "Entrez une adresse courriel valide.",
  ENTER_VALID_BRANCH_TRANSIT_NUMBER: "Entrez un num\xE9ro de transit de succursale de 5 chiffres valide.",
  ENTER_VALID_US_ACCOUNT_NUMBER: "Entrez un num\xE9ro de compte de 4 \xE0 17 chiffres valide.",
  ENTER_VALID_ROUTING_NUMBER: "Entrez un num\xE9ro d'acheminement \xE0 9 chiffres valide.",
  ENTER_VALID_IBAN: "Votre IBAN doit comporter entre 15 et 34 caract\xE8res.",
  ENTER_YOUR_EMAIL_ADDRESS: "Entrez votre adresse courriel",
  INTERAC_USAGE_DESCRIPTION: "Entrez l'adresse courriel o\xF9 vous aimeriez recevoir un avis de transfert \xE9lectronique de fonds. Lorsque vous aurez soumis votre adresse courriel, nous produirons un mot de passe qui vous permettra d'acc\xE9der \xE0 vos fonds. Vous utiliserez ce mot de passe chaque fois que vous recevez un paiement par transfert \xE9lectronique INTERAC.",
  NEW_PASSCODE: "Nouveau mot de passe",
  STRIPE_ERROR: "Erreur lors de la soumission de l'identifiant de membre",
  STRIPE_SUCCESS: "L'identifiant de membre a \xE9t\xE9 soumis",
  SUBMIT_TO_STRIPE: "Soumettre \xE0 Stripe",
  EOI_FORM_ON_UPLOAD: "Tous les formulaires de preuve d'assurabilit\xE9 doivent \xEAtre sign\xE9s \xE0 la main et <strong>envoy\xE9s par la poste directement \xE0 l\u2019assureur</strong>. League n'approuve pas les preuves d'assurabilit\xE9, ce t\xE9l\xE9versement sert seulement de r\xE9f\xE9rence.",
  EOI_FORM_ON_DOWNLOAD: "Tous les formulaires de preuve d'assurabilit\xE9 doivent \xEAtre sign\xE9s \xE0 l'encre et <strong>envoy\xE9s par la poste directement \xE0 l\u2019assureur</strong>. Si ces documents ne sont pas re\xE7us, votre demande d'inscription de couverture facultative sera incompl\xE8te.",
  PLEASE_NOTE: "Veuillez noter",
  SNAIL_MAIL_IT_IS: "Entendu, je l\u2019envoie par la poste!",
  THIS_BOX_MUST_BE_CHECKED_TO_CONTINUE: "Cochez cette case pour continuer",
  VIEW_ALL_CLAIMS: "Voir toutes les demandes",
  YOU_DONT_HAVE_CLAIMS: `Vous n'avez aucune demande "{status}".`,
  UPLOAD: "T\xE9l\xE9verser",
  DOCUMENTS_AND_FORMS: "Documents et formulaires",
  ACTIONS: "Actions",
  EVIDENCE_OF_INSURABILITY: "Preuve d'assurabilit\xE9",
  ALL_UPLOADED_DOCUMENTS_ARE_SECURE: "Tous les documents t\xE9l\xE9vers\xE9s sur League sont s\xE9curis\xE9s et confidentiels. Acc\xE9dez facilement \xE0 tous vos documents t\xE9l\xE9vers\xE9s ci-dessous.",
  NO_FORMS_REQUIRED_FOR_YOUR_BENEFIT_PLAN: "Votre r\xE9gime actuel ne requiert aucun formulaire",
  ABLE_TO_DOWNLOAD_FORMS_HERE: "\xC0 l'avenir, si la conception ou les s\xE9lections de votre r\xE9gime vous obligent \xE0 remplir des formulaires (p. ex. des formulaires de d\xE9signation de b\xE9n\xE9ficiaire), vous pourrez les t\xE9l\xE9charger \xE0 partir d'ici.",
  UPLOADS: "T\xE9l\xE9versements",
  FORMS_AVAILABLE: "Formulaires disponibles",
  FORMS: "Formulaires",
  YOUR_BENEFIT_PLAN_REQUIRES_THE_FOLLOWING_FORMS: "Les formulaires suivants sont n\xE9cessaires pour assurer que vous et vos proches soyez prot\xE9g\xE9s. Des instructions et des informations sont fournies pour vous aider \xE0 les remplir ais\xE9ment.",
  REMOVE_THIS_DOCUMENT: "Supprimer ce document",
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_DOCUMENT: "Voulez-vous vraiment supprimer ce document de votre profil? S'il est requis par League, nous vous recommandons plut\xF4t de le modifier ou de le remplacer.",
  REMOVE_DOCUMENT_QUESTION: "Supprimer ce document?",
  YES_REMOVE: "Oui, supprimer",
  DOCUMENT_FILE_TYPES: "Les formats JPG, PNG et PDF sont pris en charge",
  MAKE_SURE_THE_DOCUMENT: "Assurez-vous que le document...",
  FIELDS_MARKED_WITH_AN_ASTERISK: "Les champs marqu\xE9s d'un ast\xE9risque sont obligatoires",
  PROGRAM_INFO: "Renseignements sur le programme",
  VAL_POINTS: "{points} points",
  POINTS: "Points",
  FEELING_STUCK: "Vous vous sentez bloqu\xE9?",
  NOT_NOW: "Pas maintenant",
  QLE_ENROLLMENT_DISCLAIMER: "Une fois que vous commencez, votre inscription repartira \xE0 z\xE9ro. Vous aurez deux semaines pour choisir les s\xE9lections de votre nouveau r\xE9gime et y ajouter ou en retirer des personnes \xE0 charge.",
  QLE_YOU_QUALIFY: "Vous y avez droit! Voulez-vous commencer \xE0 choisir vos nouvelles s\xE9lections de r\xE9gime?",
  START: "D\xE9but",
  ARIA_CIRCLED_CHECKMARK: "Coche blanche entour\xE9e d'un cercle",
  BASED_ON_YOUR_INFO: "D\u2019apr\xE8s les renseignements que vous avez fournis, il se peut que vous ayez droit \xE0 de nouveaux choix d'avantages sociaux.",
  YOU_RE_ALMOST_DONE: "Vous avez presque termin\xE9",
  RE_ENTER_ENROLLMENT: "Une fois votre requ\xEAte soumise, vous pourrez vous r\xE9inscrire et modifier vos choix de r\xE9gime pour vous et votre famille.",
  ONCE_YOU_SUBMIT_YOU_WILL_BE_ABLE_TO_ADD_HSA: "Lors de votre soumission, vous pourrez ajouter votre personne \xE0 charge \xE0 votre compte de soins de sant\xE9. Ce changement prendra effet imm\xE9diatement et aucune autre action ne vous sera demand\xE9e.",
  SENT_INFORMATION_TO_HR_ADMIN_FOR_FINAL_APPROVAL: "Une fois soumises, vos donn\xE9es seront envoy\xE9es \xE0 votre administrateur des RH pour l'approbation finale.",
  DEADLINE_FOR_BENEFIT_SELECTION_AND_DEPENDENTS: "Vous aurez jusqu\u2019au <strong>{deadlineDate}</strong> pour indiquer vos nouveaux choix d\u2019avantages sociaux et ajouter ou retirer des personnes \xE0 charge.",
  IF_APPROVED_RE_ENTER_ENROLLMENT_FOR_YOURSELF_AND_FAMILY: "Si votre requ\xEAte est approuv\xE9e, vous recevrez un courriel de League vous indiquant la date \xE0 laquelle vous pourrez vous r\xE9inscrire. Lors de l'inscription vous pourrez ajouter et enlever des personnes \xE0 charge et faire de nouveaux choix pour votre r\xE9gime.",
  CONTINUE: "Poursuivre",
  INFORMATION_HAS_BEEN_SENT_FOR_HR_APPROVAL: "Vos donn\xE9es ont \xE9t\xE9 transmises avec succ\xE8s! Si votre demande est approuv\xE9e, vous serez inform\xE9 de la marche \xE0 suivre tout bient\xF4t.",
  INFORMATION_SUCCESSFULLY_SENT: "Vos donn\xE9es ont \xE9t\xE9 transmises avec succ\xE8s!",
  PLEASE_ENTER_A: "Veuillez entrer un {fieldName}.",
  HSA_CONFIRMATION_CHECKBOX: "Je comprends que cette personne \xE0 charge ne sera couverte que par mon compte de soins de sant\xE9. J\u2019atteste que les renseignements fournis sont exacts et soumis \xE0 une approbation finale. Des documents suppl\xE9mentaires peuvent \xEAtre exig\xE9s \xE0 des fins de v\xE9rification. Les personnes \xE0 charge ou les choix peuvent \xEAtre retir\xE9s en tout temps s\u2019il s\u2019av\xE8re qu\u2019ils ne satisfont pas aux crit\xE8res requis.",
  QLE_CONFIRMATION_CHECKBOX: "J'atteste que les renseignements fournis sont exacts et que les modifications propos\xE9es au r\xE9gime sont soumises \xE0 une approbation finale. Des documents suppl\xE9mentaires peuvent \xEAtre exig\xE9s \xE0 des fins de v\xE9rification. Les personnes \xE0 charge ou les choix peuvent \xEAtre retir\xE9s en tout temps s'il s'av\xE8re qu\u2019ils ne satisfont pas aux crit\xE8res requis.",
  VIEW_ACTIVITY: "Voir l'activit\xE9",
  TODAYS_ACTIVITY: "Activit\xE9 d'aujourd'hui",
  DOCUMENT_URL: "URL du document",
  LEARN_MORE: "En savoir plus",
  DID_YOU_RECENTLY: "Vous \xEAtes-vous r\xE9cemment mari\xE9 ou avez-vous r\xE9cemment eu un enfant? Certains \xE9v\xE9nements de la vie sont admissibles pour une r\xE9inscription.",
  HAVE_YOU_RECENTLY_EXPERIENCED_CHANGES: "Avez-vous r\xE9cemment v\xE9cu des changements dans votre vie ou votre famille?",
  FEEDBACK_MAX_SCALE: "Tr\xE8s utile",
  FEEDBACK_MIN_SCALE: "Inutile",
  VIEW_MY_HEALTH_PROFILE: "Voir mon profil sant\xE9",
  VIEW_ALL_PROGRAMS: "Voir tous les programmes",
  CONGRATS_COMPLETED: "F\xE9licitations, vous avez compl\xE9t\xE9",
  YOUVE_EARNED_TOTAL: "Vous avez accumul\xE9 un total de",
  TOMORROWS_ACTIVITY: "Activit\xE9 de demain",
  HELPFUL_TIPS: "Conseils",
  EVENT_DATE_IS_OUT_OF_ACCEPTABLE_DATE_RANGE: "La date de cet \xE9v\xE9nement n\u2019est pas situ\xE9e dans un intervalle de dates valide.",
  WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID: "Nous sommes d\xE9sol\xE9s, la page demand\xE9e a expir\xE9 ou n\u2019est plus valide.",
  ALL_DONE_GO_BACK_HOME: "Vous avez termin\xE9? Vous pouvez retourner \xE0 la page d'accueil",
  DONE: "Termin\xE9",
  GOOD_EVENING: "Bonsoir",
  GOOD_AFTERNOON: "Bonjour",
  GOOD_MORNING: "Bonjour",
  X_MIN_READ: "Temps de lecture de {readTime}",
  VIEW_LESS: "Voir moins.",
  VIEW_MORE: "Voir plus",
  QLE_ENDED_COMMON_LAW_RELATIONSHIP: "Je ne suis plus en union de fait",
  QLE_DATE_OF_GAIN_OF_COVERAGE: "La personne \xE0 ma charge a obtenu une couverture",
  QLE_MOVE_TO_OR_FROM_BC: "J'ai d\xE9m\xE9nag\xE9 en/de la Colombie-Britannique",
  QLE_MOVE_TO_OR_FROM_QUEBEC: "J'ai d\xE9m\xE9nag\xE9 au/du Qu\xE9bec",
  QLE_STUDENT_MOVE_TO_OR_FROM_SCHOOL: "Je suis un \xE9tudiant qui d\xE9m\xE9nage \xE0/de l'\xE9cole",
  QLE_MOVE_TO_DIFFERENT_ZIP_COUNTRY: "J'ai chang\xE9 de code postal ou de pays",
  QLE_LOST_COVERAGE_AS_DEPENDENT: "Je suis une personne \xE0 charge qui perd sa couverture",
  QLE_DEPENDENT_LOST_COVERAGE: "La personne \xE0 ma charge perd sa couverture",
  QLE_CIVIL_UNION: "Je me suis engag\xE9 dans une union civile",
  QLE_FOSTER_CARE_FOR_CHILD: "J'ai commenc\xE9 \xE0 m'occuper d'un enfant",
  QLE_DOMESTIC_PARTNERSHIP: "Je me suis engag\xE9 dans un partenariat domestique",
  QLE_DEPENDENT_DEATH: "Une personne \xE0 charge est d\xE9c\xE9d\xE9e",
  QLE_ADOPTION: "J'ai adopt\xE9 un enfant",
  QLE_SEPARATION: "Je me suis s\xE9par\xE9",
  QLE_DIVORCE: "Je me suis divorc\xE9",
  QLE_BECAME_COMMON_LAW: "J'ai commenc\xE9 une union de fait",
  QLE_BIRTH: "J'ai eu un enfant",
  QLE_MARRIAGE: "Je me suis mari\xE9",
  QLE_TYPE: "Type d'\xE9v\xE9nement de vie",
  HR: "RH",
  ELECTIONS: "Choix",
  NUM_DAYS: "Nombre de jours",
  OFF_CAPS: "\xC9TEINT",
  OFF: "\xC9teint",
  ON_CAPS: "EN MARCHE",
  NEX: "NEX",
  TYPEFORM: "Typeform",
  NONE: "Aucun",
  ALL_PROGRAMS: "Tous les programmes",
  COMPLETE: "Termin\xE9",
  GO_TO_MARKETPLACE: "Aller au March\xE9 Sant\xE9",
  GO_TO_HEALTH_BLOG: "Aller au blogue sant\xE9",
  LEARN_MORE_ABOUT_THE_QUESTION: "En savoir plus sur cette question",
  LEARN_MORE_ABOUT_THE_PROGRAM: "en savoir plus au sujet du programme {name}",
  GOAL_POINTS: "Points d'objectifs",
  NOT_PROVIDED: "Non fourni",
  TITLE_DESCRIPTION: "{title} - {description}",
  HELP_MENU: "Menu d'aide",
  TITLE_BY_PROVIDER: "{title} par {provider}",
  ONLY_CERTAIN_LIFE_EVENTS_QUALIFY_A_MEMBER: "Dans le cas de certains \xE9v\xE9nements de vie seulement, un membre peut modifier ses personnes \xE0 charge ou choisir des garanties diff\xE9rentes. Les param\xE8tres relatifs \xE0 ces \xE9v\xE9nements de vie peuvent \xEAtre configur\xE9s par niveau de classe d'avantages sociaux.",
  HR_EMAILS_FOR_QLE_APPROVALS: "Courriels de l'administrateur des RH pour l'approbation d'\xE9v\xE9nements de vie",
  AUTOMATIC_EMAIL_SENT_TO_HE_EMAILS_PROVIDED: "Un courriel automatique est envoy\xE9 \xE0 la liste fournie. Il s'agit d'une liste de groupe, toute modification apport\xE9e s'appliquant \xE0 l'ensemble du groupe en ce qui a trait aux autres types d'\xE9v\xE9nements de vie configur\xE9s pour n\xE9cessiter une approbation. Pour inclure plusieurs destinataires, s\xE9parer leurs adresses \xE9lectroniques par une virgule.",
  SEPARATE_EMAILS_BY_COMMA: "S\xE9parer plusieurs adresses courriel par une virgule",
  PLEASE_ENTER_HR_APPROVAL_EMAILS: "Veuillez saisir l'adresse courriel ou les adresses courriel du ou des destinataires \xE0 notifier pour approbation.",
  HR_EMAILS_UPDATED: "Courriels RH mis \xE0 jour!",
  SETTINGS_APPLIED: "Les param\xE8tres ont \xE9t\xE9 appliqu\xE9s!",
  INDIVIDUALLY_CONFIGURE_QLES: "Cette section vous permet de configurer le type d'\xE9v\xE9nement de vie pour cette cat\xE9gorie d'avantages sociaux.",
  RECOMMENDED: "Recommand\xE9s pour vous",
  EXIT_HEALTH_ASSESSMENT: "Quitter l'\xE9valuation de sant\xE9",
  OPTIONAL_LINK: "Lien optionnel pour en savoir plus",
  THIS_WILL_TAKE: "Cela prendra environ {minutes} minutes.",
  SELECT_ALL_THAT_APPLY: "S\xE9lectionner tout ce qui s'applique.",
  PRESS_ENTER: "Suivant",
  YOURE_QUESTIONS_AWAY: "Vous \xEAtes \xE0 {number} questions de recevoir {points} points de r\xE9compense.",
  WEVE_PICKED_UP: "Nous reprenons l\xE0 o\xF9 vous \xE9tiez.",
  FOR_PROVIDERS_AND_PHARMACISTS: "Pour les fournisseurs/pharmaciens",
  ONCE_YOU_SUBMIT: "Une fois que vous soumettez, les r\xE9ponses ne peuvent plus \xEAtre modifi\xE9es. Elles constituent votre profil sant\xE9 de base et nous aident \xE0 avoir une id\xE9e plus claire de votre sant\xE9 en comparant vos progr\xE8s au fil du temps.",
  ARE_YOU_READY_TO_SUBMIT: "\xCAtes-vous pr\xEAt \xE0 soumettre?",
  SORRY_QLE_REQUEST_EXPIRED: "D\xE9sol\xE9! La fen\xEAtre pour cette demande a expir\xE9.",
  SORRY_REFRESH_THE_PAGE: "D\xE9sol\xE9! Essayez d\u2019actualiser la page.",
  LEFTOVER_FLEX_HELPER_TEXT: "<strong>{percent}%</strong> de votre {flexDollarTerm} non d\xE9pens\xE9s seront ajout\xE9s \xE0 ce compte",
  WHAT_IS_PRORATION_TOOLTIP: "<p>La r\xE9partition au prorata, c'est quand nous divisons vos fonds proportionnellement \xE0 partir de la date \xE0 laquelle vous avez commenc\xE9 \xE0 travailler pour l'entreprise jusqu'\xE0 la dur\xE9e qu'il vous reste dans la police.</p><p>Par exemple :</p><p>Si Alex a commenc\xE9 au milieu de la p\xE9riode contractuelle, le montant de sa {flexDollarTerm} sera r\xE9duit de moiti\xE9.</p>",
  YOU_CURRENTLY_HAVE_AMOUNT_FLEX_DOLLARS_REMAINING: "Vous avez pr\xE9sentement <strong>{amount}</strong> dans vos {flexDollarTerm}. Voici \xE0 quoi votre cotisation ressemblerait en ce moment :",
  YOU_CURRENTLY_HAVE_NO_FLEX_DOLLARS_REMAINING: "Il ne vous reste aucun {flexDollarTerm}.",
  WHAT_ARE_LEFTOVER_FLEX_DOLLARS_TOOLTIP: "<p>Vous serez inscrit \xE0 ce compte seulement s'il vous reste des {flexDollarTerm} apr\xE8s vos s\xE9lections.</p><p>Par exemple : </p><p>Si le montant de {flexDollarTerm} d'Alex s'\xE9l\xE8ve \xE0 230 $ apr\xE8s la s\xE9lection de tous ses avantages sociaux, ce montant sera ajout\xE9 automatiquement \xE0 un CSS \xE0 la suite de son inscription.</p>",
  THIS_ACCOUNT_IS_FUNDED_BY_LEFTOVER_FLEX_DOLLARS: "Ce compte est financ\xE9 par le reste de vos {flexDollarTerm}.",
  ANY_LEFTOVER_FLEX_DOLLARS_WILL_BE_ADDED_INTO_THIS_ACCOUNT: "Tous {flexDollarTerm} non d\xE9pens\xE9s seront ajout\xE9s \xE0 ce compte",
  HP_ONBOARDING_SUBMIT: "Commen\xE7ons!",
  SKIP: "Sauter",
  KEEP_GOING: "Continuez!",
  FINISH_UP_TO_GET: "Terminez pour obtenir des conseils et du contenu personnalis\xE9s pour soutenir votre virage sant\xE9, plus {points} points de r\xE9compense.",
  LETS_BEGIN: "Commen\xE7ons!",
  FT: "pi",
  YOUR_HEALTH_PROFILE_IS: "Votre profil sant\xE9 est un aper\xE7u de votre sant\xE9 globale, vos habitudes et vos objectifs. Nous utilisons 6 dimensions de la sant\xE9 pour vous fournir les meilleures informations, le meilleur contenu et les meilleurs programmes pour que vous puissiez atteindre vos objectifs personnels.",
  WHY_COMPLETE: "Pourquoi remplir votre profil sant\xE9?",
  ITLL_ONLY_TAKE: "Cela ne prendra que 5 \xE0 10 minutes \xE0 remplir.",
  YOU_HAVENT_BUILT: "Vous n'avez toujours pas compl\xE9t\xE9 votre profil sant\xE9",
  BASED_ON_YOUR_HEALTH_PROFILE: "Selon votre profil sant\xE9",
  PROGRAMS_FOR_YOU: "Des programmes pour vous",
  PAST_CARDS_STATUS: "Consultez les d\xE9tails du d\xE9lai de gr\xE2ce pour la soumission de demandes en s\xE9lectionnant une carte ci-dessous.",
  PENDING_CARDS_STATUS: "Les cartes/garanties suivantes sont en attente d'activation.",
  ACTIVE_CARDS_STATUS: "Soumettez une demande, consultez les d\xE9tails de la couverture et plus encore en s\xE9lectionnant une carte ci-dessous.",
  COMPLETE_ACTIVITIES: "Compl\xE9tez les activit\xE9s pour atteindre vos objectifs de sant\xE9. Recommand\xE9 en fonction de votre profil de sant\xE9.",
  HEALTH_PROGRAMS_FOR_YOU: "Programmes sant\xE9 pour vous",
  PLATFORMS: "Plateformes",
  SDM_ID_HELPER_TEXT: "Veuillez entrer votre num\xE9ro d'identification de membre sans les z\xE9ros ; pour num\xE9ro 0000123456, veuillez entrer 123456.",
  FIRST_OF_FOLLOWING_MONTH: "Le premier mois suivant la date de l'\xE9v\xE9nement de vie admissible",
  QLE_DATE: "Date de l'\xE9v\xE9nement de vie admissible",
  BENEFIT_EFFECT_DATE_TOOLTIP: "Le jour o\xF9 les nouvelles prestations du membre deviennent actives. La date par d\xE9faut est celle de l\u2019\xE9v\xE9nement de vie admissible.",
  BENEFIT_EFFECT_DATE: "Date de prise d\u2019effet de la prestation",
  LADDER_RULES_TOOLTIP: "Si les r\xE8gles par \xE9chelon sont activ\xE9es, les membres peuvent uniquement choisir leur r\xE9gime actuel ou un r\xE9gime d'un \xE9chelon sup\xE9rieur ou inf\xE9rieur \xE0 leur r\xE9gime actuel pendant un \xE9v\xE9nement de vie admissible.\n\nRemarque : Pour que cette fonctionnalit\xE9 soit effective, les r\xE8gles par \xE9chelon doivent \xEAtre configur\xE9es sur la prestation.",
  ENABLE_LADDER_RULES_PROMPT: "Activer les r\xE8gles par \xE9chelon pour la s\xE9lection d'avantages sociaux?",
  READ_OUR_ARTICLE: "Lisez notre article",
  READING_MORE_ABOUT_DIMENSIONS: "Vous souhaitez en savoir plus sur l'impact des dimensions sur votre sant\xE9 g\xE9n\xE9rale?",
  DIMENSION_OF_HEALTH: "DIMENSION DE LA SANT\xC9",
  TOP_FOCUS_AREA: "Domaine prioritaire",
  PROGRAMS: "Programmes",
  HEALTH: "Sant\xE9",
  DEDUCTIBLE: "Franchise",
  OUT_OF_POCKET_MAXIMUM: "De\u0301bourse\u0301 maximum",
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION_LINK: "De\u0301bourse\u0301 maximum",
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION: "Un {oopLink} est le montant le plus \xE9lev\xE9 que vous pouvez payer pendant l'ann\xE9e du contrat. Une fois le maximum atteint, votre r\xE9gime couvrira un pourcentage des co\xFBts futurs pour le reste de cette ann\xE9e de police.",
  FAMILY_DEDUCTIBLE_LINK: "franchise familiale",
  DEDUCTIBLE_DESCRIPTION_2: "Si vous avez une {familyDeductibleLink} et que les co\xFBts combin\xE9s pour vous et vos personnes \xE0 charge couvrent cette franchise, votre r\xE9gime offrira une couverture pour les co\xFBts futurs encourus par votre famille.",
  COINSURANCE_LINK: "coassurance",
  COPAYS_LINK: "quote-part",
  DEDUCTIBLE_DESCRIPTION_1: "La franchise est le montant que vous devez payer de votre poche (avec votre propre argent) au cours d'une ann\xE9e de r\xE9gime avant que le r\xE9gime commence \xE0 couvrir vos prestations. Une fois que vous avez pay\xE9 la franchise, votre r\xE9gime d'assurance vous offre une couverture sous forme de partage des co\xFBts ({copaysLink} ou {coinsuranceLink}). La franchise s'applique \xE0 tous les articles et services couverts par votre r\xE9gime ou \xE0 la plupart d'entre eux.",
  DEDUCTIBLE_DESCRIPTION: "<p>Une franchise est le montant que vous devez payer de votre poche (avec votre propre argent) au cours d'une ann\xE9e de police avant que le r\xE9gime commence votre couverture. Une fois que vous avez pay\xE9 la franchise, votre r\xE9gime d'assurance vous offre une couverture sous forme de partage des co\xFBts (<a href={copays} target='_blank'>quote-part</a> ou <a href={coinsurance} target='_blank'>coassurance</a>). La franchise s'applique \xE0 tous les articles et services couverts par votre r\xE9gime ou \xE0 la plupart d'entre eux.</p><p> Si vous avez une <a href={familyDeductible} target='_blank'>franchise familiale</a> et que les co\xFBts pour vous et vos personnes \xE0 charge combin\xE9s couvrent cette franchise, votre r\xE9gime commencera \xE0 offrir une couverture pour les co\xFBts futurs encourus par votre famille.</p>",
  INDIVIDUAL_OUT_OF_POCKET_MAXIMUM: "D\xE9bours\xE9 maximum individuel",
  FAMILY_OUT_OF_POCKET_MAXIMUM: "D\xE9bours\xE9 maximum pour la famille",
  INDIVIDUAL_DEDUCTIBLE: "Franchise individuelle",
  FAMILY_DEDUCTIBLE: "Franchise familiale",
  OUT_OF_NETWORK: "Hors-R\xE9seau",
  IN_NETWORK: "Au sein du r\xE9seau",
  REFRESH_PAGE: "Actualiser la page",
  WERE_HAVING_TROUBLE: "Nous avons du mal \xE0 charger cette information. Veuillez actualiser la page. Si le probl\xE8me persiste, veuillez contacter {custCareLink}.",
  UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG: "Oh oh! Une erreur est survenue",
  CARRIER: "assureur",
  FROM: "de",
  MEMBER_ID_ERROR_BODY: "Nous avons des difficult\xE9s \xE0 charger ces informations, car votre num\xE9ro d'identification de membre est invalide ou manquant. Veuillez {custCareLink} pour mettre \xE0 jour votre num\xE9ro d'identification de membre.",
  CONTACT_CUSTOMER_CARE: "communiquer avec le service \xE0 la client\xE8le",
  MEMBER_ID_ERROR_HEADING: "Le num\xE9ro d'identification du membre semble \xEAtre invalide ou manquant",
  INDIVIDUAL_RECORDS_FOR_TODAY: "Dossiers individuels pour aujourd'hui",
  NOT_REQUIRED: "Non requis",
  INCOMPLETE: "Incomplet",
  OVERDUE: "En retard",
  UNKNOWN: "Inconnu",
  ON_LEAVE: "En cong\xE9",
  REMOTE_WORKER: "Employ\xE9 \xE0 distance",
  PHYSICAL_WORKER: "Employ\xE9 sur place",
  ELIGIBILITY: "Admissibilit\xE9",
  OFFICE: "Bureau",
  TOTAL_EMPLOYEES_AT_THIS_LOCATION: "{totalEmployees} employ\xE9s \xE0 cet endroit",
  EMPLOYEE_NAME_SLASH_ID: "Nom de l'employ\xE9 / Identifiant",
  REMAINING_BALANCE: "Solde",
  DOES_MY_BENEFIT_COVER: "Est-ce que mon {benefitName} couvre...",
  POLICY_ACTIVE: "Police active",
  WALLET_LANDING_BODY: "Votre portefeuille \xE9lectronique contient les avantages sociaux de votre employeur, programmes et les d\xE9tails de votre couverture. Trouvez ce dont vous avez besoin en un clin d'\u0153il.",
  WALLET_LANDING_TITLE: "Les informations sur votre couverture et vos avantages sociaux r\xE9unies dans un seul et m\xEAme endroit.",
  GO_BACK: "Retour",
  SEE_ALL_NOTIFICATIONS: "Voir toutes les notifications",
  WALLET_FEEDBACK_SUBTITLE_OTHER: "Les informations fournies pour {cardName} vous ont-elles \xE9t\xE9 utiles?",
  WALLET_FEEDBACK_SUBTITLE_BENEFIT: "Avez-vous trouv\xE9 l'information \xE0 propos de la garantie \u201C{benefitName}\u201D utile?",
  WALLET_FEEDBACK_TITLE: "Aidez-nous \xE0 am\xE9liorer l'exp\xE9rience portefeuille",
  LOG_OUT: "Se d\xE9connecter",
  TERMS_OF_SERVICE: "Conditions g\xE9n\xE9rales d'utilisation",
  ARTICLES: "Articles",
  BOOKINGS: "Rendez-vous",
  NO_NOTIFICATIONS_DESCRIPTIONS: "Vous pourrez trouver les nouvelles notifications sur le statut de vos demandes, les rendez-vous \xE0 venir et les objectifs de sant\xE9 ici.",
  NO_NOTIFICATIONS: "Aucune notification",
  ADD_A_DEPENDENT_CAPITAL: "Ajouter une personne \xE0 charge",
  HAS_NO_DEPENDENTS_DESCRIPTION: "Vous n'avez pas de personnes \xE0 charge associ\xE9es \xE0 cette prestation. Si vous avez r\xE9cemment v\xE9cu un \xE9v\xE9nement de vie, vous pourriez avoir le droit d'ajouter ou de modifier vos personnes \xE0 charge.  Cliquez sur le bouton ci-dessous pour v\xE9rifier en quelques \xE9tapes si vous y avez droit.",
  HAS_DEPENDENTS_DESCRIPTION: "Les personnes \xE0 votre charge pour cette prestation sont \xE9num\xE9r\xE9es ci-dessous. Si vous avez r\xE9cemment v\xE9cu un \xE9v\xE9nement de vie, vous pourriez \xEAtre autoris\xE9 \xE0 apporter des modifications \xE0 vos personnes \xE0 charge. Cliquez sur le bouton ci-dessous pour v\xE9rifier en quelques \xE9tapes si vous y avez droit.",
  ID_LIKE_TO_DOTDOTDOT: "J'aimerais...",
  ABOUT_THIS_BENEFIT_DESCRIPTION: "Cette prestation est mise \xE0 votre disposition par votre employeur. Pour toute question ou information d\xE9taill\xE9e, veuillez vous adresser \xE0 votre administrateur RH.",
  ABOUT_THIS_BENEFIT: "\xC0 propos de cette prestation",
  IF_YOU_HAVE_AN_URGENT_CLAIM_PLEASE_CONTACT_CUSTOMER_SUPPORT: "Si votre demande de remboursement est urgente, veuillez communiquer avec le <a>service \xE0 la client\xE8le</a>.",
  RECENT_CLAIMS: "Demandes r\xE9centes",
  GO_TO_CLAIM_DETAIL: "Acc\xE9der aux d\xE9tails de la demande",
  NEED_MORE_INFO_MULTIPLE_CLAIMS: "Vos demandes n\xE9cessitent plus d'informations pour \xEAtre trait\xE9es. Voir les d\xE9tails ci-dessous.",
  NEED_MORE_INFO_SINGLE_CLAIM: "Votre demande n\xE9cessite besoin de plus d'informations pour \xEAtre trait\xE9e. Voir les d\xE9tails ci-dessous",
  ROLLED_OVER: "Report\xE9",
  BACK_TO_DEPENDENTS: "Retour aux personnes \xE0 charge",
  BY_SUBMITTING_YOU_CERTIFY: "En soumettant, vous certifiez que vous avez fourni des renseignements exacts",
  PERSONAL_DETAILS: "Renseignements personnels",
  SWITCH_VIEW: "Changer de vue",
  PERSONAL_ACCOUNT: "Compte personnel",
  BUSINESS_PORTAL: "Portail de l'entreprise",
  ADMIN_PORTAL: "Portail de l'administrateur",
  PLEASE_TRY_RELOADING_THE_PAGE_OR_CHECK_BACK_LATER: "Veuillez rafra\xEEchir la page ou r\xE9essayer plus tard",
  IRELAND: "Irlande",
  UNITED_STATES: "\xC9tats-Unis",
  CANADA: "Canada",
  TRY_RELOADING_THE_PAGE: "Essayez de recharger la page ou r\xE9essayez plus tard.",
  THERE_IS_A_PROBLEM_LOADING_THIS_PAGE: "Une erreur est survenue lors du chargement de cette page",
  "RE-SEND_INVITATION": "Renvoyer l'invitation",
  INVITATION_EXPIRED: "Invitation expir\xE9e",
  JOINED: "Rejoint",
  INVITATION_SENT: "Invitation envoy\xE9e",
  SEND_INVITATION: "Envoyer une invitation",
  INVITATION_SENT_TO: "INVITATION ENVOY\xC9E \xC0",
  SEND_DEX_INVITE_CHECKBOX: "Je confirme que les informations fournies sont exactes et v\xE9ridiques.",
  DEX_INVITE_PRIVACY_DISCLAIMER: "Vos donn\xE9es sont en s\xE9curit\xE9 avec nous. Nous ne partagerons jamais vos donn\xE9es avec vos personnes \xE0 charge. En savoir plus sur notre {privacyPolicyLink}.",
  "RE-SEND_DEX_INVITE_CHECKBOX": "Je reconnais que le destinataire de cette invitation a pris connaissance et accept\xE9 cette demande, et que les informations fournies sont exactes et v\xE9ridiques.",
  SEND_DEX_INVITE_DESCRIPTION: "Veuillez utiliser une adresse \xE9lectronique qui n'est pas associ\xE9e \xE0 un compte League existant. Cette invitation expirera 14 jours apr\xE8s son envoi.",
  "RE-SEND_DEX_INVITE_DESCRIPTION": "Une invitation a d\xE9j\xE0 \xE9t\xE9 envoy\xE9e \xE0 l'adresse \xE9lectronique indiqu\xE9e. Si elle n'a pas \xE9t\xE9 re\xE7ue ou si elle a expir\xE9, veuillez vous assurer que l'adresse \xE9lectronique que vous avez saisie est correcte et renvoyer l'invitation.",
  SEND_DEX_INVITE_HEADING: "Quelle adresse \xE9lectronique devrions-nous utiliser pour inviter {name}?",
  "RE-SEND_DEX_INVITE_HEADING": "\xCAtes-vous s\xFBr de vouloir envoyer \xE0 nouveau une invitation \xE0 {name}? ",
  EMAIL: "Courriel",
  EMAIL_ERROR_MESSAGE: "Doit \xEAtre dans un format de courriel valide",
  ALL_OFFICES: "Tous les bureaux",
  DEX_GENERIC_ERROR_MESSAGE: "Quelque chose n'a pas fonctionn\xE9 de notre c\xF4t\xE9. Veuillez rafra\xEEchir la page ou r\xE9essayer. Si le probl\xE8me persiste, <a>contactez-nous</a>.",
  DEX_GENERIC_ERROR_MESSAGE_HEADING: "Oh oh! Une erreur est survenue.",
  DEX_EMAIL_EXISTS_ERROR: "Cette adresse courriel est d\xE9j\xE0 li\xE9e \xE0 un compte League.",
  DEX_SUCCESS_MESSAGE: "Votre invitation s'en vient!",
  DEX_SUCCESS_MESSAGE_HEADING: "Invitation envoy\xE9e!",
  ZE_ZIM: "Iel/Iels",
  XE_XEM: "Ielle/Ielles",
  THEY_THEM: "Eux",
  SHE_HER: "Elle",
  HE_HIM: "Il/lui",
  TWO_SPIRIT: "Bispiritualit\xE9",
  TRANSGENDER_WOMAN: "Femme transgenre",
  TRANSGENDER_MAN: "Homme transgenre",
  NON_BINARY: "Non-binaire",
  INTERSEX: "Intersexu\xE9",
  GENDERQUEER_NONCOMFORMING: "Genderqueer non-conformiste",
  GENDERQUEER: "Genderqueer",
  BIGENDER: "Bigenre",
  AGENDER: "Agenre",
  WOMAN: "Femme",
  MAN: "Homme",
  SELECT_LANGUAGE: "S\xE9lectionner la langue",
  GET_CARE: "Soutien et soins",
  DEX_VALIDATION_ERRORS_BANNER: "<strong>Nous avons rep\xE9r\xE9 des erreurs sur cette page.</strong>Veuillez corriger les champs en rouge et renvoyer.",
  SELECT_A_BENEFIT: "S\xE9lectionnez une prestation",
  DID_YOU_GET_A_PROMO_CODE_DESCRIPTION: "Si vous avez re\xE7u du cr\xE9dit ou un code de promotion, vous pouvez le renseigner ici pour recevoir vos cr\xE9dits. Ils seront automatiquement ajout\xE9s \xE0 votre compte.",
  DID_YOU_GET_A_PROMO_CODE: "Avez-vous un Code de promotion?",
  LEAGUE_CREDIT_DESCRIPTION_2: "Pour utiliser votre Cr\xE9dit League, s\xE9lectionnez  \xAB Cr\xE9dit League \xBB dans la liste des options de paiement lorsque vous \xEAtes pr\xEAt \xE0 payer sur March\xE9vie.\n{learnMoreLink}",
  LEAGUE_CREDIT_DESCRIPTION_1: "Vous pouvez d\xE9penser votre Cr\xE9dit League dans le {lifemarketLink} pour payer une vari\xE9t\xE9 de produits et de services bien-\xEAtre. Vous ne pouvez pas d\xE9penser votre Cr\xE9dit League sur les produits indiqu\xE9s comme \xAB Offre partenaire League \xBB.",
  ABOUT: "\xC0 propos",
  LIFEMARKET: "March\xE9vie",
  TOTAL_BALANCE: "Solde total",
  SUBMIT_CLAIM_AGREEMENT_4: "J'atteste que les renseignements fournis sont exacts et que mes demandes sont soumises \xE0 une approbation finale. Des documents suppl\xE9mentaires peuvent \xEAtre exig\xE9s \xE0 des fins de v\xE9rification. La demande peut \xEAtre r\xE9voqu\xE9e en tout temps s'il s'av\xE8re qu'elle ne satisfait pas aux crit\xE8res requis.",
  SUBMIT_CLAIM_AGREEMENT_3: "Je ne suis pas autoris\xE9 \xE0 soumettre, et ne soumettrai pas cette demande de remboursement ailleurs.",
  SUBMIT_CLAIM_AGREEMENT_2: "Je certifie avoir pay\xE9 le prestataire, et les documents fournis sont sp\xE9cifiques \xE0 cette demande et pour moi-m\xEAme ou un autre assur\xE9 pour cette prestation.",
  SUBMIT_CLAIM_AGREEMENT_1: "Les renseignements fournis sont, \xE0 ma connaissance, vrais et exacts, et je comprends qu'une fausse d\xE9claration peut entra\xEEner des poursuites criminelles/civiles.",
  SUBMIT_CLAIM_AGREEMENT_HEADING: "En soumettant cette demande, je comprends et accepte les points suivants:",
  BROWSE_FILES: "Parcourir les fichiers",
  OR: "ou",
  DRAG_AND_DROP_FILES_HERE: "Glisser et d\xE9poser les fichiers ici",
  CLAIM_UPLOAD_INSTRUCTIONS_3_1: "Les images occupent toute la page et sont bien \xE9clair\xE9es",
  CLAIM_UPLOAD_INSTRUCTIONS_3: "Vos images doivent \xEAtre claires et faciles \xE0 lire",
  CLAIM_UPLOAD_INSTRUCTIONS_2_1: "Tel qu'un re\xE7u ou un relev\xE9 de carte de cr\xE9dit",
  CLAIM_UPLOAD_INSTRUCTIONS_2: "Fournissez une preuve de paiement du produit ou du service",
  CLAIM_UPLOAD_INSTRUCTIONS_1_3: "Le nom du vendeur ou du prestataire de soins de sant\xE9",
  CLAIM_UPLOAD_INSTRUCTIONS_1_2: "La date de l'achat ou du service",
  CLAIM_UPLOAD_INSTRUCTIONS_1_1: "Le produit ou le service pour lequel vous avez pay\xE9",
  CLAIM_UPLOAD_INSTRUCTIONS_1: "Les documents comportent les renseignements suivants",
  CLAIM_UPLOAD_INSTRUCTIONS_HEADING: "Avant de t\xE9l\xE9verser, assurez-vous que...",
  NOTES_PLACEHOLDER: "Voulez-vous partager d'autres renseignements ne figurant pas sur vos documents? Ajoutez-les ici (par exemple le nom du fournisseur de soins, la date du service, etc.)",
  CLAIM_AMOUNT_TOOLTIP_2: "Par exemple, si vous pr\xE9sentez une demande de remboursement pour un achat qui co\xFBte 100 $, mais que vous voulez que nous vous remboursions seulement 80 $, vous pouvez entrer 80 $ ici.",
  CLAIM_AMOUNT_TOOLTIP_1: "Vous pouvez indiquer le montant que vous souhaitez que nous vous remboursions (m\xEAme s'il est inf\xE9rieur au montant indiqu\xE9 sur votre re\xE7u).",
  CLAIM_AMOUNT_LABEL: "Montant de la demande",
  CLAIM_BENEFIT_TOOLTIP_2: "Vous devez pr\xE9senter une demande pour une prestation d'assurance? S\xE9lectionnez la prestation dans votre portefeuille pour acc\xE9der au portail de votre d'assureur et soumettre votre demande.",
  CLAIM_BENEFIT_TOOLTIP_1: "C'est ici que vous trouverez les prestations pour lesquelles vous pouvez pr\xE9senter des demandes sur votre compte League.",
  CLAIM_BENEFIT_LABEL: "Prestation demand\xE9e",
  BANKING_INFORMATION: "Informations bancaires",
  BANKING_INFO_BANNER: "Vous devrez d\u2019abord configurer votre {bankingInfoLink} dans votre compte League avant que nous puissions vous rembourser!",
  OUR_GUIDE_TO_SUBMITTING_CLAIMS: "notre guide pour les demandes",
  SUBMIT_CLAIM_INTRO: "Assurez-vous que la date \xE0 laquelle l'article a \xE9t\xE9 pay\xE9 ou le service rendu est comprise dans la p\xE9riode couverte par la prestation pour laquelle vous soumettez la demande de r\xE8glement. Vous trouverez la p\xE9riode d'assurance sur la carte d'avantages sociaux dans votre {walletLink} avant de soumettre votre demande. Si vous avez des questions ou si vous souhaitez en savoir plus, veuillez consulter {ourGuideToSubmittingClaimsLink}.",
  SUBMIT_CLAIM_SUBHEADING: "T\xE9l\xE9versez des re\xE7us ou des ordonnances et obtenez un remboursement sous quelques jours!",
  SUBMIT_CLAIM_HEADING: "Soumettre une demande",
  REMOVE_NAMED_FILE: "Retirer le fichier {name}",
  CLAIM_FORM_HAS_ERRORS: "Vous devez remplir tous les champs obligatoires avant de soumettre une demande.",
  FILE_TOO_LARGE_ERROR: "Le fichier que vous avez s\xE9lectionn\xE9 est trop volumineux. Veuillez s\xE9lectionner un fichier de moins de 100 Mo.",
  FILES_REQUIRED_ERROR: "Veuillez ajouter au moins une image ou un PDF pour soumettre une demande.",
  INSERT: "Ins\xE9rer",
  SELECT_A_VARIABLE: "S\xE9lectionner une variable...",
  STYLED_CONTENT: "Contenu personnalis\xE9",
  HTML_SOURCE: "Source HTML",
  CLAIM_ERROR_LINK: "\xC9quipe du service \xE0 la client\xE8le",
  UPDATE_CLAIM_ERROR_DESCRIPTION: "Une erreur est survenue lors de la soumission de votre demande, veuillez r\xE9essayer. Si le probl\xE8me persiste, veuillez communiquer avec notre {custCareLink}.",
  SUBMIT_CLAIM_ERROR_DESCRIPTION: "Une erreur est survenue lors de la soumission de votre demande, veuillez r\xE9essayer. Si le probl\xE8me persiste, veuillez communiquer avec notre {custCareLink}.",
  CLAIM_ERROR_HEADING: "Oh oh! Une erreur est survenue.",
  SUBMIT_CLAIM_SUCCESS_DESCRIPTION: "Veuillez nous donner 1 \xE0 3 jours ouvrables pour traiter votre demande. Vous recevrez un courriel lorsque votre demande sera approuv\xE9e ou si nous avons besoin de plus d'informations pour traiter cette demande.",
  SUBMIT_CLAIM_SUCCESS_HEADING: "Votre demande a bien \xE9t\xE9 soumise.",
  CANCEL_CLAIM_BUTTON: "Annuler cette demande",
  CANCEL_CLAIM_BODY: "Toute annulation de demande est d\xE9finitive.",
  CANCEL_CLAIM_HEADING: "Voulez-vous vraiment annuler cette demande?",
  CANCEL_CLAIM_SUCCESS_BODY: "Nous avons proc\xE9d\xE9 avec succ\xE8s \xE0 l'annulation de cette demande.",
  CANCEL_CLAIM_SUCCESS_HEADING: "Demande annul\xE9e",
  CANCEL_CLAIM_ERROR_BODY: "Veuillez essayer \xE0 nouveau. Si le probl\xE8me persiste, veuillez communiquer avec notre \xE9quipe du service \xE0 la client\xE8le.",
  CANCEL_CLAIM_ERROR_HEADING: "Erreur lors de l'annulation de la demande",
  PREVIOUSLY_UPLOADED: "Pr\xE9c\xE9demment t\xE9l\xE9vers\xE9",
  UPDATE_CLAIM_SUCCESS: "Demande mise \xE0 jour",
  PLEASE_CALL: "Veuillez appeler {phoneNumber}"
};

// src/es-MX.json
var es_MX_default = {
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE_TOOLTIP: "El monto que paga su otro seguro.",
  CLAIMS_COST_DETAILS_YOUR_OTHER_INSURANCE: "Otro seguro pagado",
  CLAIMS_SERVICE_DETAILS_SERVICE_OTHER_INSURANCE_PAID: "Otro seguro pagado",
  FRE_NODE_OPTIONS_MENU: "Opciones de nodo",
  FRE_NODE_HANDLE_IF_FALSE: "Si es falso",
  FRE_NODE_HANDLE_IF_TRUE: "Si es verdad",
  FRE_NODE_HANDLE_THEN: "Entonces",
  LAST_DAY_TO_SUBMIT_CLAIMS: "\xDAltimo d\xEDa para presentar reclamaciones",
  LAST_DAY_TO_USE_BALANCE: "\xDAltimo d\xEDa para utilizar el saldo",
  CHAT_CLOSED: "Este chat ha sido cerrado.",
  CONNECTING_YOU_WITH_AN_AGENT: "Conect\xE1ndote con un agente...",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_3: "Si bien no podemos controlar lo que sucede en el mercado en general, invertimos dinero adicional en el grupo de bienestar para compensar algunos de los aumentos en los precios de los beneficios.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_2: "De manera consistente con lo que estamos viendo en muchos gastos diarios, los costos de los servicios m\xE9dicos y dentales contin\xFAan aumentando, y esto se refleja en los cambios de precios de los beneficios de KPMG.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_1: "El beneficio de salud mental de KPMG, financiado totalmente por la empresa, aumentar\xE1 a $3000 sin costo para nuestros empleados. KPMG contin\xFAa con el compromiso de cubrir el costo total de este beneficio en su nombre.",
  NEXT_MONTH: "Bot\xF3n del pr\xF3ximo mes",
  PERVIOUS_MONTH: "Bot\xF3n del mes anterior",
  USERNAME_HAS_LEFT_THE_CHAT: "{username} ha abandonado el chat",
  USERNAME_HAS_JOINED_THE_CHAT: "{username} se ha unido al chat",
  HEALTH_REPORT_PAGE_HEADING: "Tu informe de salud",
  CLAIMS_PLAN_PROGRESS_DESCRIPTION: "Obtenga una visi\xF3n general de sus costes acumulados, niveles de cobertura y mucho m\xE1s.",
  CLAIMS_COST_DETAILS_WE_PAID_ON_TOOLTIP: "El monto que tu plan cubri\xF3 por servicios de atenci\xF3n m\xE9dica.",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT_TOOLTIP: "Tu plan negoci\xF3 con tu proveedor para ofrecerte una tarifa con descuento.",
  CLAIMS_SERVICE_DETAILS_DATE: "Servicio",
  CLAIMS_ADDITIONAL_RESOURCES_HEADING: "Recursos adicionales",
  CLAIMS_SERVICE_TYPE: "{type, select, insitutional {Reclamaciones m\xE9dicas} oral {Reclamaciones dentales} vision {Reclamaci\xF3n de asistencia oftalmol\xF3gica} pharmacy {Reclamaciones farmac\xE9uticas} professional {Reclamaciones profesionales} other {Reclamaciones}}",
  ACTIVE_ELIGIBLE_CHALLENGES: "Retos admisibles activos",
  KG: "kg",
  LB: "lb",
  IN: "en",
  CM: "cm",
  CONNECT_A_DEVICE: "Conectar un dispositivo",
  FRE_FIT_VIEW_FLOW: "Ajustar el flujo para ver",
  FRE_ZOOM_OUT_FLOW: "Alejar el flujo",
  FRE_ZOOM_IN_FLOW: "Ampliar el flujo",
  INSIGHT_CARD_HEADER_WAIST_CIRCUMFERENCE: "Circunferencia de la cintura",
  FRE_NEW_FLOW: "Nuevo flujo",
  FRE_FLOW: "Flujo",
  CLEAR_ALL: "Limpiar todo",
  CLAIMS_SERVICE_TYPE_SUFFIX_PROFESSIONAL: "Reclamaci\xF3n profesional",
  QUESTIONNAIRE_FLOW_RETRY: "Reintentar",
  QUESTIONNAIRE_FLOW_CONTINUE: "Continuar",
  QUESTIONNAIRE_FLOW_GO_BACK: "Volver atr\xE1s",
  QUESTIONNAIRE_FLOW_CLOSE: "Cerrar",
  QUESTIONNAIRE_FLOW_NEW_MESSAGE: "Nuevo mensaje",
  OPEN_ACTIVITY_DETAILS_ARIA: "Ver m\xE1s detalles de la actividad del programa",
  VISIBILITY_PERIOD: "Periodo de visibilidad",
  ACTIVE_PERIOD: "Per\xEDodo de vigencia",
  PROGRAM_DATES: "Fechas del programa",
  REWARDS_ID_UNDERSCORE: "Rewards_ID: {rewardId}",
  VIEW_ALL_CHALLENGES_DESCRIPTION_SOLO: "Ve por libre y desaf\xEDate a ti mismo a completar grupos de actividades centradas en la forma f\xEDsica, la nutrici\xF3n, la salud mental, la gesti\xF3n del estr\xE9s y mucho m\xE1s.",
  VIEW_ALL_CHALLENGES_DESCRIPTION_GROUP: "Mantente responsable mientras te retas a ti mismo con trabajo de atenci\xF3n plena, iniciativas de alimentaci\xF3n sana, competiciones de fitness, actividades de reducci\xF3n del estr\xE9s, retos de pasos y mucho m\xE1s.",
  LATEST_INSIGHTS_METRICS_AND_MORE: "\xDAltimas observaciones, m\xE9tricas y m\xE1s",
  VIEW_MY_HEALTH_DASHBOARD: "Ver mi panel de salud",
  CLAIMS_SERVICE_LINE_ITEM_LABEL: "{code, select, deductible {Deducible} copayment {Copago} coinsurance {Coaseguro} may_be_billed {Se puede facturar} amount_exceeding_maximum {Cantidad que supera el m\xE1ximo} additional_member_liability {Responsabilidad adicional del miembro} special_processing {Procesamiento especial} medicare_paid {Pagado por Medicare} paid_to_member {Pagado al miembro} paid_by_other_insurance {Pagado por otro seguro} plan_paid_to_you {Plan pagado a ti} other {Otros}}",
  CHAT_MESSAGE_INPUT: "Entrada de mensajes de chat",
  LIVE_CHAT_CHARACTER_LIMIT: "L\xEDmite de caracteres alcanzado ({characterLimit})",
  ACTIVITY_POINTS_ACTIVE: "{available} Puntos",
  ACTIVITY_POINTS_EARNED_AVAILABLE: "{earned} / {available} Puntos ganados",
  ENTER_YOUR_HEIGHT_IN_INCHES: "Introduce tu altura en pulgadas",
  ENTER_YOUR_HEIGHT_IN_FEET: "Introduce tu altura en pies",
  ENTER_YOUR_HEIGHT_IN_CENTIMETERS: "Introduce tu altura en cent\xEDmetros",
  ENTER_YOUR_WEIGHT_IN_POUNDS: "Introduce tu peso en libras",
  ENTER_YOUR_WEIGHT_IN_KILOGRAMS: "Introduce tu peso en kilogramos",
  TOOLBOX_VALIDATION_HEIGHT_MAXIMUM: "La altura debe ser como m\xE1ximo de {value}{unit}",
  TOOLBOX_VALIDATION_HEIGHT_MINIMUM: "La altura debe ser al menos {value}{unit}",
  TOOLBOX_VALIDATION_WEIGHT_MAXIMUM: "El peso debe ser como m\xE1ximo de {value}{unit}",
  TOOLBOX_VALIDATION_WEIGHT_MINIMUM: "El peso debe ser al menos {value}{unit}",
  RETURN_TO_CHAT: "Volver al chat",
  END_CHAT_MODAL_DESCRIPTION: "Continuar finalizar\xE1 el chat. No podr\xE1s volver a esta conversaci\xF3n en el futuro.",
  POINTS_OVER_TOTAL_ALT: "Tienes {current} de {total} puntos",
  POINTS_OVER_TOTAL: "<span>{current}</span> / {total} Puntos",
  CHATS_IN_CONVERSATION: "Chats en conversaci\xF3n",
  CLAIMS_SERVICE_BANNER_REMARKS_CODE_IN_EOB: "C\xF3digo de comentarios en EOB: {code}",
  CLAIMS_SERVICE_DETAILS_YOUR_COST_BREAKDOWN_SUBHEADING: "Los montos a continuaci\xF3n se destinan al progreso de tu plan.",
  CLAIMS_SERVICE_DETAILS_YOUR_COST_BREAKDOWN_HEADING: "Desglose de tu coste",
  CLAIMS_EXPLANATION_OF_BENEFITS_DOWNLOAD_ERROR: "Hubo un problema al descargar tu explicaci\xF3n de beneficios.",
  "SUPPORT_AGENT_NOT_FOUND ": "Ha ocurrido un problema al descargar tu explicaci\xF3n sobre los beneficios.",
  ACTIVITY_POINTS_MISSED: "{available} Puntos perdidos",
  ACTIVITY_POINTS_EARNED: "{earned} / {available} Puntos ganados",
  COMPLETION_DATE: "Fecha de finalizaci\xF3n",
  MEMBER_DESCRIPTION: "Descripci\xF3n del miembro",
  NO_REWARDS_AVAILABLE_BODY: "El miembro no tiene recompensas en este momento.",
  NO_REWARDS_AVAILABLE_HEADING: "No hay campa\xF1as disponibles",
  PROGRAM: "Programa",
  REWARDS_ID: "ID de recompensas",
  VIEW_REWARD_LABEL_HIDDEN: "Ver los detalles de recompensa de los miembros {from} programa de recompensas",
  REWARDS_PROGRAMS: "Programas de recompensas",
  WALLET_ORDER_NEW_CARD_NO_CITY_NUMBERS_ERROR: "La ciudad no puede contener n\xFAmeros",
  ASSESSMENTS_AND_DASHBOARDS: "Evaluaciones y paneles",
  STANDARD_PROGRAMS: "Programas est\xE1ndar",
  REWARD_PROGRAMS: "Programas de recompensas",
  CLAIMS_BILL_PROVIDER_DESCRIPTION: "Recibir\xE1s de tu proveedor una factura asociada a esta reclamaci\xF3n.",
  VIEW_PLAN_PROGRESS: "Ver el progreso del plan",
  CLAIMS_PLAN_PROGRESS: "Progreso del plan",
  MESSAGE_CLOSED_TEXT: "Este mensaje ha sido entregado.",
  CLAIMS_COST_DETAILS_WE_PAID: "Pagamos",
  CLAIMS_SERVICE_DETAILS_SERVICE_REMARK: "Observaci\xF3n de servicio",
  CLAIMS_SERVICE_BANNER_VIEW_BENEFITS_BOOKLET: "Ver folleto de beneficios",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_VALUE: "Por favor, selecciona o introduce datos v\xE1lidos para cualquier campo de Valor.",
  FLOW_RULE_ENGINE_VALIDATION_MULTIPLE_VALUES: "A\xF1ade m\xE1s de un Valor para el Operador seleccionado para comparar.",
  FLOW_RULE_ENGINE_VALIDATION_MISSING_VALUE: "Introduce o selecciona un Valor",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_OPERATOR: "Selecciona un Operador que funcione con el Punto de datos.",
  FLOW_RULE_ENGINE_VALIDATION_INVALID_DATA_POINT: "Por favor, selecciona o introduce un Punto de datos v\xE1lido.",
  FLOW_RULE_ENGINE_VALIDATION_INCOMPLETE_RULE: "Rellena todos los campos para generar el objeto JSON",
  FLOW_RULE_ENGINE_VALIDATION_EMPTY_RULE: "A\xF1ade al menos una regla para generar el objeto JSON",
  WALLET_INVALID_CHARACTER_ERROR_MESSAGE: "El campo contiene caracteres no v\xE1lidos",
  COVERAGE_AND_COSTS_TOOLTIP: "Informaci\xF3n sobre los servicios cubiertos por el plan, incluido el coste de los servicios de los proveedores dentro y fuera de la red.",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_STRING: "El punto de datos funciona con texto (string)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_NUMBER: "El punto de datos requiere un n\xFAmero o decimal",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_INT: "El punto de datos requiere un n\xFAmero entero (integer)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_BOOLEAN: "El punto de datos requiere verdadero o falso (booleano)",
  FLOW_RULE_ENGINE_DATA_TYPE_HINT_DATETIME: "El punto de datos requiere una fecha (AAAA-MM-DD) y/o hora (24 horas como HH:MM)",
  FLOW_RULE_ENGINE_MIN_VALUE: "Valor m\xEDnimo",
  FLOW_RULE_ENGINE_MAX_VALUE: "Valor m\xE1ximo",
  WALLET_ORDER_NEW_CARD_SELECT_ADDRESS_OPTION_ERROR: "Selecciona una opci\xF3n de direcci\xF3n para continuar",
  WALLET_ORDER_NEW_CARD_SELECT_MEMBER_ERROR: "Selecciona uno o m\xE1s miembros para continuar",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_COMBINATOR: "<h3>Y/O</h3><p>Utiliza el selector y/o para unir dos o m\xE1s combinaciones de reglas o grupos.</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_GROUP: "<h3>Grupo</h3><p>Un contenedor que contiene varias reglas y define la relaci\xF3n l\xF3gica entre ellas (Y/o). Los grupos pueden anidarse unos dentro de otros para crear combinaciones complejas de condiciones.</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE_LIST: "<ul><li><strong>A\xF1adir datos:</strong> Encontrar el punto de datos. Esta es la fuente a la que se aplicar\xE1 tu regla.</li><li><strong>Operador:</strong> Elegir las condiciones de tu regla. As\xED se comparar\xE1 el punto de datos con un valor.</li><li><strong>Valor:</strong> Con qu\xE9 est\xE1s comparando el punto de datos mediante el operador. Cambia un valor a un punto de datos para comparar m\xE1s de 1 punto de datos.</li></ul>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_RULE: "<h3>Regla</h3><p>Una regla representa una condici\xF3n individual e incluye lo siguiente:</p>",
  FLOW_RULE_ENGINE_RULE_BUILDER_GUIDE_TITLE: "Gu\xEDa del creador de reglas",
  CLAIMS_COST_DETAILS_YOUR_COST: "Tu coste",
  CLAIMS_COST_DETAILS_WE_PAID_ON: "{paymentDate, select, other {Pagamos el {paymentDate, date, medium}} undefined {Pagamos}}",
  CLAIMS_COST_DETAILS_YOUR_MEMBER_DISCOUNT: "Tu descuento de miembro",
  CLAIMS_COST_DETAILS_PROVIDER_CHARGED: "Proveedor cobrado",
  RECOMMENDED_DEVICE_DESCRIPTION_APP_VERSION: "<strong>Versi\xF3n de app:</strong> {primaryName} {primaryCurrentVersion} o {secondaryName} {secondaryCurrentVersion}",
  RECOMMENDED_DEVICE_DESCRIPTION_WEB_BROWSERS: "<strong>Navegadores web:</strong> La versi\xF3n m\xE1s reciente de Chrome, Firefox, Safari o Microsoft Edge",
  CLAIMS_SERVICE_DETAILS_HIDE_COST_DETAILS: "Ocultar detalles de costes",
  CLAIMS_SERVICE_DETAILS_SHOW_COST_DETAILS: "Mostrar detalles de costes",
  CLAIMS_SERVICE_DETAILS_SERVICE_COST_DETAILS: "Detalles de coste",
  CLAIMS_SERVICE_DETAILS_SERVICE_YOUR_COST: "Tu coste",
  CLAIMS_SERVICE_DETAILS_SERVICE_PROCEDURE_CODE: "C\xF3digo de procedimiento",
  CLAIMS_SERVICE_DETAILS_SERVICE_STATUS: "Estatus",
  CLAIMS_SERVICE_DETAILS_SERVICE: "Servicio",
  CLAIMS_SERVICE_DETAILS_SUBHEADING: "Un desglose de los servicios individuales que recibiste y los costes asociados a ellos.",
  CLAIMS_SERVICE_DETAILS_HEADING: "Detalles del servicio",
  MESSAGE_WITH_A_MEMBER: "Habla con un asesor de miembros para que te ayude con las reclamaciones sobre tus beneficios o cuidados.",
  GET_SUPPORT: "Obtener apoyo",
  SUPPORT: "Apoyo",
  INNETWORK_DEDUCTIBLE: "se destina a tu deducible dentro de la red",
  VIEW_DEDUCTIBLE: "Ver deducible",
  DEDUCTIBLE_TRACKER: "Rastreador de deducibles",
  TOTAL_COST_DESCRIPTION: "La suma de los costes de los servicios de tu reclamaci\xF3n. No incluye los pagos que hiciste a tu proveedor, como los copagos.",
  TOTAL_COST_OVERVIEW: "Resumen del coste total",
  ORDER_CARD: "Tarjeta de pedido",
  STEP: "Paso",
  DATA_TYPE_HINT_STRING: "El punto de datos espera un string (texto)",
  DATA_TYPE_HINT_NUMBER: "El punto de datos espera un n\xFAmero o decimal",
  DATA_TYPE_HINT_INT: "El punto de datos espera un n\xFAmero entero (integer)",
  DATA_TYPE_HINT_BOOLEAN: "El punto de datos espera que sea verdadero o falso (booleano)",
  DATA_TYPE_HINT_DATETIME: "El punto de datos espera una fecha y/o una hora",
  PLEASE_MAKE_A_SELECTION: "Haz una selecci\xF3n",
  CANCEL_ORDER: "Cancelar pedido",
  CANCEL_ORDER_NEW_CARD_DESC: "Siempre puedes volver m\xE1s tarde.",
  CANCEL_ORDER_NEW_CARD_TITLE: "\xBFEst\xE1s seguro de que deseas cancelar tu pedido?",
  ORDER_NEW_CARD_HEADING: "Pedir una nueva tarjeta",
  RESUBMIT: "Reenviar",
  TRY_LATER: "Intenta de nuevo m\xE1s tarde.",
  MEMBER_CARD_ARRIVAL: "La(s) nueva(s) tarjeta(s) de identificaci\xF3n de miembro llegar\xE1(n) en aproximadamente 10 d\xEDas laborables.",
  MEMBER_CARD_ON_ITS_WAY: "Tu nueva tarjeta de identificaci\xF3n de miembro est\xE1 en camino.",
  X_OF_Y_COMPLETED_WITH_UNIT: "<span>{progress}</span> / {goal} {unit}",
  REWARDS_PROGRAM_ELIGIBLE: "Programa de recompensas elegible",
  SPOUSE_COVERAGE: "Cobertura del c\xF3nyuge",
  CHILD_COVERAGE: "Cobertura para hijo",
  DATA_COLLECTED: "Datos recogidos el {date}",
  DOWNLOAD_BENEFITS_EXPLANATION: "Descargar Explicaci\xF3n de beneficios",
  CLAIMS_BILL_DESCRIPTION: "Recibir\xE1s de tu proveedor una factura asociada a esta reclamaci\xF3n.",
  CLAIMS_PROCESSING_DESCRIPTION: "Todav\xEDa estamos procesando esta reclamaci\xF3n. Te avisaremos cuando est\xE9 lista para que la revises.",
  CLAIMS_YOUR_COST: "Tu coste",
  PLEASE_MAKE_SELECTION_FOR: "Haz una selecci\xF3n para {input}",
  REQUIRED_FIELD: "{field} es un campo obligatorio",
  SELECT_STATE: "Selecciona un estado",
  CHOOSE_NEW_ADDRESS_DESC: "Proporciona una direcci\xF3n postal diferente para la entrega de la tarjeta. Esto no cambiar\xE1 la direcci\xF3n predeterminada de tu cuenta. La direcci\xF3n postal debe estar dentro de Estados Unidos.",
  NEW_ADDRESS: "Nueva direcci\xF3n de entrega",
  MEDICAL_ID_CARD: "Tarjeta de identificaci\xF3n m\xE9dica",
  CONFIRM_DETAILS: "Confirma tus datos",
  NOT_VALID_DATA_POINT: "No es un punto de datos v\xE1lido",
  VALID_DATA_POINT: "Punto de datos v\xE1lido",
  ENTER_VALID_DATA_POINT: "Introduce un punto de datos v\xE1lido",
  CHOOSE_NEW_ADDRESS_HINT: "Proporciona una direcci\xF3n postal diferente para la entrega de la tarjeta. Esto no cambiar\xE1 la direcci\xF3n predeterminada de tu cuenta.",
  CHOOSE_NEW_ADDRESS: "Elige una nueva direcci\xF3n",
  DEFAULT_ADDRESS: "Direcci\xF3n predeterminada",
  DATA_POINT_LOADING_ERROR: "No se pueden cargar categor\xEDas de datos en este momento.",
  TEAM_AVERAGE: "Promedio del equipo",
  LEAVE_TEAM_DESCRIPTION: "\xBFSeguro que quieres dejar {teamName}? Tu puntuaci\xF3n ya no se contar\xE1 en el promedio del equipo.",
  MESSAGE_CENTER: "Centro de mensajes",
  DATA_POINT_EMPTY_SELECTION: "Selecciona un punto de datos para aplicar",
  DATA_POINT_MODAL_INSTRUCTIONS: "Elige una categor\xEDa para encontrar un punto de datos:",
  DATA_POINT_MODAL_HEADING: "Seleccionar categor\xEDa",
  ADD_TO_RULE: "A\xF1adir a regla",
  CARD_ACTIONS: "Acciones de tarjeta",
  MINDFUL_MINUTES_HIGHLIGHT: "<strong>\xA1Completado {total} minutos!</strong>",
  COMPLETE_BY_DATE: "Completa antes del {date}",
  MEDIUM_PRIORITY: "Media prioridad",
  HIGH_PRIORITY: "Alta prioridad",
  PART_OF_REWARDS_PROGRAM: "Parte del programa de recompensas",
  X_LEAGUE_POINTS: "{points} Puntos League",
  X_REWARD_POINTS: "{points} Puntos de recompensa",
  TOOLTIP_ACTION_DESCRIPTION: "Ver el criterio de clasificaci\xF3n",
  TOOLTIP_LEADERBOARD_TEAMS: "Los equipos se clasifican por su {rankingUnit} promedio para este reto",
  TOOLTIP_LEADERBOARD_USER: "Los participantes se clasifican por su {rankingUnit} total para este reto",
  EDIT_DATA_BUTTON_ARIA_LABEL: "Editar punto de datos a la entrada {inputLabel}",
  ADD_DATA_BUTTON_ARIA_LABEL: "A\xF1adir punto de datos a la entrada {inputLabel}",
  VALUE_LABEL: "Valor",
  DATA_POINT_LABEL: "Punto de datos",
  EDIT_DATA: "Editar datos",
  ADD_DATA: "A\xF1adir datos",
  OPERATOR_SELECT_TITLE: "Selector de operador para la comparaci\xF3n",
  OPERATOR_INPUT_LABEL: "Operador",
  DOES_NOT_INCLUDE: "No incluye",
  IS_NOT_BETWEEN: "No est\xE1 entre",
  BETWEEN: "Entre",
  INCLUDING: "Incluyendo",
  IS_NOT_BLANK: "No est\xE1 en blanco",
  IS_BLANK: "Est\xE1 en blanco",
  GREATER_THAN_OR_EQUAL_TO: "Superior o igual a",
  LESS_THAN_OR_EQUAL_TO: "Inferior o igual a",
  GREATER_THAN: "Superior a",
  LESS_THAN: "Inferior a",
  NOT_EQUAL_TO: "No igual a",
  EQUALS_TO: "Igual a",
  EARN_ACHIEVEMENT_COMPLETING_ACTIVITIES_CHALLENGE: "Consigue un logro por completar todas las actividades de este reto",
  ACHIEVEMENT_PROGRESS: "Progreso de logros",
  ACHIEVEMENT_EARNED: "Celebraci\xF3n de logros obtenidos",
  CHALLENGE_COMPLETED_BODY: "No hay m\xE1s actividades que completar. \xA1Bien hecho!",
  CHALLENGE_COMPLETED_TITLE: "\xA1Se acab\xF3!",
  CHALLENGE_ACTIVITY_MISSED_EMPTY_STATE: "Las actividades que te pierdas aparecer\xE1n aqu\xED.",
  CHALLENGE_ACTIVITY_MISSED: "Perdido/s ({count})",
  CHALLENGE_ACTIVITY_COMPLETED_EMPTY_STATE: "Las actividades que completes aparecer\xE1n aqu\xED.",
  CHALLENGE_ACTIVITY_COMPLETED: "Completado/s ({count})",
  CHALLENGE_ACTIVITY_UP_NEXT_EMPTY_STATE: "\xA1Vuelve cuando empiece el reto!",
  CHALLENGE_ACTIVITY_UP_NEXT: "A continuaci\xF3n ({count})",
  CHALLENGE_ACTIVITY_EMPTY_STATE_HEADER: "No hay nada aqu\xED... \xA1todav\xEDa!",
  CONFIRM_REMOVE_GROUP_DESCRIPTION: "\xBFEst\xE1s seguro de que quieres eliminar este grupo? Esto tambi\xE9n eliminar\xE1 cualquier regla y grupo dentro de este grupo.",
  CONFIRM_REMOVE_RULE_DESCRIPTION: "\xBFEst\xE1s seguro de que quieres eliminar esta regla?",
  CONFIRM_REMOVE_GROUP_HEADING: "Eliminar grupo",
  CONFIRM_REMOVE_RULE_HEADING: "Eliminar regla",
  GROUP_DESCRIPTION_SHORT: "<strong>Grupo:</strong> Contenedor que contiene varias reglas y define su relaci\xF3n l\xF3gica. Los grupos pueden anidarse para crear combinaciones complejas de condiciones.",
  RULE_DESCRIPTION_SHORT: "<strong>Regla:</strong> Una condici\xF3n individual.",
  EMPTY_RULE_STATE_HEADING: "Selecciona <strong>+ A\xF1adir regla</strong> o <strong>+ A\xF1adir grupo</strong>",
  ADD_GROUP_BUTTON_LABEL: "+ A\xF1adir grupo",
  ADD_GROUP_BUTTON_TITLE: "A\xF1adir grupo",
  ADD_RULE_BUTTON_LABEL: "+ A\xF1adir regla",
  ADD_RULE_BUTTON_TITLE: "A\xF1adir regla",
  JSON_COPY_ERROR: "No se puede copiar. Int\xE9ntalo de nuevo o selecciona el texto y c\xF3pialo.",
  JSON_COPY_SUCCESS: "JSON copiado",
  COPY_JSON: "Copiar JSON",
  GENERATE_JSON_INPUT_LABEL: "JSON Logic para copiar y pegar en Contentful",
  GENERATE_JSON: "Generar JSON",
  VALID_JSON_ERROR: "No se puede importar porque no es JSON v\xE1lido.",
  VALID_RULE_ERROR: "No se puede importar porque no hay reglas.",
  IMPORT_JSON_HINT: "S\xF3lo las cadenas (strings) que se eval\xFAen como objetos JavaScript cuando se procesen con JSON.parse se traducir\xE1n en consultas (queries).",
  IMPORT_JSON_LABEL: "Pega el c\xF3digo JSON para renderizarlo en el Generador de reglas",
  IMPORT_JSON: "Importar JSON",
  IMPORT_CTA: "Importar",
  ACTIVITY_COUNT_EDIT: "Editar {unit}",
  ORDER_NEW_CARD_ADDRESS_SELECT_HEADING: "Selecciona una direcci\xF3n de env\xEDo",
  TOP_RANKED_SUBTITLE: "por n\xFAmero de",
  REQUEST_WAIT: "Espera mientras completamos tu solicitud",
  PROGRAM_RULES: "Reglas de programa",
  RECOMMENDED_DEVICE_DESCRIPTION: "Aseg\xFArate de que los miembros en la web utilizan la versi\xF3n m\xE1s reciente de Chrome, Firefox, Safari y Microsoft Edge, y la \xFAltima versi\xF3n de la aplicaci\xF3n de iOS v2.13.4 y Android v2.14.8.",
  RECOMMENDED_DEVICE_TITLE: "Dispositivo de miembro recomendado",
  ORDER_NEW_CARD_MEMBER_SELECT_HELP_TEXT: "Selecciona uno o varios miembros.",
  ORDER_NEW_CARD_MEMBER_SELECT_HEADING: "\xBFQui\xE9n necesita una nueva tarjeta?",
  SESSION_LOG_OUT: "Cerrar sesi\xF3n",
  CONTINUE_SESSION: "Continuar sesi\xF3n",
  SESSION_TIMEOUT: "L\xEDmite de tiempo de la sesi\xF3n",
  SESSION_ABOUT_TO_TIMEOUT: "Has estado inactivo durante un tiempo y tu sesi\xF3n est\xE1 a punto de finalizar. \xBFQuieres continuar la sesi\xF3n?",
  COMPLETED_ACTIVITY_HIGHLIGHT: "\xA1Completaste una actividad!",
  DEVICE_TITLE_CASE: "Dispositivo",
  NO_RECENT_SESSIONS_DESCRIPTION: "No hay actividad de miembros durante los \xFAltimos 90 d\xEDas.",
  NO_RECENT_SESSIONS: "No hay sesiones recientes",
  POLICYHOLDER: "Titular de la p\xF3liza",
  RULE_BUILDER: "Creador de reglas",
  RULE_BUILDER_SUBHEADING: "Crea reglas y establece condiciones que las cumplan",
  RESPONDED_STUDENT_CERTIFICATION: "\xA1Tu respuesta ha sido registrada con \xE9xito!",
  CONFIRM_DEPENDENT_STATUS: "\xBFEs {firstName} {lastName} estudiante a tiempo completo?",
  STUDENT_RECERTIFICATION_NO_INFO: "Al seleccionar NO, el dependiente quedar\xE1 excluido de la cobertura. Aseg\xFArate de volver a comprobarlo antes de enviar tu respuesta.",
  STUDENT_RECERTIFICATION_YES_INFO: "Al seleccionar S\xCD, declaras que el dependiente es un estudiante a tiempo completo.",
  STUDENT_RECERTIFICATION_DESCRIPTION: "Una vez que un hijo dependiente cumple 18 o 21 a\xF1os (seg\xFAn lo que diga el folleto de tu plan), debe ser estudiante a tiempo completo para estar asegurado por tu p\xF3liza. Para los dependientes de 25 a\xF1os o menos, debes confirmar su estatus estudiantil al principio de cada curso escolar postsecundario.",
  STUDENT_RECERTIFICATION: "Recertificaci\xF3n estudiantil",
  PENDING_COVERAGE_APPROVAL: "Pendiente de aprobaci\xF3n",
  ANSWERS_NOT_SAVED: "No podemos guardar tus respuestas en este momento. Vuelve a intentarlo m\xE1s tarde",
  PROVIDER_SERVICE_POPOVER_BUTTON_NAME: "\xBFQu\xE9 es un copago y un coseguro?",
  NO_DIGITAL_CARD: "No se espera ninguna tarjeta",
  DIGITAL_CARD_BACK: "Imagen trasera de la tarjeta digital",
  DIGITAL_CARD_FRONT: "Imagen delantera de la tarjeta digital",
  SET_TEAM_AVATAR: "Establecer avatar de equipo",
  CHANGE_TEAM_AVATAR: "Cambiar avatar de equipo",
  SELECT_TEAM_AVATAR: "Seleccionar avatar de equipo",
  DIGITAL_CARD: "Tarjeta digital",
  BENEFIT_DEPENDENTS: "Dependientes del beneficio",
  QUICK_LINKS_AGENT: "Enlaces r\xE1pidos y documentos disponibles para los miembros.",
  MEMBER_ACTIONS: "Acciones de miembros",
  CHAR_LIMIT_EXCEEDED: "Se ha superado el l\xEDmite de caracteres ({maximum})",
  USER_DROPDOWN_BTN_LABEL: "Men\xFA de perfil",
  NO_CHALLENGES_PROGRAMS_EMPTY_STATE_BODY: "Navega por nuestra biblioteca para encontrar Programas y Retos de salud que se ajusten a tus objetivos personales.",
  NO_CHALLENGES_PROGRAMS_EMPTY_STATE_HEADING: "\xBFListo para empezar?",
  WALLET_WITH_CLAIMS_LANDING_TITLE: "Tus beneficios, reclamaciones e informaci\xF3n sobre bienestar, todo en un solo lugar.",
  SOMETHING_WENT_WRONG_BODY: "Hubo un problema al cargar los datos.{br}Por favor, int\xE9ntalo m\xE1s tarde.",
  SOMETHING_WENT_WRONG_HEADING: "Algo sali\xF3 mal",
  NO_BENEFITS_AVAILABLE_BODY: "El miembro no tiene beneficios en este momento.",
  NO_BENEFITS_AVAILABLE_HEADING: "No hay beneficios disponibles",
  NO_FILTERED_BENEFITS: "No pudimos encontrar nada que coincida con esos filtros.",
  CLAIMS_SUMMARY_DOWNLOAD_ERROR: "Se ha producido un problema al descargar tus reclamaciones.",
  PLAIN_SOMETHING_WENT_WRONG: "Algo sali\xF3 mal",
  OTHER_FILE_TYPES: "No se aceptan otros tipos de archivos",
  YOUR_FILE_TYPES: "Tu formato de archivo es JPG, PNG o PDF",
  HEALTH_PROGRAM: "Programa de salud",
  REQUIRED: "Requerido",
  REWARD_POINTS_MISSED: "Puntos de recompensa perdidos",
  REWARD_POINTS_EARNED: "Puntos de recompensa ganados",
  REWARD_POINTS: "Puntos de recompensa",
  ENDED_DAY_AT_TIME: "Termin\xF3 el {day}",
  DOWNLOAD_CLAIMS: "Descargar reclamaciones",
  VIEW_DEFINITION: "Ver definici\xF3n",
  NETWORK_MISSING_COVERAGE_ADMIN: "Este plan no tiene cobertura {network}",
  COVERAGE_COSTS: "Cobertura y costos",
  X_OF_Y_BENEFITS: "mostrando {visibleAmount} de {totalAmount} de beneficios",
  SIGN_UP_PERIOD_PASSED_DETAIL: "Ya no puedes inscribirte en este desaf\xEDo porque el per\xEDodo de inscripci\xF3n ha pasado.",
  ENDED_ON_DATE: "Termin\xF3 el {date}",
  SIGN_UP_PERIOD_PASSED: "Periodo de inscripci\xF3n superado",
  AVAILABLE: "Disponible",
  COMBINATOR_SELECTOR_LABEL: "Establecer relaci\xF3n entre condiciones",
  VIEW_THE_ACTIVITIES: "Ver las actividades",
  SPONSOR_LOGO: "{name} logotipo",
  ENDED: "Terminado",
  PROGRAM_CREATED_BY: "Programa creado por",
  RECENT_CLAIMS_LIST_EMPTY: 'Las reclamaciones enviadas en los \xFAltimos 90 d\xEDas aparecer\xE1n aqu\xED. Puedes acceder a todas las reclamaciones seleccionando "Ver todo". ',
  PLAN_PROGRESS_POP_UP_LABEL: "\xBFQu\xE9 es un/a {title}?",
  REMOVE_GROUP: "Eliminar grupo",
  REMOVE_RULE: "Eliminar regla",
  PLAN_PROGRESS_COST_COVERAGE: "Progreso y costes del plan",
  TOOLBOX_VALIDATION_FIELD_CANNOT_BE_EMPTY: "El campo no puede estar vac\xEDo cuando se selecciona",
  TOOLBOX_VALIDATION_SELECT_OPTIONS_TO_CONTINUE: "Selecciona las opciones {integer} para continuar",
  TOOLBOX_VALIDATION_SELECT_MAX_SELECTION: "Selecciona un m\xE1ximo de {integer} opciones",
  TOOLBOX_VALIDATION_ENTER_WHOLE_NUMBER: "Introduce un n\xFAmero entero",
  DISCLAIMER: "Descargo de responsabilidad",
  ALL_BENEFITS: "Todos los beneficios",
  TOOLBOX_VALIDATION_SELECT_DATE_EARLIER: "Selecciona una fecha anterior al {date, date, medium}",
  TOOLBOX_VALIDATION_SELECT_DATE_LATER: "Selecciona una fecha posterior al {date, date, medium}",
  TOOLBOX_VALIDATION_SELECTION_REQUIRED: "Haz una selecci\xF3n",
  VIEW_MY_PROFILE: "Ver mi perfil",
  VIEW_BENEFIT_LABEL_HIDDEN: "Ver {name} detalles del beneficio de {vendor}",
  PENDING_BENEFIT_DESCRIPTION: "Este beneficio a\xFAn no est\xE1 disponible para el miembro.",
  PAST_BENEFIT_DESCRIPTION: "Este beneficio ya no est\xE1 disponible para el miembro.",
  PENDING_BENEFIT: "Este es un beneficio pendiente.",
  PAST_BENEFIT: "Este es un beneficio pasado.",
  TOOLBOX_VALIDATION_REQUIRED_FIELD: "Este es un campo obligatorio",
  TOOLBOX_VALIDATION_NUMBER_MAXIMUM: "Introduce un n\xFAmero igual o inferior a {value}",
  TOOLBOX_VALIDATION_NUMBER_MINIMUM: "Introduce un n\xFAmero igual o superior a {value}",
  TOOLBOX_VALIDATION_MAXIMUM_CHARACTERS: "El l\xEDmite de caracteres es {limit}",
  THREAD_SUBJECT_MAX_LENGTH_ERROR: "Has superado el l\xEDmite m\xE1ximo de caracteres ({maximum})",
  POLICY_INFORMATION: "Informaci\xF3n de la p\xF3liza",
  ANSWER_SHOULD_BE_INTEGER: "La respuesta debe ser un n\xFAmero entero",
  CLAIMS_LIST_NO_CLAIMS_MATCH_FILTER_MESSAGE: "Ninguna reclamaci\xF3n coincide con {filterCount, plural, one {filtro} other {filtros}} que has elegido",
  CLAIMS_LIST_NO_CLAIMS_MATCH_FILTER_HEADING: "Sin resultados",
  HISTORY_EMPTY_STATE_DESCRIPTION: "Cuando completes una actividad, programa o reto, aparecer\xE1 aqu\xED.",
  HISTORY_EMPTY_STATE_HEADER: "No hay nada aqu\xED... \xA1todav\xEDa!",
  TOOLBOX_VALIDATION_MONTH_YEAR: "Selecciona un mes y un a\xF1o",
  TOOLBOX_VALIDATION_DAY_MONTH_YEAR: "Selecciona un d\xEDa, un mes y un a\xF1o",
  COMPLETED_ON_DATE: "Completado el {date}",
  THIS_CHALLENGE_HAS_ENDED: "Este reto ha terminado",
  CLAIMS_SUMMARY_PAGE_SUB_HEADING: "Ve lo que tu seguro ha cubierto y lo que a\xFAn puedes deber a tu proveedor. Puedes acceder a tus reclamaciones enviadas en los dos a\xF1os anteriores a la fecha de hoy.",
  THIS_CHALLENGE_RUNS_YEAR_ROUND: "Este reto dura todo el a\xF1o",
  CLAIMS_GENERIC_ERROR_MESSAGE: "Se ha producido un problema por nuestra parte.{br}Por favor, int\xE9ntalo de nuevo en unos minutos.",
  CLAIMS_GENERIC_ERROR_HEADING: "Algo sali\xF3 mal",
  CLAIMS_LIST_EMPTY_MESSAGE: "Las reclamaciones que se env\xEDen aparecer\xE1n aqu\xED.",
  CLAIMS_LIST_EMPTY_HEADING: "\xA1Nada por aqu\xED todav\xEDa!",
  CLAIMS_SERVICE_TYPE_SUFFIX_ORAL: "Reclamaci\xF3n dental",
  CLAIMS_SERVICE_TYPE_SUFFIX_INSTITUTIONAL: "Reclamaci\xF3n m\xE9dica",
  CLAIMS_FILTER_VALUE_ORAL: "Dental",
  CHECK_CHALLENGE_DETAILS: "Consulta los detalles del reto para familiarizarte con las actividades",
  YOURE_GOOD_TO_GO: "\xA1Ya est\xE1s listo!",
  LEARN_MORE_ABOUT_PRIVACY_POLICY: "Aprende m\xE1s sobre c\xF3mo League gestiona y utiliza tus datos en nuestra <a>pol\xEDtica de privacidad.</a>",
  SHARE_CHALLENGE_PROGRESS_WITH_EMPLOYER: "Comparta el progreso de su desaf\xEDo con su empleador",
  TRACK_MANUALLY_CONFIRM_CONNECT_LATER_DESCRIPTION: "A\xFAn puedes conectar un dispositivo m\xE1s adelante",
  TRACK_CHALLENGE_MANUALLY_CHANGE_LATER: "Si eliges rastrear manualmente, a\xFAn puedes conectar un dispositivo m\xE1s adelante. {learnMoreLink} sobre el seguimiento manual de tu progreso.",
  FILTER_BY: "Filtrar por",
  CLAIMS_SERVICE_TYPE_SUFFIX_PHARMACY: "Reclamaci\xF3n de farmacia",
  CLAIMS_SERVICE_TYPE_SUFFIX_VISION: "Reclamaci\xF3n de visi\xF3n",
  CLAIMS_SERVICE_TYPE_SUFFIX_DENTAL: "Reclamaci\xF3n dental",
  CLAIMS_SERVICE_TYPE_SUFFIX_MEDICAL: "Reclamaci\xF3n m\xE9dica",
  YOUR_COST: "Tu coste:",
  VIEW_ALL_SOLO_CHALLENGES_DESCRIPTION: "Ponte a prueba yendo solo y completando grupos de actividades diarias por tu cuenta.",
  VIEW_ALL_GROUP_CHALLENGES_DESCRIPTION: "\xBFTe sientes motivado? Ponte a prueba siendo responsable de estas ofertas.",
  VIEW_ALL_SOLO_CHALLENGES_HEADING: "Retos individuales",
  VIEW_ALL_GROUP_CHALLENGES_HEADING: "Retos de grupo",
  CHALLENGE_TYPE_SOLO_TAG: "Individual",
  CHALLENGE_TYPE_GROUP_TAG: "Grupo",
  APPROVED_ACTIVITIES_TOOLTIP: "Las actividades aprobadas han sido revisadas y est\xE1n listas para ser publicadas. No est\xE1n activas y no son visibles para los usuarios. ",
  DRAFT_ACTIVITIES_TOOLTIP: "Las actividades que est\xE1n en estado borrador no est\xE1n activas y no son visibles para los usuarios.",
  PUBLISHED_ACTIVITIES_TOOLTIP: "Las actividades publicadas est\xE1n activas y visibles para los usuarios.",
  APPROVED_PROGRAMS_TOOLTIP: "Los programas aprobados han sido revisados y est\xE1n listos para ser publicados. No est\xE1n activos y no son visibles para los usuarios. ",
  DRAFT_PROGRAMS_TOOLTIP: "Los programas que est\xE1n en estado borrador no est\xE1n activos y no son visibles para los usuarios.",
  PUBLISHED_PROGRAMS_TOOLTIP: "Los programas publicados est\xE1n vivos y visibles para los usuarios.",
  PUBLISHED: "Publicado",
  CLEAR_ALL_FILTERS: "Borrar todos los filtros",
  CLAIMS_STATUS_DEFINITION_ADJUSTED: "Ajustado significa que algo ha cambiado aqu\xED, as\xED que tuvimos que echar otro vistazo a esta reclamaci\xF3n.",
  CLAIMS_STATUS_DEFINITION_NOT_COVERED: "Sin cobertura significa que el servicio no est\xE1 cubierto por tu plan de beneficios y tendr\xE1s que pagarlo.",
  CLAIMS_STATUS_DEFINITION_PARTIALLY_PAID: "Cobertura parcial significa que cubriremos una parte de tu reclamaci\xF3n.",
  CLAIMS_STATUS_DEFINITION_PENDING: "Procesando significa que estamos averiguando qui\xE9n debe qu\xE9.",
  CLAIMS_STATUS_DEFINITION_APPROVED: "Cumpliremos nuestra parte significa que la reclamaci\xF3n se aprueba y que aceptamos pagar una parte o la totalidad de la reclamaci\xF3n en funci\xF3n de tu plan.",
  CLAIMS_STATUS_DEFINITIONS: "Definiciones del estatus de reclamaci\xF3n",
  UNDERSTANDING_HOW_YOUR_CLAIMS_WORK_TEXT: "<p>Cuando visitas a uno de los m\xE9dicos de tu red, te solicitan el pago. Si tienes un copago, te lo cobran y luego env\xEDan la reclamaci\xF3n a la compa\xF1\xEDa de seguros.</p><p>A continuaci\xF3n, revisamos tu plan de seguro, tambi\xE9n conocido como contrato de beneficios, para determinar si el servicio est\xE1 cubierto. Si lo est\xE1, lo aprobaremos seg\xFAn las condiciones del contrato de prestaciones. Si no est\xE1 cubierto como parte de tu plan de beneficios, eres responsable de pagar el servicio.</p>",
  UNDERSTANDING_HOW_YOUR_CLAIMS_WORK_TITLE: "Entender c\xF3mo funcionan tus reclamaciones",
  X_PERCENT_COMPLETE: "{number}% Completo",
  CHALLENGE_PROGRESS: "Progreso del reto",
  SOLO_CHALLENGE: "Reto individual",
  GROUP_CHALLENGE: "Reto grupal",
  ACTIVITY_INPUT_FREE_TEXT: "Introduce tu propio valor",
  ADJUSTED: "Ajustado",
  CLAIMS_BENEFIT_CARD_SUBTITLE: "Lleva la cuenta de lo que debes por tu asistencia y cu\xE1nto cubrir\xE1 el seguro",
  CLAIMS_BENEFIT_CARD_TITLE: "Reclamaciones de seguros",
  PERIOD: "Per\xEDodo",
  HOW_CLAIMS_WORK: "C\xF3mo funcionan las reclamaciones",
  HELPFUL_LINKS: "Enlaces \xFAtiles",
  CLAIMS_FILTER_VALUE_LAST_2_YEARS: "\xDAltimos 2 a\xF1os",
  CLAIMS_FILTER_VALUE_LAST_12_MONTHS: "\xDAltimos 12 meses",
  CLAIMS_FILTER_VALUE_PRIOR_YEAR: "A\xF1o anterior",
  CLAIMS_FILTER_VALUE_YEAR_TO_DATE: "A\xF1o hasta la fecha",
  CLAIMS_FILTER_PROPERTY_SERVICEDATE: "Rango de fechas",
  FME_SHARING_PERMISSIONS: "Compartir permisos",
  STOP_SHARING_WITH_MEMBERS: "Dejar de compartir con todos los miembros",
  SELECT_MEMBERS_TO_ENABLE_SHARING_PERMISSIONS: "Selecciona los miembros para activar los permisos de uso compartido",
  ENABLE_SHARING_PERMISSIONS: "Para hablar de tu asistencia y navegar por ella con otras personas, tendr\xE1s que activar los permisos para compartir.",
  CLAIMS_FILTER_VALUE_ADJUSTED: "Ajustado",
  CLAIMS_FILTER_VALUE_NOT_COVERED: "Sin cobertura",
  CLAIMS_FILTER_VALUE_PARTIALLY_PAID: "Cobertura parcial",
  CLAIMS_FILTER_VALUE_PENDING: "Procesando",
  CLAIMS_FILTER_VALUE_APPROVED: "Haremos nuestra parte",
  CLAIMS_FILTER_VALUE_PROFESSIONAL: "Profesional",
  CLAIMS_FILTER_VALUE_INSTITUTIONAL: "Institucional",
  CLAIMS_FILTER_VALUE_PHARMACY: "Farmacia",
  CLAIMS_FILTER_VALUE_VISION: "Visi\xF3n",
  CLAIMS_FILTER_VALUE_MEDICAL: "M\xE9dico",
  CLAIMS_FILTER_VALUE_DENTAL: "Dental",
  CLAIMS_FILTER_PROPERTY_PATIENTNAME: "Miembro",
  CLAIMS_FILTER_PROPERTY_STATUS: "Estatus de reclamaci\xF3n",
  CLAIMS_FILTER_PROPERTY_TYPE: "Tipo de servicio",
  CLAIM_TABLE_CAPTION: "Historial de reclamaciones",
  DRINKS_PER_WEEK: "Bebidas por semana",
  OR_SELECT_BETWEEN_THESE: "o seleccionar entre estos",
  INVITATION_WILL_EXPIRE: "Cualquier direcci\xF3n de correo electr\xF3nico guardada se guardar\xE1 en el archivo para este miembro. Esta invitaci\xF3n caducar\xE1 despu\xE9s de 14 d\xEDas.",
  SEE_REWARD_PROGRAM: "Ver Programa de recompensas",
  REWARD_PROGRAM: "Programa de recompensas",
  SESSION_DURATION: "Duraci\xF3n de la sesi\xF3n",
  LAST_ACCESSED: "\xDAltimo acceso",
  ANDROID: "Android",
  IOS: "iOS",
  LATEST_APP_VERSION: "\xDAltima versi\xF3n de la aplicaci\xF3n",
  APP_VERSION: "Versi\xF3n de la aplicaci\xF3n",
  ONCE_MEMBER_REGISTERED: "Una vez que este miembro se haya registrado, podr\xE1:",
  INVITE_MEMBER: "Invita a un miembro",
  FAQ: "PREGUNTAS FRECUENTES",
  MULTISELECT_HINT: "Utiliza las teclas de flecha y retroceso para borrar una selecci\xF3n.",
  DISMISS_TOAST: "Descartar notificaci\xF3n",
  INVITE_MEMBER_SUCCESS_MSG_DESC: "En breve se enviar\xE1 una invitaci\xF3n a la direcci\xF3n de correo electr\xF3nico de {invitedMember}.",
  INVITE_FAMILY_MEMBERS_BANNER_TEXT_NON_PAO: "Para invitar a los miembros a unirse a Care Advisor Connect, ponte en contacto con el propietario principal de la cuenta que aparece a continuaci\xF3n.",
  INVITE_FAMILY_MEMBERS_BANNER_TEXT_PAO: "Invita a los miembros de tu familia a unirse a la experiencia para recorrer juntos tu trayectoria de salud.",
  FME_PERSONAL_CARE_TEAM: "Equipo de Cuidado personal",
  COMPLETED_TODAY: "Completado",
  REMOVED: "Remoto",
  UPCOMING: "Por venir",
  UPDATE_ROI: "Actualizaci\xF3n de la divulgaci\xF3n de informaci\xF3n",
  SIGN_ROI_SUCCESS_MSG_DESC: "Configuraci\xF3n > Compartir y permisos.",
  SIGN_ROI_SUCCESS_MSG_TITLE: "Tu divulgaci\xF3n de informaci\xF3n se ha firmado correctamente. Puedes cambiar estos permisos en cualquier momento en",
  SIGN_ROI: "Firmar la divulgaci\xF3n de informaci\xF3n",
  ROI_PAGE_HEADING: "Autorizaci\xF3n para la divulgaci\xF3n de informaci\xF3n",
  VIEW_ALL_STANDALONE_ACTIVITIES: "Ver todas las actividades independientes",
  DATE_SIGNED: "Fecha de firma",
  AUTHORIZED_BY: "Autorizado por",
  DOCUMENT_THUMBNAIL_MODAL: "Documento adjunto modal",
  SCROLL_DOWN_TO_SIGN: "Despl\xE1zate hacia abajo para firmar",
  SKIP_TO_SIGN_FORM: "Saltar al formulario de firma",
  SIGNATURE_PANEL_INPUT_PLACEHOLDER: "Nombre completo (como aparece arriba)",
  SIGNATURE_PANEL_HINT_TEXT: "Introduce tu nombre completo tal y como aparece arriba para autorizar la divulgaci\xF3n de tu informaci\xF3n sanitaria",
  SIGNATURE: "Firma",
  INVALID_SIGNATURE_ERROR_MSG: "Lo sentimos, tu firma no es v\xE1lida. Por favor, introduce tu nombre tal y como lo ves en el formulario.",
  FAMILY_BALANCE: "Familia {balance}",
  INDIVIDUAL_BALANCE: "{balance} de {membersFullName}",
  SEE_MORE_MISSED_ACTIVITIES_ARIA_LABEL: "Ver m\xE1s actividades perdidas",
  SEE_MORE_COMPLETED_ACTIVITIES_ARIA_LABEL: "Ver m\xE1s actividades completadas",
  SEE_MORE_ACTIVITIES: "Ver m\xE1s",
  GENERIC_TOOLTIP_NAME: "{name} tool-tip",
  ACHIEVEMENTS_EMPTY_STATE_DESCRIPTION: "Completa las actividades de tu Trayectoria para empezar a conseguir logros.",
  ACHIEVEMENTS_EMPTY_STATE_HEADER: "Sin logros (\xA1todav\xEDa!)",
  MULTIPLE_ACHIEVEMENT_DESCRIPTION: "\xA1Nos encanta ver que das prioridad a tu salud y bienestar! \xC9chale un vistazo a otros logros que has ganado y a aquellos en los que est\xE1s trabajando.",
  SINGLE_ACHIEVEMENT_DESCRIPTION: "\xA1Nos encanta ver que das prioridad a tu salud y bienestar! \xC9chale un vistazo a otros logros en los que est\xE1s trabajando.",
  ACHIEVEMENTS_BANNER_HEADER: "\xA1Felicitaciones por ganar estas insignias!",
  RECENT_ACHIEVEMENTS: "Logros recientes",
  BROWSER_APP_INFO: "Info de navegador o app",
  SESSION_TIME: "Duraci\xF3n de la sesi\xF3n (minutos/segundos)",
  LOG_IN_HISTORY: "historial de inicio de sesi\xF3n",
  MEMBER_DEVICE_OS: "Dispositivo o sistema operativo",
  RECENT_SESSIONS: "Sesiones recientes",
  HELP: "Ayuda",
  X_POINTS: "{points, plural, one {# ficha} other {# fichas}}",
  FILTER_WALLET_CLAIMS: "Elige un periodo de beneficios para buscar reclamaciones de cartera.",
  CURRENT: "Actual",
  CAPABILITY: "Capacidad",
  YOUR_SALARY: "Tu sueldo",
  UNAVAILABLE_ACTION: "{name} (a\xFAn no est\xE1 disponible)",
  STANDALONE_ACTIVITIES_EMPTY_STATE: "\xA1Nada que hacer aqu\xED todav\xEDa! Vuelve m\xE1s tarde.",
  PARTIALLY_PAID: "Parcialmente pagado",
  DOLLAR_AMOUNT: "${amount}",
  CLAIM_PATIENT_NAME: "Para: {patientName}",
  MAY_OWE_AMOUNT: "Puedes deber:",
  OWED_AMOUNT: "T\xFA debes:",
  FEEDBACK_LINK_LABEL: "Califica la informaci\xF3n de {value} sobre {maximum} en utilidad",
  URGENT_BANNER_ICON_ALT_TEXT: "Alerta",
  STANDALONE_ACTIVITIES_DESCRIPTION: "Sin Programas. Ni Retos. S\xF3lo actividades puntuales.",
  VIEW_ALL_MISSED_ACTIVITIES_ARIA_LABEL: "Ver todas las actividades perdidas",
  VIEW_ALL_COMPLETED_ACTIVITIES_ARIA_LABEL: "Ver todas las actividades completadas",
  EXPLORE: "Explorar",
  DOCUMENT_NAME_TOO_LONG: "Has superado el l\xEDmite m\xE1ximo de caracteres ({maximum})",
  CLAIMS_SUMMARY: "Resumen de reclamaciones",
  STANDALONE_ACTIVITIES: "Actividades independientes",
  DISPLAYING_ACTIVITIES_FROM_DATE: "Mostrando actividades desde {date}",
  DELETE_ACCOUNT_FAILED: "Error al eliminar cuenta",
  SOLUTIONS: "Soluciones",
  X_PERCENTAGE: "{percent}%",
  X_OF_Y_ACTIVITIES: "{completed} de {total} actividades",
  NEXT_ACTIVITY: "Siguiente actividad",
  VIEW_DETAILS: "Ver detalles",
  TOKENS_MISSED: "{points} fichas perdidas",
  TOKENS_EARNED: "{points} fichas ganadas",
  WARNING: "Atenci\xF3n",
  HEALTH_JOURNEY_PAGE: "P\xE1gina de Trayectoria de salud",
  YOUVE_GOT_THIS_NAME: "\xA1Lo tienes, {firstName}!",
  YOUVE_GOT_THIS: "\xA1Lo tienes!",
  PERSONAL_CARE_TEAM_EMPTY_STATE_DESC: "Actualmente no tienes ning\xFAn familiar a\xF1adido.",
  SHARING_PERMISSIONS_EMPTY_STATE_SUB_DESC: "Ponte en contacto con tu representante para empezar.",
  SHARING_PERMISSIONS_EMPTY_STATE_DESC: "Tendr\xE1s que a\xF1adir familiares a tu Equipo de cuidados de salud para poder gestionar sus permisos de uso compartido.",
  NO_PERSONAL_CARE_TEAM_MEMBERS: "A\xFAn no hay miembros del equipo de atenci\xF3n",
  MISSED_ACTIVITIES_EMPTY_STATE: "Cuando te pierdas actividades, \xA1aparecer\xE1n aqu\xED!",
  MISSED_ACTIVITIES: "Actividades perdidas",
  COMPLETED_ACTIVITIES_EMPTY_STATE: "Cuando completes actividades, \xA1aparecer\xE1n aqu\xED!",
  COMPLETED_ACTIVITIES: "Actividades completadas",
  EXPLORE_LIBRARY: "Explorar la biblioteca",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_DESCRIPTION: "Navega por nuestra biblioteca para encontrar Programas y Retos de salud que se ajusten a tus objetivos personales.",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_HEADING: "\xBFListo para empezar?",
  NO_CONFIGURABLE_ACTIVITIES_EMPTY_STATE_EYEBROW: "Programas y retos",
  X_TOKENS: "{tokenCount, plural, one {# ficha} other {# fichas}}",
  YOU_PAY_ANNUALLY_TOOLTIP_ALTTEXT: "Pagas anualmente tool-tip",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP_ALTTEXT: "Tu deducci\xF3n de la n\xF3mina tool-tip",
  MEMBER_ROLE_POLICY: "Miembro de la p\xF3liza",
  MEMBER_ROLE_NON_POLICY: "No miembro de la p\xF3liza",
  MEMBER_ROLE_PAO: "Propietario principal de la cuenta",
  REVOKE_PERMISSIONS_MODAL_CONFIRM_CTA: "Dejar de compartir",
  REVOKE_PERMISSIONS_MODAL_DESC: "Ya no podr\xE1s compartir documentos ni hablar de tus cuidados con esta(s) persona(s).",
  REVOKE_PERMISSIONS_MODAL_TITLE: "\xBFEst\xE1s seguro de que quieres eliminar los permisos para compartir con todos los miembros?",
  WITH_DOCUMENT: "con documento",
  BACK_TO_FAMILY_PAGE: "Volver a la p\xE1gina de familia",
  EXPIRED_ROI_BANNER_WITH_DATE: "Tu Divulgaci\xF3n de informaci\xF3n expir\xF3 el {expiryDate}.",
  EXPIRED_ROI_BANNER: "Tu Divulgaci\xF3n de informaci\xF3n expir\xF3.",
  REMOVE_FILE_FILENAME: "Eliminar archivo: {filename}",
  START_X_MORE_PROGRAMS_PLURALIZED: "Inicia {duration, plural, one {# programa m\xE1s} other {# programas m\xE1s}}. Mant\xE9n el impulso.",
  CONTINUE_WITH_HSID: "Continuar con HealthSafe ID",
  UNREAD_MESSAGES: "Mensajes no le\xEDdos",
  METRICS: "M\xE9tricas",
  UNREGISTERED: "No registrado",
  ASSIGN_NEW_ACTIVITY: "Asignar nueva actividad",
  TOGGLE_MENU: "Alternar lista de mensajes",
  VIEW_ALL_MESSAGE_THREADS: "Ver todos los hilos de mensajes",
  LINK_OUT_MESSAGE: "Debes ver",
  THIS_HELPS_IDENTIFY_PERSON_DOCUMENT_RELATES_TO: "Esto ayuda a tu Asesor de atenci\xF3n a identificar a la persona a la que se refiere este documento.",
  SELECT_DOCUMENT: "Seleccionar documento",
  SELECT_A_USER: "Selecciona un usuario",
  WHO_IS_THIS_DOCUMENT_ABOUT: "\xBFDe qui\xE9n trata este documento?",
  SEE_MORE: "VER M\xC1S",
  UNABLE_TO_LOAD_PAGE_TRY_AGAIN: "No podemos cargar esta p\xE1gina. Comprueba tu Internet e int\xE9ntalo de nuevo.",
  CHARGE_SUBSCRIPTION_FEES: "Cobrar tarifas de suscripci\xF3n",
  SUBSCRIPTION_FEES_FOR_PER_BENEFIT_CLASS: "Tarifas de suscripci\xF3n por Clase de beneficios",
  SUBSCRIPTION_RATE: "Tarifa de suscripci\xF3n (por empleado/mes)",
  SUBSCRIPTION_FEES_FOR_INVITED_USER: "Tarifas de suscripci\xF3n para Usuarios invitados",
  SAVED_SUBSCRIPTION_FEES: "\xA1Tarifas de suscripci\xF3n ahorradas!",
  SAVE_SUBSCRIPTION_FEES: "Guardar tarifas",
  SUBSCRIPTION_FEES: "Tarifas de suscripci\xF3n",
  ERROR_CAPTION_NO_CONNECTION: "Se present\xF3 un problema cargando los datos.\n Verifica tu conexi\xF3n a internet y vuelve a intentarlo.",
  ERROR_CAPTION_BACKEND: "Se ha producido un problema al conectar con nuestro servidor.\n Vuelve a intentarlo m\xE1s tarde.",
  ERROR_HEADING_NO_CONNECTION: "No hay conexi\xF3n a Internet",
  ERROR_HEADING_GENERIC: "Algo sali\xF3 mal",
  ABOUT_ME: "Acerca de m\xED",
  ERROR_DELETING_YOUR_DOCUMENT: "Error al borrar tu documento",
  DOCUMENT_DELETED: "Documento eliminado",
  ADD_A_LINK_NAME: "Agregar un nombre de enlace",
  ACTIVITIES_PLURALIZATION: "{count, plural, one {Actividad} other {Actividades}}",
  DELETE_DOCUMENT: "Borrar documento",
  CANNOT_BE_UNDONE: "Esta acci\xF3n no se puede deshacer",
  SURE_YOU_WANT_TO_DELETE: "Est\xE1s seguro de que quieres eliminar {name}",
  DISMISSED_BY_MEMBER_DATE: "Descartado por {member} {date}",
  DISMISSED: "Descartado",
  ALL_DOCUMENTS: "Todos los documentos",
  DOCUMENT_RENAMED_SUCCESSFULLY: "Documento renombrado con \xE9xito.",
  ERROR_RENAMING_YOUR_DOCUMENT: "Error al renombrar tu documento. Vuelve a intentarlo.",
  SPENDING_ACCOUNTS_INSIGHTS: "Conocimientos de la Cuenta de gastos",
  INSIGHT_CARD_UNIT_FLOORS_CLIMBED_OLD: "pisos",
  INSIGHT_CARD_UNIT_STEPS_OLD: "pasos",
  KM: "km",
  CAL: "Cal",
  KJ: "KJ",
  MIN: "min",
  MILES: "{count, plural, one {milla} other {millas}}",
  YOUR_DOCUMENT_WILL_BE_AVAILABLE_SOON: "Tu documento estar\xE1 disponible en breve. Por favor, espera unos momentos",
  DOCUMENT_SUBMITTED: "Documento enviado",
  ERROR_UPLOADING_YOUR_FILE: "Error al subir tu archivo. Int\xE9ntalo de nuevo.",
  COMPLETED_BY_MEMBER_DATE: "Completado por {member} {date}",
  ASSIGNED_TO_MEMBER_DATE: "Asignado a {member} {date}",
  VISIT_LINK: "Visitar enlace",
  QUESTION_PROGRESS: "PREGUNTA {questionNumber} DE {totalQuestions}",
  AMOUNT_QUESTIONS: "{amount} preguntas",
  VIEW_DOCUMENT_ACTIONS: "Ver acciones de {documentName}.",
  VALIDATION_ERROR_MSG: "Elige la Fecha de vencimiento despu\xE9s de la Fecha de vigencia",
  SAVED_CREDITS: "\xA1Se guardaron los cr\xE9ditos!",
  SET_CREDITS: "Establecer cr\xE9ditos",
  CREDITS_EXPIRY_DATE: "Fecha de vencimiento",
  CERTIFICATION_TYPE: "Tipo de certificaci\xF3n",
  CERTIFICATION_CREDITS: "Cr\xE9ditos",
  INVOICES_TOOLTIP: "Haz clic aqu\xED para ver los datos de facturaci\xF3n a nivel de usuario",
  VIEW_OR_DOWNLOAD: "Ver / Descargar",
  PREVIEW_OF_FILE: "Vista previa de {fileName}",
  SELECT_PARTICIPANT_ERROR: "Selecciona a alguien a quien enviar un mensaje",
  TRANSACTION_TABLE_CAPTION: "Historial de transacciones",
  MESSAGES_PAGINATION_ERROR: "No se han podido cargar los mensajes. <button>Int\xE9ntalo de nuevo.</button>",
  MAXIMUM_FILE_SIZE_IS: "El tama\xF1o m\xE1ximo del archivo es {fileSize}.",
  FILE_TYPE_TOO_LARGE: "El tama\xF1o del archivo es demasiado grande",
  MODIFIED_ON_DATE: "Modificado el {date}",
  ASSIGNING_ACTIVITY_ERROR_MESSAGE_TOAST: "Vuelve a la p\xE1gina de edici\xF3n del formulario de actividad para corregir los errores e int\xE9ntalo de nuevo.",
  ERROR_ASSIGNING_ACTIVITY: "Error al asignar actividad.",
  ERROR_SAVING_TRY_AGAIN: "Error al guardar los cambios. Int\xE9ntalo de nuevo",
  SAVED_NETSUITE_ID: "NetSuite ID se ha guardado.",
  SAVE_NETSUITE_ID: "Guardar NetSuite ID",
  NETSUITE_ID: "NetSuite ID",
  NETSUITE: "NetSuite",
  USER_ADDED_DOCUMENT: "{user} agreg\xF3 un documento",
  START_A_NEW_MESSAGE: "Iniciar un nuevo mensaje",
  NO_MESSAGES_DETAIL: "Tu Asesor de atenci\xF3n asignado te ayudar\xE1 a tomar decisiones de atenci\xF3n sanitaria m\xE1s inteligentes, en cada paso del camino.",
  NO_MESSAGES_YET: "No tienes mensajes (\xA1todav\xEDa!)",
  RECENT_MESSAGES: "Mensajes recientes",
  MARK_AS_DONE_ACTIVITY_SUCCESS_TOAST: "La actividad ha sido marcada como lista para {memberName}.",
  UNASSIGN_ACTIVITY_SUCCESS_TOAST: "La actividad ha sido desasignada a {memberName}.",
  MARK_AS_DONE_ERROR_TOAST: "Error: la actividad no se ha marcado como lista",
  ACTIVITY_ERROR_MESSAGE_TOAST: "Actualiza la p\xE1gina e int\xE9ntalo de nuevo.",
  UNASSIGN_ACTIVITY_ERROR_TOAST: "Error: la actividad no se ha desasignado",
  USE_FILE_IN_ONE_OF_THESE_FORMATS: "Usa un archivo en uno de estos formatos: {fileTypes}",
  FILE_TYPE_NOT_SUPPORTED: "Tipo de archivo no compatible",
  ADD_A_LINK: "A\xF1adir un enlace v\xE1lido",
  ERROR_MESSAGE_ASSIGN_MODAL: "Intenta recargar la p\xE1gina.",
  ERROR_HEADING_ASSIGN_MODAL: "Algo sali\xF3 mal.",
  ERROR_MESSAGE_SALESFORCE: "Intenta recargar la p\xE1gina. Si eso no funciona, sal de Salesforce y vuelve a entrar.",
  ERROR_HEADING: "Algo sali\xF3 mal por nuestra parte.",
  UNABLE_TO_SEND: "No se puede enviar, int\xE9ntalo de nuevo",
  REFRESH_PAGE_TRY_AGAIN: "Actualiza la p\xE1gina e int\xE9ntalo de nuevo",
  ERROR_ASSIGNING_ACTIVITY_TO_NAME: "Error al asignar actividad a {name}",
  ACTIVITY_ASSIGNED_TO_NAME: "\xC9xito al asignar actividad a {name}",
  APP_AND_DEVICE_METRICS: "M\xE9tricas de aplicaciones y dispositivos",
  REMOVE_FILE: "Eliminar archivo",
  ADD_A_DOCUMENT: "Agregar un documento",
  DRAG_FILES_HERE_OR: "Arrastra archivos aqu\xED o",
  SELECT_A_MEMBER: "Selecciona un miembro",
  ASSIGNED_TO: "Asignado a",
  LINK_URL: "URL del enlace",
  LINK_NAME: "Nombre del enlace",
  DESCRIBE_THE_ACTIVITY: "Describir la actividad",
  CONTENT: "Contenido",
  NAME_THE_ACTIVITY: "Nombrar la actividad",
  ACTIVITY_NAME: "Nombre de actividad",
  SELECT_A_CATEGORY: "Selecciona una categor\xEDa",
  CATEGORY: "Categor\xEDa",
  SELECT_AN_IMAGE: "Seleccionar una imagen",
  LAST_30_DAYS: "\xDAltimos 30 d\xEDas",
  LAST_7_DAYS: "\xDAltimos 7 d\xEDas",
  GENERIC_MESSAGING_ERROR: "Parece que ha habido un problema, actualiza la p\xE1gina e int\xE9ntalo de nuevo.",
  POINTS_MISSED: "{points} puntos perdidos",
  POINTS_EARNED: "{points} puntos ganados",
  ACTIVITY_EXPIRY_DATE: "Expira el {date}",
  HIGH_PRIORITY_ACTIVITY_ALT_TEXT: "Esta es una actividad de alta prioridad",
  MEDIUM_PRIORITY_ACTIVITY_ALT_TEXT: "Esta es una actividad de prioridad media",
  LOW_PRIORITY_ACTIVITY_ALT_TEXT: "Esta es una actividad de baja prioridad",
  BACK_TO_INVOICES: "Volver a facturas",
  BILLINGS_INSIGHTS_MESSAGE: "Esta tabla contiene datos de facturaci\xF3n completos s\xF3lo a partir de enero de 2023. Para el ciclo de facturaci\xF3n anterior, vuelve a las facturas.",
  INVOICE_DATA: "Datos de facturaci\xF3n",
  EMPTY_COMPLETED_ACTIVITIES: "A medida que el miembro complete actividades, aparecer\xE1n aqu\xED.",
  EMPTY_ASSIGNED_ACTIVITIES: "Las actividades que asignes a este miembro aparecer\xE1n aqu\xED.",
  COMPLETED_ASSIGNED_ACTIVITY_TABLE_HEADING: "{memberFirstName} ha completado {activityCount} {amount, plural, one {Actividad} other {Actividades}}.",
  ACTIVITY: "Actividad",
  ENTER_DOCUMENT_NAME: "Por favor, introduce el nombre del documento",
  RENAME: "Cambiar el nombre",
  VIEW_ALL_METRICS: "Ver m\xE9tricas de aplicaciones y dispositivos",
  APPS_AND_DEVICES: "Aplicaciones y dispositivos",
  YOUR_METRICS_SUBTITLE: "Los datos de salud m\xE1s recientes de las aplicaciones y dispositivos conectados.",
  YOUR_METRICS: "Mis m\xE9tricas",
  AVERAGE_TOKENS: "Fichas promedio",
  PROGRAM_QUIT_DATE: "Fecha de salida",
  CARE_TEAM_MEMBER: "Miembros del equipo de atenci\xF3n",
  MEMBERS_SORTED_BY_ACTIVITIES: "Miembros ordenados por actividades recientes",
  NO_ONE_HERE: "No hay nadie aqu\xED",
  NO_MEMBERS_MESSAGE: "Actualmente no tienes ning\xFAn familiar o cuidador a\xF1adido",
  ACTIVITY_ASSIGNMENT: "Asignaci\xF3n de actividad",
  ASSIGNED: "Asignado",
  MESSAGES_IN_CONVERSATION_WITH: "Mensajes en la conversaci\xF3n con {participants}",
  MESSAGES_IN_CONVERSATION_TITLED: "Mensajes en la conversaci\xF3n titulada {subject}",
  YOU: "T\xFA",
  USER_COLON: "{user}:",
  ADD_DOCUMENT: "Agregar un documento",
  OPEN_DOCUMENT_ACTIONS_DROPDOWN: "Abre el desplegable de acciones del documento para {title}",
  RETRY: "Reintentar",
  TROUBLE_DISPLAYING_DATA: "Tenemos problemas para mostrar tus datos",
  YOU_ARE_PREVIEWING_THE_ACTIVITY: "Est\xE1 viendo la actividad",
  LINK: "Enlace",
  RETURN: "Volver",
  UNASSIGN: "Desasignar",
  CREATE_NEW_ACTIVITY: "Crear una nueva actividad",
  ALL_MESSAGES_FOR_THREAD_BANNER: "Por favor, no nos env\xEDes informaci\xF3n espec\xEDfica sobre tus condiciones de salud a trav\xE9s de este chat.",
  MESSAGE_THREADS_LIST_SUBTITLE: "Nuestro equipo puede tardar hasta 2 d\xEDas laborables en responder. Si se trata de una emergencia m\xE9dica, llama al 911.",
  KPMG_RE_ENROLL_HELP_TEXT: "Si no vuelves a seleccionar la cobertura aprobada anteriormente, no se trasladar\xE1 al nuevo a\xF1o del plan.",
  CAROUSEL_DIGITAL_WALLET_DESCRIPTION: "Accede a todos tus beneficios y cuentas de gastos, en un solo lugar.",
  CAROUSEL_DIGITAL_WALLET_HEADING: "Cartera digital",
  CAROUSEL_JOURNEY_DESCRIPTION: "Alcanza tus objetivos con una trayectoria de salud personalizada.",
  CAROUSEL_JOURNEY_HEADING: "Trayectoria",
  CAROUSEL_ALL_IN_ONE_DESCRIPTION: "Mantente f\xE1cilmente al tanto de tu salud, bienestar y beneficios.",
  CAROUSEL_ALL_IN_ONE_HEADING: "Aplicaci\xF3n todo en uno",
  PREVIOUSLY_ASSIGNED_ACTIVITIES: "Actividades asignadas anteriormente",
  POLICY_INFORMATION_UNAVAILABLE_DEPENDENTS: "No hay informaci\xF3n para este miembro. Por favor, intenta con otro miembro.",
  POLICY_INFORMATION_UNAVAILABLE: "No hay informaci\xF3n disponible para este periodo de la p\xF3liza.",
  INSIGHT_CHART_MINDFUL_DURATION_DATUM_LABEL: "Tuviste {value} minutos conscientes el {date}",
  INSIGHT_CHART_MINDFUL_DURATION_MONTH_DESCRIPTION: "El n\xFAmero de minutos conscientes cada d\xEDa del mes.",
  INSIGHT_CHART_MINDFUL_DURATION_WEEK_DESCRIPTION: "El n\xFAmero de minutos conscientes para cada d\xEDa de la semana.",
  INSIGHT_CHART_FLOORS_CLIMBED_DATUM_LABEL: "Has subido {value} pisos de escaleras el {date}",
  INSIGHT_CHART_FLOORS_CLIMBED_MONTH_DESCRIPTION: "El n\xFAmero de pisos que has subido cada d\xEDa del mes.",
  INSIGHT_CHART_FLOORS_CLIMBED_WEEK_DESCRIPTION: "El n\xFAmero de pisos que has subido cada d\xEDa de la semana.",
  INSIGHT_CHART_ACTIVE_DURATION_DATUM_LABEL: "Tuviste {value} minutos activos el {date}",
  INSIGHT_CHART_ACTIVE_DURATION_MONTH_DESCRIPTION: "El n\xFAmero de minutos activos cada d\xEDa del mes.",
  INSIGHT_CHART_ACTIVE_DURATION_WEEK_DESCRIPTION: "El n\xFAmero de minutos activos para cada d\xEDa de la semana.",
  INSIGHT_CHART_ENERGY_BURNED_DATUM_LABEL: "Has quemado {energy} {unit} el {date}",
  INSIGHT_CHART_ENERGY_BURNED_MONTH_DESCRIPTION: "El n\xFAmero de {unit} que has quemado cada d\xEDa del mes.",
  INSIGHT_CHART_ENERGY_BURNED_WEEK_DESCRIPTION: "El n\xFAmero de {unit} que has quemado cada d\xEDa de la semana.",
  INSIGHT_CHART_STEPS_DATUM_LABEL: "Has caminado {distance} {unit} el {date}",
  INSIGHT_CHART_STEPS_MONTH_DESCRIPTION: "El n\xFAmero de pasos que has caminado cada d\xEDa del mes.",
  INSIGHT_CHART_STEPS_WEEK_DESCRIPTION: "El n\xFAmero de pasos que has caminado cada d\xEDa de la semana.",
  INSIGHT_CHART_MONTH_TITLE: "Gr\xE1fico de barras mensual {type}",
  INSIGHT_CHART_WEEK_TITLE: "Gr\xE1fico de barras semanal {type}",
  INSIGHT_CARD_HEADER_MINDFUL_DURATION: "Minutos de atenci\xF3n plena",
  CARE_ADVISOR: "Asesor de atenci\xF3n",
  CHECK_FAQ: "Consulta nuestras preguntas frecuentes.",
  BACK_TO_SIGN_IN: "Volver a Iniciar sesi\xF3n",
  FAMILY_CAP: "Familia",
  ASSIGN_ACTIVITY: "Asignar actividad",
  ASSIGN_AN_ACTIVITY: "Asignar una actividad",
  COMPOSE: "Componer",
  YOUR_CARE_ADVISOR: "Tu asesor de atenci\xF3n",
  VIEW_DATA_SOURCES: "Ver fuentes de datos",
  INSIGHT_CHART_DISTANCE_DATUM_LABEL: "Has caminado {distance} {unit} el {date}",
  INSIGHT_CHART_DISTANCE_MONTH_DESCRIPTION: "El n\xFAmero de {unit} que has caminado cada d\xEDa del mes.",
  INSIGHT_CHART_DISTANCE_MONTH_TITLE: "Gr\xE1fico de barras mensual de distancia",
  INSIGHT_CHART_DISTANCE_WEEK_DESCRIPTION: "El n\xFAmero de {unit} que has caminado cada d\xEDa de la semana.",
  INSIGHT_CHART_DISTANCE_WEEK_TITLE: "Gr\xE1fico de barras semanal de distancia",
  DAY_OF_THE_MONTH: "D\xCDA DEL MES",
  DAY_OF_THE_WEEK: "D\xCDA DE LA SEMANA",
  LEADERBOARD_CURRENT_TEAM_NAME: "{team} (Tu equipo)",
  NO_DOCUMENTS_DESCRIPTION: "Puedes a\xF1adir documentos para compartirlos con tu asesor asistencial y mantenerlos organizados en un solo lugar.",
  NO_DOCUMENTS: "No hay documentos aqu\xED (todav\xEDa)",
  OR_CALL_PHONE: "o llama a {phoneNumber}",
  LEAVE_TEAM_FAILURE: "Algo ha ido mal, por favor, int\xE9ntalo de nuevo.",
  SUCCESSFULLY_LEFT_TEAM: "Has abandonado con \xE9xito {teamName}",
  CANT_JOIN_TEAM_JOIN_CHALLENGE_DETAIL: "Parece que a\xFAn no te has inscrito en el reto. Tendr\xE1s que inscribirte antes de poder unirte a {teamName}.",
  JOIN_TEAM_SUCCESS_BUTTON: "Ir a Detalles del equipo",
  JOIN_TEAM_SUCCESS_DETAIL: "Vamos a conquistar estas actividades juntos.",
  JOIN_TEAM_SUCCESS_TITLE: "\xA1Bienvenido al equipo!",
  VIEW_DOCUMENT: "Ver documento:",
  UPLOADED_DOCUMENT: "Subido {date} \u2022 Subido por {user}",
  INDIVIDUAL_CAP: "Individual",
  CURRENTLY_NOT_SHARING_DATA: "Actualmente no est\xE1s compartiendo ning\xFAn dato para {metric}",
  START_TRACKING_METRIC: "Empieza a rastrear {metric}",
  SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER_TITLE: "Ay\xFAdanos a mejorar la experiencia del Conocimiento de la cuenta de gastos",
  SPENDING_ACCOUNTS_INSIGHTS_FEEDBACK_BANNER: "Nos complace anunciar el lanzamiento del producto viable m\xEDnimo del panel de informaci\xF3n interactiva para cuentas de gastos. \xBFC\xF3mo calificar\xEDas el valor que tiene para ti la experiencia de Conocimiento e informaci\xF3n?",
  ASSIGNED_BY_CARE_ADVISOR: "Asignado por el asesor de atenci\xF3n",
  SHOPIFY_EOI_FORM_NOT_READY_INSTRUCTIONS: "<strong>Instrucciones:</strong> Este formulario lo pide tu aseguradora para seguro opcional de vida, seguro opcional de vida para c\xF3nyuges, seguro opcional de enfermedades graves y seguro opcional de enfermedades graves para c\xF3nyuges si lo est\xE1s solicitando por primera vez o si est\xE1s solicitando aumentar el monto de tu cobertura. Si est\xE1s confirmando cantidades que ya se aprobaron, no es necesario el formulario.",
  ACTIVITIES_PILL: "Actividades",
  INSIGHT_CARD_UNIT_FLOORS_CLIMBED: "{count, plural, one {piso} other {pisos}}",
  INSIGHT_CARD_UNIT_STEPS: "{count, plural, one {paso} other {pasos}}",
  FOR_INSURED_BENEFITS_5: "Para que un c\xF3nyuge de hecho quede cubierto, deben haber cohabitado por un m\xEDnimo de 12 meses",
  ENROLLMENT_INSIGHTS_FEEDBACK_BANNER_TITLE: "Ay\xFAdanos a mejorar la experiencia de Conocimiento e informaci\xF3n de la inscripci\xF3n",
  ENROLLMENT_INSIGHTS_FEEDBACK_BANNER: "Justo a tiempo para la pr\xF3xima inscripci\xF3n abierta, nos complace presentar algunas mejoras nuevas en torno a nuestra soluci\xF3n de Conocimiento de la inscripci\xF3n. \xBFC\xF3mo calificar\xEDas el valor que tiene para ti la experiencia de Conocimiento e informaci\xF3n?",
  UNABLE_TO_CREATE_TEAM: "No fue posible crear un equipo nuevo: intenta de nuevo. Si ya te uniste a un equipo, necesitar\xE1s dejar el equipo actual antes de crear uno nuevo.",
  RETRIEVAL_EMAIL_SENT_MESSAGE: "Busca en tu correo electr\xF3nico un mensaje de League para configurar tu cuenta nueva.",
  RETRIEVAL_EMAIL_SENT: "Correo electr\xF3nico de recuperaci\xF3n enviado",
  REVIEW_FAQ: "revisa nuestras preguntas frecuentes",
  RETRIEVE_ACCOUNT_SUCCESS_MESSAGE: "Si no recibes un correo electr\xF3nico de League en los pr\xF3ximos 30 minutos, aseg\xFArate de haber ingresado la direcci\xF3n correcta de tu correo electr\xF3nico y { FAQ } si sigues teniendo problemas.",
  BACK_TO_LOGIN: "Volver al inicio de sesi\xF3n",
  RETRIEVE_ACCOUNT_MESSAGE: "Para recuperar tu cuenta, ingresa la direcci\xF3n de correo electr\xF3nico asociada con tu cuenta de League.",
  RETRIEVE_ACCOUNT: "Recupera tu cuenta",
  ASSIGNED_TODAY: "hoy",
  ASSIGNED_ON: "el {date}",
  PREVIOUS_30_DAYS: "\xDAltimos 30 d\xEDas",
  NEXT_30_DAYS: "Pr\xF3ximos 30 d\xEDas",
  PREVIOUS_WEEK: "Semana pasada",
  NEXT_WEEK: "Pr\xF3xima semana",
  VIEW_ALL_METRIC_DATA: "Ver todos los datos de {metric}",
  AVERAGE: "Promedio",
  SPOUSE_OPTIONAL_LIFE: "Seguro de vida opcional del c\xF3nyuge",
  DOCUMENTS: "Documentos",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILD: "Yo mismo, mis hijos, mi pareja de hecho y el hijo de mi pareja de hecho",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILD: "Yo mismo, mi pareja de hecho y el hijo de mi pareja de hecho",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD_CHILDREN: "Yo mismo, mis hijos y el hijo de mi pareja de hecho",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILD: "Yo mismo y el hijo de mi pareja de hecho",
  BROWSE_SUPPORTED_DATA: "\xBFQu\xE9 puedo rastrear?",
  START_TRACKING_HEALTH_BODY: "Sigue f\xE1cilmente tus m\xE9tricas a lo largo del tiempo para ver hasta d\xF3nde has llegado y c\xF3mo puedes trabajar para alcanzar tus objetivos de salud.",
  START_TRACKING_HEALTH_HEADER: "Empieza a rastrear tu salud",
  DATA_AND_INSIGHTS: "Datos y observaciones",
  SPENDING_ACCOUNTS_PILOT: "Conocimientos de la cuenta de gastos (Piloto)",
  CARE_COLLECTIONS: "Colecciones de atenci\xF3n m\xE9dica",
  YEARLY_PREMIUM: "Prima anual",
  CHANGING_UNITS_OF_MEASURE_TIP: "Esto afectar\xE1 a la forma en que se muestran las mediciones de m\xE9tricas de aplicaci\xF3n y dispositivo.",
  UPDATE_UNITS_OF_MEASURE: "Cambio de unidades de medida",
  MANAGE_UNITS_CHANGES_SAVED: "Cambios guardados a las {time}",
  MANAGE_UNITS_APPLYING_CHANGES: "Aplicando cambios...",
  DATAPOINT_CATEGORY_BLOOD: "Sangre",
  DATAPOINT_CATEGORY_BODY: "Cuerpo",
  DATAPOINT_CATEGORY_ACTIVITY: "Actividad",
  SET_BENEFIT_VENDOR: "\xA1Se guard\xF3 el proveedor de beneficios!",
  CREATE_NEW_VENDOR: "Crear proveedor nuevo",
  SAVE_VENDOR: "Guardar proveedor",
  PRIMARY_COLOUR_HEX_TOOLTIP: "Esto se puede buscar con una herramienta cuentagotas que est\xE1 disponible en Figma. Selecciona el color primario/principal del proveedor",
  PRIMARY_COLOUR_HEX: "Hex de color primario",
  VENDOR_LOGO: "Logotipo del proveedor",
  LOGO_IMAGE_TOOLTIP: "Para obtener mejores resultados, usa una imagen en formato PNG",
  LOGO_IMAGE: "Imagen del logotipo",
  VENDOR_NAME: "Nombre",
  VENDOR_ID: "ID del proveedor",
  UPLOAD_IMAGE_NOTE: "Nota: aseg\xFArate de que el logotipo est\xE1 en formato horizontal antes de cargarlo o no se mostrar\xE1 correctamente en la tarjeta de la cartera.",
  BENEFIT_VENDOR_LIST_INFO: "Los proveedores de beneficios se incluyen en las carteras de los miembros de League para indicarles qui\xE9n es el proveedor afiliado para un beneficio espec\xEDfico. Antes de crear un proveedor nuevo, verifica si ya existe. Al crear el proveedor, estar\xE1 disponible en un men\xFA desplegable cuando se configure un beneficio dentro de un grupo o una clase de beneficio, solo para grupos de Estados Unidos.",
  BENEFIT_VENDOR_LIST: "Lista de proveedores de beneficios",
  BACK_TO_VENDOR_LIST: "Volver a la lista de proveedores",
  MEMBER_LABEL: "Miembro",
  MEMBERS_LOWERCASE: "{totalParticipants} miembros",
  YOO_FITNESS: "YOO Fitness",
  BIOTEL_CARE: "BioTel Care",
  IGLUCOSE: "iGlucose",
  UNDERARMOUR: "Under Armour",
  LOSE_IT: "Pi\xE9rdelo",
  MISFIT: "Misfit",
  IHEALTH: "iHealth",
  STRAVA: "Strava",
  OMRON: "Omron",
  WITHINGS: "Withings",
  POLAR: "Polar",
  GARMIN: "Garmin",
  FITBIT: "Fitbit",
  GOOGLE_FIT: "Google Fit",
  APPLE_HEALTH: "Salud de Apple",
  WHEN_MULTIPLE_SOURCES_AVAILABLE: "Cuando haya varias fuentes disponibles, la fuente de datos con el mayor valor se registrar\xE1 al final del d\xEDa (11:59pm).",
  SOURCES_CAN_UPDATE: "Se permiten las siguientes fuentes para actualizar tu",
  DATA_SOURCES: "Fuentes de datos",
  GET_ASSISTANCE_NOW_SUBHEADING: "Habla con nuestros profesionales de salud certificados y nuestro personal de servicio, quienes pueden ayudarte a ahorrar dinero al recomendarte los recursos m\xE1s accesibles y de mejor calidad en tu \xE1rea, as\xED como brindarte respuestas a todas las preguntas sobre tus beneficios.",
  SEND: "Enviar",
  WRITE_A_MESSAGE: "Escribir un mensaje...",
  WRITE_TO: "Escribir a {user}",
  NO_CARE_COLLECTIONS: "Lo sentimos, no hay colecciones de cuidados disponibles.",
  TOKENS_YESTERDAY_HIGHLIGHT: " \xA1ayer!",
  USER_OR_TEAM_EARNED_TOKENS_HIGHLIGHT: "<strong>{name}</strong> gan\xF3 un total de ",
  EARNED_ACHIEVEMENT_HIGHLIGHT: "<strong>Logro {achievement} Ganado</strong>",
  ACTIVE_MINUTES_HIGHLIGHT: "<strong>Hoy has movido {total} de minutos</strong>",
  STEPS_WALKED_HIGHLIGHT: "<strong>Ayer has caminado {total} de pasos</strong>",
  JOINED_TEAM_HIGHLIGHT: "\xA1Se ha unido <strong>{teamName}</strong>!",
  CREATED_TEAM_HIGHLIGHT: "\xA1Se ha creado <strong>{teamName}</strong>!",
  JOINED_CHALLENGE_HIGHLIGHT: "\xA1Se ha unido <strong>{challengeTitle}</strong>!",
  YOU_SENT: "T\xFA enviaste:",
  RESULT_COUNT: "{count, plural, one {# resultado} other {# resultados}}",
  ANIMATION_ACCESSIBILITY_PLAYBACK: "Reproducir o pausar la animaci\xF3n actualmente seleccionada",
  JOURNEY_DELIGHT_3: "Los peque\xF1os pasos pueden marcar una gran diferencia. T\xFA est\xE1s dando ese paso.",
  JOURNEY_DELIGHT_2: "Activo. Centrado. Manteni\xE9ndote en tu carril. Nos encanta verlo.",
  JOURNEY_DELIGHT_1: "La motivaci\xF3n te hace empezar. El h\xE1bito te mantiene en marcha. Ya lo tienes.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS: "Efectivo a partir del 1 de diciembre de 2022, el costo del seguro de vida b\xE1sico se puede pagar con WP$. Los d\xF3lares del fondo de bienestar se asignan primero al costo de la cobertura de medicamentos, m\xE9dica y dental. Si te quedan WP$, se aplicar\xE1n de forma autom\xE1tica al costo del seguro de vida b\xE1sico. Los d\xF3lares del fondo de bienestar que se usen para cubrir el costo del seguro de vida b\xE1sico se considerar\xE1n un beneficio gravable. Si prefieres pagar el seguro de vida b\xE1sico con deducciones de n\xF3mina despu\xE9s de impuestos, puedes asignar los WP$ restantes a una de las cuentas de gastos. El seguro de vida opcional se seguir\xE1 pagando por medio de deducciones de n\xF3mina.",
  KPMG_LIFE_INSURANCE_WELLNESS_DOLLARS_HEADING: "\xA1Nuevo para 2022! Paga por el seguro de vida b\xE1sico con d\xF3lares del fondo de bienestar (WP$)",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_WP: "\xA1NUEVO! Si despu\xE9s de hacer tus selecciones de medicamentos, m\xE9dicas y dentales te quedan WP$, se aplicar\xE1n autom\xE1ticamente al costo del seguro de vida b\xE1sico. Los d\xF3lares del fondo de bienestar que se usen para cubrir el costo del seguro de vida b\xE1sico son un beneficio gravable. Esto no se aplica para los beneficios del seguro de vida opcional.",
  CREATE_MESSAGE: "Crear mensaje",
  TO: "Para",
  MESSAGE_SUBJECT_PLACEHOLDER: "Establecer el tema del mensaje",
  MESSAGE_SUBJECT: "Asunto del mensaje (opcional)",
  NEW_MESSAGE: "Nuevo mensaje",
  ALL_RECORDED_DATA: "Todos los datos registrados",
  CHALLENGES_LEGAL_DISCLAIMER: "Este Reto pretende ser una forma divertida de que todos participen y tengan una oportunidad justa de ganar. En ocasiones, el organizador del Reto puede ofrecer premios en efectivo o de otro tipo. League no dise\xF1a la estructura del Reto ni decide si se ofrecen premios. Si tienes dudas sobre la estructura de este Reto o crees que has sido excluido de la posibilidad de ganar, habla con el organizador del Reto.",
  NO_RECOMENDATIONS: "Lo sentimos, no tenemos ninguna recomendaci\xF3n para esta \xE1rea de salud en este momento.",
  FEEDBACK_TITLE: "\xBFQu\xE9 tan \xFAtil fue esta informaci\xF3n?",
  TODAYS_FOCUS_USER_PROMPT: "Visita tu Trayectoria para completar las Actividades del reto.",
  TODAYS_FOCUS: "Enfoque de hoy",
  RETURN_TO: "Volver a {location}",
  NO_MESSAGES_DESCRIPTION: "\xA1Comienza enviando un nuevo mensaje!",
  NO_MESSAGES_HEADING: "Todav\xEDa no hay mensajes",
  UNREAD: "No le\xEDdo",
  CURRENT_PAGE: "p\xE1gina actual",
  NEW_MESSAGE_DEFAULT: "Este es el comienzo de un nuevo mensaje",
  CARD_BACK_FILENAME: "{benefitName}-reverso",
  CARD_FRONT_FILENAME: "{benefitName}-anverso",
  START_A_VISIT: "Iniciar una visita",
  NETWORK_MISSING_COVERAGE: "Tu plan no tiene ninguna cobertura de {network}",
  SEND_NEW_MESSAGE: "Enviar un nuevo mensaje",
  MESSAGES: "Mensajes",
  LIKED: "Me gusta",
  LIKE: "Me gusta",
  VISION_BENEFITS_DESCRIPTION: "Se rellena con el nombre del dependiente si se han coordinado los beneficios de la vista y si la funcionalidad est\xE1 activada para el grupo",
  TERMINATION_REASON_DESCRIPTION: "Este campo refleja la causa de la separaci\xF3n del miembro del Empleador",
  SUSPENDED_TYPE_DESCRIPTION: "El estado de terminaci\xF3n del miembro",
  SUSPENDED_NOTICE_DATE_DESCRIPTION: "Fecha de aviso de terminaci\xF3n",
  SUSPENDED_DATE_DESCRIPTION: "Fecha de terminaci\xF3n",
  SUSPENDED_BENEFITS_EXTENSION_DATE_DESCRIPTION: "Se concede al empleado despedido para mantener los beneficios activos, este campo refleja la fecha en que estos beneficios terminan.",
  SUSPENDED_DESCRIPTION: "Terminado",
  SUPERVISOR_DESCRIPTION: "Una persona a cargo de un departamento espec\xEDfico a quien los empleados pueden reportar",
  REINSTATED_DATE_DESCRIPTION: "Fecha en la que se ha reactivado a un miembro despu\xE9s de haber sido dado de baja previamente",
  REINSTATED_DESCRIPTION: "El miembro que fue dado de baja anteriormente vuelve a estar activo",
  PREFERRED_FIRST_NAME_DESCRIPTION: "Nombre del empleado",
  PHONE_NUMBER_DESCRIPTION: "N\xFAmero de contacto del miembro",
  PAYROLL_ID_DESCRIPTION: "N\xFAmero de identificaci\xF3n del empleado utilizado por el agente de n\xF3minas",
  PAY_GROUP_DESCRIPTION: "A efectos de la n\xF3mina, los miembros se colocan en grupos de pago en funci\xF3n de la frecuencia de pago, la ubicaci\xF3n, etc. Tambi\xE9n influye en la cantidad por pago que se muestra en la inscripci\xF3n a los usuarios.",
  OFFICE_LOCATION_DESCRIPTION: "Ciudad/ID de la oficina donde el miembro ha sido empleado",
  NO_PLATFORM_FEE_CHARGES_DESCRIPTION: "Excluir a los empleados de las tarifas de la plataforma",
  INVITATION_CODE_DESCRIPTION: "Este c\xF3digo se genera para enviar un enlace al miembro para activar su perfil de League",
  HR_MANAGER_DESCRIPTION: "Gerente de recursos humanos",
  HOURLY_RATE_DESCRIPTION: "Se reflejar\xE1 un importe en este campo si el miembro es pagado por hora y no por salario",
  HIDE_TYPEFORM_STEP_DESCRIPTION: "No se pedir\xE1 al miembro que rellene un Typeform",
  HIDE_DEPENDENTS_STEP_DESCRIPTION: "No permite que el miembro agregue/cambie dependientes en ning\xFAn momento",
  HIDE_BENEFIT_CONFIG_STEP_DESCRIPTION: "Este campo impide que un miembro realice cambios en los beneficios en cualquier momento",
  FLSA_EXEMPT_DESCRIPTION: "Bandera de exenci\xF3n de la Ley de Normas Laborales Justas (Fair Labor Standards Act)",
  EXTENDED_HEALTH_BENEFITS_DESCRIPTION: "Se rellena con el nombre del dependiente si se han coordinado los beneficios de la salud y si la funcionalidad est\xE1 activada para el grupo",
  EMPLOYEE_LEAVE_START_DATE_DESCRIPTION: "Ejemplo: una miembro se encuentra de baja por maternidad, este campo refleja la fecha de inicio de la baja",
  DRUG_BENEFITS_DESCRIPTION: "Se rellena con el nombre del dependiente si se han coordinado los beneficios de medicamentos y si la funcionalidad est\xE1 activada para el grupo",
  DEPARTMENT_DESCRIPTION: "Lugar de trabajo del empleado en la empresa",
  DENTAL_CARE_DESCRIPTION: "Se rellena con el nombre del dependiente si se han coordinado los beneficios dentales y si la funcionalidad est\xE1 activada para el grupo",
  CUSTOM_FIELDS_DESCRIPTION: "Espec\xEDfico (puede variar seg\xFAn los requisitos del grupo)",
  BILLING_DIVISION_DESCRIPTION: "El cliente se factura en funci\xF3n de la divisi\xF3n de facturaci\xF3n, un miembro se incluye en la factura correspondiente en funci\xF3n de la divisi\xF3n que se le asigna.",
  ANNUAL_EARNINGS_FOR_POOLED_BENEFITS_DESCRIPTION: "Salario anual del empleado, vinculado a los beneficios basados en el salario",
  ACTIVATION_DATE_DESCRIPTION: "Fecha en la que el miembro crea/registra su inicio de sesi\xF3n en League",
  VIEW_ALL_INSIGHTS: "Ver todas las observaciones",
  YOUR_INSIGHTS_SUBTITLE: "Ve los datos de salud m\xE1s recientes recibidos de tus aplicaciones y dispositivos conectados",
  YOUR_INSIGHTS_TITLE: "Tus observaciones",
  CHANGING_YOUR_UNITS: "El cambio de tus unidades afectar\xE1 a la forma en que se muestran en la aplicaci\xF3n.",
  UPDATING_UNITS_OF_MEASURE: "Actualizaci\xF3n de las unidades de medida",
  YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NOR_POINTS: "S\xF3lo te faltan unas cuantas preguntas para completar la evaluaci\xF3n.",
  YOURE_QUESTIONS_AWAY_WITHOUT_QUESTIONS_NUMBER: "S\xF3lo te faltan unas cuantas preguntas para ganar {points} puntos.",
  LIVE_BALANCE_REMAINING: "{amount} restante",
  LIVE_BALANCE_SPEND: "{spentAmount} de {totalAmount} gastado",
  APPS_AND_DEVICES_CURRENTLY_SUPPORTED: "aplicaciones y dispositivos compatibles actualmente",
  CONNECTING_UNAVAILABLE_DETAILS_WITH_LINK: "Por ahora, visita la aplicaci\xF3n m\xF3vil de League para conectar tus aplicaciones y dispositivos de salud favoritos. Si quieres saber m\xE1s, puedes encontrar una lista completa de las",
  CONNECT_DEVICES_LEARN_MORE: "M\xE1s informaci\xF3n",
  HOW_CAN_WE_HELP: "\xBFC\xF3mo podemos ayudar?",
  GET_ASSISTANCE_NOW: "Obt\xE9n asistencia ahora",
  OLDER: "M\xE1s antiguo",
  PAST_30_DAYS: "\xDAltimos 30 d\xEDas",
  PAST_7_DAYS: "\xDAltimos 7 d\xEDas",
  ALL_INSIGHTS: "Todas las observaciones",
  NO_DATA_AVAILABLE: "No hay datos disponibles",
  MANAGE_UNITS: "Cambiar las unidades de medida",
  ADD_APPS_DEVICES: "Ver aplicaciones y dispositivos",
  ACCUMULATION_PERIOD: "Per\xEDodo de acumulaci\xF3n: {start} - {end}",
  LAST_UPDATED_CARRIER_DATE: "\xDAltima actualizaci\xF3n: {date} de {carrier}",
  FIELD_SETTINGS_LIST_TWO: 'Debes utilizar el bot\xF3n "guardar" en la parte inferior de esta p\xE1gina para aplicar tus cambios.',
  FIELD_SETTINGS_LIST_ONE: "Desactivar un campo no lo eliminar\xE1 de la base de datos ni borrar\xE1 los valores introducidos anteriormente.",
  LOAD_MORE_FALLBACK_ERROR: "No podemos cargar m\xE1s resultados en este momento. Por favor, int\xE9ntalo m\xE1s tarde.",
  LOAD_MORE_ERROR: "Se ha producido un error al cargar tus resultados. Por favor, int\xE9ntalo de nuevo.",
  REMEMBER_TO_SAVE: "Por favor, recuerda desplazarte hasta el final y pulsar guardar.",
  FIELD_SETTINGS: "Configuraci\xF3n de campo",
  INSIGHT_CARD_HEADER_MINDFULNESS: "Conciencia plena",
  INSIGHT_CARD_HEADER_HEIGHT: "Altura",
  INSIGHT_CARD_HEADER_BODY_TEMPERATURE: "Temperatura corporal",
  INSIGHT_CARD_HEADER_WEIGHT: "Peso",
  INSIGHT_CARD_HEADER_BLOOD_OXYGEN: "Ox\xEDgeno en la sangre",
  INSIGHT_CARD_HEADER_BLOOD_PRESSURE: "Presi\xF3n arterial",
  INSIGHT_CARD_HEADER_BLOOD_GLUCOSE: "Glucosa en sangre",
  INSIGHT_CARD_HEADER_HEART_RATE: "Ritmo card\xEDaco",
  INSIGHT_CARD_HEADER_SLEEP_DURATION: "Duraci\xF3n del sue\xF1o",
  INSIGHT_CARD_HEADER_ENERGY_BURNED: "Energ\xEDa quemada",
  INSIGHT_CARD_HEADER_ACTIVE_DURATION: "Minutos activos",
  INSIGHT_CARD_HEADER_FLOORS_CLIMBED: "Pisos subidos",
  INSIGHT_CARD_HEADER_STEPS: "Pasos",
  INSIGHT_CARD_HEADER_DISTANCE: "Distancia",
  SINCE_LAST_WEEK: "desde la semana pasada",
  PLAN_PROGRESS: "Progreso del plan",
  CHALLENGE_LETS_START_TEAMS_ENABLED: "Est\xE1s preparado para empezar tu reto. \xBFQuieres unirte o crear un equipo?",
  YOU_LOOK_GREAT: "\xA1Te ves genial!",
  VIEWABLE: "Visible",
  FIELD_SETTINGS_NOTE: "<strong>Nota:</strong>",
  FIELD_SETTINGS_SUBTITLE: "La configuraci\xF3n de esta p\xE1gina te permite alternar la visibilidad de campos de perfil espec\xEDficos en el perfil de miembro. Util\xEDzalos para ocultar de la vista de los miembros los campos que un grupo no est\xE1 utilizando.",
  FIELD_SETTINGS_TITLE: "Configuraci\xF3n del campo de perfil",
  RECOMMENDED_NEXT_STEP: "Pr\xF3ximo paso recomendado",
  VIEW_THIS_RESOURCE: "Ver este recurso",
  VISIBILITY: "Visibilidad",
  FIELD_SETTINGS_SUBHEADER: "Proporciona una experiencia de empleador a medida al administrador de recursos humanos, eligiendo qu\xE9 campos no relacionados con el sistema deben ser visibles en el Formulario de perfil del empleado.",
  YOUVE_EARNED_ACHIEVEMENT_BADGE: "Has obtenido una insignia de logro",
  TAKE_ME_BACK: "Ll\xE9vame de vuelta",
  VIEW_ALL_ACHIEVEMENTS: "Ver todos los logros",
  SEE_ALL_ACHIEVEMENTS: "Ver todos los logros",
  CHALLENGE_FAQ_CONTENT: "Consulta nuestro {helpCenterLink} para todas tus preguntas m\xE1s frecuentes.",
  CHALLENGE_FAQ_HEADING: "\xBFTienes preguntas sobre los Retos?",
  FEATURES_HIGHLIGHT_CAROUSEL_LABEL: "Carrusel de caracter\xEDsticas destacadas",
  ENROLLMENT_CHOOSE_A_TEMPLATE: "Elige una plantilla...",
  ENROLLMENT_TEMPLATE_SELECT_LIST_LABEL: "Seleccionar una plantilla",
  CREATE_NEW_TEMPLATE: "Crear una nueva plantilla",
  ENROLLMENT_CONFIGURE_TEMPLATE: "Configurar la plantilla",
  ENROLLMENT_TEMPLATE_TOOLTIP: "Elige una plantilla por defecto de la lista o crea tu propia plantilla personalizada espec\xEDfica para el grupo, la clase o el conjunto utilizando los botones de abajo.",
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_OR_FUTURE_HEADING: "No hay nada planeado - \xA1todav\xEDa!",
  NO_HIGHLIGHTS_ACTIVITY_DESCRIPTION: "Vuelve aqu\xED para ver pronto los aspectos m\xE1s destacados del reto.",
  NO_ACTIVITY_TITLE: "No hay nada que ver aqu\xED (\xA1todav\xEDa!)",
  VIEW_TEAM_DETAILS: "Ver detalles del equipo",
  CREATE_TEAM_SUCCESS_DESCRIPTION: "Empieza a formar tu equipo. Comparte este enlace con otros para que puedan completar las actividades juntos.",
  CREATE_TEAM_SUCCESS: "\xA1Tu equipo est\xE1 listo!",
  TEAMS_SELECT_AVATAR_DESCRIPTION: "Este avatar aparece en el perfil de tu equipo y en la tabla de clasificaci\xF3n.",
  TEAM_PROFILE_PHOTO_DESCRIPTION: "Esta foto aparece en el perfil de tu equipo y en la tabla de clasificaci\xF3n.",
  SEARCH_FOR_TEAM: "Buscar un equipo",
  PC_HEALTH_ID: "ID de PC Health",
  ACTIVITY_DISMISS_ERROR: "No hemos podido eliminar la actividad en este momento. Por favor, int\xE9ntalo de nuevo.",
  ACTIVITY_COMPLETE_ERROR: "No hemos podido completar la actividad en este momento. Por favor, int\xE9ntalo de nuevo.",
  ACHIEVEMENT_MODAL_LABEL: "Detalle de logro modal",
  NO_SOLUTIONS_AVAILABLE: "Lo sentimos, no hay soluciones disponibles en este momento.",
  NONE_OF_THE_ABOVE: "Ninguno de los anteriores",
  DATAPOINT_SYNC_ISSUE: "Hubo un problema al sincronizar tu {dataPoint}.",
  FIX_SYNC_ISSUE: "Resolver problema",
  SYNC_ISSUE: "PROBLEMA DE SINCRONIZACI\xD3N",
  SET_TEAM_PHOTO: "Establecer foto de equipo",
  SLIDE_OF_TOTAL: "Diapositiva {key} de {total}",
  TEAM_NAME_ERROR: "Por favor, introduce un nombre para tu equipo.",
  TEAM_NAME_LABEL: "Nombre del equipo",
  CHANGE_TEAM_PHOTO: "Cambiar la foto del equipo",
  SELECT_TEAM_PHOTO: "Seleccionar la foto del equipo",
  CREATE_A_TEAM: "Crear un equipo",
  QLE_WELCOME_IMPORTANT_NOTE_EFFECTIVE_DATE_KPMG: "Tienes hasta {qleEnrollmentEndDate} para hacer cambios en tus elecciones. Si cambias tus elecciones, tus nuevos beneficios entrar\xE1n en vigor el primer d\xEDa del mes siguiente a la presentaci\xF3n de tus elecciones.",
  DATE_AND_TIME: "Fecha y hora",
  PROGRAM_REMOVE_ERROR: "No hemos podido eliminar el programa en este momento. Por favor, int\xE9ntalo de nuevo.",
  PROGRAM_ADD_ERROR: "No hemos podido agregar el programa en este momento. Por favor, int\xE9ntalo de nuevo o elige otro programa.",
  SWITCH_TO_NEW_ENROLLMENT_DESIGN_TOOL: "Cambiar a la nueva herramienta de dise\xF1o de inscripciones",
  ENROLLMENT_CONFIGURATION: "Configuraci\xF3n de la inscripci\xF3n",
  UNABLE_TO_COPY_LINK_TO_CLIPBOARD: "No hemos podido copiar el enlace en tu portapapeles. Por favor, resalta el enlace y c\xF3pialo manualmente.",
  INVITE_LINK_COPIED_TO_CLIPBOARD: "\xA1Enlace de invitaci\xF3n copiado al portapapeles!",
  TEAM_INVITE_LINK: "Enlace de invitaci\xF3n al equipo",
  "CHAT_DEEPLINK_START_REGISTERED_NURSE_CHAT ": "Chat del Personal de enfermer\xEDa registrado",
  "CHAT_REGISTERED_NURSE_ONLINE_HOURS ": "Horario en l\xEDnea del Personal de enfermer\xEDa registrado",
  CHAT_SEND_EMAIL_TO_REGISTERED_NURSE: "Enviar correo electr\xF3nico al personal de enfermer\xEDa registrado",
  CHAT_REGISTERED_NURSE_DESCRIPTION: "Obt\xE9n las respuestas y los consejos que necesitas sobre temas relacionados con la salud, incluso se\xF1ales, s\xEDntomas y opciones de tratamiento.",
  REGISTERED_NURSE: "Personal de enfermer\xEDa registrado",
  GET_AVATARS_ERROR: "\xA1Oops! Se ha producido un problema al cargar los avatares. Por favor, actualiza e int\xE9ntalo de nuevo.",
  LEADERBOARD_USERNAME: "Nombre de usuario",
  LEADERBOARD_TOKENS: "Fichas",
  LEADERBOARD_RANKING: "Clasificaci\xF3n",
  USER_EARNED: "{user} ha ganado un total de",
  XTH_PLACE: "{currentRank, selectordinal, one {#st} two {#nd} few {#rd} other {#th} } puesto",
  SELECT_UP_TO: "Seleccionar hasta {number}",
  WORD_WITH_EXCLAMATION_POINT: "\xA1{word}!",
  TOKENS: "fichas",
  EARN: "Gana",
  UP_YOUR_GAME_DESCRIPTION: "\xDAnete a un equipo o crea uno para enfrentaros juntos al reto.",
  UP_YOUR_GAME: "\xA1Sube de nivel!",
  CREATE_TEAM_DESCRIPTION: "Personaliza el nombre y el avatar de tu equipo. \xA1Invita a otros a unirse!",
  CREATE_TEAM: "Crea un equipo",
  JOIN_TEAM_DESCRIPTION: "Busca un equipo para unirte y competir con tus compa\xF1eros.",
  JOIN_A_TEAM: "\xDAnete a un equipo",
  CHALLENGE_TEAMS_DESCRIPTION: "\xDAnete a un equipo existente o crea el tuyo para competir juntos en retos.",
  TEAMS: "Equipos",
  JOIN_OR_CREATE_TEAM: "Unirse a/crear un equipo",
  YOUR_TEAM: "Tu equipo",
  SHOW_MY_ACTIVITY: "Mostrar mi actividad",
  CHALLENGE_ACTIVITY_DETAIL: "Aqu\xED los usuarios pueden ver cuando completas una actividad u obtienes un logro mientras participas en un reto.",
  CHALLENGE_ACTIVITY: "Actividad del reto",
  LEAVE_TEAM_DETAIL: "\xBFSeguro que quieres eliminar {teamName}? Tus puntos ya no se contar\xE1n en el promedio del equipo.",
  LEAVE_TEAM_QUESTION: "\xBFSalir de {teamName}?",
  CANT_JOIN_TEAM_DETAIL: "Reg\xEDstrate para el reto para unirte a {teamName}.",
  CANT_JOIN_TEAM_TITLE: "A\xFAn no puedes unirte a {teamName}",
  PRIVACY: "Privacidad",
  INVITE_FRIENDS: "Invitar a amigos",
  LEAVE_TEAM: "Salir del equipo",
  INVITE: "Invitar",
  TEAM_INVITE_LINK_DESCRIPTION: "\xA1Cuantos m\xE1s, mejor! Comparte el enlace con tus amigos para que se puedan unir a tu equipo.",
  TEAM_INVITE_DESCRIPTION: "Invita a tus amigos al equipo para completar actividades juntos.",
  TEAM_INVITE_HEADING: "\xA1La uni\xF3n hace la fuerza!",
  X_LIKES: '{activityLikes} "Me gusta"',
  TOTAL_TOKENS: "Total de fichas",
  CURRENT_RANK: "Puntuaci\xF3n actual",
  HIGHLIGHTS: "Destacados",
  X_SPOTS_AVAILABLE: "{spotsAvailable} puestos disponibles",
  X_TEAM_MEMBERS: "{memberTotal} miembros del equipo",
  JOIN_TEAM: "Unirte a un equipo",
  SELECT_PROFILE_PICTURE_ERROR: "Elige una foto de perfil antes de continuar.",
  SIGN_UP_BEGINS_DATE: "El registro empieza el {date}",
  SUCCESSFULLY_LEFT_CHALLENGE: "Abandonaste el desaf\xEDo correctamente",
  SECONDARY_DESCRIPTION: "Descripci\xF3n secundaria",
  PROFILE_DETAILS: "Detalles del perfil",
  USER_SEARCH_SUCCESSFUL: "B\xFAsqueda de usuario completada",
  NO_USERS_FOUND: "No se han encontrado usuarios",
  NO_USERS_AWAITING_VALIDATION: "No hay usuarios esperando validaci\xF3n",
  UNABLE_TO_JOIN_CHALLENGE: "No puedes unirte al desaf\xEDo. Int\xE9ntalo de nuevo m\xE1s tarde.",
  UNABLE_TO_LEAVE_CHALLENGE: "No puedes dejar el desaf\xEDo. Int\xE9ntalo de nuevo m\xE1s tarde.",
  LEAGUE_FAMILY_INVITE_MODAL_SUCCESS_DESCRIPTION: "Informa a tus seres queridos que pueden descargar la app de League.",
  LEAGUE_FAMILY_INVITE_MODAL_SUCCESS_HEADING: "Se ha enviado tu invitaci\xF3n",
  LEAGUE_FAMILY_INVITE_MODAL_PRIVACY_DISCLAIMER: "Tu informaci\xF3n personal est\xE1 segura con nosotros, no ser\xE1 compartida con tus dependientes. M\xE1s informaci\xF3n en nuestra {privacyPolicyLink}.",
  LEAGUE_FAMILY_INVITE_MODAL_CONSENT_LABEL: "Acepto que el receptor de esta invitaci\xF3n conoce y aprueba esta solicitud y que la informaci\xF3n provista es exacta y verdadera.",
  LEAGUE_FAMILY_INVITE_MODAL_DESCRIPTION: "Utiliza una direcci\xF3n de correo electr\xF3nico que no est\xE9 asociada con una cuenta de League ya existente. Esta invitaci\xF3n vence 14 d\xEDas luego de su env\xEDo.",
  LEAGUE_FAMILY_INVITE_MODAL_RESEND_HEADING: "\xBFD\xF3nde debemos reenviar la invitaci\xF3n de {name}?",
  LEAGUE_FAMILY_INVITE_MODAL_HEADING: "\xBFD\xF3nde debemos enviar la invitaci\xF3n de {name}?",
  OPT_IN_TERMS_NOT_ACKNOWLEDGED_ERROR: "<strong>\xA1Espera! Nos falta informaci\xF3n.</strong> Por favor, lee y acepta el acuerdo antes de continuar.",
  JOB_DETAILS: "Detalles del trabajo",
  DEPENDENT_NOTIFICATION_MESSAGE: "Se podr\xEDa invitar a nuevos dependientes",
  LEAGUE_FAMILY_EDUCATION_BANNER_SUBHEADER: "Tus dependientes pueden acceder a League y disfrutar tambi\xE9n de una vida m\xE1s saludable.",
  LEAGUE_FAMILY_EDUCATION_BANNER_HEADER: "\xBFTe preguntas por qu\xE9 es beneficioso a\xF1adir a tus dependientes?",
  GOAL_REACHED: "\xA1Has llegado a la meta!",
  ACTIVITY_COMPLETE_LATER_PROMPT: 'Para seguir adelante, haz clic en "Volver a trayectoria" y vuelve m\xE1s tarde para marcarlo como listo.',
  RELOAD_MY_SUGGESTIONS: "Recargar mis sugerencias",
  OUTCOMES_FRICTION_SCREEN_DESCRIPTION: "Danos un momento para encontrar algunos recursos \xFAtiles para ti basados en tus respuestas",
  OUTCOMES_FRICTION_SCREEN_TITLE: "Personalizando sugerencias...",
  LEADERBOARD: "Tabla de clasificaci\xF3n",
  TARGET_LANGUAGES_OPTIONAL_HINT: "Selecciona el idioma en el que est\xE1 escrito tu bandera para dirigirte a los miembros con esa configuraci\xF3n de idioma. Si no haces ninguna selecci\xF3n, la bandera ser\xE1 visible para todos los miembros, independientemente de la configuraci\xF3n del idioma.",
  PREVIOUS_SLIDE: "Diapositiva anterior",
  NEXT_SLIDE: "Siguiente diapositiva",
  IMAGE_CAROUSEL_DISPLAY: "Visualizaci\xF3n del carrusel del blog de League",
  IMAGE_BANNER_DISPLAY: "Visualizaci\xF3n de la bandera",
  VIEW_CHALLENGE_DETAILS: "Ver detalles del reto",
  TOP_RANKED: "Mejor clasificado",
  LEADERBOARD_CURRENT_USER_NAME: "{user} (T\xFA)",
  LEADERBOARD_ALL_TIME_RANKING: "Clasificaci\xF3n de todos los tiempos",
  ACTIVE_CHALLENGE: "{length, plural, one {Reto activo} other {Retos activos}}",
  PROGRESS_RECORD_ERROR: "Hubo un problema al registrar tu \xFAltimo progreso.",
  GOAL_INCOMPLETE: "Debes completar el objetivo antes de continuar.",
  MANAGER: "Gerente",
  WORK_LOCATION: "Lugar de trabajo",
  DEPARTMENT: "Departamento",
  ENGLISH_TITLE_MERCH_LIST_HINT: "Este campo s\xF3lo se puede ver dentro de la aplicaci\xF3n en los carruseles, para los banners normales (como los banners de los clientes), este campo es s\xF3lo interno.",
  YOUR_RANKING: "Tu clasificaci\xF3n",
  KAISER_COMPLIANCE_DESCRIPTION: "Entiendo que (excepto los casos del Tribunal de Reclamaciones de Menor Cuant\xEDa, las reclamaciones sujetas a un procedimiento de apelaci\xF3n de Medicare o a la regulaci\xF3n del procedimiento de reclamaciones de ERISA, y cualquier otra reclamaci\xF3n que no pueda estar sujeta a arbitraje vinculante seg\xFAn la ley vigente) cualquier disputa entre yo, mis herederos, familiares u otras partes asociadas, por un lado, y Kaiser Foundation Health Plan, Inc. (KFHP), cualquier proveedor de asistencia sanitaria contratado, administradores u otras partes asociadas, por otro lado, por la supuesta violaci\xF3n de cualquier deber que surja o est\xE9 relacionado con la pertenencia a KFHP, incluida cualquier reclamaci\xF3n por mala praxis m\xE9dica u hospitalaria (una reclamaci\xF3n de que los servicios m\xE9dicos fueron innecesarios o no autorizados o se prestaron de forma inadecuada o negligente.",
  KAISER_COMPLIANCE_TITLE: "Acuerdo de arbitraje del plan de salud de la Fundaci\xF3n Kaiser",
  CALL_TO_ACTION_HINT: "Este es el texto resaltado que los miembros pueden pulsar para abrir la URL",
  TARGET_USER_IDS_OPTIONAL_HINT: "Para dirigir tu banner a miembros espec\xEDficos. Los ID de usuario deben estar separados por comas. Si dejas esta secci\xF3n en blanco, se dirigir\xE1 a todos los miembros, o s\xF3lo a los miembros de las empresas especificadas en ID de grupo (si se utiliza ese campo)",
  TARGET_GROUP_IDS_OPTIONAL_HINT: "Para dirigir tu banner a los miembros de empresas espec\xEDficas. Si dejas esta secci\xF3n en blanco, tu banner se dirigir\xE1 a todos los miembros, o s\xF3lo a los miembros especificados en ID de usuario (si utilizas ese campo).",
  TARGET_BY_COUNTRY_OPTIONAL_HINT: "Para dirigir tu banner a pa\xEDses espec\xEDficos. Si tu banner debe mostrarse a todos los miembros, sin importar su ubicaci\xF3n, no selecciones nada en esta secci\xF3n.",
  ACTION_HINT: "Selecciona Abrir URL si quieres que los miembros realicen una acci\xF3n en el banner, o d\xE9jalo como ninguno si s\xF3lo es informativo.",
  CALL_TO_ACTION_TEXT: "Texto de llamada a la acci\xF3n",
  ENTER_BODY_COPY_FOR_ARTICLE: "Introduce el cuerpo del art\xEDculo",
  BODY_COPY: "Cuerpo del texto",
  ENTER_HEADLINE_FOR_ARTICLE: "Introduce un encabezado para el art\xEDculo",
  HEADLINE: "Encabezado",
  ENTER_TARGET_USER_IDS: "Introduce los identificadores de usuario de destino separados por comas",
  ENTER_TARGET_GROUP_IDS: "Introduce los identificadores de los grupos de destino separados por comas",
  TARGET_USER_IDS_OPTIONAL: "Identificadores de usuario objetivo (opcional)",
  TARGET_GROUP_IDS_OPTIONAL: "Identificaci\xF3n del grupo objetivo (opcional)",
  UPLOAD_CSV_FILE: "Subir un archivo CSV",
  ENERGY_BURNED: "calor\xEDas quemadas",
  DISTANCE: "distancia recorrida",
  NO_LEADERBOARD_ACTIVITY_DESCRIPTION: "Vuelve aqu\xED para ver tu clasificaci\xF3n cuando comience el reto.",
  I_AGREE: "Estoy de acuerdo",
  PSA_SHORT_DESC: "<p>Usa tu {name} ({shortName}) para pagar los servicios de estilo de vida y bienestar que te interesan. Esta cuenta es un beneficio gravable. Tu PSA cubre muchos productos y servicios para apoyar un estilo de vida saludable.</p>",
  ACTIVITY_SAVE_PROGRESS: "\xA1Guarda tu progreso para el d\xEDa!",
  ACTIVITY_COMPLETE_PROMPT: 'Esta actividad no se completar\xE1 hasta que hagas clic en "Marcar como listo".',
  CLOSE_ACTIVITY: "Cerrar actividad",
  MARK_AS_DONE: "Marcar como listo",
  UPDATE_MERCH_LIST_SUCCESSFUL: "La lista de productos se ha actualizado con \xE9xito",
  MODULE_TEMPLATE_BUTTON: "Bot\xF3n de la plantilla del m\xF3dulo",
  EXIT: "Salir",
  EXIT_CHALLENGE_SIGN_UP_CONFIRM_DESCRIPTION: "Si eliges salir, es posible que tus cambios no se guarden.",
  EXIT_CHALLENGE_SIGN_UP_CONFIRM_TITLE: "\xBFEst\xE1s seguro de que quieres salir?",
  YOUR_PROGRESS_IN_NUMBERS: "{current} de {total} {unit} o {percentage} por ciento completado",
  YOUR_PROGRESS: "Tu progreso",
  UPDATED: "Se ha actualizado",
  TRACK_CHALLENGE_MANUALLY_LEARN_MORE: "M\xE1s informaci\xF3n",
  TRACK_CHALLENGE_MANUALLY_DESCRIPTION: "Si eliges dar seguimiento de forma manual, puedes marcar las actividades como completas, pero no podr\xE1s conectar un dispositivo m\xE1s adelante.\n{learnMoreLink} sobre seguir tu progreso manualmente.\n",
  TRACK_CHALLENGE_AUTOMATICALLY_INSTRUCTION: "sigue estos pasos",
  TRACK_CHALLENGE_AUTOMATICALLY_DESCRIPTION: "Si eliges dar seguimiento de forma autom\xE1tica despu\xE9s de registrarte, {instructionsLink} para conectar tu dispositivo con la aplicaci\xF3n m\xF3vil de League.",
  DEVICES_UNCONNECTED_DESCRIPTION: "Conecta tus aplicaciones y dispositivos de salud favoritos para que marquen las actividades como completas de forma autom\xE1tica.",
  DEVICES_UNCONNECTED_TRACK_MANUALLY_SUBTITLE: "Seguir manualmente",
  DEVICES_UNCONNECTED_TRACK_AUTOMATICALLY_SUBTITLE: "Seguir autom\xE1ticamente",
  DEVICES_UNCONNECTED_TITLE: "\xA1Conectemos tu dispositivo!",
  BENEFITS_WALLET_LANDING_TEMPLATE_SELECT_ERROR: "Selecciona una pantalla de inicio diferente para publicar",
  BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_DESCRIPTION: "La pantalla de inicio de la billetera se edit\xF3 con \xE9xito",
  BENEFITS_WALLET_LANDING_TEMPLATE_TOAST_SUCCESS_TITLE: "Pantalla de inicio publicada",
  BENEFITS_WALLET_LANDING_TEMPLATE_LABEL: "Pantalla de inicio",
  BENEFITS_WALLET_LANDING_TEMPLATE_DESCRIPTION: "Selecciona una pantalla de inicio para aplicar en este grupo.  <br />Para crear una nueva, visita Contentful y crea una p\xE1gina de inicio, o comun\xEDcate con el administrador de League Contentful.",
  BENEFITS_WALLET_LANDING_TEMPLATE_HEADER: "Pantalla de inicio de la cartera",
  PUBLISH: "Publicar",
  TRACK_MANUALLY_CONFIRM_DESCRIPTION: "No podr\xE1s conectarte a tu dispositivo m\xE1s adelante.",
  TRACK_MANUALLY_CONFIRM_TITLE: "\xBFEst\xE1s seguro de que quieres dar seguimiento de forma manual?",
  TRACK_HEALTH_DATA_ALT: "El panel de control graficar\xE1 los datos de seguimiento de salud",
  COVERAGE_SEARCH_TRY_AGAIN: "Prueba nuevamente usando diferentes palabras clave.",
  COVERAGE_SEARCH_QUERY_NOT_FOUND: '"{query}" no pudo ser encontrado.',
  SELECT_AN_ANSWER: "Selecciona una respuesta",
  TRACK_CHALLENGE_MANUALLY: "Seguir de forma manual en su lugar",
  TRACK_CHALLENGE_AUTOMATICALLY: "Seguir autom\xE1ticamente",
  DEVICES_CONNECTED_DESCRIPTION: "Ya sincronizamos tus datos. Si as\xED lo eliges, podemos marcar las actividades autom\xE1ticamente como completadas con el {dataPoint} de tu dispositivo.",
  DEVICES_CONNECTED_TITLE: "\xA1Tal parece que ya est\xE1s conectado!",
  TRACK_WEARABLES_ALT_TEXT: "Persona buscando datos de un reloj inteligente",
  STARTING_ON: "Iniciando el {start, date, medium}",
  MARKETING_CONTENT_REMOVED: "\xA1Se elimin\xF3 el contenido de marketing!",
  ACTIVITY_COUNT_GOAL: "Objetivo: {goal} {unit}",
  NO_CHALLENGE_ACTIVITIES_TODAY: "No hay actividades de retos para ti este d\xEDa.",
  ACTIVITIES_APPEAR_HERE_WHEN_CHALLENGE_STARTS: "Las actividades diarias aparecer\xE1n aqu\xED cuando inicie tu reto el <strong>{date}</strong>.",
  CHALLENGE_ABOUT_TO_START: "\xA1Tu reto est\xE1 a punto de comenzar!",
  FLOORS_CLIMBED: "pisos subidos",
  MINDFUL_DURATION: "minutos conscientes",
  LEAVE: "Permiso",
  LEAVE_CHALLENGE_DETAIL: "\xBFEst\xE1s seguro de que deseas eliminar este reto de tu trayectoria? Se restablecer\xE1 cualquier progreso que tengas en este reto.",
  LEAVE_CHALLENGE_QUESTION: "\xBFAbandonar el reto?",
  CONFIRM_UPDATE: "Confirmar la actualizaci\xF3n",
  DISPLAY_AS_CAROUSEL: "Mostrar como carrusel de blogs de League (el carrusel estar\xE1 escondido para los miembros espa\xF1oles)",
  DISPLAY_AS_BANNER: "Mostrar como cintillo",
  ADD_SECTION: "Agregar secci\xF3n",
  SELECT_AVATAR_ERROR: "Debes elegir un avatar antes de continuar.",
  BLUECAT_LSA_TOOLTIP: "Este beneficio solo est\xE1 disponible para los empleados contratados antes del 7 de enero de 2022.",
  BACK_TO_MERCH_LIST: "Regresar a la lista de productos",
  SAVE_LIST_BEFORE_ADDING_CONTENT: "Guarda esta lista nueva primero antes de a\xF1adir contenido.",
  CREATE_NEW_MARKETING_CONTENT: "Crear nuevo contenido de marketing",
  CHALLENGE_PROGRAM_LIBRARY_DESCRIPTION: "Reg\xEDstrate en un reto para completar actividades diarias y competir con tus compa\xF1eros.",
  CHALLENGES: "Retos",
  CHALLENGE_LETS_START: "Comencemos con el desaf\xEDo.",
  YOURE_ALL_SET: "\xA1Ya est\xE1 todo listo!",
  VIEW_FORM: "Ver formulario",
  SIGN_UP_BY: "Inscr\xEDbete antes del {date}",
  UPLOAD_MY_OWN: "Subir el propio",
  SELECT_AVATAR: "Seleccionar avatar",
  PROFILE_PHOTO_DESCRIPTION: "Esta foto aparece en tu perfil de usuario y en tu perfil de salud",
  UPDATE_PROFILE_PHOTO: "Actualiza la foto de tu perfil",
  SET_UP_PROFILE_PHOTO: "Configura tu foto de perfil",
  SKIP_FOR_NOW: "Saltar por ahora",
  MARKETING_CONTENT_SAVED: "\xA1Se guard\xF3 el contenido de marketing!",
  SET_MERCH_LIST: "\xA1Lista de productos establecida con \xE9xito!",
  CONFIRM_REMOVAL_TEXT_UPDATE_LIST: "\xBFEst\xE1s seguro de que deseas guardar esta lista? Esta acci\xF3n es irreversible.",
  HOME_PAGE_CONFIG_SET: "Se estableci\xF3 el contenido de la p\xE1gina de inicio",
  MORE_INFORMATION: "M\xE1s informaci\xF3n",
  CHECK_TO_CONFIRM_PRIVACY_POLICY: "Al marcar esta casilla, expresas tu acuerdo con la <a>pol\xEDtica de confidencialidad</a>.",
  PHONE_LOCK_ALT_TEXT: "Tel\xE9fono con un candado superpuesto en la parte superior.",
  ACCEPT_BEFORE_CONTINUE: "Debes aceptar antes de continuar.",
  DETERMINE_CHALLENGE_WINNERS: "Elegir los ganadores del reto",
  RANK_SCORE: "Clasifica tu puntuaci\xF3n en un panel",
  SHARE_CHALLENGE_PROGRESS: "Comparte tu progreso en el reto con tus compa\xF1eros (y empleador)",
  COMPLETE_ACTIVITIES_SHORT: "Completar actividades",
  CHALLENGE_USING_DATA_TO: "Cuando te registras para un reto, esto es para lo que usamos tus datos:",
  CHALLENGE_SENSITIVE_DATA: "Tus datos de salud y tu informaci\xF3n personal son altamente sensibles y es nuestra responsabilidad mantenerlos privados y seguros. Nos tomamos esta responsabilidad muy en serio.",
  SIGN_UP_FOR_THE_CHALLENGE: "Reg\xEDstrate para el Reto",
  PAY_CALENDAR_MENU: "Men\xFA del calendario de pagos",
  DEFAULT_CONFIG: "Configuraci\xF3n de n\xF3mina predeterminada",
  LINK_PAYROLL_CONFIGS: "Configuraci\xF3n del enlace",
  PAYROLL_REPORT_CONFIGS_COPY: "Elige una configuraci\xF3n del informe de n\xF3mina para enlazar la programaci\xF3n.",
  PAYROLL_REPORT_CONFIGS: "Configuraci\xF3n del informe de n\xF3mina",
  LINK_PAYROLL_REPORT_CONFIGS: "Enlazar la configuraci\xF3n del informe de n\xF3mina",
  REFERENCES: "Referencias",
  ENROLLMENT_PASSED: "No puedes registrarte para este reto porque ya pasaron las fechas de inscripci\xF3n.",
  REQUIRED_FIELD_MERCH_LIST: "Requerida si se proporcion\xF3 la descripci\xF3n en ingl\xE9s.",
  MARKETING_CONTENT_ADDED: "\xA1Se a\xF1adi\xF3 el contenido de marketing!",
  IMAGE: "Imagen",
  ENTER_TAGS_ASSOCIATED_ARTICLE: "Ingresar las etiquetas separadas por comas asociadas con este art\xEDculo (es decir, salud, nutrici\xF3n, millennials)",
  ENTER_READ_TIME_MINUTES: "Ingresar el tiempo de lectura en minutos (\xA1solo valores num\xE9ricos!)",
  ENTER_HEX_CODE: "Ingresar c\xF3digo hex (por ejemplo, #eaeefb)",
  ENTER_SOURCE_ARTICLE: "Ingresar la fuente del art\xEDculo (por ejemplo, NY Times)",
  ENTER_DESCRIPTION_SHOW_ARTICLE_PREVIEW: "Ingresar una descripci\xF3n para mostrar en el texto de vista previa del art\xEDculo",
  ENTER_SUBTITLE_FOR_ARTICLE: "Ingresar un subt\xEDtulo para el art\xEDculo",
  ON_SITE_TREATMENTS: "Tratamientos in situ",
  HEALTH_SCREENINGS: "Ex\xE1menes m\xE9dicos",
  WELLNESS_SEMINARS: "Seminarios de bienestar",
  GROUP_CLASSES: "Clases grupales",
  FLU_CLINICS: "Cl\xEDnicas de influenza",
  CONSUMER_SERVICE: "Servicio al consumidor",
  PEACE_OF_MIND: "Tranquilidad de esp\xEDritu",
  HEALTH_REIMBURSEMENT_ACCOUNT: "Cuenta de reembolso de gastos de salud",
  SPENDING_ACCOUNT: "Cuenta de gastos",
  CORPORATE_WELLNESS: "Bienestar corporativo",
  HAS_LSA_BALANCE: "Tiene saldo de CGB",
  HAS_HSA_BALANCE: "Tiene saldo de CGS",
  URL: "URL",
  OPEN_URL: "Abrir URL",
  TARGET_LANGUAGES_OPTIONAL: "Idiomas meta (opcional)",
  TARGET_USERS_WITH_ANY_OPTIONAL: "Usuarios objetivo con CUALQUIERA de los siguientes (opcional)",
  TARGET_USERS_WITH_ALL_OPTIONAL: "Usuarios objetivo con TODO de lo siguiente (opcional)",
  PROS: "Pros",
  MEMBERS: "Miembros",
  HR_ADMINS: "Administradores de RH",
  EVERYONE: "Todo el mundo",
  TARGET_USER_TYPE_OPTIONAL: "Tipo de usuario objetivo (opcional)",
  TARGET_BY_COUNTRY_OPTIONAL: "Objetivo por pa\xEDs (opcional)",
  TAGS_OPTIONAL: "Etiquetas (opcional)",
  READ_TIME_OPTIONAL: "Tiempo de lectura (opcional)",
  BACKGROUND_COLOR_OPTIONAL: "Color de fondo (opcional)",
  SOURCE_OPTIONAL: "Fuente (opcional)",
  SUBTITLE_OPTIONAL: "Subt\xEDtulo (opcional)",
  TITLE: "T\xEDtulo",
  MARKETING_CONTENT: "Contenido de marketing",
  ACTIVITY_COMPLETE: "Actividad completa",
  ADD_NEW_SECTION: "Agregar secci\xF3n nueva",
  HOME_PAGE_NO_RESULTS: "Sin contenido en p\xE1gina de inicio",
  LIST_HEADING: "Encabezado de la lista",
  SECTION_TYPE: "Tipo de secci\xF3n",
  EXPIRES_TODAY: "Caduca hoy",
  REMOVE_PRESETS: "Eliminar ajustes preestablecidos",
  PRESETS_REPORTS: "Informe de ajustes preestablecidos",
  REMOVE_PRESETS_REPORT: "Eliminar informe de ajustes preestablecidos",
  YOUVE_COMPLETED: "Completaste",
  YOUVE_EARNED_GRAND_TOTAL: "Ganaste un gran total de",
  VIEW_ACHIEVEMENTS: "Ver logros",
  YOU_EARNED: "Acumulaste",
  UNLOCK_ACHIEVEMENTS: "Desbloquea un logro por completar todas las actividades de este programa.",
  GO_TO_JOURNEY: "Ir a Trayectoria",
  SHOPIFY_COLLECTION_NO_DATA: "No hay datos para esto",
  SHOPIFY_COLLECTION_US_TITLE: "Vista previa de Shopify de EE. UU.",
  SHOPIFY_COLLECTION_CA_TITLE: "Vista previa de Shopify de CA",
  PREVIEW_ITEMS: "Vista previa de elementos",
  SHOPIFY_COLLECTION_TITLE: "Productos de Lifemarket en la colecci\xF3n de Shopify",
  JOINED_CHALLENGE: "Te uniste al reto",
  SIGN_UP_DATE_PASSED: "La fecha de registro ya transcurri\xF3",
  LEAVE_CHALLENGE: "Abandonar el reto",
  EXPORT_DEDUCTION_CODES: "C\xF3digos de deducci\xF3n de la exportaci\xF3n",
  SHOPIFY_HELP_TEXT: "ejem. https://marketplace.ca.league.dev/collections/medical-supplies = `medical-supplies`",
  SHOPIFY_COLLECTION_HANDLE: "Identificador de la colecci\xF3n de Shopify",
  LIFEMARKET_MERCH_LIST_HELP_TEXT: "Este es un t\xEDtulo interno del administrador para esta lista de mercader\xEDas de Lifemarket. El t\xEDtulo de la colecci\xF3n de Shopify ser\xE1 el que se muestre a los usuarios en los carruseles.",
  LIST_TITLE: "T\xEDtulo de la lista",
  PROGRAM_TYPE: "Tipo de programa:",
  CHALLENGE: "Reto",
  CHALLENGE_STATUS: "Estado del reto:",
  SIGN_IN_HERE: "Iniciar sesi\xF3n aqu\xED",
  ALREADY_A_LEAGUE_MEMBER: "\xBFYa eres miembro de League?",
  RULES: "Reglas",
  PRIZE: "Premio",
  SIGN_UP_FOR_CHALLENGE: "Inscr\xEDbete para el reto",
  CHALLENGE_DETAILS: "Detalles del reto",
  PARTICIPATE_TO_WIN: "\xA1Participa para tener la oportunidad de ganar!",
  ENDS_DATE: "Finaliza el {date}",
  SIGN_UP_BY_DATE: "Inscr\xEDbete antes del {date}",
  START_AND_END_DATES: "Comienza {startDate} \u2022 Termina {endDate}",
  EXTERNAL_LINK: "Enlace externo",
  MEMBER_WALLET_LINK: "Enlace a la cartera de miembro",
  MEMBER_TOOLING_LINK: "Enlace a las herramientas de miembro",
  EMPLOYEE_PROFILE_LINKS_BY_GROUP: "Enlaces al perfil de los empleados por grupo",
  USER_LINKS: "Enlaces de usuario",
  AVAILABLE_FORMS_FOR_EMPLOYEE_PLAN: "Estos son los formularios disponibles para el plan de empleados",
  NO_FORMS_AVAILABLE_FOR_EMPLOYEE_PLAN: "No hay formularios disponibles para el plan de empleados",
  DEVICES: "Dispositivos",
  NO_USER_DEVICES_PLACEHOLDER: "No hay dispositivos asociados a este usuario",
  PRESETS_COLUMN_BLURB: "Estas columnas se mostrar\xE1n en el informe de izquierda a derecha en el orden que definas en esta lista.",
  AVAILABLE_COLUMNS_BLURB: 'A continuaci\xF3n se enumeran todas las columnas disponibles actualmente para este informe. Si no agregas una columna a la secci\xF3n "Preajustes", no aparecer\xE1 en el informe.',
  AVAILABLE_COLUMNS: "Columnas disponibles",
  PRESETS_NAME: "Nombre de preajustes",
  PRESETS_COLUMN: "Columna de preajustes",
  CUSTOM_REPORT: "Informe personalizado",
  CUSTOM: "Personalizado",
  GROUP_NAME: "Nombre del grupo",
  COMPLETED_X_TIMES: "Se complet\xF3 {amount} veces",
  EARNED_X_TIMES: "Se gan\xF3 {amount} veces",
  CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_MESSAGE: "Esto tendr\xE1 efecto inmediato en el usuario.",
  CONFIRM_REMOVE_USER_PLAN_ENROLLMENT_TITLE: "\xBFEliminar la inscripci\xF3n en el plan de usuarios?",
  COME_BACK_COMPLETE_ACTIVITY_LATER: "Vuelve m\xE1s tarde para completar esta actividad.",
  NO_PAYMENT_PROFILES_PLACEHOLDER: "No hay perfiles de pago asociados a este usuario",
  PAYMENT_PROFILES: "Perfiles de pago",
  NO_PROMO_CODE_HISTORY_PLACEHOLDER: "No hay un historial de c\xF3digos de promoci\xF3n asociado a este usuario",
  PROMO_CODE_HISTORY: "Historial de c\xF3digos de promoci\xF3n",
  PERCENTAGE_USED: "{percentage} utilizado.",
  PROMO_CODE: "C\xF3digo de promoci\xF3n",
  SUGGESTED_FOR_YOU: "Sugerido para ti",
  VALIDATION: "Validaci\xF3n",
  CREDENTIALS: "Credenciales",
  PAYMENT_PROFILE: "Perfil de pago",
  TRIGGER_DEACTIVATE_ACCOUNT_MODAL: "\xBFEst\xE1s seguro de que quieres desactivar esta cuenta?",
  DEACTIVATE_ACCOUNT: "Desactivar la cuenta",
  YOUR_JOURNEY: "Tu trayectoria",
  EARNED: "ganados",
  EDIT_DEPENDENT_DETAILS: "Editar los datos del dependiente",
  BACK_TO_MERCH_LISTS: "Volver a las listas de productos",
  SPANISH_DESCRIPTION_MERCH_LIST: "Descripci\xF3n traducida (es-MX) (es obligatorio si se proporcion\xF3 una descripci\xF3n en ingl\xE9s)",
  FRENCH_DESCRIPTION_MERCH_LIST: "Descripci\xF3n traducida (fr-CA) (es obligatorio si se proporcion\xF3 una descripci\xF3n en ingl\xE9s)",
  ENGLISH_DESCRIPTION_MERCH_LIST: "Descripci\xF3n en ingl\xE9s (opcional)",
  SPANISH_TITLE_MERCH_LIST: "T\xEDtulo traducido (es-MX)",
  FRENCH_TITLE_MERCH_LIST: "T\xEDtulo traducido (fr-CA)",
  ENGLISH_TITLE_MERCH_LIST: "T\xEDtulo en ingl\xE9s",
  PUBLISHED_MERCH_LIST: "Publicado",
  SAVE_MERCH_LIST: "Guardar lista",
  ACTIVITY_PROGRESS: "Progreso de la actividad",
  CONFIRM_REMOVAL_TEXT: "\xBFEst\xE1s seguro de que quieres eliminar {t\xEDtulo}? Esto no se puede deshacer.",
  CONFIRM_REMOVAL: "Confirmar la eliminaci\xF3n",
  CREATE_NEW_LIST: "Crear una nueva lista",
  CONTENT_MERCH_LIST_TITLE: "T\xEDtulo de la lista de contenido comercial",
  CLEAR_FILTERS: "Limpiar filtros",
  FILTER: "Filtro",
  PHONE_NUMBER_UPDATE_SUCCESS: "Celular actualizado",
  PHONE_NUMBER_CONFIRM_MISMATCH: "Los n\xFAmeros de tel\xE9fono deben coincidir",
  PHONE_COUNTRY_CODE_ERROR: "Introduce un c\xF3digo de pa\xEDs v\xE1lido",
  PHONE_NUMBER_ERROR: "Ingresa un n\xFAmero de tel\xE9fono v\xE1lido",
  CONFIRM_NEW_PHONE_NUMBER: "Confirma el nuevo n\xFAmero de tel\xE9fono",
  NEW_PHONE_NUMBER: "Nuevo n\xFAmero de tel\xE9fono",
  NEW_PHONE_COUNTRY_CODE: "Nuevo c\xF3digo de pa\xEDs",
  CURRENT_PHONE_NUMBER: "N\xFAmero de tel\xE9fono actual",
  UPDATE_PHONE_NUMBER: "Actualizar el n\xFAmero de tel\xE9fono",
  NO_ACHIEVEMENTS_DESCRIPTION: "Completa las actividades de tu Trayectoria para empezar a desbloquear logros.",
  VIEW_ACTIVITIES: "Ver actividades",
  MISSED: "Perdida",
  EXPIRES_TOMORROW: "Expira ma\xF1ana",
  RESET_TYPE: "Tipo de restablecimiento",
  ENROLLMENT_TYPE_OPTIONAL: "Tipo de inscripci\xF3n (opcional)",
  BENEFIT_PLAN_OPTIONAL: "Plan de beneficios (opcional)",
  TRIGGER_ADD_PROMO_CODE_MODAL: "\xBFEst\xE1s seguro de que quieres a\xF1adir el c\xF3digo promocional a la cuenta?",
  TRIGGER_REMOVE_PROMO_CODE_MODAL: "\xBFEst\xE1s seguro de que quieres eliminar el c\xF3digo promocional de la cuenta?",
  TRIGGER_UPDATE_FUNDS_PROMO_CODE_MODAL: "\xBFEst\xE1s seguro de que quieres actualizar los fondos?",
  UPDATE_FUNDS_DESCRIPTION_PROMO_CODE: "Agregar o quitar fondos del saldo de los usuarios de cuentas HSA o PSA.",
  UPDATE_FUNDS_PROMO_CODE: "Actualizar fondos",
  REMOVE_PROMO_CODE: "Eliminar el c\xF3digo de promoci\xF3n de la cuenta",
  PROMO_CODE_USER_ID_LABEL: "ID del usuario",
  ADD_PROMO_CODE: "Agregar c\xF3digo de promoci\xF3n a la cuenta",
  TRIGGER_NEW_PROMO_CODE_MODAL: "\xBFSeguro que quieres crear el nuevo c\xF3digo promocional?",
  UPDATE_FUNDS_PROMO_CODE_POLICY_LABEL: "Tipo de p\xF3liza",
  NEW_PROMO_CODE_EXPIRES_LABEL: "Vencimiento",
  NEW_PROMO_CODE_MAX_USERS_LABEL: "Usuarios m\xE1ximos",
  PROMO_CODE_UPDATE_FUNDS_AMOUNT_PLACEHOLDER: "Especifica un monto positivo para agregar o un monto negativo para quitar.",
  NEW_PROMO_CODE_MAX_USERS_PLACEHOLDER: "El n\xFAmero de cuentas de usuario a las que te gustar\xEDa aplicar un c\xF3digo promocional",
  PROMO_CODE_AMOUNT_PLACEHOLDER: "Cualquier cantidad desde $1.00 hasta $50.00 (para cualquier cantidad superior a $50.00, solicite la confirmaci\xF3n del l\xEDder del equipo)",
  PROMO_CODE_USER_ID_PLACEHOLDER: "Introduce el ID de usuario del miembro",
  PROMO_CODE_CODE_PLACEHOLDER: "Deben ser tres letras min\xFAsculas seguidas de tres n\xFAmeros",
  PROMO_CODE_CODE_LABEL: "C\xF3digo",
  NEW_PROMO_CODE: "Nuevo c\xF3digo de promoci\xF3n",
  PROMO_CODES_HEADING: "C\xF3digos de promoci\xF3n",
  DOWNLOAD_SDR_REPORT: "Descargar informe SDR",
  SEARCH_USERS_HEADING: "Buscar usuarios",
  IMG_ALT_EMPTY_STATE_REST: "Hombre descansando en una silla c\xF3moda con una taza caliente y aud\xEDfonos",
  IMG_ALT_EMPTY_STATE_PROGRAMS: "Mujer de pie y desplazando una lista de programas enorme",
  USERS_AWAITING_VALIDATION: "Usuarios esperando validaci\xF3n",
  SEARCH_BY_USERID_EMAIL_OR_NAME: "Buscar por ID de usuario, correo electr\xF3nico o nombre",
  ACHIEVEMENTS: "Logros",
  NO_PROGRAMS: "No hay nada que ver aqu\xED todav\xEDa",
  IMG_ALT_HIGH_FIVE_HANDS: "Dos manos chocando los cinco",
  NO_ACTIVITIES_COMPLETED_TODAY_DESCRIPTION: "Vuelve ma\xF1ana para obtener m\xE1s informaci\xF3n o explorar las actividades sugeridas.",
  NO_ACTIVITIES_COMPLETED_TODAY_HEADING: "\xA1Bien hecho, est\xE1s en una buena racha!",
  REMOVE_GROUP_CATEGORIES_SUCCESS: "Grupo de categor\xEDas eliminado con \xE9xito",
  REMOVE_GROUP_CATEGORIES: "Eliminar categor\xEDas",
  REMOVE_COVERAGE_CATEGORY_CONFIRMATION_DESCRIPTION: "\xBFEst\xE1s seguro de que quieres eliminar todas las categor\xEDas de cobertura de {benefitName}?",
  REMOVE_COVERAGE_CATEGORY_CONFIRMATION_TITLE: "Eliminar la categor\xEDa de cobertura: {benefitName}",
  PATIENT: "Paciente",
  DATE_OF_CONSULT: "Fecha de consulta",
  CONSULT_TYPE: "Tipo de consulta",
  SERVICE: "Servicio",
  RISK_PERSONA_CARD_CAPTION: "Trabajamos con un equipo de expertos en prestaciones para ofrecer estas recomendaciones.",
  RISK_PERSONA_CARD_TITLE: "\xBFPara qui\xE9n es bueno este plan?",
  RISK_PERSONA_CARD_IMAGE_TEXT: "Persona de riesgo",
  RISK_PERSONA: "Persona de riesgo",
  CLOSE_MODAL: "Cerrar Modal",
  SEE_EXAMPLE: "Ver ejemplo",
  YOU_SKIPPED_THIS_ACTIVITY: "Te has saltado esta actividad.",
  LOOKS_LIKE_THIS_WASNT_MEANT_FOR_YOU: "Parece que este no estaba destinado a ti",
  THIS_ACTIVITY_EXPIRED_ON_DATE: "Esta actividad expir\xF3 el {date}.",
  LOOKS_LIKE_YOU_MISSED_THIS_ONE: "\xA1Parece que te has perdido esto!",
  COME_BACK_COMPLETE_ACTIVITY_ON_DATE: "Vuelve y completa esta actividad el {date}.",
  THIS_IS_JUST_A_SNEAK_PEAK: "\xA1Esto es solo un adelanto!",
  HOME_PAGE_CONFIG: "Configuraci\xF3n de la p\xE1gina de inicio",
  CONTENT_MERCH_LIST: "Lista de contenido comercial",
  LIFEMARKET_MERCH_LIST: "Lista de productos de Lifemarket",
  MERCH_LISTS: "Lista de comercios",
  SELECT_PROFILE_PHOTO: "Seleccionar foto de perfil",
  AUTH0_GENERIC_ERROR_MESSAGE: "Algo sali\xF3 mal de nuestro lado. Vuelve a intentarlo. Si el problema persiste, <a>comun\xEDcate con nosotros</a>.",
  INSURANCE_ERROR_PREPARING_FORMS: "Se produjo un error al descargar tu formulario",
  IMG_ALT_TO_DO_CLIPBOARD: "Portapapeles con lista de tareas completada",
  IMG_ALT_STANDING_YOGA_POSE: "Persona haciendo una postura de yoga de pie sobre una esterilla",
  BUTTON_BROWSE_PROGRAMS: "Examinar los programas",
  NO_ACTIVITIES_PAST_DESCRIPTION: "Parece que aprovechaste este d\xEDa para relajarte y recuperarte.",
  NO_ACTIVITIES_PAST_HEADING: "D\xEDa de descanso",
  NO_ACTIVITIES_FUTURE_DESCRIPTION: "No tienes ninguna actividad programada para este d\xEDa.",
  NO_ACTIVITIES_HAS_PROGRAMS_TODAY_DESCRIPTION: "Toca el bot\xF3n 'Agregar' para buscar nuevos programas o navegar por una lista de actividades elegidas solo para ti.",
  DISCOVER_ACTIVITIES_CAPTION: "Navega por las actividades que creemos que te encantar\xE1n",
  DISCOVER_ACTIVITIES: "Descubre nuevas actividades",
  NO_PLAN_ENROLLMENT: "Sin inscripci\xF3n al plan",
  BACK_TO_PREVIOUS: "\u2190 Volver a la p\xE1gina anterior",
  USER_PLAN_ENROLLMENTS_TITLE: "Inscripciones al plan del usuario para: plan {planName}",
  VIEW_USER_PLAN_ENROLLMENT: "\u{1F440} Ver inscripci\xF3n al plan del usuario",
  HR_ADMIN_SEARCH_FILTER_TEXT: "Mostrar solo administradores de Recursos Humanos",
  ACTIVE_EMPLOYEE_SEARCH_FILTER_TEXT: "Mostrar solo empleados activos",
  EMPLOYEE_SEARCH_FILTER_LABEL: "Filtro",
  TRUPANION_CONFIRMATION_DESCRIPTION: 'Al inscribirme en los beneficios de Aflac, afirmo que he le\xEDdo el aviso "Cosas a considerar sobre la cobertura de mi seguro", que se ha puesto a mi disposici\xF3n en este sitio de inscripci\xF3n electr\xF3nica.',
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_6: "Entiendo y acepto que la cobertura que estoy solicitando puede tener una exclusi\xF3n por condici\xF3n preexistente.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_5: "He le\xEDdo la Solicitud/Declaraci\xF3n de asegurabilidad del empleado completa y las declaraciones y respuestas que me corresponden a m\xED y a mi c\xF3nyuge. Certifico que estas declaraciones y respuestas son verdaderas y est\xE1n completas a mi leal saber y entender, y que la compa\xF1\xEDa de seguros utilizar\xE1 las declaraciones y respuestas para determinar la asegurabilidad. Comprendo que cualquier declaraci\xF3n falsa o tergiversaci\xF3n en la Solicitud/Declaraci\xF3n de asegurabilidad del empleado puede dar como resultado la p\xE9rdida de la cobertura bajo el Certificado. Entiendo que ning\xFAn seguro estar\xE1 vigente hasta que se apruebe mi Solicitud/Declaraci\xF3n de asegurabilidad del empleado y se pague la prima necesaria.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_4: "TOME EN CUENTA: las elecciones por enfermedad cr\xEDtica del c\xF3nyuge est\xE1n establecidas en un m\xEDnimo de $ 5,000. Cualquier monto de elecci\xF3n por debajo de $ 5,000 se corregir\xE1 antes de que las elecciones se presenten a Aflac. Si hay un cambio en su tarifa, nos comunicaremos con usted.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_3: "La cobertura no entrar\xE1 en vigor, a menos que est\xE9 trabajando de forma activa en la Fecha de vigencia del certificado. Si no est\xE1 trabajando activamente en esa fecha, la cobertura entrar\xE1 en vigor en la fecha en que regrese a un estado laboral activo.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_2: "He considerado toda mi cobertura de seguro m\xE9dico existente con Aflac y considero que esta cobertura adicional es apropiada para mis necesidades de seguro. Adem\xE1s, entiendo que puedo comunicarme con Aflac al 1-800-992-3522 con respecto a mi p\xF3liza individual y para obtener ayuda para evaluar la idoneidad de mi cobertura de seguro.",
  TRUPANION_CRITICAL_ILLNESS_DESCRIPTION_1: "Si esta cobertura reemplazar\xE1 cualquier p\xF3liza individual existente de Aflac, tome en cuenta que puede ser lo mejor para usted mantener su p\xF3liza individual renovable garantizada a trav\xE9s de una factura directa.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_6: "Autorizo al titular de la p\xF3liza de grupo a deducir la cantidad apropiada en d\xF3lares de mis ganancias en cada per\xEDodo de pago para pagar a Continental American Insurance Company la prima requerida para mi seguro.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_5: "Entiendo y acepto que la cobertura que estoy solicitando puede tener una limitaci\xF3n por condiciones preexistentes. Entiendo y acepto que cualquier limitaci\xF3n de condici\xF3n preexistente puede aplicarse a cualquier condici\xF3n que se haya revelado en este formulario de solicitud. He recibido un Resumen de cobertura correspondiente a la cobertura que se solicita.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_4: "Seg\xFAn mi leal saber y entender, mis respuestas a las preguntas son verdaderas y est\xE1n completas. Se ofrecen a Continental American Insurance Company como base para cualquier seguro emitido. Entiendo que cualquier declaraci\xF3n falsa o tergiversaci\xF3n en la solicitud puede dar como resultado la p\xE9rdida de cobertura bajo el certificado. Entiendo que ning\xFAn seguro estar\xE1 vigente a menos que est\xE9 trabajando de forma activa en la fecha de vigencia de la cobertura y hasta que se apruebe mi solicitud y se pague la prima necesaria. Si no estoy trabajando activamente en la fecha de vigencia de la cobertura, la cobertura entrar\xE1 en vigencia en la fecha en que regrese a un estado de trabajo activo.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_3: "Si un hijo cubierto alcanza una edad l\xEDmite como se especifica en el certificado o en una cl\xE1usula adicional, es su responsabilidad notificar a la compa\xF1\xEDa.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_2: "He considerado toda mi cobertura de seguro m\xE9dico existente con Aflac y considero que esta cobertura adicional es apropiada para mis necesidades de seguro. Adem\xE1s, entiendo que puedo comunicarme con Aflac al 1-800-992-3522 con respecto a mi p\xF3liza individual y para obtener ayuda para evaluar la idoneidad de mi cobertura de seguro.",
  TRUPANION_HOSPITAL_INDEMNITY_DESCRIPTION_1: "Si esta cobertura reemplazar\xE1 cualquier p\xF3liza individual existente de Aflac, tome en cuenta que puede ser lo mejor para usted mantener su p\xF3liza individual renovable garantizada a trav\xE9s de una factura directa.",
  TRUPANION_ACCIDENT_DESCRIPTION_4: "Seg\xFAn mi leal saber y entender, mis respuestas a las preguntas son verdaderas y est\xE1n completas. Se ofrecen a Continental American Insurance Company como base para cualquier seguro emitido. Entiendo que cualquier declaraci\xF3n falsa o tergiversaci\xF3n en la solicitud puede dar como resultado la p\xE9rdida de cobertura bajo el certificado. Entiendo que ning\xFAn seguro estar\xE1 vigente a menos que est\xE9 trabajando de forma activa en la fecha de vigencia de la cobertura y hasta que se apruebe mi solicitud y se pague la prima necesaria. Si no estoy trabajando activamente en la fecha de vigencia de la cobertura, la cobertura entrar\xE1 en vigencia en la fecha en que regrese a un estado de trabajo activo.",
  TRUPANION_ACCIDENT_DESCRIPTION_3: "Si un hijo cubierto alcanza una edad l\xEDmite como se especifica en el certificado o en una cl\xE1usula adicional, es su responsabilidad notificar a la compa\xF1\xEDa.",
  TRUPANION_ACCIDENT_DESCRIPTION_2: "He considerado toda mi cobertura de seguro m\xE9dico existente con Aflac y considero que esta cobertura adicional es apropiada para mis necesidades de seguro. Adem\xE1s, entiendo que puedo comunicarme con Aflac al 1-800-992-3522 con respecto a mi p\xF3liza individual y para obtener ayuda para evaluar la idoneidad de mi cobertura de seguro.",
  TRUPANION_ACCIDENT_DESCRIPTION_1: "Si esta cobertura reemplazar\xE1 cualquier p\xF3liza individual existente de Aflac, tome en cuenta que puede ser lo mejor para usted mantener su p\xF3liza individual renovable garantizada a trav\xE9s de una factura directa.",
  TRUPANION_IMPORTANT_READ_ALL_DESCRIPTION: "Como parte de la experiencia, podr\xE1 elegir los beneficios de Accidente, Enfermedad Cr\xEDtica e Indemnizaci\xF3n hospitalaria de Aflac. Revise el siguiente texto de cumplimiento antes de seguir adelante con sus elecciones de inscripci\xF3n abierta de 2022",
  TRUPANION_IMPORTANT_READ_ALL: "Importante, lea todo lo siguiente",
  TRIGGER_REFUND_CLAIM_MODAL: "\xBFEst\xE1 seguro de que desea reembolsar la siguiente reclamaci\xF3n?",
  TRIGGER_RESUBMIT_CLAIM_PSA_PLACEHOLDER: "Ingrese un monto de LSA",
  TRIGGER_RESUBMIT_CLAIM_HSA_PLACEHOLDER: "Ingrese un monto de HSA",
  TRIGGER_RESUBMIT_CLAIM_CLAIM_ID_PLACEHOLDER: "Ingrese la ID de una reclamaci\xF3n",
  REFUND_CLAIM_HEADING: "Reembolsar reclamaci\xF3n",
  RETRIGGER_PAYMENT_HEADING: "Pago de reactivaci\xF3n",
  PAYMENT_PAYOUT_DETAILS_HEADING: "Detalles de liquidaci\xF3n de pago",
  INVALID_ZIP_CODE_NO_FORMAT: "C\xF3digo postal no v\xE1lido",
  INVALID_POSTAL_CODE_NO_FORMAT: "C\xF3digo postal no v\xE1lido",
  ACTIVITY_STATUS_UPCOMING: "Disponible pronto",
  ACTIVITY_STATUS_REMOVED: "Saltado",
  ACTIVITY_STATUS_EXPIRED: "Vencido",
  ACTIVITY_STATUS: "Estatus de actividad:",
  NEXT_DAY: "Siguiente d\xEDa",
  PREVIOUS_DAY: "D\xEDa anterior",
  BACK_TO_TODAY: "Volver al d\xEDa de hoy",
  YESTERDAY: "Ayer",
  TOMORROW: "Ma\xF1ana",
  CONTRIBUTION_CARD_HEADING: "{vendorName} {name}",
  IT_LOOKS_LIKE_YOU_HAVE_SOME_LEFTOVER_WELLNESS_POOL: "\xA1Parece que tienes algunos {flexDollarTerm} restantes! {message}",
  WELLNESS_POOL_USED_WITH_TERM: "{flexDollarTerm} utilizados",
  LEFTOVER_WELLNESS_POOL_HELPER_TEXT: "<strong>{percent}%</strong> de {flexDollarTerm} restantes que ser\xE1n agregados a esta cuenta",
  WELLNESS_POOL_BALANCE_DEDUCTION_DESCRIPTION: "Estas cuentas tendr\xE1n la prioridad, as\xED que puedes gastar todos tus {flexDollarTerm} en estas cuentas y despu\xE9s tus elecciones previas ser\xE1n cubiertas por deducciones en tu n\xF3mina de pago.",
  WELLNESS_POOL_ELIGIBLE: "\xA1Puedes utilizar tu {flexDollarTerm} para pagar por este beneficio!",
  TRIGGER_INTERAC_PAYMENT_MODAL: "\xBFEst\xE1 seguro de que deseas reenviar el siguiente pago?",
  TRIGGER_INTERAC_PAYMENT_PLACEHOLDER: "Ingrese una ID de pago de Interac",
  TRIGGER_INTERAC_PAYMENT_BODY: 'Ingrese la "ID \xFAnica" de la transacci\xF3n para volver a emitir el pago.',
  TRIGGER_INTERAC_PAYMENT_HEADING: "Volver a enviar pago Interac",
  VIEW_BENEFIT_ALLOCATION_DRY_RUN: "\u{1F4BE} Ver simulacro de asignaci\xF3n de beneficios",
  FULL_AMOUNT: "Monto completo",
  USER_SELECTION: "Selecci\xF3n del usuario",
  DRY_RUN_COVERAGE_VOLUME_OPTION: "Opci\xF3n de volumen de cobertura",
  DRY_RUN_COVERAGE_VOLUME_AMOUNT_APPROVED: "Monto de volumen de cobertura aprobado",
  DRY_RUN_COVERAGE_VOLUME_AMOUNT: "Monto de volumen de cobertura",
  DEPENDENT_COVERAGE: "Cobertura de dependientes",
  ACTIVATION_DATE: "Fecha de activaci\xF3n",
  PLAN_NAME: "Nombre del plan",
  PARENT_BENEFIT_ID: "ID del beneficio de los padres",
  BENEFIT_DETAILS: "Detalles del beneficio",
  SPECIFY_A_TIME_OPTIONAL: "Especifique una hora (opcional)",
  ALLOCATED_BENEFITS: "Beneficios asignados",
  DATE_OF_BIRTH_TOOLTIP_NON_QUEBEC: "<strong>Para los miembros que no son de Quebec, </strong>llene la informaci\xF3n a continuaci\xF3n al designar a un menor como beneficiario.",
  DATE_OF_BIRTH_TOOLTIP_QUEBEC: "<strong> Para los miembros de Quebec, </strong> si designa a un menor como beneficiario, a menos que se cree un fideicomiso, la cantidad pagadera al menor (mientras a\xFAn sea menor) se pagar\xE1 a los padres o tutor(es) legal(es) del menor. Para obtener m\xE1s informaci\xF3n sobre la creaci\xF3n de un fideicomiso, comun\xEDquese con su operador.",
  BENEFIT_SET_HEADING: "Su cobertura de {benefitSetName}",
  X_MISSED: "{missed} perdida(s)",
  X_OF_Y_COMPLETE: "{completed}/{total} completada(s)",
  EMAIL_UPDATE_ERROR: "La direcci\xF3n de correo electr\xF3nico no se pudo actualizar porque {reason}",
  EMAIL_UPDATE_SUCCESS: "Direcci\xF3n de correo electr\xF3nico actualizada",
  EMAIL_CONFIRM_MISMATCH: "Las direcciones de correo electr\xF3nico deben coincidir",
  EMAIL_ADDRESS_ERROR: "Ingrese una direcci\xF3n de correo electr\xF3nico v\xE1lida",
  CONFIRM_NEW_EMAIL: "Confirmar correo electr\xF3nico nuevo",
  NEW_EMAIL: "Correo electr\xF3nico nuevo",
  CURRENT_EMAIL: "Correo electr\xF3nico actual",
  UPDATE_EMAIL_ADDRESS: "Actualizar direcci\xF3n de correo electr\xF3nico",
  RESET_MFA_ERROR: "El m\xE9todo de autenticaci\xF3n multifactor no se pudo restablecer porque {reason}",
  RESET_MFA_SUCCESS: "El m\xE9todo de autenticaci\xF3n multifactor se restableci\xF3 correctamente",
  RESET_MFA_CONFIRM: "Se enviar\xE1 un correo electr\xF3nico a {email} con un enlace para inscribirse en la autenticaci\xF3n multifactor.",
  LEGACY_USER: "Usuario con contrase\xF1a heredada",
  AUTH0_USER: "Usuario Auth0",
  RESET_LEGACY: "Utilice la herramienta heredada para actualizar los detalles de la cuenta",
  RESET_MFA: "Restablecer la autenticaci\xF3n multifactor",
  AUTHENTICATION_METHOD: "M\xE9todo de autenticaci\xF3n",
  ON_LEAVE_REASON: "<strong> \xBFPor qu\xE9 no puedo cambiar nada? </strong> No puede actualizar ni cambiar su plan de beneficios mientras tenga un permiso.",
  ENROLLMENT_DETAILS: "Detalles de la inscripci\xF3n",
  ENROLLMENT_DESIGN_OPTIONS_RESET_ERROR: "Error al aplicar las configuraciones predeterminadas",
  ENROLLMENT_DESIGN_OPTIONS_RESET_SUCCESS: "\xA1Las configuraciones predeterminadas se aplicaron correctamente!",
  APPLY_DEFAULT_CONFIG_WARNING: "Se perder\xE1n todas las personalizaciones hechas en la configuraci\xF3n de la experiencia de inscripci\xF3n. Esta acci\xF3n no se puede deshacer.",
  APPLY_DEFAULT_CONFIG_DESCRIPTION: "La aplicaci\xF3n de la configuraci\xF3n predeterminada sobrescribir\xE1 la configuraci\xF3n de la experiencia de inscripci\xF3n existente. El orden, la agrupaci\xF3n, el dise\xF1o y el contenido de todos los pasos, grupos y conjuntos de beneficios se establecer\xE1n para que coincidan con la configuraci\xF3n predeterminada.",
  APPLY_DEFAULT_CONFIG_TITLE: "Aplicar configuraciones predeterminadas",
  APPLY_DEFAULT_CONFIG_BUTTON: "Aplicar configuraci\xF3n predeterminada",
  OPEN_IN_APP_BUTTON: "Abrir en la aplicaci\xF3n",
  TRY_OUR_APP: "Este contenido se ve mucho mejor en la aplicaci\xF3n, \xA1pru\xE9bala!",
  ENROLLMENT_CREATE_TEMPLATE_TYPE: "Crear plantilla para ID de {type}",
  ENROLLMENT_TEMPLATES_CREATED_ERROR: "Error al crear una nueva plantilla con contenido",
  ENROLLMENT_TEMPLATES_CREATED_SUCCESS: "\xA1Se ha creado con \xE9xito una nueva plantilla de contentful!",
  DESIGNATE_REVOCABLE_BENEFICIARY: "Si procede, designar a mi c\xF3nyuge como beneficiario revocable",
  REVOCABLE_DESIGNATIONS_QUEBEC_RESIDENTS: "Para los residentes de Quebec, si nombra a su c\xF3nyuge legal (matrimonio o uni\xF3n libre) como beneficiario, este beneficiario ser\xE1 irrevocable para todos los siguientes seguros, a menos que marque la casilla a continuaci\xF3n.",
  EDITING_PANEL_LAYOUT_PLACEHOLDER: "Seleccione un dise\xF1o...",
  EDITING_PANEL_LAYOUT: "Dise\xF1o",
  EDITING_PANEL_URL_MUST_BE_VALID: "URL Slug no es v\xE1lido, solo se permiten letras, guiones bajos (_) o guiones (-).",
  EDITING_PANEL_URL_MUST_BE_UNIQUE: '"{url}" se est\xE1 utilizando en otro paso. Elija un URL diferente.',
  EDITING_PANEL_URL_IS_REQUIRED: "Se requiere el slug del URL.",
  EDITING_PANEL_URL_HINT: 'El paso del slug del URL se agrega al final del URL para identificar el paso, ex "m\xE9dico" o "dental". El slug del URL debe ser \xFAnico y solo contener letras, guiones bajos(_) o guiones(-).',
  EDITING_PANEL_URL_PLACEHOLDER: "Introduzca el paso slug url...",
  EDITING_PANEL_URL: "Slug del URL",
  EDITING_PANEL_NAME_IS_REQUIRED: "El nombre es obligatorio.",
  EDITING_PANEL_NAME_HINT: "El nombre solo se usa dentro de las herramientas de dise\xF1o de inscripci\xF3n. Para editar el nombre que se muestra en la experiencia de inscripci\xF3n, la plantilla de contenido seleccionada a continuaci\xF3n deber\xEDa actualizarse.",
  EDITING_PANEL_NAME_PLACEHOLDER: "Ingrese el nombre...",
  EDITING_PANEL_NAME: "Nombre",
  PRONOUNS_OPTIONAL: "Pronombres (opcional)",
  GENDER_IDENTITY_OPTIONAL: "Identidad de g\xE9nero (opcional)",
  CAROUSEL_LIFEMARKET_DESCRIPTION: "Ofertas exclusivas y regalos para tu salud, tu estado f\xEDsico y tu estilo de vida.",
  CAROUSEL_LIFEMARKET_HEADING: "Lifemarket",
  LIFEMARKET_RECEIPT_FOOTER: "Gracias por elegir League. Si tienes alguna pregunta, comun\xEDcate con nosotros a trav\xE9s de {leagueChatLink} .",
  LIFEMARKET_RECEIPT_DESCRIPTION: "Si este servicio est\xE1 cubierto por tu plan de atenci\xF3n m\xE9dica, puedes usar este recibo como prueba de compra para tu proveedor de seguros.",
  SPANISH: "Espa\xF1ol",
  DELETE_ENROLLMENT_GROUP: "Eliminar grupo de conjunto de beneficios",
  DELETE_ENROLLMENT_STEP: "Eliminar paso",
  UPDATE_AMOUNT_SUCCESS: "\xA1\xC9xito! Monto actualizado.",
  KPMG_EQUITY_PARTNER_WITH_OPTIONAL_LTD_EOI_FORM_INSTRUCTIONS: "<strong> Instrucciones: </strong> no descargue el formulario de Evidencia de asegurabilidad (EOI). KPMG Total Rewards le proporcionar\xE1 el formulario requerido para completar",
  CREATE_GROUP_TEXT: "Agregar un grupo",
  CREATE_STEP_TEXT: "Crear paso",
  ENROLLMENT_CREATE_TEMPLATE_CLASS: "Crear plantilla para ID de clase",
  ENROLLMENT_CREATE_TEMPLATE_GROUP: "Crear plantilla para ID de grupo",
  ENROLLMENT_DEFAULT_TEMPLATE: "Plantilla predeterminada",
  ENROLLMENT_TEMPLATE_STATUS: "Estatus de la plantilla",
  ENROLLMENT_CONTENTFUL_DESCRIPTION: "Esta integraci\xF3n recupera contenido para este beneficio de Contentful (nuestro sistema de administraci\xF3n de contenido) para mostrarlo en la experiencia de inscripci\xF3n.",
  ENROLLMENT_CONTENTFUL_INFO_BANNER: "Aseg\xFArese de guardar la configuraci\xF3n despu\xE9s de cambiar la plantilla seleccionada.",
  ENROLLMENT_CONTENTFUL_ERROR_BANNER: "No se encontraron plantillas aplicables en Contentful. Cree una nueva plantilla a continuaci\xF3n o comun\xEDquese con Operaciones para que se cree el contenido.",
  ENROLLMENT_CONTENTFUL_DEFAULT_ERROR_BANNER: "No hay una plantilla predeterminada, utilice una plantilla personalizada o cree una nueva plantilla a continuaci\xF3n.",
  ENROLLMENT_DEFAULT_TEMPLATE_TOOLTIP: "Las plantillas predeterminadas se comparten entre todos los empleadores del mismo pa\xEDs. Si es necesario, comun\xEDquese con Operaciones para crear/editar una plantilla.",
  TRUSTEE_APPOINTMENT_DESCRIPTION: "Si el beneficiario es menor de 18 a\xF1os, nombre a un fideicomisario. Al completar los campos a continuaci\xF3n, autorizo al fideicomisario a recibir cualquier pago en nombre del beneficiario mientras sea menor de 18 a\xF1os y a aplicar los ingresos \xFAnicamente para el apoyo, mantenimiento, educaci\xF3n y beneficio de dicho beneficiario a discreci\xF3n del fideicomisario.",
  TRUSTEE_APPOINTMENT: "Nombramiento de fideicomisario",
  COMPANY_NAME: "Nombre de empresa",
  GROUP_DRAG_ERROR_WITHIN_LIST: "No se puede arrastrar el grupo a la lista de conjuntos de beneficios disponibles",
  TRUSTEE: "Fideicomisario",
  ENROLLMENT_DESIGN_SELECT_TO_EDIT: "Seleccione un paso, un grupo de conjuntos de beneficios o un conjunto de beneficios para empezar a editar.",
  ENROLLMENT_DESIGN_SETTINGS: "Configuraci\xF3n",
  PAY_GROUP: "Grupo de pago",
  YTD_END_DATE: "Fecha de finalizaci\xF3n",
  START_DATE: "Fecha de inicio",
  YTD_REPORT_COPY: "Este informe muestra las deducciones de primas, los ajustes y las contribuciones de TASA para los miembros que cumplen con los criterios seleccionados durante las fechas que se especifican.",
  YTD_REPORT: "Informe de enero a la fecha",
  ENROLLMENT_DESIGN_OPTIONS_SAVED_ERROR: "Error al guardar las opciones de dise\xF1o de inscripci\xF3n",
  ENROLLMENT_DESIGN_OPTIONS_SAVED_SUCCESS: "\xA1Las opciones de dise\xF1o de inscripci\xF3n se guardaron correctamente!",
  DEPENDENT_EDITED_SUCCESS_MESSAGE: "Ha editado correctamente la informaci\xF3n de su dependiente",
  DEPENDENT_EDITED_SUCCESS_HEADING: "Detalles de dependientes guardados",
  DEPENDENT_ADDED_SUCCESS_MESSAGE: "Su dependiente ahora est\xE1 cubierto por sus beneficios.",
  DEPENDENT_ADDED_SUCCESS_HEADING: "Se agreg\xF3 un nuevo dependiente",
  DEPENDENT_ERROR_MESSAGE: "El servidor detect\xF3 un error, intente agregar a su dependiente nuevamente.",
  ADDRESS_2_OPTIONAL: "Direcci\xF3n 2 (opcional)",
  ZIP_CODE_OR_POSTAL_CODE: "C\xF3digo postal",
  STATE_PROVINCE_REGION: "Estado/Provincia/Regi\xF3n",
  TAX_ID_OPTIONAL: "N\xFAmero de identificaci\xF3n fiscal (opcional)",
  ORGANIZATION_LEGAL_NAME: "Nombre legal de la organizaci\xF3n",
  RELATIONSHIP_TO_YOU: "Relaci\xF3n contigo",
  SOCIAL_SECURITY_NUMBER_OPTIONAL: "N\xFAmero de seguro social (opcional)",
  SOCIAL_INSURANCE_NUMBER_OPTIONAL: "N\xFAmero de seguro social (opcional)",
  MIDDLE_NAME_OPTIONAL: "Segundo nombre (opcional)",
  PROVINCE_OF_EMPLOYMENT: "Provincia de empleo",
  WHAT_DEVICES_CAN_CONNECT: "\xBFQu\xE9 aplicaciones y dispositivos son compatibles?",
  CONNECT_DEVICES_IMAGE_ALT: "Mujer revisando los datos del dispositivo conectado",
  DONT_HAVE_THE_APP: "\xBFNo tienes la aplicaci\xF3n m\xF3vil de League?",
  CONNECTING_UNAVAILABLE_DETAILS: "Por ahora, visite la aplicaci\xF3n m\xF3vil de League para conectar sus aplicaciones y dispositivos de salud favoritos.",
  CONNECTING_UNAVAILABLE: "La conexi\xF3n de aplicaciones y dispositivos no est\xE1 disponible en la web",
  DONT_CONNECT_APPS_DEVICES: "No rastrear mi progreso autom\xE1ticamente",
  CONNECT_APPS_DEVICES: "Conectar aplicaciones y dispositivos",
  CONNECT_TO_TRACK: "Conecta tus aplicaciones y dispositivos de salud favoritos para marcar autom\xE1ticamente las actividades como completas.",
  TRACK_AUTOMATICALLY: "Rastrea tu progreso autom\xE1ticamente",
  HEALTH_PROGRAMS_CONNECTED_PROGRAM: "Programa de salud conectado",
  HEALTH_PROGRAMS_SYNC_DEVICE: "Sincroniza tu {device} para una experiencia \xF3ptima del programa.",
  DEVICE: "dispositivo",
  ACTIVE_DURATION: "minutos activos",
  STEPS: "pasos",
  X_OF_Y: "{current} de {total}",
  USER_TERMS_UPDATE_FAILED: "Ocurri\xF3 un error al aceptar los T\xE9rminos y condiciones",
  AVAILABLE_BENEFIT_SETS: "Conjuntos de beneficios disponibles",
  DOWNLOAD_PLAY_STORE: "Obtener en Google Play",
  DOWNLOAD_APP_STORE: "Descargar en la App Store",
  TEXT_ME_DOWNLOAD_LINK: "Env\xEDenme un enlace de descarga por mensaje de texto",
  GET_THE_APP: "Obt\xE9n la aplicaci\xF3n para gestionar tus beneficios personalizados, acceder a ofertas exclusivas en nuestro mercado o contactar nuestro destacado servicio al cliente.",
  DONT_HAVE_OUR_APP: "\xBFNo tienes nuestra aplicaci\xF3n?",
  PLAN_ID: "ID del plan",
  CLASS_PLAN_NAME: "Plan",
  ENROLLMENT_DESIGN_SET: "Conjunto de beneficios",
  ENROLLMENT_DESIGN_GROUP: "Grupo de conjunto de beneficios",
  ENROLLMENT_DESIGN_STEP: "Etapa",
  ENROLLMENT_DESIGN_STEPS: "Etapas",
  NO_PLAN_SELECTION_RESULTS: "Sin resultados",
  ENTER_CLASS_OR_PLAN: "Ingresa el nombre de la clase o del plan",
  GOOD_EVENING_WITH_NAME: "\xA1Buenas noches, {name}!",
  GOOD_AFTERNOON_WITH_NAME: "\xA1Buenas tardes, {name}!",
  GOOD_MORNING_WITH_NAME: "\xA1Buenos d\xEDas, {name}!",
  CREATE: "Crea {label}",
  ONLY_IN_LIFEMARKET: "Solo se puede canjear en Lifemarket",
  REPORTS_SECTION_DESCRIPTION: "Estos son los informes que llenan la informaci\xF3n que ves en este panel",
  PLAN_ID_VALUE: "ID del plan: {planId}",
  MAP: "Mapa",
  TRACE: "Rastro",
  BENEFIT_ALLOCATION_DRY_RUN: "Simulacro de asignaci\xF3n de beneficios",
  EXIT_ACTIVITY_CTA: "Salir de la actividad",
  EXIT_ACTIVITY_BODY: "Tu progreso no se guardar\xE1 y tendr\xE1s que volver a empezar la actividad desde el principio.",
  EXIT_ACTIVITY_TITLE: "\xBFEst\xE1s seguro de que deseas salir de esta actividad?",
  COMPANY_PROFILE: "Perfil de la empresa",
  ENROLLMENT_DESIGN_PREV_STEP_BUTTON: "Paso anterior",
  ENROLLMENT_DESIGN_NEXT_STEP_BUTTON: "Etapa siguiente",
  NO_PLAN_MESSAGE: "No hay planes",
  BENEFIT_CONFIG_TITLE: "Configurar conjuntos de beneficios",
  NEXT_STEP: "Etapa siguiente",
  I_WOULD_LIKE_TO_OPTIN_MARKETING: "Quisiera recibir correos electr\xF3nicos, incluyendo recompensas y descuentos por servicios de salud y bienestar.",
  I_AGREE_TO_THE_TERMS_AND: "Acepto los T\xE9rminos y condiciones y la Pol\xEDtica de privacidad",
  PLEASE_REVIEW_OUR_TERMS_CONDITIONS: "Revisa nuestros T\xE9rminos y condiciones y nuestra <a>Pol\xEDtica de privacidad</a>",
  TERMS_AND_PRIVACY: "T\xE9rminos y privacidad",
  CONTACT_TC_EMAIL: "Escribe a {helpEmail} si tienes preguntas sobre esta actualizaci\xF3n.",
  TO_SERVE_YOU_BETTER: "Para servirte mejor, queremos que est\xE9s informado de los cambios que hemos realizado. Verifica y acepta los nuevos T\xE9rminos y condiciones para continuar usando League.",
  WEVE_REVISED_OUR_TERMS: "Hemos revisado nuestros t\xE9rminos y condiciones.",
  HEALTH_TOOLS: "Herramientas de salud",
  WALLET_TOOLS: "Herramientas de la cartera",
  SCRIPT_HELPERS: "Ayudantes de scripts",
  SUBMIT_FEEDBACK: "Enviar retroalimentaci\xF3n",
  FORM_OPENS_IN_A_NEW_TAB: "Abre la encuesta para empleados en una pesta\xF1a nueva",
  ENROLLMENT_EXPERIENCE_CONFIG: "Configuraci\xF3n de experiencia de inscripci\xF3n",
  BENEFIT_SET_CONFIG: "Configuraci\xF3n de conjunto de beneficios",
  SELECT_PLAN: "Selecciona un plan",
  CURRENT_STEP: "Paso {stepNumber}: {stepName}",
  NO_PLAN_SELECTED: "No hay ning\xFAn plan seleccionado",
  "SELECTED PLAN": "Plan seleccionado",
  DEPENDENT_COVERED_BY_HSA_ONLY: "Nota Este dependiente solo estar\xE1 cubierto por tu cuenta de gastos m\xE9dicos.",
  CHANGE_DEPENDENT_RELATIONSHIP_LINK: "comun\xEDcate a atenci\xF3n al cliente",
  CHANGE_DEPENDENT_RELATIONSHIP: "Para cambiar la relaci\xF3n con tu dependiente, escribe a {emailLink}.",
  EDIT_DEPENDENT_CONTACT_CUST_CARE_LINK: "chatear con un agente",
  EDIT_DEPENDENT_CONTACT_CUST_CARE: "Si necesitas hacer alg\xFAn cambio o eliminar a este dependiente, haz clic en {chatLink}.",
  ENROLLMENT_EXPERIENCE_CONFIG_TITLE: "Configura tu experiencia de inscripci\xF3n",
  ENROLLMENT_DESIGN_PLAN_SELECTION_TITLE: "Selecciona un plan",
  MAIN_NAVIGATION_LIST_LABEL: "Elementos de navegaci\xF3n de League",
  YOU_EARNED_X_POINTS: "\xA1Ganaste {points} puntos!",
  PROPERTY_NAME_UNAVAILABLE: "Sin nombre de {property}",
  COPY_ACTIVITY_CAMPAIGN_ID_HINT: "*Haz clic en el nombre de una actividad o campa\xF1a para copiar la ID de cada una respectivamente",
  EDIT_DETAILS: "Editar detalles",
  COMPLETED_EXPIRED_REMOVED_ACTIVITIES: "Detalles de actividades finalizadas, vencidas, eliminadas y pr\xF3ximas",
  HEALTH_ACTIVITIES: "Actividades de salud",
  COPIED_PROPERTY: "{property} copiada",
  COPY_PROPERTY: "Copiar {property}",
  DOCUMENT_FORM_HAS_ERRORS: "Debes llenar todos los campos requeridos antes de enviar un documento.",
  EDIT_INTERAC_DEPOSIT: "Editar transferencia electr\xF3nica Interac",
  INVITE_ERROR_NOTE: "Vuelve a probar el enlace del correo electr\xF3nico de invitaci\xF3n o env\xEDa un correo electr\xF3nico a <a>Atenci\xF3n al Cliente</a> para obtener ayuda.",
  INVITE_ERROR_SOMETHING_WENT_WRONG: "Lo sentimos, algo sali\xF3 mal.",
  INVITE_REDEEMED_NOTE: "Parece que este enlace de invitaci\xF3n ya ha sido canjeado. Puedes seguir adelante e iniciar sesi\xF3n en tu cuenta ahora.",
  YOUR_INVITE_WAS_REDEEMED: "Tu invitaci\xF3n fue canjeada",
  INVITE_EXPIRED_NOTE: "Este enlace de invitaci\xF3n ya no es v\xE1lido. Pide al titular de la cuenta principal que te env\xEDe un nuevo correo electr\xF3nico de invitaci\xF3n.",
  YOUR_INVITE_HAS_EXPIRED: "Tu invitaci\xF3n ha vencido",
  YOULL_GET_TOOLS: "Obtendr\xE1s herramientas, recursos y apoyo para ayudarte a vivir m\xE1s feliz y sano cada d\xEDa. Solo te llevar\xE1 unos minutos y te guiaremos en cada paso del camino.",
  OPTIONS_MENU_FOR_DEPENDENT: "Men\xFA de opciones para {dependent}",
  INVITATION_STATUS: "Estado de la invitaci\xF3n: ",
  TASA_CONTRIBUTIONS: "Contribuciones de TASA",
  POST_TAX_COST: "Costo posterior a impuestos",
  PRE_TAX_COST: "Costo antes de impuestos",
  IMPUTED_INCOME: "Ingreso imputado",
  QUEBEC_TAXABLE_BENEFIT: "Beneficio imponible de Quebec",
  FLEX_COST: "Costo flexible",
  EMPLOYER_COST: "Coste para el empleador",
  EMPLOYEE_COST: "Costo para empleados",
  NO_PAYROLL_DEDUCTIONS: "Sin deducciones de n\xF3mina",
  COST_TYPE: "Tipo de costo",
  PAYROLL_DEDUCTIONS_COPY: "Ver y administrar las deducciones de n\xF3mina",
  QLE_LOST_MY_COVERAGE: "He perdido mi cobertura",
  FIELD_REQUIRED: "{field} es obligatorio.",
  PAGE_TITLE_HEALTH_PROGRAM_CATEGORY: "Categor\xEDa del programa de salud",
  PAGE_TITLE_ACTIVITY_DETAILS: "Detalles de la actividad",
  VIEW_BENEFIT_SELECTIONS_DIFF: "\u{1F575}\uFE0F\u200D\u2642\uFE0F Ver el historial de selecciones de los beneficios del plan",
  VIEW_CARD_FOR_DEPENDENT: "Ver tarjeta de {dependentName}",
  JUMP_TO_NAVIGATOR_LINK: "Ir a navegaci\xF3n",
  SKIP_TO_CONTENT_MESSAGE: "Saltar al contenido",
  COVERAGE_SEARCH_BEHAVIOUR_DESCRIPTION: "Los resultados de la b\xFAsqueda se actualizar\xE1n autom\xE1ticamente a medida que escribes",
  COVERAGE_CATEGORIES: "Categor\xEDas de cobertura",
  HOME_PAGE: "P\xE1gina de inicio",
  FILE_TRANSFER_IS_CANCELED: "Se cancel\xF3 la transferencia del archivo.",
  YOUR_FILE_FAILED_TO_UPLOAD: "No se pudo cargar tu archivo.",
  YOUR_FILE_HAS_BEEN_UPLOADED: "Tu archivo se carg\xF3 con \xE9xito para el agente.",
  THE_AGENT_HAS_REQUESTED_A_FILE: "El agente solicit\xF3 que se cargue un archivo.",
  THE_AGENT_HAS_ENDED_THE_CHAT: "El agente ha finalizado el chat.",
  NEW_MESSAGE_FROM_THE_AGENT: "Nuevo mensaje del agente.",
  THE_AGENT_IS_TYPING: "El agente est\xE1 escribiendo.",
  REWARDS_ERROR: "Error de recompensas",
  TITLE_BY_PROVIDER_FOR_PRICE: "{title} de {provider} por {price}",
  INVALID_CHARACTER_ERROR_MESSAGE: 'Este campo contiene caracteres no v\xE1lidos. " {invalidCharacter} "',
  VERIFICATION_CALL_SENT: "Llamada de verificaci\xF3n enviada",
  VERIFICATION_CODE_SENT: "C\xF3digo de verificaci\xF3n enviado",
  OPEN_DOCUMENT_INSTRUCTION_HIDDEN: "Abre el documento {documentType} en una pesta\xF1a nueva",
  YOU_HAVENT_ADDED_ANY_DEPENDENTS: "No has agregado ning\xFAn dependiente",
  SUITE_NUMBER_SENTENCE_CASE: "N\xFAmero de suite o apartamento (opcional)",
  STREET_ADDRESS_SENTENCE_CASE: "Calle y n\xFAmero",
  WHAT_SHOULD_WE_CALL_YOU_SENTENCE_CASE: "\xBFComo deber\xEDamos llamarte? (opcional)",
  LEGAL_FIRST_NAME_SENTENCE_CASE: "Nombre legal",
  ZIP_CODE_SENTENCE_CASE: "C\xF3digo postal",
  POSTAL_CODE_SENTENCE_CASE: "C\xF3digo postal",
  NORTHERN_MARIANA_ISLANDS: "Islas Marianas del Norte",
  MARSHALL_ISLANDS: "Islas Marshall",
  FEDERATED_STATES_OF_MICRONESIA: "Estados Federados de Micronesia",
  LEGAL_LAST_NAME: "Apellido legal",
  WALLET_LANDING_NAV_LABEL: "Navegaci\xF3n por la cartera",
  FIELD_MUST_NOT_BE_EMPTY: "El campo no debe estar vac\xEDo",
  YOU_HAVE_BEEN_DISCONNECTED: "Te han desconectado. Qu\xE9date sentado mientras intentamos que vuelvas a tu chat.",
  TRYING_TO_RECONNECT: "Intentado reconectar...",
  YOUR_CHAT_ENDED_BECAUSE_ISSUE_WITH_CONNECTION: "Tu chat finaliz\xF3 porque ten\xEDamos problemas para mantener tu conexi\xF3n. Verifica si est\xE1s conectado a Internet y vuelve a intentarlo.",
  CONNECTION_LOST: "Conexi\xF3n perdida",
  NO_AGENTS_ARE_AVAILABLE_AT_THE_MOMENT: "No hay agentes disponibles en este momento, por lo que tu sesi\xF3n de chat ha finalizado. Por favor, int\xE9ntalo de nuevo m\xE1s tarde.",
  YOUR_CHAT_HAS_ENDED: "Tu chat finaliz\xF3",
  ARE_YOU_SURE_YOU_WANT_TO_END_THIS_CHAT: "\xBFEst\xE1s seguro de que deseas finalizar este chat? No te preocupes, a\xFAn puedes exportar tu chat despu\xE9s.",
  END_CHAT: "Finalizar chat",
  PRICE_PERIOD_PREMIUM_TOOLTIP_CONTENT: "Para mantener tu plan activo, debes pagar esta tarifa de {periodType}. Esto no contribuye a ning\xFAn deducible o gasto(s) de bolsillo m\xE1ximo(s).",
  PRICE_PERIOD_PREMIUM: "Prima de {periodType}",
  EMPLOYER_COST_TABLE_INFO: "Tu empleador aporta <strong>{amount}</strong> / {periodType} para este plan",
  YOU_CURRENTLY_HAVE_NO_DEPENDENTS: "En este momento no tienes dependientes asociados a esta cuenta.",
  NO_DEPENDENTS: "No hay dependientes",
  PASSWORD: "Contrase\xF1a",
  COMMUNICATION_PREFERENCES: "Preferencias de comunicaci\xF3n",
  MOBILE_PHONE_NUMBER_UPDATED: "N\xFAmero de tel\xE9fono celular actualizado",
  MOBILE_PHONE_NUMBER: "N\xFAmero de tel\xE9fono m\xF3vil",
  ACCOUNT_DETAILS: "Detalles de la cuenta",
  PERSONAL_INFO: "Informaci\xF3n personal",
  LOOKS_LIKE_CHANGES: "Parece que tienes algunos cambios sin guardar. Si abandonas esta p\xE1gina, estos cambios se perder\xE1n.",
  WE_NEED_TO_MAKE_SURE_ONLY_YOU: "Necesitamos asegurarnos de que tengas acceso a tu cuenta. No compartiremos tu n\xFAmero con nadie.",
  YOU_HAVE_UNSAVED: "Tienes cambios sin guardar",
  CHANGE_PHOTO: "Cambiar foto",
  THIS_PHOTO_SHOWS_UP: "Esta foto aparece en tu perfil de usuario y en tu perfil de salud.",
  GET_DEPENDENTS_ERROR: "No fue posible recuperar a los dependientes",
  CHANGING_YOUR_EMAIL_ADDRESS_WILL_ALSO: "Cambiar tu direcci\xF3n de correo electr\xF3nico cambiar\xE1 tambi\xE9n tus credenciales para conectarte.",
  NO_PHONE_NUMBER_FOUND: "No se encontr\xF3 ning\xFAn n\xFAmero de tel\xE9fono",
  PROFILE_PHOTO: "Foto de perfil",
  UPLOAD_PHOTO: "Cargar foto",
  DRAG_AND_DROP_PHOTO: "Arrastrar y soltar una foto",
  UPDATE_PHOTO: "Actualizar foto",
  PASSWORD_UPDATED: "Contrase\xF1a actualizada",
  CHANGE_PASSWORD_ERROR: "No fue posible cambiar la contrase\xF1a",
  CONFIRM_NEW_PASSWORD: "Confirmar contrase\xF1a nueva",
  NEW_PASSWORD: "Contrase\xF1a nueva",
  CURRENT_PASSWORD: "Contrase\xF1a actual",
  CHOOSE_A_STRONG_PASSWORD: "Elige una contrase\xF1a segura y no la vuelvas a utilizar para otras cuentas.",
  CHANGE_PASSWORD: "Cambiar contrase\xF1a",
  PHONE_NUMBER_SAME: "Los n\xFAmeros de tel\xE9fono son los mismos, int\xE9ntalo de nuevo.",
  VERIFICATION_CODE_FAILED: "Fall\xF3 el c\xF3digo de verificaci\xF3n",
  SEND_VERIFICATION_CODE: "Enviar c\xF3digo de verificaci\xF3n",
  WELL_TEXT_YOU: "Te enviaremos por mensaje de texto tu c\xF3digo de verificaci\xF3n",
  EDIT_MOBILE_PHONE_FAILED: "Error al editar el n\xFAmero de tel\xE9fono celular",
  EDIT_MOBILE_PHONE: "Editar n\xFAmero de tel\xE9fono celular",
  CALL_ME_INSTEAD: "Cambiar a llamada",
  SEND_AGAIN: "Enviar nuevamente",
  ENTER_YOUR_VERIFICATION_CODE: "Ingresa tu c\xF3digo de verificaci\xF3n",
  VERIFICATION_CODE: "C\xF3digo de verificaci\xF3n",
  SENT_TO_YOUR_MOBILE: "Se envi\xF3 un c\xF3digo de verificaci\xF3n a tu n\xFAmero de tel\xE9fono celular.",
  ENTER_VERIFICATION_CODE: "Ingresar c\xF3digo de verificaci\xF3n",
  DISCARD: "Descartar",
  ARE_YOU_SURE_DISCARD_CHANGES: "\xBFEst\xE1s seguro de que deseas descartar estos cambios?",
  PROFILE_UPDATE_FAILED: "Error al actualizar el perfil",
  PROFILE_UPDATED: "Perfil actualizado",
  SEX_TOOLTIP: "Las compa\xF1\xEDas de seguros con la que estamos asociados requiere conocer tu sexo para administrar tus beneficios. <br/><br/>Nuestro equipo est\xE1 comprometido a asegurar que todos los miembros se sientan seguros y respetados. Si quieres decirnos m\xE1s acerca de c\xF3mo te identificas, usa los campos del formulario a continuaci\xF3n para agregar tu identidad de g\xE9nero y pronombres.",
  THE_PROGRAM_WILL_APPEAR_IN_JOURNEY: "Ahora el programa aparecer\xE1 en tu trayectoria",
  PROGRAM_ADDED: "Programa agregado",
  PROGRAM_REMOVED: "Programa eliminado",
  ARE_YOU_SURE_REMOVE_PROGRAM: "\xBFEst\xE1s seguro de que deseas eliminar este programa de tu recorrido? Cualquier progreso en este programa se reiniciar\xE1 y tendr\xE1s que empezar de nuevo desde el principio.",
  REMOVE_PROGRAM: "\xBFEliminar programa?",
  YOU_WILL_NO_LONGER_SEE_IN_JOURNEY: "Ya no se ver\xE1 en tu trayectoria",
  COLLAPSE: "Colapsar",
  EXPAND: "Ampliar",
  QLE_LINK_LABEL: "Conoce m\xE1s informaci\xF3n sobre los eventos de vida calificados",
  VIEW_CLAIM_OF_STATUS: "Ver reclamaci\xF3n {status}",
  CLAIM_DETAIL_EXPAND_DOCUMENT_HIDDEN: "Expandir documento {documentType}",
  PREVIOUS: "Previo",
  PLAN_BOOKLET: "Folleto del plan",
  CLAIM_DETAIL_ASIDE_HEADING: "Opciones de reclamaci\xF3n",
  COMPLETE_ACTIVE_PROGRAM_BEFORE_NEW_ONE: "Termina un programa activo antes de agregar uno nuevo. <strong>{ctaText}</strong>",
  LOOKS_LIKE_REACHED_PROGRAM_LIMIT: "\xA1Parece que llegaste al l\xEDmite de tu programa!",
  EMPLOYER_COST_BANNER_WITH_FLEX_DOLLAR: "\xA1Lo hemos sumado todo! Tu empleador ha contribuido con un total de <strong>{amount}</strong> para tu salud y bienestar durante el a\xF1o. Esto incluye tu asignaci\xF3n de {flexDollarTerm} y todas las contribuciones a los beneficios.",
  EMPLOYER_COST_BANNER_NO_FLEX_DOLLAR: "\xA1Lo hemos sumado todo! Tu empleador ha contribuido con un total de <strong>{amount}</strong> para tu salud y bienestar durante el a\xF1o.",
  EMPLOYER_COST_BANNER_HEADER: "Tu empleador est\xE1 invirtiendo en ti y en tu salud",
  SHOW_LESS: "Mostrar menos",
  CLAIMS_TABLE_READ_MORE_HIDDEN: "Enlace a los detalles de la reclamaci\xF3n",
  DOCUMENT_THUMBNAIL_HIDDEN: "Documentos adjuntos",
  DOCUMENT_MULTIPLE_HIDDEN: "M\xFAltiples documentos adjuntos",
  DOCUMENT_THUMBNAIL_PDF_HIDDEN: "PDF",
  PROVINCE_OF_REGISTRATION: "Provincia de registro",
  PATIENT_GUID: "GUID del paciente",
  PATIENT_DETAILS: "Detalles del paciente",
  IN_PROGRESS: "En curso",
  CANCELED: "Cancelado",
  COMPLETED: "Completado",
  STORE_ADDRESS: "Direcci\xF3n de la tienda",
  STORE_PHONE: "Tel\xE9fono de la tienda",
  STORE_NAME: "Nombre de la tienda",
  APPOINTMENT_DATE: "Fecha de la cita",
  APPOINTMENT_STATUS: "Estatus de la cita",
  BOOKING_EXPIRY_DATE: "Fecha de vencimiento de la reservaci\xF3n",
  BOOKING_DATE: "Fecha de la reservaci\xF3n",
  BOOKING_STATUS: "Estatus de reservaci\xF3n",
  ELIGIBILITY_DATE: "Fecha de elegibilidad",
  ELIGIBILITY_STATUS: "Estatus de elegibilidad",
  REGISTRATION_CODE: "C\xF3digo de registro",
  REQUESTED_DATE: "Fecha solicitada",
  REQUEST_STATUS: "Estatus de la solicitud",
  APPOINTMENT_DETAILS_FOR_DOSE: "Detalles de la cita para la dosis: {dose} / {seriesDoses}",
  REGISTRATION_DETAILS: "Detalles del registro",
  VACCINE_JOURNEY: "Trayectoria de vacunaci\xF3n",
  COVID_19_VACCINE_JOURNEY: "Trayectoria de vacunaci\xF3n contra el COVID-19",
  VIEW_CLAIM_LABEL_HIDDEN: "Ver reclamaci\xF3n {referenceId} de {vendor}",
  VIEW_CLAIM_SELECT_LABEL: "Ver el estado de las reclamaciones",
  COMPLETE_ONE_OF_ACTIVE_PROGRAMS: "Completa uno de tus programas activos para agregarlo a tu trayectoria.",
  DURATION_MONTHS: "{duration, plural, =0 {Zero months} one {# month} other {# months}}",
  OPENS_IN_A_NEW_TAB: "Se abre en una pesta\xF1a nueva",
  EXTENSION_BENEFITS_END_DATE: "Fecha de finalizaci\xF3n de la extensi\xF3n de beneficios",
  NOTICE_DATE: "Fecha del aviso",
  USER_GROUP: "Grupo del usuario",
  MAPLE_USER_PROFILE: "Perfil del usuario de Maple",
  ENROLLED_IN_PCO: "Inscrito en PCO",
  USER_OPTIMUM_PROFILE: "Perfil \xF3ptimo del usuario",
  GENDER: "G\xE9nero",
  DEPENDENT_CLASS: "Clase de dependiente",
  EMPLOYMENT_STATUS: "Estado de empleo",
  ADDRESS_2: "Direcci\xF3n 2",
  ADDRESS_1: "Direcci\xF3n 1",
  OK: "De acuerdo",
  START_X_MORE_PROGRAMS: "Inicia {duration} m\xE1s programas. Mant\xE9n el impulso.",
  YOUVE_REACHED_PROGRAM_LIMIT: "\xA1Llegaste a tu l\xEDmite de programas!",
  ACTIVE_PROGRAMS: "Programas activos",
  NAVIGATION_STEPS_MENU_ERROR: "Los pasos de navegaci\xF3n tienen errores",
  NAVIGATION_STEPS_MENU: "Men\xFA de pasos de navegaci\xF3n",
  TAP_ADD_PROGRAM_BUTTON: "Toca el bot\xF3n 'Agregar programa' para buscar programas.",
  ADD_PROGRAM: "Agregar programa",
  YOU_HAVENT_STARTED_PROGRAMS: "No has iniciado ning\xFAn programa de salud",
  SCROLL_CAROUSEL_LEFT: "Desplaza el carrusel hacia la izquierda",
  SCROLL_CAROUSEL_RIGHT: "Desplaza el carrusel hacia la derecha",
  UPDATE_ELECTIONS: "Actualizar elecciones",
  QLE_PLAN_PICKER_COMPLETED: "\xA1Todo listo! Tus elecciones de {planName} est\xE1n oficialmente bloqueadas.",
  QLE_PLAN_PICKER_DEADLINE: "Tienes hasta el <strong>{date}</strong> para actualizar este plan",
  QLE_PLAN_PICKER_INFO_BANNER: "Completa ambas inscripciones, ya que tus elecciones en una inscripci\xF3n no se transferir\xE1n autom\xE1ticamente a la otra.",
  QLE_PLAN_PICKER_EXPLANATION: "Debido a que tu evento de cambio de vida cae dentro y alrededor de la fecha de inscripci\xF3n abierta, tienes la oportunidad de actualizar y/o elegir la cobertura para tus p\xF3lizas actuales y futuras.",
  DOCUMENT_TITLE: "{title} - League",
  VIEW_ACTIVE_PROGRAM_DETAILS: "Ver detalles del programa de salud activo",
  HEALTH_NEED: "Necesidad de salud",
  X_DAYS_REMAINING: "{duration, plural, =0 { No days } one {# day } other {# days }} restante(s)",
  PROGRESS: "Progreso",
  PROGRAM_DETAILS: "Detalles del programa",
  EARN_UP_TO_X_POINTS: "Gana hasta {points} puntos",
  DURATION_WEEKS: "{duration, plural, =0 {Zero weeks} one {# week} other {# weeks}}",
  CHANGE_EMPLOYEE_CLASS_ERROR_UNDEFINED_CHANGE_TYPE_MESSAGE: "Tipo de cambio indefinido",
  CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_2: "{change_reason}",
  CHANGE_EMPLOYEE_CLASS_MODAL_REASON_CONTENT_1: "<strong> Raz\xF3n: </strong>",
  CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_BULLET_1: "Se abrir\xE1 la inscripci\xF3n para miembros",
  CHANGE_EMPLOYEE_CLASS_MODAL_NEW_HIRE_CONTENT_1: "El cambio de clase del beneficio que activaste es elegible para <strong>Inscripci\xF3n de nueva contrataci\xF3n</strong>. Una vez confirmado, se llevar\xE1 a cabo lo siguiente:",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_4: "Si el miembro no se presenta dentro del per\xEDodo de inscripci\xF3n, recibir\xE1 selecciones predeterminadas para beneficios reci\xE9n desbloqueados",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_3: "Al presentar a los miembros, se les asignar\xE1n beneficios inmediatamente",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_2: "El miembro recibir\xE1 un correo electr\xF3nico sobre su inscripci\xF3n pendiente",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_BULLET_1: "Se abrir\xE1 la inscripci\xF3n para miembros",
  CHANGE_EMPLOYEE_CLASS_MODAL_BCC_ENROLLMENT_CONTENT_1: "El cambio de clase del beneficio que activaste es elegible para <strong>Inscripci\xF3n de BCC</strong>. Una vez confirmado, se llevar\xE1 a cabo lo siguiente:",
  TRIGGER_PAYMENT_MODAL: "\xBFEst\xE1s seguro de que deseas reactivar el siguiente pago?",
  TRIGGER_PAYMENT_PLACEHOLDER: "Ingresa una ID de pago",
  TRIGGER_PAYMENT_BODY: 'Proporciona una identificaci\xF3n de pago individual para cambiar el estado del pago de "cancelado" o "transferencia pendiente" a "liquidado". Esto permitir\xE1 que el pago se cobre con el pr\xF3ximo pago programado.',
  TRIGGER_PAYMENT_HEADING: "Activar el pago para que se reenv\xEDe",
  REWARDS_BODY: "Gana puntos de recompensa por completar objetivos que fomenten nuevos h\xE1bitos y comportamientos saludables.",
  REWARDS_HEADING: "Recompensas por h\xE1bitos saludables",
  ANSWERS_BODY: "Ante dudas o preguntas, habla con una persona real experta en salud, directamente desde tu aplicaci\xF3n.",
  ANSWERS_HEADING: "Respuestas r\xE1pidas a preguntas sobre salud",
  PRODUCTS_BODY: "Ten acceso exclusivo a productos y servicios cuidadosamente seleccionados para apoyar tu estilo de vida saludable.",
  PRODUCTS_HEADING: "Productos y servicios personalmente seleccionados.",
  COVERAGE_BODY: "Accede a toda la informaci\xF3n sobre tu cobertura y env\xEDa reclamaciones en apenas unos pasos, directamente desde tu tel\xE9fono.",
  COVERAGE_HEADING: "Toda la informaci\xF3n sobre tu cobertura",
  IN_IT_FOR_YOU: "Estos son los beneficios para ti:",
  ALREADY_HAVE_ACCOUNT: "\xBFYa tienes una cuenta?",
  CREATE_ACCOUNT: "Crear una cuenta",
  LETS_CREATE_ACCOUNT: "Vamos a crear tu cuenta de League.",
  WELCOME_EXCLAMATION_NAME: "\xA1Bienvenido, {firstName}!",
  WELCOME_EXCLAMATION: "\xA1Bienvenido!",
  GET_STARTED_ERROR: "El c\xF3digo de invitaci\xF3n que usaste no es v\xE1lido. Verifica el enlace en tu correo electr\xF3nico de invitaci\xF3n e intenta de nuevo.",
  POUNDS: "Libras",
  KILOGRAMS: "Kilogramos",
  INCH: "Pulgada",
  INCHES: "Pulgadas",
  FOOT: "Pie",
  FEET: "Pies",
  CENTIMETERS: "Cent\xEDmetros",
  BCC_ENROLLMENT: "<strong> \xBFPor qu\xE9 no puedo cambiar mis elecciones? </strong> Durante esta inscripci\xF3n, solo podr\xE1s inscribirte en los beneficios ofrecidos recientemente como parte de tu cambio de trabajo.",
  LEARN_MORE_QLE: "acerca de eventos de la vida que podr\xEDan calificar para la reinscripci\xF3n",
  LEARN_MORE_LEAGUE_FAMILY: "acerca de League Family para tus dependientes",
  LINK_TO_TRANSACTION_DETAILS: "Enlace a detalles",
  GO_TO_RECEIPT: "Ver recibo de {title}",
  GO_TO_CLAIM: "Ver detalles de reclamaci\xF3n de {title}",
  GO_TO_LIFEMARKET: "Ve a Lifemarket para ver el n\xFAmero de pedido {invoiceId}",
  COVERAGE_VOLUME_AMOUNT: "{amount} / {period}",
  ESTIMATED_BENEFIT_TOOLTIP_WEEKLY: "Este es un estimado de la cantidad de dinero que recibir\xE1s semanalmente durante tu per\xEDodo de cobertura. Se calcula seg\xFAn tus ingresos semanales.",
  ESTIMATED_BENEFIT_TOOLTIP_MONTHLY: "Este es un estimado de la cantidad de dinero que recibir\xE1s mensualmente durante tu per\xEDodo de cobertura. Se calcula seg\xFAn tus ingresos mensuales.",
  ESTIMATED_BENEFIT_LABEL: "Monto estimado de tu beneficio",
  DOWNLOAD_TEMPLATE: "Descargar la plantilla",
  PROVINCE_OF_RESIDENCE: "Provincia de residencia",
  DEPENDENT_COVERAGE_LEVEL: "Nivel de cobertura de dependientes",
  TYPE_OF_PREMIUM: "Tipo de prima",
  BENEFIT_PLAN_NAME: "Nombre del plan de beneficios",
  UPLOAD_DEDUCTION_CODES: "Cargar c\xF3digos de deducci\xF3n",
  NO_DEDUCTION_CODES: "\xA1No hay c\xF3digos de deducci\xF3n para este grupo!",
  NEW_MAPPING: "Nuevo mapeo",
  EDIT_DEDUCTION_CODE: "Editar c\xF3digo de deducci\xF3n",
  NEW_DEDUCTION_CODE: "Nuevo c\xF3digo de deducci\xF3n",
  IMPORT_DEDUCTION_CODES_COPY: "\xA1Carga masiva de c\xF3digos de deducci\xF3n desde un archivo CSV. Ten en cuenta que una nueva carga crear\xE1 nuevos c\xF3digos de deducci\xF3n y no reemplazar\xE1 los existentes!",
  DEDUCTION_CODE_COPY: "Define la asignaci\xF3n de c\xF3digos de deducci\xF3n para los beneficios del usuario",
  DEDUCTION_CODES: "C\xF3digos de deducci\xF3n",
  DEDUCTION_CODE: "C\xF3digo de deducci\xF3n",
  ADD_FILTER: "Agregar filtro",
  VALUES: "Valores",
  FIELD: "Campo",
  TRACKER_YOU_PAY_TOOLTIP_CONTENT_NOTE: "Nota: \xA1Es posible que ya veas un saldo si algunos beneficios son obligatorios o los preseleccion\xF3 para ti {company}!",
  TRACKER_YOU_PAY_TOOLTIP_CONTENT: "Este es el total acumulado del costo anual de todos los beneficios incluidos en tu paquete. Notar\xE1s que esta cantidad aumenta a medida que avanzas en los pasos de inscripci\xF3n.",
  STEP_SELECTED: "Paso actual",
  STEP_COMPLETED: "Paso completado",
  STEP_HAS_ERROR: "Este paso tiene error(es)",
  STEP_NOT_COMPLETED: "Paso no completado",
  CANCEL_DEPENDENT_DEACTIVATION_SUCCESS_TOAST: "El dependiente se agreg\xF3 de nuevo a tu plan",
  CONTENTFUL_ERROR_BANNER_MULTICARD: "El contenido de una o m\xE1s tarjetas en este beneficio no existe en Contentful. Si no existe el contenido para una tarjeta habilitada, deshabilita la integraci\xF3n o ponte en contacto con Operaciones para crear el contenido.",
  CLOSE_BENEFIT_LIST: "Cerrar lista de beneficios",
  EXPAND_BENEFIT_LIST: "Ampliar lista de beneficios",
  DOWNLOAD_BENEFICIARIES_REPORT: "Descargar informe de beneficiarios",
  BENEFICIARIES_REPORT_TEXT: "Este informe ofrece detalles sobre las designaciones de beneficiarios de cada uno de los empleados por cada beneficio correspondiente.",
  BENEFICIARIES_REPORT: "Informe de beneficiarios",
  LEARN_MORE_ABOUT_COORDINATION_OF_BENEFITS: "Conoce m\xE1s sobre Coordinaci\xF3n de beneficios",
  ERROR_CREATING_WALLET_TEMPLATE: "Error al crear plantilla de cartera",
  DEPENDENT_REMOVED_FROM_UPCOMING_PLAN: "Se elimin\xF3 el dependiente de tu pr\xF3ximo plan",
  LEARN_MORE_ABOUT_ADDING_DOCUMENTS: "Aprende c\xF3mo agregar documentos en League",
  CREATE_IN_CONTENTFUL: "Crear en Contentful",
  CONTENTFUL_INFO_BANNER: "Aseg\xFArate de guardar este beneficio despu\xE9s de habilitar/deshabilitar la integraci\xF3n.",
  CONTENTFUL_ERROR_BANNER: "El contenido para este beneficio no existe en Contentful. Deshabilita la integraci\xF3n o ponte en contacto con Operaciones para crear el contenido.",
  CUSTOM_TEMPLATE_TOOLTIP: "Las plantillas personalizadas son espec\xEDficas para un grupo y anulan toda plantilla predeterminada existente. Si es necesario, ponte en contacto con Operaciones para crear/editar una plantilla.",
  DEFAULT_TEMPLATE_TOOLTIP: "Las plantillas predeterminadas se comparten entre grupos que usan la misma compa\xF1\xEDa y el mismo tipo de beneficio (por ejemplo, Cigna Medical, Manulife Critical Illness). Si es necesario, ponte en contacto con Operaciones para crear/editar una plantilla.",
  CONTRIBUTION_AFTER_PRORATION_AMOUNT: "La contribuci\xF3n despu\xE9s del prorrateo es de {proratedAmount}",
  ERROR_SAVING_USER_PROFILE: "Hubo un error al guardar tu perfil de usuario.",
  ADD_TO_JOURNEY: "Agregar a trayectoria",
  BACK_TO_JOURNEY: "Volver a trayectoria",
  ACTIVITY_REMOVED: "Actividad eliminada",
  ARE_YOU_SURE_REMOVE_ACTIVITY: "\xBFEst\xE1s seguro de que deseas eliminar esta actividad de tu trayectoria? Esta acci\xF3n no se puede deshacer.",
  REMOVE_ACTIVITY: "\xBFEliminar actividad?",
  DELETE_BENEFICIARY_BUTTON_LABEL: "Eliminar {beneficiaryName}",
  NEW_DEPENDENT: "Dependiente nuevo",
  REMOVE_DEPENDENT_NAME: "Eliminar {dependentFullName}",
  INACTIVE: "inactivo",
  LIVE: "en vivo",
  NO_TEMPLATE: "sin plantilla",
  BENEFIT_WALLET_CONTENT: "Contenido de la cartera de beneficios",
  VIEW_IN_CONTENTFUL: "Ver en Contentful",
  CUSTOM_TEMPLATE_STATUS: "Estatus de la plantilla personalizada",
  DEFAULT_TEMPLATE_STATUS: "Estatus de la plantilla predeterminada",
  CONTENTFUL_DESCRIPTION: "Esta integraci\xF3n recupera contenido para este beneficio desde Contentful (nuestro sistema de administraci\xF3n de contenido) para mostrarlo en Cartera.",
  PENDING_DEPENDENT_REMOVAL: "Eliminaci\xF3n pendiente: {deactivationDate}",
  UNDO: "Deshacer",
  UNDO_REMOVAL_OF_DEPENDENT: "Deshacer eliminaci\xF3n de {dependentName}",
  POPULAR_PROGRAMS: "Programas populares",
  EXTENSION_OF_BENEFITS: "Extensi\xF3n de beneficios",
  TERMINATED: "Terminado",
  INVITED: "Invitado",
  UPCOMING_ACTIVITIES: "Pr\xF3ximas actividades",
  TODAYS_ACTIVITIES: "Actividades de hoy",
  GO_TO_HEALTH_JOURNEY: "Ir a la trayectoria de salud",
  JOURNEY: "Trayectoria",
  BROWSE_PROGRAMS: "Examinar los programas",
  GO_TO_PROGRAM_LIBRARY: 'Ve a la "Biblioteca de programas" para examinar nuestros programas de salud y comenzar tu trayectoria de salud.',
  NOTHING_HERE_YET: "\xA1Todav\xEDa no hay nada aqu\xED!",
  SNEAK_PEEK_AT_HEALTH_PROGRAM_ACTIVITIES: "Echa un vistazo a las pr\xF3ximas actividades del programa de salud que estar\xE1n disponibles pronto",
  PREVIEW: "Vista previa",
  LATER: "M\xE1s tarde",
  THIS_MONTH: "Este mes",
  THIS_WEEK: "Esta semana",
  PROGRAM_LIBRARY: "Biblioteca de programas",
  ACTIVITIES: "Actividades",
  HEALTH_JOURNEY: "Trayectoria de salud",
  REMOVE_DEPENDENT_TEXT: "Est\xE1s a punto de eliminar <strong> {dependentName} </strong> como dependiente de tu plan <strong> {planName} </strong>",
  REMOVE_DEPENDENT_HEADING: "\xBFEst\xE1s seguro de que deseas eliminar este dependiente?",
  REDEEMABLE: "Canjeable por {value} .",
  VIEW_YOUR_REWARDS: "Ver tus recompensas",
  YOURE_QUESTIONS_AWAY_WITHOUT_POINTS: "Te faltan {number} preguntas para completar la evaluaci\xF3n.",
  HIDE_NOTIFICATIONS: "Ocultar notificaciones ({unreadCount} notificaciones no le\xEDdas)",
  VIEW_NOTIFICATIONS: "Ver notificaciones ({unreadCount} notificaciones no le\xEDdas)",
  PRO_DESIGNATION: "Designaci\xF3n profesional",
  PRO_ASSOCIATION: "Asociaci\xF3n profesional",
  LEAGUE_CHAT: "Chat de League",
  AMOUNT_OWING: "Monto adeudado",
  TOTAL_DUE: "Total adeudado",
  ISSUED_TO: "Emitido a",
  SUMMARY: "Resumen",
  LICENSE_NUMBER: "N\xFAmero de licencia",
  SERVICE_PROVIDER: "Proveedor de servicios",
  HST_NUMBER: "N\xFAmero HST",
  RECEIPT_NUMBER: "N\xFAmero de recibo",
  TRANSACTION_DATE: "Fecha de transacci\xF3n",
  TAX: "Impuesto",
  UNDO_REMOVAL: "Deshacer eliminaci\xF3n",
  DEPENDENT_MODAL_WARNING: "Revisa y actualiza las elecciones que hayas realizado anteriormente para este dependiente.",
  UNDO_REMOVE_DEPENDENT_HEADING: "\xBFEst\xE1s seguro de que deseas deshacer esta eliminaci\xF3n pendiente?",
  CANCEL_DEPENDENT_CHANGES_WARNING: "Se perder\xE1n todas las actualizaciones que le hayas hecho a tu dependiente.",
  CANCEL_DEPENDENT_CHANGES_HEADING: "\xBFEst\xE1s seguro de que deseas cancelar tus cambios?",
  BACK_TO_REWARDS: "Volver a Recompensas",
  NEW_POINTS_BALANCE: "Nuevo saldo de puntos",
  PREV_POINTS_BALANCE: "Saldo anterior de puntos",
  YOUVE_SUCCESSFULLY_REDEEMED: "Has canjeado correctamente {points} puntos por {value} $ en Cr\xE9dito League.",
  POINTS_REDEEMED: "Puntos canjeados",
  YOUR_POINTS_CANNOT_CONVERT: "Tus puntos se convertir\xE1n en Cr\xE9dito League para usarse en nuestro Lifemarket y no se pueden volver a convertir en puntos.",
  ARE_YOU_SURE_REDEEM_POINTS: "\xBFEst\xE1s seguro de que deseas canjear <strong> {points} puntos por {value} $ de Cr\xE9dito League? </strong>",
  CONFIRM_REDEEM_POINTS: "Confirmar canje de puntos",
  REDEEM_POINTS_DESCRIPTION: "Al hacer clic en <strong> 'Canjear puntos' </strong>, tus puntos se convertir\xE1n en Cr\xE9dito League para utilizarlos en productos y servicios de nuestro Lifemarket.",
  REDEEM_NEXT_TIER_POINTS: "Est\xE1s a {points} de canjear {value}.",
  GET_STARTED_REWARDS: "Empieza con las Recompensas de League",
  EXPLORE_LIFEMARKET: "Explorar Lifemarket",
  LEAGUE_CREDIT_LIFEMARKET_DESCRIPTION: "\xBFAlgo en nuestro Lifemarket te llam\xF3 la atenci\xF3n? Destina tu Cr\xE9dito League para comprar cualquier producto o servicio.",
  USE_LEAGUE_CREDIT: "Usa el Cr\xE9dito League para pagar",
  EXPLORE_PROGRAMS: "Explorar los programas",
  EARN_POINTS_PROGRAMS: "Gana puntos de recompensa al terminar las actividades del programa de salud.",
  EARN_POINTS: "Gana puntos y desarrolla h\xE1bitos saludables",
  REWARD_STATUS: "Estatus de recompensa",
  REDEEM_POINTS: "Canjear puntos",
  REDEEMABLE_VALUE: "Valor canjeable",
  POINTS_BALANCE: "Saldo de puntos",
  YOUR_REWARDS: "Tus recompensas",
  WHAT_TO_DO: "Qu\xE9 hacer",
  ABOUT_THIS_PROGRAM: "Acerca de este programa",
  ABOUT_THIS_ARTICLE: "Acerca de este art\xEDculo",
  WHY_TALK_TO_RN: "\xBFPor qu\xE9 hablar con una enfermera certificada?",
  WHAT_IS_MY_HEALTH_PROFILE: "\xBFQu\xE9 es mi perfil de salud?",
  COME_BACK_IN_DAYS: "Vuelve en {d\xEDas} d\xEDas para completarla.",
  ACTIVITY_NOT_AVAILABLE: "\xA1Esta actividad a\xFAn no est\xE1 disponible!",
  REMOVE_FROM_JOURNEY: "Quitar de la trayectoria",
  OPEN_LINK: "Abrir enlace >",
  RE_REGISTER_REASON: "Motivo para registrarse de nuevo",
  SUGGESTED: "Sugerido",
  AVAILABLE_ON: "Disponible el {date}",
  SAVED_BUSINESS_RULE: "\xA1Regla comercial guardada con \xE9xito!",
  UPDATE_CONTRIBUTIONS_ERROR: "Lo sentimos, hubo un error al actualizar las contribuciones a los beneficios de los empleados",
  UNLINKED_MAPLE_ACCOUNT_OPTION_TWO: "(Usuario nuevo de Maple) Crea una cuenta nueva de Maple a trav\xE9s del flujo de transferencia de PC Health para vincular tu cuenta de PC Health.",
  UNLINKED_MAPLE_ACCOUNT_OPTION_ONE: "(Usuario actual de Maple) Utiliza las credenciales de tu cuenta existente de Maple dentro del flujo de transferencia de PC Health para acceder a Maple y vincular tu cuenta de PC Health.",
  UNLINKED_MAPLE_ACCOUNT_OPTIONS: "Si el miembro desea acceder a Maple a trav\xE9s del flujo de transferencia de PC Health, comparte las siguientes opciones:",
  UNLINKED: "Sin vinculo",
  LINKED: "Vinculados",
  CURRENT_MAPLE_USER_STATUS: "Estatus actual de usuario de Maple",
  SHARED_MAPLE_USER: "Usuario compartido: este usuario vincul\xF3 su cuenta existente de Maple a PC Health",
  SDM_MAPLE_USER: "Usuario de SDM: este usuario se introdujo por primera vez a Maple a trav\xE9s de PC Health",
  NO_MAPLE_ACCOUNT_LINKED: "No hay ninguna cuenta de Maple vinculada",
  MAPLE_UNLINK_DATE: "Fecha de desvinculaci\xF3n de Maple",
  MAPLE_CONSENT_DATE: "Fecha de consentimiento a Maple",
  MAPLE_USER_TYPE: "Tipo de usuario de Maple",
  DATE_CONSULT_ENDED: "Fecha en que la consulta termin\xF3",
  HEALTH_PROFILE_COMPLETION_POINTS: "Ganaste {points} puntos",
  UPDATE_HEALTH_PROFILE: "Actualizar tu Perfil de salud",
  LAST_UPDATED_DATE: "\xDAltima actualizaci\xF3n el {date}",
  ANY_HEALTH_CHANGES: "\xBFHa habido alg\xFAn cambio reciente en tu salud?",
  PAYROLL_CONFIG_COPY: "Configura reglas para impulsar el registro y los informes de los empleados.",
  PAYROLL_CONFIG: "Configuraci\xF3n de n\xF3mina",
  WARNING_OPENS_NEW_TAB: "Se abre en una pesta\xF1a nueva",
  RE_REGISTER_START: "Vuelve a registrar el per\xEDodo de pago. Espera porque esto puede tardar varios minutos.",
  RE_REGISTER_SUCCESS: "\xA1Los informes se volvieron a registrar con \xE9xito! Ver el informe de actualizaci\xF3n en la <a> pesta\xF1a Informes programados </a>",
  RE_REGISTER_CONFIRMATION: "\xBFEst\xE1s seguro de que deseas volver a registrarte?",
  RE_REGISTER_WARNING: "<strong>Atenci\xF3n: </strong>Esta acci\xF3n sobrescribir\xE1 cualquier informe existente que se haya registrado para este periodo de pago. Por favor, aseg\xFArate de que los archivos no son utilizados por nadie antes de proceder.",
  RE_REGISTER_PAY_PERIOD: "Volver a registrar el per\xEDodo de pago",
  RE_REGISTER: "Volver a registrar",
  NO_PAY_CALENDARS: "No hay calendarios de pago establecidos para este grupo.",
  NAMED_REPORTS: "{name} Informes",
  NAMED_PAY_CALENDAR: "{name} Calendario de pago",
  YEAR_PLAN_YEAR: "A\xF1o del plan {year}",
  CARE_PROVIDER_HISTORY: "Historial del proveedor de atenci\xF3n",
  AGENT: "Agente",
  PROVIDER: "Proveedores",
  CHANGE: "Cambio",
  FULL: "Completo",
  REPORT_NAME: "Nombre del informe",
  BACK_TO_SCHEDULED_REPORTS: "Volver a Informes programados",
  NO_SCHEDULED_REPORTS: "No hay informes programados para este calendario de pago.",
  PREMIUM_REGISTRATION_RUN: "Ejecuci\xF3n de registro premium",
  SCHEDULED_REPORTS_COPY: "Ver y descargar informes generados autom\xE1ticamente.",
  SCHEDULED_REPORTS: "Informes programados",
  NOTE_REPORTS_LOADING_COPY: "<strong> Nota: </strong>Seg\xFAn el n\xFAmero de registros, este informe puede tardar unas horas en generarse. Te enviaremos por correo electr\xF3nico un enlace para descargar el informe una vez que se haya procesado.",
  DOWNLOAD_USAGE_SUMMARY_REPORT: "Descargar el informe de resumen de uso",
  DOWNLOAD_DETAILED_USAGE_REPORT: "Descargar el informe de uso detallado",
  PLAN_PERIOD: "Per\xEDodo del plan",
  NO_CONTRIBUTION_ACCOUNTS: "El usuario no tiene cuentas de contribuci\xF3n",
  NO_CONTRIBUTIONS: "El usuario no ha realizado ninguna contribuci\xF3n.",
  CONTRIBUTION_SOURCE: "Fuente de contribuci\xF3n",
  NEXT_CONTRIBUTION_AMOUNT: "Monto de la pr\xF3xima contribuci\xF3n",
  TARGET_EMPLOYEE_CONTRIBUTION: "Contribuci\xF3n objetivo del empleado",
  YTD_EMPLOYEE_CONTRIBUTION: "Contribuci\xF3n de los empleados a lo largo del a\xF1o",
  CONTRIBUTIONS_ACCOUNT: "Cuenta de contribuciones",
  SEARCH_EMPLOYEE_ID: "Buscar el ID del empleado",
  BENEFIT_CONTRIBUTION_LABEL: "Se ha realizado una contribuci\xF3n de ${amount} {currency} para el {period}",
  EMPLOYEE_TASA_DEDUCTIONS_COPY: "Ver y gestionar las deducciones TASA de empleados.",
  EMPLOYEE_TASA_DEDUCTIONS: "Deducciones TASA de empleados",
  EMPLOYEE_DEDUCTIONS: "Deducciones de empleados",
  PTS: "pts",
  GET_NETSUITE_INVOICE_REPORT_ERROR: "No se ha podido generar el informe de facturas con formato de NetSuite, \xA1int\xE9ntalo de nuevo!",
  GET_NETSUITE_INVOICE_REPORT_SUCCESS: "El informe de facturas con formato de NetSuite se ha generado con \xE9xito.",
  NETSUITE_INVOICE_REPORT_TO_DATE: "Hasta la fecha",
  NETSUITE_INVOICE_REPORT_FROM_DATE: "Desde la fecha",
  GET_NETSUITE_INVOICE_REPORT: "Solicitar informe",
  NETSUITE_INVOICE_REPORT: "Informe de factura con formato para NetSuite",
  BENEFIT_YEAR: "A\xF1o del beneficio",
  BENEFIT_TYPE_FILTER: "Filtro de tipo de beneficio",
  EMPLOYER_BENEFIT: "Beneficio del empleador",
  COPY_PRICING_CONFIG_NOTE: "Nota: esta acci\xF3n anular\xE1 toda configuraci\xF3n de precios existente para el beneficio seleccionado, si est\xE1 definida. Es una acci\xF3n irreversible.",
  COPY_PRICING_CONFIG_COPY: "Copiar la configuraci\xF3n actual de precios guardada en otro beneficio.",
  COPY_PRICING_CONFIG: "Copiar configuraci\xF3n de precios",
  RATE_SHEET_EXTRACT_SEARCH_PLACEHOLDER: "Buscar por clase de beneficio, nombre o ID",
  RATE_SHEET_EXTRACT_COPY: "Descarga un informe que contiene tablas de tarifas y datos de precios para determinados beneficios y clases de beneficios.",
  RATE_SHEET_EXTRACT: "Extracto de hoja de tarifas",
  DEPENDENT_VERIFICATION: "Verificaci\xF3n de dependiente",
  TRY_AGAIN: "Int\xE9ntalo de nuevo",
  THERE_SEEMS_T0_HAVE_BEEN_A_PROBLEM: "Vaya, parece que ha habido un problema.",
  CHAT_WITH_YOUR_CARE_TEAM: "Habla con tu equipo de atenci\xF3n",
  FIXED_MONTHLY_PLATFORM_FEES: "Tarifas mensuales de plataforma fijadas",
  DISCOUNT: "Descuento",
  CREDIT_ADJUSTMENT: "Ajuste del cr\xE9dito",
  COMMA_SEPARATED_USER_IDS: "ID de los usuarios (separadas por una coma)",
  PRICING_SCENARIO_COPY: "Este informe es utilizado para auditar las deducciones de la n\xF3mina de pago para una selecci\xF3n de beneficios del usuario a lo largo de diferentes opciones de cobertura.",
  PRICING_SCENARIO_REPORT: "Informe del escenario de precios",
  PRICING_SCENARIO: "Escenario de precios",
  YOU_HAVE_UNSAVED_CHANGES: "Tienes cambios sin guardar, \xBFquieres salir y descartar todos los cambios?",
  BACK_TO_PAY_CALENDARS: "Volver a calendarios de pagos",
  PAY_PERIOD_DETAILS: "Detalles del periodo de pago",
  NOT_REGISTERED: "No registrado",
  REGISTERED: "Registrado",
  PAY_PERIOD_MEMBER_PAY_DATE_TOOLTIP: "Esta es la fecha en la que los miembros recibir\xE1n su cheque de pago para este periodo de pago espec\xEDfico.",
  PAY_PERIOD_SCHEDULED_FILE_DELIVERY_DATE_TOOLTIP: "Esta es la fecha que fue inicialmente agendada para enviar al cliente la n\xF3mina de pago. ",
  PAY_PERIOD_REGISTER_STATUS_TOOLTIP: "Este estatus muestra si un periodo de pago ha sido registrado o no. Cuando un periodo de pago es registrado, significa que las deducciones en la n\xF3mina de pago de los miembros se registran en nuestro sistema. ",
  PAY_PERIOD_REGISTER_DATE_TOOLTIP: "Usaremos esta fecha para registrar en nuestro sistema una deducci\xF3n en la n\xF3mina de pago de los miembros para este periodo de pago.",
  PAY_PERIOD_END_DATE_TOOLTIP: "Este es el \xFAltimo d\xEDa del periodo de pago para miembros en este calendario de pagos.",
  PAY_PERIOD_START_DATE_TOOLTIP: "Este es el primer d\xEDa del periodo de pago para miembros en este calendario de pagos.",
  PLAN_PERIOD_END_DATE_TOOLTIP: "Este es el \xFAltimo d\xEDa del per\xEDodo del plan para los miembros en este calendario de n\xF3mina.",
  PLAN_PERIOD_START_DATE_TOOLTIP: "Este es el primer d\xEDa del per\xEDodo del plan para los miembros en este calendario de n\xF3mina.",
  PAY_PERIOD_MEMBER_PAY_DATE: "Fecha de pago del miembro",
  PAY_PERIOD_SCHEDULED_FILE_DELIVERY_DATE: "Fecha prevista de entrega del archivo",
  PAY_PERIOD_REGISTER_STATUS: "Estatus del registro",
  PAY_PERIOD_REGISTER_DATE: "Fecha de registro",
  PAY_PERIOD_END_DATE: "Fecha de finalizaci\xF3n",
  PAY_PERIOD_START_DATE: "Fecha de inicio",
  PLAN_PERIOD_END_DATE: "Fecha de finalizaci\xF3n del per\xEDodo del plan",
  PLAN_PERIOD_START_DATE: "Fecha de inicio del periodo del plan",
  DOWNLOAD_EXPORTED_PAY_SCHEDULE: "Descargar la agenda de pagos exportada",
  EXPORT_PAY_SCHEDULE: "Exportar agenda de pagos",
  REQUESTED_PAYROLL_SCHEDULE_EXPORT: "Exportaci\xF3n de la agenda de n\xF3mina solicitada, por favor, espera.",
  REACH_YOUR_GOALS_SUBTITLE: "Utilizamos tu Perfil de salud para recomendarte los mejores Programas de salud, ofertas del Mercado y contenido para tus necesidades y objetivos espec\xEDficos.",
  REACH_YOUR_GOALS: "Logra tus objetivos con programas, contenido y servicios personalizados.",
  LEARN_MORE_ABOUT_LEAGUE_REWARDS: "M\xE1s informaci\xF3n sobre las Recompensas League",
  GET_INSIGHTS_INTO_YOUR_HEALTH_SUBTITLE: "Tu salud es multifac\xE9tica. Tambi\xE9n lo es tu perfil de salud. Aprender\xE1s sobre tu cuerpo, estilo de vida, mente, t\xFA mismo, salud m\xE9dica y salud social.",
  GET_INSIGHTS_INTO_YOUR_HEALTH: "Obt\xE9n recomendaciones en seis \xE1reas de tu salud.",
  LEARN_MORE_ABOUT_YOUR_HEALTH_PROFILE: "M\xE1s informaci\xF3n sobre tu Perfil de salud",
  EASILY_STAY_ON_TOP_OF_YOUR_HEALTH_SUBTITLE: "Tu Perfil de salud pone toda la informaci\xF3n sobre tu salud en un solo lugar accesible desde el cual puedes controlar tu salud y tus objetivos en todo momento.",
  EASILY_STAY_ON_TOP_OF_YOUR_HEALTH: "Mant\xE9n f\xE1cilmente un estado de salud \xF3ptimo.",
  BUILD_YOUR_HEALTH_PROFILE_SUBTITLE: "Toda la informaci\xF3n sobre tu salud, recursos y objetivos en un solo lugar.",
  BUILD_YOUR_HEALTH_PROFILE: "Construir tu Perfil de salud",
  IMPORT: "Importar",
  ADD_PAY_CALENDAR: "Agregar calendario de pagos",
  IMPORT_PAYROLL_SCHEDULE_SUCCESS: "El calendario de pagos se import\xF3 con \xE9xito",
  PROVIDE_PAY_CALENDAR_NAME: "Pon un nombre al calendario de pagos",
  NO_PAY_SCHEDULE_UPLOADED: "\xA1Todav\xEDa no cargaste ninguna hoja de c\xE1lculo de agenda de pagos!",
  SAVE_PAYROLL_SCHEDULE_SUCCESS: "Calendario de pagos guardado con \xE9xito",
  RENAME_PAY_CALENDAR_COPY: "Editar el nombre visible de este calendario de pagos.",
  RENAME_PAY_CALENDAR: "Cambiar el nombre del calendario de pagos",
  PAY_CALENDAR_NAME: "Nombre del calendario de pagos",
  PAY_SCHEDULE_UPLOADED: "Agenda de pagos cargada",
  IMPORT_PAY_CALENDAR_NOTE: "Si realiza alg\xFAn cambio en la hoja de c\xE1lculo de Google no se actualizar\xE1 autom\xE1ticamente en la plataforma de la administraci\xF3n.",
  IMPORT_PAY_CALENDAR_COPY: "Importar un calendario de pago generar\xE1 periodos de plan y registrar\xE1 fechas desde una plantilla de Google Sheet.",
  PC_HEALTH_LOGO: "Logo de PC Salud",
  LEAGUE_LOGO: "Logo de League",
  ERROR_PAY_CALENDAR_DOES_NOT_EXIST: "No puedes solicitar el informe, el calendario de pagos no existe.",
  REQUEST: "Petici\xF3n",
  ONLY_REGISTERED_PAY_PERIODS: "Solo se mostrar\xE1n en este desplegable los periodos de pago registrados",
  PAY_PERIOD_PREMIUM_EXPORTS_REPORT: "Informe del periodo de pagos de PX",
  GROUP_PREMIUM_EXPORTS_REPORT: "Informe del Grupo PX",
  COPY_REPORT_ID: "Copiar la ID del informe",
  PAY_SCHEDULE: "Agenda de pagos",
  PAY_CALENDAR: "Calendario de pagos",
  PAY_CALENDARS_COPY: "Calendarios que indican los periodos de pago utilizados para impulsar las contribuciones y los informes relacionados con la n\xF3mina.",
  PAY_CALENDARS: "Calendarios de pago",
  PAYROLL_TAB_DESCRIPTION: "Ver y gestionar archivos de la n\xF3mina de pago y agendas",
  INVOICE_HEADER_INVOICE_AMOUNT: "Monto de la factura",
  PURCHASE_HISTORY: "Historial de compras",
  YOU_WILL_EARN_DOLLARS_IN_LEAGUE_CREDIT: "Recibir\xE1s {dollars} d\xF3lares de Cr\xE9dito League.",
  ARE_YOU_SURE_TO_REDEEM_POINTS: "\xBFEst\xE1s seguro de que deseas canjear tus puntos?",
  REDEEM_MY_POINTS: "Canjear mis puntos",
  REWARDS_HISTORY: "Historial de recompensas",
  MEMBER_TOOLING: "Conjunto de herramientas del miembro",
  MEMBER_TOOLS: "Herramientas del miembro",
  CREATED: "Creado",
  ENROLLMENT_SELECTION_REPORT_DOWNLOAD: "Descargar el informe de selecci\xF3n de inscripci\xF3n",
  GET_ENROLLMENT_SELECTION_REPORT: "Obtener el informe de selecci\xF3n de inscripci\xF3n",
  ENROLLMENT_SELECTION_REPORT: "Informe de selecci\xF3n de inscripci\xF3n",
  END_OF_PAGINATION_LIST: "Parece que llegaste al final",
  PLEASE_TRY_AGAIN: "Por favor, int\xE9ntalo de nuevo",
  SHOW_MORE: "Mostrar m\xE1s",
  LOAD_MORE: "Cargar m\xE1s",
  CHAT_DEEPLINK_EXPLAINER: "Inicia un chat en vivo con uno de nuestros expertos del Equipo de servicios y obt\xE9n respuestas a todas tus preguntas.",
  CHAT_DEEPLINK_START_CARE_NAVIGATOR_CHAT: "Chat del Navegador de atenci\xF3n",
  CHAT_DEEPLINK_START_CUSTOMER_CARE_CHAT: "Chat de Atenci\xF3n al cliente",
  CHAT_IN_PROGRESS_EXPLAINER: "Te encuentras en este momento en una sesi\xF3n de chat. Para comenzar un nuevo chat, debes cerrar la sesi\xF3n actual.",
  CHAT_IN_PROGRESS: "Chat en curso",
  PRORATED_AMOUNTS_TEXT: "hemos ajustado algunos importes en funci\xF3n del tiempo que queda del periodo de la p\xF3liza.",
  PRORATED_PRICING_TEXT: "hemos ajustado el precio de los beneficios con base en el tiempo que le resta al periodo de la p\xF3liza.",
  PRORATED_FLEX_TEXT: "hemos ajustado tu monto en {flexDollarTerm} disponible con base en el tiempo que le resta al periodo de la p\xF3liza.",
  PRORATED_PRICING_AND_FLEX_TEXT: "hemos ajustado el precio de los beneficios y tu monto en {flexDollarTerm} disponible con base en el tiempo que le resta al periodo de la p\xF3liza.",
  NEW_HIRE_PRORATION_EXAMPLE: "Fun Company Limited otorga a cada empleado $500 {flexDollarTerm} cada a\xF1o al comienzo del a\xF1o de la p\xF3liza. Si te unes a la compa\xF1\xEDa a mitad del a\xF1o, ese monto ser\xE1 prorrateado a $250.",
  NEW_HIRE_PRORATION_EXAMPLE_TITLE: "Ejemplo de prorrateo",
  NEW_HIRE_PRORATION_MODAL_SUB_HEADING: "{greeting} Porque est\xE1s comenzando a mitad el periodo de la p\xF3liza, {message}",
  NEW_HIRE_PRORATION_MODAL_HEADER: "El precio en tu experiencia est\xE1 prorrateado",
  CONGRATULATIONS_ON_THE_NEW_JOB: "\xA1Felicitaciones por tu nuevo empleo!",
  CHAT_UNAVAILABLE_INFO: "Estamos teniendo algunos problemas con el chat en este momento, pero trabajamos para solucionarlos. Si necesitas asistencia cont\xE1ctanos en {helpLink}.",
  CHAT_UNAVAILABLE: "El chat no est\xE1 disponible en este momento",
  YOUR_PLACE_IN_QUEUE: "Tu lugar en la fila",
  APPROXIMATE_WAIT: "Aproximadamente {waitTime} minutos",
  CANCEL_CHAT: "Cancelar el chat",
  WAITING_FOR_PROVIDER: "Esperando al pr\xF3ximo proveedor disponible",
  AFTER_EXPIRY_YOU_HAVE_UNTIL_DATE_TO_SUBMIT: "Luego de vencido, tienes hasta el <strong>{inactiveDate}</strong> para enviar reclamaciones con fecha de compra/servicio del <strong>{endDate}</strong> o antes",
  YOU_HAVE_X_DAYS_BEFORE_EXPIRY: "Te restan {days} d\xEDas para usar este beneficio antes del vencimiento de tu periodo de p\xF3liza.",
  EXPIRES_ON_DATE: "Vence en {date}",
  YOU_HAVE_UNTIL_DATE_TO_SUBMIT_PAST_CLAIMS: "Tienes hasta el <strong>{inactiveDate}</strong> para enviar reclamaciones pasadas con fecha de compra/servicio del <strong>{endDate}</strong> o antes",
  THIS_POLICY_EXPIRES_TOMORROW: "El periodo de esta p\xF3liza ha vencido, pero hoy es el \xFAltimo d\xEDa para enviar reclamaciones pasadas de este beneficio",
  THIS_POLICY_HAS_EXPIRED: "El periodo de esta p\xF3liza ha vencido, pero te restan {days} d\xEDas para enviar reclamaciones pasadas de este beneficio",
  SUBMIT_PAST_CLAIMS_BY_DATE: "Env\xEDa tus reclamaciones pasadas hasta el {date}",
  YOU_CAN_ONLY_SUBMIT_CLAIMS_IN_DATE_RANGE: "\xDAnicamente puedes enviar reclamaciones de este beneficio con una fecha de compra/servicio entre <strong>{dateRange}</strong>. Ve los detalles de tu cobertura para obtener m\xE1s informaci\xF3n.",
  BECOMES_ACTIVE_IN_X_DAYS: "El beneficio se activar\xE1 en {days} d\xEDas",
  BECOMES_ACTIVE_ON_DATE: "Se activar\xE1 el {date}",
  EMAIL_MESSAGE: "Mensaje de correo electr\xF3nico",
  START_CHAT_WITH_REGISTERED_NURSE: "Inicia un chat con una enfermera certificada (se abre en una pesta\xF1a nueva)",
  START_CHAT_WITH_CUSTOMER_CARE: "Inicia un chat con un agente de atenci\xF3n al cliente (se abre en una pesta\xF1a nueva)",
  START_CHAT_WITH_CARE_NAVIGATOR: "Inicia un chat con un navegador de atenci\xF3n (se abre en una pesta\xF1a nueva)",
  CHAT_WITH_REGISTERED_NURSE_PAGE_TITLE: "Chatea con una enfermera certificada - League",
  CHAT_WITH_CUSTOMER_CARE_PAGE_TITLE: "Chatea con un agente de atenci\xF3n al cliente - League",
  CHAT_WITH_CARE_NAVIGATOR_PAGE_TITLE: "Chatea con un navegador de atenci\xF3n - League",
  CHAT_HISTORY_DETAILS_PAGE_TITLE: "Transcripci\xF3n del chat - League",
  CHAT_HISTORY_PAGE_TITLE: "Historial del chat - League",
  CHAT_PAGE_TITLE: "Chat - League",
  CHAT_WITH_REGISTERED_NURSE: "Chatea con personal de enfermer\xEDa registrado",
  CHAT_WITH_CUSTOMER_CARE: "Chatear con un agente de atenci\xF3n al cliente",
  CHAT_WITH_CARE_NAVIGATOR: "Chatea con un navegador de servicios",
  CHAT_VIEW_TRANSCRIPT_ARIA_LABEL: "Ver la transcripci\xF3n del chat con {owner} el {date} a las {time}",
  CHAT_WITH_NAME: "Chat con {name}",
  CHAT_HISTORY_EMPTY_DESCRIPTION: "Cuando finalizas una sesi\xF3n de chat, podr\xE1s ver los detalles y la transcripci\xF3n de la conversaci\xF3n aqu\xED.",
  CHAT_HISTORY_EMPTY_HEADING: "\xA1Nada por aqu\xED todav\xEDa!",
  CHAT_WITH: "Chat con",
  CHAT_PROVIDER: "Proveedor del chat",
  CHAT_VIEW_HISTORY: "Ver historial del chat",
  CHAT_HELPFUL_ACTIONS: "Acciones \xFAtiles",
  CHAT_DETAILS: "Detalles del chat",
  CHAT_HISTORY: "Historial del chat",
  CHAT_CARE_NAVIGATOR_ONLINE_HOURS: "Horario en l\xEDnea del Navegador de servicios",
  CHAT_CUSTOMER_CARE_ONLINE_HOURS: "Horario en l\xEDnea de Atenci\xF3n al cliente",
  CHAT_VIEW_ONLINE_HOURS: "Ver el horario en l\xEDnea",
  CHAT_CREATE_OFFLINE_CASE_SUCCESS_BODY: "Nos estamos ocupando y te enviaremos una respuesta pronto.",
  CHAT_CREATE_OFFLINE_CASE_SUCCESS: "Hemos recibido tu correo electr\xF3nico",
  CHAT_CREATE_OFFLINE_CASE_ERROR: "Se produjo un error al enviar el correo electr\xF3nico, por favor, vuelve a intentarlo.",
  CHAT_OFFLINE_CASE_SUBJECT_TOO_SHORT: "Se requiere un mensaje",
  CHAT_OFFLINE_CASE_SUBJECT_LABEL: "\xBFEn qu\xE9 est\xE1s pensando?",
  CHAT_OFFLINE_CASE_DESCRIPTION: "Env\xEDanos un mensaje y nos ocuparemos tan pronto como los miembros de nuestro equipo est\xE9n en l\xEDnea nuevamente. Verifica la respuesta en tu correo electr\xF3nico en las pr\xF3ximas 24 horas.",
  CHAT_SEND_EMAIL_TO_CARE_NAVIGATOR: "Enviar correo electr\xF3nico al Navegador de servicios",
  CHAT_SEND_EMAIL_TO_CUSTOMER_CARE: "Enviar correo electr\xF3nico a Atenci\xF3n al cliente",
  CHAT_SEND_EMAIL: "Enviar correo electr\xF3nico",
  CHAT_EMERGENCY_MESSAGE: "Si se trata de una emergencia, contacta por favor al 911.",
  CHAT_FAILED_TO_LOAD_MESSAGE: "Estamos teniendo algunos problemas con el chat en este momento, pero estamos trabajando para solucionarlos. Entre tanto, puedes enviar tus preguntas a {email}.",
  CHAT_OFFLINE: "Desconectado",
  CHAT_NOT_AVAILABLE: "No disponible",
  CHAT_START_CHAT_WITH_TAB_WARNING: "Iniciar chat (se abre en una pesta\xF1a nueva)",
  CHAT_START_CHAT: "Comenzar un chat",
  CHAT_CARE_NAVIGATOR_DESCRIPTION: "Obt\xE9n ayuda explorando el panorama de la salud, incluyendo asistencia directa para encontrar un proveedor, una instituci\xF3n o un servicio para una necesidad espec\xEDfica.",
  CHAT_CARE_NAVIGATOR_HEADING: "Navegador de servicios",
  CHAT_CUSTOMER_CARE_DESCRIPTION: "\xBFSe te present\xF3 un problema? \xBFTienes alguna pregunta sobre tus beneficios, cuenta o dependientes? Habla con un miembro de nuestro equipo.",
  CUSTOMER_CARE: "Atenci\xF3n al cliente",
  CHAT_TEAM_DESCRIPTION: "Habla con nuestros profesionales de salud certificados y nuestro personal de servicio, quienes pueden ayudarte a ahorrar dinero al recomendarte los recursos m\xE1s accesibles y de mejor calidad en tu \xE1rea, as\xED como brindarte respuestas a todas las preguntas sobre tus beneficios.",
  CHAT_TEAM_HEADING: "Obt\xE9n respuestas de nuestro Equipo de servicios",
  CHAT: "Chat",
  TOP_MATCH: "Combinaci\xF3n \xF3ptima",
  HISTORY: "Historial",
  CHANGE_REPORT: "Cambiar informe",
  DOWNLOAD_REPORT: "Descargar informe",
  FULL_REPORT: "Informe completo",
  REPORT_ID: "ID del informe",
  CHAT_WITH_HEALTH_CONCIERGE: "Chatear con Personal de enfermer\xEDa registrado",
  TALK_ABOUT_PERSONALIZED_HEALTH_NEEDS: "Habla con una persona acerca de tus necesidades personales de salud. Nuestro equipo de profesionales de salud certificados est\xE1n a solo un paso de comunicarse contigo.",
  HELP_FIND_RIGHT_RESOURCES_FOR_HEALTH_NEEDS: "Podemos ayudarte a encontrar los recursos adecuados para tus necesidades de salud",
  VIEW_MORE_IN_OUR_HELP_CENTER: "M\xE1s informaci\xF3n en nuestro Centro de ayuda",
  LEGACY_FEES: "Tarifas de Legacy",
  PENDING_FEES: "Tarifas pendientes",
  ADHOC_FEE_TERM_TOOLTIP: "Especifica un valor si la tarifa es recurrente. Por ej. una tarifa de plataforma mensual durante 12 meses o una tarifa de consultor\xEDa de salud trimestral.",
  ADHOC_FEE_UNIT_TOOLTIP: "Este valor puede representar lugares en la plataforma o una carga simple (en cuyo caso debe configurarse a 1).",
  ADHOC_FEE_RATE_TOOLTIP: "Este valor debe tener en cuenta el \u2018N\xFAmero de unidades\u2018 y, si corresponde, el \u2018Plazo\u2018. Por ej. una tarifa que es por unidad por mes.",
  OFFERED_BY_LEAGUE: "Ofrecido por League",
  MORE_GREAT_RESOURCES: "M\xE1s recursos excelentes",
  LEAGUE_HEALTH_PROGRAM: "Programa de salud de League",
  ADDITIONAL_BENEFITS_MODAL_DESCRIPTION: "Puedes encontrar estos beneficios y la manera de usarlos en la aplicaci\xF3n de League.",
  CLEAR_FIELD_VALUE: "--Limpiar valor del campo--",
  ADDITIONAL_BENEFITS_BANNER_DESCRIPTION: "Tu empleador tambi\xE9n te brinda los siguientes beneficios como parte de tu paquete total de recompensas.",
  ADHOC_FEE_MULTIPART_TERM_ERROR: "Debes llenar ambos campos",
  NO_CARE_PROVIDER_HISTORY: "La siguiente ID de usuario no tiene un historial de proveedores de atenci\xF3n",
  NO_PURCHASE_HISTORY: "La siguiente ID de usuario no tiene ning\xFAn historial de compra",
  NO_REWARDS_HISTORY: "La siguiente ID de usuario no tiene ning\xFAn historial de recompensas.",
  NO_HEALTH_GOAL_PROGRAMS: "La siguiente ID de usuario no ha completado ning\xFAn programa de salud",
  PROGRAM_GOAL: "Objetivo",
  PROGRAM_END_DATE: "Fecha de finalizaci\xF3n",
  PROGRAM_START_DATE: "Fecha de inicio",
  IN_PROGRESS_PROGRAM_DETAILS: "Detalles del programa en progreso",
  COMPLETED_PROGRAM_DETAILS: "Detalles del programa completados",
  ISSUE_INVOICE_SUCCESS: "Factura emitida. Se puede ver en la pesta\xF1a {link}",
  ISSUE_INVOICE_MODAL_INFO: "\xBFEst\xE1s seguro de que deseas emitir una factura independiente con las siguientes tarifas?",
  ISSUE_INVOICE_MODAL_TITLE: "Confirmar factura",
  REMOVE_FEE_CONFIRM: "\xBFEst\xE1s seguro de que deseas eliminar esta tarifa?",
  PREMIUMS_HEADER_COPY: "Descargar un informe de las deducciones por periodo de pago para todos los empleados de este grupo.",
  PREMIUMS_HEADER: "Informe de exportaci\xF3n de primas",
  PREMIUMS: "Exportaci\xF3n de primas",
  PLAN_STATUS: "Estatus del plan",
  ENROLLING: "Inscribiendo",
  PENDING_FEE_UNITS: "Unidad/es",
  REMOVE_FEE_SUCCESS: "Tarifa eliminada con \xE9xito",
  REMOVE_FEE: "Quitar tarifa",
  ADD_FEE_INFORMATION: "Agregar plataforma, implementaci\xF3n, integraci\xF3n y otras tarifas a una factura autom\xE1tica.",
  ISSUE_INVOICE: "Emitir factura",
  PREVIEW_INVOICE: "Vista previa de la factura",
  PENDING_FEES_INFO: "Vista previa y emisi\xF3n de facturas. Todas las tarifas enumeradas abajo ser\xE1n agregadas a una factura.",
  NO_PENDING_FEES: "No hay tarifas pendientes. Para agregar una, ve a Tarifas.",
  PENDING_FEE: "tarifa pendiente",
  SUBMIT_FEE_SUCCESS: "Tarifa enviada. Ver {link}.",
  QLE_WELCOME_FLEX_DOLLAR_TERM_TOOLTIP: "{group} te ofrece {flexDollarTerm}, que son fondos adicionales que puedes gastar en beneficios como lo desees.",
  QLE_WELCOME_IMPORTANT_NOTE_EFFECTIVE_DATE: "Tienes hasta {qleEnrollmentEndDate} para efectuar cambios en tus elecciones. Si cambias de elecciones, tus nuevos beneficios entrar\xE1n en vigor el {qleCoverageStartDate}.",
  QLE_WELCOME_IMPORTANT_NOTE_PRORATED: "Cada nuevo fondo que agregues a tu cuenta bienestar ser\xE1 prorrateado con base en la fecha de tu Evento de vida calificado {qleDate}.",
  QLE_WELCOME_IMPORTANT: "Cosas importantes para tomar nota:",
  QLE_WELCOME_SUBMIT_CHANGES: "Enviar tus cambios (o deja todo sin cambios)",
  QLE_WELCOME_MANAGE_DEPENDENTS: "Agregar, editar o quitar dependientes",
  QLE_WELCOME_SPEND_AVAILABLE_FLEX: "Gasta tus {flexDollarTerm} disponibles en beneficios",
  QLE_WELCOME_REVIEW_YOUR_CURRENT_ELECTIONS: "Revisar tus elecciones actuales",
  QLE_WELCOME_HERE_IS_WHAT_YOU_CAN_DO: "Esto es lo que puedes hacer:",
  QUALIFYING_LIFE_EVENT_QLE: "Evento de vida calificado (QLE)",
  QLE_WELCOME_SUB_HEAD_DESCRIPTION: "Recientemente tuviste un {qleLink}. Veamos si tu plan de beneficios actual necesita un ajuste para adaptarse mejor a tu situaci\xF3n.",
  QLE_WELCOME: "\xA1Hola de nuevo, {userName}!",
  UNIT_TOOLTIP: "Este valor puede representar tanto el n\xFAmero de empleados como una carga simple, en cuyo caso el valor ser\xEDa 1.",
  YEARS_LOWERCASE: "a\xF1os",
  QUARTERS_LOWERCASE: "trimestres",
  QUARTER_LOWERCASE: "trimestre",
  MONTHS_LOWERCASE: "meses",
  DAYS_LOWERCASE: "d\xEDas",
  YEARS: "A\xF1os",
  QUARTERS: "Trimestres",
  QUARTER: "Trimestre",
  MONTHS: "Meses",
  FEE_RATE_PLACEHOLDER: "10.00",
  FEE_RATE_TOOLTIP: 'Este valor debe tener en cuenta el "N\xFAmero de unidades" y, si corresponde, el "Plazo". Por ej. una tarifa que es por unidad al mes.',
  FEE_RATE: "Tarifa",
  TERM_TOOLTIP: "Especifica un valor si la tarifa es recurrente. Por ej. una tarifa de plataforma mensual en 12 meses o una tarifa de consultor\xEDa de salud en 4 trimestres",
  TERM: "Plazo",
  UNITS_TOOLTIP: "Este valor puede representar lugares en la plataforma o una carga simple (en cuyo caso debe configurarse a 1)",
  UNITS: "N\xFAmero de unidades",
  BILLING_PERIOD_END_DATE: "Fecha de finalizaci\xF3n del periodo de facturaci\xF3n (opcional)",
  BILLING_PERIOD_START_DATE: "Inicio del periodo de facturaci\xF3n",
  BILLING_DIVISION: "Divisi\xF3n de facturaci\xF3n",
  INTEGRATION: "Integraci\xF3n",
  IMPLEMENTATION: "Implementaci\xF3n",
  HEALTH_ADVISORY: "Recomendaciones de salud",
  PLATFORM: "Plataforma",
  FEE_TYPE_HELPER: "Selecciona el tipo de tarifa",
  FEE_TYPE: "Tipo de tarifa",
  CHOOSE_ONE: "Elegir una",
  TAKE_ACTION: "Realizar una acci\xF3n",
  NEVER_SUBMITTED_DEFAULTED: "Nunca fue enviado (por defecto)",
  NOT_SUBMITTED: "No enviado",
  LAST_SUBMITTED: "\xDAltimo env\xEDo {submittedTime}",
  SUBMITTED_STATE: "Estado del env\xEDo:",
  ENROLLMENT_CLOSED: "Inscripci\xF3n cerrada",
  IN_ENROLLMENT: "En Inscripci\xF3n",
  UPDATE_GROUP_CATEGORIES_SUCCESS: "Lista de cobertura actualizada con \xE9xito",
  UPDATE_GROUP_CATEGORIES_DESCRIPTION: "Selecciona por favor un archivo CSV a usar para actualizar la cobertura de esta cuenta de gastos (puede tomar un tiempo para procesarse).",
  UPDATE_GROUP_CATEGORIES: "Actualizar categor\xEDas",
  PREFERENCES: "Preferencias",
  LOST_COVERAGE: "P\xE9rdida de cobertura",
  DEPENDENT_CHILD_OF_DOMESTIC_PARTNER: "\xBFEste dependiente es hijo de una pareja de hecho?",
  DEPENDENT_OVERAGE_DISABLED_CHILD: "\xBFEste hijo/a es un dependiente excedido en edad con discapacidad? ",
  DEPENDENT_FULL_TIME_STUDENT: "\xBFEste hijo/a es estudiante de tiempo completo?",
  DEPENDENT_TOBACCO_USER: "\xBFEste dependiente consume tabaco?",
  REMOVE_DEPENDENT_MODAL_MESSAGE: "Identifica por favor una fecha de finalizaci\xF3n de los beneficios y un motivo de la finalizaci\xF3n para quitar a <strong>{dependentName}</strong> como dependiente de <strong>{employeeName}</strong>.",
  DEPENDENT_DETAILS: "Informaci\xF3n sobre el dependiente",
  BASIC_INFO: "Informaci\xF3n b\xE1sica",
  VERIFIED: "Verificado",
  VERIFICATION_STATUS_EFFECTIVE_DATE: "Estado de verificaci\xF3n Fecha de vigencia",
  VERIFICATION_STATUS: "Estado de verificaci\xF3n",
  REMOVE_QLE_DATE: "Quitar fecha del QLE",
  ADD_QLE_DATE: "Agregar fecha del QLE",
  ADD_QLE_TYPE: "Agregar tipo de QLE",
  REMOVE_DEPENDENT_MODAL_TITLE: "Quitar {dependentName}",
  DEPENDENT_TERMINATION_PENDING: "Se inactiv\xF3 el {terminationDate}",
  TERMINATION_REASON: "Motivo de la finalizaci\xF3n",
  BENEFITS_TERMINATION_DATE: "Fecha de finalizaci\xF3n de los beneficios",
  CHILD_OF_DOMESTIC_PARTNER: "Hijo de pareja de hecho",
  USES_TOBACCO: "Consume tabaco",
  AMOUNT_PER_PERIOD: "{amount} / {period}",
  CERTIFICATION_ERROR_REQUIRED: "Esta informaci\xF3n es necesaria para proceder",
  CERTIFICATION_TITLE_TOOLTIP_TOBACCO_CESSATION: "<p>Calificas como consumidor de tabaco si has consumido tabaco m\xE1s de <strong> una vez por mes en promedio durante los \xFAltimos 6 meses </strong>.</p><p>Consumir tabaco incluye fumar, masticar, inhalar y/o vapear. Este recargo tiene la intenci\xF3n de promover la buena salud y de compensar una parte de los costos mayores que el promedio incurridos por los consumidores de tabaco.</p>",
  CERTIFICATION_OPTION_NO_TOBACCO_CESSATION_DEPENDENTS: "<strong>No</strong> - Ni yo ni mis dependientes consumimos tabaco",
  CERTIFICATION_OPTION_YES_TOBACCO_CESSATION_DEPENDENTS: "<strong>S\xED</strong> - Yo y/o mis dependientes consumimos tabaco",
  CERTIFICATION_OPTION_NO_TOBACCO_CESSATION_MYSELF: "<strong>No</strong> - No consumo tabaco",
  CERTIFICATION_OPTION_YES_TOBACCO_CESSATION_MYSELF: "<strong>S\xED</strong> - Consumo tabaco",
  CERTIFICATION_LINK_TOBACCO_CESSATION: "\xBFNecesitas ayuda para dejar de fumar? Encontrar\xE1s m\xE1s informaci\xF3n aqu\xED",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION_DEPENDENTS: "Si t\xFA participas o alguno de tus dependientes participa en {name}, el cargo por consumo de tabaco ya no se aplicar\xE1 al costo de tu plan m\xE9dico.",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION_MYSELF: "Si participas en {name} ya no se aplicar\xE1 el cargo por consumo de tabaco al costo de tu plan m\xE9dico.",
  CERTIFICATION_DESCRIPTION_TOBACCO_CESSATION: "Debes certificar si t\xFA o alguno de tus dependientes mayores de 18 a\xF1os consume tabaco para poder inscribirte en la cobertura m\xE9dica. Un cargo de <strong>{amount}</strong> se aplicar\xE1 al costo de tu plan m\xE9dico si confirmas que <strong>S\xED</strong>.",
  CERTIFICATION_TITLE_TOBACCO_CESSATION_DEPENDENTS: "\xBFConsumes t\xFA o consume alguno de tus dependientes tabaco?",
  CERTIFICATION_TITLE_TOBACCO_CESSATION_MYSELF: "\xBFConsumes tabaco?",
  CERTIFICATION_TITLE_MEDICARE: "\xBFEst\xE1s inscrito en Medicare?",
  CERTIFICATION_OPTION_NO_MEDICARE: "<strong>No</strong> - No estoy inscrito en Medicare",
  CERTIFICATION_OPTION_YES_MEDICARE: "<strong>S\xED</strong> - Estoy inscrito en Medicare",
  LEARN_MORE_ABOUT_MEDICARE: "Conoce m\xE1s sobre Medicare",
  CERTIFICATION_LINK_MEDICARE: "M\xE1s informaci\xF3n",
  CERTIFICATION_DESCRIPTION_MEDICARE_CONTACT: "Si tienes preguntas o decides en un futuro inscribirte a Medicare, contacta a <a>ayuda@league.com</a>.",
  CERTIFICATION_DESCRIPTION_TOOLTIP_MEDICARE: "Las HRA le permiten a {employerName} separar fondos para reembolsarte tus gastos m\xE9dicos calificados; recibir\xE1s en tu HRA los fondos de la compa\xF1\xEDa aseguradora que de otro modo hubieses recibido en tu CAS.",
  CERTIFICATION_DESCRIPTION_MEDICARE_WITH_FALLBACK: "Si est\xE1s inscrito en Medicare (Partes A, B, C y/o D) ser\xE1s descalificado para hacer o recibir contribuciones del empleador a un {ineligibleBenefitIfSelectedName}{ineligibleBenefitIfSelectedShortName}. Como quieras puedes inscribirte en un plan m\xE9dico calificado {ineligibleBenefitIfSelectedShortName} pero en lugar de recibir un {ineligibleBenefitIfSelectedShortName}, estar\xE1s inscrito en un{eligibleBenefitIfSelectedName}{eligibleBenefitIfSelectedShortName}).",
  CERTIFICATION_DESCRIPTION_MEDICARE: "Si est\xE1s inscrito en Medicare (Partes A, B, C y/o D) ser\xE1s descalificado para hacer o recibir contribuciones del empleador a un {ineligibleBenefitIfSelectedName}{ineligibleBenefitIfSelectedShortName}. Como quieras puedes inscribirte en un plan m\xE9dico calificado {ineligibleBenefitIfSelectedShortName}, solo que no estar\xE1s inscrito en un {ineligibleBenefitIfSelectedShortName}.",
  CERTIFICATION_TITLE_ALTERNATE_COVERAGE: "\xBFTu c\xF3nyuge tiene acceso a cobertura en alg\xFAn otro lado?",
  CERTIFICATION_OPTION_NO_ALTERNATE_COVERAGE: "<strong>No</strong> - Mi pareja no tiene acceso a una cobertura m\xE9dica ofrecida por su empleador",
  CERTIFICATION_OPTION_YES_ALTERNATE_COVERAGE: "<strong>S\xED</strong> - Mi pareja tiene acceso a cobertura m\xE9dica ofrecida por su empleador",
  LEARN_MORE_ABOUT_THIS_SURCHARGE: "Conoce m\xE1s sobre este recargo",
  CERTIFICATION_LINK_ALTERNATE_COVERAGE: "M\xE1s informaci\xF3n",
  CERTIFICATION_DESCRIPTION_ALTERNATE_COVERAGE: "Debes certificar si tu c\xF3nyuge o pareja tiene cobertura m\xE9dica ofrecida por su empleador. Se aplicar\xE1 un cargo de <strong>{amount}</strong> al costo total del plan m\xE9dico que elijas si confirmas <strong>que s\xED</strong>.",
  CERTIFICATION_PROGRAM_DISCOUNT_APPLIED: "Felicitaciones por completar el {name} - Aplicamos un descuento de <strong>{discount}</strong> a tus opciones de plan {benefitSetName}.",
  CERTIFICATION_PROGRAM_DISCOUNT_AVAILABLE: "Puedes recibir un descuento de <strong>{discount}</strong> de tu plan <strong>{benefitSetName}</strong> si completas <strong>{name}</strong> dentro de {deadlineDays} de tu fecha de inscripci\xF3n.",
  DEFAULT_UNITS: "Unidades por defecto",
  AT_RISK_DESCRIPTION: "Descripci\xF3n de A riesgo",
  AT_RISK: "A riesgo",
  RISK: "Riesgo",
  RISK_FACTOR_RESULTS: "Resultados de Factores de riesgo",
  USER_INPUT: "Entrada del usuario",
  RISK_FACTOR: "Factores de riesgo",
  RISK_FACTOR_INPUTS: "Entradas de Factores de riesgo",
  ENTER: "Ingresar",
  INPUT_USER_ID: "Por favor, ingresa una ID de usuario",
  HOME: "Inicio",
  HEALTH_PROGRAMS: "Programas de salud",
  HEALTH_PROFILE: "Perfil de salud",
  USER_PROFILE: "Perfil del usuario",
  PRICING_CONFIG_DOWNLOAD: "Descargar Config de precios del grupo ",
  EXPORT_PRICING_CONFIG_TO_CSV: "Exportar Config de grupo a CSV",
  PLEASE_ENTER_A_VALID_EMAIL: "Ingresa una direcci\xF3n de correo electr\xF3nico v\xE1lida",
  IF_YOU_NEED_HELP_EMAIL_US: "Si necesitas ayuda o tienes preguntas sobre tu cuenta, cont\xE1ctanos a {emailLink}.",
  SORRY_WE_CANT_FIND_PAGE: "Lo lamentamos, no podemos encontrar la p\xE1gina que est\xE1s buscando.",
  UH_OH_SOMETHING_WENT_WRONG: "\xA1Oh! parece que algo sali\xF3 mal",
  YOURE_A_TAD_EARLY_NO_DATE: "Te has anticipado un poco. Por favor, vuelve a ingresar en la fecha de inicio de la inscripci\xF3n para activar tu cuenta con League.",
  YOURE_A_TAD_EARLY: "Te has anticipado un poco. Por favor, vuelve a ingresar el {enrollmentStartDate} (registration start date) para activar tu cuenta con League.",
  WE_COULDNT_FIND_YOU_DESCRIPTION: "Si a\xFAn no puedes acceder, env\xEDa un correo electr\xF3nico a {emailLink} con tu nombre y apellido, fecha de nacimiento, ID de miembro de Great-West Life y una foto de tu tarjeta de ID de miembro de Great-West Life.",
  WE_COULDNT_FIND_YOU: "No hemos podido encontrarte. Por favor, verifica nuevamente la informaci\xF3n anterior y vuelve a intentarlo.",
  BENEFIT_TYPE: "Tipo de beneficio",
  BENEFIT_ID: "ID del beneficio",
  BENEFIT_SELECTION_INFO: "<strong>ID del beneficio</strong>: {benefitId} </br> <strong>Tipo de beneficio</strong>: {benefitType}",
  MEMBER_POLICY_ID_DELETED: "\xA1La informaci\xF3n de la tarjeta ha sido borrada con \xE9xito!",
  MEMBER_POLICY_ID_SUCCESS: "\xA1Informaci\xF3n de la tarjeta guardada! Cons\xFAltala en todo momento en tu cartera.",
  MEMBER_POLICY_ID_ERROR: "Algo sali\xF3 mal de nuestro lado. Por favor, vuelve a intentarlo.",
  PRICING_CONFIG_SAVE_CONTENT: "<strong>Nota:</strong> Guardar una configuraci\xF3n de precios tiene un efecto inmediato en los beneficios elegidos por el empleado {benefitId}",
  PRICING_CONFIG_SAVE_HEADER: "Confirmar guardar la configuraci\xF3n de precios de beneficios",
  CAROUSEL_WALLET_DESCRIPTION: "Una cartera digital personalizada con tus tarjetas de beneficios.",
  CAROUSEL_WALLET_HEADING: "Cartera",
  CAROUSEL_HEALTH_PROGRAMS_DESCRIPTION: "Descubre los cambios que puedes realizar para mejorar tu salud ",
  CAROUSEL_HEALTH_PROGRAMS_HEADING: "Programas de salud",
  CAROUSEL_CHAT_DESCRIPTION: "Obt\xE9n respuestas r\xE1pidas a todas tus preguntas sobre salud y beneficios.",
  CAROUSEL_CHAT_HEADING: "Chat en vivo",
  FOR_YOU_ONE_CHILD: "Para ti y para tu hijo/a",
  FOR_YOU_CHILDREN: "Para ti y para tus hijos/as",
  FOR_YOU_SPOUSE_CHILDREN: "Para ti, para tu pareja y para tus hijos/as",
  FOR_YOU_SPOUSE: "Para ti y para tu pareja",
  FOR_YOU: "Para ti",
  BENEFIT_NAME_WITH_DEPENDENT_COVERAGE: "{benefitName} ({dependentCoverage})",
  IDENT_AMOUNT: "{amount}",
  IDENT_VALUE: "{value}",
  NUMBER_OF_BENEFITS: "{number} beneficios",
  WHERE_CAN_I_FIND_MY: "\xBFD\xF3nde puedo encontrar mi",
  PRIVACY_POLICY: "Pol\xEDtica de privacidad",
  SET_EMPLOYER_BENEFIT_PRICE_CONFIG_SUCCESS_MESSAGE: "La configuraci\xF3n de precios de beneficios se ha guardado con \xE9xito",
  CHAT_DESCRIPTION: "Obt\xE9n respuestas r\xE1pidas a todas tus preguntas sobre salud y beneficios.",
  CHAT_HEADING: "Chat-Hablar",
  SIGN_IN: "Conectarse",
  IF_THIS_WASNT_YOU: "Si no fuiste t\xFA o si tienes alguna pregunta, contacta a",
  LOOKS_LIKE_YOU_ALREADY_ACTIVATED: "Parece que ya has activado tu cuenta.",
  YOUR_ACCOUNT_IS_ALREADY_ACTIVE: "Tu cuenta ya est\xE1 activada",
  CREATE_PASSWORD: "Crear contrase\xF1a",
  WE_FOUND_YOUR_ACCOUNT: "Encontramos tu cuenta. Terminemos creando tu contrase\xF1a.",
  NICE_TO_SEE_YOU: "Encantado de verte, {name}",
  APPROVED_COVERAGE_EOI_BANNER: "Solo se te cobrar\xE1 por tu cobertura aprobada hasta que tu formulario {eoiTooltip} haya sido enviado y aprobado por tu compa\xF1\xEDa aseguradora.",
  APPROVED_COVERAGE_TOOLTIP: "Este es el monto de la cobertura que tienes actualmente aprobado. Solo se te cobrar\xE1 por esta cobertura hasta que tu formulario de Evidencia de asegurabilidad sea enviado y aprobado por tu compa\xF1\xEDa aseguradora.",
  APPROVED_COVERAGE: "Cobertura aprobada",
  TOTAL_COST: "Costo total",
  COVERAGE: "Cobertura",
  COST: "Costo",
  COVERAGE_FOR: "Cobertura para",
  CURRENT_COVERAGE_INFO: "Este es un resumen de tus beneficios actuales. Pensamos que esto facilitar\xEDa tus elecciones de inscripci\xF3n.",
  YOUR_CURRENT_PLAN: "Tu plan actual",
  YOUR_CURRENT_PLAN_COST: "Tu costo de plan actual",
  SEE_CURRENT_COVERAGE: "Ver Cobertura actual",
  CURRENT_COVERAGE: "Cobertura actual",
  EMPLOYER_ALLOCATION: "Asignaci\xF3n del empleador",
  PERSONAL_ALLOCATION: "Asignaci\xF3n personal",
  TOTAL_ALLOCATION: "Asignaci\xF3n total",
  COORDINATION_OF_BENEFITS_EXPLANATION: "Cuando env\xEDes una reclamaci\xF3n, tu plan de seguro ser\xE1 el <strong>pagador primario</strong>. Si tu pareja tiene un plan de seguro que te cubre a ti, ser\xE1 el <strong>pagador secundario</strong>. Esto significa que ante cada reclamaci\xF3n que env\xEDes, tu plan pagar\xE1 primero y lo restante ser\xE1 pagado por el plan de tu pareja.",
  COORDINATION_OF_BENEFITS_EXPLANATION_TITLE: "\xBFQu\xE9 impacto tiene esto en mis reclamaciones?",
  EXISTING_COVERAGE_HEALTH: "Cobertura de salud actual de tu c\xF3nyuge.",
  EXISTING_COVERAGE_DENTAL: "Cobertura dental actual de tu c\xF3nyuge",
  EXISTING_COVERAGE_VISION: "Cobertura de visi\xF3n actual de tu c\xF3nyuge",
  EXISTING_COVERAGE_DRUG: "Cobertura de medicamentos actual de tu c\xF3nyuge",
  COORDINATION_OF_BENEFITS_SUBTITLE: "A veces dos planes de seguro trabajan juntos para pagar reclamaciones a una misma persona. Esto se llama coordinaci\xF3n de beneficios y contribuye a tener m\xE1s de tus costos de reclamaci\xF3n cubiertos.",
  COORDINATION_OF_BENEFITS: "Coordinaci\xF3n de beneficios",
  BENEFIT: "beneficio",
  CANT_UNDO: "No podr\xE1s revertir esta acci\xF3n.",
  CONFIRM_REMOVE_ITEM: '\xBFEst\xE1s seguro de que deseas quitar "{itemName}"?',
  ADD_AN_ITEM: "Agregar un elemento",
  BLOCK_TOGGLE_NODE_MODAL_BODY: "Hemos notado que has realizado algunos cambios que no han sido guardados. Aseg\xFArate de guardar tus cambios antes de seguir adelante. <br/><br/>Si no deseas guardar algo que cambiaste o actualizaste, puedes reiniciar el formulario para volver a tu versi\xF3n previa.",
  BLOCK_TOGGLE_NODE_MODAL_HEADER: "Tienes cambios no guardados en este formulario",
  ENABLE_TYPEFORM_TOOLTIP: "Esto determina si un miembro puede actualizar sus elecciones de beneficios del plan. Esto es provisto por Ops y puede ser personalizado por tipo de QLE y categor\xEDa de beneficio. Desactivar esto para los QLE que no permiten a los miembros actualizar sus elecciones de plan.",
  ENABLE_MANAGE_DEPENDENTS_TOOLTIP: "Esto determina si un miembro puede ingresar a la p\xE1gina de gestionar dependientes donde puede agregar y/o quitar dependientes. Desactivar esto para los QLE que no permiten a los miembros actualizar sus dependientes.",
  WHERE_TO_DIRECT_MEMBERS_TOOLTIP: "Esto determina si un miembro es enviado nuevamente a inscripci\xF3n en NEX (en este momento solo disponible si es un QLE total) o si es una configuraci\xF3n personalizada que puedes configurar para habilitar gestionar dependientes y/o Typeform (en este momento a cargo de los QLE parciales).",
  QLE_REQUIRES_HR_TOOLTIP: "Esto determina si un miembro puede proceder directamente a actualizar sus beneficios o si necesita primero la aprobaci\xF3n de RR. HH.",
  ALLOW_PRECEDING_QLE_DATE_TOOLTIP: "Esto permite a los miembros informar sus QLE antes de que sucedan, es decir, en el futuro.",
  ALLOW_FOLLOWING_QLE_DATE_TOOLTIP: "Esto permite a los miembros informar sus QLE luego de sucedidos, es decir, en el pasado.",
  FIRST_NAME: "Nombre",
  SELECT_ONE_OF_THE_OPTIONS: "Selecciona por favor una de las opciones anteriores.",
  YOU_ARE_ELIGIBLE_IF: "<p>Eres elegible para recibir beneficios si tu empleador te ofrece cobertura de salud y dental.</p><p>Eres elegible para recibir beneficios a\xFAn cuando hayas renunciado a ellos.</p>",
  NO_I_AM_NOT_ELIGIBLE: "No, no soy elegible",
  YES_I_AM_ELIGIBLE: "Si, soy elegible",
  ARE_YOU_ELIGIBLE_FOR_BENEFITS: "\xBFEres elegible para beneficios?",
  IF_YOURE_NOT_SURE: "\xBFNo est\xE1s seguro de ser elegible? Contacta a nuestra oficina de administraci\xF3n para saberlo.",
  IF_ELIGIBLE_FOR_BENEFITS: "Activa tu cuenta League para desbloquear tu experiencia personalizada de salud y bienestar. Antes, inf\xF3rmanos si eres elegible para recibir beneficios.",
  SAY_HELLO_TO_LEAGUE: "Saludar a League",
  FIND_ACCOUNT: "Encontrar Cuenta",
  START_VERIFICATION: "Iniciar verificaci\xF3n",
  INVITE_EXPIRED_TEXT: "Lamentablemente, tu invitaci\xF3n ha caducado. Pero no te preocupes, s\xF3lo tienes que volver a confirmar tu identidad antes de proceder a crear una cuenta. Despu\xE9s de la verificaci\xF3n, tienes 1 hora para crear tu cuenta.",
  INVITE_EXPIRED_HEADING: "Tu invitaci\xF3n ha vencido",
  NOT_VERIFIED_TEXT: "Antes de que puedas comenzar, necesitamos m\xE1s informaci\xF3n para ayudarte a crear tu cuenta de League.",
  NOT_VERIFIED_HEADING: "Todav\xEDa no est\xE1s verificado",
  VIEW_BENEFITS: "Ver Beneficios",
  IMPORT_PLAN_DESIGN_SUCCESS: "\xA1Listo! La hoja de Google Sheet ha sido importada y el grupo beneficios ha sido actualizado",
  EXPORT_PLAN_DESIGN_SUCCESS: "\xA1Listo! Hemos exportado los beneficios y la hoja de Google Sheet ya est\xE1 lista.",
  CUSTOM_PLAN_DESCRIPTION: "Estos beneficios se exportar\xE1n a una nueva hoja de Google Sheet",
  CUSTOM_PLAN: "Plan personalizado",
  AVAILABLE_BENEFITS_DESCRIPTION: "Todos los beneficios que ya han sido agregados a este grupo se mostrar\xE1n aqu\xED.",
  EXPORT_CUSTOM_PLAN_DESCRIPTION: "Selecciona los beneficios que deseas exportar",
  EXPORT_PLAN_DESIGN_DESCRIPTION: "Exportar un dise\xF1o de plan generar\xE1 una hoja en Google Sheet con la configuraci\xF3n de beneficio existente. Puedes usar esta hoja para actualizar el plan actual y luego importarlo nuevamente a nuestra herramienta de administraci\xF3n. Tambi\xE9n puedes usarla para crear nuevos beneficios para un nuevo a\xF1o.",
  IMPORT_PLAN_DESIGN_DESCRIPTION: "Importar un dise\xF1o de plan genera categor\xEDas, planes y beneficios de una plantilla de Google Sheet. Se crear\xE1 o actualizar\xE1 un beneficio con base en si el campo ID del beneficio est\xE1 vac\xEDo o no. Importar un plan no borrar\xE1 ning\xFAn dato, solo crear\xE1 o actualizar\xE1.",
  EXPORT_BENEFIT_IDS: "Exportar con ID de los beneficios",
  EXPORT_CUSTOM_PLAN: "Exportar plan personalizado",
  EXPORT_PLAN_DESIGN: "Exportar el dise\xF1o del plan",
  INVOICE_HEADER_INVOICE_DATE: "Fecha de la factura",
  NO_ITEMS_OF_NAME: "No {name}",
  TURN_ON_IMPLICATIONS: "Al cambiar este evento de vida calificado, su configuraci\xF3n se activar\xE1.<strong>Una vez que haga clic en enviar</strong>, los miembros podr\xE1n ver/informar este tipo de evento de vida calificado en su plataforma de miembro.",
  TURN_OFF_IMPLICATIONS: "Al cambiar este evento de vida calificado, tus configuraciones se desactivar\xE1n. <strong>Una vez que hagas clic en enviar</strong>, los miembros ya no podr\xE1n ver/informar este tipo de evento de vida calificado en su plataforma de miembro.",
  ARE_YOU_SURE_TURN_QLE: "\xBFEst\xE1s seguro de que deseas cambiar este tipo de evento de vida calificado {toggle}?",
  TURN: "Cambiar {toggle}",
  TURN_THIS_QLE: "Cambiar {toggle} este evento de vida calificado",
  QLE_CONFIG_FORM_ERROR_MISSING_NON_NEX_CONFIG: "Selecciona por favor una configuraci\xF3n. Debes configurar al menos 1 de las opciones arriba mencionadas",
  QLE_CONFIG_FORM_ERROR_MISSING_HR_EMAIL: "Por favor ingresa las direcciones de correo electr\xF3nico de los destinatarios que deber\xEDan ser notificados para su aprobaci\xF3n.",
  QLE_CONFIG_FORM_ERROR_REPORTING_WINDOW: "Selecciona por favor una ventana de informe. Debes configurar al menos 1 de las opciones arriba mencionadas",
  SEND_MEMBERS_TO_MANAGE_DEPENDENTS_OR_TYPEFORM: "Configuraci\xF3n personalizada - Enviar miembros a Gestionar dependientes/Typeform (eventos de vida parciales/Inscripci\xF3n Typeform)",
  SEND_MEMBERS_TO_ENROLLMENT_IN_NEX_FULL_QLE: "Enviar a los miembros a Inscripci\xF3n en NEX (eventos de vida calificados totales)",
  WHERE_SHOULD_MEMBERS_BE_DIRECTED: "\xBFHacia d\xF3nde debe dirigirse a los miembros?",
  PASTE_TYPEFORM_URL_HERE: "Copiar URL de Typeform",
  TYPEFORM_URL_CAPS: "URL de Typeform:",
  REMOVE_CAPS: "QUITAR",
  ADD_CAPS: "AGREGAR",
  LOWERCASE_DEPENDENTS: "dependientes",
  ALLOW_MEMBERS_TO_CHANGE_DEPENDENTS: "Permitir a los miembros ",
  ENABLE_TYPEFORM: "\xBFPermitir Typeform?",
  ENABLE_MANAGE_DEPENDENTS: "\xBFPermitir gestionar dependientes?",
  CUSTOM_CONFIGURATION_BLURB: "Cuando realizas una configuraci\xF3n personalizada o parcial de un evento de vida calificado, el miembro no vuelve atr\xE1s a inscripci\xF3n en NEX. Puedes permitir gestionar dependientes (los miembros pueden agregar o quitar dependientes) y/o Typeform (los miembros actualizan sus elecciones de plan). Si permites Typeform, copia tu enlace Typeform en el siguiente campo.",
  CUSTOM_CONFIGURATION: "Configuraci\xF3n personalizada",
  PLEASE_ENTER_THE_EMAIL: "Por favor ingresa las direcciones de correo electr\xF3nico de los destinatarios que deber\xEDan ser notificados para su aprobaci\xF3n.",
  SEPARATE_MULTIPLE_EMAIL: "Separa las diferentes direcciones de correo electr\xF3nico con coma",
  HR_EMAIL: "Direcciones de correo electr\xF3nico de RR. HH.",
  REQUIRES_APPROVAL_FROM_HR: "\xBFRequiere aprobaci\xF3n de RR. HH.?",
  FULL_TIME_CLASS: "Categor\xEDa tiempo completo",
  NUMBER_OF_DAYS_ALLOWED_FOLLOWING: "N\xFAmero de d\xEDas en que est\xE1 permitido informar",
  NUMBER_OF_DAYS_ALLOWED_PRECEDING: "# de d\xEDas permitidos anteriores a la fecha del QLE",
  PRECEDING_LOWERCASE: "anterior",
  FOLLOWING_LOWERCASE: "siguiente",
  PRECEDING: "ANTERIOR",
  FOLLOWING: "SIGUIENTE",
  ALLOW_MEMBERS_TO_REPORT_THE_QLE_EVENT_DATE: "\xBFPermitir a los miembros informar {followOrPrecede} la fecha del evento QLE?",
  WHEN_A_MEMBER_REPORTS_A_QLE_TO_LEAGUE: "Cuando un miembro informa un QLE a League, ingresa la fecha de ese QLE. Puedes configurar la ventana disponible que el miembro tiene para informar del evento el d\xEDa anterior y siguiente al QLE.",
  QLE_REPORTING_WINDOW: "Ventana de informe de QLE",
  INFINITE_DAYS: "\u221E d\xEDas infinitos",
  NUMBER_OF_DAYS_MUST_BE_GREATER_THAN_0: "El n\xFAmero de d\xEDas debe ser superior a 0",
  ALLOW_INFINITE_NUMBER_OF_DAYS: "Permitir un n\xFAmero infinito de d\xEDas (\u221E)",
  QLE_CONFIG: "Config QLE",
  QLE_CONFIGURATIONS: "Configuraciones QLE",
  LEAGUE_MEMBER_EXCLUSIVE: "EXCLUSIVO PARA MIEMBROS DE LEAGUE",
  DOLLAR_OFF: "{discount} DE DESCUENTO",
  NUMBER_MINS: " \u2022 {durationValue}mins",
  PLUS_MORE: "{date} +M\xC1S",
  REGULAR_PRICE: "Reg {regularPrice}",
  LOOKING_FOR_SOMETHING_ELSE: "\xBFBuscas algo m\xE1s?",
  BROWSE_HEALTH_PROGRAM_CATEGORIES: "Navegar por las categor\xEDas de programas de salud",
  HEALTH_PROGRAMS_RECOMMENDED_FOR_YOU: "Programas de salud recomendados para ti.",
  DAY_OF_WEEK: "D\xEDa {day} de {week}",
  START_THIS_PROGRAM_TO_UNLOCK_ACTIVITIES: "\xA1Comienza este programa para desbloquear estas actividades!",
  ACTIVITIES_THIS_WEEK: "Actividades esta semana",
  MULTI_SELECT_DEFAULT_PLACEHOLDER: "Selecciona tus opciones",
  MULTI_SELECT_DEFAULT_CAN_CREATE: "\xA1Tambi\xE9n puedes escribir para crear una nueva opci\xF3n!",
  PROGRAM_ACTIVITIES: "Actividades del programa",
  PROGRAM_PROGRESS: "Progreso del programa",
  VIEW_PROGRAM_ACTIVITIES: "Ver las actividades del programa",
  VIEW_MY_PROGRESS: "Ver Mi progreso",
  DAY_NUMBER: "D\xCDA {number}",
  BACK_TO_HEALTH: "Volver a Salud",
  LETS_CHAT: "Vamos a hablar",
  HAVING_TROUBLE: "\xBFTienes dificultad para encontrar el programa adecuado para ti? \xA1Habla con un <strong>Consejero de salud!</strong>",
  START_PROGRAM: "Comenzar programa",
  ACTIVE_PROGRAM: "Programa en curso",
  OUR_HEALTH_PROGRAMS_HELP: "Nuestros programas de salud contribuyen de manera proactiva al logro de tus objetivos de salud y bienestar.",
  FEATURED: "Destacados",
  CATEGORIES: "Categor\xEDas",
  LOG_IN_MANUALLY: "Conectarse manualmente",
  CONTACT_US: "Cont\xE1ctanos",
  SOMETHING_WENT_WRONG: "\xA1Algo sali\xF3 mal al tratar de conectarte! Por favor, vuelve a intentarlo o contacta a {emailLink}.",
  SORRY_LET_US_TRY_AGAIN: "Perd\xF3n, volvamos a intentarlo.",
  DEPENDENTS_REPORT_DESCRIPTION: "Este informe contiene un subconjunto de los datos que existen hoy en el informe de inscripci\xF3n y tendr\xE1 el formato de un solo dependiente por fila.",
  DEPENDENTS_REPORT: "Informe de dependientes",
  CHOOSE_FILE: "Elegir archivo",
  REQUEST_DATE: "Fecha de solicitud",
  DOCUMENT: "Documento",
  FLOW_TYPE: "Tipo de flujo",
  CARRIER_AUDITS_REPORTS_DESCRIPTION: "Completa todos los campos a continuaci\xF3n para solicitar un informe. La solicitud tomar\xE1 algo de tiempo en completarse, luego de eso, el informe se abrir\xE1 en una nueva ventana.",
  CARRIER_AUDITS_REPORT: "Informe de las auditor\xEDas de la compa\xF1\xEDa aseguradora",
  CARRIER_AUDITS: "Auditor\xEDas de la compa\xF1\xEDa aseguradora",
  DOWNLOAD_EMPLOYER_BENEFITS_REPORT: "Descargar informe de beneficios",
  EMPLOYER_BENEFITS_REPORT_DESCRIPTION: "Este informe contiene un subconjunto de los datos que existen hoy en el informe de inscripci\xF3n y tendr\xE1 el formato de un solo beneficio por usuario por fila.",
  EMPLOYER_BENEFITS_REPORT: "Informe de beneficios",
  DOWNLOAD_EMPLOYEE_DEMOGRAPHICS_REPORT: "Descargar informe sobre la demograf\xEDa de los empleados",
  EMPLOYEE_DEMOGRAPHICS_REPORT_DESCRIPTION: "Este informe brinda detalles sobre la demograf\xEDa de todos los empleados recopilada por League hasta la fecha.",
  EMPLOYEE_DEMOGRAPHICS: "Demograf\xEDa de los empleados",
  EMPLOYEE_DEMOGRAPHICS_REPORT: "Informe sobre la demograf\xEDa de los empleados",
  INFO_SUCCESSFULLY_SENT: "\xA1Informaci\xF3n enviada con \xE9xito!",
  DOWNLOAD_ENROLLMENT_PROGRESS_REPORT: "Descargar el informe de progreso de inscripci\xF3n",
  ENROLLMENT_PROGRESS_REPORT_DESCRIPTION: "Este informe brinda el estatus de la inscripci\xF3n de cada empleado recopilado por League hasta la fecha.",
  ENROLLMENT_PROGRESS: "Progreso de la inscripci\xF3n",
  ENROLLMENT_PROGRESS_REPORT: "Informe del progreso de la inscripci\xF3n",
  SORRY_REQUEST_HAS_EXPIRED: "\xA1Perd\xF3n! la ventana para esta solicitud ha vencido.",
  SORRY_ERROR_ONLOAD_PLEASE_REFRESH: "\xA1Perd\xF3n! Trata de actualizar la p\xE1gina.",
  CUSTOMIZE_YOUR_BENEFITS: "Personaliza tus planes de beneficios, realiza por favor tus elecciones completando el formulario a continuaci\xF3n. ",
  BACK_TO_MANAGE_DEPENDENTS: "Volver a Gestionar dependientes",
  CHOOSE_YOUR_BENEFITS: "Elegir tus beneficios",
  BACK_HOME: "Volver al inicio",
  NEXT_UP_REVIEW_PLANS: "Siguiente: revisar tus planes",
  REVIEW_PLANS: "Revisar planes",
  IF_YOU_DONT_HAVE_ANYONE: "Si no tienes a nadie que agregar, puedes saltar este paso.",
  LETS_GET_TO_KNOW: "\xA1Queremos conocer a tus seres queridos!",
  WHICH_PLAN_WOULD_YOU_LIKE: "\xBFA qu\xE9 plan deseas agregar un dependiente?",
  ADD_A_DEPENDENT: "Agregar un dependiente",
  FINE_PRINT_HSA: "Todos los dependientes deben vivir en Canad\xE1 (con excepci\xF3n de hijos/as y nietos/as) y depender financieramente de ti para calificar.",
  IF_YOU_ARE_CARING_FOR_A_LOVED_ONE: "Si tienes a tu cuidado un ser querido que es financieramente dependiente de ti, como un c\xF3nyuge, padre/madre, abuelo/a o nieto/a, puedes agregarlo a tu cuenta CGS en todo momento.",
  HEALTH_SPENDING_ACCOUNTS: "Cuenta gastos de salud",
  FINE_PRINT_INSURED: "Si tambi\xE9n tienes una Cuenta gastos de salud, los dependientes agregados a tus beneficios asegurados se ver\xE1n reflejados all\xED tambi\xE9n.",
  YOU_HAVE_NO_DEPENDENTS: "No tienes ning\xFAn dependiente",
  MY_DEPENDENTS: "Mis dependientes",
  DATE_WINDOW: "del <strong>{startDate}</strong> al <strong>{endDate}</strong>",
  PART_TIME_EMPLOYEES: "Empleados a tiempo parcial",
  FULL_TIME_EMPLOYEES: "Empleados de tiempo completo",
  BENEFIT_GUIDES_DESCRIPTION: "Hemos preparado una descripci\xF3n de tu oferta de beneficios en esta gu\xEDa simple y de lectura f\xE1cil. Recomendamos usarla como referencia durante tu inscripci\xF3n.",
  ENROLLMENT_WELCOME_DESCRIPTION: "A trav\xE9s de este proceso de inscripci\xF3n te guiaremos por las opciones de tu plan para que puedas comprender totalmente lo que <strong>Lush US</strong> te ofrece.",
  ENROLLMENT_WELCOME: "Bienvenido a la experiencia de inscripci\xF3n de Lush US",
  READY_TO_ENROLL: "\xBFListo para inscribirte en beneficios?",
  UNTIL_WHEN_CAN_I_ENROLL: "Tienes hasta <strong>{enrollmentEndDate}</strong> para enviar tus elecciones de beneficios. Si no completas este proceso de inscripci\xF3n, tus elecciones del a\xF1o del plan {prevPlanStartYear}-{prevPlanEndYear} no se transferir\xE1n y no estar\xE1s cubierto durante el a\xF1o del plan {planStartYear}-{planEndYear}. <strong>Por favor, aseg\xFArate de presentar tus elecciones.</strong>",
  BENEFIT_PROGRAM_DESCRIPTION: "Lush se complace en ofrecerte un programa de beneficios mejorado. Se trata de una inscripci\xF3n <strong>ACTIVA</strong> lo que significa que si no completas la inscripci\xF3n, no se transferir\xE1 ninguna elecci\xF3n anterior y no estar\xE1s cubierto para el a\xF1o del plan {planStartYear}-{planEndYear}. Este proceso autoguiado solo te llevar\xE1 unos minutos",
  PART_TIME_EMPLOYEES_BENEFIT_GUIDES: "Gu\xEDas de beneficios para empleados de medio tiempo",
  FULL_TIME_EMPLOYEES_BENEFIT_GUIDES: "Gu\xEDas de beneficios para empleados de tiempo completo",
  BENEFIT_GUIDES: "Gu\xEDas de beneficios",
  WE_ARE_HERE_TO_HELP: "Estamos aqu\xED para ayudar",
  BENEFIT_PLAN_YEAR: "A\xF1o del plan de beneficios",
  GENERAL_INFO: "Informaci\xF3n general",
  BETA: "BETA",
  FINE_PRINT: "Si tambi\xE9n tienes una Cuenta gastos de salud, los dependientes agregados a tus beneficios asegurados se ver\xE1n reflejados all\xED tambi\xE9n.",
  YOU_CAN_ADD_A_DEPENDENT: "Puedes agregar un dependiente a tus beneficios asegurados (como m\xE9dico, visi\xF3n, dental, vida, etc). si pasas por un evento de vida calificado como casarte o tener un hijo/a.",
  INSURED_BENEFIT_DETAILS: "Detalles del beneficio asegurado",
  CLICK_HERE_FOR_HELP: "Haz clic aqu\xED para recibir ayuda.",
  LIVE_CHAT_DESCRIPTION: "\xA1Puedes hablar en l\xEDnea con nuestros agentes desde el escritorio de tu dispositivo!",
  LIVE_CHAT_TITLE: "Hablar-Chat en l\xEDnea",
  HELP_CENTER_DESCRIPTION: "Tambi\xE9n puedes visitar nuestro {helpCenter} en donde encontrar\xE1s respuesta a las preguntas m\xE1s comunes.",
  HELP_CENTER: "Centro de ayuda",
  CONTACT_AGENTS_DESCRIPTION: "Puedes contactar a nuestros agentes de atenci\xF3n al cliente al correo electr\xF3nico {email}",
  CONTACT_AGENTS_TITLE: "Correo electr\xF3nico",
  HELP_MODAL_DESCRIPTION: "Tanto si es una pregunta sobre tu cobertura como si algo no est\xE1 funcionando como esperabas, \xA1queremos que nos cuentes!",
  HELP_MODAL_HEADER: "Nuestro equipo est\xE1 aqu\xED para ayudarte.",
  SELECTION_REASON_DISCLAIMER: "Tu salud es importante y {employerName} quiere asegurarse de que est\xE1s cubierto. El plan que seleccionaste ofrece muy poco o nada de cobertura y necesitamos que certifiques que tienes una cobertura alternativa por medio del seguro de tu c\xF3nyuge o pareja.",
  OPT_OUT_BONUS_PER_PAY_PERIOD: "\xBFYa tienes una cobertura? {employerName} te ofrecer\xE1 un <strong>{optOutBonusAmount}{periodType}</strong> extra si renuncias a la cobertura.",
  OPT_OUT_BONUS: "\xBFYa tienes una cobertura? {employerName} te ofrecer\xE1 un <strong>{optOutBonusAmount}</strong> extra si renuncias a la cobertura.",
  OPT_OUT_DOCUMENTS_LINK_LABEL: "Ver instrucciones sobre c\xF3mo enviar una prueba de cobertura",
  OPT_OUT_DOCUMENTS: "Para renunciar a la cobertura, tambi\xE9n debes enviar una prueba de cobertura. <a>Puedes ver aqu\xED las instrucciones de c\xF3mo hacerlo</a>.",
  OPT_OUT_REASON_PLACEHOLDER_USA: "Haznos saber la raz\xF3n por la cual renuncias a la cobertura",
  OPT_OUT_ACKNOWLEDGED_LABEL_USA: "Si, quiero renunciar a la cobertura {benefitName}",
  OPT_OUT_DISCLAIMER_USA: "Si eliges renunciar a tu cobertura, no podr\xE1s inscribirte nuevamente hasta el pr\xF3ximo periodo anual de inscripci\xF3n, salvo que t\xFA pases o uno de tus dependientes elegibles pase por un evento de vida calificado.",
  OPT_OUT_HEADING_USA: "\xBFEst\xE1s seguro de que deseas renunciar a la cobertura {benefitName}? ",
  REASON_FOR_WAIVING_PLACEHOLDER: "Por favor, especifica la compa\xF1\xEDa aseguradora y el n\xFAmero de p\xF3liza de tu cobertura.",
  WAIVE_ACKNOWLEDGED_LABEL: "Si, tengo una cobertura alternativa",
  REASON_FOR_WAIVING_DISCLAIMER: "Tu salud es importante y {employerName} quiere asegurarse de que est\xE1s cubierto. Para poder renunciar a este beneficio, debes certificar que tienes una cobertura alternativa por medio del seguro de tu c\xF3nyuge o pareja.",
  REASON_FOR_WAIVING_HEADING: "\xBFTienes alguna cobertura alternativa?",
  MISSING_QLE_DATA: "Falta informaci\xF3n sobre el evento de vida calificado.",
  DATE_OF_MARRIAGE: "Fecha de casamiento",
  SOME_EXCEPTIONS_APPLY_SEE_ASTERISK: "Se aplican algunas excepciones. Los campos marcados con un asterisco<em>*</em>son obligatorios.",
  NEXT: "Siguiente",
  BACK: "Atr\xE1s",
  HOWEVER_STILL_QUALIFY: "Sin embargo, puede que todav\xEDa califiques. Solo necesitaremos m\xE1s informaci\xF3n para comenzar. Un agente te espera para ayudarte.",
  YOU_ARE_OUTSIDE: "Est\xE1s fuera de la ventana disponible para este evento de vida.",
  TOTAL_ALLOCATION_FOR_THIS_PERIOD_TOOLTIP: "Esta es la suma de la asignaci\xF3n de tu inscripci\xF3n anterior y tu nueva asignaci\xF3n.",
  TOTAL_ALLOCATION_FOR_THIS_PERIOD: "Asignaci\xF3n total para este periodo de p\xF3liza",
  LEARN_MORE_ABOUT_PRORATED_FUNDS: "Conoce m\xE1s sobre fondos prorrateados",
  THIS_AMOUNT_IS_PRORATED: "Este monto es prorrateado",
  NEW_ALLOCATION: "Nueva asignaci\xF3n",
  QLE_CONTRIBUTIONS_CHANGE_DISABLED: "No puedes cambiar tu monto de contribuci\xF3n para esta cuenta durante un QLE.",
  NO_FLEX_DOLLAR_REMAINING: "En este momento no tienes {flexDollarTerm} restantes.",
  NO_FLEX_DOLLAR_TO_ADD: "En este momento no tienes {flexDollarTerm} para agregar a esta cuenta.",
  QLE_ALLOCATION_TOOLTIP_NOTE: "Nota: este no es el saldo en tiempo real de tu cuenta, ninguna transacci\xF3n realizada se reflejar\xE1 en este monto.",
  PREVIOUS_ENROLLMENT_ALLOCATION_TOOLTIP: "Este es el monto total que asignaste a esta cuenta en tus inscripciones anteriores.",
  PREVIOUS_ENROLLMENT_ALLOCATION: "Asignaci\xF3n de la inscripci\xF3n previa",
  PRORATED_VALUE: "Valor prorrateado: ",
  PRORATED_CONTRIBUTOR_CONTRIBUTION_EMPLOYER: "Contribuci\xF3n prorrateada del empleador",
  PRORATED_CONTRIBUTOR_CONTRIBUTION_EMPLOYEE: "Tu contribuci\xF3n prorrateada",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYEE_TO_BENEFIT: "Tu contribuci\xF3n a {benefitName} de {vendorName}",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYER: "Contribuci\xF3n del empleador",
  CONTRIBUTOR_CONTRIBUTION_EMPLOYEE: "Tu contribuci\xF3n",
  CONTRIBUTIONS: "Cuentas con ventajas fiscales",
  QUESTION_X_OF_Y: "PREGUNTA {x} DE {y}",
  SOME_EXCEPTIONS_APPLY: "Hay algunas excepciones.",
  QLE_MUST_BE_REPORTED_IN_TIME: "Tienes un tiempo determinado para informar de un evento de vida calificado y completar tus elecciones de plan.",
  WHICH_APPLIES_TO_YOU: "\xBFCu\xE1l se aplica a tu caso?",
  CHECK_IF_I_QUALIFY: "Verificar si califico",
  WHEN_COVERAGE_TAKE_EFFECT: "\xBFCu\xE1ndo entra en vigencia la nueva cobertura?",
  WHAT_IS_A_QLE: "\xBFQu\xE9 es un evento de vida calificado?",
  IF_YOU_DID_NOT_EXPERIENCE_A_QLE: "Si has vivido otro tipo de evento ",
  IF_YOU_EXPERIENCED_A_CERTAIN_LIFE_EVENT: "Si has vivido un determinado evento como casarte o tener un beb\xE9, eres elegible para realizar cambios a tus elecciones de plan.",
  LIFE_IS_UNPREDICTABLE: "La vida es impredecible pero tus beneficios no tienen por qu\xE9 serlo",
  REPORT_DATE_REQUIRED_ERROR_COPY: "Por favor, selecciona una fecha para generar el informe.",
  REPORT_FUTURE_DATE_ERROR_COPY: "Selecciona por favor la fecha actual o una fecha pasada.",
  CHOOSE_DATE: "Elegir fecha",
  REQUEST_REPORT: "Solicitar informe",
  COVERAGE_SUMMARY_UNAVAILABLE_MESSAGE: "Este grupo no est\xE1 (\xA1todav\xEDa!) en la etapa de inscripci\xF3n por lo que no podemos producir un resumen de los detalles de tus beneficios. Por favor, mira la pesta\xF1a Beneficios para ver en qu\xE9 beneficios est\xE1 inscrito este usuario.",
  FILE_DOWNLOAD: "Descargar archivo",
  YEAR_LOWERCASE: "a\xF1o",
  MONTH_LOWERCASE: "mes",
  DAY_LOWERCASE: "d\xEDa",
  YEAR: "A\xF1o",
  MONTH: "Mes",
  DAY: "D\xEDa",
  FINANCE_INVOICING: "Facturaci\xF3n",
  FINANCE_REPORTS: "Informes financieros",
  WAS_THE_LAST_DAY_TO_SUBMIT: "El {endDate} a las {time}{timeZone} fue el \xFAltimo d\xEDa para enviar tus elecciones.",
  YOU_HAVE_UNTIL_THIS_DATE_TO_COMPLETE_YOUR_ENROLLMENT: "Tienes hasta el {endDate} a las {time} {timeZone} para enviar tus elecciones.",
  TODAY_IS_YOUR_LAST_DAY_TO_SUBMIT: "\xA1Hoy es tu \xFAltimo d\xEDa!",
  YOU_HAVE_DAYS_LEFT: "\xA1Te restan <strong>{daysLeft} d\xEDas</strong>!",
  INVOICE_STATUS_PARTIALLY_PAID: "Parcialmente pagado",
  INVOICE_STATUS_OUTSTANDING: "Resoluci\xF3n",
  NEW: "Nuevo",
  ADD_NEW_BENEFIT_SET: "+ Agregar nuevo conjunto",
  BENEFIT_SETS_EMPTY_2: "Agregar un conjunto usando el bot\xF3n de la esquina superior derecha.",
  BENEFIT_SETS_EMPTY_1: "Todav\xEDa no tiene ning\xFAn conjunto configurado.",
  BENEFIT_SETS_BLURB: "Los beneficios est\xE1n organizados por tipo de beneficios agregados al conjunto. Podr\xE1s definir esto cuando edites o agregues un nuevo conjunto.",
  NO_INVOICES_BY_DIVISION_MESSAGE: "No hay facturas seleccionadas para las divisiones de facturaci\xF3n.",
  SEARCH: "Buscar",
  ERROR_RENEWING_PLAN: "Se produjo un error durante la renovaci\xF3n del plan: {error}",
  PLAN_END_DATE: "Fecha de finalizaci\xF3n",
  PLAN_START_DATE: "Fecha de inicio",
  PLAN_RENEWED_SUCCESSFULLY: "Plan renovado con \xE9xito",
  RENEW_PLAN_DESCRIPTION: "Se crear\xE1 un nuevo plan con la misma configuraci\xF3n, archivos adjuntos y configuraci\xF3n de inscripci\xF3n que el plan actual {benefitPlanName}. Los beneficios del plan anterior y del nuevo ser\xE1n autom\xE1ticamente relacionados para ayudar con renovaciones pasivas y con las reinversiones de las cuentas de gastos.",
  RENEW_PLAN_HEADER: 'Renovar el plan "{benefitPlanName}"',
  RENEW: "Renovar",
  ERROR_COPYING_PLAN: "Se produjo un error al momento de copiar el plan: {error}",
  PLAN_COPIED_SUCCESSFULLY: "Plan copiado con \xE9xito",
  CHOOSE_A_BENEFIT_CLASS: "Elige una categor\xEDa de beneficios",
  COPY_PLAN_BENEFIT_CLASS_SELECT_LABEL: "\xBFA que categor\xEDa de beneficio deber\xEDa ser copiado el plan?",
  COPY_PLAN_DESCRIPTION: "Se crear\xE1 un nuevo plan con la misma configuraci\xF3n, archivos adjuntos y configuraci\xF3n de inscripci\xF3n que el plan actual {benefitPlanName}. Aseg\xFArate por favor de actualizar el nuevo plan en consecuencia.",
  COPY_PLAN_HEADER: "Copiar plan {benefitPlanName}",
  FORM_VALIDATION_WARNING_HEADER: "Encontramos<strong>{warningCount, plural, one {# warning} other {# warnings}}</strong> con esta<strong>{formTitle}</strong>",
  FORM_VALIDATION_ERROR_HEADER: "Encontramos <strong>{errorCount, plural, one {# error} other {# errors}}</strong> con esta<strong>{formTitle}</strong>",
  VALIDATION_WARNING_WARNINGS: "\u26A0\uFE0F {warningCount, plural, one {# warning} other {# warnings}}",
  VALIDATION_WARNING_ERRORS: "\u{1F6D1} {errorCount, plural, one {# error} other {# errors}}",
  VALIDATION_WARNINGS_HEADER: "\xA1Espera! Hemos encontrado ciertos problemas potenciales en esta configuraci\xF3n de plan:",
  NO_VALIDATION_WARNINGS_MESSAGE: "\u2705 \xA1No hay advertencias de validaci\xF3n, listos para iniciar la inscripci\xF3n!",
  ENROLLMENT_COMPLETE: "inscripci\xF3n completada",
  ENROLLMENT_STARTED: "inscripci\xF3n iniciada",
  START_ENROLLMENT_SERVER_ERROR: "Error al iniciar la inscripci\xF3n: {reason}",
  ENROLLMENT_STARTED_SUCCESSFULLY: "La inscripci\xF3n se inici\xF3 con \xE9xito",
  OPEN_ENROLLMENT_TEXT: "Est\xE1s por iniciar la inscripci\xF3n para estos miembros de {employerName}:",
  CONFIRM_START_ENROLLMENT: "Confirmar el inicio de la inscripci\xF3n",
  NO_DEFAULT_BENEFITS_IN_PLAN: "No hay beneficios por defecto en el plan",
  DEFAULTS_IN_MULTIPLE_BUNDLES_WARNING: "\xA1Oh! tienes beneficios por defecto en m\xE1s de un conjunto.",
  DOWNLOADING: "Descargando...",
  VIEW_DEFAULT_PLAN: "Ver plan por defecto",
  EXPORT_CONFIGURATION: "Configuraci\xF3n de exportaci\xF3n",
  SET_ENROLLMENT_DATES_SUCCESS_MESSAGE: "Fechas de inscripci\xF3n guardadas",
  NEW_EMPLOYEE_ENROLLMENT_DURATION: "Nueva duraci\xF3n de la inscripci\xF3n del empleado (d\xEDas)",
  ENROLLMENT_DATES_WARNING: "En este momento no permitimos eliminar las fechas de inscripci\xF3n una vez que han sido guardadas, \xBFest\xE1s seguro de querer agregar estas fechas?",
  ADD_ENROLLMENT_DATES: "Agregar fechas de inscripci\xF3n",
  ADDING_ENROLLMENT_DATES: "Agregando fechas de inscripci\xF3n",
  EMPLOYER_TIME_ZONE: "Nota: esta fecha y hora han sido configuradas tomando en cuenta la zona horaria del empleador, la cual es <strong>{timezone}</strong>",
  START_PLAN_CONFIGURATION: "Comenzar la configuraci\xF3n del plan",
  MISSING_PLAN_OPTIONS: "Comienza aqu\xED para crear la configuraci\xF3n de este nuevo plan",
  CONFIGURATION: "Configuraci\xF3n",
  GROUP_OPTIONS: "Opciones de grupo",
  BENEFIT_SETS_PLANS_EMPTY_2: 'Agrega un plan en la pesta\xF1a "Dise\xF1o de plan".',
  BENEFIT_SETS_PLANS_EMPTY_1: "A\xFAn no tienes planes configurados.",
  BENEFIT_SETS_PLANS_INFO_BOX: "\xA1Crear conjuntos de beneficios ser\xE1 mucho m\xE1s f\xE1cil si tienes todas las categor\xEDas, planes y beneficios definidos con anticipaci\xF3n!<br /> <strong>Restricci\xF3n: no podr\xE1s configurar este grupo si tienes m\xE1s de un plan por categor\xEDa.</strong>",
  PICKED_BENEFITS_BLURB: "Estos beneficios se mostrar\xE1n al usuario en el orden que definas.",
  BENEFIT_SETS_PLANS_BLURB: "Los conjuntos de beneficios te brindan la flexibilidad para configurar el modo en que deseas que los beneficios se muestren durante la inscripci\xF3n. Las categor\xEDas configuradas en la secci\xF3n Dise\xF1o del plan se mostrar\xE1n a continuaci\xF3n.",
  BENEFIT_SETS_TOOLTIP: "Un conjunto de beneficios es un grupo de beneficios al cual puedes aplicar una configuraci\xF3n espec\xEDfica.<br /><br /><strong>Ejemplo:</strong><br /><br />un conjunto de <em>beneficios m\xE9dicos</em> incluir\xEDa todos los beneficios m\xE9dicos entre los cuales el usuario puede elegir y c\xF3mo puede interactuar con sus opciones.",
  BENEFIT_SET: "Conjunto de beneficios",
  BENEFIT_SETS: "Conjuntos de beneficios",
  SET_EMPLOYER_BENEFIT_SET_SUCCESS_MESSAGE: "\xA1Bien hecho! Conjunto de beneficios guardado.",
  BENEFIT_SET_SAVE: "Guardar conjunto",
  AVAILABLE_BENEFITS_BLURB: "Los beneficios de otra categor\xEDa o beneficios que ya han sido agregados al conjunto no se mostrar\xE1n en esta lista",
  AVAILABLE_BENEFITS: "Beneficios disponibles",
  ADDING_BENEFITS_BLURB: "Los beneficios agregados en esta secci\xF3n se mostrar\xE1n al usuario",
  ADDING_BENEFITS: "Agregando beneficios",
  UPLOAD_BENEFIT_SET_DOCUMENT_BLURB: "Todo documento cargado aqu\xED se mostrar\xE1 al usuario a nivel del conjunto de beneficios",
  UPLOAD_A_FILE: "un archivo para cargar",
  BROWSE: "busca",
  DRAG_A_FILE_HERE: "Arrastra un archivo aqu\xED o",
  BENEFIT_SET_WAIVABLE_NEITHER: "Ninguna, \xA1es una cuenta de gastos!",
  BENEFIT_SET_WAIVABLE_TOOLTIP: "Esto afectar\xE1 si el usuario ve o no la opci\xF3n de anular la cobertura.<br /><br /><strong>S\xED:</strong> el usuario ver\xE1 la opci\xF3n de inscribirse o de anular<br /><strong>No:</strong> al usuario se le indicar\xE1 que es un beneficio obligatorio y que debe elegir esa opci\xF3n<br /><strong>Ninguna:</strong> solo para cuentas de gastos",
  BENEFIT_SET_WAIVABLE_LABEL: "\xBFPuede el usuario anular la cobertura?",
  BENEFIT_SET_NAME_TOOLTIP: "Esto afectar\xE1 el nombre de la p\xE1gina durante la inscripci\xF3n y el nombre del conjunto. <br /><br /><strong>Nota:</strong> no puedes haber varios conjuntos del mismo tipo.",
  BENEFIT_SET_NAME_LABEL: "\xBFQu\xE9 tipo de beneficios tendr\xE1 este conjunto?",
  BENEFIT_SET_DETAILS_BLURB: "Estos detalles afectan lo que se mostrar\xE1 al usuario a medida que realiza su inscripci\xF3n.",
  BENEFIT_SET_DETAILS: "Configurar detalles",
  UPLOADING_ENROLLMENT_DOCUMENT_SUCCEEDED: "Documento cargado",
  ERROR_UPLOADING_ENROLLMENT_DOCUMENT: "Se produjo un error al guardar el documento, por favor, vuelve a intentarlo.",
  PLAN_OPTIONS_SAVE_ERROR: "Error al guardar las opciones de plan",
  PLAN_OPTIONS_SAVED_SUCCESS: "\xA1Opciones de plan guardadas con \xE9xito!",
  NEW_BENEFIT_SET: "Nuevo conjunto de beneficios",
  PRICING_DESIGN_BLURB: "Esta secci\xF3n te permitir\xE1 configurar los precios de los beneficios del empleador.",
  ENROLLMENT_DESIGN_BLURB: "Esta secci\xF3n te permitir\xE1 dise\xF1ar la experiencia de inscripci\xF3n de {employerName} con base en los beneficios que configuraste en la secci\xF3n de dise\xF1o del plan.",
  REQUIRED_FIELDS: "Campos obligatorios",
  INVOICE_TABLE_COLLAPSE: "Colapsar",
  INVOICE_TABLE_EXPAND: "Ampliar",
  INVOICE_TABLE_AMOUNT_OWING: "Monto adeudado",
  TOTAL_AMOUNT: "Monto total",
  SA: "CG",
  HRA: "HRA",
  LDSA: "LDSA",
  FSA: "CGF",
  PSA: "CGB",
  CGA_AMOUNT: "Monto CGA",
  CHARITY_AMOUNT: "Monto instituciones de caridad",
  SA_AMOUNT: "Monto CG (Cuenta Gastos)",
  HRA_AMOUNT: "Monto HRA (Acuerdo de reembolso de gastos m\xE9dicos)",
  LDSA_AMOUNT: "Monto LDSA",
  FSA_AMOUNT: "Monto CGF (Cuenta Gastos Flexibles)",
  PSA_AMOUNT: "Monto CGB",
  HSA_AMOUNT: "Monto CGS",
  SUBMITTED_DATE: "Fecha de env\xEDo",
  TIME_SELECTOR_TEXT_2: " hora del Este",
  TIME_SELECTOR_TEXT_1: " en ",
  AUTHOR_ON_DATE: "Por {author} el {date}",
  EDIT_CLAIM_LINK_TEXT: "editar tu reclamaci\xF3n.",
  EDIT_CLAIM_PROVIDE_MORE_INFO: "Para brindar m\xE1s informaci\xF3n,",
  TOTAL_OUTSTANDING_ERROR: "Perd\xF3n, se produjo un error al recibir el pendiente total",
  TOTAL_OUTSTANDING: "PENDIENTE TOTAL",
  CLAIM_LIST_NEEDS_MORE_INFO: "Tienes reclamaciones que necesitan informaci\xF3n adicional.",
  CHAT_WITH_AN_AGENT: "Hablar por chat con un agente",
  CANCEL_CLAIM: "Cancelar reclamaci\xF3n",
  EDIT_CLAIM: "Editar reclamaci\xF3n",
  VIEW_ALL: "Ver todo",
  SUBMIT_CLAIM: "Enviar reclamaci\xF3n",
  NO_CLAIMS_INFO: "Una vez que realices reclamaciones, aparecer\xE1n listadas aqu\xED y podr\xE1s verificar tu estatus.",
  NO_CLAIMS: "No hay reclamaciones",
  CHILD_HAS_COVERAGE: "Tu hijo/a tiene <strong>{amount}</strong> de cobertura b\xE1sica de vida incluidos en tu plan. Cualquier cobertura de {benefitName} se agregar\xE1 a la cobertura existente.",
  SPOUSE_HAS_COVERAGE: "Tu c\xF3nyuge tiene <strong>{amount}</strong> de cobertura b\xE1sica de vida incluidos en tu plan. Cualquier cobertura de {benefitName} se agregar\xE1 a la cobertura existente.",
  CLAIM_STATUS: "Estatus de reclamaci\xF3n",
  BACK_TO_WALLET: "Volver a la cartera",
  BACK_TO_CLAIMS: "Volver a reclamaciones",
  EMPLOYEE_NO_DEPENDENTS: "Este empleado no tiene dependientes",
  EMPLOYEE_INACTIVE_DEPENDENTS: "Desactivar dependientes",
  EMPLOYEE_ACTIVE_DEPENDENTS: "Activar dependientes",
  CHANGE_IN_LOCATION: "Cambio de localidad",
  DEATH: "Muerte",
  SEPARATION: "Separaci\xF3n",
  DIVORCE: "Divorcio",
  ADOPTION: "Adopci\xF3n",
  BIRTH: "Nacimiento",
  ENDING_COMMON_LAW_RELATIONSHIP: "Final de una pareja de hecho",
  BECAME_COMMON_LAW: "Inicio de una pareja de hecho",
  MARRIAGE: "Casamiento",
  DEPENDENT_LOST_COVERAGE: "Un dependiente pierde cobertura",
  DEPENDENT_GAIN_COVERAGE: "Un dependiente obtiene cobertura",
  REASON_FOR_ADDING: "Raz\xF3n para agregar",
  EFFECTIVE_DATE: "Fecha de inicio",
  QUALIFYING_LIFE_EVENT: "Evento de vida calificado",
  CLAIM_REF_NUMBER: "Reclamaci\xF3n #",
  LAST_UPDATED_LOWERCASE: "\xDAltima actualizaci\xF3n",
  LAST_UPDATED: "\xDAltima Actualizaci\xF3n",
  DOWNLOAD_PLAN_COMPARISON: "Descargar la comparaci\xF3n de planes",
  BUNDLES_NO_SELECTION_ERROR_MESSAGE: "<strong>\xA1Espera un momento!</strong> Necesitas elegir un plan antes de revisarlo.",
  BUNDLE_SELECTOR_CARD_DESCRIPTION: "{employerName} te da la posibilidad de elegir entre los planes descritos a continuaci\xF3n. Te recomendamos descargar el documento de comparaci\xF3n de planes y tomarte tiempo para considerar qu\xE9 plan tiene m\xE1s sentido para ti. Tendr\xE1s la oportunidad de revisar los detalles del plan seleccionado m\xE1s adelante luego de realizar tu elecci\xF3n.",
  BUNDLE_SELECTOR_CARD_TITLE: "Comparaci\xF3n de planes",
  BUNDLE_SELECTOR_CARD_HEAD: "\xBFCu\xE1l es el plan adecuado para ti?",
  BUNDLE_SELECTION_SUBHEAD: "Tienes la opci\xF3n de elegir entre los planes descritos a continuaci\xF3n. Selecciona el plan m\xE1s adecuado para ti.",
  BUNDLE_SELECTION_HEAD: "Selecci\xF3n de plan",
  VIEW_INSTRUCTIONS: "Ver instrucciones",
  VIEW_MORE_DETAILS_SENTENCE_CASE: "Ver m\xE1s detalles",
  VIEW_MORE_DETAILS: "Ver M\xE1s Detalles",
  VIEW_LESS_DETAILS: "Ver Menos Detalles",
  TYPE_REQUIRED: "Se requiere un tipo",
  LABEL_REQUIRED: "Se requiere una etiqueta",
  VALUE_REQUIRED: "Se requiere un valor",
  VALID_URL_REQUIRED: "Se requiere un URL v\xE1lido",
  VALID_PHONE_NUMBER_PLUS: "Debe tener al menos un +",
  VALID_PHONE_NUMBER_CHARACTERS: "Solo debe tener caracteres 0123456789+",
  VALID_PHONE_NUMBER_LONG: "Debe tener un m\xE1ximo de 15 d\xEDgitos",
  VALID_PHONE_NUMBER_SHORT: "Debe tener al menos 4 d\xEDgitos",
  EDIT_WALLET_BUTTON: "Editar detalles de la cartera",
  BENEFIT_WALLET_ACTION_VALUE_TOOLTIP: "El valor que est\xE1 asociado a la acci\xF3n. <br>Como nota, aseg\xFArate de incluir el c\xF3digo de \xE1rea para los n\xFAmeros de tel\xE9fono y la direcci\xF3n en la red completa para los URL.",
  BENEFIT_WALLET_ACTION_TYPE_TOOLTIP: "El tipo informa al sistema c\xF3mo tratar esta acci\xF3n en la cartera seg\xFAn sea un URL o un n\xFAmero de tel\xE9fono.",
  BENEFIT_WALLET_ACTION_LABEL_TOOLTIP: "La etiqueta es lo que se muestra al usuario en la cartera para esta acci\xF3n",
  REMOVE_WALLET_ACTIONS_MODAL_BODY: "Si eliminas esta acci\xF3n, no estar\xE1 disponible en la cartera del usuario para este beneficio.",
  REMOVE_WALLET_ACTIONS_MODAL_HEADER: "\xBFEst\xE1s seguro de que deseas eliminar esta acci\xF3n?",
  SAVE_WALLET_ACTION_ERROR_MESSAGE: "\xA1Se produjo un error!",
  SAVE_WALLET_ACTION_SUCCESS_MESSAGE: "\xA1Acci\xF3n guardada con \xE9xito!",
  REMOVE_WALLET_ACTION_ERROR_MESSAGE: "Error al eliminar la acci\xF3n",
  REMOVE_WALLET_ACTION_SUCCESS_MESSAGE: "\xA1Acci\xF3n eliminada con \xE9xito!",
  VALUE: "Valor",
  LABEL: "Etiqueta",
  SAVE: "Guardar",
  ADD_MORE_ACTION: "+ Agregar una nueva acci\xF3n",
  CANT_ADD_SECONDARY_ACTIONS: "No puedes agregar ninguna acci\xF3n secundaria hasta que no hayas agregado una acci\xF3n primaria",
  PRIMARY_ACTION: "Acci\xF3n primaria",
  BENEFIT_NAME_WALLET_ACTIONS_SUBHEADER: "Aqu\xED es donde puedes editar, agregar y configurar las acciones de la cartera de beneficios",
  BENEFIT_NAME_WALLET_ACTIONS_HEADER: "{benefitName}{vendorName} - Acciones de cartera",
  NO_SELECTED_BENEFIT: "Una vez que elijas un beneficio, podr\xE1s configurar las acciones de cartera aqu\xED",
  USAGE_INSTRUCTIONS_GONE_FOREVER: 'Si borras las instrucciones de uso configuradas, el usuario podr\xE1 ver las instrucciones de uso predeterminadas (si las hay) configuradas para el beneficio. Si no hay instrucciones de uso disponibles para el beneficio, el usuario no ver\xE1 la pesta\xF1a "Instrucciones" en la cartera.',
  USAGE_INSTRUCTIONS_ARE_YOU_SURE: "\xBFEst\xE1s seguro de que deseas borrar las instrucciones de uso?",
  USAGE_INSTRUCTIONS_ERROR: "Se produjo un error al actualizar las instrucciones de uso",
  USAGE_INSTRUCTIONS_SUCCESS: "\xA1Las instrucciones de uso han sido guardadas con \xE9xito!",
  SELECT_BENEFIT: "Seleccionar beneficio",
  NO_CONFIGURABLE_BENEFITS_SUB_HEADER: "Una vez que agregues un producto asegurado, podr\xE1s configurar los detalles de la cartera de beneficios.",
  NO_CONFIGURABLE_BENEFITS_HEADER: "<strong>\xA1Este grupo no tiene ning\xFAn tipo de beneficio configurable!</strong>",
  USAGE_INSTRUCTIONS_TITLE: "{product} ({carrier}) - Instrucciones de uso",
  USAGE_INSTRUCTIONS_EMPTY: "Una vez que elijas un beneficio, podr\xE1s configurar las instrucciones de uso aqu\xED",
  USAGE_INSTRUCTIONS: "Instrucciones de uso",
  WALLET_OVERVIEW_ERROR: "Se produjo un error al actualizar la vista general",
  WALLET_OVERVIEW_SUCCESS: "\xA1Vista general actualizada!",
  OVERVIEW_CLEAR_MODAL_CONTENT: "Si borras la vista general configurada, el usuario ver\xE1 la descripci\xF3n de la vista general predeterminada y los detalles de cobertura determinados para este beneficio.",
  OVERVIEW_CLEAR_MODAL_HEADER: "\xBFEst\xE1s seguro de que deseas borrar la descripci\xF3n de la vista general?",
  OVERVIEW_UPDATE_MODAL_CONTENT: "<strong>Nota para beneficios asegurados:</strong>Configurar una vista general personalizada tambi\xE9n ocultar\xE1 los detalles de la cobertura para este beneficio en la cartera. Esto significa que los usuarios no ver\xE1n ninguna informaci\xF3n sobre la cobertura que ha sido configurada para este beneficio (si la hay).",
  OVERVIEW_UPDATE_MODAL_HEADER: "\xBFEst\xE1s seguro de que deseas actualizar la Vista general para {productName}?",
  OVERVIEW_INFO_BOX: "Recomendaci\xF3n: Configure una vista general para describir mejor los programas de la empresa.",
  OVERVIEW_TITLE: "{product} ({carrier}) - Vista general",
  OVERVIEW_EMPTY: "Una vez que elijas un beneficio, podr\xE1s configurar la vista general aqu\xED",
  BENEFITS_WALLET_HEADER_SUB_HEADER: "Cambiar alguna de la siguiente informaci\xF3n actualizar\xE1 lo que se muestra en la tarjeta de la cartera de beneficios correspondiente. Nota por favor que todo cambio en el beneficio repercute en todas las categor\xEDas del beneficio.",
  BENEFITS_WALLET_HEADER: "Editar detalles de la cartera",
  NEXT_STEPS: "Pr\xF3ximas etapas",
  NUMBER_OF_PLANS_AVAILABLE: "{number} Planes disponibles",
  YOUR_BENEFIT_COVERAGE_PLANS: "Tus planes de cobertura {benefitName}",
  ADD_DEPENDENT: "Agregar dependiente",
  DOWNLOAD_EOI_FORM: "Descargar formulario de EOI",
  READ_LESS: "Leer menos",
  READ_MORE: "Leer m\xE1s",
  FEATURE_FLAG_UPDATED: "\xA1Indicador de caracter\xEDsticas actualizado!",
  GROUPS_COUNT: "{count} grupos",
  USERS_COUNT: "{count} usuarios",
  ON_FOR_GROUPS: "Activar para los grupos: ",
  OFF_FOR_USERS: "Desactivar para los usuarios: ",
  ON_FOR_USERS: "Activar para los usuarios: ",
  ADD_A_LIST_OF_COMMA_SEPARATED_IDS: "Agregar una lista de ID separadas por una coma",
  GROUPS: "Grupos",
  USERS: "Usuarios",
  OFF_FOR_ALL: "\xBFDesactivar para todo?",
  ON_FOR_ALL: "\xBFActivar para todo?",
  ON_OFF_FOR_ALL: "Activar/Desactivar para todo",
  FLAG_DETAILS: "Detalles del indicador: {flagId}",
  FLAG_HAS_NO_ID_WARNING: "\xA1Algo est\xE1 incorrecto! De acuerdo al soporte, este indicador no tiene ID, lo cual no debe ser. Esto requiere dedicaci\xF3n manual de parte de un ingeniero.",
  BACK_TO_FLAGS_LIST: "Volver a la lista de indicadores",
  NO_DESCRIPTION: "No hay descripci\xF3n",
  FEATURE_FLAGS: "Banderas locales",
  FINANCE: "Finanzas",
  LEGACY_ADMIN_TOOLS: "Herramienta Legacy",
  VISIT_LEGACY: "Herramienta H&W Legacy",
  GOT_IT: "Entendido",
  NO_DEPENDENT_SELECTION: "No has elegido cobertura de dependientes",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN_DOMESTICPARTNERCHILDREN: "Mis hijos, mi pareja de hecho, los hijos de mi pareja de hecho y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_CHILDREN: "Mi pareja de hecho, mis hijos y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER_DOMESTICPARTNERCHILDREN: "Mi pareja de hecho, los hijos de mi pareja de hecho y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN_CHILDREN: "Mis hijos, los hijos de mi pareja de hecho y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNERCHILDREN: "Los hijos de mi pareja de hecho y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_DOMESTICPARTNER: "Mi pareja de hecho y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_ONE_CHILD: "Mi hijo/a y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_CHILDREN: "Mis hijos/as y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE_CHILDREN: "Mi c\xF3nyuge, mi/s hijo/s y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF_SPOUSE: "Mi c\xF3nyuge y yo",
  DEPENDENT_COVERAGE_TYPE_MYSELF: "M\xED",
  DEPENDENT_COVERAGE_SELECTION_TEXT: "Para {dependentCoverageType}",
  DEPENDENT_CHECK_TEXT: "<strong>Solo verificando...</strong> Vemos que has agregado dependientes pero luego no los cubriste con ning\xFAn beneficio. Si esa fue tu intenci\xF3n, \xA1no hay problema! Si no es as\xED, ve y actualiza tus <strong>Preferencias de cobertura de dependientes</strong> para proteger a tus seres queridos.",
  COVERAGE_SUMMARY: "Resumen de la cobertura",
  INITIATE_QLE_ERROR_TOAST: "Error al iniciar el evento de vida calificado para el empleado: {reason}",
  INITIATE_QLE_SUCCESS_TOAST: "Ha comenzado la reinscripci\xF3n por evento de vida calificado para {employeeName}",
  INITIATE_QLE_TYPE_ERROR: "Debes seleccionar un evento de vida calificado",
  INITIATE_QLE_TYPE_PLACEHOLDER: "Selecciona un evento de vida calificado",
  INITIATE_QLE_TYPE_LABEL: "Tipo de evento de vida calificado",
  INITIATE_QLE_TYPE_QUESTION: "\xBFQu\xE9 clase de evento de vida calificado tuvo el usuario?",
  INITIATE_QLE_ENROLLMENT_END_DATE_ERROR: "Debes seleccionar una fecha de finalizaci\xF3n de la inscripci\xF3n",
  INITIATE_QLE_ENROLLMENT_END_DATE_LABEL: "Fecha de finalizaci\xF3n de la inscripci\xF3n por evento de vida calificado",
  INITIATE_QLE_ENROLLMENT_END_DATE_TOOLTIP: "Esto marcar\xE1 la finalizaci\xF3n del periodo de inscripci\xF3n de los usuarios. El usuario tendr\xE1 hasta las 11:59 PM (seg\xFAn la zona horaria del grupo) para hacer o actualizar sus elecciones.",
  INITIATE_QLE_ENROLLMENT_END_DATE_QUESTION: "\xBFCu\xE1ndo debe cerrar la inscripci\xF3n para este usuario?",
  INITIATE_QLE_COVERAGE_START_DATE_ERROR: "Debes seleccionar una fecha de inicio de la cobertura",
  INITIATE_QLE_COVERAGE_START_DATE_LABEL: "Fecha de inicio de la cobertura",
  INITIATE_QLE_COVERAGE_START_DATE_TOOLTIP: "La cobertura de los usuarios entrar\xE1 en vigor en esta fecha.",
  INITIATE_QLE_COVERAGE_START_DATE_NOTE: "(Nota: su cobertura anterior finalizar\xE1 a las 11:59PM hora del Este la noche anterior de esta fecha)",
  INITIATE_QLE_COVERAGE_START_DATE_QUESTION: "\xBFEn qu\xE9 fecha debe iniciar esta cobertura?",
  INITIATE_QLE_CONTENT: "Iniciar una Reinscripci\xF3n por Evento de vida calificado (QLE) le permitir\xE1 a <strong>{employeeName}</strong> volver a la etapa de inscripci\xF3n y realizar nuevas elecciones. Completa todos los campos requeridos a continuaci\xF3n.",
  INITIATE_QLE_REENROLLMENT: "Iniciar Reinscripci\xF3n por Evento de vida calificado",
  SOME_PAGES_HAVE_ERRORS: "Algunas p\xE1ginas presentan errores de los que debes ocuparte",
  FUNDS_OUT_OF_SYNC: "Estos fondos no est\xE1n sincronizados",
  FUNDS_USED: "Fondos utilizados",
  TOTAL_FUNDS: "Fondos totales",
  BALANCE_FUNDS_SUCCESS_NOTIFICATION: "Fondos compensados con \xE9xito",
  BALANCE_FUNDS_CANT_BE_UNDONE: "<strong>Nota:</strong> solo se debe compensar fondos despu\xE9s de hacer los cambios necesarios a los beneficios de {employeeName}. Esta acci\xF3n es irreversible.",
  BALANCE_FUNDS_SPLAINER: "Los fondos asignados a  los beneficios de {employeeName} vs el monto total a su disposici\xF3n no est\xE1n sincronizados. Esto quiere decir que tiene dinero sin usar que deber\xEDa ir a su CGB.  Los fondos resultantes de la compensaci\xF3n ir\xE1n a su CGB.",
  EMPLOYEE_FUNDS_UNBALANCED_BODY: "Los fondos asignados a los beneficios de {employeeName} vs el monto total a su disposici\xF3n no est\xE1n sincronizados. Se deben compensar los  fondos de {employeeName} y enviar el excedente a su CGB",
  EMPLOYEE_FUNDS_UNBALANCED: "Los fondos de {employeeName} no est\xE1n sincronizados",
  BALANCE_FUNDS: "Saldo de los fondos",
  USER_BENEFITS_RESET_FAILED: "Error al reiniciar la selecci\xF3n de beneficios del usuario",
  USER_BENEFITS_RESET_MAINTAIN_SELECTION_SUCCEEDED: "Reinicio de de beneficios del usuario y fecha de finalizaci\xF3n de la inscripci\xF3n configurada el {enrollmentEndDate}",
  USER_BENEFITS_RESET_CLEAR_SELECTION_SUCCEEDED: "Reinicio de beneficios y elecci\xF3n del usuario, fecha de finalizaci\xF3n de la inscripci\xF3n configurada el {enrollmentEndDate}",
  RESET_USER_BENEFITS_AND_MAINTAIN_SELECTION: "Reiniciar beneficios de usuario y mantener la selecci\xF3n",
  RESET_USER_BENEFITS_AND_CLEAR_SELECTION: "Reiniciar beneficios de usuario y borrar selecci\xF3n",
  RESET_USER_BENEFIT_SELECTION_SPLAINER_TEXT: "Esto reiniciar\xE1 beneficios de usuario previos y los ubicar\xE1 nuevamente en FTUX. Tendr\xE1n hasta la fecha de finalizaci\xF3n de la inscripci\xF3n para completar su selecci\xF3n y reenviar. Los nuevos beneficios de usuarios ser\xE1n asignados luego de la fecha de finalizaci\xF3n de la inscripci\xF3n.",
  YOU_MUST_SELECT_A_RESET_TYPE: "Debes seleccionar un tipo de reinicio",
  ENROLLMENT_END_DATE_IS_REQUIRED: "La fecha de finalizaci\xF3n de la inscripci\xF3n es obligatoria",
  ENROLLMENT_END_DATE: "Fecha de finalizaci\xF3n de la inscripci\xF3n",
  RESET_BENEFIT_SELECTION_CONFIRM_HEADER: "\xBFReiniciar la elecci\xF3n de beneficios para {employeeName}?",
  RESET_USER_BENEFITS: "Reiniciar beneficios de usuario",
  WELCOME: "Bienvenido",
  DONT_NEED_TO_ADD_DEPENDENTS: "Agregar dependientes a tu plan o actualizar tus dependientes no es una opci\xF3n disponible en este momento.",
  CANT_MANAGE_DEPENDENTS: "<strong>\xBFPor qu\xE9 no puedo cambiar nada?</strong> En base a tu cobertura, no necesitas agregar dependientes.",
  PRELOADED_INFO: "<strong>\xBFPor qu\xE9 no puedo cambiar nada?</strong> Esta elecci\xF3n no puede modificarse para este periodo de inscripci\xF3n. Si tienes dudas, el Equipo de cuidado de salud de League est\xE1 disponible para ayudarte.",
  ENROLLMENT_PERIOD_OVER_INFO: "<strong>\xBFPor qu\xE9 no puedo cambiar nada?</strong> Dado que perdiste el periodo de inscripci\xF3n, no puedes actualizar o cambiar tu plan de beneficios.",
  ON_DISABILITY_LEAVE_INFO: "<strong>\xBFPor qu\xE9 no puedo cambiar nada?</strong> No puedes actualizar o cambiar tu plan de beneficios mientras est\xE1s en licencia por discapacidad.",
  CONTRIBUTION_NOT_ALLOWED_INFO: "<strong>\xA1Suj\xE9tate!</strong> No eres elegible para contribuir a\xFAn en alguna de tus cuentas de bienestar, pero podr\xE1s contribuir durante tu pr\xF3ximo periodo de inscripci\xF3n.",
  SUPPORTING_DOCUMENTS_CAN_BE_THINGS: "Un documento de apoyo puede ser algo como una receta, una membres\xEDa a un gimnasio, etc. a los que regularmente se hace referencia en los procesos de adjudicaci\xF3n de reclamaciones.",
  BAD_NETWORK_CONNECTION_TEXT: "\xA1Intenta actualizar tu navegador o verifica<br /> tu conexi\xF3n internet!",
  BAD_NETWORK_CONNECTION_TITLE: "Mala conexi\xF3n a la red",
  SELECT_FILE: "Seleccionar un documento de tu escritorio",
  ERROR_ADDING_DOC_CLAIM: "Error al agregar el documento de apoyo a la reclamaci\xF3n",
  DOC_ADDED_CLAIM: "Documento de apoyo agregado a la reclamaci\xF3n",
  SUPPORTING_DOC_REMOVED_CLAIM: "Documento de apoyo retirado de la reclamaci\xF3n",
  ERROR_PINNING: "Error al fijar el documento de reclamaci\xF3n",
  CLAIM_DOC_PINNED: "\xA1Documento de reclamaci\xF3n fijado!",
  ERROR_UPLOADING_DOC: "Error al cargar los documentos de apoyo",
  ERROR_UPDATING_SUPP_DOC: "Error al actualizar los documentos de apoyo",
  DOC_SUCCESSFULLY_UPDATED: "\xA1Documentos actualizados con \xE9xito!",
  SUPPORTING_DOC_REMOVED: "Se han quitado los documentos de apoyo",
  SUPPORTING_DOC_ADDED: "\xA1Los documentos de apoyo han sido agregados con \xE9xito!",
  COVERAGE_SUMMARY_ERROR_TEXT: "<strong>Tienes etapas sin completar y/o errores.</strong> Deber\xE1s resolver estos errores antes de completar tu inscripci\xF3n.",
  INTEGRATIONS: "Integraciones",
  BENEFIT_COVERAGE: "Cobertura {benefit}",
  WHAT_TYPE: "\xBFQu\xE9 tipo de documento es este?",
  IDENTIFICATION_TYPE_TWO_FILES: "Los documentos de identificaci\xF3n solo pueden tener hasta 2 im\xE1genes de apoyo.",
  ADD_EXTRA_FILES: "Agrega archivos adicionales si es necesario",
  FULLY_VISIBLE: "Aseg\xFArate de que la imagen est\xE9 completamente visible y no est\xE9 cortada",
  MAKE_SURE_DOCUMENT: "Aseg\xFArate de que el documento...",
  TIPS_FOR_UPLOADING: "Consejos para cargar documentos",
  ADD_IMAGE: "Agrega m\xE1s p\xE1ginas a este documento (si corresponde)",
  SELECT_TYPE: "Selecciona un tipo",
  DRAG_OR_UPLOAD_PHOTO: "Carga una foto de este documento",
  DRAG_OR_UPLOAD: "Arrastra o carga un documento",
  DRAG_AND_DROP: "Arrastra y suelta un documento en esta ventana o selecci\xF3nalo desde tu escritorio.",
  WAIT_ENROLLMENT: "Tu inscripci\xF3n a {planName} abrir\xE1 cuando finalices tu inscripci\xF3n {previousPlanName}",
  CONTINUE_ENROLLMENT: "Continuar la inscripci\xF3n",
  START_ENROLLMENT: "Comenzar la inscripci\xF3n",
  PLAN_PICKER_EXPLANATION: "Este es el periodo en el cual tus beneficios ser\xE1n activos y podr\xE1n ser utilizados.",
  PLAN_PICKER_WAIT: "Tu {waitingName} se abrir\xE1 cuando finalices tu {currentName}",
  PLAN_PICKER_DEADLINE: "Tienes hasta <strong>{date}</strong> para inscribirte en este plan",
  NEW_JOB_EXPLANATION: "Dado que acabas de empezar en {employer}, tu inscripci\xF3n ser\xE1 un poco diferente que lo normal. Deber\xE1s elegir una cobertura para el periodo de p\xF3liza actual as\xED como una cobertura para el pr\xF3ximo periodo de p\xF3liza.",
  NEW_JOB_CONGRATS: "Felicitaciones por tu nuevo empleo",
  YOUR_ENROLLMENT_EXPERIENCE: "Tu experiencia de inscripci\xF3n",
  THE_TOOL: "La Herramienta",
  DECISION_SUPPORT_STATUS_ERROR_DESCRIPTION: "Intenta volver a realizar la encuesta o actualizar tu navegador.",
  DECISION_SUPPORT_STATUS_PENDING_DESCRIPTION: "Termina la encuesta de Picwell para obtener tu mejor coincidencia.",
  DECISION_SUPPORT_STATUS_COMPLETED_DESCRIPTION: "Tu mejor coincidencia aparecer\xE1 en la tabla de abajo.",
  PENDING_STATE_TITLE: "Estamos esperando tus resultados...",
  COMPLETED_STATE_TITLE: "Completado",
  HELP_ME_CHOOSE: "Ay\xFAdame a elegir",
  DECISION_SUPPORT_BANNER_DESCRIPTION: "Picwell es una herramienta con la que nos hemos asociado para ayudar a determinar qu\xE9 plan de {benefitSetName} es el m\xE1s adecuado para ti. La encuesta es an\xF3nima y s\xF3lo dura 6 minutos.",
  DECISION_SUPPORT_BANNER_HEADER: "\xBFNecesitas ayuda para elegir un plan?",
  DEPENDENT_COVERAGE_CHANGED_TO: 'Actualizamos la cobertura de tu dependiente {plan} a "{dependentCoverage}"',
  DEPENDENT_COVERAGE_PREPOPULATED: "Nota: tu elecci\xF3n para la cobertura de tu dependiente debe ser igual que tu cobertura de {benefits}. Autocompletaremos estos campos por ti.",
  PLEASE_ENTER_A_VALID_VALUE: "Por favor, introduce un valor v\xE1lido",
  UNIQUE_IDENTIFIER_MAX_LENGTH_ERROR: "Debe tener como m\xE1ximo {length} caracteres",
  UNIQUE_IDENTIFIER_MIN_LENGTH_ERROR: "Debe tener al menos {length} caracteres",
  TOO_LONG_AGO_DATE_ERROR_MESSAGE: "Selecciona una fecha posterior a 1900",
  FUTURE_DATE_ERROR_MESSAGE: "Selecciona por favor una fecha pasada.",
  INVALID_DAY_ERROR_MESSAGE: "La fecha seleccionada no es v\xE1lida.",
  INVALID_DATE_ERROR_MESSAGE: "Esta fecha no es v\xE1lida, ingresa por favor una fecha v\xE1lida.",
  FLEX_DECREASE_SUBHEADING: "La subvenci\xF3n adicional de {employerName} puede cambiar con base en tus elecciones.",
  FLEX_DECREASE_HEADING: "Tu {flexTerm} ha sido ajustada con base en la cobertura seleccionada.",
  FLEX_INCREASE_SUBHEADING: "{employerName} ha puesto a tu disposici\xF3n fondos adicionales con base en tu elecci\xF3n.",
  FLEX_INCREASE_HEADING: "\xA1Tu {flexTerm} ha aumentado con base en la cobertura seleccionada!",
  READY_TO_BUILD_YOUR_PLAN: "\xBFListo para armar tu plan?",
  RAMQ_ALT_COVERAGE_INFO: "El plan que seleccionaste no cumple con la normativa de la RAMQ. La RAMQ requiere que certifiques que tienes cobertura alternativa ya sea a trav\xE9s de tu c\xF3nyuge, pareja de hecho o por otros medios.",
  RAMQ_CONFIRM_ALT_COVERAGE: "Si, tengo cobertura alternativa",
  RAMQ_TOOLTIP_TEXT: "La RAMQ (R\xE9gie de l'assurance maladie du Qu\xE9bec) es el sistema de seguro de salud obligatorio del gobierno de Quebec. La RAMQ ofrece a los residentes de Quebec cobertura b\xE1sica de salud (medicamentos recetados) en caso de no estar cubiertos por un plan grupal elegible.",
  WHAT_IS_RAMQ: "\xBFQu\xE9 es la RAMQ?",
  RAMQ_ALT_COVERAGE_HEADING: "\xBFTienes alguna cobertura alternativa?",
  DMY_DATE_FIELD_PLACEHOLDER: "DD/MM/AAAA",
  MDY_DATE_FIELD_PLACEHOLDER: "MM/DD/AAAA",
  YMD_DATE_FIELD_PLACEHOLDER: "AAAA/MM/DD",
  USER_PROFILE_UNSAVED_ERROR_MESSAGE: "<strong>\xA1Guarda tu perfil!</strong> Debes completar y guardar el formulario antes de pasar al siguiente paso.",
  DEPENDENTS_PAGE_UNSAVED_ERROR_MESSAGE: "<strong>\xA1Guarda tus dependientes!</strong> Debes completar y guardar cada formulario antes de pasar a la siguiente etapa.",
  OPTIONAL_BENEFIT_NOT_SELECTED_ERROR: "<strong>\xA1Espera! Nos falta cierta informaci\xF3n.</strong> Por favor, selecciona un plan  o cancela la cobertura para solucionar el problema.",
  REQUIRED_BENEFIT_NOT_SELECTED_ERROR: "<strong>\xA1Espera! Nos falta cierta informaci\xF3n.</strong> Por favor, selecciona un plan para solucionar el problema.",
  RAMQ_ACKNOWLEDGEMENT_REQUIRED_ERROR: "<strong>El plan que elegiste no cumple con la normativa de la RAMQ. </strong> Avisa que tienes una cobertura alternativa al pie de esta p\xE1gina.",
  MISSING_INFORMATION_VALIDATION_ERROR_MESSAGE: "<strong>Hemos encontrado errores en esta p\xE1gina.</strong> Tendr\xE1s que corregirlos antes de pasar a la siguiente etapa.",
  REMOVE_DEPENDENT: "Borrar dependiente",
  SAVE_CHANGES: "Guardar cambios",
  DEPENDENT_FORM_TITLE: "Informaci\xF3n sobre {dependent}",
  SPOUSE_DEPENDENT_FORM_TITLE: "Informaci\xF3n sobre tu c\xF3nyuge o pareja de hecho",
  INVALID_US_SSN: "Formato de SSN no v\xE1lido (111-22-3333 or 111111111)",
  INVALID_ZIP_CODE: "Formato de c\xF3digo postal no v\xE1lido (11111 or 11111-2222)",
  INVALID_POSTAL_CODE: "Formato de c\xF3digo postal no v\xE1lido (A1A 1A1)",
  THIS_FIELD_IS_REQUIRED: "Este campo es obligatorio",
  FLEX_BALANCE_DEDUCTION_DESCRIPTION: "Estas cuentas tendr\xE1n la prioridad, as\xED que puedes gastar todos tus {flexDollarTerm} en estas cuentas y despu\xE9s tus elecciones previas ser\xE1n cubiertas por deducciones en tu n\xF3mina de pago.",
  LETS_GET_STARTED: "Comencemos",
  YOU_CAN_ALSO_VISIT_KNOWLEDGE_BASE: "Tambi\xE9n puedes visitar nuestra {knowledgeBaseLink} para m\xE1s informaci\xF3n sobre los beneficios que se te ofrecen.",
  HAVE_QUESTIONS_DESCRIPTION: "El equipo de Atenci\xF3n al cliente de League est\xE1 disponible si tienes alguna pregunta durante el proceso. ",
  HAVE_QUESTIONS: "\xBFTienes alguna pregunta?",
  NEW_HIRE_SAVING_AND_EDITING_DESCRIPTION: "Es posible que necesites tomarte un tiempo para completar esta inscripci\xF3n. No te preocupes, tus elecciones en cada etapa se van guardando, pero a\xFAn as\xED necesitas enviar tus elecciones para completar tu inscripci\xF3n.",
  PARTNER_SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS: "Puedes tomarte tu tiempo para completar tu inscripci\xF3n. No te preocupes, tus selecciones se guardan a medida que avanzas por cada secci\xF3n, pero debes enviarlas para que tengan efecto. Tambi\xE9n puedes modificar tus selecciones de plan en cualquier momento del a\xF1o, poni\xE9ndote en contacto con el Equipo de cuidado de salud de League.",
  PARTNER_SAVING_AND_EDITING_DESCRIPTION: "Es posible que quieras tomarte tu tiempo para completar esta inscripci\xF3n. No te preocupes, tus selecciones se guardan a medida que avanzas por cada secci\xF3n, pero debes enviarlas para que tengan efecto. Despu\xE9s de enviarlas, puedes volver a actualizar tus selecciones desde la p\xE1gina de inicio de la League. Puedes editar tus selecciones hasta el final de tu periodo de inscripci\xF3n ({enrollmentEndDate}). <p>Tambi\xE9n puedes modificar tu selecci\xF3n de planes en cualquier momento del a\xF1o, poni\xE9ndote en contacto con el Equipo de cuidado de salud de League.</p>",
  SAVING_AND_EDITING_DESCRIPTION_MULTIPLE_PLANS: "Es posible que necesites tomarte un tiempo para completar esta inscripci\xF3n. No te preocupes, tus elecciones en cada etapa se van guardando, pero a\xFAn as\xED necesitas enviar tus elecciones para completar tu inscripci\xF3n.",
  SAVING_AND_EDITING_DESCRIPTION: "Es posible que desees tomarte un tiempo para completar esta inscripci\xF3n. No te preocupes, tus elecciones se guardan a medida que avanzas en cada secci\xF3n, pero debes enviarlas para completar tu  inscripci\xF3n. Despu\xE9s de enviarlas, puedes volver a actualizar tus elecciones desde la p\xE1gina de inicio de League. Puedes editar tus elecciones hasta el final de tu per\xEDodo de inscripci\xF3n ( {enrollmentEndDate} ). <p> Recibir\xE1s un correo electr\xF3nico confirmando el env\xEDo de tu inscripci\xF3n. Si no recibes este correo, aseg\xFAate de volver a iniciar sesi\xF3n y enviar tus elecciones. </p>",
  SAVING_AND_EDITING: "Guardando y editando",
  NEW_HIRE_POLICY_DURATION_DESCRIPTION: "Las elecciones que realices durante el periodo de inscripci\xF3n entrar\xE1n en vigor el d\xEDa 1 del mes siguiente a tu fecha de contrataci\xF3n. Ser\xE1n v\xE1lidas hasta <strong>{planEndDate}</strong>.",
  POLICY_DURATION_DESCRIPTION: "Las elecciones realizadas durante el periodo de inscripci\xF3n se har\xE1n efectivas el primer d\xEDa del mes siguiente al env\xEDo. Estar\xE1n vigentes hasta el <strong>{planEndDate}.</strong>",
  POLICY_DURATION: "Duraci\xF3n de la p\xF3liza",
  NEW_HIRE_PARTNER_ENROLLMENT_PERIOD_DESCRIPTION: "Tienes 31 d\xEDas a partir de la fecha de contrataci\xF3n para completar y enviar tus elecciones. Tambi\xE9n puedes modificar tus selecciones del plan en cualquier momento del a\xF1o poni\xE9ndote en contacto con el Equipo de cuidado de salud de League.",
  PARTNER_ENROLLMENT_PERIOD_DESCRIPTION: "Puedes revisar y actualizar tus opciones tantas veces como quieras hasta el final del periodo de inscripci\xF3n. Si no presentas ninguna opci\xF3n, tu cobertura actual seguir\xE1 vigente.",
  NEW_HIRE_ENROLLMENT_PERIOD_DESCRIPTION: "Tienes 31 d\xEDas a partir de la fecha de contrataci\xF3n para completar y enviar tus opciones. Si no presentas ninguna opci\xF3n, se asignar\xE1 una cobertura por defecto. Una vez que las env\xEDes, no podr\xE1s hacer m\xE1s cambios. Tu pr\xF3xima oportunidad de hacer cambios ser\xEDa como resultado de un evento de vida o durante la inscripci\xF3n para el per\xEDodo de la p\xF3liza del a\xF1o siguiente.",
  ENROLLMENT_PERIOD_DESCRIPTION: "Tienes 31 d\xEDas desde la fecha de tu contrataci\xF3n para completar y enviar tus elecciones. Si no env\xEDas ninguna elecci\xF3n, se te asignar\xE1 una cobertura predeterminada. Una vez enviado, no podr\xE1s realizar ning\xFAn cambio. Tu pr\xF3xima oportunidad de hacer cambios ser\xE1 a causa de un evento de vida calificado o durante el pr\xF3ximo periodo de inscripci\xF3n de tu p\xF3liza anual.",
  ENROLLMENT_PERIOD: "Periodo de inscripci\xF3n",
  PAYROLL_DEDUCTIONS_PARTNERS: 'Este valor indica el monto que ser\xE1 cubierto como gasto de tu bolsillo. \nCuando inicies sesi\xF3n, podr\xE1s ver ya reflejado el costo de los beneficios obligatorios (seguros de vida, a largo plazo y de accidentes).\nEl monto "T\xFA pagas" cambiar\xE1 de acuerdo a tus elecciones.',
  PAYROLL_DEDUCTIONS_DESCRIPTION: 'Este valor indica el monto que ser\xE1 cubierto como un gasto de tu bolsillo. Cuando inicies sesi\xF3n, podr\xE1s ver ya reflejado el costo del Seguro de vida obligatorio.<p>El Seguro de vida, el de Discapacidad a largo plazo y el de muerte accidental y desmembramiento AD&D deben pagarse a trav\xE9s de deducciones en la n\xF3mina de pago para evitar un beneficio con impuestos al momento de pagar el beneficio.</p><p>\nEl monto "T\xFA pagas" cambiar\xE1 de acuerdo a tus elecciones. Si tus elecciones de plan m\xE9dico, de medicamentos y dental exceden el saldo del fondo com\xFAn bienestar, cualquier costo restante tambi\xE9n ser\xE1 cubierto por deducciones en la n\xF3mina de pago.</p>',
  PAYROLL_DEDUCTIONS: "Deducciones en la n\xF3mina de pago",
  BENEFIT_SUBSIDIZATION_DESCRIPTION: "KPMG reconoce los costos adicionales de inscribirte en beneficios m\xE9dicos y dentales para tu familia y, por lo tanto, otorga un subsidio adem\xE1s de tu fondo com\xFAn bienestar para apoyarte si decides inscribir alg\xFAn dependiente elegible en la cobertura de beneficios.",
  BENEFIT_SUBSIDIZATION: "Subvenci\xF3n de beneficios",
  WELLNESS_POOL_DESCRIPTION: "KPMG ha dise\xF1ado un paquete de recompensas globales para ti que est\xE1 fuertemente subsidiado a trav\xE9s de un Fondo com\xFAn bienestar. Tienes flexibilidad para elegir c\xF3mo asignar los d\xF3lares de tu fondo com\xFAn bienestar. Ver\xE1s que el saldo de tu fondo com\xFAn bienestar disminuye a medida que realizas las elecciones de plan adecuadas para tu estilo de vida.",
  KPMG_PAYS: "KPMG paga",
  OVERVIEW: "Resumen",
  WELCOME_SCREEN_SUB_HEAD_DESCRIPTION: "KPMG ofrece un paquete de recompensas globales solo para ti. En este proceso de inscripci\xF3n, decidir\xE1s entre opciones de beneficios para construir un plan flexible que responda a tus necesidades particulares. Te guiaremos a trav\xE9s de cada una de las etapas.",
  WELCOME_TO_YOUR_BENEFITS_EXPERIENCE: "Bienvenido a tu experiencia de beneficios",
  OPTIONAL_LTD: "LTD opcional",
  VISIT_LEAGUE: "Visita League",
  GET_STARTED: "\xA1Comienza con League!",
  DOWNLOAD_ANDROID: "Obtener en Google Play.",
  DOWNLOAD_IOS: "Descargar en la App Store.",
  SIGN_IN_FROM_DESKTOP: "\xA1O con\xE9ctate desde el escritorio de tu dispositivo!",
  LEAGUE_DESKTOP: "Escritorio League",
  OR_VISIT: "o visita",
  DOWNLOAD_MOBILE_APPS: "\xA1Descarga nuestras aplicaciones m\xF3viles!",
  EXPERIENCE_LEAGUE_SUBHEADING: "\xA1No tienes que esperar m\xE1s para comenzar a vivir la experiencia League! Para tener acceso a tu salud desde cualquier lugar, instala la aplicaci\xF3n League en todos tus dispositivos.",
  EXPERIENCE_LEAGUE: "Vive la experiencia League",
  ARTICLE_EXTERNAL_ACCOUNT_BENEFIT: "un {benefitName}",
  VIEW_MY_WALLET: "Ver mi cartera",
  EXTERNAL_ACCOUNT_CARD_CONTENT_MULTIPLE: "Parece que has contribuido fondos a {formattedExternalAccountBenefits}. Si todav\xEDa no lo hiciste, deber\xE1s configurar perfiles para estas cuentas. Te explicaremos c\xF3mo hacerlo en tu cartera digital League cuando se agreguen tus beneficios.",
  EXTERNAL_ACCOUNT_CARD_CONTENT_SINGLE: "Parece que has contribuido fondos a {formattedExternalAccountBenefits}. Si todav\xEDa no lo hiciste, deber\xE1s configurar un perfil para esta cuenta. Te explicaremos c\xF3mo hacerlo en tu cartera digital League cuando se agreguen tus beneficios.",
  EXTERNAL_ACCOUNT_CARD_TITLE: "Algunas cuentas todav\xEDa necesitan ser configuradas",
  TAKE_SURVEY: "Completa nuestra encuesta",
  BY_FILLING_OUT_SURVEY: "Al completar este cuestionario r\xE1pido (realmente toma solo 2 minutos), nos ayudar\xE1s a mejorar la experiencia de inscripci\xF3n para los futuros usuarios, \xA1incluso para ti durante tu pr\xF3xima inscripci\xF3n!",
  WE_WANT_TO_HEAR_FROM_YOU: "Queremos conocer tu opini\xF3n",
  VIEW_DESIGNATIONS: "Ver designaciones",
  DELETE_BENEFICIARY_WARNING_MODAL_DESCRIPTION: "Primero debes quitar la o las designaciones asociadas a este beneficiario de los siguientes beneficios:",
  DELETE_BENEFICIARY_WARNING_MODAL_HEADER: "El beneficiario no puede ser borrado",
  DELETE_BENEFICIARY: "Borrar beneficiario",
  DELETE_BENEFICIARY_CONFIRM_MODAL_DESCRIPTION: "Esta acci\xF3n es permanente e irreversible.",
  DELETE_BENEFICIARY_CONFIRM_MODAL_HEADER: '\xBFEst\xE1s seguro de que deseas borrar a "{beneficiaryName}"?',
  BENEFICIARY_CARD_WITHOUT_DESIGNATIONS: "Este beneficiario no est\xE1 designado para ning\xFAn beneficio",
  BENEFICIARY_CARD_WITH_DESIGNATIONS: "Designaciones: {benefitDesignations}",
  BENEFICIARIES_LIST_VIEW_EMPTY_STATE: 'A\xFAn no has agregado ning\xFAn beneficiario. Puedes hacerlo en la secci\xF3n Designaciones haciendo clic en "Agregar beneficiario" dentro de un beneficio.',
  BENEFICIARIES_LIST_VIEW_DESCRIPTION: "Un beneficiario es la persona o entidad que recibir\xE1 el pago de la compa\xF1\xEDa aseguradora en el desafortunado evento de tu fallecimiento. Es importante designar a tus beneficiarios para asegurarte de que tu dinero termine en las manos correctas.",
  ERROR_BENEFICIARY_ALREADY_ADDED: "{beneficiaryName} ya se agreg\xF3 a este beneficio.",
  ERROR_SAVING_BENEFICIARY_DESIGNATIONS: "Lo lamentamos pero se ha producido un error al guardar las designaciones de tus beneficiarios. {error}",
  BENEFICIARY_DESIGNATIONS_SAVED: "Tu designaci\xF3n de beneficiario/s se guard\xF3.",
  ERROR: "Error",
  SUCCESS: "\xA1Bien hecho!",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_ERROR: "Esta confirmaci\xF3n es necesaria para guardar tu(s) designaci\xF3n(es)",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_ACCEPTANCE: "He le\xEDdo lo anterior y confirmo mi(s) nueva(s) designaci\xF3n(es)",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_STATEMENT: "Revoco cualquier designaci\xF3n de beneficiario que se haya hecho anteriormente para los beneficios de Vida y/o Muerte y Desmembramiento patrocinados por el plan de mi empleador, y autorizo que las ganancias de los beneficios mencionados se paguen al beneficiario o beneficiarios nombrados en este formulario.",
  BENEFICIARY_DESIGNATIONS_ATTESTATION_HEADER: "Confirmar tu(s) nueva(s) designaci\xF3n(es) de beneficiario(s)",
  SAVE_WITHOUT_DESIGNATIONS: "\xBFEst\xE1s seguro de que deseas guardar sin designaciones para el o los siguientes beneficios?:",
  MISSING_DESIGNATIONS: "Designaciones faltantes",
  CONTINGENT_BENEFICIARIES_TOOLTIP: "Los beneficiarios contingentes son los segundos en la lista para recibir los ingresos del pago del beneficio. Los beneficiarios contingentes tienen derecho a recibir el dinero solo si ninguno de los beneficiarios primarios est\xE1 en condiciones de recibirlo.",
  PRIMARY_BENEFICIARIES_TOOLTIP: "Los beneficiarios primarios son los primeros en recibir los ingresos del pago del beneficio.",
  ORGANIZATION_DESCRIPTION: "Puedes nombrar a una entidad como beneficiario, tal como una instituci\xF3n caritativa, un fideicomiso o un patrimonio.",
  ORGANIZATION: "Organizaci\xF3n",
  PERSON_DESCRIPTION: "Puedes nombrar a cualquier persona como beneficiario.",
  PERSON: "Persona",
  BENEFICIARY_FORM_LEGEND: "Selecciona el tipo de beneficiario que deseas agregar",
  ALL_FIELDS_ARE_REQUIRED_UNLESS_STATED_AS_OPTIONAL: "Todos los campos son requeridos salvo que est\xE9n marcados como opcionales.",
  BENEFICIARY_DETAILS: "Detalles del beneficiario",
  EDIT_BENEFICIARY: "Editar beneficiario",
  ADD_BENEFICIARY: "Agregar beneficiario",
  NO_BENEFICIARIES_DESIGNATED: "No se han designado beneficiarios {designationType}",
  TOTAL_PERCENTAGE: "Porcentaje total",
  CONTINGENT: "contingentes",
  PRIMARY: "primarios",
  DESIGNATION_LIST_HEADER: "Beneficiarios {designationType}",
  DESIGNATION_SHARE_PERCENTAGE_ERROR: "Por favor, especifica un porcentaje para cada beneficiario y aseg\xFArate de que la suma de los porcentajes sea igual a 100.",
  PERCENTAGE_TOTAL_REQUIREMENT: "Debe sumar 100%",
  PERCENTAGE: "Porcentaje",
  BENEFICIARY_DESIGNATION_COUNT: "{designationCount, plural, one {# beneficiario} other {# beneficiarios}} designados",
  CREATE_NEW_BENEFICIARY: "Crear nuevo",
  ADD_BENEFICIARIES_EMPTY_TEXT: "No has agregado ning\xFAn beneficiario",
  ADD_BENEFICIARY_DROPDOWN_BUTTON_TO_BENEFIT: "Agregar beneficiario de {designationType} a {benefitName}",
  ADD_BENEFICIARY_DROPDOWN_BUTTON: "+ Agregar beneficiario",
  SAVE_AND_SUBMIT: "Guardar y enviar",
  DESIGNATING_BENEFICIARIES: "Designaci\xF3n de beneficiarios",
  DESIGNATIONS_VIEW_DESCRIPTION: "Para cada beneficio, puedes nombrar uno o m\xE1s beneficiarios primarios y, opcionalmente, uno o m\xE1s beneficiarios contingentes. Cuando nombres un beneficiario, especifica el porcentaje del pago del beneficio que debe recibir.",
  YOUR_BENEFICIARIES: "Tus beneficiarios",
  DESIGNATIONS: "Designaciones",
  RECOMMENDED_FORM: "Recomendado",
  REQUIRED_FORM: "Requerido",
  DIGITAL_BENEFICIARIES_CARD_LAST_UPDATED: "\xDAltima actualizaci\xF3n: {lastUpdated}",
  DIGITAL_BENEFICIARIES_CARD_TOTAL_BENEFICIARIES: "Tus beneficiarios {totalBeneficiaries}",
  DIGITAL_BENEFICIARIES_CARD_EMPTY: "No has designado ning\xFAn beneficiario",
  PAPER_BENEFICIARIES_CARD_INFO_BANNER: "<strong>Instrucciones:</strong> luego de completar el formulario, puedes agregarlo a la secci\xF3n <strong>Cargas</strong> de esta p\xE1gina, salvo que tu equipo de RR. HH. te haya indicado lo contrario.",
  PAPER_BENEFICIARIES_CARD_DESCRIPTION_NOTE: "Por favor, toma en cuenta que si ya enviaste un formulario de beneficiario a tu empleador actual y no deseas realizar cambios en tus designaciones, no es necesario que env\xEDes un nuevo formulario.",
  BENEFICIARIES_FORM_CARD_DESCRIPTION: "Para la seguridad de tus seres queridos, League recomienda que siempre designes beneficiarios de tus p\xF3lizas de seguro.",
  BENEFICIARIES_FORM_CARD_TOOLTIP: "<p>Tu beneficiario es la persona o entidad que recibe el pago de la compa\xF1\xEDa aseguradora en el desafortunado evento de tu fallecimiento.</p><p>Es importante designar tus beneficiarios para asegurarte de que tu dinero termine en las manos correctas en caso de que fallezcas. Sin beneficiarios, tu dinero puede ser retenido y sujeto a tarifas onerosas e innecesarias.</p><p>Te mostraremos c\xF3mo designar tus beneficiarios, si es que a\xFAn no lo hiciste, luego de que completes tu inscripci\xF3n.</p>",
  DOWNLOAD_FORMS_TEXT: "Tu compa\xF1\xEDa aseguradora requiere que env\xEDes una copia f\xEDsica, una versi\xF3n escaneada o incluso una foto de los siguientes formularios para proceder a tu cobertura.",
  DOWNLOAD_FORMS: "Descargar formularios",
  DOWNLOAD_FORM: "Descargar formulario",
  FOREIGN_CONTRACT: "Cuestionario a trabajadores extranjeros contratados",
  DOWNLOAD: "Descargar",
  VIEW: "Ver",
  IDENTIFICATION: "Identificaci\xF3n",
  PROOF_OF_BUSINESS: "Comprobante de transacci\xF3n",
  BENEFICIARY_FORM: "Formulario de beneficiario",
  GYM_MEMBERSHIP: "Membres\xEDa del gimnasio",
  PRESCRIPTION: "Receta",
  NAME: "Nombre",
  UPLOADED: "Cargado",
  UPLOAD_A_DOCUMENT: "Cargar un documento",
  UPLOAD_DOCUMENT: "Cargar documento",
  ONCE_YOU_UPLOAD: "Cuando lo hagas, o que un agente marque uno para ti, los ver\xE1s aqu\xED. \xBFQuieres que comencemos?",
  DONT_HAVE_ANY_UPLOADED_DOCUMENTS: "Todav\xEDa no has cargado ning\xFAn documento",
  BACK_TO_DOCUMENTS_AND_FORMS: "Volver a Documentos y formularios",
  MY_DOCUMENTS: "Mis documentos",
  SUBMITTING_FORMS_EOI_BOLD: "directamente a la compa\xF1\xEDa aseguradora.",
  SUBMITTING_FORMS_BENEFICIARIES: 'Beneficiarios: es posible descargar tu formulario de beneficiario aqu\xED. Por favor, descarga el formulario, compl\xE9talo y f\xEDrmalo con tinta o digitalmente. Toma en cuenta que se trata de un formulario est\xE1ndar de SunLife que requiere firma con tinta, sin embargo, SunLife permite tambi\xE9n tu firma digital.<br /> Puedes cargar el formulario completado en "Mis documentos" en tu escritorio o tomar una foto y cargarla directamente a trav\xE9s de tu aplicaci\xF3n League.',
  SUBMITTING_FORMS_EOI: "Evidencia de asegurabilidad: debes enviar este formulario {boldText}",
  SUBMITTING_FORMS: "Enviando formularios",
  MUST_BE_INK_SIGNED: "Debe firmarse con tinta y luego ser enviado por correo postal a la compa\xF1\xEDa aseguradora",
  MAIL_TO_CARRIER: "env\xEDalo por correo postal directamente a la compa\xF1\xEDa aseguradora",
  WILL_NOTIFY_ONCE_AVAILABLE: "Te notificaremos cuando el formulario est\xE9 disponible.",
  EOI_INFO: "La Evidencia de asegurabilidad es un cuestionario de salud que brinda informaci\xF3n sobre tu condici\xF3n de salud. Debe ser completado, firmado con tinta y enviado por correo postal a la compa\xF1\xEDa aseguradora para ser aprobado para el seguro de {benefitNames}.",
  DOWNLOAD_NOW: "Descargar ahora.",
  EOI_FORM_TEXT: "{eoiForm} Dado que elegiste {allSelectedEoiBenefitNames}, necesitas completar un formulario de Evidencia de asegurabilidad. Recibir\xE1s este formulario luego de finalizado tu periodo de inscripci\xF3n. Por favor, f\xEDrmalo con tinta y luego {mailToCarrier} para su aprobaci\xF3n. Si tu compa\xF1\xEDa aseguradora no lo recibe, tu aplicaci\xF3n no estar\xE1 completa. {downloadOrWait}",
  EOI_FORMS: " Evidencia de asegurabilidad (Obligatoria)",
  EOI_FORM: "Formulario de Evidencia de asegurabilidad:",
  BENEFICIARIES_FORM_TEXT_AVAILABLE: "{beneficiariesForm} Para tu seguridad y la de tus seres queridos, League recomienda que tengas siempre designados los beneficiarios de tu p\xF3liza de seguro.",
  BENEFICIARIES_FORM_TEXT: "{beneficiariesForm} Para tu seguridad y la de tus seres queridos, League recomienda que tengas siempre designados los beneficiarios de tu p\xF3liza de seguro. Podr\xE1s descargar este formulario luego de finalizado tu periodo de inscripci\xF3n en la secci\xF3n {documentsAndFormsLink} de tu aplicaci\xF3n League.",
  BENEFICIARY_FORMS: "Formularios del beneficiario",
  BENEFICIARIES_FORM: "Formularios de los beneficiarios:",
  BENEFICIARIES: "Beneficiarios",
  ERROR_DOWNLOADING_INSURANCE_FORM: "Se produjo un error al descargar tu formulario",
  FORMS_DOWNLOAD_INFO: "Los siguientes formularios son necesarios para asegurar tu protecci\xF3n y la de tus seres queridos. Si tu compa\xF1\xEDa aseguradora acepta formularios digitales, tambi\xE9n podr\xE1s encontrarlos aqu\xED.",
  YOUR_INSURANCE_FORMS: "Tus formularios de seguro",
  NEXT_STEPS_SUBHEADING: "Verifica a continuaci\xF3n tus pr\xF3ximas etapas y estar\xE1s en camino de vivir el a\xF1o m\xE1s saludable de tu vida.",
  NEXT_STEPS_HEADING: "\xA1Ya est\xE1 todo listo, {name}!",
  DISMISS: "Descartar",
  CHANGE_PLAN_LINK: "Cambiar elecciones de planes",
  CHANGE_PLAN_DESCRIPTION: "\xBFDeseas realizar cambios a tu plan o a tu informaci\xF3n personal?",
  CHANGE_PLAN_LABEL: "Cambiar las elecciones del plan",
  TOTAL_REWARDS_LINK: "Ver el resumen de la cobertura",
  TOTAL_REWARDS_DESCRIPTION: "Revisa las elecciones del plan realizadas durante la inscripci\xF3n.",
  TOTAL_REWARDS_LABEL: "Resumen de la cobertura",
  SELECTIONS_FINALIZED_SUCCESS: "\xA1Elecciones finalizadas!",
  ERROR_FINALIZING_SELECTIONS_INVALID_SELECTIONS: "Parece que tus elecciones est\xE1n incompletas. Completa por favor la inscripci\xF3n y vuelve a intentar.",
  ERROR_FINALIZING_SELECTIONS: "Se produjo un error al enviar tus elecciones.",
  FINALIZE_PLAN_SELECTIONS: "Finalizar elecciones del plan",
  FINALIZE_SELECTIONS_ACKNOWLEDGEMENT: "Entiendo que no podr\xE9 cambiar mis elecciones",
  FINALIZE_SELECTIONS_COPY_2: "Tambi\xE9n significa que no podr\xE1s realizar ning\xFAn cambio a tu plan luego de hacer clic en enviar.",
  FINALIZE_SELECTIONS_COPY_1: "Al finalizar tus elecciones, podr\xE1s enviar inmediatamente el plan a tu compa\xF1\xEDa de seguros. Esto quiere decir que no tendr\xE1s que esperar hasta el fin de tu periodo para ver tu cartera.",
  FINALIZE_SELECTIONS_HEADER_COPY: "\xBFQuieres tener acceso a tus beneficios anticipadamente?",
  HEALTH_PROGRAM_LINK_COPY: "prueba uno de nuestros programas de salud",
  WALLET_DESCRIPTION: "Tu cartera es donde podr\xE1s acceder a tus beneficios, enviar reclamaciones y verificar tu cobertura. Podr\xE1s enviar reclamaciones por costos incurridos durante el periodo de inscripci\xF3n una vez que tu cartera se active. \xA1Mientras tanto, {healthProgramLink}!",
  WALLET_WELCOME: "\xA1Bienvenido a tu cartera!",
  LAST_DAY_OF_ENROLLMENT: "\xDAltimo d\xEDa del periodo de inscripci\xF3n: {lastDay}",
  INTERNAL_ERROR_CTA: "Volver atr\xE1s",
  INTERNAL_ERROR_PROMPT: "\xA1Por suerte guardamos toda tu informaci\xF3n tan pronto como la agregas!",
  ADD_DEPENDENT_CTA: "Agregar otro dependiente",
  FOR_INSURED_BENEFITS_4: "Para obtener toda la informaci\xF3n, consulta por favor el folleto completo de tu cobertura para conocer tus beneficios de grupo con tu compa\xF1\xEDa de seguros.",
  FOR_INSURED_BENEFITS_3: "Tambi\xE9n pueden existir limitaciones por edad, geogr\xE1ficas u otras. Por ejemplo, un hijo/a puede estar cubierto/a por tus beneficios asegurados si es menor de {validChildAge} o menor de {validFullTimeStudentAge} si es estudiante de tiempo completo",
  FOR_INSURED_BENEFITS_2: "Depende de tu sost\xE9n financiero",
  FOR_INSURED_BENEFITS_1: "Tu c\xF3nyuge, tu pareja de hecho, hijo/a tuyo/a o de tu pareja de hecho",
  FOR_INSURED_BENEFITS: "Para beneficios asegurados",
  WHO_QUALIFIES_CHILD_TEXT_US: "Un <strong>hijo/a</strong> es tu hijo/a biol\xF3gico/a, hijastro/a, hijo/a adoptado/a legalmente, hijo/a adoptado/a transitoriamente, en guardia legal o hijo/a de tu pareja de hecho de 25 a\xF1os o menor. Si eres legalmente responsable de otra persona y tienes preguntas acerca de su elegibilidad, contacta por favor a ayuda@league.com y recibir\xE1s ayuda para determinar si es elegible para inscribirse.",
  WHO_QUALIFIES_PARTNER_TEXT_US: "Una <strong>pareja de hecho</strong> es una pareja de cualquier g\xE9nero registrada en cualquier estado o departamento local del gobierno. Tu compa\xF1\xEDa de seguros puede extender beneficios a tu pareja de hecho y a sus hijos/as. Los beneficios brindados a las parejas de hecho pueden estar sujetos a un tratamiento fiscal adicional o diferente seg\xFAn la ley federal o estatal. Consulta por favor a un profesional contable o legal para obtener m\xE1s informaci\xF3n sobre c\xF3mo se aplica la ley federal o estatal en este caso.",
  WHO_QUALIFIES_SPOUSE_TEXT_US: "Un <strong>c\xF3nyuge</strong>es tu esposo/a con quien est\xE1s legalmente casado/a bajo la ley federal.",
  WHO_QUALIFIES_DEPENDENT_TEXT_CA: "Para ser elegible para usar tus beneficios League, un dependiente es alguien especial que cumple con los siguientes criterios:",
  WHO_QUALIFIES: "\xBFQui\xE9n califica?",
  WHO_QUALIFIES_DEPENDENT_PLAIN: "\xBFQui\xE9n califica como dependiente?",
  WHO_QUALIFIES_DEPENDENT_TITLE: "\xBFQui\xE9n califica como dependiente?",
  WHO_QUALIFIES_DEPENDENT_TOOLTIP_TITLE: "\xBFqui\xE9n califica como dependiente?",
  PLEASE_NOTE_NO_DEPENDENT: "Por favor, toma en cuenta que si no tienes un dependiente, puedes dejar esta secci\xF3n sin llenar. Estos campos solo son requeridos si ingresaste informaci\xF3n sobre un dependiente. Puedes guardar tu perfil y proceder a la pr\xF3xima etapa.",
  CHILD_OF_DOMESTIC_PARTNER_TOOLTIP: "Te preguntamos para poder determinar tu relaci\xF3n con tu dependiente y compartir la informaci\xF3n con tu compa\xF1\xEDa de seguros.",
  DEPENDENTS_CHILD_DISABLED_TOOLTIP: "Un hijo/a es considerado/a con discapacidad si no es capaz de manejarse solo o de realizar ning\xFAn tipo de actividad remunerada. Es considerado discapacitado si depende de ti, el Miembro, para apoyo financiero, manutenci\xF3n y cuidado, dentro de los t\xE9rminos de la ley de impuestos sobre los ingresos, debido a una discapacidad mental o f\xEDsica.",
  DEPENDENTS_CHILD_FULL_TIME_STUDENT_TOOLTIP: "Los dependientes entre {validChildAge} y {validFullTimeStudentAge} de edad son elegibles para cobertura de beneficios si son estudiantes de tiempo completo al momento de su inscripci\xF3n.",
  DEPENDENTS_PARTNER_EFFECTIVE_DATE_TOOLTIP: "Te preguntamos esto para determinar la duraci\xF3n de tu relaci\xF3n con tu dependiente y compartir esa informaci\xF3n con tu compa\xF1\xEDa de seguros",
  DEPENDENTS_PARTNER_RELATIONSHIP_TYPE_TOOLTIP: "Te preguntamos para poder determinar tu relaci\xF3n con tu dependiente y compartir la informaci\xF3n con tu compa\xF1\xEDa de seguros ",
  REMOVING_DEPENDENT_TEXT_CONTINUED: "\xBFEst\xE1s seguro de que deseas quitar a {name} como dependiente?",
  REMOVING_DEPENDENT_TEXT: "Al quitar a {name} como dependiente, todas tus elecciones previas se borrar\xE1n. Tendr\xE1s que pasar por cada beneficio y hacer tus elecciones nuevamente.",
  REMOVING_DEPENDENT: "Quitando al dependiente",
  INTERNAL_ERROR_TITLE: "\xA1Oh! Parece que algo sali\xF3 mal",
  DEPENDENTS_TOBACCO_USER_TOOLTIP: "Te preguntamos esto porque es un factor importante en la determinaci\xF3n de las primas de seguro de vida. Puedes cambiar el estatus de tu dependiente m\xE1s adelante, cont\xE1ctanos si su estatus cambia.",
  DEPENDENTS_SEX_TOOLTIP: "Las compa\xF1\xEDas de seguros con la que estamos asociados requiere saber el sexo actual de tu dependiente para administrar sus beneficios (nota: no requiere ning\xFAn documento legal de transici\xF3n si est\xE1 en un proceso de transici\xF3n). Esta informaci\xF3n no ser\xE1 compartida fuera de las compa\xF1\xEDas de seguros. Nuestro equipo est\xE1 comprometido a asegurar que todos sus miembros se sientan seguros y respetados.",
  DEPENDENTS_SSN_TOOLTIP: "Te preguntamos esto porque los proveedores de seguros con los que estamos asociados requieren esta informaci\xF3n para inscribir a tu dependiente en tu plan.",
  PROFILE_SSN_TOOLTIP: "Te preguntamos esto porque los proveedores de seguros con los que estamos asociados requieren esta informaci\xF3n para inscribirte en tu plan.",
  PROFILE_SEX_TOOLTIP: "Las compa\xF1\xEDas de seguros con la que estamos asociados requiere conocer tu sexo para administrar tus beneficios. <br/><br/>Nuestro equipo est\xE1 comprometido a asegurar que todos los miembros se sientan seguros y respetados. Si quieres decirnos m\xE1s acerca de c\xF3mo te identificas, usa los campos del formulario a continuaci\xF3n para agregar tu <strong>Identidad de g\xE9nero y Pronombres.</strong>",
  PROFILE_TOBACCO_USER_TOOLTIP: "Te preguntamos porque el consumo de tabaco impacta el costo de la prima de tus beneficios.<br/><br/>Consumir tabaco significa que (dentro de los \xFAltimos 12 meses) has fumado o utilizado cigarrillos, cigarrillos electr\xF3nicos, hach\xEDs, cigarros puros, pipa, masticas tabaco, parches y/o gomas de mascar de nicotina, nuez de betel o tabaco o nicotina en cualquier otra forma.",
  PROFILE_LOCALE_TOOLTIP: "Te preguntamos para saber en qu\xE9 idioma enviarte la informaci\xF3n.",
  PROFILE_DOB_TOOLTIP: "Te preguntamos para transmitir a tu proveedor de seguros esta informaci\xF3n necesaria. A veces, el precio o la elegibilidad para algunos productos y servicios est\xE1n basados en la edad.",
  PROFILE_INDIAN_ACT_TOOLTIP: "Tu compa\xF1\xEDa aseguradora necesita saber si est\xE1s registrado bajo la Ley canadiense sobre abor\xEDgenes para poder calcular tus impuestos.",
  WHY_DO_YOU_ASK: "\xBFPor qu\xE9 preguntas?",
  NO: "No",
  YES: "S\xED",
  DOMESTIC_PARTNERSHIP: "En pareja de hecho",
  SINGLE: "Soltero/a",
  MARRIED: "Casado/a",
  FEMALE: "Femenino",
  MALE: "Masculino",
  FRENCH: "Fran\xE7ais",
  ENGLISH: "Ingl\xE9s",
  WYOMING: "Wyoming",
  WISCONSIN: "Wisconsin",
  WEST_VIRGINIA: "Virginia Occidental",
  WASHINGTON: "Washington",
  VIRGINIA: "Virginia",
  VERMONT: "Vermont",
  UTAH: "Utah",
  US_VIRGIN_ISLANDS: "Islas V\xEDrgenes Estadounidenses",
  TEXAS: "Texas",
  TENNESSEE: "Tennessee",
  SOUTH_DAKOTA: "Dakota del Sur",
  SOUTH_CAROLINA: "Carolina del Sur",
  RHODE_ISLAND: "Rhode Island",
  PUERTO_RICO: "Puerto Rico",
  PENNSYLVANIA: "Pensilvania",
  OREGON: "Oregon",
  OKLAHOMA: "Oklahoma",
  OHIO: "Ohio",
  NORTH_DAKOTA: "Dakota del Norte",
  NORTH_CAROLINA: "Carolina del Norte",
  NEW_YORK: "Nueva York",
  NEW_MEXICO: "Nuevo M\xE9xico",
  NEW_JERSEY: "Nueva Jersey",
  NEW_HAMPSHIRE: "Nueva Hampshire",
  NEVADA: "Nevada",
  NEBRASKA: "Nebraska",
  MONTANA: "Montana",
  MISSOURI: "Missouri",
  MISSISSIPPI: "Mississippi",
  MINNESOTA: "Minnesota",
  MICHIGAN: "Michigan",
  MASSACHUSETTS: "Massachusetts",
  MARYLAND: "Maryland",
  MAINE: "Maine",
  LOUISIANA: "Luisiana",
  KENTUCKY: "Kentucky",
  KANSAS: "Kansas",
  IOWA: "Iowa",
  INDIANA: "Indiana",
  ILLINOIS: "Illinois",
  IDAHO: "Idaho",
  HAWAII: "Haw\xE1i",
  GUAM: "Guam",
  GEORGIA: "Georgia",
  FLORIDA: "Florida",
  DISTRICT_OF_COLUMBIA: "Distrito de Columbia",
  DELAWARE: "Delaware",
  CONNECTICUT: "Connecticut",
  COLORADO: "Colorado",
  CALIFORNIA: "California",
  ARKANSAS: "Arkansas",
  ARIZONA: "Arizona",
  AMERICAN_SAMOA: "Samoa Americana",
  ALASKA: "Alaska",
  ALABAMA: "Alabama",
  YUKON: "Yuk\xF3n",
  NUNAVUT: "Nunavut",
  NORTHWEST_TERRITORIES: "Territorios del Noroeste",
  SASKATCHEWAN: "Saskatchewan",
  QUEBEC: "Quebec",
  PRINCE_EDWARD_ISLAND: "Isla del Pr\xEDncipe Eduardo",
  ONTARIO: "Ontario",
  NOVA_SCOTIA: "Nueva Escocia",
  NEWFOUNDLAND_LABRADOR: "Terranova y Labrador",
  NEW_BRUNSWICK: "Nueva Brunswick",
  MANITOBA: "Manitoba",
  BRITISH_COLUMBIA: "Columbia Brit\xE1nica",
  ALBERTA: "Alberta",
  PROFILE_DEPENDENTS_SECTION_TITLE: "\xA1Queremos conocer a tus seres queridos!",
  WELLNESS_ACCOUNTS: "Cuentas de bienestar",
  WAIVE: "Anular",
  IS_DEPENDENT_DISABLED: "\xBFTu dependiente tiene una discapacidad?",
  IS_DEPENDENT_FULL_TIME_STUDENT: "\xBFTu dependiente es estudiante de tiempo completo?",
  RELATIONSHIP_EFFECTIVE_DATE: "Fecha efectiva de la relaci\xF3n",
  RELATIONSHIP_TYPE: "Tipo de relaci\xF3n",
  ADDRESS_LINE_2: "Direcci\xF3n l\xEDnea 2",
  ADDRESS_LINE_1: "Direcci\xF3n l\xEDnea 1",
  SUITE_NUMBER: "N\xFAmero de departamento",
  STREET_ADDRESS: "Calle y n\xFAmero",
  ARE_YOU_REGISTERED_INDIAN_ACT: "\xBFTienes estatus de Primeras Naciones?",
  DOES_YOUR_PARTNER_USE_TOBACCO: "\xBFTu c\xF3nyuge consume tabaco?",
  DO_YOU_USE_TOBACCO: "\xBFConsumes tabaco?",
  LANGUAGE_PREFERENCE: "Preferencias de idioma",
  NO_DEPENDENT_SSN: "Mi {dependentType} no tiene un SSN",
  SSN: "N\xFAmero de seguro social",
  SIN: "SIN",
  SIN_OR_SSN: "N\xFAmero SSN o SIN",
  THIS_INFO_WILL_NOT_BE_SHARED: "Esta informaci\xF3n es privada y no ser\xE1 compartida.",
  INFORMATION_NOT_SHARED_WITH_EMPLOYER: "Esta informaci\xF3n no ser\xE1 compartida con tu empleador y/o compa\xF1\xEDa/s.",
  LEAGUE_SUPPORTS_GENDER_EXPRESSION: "League apoya las expresiones de g\xE9nero y quisiera saber c\xF3mo te identificas",
  PRONOUNS_TOOLTIP: '\xA1A\xF1ade tus pronombres para que sepamos c\xF3mo referirnos a ti!<br /><br />Por ejemplo: Si los pronombres de Alex son sus/ellos, podremos decir "Alex recientemente se inscribieron en un programa de salud mental y han notado una mejora en su bienestar mental".',
  PRONOUNS: "Pronombres",
  GENDER_IDENTITY_TOOLTIP: "League apoya las expresiones de g\xE9nero y quisiera saber c\xF3mo te identificas.<br/><br/>La identidad de g\xE9nero es la percepci\xF3n personal de tu propio g\xE9nero. Puede corresponder con tu sexo o ser diferente.",
  GENDER_IDENTITY: "Identidad sexual",
  SEX: "Sexo",
  DATE_OF_BIRTH: "Fecha de nacimiento",
  MIDDLE_INITIAL: "Segundo nombre o inicial",
  PREFERRED_NAME_TOOLTIP: "\xBFUsas un nombre diferente? Queremos tenerlo presente y llamarte as\xED cada vez que sea posible.",
  PREFERRED_NAME_PLACEHOLDER: "Si es diferente de tu nombre legal",
  WHAT_SHOULD_WE_CALL_YOU: "\xBFC\xF3mo debemos llamarte?",
  PREFERRED_FIRST_NAME: "Nombre que prefieres usar",
  LAST_NAME: "Apellido",
  LEGAL_FIRST_NAME: "Nombre legal",
  EMPLOYEE_ASSISTANCE_PROGRAM: "Programa de asistencia al empleado",
  NO_SELECTION_MADE: "No realizaste ninguna selecci\xF3n",
  YOUR_ALLOCATION: "Tu asignaci\xF3n",
  WAIVED: "Anulado",
  TRACKER_WELLNESS_POOL_TOOLTIP: 'El Fondo com\xFAn bienestar es dinero extra que {company} te ofrece para que "gastes". Notar\xE1s que el monto va disminuyendo a medida que avanzas en las etapas de tu inscripci\xF3n.',
  TRACKER_FLEX_DOLLAR_TOOLTIP: 'Los D\xF3lares flexibles son dinero que {company} te ofrece para que "gastes". Notar\xE1s que el monto va disminuyendo a medida que avanzas en las etapas de tu inscripci\xF3n.',
  COST_PER_PAY_PERIOD: "<strong>{price}</strong>/ {periodType}",
  ANNUAL_COST_PRICE: "Costo de tu plan anual <strong>{price}</strong>",
  COVERAGE_PLAN_SELECTION: "Selecci\xF3n de plan",
  ENROLLMENT_STEP: "Etapa",
  ADDITIONAL_BENEFITS: "Beneficios adicionales",
  VIEW_ADDITIONAL_BENEFITS: "Ver beneficios adicionales",
  ADDITIONAL_BENEFITS_CARD_TITLE: "\xA1Espera! hay m\xE1s todav\xEDa",
  TOTAL_OUT_OF_POCKET: "Costo total anual de bolsillo",
  TOTAL_COVERAGE_COST: "Costo total de la cobertura",
  TOTAL: "Total",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_TOOLTIP: "Si tu hijo/a es diagnosticado/a con una condici\xF3n grave cubierta, recibir\xE1s una suma global libre de impuestos equivalente al monto que selecciones a continuaci\xF3n.",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_SUBHEADING: "Selecciona tu monto de cobertura para el Seguro de enfermedad cr\xEDtica de hijo/a",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "El Seguro de enfermedad cr\xEDtica de hijo/a te brinda cobertura financiera en el caso de que tu hijo/a sea diagnosticado/a con una enfermedad cr\xEDtica cubierta.",
  VOLUME_PLAN_CRITICAL_ILLNESS_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "El Seguro de enfermedad cr\xEDtica de hijo/a te brinda cobertura financiera en el caso de que tu hijo/a sea diagnosticado/a con una condici\xF3n grave cubierta. \xBFNecesitas agregar a tu hijo/a? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_TOOLTIP: "Si tu c\xF3nyuge o pareja es diagnosticado con una enfermedad cr\xEDtica cubierta, recibir\xE1s una suma global libre de impuestos equivalente al monto que selecciones a continuaci\xF3n.\n\nEl Seguro de enfermedad cr\xEDtica del c\xF3nyuge requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Toma en cuenta por favor de que no se aplicar\xE1n cargos por este beneficio hasta que tu c\xF3nyuge o pareja reciba la aprobaci\xF3n para la cobertura adicional.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_SUBHEADING: "Selecciona tu monto de cobertura para el Seguro de enfermedad cr\xEDtica del c\xF3nyuge",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "El Seguro de enfermedad cr\xEDtica del c\xF3nyuge te brinda cobertura financiera en el caso de que tu c\xF3nyuge o pareja sea diagnosticado con una condici\xF3n grave cubierta.",
  VOLUME_PLAN_CRITICAL_ILLNESS_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "El Seguro de enfermedad cr\xEDtica del c\xF3nyuge te brinda cobertura financiera en el caso de que tu c\xF3nyuge o pareja sea diagnosticado con una condici\xF3n grave cubierta. \xBFNecesitas agregar a tu c\xF3nyuge o pareja? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_CRITICAL_ILLNESS_OPTIONAL_TOOLTIP: "Si fuiste diagnosticado con una enfermedad cr\xEDtica cubierta, recibir\xE1s por \xFAnica vez un pago global equivalente al monto de la cobertura que especificas a continuaci\xF3n.\n\nUna enfermedad cr\xEDtica requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n con {vendorName}. Por favor, toma en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que recibas la aprobaci\xF3n para tu cobertura adicional. ",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION_US_PT2: "Es importante que sepas que el Seguro de enfermedad cr\xEDtica es: <ul><li>un seguro disponible para todos los empleados en empleo activo en Estados Unidos que trabajan al menos 20 horas semanales y para su c\xF3nyuge e hijos/as elegibles (hasta los 26 a\xF1os sin importar si estudian o su estado civil)</li><li>Se trata de un complemento al seguro de salud, no un sustituto de los beneficios de salud esenciales o de la cobertura m\xEDnima esencial como se define en la ley federal. Si tanto t\xFA como cada una de las personas para las cuales elegiste cobertura no ten\xE9is los beneficios de salud esenciales o la cobertura m\xEDnima esencial, no sois elegibles para esta cobertura.</li></ul><br/>Revisa los detalles del plan que aparecen abajo para obtener m\xE1s informaci\xF3n, incluidos el Aviso y la Gu\xEDa de Medicare.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION_US_PT1: "El Seguro de enfermedad cr\xEDtica puede brindar una suma global de dinero en el caso de una enfermedad o condici\xF3n cubierta. Puedes utilizar el dinero como lo desees. <br/><br/>",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_AND_OPTIONAL_CRITICAL_ILLNESS_DESCRIPTION: "El Seguro de enfermedad cr\xEDtica es una fuente importante de protecci\xF3n financiera en el caso de que tus dependientes reciban un diagn\xF3stico de enfermedad cr\xEDtica cubierta, por ejemplo: alzheimer, c\xE1ncer, parkinson, entre otras.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_SELECTOR_TOOLTIP_US: "El dinero puede ayudar a pagar tus gastos m\xE9dicos de bolsillo, como copagos y deducibles. Puedes usar esta cobertura m\xE1s de una vez. Aun cuando recibiste un pago por una enfermedad, sigues teniendo cobertura por las condiciones restantes.",
  VOLUME_PLAN_CRITICAL_ILLNESS_BASE_SELECTOR_TOOLTIP: "Si recibes un diagn\xF3stico de una enfermedad cr\xEDtica cubierta, se te pagar\xE1 una suma global \xFAnica equivalente al monto de cobertura que especifiques a continuaci\xF3n.",
  CRITICAL_ILLNESS: "Enfermedad cr\xEDtica",
  CRITICAL_ILLNESS_INSURANCE_TITLE: "Seguro de enfermedad cr\xEDtica",
  COVERAGE_AMOUNT: "Monto de la cobertura",
  COVERAGE_TYPE: "Tipo de cobertura",
  LEARN_MORE_ABOUT_PLAN_DETAILS: "M\xE1s informaci\xF3n sobre {planName} detalles",
  PLAN_NAME_DETAILS: "Detalles de {planName}",
  LEARN_MORE_ABOUT_FIND_IN_NETWORK_PROVIDERS: "M\xE1s informaci\xF3n sobre c\xF3mo encontrar proveedores de la red",
  FIND_IN_NETWORK_PROVIDERS: "Encontrar proveedores en la red",
  PLAN_DETAILS: "Detalles del plan",
  BENEFIT_PRICE_ERROR: "\xA1Perd\xF3n! Algo sali\xF3 mal en tu intento de recibir el precio de este beneficio. Por favor, contacta a nuestro equipo de consejeros de beneficios para resolver el problema.",
  VOLUME_PREREQUISITE_NOT_MET: "El seguro {benefitName} solo est\xE1 disponible si est\xE1s inscrito en {prerequisiteName}",
  VOLUME_INELIGIBLE_DEFAULT: "No eres elegible para este beneficio: {reason}",
  VOLUME_DOCUMENT_LINK_LABEL: "Detalles de {benefitName}",
  PLAN_COST: "Costo del plan",
  INSURABLE_EARNINGS_TOOLTIP_LTD: "El monto de dinero que recibes durante la discapacidad a largo plazo para compensar tu p\xE9rdida de ingresos se calcula como un porcentaje de tus ingresos mensuales.",
  INSURABLE_EARNINGS_TOOLTIP_STD: "El monto de dinero que recibes durante la discapacidad a corto plazo para compensar tu p\xE9rdida de ingresos se calcula como un porcentaje de tus ingresos semanales.",
  INSURABLE_EARNINGS_TOOLTIP: "El monto de dinero que recibes durante {benefitName} se basa en tus ingresos asegurables.",
  INSURABLE_EARNINGS_AMOUNT: "Ingresos anuales",
  INSURABLE_EARNINGS_SUBTITLE: "Tus ingresos anuales",
  PLAN_SELECTOR_DESCRIPTION: "El seguro {benefitName} asegura la protecci\xF3n de tu cheque de pago en caso de que no puedas trabajar debido a {benefitNameLowered}.",
  PLAN_SELECTOR_DESCRIPTION_LTD: "El Seguro de discapacidad a largo plazo (LTD) asegura tu protecci\xF3n ante la p\xE9rdida de ingresos en el caso de que no puedas trabajar por un periodo extendido de tiempo debido a una enfermedad, lesi\xF3n o accidente. Seg\xFAn tus circunstancias y elegibilidad, el beneficio por Discapacidad a largo plazo comienza a cubrir cuando finaliza tu periodo de eliminaci\xF3n.",
  PLAN_SELECTOR_DESCRIPTION_STD: "El Seguro de discapacidad a corto plazo (STD) asegura tu protecci\xF3n ante la p\xE9rdida de ingresos en el caso de que temporalmente no puedas trabajar debido a una enfermedad, una lesi\xF3n o un accidente.",
  PLAN_SELECTOR_HEADING: "Tu cobertura {benefitName}",
  PLANS_EXAMPLE: "Oro, Plata, Bronce",
  PLANS_EXPLANATION: "Los planes se utilizan para separar nuestros diferentes paquetes de beneficios",
  CLASSES_EXAMPLE: "Tiempo completo, Tiempo parcial y Asociado",
  CLASSES_EXPLANATION: "La clasificaci\xF3n se utiliza para segmentar a los empleados en diferentes categor\xEDas",
  LTD_PLAN_SELECTOR_DESCRIPTION: "Est\xE1s personalizando tu paquete de beneficios por discapacidad a largo plazo",
  LTD_PLAN_SELECTOR_TITLE: "Discapacidad a largo plazo",
  STD_PLAN_SELECTOR_DESCRIPTION: "Est\xE1s personalizando tu paquete de beneficios por discapacidad a largo plazo",
  STD_PLAN_SELECTOR_TITLE: "Discapacidad a corto plazo",
  MEDICAL_PLAN_SELECTOR_TITLE: "Beneficios m\xE9dicos",
  VISION_PLAN_SELECTOR_TITLE: "Beneficios en visi\xF3n",
  DENTAL_PLAN_SELECTOR_TITLE: "Beneficios dentales",
  DRUG_PLAN_SELECTOR_TITLE: "Beneficios en medicamentos",
  BEST_MATCH: "La mejor coincidencia",
  PER_PAY_PERIOD_DEDUCTION: "{deduction} / {periodType}",
  ANNUAL_DEDUCTION: "{deduction} anual",
  PROVIDED_BY: "Proporcionado por",
  PROVIDED_BY_VENDOR: "Provisto por {vendor}",
  PREVIOUSLY_SELECTED_PLAN_TOOLTIP_CONTENT: "Este es el plan que elegiste durante tu inscripci\xF3n anterior.",
  BENEFICIARIES_INFO_BANNER_CARD_TOOLTIP: "<p>Un beneficiario es la persona o entidad que elegiste para recibir el pago de la compa\xF1\xEDa en el desafortunado evento de tu fallecimiento.</p><p>Es importante designar a tus beneficiarios para que tu dinero vaya a la o las personas correctas al momento de tu muerte. Sin beneficiarios, tu dinero puede ser retenido y sujeto a tarifas onerosas e innecesarias.</p><p>Te mostraremos c\xF3mo designar tus beneficiarios, si es que a\xFAn no lo hiciste, luego de tu inscripci\xF3n.</p>",
  LEARN_MORE_ABOUT_BENEFICIARIES: "M\xE1s informaci\xF3n sobre los beneficiarios",
  DESIGNATE_BENEFICIARIES: "designar beneficiarios",
  BENEFICIARIES_INFO_BANNER_CARD: "Cuando te inscribes en ciertos beneficios, tu/s dependiente/s no estar\xE1/n completamente protegido/s si no {designateBeneficiariesTooltip}. Puedes agregar o actualizar tus beneficiarios luego de completar tu inscripci\xF3n.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_TOOLTIP: "Si tu hijo/a sufre una lesi\xF3n calificada, el Seguro de accidente te paga un beneficio basado en el monto de cobertura seleccionado y en la naturaleza de la lesi\xF3n.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "El Seguro de accidente de hijo/a ofrece cobertura ante el evento de que tu hijo/a sufra una lesi\xF3n accidental. \xBFNecesitas agregar un hijo/a? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "El Seguro de accidente de hijo/a ofrece cobertura ante el evento de que tu hijo/a sufra una lesi\xF3n accidental.",
  VOLUME_PLAN_ACCIDENT_CHILD_SELECTOR_SUBHEADING: "Selecciona el monto de la cobertura del Seguro de accidente de tu hijo/a",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_TOOLTIP: "Si tu c\xF3nyuge sufre una lesi\xF3n calificada, el Seguro de accidente te paga un beneficio con base en el monto de cobertura seleccionado y en la naturaleza de la lesi\xF3n.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "El Seguro de accidente del c\xF3nyuge te ofrece cobertura ante el evento de que tu c\xF3nyuge sufra una lesi\xF3n accidental. \xBFNecesitas agregar a tu c\xF3nyuge? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "El Seguro de accidente del c\xF3nyuge te ofrece cobertura ante el evento de que tu c\xF3nyuge sufra una lesi\xF3n accidental.",
  VOLUME_PLAN_ACCIDENT_SPOUSE_SELECTOR_SUBHEADING: "Selecciona el monto de cobertura del Seguro de accidente de tu c\xF3nyuge",
  VOLUME_PLAN_ACCIDENT_OPTIONAL_TOOLTIP: "El seguro de accidente opcional complementa tu cobertura de accidente para reducir el impacto potencialmente devastador que la p\xE9rdida de tu ingreso puede tener en tu familia.",
  VOLUME_PLAN_ACCIDENT_BASE_SELECTOR_TOOLTIP_US: "El Seguro de accidente te ayuda a pagar los gastos m\xE9dicos y de bolsillo en los que puedes incurrir luego de una lesi\xF3n accidental. Esto incluye tratamiento de emergencia, estad\xEDa en un hospital, ex\xE1menes m\xE9dicos y otros gastos que necesites afrontar, tales como transporte y alojamiento.",
  VOLUME_PLAN_ACCIDENT_BASE_SELECTOR_TOOLTIP: "Si sufres una lesi\xF3n o accidente calificado, recibir\xE1s un beneficio. Si mueres en un accidente, tu beneficiario recibir\xE1 el beneficio. El monto de tu beneficio depende del monto de tu cobertura y de la extensi\xF3n de los da\xF1os causados por el accidente.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION_US_PT2: "Cubre accidentes que sufras dentro y fuera de tu trabajo. Incluye una variedad de accidentes, desde lesiones comunes a eventos de mayor gravedad. Revisa los detalles de tu plan para obtener m\xE1s informaci\xF3n.<br/><br/>Al seleccionar cobertura, est\xE1s confirmando que:<ul><li>Has revisado la documentaci\xF3n legal y de conformidad (en los detalles del plan)</li><li>Est\xE1s empleado y activo en tu trabajo</li><li>Si elegiste cobertura del c\xF3nyuge, est\xE1s confirmando que tu c\xF3nyuge o pareja de hecho vive actualmente en EE.UU.</li></ul>",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION_US_PT1: "El Seguro de accidente puede pagar un monto de beneficio predeterminado seg\xFAn el tipo de lesi\xF3n que tengas y el tipo de tratamiento que necesites.",
  VOLUME_PLAN_ACCIDENT_BASE_AND_OPTIONAL_ACCIDENT_DESCRIPTION: "El Seguro de accidente es una fuente importante de protecci\xF3n financiera para ti y para tus seres queridos en el caso de que resultes seriamente herido o fallezcas en un accidente. La extensi\xF3n de tu cobertura depende del monto elegido y de la gravedad y naturaleza del accidente.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_TOOLTIP_EOI_REQUIRED: "Ante el evento del fallecimiento de tu hijo/a, recibir\xE1s un monto total libre de impuestos equivalente al monto que selecciones a continuaci\xF3n. Eres el beneficiario de la reclamaci\xF3n del Seguro de vida y de muerte accidental y desmembramiento AD&D de hijo/a.\n\nEl Seguro de vida y de AD&D de hijo/a requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor toma en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que tus hijos elegibles reciban la aprobaci\xF3n para la cobertura adicional.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_TOOLTIP: "Ante el evento del fallecimiento de tu hijo/a, recibir\xE1s una suma global libre de impuestos equivalente al monto que selecciones a continuaci\xF3n. Eres el beneficiario de la reclamaci\xF3n del Seguro de vida y de muerte accidental y desmembramiento AD&D de hijo/a.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "El Seguro de vida y muerte accidental y desmembramiento AD&D de hijo/a te ofrece cobertura ante el desafortunado evento en que tu hijo/a fallezca. Elegir un Seguro de vida y AD&D de hijo/a asegurar\xE1 la cobertura de todos los hijos/as elegibles. \xBFNecesitas agregar a tus hijos/as? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "El Seguro de vida y de muerte accidental y desmembramiento AD&D de hijo/a te ofrece cobertura ante el desafortunado evento en que tu hijo/a fallezca. Elegir un Seguro de vida y AD&D de hijo/a asegurar\xE1 la cobertura de todos los hijos/as elegibles.",
  VOLUME_PLAN_LIFE_AND_ADD_CHILD_SELECTOR_SUBHEADING: "Selecciona el monto de cobertura de tu Seguro de vida y de muerte accidental y desmembramiento AD&D de hijo/a",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_TOOLTIP: "Ante el evento del fallecimiento de tu c\xF3nyuge o pareja, recibir\xE1s una suma global libre de impuestos equivalente al monto que selecciones a continuaci\xF3n. Eres el beneficiario de la reclamaci\xF3n del Seguro de vida y de muerte accidental y desmembramiento AD&D del c\xF3nyuge.\n\nEl seguro de vida y de muerte accidental y desmembramiento AD&D del c\xF3nyuge requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor toma en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que tu c\xF3nyuge o pareja reciba la aprobaci\xF3n para la cobertura adicional.",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_SUBHEADING: "Selecciona el monto de cobertura de tu Seguro de vida  y de muerte accidental y desmembramiento AD&D del c\xF3nyuge",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "El Seguro de vida y de muerte accidental y desmembramiento AD&D del c\xF3nyuge te brinda cobertura ante el desafortunado evento del fallecimiento de tu c\xF3nyuge o pareja. \xBFNecesitas agregar a tu c\xF3nyuge? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_LIFE_AND_ADD_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "El Seguro de vida y de muerte accidental y desmembramiento AD&D del c\xF3nyuge te brinda cobertura ante el desafortunado evento del fallecimiento de tu c\xF3nyuge o pareja.",
  VOLUME_PLAN_LIFE_AND_ADD_OPTIONAL_SELECTOR_TOOLTIP: "El seguro {benefitName} complementa tu cobertura b\xE1sica obligatoria de seguro de vida para reducir el potencialmente devastador impacto que la p\xE9rdida de tu ingreso puede tener en tu familia. \n\nEl seguro {benefitName} requiere de una evidencia de asegurabilidad. Esto lanzar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor toma en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que recibas la aprobaci\xF3n para la cobertura adicional.\n\nEn el desafortunado evento de tu fallecimiento, tu beneficiario recibir\xE1 un pago \xFAnico libre de impuestos equivalente al monto de cobertura especificado a continuaci\xF3n",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_SELECTOR_TOOLTIP: "El Seguro de vida b\xE1sico y de muerte accidental y desmembramiento (AD&D) se te ofrece sin costo. Tu/s beneficiario/s recibir\xE1n una suma de dinero ante el evento de tu fallecimiento o tu muerte accidental o desmembramiento.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_DESCRIPTION_PT2: "Este beneficio otorga un nivel de seguridad financiera ante el evento de tu fallecimiento o de una muerte accidental o desmembramiento. Revisa los detalles de tu plan para m\xE1s informaci\xF3n.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "El Seguro de vida y de muerte accidental y desmembramiento AD&D es una fuente importante de protecci\xF3n financiera para ti y para tu/s beneficiario/s.",
  VOLUME_PLAN_LIFE_AND_ADD_BASE_AND_OPTIONAL_HEADING: "Tu cobertura {benefitName}",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_TOOLTIP_EOI_REQUIRED: "El seguro de muerte accidental y desmembramiento AD&D de hijo/a te brinda cobertura ante el evento de que tu hijo/a sufra una lesi\xF3n grave o fallezca.<br/><br/>El seguro de muerte accidental y desmembramiento AD&D de hijo/a requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor toma en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que el hijo/a elegible reciba la aprobaci\xF3n para la cobertura adicional.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_NO_CHILD_DESCRIPTION: "El Seguro de muerte accidental y desmembramiento AD&D de hijo/a te brinda cobertura ante el evento de que tu hijo/a sufra una lesi\xF3n grave o fallezca. \xBFNecesitas agregar a tus hijos/as? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HAS_CHILD_DESCRIPTION: "El Seguro de muerte accidental y desmembramiento AD&D de hijo/a te ofrece cobertura ante el evento de que tu hijo/a sufra una lesi\xF3n grave o fallezca.",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_SUBHEADING: "Elige la cobertura de muerte accidental y desmembramiento AD&D adecuada para tu hijo/a",
  VOLUME_PLAN_CHILD_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HEADING: "Seguro voluntario de muerte accidental y desmembramiento (AD&D) de hijo/a",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_NO_SPOUSE_DESCRIPTION: "El Seguro de muerte accidental y desmembramiento (AD&D) del c\xF3nyuge te brinda cobertura ante el evento de que tu c\xF3nyuge o pareja sufra una lesi\xF3n seria o fallezca. \xBFNecesitas agregar a tu c\xF3nyuge? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HAS_SPOUSE_DESCRIPTION: "El Seguro de muerte accidental y desmembramiento AD&D del c\xF3nyuge te brinda cobertura ante el evento de que tu c\xF3nyuge o pareja sufra una lesi\xF3n seria o fallezca.",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_SUBHEADING: "Elige la cobertura de seguro de muerte accidental y desmembramiento AD&D adecuada para tu c\xF3nyuge o pareja",
  VOLUME_PLAN_SPOUSE_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_HEADING: "Seguro voluntario de muerte accidental y desmembramiento (AD&D) del c\xF3nyuge",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_OPTIONAL_HEADING: "Tu Seguro voluntario de muerte accidental y desmembramiento (AD&D)",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_SELECTOR_TOOLTIP: "Usted o su/s beneficiario/s recibir\xE1n una suma de dinero en el caso de que ustd resulte seriamente herido o fallezca.<br/><br/>El monto recibido se basar\xE1 en el monto de cobertura que seleccione y el porcentaje depende de los tipos de accidente o lesi\xF3n cubiertos.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "Una lesi\xF3n grave o la muerte es algo devastador y todos los costos inesperados que pueden surgir ocasionan incluso mayor estr\xE9s. El Seguro de muerte accidental y desmembramiento AD&D es una fuente importante de protecci\xF3n financiera para ti y para tus seres queridos en caso de que un accidente te cause lesiones graves o la muerte.",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_HEADING: "Tu Seguro de muerte accidental y desmembramiento (AD&D)",
  VOLUME_PLAN_ACCIDENTAL_DEATH_DISMEMBERMENT_BASE_AND_OPTIONAL_HEADING: "Tu cobertura de Seguro de muerte accidental y desmembramiento (AD&D)",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP_EOI_REQUIRED: "Ante el evento del fallecimiento de su hijo/a, recibir\xE1 un monto total libre de impuestos equivalente al monto que elija a continuaci\xF3n. Ud. es el beneficiario en caso de una reclamaci\xF3n del Seguro de vida de hijo/a.\n\nEl Seguro de vida de hijo/a requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor, tome en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que el hijo/a elegible reciba la aprobaci\xF3n para la cobertura adicional.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP_US: "Ante el evento del fallecimiento de un hijo/a asegurado (seg\xFAn definici\xF3n de la p\xF3liza), el o los beneficiarios recibir\xE1n un pago \xFAnico en efectivo equivalente al monto que elija a continuaci\xF3n.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_TOOLTIP: "Ante el evento del fallecimiento de su hijo/a, recibir\xE1 un monto total libre de impuestos equivalente al monto que elija a continuaci\xF3n. Ud. es el beneficiario en caso de una reclamaci\xF3n del seguro de vida de un hijo/a.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_NO_CHILD_DESCRIPTION: "El Seguro de vida de hijo/a te ofrece cobertura ante el desafortunado evento del fallecimiento de tu hijo/a. La selecci\xF3n del Seguro de vida de hijo/a asegura la cobertura para todos tus hijos/as elegibles. \xBFNecesitas agregar un hijo/a? Dir\xEDgete a {dependentsLink} para realizar esta selecci\xF3n.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_HAS_CHILD_DESCRIPTION: "El Seguro de vida de hijo/a te ofrece cobertura ante el desafortunado evento del fallecimiento de tu hijo/a.  La selecci\xF3n del Seguro de vida de hijo/a asegura la cobertura para todos tus hijos/as elegibles.",
  VOLUME_PLAN_LIFE_CHILD_SELECTOR_SUBHEADING: "Selecciona el monto de cobertura del seguro de vida de tu hijo/a.",
  VOLUME_PLAN_CHILD_SELECTOR_HEADING: "Elija la cobertura de seguro {benefitName} adecuada para su hijo/a.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_TOOLTIP_US: "Ante el evento de fallecimiento del c\xF3nyuge o pareja de hecho asegurado, el o los beneficiarios recibir\xE1n un pago \xFAnico en efectivo equivalente al monto que elija a continuaci\xF3n.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_TOOLTIP: "En caso de fallecimiento de su c\xF3nyuge o pareja, recibir\xE1 una suma global libre de impuestos equivalente al monto seleccionado a continuaci\xF3n. Usted es el beneficiario en el caso de una reclamaci\xF3n de Seguro de vida del c\xF3nyuge.\n\nEl Seguro de vida del c\xF3nyuge requiere de una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor tome en cuenta que no se aplicar\xE1n cargos por este beneficio hasta que su c\xF3nyuge o pareja reciba la aprobaci\xF3n para la cobertura adicional.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_SUBHEADING: "Selecciona el monto de cobertura de tu Seguro de vida del c\xF3nyuge",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_NO_SPOUSE_DESCRIPTION: "El Seguro de vida del c\xF3nyuge te brinda cobertura ante el desafortunado evento del fallecimiento de tu c\xF3nyuge o pareja. \xBFNecesitas agregar a tu c\xF3nyuge? Dir\xEDgete a {dependentsLink} para realizar esta elecci\xF3n.",
  VOLUME_PLAN_LIFE_SPOUSE_SELECTOR_HAS_SPOUSE_DESCRIPTION: "El Seguro de vida del c\xF3nyuge te brinda cobertura ante el desafortunado evento del fallecimiento de tu c\xF3nyuge o pareja.",
  VOLUME_PLAN_SPOUSE_SELECTOR_HEADING: "Elija la cobertura de seguro {benefitName} adecuada para su c\xF3nyuge o pareja",
  VOLUME_PLAN_LIFE_OPTIONAL_SELECTOR_TOOLTIP_US: "En el caso de su fallecimiento, su o sus beneficiarios recibir\xE1n un pago \xFAnico en efectivo equivalente al monto que elija a continuaci\xF3n.",
  VOLUME_PLAN_LIFE_OPTIONAL_SELECTOR_TOOLTIP: "El seguro {benefitName} complementa su cobertura b\xE1sica obligatoria de seguro de vida para reducir el impacto potencialmente devastador que la p\xE9rdida de su ingreso puede tener en su familia.\n\nEl seguro {benefitName} requiere que aporte una evidencia de asegurabilidad. Esto iniciar\xE1 un proceso de evaluaci\xF3n y aprobaci\xF3n con {vendorName}. Por favor, tome en cuenta que no se aplican cargos por este beneficio hasta que reciba la aprobaci\xF3n para la cobertura adicional.\n\nEn el desafortunado evento de su fallecimiento, su beneficiario recibir\xE1 un pago \xFAnico libre de impuestos equivalente al monto de cobertura especificado a continuaci\xF3n",
  VOLUME_PLAN_OPTIONAL_SELECTOR_HEADING: "Seguro {benefitName}",
  VOLUME_PLAN_LIFE_BASE_SELECTOR_TOOLTIP: "En el desafortunado evento de su fallecimiento, su beneficiario recibir\xE1 un pago \xFAnico libre de impuestos equivalente al monto de cobertura especificado a continuaci\xF3n",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_PT2: "Este beneficio otorga un nivel de seguridad financiera en el evento en que t\xFA o un miembro de tu familia elegible fallezca. Revisa los detalles de tu plan para obtener m\xE1s informaci\xF3n.",
  VOLUME_PLAN_LIFE_BASE_AND_OPTIONAL_DESCRIPTION_PT1: "El seguro de vida es una fuente importante de protecci\xF3n financiera para tu familia.",
  VOLUME_PLAN_BASE_AND_OPTIONAL_TOOLTIP: "<p>Tu beneficiario es la persona o entidad que elegiste para recibir el pago de la compa\xF1\xEDa aseguradora en el desafortunado evento de tu fallecimiento.</p><p>Es importante designar tus beneficiarios para que tu dinero vaya a la o las personas correctas al momento de tu muerte. Sin beneficiarios, tu dinero puede ser retenido y sujeto a tarifas onerosas e innecesarias.</p><p>Te mostraremos c\xF3mo designar tus beneficiarios, si es que a\xFAn no lo has hecho, luego de la inscripci\xF3n.</p>",
  VOLUME_PLAN_BASE_AND_OPTIONAL_HEADING: "El {benefitName} de tu cobertura de seguro",
  VOLUME_OPTIONS_EOI_GROUP_LABEL_WITH_NEM: "La cobertura por encima de {noEvidenceMaximum} requiere un formulario de Evidencia de asegurabilidad.",
  VOLUME_OPTIONS_EOI_GROUP_LABEL: "Toda la cobertura requiere un formulario de Evidencia de asegurabilidad.",
  ACCIDENT_INSURANCE_TITLE: "Seguro de accidente",
  OPTIONAL_LIFE: "Seguro de vida opcional",
  CANT_OPT_OUT_INFO_EMPLOYER_PAID: "Por ser un empleado valioso, <strong>{employerName}</strong> te ofrece este beneficio sin costo.",
  CANT_OPT_OUT_INFO: "<strong>{employerName}</strong> requiere que te inscribas en este beneficio como parte de la oferta de tu plan.",
  CANT_OPT_OUT_TITLE: "Este es un beneficio obligatorio",
  ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE_TITLE: "Muerte accidental y desmembramiento",
  LIFE_AND_ADD_INSURANCE_TITLE: "Vida y Muerte accidental y desmembramiento",
  LIFE_INSURANCE_TITLE: "Seguro de vida",
  ACCOUNT_TYPE_FINANCIAL_CONTRIBUTION: "contribuci\xF3n financiera",
  ACCOUNT_TYPE_SPENDING_ACCOUNT: "cuenta de gastos",
  ACCOUNT_TYPE_LTD: "ltd",
  ACCOUNT_TYPE_STD: "std",
  ACCOUNT_TYPE_ACCIDENT: "accidente",
  ACCOUNT_TYPE_LIFE: "vida",
  ACCOUNT_TYPE_DENTAL: "dental",
  ACCOUNT_TYPE_MEDICAL: "m\xE9dico",
  ACCOUNT_TYPE_DRUG: "medicamento",
  ALL_DONE: "\xBFTodo terminado?",
  SAVE_PROFILE: "Guardar perfil",
  EAP: "Programa de asistencia al empleado PAE",
  FINANCIAL_CONTRIBUTIONS: "Contribuciones financieras",
  SPENDING_ACCOUNTS: "Cuentas de gastos",
  GROUP_UMBRELLA_LIABILITY: "Responsabilidad civil en exceso (paraguas) del grupo",
  GROUP_UMBRELLA_LIABILITY_INSURANCE: "Seguro de responsabilidad civil en exceso (paraguas) en grupo",
  LONG_TERM_DISABILITY: "Discapacidad a largo plazo",
  LTD: "LTD (Discapacidad a largo plazo)",
  SHORT_TERM_DISABILITY: "Discapacidad a corto plazo",
  STD: "STD (Seguro de discapacidad a corto plazo)",
  AD_AND_D: "Muerte accidental y desmembramiento AD&D",
  ACCIDENTAL_DEATH_DISMEMBERMENT_INSURANCE: "Muerte accidental y desmembramiento",
  ACCIDENT_INSURANCE: "Seguro de accidente",
  ACCIDENT: "Accidente",
  LIFE_AND_ADD_INSURANCE: "Vida y muerte accidental y desmembramiento",
  LIFE_AND_ADD: "Vida y muerte accidental y desmembramiento AD&D",
  LIFE_INSURANCE: "Seguro de vida",
  LIFE: "Vida",
  HOSPITAL_INDEMNITY: "Indemnizaci\xF3n del hospital",
  VISION_INSURANCE: "Seguro de la visi\xF3n",
  VISION: "Visi\xF3n",
  DENTAL_INSURANCE: "Seguro dental",
  DENTAL: "Dental",
  MEDICAL_INSURANCE: "Seguro m\xE9dico",
  MEDICAL: "M\xE9dico",
  DID_YOU_KNOW: "\xBFLo sab\xEDas?",
  DRUG_INSURANCE: "Seguro de medicamentos",
  DRUG: "Medicamento",
  FLEX_BETWEEN_TEXT: " entre ",
  FLEX_JOIN_TEXT: " y/o ",
  REDUCTION_IN_MONTHLY_DRAW: "Reducci\xF3n en un retiro mensual de",
  MONTHLY_PAYROLL_DEDUCTION: "Deducci\xF3n mensual en la n\xF3mina de pago",
  SEMI_MONTHLY_PAYROLL_DEDUCTION: "Deducci\xF3n quincenal en la n\xF3mina de pago",
  BI_WEEKLY_PAYROLL_DEDUCTION: "Deducci\xF3n cada dos semanas en la n\xF3mina de pago",
  WEEKLY_PAYROLL_DEDUCTION: "Deducci\xF3n semanal en la n\xF3mina de pago",
  WEEKLY: "Semanales",
  YOU_PAY: "Pagas",
  YOUR_BALANCE_UPDATED_AMOUNT: "\xA1Tu saldo actualizado! {amount}",
  PAYROLL_DEDUCTION_TOAST: "DEDUCCI\xD3N EN LA N\xD3MINA DE PAGO: {amount}",
  WELLNESS_POOL_TOAST: "FONDO COM\xDAN BIENESTAR: {amount}",
  FLEX_DOLLAR_ELIGIBLE: "\xA1Puedes utilizar tu {flexDollarTerm} para pagar por este beneficio!",
  FLEX_DOLLAR_TOAST: "D\xD3LARES FLEXIBLES: {amount}",
  FLEXIBLE_DOLLARS_USED_TOOLTIP: "Esta es la cantidad de {flexDollarTerm} que has utilizado en tus beneficios.",
  YOU_PAY_ANNUALLY_TOOLTIP: "Este es el costo anual personal de tus beneficios para el periodo completo de tu p\xF3liza.",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP_NOTE: "Nota: este monto est\xE1 sujeto a cambios si tienes beneficios pendientes de aprobaci\xF3n.",
  YOUR_PAYROLL_DEDUCTION_TOOLTIP: "Esto es lo que puedes esperar en tu cheque de pago con base en {payGroup}.",
  TOTAL_FLEXIBLE_DOLLARS: "Total {flexType}: ",
  VIEW_BREAKDOWN: "Ver desglose",
  YOUR_FLEX_DOLLARS_USED: "Tu {flexDollarTerm} utilizado",
  YOU_PAY_ANNUALLY: "Tu pago anual",
  YOUR_PAYROLL_DEDUCTION: "Tu deducci\xF3n en la n\xF3mina de pago",
  FLEXIBLE_DOLLARS_USED_WITH_TERM: "{flexDollarTerm} utilizados",
  WELLNESS_POOL_USED: "Fondo com\xFAn bienestar utilizado",
  FLEXIBLE_DOLLARS_USED: "D\xF3lares flexibles utilizados",
  WELLNESS_POOL_WITH_DOLLARS: "d\xF3lares del fondo com\xFAn bienestar",
  FLEXIBLE_DOLLARS_WITH_DOLLARS: "d\xF3lares flexibles",
  WELLNESS_POOL_CAPITALIZED: "Fondo com\xFAn bienestar",
  FLEXIBLE_DOLLARS_CAPITALIZED: "D\xF3lares flexibles",
  WELLNESS_POOL: "fondo com\xFAn bienestar",
  FLEXIBLE_DOLLARS: "d\xF3lares flexibles",
  LEFTOVER_FLEX_BANNER_INFO_ZERO_SPLIT: "Todo fondo restante ser\xE1 repartido entre tus cuentas:{message}",
  LEFTOVER_FLEX_BANNER_INFO_SPLIT: "Estos fondos restantes ser\xE1n repartidos entre tus cuentas: {message}",
  LEFTOVER_FLEX_BANNER_INFO_ZERO_SINGLE: "Todo fondo restante ser\xE1 agregado a tu {benefitName}.",
  LEFTOVER_FLEX_BANNER_INFO_SINGLE: "Estos fondos restantes ser\xE1n agregados a tu {benefitName}.",
  LEFTOVER_FLEX_BANNER_EXPLANATION: "{flexDollarTerm} restantes es el monto de fondos restantes luego de cubrir los costos de los beneficios elegibles. {message}",
  LEFTOVER_FLEX_BANNER_HEADING_ZERO: "No tienes {flexDollarTerm} restantes",
  LEFTOVER_FLEX_BANNER_HEADING: "Tienes {leftoverFlexDollars} restantes en {flexDollarTerm}",
  LEFTOVER_FLEX_DOLLAR_INFO_TEXT_SINGLE: "<strong>{percent}%</strong> ser\xE1 agregado a tu <strong>{benefitName}</strong>.",
  IT_LOOKS_LIKE_YOU_HAVE_SOME_LEFTOVER_FLEX: "\xA1Parece que tienes algunos {flexDollarTerm} restantes! {message}",
  FLEX_DOLLAR_EXPLAINER_TITLE: "\xBFQu\xE9 pasa si ya gast\xE9 algunos o todos mis {flexDollarTerm}?",
  FLEX_DOLLAR_INFO_TEXT_MULTIPLE: "<strong>{percent}%</strong> ser\xE1 agregado a tu <strong>{benefitName}</strong>",
  FLEX_DOLLAR_INFO_TEXT_SINGLE: "ser\xE1n autom\xE1ticamente agregados a tu {benefitName}.",
  FLEX_DOLLAR_INFO_TEXT: "Si tienes {flexDollarTerm} restantes, {message}",
  FLEX_DOLLAR_SUBTITLE_EXACT_COST: "Aseg\xFArate de asignar el monto total entre cualquiera de las siguientes cuentas.",
  FLEX_DOLLAR_SUBTITLE_MAX_COST: "Puedes asignar hasta este monto a las siguientes cuentas.",
  WELLNESS_POOL_TITLE: "{company} ha provisto un monto de {value} de {flexTerm} para que gastes a tu gusto. {maxValue}",
  FLEX_DOLLAR_TITLE: "{company} ha provisto {value} en {flexTerm} para que gastes a tu gusto. {maxValue}",
  PAPER_BENEFICIARIES_FORM_READY_INSTRUCTIONS: "<strong>Instrucciones:</strong> luego de completar el formulario, podr\xE1s cargarlo en League, salvo que tu equipo de RR. HH. te haya indicado lo contrario.",
  EOI_FORM_READY_INSTRUCTIONS: "<strong>Instrucciones:</strong>Deber\xE1 firmar en papel este formulario y enviarlo por correo postal a su compa\xF1\xEDa. Se incluyen m\xE1s instrucciones en el formulario.",
  FORM_NOT_READY_INSTRUCTIONS: "<strong>Instrucciones:</strong> una vez que tu formulario est\xE9 disponible, te enviaremos un correo electr\xF3nico con las instrucciones para completarlo.",
  EOI_CARD_DESCRIPTION: "Este formulario es un requisito de tu compa\xF1\xEDa para la cobertura del <strong>{benefitNames}</strong> que has elegido.",
  LEARN_MORE_ABOUT_EOI: "M\xE1s informaci\xF3n sobre la prueba de asegurabilidad",
  EVIDENCE_OF_INSURABILITY_EOI: "Evidencia de asegurabilidad (EOI)",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT2: "Es posible que tambi\xE9n necesites una evaluaci\xF3n f\xEDsica y/o unas pruebas de laboratorio para completar este proceso.",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_LIST: "<li>Te inscribes en un beneficio que requiere una EOI para toda la cobertura</li><li>Eliges un nivel de cobertura que requiere una EOI</li><li>Tu salario autom\xE1ticamente te sit\xFAa en un nivel de cobertura que requiere de una EOI</li>",
  EVIDENCE_OF_INSURABILITY_BANNER_TOOLTIP_PT1: "La Evidencia de asegurabilidad (EOI) es como un cuestionario m\xE9dico que tu compa\xF1\xEDa te solicita en las siguientes situaciones:",
  EVIDENCE_OF_INSURABILITY_BANNER_TEXT_WITH_NEM: "Debes completar y enviar un formulario de {eoi} para solicitar una cobertura superior a {noEvidenceMaximumValue}. Pondremos a tu disposici\xF3n este formulario luego de que completes tu inscripci\xF3n.",
  EVIDENCE_OF_INSURABILITY_BANNER_TEXT: "Debes completar y enviar un formulario de {eoi} para solicitar {benefitName}. Pondremos a tu disposici\xF3n este formulario luego de que completes tu inscripci\xF3n.",
  EVIDENCE_THIS_FORM: "este formulario",
  EVIDENCE_OF_INSURABILITY_TEXT_NOTE: "Nota: si ya est\xE1s inscrito en este beneficio, entonces no necesitas enviar la EOI, salvo que est\xE9s eligiendo aumentar tu cobertura.",
  EVIDENCE_OF_INSURABILITY_TEXT_KPMG: "Para solicitar este beneficio, deber\xE1s proveer una Evidencia de asegurabilidad. Por favor, completa y env\xEDa una edici\xF3n de {questionnaireLink} a {vendor}.",
  EVIDENCE_OF_INSURABILITY_TEXT: "Para solicitar este beneficio deber\xE1s completar una Evidencia de asegurabilidad y enviarla a {vendor} para su aprobaci\xF3n. League realizar\xE1 un seguimiento con las instrucciones para completar y enviar el formulario de Evidencia de asegurabilidad a {vendor}.",
  WHO_DOES_THIS_COVER: "\xBFQu\xE9 cubre esto?",
  WHAT_DOES_THIS_MEAN: "\xBFQu\xE9 significa esto?",
  WHAT_FOR: "\xBFPara qu\xE9 es esto?",
  EVIDENCE_OF_INSURABILITY_TOOLTIP: "Para aplicar para este beneficio se requiere una Evidencia de asegurabilidad (EOI). Deber\xE1s completar y enviar un formulario de EOI (que incluye un cuestionario de salud) para aprobaci\xF3n por parte de {vendor} antes de que el monto de cobertura elegido entre en vigor.\n\nTen en cuenta por favor que puede ser necesaria informaci\xF3n m\xE9dica adicional, la cual puede serte solicitada por un tercero a trav\xE9s de una cita personal.",
  EVIDENCE_OF_INSURABILITY_US_TOOLTIP: "Si elegiste un monto de cobertura m\xE1s alto que el monto de garant\xEDa asegurado (GI), necesitar\xE1s completar un formulario de solicitud de Evidencia de asegurabilidad (EOI). Esto iniciar\xE1 un proceso de solicitud que requerir\xE1 que brindes informaci\xF3n sobre tu salud. Una vez que completes la solicitud del EOI, deber\xE1 ser revisada y aprobada por {vendor} antes de que tu monto de cobertura total entre en vigor.\n\nTu monto de cobertura se basar\xE1 en el GI hasta que tu EOI sea aprobado por {vendor}. Tu costo por periodo de pago es lo que puedes esperar pagar luego de la aprobaci\xF3n del EOI por tu {vendor}. League se coordinar\xE1 con {vendor} para darte instrucciones de c\xF3mo completar el formulario EOI.",
  WHY_CONTRIBUTE: "\xBFPor qu\xE9 contribuir?",
  SHORT_DESC_WITH_EMPLOYER_CONTRIBUTION: "La contribuci\xF3n de tu empleador no cuenta para el l\xEDmite de tu contribuci\xF3n anual.",
  LPFSA_DESC: "Puedes contribuir d\xF3lares antes de impuestos a una cuenta flexible de gastos con fines limitados (LPFSA) para pagar gastos calificados de salud dental y visi\xF3n, hasta por el l\xEDmite de contribuci\xF3n anual. El monto que asignes se deducir\xE1 de tu cheque de pago de manera uniforme durante el a\xF1o.",
  HFSA_SHORT_DESC: "Una Cuenta de gastos flexible de atenci\xF3n m\xE9dica (HCFSA) es una cuenta con ventajas fiscales que puedes utilizar para ahorrar y pagar los gastos m\xE9dicos, dentales y oftalmol\xF3gicos elegibles que no est\xE9n cubiertos por ning\xFAn otro medio. La cantidad que elijas aportar se deducir\xE1 de manera uniforme en tus n\xF3minas a lo largo del a\xF1o. {employerContributionDesc}",
  DCFSA_SHORT_DESC: "<p>Una Cuenta de gastos flexible para el cuidado de dependientes (DCFSA) es una cuenta con ventajas fiscales que puedes utilizar para ahorrar y pagar los gastos que cumplan los requisitos, como la guarder\xEDa, el preescolar o el cuidado extraescolar de una persona que cumpla los requisitos. La cantidad que elijas aportar se deducir\xE1 de manera uniforme en tus n\xF3minas a lo largo del a\xF1o.</p><strong>Nota</strong> Los l\xEDmites de las aportaciones a esta cuenta tambi\xE9n depender\xE1n de si contribuyes como familia o si est\xE1s casado pero declaras los impuestos por separado.{employerContributionDesc}",
  FSA_SHORT_DESC: "Si tienes algunos objetivos personales de mejora personal este a\xF1o, \xA1dedicar algunos fondos a esta cuenta puede ser un paso en la direcci\xF3n correcta para lograrlos! Todos los fondos agregados a esta cuenta pueden ser usados para pagar gastos calificados de Acondicionamiento f\xEDsico. Este es un beneficio que paga impuestos, por lo que se cobrar\xE1n impuestos sobre el dinero gastado proveniente de esta cuenta.",
  LDSA_SHORT_DESC: "Si tienes algunos objetivos de mejora personal este a\xF1o, \xA1dedicar algunos fondos a esta cuenta es un paso en la direcci\xF3n correcta para lograrlos! Todos los fondos agregados a esta cuenta pueden ser usados para pagar por gastos calificados de Aprendizaje y desarrollo. Este es un beneficio que causa impuestos, por lo que se cobrar\xE1n impuestos sobre el dinero gastado proveniente de esta cuenta.",
  SHORT_DESC_WITH_POST_TAX_CONTRIBUTION: "Todos los fondos que contribuyas m\xE1s all\xE1 del l\xEDmite mensual causar\xE1n impuestos. ",
  CRA_SHORT_DESC: "Una Cuenta de reembolso del transporte (CRT) es una cuenta con ventajas fiscales que puedes utilizar para ahorrar y pagar gastos calificados de transporte y estacionamiento. {postTaxContributionDesc}El monto que elijas contribuir ser\xE1 deducido de manera uniforme de tus cheques de pago durante el a\xF1o.",
  HEALTH_SAVINGS_ACCOUNT_SHORT_DESC: "Una cuenta gastos de salud (CGS) es una cuenta a largo plazo con ventajas fiscales que puedes usar para tus gastos de salud. Eres due\xF1o de tu cuenta y tu saldo pasa de un a\xF1o al otro. El monto con el que eliges contribuir se deducir\xE1 de manera uniforme de tu cheque de pago durante el a\xF1o.",
  CHARITY_SHORT_DESC: "Usa tu Cuenta donaciones a instituciones de caridad (CDC) para donar a las instituciones de caridad registradas de tu elecci\xF3n.",
  PENSION_SHORT_DESC: "Una cuenta de ahorro personal para tu jubilaci\xF3n (PRSA) es un beneficio flexible de pensi\xF3n que ofrece un desahogo a los impuestos a las contribuciones.",
  TFSA_SHORT_DESC: "<p>El dinero no crece en los \xE1rboles, pero si crece en un TFSA. Un TFSA es una cuenta de inversi\xF3n patrocinada por el gobierno que puedes usar para aumentar tus ahorros e invertir en fondos mutuos. El ingreso que se generen de esos fondos en tu TFSA es, como podr\xE1s suponerlo, libre de impuestos.{br}S\xE9 consciente de tu l\xEDmite personal al momento de hacer tu contribuci\xF3n.</p>",
  RRSP_SHORT_DESC: "<p>Ahorra para tu futuro personal. Puedes usar un RRSP para ahorrar para tu jubilaci\xF3n y comprar diferentes inversiones o como ayuda para comprar tu primera casa. Toda contribuci\xF3n que hagas al RRSP est\xE1 exenta de impuestos. </p>{br} S\xE9 consciente de tu l\xEDmite personal al momento de hacer tu contribuci\xF3n.",
  HRA_SHORT_DESC: "<p>Dado que est\xE1s inscrito en el plan m\xE9dico calificado Acuerdo de reembolso de gastos de salud (HRA), {employerName} financiar\xE1 un HRA para ayudarte a pagar tus gastos elegibles de salud.</p>Los HRA son propiedad del empleador y se financian solamente con contribuciones del empleador.",
  LSA_SHORT_DESC: "<p>Aviva tu estilo de vida con {name} ({shortName}). Puedes utilizar los fondos de esta cuenta para comprar diversos productos o servicios de la forma que quieras.</p>Ten en cuenta que {shortName} es un beneficio imponible, lo que significa que tendr\xE1s que pagar el impuesto sobre la renta por el dinero que gastes de esta cuenta.",
  HSA_SHORT_DESC: "Cuida de tu bienestar con {name}{shortName}. Puedes usar esta cuenta para ayudar a satisfacer tus necesidades de salud, cubrir citas y servicios de cuidado dental, de la visi\xF3n, incluyendo profesionales registrados tales como masajistas, acupunturistas, quiropr\xE1cticos, etc.{br}Esta cuenta es libre de impuestos, lo que significa que no tendr\xE1s que pagar impuestos sobre el dinero que gastes proveniente de ella (Excepci\xF3n: {shortName} paga impuestos en Quebec).",
  VENDOR: "Vendedor",
  YOUR_SPENDING_ACCOUNT_COVERS: "Lo que cubre tu {accountType}",
  ACCOUNT_DESCRIPTION_COPY: "Lee todos los detalles sobre tu cobertura",
  ACCOUNT_LINK_COPY: "+ mucho m\xE1s",
  DEFAULT_DESCRIPTION_CURRENCY_INPUT: "Ingresa tu monto personalizado",
  SUBMIT_SELECTIONS: "Enviar elecciones",
  CONFIRM_SELECTIONS_TEXT_EXTENDED: "No tienes por qu\xE9 temer, puedes volver atr\xE1s y actualizar tus opciones antes de que sean definitivas. Tienes hasta el final de tu periodo de inscripci\xF3n para hacerlo. Aseg\xFArate de enviar cada vez que hagas cambios a tus opciones de plan.",
  CONFIRM_SUBMISSION: "Al enviar certificas que has brindado informaci\xF3n exacta.",
  CONFIRM_SELECTIONS_FINAL: "Una vez enviadas tus elecciones, ser\xE1n definitivas.",
  CONFIRM_SELECTIONS_TEXT: "Al completar tu inscripci\xF3n, certificas que brindaste informaci\xF3n exacta.",
  CONFIRM_YOUR_SELECTIONS: "Confirma tus elecciones",
  REVIEW: "Revisar",
  PRINT: "Imprimir",
  MORE_OPTIONS: "M\xE1s opciones",
  YOUR_BENEFITS_SUMMARY_TEXT: "En la siguiente pantalla, ver\xE1s el resumen final de tus elecciones.",
  PAST_PLANS: "Plan(es) pasado(s)",
  CURRENT_PLAN: "Plan actual",
  UPCOMING_PLAN: "Plan pr\xF3ximo",
  PLAN_YEAR: "A\xF1o del plan",
  YOUR_BENEFITS_SUMMARY: "Resumen de tu cobertura",
  BENEFITS_SUMMARY: "Resumen de los beneficios",
  REVIEW_PLAN: "Revisar plan",
  REVIEW_YOUR_PLAN: "Siguiente: revisar tu plan",
  CHOOSE_PLAN: "Elegir plan",
  CHOOSE_YOUR_PLAN: "Siguiente: elegir tu plan",
  NEXT_BENEFIT_BUTTON: "{benefitType, select, other {Revisar {benefitType}} Accident {Revisar Seguro de accidente} Dental {Revisar Seguro dental} Disability {Revisar Discapacidad a largo plazo} Drug {Revisar Seguro de medicamentos} Life {Revisar Seguro de vida} Medical {Revisar Seguro m\xE9dico} Wellness {Revisar Cuentas de bienestar}}",
  NEXT_BENEFIT_HEADING: "Siguiente: revisar tu cobertura {benefitType}",
  QUESTION_MARK: "?",
  BENEFIT_IS_INELIGIBLE: "Este beneficio no es elegible",
  SELECT_THIS_PLAN: "Elegir este plan",
  CHOOSE_COVERAGE_RIGHT_FOR_YOU: "Tu cobertura {benefitName}",
  NO_WAIVE_COVERAGE: "No, renunciar a la cobertura",
  YES_ENROLL_IN_COVERAGE: "Si, inscribir para cobertura",
  WHO_WILL_YOU_BE_ENROLLING: "\xBFA qui\xE9n inscribir\xE1s para recibir cobertura?",
  WOULD_YOU_LIKE_TO_ENROLL: "\xBFDeseas inscribirte en la cobertura {benefitName}?",
  SELECT_ONE: "Selecciona una",
  SELECT_AN_OPTION: "Selecciona una opci\xF3n",
  NA: "N/A",
  CHOOSE_PLAN_INDICATOR: "\xA1Podr\xE1s revisar el plan elegido luego de realizar tu selecci\xF3n!",
  PLAN_SELECTION: "Elecci\xF3n del plan",
  DEPENDENT_COVERAGE_SELECT_DEPENDENTS_LINK_LABEL: "secci\xF3n dependientes",
  FAMILY_MEMBER: "Miembro de la familia",
  OTHER_DEPENDENT_TYPE_FOOTNOTE: "*Solo elegible para cuentas de gastos",
  OTHER_DEPENDENT_TYPE_DESCRIPTION: "Debe ser residente de Canad\xE1 y depender financieramente de ti",
  CHILD_DEPENDENT_TYPE_DESCRIPTION: "Debe ser tu hijo/a o el hijo/a de tu c\xF3nyuge/pareja",
  PARTNER_ALREADY_ADDED: "Ya has agregado un c\xF3nyuge/pareja",
  PARTNER_DEPENDENT_TYPE_DESCRIPTION_US: "Debe ser alguien con quien est\xE9s casado o cohabites",
  PARTNER_DEPENDENT_TYPE_DESCRIPTION: "Debe ser alguien con quien est\xE9s casado o quien es tu pareja de hecho",
  OTHER_FAMILY_MEMBERS: "Otros miembros de la familia*",
  DEPENDENT_COVERAGE_SELECT_ADD_DEPENDENTS: "\xBFNecesitas agregar otro dependiente? Dir\xEDgete a {link} para hacer esa elecci\xF3n. Guardaremos tus elecciones actuales.",
  DEPENDENT_COVERAGE_SELECT_HEADER: "\xBFA qui\xE9n vas a inscribir para la cobertura {benefitName}?",
  CURIOUS_ABOUT: "Interesado en",
  ADD_FAMILY_MEMBER: "Agregar miembro de la familia",
  ADD_PARTNER: "Agregar pareja",
  ADD_CHILD: "Agregar hijo/a",
  NO_DEPENDENTS_DESCRIPTION: "Puedes pasar a la siguiente etapa si no tienes alguien para agregar en este momento.",
  REVIEW_DEPENDENTS: "Revisar dependientes",
  NEXT_UP_DEPENDENTS: "Pr\xF3ximo: Dependientes",
  YOUR_PROFILE_SECTION_SUBHEADING_INTEGRATION: "Hemos completado previamente algunos campos con base en la informaci\xF3n recibida de {employerName}. Por favor, verifica que la informaci\xF3n es correcta y completa tu inscripci\xF3n. Toma en cuenta que todo cambio realizado aqu\xED tambi\xE9n ser\xE1 actualizado en tu sistema de RR. HH.",
  YOUR_PROFILE_SECTION_SUBHEADING: "Hemos completado previamente algunos campos con base en la informaci\xF3n recibida de {employerName}. Puedes editarlos si as\xED lo deseas.",
  YOUR_PROFILE_SECTION_HEADING: "\xA1Hola! Vamos a conocernos un poco.",
  PROFILE_PAGE_HEAD: "Configurar tus beneficios",
  DEPENDENTS: "Dependientes",
  YOUR_PROFILE: "Tu perfil",
  DIVISION_ADD_ERROR: "Se produjo un error al borrar la divisi\xF3n de facturaci\xF3n. \xA1Verifica que el nombre no est\xE9 ya en uso!",
  DIVISION_DELETE_ERROR: "Se produjo un error al borrar la divisi\xF3n de facturaci\xF3n. Verifica que no haya empleados en la divisi\xF3n.",
  DIVISION_DELETED: "\xA1La divisi\xF3n de facturaci\xF3n se borr\xF3 con \xE9xito!",
  DIVISIONS: "Divisiones",
  ADD_BILLING_DIVISION: "Agregar divisi\xF3n de facturaci\xF3n",
  BILLING_DIVISIONS: "Divisi\xF3n de facturaci\xF3n",
  INVOICE_DIAGNOSTICS: "Diagn\xF3sticos de facturas",
  STATEMENT_OF_ACCOUNT: "Estado de cuenta",
  GET_INVOICE_DIAGNOSTICS: "Recibir diagn\xF3sticos de facturas",
  GET_STATEMENT_OF_ACCOUNT: "Recibir estado de cuenta",
  CSV_LINK: ".CSV",
  PDF_LINK: ".PDF",
  DRY_RUN: "Ejecutar Dryrun",
  CLEAR_INVOICE_DIAGNOSTICS_ERROR: "Error al borrar los diagn\xF3sticos de facturaci\xF3n",
  CLEAR_INVOICE_DIAGNOSTICS_SUCCESS: "Diagn\xF3sticos de facturaci\xF3n borrados",
  GET_INVOICE_DIAGNOSTICS_ERROR: "Error al recibir los diagn\xF3sticos de facturaci\xF3n",
  GET_STATEMENT_OF_ACCOUNT_ERROR: "Error al recibir el estado de cuenta",
  DRY_RUN_ERROR: "Error al recibir la factura Dryrun",
  INDIVIDUAL: "individuo",
  FAMILY: "familia",
  SUCCESSFULLY_UPDATED_ALLOCATION: "\xA1La asignaci\xF3n se actualiz\xF3 con \xE9xito!",
  FOR_THE_POLICY_PERIOD: "para el periodo de la p\xF3liza",
  ZERO_CONTRIBUTION_LIMIT_DEFAULT: "No puedes agregar fondos a esta cuenta en este momento. Contacta a nuestro equipo de Atenci\xF3n al cliente para m\xE1s informaci\xF3n.",
  ZERO_CONTRIBUTION_LIMIT_HSA: "Debes estar inscrito en un plan Medical elegible para una Cuenta gastos salud (CGS) antes del primero de diciembre para poder contribuir a esta cuenta.",
  CONTRIBUTION_LIMIT_SOFT_AND_MAX: "Puedes aportar hasta <strong>{maxAmount}</strong> a esta cuenta. Las contribuciones hasta <strong>{amount}</strong> ser\xE1n antes de impuestos.",
  PERSONAL_CONTRIBUTION_LIMIT_MAX: "Tu l\xEDmite de contribuci\xF3n personal para esta cuenta es <strong>{amount}</strong>{contributionFrequency}.",
  LEARN_MORE_ABOUT_HSA_CONTRIBUTION_LIMIT: "Conoce m\xE1s informaci\xF3n sobre el l\xEDmite de contribuci\xF3n personal de la HSA",
  CONTRIBUTION_LIMIT_MAX: "El l\xEDmite de tu contribuci\xF3n para esta cuenta de <strong>{amount}</strong>{contributionFrequency}.",
  CONTRIBUTION_LIMIT_SOFT: "Puedes contribuir hasta <strong>{amount}</strong> antes de impuestos, cualquier monto que exceda ese l\xEDmite causar\xE1 impuestos.",
  QLE_CONTRIBUTIONS_RESTRICTIONS_INFO: "Dado que est\xE1s en una inscripci\xF3n por un Evento de vida calificado (QLE), no podr\xE1s agregar o editar fondos a ciertas cuentas en esta p\xE1gina. Tendr\xE1s la oportunidad de agregar o editar fondos durante tu pr\xF3xima inscripci\xF3n anual.",
  LEFTOVER_FLEX_DOLLAR: "{flexDollarTerm} restantes",
  WHY_ARE_MY_FUNDS_PRORATED: "\xBFPor qu\xE9 se prorratean mis fondos?",
  PRORATED_FUNDS: "Fondos prorrateados",
  PRORATION_CALCULATION_EXAMPLE: "$500 x 6/12 (meses restantes en el periodo de tu p\xF3liza) = $250",
  PRORATION_BANNER_TOOLTIP_REASON_MOBILE: "Dado que est\xE1s a mitad del periodo de tu p\xF3liza, los fondos agregados ser\xE1n prorrateados con base en el n\xFAmero de {periodName} que quedan en el periodo de tu p\xF3liza.",
  PRORATION_BANNER_TOOLTIP_REASON: "Dado que est\xE1s a mitad del periodo de tu p\xF3liza, solo recibir\xE1s parte de este monto cuando asignes fondos a tu cuenta. En este caso, terminar\xE1s recibiendo la mitad.",
  PRORATION_BANNER_TOOLTIP_EXAMPLE: "Digamos que el periodo de tu p\xF3liza comenz\xF3 el primero de enero y que tienes un Evento de vida calificado (QLE) en julio. Realizas ciertos cambios en tus elecciones y terminas con $500 {flexDollarTerm}. Este monto es para el periodo completo de tu p\xF3liza.",
  LEARN_MORE_ABOUT_PRORATION: "M\xE1s informaci\xF3n sobre el prorrateo",
  SEE_AN_EXAMPLE_OF_PRORATION: "Ver un ejemplo de prorrateo",
  PRORATION_BANNER_CONTENT: "Estamos a la mitad del periodo de tu p\xF3liza. Todos los fondos asignados a estas cuentas ser\xE1n prorrateados con base en el n\xFAmero de {periodName} restantes en el periodo de tu p\xF3liza {prorationExample}.",
  PRORATION_BANNER_HEADING: "Advertencia: los fondos asignados a estas cuentas ser\xE1n prorrateados.",
  ADD_FUNDS_OVER_ALLOCATION_ERROR: "\xA1Parece que est\xE1s intentando agregar m\xE1s fondos de los disponibles en tu saldo!",
  ADD_FUNDS_EDIT_ALLOCATION_ERROR: "Este monto es el mismo que ingresaste anteriormente",
  ADD_FUNDS_NO_ALLOCATION_ERROR: "Por favor, agrega fondos aqu\xED para proceder",
  PRORATION_EQUATION: "{fullAmount} x {periodsRemaining}/{totalPeriods}{periodName} restantes en el periodo de la p\xF3liza = {proratedAmount}",
  PRORATED_EXPLAINER_TEXT: "Dado que tienes <strong>{periodsRemaining}{periodName}</strong> restantes en tu p\xF3liza, tomamos el monto total y lo multiplicamos por <strong>{periodsRemaining}{totalPeriods}</strong> para calcular este monto prorrateado.",
  CALCULATING_PRORATED_AMOUNT: "Calcular monto prorrateado",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_BODY_WITH_EMPLOYER_CONTRIBUTION: "Hemos calculado tu l\xEDmite de contribuci\xF3n personal tomando el l\xEDmite anual del IRS y rest\xE1ndole la contribuci\xF3n de tu empleador para el periodo de la p\xF3liza. Si tienes 55 a\xF1os o m\xE1s este a\xF1o, tu l\xEDmite de contribuci\xF3n personal incluye un l\xEDmite de contribuci\xF3n adicional para ponerse al d\xEDa de {catchUpLimit}.",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_BODY_NO_EMPLOYER_CONTRIBUTION: "Tu l\xEDmite de contribuci\xF3n personal est\xE1 basado en el l\xEDmite anual del IRS. Si tienes 55 a\xF1os o m\xE1s, tu l\xEDmite personal incluye un l\xEDmite adicional de contribuci\xF3n para ponerse al d\xEDa de {catchUpLimit}",
  HSA_CONTRIBUTION_GUIDANCE_MODAL_HEADER: "Puedes contribuir hasta {employeeContributionLimit} para este periodo de la p\xF3liza.",
  HSA_CONTRIBUTION_FINAL_GOAL_WITH_EMPLOYER_CONTRIBUTION: "Finalmente, si te es posible, puedes contribuir hasta <strong>{employeeContributionLimit} por el periodo de la p\xF3liza</strong>. Esto tiene en cuenta la contribuci\xF3n de tu empleador de <strong>{employerContribution} por el periodo de la p\xF3liza</strong>.",
  HSA_CONTRIBUTION_FINAL_GOAL_NO_EMPLOYER_CONTRIBUTION: "Finalmente, si est\xE1 en posibilidades, puedes contribuir hasta <strong>{employeeContributionLimit} por el periodo de la p\xF3liza </strong>.",
  HSA_CONTRIBUTION_NEXT_GOAL: "Tu siguiente objetivo puede ser cubrir el m\xE1ximo de gastos de bolsillo <strong>{outOfPocketMax} de tu {dependentCoverageType} durante el periodo de la p\xF3liza </strong>.",
  HSA_CONTRIBUTION_FIRST_GOAL: "Tu primer objetivo puede apuntar a cubrir el deducible de <strong>{deductible} de tu {dependentCoverageType} durante el periodo de la p\xF3liza </strong>.",
  HSA_CONTRIBUTION_GUIDANCE_SUBHEADING: "Los expertos financieros recomiendan los siguientes objetivos al momento de contribuir a tu CGS:",
  CONTRIBUTION_GUIDANCE_HEADER: "\xBFCu\xE1nto deber\xEDa contribuir?",
  OKAY: "Bien",
  EDIT_CONTRIBUTION_ERROR_NO_CHANGE_VALUE: "Por favor, ingresa una nueva contribuci\xF3n para continuar",
  EDIT_CONTRIBUTION_SUCCESS_CRA: "Ver\xE1s que tus nuevas deducciones en la n\xF3mina de pago se har\xE1n efectivas dentro de la segunda a tercera fecha de pago luego de completada tu inscripci\xF3n.",
  EDIT_CONTRIBUTION_SUCCESS_DEFAULT: "Ver\xE1s que tus nuevas deducciones en la n\xF3mina de pago se har\xE1n efectivas dentro de la primera a segunda fechas de pago luego de completada tu inscripci\xF3n.",
  EDIT_CONTRIBUTION_WARNING: "Si tu nueva contribuci\xF3n est\xE1 por debajo de lo que ya has contribuido para el plan de este a\xF1o, tu deducci\xF3n en la nomina de pago en adelante ser\xE1 de $0. No te ser\xE1 reembolsado ning\xFAn fondo de deducciones previas en la n\xF3mina de pago.",
  NEW_CONTRIBUTION_NOTE: "Tu contribuci\xF3n personal puede ascender hasta {amount}",
  NEW_CONTRIBUTION: "Nueva contribuci\xF3n",
  CURRENT_CONTRIBUTION_NOTE: "Este es el monto con el cual elegiste contribuir durante tu anterior inscripci\xF3n.",
  CURRENT_CONTRIBUTION: "Contribuci\xF3n actual",
  BENEFIT_NAME_CONTRIBUTION_UPDATED: "\xA1Contribuci\xF3n a {benefitName} actualizada!",
  BENEFIT_NAME_CONTRIBUTION: "Contribuci\xF3n a {benefitName}",
  EDIT_CONTRIBUTION_OF_BENEFIT: "Editar contribuci\xF3n de {benefitName} de {vendorName}",
  EDIT_CONTRIBUTION: "Editar contribuci\xF3n",
  CHANGE_CONTRIBUTION: "Cambiar contribuci\xF3n",
  ADD_FLEXIBLE_DOLLARS_TO_BENEFIT: "Agregar {flexDollarTerm} a {benefitName} de {vendorName}",
  ADD_FLEXIBLE_DOLLARS: "Agregar {flexDollarTerm}",
  CONTRIBUTION_AFTER_PRORATION: "Contribuci\xF3n luego del prorrateo",
  ADD_FUNDS_MODAL_TEXT: "Agrega tantos fondos como desees del saldo de tu {flexDollarTerm} a esta cuenta. Si no utilizas tu saldo, el resto se agregar\xE1 autom\xE1ticamente a tu {defaultFlexAccount}.",
  YOUR_FLEX_DOLLAR_BALANCE: "Tu saldo {flexDollarTerm}: ",
  PRORATED_CONTRIBUTION_DETAILS_MODAL_DESCRIPTION: "Estamos a la mitad del periodo de tu p\xF3liza. Los fondos contribuidos a esta cuenta han sido prorrateados con base en el n\xFAmero de {periodName} restantes en el periodo de tu p\xF3liza.",
  EDIT_FUNDS_MODAL_HEADER: "Editar {flexDollarTerm} en tu {benefitName}",
  ADD_FUNDS_MODAL_HEADER: "Agregar {flexDollarTerm} a tu {benefitName}",
  SEARCH_COVERAGE: "Buscar tu cobertura",
  RESTRICTIONS: "Restricciones",
  EXAMPLES: "Ejemplos: ",
  COLLAPSE_ALL: "Colapsar todo",
  EXPAND_ALL: "Expandir todo",
  MORE_DETAILS_ON_THE_BENEFIT: "M\xE1s detalles en {benefitShortName}",
  VIEW_COVERAGE: "Ver cobertura",
  VIEW_YOUR_COVERAGE: "Ver tu cobertura",
  COVERED_WITH_RESTRICTIONS: "Cubierto con restricciones",
  NOT_COVERED: "No cubierto",
  COVERED: "Cubierto",
  INCORRECT_AMOUNT: "Monto incorrecto",
  NEW_FUNDS: "Nuevos fondos",
  CURRENT_FUNDS: "Fondos actuales",
  CHANGES_WILL_AFFECT_OVERALL_POLICY: "Todo cambio realizado a los fondos del usuario afectar\xE1n la totalidad de su p\xF3liza, incluido el prorrateo y las asignaciones peri\xF3dicas.",
  ALLOCATION_UNDER_WELLNESS_POOL: "<strong>\xA1No seas tan medido!</strong> No has gastado tu asignaci\xF3n total de {amount}.",
  ALLOCATION_EXCEEDS_CONTRIBUTION_LIMIT: "<strong>\xA1Eh derrochador!</strong> Parece que has excedido un l\xEDmite de contribuci\xF3n",
  ALLOCATION_EXCEEDS_WELLNESS_POOL: "<strong>\xA1Eh, derrochador!</strong> Has excedido el monto de tu asignaci\xF3n para tu {flexTerm}. Por favor, procede a la reasignaci\xF3n de fondos para no exceder {amount}.",
  EDIT_SPENDING_ACCOUNT_ALLOCATIONS: "Editar las asignaciones de la cuenta de gastos",
  CHANGE_ALLOCATION_DETAILS: "Cambiar los detalles de la asignaci\xF3n",
  BUNDLED_BENEFIT_SELECTED_CONTENT: "Hemos actualizado tu plan {bundledBenefitName} a <strong> {bundledBenefitPlanName}</strong>para que corresponda a tu selecci\xF3n para <strong>{selectedBenefitPlanName}</strong>",
  BUNDLED_BENEFIT_SELECTED_HEADER: "Tu {selectedBenefitName} y planes {bundledBenefitName} forman parte del mismo paquete.",
  LINKED_BENEFIT_REMOVED_CONTENT: "Ya no podr\xE1s ver este beneficio en la p\xE1gina {benefitSetName}.",
  LINKED_BENEFIT_ADDED_CONTENT: "Podr\xE1s ver este beneficio en la p\xE1gina {benefitSetName}.",
  LINKED_BENEFIT_REMOVED_HEADER: "\xA1{benefitName} quitado!",
  LINKED_BENEFIT_ADDED_HEADER: "\xA1{benefitName} agregado!",
  DEPENDENT_REMOVED_ERROR: "Error al quitar el dependiente",
  DEPENDENT_REMOVED: "Dependiente quitado",
  DEPENDENT_UPDATE_ERRORED: "Se ha producido un error al guardar el dependiente",
  DEPENDENT_UPDATED: "Dependientes guardados",
  DEPENDENT_TOOLTIP: "Los dependientes pueden ser hijos/as o nietos/as de un empleado (tanto biol\xF3gicos como adoptados o hijastros/as), padres/madres o abuelos/as, hermanos y hermanas (incluyendo cu\xF1ados/as) sobrinos/as y t\xEDos/as.",
  SPOUSE_TOOLTIP: "Un c\xF3nyuge es una persona legalmente casada o en pareja de hecho con un empleado.",
  DEPENDENT_CLASS_DESCRIPTION_COUPLE_FAMILY: "parte de una {family} con al menos un dependiente",
  DEPENDENT_CLASS_DESCRIPTION_FAMILY: "parte de una familia con al menos 1 dependiente diferente del c\xF3nyuge",
  DEPENDENT_CLASS_DESCRIPTION_COUPLE: "con un {spouse} y ning\xFAn otro {dependents}",
  DEPENDENT_CLASS_DESCRIPTION_SINGLE: "que son solteros sin {spouse} ni {dependents}",
  EMPLOYEE_ALLOCATION_LABEL: "Los individuos {dependentClassDescription} recibir\xE1n",
  ONE_TIME_TOTAL_ALLOCATION_SUMMARY: "Cada total de asignaci\xF3n de p\xF3liza se pagar\xE1 cada {frequency} durante el periodo de la p\xF3liza comenzando el {startDate}",
  TOTAL_ALLOCATION_SUMMARY: "Cada total de asignaci\xF3n de p\xF3liza se dividir\xE1 en cuotas iguales a pagar cada {frequency} entre el {startDate} y el {endDate}.",
  MISSING_AMOUNT_TO_BE_ADDED: "Monto desaparecido a agregar",
  MISSING_SPENDING_ACCOUNT: "Cuenta de gastos desaparecida",
  ACCOUNT: "Cuenta",
  ADD_FUNDS_SUCCESS: "\xA1Bien hecho! Los fondos se han agregado. El nuevo saldo de {userName} es {balance}",
  FUNDS_TO_BE_ADDED: "Fondos a agregar",
  CUSTOMER_SUPPORT: "Servicio de apoyo al cliente",
  CONTACT_CUSTOMER_SUPPORT: "Contacta el servicio de apoyo al cliente",
  THESE_FUNDS_ARE_ONE_TIME_ALLOCATION: "Estos fondos se agregan por \xFAnica vez. Si necesitas realizar alg\xFAn cambio a la alocaci\xF3n total, {linkToCustomerSupport}.",
  ADD_FUNDS_EMPLOYEE: "Agregar fondos a {employee}",
  ADD_FUNDS: "Agregar fondos",
  REMOVE_ADMIN_ROLE: "Eliminar Rol de administrador",
  ADD_ADMIN_ROLE: "Agregar Rol de administrador",
  BALANCE: "Saldo",
  AMOUNT_CONTRIBUTION: "{amount} de contribuci\xF3n",
  AMOUNT_ALLOCATION: "{amount} de asignaci\xF3n",
  ALLOCATION: "Asignaci\xF3n",
  ACTION: "Acci\xF3n",
  DUPLICATE_BENEFIT_NAME_WARNING: "M\xFAltiples beneficios de este tipo tienen el mismo nombre. Considera darles nombres diferentes.",
  GENERATE_CSV_ERROR_STATE_MESSAGE: "\xA1Algo sali\xF3 mal! Por favor, vuelve a intentarlo. Si el problema persiste, contacta a {emailLink} para recibir asistencia.",
  USAGE_HISTORY: "Obtener el informe de uso",
  GENERATE_CSV: "Generar CSV",
  DOWNLOAD_ENROLLMENT_REPORT: "Descargar el informe de inscripci\xF3n",
  DOWNLOAD_A_CSV: "Descargar un informe que contenga todos los detalles del empleado, las selecciones del plan y la informaci\xF3n sobre los dependientes recolectada por League hasta la fecha",
  ENROLLMENT_REPORT: "Informe de inscripci\xF3n",
  DOWNLOAD_THE_LATEST: "Descargar los \xFAltimos informes en tu escritorio.",
  CONFIRM_UNSCHEDULE: "Confirmar desprogramaci\xF3n",
  UNSCHEDULE_REINSTATE_DESCRIPTION: "Por favor, confirma que deseas desprogramar la reincorporaci\xF3n de este empleado.",
  UNSCHEDULE_TERMINATION_DESCRIPTION: "Por favor, confirma que deseas desprogramar la baja de este empleado.",
  UNSCHEDULE_SUSPENSION_DESCRIPTION: "Por favor, confirma que deseas desprogramar la suspensi\xF3n de este empleado.",
  UNSCHEDULE_REINSTATE_HEADER: "Cancelar reincorporaci\xF3n",
  UNSCHEDULE_SUSPENSION_HEADER: "Cancelar suspensi\xF3n",
  UNSCHEDULE_TERMINATION_HEADER: "Cancelar baja",
  CONFIRM: "Confirmar",
  CANCEL: "Cancelar",
  TERMINATE: "Dar de baja",
  SUSPEND: "Suspender",
  REINSTATE: "Reincorporar",
  INTEGRATIONS_CONFIGURATION_SAVED: "Configuraci\xF3n de integraciones guardada con \xE9xito",
  TYPEFORM_URL: "URL de Typeform",
  BENEFIT_CLASS: "Categor\xEDa de beneficio",
  COPY_PASTE_TYPEFORM_URL: "Copiar y pegar un URL Typeform para agregar ese Typeform como un nuevo paso en el proceso de inscripci\xF3n. Se agregaron o quitaron categor\xEDas de la pesta\xF1a beneficios.",
  ENABLE_THIRD_PARTY_INTEGRATIONS_BY_FILLING_IN_FIELDS: "Activar la integraci\xF3n de terceros completando los campos asociados.",
  CONFIGURE_THIRD_PARTY_INTEGRATIONS: "Configurar la integraci\xF3n de terceros",
  UPDATE: "Actualizar",
  REJECT: "Denegar",
  REQUEST_MORE_INFORMATION: "Solicitar m\xE1s informaci\xF3n",
  CLAIM_NOTE: "El usuario recibir\xE1 este mensaje a trav\xE9s del historial de reclamaciones:",
  PAYMENT_ADJUSTMENT_NOTE: "Nota",
  PAYMENT_ADJUSTMENT_BILLING_DIVISION: "Divisi\xF3n de facturaci\xF3n",
  PAYMENT_ADJUSTMENT_AMOUNT: "Monto",
  PAYMENT_ADJUSTMENT_DATE: "Fecha",
  DUPLICATE_CLAIM_CONTACT_MANAGER: "Si no est\xE1s seguro de qu\xE9 acci\xF3n realizar, contacta a un gerente.",
  DUPLICATE_CLAIM_REJECT_DESCRIPTION: "Denegar la reclamaci\xF3n y enviar al miembro una respuesta preestablecida advirtiendo que esta reclamaci\xF3n est\xE1 duplicada.",
  DUPLICATE_CLAIM_REJECT_ACTION: "Denegar reclamaci\xF3n",
  DUPLICATE_CLAIM_APPROVE_DESCRIPTION: "Aprobar reclamaci\xF3n e ignorar la advertencia de reclamaci\xF3n duplicada.",
  DUPLICATE_CLAIM_APPROVE_ACTION: "Aprobar reclamaci\xF3n",
  DUPLICATE_CLAIM_CANCEL_DESCRIPTION: "No se indic\xF3 ninguna acci\xF3n, volver\xE1s a la pantalla previa.",
  DUPLICATE_CLAIM_CANCEL_ACTION: "Cancelar",
  DUPLICATE_CLAIM_INTRO: "Puedes elegir entre las siguientes opciones:",
  CLAIM_REF: "<ID de la reclamaci\xF3n: {referenceId}>",
  DUPLICATE_CLAIM_HEADER: "La reclamaci\xF3n que est\xE1s enviando es un duplicado de {claimLink}",
  SENT_FOR_EXTERNAL_REVIEW: "Enviado para revisi\xF3n externa",
  APPROVE: "Aprobar",
  CHOOSE_NEW_STATUS: "Elegir un nuevo estatus",
  UPDATE_STATUS: "Actualizar estatus",
  NO_ADJUSTMENTS: "No hay ajustes",
  MAKE_ADJUSTMENT: "Realizar ajustes",
  ADJUSTMENTS: "Ajustes",
  PIN_TO_SAVE_USER_DOCUMENT: "Seleccionar para guardar este documento en el perfil del usuario",
  EXPIRES: "Vencimiento",
  UNPIN_TO_REMOVE_USER_DOCUMENT: "Quitar la selecci\xF3n para eliminar del perfil del usuario",
  CONFIRM_DELETE_SUPPORTING_DOC_TEXT: "Esto borrar\xE1 este documento de apoyo de manera permanente. \xBFEst\xE1s seguro?",
  CONFIRM_REMOVE_SUPPORTING_DOC_TEXT: "Esto quitar\xE1 el documento de apoyo del perfil de usuario. \xBFEst\xE1s seguro?",
  DOCUMENT_NAME: "Nombre del documento",
  DOCUMENT_TYPE: "Tipo de documento",
  ALL_FIELDS_REQUIRED: "Todos los campos son requeridos salvo que est\xE9n marcados como opcionales",
  EDIT_DOCUMENT: "Editar documento",
  EDIT_SUPPORTING_DOCUMENT: "Editar documentos de apoyo",
  UPLOAD_SUPPORTING_DOCUMENT: "Cargar documentos de apoyo",
  ADD_DOCUMENT_INFORMATION: "Agregar documentos de informaci\xF3n",
  DOCUMENT_INFORMATION: "Documentos de informaci\xF3n",
  ATTACHED_TO_CLAIM: "Adjuntados a la reclamaci\xF3n",
  REMOVE_FROM_CLAIM: "Quitar de la reclamaci\xF3n",
  ATTACH_TO_CLAIM: "Adjuntar a la reclamaci\xF3n",
  SUPPORTING_DOCUMENTS: "Documentos de apoyo",
  CLAIM_DOCUMENTS: "Documentos de reclamaci\xF3n",
  ENTER_CODE: "Ingresar c\xF3digo",
  CREDIT_INFORMATION: "Informaci\xF3n del cr\xE9dito",
  TOTAL_CREDIT: "Cr\xE9dito total:",
  YOUR_CARD: "Tu tarjeta",
  ADD_CARD: "Agregar tarjeta",
  ADD_CREDIT_CARD_TEXT: "Para reservar servicios de salud en League, por favor, ingresa una tarjeta VISA, MasterCard o AMEX v\xE1lida.",
  SIMPLY_ADD_YOUR_CREDIT_CARD_TO: "Simplemente agrega tu tarjeta de cr\xE9dito a la aplicaci\xF3n, c\xE1rgala y env\xEDa una reclamaci\xF3n para reembolso.",
  CREDIT_CARD_INTRO: "Deja que la aplicaci\xF3n se encargue de tu factura.",
  CODE_ALREADY_REDEEMED: "Este c\xF3digo de promoci\xF3n ya fue canjeado",
  PROMO_CODE_SUCCESS: "\xA1C\xF3digo de promoci\xF3n canjeado!",
  PROMO_CODE_NOT_FOUND: "C\xF3digo de promoci\xF3n no v\xE1lido",
  NO_MORE_TRANSACTIONS: "No m\xE1s transacciones",
  NO_TRANSACTIONS_YET: "A\xFAn no hay transacciones",
  CGA: "CDC (Cuenta donaciones a instituciones de caridad)",
  CREDIT_CARD: "Tarjeta de cr\xE9dito",
  LEAGUE_CREDIT: "Cr\xE9dito League",
  REFUNDED: "Reembolsado",
  REFUND: "Reembolso",
  ADJUSTMENT: "Ajuste",
  TOP_UP: "Recargar",
  CREDIT: "Cr\xE9dito",
  MARKETPLACE: "Mercado",
  CLAIM: "Reclamaci\xF3n",
  ID: "ID",
  TYPE: "Tipo",
  DESCRIPTION: "Descripci\xF3n",
  TIME: "Hora",
  DATE: "Fecha",
  SAVE_CARD: "Guardar tarjeta",
  DEPENDENT_CARD_HOLDER: "Tarjeta para {dependentName}",
  MORE_ACTIONS: "M\xE1s acciones",
  CHILD_LOWERCASE: "Hijo/a",
  PARTNER_LOWERCASE: "pareja",
  OTHER: "Otro/a",
  SIBLING: "Hermano/a",
  INELIGIBLE_CHILD: "Hijo/a no elegible",
  NEPHEW_NIECE: "Sobrino/a",
  UNCLE_AUNT: "T\xEDo/a",
  GRANDCHILD: "Nieto/a",
  GRANDPARENT: "Abuelo/a",
  PARENT: "Padre/madre",
  CHILD: "Hijo/a",
  PARTNER: "Pareja",
  COMMON_LAW: "Pareja de hecho",
  SPOUSE: "C\xF3nyuge",
  RELATIONSHIP: "Relaci\xF3n",
  MEMBER_ID: "ID de miembro",
  VIEW_CARD: "Ver tarjeta",
  VIEW_DEPENDENTS_MORE_INFO_LINK_TEXT: "Ent\xE9rate qui\xE9nes califican como dependientes",
  VIEW_DEPENDENTS_DESCRIPTION_TEXT: "Tus dependientes tambi\xE9n pueden usar tu {benefitName} tanto como lo deseen.",
  MANAGE_DEPENDENTS: "Gestionar dependientes",
  PLEASE_CONTACT: "por favor contacta",
  IF_THE_PROBLEM_PERSISTS: "Si el problema persiste",
  IF_YOU_BELIEVE_THIS_IS_AN_ERROR: "Si crees que se trata de un error",
  IF_YOU_HAVE_AN_URGENT_CLAIM: "Si tienes una reclamaci\xF3n urgente",
  PLEASE_TRY_AGAIN_SHORTLY: "Por favor, vuelve a intentarlo en unos instantes",
  WE_CANT_CURRENTLY_ACCESS_YOUR_ACCOUNT_DETAILS: "En este momento no podemos acceder a los detalles de tu cuenta.",
  YOUR_ACCOUNT_EXPIRED_ON: "Tu cuenta vence el:",
  CLAIMS_FOR_PURCHASES_MADE_AFTER_DATE_WILL_BE_REJECTED: "Las reclamaciones por compras realizadas despu\xE9s del {endDate} ser\xE1n denegadas.",
  YOU_HAVE_UNTIL_DATE_TO_SUBMIT: "Tienes hasta el <strong>{inactiveDate}</strong> para enviar por compras anteriores al <strong>{endDate}</strong>.",
  YOUR_BENEFITS_WILL_BE_ACTIVE_ON: "Tus beneficios se activar\xE1n en:",
  YOUR_ACCOUNT_IS_ACTIVE_AND_IN_THE_PROCESS_OF_BEING_SET_UP: "Tu cuenta est\xE1 activa y en proceso de configuraci\xF3n.",
  YOU_CAN_USE_YOUR_ACCOUNT_ON: "Puedes usar tu cuenta en:",
  CURRENTLY_UNAVAILABLE: "No disponible en este momento",
  EXPIRED: "Vencido",
  EXPIRING_SOON: "Vence pr\xF3ximamente",
  ACTIVATION_PENDING: "Activaci\xF3n pendiente",
  CONTACT_CUSTOMER_SERVICE: "Contactar a servicio al cliente",
  NEED_HELP_TEXT: "Si tienes alguna pregunta sobre tu cobertura, \xA1nuestro equipo est\xE1 aqu\xED para ayudarte!",
  NEED_HELP: "\xBFNecesitas ayuda?",
  SELECT_A_BENEFIT_CARD_TO_SUBMIT_A_CLAIM: "Selecciona una tarjeta de beneficios para enviar una reclamaci\xF3n, ver tu cobertura, gestionar tus dependientes y m\xE1s.",
  SELECT_CARD: "Seleccionar tarjeta",
  VIEW_ALL_WITH_ARROW: "Ver todo >",
  NO_DONT_SKIP: "No, no saltar",
  YES_SKIP_GOAL: "Si, saltar este objetivo",
  THE_GOAL_WILL_BE_MARKED_INCOMPLETE: "El objetivo se marcar\xE1 como incompleto y en esta oportunidad no recibir\xE1s puntos por el mismo.",
  ARE_YOU_SURE_SKIP_GOAL: "\xBFEst\xE1s seguro de que deseas saltar este objetivo?",
  SKIP_THIS_ACTIVITY: "Saltar esta actividad",
  SUCCESS_UPDATING_EMPLOYER: "\xA1Bien hecho! El perfil se ha actualizado",
  ERROR_UPDATING_EMPLOYER: "\xA1Error! El perfil no pudo ser actualizado",
  NO_EMPLOYEE_BENEFITS_MESSAGER: "No hay beneficios disponibles.",
  PAYROLL_HEADER_DOWNLOAD: "Descargar",
  PAYROLL_HEADER_DATE_ISSUED: "Fecha de emisi\xF3n",
  PAYROLL_BILLING_DATE_RANGE: "{startDate} a {endDate}",
  PAYROLL_HEADER_BILLING_PERIOD: "Periodo de facturaci\xF3n",
  CLEAR_INVOICE_DIAGNOSTICS_CONFIRMATION: "<strong>Atenci\xF3n: </strong> Borrar los diagn\xF3sticos de facturas es una acci\xF3n definitiva que eliminar\xE1 todos los diagn\xF3sticos existentes del sistema de League.",
  CLEAR_INVOICING_DIAGNOSTICS: "Borrar el diagn\xF3stico de facturas:",
  INVOICE_DIAGNOSTIC_DOWNLOAD: "Descargar el informe diagn\xF3sticos de facturas: ",
  STATEMENT_OF_ACCOUNT_DOWNLOAD: "Descargar el estado de cuenta: ",
  DRY_RUN_CREATED: "Creado con Dryrun",
  DRY_RUN_GROUP_NAME: "Nombre del grupo Dryrun",
  DRY_RUN_DOWNLOAD: "Descargar Dryrun ",
  INVOICE_DRY_RUN: "Factura Dryrun",
  INVOICE_HEADER_DOWNLOAD: "Descargar",
  INVOICE_HEADER_STATUS: "Estatus",
  INVOICE_HEADER_DATE_ISSUED: "Fecha de emisi\xF3n",
  INVOICE_BILLING_DIVISION: "Divisi\xF3n de facturaci\xF3n",
  INVOICE_HEADER_INVOICE_NUMBER: "N\xFAmero de factura",
  NO_PAYROLL_MESSAGE: "No hay informes de n\xF3mina de pago disponibles.",
  PAYROLL_HEADER_COPY: "Esta secci\xF3n contiene los informes de la n\xF3mina de pago para cada periodo facturado. Los informes de la n\xF3mina de pago se generan junto con las facturas al comienzo de cada mes y cubren el mismo periodo que tu factura mensual.",
  PAYROLL_HEADER: "Informes de la n\xF3mina de pago",
  PAYROLL: "N\xF3mina de pago",
  BILLING_EMAIL: "billing@league.com",
  INVOICES_HELP_EMAIL_MESSAGE: "Si no encuentras las respuestas que est\xE1s buscando, por favor cont\xE1ctanos a {link}.",
  KNOWLEDGE_BASE_URL: "https://help.league.com/s/topic/0TO0d000000MbAxGAK/for-hr-admins?utm_source=Backend",
  KNOWLEDGE_BASE: "base de datos",
  INVOICES_HELP_KB_MESSAGE: "\xBFTienes alguna pregunta sobre tu factura? \xA1Revisa nuestro {link}!",
  NO_INVOICES_MESSAGE: "No hay facturas disponibles.",
  NO_BENEFITS_FOR_GROUP_MESSAGE: "No hay beneficios para este grupo.",
  NO_BENEFITS_FOR_PLAN_MESSAGE: "No hay beneficios para este plan.",
  NO_PLANS_MESSAGE: "No hay planes para esta categor\xEDa de beneficio.",
  NO_BENEFITS_MESSAGE: "No hay beneficios disponibles.",
  YOU_QUALIFY_FOR_A_REWARD_OF: "\xA1Califica para una recompensa de {amount} Cr\xE9dito League!",
  REDEEM_YOUR_REWARD_NOW: "\xA1Canjea ya mismo tu recompensa!",
  REMAINING_POINTS_AFTER_REDEMPTION: "Puntos restantes luego del canje: {point}",
  CURRENT_POINT_BALANCE: "Saldo actual de puntos: {points}",
  YOU_SUCCESSFULLY_REDEEMED: "Has canjeado con \xE9xito {points} puntos. \xA1Eso te alcanza para {dollars} en Cr\xE9dito League!",
  HOW_EXCITING: "\xA1Muy interesante!",
  REWARD: "Recompensa",
  POINTS_NEEDED: "Puntos necesarios",
  WHAT_ARE_MY_POINTS_WORTH: "\xBFQu\xE9 valor tienen mis puntos?",
  NO_EXPIRY_DATE: "No tiene fecha de vencimiento",
  EXPIRY_DATE: "Fecha de vencimiento",
  REDEEM_NOW: "Canjear ahora",
  YOUR_REWARD_POINTS: "Tus puntos de recompensa",
  DOLLARS_LEAGUE_CREDIT: "{dollars} d\xF3lares de Cr\xE9dito League",
  MORE_POINTS_TO_REACH: "Necesitas {points} puntos m\xE1s para alcanzar tu pr\xF3xima recompensa",
  MORE_POINTS_TO_REDEEM: "{points} m\xE1s puntos para canjear {leagueCredit}",
  YOUR_NEXT_REWARD: "Tu pr\xF3xima recompensa",
  REDEEM_REWARDS: "Canjear recompensas",
  STAY_IN_PROGRAM: "Permanecer en el programa",
  YOU_WILL_LOSE_ALL_PROGRESS_IN_PROGRAM: "Vas a perder todo tu progreso en este programa. Si decides volver a probar despu\xE9s, deber\xE1s recomenzar desde el principio.",
  ARE_YOU_SURE: "\xBFEst\xE1s seguro?",
  LEAVE_PROGRAM: "Salir del programa",
  MORE_DETAILS_ARROW: "M\xE1s detalles >",
  CURRENT_GOAL: "Objetivo actual",
  EXIT_ADMIN: "Tu cuenta",
  EMPLOYEE_UPDATE_ERROR_MESSAGE: "Por favor, vuelve a intentarlo. Si el problema persiste, contacta a ayuda@league.com",
  EMPLOYEE_UPDATE_ERROR_MESSAGE_HEADER: "El empleado no ha sido actualizado",
  EMPLOYEE_UPDATE_SUCCESS_MESSAGE: "\xA1Bien hecho! El empleado ha sido actualizado.",
  EMPLOYEE_CREATE_SUCCESS_MESSAGE: "\xA1Bien hecho! El empleado ha sido creado.",
  CHANGE_CLASS_LABEL: "Categor\xEDa",
  USER_BENEFITS_SETTINGS_HEADER: "Configuraci\xF3n",
  WE_CAN_HELP: "Podemos ayudarte, contacta a nuestro equipo de satisfacci\xF3n del cliente:",
  WANT_TO_MAKE_A_CHANGE: "\xBFQuieres realizar alg\xFAn cambio en tus beneficios?",
  SUCCESS_REMOVING_USER_BENEFIT: "\xA1Bien hecho! El beneficio ha sido eliminado.",
  SUCCESS_UPDATING_USER_BENEFIT: "\xA1Bien hecho! El beneficio ha sido actualizado.",
  SUCCESS_ADDING_USER_BENEFIT: "\xA1Bien hecho! El beneficio ha sido agregado.",
  PAGE_NOT_FOUND_BUTTON: "Volver a Inicio",
  PAGE_NOT_FOUND_CONTENT_SECONDARY: "Permite que te ayudemos a ubicarte.",
  PAGE_NOT_FOUND_CONTENT: "\xA1Parece que est\xE1s un poco confundido!",
  PAGE_NOT_FOUND_HEADER: "P\xE1gina no encontrada",
  SIGN_OUT: "Cerrar sesi\xF3n",
  USER_BULK_BENEFIT_OPTIONS_HEADER: "Configurar el estatus de todos los beneficios:",
  USER_BENEFIT_OPTIONS_HEADER: "Configurar el estatus del beneficio en:",
  SAVE_BENEFIT: "Guardar beneficio",
  BENEFIT_ERROR: "Error en el beneficio",
  BENEFIT_SAVED: "Beneficio guardado",
  START_A_NEW_PROGRAM: "\xA1Inicia un nuevo programa para seguir ganando recompensas por lograr tus objetivos!",
  PROGRAM_HAS_ENDED: "El programa ha finalizado. \xBFEst\xE1s listo para iniciar uno nuevo?",
  SELECT_NEW_PROGRAM: "Selecciona un nuevo programa",
  YOU_HAVE_REACHED_THE_END_OF_THE_PROGRAM: "\xA1Has llegado al final del {name} con un total de {points} puntos ganados!",
  CONGRATULATIONS: "\xA1Felicitaciones!",
  CONGRATS: "\xA1Felicitaciones!",
  BACK_TO_PROGRAM: "Volver al programa",
  CONGRATS_ON_EARNING_POINTS: "\xA1Felicitaciones por sumar suficientes puntos para ganar una recompensa! \xA1Te la mereces!",
  YOU_HAVE_EARNED_A_REWARD: "\xA1Has ganado una recompensa!",
  YOU_COMPLETED_THE_ACTIVITY_AND_EARNED_POINTS: "\xA1Completaste la actividad {name} y ganaste {points} puntos! \xA1Muy bien!",
  WAY_TO_GO: "\xA1Bien hecho!",
  MARK_AS_COMPLETE: "Marcar como completo",
  CHECK_BACK_TOMORROW: "\xA1Vuelve a verificar ma\xF1ana!",
  UNLOCKS_DAY_AT_TIME: "{icon} se libera el {day} a las {time}",
  START_DAY_AT_TIME: "Inicia el {day}",
  END_DAY_AT_TIME: "Finaliza el {day}",
  YOU_DONT_HAVE_ANY_POINTS: "No tienes puntos en este momento. Completa objetivos diarios para ganar puntos.",
  VIEW_REWARDS: "Ver recompensas",
  POINT_BALANCE: "Saldo de puntos",
  CLOSE: "Cerrar",
  I_AGREE_TO_THESE_TERMS_AND_CONDITIONS: "Estoy de acuerdo con {linkToTOS}",
  DISABLE_PUSH_SETTING_CHECKBOX_LABEL: "Notificaciones Push en el celular",
  NOTIFICATION_SETTINGS: "Configuraci\xF3n de las notificaciones",
  TERMS_AND_CONDITIONS: "t\xE9rminos y condiciones",
  LETS_DO_IT: "Vamos a hacerlo",
  EARN_NUMBER_POINTS_FULL: "Gana {points} puntos",
  EARN_NUMBER_POINTS: "Gana {points} puntos",
  DURATION_DAYS: "{duration} d\xEDas",
  SELECT_PROGRAM_FOR_MORE_DETAILS: "Selecciona un programa para m\xE1s detalles",
  PROGRAM_SELECTION: "Selecci\xF3n de programa",
  WAITING_PERIOD_DAYS: "Duraci\xF3n del periodo de espera",
  IN_GRACE_PERIOD: "En periodo de gracia",
  BENEFIT_INACTIVE_GRACE_PERIOD: "Periodo de gracia inactivo",
  BENEFIT_INACTIVE: "Inactivo",
  BENEFIT_GRACE_PERIOD: "Periodo de gracia",
  BENEFIT_ACTIVE: "Activo",
  BENEFIT_PUBLISHED: "Publicado",
  BENEFIT_READY: "Listo",
  BENEFIT_CREATED: "Creado",
  BENEFIT_OPTED_OUT: "Elegiste salirte",
  BENEFIT_DRAFT: "Borrador",
  SUBMIT: "Enviar",
  BULK_UPLOAD_STEP_2_CONTENT: "Por favor, carga tu CSV completo",
  BULK_UPLOAD_STEP_1_CONTENT: "Descarga nuestra plantilla CSV de Census. Abre la plantilla en tu editor de hojas de c\xE1lculo preferido (Microsoft Excel, Numbers, Google Sheets, etc.) y completa todos los campos para cada empleado.",
  BULK_UPLOAD_STEP_2_HEADER: "Cargar tu CSV de Census",
  BULK_UPLOAD_STEP_1_HEADER: "1. Descargar la plantilla de Census",
  BULK_UPLOAD_BACK_BTN_LABEL: "Atr\xE1s",
  BULK_UPLOAD_FINISH_BTN_LABEL: "Terminado",
  BULK_UPLOAD_NEXT_BTN_LABEL: "Siguiente",
  BULK_UPLOAD_CENSUS_BTN_LABEL: "Cargar el padr\xF3n Census",
  BULK_UPLOAD_DOWNLOAD_BTN_LABEL: "Descargar la plantilla",
  BULK_UPLOAD_ERROR_COPY: "No hemos podido importar {erroredEmployeesCount} empleados con {errorsCount} errores. Por favor, corrige los errores en tu CSV y vuelve a cargar tu padr\xF3n Census.",
  BULK_UPLOAD_ERROR_HEADER: "\xA1Atenci\xF3n!",
  BULK_UPLOAD_SUCCESS_COPY: "Hemos  agregado con \xE9xito {successfullyAddedEmployeesCount} empleados",
  BULK_UPLOAD_SUCCESS_HEADER: "\xA1Bien hecho!",
  BULK_UPLOAD_SAVE_IMPORT_DESC: "Guardar e invitar empleados",
  BULK_UPLOAD_SAVE_IMPORT_TITLE: "Guardar importaci\xF3n",
  BULK_UPLOAD_UPLOAD_CENSUS_DESC: "Cargar y validar tu CSV",
  BULK_UPLOAD_UPLOAD_CENSUS_TITLE: "Cargar Census",
  BULK_UPLOAD_DOWNLOAD_TEMPLATE_DESC: "Seleccionar los campos a actualizar",
  BULK_UPLOAD_DOWNLOAD_TEMPLATE_TITLE: "Descargar la plantilla",
  UPDATE_FLEX_DOLLARS_SUCCESS_MESSAGE: "D\xF3lares Flex de base actualizados",
  UPDATE_FLEX_DOLLARS_LOADED_FLEX_LABEL: "D\xF3lares Flex de base",
  UPDATE_FLEX_DOLLARS_TOTAL_FLEX_LABEL: "Total actual de d\xF3lares Flex",
  UPDATE_FLEX_DOLLARS_DESCRIPTION: "Esta herramienta ofrece la posibilidad de actualizar los d\xF3lares Flex para empleados espec\xEDficos que tienen un cambio de categor\xEDa en su plan a mitad del a\xF1o. Puedes actualizar el monto de los d\xF3lares Flex del miembro en el campo a continuaci\xF3n.",
  UPDATE_FLEX_DOLLARS: "Actualizar d\xF3lares Flex",
  SUSPEND_EMPLOYER: "Suspender empleador",
  ACTIVATE_EMPLOYER: "Activar empleador",
  PUBLISH_EMPLOYER: "Publicar empleador",
  BACK_TO_EMPLOYEES: "Volver a empleados",
  SEND_INVITE: "Enviar invitaci\xF3n",
  ADD_EMPLOYEE_INTRO: "Al guardar este empleado, el nuevo empleado creado recibir\xE1 un correo electr\xF3nico invit\xE1ndolo a completar nuestra integraci\xF3n en l\xEDnea.",
  INVITE_AN_EMPLOYEE: "Agregar empleado",
  WE_BE_BACK_WITH_SOMETHING_NEW: "\xA1Estaremos de vuelta con algo nuevo!",
  COMING_SOON: "En breve",
  NO_NOTIFICATIONS_MESSAGE: "Las actualizaciones para cuestiones importantes como presentaciones de reclamaciones, reservaciones y compras realizadas a trav\xE9s de League se mostrar\xE1n aqu\xED.",
  NO_NOTIFICATIONS_TITLE: "A\xFAn no has recibido notificaciones",
  HELP_EMAIL: "ayuda@league.com",
  ERROR_STATE_MESSAGE: "Se ha presentado un imprevisto. Intenta actualizar la p\xE1gina.\nSi el problema persiste, ponte en contacto con el servicio de atenci\xF3n al cliente.",
  ERROR_STATE_TITLE: "Algo sali\xF3 mal",
  SUCCESS_STATE_TITLE: "\xA1Bien hecho!",
  SAVING: "Guardando",
  LOADING: "Cargando",
  EMPTY_DATE_REINSTATE_ERROR: "Selecciona por favor una fecha de reincorporaci\xF3n",
  EMPTY_DATE_SUSPEND_ERROR: "Selecciona por favor una fecha de suspensi\xF3n",
  EMPTY_REASON_TERMINATE_ERROR: "Selecciona por favor la raz\xF3n por la que te diste de baja",
  EMPTY_DATE_TERMINATE_ERROR: "Selecciona por favor la fecha en que te diste de baja",
  PLEASE_CONTACT_FOR_SUPPORT: "Para obtener m\xE1s ayuda, por favor, contacta a ayuda@league.com.",
  THIRTY_DAYS_LIMIT_REINSTATE_ERROR: "Solo se pueden reincorporar empleados en League hasta 30 d\xEDas atr\xE1s.",
  THIRTY_DAYS_LIMIT_SUSPEND_ERROR: "Solo se puede suspender a empleados en League hasta 30 d\xEDas atr\xE1s.",
  ERROR_CREATING_EMPLOYEE: "No hemos podido crear el perfil del empleado a causa de un error.",
  BULK_ACTIONS: "Acciones de gran volumen",
  REINSTATE_EMPLOYEES: "Reincorporar empleados",
  SUSPEND_EMPLOYEES: "Suspender empleados",
  TERMINATE_EMPLOYEES: "Dar de baja empleados",
  TAX_RECEIPTS_LABEL: "Emitir recibos de impuestos",
  EMPLOYEE_ACTIONS: "Acciones",
  REINSTATE_EMPLOYEE: "Reincorporar empleado",
  SUSPEND_EMPLOYEE: "Suspender empleado",
  TERMINATE_EMPLOYEE: "Dar de baja empleado",
  EMPLOYEE: "Empleado",
  ONE_PLAN_AVAILABLE: "1 Plan disponible",
  PLANS_AVAILABLE: "{number} Planes disponibles",
  STEP_PLANS: "Planes {benefitName}",
  PLAN: "Plan",
  CLASS: "Categor\xEDa",
  ROLE: "Rol",
  STATUS_UPDATED: "Estatus actualizado",
  PAST: "Pasado",
  PENDING: "Pendiente",
  DRAFT: "Borrador",
  SUSPENDED: "Suspendido",
  READY: "Listo",
  ACTIVE: "Activo",
  EMPLOYEES_ALL_SUB_HEADING: "con o sin c\xF3nyuge o dependientes",
  EMPLOYEES_FAMILY_SUB_HEADING: "con al menos 1 dependiente aparte del c\xF3nyuge",
  EMPLOYEES_FAMILY_HEADING: "Los empleados que son parte de una familia...",
  EMPLOYEES_COUPLE_SUB_HEADING: "que tienen un c\xF3nyuge pero sin otros dependientes",
  EMPLOYEES_COUPLE_HEADING: "Los empleados que est\xE1n en pareja...",
  ALL_EMPLOYEES_CLASS_DESCRIPTION: "Todos los empleados recibir\xE1n",
  SINGLE_EMPLOYEES_SUB_HEADING: "sin c\xF3nyuge o dependientes",
  SINGLE_EMPLOYEES_HEADING: "Los empleados solteros...",
  ALLOCATION_CLASS_LABEL: "Categor\xEDa de asignaci\xF3n",
  ALLOCATION_RULE_SET_LABEL: "Categor\xEDas de relaciones del empleado",
  FUNDS_PER_POLICY_LABEL: "Fondos por periodo de p\xF3liza",
  SINGLE_FAMILY: "Individuo / Familia",
  SINGLE_COUPLE_FAMILY: "Individuo / Individuo + C\xF3nyuge / Familia",
  ALL_EMPLOYEES: "Todos los empleados",
  BENEFIT_VENDOR_NAME: "por {vendorName}",
  BENEFIT_NAME: "{name} ",
  BENEFIT_END_DATE: "Fecha de finalizaci\xF3n",
  BENEFIT_START_DATE: "Fecha de inicio",
  EMPLOYEES_SEARCH_HINT: "Ingresar o seleccionar: rol, categor\xEDa, plan o estatus",
  FILTER_EMPLOYEES: "Filtrar empleados",
  SEARCH_EMPLOYEES: "Buscar empleados",
  ADD_EMPLOYER: "Agregar empleador",
  ADD_EMPLOYEE: "Agregar empleado",
  BULK_UPLOAD: "Cargas de gran volumen",
  SETTINGS: "Configuraci\xF3n",
  OTHER_FIELDS: "Otros campos",
  BENEFITS: "Beneficios",
  REPORT_ID_CLIPBOARD: "ID del informe copiado en el portapapeles",
  RUN_REPORT: "Mostrar informe",
  SELECTED_REPORT: "Informe seleccionado",
  REQUEST_CHANGE_REPORT_NOTE: "Nota: este informe toma cierto tiempo en generarse y te ser\xE1 enviado a tu correo electr\xF3nico cuando est\xE9 listo.",
  REQUEST_CHANGE_REPORT_EXPLANATION: "Se crear\xE1 un informe de cambio donde se compare el informe seleccionado con un informe reci\xE9n generado.",
  REQUEST_CHANGE_REPORT: "Solicitar un informe de cambios",
  CREATE_CHANGE_REPORT: "Crear un informe de cambios",
  DAILY_ASSESSMENT_REPORT_REQUESTED_COPY: "Se ha solicitado tu informe. Esto podr\xEDa tardar unos minutos en generarse, dependiendo del n\xFAmero de registros, pero la descarga se iniciar\xE1 autom\xE1ticamente cuando el informe est\xE9 hecho.",
  PREMIUMS_EXPORT_ERROR_COPY: "Se present\xF3 un inconveniente al generar el informe. Intenta generar el informe nuevamente y si sigues teniendo problemas, contacta a ayuda@league.com",
  PREMIUMS_EXPORT_REQUESTED_COPY: "Tu informe ha sido solicitado y se te enviar\xE1 un correo electr\xF3nico a {email} con un enlace para descargarlo. Es posible que tarde varias horas en generarse.",
  EMPLOYER_REPORT_NO_DATA_ERROR_COPY: "No hemos podido generar el informe solicitado porque no hay datos disponibles. Si necesitas m\xE1s apoyo, contacta a nuestro equipo de atenci\xF3n al cliente en ayuda@league.com y con gusto te ayudaremos.",
  EMPLOYER_REPORT_ERROR_COPY: "Lo lamentamos pero se present\xF3 un inconveniente al generar el informe que solicit\xF3. Por favor, intenta generar el informe nuevamente. Si sigues teniendo problemas, contacta a nuestro equipo de atenci\xF3n al cliente en ayuda@league.com y con gusto te ayudaremos.",
  EMPLOYER_REPORT_WITH_DATES_NO_DATA_ERROR_COPY: "No hemos podido generar el informe solicitado porque no hay datos en las fechas seleccionadas. Selecciona por favor otras fechas e intenta generar el informe nuevamente. Si sigues teniendo problemas, contacta a nuestro equipo de atenci\xF3n al cliente en ayuda@league.com y con gusto te ayudaremos.",
  EMPLOYER_REPORT_WITH_DATES_ERROR_COPY: "Lo lamentamos pero se present\xF3 un inconveniente al generar el informe que solicit\xF3. Por favor, selecciona una fecha e intenta generar el informe nuevamente. Si sigues teniendo problemas, contacta a nuestro equipo de atenci\xF3n al cliente en ayuda@league.com y con gusto te ayudaremos.",
  EMPLOYER_REPORT_GENERATED_COPY: "Tu informe se ha generado. Se envi\xF3 un correo electr\xF3nico a {email} con un enlace para descargar el archivo.",
  EMPLOYER_REPORT_REQUESTED_COPY: "Tu informe ha sido solicitado. Se te enviar\xE1 un correo electr\xF3nico a {email} con un enlace para descargar el informe cuando est\xE9 listo. Es posible que tarde varias horas en generarse dependiendo del n\xFAmero de registros.",
  SPENDING_ACCOUNT_BALANCE_REPORT_COPY: "Este informe proporciona el saldo de la cuenta de gastos de cada empleado a partir de la fecha seleccionada. El informe incluye informaci\xF3n sobre las asignaciones transferidas de a\xF1os anteriores de la p\xF3liza, las asignaciones adicionales, as\xED como el uso total hasta la fecha. El informe se exporta como un CSV",
  SPENDING_ACCOUNT_BALANCE_REPORT: "Informe del saldo de la cuenta de gastos",
  SPENDING_ACCOUNT_BALANCE: "Saldo de la cuenta de gastos",
  REPORTS_LOADING_NOTE_COPY: "Dependiendo del n\xFAmero de registros, es posible que tarde varias horas en generarse. Te enviaremos un correo electr\xF3nico con un enlace para descargar el informe una vez que haya sido procesado.",
  USAGE_REPORTS_COPY: "Genera un informe que incluya un desglose rengl\xF3n por rengl\xF3n de las transacciones de la cuenta de gastos de cada empleado para un periodo espec\xEDfico (hasta 12 meses). El informe ser\xE1 descargado como CSV (valores separados por comas) y puede ser utilizado para determinar el uso de un beneficio con impuestos en las entradas de la n\xF3mina de pagos.",
  USAGE_REPORTS: "Informes de uso",
  USAGE: "Uso",
  GET_PREVIOUS_REPORTS_ERROR: "Se produjo un error al cargar los informes previos. Si sigues teniendo problemas, contacta a nuestro equipo de Atenci\xF3n al cliente en ayuda@league.com y con gusto te ayudaremos.",
  NO_REPORTS_AVAILABLE: "No hay informes disponibles",
  REPORTS: "Informes",
  EMPLOYEES: "Empleados",
  RATE: "Tarifa (por empleado/mes)",
  UPPER: "Superior",
  LOWER: "Inferior",
  CONTACT_ERRORED: "Error al guardar el contacto",
  CONTACT_SAVED: "Contacto guardado",
  SAVE_CONTACT: "Guardar contacto",
  CONTACT: "Contacto",
  FEES_ERRORED: "Error al guardar las tarifas",
  FEES_SAVED: "Tarifas guardadas",
  SAVE_FEES: "Guardar tarifas",
  PLATFORM_FEE: "Tarifa de plataforma",
  ADMIN_FEE: "Tarifa de administraci\xF3n",
  SUBSCRIPTION_FEE: "Tarifa de suscripci\xF3n",
  FEES: "Tarifas",
  PRE_PAYMENTS_ERRORED: "Error al guardar los prepagos",
  PRE_PAYMENTS_SAVED: "Prepagos guardados",
  SAVE_PRE_PAYMENTS: "Guardar prepagos",
  CHARITABLE_GIVING_ACCOUNT: "Cuenta de donaciones caritativas",
  CHARITY: "Cuenta de donaciones caritativas",
  HEALTH_INSURANCE: "Seguro de salud",
  TRAVEL: "Viajes",
  DRUGS: "Medicamentos",
  HEALTH_REIMBURSEMENT_ARRANGEMENT: "Acuerdo de reembolso de gastos de salud",
  LSA: "CGB",
  LIFESTYLE_SPENDING_ACCOUNT: "Cuenta gastos bienestar",
  HSA: "CGS",
  HEALTH_SPENDING_ACCOUNT: "Cuenta gastos de salud",
  PRE_PAYMENTS: "Prepagos",
  BILLING_PREFERENCE_ERRORED: "Error al guardar las preferencias de facturaci\xF3n",
  BILLING_PREFERENCE_SAVED: "Preferencias de facturaci\xF3n guardadas",
  PAYMENT_METHOD_ERRORED: "Error al guardar el m\xE9todo de pago",
  PAYMENT_METHOD_SAVED: "M\xE9todo de pago guardado",
  SAVE_BILLING_PREFERENCE: "Guardar preferencias de facturaci\xF3n",
  SAVE_PAYMENT_METHOD: "Guardar m\xE9todo de pago",
  PAYMENT_METHOD: "M\xE9todo de pago",
  PAYMENT_AUTO_RENEWAL: "Autorenovaci\xF3n para todos los beneficios, productos y servicios",
  BILLING_SUBHEADER: "Gestiona tu m\xE9todo de pago actual y tu estructura de tarifas",
  ADJUST_END_DATE_EMPTY_ERROR: "Por favor, especifica la fecha de finalizaci\xF3n para este beneficio",
  ADJUST_END_DATE_SUCCESS_MESSAGE: "\xA1La fecha de finalizaci\xF3n de {benefitName} se ha ajustado!",
  ADJUST_END_DATE_CONFIRMATION_CONFIRM_BTN: "Ajustar",
  ADJUST_END_DATE_ERROR: "Por favor, elige una fecha dentro del periodo de la p\xF3liza actual.",
  SELECT_DATE: "<strong>Seleccionar fecha</strong>",
  ADJUST_END_DATE_CONFIRMATION_NOTE: "<strong>Nota:</strong> tambi\xE9n puedes ajustar esa fecha en el pasado.",
  ADJUST_END_DATE_CONFIRMATION_COPY: "Ajustar la fecha de finalizaci\xF3n significa que {benefitName} est\xE1 activo hasta la nueva fecha que seleccione. El beneficio actualmente finaliza o finaliz\xF3 el {benefitEndDate}. \xBFQu\xE9 fecha deseas configurar como nueva fecha de finalizaci\xF3n?",
  STATUS_BENEFIT_CONFIRMATION_CONFIRM_BTN: "Confirmar",
  BENEFIT_STATUS_CONFIRMATION_CANCEL_BTN: "Cancelar",
  OPTED_OUT_BENEFIT_CONFIRMATION_NOTE: "<strong>Nota:</strong> Esto quitar\xE1 el beneficio de la cartera del usuario.",
  READY_BENEFIT_CONFIRMATION_NOTE: "<strong>Nota:</strong> por favor verifica que las fechas de activaci\xF3n y de finalizaci\xF3n de los beneficios sean correctas. Si la fecha de activaci\xF3n del beneficio es anterior a la fecha actual, el beneficio autom\xE1ticamente cambiar\xE1 a<strong>Activo</strong>.",
  STATUS_BENEFIT_CONFIRMATION_COPY: "\xBFEst\xE1s seguro de que deseas cambiar el estatus del beneficio {benefitName} de <strong>{benefitStatus}</strong> a <strong>{newStatus}</strong>?",
  STATUS_BENEFIT_CONFIRMATION_HEADER: "Transici\xF3n del estatus del beneficio",
  REMOVE_BENEFIT_CONFIRMATION_REMOVE_BTN: "Quitar",
  REMOVE_BENEFIT_CONFIRMATION_CANCEL_BTN: "Cancelar",
  REMOVE_BENEFIT_CONFIRMATION_COPY: "\xBFEst\xE1s seguro de que deseas borrar el beneficio {benefitName}? Esta acci\xF3n no es reversible.",
  REMOVE_BENEFIT_CONFIRMATION_HEADER: "\xBFBorrar {benefitName}?",
  REMOVE_BENEFIT_PLAN_CONFIRMATION_REMOVE_BTN: "Quitar plan",
  REMOVE_BENEFIT_PLAN_CONFIRMATION_CANCEL_BTN: "Cancelar",
  REMOVE_LAST_BENEFIT_PLAN_ERROR_COPY: 'No puedes borrar el plan de beneficios "{benefitPlanName}". Tienes que haber al menos un plan de beneficios configurado.',
  REMOVE_BENEFIT_PLAN_CONFIRMATION_COPY: '\xBFEst\xE1s seguro de que quieres borrar el plan de beneficios "{benefitPlanName}" y todos sus beneficios? Esta acci\xF3n no es reversible.',
  REMOVE_BENEFIT_PLAN_CONFIRMATION_HEADER: '\xBFBorrar "{benefitPlanName}"?',
  REMOVE_BENEFIT_CLASS_CONFIRMATION_REMOVE_BTN: "Quitar categor\xEDa",
  REMOVE_BENEFIT_CLASS_CONFIRMATION_CANCEL_BTN: "Cancelar",
  REMOVE_LAST_BENEFIT_CLASS_ERROR_COPY: 'No puedes borrar la categor\xEDa de beneficios "{benefitClassName}". Tienes que haber al menos una categor\xEDa de beneficios configurada.',
  REMOVE_BENEFIT_CLASS_CONFIRMATION_COPY: '\xBFEst\xE1s seguro de que quieres borrar la categor\xEDa de beneficios "{benefitClassName}" y todos sus planes y beneficios? Esta acci\xF3n no es reversible.',
  REMOVE_BENEFIT_CLASS_CONFIRMATION_HEADER: '\xBFBorrar "{benefitClassName}"?',
  SIGN_UPS_CURRENTLY_CLOSED: "Las suscripciones est\xE1n cerradas en este momento.",
  SIGN_ME_UP: "Suscribirme",
  YOURE_ALREADY_SIGNED_UP: "\xA1Ya est\xE1s suscrito!",
  WANT_TO_BE_THE_FIRST_TO_KNOW: "\xBFQuieres ser el primero en enterarte cu\xE1ndo es el lanzamiento? Ten un acceso anticipado y exclusivo en un simple paso, luego vigila tu correo electr\xF3nico para obtener m\xE1s detalles.",
  FREE_FOR_ALL_LEAGUE_MEMBERS: "\xA1GRATUITO para todos los miembros de League!",
  STAY_MOTIVATED_BULLET: "Desaf\xEDos diarios, contenido de salud y promociones exclusivas.",
  STAY_MOTIVATED: "\xA1Mant\xE9nte motivado!",
  EARN_POINTS_DAILY_BULLET: "Registra tu progreso y recibe recompensas.",
  EARN_POINTS_DAILY: "\xA1Gana puntos todos los d\xEDas!",
  JOIN_THE_MOVEMENT_BULLET: "Programas exclusivos de profesionales de la salud certificados.",
  JOIN_THE_MOVEMENT: "\xA1\xDAnete al movimiento!",
  HEALTH_GOALS_INTRO: "\xA1Anunciamos nuestro nuevo programa Beta! Queremos que te sea m\xE1s f\xE1cil mejorar tu salud.",
  BE_HEALTHIER_BE_REWARDED: "S\xE9 m\xE1s sano, recibe recompensas",
  JOIN_OUR_EXCLUSIVE_BETA: "EN BREVE - ENERO DE 2018",
  GET_REWARDED: "Recibe recompensas",
  GET_INSPIRED: "Insp\xEDrate",
  DURATION: "Duraci\xF3n",
  PHONE_NUMBERS: "N\xFAmeros de tel\xE9fono",
  PHONE_NUMBER: "N\xFAmero de tel\xE9fono",
  MORE_ARROW: "M\xE1s >",
  WEEK: "Semana",
  SEMI_MONTHLY: "Quincenal",
  BI_WEEKLY: "Bisemanal",
  MONTHLY_DRAW: "Retiro mensual",
  PAY_PERIOD: "Periodo de pago",
  POLICY_PERIOD: "Periodo de la p\xF3liza",
  YEARLY: "Anual",
  MONTHLY: "Mensual",
  END_DATE: "Finaliza el {date}",
  DISCOUNT_OFF: "{discount} de descuento",
  FREE: "GRATUITO",
  SETUP_YOUR_BENEFITS_INTRO: "Elige los beneficios disponibles para los empleados\nen tu empresa. Crea una categor\xEDa para segmentar a tus empleados. Crea un\nplan para brindar a tus empleados la opci\xF3n de elegir entre conjuntos de beneficios.",
  RESET: "Reiniciar",
  REMOVE: "Quitar",
  RENAME_BENEFITS_PLAN_PLACEHOLDER: "Ingresar el nombre del plan...",
  RENAME_BENEFITS_PLAN: "Cambiar el nombre",
  REMOVE_BENEFITS_PLAN: "Quitar",
  RENAME_BENEFITS_CLASS_PLACEHOLDER: "Ingresar nombre de la categor\xEDa...",
  RENAME_BENEFITS_CLASS: "Cambiar el nombre",
  REMOVE_BENEFITS_CLASS: "Quitar",
  REMOVE_USER_BENEFIT: "Borrar beneficio",
  ADJUST_END_DATE: "Ajustar la fecha de finalizaci\xF3n",
  PREVIEW_BENEFIT: "Vista previa del beneficio",
  REMOVE_BENEFIT: "Quitar beneficio",
  RENEW_BENEFIT: "Renovar beneficio",
  PUBLISH_BENEFIT: "Publicar beneficio",
  SEARCH_BENEFITS_PLACEHOLDER: "Buscar beneficios",
  ADD_BENEFIT: "Agregar beneficio",
  ADD_BENEFITS_PLAN_PLACEHOLDER: "Ingresar el nombre del plan",
  ADD_BENEFITS_PLAN: "Agregar plan",
  ADD_BENEFITS_CLASS_PLACEHOLDER: "Ingresar nombre de la categor\xEDa...",
  ADD_BENEFITS_CLASS: "Agregar categor\xEDa",
  INVITES: "Invitaciones",
  ACTIVE_USERS: "Usuarios activos",
  NO_RESULTS: "No hay resultados",
  SEARCH_EMPLOYERS: "Buscar empleadores",
  ALL_EMPLOYERS: "Todos los empleadores",
  EDIT_COMPANY_ERROR_TITLE: "No hemos podido actualizar tu perfil a causa de un error.",
  EDIT_COMPANY_INTRO: "Salvo que se indique lo contrario, todos los campos siguientes son necesarios.",
  EDIT_COMPANY_TITLE: "Editar el perfil de {companyName}",
  CREATED_ON: "Creado el",
  CREATE_EMPLOYER_ERROR_TITLE: "No hemos podido crear tu perfil a causa de un error",
  CREATE_EMPLOYER_INTRO: "Para configurar un nuevo empleador, necesitamos conocer un poco sobre la empresa. Salvo que se indique lo contrario, todos los campos siguientes son necesarios.",
  CREATE_EMPLOYER_TITLE: "Crear un nuevo empleador",
  CAD_DOLLAR: "Canad\xE1 - D\xF3lares canadienses",
  US_DOLLAR: "EE.UU - D\xF3lares americanos",
  EMPLOYER_NAME: "Nombre del empleador",
  COUNTRY: "Pa\xEDs",
  CURRENCY: "Moneda",
  EMAIL_ADDRESS: "Direcci\xF3n de correo electr\xF3nico",
  DISABLE_EMAIL_SETTING_CHECKBOX_LABEL: "Correo electr\xF3nico",
  ADDRESS: "Direcci\xF3n",
  OPTIONAL: "Opcional",
  UNIT_SUITE: "Unidad/Departamento",
  STATE: "Estado",
  PROVINCE_COUNTY: "Provincia/Municipio",
  PROVINCE: "Provincia",
  CITY_TOWN: "Ciudad/Poblaci\xF3n",
  CITY: "Ciudad",
  ZIP_CODE: "C\xF3digo postal",
  POSTAL_CODE: "C\xF3digo postal",
  SALESFORCE_ID: "ID del equipo de ventas",
  GROUP_ID: "ID del grupo",
  DAYS: "D\xEDas",
  WAITING_PERIOD: "Periodo de espera",
  SAVE_EMPLOYER: "Guardar empleador",
  INVOICES: "Facturas",
  BILLING: "Facturaci\xF3n",
  SALESFORCE_SYNC_ENABLE_SUCCESS_MESSAGE: "Los cambios se guardaron con \xE9xito.",
  SALESFORCE_SYNC_ENABLE_BUTTON: "Guardar",
  SALESFORCE_SYNC_COBRA_DESCRIPTION: "Administraci\xF3n COBRA: luego de dejar o perder tu trabajo en Estados Unidos, puedes temporalmente continuar con tu cobertura de seguro de salud patrocinada por tu empleador por medio de una ley federal conocida como COBRA. El rol de League es inscribir miembros que fueron dados de baja con el vendedor de COBRA del grupo entregando la informaci\xF3n del miembro dado de baja al vendedor de COBRA, quien a partir de ese momento se hace cargo.",
  SALESFORCE_SYNC_NOTE: "NOTA: una vez habilitados, los vales comenzar\xE1n a generarse dentro de las 48 horas. Aseg\xFArate por favor de completar la inscripci\xF3n y que el archivo inicial de la inscripci\xF3n haya sido enviado a la compa\xF1\xEDa.",
  SALESFORCE_SYNC_INTRO: "De la lista a continuaci\xF3n, selecciona o deselecciona vendedores para activar la Sincronizaci\xF3n de Vendedores de Productos. Una vez habilitada, se generar\xE1n vales del equipo de ventas para los asociados de operaciones con base en cada vendedor para los cambios demogr\xE1ficos y/o de elegibilidad de beneficios de los miembros.",
  SALESFORCE_SYNC_TAB_LABEL: "Notificaciones del equipo de ventas",
  WALLET_DETAILS: "Detalles de la cartera",
  PRICING_CONFIG: "Configuraci\xF3n del precio",
  ENROLLMENT_DESIGN: "Dise\xF1o de la inscripci\xF3n",
  PLAN_DESIGN: "Dise\xF1o del plan",
  PROFILE: "Perfil",
  NOTE: "Nota",
  INSIGHTS: "Observaciones",
  EMPLOYERS: "Empleadores",
  CLAIMS: "Reclamaciones",
  NOTHING_MORE_TO_SEE_HERE: "\xA1Nada m\xE1s que mostrar aqu\xED!",
  YOU_HAVENT_RECEIVED_ANY_NOTIFICATIONS: "No has recibido recientemente ninguna notificaci\xF3n.",
  LOADING_NOTIFICATIONS: "Cargando las notificaciones",
  NOTIFICATIONS: "Notificaciones",
  LAST_3_MONTHS: "\xDAltimos 3 meses",
  LAST_MONTH: "\xDAltimo mes",
  LAST_14_DAYS: "\xDAltimos 14 d\xEDas",
  TODAY: "Hoy",
  COVERAGE_NOT_COVERED_BY_YOUR_POLICY: "No cubierto por tu p\xF3liza.",
  CHAT_WITH_US_LOWERCASE: "habla por chat con nosotros.",
  CHAT_WITH_US: "Habla por chat con nosotros",
  CHECK_COVERAGE_WALLET_LINK: "Verifica la cobertura",
  CHECK_COVERAGE_TOOLTIP_TEXT: "Esto puede ser cubierto por {name}. {walletLink} o {chatLink}",
  PARTIAL_TOOLTIP_TEXT: "Este producto est\xE1 cubierto, pero con la restricci\xF3n de:",
  COVERED_TOOLTIP_TEXT: "Este producto est\xE1 totalmente cubierto por su {name}.",
  CHECK_COVERAGE_TOOLTIP_TITLE: "Verifica tu cobertura",
  PARTIAL_TOOLTIP_TITLE: "Cubierto con restricciones",
  COVERED_TOOLTIP_TITLE: "Cubierto",
  NOT_COVERED_BY_YOUR_POLICY: "No cubierto por tu p\xF3liza",
  OPEN_CHAT: "Abrir chat",
  SEE_DETAILS: "Ver detalles",
  WAS_REQUESTED: "{amount} fueron solicitados.",
  SUBMITTED_CLAIM_ON: "reclamaci\xF3n enviada el",
  WAS_APPROVED: "{amount} fueron aprobados.",
  AND: "y",
  REJECTION_REASON: "Raz\xF3n del rechazo: {reason} ",
  BY: "Por ",
  ON: "Conectado ",
  CURRENT_STATUS: "Estatus actual",
  EMPLOYER: "Empleador",
  QLE_ENROLLMENT: "INSCRIPCI\xD3N A UN QLE",
  ENROLLMENT_YEAR: "Inscripci\xF3n a {planName}",
  ENROLLMENT: "Inscripci\xF3n",
  INFORMATION: "Informaci\xF3n",
  IMAGES: "Im\xE1genes",
  DETAILS: "Detalles",
  AGENT_RESPONSE: "Respuesta del agente",
  PURCHASE_DATE: "Fecha de compra",
  LIFESTYLE_SPENDING: "Gastos en bienestar:",
  HEALTH_SPENDING: "Gastos de salud:",
  DELETE: "Borrar",
  EDIT: "Editar",
  NOTES: "Notas",
  MY_BAD: "Fue mi culpa, yo caus\xE9 el problema...",
  FILTER_LOG: "Registro de filtros",
  VISIBLE_TO_MEMBER: "Visible para el miembro",
  ONLY_VISIBLE_TO_ADMIN: "Solo visible para los administradores de League",
  ADD: "Agregar",
  ADD_NOTE: "Agregar nota",
  RESEND_PAYMENT: "Reenviar el pago",
  AMOUNT: "Monto",
  INTERAC_ID: "ID de Interac",
  PAYMENT: "Pago",
  PAYMENT_ID: "ID del pago",
  PAYMENT_STATUS: "Estatus del pago",
  NEW_BENEFIT_ERROR_MESSAGE: "Perd\xF3n, se produjo un error al agregar el beneficio",
  NEW_BENEFIT_SUCCESS_MESSAGE: "\xA1Bien hecho! El beneficio se ha agregado",
  IMPORT_PLAN_DESIGN: "Importar el dise\xF1o del plan",
  EMPLOYEE_SEARCH_HINT: "Ingresar el nombre o la direcci\xF3n de correo electr\xF3nico del empleado",
  EMPLOYER_SEARCH_HINT: "Ingresar el nombre o ID del empleador",
  EMPLOYEE_PROFILE_ERROR: "Perd\xF3n, se produjo un error al crear el empleado",
  EMPLOYEE_PROFILE_SAVED: "\xA1El perfil del empleado ha sido guardado!",
  NEW_EMPLOYEE_SUCCESS_MESSAGE: "\xA1Bien hecho! El empleado ha sido agregado a {group}",
  NEW_EMPLOYER: "Nuevo empleador",
  SEARCH_HINT: "Buscar por ID de reclamaci\xF3n, nombre de usuario o direcci\xF3n de correo electr\xF3nico",
  CLEAR: "Limpiar",
  RESULTS_FOR: "Resultados para {query}",
  RESULTS_FOR_ALL_CLAIMS: 'Resultados para "Todas las reclamaciones"',
  FILTER_BY_STATUS: "Filtrar por Estatus",
  FILTER_BY_MODIFIED_BY: 'Filtrar por "Modificada por"',
  SEARCH_CLAIMS: "Buscar reclamaciones",
  WALLET_INDEX_NAV: "{current} de {total}",
  ALL: "Todas",
  VIEW_WALLET_IN_NEW_TAB: "Ver la cartera en una nueva pesta\xF1a",
  WALLET: "Cartera",
  LOADING_CLAIMS: "Cargando reclamaciones...",
  ALL_CLAIMS: "Todas las reclamaciones",
  CLOSED: "Cerrado",
  VOIDED_BY_LEAGUE: "Anulada por League",
  PENDING_TRANSFER: "Transferencia pendiente",
  SETTLED: "Establecido",
  SUBMITTED: "Enviado",
  SUBMITTED_ON: "Enviado el",
  SUBMITTED_FOR_PREAPPROVAL: "Enviado para preaprobaci\xF3n",
  UPDATE_CLAIM: "Actualizar reclamaci\xF3n",
  REJECTED: "Denegado",
  PREAPPROVED: "Preaprobado",
  PREAPPROVAL_REJECTED: "Preaprobaci\xF3n denegada",
  PREAPPROVAL_MORE_INFORMATION_NEEDED: "M\xE1s informaci\xF3n sobre preaprobaci\xF3n",
  PENDING_EXTERNAL_REVIEW: "Enviado para revisi\xF3n externa",
  PAID: "Pagado",
  MORE_INFORMATION_NEEDED: "Necesita informaci\xF3n",
  MODIFIED_ON: "Modificado el",
  MODIFIED_BY: "Modificado por",
  CANCELLED: "Cancelado",
  APPROVED: "Aprobado",
  STARTED: "Iniciado",
  STATUS: "Estatus",
  USER_ID: "ID del usuario",
  USER: "Usuario",
  CLAIM_ID: "ID de la reclamaci\xF3n",
  LEAGUE: "League",
  CHANGE_EMPLOYEE_CLASS_ERROR_MESSAGE: "Hubo un error al intentar determinar el tipo de cambio de clase:",
  CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_BULLET_1: "<strong> Se crear\xE1 un ticket en la cola de Ops BenAdmin para contactar al miembro y recopilar las selecciones de los beneficios requeridos </strong>",
  CHANGE_EMPLOYEE_CLASS_MODAL_SELECTIONS_REQUIRED_CONTENT_1: "El cambio de clase del beneficio que activaste requiere selecciones del miembro. Una vez confirmadas, se llevar\xE1 a cabo lo siguiente:",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_6: "Toda cuenta de gastos que ya no se ofrezca se eliminar\xE1 y entrar\xE1 en un per\xEDodo de gracia",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_5: "Toda cuenta de gastos nueva se agregar\xE1 con asignaci\xF3n prorrateada",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_4: "Toda cuenta de gastos existente se asignar\xE1 a la clase nueva y el saldo se actualizar\xE1 por asignaci\xF3n de la clase nueva",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_3: "Todo beneficio que ya no se ofrezca se desactivar\xE1 y entrar\xE1 en un per\xEDodo de gracia",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_2: "Todo beneficio ofrecido recientemente (que no requiera selecciones) se asignar\xE1 autom\xE1ticamente con una <em> fecha de vigencia </em> = fecha de cambio de clase",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_BULLET_1: "Los beneficios actuales del miembro se asignar\xE1n a la clase nueva y mantendr\xE1n la misma <em> fecha de vigencia </em>",
  CHANGE_EMPLOYEE_CLASS_MODAL_AUTO_ALLOCATE_CONTENT_1: "El cambio de clase del beneficio que activaste es elegible para <strong>asignaci\xF3n autom\xE1tica</strong>. Una vez confirmado, se llevar\xE1 a cabo lo siguiente:",
  CHECKING_CLASS_CHANGE_TYPE: "Verificando tipo de cambio de clase...",
  CHANGE_EMPLOYEE_CLASS_CONFIRM: "Cambiar categor\xEDa",
  CHANGE_EMPLOYEE_CLASS_CANCEL: "Cancelar",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_4: "Cualquier saldo de cuentas de gastos ser\xE1 transferido a las nuevas cuentas de gastos correspondientes",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_3: "Si el empleado tiene opciones de tipo de cobertura y de selecci\xF3n de plan, deber\xE1 iniciar sesi\xF3n para activar sus beneficios.",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_2: "El plan de beneficios actual del empleado seguir\xE1 vigente hasta la activaci\xF3n del nuevo plan de beneficios.",
  CHANGE_EMPLOYEE_CLASS_MODAL_CONTENT_1: "Al cambiar la categor\xEDa del empleado, se producir\xE1n los siguientes cambios:",
  CHANGE_EMPLOYEE_CLASS_HEADER: "Cambiar la categor\xEDa del empleado {employee}",
  REMOVE_ADMIN_ROLE_BUTTON: "Eliminar Rol de administrador",
  REMOVE_ADMIN_ROLE_TEXT: "Al eliminar este usuario como administrador ya no tendr\xE1:",
  REMOVE_ADMIN_ROLE_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas eliminar el permiso como administrador del empleado {name}?",
  REMOVE_ADMIN_ROLE_HEADER: "Eliminar Rol de administrador",
  ADD_ADMIN_ROLE_BUTTON: "Agregar Rol de administrador",
  ADD_ADMIN_ROLE_TEXT: "Al cambiar este usuario a administrador, tendr\xE1s:",
  ADD_ADMIN_ROLE_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas agregar permiso de administrador al empleado {name}?",
  ADD_ADMIN_ROLE_HEADER: "Agregar Rol de administrador",
  RESEND_INVITE_EMAIL_ERROR: "Perd\xF3n, se produjo un error al reenviar el correo electr\xF3nico de la invitaci\xF3n.",
  RESEND_INVITE_EMAIL_SUCCESS: "El correo electr\xF3nico de la invitaci\xF3n fue reenviado.",
  RESEND_INVITE_EMAIL: "Reenviar el correo electr\xF3nico de invitaci\xF3n",
  COPY_INVITE_URL_CONFIRMATION_BTN: "Continuar",
  COPY_INVITE_URL_CONFIRMATION_CONTENT: "El URL {url} de la invitaci\xF3n fue copiado a tu portapapeles. P\xE9galo donde lo necesites.",
  COPY_INVITE_URL_CONFIRMATION_HEADER: "URL de la invitaci\xF3n copiado",
  COPY_INVITE_URL: "Copiar URL de la invitaci\xF3n",
  REINSTATE_CONFIRMATION_BUTTON: "Reincorporar empleado",
  SCHEDULE_REINSTATE_CONFIRMATION_LABEL: "Fecha de reincorporaci\xF3n",
  REINSTATE_CONFIRMATION_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas reincorporar al empleado {name}? Elige la fecha de reincorporaci\xF3n para este empleado a continuaci\xF3n.",
  REINSTATE_CONFIRMATION_HEADER: "Reincorporar a {name}",
  SUSPENSION_EMPLOYER_CONFIRMATION_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas suspender al empleador {name}?",
  SUSPENSION_CONFIRMATION_BUTTON: "Suspender empleado",
  SCHEDULE_SUSPENSION_CONFIRMATION_LABEL: "Fecha de suspensi\xF3n",
  SUSPENSION_CONFIRMATION_DESCRIPTION: "Suspender a {name} tambi\xE9n actualizar\xE1 las fechas en las que vencer\xE1n tus beneficios. Elige la fecha de suspensi\xF3n para este empleado a continuaci\xF3n.",
  SUSPENSION_CONFIRMATION_HEADER: "Suspender a {name}",
  TERMINATION_CONFIRMATION_BUTTON: "Dar de baja a empleado",
  SCHEDULE_TERMINATION_CODE_LABEL: "Motivo de la baja",
  SCHEDULE_TERMINATION_CONFIRMATION_LABEL: "Fecha de la baja",
  TERMINATION_CONFIRMATION_DESCRIPTION: "Dar de baja a {name} tambi\xE9n actualizar\xE1 las fechas en las que vencer\xE1n los beneficios. Elige la fecha de la baja del empleado a continuaci\xF3n.",
  TERMINATION_CONFIRMATION_HEADER: "Dar de baja a {name}",
  BULK_REINSTATE_CONFIRMATION_BUTTON: "Reincorporar empleados",
  BULK_REINSTATE_CONFIRMATION_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas reincorporar los empleados seleccionados?",
  BULK_REINSTATE_CONFIRMATION_HEADER: "Reincorporar Empleados",
  BULK_SUSPENSION_CONFIRMATION_BUTTON: "Suspender empleados",
  BULK_SUSPENSION_CONFIRMATION_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas suspender a los empleados seleccionados?",
  BULK_SUSPENSION_CONFIRMATION_HEADER: "Suspender empleados",
  BULK_TERMINATION_CONFIRMATION_BUTTON: "Dar de baja empleados",
  BULK_TERMINATION_CONFIRMATION_DESCRIPTION: "\xBFEst\xE1s seguro de que deseas dar de baja a los empleados seleccionados?",
  BULK_TERMINATION_CONFIRMATION_HEADER: "Dar de baja Empleados",
  ADD_DEPOSIT_ACCOUNT: "Agregar Cuenta de dep\xF3sito",
  QUICKLY_ADD_YOUR_BANKING_INFO: "Agrega r\xE1pidamente tu informaci\xF3n bancaria para recibir los pagos por tus reclamaciones.",
  NO_DEPOSIT_ACCOUNTS_CONNECTED: "No tienes cuentas de dep\xF3sito conectadas",
  REIMBURSEMENT_METHODS_APPLY_ONLY_TO: "Estos m\xE9todos de reembolso solo aplican para las cuentas de gastos de League.",
  INTERAC_E_TRANSFER: "Transferencia electr\xF3nica Interac",
  DIRECT_DEPOSIT: "Dep\xF3sito directo",
  MAKE_DEFAULT: "Configurar como por defecto",
  DEFAULT: "Por defecto",
  PASSCODE: "C\xF3digo de acceso",
  COPIED_INTERAC_CODE: "C\xF3digo Interac copiado",
  COPY: "Copiar",
  NEW_PASSCODE_GENERATED_SUCCESS: "Nuevo C\xF3digo de acceso generado",
  DEFAULT_PAYEE_ERROR: "No se pudo cambiar la cuenta por defecto",
  DEFAULT_PAYEE_SUCCESS: "Cuenta por defecto cambiada",
  INTERAC: "Interac",
  CONFIRM_ACCOUNT_INFORMATION: "Confirmar la informaci\xF3n de la cuenta",
  ADD_ACCOUNT: "Agregar cuenta",
  IBAN_BASED_ON_EXAMPLE_BELOW: "Puedes encontrar tu IBAN basado en el siguiente ejemplo, en tu cuenta bancaria en l\xEDnea o en tus estados de cuenta bancarios.",
  ACCOUNT_NUMBER: "N\xFAmero de cuenta",
  BRANCH_IDENTIFIER: "Identificador de la sucursal",
  BANK_IDENTIFIER: "Identificador del banco",
  CHECK_NUMBER: "N\xFAmero de cheque",
  COUNTRY_CODE: "C\xF3digo de pa\xEDs",
  HOW_CAN_I_FIND_IBAN_NUMBER: "\xBFD\xF3nde puedo encontrar mi n\xFAmero IBAN?",
  USE_AS_DEFAULT_DEPOSIT_ACCOUNT: "Usar esta como mi cuenta de dep\xF3sito por defecto",
  ENTER_IBAN_NUMBER: "Ingresa n\xFAmero IBAN",
  IBAN_NUMBER_WITH_ACCOUNT_NUMBER: "<strong>N\xFAmero IBAN</strong>:{accountNumber}",
  IBAN_NUMBER: "N\xFAmero IBAN",
  WHERE_CAN_I_FIND_THIS_INFORMATION: "\xBFD\xF3nde puedo encontrar esta informaci\xF3n?",
  ENTER_YOUR_BANK_INFORMATION: "Ingresa la informaci\xF3n de tu banco para recibir los pagos por dep\xF3sito directo en tu cuenta. ",
  ACCOUNT_INFORMATION: "Informaci\xF3n sobre la cuenta",
  DEPOSIT_METHODS_APPLY_ONLY_TO: "Estos m\xE9todos de dep\xF3sito solo se aplican a las cuentas de gastos de League.",
  SELECT_DEPOSIT_METHOD: "Selecciona el m\xE9todo de dep\xF3sito que deseas agregar.",
  BACK_TO_BANKING_INFO: "Volver a Informaci\xF3n bancaria",
  SUCCESS_ACCOUNT_ADDED: "\xA1Bien hecho! Cuenta agregada.",
  BANKING_INFO: "Informaci\xF3n bancaria",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DEPOSIT_ACCOUNT: "\xBFEst\xE1s seguro de que deseas borrar esta cuenta de dep\xF3sito? Esta acci\xF3n no es reversible.",
  DELETE_ACCOUNT: "Borrar cuenta",
  BY_DELETING_THIS_PAYMENT_METHOD: "Al borrar este m\xE9todo de pago, no podr\xE1s recibir m\xE1s pagos por este medio. Para asegurarte de que todas las reclamaciones de pago sean procesadas correctamente, verifica que tienes al menos un m\xE9todo de pago configurado.",
  IBAN_NUMBER_IS_REQUIRED: "El n\xFAmero IBAN es obligatorio.",
  DELETE_DIRECT_DEPOSIT: "Borrar Dep\xF3sito directo",
  EDIT_DIRECT_DEPOSIT: "Editar Dep\xF3sito directo",
  HOW_CAN_I_FIND_MY_IBAN_NUMBER: "\xBFD\xF3nde puedo encontrar mi n\xFAmero IBAN?",
  ENTER_YOUR_IBAN_NUMBER_TO_HAVE_PAYMENTS_DIRECTLY_DEPOSITED: "Ingresa tu n\xFAmero IBAN para recibir pagos por dep\xF3sito directo en tu cuenta. ",
  YOUR_DEPOSIT_ACCOUNT_WAS_DELETED: "Tu cuenta de dep\xF3sito fue borrada.",
  WE_SAVED_YOUR_CHANGES: "Hemos guardado tus cambios.",
  AN_ISSUE_EDITING_YOUR_ACCOUNT: "Se present\xF3 un problema al editar tu cuenta, por favor, vuelve a intentarlo. Si el problema persiste, contacta nuestro equipo de atenci\xF3n al cliente.",
  AN_ISSUE_ADDING_YOUR_ACCOUNT: "Se present\xF3 un problema al agregar tu cuenta, por favor, vuelve a intentarlo. Si el problema persiste, contacta nuestro equipo de atenci\xF3n al cliente.",
  ENTER_YOUR_ACCOUNT_NUMBER_US: "Ingresa tu n\xFAmero de cuenta",
  ENTER_YOUR_ACCOUNT_NUMBER_CA: "Ingresa tu n\xFAmero de cuenta (hasta 12 d\xEDgitos)",
  ENTER_YOUR_INSTITUTION_NUMBER: "Ingresa tu n\xFAmero de instituci\xF3n de 3 d\xEDgitos",
  INSTITUTION_NUMBER: "N\xFAmero de la instituci\xF3n",
  ENTER_YOUR_BRANCH_TRANSIT_NUMBER: "Ingresa el n\xFAmero de tr\xE1nsito de tu sucursal de 5 d\xEDgitos",
  BRANCH_TRANSIT_NUMBER: "N\xFAmero de tr\xE1nsito de la sucursal",
  ENTER_YOUR_NAME_AS_APPEARS_ON_ACCOUNT: "Ingresa tu nombre tal como aparece en tu cuenta",
  FULL_NAME: "Nombre completo",
  YOUR_NAME: "Tu nombre",
  ENTER_YOUR_ROUTING_NUMBER: "Ingresa tu n\xFAmero de ruta de 9 d\xEDgitos",
  ROUTING_NUMBER: "N\xFAmero de ruta",
  YOU_CAN_FIND_DIRECT_DEPOSIT_INFORMATION_ON: "Puedes encontrar esta informaci\xF3n en un cheque personal, en tu banco en l\xEDnea o en tus estados de cuenta bancarios.",
  INSTITUTION_NUMBER_REQUIRED: "El n\xFAmero de instituci\xF3n es obligatorio.",
  EMAIL_REQUIRED: "La direcci\xF3n de correo electr\xF3nico es obligatoria.",
  BRANCH_NUMBER_REQUIRED: "El n\xFAmero de sucursal es obligatorio.",
  ROUTING_NUMBER_REQUIRED: "El n\xFAmero de ruta es obligatorio.",
  ACCOUNT_NUMBER_REQUIRED: "El n\xFAmero de cuenta es obligatorio.",
  ENTER_VALID_CA_ACCOUNT_NUMBER: "Ingresa un n\xFAmero de cuenta v\xE1lido de 5 a 12 d\xEDgitos.",
  ENTER_VALID_INSTITUTION_NUMBER: "Ingresa un n\xFAmero de instituci\xF3n v\xE1lido de 3 d\xEDgitos.",
  ENTER_VALID_EMAIL: "Ingresa una direcci\xF3n de correo electr\xF3nico v\xE1lida.",
  ENTER_VALID_BRANCH_TRANSIT_NUMBER: "Ingresa un n\xFAmero de tr\xE1nsito de la sucursal v\xE1lido de 5 d\xEDgitos.",
  ENTER_VALID_US_ACCOUNT_NUMBER: "Ingresa un n\xFAmero de cuenta v\xE1lido de 4 a 17 d\xEDgitos.",
  ENTER_VALID_ROUTING_NUMBER: "Ingresa un n\xFAmero de ruta de 9 d\xEDgitos v\xE1lido.",
  ENTER_VALID_IBAN: "Tu n\xFAmero IBAN debe tener entre 15 y 34 caracteres.",
  ENTER_YOUR_EMAIL_ADDRESS: "Ingresa tu direcci\xF3n de correo electr\xF3nico",
  INTERAC_USAGE_DESCRIPTION: "Ingresa la direcci\xF3n de correo electr\xF3nico en la cual quieres recibir notificaciones sobre tus transferencias electr\xF3nicas de fondos. Una vez que env\xEDes tu direcci\xF3n de correo electr\xF3nico, generaremos un c\xF3digo de acceso a tus fondos. Puedes usar este c\xF3digo de acceso cada vez que recibas una transferencia electr\xF3nica de INTERAC.",
  NEW_PASSCODE: "Nuevo C\xF3digo de acceso",
  STRIPE_ERROR: "Problemas al enviar la ID de miembro",
  STRIPE_SUCCESS: "ID de miembro enviada",
  SUBMIT_TO_STRIPE: "Enviar a Stripe",
  EOI_FORM_ON_UPLOAD: "Todos los formularios de Evidencia de asegurabilidad deben ser firmados f\xEDsicamente y <strong> enviados por correo postal directamente a la compa\xF1\xEDa aseguradora</strong>. League no aprueba las EOI, esta carga es solo para tu referencia.",
  EOI_FORM_ON_DOWNLOAD: "Todos los formularios de Evidencia de asegurabilidad deben ser firmados con tinta y luego<strong> enviados directamente por correo postal a la compa\xF1\xEDa aseguradora </strong>. Si no los recibe, tu solicitud de cobertura opcional no estar\xE1 completa.",
  PLEASE_NOTE: "Por favor, toma nota",
  SNAIL_MAIL_IT_IS: "\xA1Entendido, por correo postal!",
  THIS_BOX_MUST_BE_CHECKED_TO_CONTINUE: "Este casillero debe ser marcado para continuar",
  VIEW_ALL_CLAIMS: "Ver todas las reclamaciones",
  YOU_DONT_HAVE_CLAIMS: 'No tienes ninguna reclamaci\xF3n "{status}".',
  UPLOAD: "Cargar",
  DOCUMENTS_AND_FORMS: "Documentos y formularios",
  ACTIONS: "Acciones",
  EVIDENCE_OF_INSURABILITY: "Evidencia de asegurabilidad (EOI)",
  ALL_UPLOADED_DOCUMENTS_ARE_SECURE: "Todos los documentos cargados en League est\xE1n seguros y son confidenciales. A continuaci\xF3n tienes acceso f\xE1cil a todos los documentos que cargaste.",
  NO_FORMS_REQUIRED_FOR_YOUR_BENEFIT_PLAN: "No se requieren formularios para tu plan actual",
  ABLE_TO_DOWNLOAD_FORMS_HERE: "En el futuro, si el dise\xF1o de tu plan o tus elecciones requieren que completes formularios (ejemplo, formularios de beneficiarios), podr\xE1s descargarlos desde aqu\xED.",
  UPLOADS: "Cargas",
  FORMS_AVAILABLE: "Formularios disponibles",
  FORMS: "Formularios",
  YOUR_BENEFIT_PLAN_REQUIRES_THE_FOLLOWING_FORMS: "Los siguientes formularios son necesarios para asegurar que t\xFA y tus seres queridos est\xE9n protegidos. A continuaci\xF3n se brinda informaci\xF3n adicional e instrucciones para completarlos.",
  REMOVE_THIS_DOCUMENT: "Eliminar este documento",
  ARE_YOU_SURE_YOU_WANT_TO_REMOVE_DOCUMENT: "\xBFEst\xE1s seguro de que deseas eliminar este documento de tu perfil? Si es requerido por League, te recomendamos que lo edites o reemplaces en lugar de eliminarlo.",
  REMOVE_DOCUMENT_QUESTION: "\xBFEliminar este documento?",
  YES_REMOVE: "S\xED, eliminar",
  DOCUMENT_FILE_TYPES: "Funcionar\xE1 con los formatos JPG, PNG y PDF",
  MAKE_SURE_THE_DOCUMENT: "Aseg\xFArate de que el documento...",
  FIELDS_MARKED_WITH_AN_ASTERISK: "Los campos marcados con un asterisco son obligatorios",
  PROGRAM_INFO: "Informaci\xF3n sobre el programa",
  VAL_POINTS: "{points} puntos",
  POINTS: "Puntos",
  FEELING_STUCK: "\xBFTe sientes atorado?",
  NOT_NOW: "No en este momento",
  QLE_ENROLLMENT_DISCLAIMER: "Una vez que comiences, reingresar\xE1s a inscripci\xF3n con la posibilidad de hacer borr\xF3n y cuenta nueva, tendr\xE1s 2 semanas para hacer todas las elecciones de tu nuevo plan, agregar o quitar dependientes.",
  QLE_YOU_QUALIFY: "\xA1Calificas! \xBFDeseas comenzar a elegir tu nuevo plan?",
  START: "Comenzar",
  ARIA_CIRCLED_CHECKMARK: "Marca de verificaci\xF3n blanca dentro de un c\xEDrculo",
  BASED_ON_YOUR_INFO: "Con base en la informaci\xF3n que brindaste, puede que califiques para elegir nuevos beneficios.",
  YOU_RE_ALMOST_DONE: "Ya casi terminas",
  RE_ENTER_ENROLLMENT: "Una vez enviada la informaci\xF3n, reingresar\xE1s a inscripci\xF3n donde podr\xE1s realizar cambios a tus elecciones de plan para ti y para tu familia.",
  ONCE_YOU_SUBMIT_YOU_WILL_BE_ABLE_TO_ADD_HSA: "Una vez enviada la informaci\xF3n, podr\xE1s agregar tu dependiente a tu Cuenta de Gastos de Salud. Este cambio tendr\xE1 efecto inmediatamente y no son necesarias otras acciones de tu parte.",
  SENT_INFORMATION_TO_HR_ADMIN_FOR_FINAL_APPROVAL: "Una vez enviada la informaci\xF3n, se entregar\xE1 a tu administrador de RR. HH. para su aprobaci\xF3n final.",
  DEADLINE_FOR_BENEFIT_SELECTION_AND_DEPENDENTS: "Todav\xEDa tienes hasta <strong>{deadlineDate}</strong> para completar la elecci\xF3n de tus nuevos beneficios y agregar o quitar dependientes.",
  IF_APPROVED_RE_ENTER_ENROLLMENT_FOR_YOURSELF_AND_FAMILY: "De ser aprobada, League te enviar\xE1 un correo electr\xF3nico avis\xE1ndote cu\xE1ndo puedes reingresar tu inscripci\xF3n y hacer nuevas elecciones para ti y para tu familia.",
  CONTINUE: "Continuar",
  INFORMATION_HAS_BEEN_SENT_FOR_HR_APPROVAL: "Tu informaci\xF3n fue enviada con \xE9xito. Te comunicaremos los siguientes pasos a la brevedad.",
  INFORMATION_SUCCESSFULLY_SENT: "\xA1Informaci\xF3n enviada con \xE9xito!",
  PLEASE_ENTER_A: "Por favor ingresa un {fieldName}",
  HSA_CONFIRMATION_CHECKBOX: "Entiendo que este dependiente solo estar\xE1 cubierto en mi Cuenta de Gastos de Salud. Certifico que la informaci\xF3n provista es exacta y est\xE1 sujeta a u aprobaci\xF3n final. Puede requerirse documentaci\xF3n adicional para verificaci\xF3n. Si se determina que no cumple con los criterios relevantes, los dependientes o las elecciones se pueden revocar en todo momento.",
  QLE_CONFIRMATION_CHECKBOX: "Certifico que la informaci\xF3n provista es exacta y que los cambios de plan propuestos est\xE1n sujetos a su aprobaci\xF3n final. Puede requerirse documentaci\xF3n adicional para verificaci\xF3n. Si se determina que no cumple con los criterios relevantes, los dependientes o las elecciones se pueden revocar en todo momento.",
  VIEW_ACTIVITY: "Ver actividad",
  TODAYS_ACTIVITY: "Actividad de hoy",
  DOCUMENT_URL: "URL del documento",
  LEARN_MORE: "M\xE1s informaci\xF3n",
  DID_YOU_RECENTLY: "\xBFTe casaste o tuviste un beb\xE9 recientemente? Algunos eventos de vida pueden calificar para una reinscripci\xF3n.",
  HAVE_YOU_RECENTLY_EXPERIENCED_CHANGES: "\xBFHas experimentado cambios recientes en tu vida o en tu familia?",
  FEEDBACK_MAX_SCALE: "Muy \xFAtil",
  FEEDBACK_MIN_SCALE: "Poco \xFAtil",
  VIEW_MY_HEALTH_PROFILE: "Ver mi Perfil de salud",
  VIEW_ALL_PROGRAMS: "Ver todos los programas",
  CONGRATS_COMPLETED: "\xA1Felicitaciones! Has completado",
  YOUVE_EARNED_TOTAL: "Has ganado un total de",
  TOMORROWS_ACTIVITY: "Actividad de ma\xF1ana",
  HELPFUL_TIPS: "Consejos \xFAtiles",
  EVENT_DATE_IS_OUT_OF_ACCEPTABLE_DATE_RANGE: "La fecha de este evento est\xE1 fuera del periodo aceptado.",
  WE_RE_SORRY_REQUESTED_PAGE_EXPIRED_OR_NOT_VALID: "Lo sentimos, la p\xE1gina que solicitaste ha vencido o ya no es v\xE1lida.",
  ALL_DONE_GO_BACK_HOME: "\xBFTodo terminado? Volver a inicio",
  DONE: "Terminado",
  GOOD_EVENING: "\xA1Buenas noches",
  GOOD_AFTERNOON: "\xA1Buenas tardes",
  GOOD_MORNING: "\xA1Buenos d\xEDas",
  X_MIN_READ: "{readTime} min de lectura ",
  VIEW_LESS: "Ver menos",
  VIEW_MORE: "Ver m\xE1s",
  QLE_ENDED_COMMON_LAW_RELATIONSHIP: "Mi relaci\xF3n de uni\xF3n libre termin\xF3",
  QLE_DATE_OF_GAIN_OF_COVERAGE: "Mi dependiente aument\xF3 su cobertura",
  QLE_MOVE_TO_OR_FROM_BC: "Me mud\xE9 a o de Columbia Brit\xE1nica",
  QLE_MOVE_TO_OR_FROM_QUEBEC: "Me mud\xE9 a o de Quebec",
  QLE_STUDENT_MOVE_TO_OR_FROM_SCHOOL: "Soy un estudiante que se va o llega de una escuela",
  QLE_MOVE_TO_DIFFERENT_ZIP_COUNTRY: "Me mud\xE9 a un c\xF3digo postal diferente o a otro pa\xEDs",
  QLE_LOST_COVERAGE_AS_DEPENDENT: "Soy un dependiente que est\xE1 perdiendo cobertura",
  QLE_DEPENDENT_LOST_COVERAGE: "Mi dependiente est\xE1 perdiendo cobertura",
  QLE_CIVIL_UNION: "Form\xE9 una uni\xF3n civil",
  QLE_FOSTER_CARE_FOR_CHILD: "Comenc\xE9 la adopci\xF3n temporal de un ni\xF1o",
  QLE_DOMESTIC_PARTNERSHIP: "Tengo una pareja de hecho",
  QLE_DEPENDENT_DEATH: "Uno de mis dependientes falleci\xF3",
  QLE_ADOPTION: "Adopt\xE9 un ni\xF1o",
  QLE_SEPARATION: "Me separ\xE9",
  QLE_DIVORCE: "Me divorci\xE9",
  QLE_BECAME_COMMON_LAW: "Empec\xE9 a vivir en uni\xF3n libre",
  QLE_BIRTH: "Tuve un beb\xE9",
  QLE_MARRIAGE: "Me cas\xE9",
  QLE_TYPE: "Tipo de QLE",
  HR: "RR. HH.",
  ELECTIONS: "Elecciones",
  NUM_DAYS: "# de d\xEDas",
  OFF_CAPS: "APAGADO",
  OFF: "Apagado",
  ON_CAPS: "ENCENDIDO",
  NEX: "SIG",
  TYPEFORM: "Typeform",
  NONE: "Ninguno",
  ALL_PROGRAMS: "Todos los programas",
  COMPLETE: "Completar",
  GO_TO_MARKETPLACE: "Ir al mercado",
  GO_TO_HEALTH_BLOG: "Ir al blog de salud",
  LEARN_MORE_ABOUT_THE_QUESTION: "M\xE1s informaci\xF3n sobre esta pregunta",
  LEARN_MORE_ABOUT_THE_PROGRAM: "M\xE1s informaci\xF3n sobre el programa {name}",
  GOAL_POINTS: "Puntos de objetivos",
  NOT_PROVIDED: "No provisto",
  TITLE_DESCRIPTION: "{title} - {description}",
  HELP_MENU: "Men\xFA Ayuda",
  TITLE_BY_PROVIDER: "{title} por {provider}",
  ONLY_CERTAIN_LIFE_EVENTS_QUALIFY_A_MEMBER: "Solo ciertos eventos de vida califican a un miembro a realizar cambios en los dependientes o elegir diferentes beneficios. Puedes configurar las caracter\xEDsticas de estos eventos de vida calificados (QLE) por nivel de clase de beneficio.",
  HR_EMAILS_FOR_QLE_APPROVALS: "Correos electr\xF3nicos de la administraci\xF3n de RR. HH. para aprobaci\xF3n de QLE",
  AUTOMATIC_EMAIL_SENT_TO_HE_EMAILS_PROVIDED: "Se env\xEDa un correo electr\xF3nico autom\xE1tico a la lista provista. La lista est\xE1 en un nivel de grupo y todo cambio realizado se refleja a nivel del grupo para todo tipo de QLE configurado para necesitar aprobaci\xF3n. Si deseas incluir m\xFAltiples destinatarios, separa sus direcciones de correo electr\xF3nico con una coma.",
  SEPARATE_EMAILS_BY_COMMA: "Separa las diferentes direcciones de correo electr\xF3nico con una coma",
  PLEASE_ENTER_HR_APPROVAL_EMAILS: "Por favor ingresa las direcciones de correo electr\xF3nico de los destinatarios que deber\xEDan ser notificados para su aprobaci\xF3n.",
  HR_EMAILS_UPDATED: "\xA1Correos electr\xF3nicos de RR. HH. actualizados!",
  SETTINGS_APPLIED: "\xA1Configuraci\xF3n aplicada!",
  INDIVIDUALLY_CONFIGURE_QLES: "Esta secci\xF3n te permite configurar individualmente los tipos de Eventos de vida calificados para esta clase de beneficio.",
  RECOMMENDED: "Recomendado para ti",
  EXIT_HEALTH_ASSESSMENT: "Salir de la Evaluaci\xF3n de salud",
  OPTIONAL_LINK: "\\{Optional link to learn more\\} Enlace opcional para obtener m\xE1s informaci\xF3n",
  THIS_WILL_TAKE: "Esto te llevar\xE1 cerca de {minutes} minutos.",
  SELECT_ALL_THAT_APPLY: "Selecciona todo lo que aplique.",
  PRESS_ENTER: "Siguiente",
  YOURE_QUESTIONS_AWAY: "Est\xE1s a {number} preguntas de ganar puntos {points} de recompensa.",
  WEVE_PICKED_UP: "Se ha retomado desde donde te quedaste.",
  FOR_PROVIDERS_AND_PHARMACISTS: "Para Proveedores/Farmac\xE9uticos",
  ONCE_YOU_SUBMIT: "Una vez enviadas, las respuestas no pueden cambiarse. Forman parte de tu Perfil de salud de base y nos ayudan a tener un panorama m\xE1s claro de tu salud estableciendo comparaciones peri\xF3dicas.",
  ARE_YOU_READY_TO_SUBMIT: "\xBFEst\xE1s listo para enviar?",
  SORRY_QLE_REQUEST_EXPIRED: "\xA1Perd\xF3n! La ventana para esta solicitud ha vencido.",
  SORRY_REFRESH_THE_PAGE: "\xA1Perd\xF3n! Trata de actualizar la p\xE1gina.",
  LEFTOVER_FLEX_HELPER_TEXT: "<strong>{percent}%</strong> de {flexDollarTerm} restantes que ser\xE1n agregados a esta cuenta",
  WHAT_IS_PRORATION_TOOLTIP: "<p>Prorrateo es cuando se dividen tus fondos proporcionalmente a cuando comenzaste en la empresa y el tiempo que te queda de la p\xF3liza. </p><p>Por ejemplo:</p><p>si Alex comenz\xF3 en la mitad de un periodo de p\xF3liza, su contribuci\xF3n {flexDollarTerm} ser\xE1 dividida a la mitad.</p>",
  YOU_CURRENTLY_HAVE_AMOUNT_FLEX_DOLLARS_REMAINING: "En este momento tienes <strong>{amount}</strong> {flexDollarTerm} restantes, as\xED se ve tu contribuci\xF3n actual:",
  YOU_CURRENTLY_HAVE_NO_FLEX_DOLLARS_REMAINING: "En este momento no tienes {flexDollarTerm} restantes.",
  WHAT_ARE_LEFTOVER_FLEX_DOLLARS_TOOLTIP: "<p>Solo ser\xE1s inscrito en esta cuenta si tienes {flexDollarTerm} restantes luego de enviar tus elecciones.</p><p>Por ejemplo:</p><p>Alex tiene $230.00 {flexDollarTerm} luego de seleccionar todos sus beneficios, esos d\xF3lares ser\xE1n agregados autom\xE1ticamente a una HSA (Cuenta de ahorros para gastos m\xE9dicos) al terminar la inscripci\xF3n.<p>",
  THIS_ACCOUNT_IS_FUNDED_BY_LEFTOVER_FLEX_DOLLARS: "Esta cuenta est\xE1 financiada por {flexDollarTerm} restantes.",
  ANY_LEFTOVER_FLEX_DOLLARS_WILL_BE_ADDED_INTO_THIS_ACCOUNT: "Cualquier  {flexDollarTerm} restante ser\xE1 agregado a esta cuenta",
  HP_ONBOARDING_SUBMIT: "Comencemos",
  SKIP: "Saltar",
  KEEP_GOING: "Contin\xFAa",
  FINISH_UP_TO_GET: "Finaliza para obtener consejos personalizados y contenido de apoyo a tu trayectoria de salud\u2014 adem\xE1s de {points} puntos de recompensa.",
  LETS_BEGIN: "\xA1Comencemos!",
  FT: "ft",
  YOUR_HEALTH_PROFILE_IS: "Tu Perfil de salud es como un panorama general de tu salud, h\xE1bitos y objetivos. Usamos 6 aspectos de la salud para brindarte una perspectiva adecuada, contenidos y programas que te permitan lograr tus objetivos personales.",
  WHY_COMPLETE: "\xBFPor qu\xE9 completar tu Perfil de salud?",
  ITLL_ONLY_TAKE: "Solo te lleva de 10 a 15 minutos completarlo.",
  YOU_HAVENT_BUILT: "No has completado tu Perfil de salud",
  BASED_ON_YOUR_HEALTH_PROFILE: "Basados en tu Perfil de salud",
  PROGRAMS_FOR_YOU: "Programas para ti",
  PAST_CARDS_STATUS: "Ve los detalles del periodo de gracia para enviar reclamaciones seleccionando una de las siguientes tarjetas.",
  PENDING_CARDS_STATUS: "Las siguientes tarjetas/beneficios est\xE1n pendientes de activaci\xF3n.",
  ACTIVE_CARDS_STATUS: "Env\xEDa una reclamaci\xF3n, ve los detalles de tu cobertura y m\xE1s seleccionando una de las siguientes tarjetas.",
  COMPLETE_ACTIVITIES: "Completa actividades para alcanzar tus objetivos de salud. Recomendadas en base a tu Perfil de salud.",
  HEALTH_PROGRAMS_FOR_YOU: "Programas de salud para ti",
  PLATFORMS: "Plataformas",
  SDM_ID_HELPER_TEXT: "Ingresa tu ID de miembro sin los ceros del inicio, por ejemplo para 0000123456, ingresa 123456.",
  FIRST_OF_FOLLOWING_MONTH: "Primer d\xEDa del mes siguiente al QLE",
  QLE_DATE: "Fecha del QLE",
  BENEFIT_EFFECT_DATE_TOOLTIP: "El d\xEDa en que el nuevo beneficio del miembro se hizo efectivo. La fecha predeterminada es la del QLE.",
  BENEFIT_EFFECT_DATE: "Fecha de inicio del beneficio",
  LADDER_RULES_TOOLTIP: "Si las reglas de escala est\xE1n activadas, los miembros solo pueden elegir su plan actual o un plan ubicado arriba o abajo de su plan actual durante un QLE (Evento de vida calificado).\n\nNota: para que esta configuraci\xF3n funcione, las reglas de escala deben estar configuradas para este beneficio.",
  ENABLE_LADDER_RULES_PROMPT: "\xBFActivar las reglas de escala para las elecciones de beneficios?",
  READ_OUR_ARTICLE: "Lee nuestro art\xEDculo",
  READING_MORE_ABOUT_DIMENSIONS: "\xBFTe interesa leer sobre c\xF3mo los diferentes aspectos de la salud repercuten en tu salud general?",
  DIMENSION_OF_HEALTH: "ASPECTOS DE LA SALUD",
  TOP_FOCUS_AREA: "\xC1rea de enfoque principal",
  PROGRAMS: "Programas",
  HEALTH: "Salud",
  DEDUCTIBLE: "Deducible",
  OUT_OF_POCKET_MAXIMUM: "M\xE1ximo de Gastos de bolsillo",
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION_LINK: "m\xE1ximo de desembolso (OOP)",
  OUT_OF_POCKET_MAXIMUM_DESCRIPTION: "Un {oopLink} es el m\xE1ximo de dinero que puedes pagar durante tu a\xF1o de plan. Una vez que alcanzaste el m\xE1ximo, tu plan cubrir\xE1 un porcentaje de los futuros costos por el resto de tu a\xF1o de plan.",
  FAMILY_DEDUCTIBLE_LINK: "deducible familiar",
  DEDUCTIBLE_DESCRIPTION_2: "Si tienes un {familyDeductibleLink} y los costos para ti y tus dependientes combinados alcanzan este deducible, tu plan comenzar\xE1 a cubrir los futuros costos de tu familia.",
  COINSURANCE_LINK: "coaseguro",
  COPAYS_LINK: "copagos",
  DEDUCTIBLE_DESCRIPTION_1: "Un deducible es el monto de dinero que debes pagar como gastos de bolsillo en un a\xF1o de plan antes de que tu cobertura de seguro comience. Cuando alcances el deducible, tu plan de seguro te ofrecer\xE1 cobertura bajo la forma de costos compartidos ({copaysLink} or {coinsuranceLink}). El deducible se aplica a todos o a la mayor\xEDa de los aspectos y servicios cubiertos por tu plan.",
  DEDUCTIBLE_DESCRIPTION: "<p>Un deducible es el monto de dinero que debes pagar como gastos de bolsillo en un a\xF1o de plan antes de que tu cobertura de seguro comience. Cuando alcances el deducible, tu plan de seguro te ofrecer\xE1 cobertura bajo la forma de costos compartidos (<a href={copays} target='_blank'>copays</a> or <a href={coinsurance} target='_blank'>coinsurance</a>). El deducible se aplica a todos o a la mayor\xEDa de los aspectos y servicios cubiertos por tu plan. </p><p>Si tienes un <a href={familyDeductible} target='_blank'>family deductible</a> y los costos para ti y tus dependientes combinados alcanzan este deducible, tu plan comenzar\xE1 a cubrir los futuros costos de tu familia.</p>",
  INDIVIDUAL_OUT_OF_POCKET_MAXIMUM: "M\xE1ximo de gastos de bolsillo individuales",
  FAMILY_OUT_OF_POCKET_MAXIMUM: "M\xE1ximo de gastos de bolsillo familiares",
  INDIVIDUAL_DEDUCTIBLE: "Deducible individual",
  FAMILY_DEDUCTIBLE: "Deducible familiar",
  OUT_OF_NETWORK: "Fuera de la red",
  IN_NETWORK: "En la red",
  REFRESH_PAGE: "Actualizar la p\xE1gina",
  WERE_HAVING_TROUBLE: "Estamos teniendo problemas para cargar esta informaci\xF3n, por favor, trata de actualizar la p\xE1gina. Si el problema persiste, {custCareLink}.",
  UH_OH_LOOKS_LIKE_SOMETHING_WENT_WRONG: "Uh, oh, parece que algo sali\xF3 mal",
  CARRIER: "compa\xF1\xEDa aseguradora",
  FROM: "de",
  MEMBER_ID_ERROR_BODY: "Estamos teniendo problemas para cargar esta informaci\xF3n porque falta tu ID de miembro o es inv\xE1lida. Contacta con {custCareLink} para actualizar su ID de miembro.",
  CONTACT_CUSTOMER_CARE: "contactar con atenci\xF3n al cliente",
  MEMBER_ID_ERROR_HEADING: "Parece que falta la ID de miembro o es inv\xE1lida",
  INDIVIDUAL_RECORDS_FOR_TODAY: "Registros individuales de hoy",
  NOT_REQUIRED: "No requerido",
  INCOMPLETE: "Incompleto",
  OVERDUE: "Atrasado",
  UNKNOWN: "Desconocido",
  ON_LEAVE: "Con permiso",
  REMOTE_WORKER: "Trabajador remoto",
  PHYSICAL_WORKER: "Trabajador f\xEDsicamente presente",
  ELIGIBILITY: "Elegibilidad",
  OFFICE: "Oficina",
  TOTAL_EMPLOYEES_AT_THIS_LOCATION: "{totalEmployees} empleados en este sitio",
  EMPLOYEE_NAME_SLASH_ID: "Nombre del empleado/ID",
  REMAINING_BALANCE: "Saldo restante",
  DOES_MY_BENEFIT_COVER: "\xBFMi {benefitName} cubre...",
  POLICY_ACTIVE: "Reglamento vigente",
  WALLET_LANDING_BODY: "Tu cartera digital es el espacio de tus beneficios de empleado, programas y detalles de tu cobertura. Encuentra lo que necesitas en un instante.",
  WALLET_LANDING_TITLE: "Tus beneficios y la informaci\xF3n sobre tu cobertura, todo en un mismo lugar.",
  GO_BACK: "Regresar",
  SEE_ALL_NOTIFICATIONS: "Ver todas las notificaciones",
  WALLET_FEEDBACK_SUBTITLE_OTHER: "\xBFQu\xE9 tan \xFAtil es la informaci\xF3n brindada por tu {cardName}?",
  WALLET_FEEDBACK_SUBTITLE_BENEFIT: "\xBFQu\xE9 tan \xFAtil es la informaci\xF3n brindada sobre tu beneficio \u201C{benefitName}\u201D?",
  WALLET_FEEDBACK_TITLE: "Ay\xFAdanos a mejorar la experiencia con la cartera",
  LOG_OUT: "Desconectar",
  TERMS_OF_SERVICE: "Condiciones de servicio",
  ARTICLES: "Art\xEDculos",
  BOOKINGS: "Reservaciones",
  NO_NOTIFICATIONS_DESCRIPTIONS: "Aqu\xED encontrar\xE1s nuevas notificaciones tales como estatus de reclamaciones, pr\xF3ximas citas y objetivos de salud.",
  NO_NOTIFICATIONS: "Sin notificaciones",
  ADD_A_DEPENDENT_CAPITAL: "Agregar un dependiente",
  HAS_NO_DEPENDENTS_DESCRIPTION: "No tienes ning\xFAn dependiente asociado a este beneficio. Si recientemente viviste alg\xFAn cambio importante, posiblemente seas elegible para agregar o cambiar tus dependientes. Presiona el bot\xF3n de abajo para verificar en solo unos pasos si calificas.",
  HAS_DEPENDENTS_DESCRIPTION: "Tus dependientes para este beneficio est\xE1n listados a continuaci\xF3n. Si recientemente viviste alg\xFAn cambio importante, posiblemente seas elegible para cambiar tus dependientes. Presiona el bot\xF3n de abajo para verificar en solo unos pasos si calificas.",
  ID_LIKE_TO_DOTDOTDOT: "Quisiera...",
  ABOUT_THIS_BENEFIT_DESCRIPTION: "Este beneficio te fue otorgado por tu empleador. Para preguntas o informaci\xF3n detallada, comun\xEDcate con tu administrador de RR. HH.",
  ABOUT_THIS_BENEFIT: "Acerca de este beneficio",
  IF_YOU_HAVE_AN_URGENT_CLAIM_PLEASE_CONTACT_CUSTOMER_SUPPORT: "Si tienes alguna reclamaci\xF3n urgente, comun\xEDcate con <a>Servicio al cliente</a>.",
  RECENT_CLAIMS: "Reclamaciones recientes",
  GO_TO_CLAIM_DETAIL: "Ir a detalle de la reclamaci\xF3n",
  NEED_MORE_INFO_MULTIPLE_CLAIMS: "Tus reclamaciones necesitan m\xE1s informaci\xF3n para ser procesadas. Ve los detalles a continuaci\xF3n,",
  NEED_MORE_INFO_SINGLE_CLAIM: "Tu reclamaci\xF3n necesita m\xE1s informaci\xF3n para ser procesada. Ve los detalles a continuaci\xF3n.",
  ROLLED_OVER: "Omitir",
  BACK_TO_DEPENDENTS: "Volver a Dependientes",
  BY_SUBMITTING_YOU_CERTIFY: "Al enviar certificas que proporcionaste informaci\xF3n exacta",
  PERSONAL_DETAILS: "Detalles personales",
  SWITCH_VIEW: "Cambiar vista",
  PERSONAL_ACCOUNT: "Cuenta personal",
  BUSINESS_PORTAL: "Portal de negocios",
  ADMIN_PORTAL: "Portal de administraci\xF3n",
  PLEASE_TRY_RELOADING_THE_PAGE_OR_CHECK_BACK_LATER: "Intenta volver a cargar la p\xE1gina o verifica despu\xE9s",
  IRELAND: "Irlanda",
  UNITED_STATES: "Estados Unidos",
  CANADA: "Canad\xE1",
  TRY_RELOADING_THE_PAGE: "Intenta volver a cargar o verifica m\xE1s tarde.",
  THERE_IS_A_PROBLEM_LOADING_THIS_PAGE: "Hay un problema al cargar la p\xE1gina",
  "RE-SEND_INVITATION": "Reenviar la invitaci\xF3n",
  INVITATION_EXPIRED: "Invitaci\xF3n vencida",
  JOINED: "Ingresado",
  INVITATION_SENT: "Invitaci\xF3n enviada",
  SEND_INVITATION: "Enviar invitaci\xF3n",
  INVITATION_SENT_TO: "INVITACI\xD3N ENVIADA A",
  SEND_DEX_INVITE_CHECKBOX: "Confirmo que la informaci\xF3n provista es exacta y verdadera.",
  DEX_INVITE_PRIVACY_DISCLAIMER: "Tu informaci\xF3n personal est\xE1 segura con nosotros, no ser\xE1 compartida con tus dependientes. M\xE1s informaci\xF3n en nuestra {privacyPolicyLink}.",
  "RE-SEND_DEX_INVITE_CHECKBOX": "Acepto que el receptor de esta invitaci\xF3n conoce y aprueba esta solicitud y que la informaci\xF3n provista es exacta y verdadera.",
  SEND_DEX_INVITE_DESCRIPTION: "Utiliza una direcci\xF3n de correo electr\xF3nico que no est\xE9 asociada con una cuenta de League ya existente. Esta invitaci\xF3n vence 14 d\xEDas luego de su env\xEDo.",
  "RE-SEND_DEX_INVITE_DESCRIPTION": "Ya se ha enviado una invitaci\xF3n a la lista de direcciones de correo electr\xF3nico. Si todav\xEDa no han sido recibidas o caducaron, aseg\xFArate de que la direcci\xF3n de correo electr\xF3nico sea la correcta y vuelve a enviarla.",
  SEND_DEX_INVITE_HEADING: "\xBFQu\xE9 direcci\xF3n de correo electr\xF3nico debemos usar para invitar a {name}?",
  "RE-SEND_DEX_INVITE_HEADING": "\xBFEst\xE1s seguro de que deseas reenviar una invitaci\xF3n a {name}? ",
  EMAIL: "Correo electr\xF3nico",
  EMAIL_ERROR_MESSAGE: "Debe utilizar un formato v\xE1lido de correo electr\xF3nico",
  ALL_OFFICES: "Todas las oficinas",
  DEX_GENERIC_ERROR_MESSAGE: "Parece que algo sali\xF3 mal. Carga nuevamente o vuelve a intentarlo. Si el problema persiste, <a>comun\xEDcate con nosotros</a>",
  DEX_GENERIC_ERROR_MESSAGE_HEADING: "\xA1Oh! parece que algo sali\xF3 mal",
  DEX_EMAIL_EXISTS_ERROR: "Este correo electr\xF3nico ya est\xE1 asociado a una cuenta de League.",
  DEX_SUCCESS_MESSAGE: "\xA1Tu invitaci\xF3n est\xE1 en camino!",
  DEX_SUCCESS_MESSAGE_HEADING: "\xA1Invitaci\xF3n enviada!",
  ZE_ZIM: "Ze/Zim",
  XE_XEM: "Elle",
  THEY_THEM: "Ellos/los",
  SHE_HER: "Ella/ella",
  HE_HIM: "\xC9l/\xE9l",
  TWO_SPIRIT: "Dos esp\xEDritus",
  TRANSGENDER_WOMAN: "Mujer transg\xE9nero",
  TRANSGENDER_MAN: "Hombre transg\xE9nero",
  NON_BINARY: "No binario",
  INTERSEX: "Intersexual",
  GENDERQUEER_NONCOMFORMING: "G\xE9nero variado no conforme",
  GENDERQUEER: "G\xE9nero variado",
  BIGENDER: "Big\xE9nero",
  AGENDER: "Ag\xE9nero",
  WOMAN: "Mujer",
  MAN: "Hombre",
  SELECT_LANGUAGE: "Selecciona un idioma",
  GET_CARE: "Cu\xEDdate",
  DEX_VALIDATION_ERRORS_BANNER: "<strong>Hemos encontrado algunos errores en esta p\xE1gina.</strong> Por favor, corrige los campos marcados en rojo y vuelve a enviarlos.",
  SELECT_A_BENEFIT: "Selecciona un beneficio",
  DID_YOU_GET_A_PROMO_CODE_DESCRIPTION: "Si has recibido un cr\xE9dito de la tienda o un c\xF3digo de promoci\xF3n, puedes introducirlo aqu\xED para recibir los cr\xE9ditos. Una vez introducidos se aplicar\xE1n autom\xE1ticamente a tu cuenta.",
  DID_YOU_GET_A_PROMO_CODE: "\xBFRecibiste un c\xF3digo de promoci\xF3n?",
  LEAGUE_CREDIT_DESCRIPTION_2: 'Para utilizar tu Cr\xE9dito League, selecciona "Cr\xE9dito League" en la lista de opciones de pago cuando realices la compra en Lifemarket. {learnMoreLink}',
  LEAGUE_CREDIT_DESCRIPTION_1: 'Puedes gastar el Cr\xE9dito League en {lifemarketLink} en una variedad de productos y servicios de estilo de vida y bienestar. Lo \xFAnico en lo que no puedes gastarlo es en cualquier cosa marcada como "Oferta de Socio de League".',
  ABOUT: "Acerca de",
  LIFEMARKET: "Lifemarket",
  TOTAL_BALANCE: "Saldo total",
  SUBMIT_CLAIM_AGREEMENT_4: "Certifico que la informaci\xF3n proporcionada es exacta y que mis reclamaciones est\xE1n sujetas a la aprobaci\xF3n final. Es posible que se requiera documentaci\xF3n adicional para su verificaci\xF3n. Si se determina que no cumple los criterios pertinentes, esta solicitud puede ser revocada en cualquier momento.",
  SUBMIT_CLAIM_AGREEMENT_3: "Que no puedo ni quiero presentar esta solicitud de reembolso en otro lugar.",
  SUBMIT_CLAIM_AGREEMENT_2: "Ya he pagado al proveedor y la documentaci\xF3n aportada es espec\xEDfica para esta reclamaci\xF3n y para m\xED o para otro miembro asegurado para este beneficio.",
  SUBMIT_CLAIM_AGREEMENT_1: "La informaci\xF3n es, a mi leal saber y entender, verdadera y correcta, y entiendo que las reclamaciones falsas o fraudulentas pueden dar lugar a un proceso civil o penal.",
  SUBMIT_CLAIM_AGREEMENT_HEADING: "Al enviar, entiendo y acepto lo siguiente:",
  BROWSE_FILES: "Navegar archivos",
  OR: "o",
  DRAG_AND_DROP_FILES_HERE: "Arrastrar y soltar archivos aqu\xED",
  CLAIM_UPLOAD_INSTRUCTIONS_3_1: "Las im\xE1genes ocupan toda la p\xE1gina y tienen una buena iluminaci\xF3n",
  CLAIM_UPLOAD_INSTRUCTIONS_3: "Tus im\xE1genes son claras y f\xE1ciles de leer",
  CLAIM_UPLOAD_INSTRUCTIONS_2_1: "Puede ser un recibo o un extracto de la tarjeta de cr\xE9dito",
  CLAIM_UPLOAD_INSTRUCTIONS_2: "Incluyes el comprobante de pago del producto o servicio",
  CLAIM_UPLOAD_INSTRUCTIONS_1_3: "El nombre del vendedor o proveedor de servicios sanitarios",
  CLAIM_UPLOAD_INSTRUCTIONS_1_2: "La fecha en que hiciste la compra o recibiste el servicio",
  CLAIM_UPLOAD_INSTRUCTIONS_1_1: "El art\xEDculo o servicio que has comprado",
  CLAIM_UPLOAD_INSTRUCTIONS_1: "Los documentos incluyen la siguiente informaci\xF3n",
  CLAIM_UPLOAD_INSTRUCTIONS_HEADING: "Antes de subir, aseg\xFArate de que...",
  NOTES_PLACEHOLDER: "Have any other info you\u2019d like to share with us or doesn\u2019t appear on your documents? Add it here (ex. provider name, service date, etc.)",
  CLAIM_AMOUNT_TOOLTIP_2: "Por ejemplo, si presentas una reclamaci\xF3n por una compra que cuesta $100 pero s\xF3lo quieres que te reembolsemos $80, introduce aqu\xED $80.",
  CLAIM_AMOUNT_TOOLTIP_1: "Puedes introducir la cantidad de dinero que quieres que te reembolsemos (incluso si es menor que la cantidad que aparece en tu recibo).",
  CLAIM_AMOUNT_LABEL: "Cantidad que quieres reclamar",
  CLAIM_BENEFIT_TOOLTIP_2: "\xBFNecesitas presentar una reclamaci\xF3n por un beneficio del seguro? Selecciona el beneficio en tu Cartera para acceder al portal de tu aseguradora y enviar tu reclamaci\xF3n.",
  CLAIM_BENEFIT_TOOLTIP_1: "Aqu\xED encontrar\xE1s los beneficios por los que puedes enviar reclamaciones en tu cuenta de League.",
  CLAIM_BENEFIT_LABEL: "Beneficio a reclamar",
  BANKING_INFORMATION: "Informaci\xF3n bancaria",
  BANKING_INFO_BANNER: "Primero tendr\xE1s que configurar tu {bankingInfoLink} en tu cuenta de League antes de que podamos reembols\xE1rtelo.",
  OUR_GUIDE_TO_SUBMITTING_CLAIMS: "nuestra gu\xEDa para presentar reclamaciones",
  SUBMIT_CLAIM_INTRO: "Aseg\xFArate de que la fecha en que pagaste el art\xEDculo o recibiste el servicio est\xE1 dentro del periodo de p\xF3liza del beneficio por el que env\xEDas la reclamaci\xF3n. Puedes encontrar el periodo de p\xF3liza en la tarjeta del beneficio en tu {walletLink} antes de enviar tu reclamaci\xF3n. Si tienes alguna duda o quieres saber m\xE1s, lee {ourGuideToSubmittingClaimsLink}.",
  SUBMIT_CLAIM_SUBHEADING: "Sube los recibos o las recetas y obt\xE9n el reembolso en pocos d\xEDas.",
  SUBMIT_CLAIM_HEADING: "Enviar una reclamaci\xF3n",
  REMOVE_NAMED_FILE: "Eliminar el archivo {name}",
  CLAIM_FORM_HAS_ERRORS: "Debes rellenar todos los campos obligatorios antes de enviar una reclamaci\xF3n.",
  FILE_TOO_LARGE_ERROR: "El archivo que has seleccionado es demasiado grande. Por favor, selecciona un archivo de menos de 100 MB.",
  FILES_REQUIRED_ERROR: "Por favor, a\xF1ade al menos una imagen o un PDF para enviar una reclamaci\xF3n.",
  INSERT: "Insertar",
  SELECT_A_VARIABLE: "Selecciona una variable...",
  STYLED_CONTENT: "Contenido con estilo",
  HTML_SOURCE: "Fuente HTML",
  CLAIM_ERROR_LINK: "Equipo de atenci\xF3n al cliente",
  UPDATE_CLAIM_ERROR_DESCRIPTION: "Hubo un problema al actualizar tu reclamaci\xF3n, vuelve a intentarlo. Si eso no funciona, ponte en contacto con nuestro {custCareLink} .",
  SUBMIT_CLAIM_ERROR_DESCRIPTION: "Hubo un problema al enviar tu reclamaci\xF3n, vuelve a intentarlo. Si eso no funciona, ponte en contacto con nuestro {custCareLink} .",
  CLAIM_ERROR_HEADING: "\xA1Oh, no! Parece que algo sali\xF3 mal.",
  SUBMIT_CLAIM_SUCCESS_DESCRIPTION: "Danos de 1 a 3 d\xEDas h\xE1biles para procesar tu solicitud. Recibir\xE1s un correo electr\xF3nico cuando se apruebe tu reclamaci\xF3n o si necesitamos m\xE1s informaci\xF3n para procesarla.",
  SUBMIT_CLAIM_SUCCESS_HEADING: "Tu reclamaci\xF3n se envi\xF3 con \xE9xito.",
  CANCEL_CLAIM_BUTTON: "Cancelar esta reclamaci\xF3n",
  CANCEL_CLAIM_BODY: "Si cancelas tu reclamaci\xF3n, no se puede deshacer la acci\xF3n.",
  CANCEL_CLAIM_HEADING: "\xBFEst\xE1s seguro de que deseas cancelar esta reclamaci\xF3n?",
  CANCEL_CLAIM_SUCCESS_BODY: "Procedimos y cancelamos esta reclamaci\xF3n con \xE9xito.",
  CANCEL_CLAIM_SUCCESS_HEADING: "Reclamaci\xF3n cancelada",
  CANCEL_CLAIM_ERROR_BODY: "Intenta de nuevo. Si no funciona, ponte en contacto con nuestro equipo de atenci\xF3n al cliente.",
  CANCEL_CLAIM_ERROR_HEADING: "Error al cancelar la reclamaci\xF3n",
  PREVIOUSLY_UPLOADED: "Cargado anteriormente",
  UPDATE_CLAIM_SUCCESS: "Reclamaci\xF3n actualizada",
  PLEASE_CALL: "Llama al {phoneNumber}"
};

// src/hooks/use-intl.tsx
import React from "react";
import { genesisStyled, Box } from "@leagueplatform/genesis-commons";
import { useIntl as useReactIntl } from "react-intl";
var MessageContainer = genesisStyled(Box)`
  p:last-of-type {
    margin-bottom: 0;
  }
`;
var useIntl = () => {
  const intl = useReactIntl();
  return __spreadProps(__spreadValues({}, intl), {
    formatMoney: ({ F = 0, C = "USD" } = {}, {
      minimumFractionDigits = 2,
      style = "currency",
      currencyDisplay = "symbol"
    } = {}) => intl.formatNumber(F, {
      currency: C,
      minimumFractionDigits,
      style,
      currencyDisplay
    }),
    formatHTMLMessage: (props, values) => /* @__PURE__ */ React.createElement(
      MessageContainer,
      {
        as: "span",
        dangerouslySetInnerHTML: {
          __html: intl.formatMessage(props, values)
        }
      }
    )
  });
};

// src/utils/cookie-utils.ts
import cookie from "cookie";
var internalSetCookie = (name, value) => {
  const rawCookie = {};
  rawCookie[name] = value;
  if (typeof value === "object") {
    rawCookie[name] = JSON.stringify(value);
  }
  document.cookie = cookie.serialize(name, rawCookie[name], { path: "/" });
  return document.cookie;
};
var getCookie = (name) => {
  const cookies = cookie.parse(document.cookie);
  const value = cookies && cookies[name];
  let parsedValue = null;
  try {
    parsedValue = JSON.parse(value);
  } catch (e) {
  }
  return parsedValue || value;
};

// src/constants.ts
var DEFAULT_MESSAGES = {
  default: en_default,
  en: en_default,
  fr: fr_CA_default,
  es: es_MX_default
};
var supportedLocaleList = ["en", "fr", "es"];
var CA_COUNTRY_CODE = "CA";
var US_COUNTRY_CODE = "US";
var supportedLocaleMapping = {
  [CA_COUNTRY_CODE]: ["en", "fr"],
  [US_COUNTRY_CODE]: ["en", "es-MX"]
};
var FullNameLocaleList = {
  en: "LANGUAGE_SELECTOR_ENGLISH",
  fr: "LANGUAGE_SELECTOR_FRENCH",
  "es-MX": "LANGUAGE_SELECTOR_SPANISH"
};
var DEFAULT_LOCALE = "en";
var LANGUAGE_COOKIE_NAME = "league_current_language";

// src/get-current-locale.ts
var getCurrentLocale = (defaultLocale = DEFAULT_LOCALE) => getCookie(LANGUAGE_COOKIE_NAME) || (navigator == null ? void 0 : navigator.language) || defaultLocale;

// src/get-react-intl-props.ts
import { isProduction } from "@leagueplatform/app-environment";
import { getCustomLocaleConfig } from "@leagueplatform/locale-config";
var getReactIntlProps = (defaultLocale, supportedLocaleList2, locale = defaultLocale) => {
  const language = locale.split("-")[0];
  const localeMessages = DEFAULT_MESSAGES[locale] || DEFAULT_MESSAGES[language];
  const customLocales = getCustomLocaleConfig();
  const customLocaleMessages = (customLocales == null ? void 0 : customLocales[language]) || (customLocales == null ? void 0 : customLocales.default);
  const messages = __spreadValues(__spreadValues(__spreadValues({}, DEFAULT_MESSAGES.default), localeMessages), customLocaleMessages);
  if (!isProduction() && localStorage.getItem("LEAGUE_SHOW_LOCALE_KEYS")) {
    Object.keys(messages).forEach((key) => messages[key] = `<${key}>`);
  }
  return {
    locale: supportedLocaleList2.includes(language) ? locale : defaultLocale,
    messages
  };
};

// src/get-app-supported-locale.ts
var getAppSupportedLocale = (defaultLocale, supportedLocaleList2) => {
  var _a;
  const languageLocale = (_a = navigator == null ? void 0 : navigator.language) == null ? void 0 : _a.split("-")[0];
  if (languageLocale && supportedLocaleList2.includes(languageLocale))
    return languageLocale;
  return defaultLocale;
};

// src/intl-shape.ts
import PropTypes from "prop-types";
var intlShape = PropTypes.shape({
  formatMessage: PropTypes.func,
  formatHTMLMessage: PropTypes.func,
  formatNumber: PropTypes.func,
  formatDate: PropTypes.func
});

// src/is-french.ts
var isFrench = (defaultLocale = DEFAULT_LOCALE) => getCurrentLocale(defaultLocale).includes("fr");

// src/is-spanish.ts
var isSpanish = (defaultLocale = DEFAULT_LOCALE) => getCurrentLocale(defaultLocale).includes("es");

// src/league-intl-provider/league-intl-provider.component.tsx
import React3 from "react";

// src/league-intl-provider/intl-provider-wrapper.component.tsx
import React2, { useContext } from "react";
import { IntlProvider } from "react-intl";
var defaultRichTextElements = Object.fromEntries(
  ["strong", "p", "em", "li", "ul", "br", "span", "button"].map((tag) => {
    const formatter = (chunks) => chunks.length ? `<${tag}>${chunks}</${tag}>` : `<${tag} />`;
    return [tag, formatter];
  })
);
var LocaleContext = React2.createContext({
  locale: DEFAULT_LOCALE,
  setLocale: () => {
  }
});
var IntlProviderWrapper = ({ children }) => {
  const { locale } = useContext(LocaleContext);
  const intlProps = getReactIntlProps(
    DEFAULT_LOCALE,
    supportedLocaleList,
    locale
  );
  return /* @__PURE__ */ React2.createElement(
    IntlProvider,
    {
      locale: intlProps.locale,
      messages: intlProps.messages,
      defaultRichTextElements
    },
    children
  );
};

// src/hooks/use-cookie/use-cookie-hook.ts
import { useState, useEffect } from "react";
var useCookie = (cookieName) => {
  const initialCookieValue = getCookie(cookieName);
  const [cookie2, setCookieState] = useState();
  const setCookie = (value) => {
    internalSetCookie(cookieName, value);
    setCookieState(value);
  };
  useEffect(() => {
    setCookieState(initialCookieValue);
  }, [initialCookieValue]);
  return { cookie: cookie2, setCookie };
};

// src/league-intl-provider/league-intl-provider.component.tsx
var LeagueIntlProvider = ({ children }) => {
  const { cookie: cookieLocale, setCookie: setLocale } = useCookie(LANGUAGE_COOKIE_NAME);
  const currentLocale = getCurrentLocale(DEFAULT_LOCALE);
  const locale = cookieLocale || currentLocale;
  return /* @__PURE__ */ React3.createElement(LocaleContext.Provider, { value: { locale, setLocale } }, /* @__PURE__ */ React3.createElement(IntlProviderWrapper, null, children));
};

// src/formatted-html-message.component.tsx
import React4 from "react";
var FormattedHTMLMessage = ({
  id,
  description,
  defaultMessage,
  values,
  ignoreTag,
  tagName = "span",
  children
}) => {
  const { formatMessage } = useIntl();
  const descriptor = {
    id,
    description,
    defaultMessage
  };
  const nodes = formatMessage(descriptor, values, { ignoreTag });
  if (children) {
    return children(Array.isArray(nodes) ? nodes : [nodes]);
  }
  const Tag = tagName;
  return /* @__PURE__ */ React4.createElement(
    Tag,
    {
      dangerouslySetInnerHTML: {
        __html: nodes
      }
    }
  );
};
export {
  CA_COUNTRY_CODE,
  DEFAULT_LOCALE,
  DEFAULT_MESSAGES,
  en_default as EN,
  es_MX_default as ES,
  fr_CA_default as FR,
  FormattedHTMLMessage,
  FullNameLocaleList,
  LeagueIntlProvider,
  LocaleContext,
  US_COUNTRY_CODE,
  defaultRichTextElements,
  getAppSupportedLocale,
  getCurrentLocale,
  getReactIntlProps,
  intlShape,
  isFrench,
  isSpanish,
  supportedLocaleList,
  supportedLocaleMapping,
  useIntl
};
