import { ChangeEvent, MouseEvent, useRef } from 'react';
import { styled, TextAction } from '@leagueplatform/genesis-core';

const InvisibleInput = styled('input', {
  display: 'none',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const fileSizeLimit = 1000000; // 1MB

interface Props {
  title: string;
  setIsFileTooLarge: (val: boolean) => void;
  uploadProfilePhoto: (file: File) => void;
  onClickCallback?: () => void;
}

export const InvisibleFileInput = ({
  title,
  setIsFileTooLarge,
  uploadProfilePhoto,
  onClickCallback = () => {},
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <label htmlFor="photo-file-input" style={{ position: 'relative' }}>
      <InvisibleInput
        id="photo-file-input"
        ref={inputRef}
        type="file"
        accept="image/*"
        data-testid="file-input"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!e.currentTarget.files) return;
          const file = e?.currentTarget?.files[0];
          if (file) {
            if (file.size < fileSizeLimit) uploadProfilePhoto(file);
            else setIsFileTooLarge(true);
          }
        }}
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          (e.target as HTMLInputElement).value = '';
          setIsFileTooLarge(false);
          onClickCallback();
        }}
      />
      <TextAction as="span">{title}</TextAction>
    </label>
  );
};
