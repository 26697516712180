import type { ReactNode } from 'react';
import type { AgreementStatus } from 'common/types';
import { useState, useEffect, useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { BasePage } from 'components/base-page';
import { useGetAgreementStatus } from 'hooks/use-get-agreement-status.hook';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { ReacceptTOUAgreement } from 'components/forms/reaccept-tou-agreement/reaccept-tou-agreement.component';
import { FullPageError } from 'components/error/full-page-error.component';
import { AGREEMENT_STATUS_TYPES } from 'common/constants';
import { ReacceptGINAAgreement } from 'components/reaccept-gina-agreement/reaccept-gina-agreement.component';

export const AgreementStatusCheckContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { formatMessage } = useIntl();
  const { data, isLoading, isError, refetch } = useGetAgreementStatus({
    // Guarantee that specific agreements returned will need to be accepted again
    acceptanceStatus: false,
    types: [AGREEMENT_STATUS_TYPES.TOU, AGREEMENT_STATUS_TYPES.GINA],
  });

  const [displayTOU, setDisplayTOU] = useState<boolean | null>(null);
  const [displayGINA, setDisplayGINA] = useState<boolean | null>(null);

  // Check whether an agreement type was returned in the data.
  const checkDataForAgreement = useCallback(
    (agreementType: AgreementStatus['agreementType']): boolean =>
      !!data?.find(
        ({ attributes }: { attributes: AgreementStatus }) =>
          attributes.agreementType === agreementType,
      ),
    [data],
  );

  useEffect(() => {
    if (data !== undefined && !isLoading) {
      // Set the agreement reacceptance display state based on data returned
      if (displayTOU === null) {
        setDisplayTOU(checkDataForAgreement(AGREEMENT_STATUS_TYPES.TOU));
      }
      if (displayGINA === null) {
        setDisplayGINA(checkDataForAgreement(AGREEMENT_STATUS_TYPES.GINA));
      }
    }
  }, [isLoading, data, displayTOU, displayGINA, checkDataForAgreement]);

  if (isLoading) return <FullPageLoadingSpinner />;

  if (isError) {
    return (
      <FullPageError
        errorType={ErrorTypes.API_CONNECTION_ERROR}
        buttonOnClick={() => refetch()}
        buttonChildren={formatMessage({ id: 'RETRY' })}
      />
    );
  }

  //  Allow the user to proceed into the app if no agreements require reacceptance
  if (data.length === 0) return <BasePage>{children}</BasePage>;

  if (displayTOU) {
    return (
      <ReacceptTOUAgreement endReacceptanceFlow={() => setDisplayTOU(false)} />
    );
  }

  if (displayGINA) {
    return (
      <ReacceptGINAAgreement
        endReacceptanceFlow={() => setDisplayGINA(false)}
      />
    );
  }

  return <BasePage>{children}</BasePage>;
};
