import { NavLink } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH } from 'common/constants';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import {
  Box,
  HeadingText,
  StackLayout,
  Icon,
  styled,
} from '@leagueplatform/genesis-core';
import DocumentDownloadIcon from 'assets/document-download.svg';

const ActionLink = styled(NavLink, {
  color: '$onSurfaceTextSubdued',
  textDecoration: 'none',
  borderRadius: '3px',
});

export const PersonalInfoActions = () => {
  const { formatMessage } = useIntl();
  const { featureFlags } = useFeatureFlags([
    FEATURE_FLAGS.VIEW_GINA,
    FEATURE_FLAGS.CONSENT_MANAGEMENT,
  ]);

  const {
    [FEATURE_FLAGS.VIEW_GINA]: viewGinaIsEnabled,
    [FEATURE_FLAGS.CONSENT_MANAGEMENT]: consentManagementIsEnabled,
  } = featureFlags;

  if (!viewGinaIsEnabled || consentManagementIsEnabled) return null;

  return (
    <StackLayout orientation="vertical" spacing="$oneAndHalf">
      <HeadingText
        as="h2"
        level="4"
        size="lg"
        css={{
          color: '$onSurfaceTextSubdued',
        }}
      >
        {formatMessage({ id: 'ACTIONS' })}
      </HeadingText>
      <StackLayout orientation="vertical" css={{ width: '100%' }}>
        <ActionLink
          to={VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH}
          css={{
            width: '100%',
            color: '$onSurfaceTextSubdued',
            '&:hover, &:focus': {
              color: '$onSurfaceTextPrimary',
            },
          }}
        >
          <StackLayout
            orientation="horizontal"
            verticalAlignment="center"
            horizontalAlignment="spaceBetween"
          >
            <StackLayout
              orientation="horizontal"
              spacing="$half"
              verticalAlignment="center"
            >
              <Icon icon={DocumentDownloadIcon} aria-hidden />
              <Box as="span">
                {formatMessage({ id: 'VIEW_HEALTH_INFORMATION_AUTHORIZATION' })}
              </Box>
            </StackLayout>
            <Icon
              icon="interfaceChevronRight"
              css={{
                color: '$onSurfaceTextSubdued',
              }}
            />
          </StackLayout>
        </ActionLink>
      </StackLayout>
    </StackLayout>
  );
};
