// TODO: Import only as types once SDK is updated
import {
  LiveChatClient,
  EventTypes,
  ChatEndedReason,
  ChatEndedReasonTypes,
} from '@leagueplatform/live-chat';
import cometDLib, { Message, ListenerHandle } from 'cometd';
import {
  LIVE_CHAT,
  LIVE_CHAT_EVENTS,
  LIVE_CHAT_OPERATIONS,
  LIVE_CHAT_QUIT_CODES,
} from './constants';

type ChatConfig = {
  [key: string]: string;
};

type Participant = {
  participantId: number;
  nickname: string;
  type: string;
};

const cometD = new cometDLib.CometD();
let savedSecureKey: string | undefined;
let handshakeListener: ListenerHandle;
let channelListener: ListenerHandle;

const disconnectChat = () => {
  cometD.removeListener(handshakeListener);
  cometD.removeListener(channelListener);
  cometD.disconnect();
  savedSecureKey = undefined;
};

const userChatConfig = (config: ChatConfig) => {
  // console.log(config);
  const { token, ...userData } = config;
  const {
    ENT_ContactFirstNm: firstName,
    ENT_ContactLastNm: lastName,
    ENT_SubFunction: subject,
  } = userData;

  userData.ENT_BookingDeviceType = LIVE_CHAT.BOOKING_DEVICE_TYPE;
  // this is a required KVP but since we're on web, leave it blank
  userData.ENT_DeviceOS = '';
  // this is the page the user has come to chat from, right now this is the only entry point
  userData.CHAT_PortalPage = LIVE_CHAT.PORTAL_PAGE;

  return {
    operation: LIVE_CHAT_OPERATIONS.REQUEST_CHAT,
    nickname: firstName,
    firstName,
    lastName,
    subject,
    userData,
  };
};

const startChat = (config: ChatConfig) => {
  const { token } = config;
  const url = `${LIVE_CHAT.SERVER_URL}?access_token=${token}`;

  cometD.websocketEnabled = true;
  cometD.configure({
    url,
    // appending messages is not supported, so disable it
    appendMessageTypeToURL: false,
  });

  const handshake = (res: Message) => {
    const userConfig = {
      ...userChatConfig(config),
    };

    // TODO: What do we want to happen if the handshake fails
    if (res.successful) {
      cometD.publish(LIVE_CHAT.CHANNEL, userConfig);
    }
  };

  handshakeListener = cometD.addListener(
    LIVE_CHAT.HANDSHAKE_CHANNEL,
    handshake,
  );

  cometD.handshake();
};

const getParticipantDetails = (
  from: Participant,
): { id: string; name: string } => ({
  id: from?.participantId?.toString(),
  name: from?.nickname,
});

const queueRegex = /^You are currently \d* in queue.$/;

const isWaitingRoom = ({
  from,
  text,
}: {
  from: Participant;
  text: string;
}): boolean =>
  from?.type === LIVE_CHAT.EXTERNAL &&
  (text === LIVE_CHAT.WAITING_ROOM_TITLE || queueRegex.test(text));

export const optumLiveChatExtension: LiveChatClient = {
  subscribeToAgentChatEvents: (updateMessagingUI) => {
    // We need to keep track of whether chat is active so we can tell when to fire WAITING_ROOM or CHAT_STARTED events
    let chatActive = false;
    channelListener = cometD.addListener(LIVE_CHAT.CHANNEL, (res: Message) => {
      const { data } = res;

      const { messages, secureKey, chatEnded } = data;
      let quitReason: ChatEndedReason = {
        type: ChatEndedReasonTypes.CHAT_UNAVAILABLE,
      };

      if (secureKey) {
        savedSecureKey = secureKey;
      }

      if (messages.length) {
        const { type, index, from, utcTime, text, eventAttributes } =
          messages[0];
        const isClient = from?.type === LIVE_CHAT.CLIENT;
        const id = `chat-event-${index.toString()}`;

        const quitCode = eventAttributes?.GCTI_SYSTEM?.['quit-reason-code'];

        switch (quitCode) {
          case LIVE_CHAT_QUIT_CODES.LOGOUT_REQUEST:
            quitReason = { type: ChatEndedReasonTypes.USER_DISCONNECTED };
            break;
          case LIVE_CHAT_QUIT_CODES.CHAT_PROTOCOL_INACTIVITY:
          case LIVE_CHAT_QUIT_CODES.CHAT_SESSION_INACTIVITY:
          case LIVE_CHAT_QUIT_CODES.REMOVED_DURING_RESTORATION:
            quitReason = { type: ChatEndedReasonTypes.CONNECTION_LOST };
            break;
          case LIVE_CHAT_QUIT_CODES.PARTICIPANT_REMOVED_PARTICIPANT:
            quitReason = { type: ChatEndedReasonTypes.AGENT_DISCONNECTED };
            break;
          case LIVE_CHAT_QUIT_CODES.SERVER_REMOVED_PARTICIPANT:
            quitReason = {
              type: ChatEndedReasonTypes.CHAT_UNAVAILABLE,
              // TODO: Localize these strings
              // https://everlong.atlassian.net/jira/software/c/projects/OPTM/boards/559/backlog?selectedIssue=OPTM-4069
              errorTitle: LIVE_CHAT.CLOSED_TITLE,
              errorMessage: LIVE_CHAT.CLOSED_MESSAGE,
            };
            break;
          default:
        }

        if (!isClient) {
          switch (type) {
            case LIVE_CHAT_EVENTS.TYPING_STARTED:
            case LIVE_CHAT_EVENTS.TYPING_STOPPED:
              // Ignore user typing events, since we don't need them shown in our UI
              updateMessagingUI({
                eventType: EventTypes.PARTICIPANT_TYPING,
                eventId: id,
                isTyping: type === LIVE_CHAT_EVENTS.TYPING_STARTED,
              });
              break;
            case LIVE_CHAT_EVENTS.MESSAGE:
            case LIVE_CHAT_EVENTS.PUSH_URL:
              if (
                type === LIVE_CHAT_EVENTS.MESSAGE &&
                isWaitingRoom({ from, text })
              ) {
                updateMessagingUI({
                  eventId: id,
                  eventType: EventTypes.WAITING_ROOM,
                  title: LIVE_CHAT.WAITING_ROOM_TITLE,
                  description: queueRegex.test(text) ? text : '',
                });
                chatActive = false;
              }
              updateMessagingUI({
                eventId: id,
                eventType: EventTypes.MESSAGE,
                sender: getParticipantDetails(from),
                timestamp: utcTime,
                message: text,
              });
              break;
            case LIVE_CHAT_EVENTS.PARTICIPANT_JOINED:
              // If an agent joins, move out of the waiting room
              if (!chatActive && from?.type === LIVE_CHAT.AGENT) {
                updateMessagingUI({
                  eventType: EventTypes.CHAT_STARTED,
                  eventId: 'chat-event-start',
                });
                chatActive = true;
              }
              updateMessagingUI({
                eventId: id,
                eventType: EventTypes.PARTICIPANT_JOINED,
                participant: getParticipantDetails(from),
              });
              break;
            case LIVE_CHAT_EVENTS.PARTICIPANT_LEFT:
              updateMessagingUI({
                eventId: id,
                eventType: EventTypes.PARTICIPANT_LEFT,
                participant: getParticipantDetails(from),
              });
              break;
            default:
          }
        }
      }

      if (chatEnded) {
        updateMessagingUI({
          eventId: 'chat-event-end',
          eventType: EventTypes.CHAT_ENDED,
          reason: quitReason,
        });
        disconnectChat();
        chatActive = false;
      }
    });
  },
  handleUserChatEvents: (event) => {
    // console.log(event);
    switch (event.eventType) {
      case EventTypes.START_CHAT:
        if (event.config.token) startChat(event.config);
        break;
      case EventTypes.END_CHAT:
        cometD.publish(LIVE_CHAT.CHANNEL, {
          operation: LIVE_CHAT_OPERATIONS.DISCONNECT,
          secureKey: savedSecureKey,
        });
        disconnectChat();
        break;
      case EventTypes.MESSAGE:
        cometD.publish(LIVE_CHAT.CHANNEL, {
          operation: LIVE_CHAT_OPERATIONS.SEND_MESSAGE,
          message: event.message,
          secureKey: savedSecureKey,
        });
        break;
      case EventTypes.PARTICIPANT_TYPING:
        if (event.isTyping) {
          cometD.publish(LIVE_CHAT.CHANNEL, {
            operation: LIVE_CHAT_OPERATIONS.START_TYPING,
            secureKey: savedSecureKey,
          });
        } else {
          cometD.publish(LIVE_CHAT.CHANNEL, {
            operation: LIVE_CHAT_OPERATIONS.STOP_TYPING,
            secureKey: savedSecureKey,
          });
        }
        break;
      default:
    }
  },
};
