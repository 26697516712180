import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { uploadFile } from '@leagueplatform/league-content-api';
import {
  setUserProfile,
  GET_USER_PROFILE_MESSAGE_TYPE,
} from '@leagueplatform/user-profile-api';
import type { UserProfile } from '@leagueplatform/user-profile-api';
import { UserDataModel } from 'models/user-data.model';

/**
 *
 * @name useUploadUserProfilePhoto
 * @desc React hook to upload a user profile photo.
 * @returns Function that is used to upload a file, and returns user profile photo reference ID
 */
export const useUploadUserProfilePhoto = () => {
  const uploadProfilePhoto = useMutation((file: File) => uploadFile(file));

  // eslint-disable-next-line consistent-return
  return async (file: File) => {
    try {
      const response = await uploadProfilePhoto.mutateAsync(file);
      const referenceId: string = response?.reference_id;

      if (!referenceId) throw new Error();
      return referenceId;
    } catch (error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'PROFILE_UPDATE_FAILED',
      });
    }
  };
};

/**
 * @name useUpdateUserProfilePhoto
 * @desc React hook to update the user profile with a new avatar image id
 * @returns Function that updates user profile avatar image id
 */
export const useUpdateUserProfilePhoto = () => {
  const queryClient = useQueryClient();
  const updateUserProfileMutation = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]),
    },
  );

  return async (imageId: string) => {
    if (!imageId)
      throw new Error('No imageId was provided for user profile update.');
    const userProfile = {
      avatar: { image_id: imageId },
    };

    try {
      await updateUserProfileMutation.mutateAsync(userProfile);
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'PROFILE_UPDATED',
      });
    } catch (error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'PROFILE_UPDATE_FAILED',
      });
    }
  };
};

export const useManageUserProfileData = (
  data: UserDataModel,
): [
  {
    profilePhotoId: string;
    image: {
      src: string;
      width: string;
      alt: string;
    };
    uploadError?: boolean;
  },
  {
    setProfilePhotoId: (value: string) => void;
    setIsFileTooLarge: (value: boolean) => void;
    setUploadError: (value: boolean) => void;
    uploadProfilePhoto: (file: File) => Promise<void>;
  },
] => {
  const [profilePhotoId, setProfilePhotoId] = useState(
    data.userProfile?.avatar?.imageId || '',
  );
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const uploadUserPhoto = useUploadUserProfilePhoto();

  const memoizedImage = useMemo(
    () => ({
      src: profilePhotoId || '',
      width: '128px',
      alt: 'USER_PROFILE_PHOTO',
    }),
    [profilePhotoId],
  );

  useEffect(() => {
    if (data?.userProfile?.avatar?.imageId && !profilePhotoId) {
      setProfilePhotoId(data?.userProfile?.avatar?.imageId);
    }
  }, [data, profilePhotoId]);

  useEffect(() => {
    if (isFileTooLarge) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'FILE_TOO_LARGE_ERROR',
      });
    }
  }, [isFileTooLarge]);

  const uploadProfilePhoto = useCallback(
    async (file) => {
      try {
        const userProfilePhotoId = await uploadUserPhoto(file);
        if (userProfilePhotoId) {
          setProfilePhotoId(userProfilePhotoId);
        } else {
          throw new Error();
        }
      } catch (err) {
        setUploadError(true);
      }
    },
    [uploadUserPhoto],
  );

  return [
    {
      profilePhotoId,
      image: memoizedImage,
      uploadError,
    },
    {
      setProfilePhotoId,
      setIsFileTooLarge,
      setUploadError,
      uploadProfilePhoto,
    },
  ];
};
