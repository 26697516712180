import * as React from 'react';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { Box, Modal } from '@leagueplatform/genesis-core';
import { useModal } from 'hooks/use-modal.hook';
import { AccountDeletedModal } from './modals/account-deleted-modal.component';
import { DeleteAccountModal } from './modals/delete-account-modal.component';
import { AppNavBarComponent } from './navbar/nav.component';
import { APP_NAV_DEFAULT_HEIGHT } from './navbar/nav.constants';

interface Props {
  isPrivatePage?: boolean;
  height?: string;
  children: React.ReactNode;
}

export const BasePage = ({
  children,
  isPrivatePage = true,
  height = 'auto',
}: Props) => {
  const {
    isShowing: showDeleteAccountModal,
    toggle: toggleDeleteAccountModal,
  } = useModal();
  const {
    isShowing: showAccountDeletedModal,
    toggle: toggleAccountDeletedModal,
  } = useModal();

  const handleAccountDeleted = () => {
    StandaloneAuth.client.logout();
  };

  useScrollTopOnLocationChange();

  return (
    <>
      {isPrivatePage && (
        <AppNavBarComponent handleDeleteAccount={toggleDeleteAccountModal} />
      )}
      <Modal.Root
        open={showDeleteAccountModal}
        onOpenChange={toggleDeleteAccountModal}
      >
        <DeleteAccountModal
          onSubmit={toggleAccountDeletedModal}
          isOpen={showDeleteAccountModal}
        />
      </Modal.Root>
      <Modal.Root
        open={showAccountDeletedModal}
        onOpenChange={() => {
          toggleAccountDeletedModal();
          handleAccountDeleted();
        }}
      >
        <AccountDeletedModal />
      </Modal.Root>
      <Box
        as="main"
        id="main-content"
        css={{
          position: 'relative',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          minHeight: `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`,
          flex: 1,
          height,
        }}
      >
        {children}
      </Box>
    </>
  );
};
