import {
  CoverImage,
  Flex,
  genesisStyled,
  css,
} from '@leagueplatform/genesis-commons';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { UserDataModel } from 'models/user-data.model';

const EmptyImageCard = genesisStyled(Flex)(
  css({
    alignItems: 'center',
    justifyContent: 'center',
    width: '162.03px',
    height: '162.03px',
    borderRadius: '50%',
    color: 'onSurface.text.primary',
    backgroundColor: 'surface.card.primary',
    fontSize: '32px',
    fontWeight: 'bold',
  }),
);

interface Prop {
  user: {
    data: UserDataModel;
    image?: {
      src: string;
      width: string;
    };
  };
}

export const ProfileImage = ({ user }: Prop) => {
  if (user?.image?.src) {
    return (
      <CoverImage
        src={getContentUrl(user?.image.src)}
        alt={`${user?.data?.userProfile?.preferredFirstName} ${user?.data?.userProfile?.lastName}`}
        size={user.image.width}
        borderRadius="circle"
        marginBottom="one"
      />
    );
  }

  if (!user?.data?.userInitials) return null;

  return (
    <EmptyImageCard>
      <span>{user?.data.userInitials} </span>
    </EmptyImageCard>
  );
};
