import { Suspense } from 'react';
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ExternalRedirect } from '@leagueplatform/web-common-components';
import {
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  CareDocumentsRoutes,
  FamilyExperienceRoutes,
  HealthJourneyRoutes,
  Home,
  LiveChatRoutes,
  MessagingRoutes,
  WalletRoutes,
} from 'components/lazy-routes.component';
import { LandingPage } from 'pages/landing.page';
import {
  PrivacyPolicyPage,
  TermsOfUsePage,
} from 'pages/agreement-content/tou-and-pp-agreement-content.pages';
import { TbkLesson } from 'pages/tbk/tbk.page';
import { UserProfilePage } from 'pages/settings/personal-information/personal-information.page';
import { AboutCareAdvisorPage } from 'pages/about/about-care-advisor-connect.page';
import { CommunicationPreferencesPage } from 'pages/settings/communication-preferences/communication-preferences.page';
import { AuthSignIn } from 'pages/auth/auth-sign-in.page';
import { AuthSignOut } from 'pages/auth/auth-sign-out.page';
import { HealthAuthorizationPage } from 'pages/settings/health-info-authorization/health-info-authorization.page';
import { CallbackRequestPage } from 'pages/callback-request/callback-request.page';
import { SignHealthAuthorizationPage } from 'pages/sign-health-authorization/sign-health-authorization.page';
import {
  OPTUM_FAQ,
  OPTUM_PRIVACY_POLICY_LINK,
  OPTUM_TERMS_CONDITION_LINK,
  TERMS_OF_USE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH,
  PATHS,
} from 'common/constants';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { AuthSessionContainer } from 'components/auth-session-container/auth-session-container';
import { PrivateRoutesContainer } from 'components/private-routes-container/private-routes-container';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import { FlagProtectedComponent } from 'components/flag-protected-component/flag-protected-component.component';
import { EPMPPage } from 'pages/epmp/epmp.page';

export function AppRoutes() {
  const { featureFlags, isLoading: featureFlagsAreLoading } = useFeatureFlags([
    FEATURE_FLAGS.VIEW_GINA,
    FEATURE_FLAGS.LIVE_CHAT,
    FEATURE_FLAGS.CONSENT_MANAGEMENT,
  ]);

  const {
    [FEATURE_FLAGS.VIEW_GINA]: viewGinaIsEnabled,
    [FEATURE_FLAGS.LIVE_CHAT]: liveChatIsEnabled,
    [FEATURE_FLAGS.CONSENT_MANAGEMENT]: consentManagementIsEnabled,
  } = featureFlags;

  return (
    <Suspense fallback={<FullPageLoadingSpinner />}>
      <Switch>
        <Route path="/sign-in" component={AuthSignIn} />
        <Route path="/sign-out" component={AuthSignOut} />
        <Route path={TERMS_OF_USE_ROUTE}>
          <FlagProtectedComponent
            flag={consentManagementIsEnabled}
            isLoading={featureFlagsAreLoading}
            componentRenderedWhenTrue={<TermsOfUsePage />}
            componentRenderedWhenFalse={
              <ExternalRedirect to={OPTUM_TERMS_CONDITION_LINK} />
            }
          />
        </Route>
        <Route path={PRIVACY_POLICY_ROUTE}>
          <FlagProtectedComponent
            flag={consentManagementIsEnabled}
            isLoading={featureFlagsAreLoading}
            componentRenderedWhenTrue={<PrivacyPolicyPage />}
            componentRenderedWhenFalse={
              <ExternalRedirect to={OPTUM_PRIVACY_POLICY_LINK} />
            }
          />
        </Route>
        <Route path="/" exact component={LandingPage} />
        <AuthSessionContainer>
          <PrivateRoutesContainer>
            <Route path="/home" exact component={Home} />
            <Route
              path={[
                getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
              ]}
              component={HealthJourneyRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
            />
            <Switch>
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDocuments)}
                component={CareDocumentsRoutes}
              />
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
                component={CareDiscoveryRoutes}
              />
            </Switch>
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.messaging)}
              component={MessagingRoutes}
            />
            <Route path={getModulePath(LEAGUE_MODULE_NAMES.familyExperience)}>
              <FamilyExperienceRoutes appName="optum" />
            </Route>
            <Route
              path="/settings/personal-information"
              component={UserProfilePage}
            />
            <Route path="/callback-request" component={CallbackRequestPage} />
            <Route
              path="/about-care-advisor"
              component={AboutCareAdvisorPage}
            />
            <Route
              path="/communication-preferences"
              exact
              component={CommunicationPreferencesPage}
            />
            {/* TODO this route should not display the navigation */}
            <Route path={PATHS.EPMP} exact component={EPMPPage} />
            <Route
              path="/client-optum-tbk/courses/:courseIdentifier/lessons/:lessonIdentifier"
              component={TbkLesson}
            />

            {viewGinaIsEnabled && (
              <Route
                path={VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH}
                component={HealthAuthorizationPage}
              />
            )}
            {liveChatIsEnabled && (
              <Route
                path={getModulePath(LEAGUE_MODULE_NAMES.liveChat)}
                component={LiveChatRoutes}
              />
            )}
            {consentManagementIsEnabled && (
              <Route
                path={PATHS.SIGN_HEALTH_AUTHORIZATION}
                component={SignHealthAuthorizationPage}
              />
            )}
            {/* External redirects used for mobile deeplinking */}
            <Route path="/faq">
              <ExternalRedirect to={OPTUM_FAQ} />
            </Route>
          </PrivateRoutesContainer>
        </AuthSessionContainer>
      </Switch>
    </Suspense>
  );
}
