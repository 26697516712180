import { useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { ProfileImageSection } from 'components/profile-image-section/profile-image-section.component';
import {
  usePersonalInformation,
  useUpdateUserProfile,
} from 'hooks/use-personal-information.hook';
import { useElementHeight } from 'hooks/use-element-height.hook';
import { useManageUserProfileData } from 'hooks/use-upload-profile-photo.hook';
import { OnboardingStage } from 'models/onboarding-stage.model';
import { FormContent } from 'components/forms/account-setup/form-content.component';
import { FixedFormFooter } from 'components/forms/fixed-form-footer.component';
import { AccountSetupTopSection } from 'components/forms/account-setup/top-section.component';
import { acceptMemberTerms } from 'api/accept-member-terms';
import { AccountSetupFormValues } from 'components/forms/account-setup/account-setup.types';
import { useFeatureFlags } from 'hooks/use-feature-flags.hook';
import { FEATURE_FLAGS } from 'common/firebase';
import { setAgreementStatus } from 'api/set-agreement-status';
import { AGREEMENT_STATUS_TYPES } from 'common/constants';

export const AccountSetupForm = ({
  setStatus,
}: {
  setStatus: (stage: OnboardingStage) => void;
}) => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'ACCOUNT_SETUP' }));
  const { data } = usePersonalInformation();
  const updateUserProfile = useUpdateUserProfile();
  const [
    { profilePhotoId, image, uploadError },
    { setIsFileTooLarge, uploadProfilePhoto, setUploadError },
  ] = useManageUserProfileData(data);
  const { featureFlags } = useFeatureFlags([FEATURE_FLAGS.CONSENT_MANAGEMENT]);

  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.ACCOUNT_SET_UP,
      screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
    });
  }, []);

  const onSubmit = async (
    values: AccountSetupFormValues,
    actions: FormikHelpers<AccountSetupFormValues>,
  ) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: SUB_PRODUCT_AREA.ACCOUNT_SET_UP,
      screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
      detail: 'continue',
    });
    try {
      const { isEmailEnabled, preferredFirstName } = values;

      const userData = {
        avatar:
          profilePhotoId !== data?.userProfile?.avatar?.imageId
            ? {
                imageId: profilePhotoId,
              }
            : {},
        preferredFirstName,
        optedIntoMarketingCommunications: isEmailEnabled,
      };

      await Promise.all([
        updateUserProfile(userData),
        featureFlags.rel_as_consent_management
          ? setAgreementStatus({
              agreementType: AGREEMENT_STATUS_TYPES.TOU,
              isAccepted: true,
            })
          : acceptMemberTerms(),
      ]);
      actions.resetForm({ values });
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'PROFILE_UPDATED',
      });
      setStatus('ginaConsent');
    } catch (error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'PROFILE_UPDATE_FAILED',
      });
    }
    actions.setSubmitting(false);
  };

  // Track footer height and adjust form bottom margin accordingly
  const { elementRef: footerRef, elementHeight: footerHeight } =
    useElementHeight();

  const initialValues: AccountSetupFormValues = {
    preferredFirstName: data?.userProfile?.preferredFirstName ?? '',
    isTermsAccepted: false,
    isEmailEnabled: !!data?.userProfile?.optedIntoMarketingCommunications,
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={() => ({})}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <Form>
          <StackLayout
            horizontalAlignment="center"
            css={{
              maxWidth: 768,
              padding: '$two',
              margin: '$none auto',
              marginBottom: footerHeight,
              '@mobile': { padding: '$oneAndHalf' },
            }}
          >
            <AccountSetupTopSection
              title={formatMessage({ id: 'ACCOUNT_SETUP_TITLE' })}
              description={formatMessage({ id: 'ACCOUNT_SETUP_DESCRIPTION' })}
            />
            <ProfileImageSection
              title={formatMessage({
                id: profilePhotoId
                  ? 'UPDATE_PROFILE_PHOTO'
                  : 'ADD_PROFILE_PHOTO',
              })}
              user={{ image, data }}
              error={uploadError}
              setIsFileTooLarge={setIsFileTooLarge}
              setUploadError={setUploadError}
              uploadProfilePhoto={uploadProfilePhoto}
              onCTAClick={() => {
                trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                  product_area: PRODUCT_AREA.ONBOARDING,
                  screen_name: SCREEN_NAMES.ACCOUNT_SET_UP,
                  details: `${profilePhotoId ? 'update' : 'add'} profile photo`,
                });
              }}
            />
            <StackLayout
              spacing="$one"
              horizontalAlignment="stretch"
              css={{ marginTop: '$oneAndHalf' }}
            >
              <FormContent setFieldValue={formProps.setFieldValue} />
            </StackLayout>
          </StackLayout>
          <FixedFormFooter
            dirty={formProps?.dirty}
            back={() => setStatus('featureHighlights')}
            allowSubmit={!!formProps?.values?.isTermsAccepted}
            isLoading={formProps.isSubmitting}
            submitText={formatMessage({ id: 'CONTINUE' })}
            ref={footerRef}
          />
        </Form>
      )}
    </Formik>
  );
};
