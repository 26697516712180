import { useEffect } from 'react';
import { Box, Flex } from '@leagueplatform/genesis-commons';
import { useHistory, useParams } from '@leagueplatform/routing';
import { captureMessage } from '@leagueplatform/observability';

interface TbkEmbedProps {
  type: string;
  identifier: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tbk-embed': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > &
        TbkEmbedProps;
    }
  }
  interface Window {
    TBK: unknown;
  }
}

export const TbkLesson: React.FC = () => {
  const { courseIdentifier, lessonIdentifier } = useParams<{
    courseIdentifier: string;
    lessonIdentifier: string;
  }>();
  const history = useHistory();

  useEffect(() => {
    // TBK object on window doesn't exist & the embed doesn't have child elements
    if (!window?.TBK && !document.querySelector('tbk-embed')?.hasChildNodes()) {
      captureMessage('TBK: unable to load embed', {
        severityLevel: 'error',
        tags: {
          course: courseIdentifier,
          lesson: lessonIdentifier,
        },
        context: {
          'TBK Info': {
            course: courseIdentifier,
            lesson: lessonIdentifier,
            'opened-in-new-tab': history.length === 1,
            'window.TBK': Boolean(window.TBK),
            'embed-has-child-nodes': document
              .querySelector('tbk-embed')
              ?.hasChildNodes(),
            'retrieved-tbk-token': Boolean(localStorage.getItem('tbkUserInfo')),
            'tbk-assets': document
              .querySelectorAll(
                'script[src*="thebigknow"], link[rel="stylesheet"][href*="thebigknow"]',
              )
              .forEach(
                (element) =>
                  element.getAttribute('src') || element.getAttribute('href'),
              ),
          },
        },
      });
    }
  }, [courseIdentifier, lessonIdentifier, history.length]);
  return (
    <Flex flexGrow={1}>
      <Box width="100%" alignSelf="center">
        <tbk-embed
          type="lesson"
          course-identifier={courseIdentifier}
          identifier={lessonIdentifier}
        />
      </Box>
    </Flex>
  );
};
