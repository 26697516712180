import { useEffect } from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useGetPartnerAccessToken } from 'hooks/use-get-partner-access-token.hook';
// import type { OptumUserProfile } from 'common/types';

const defaultGlobalLoaderConfig = {
  theme: {
    fonts: {
      light: {
        fontFamily: 'UHC Sans',
        fontStyle: 'normal',
        fontWeight: '300',
      },
      normal: {
        fontFamily: 'UHC Sans',
        fontStyle: 'normal',
        fontWeight: '400',
      },
      bold: {
        fontFamily: 'UHC Sans',
        fontStyle: 'normal',
        fontWeight: '700',
      },
    },
  },
};

export const EPMPPage = () => {
  const { data: token } = useGetPartnerAccessToken();
  const { data: userData } = useGetUserProfile();
  const userProfile = userData?.userProfile;

  useEffect(() => {
    if (token && userProfile) {
      // TODO use user data from the BE for initalize function
      // const {
      //   firstName,
      //   lastName,
      //   dateOfBirth,
      //   optumSubscriberId,
      // }: OptumUserProfile = userData.userProfile;
      // const userData = {
      //   token,
      //   firstName: firstName,
      //   lastName: lastName,
      //   dob: dateOfBirth?.split('T')[0],
      //   idValue: optumSubscriberId,
      //   idType: 'SCR',
      // };

      const initializeGlobalLoader = (configUrl: string) =>
        window?.globalLoader?.initialize({
          ...defaultGlobalLoaderConfig,
          globalLoaderConfigUrl: configUrl,
          token,
          // TODO Replace hardcoded data with data from the BE
          userData: {
            lastName: 'Juneja',
            firstName: 'Neha',
            policyNumber: '101902',
            dob: '1995-03-24',
            idType: 'SCR',
            idValue: '1539759',
          },
        });

      try {
        initializeGlobalLoader(import.meta.env.VITE_EPMP_PREFERENCE_CONFIG_URL);
        initializeGlobalLoader(import.meta.env.VITE_EPMP_CONTACT_CONFIG_URL);
      } catch {
        // TODO add observability for if initializefn doesn't exist
        // TODO add observability for any other errors occurring during initialize
        // TODO display error page?
      }
    }
  }, [token, userProfile]);

  // TODO change the order of the UI based on search param
  return (
    <>
      <epmp-preferences />
      <epmp-contact />
    </>
  );
};
