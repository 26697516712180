import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useOnlineStatus } from '@leagueplatform/web-common';
import League from '@leagueplatform/core';

import { initializeSDKConfig } from 'init-config';
import { AppRoutes } from 'components/app-routes';
import { ErrorBoundaryWrapper } from 'components/error/error-boundary.component';
import { FullPageError } from 'components/error/full-page-error.component';
import { ErrorTypes } from 'hooks/use-error-message.hook';
import { RemoteConfigProvider } from 'components/remote-config-provider/remote-config-provider.component';
import { AppEntryWrapper } from 'components/app-entry-wrapper/app-entry-wrapper.component';

initializeSDKConfig();

export function App() {
  const { isOnline, isOffline } = useOnlineStatus();

  return (
    <League.LeagueProvider>
      {isOffline && <FullPageError errorType={ErrorTypes.NO_CONNECTION} />}
      {isOnline && (
        <ErrorBoundaryWrapper>
          <RemoteConfigProvider>
            <AppEntryWrapper>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </AppEntryWrapper>
          </RemoteConfigProvider>
        </ErrorBoundaryWrapper>
      )}
      <ReactQueryDevtools />
    </League.LeagueProvider>
  );
}
