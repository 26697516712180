/* eslint-disable react/jsx-props-no-spreading */
import { useHistory } from '@leagueplatform/routing';
import { Formik, Form, FormikHelpers } from 'formik';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import { useIntl } from '@leagueplatform/locales';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import {
  isPhoneNumber,
  formatPhoneNumber,
  stripPhoneNumber,
} from 'utils/phone-number';
import { UserDataModel } from 'models/user-data.model';
import { useElementHeight } from 'hooks/use-element-height.hook';
import { PersonalInfoActions } from 'components/personal-info-actions/personal-info-actions.component';
import { FixedFormFooter } from 'components/forms/fixed-form-footer.component';
import { FormContent } from 'components/forms/user-profile/form-content.component';

interface Props {
  updateUserProfile: (values: UserDataModel) => void;
  data: UserDataModel;
  profilePhotoId: string;
}

/**
 * While the API returns the user profile data as a nested object inside a larger
 * object, we want to flatten it for use as a collection of fields in this form,
 * so we'll remove the `userProfile` property from the parent and spread its contents
 * *into* the parent instead.
 */
export type UserProfileFormValues = Omit<UserDataModel, 'userProfile'> &
  UserDataModel['userProfile'];

export const UserProfileForm: React.FC<Props> = ({
  data,
  updateUserProfile,
  profilePhotoId,
}: Props) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const onSubmit = async (
    values: UserProfileFormValues,
    actions: FormikHelpers<UserProfileFormValues>,
  ) => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.PERSONAL_INFO,
      detail: 'save',
    });
    try {
      let userData = {
        ...values,
        ...(values.phoneNumber && {
          phoneNumber: stripPhoneNumber(values.phoneNumber),
        }),
      };

      if (profilePhotoId !== data?.userProfile?.avatar?.imageId) {
        userData = {
          ...userData,
          avatar: {
            imageId: profilePhotoId,
          },
        };
      }

      await updateUserProfile(userData as UserDataModel);

      actions.resetForm({ values });

      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'PROFILE_UPDATED',
      });
    } catch (error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'PROFILE_UPDATE_FAILED',
      });
    }
    actions.setSubmitting(false);
  };

  const validate = (values: UserProfileFormValues) => {
    let errors: UserProfileFormValues = {};

    if (values?.phoneNumber && !isPhoneNumber(values.phoneNumber)) {
      errors = {
        phoneNumber: formatMessage({
          id: 'PHONE_NUMBER_VALIDATION_ERROR_MESSAGE',
        }),
      };
    }
    return errors;
  };

  const initialFormValues: UserProfileFormValues = {
    ...data.userProfile,
    email: data?.email,
    phoneNumber: data?.phoneNumber && formatPhoneNumber(data.phoneNumber),
  };

  // Track footer height and adjust form bottom margin accordingly
  const { elementRef: footerRef, elementHeight: footerHeight } =
    useElementHeight();

  return (
    <Formik
      initialValues={initialFormValues}
      enableReinitialize
      validate={validate}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <Form>
          <FormContent
            errors={formProps.errors}
            handleChange={formProps.handleChange}
            setFieldValue={formProps.setFieldValue}
          />
          <div style={{ marginBottom: footerHeight }}>
            <PersonalInfoActions />
          </div>
          <FixedFormFooter
            dirty={formProps.dirty}
            back={() => history.push('/home')}
            allowSubmit={Object.keys(formProps.errors).length === 0}
            isLoading={formProps.isSubmitting}
            submitText={formatMessage({ id: 'SAVE' })}
            ref={footerRef}
          />
        </Form>
      )}
    </Formik>
  );
};
