import { OnboardingStage } from 'models/onboarding-stage.model';
import { useEffect, useState } from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { useLocalStorage } from './use-local-storage';

export type UseOnboardingReturn = {
  status: OnboardingStage;
  completed: boolean;
  isLoading: boolean;
  isError: boolean;
  handleFeatureHighlightCompleted: () => void;
  setStatus: (stage: OnboardingStage) => void;
};

export const useOnboarding = (): UseOnboardingReturn => {
  const [status, setStatus] = useState<OnboardingStage>('none');

  const [featureHighlightCompleted, setFeatureHighlightCompleted] =
    useLocalStorage('featureHighlightCompleted', false);
  const { data, isLoading, isError } = useGetUserProfile();
  const termsAccepted =
    !!data?.userProfile?.memberTermsAndConditions?.dateAccepted;
  const ginaConsent = !!data?.userProfile?.ginaConsent?.dateAccepted;
  const completed = termsAccepted && ginaConsent;

  const handleFeatureHighlightCompleted = () => {
    setFeatureHighlightCompleted(true);
  };

  useEffect(() => {
    if (!isLoading) {
      if (completed) {
        setStatus('completed');
      } else if (termsAccepted) {
        setStatus('ginaConsent');
      } else if (featureHighlightCompleted) {
        setStatus('accountSetup');
      } else {
        setStatus('featureHighlights');
      }
    }
  }, [completed, featureHighlightCompleted, termsAccepted, isLoading]);

  return {
    status,
    completed,
    isLoading,
    isError,
    setStatus,
    handleFeatureHighlightCompleted,
  };
};
