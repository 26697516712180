import { Field } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import {
  FormField,
  TextInput,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { formatPhoneNumber } from 'utils/phone-number';

const inputData = [
  {
    name: 'firstName',
    label: 'FIRST_NAME',
    disabled: true,
  },
  {
    name: 'lastName',
    label: 'LAST_NAME',
    disabled: true,
  },
  {
    name: 'preferredFirstName',
    label: 'PREFERRED_NAME',
  },
  {
    name: 'email',
    label: 'EMAIL_ADDRESS',
    disabled: true,
  },
  {
    name: 'phoneNumber',
    label: 'PHONE_NUMBER_OPTIONAL',
    placeholder: '(___) ___-____',
  },
  {
    name: 'dateOfBirth',
    label: 'DATE_OF_BIRTH',
    disabled: true,
  },
];

export const FormContent = ({
  errors,
  handleChange,
  setFieldValue,
}: {
  errors: { [key: string]: string };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean,
  ) => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout spacing="$two" css={{ marginBlock: '$one' }}>
      {inputData.map(({ name, label, disabled, placeholder }) => {
        const error = errors[name];
        return (
          <FormField
            id={name}
            name={name}
            label={formatMessage({ id: label })}
            inputStatus={error ? 'error' : undefined}
            statusMessage={error}
          >
            <Field
              as={TextInput}
              disabled={disabled}
              inputStatus={error ? 'error' : undefined}
              placeholder={placeholder}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChange(event);
                if (name === 'phoneNumber') {
                  const formatted = formatPhoneNumber(event.target.value);
                  setFieldValue(name, formatted);
                }
              }}
            />
          </FormField>
        );
      })}
    </StackLayout>
  );
};
