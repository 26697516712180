import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { StackLayout } from '@leagueplatform/genesis-core';
import {
  trackAnalyticsEvent,
  EVENT_NAME,
  PRODUCT_AREA,
  SCREEN_NAMES,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { UserProfileForm } from 'components/forms/user-profile/user-profile.form.component';
import { FullPageLoadingSpinner } from 'components/loaders/full-page-loading-spinner.component';
import { ProfileImageSection } from 'components/profile-image-section/profile-image-section.component';
import { HeroSection } from 'components/hero-section/hero-section.component';
import {
  usePersonalInformation,
  useUpdateUserProfile,
} from 'hooks/use-personal-information.hook';
import { useManageUserProfileData } from 'hooks/use-upload-profile-photo.hook';

export const UserProfilePage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'PERSONAL_INFO' }));
  const { data, isLoading } = usePersonalInformation();
  const updateUserProfile = useUpdateUserProfile();
  const [
    { profilePhotoId, image, uploadError },
    { setIsFileTooLarge, setUploadError, uploadProfilePhoto },
  ] = useManageUserProfileData(data);
  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.PERSONAL_INFO,
  });

  if (isLoading) {
    return <FullPageLoadingSpinner />;
  }

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'PERSONAL_INFO' })}
        goBack={history.goBack}
      />
      <ProfileImageSection
        title={formatMessage({
          id: profilePhotoId ? 'UPDATE_PROFILE_PHOTO' : 'ADD_PROFILE_PHOTO',
        })}
        user={{ image, data }}
        error={uploadError}
        setIsFileTooLarge={setIsFileTooLarge}
        setUploadError={setUploadError}
        uploadProfilePhoto={uploadProfilePhoto}
        onCTAClick={() => {
          trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
            product_area: PRODUCT_AREA.SETTINGS,
            screen_name: SCREEN_NAMES.PERSONAL_INFO,
            details: `${profilePhotoId ? 'update' : 'add'} profile photo`,
          });
        }}
      />
      <StackLayout
        horizontalAlignment="stretch"
        css={{
          width: '100%',
          maxWidth: 768,
          margin: '0 auto',
          paddingBlock: '$oneAndHalf',
        }}
      >
        <UserProfileForm
          updateUserProfile={updateUserProfile}
          data={data}
          profilePhotoId={profilePhotoId}
        />
      </StackLayout>
    </MainPageContainer>
  );
};
